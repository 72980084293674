import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormType } from 'src/app/enums/form-type';
import { FormViewModel } from 'src/app/models/form/form-view-model';

@Component({
  selector: 'app-form-inject',
  templateUrl: './form-injector.component.html',
  styleUrls: []
})
export class FormInjectorComponent implements OnInit {
  title = "Select Form(s)";
  filterTerm = null;
  forms :  FormViewModel[] = [];
  addedForms : number[] = [];
  addForm : Function;
  @Input() formType: FormType = FormType.Generic;
  FormType = FormType;

  get availableForms(): FormViewModel[] {
    return this.forms.filter(function (x) { return this.indexOf(x.id) < 0 }, this.addedForms);
  };
  constructor(public bsModalRef: BsModalRef,) { }

  ngOnInit() {
  }

  onAddForm(id: number) {
    var form = this.availableForms.find(x=> x.id == id);
    if(form && this.addForm)
    {
      this.addedForms.push(id);
      this.addForm(form);
    }
  }

}
