import { SeatingMode as FillMode } from './../enums/seating-mode.enum';
import { AccompanierMode } from '../enums/accompanier-mode';
import { AutomaticCheckInMode } from '../enums/automatic-checkin-mode';
import { Timezone } from './time-zone';
import { CountryViewModel } from './country-view-model';
import { UserDocumentType } from './user-document-type';
import { SiteQuestionsViewModel } from './sitequestion-viewmodel';
import { BaseSiteRequiredFieldViewModel } from './site-required-field-viewmodel';
import { AutoCheckOutType } from '../enums/auto-check-out-type.enum';
import { MultipleCheckinType } from '../enums/multiple-checkin-type.enum';
import { MaxPeopleOnSiteMode } from '../enums/max-people-on-site-mode.enum';
import { VisitorType } from './visitor-type';
import { ImportRecordState } from '../enums/import-record-status.enum';
import { ValidationResultModel } from './validation-result-model';
import { InductionMode } from '../enums/site-induction-mode.enum';
import { SiteAttendanceInductionViewModel } from './site-attendance-induction-view-model';
import { SiteVisitorTypeViewModel } from './site-visitortype-accompanier-viewmodel';
import { CheckInApprovalMode } from '../enums/check-in-approval-mode.enum';
import { DelayedAnswerMode } from '../enums/delayed-answer-mode';
import { QrCodeCheckinMode as QrCodeCheckinMode } from '../enums/qr-code-checkin-mode';
import { CheckinLocationMode } from '../enums/checkin-location-mode.enum';
import { AccessControlAutomaticAttendanceMode, AccessControlInductionMode, AccessControlSiteBriefingMode, AccessControlSupplierDocumentMode } from '../enums/access-control-automatic-attendance-mode';
import { AccessControlUnitModel } from './access-control/access-control-unit';
import { ExternalSitePermission } from '../enums/external-site-permission';
import { LocationOverrideMode } from '../enums/location-override-mode-enum';

export interface SiteCloningModel {
  name: string;
  address: string;
  latitude: string;
  longitude: string;
}

export interface BaseSiteViewModel {
  id: number;
  name: string;
  siteReference?: string;
  accompanierMode?: AccompanierMode;
  secretCode?: string;
}

export interface BaseSiteWithVisitorTypesViewModel extends BaseSiteViewModel {
  siteVisitorTypes: SiteVisitorTypeViewModel[];
}

export class SiteViewModel implements BaseSiteViewModel {
  public static MAX_ALLOWED_SITE_RADIUS_DEFAULT: number = 500;
  public static MAX_ALLOWED_AUTOMATIC_CHECKOUT_THRESHOLD_MINUTES_DEFAULT: number = 60;
  public seatingLocationFillMode: FillMode;
  public id: number;
  public companyId: number;
  public name: string;
  public description: string;
  public enableNotifyNearUser: boolean;
  //public enableGeofencing: boolean;
  public autoCheckOutType: AutoCheckOutType;
  public isPublicSite: boolean;
  public companyName: string;
  public siteAttendancesCount: number;
  public address: string;
  public secretCode: string;
  public latitude: string;
  public longitude: string;
  public checkinLocationMode: CheckinLocationMode;
  public qrCodeCheckinMode: QrCodeCheckinMode;
  public geofencingThresholdInMinutes: number;
  public siteRadius: number;
  public maxAllowedSiteRadius: number =
    SiteViewModel.MAX_ALLOWED_SITE_RADIUS_DEFAULT;
  public accompanierMode: AccompanierMode;
  public multipleCheckinType: MultipleCheckinType;
  public autoCheckInType: AutomaticCheckInMode;
  public autoCheckInDelayInMinute: number;
  public siteReference: string;
  public timezoneId?: number;
  public timezone: Timezone;
  public isForcedCheckOutTime: boolean;
  public forceCheckOutTime?: any;//confirm end time of day for example 22:00
  public forceCheckOutAnonymousAfterInMinute?: number;
  public forceCheckOutAfterMinute?: number;//Confirm minutes a person colud be checked in
  public isActive: boolean;
  public siteSupervisorEmailAddress: string;
  public maxPeopleOnSite: number;
  public maxPeopleOnSiteMode: MaxPeopleOnSiteMode;
  public checkInApprovalMode: CheckInApprovalMode;
  public logoUrl: string;
  //public forceCheckOutDateTime? :Date; this property didn't used
  public logoBase64: string;
  public logoExtension: string;
  public locationVerifiedUserId: number;
  public locationVerifiedDate: Date;
  public delayedAnswerModeForCheckIn: DelayedAnswerMode;
  public delayedAnswerModeForCheckOut: DelayedAnswerMode;
  public delayedAnswerForCheckInThresholdInHour: number;
  public delayedAnswerForCheckOutThresholdInHour: number;
  public isTemplate: boolean;
  public delayedAnswerNotificationFrequencyInMinute?: number;
  public delayedAnswerNotificationAfterCheckOutNotifyCount?: number;
  public enablePreCheckIn: boolean;
  public enableOffsiteInductions: boolean;
  public enableOffsiteQR: boolean;
  public enablePreCheckInForFrequentUsers: boolean;
  public enablePreCheckInByCode: boolean;
  public inductionMode: InductionMode;
  public contactUserId: number;
  public regionId: number;
  public contactInformationAsJson: string;

  // Only for compatibility fields
  public enableDelayedAnswerForCheckIn: boolean;
  public enableDelayedAnswerForCheckOut: boolean;
  public enableAnonymousCheckinWithQRCode: boolean;
  public locationIsRequired: boolean;
  public CheckInLocationOverrideInKioskMode: LocationOverrideMode;

  public bioStarApiServerUrl: string;
  public bioStarApiUsername: string;
  public bioStarApiPassword: string;
}

export class SiteDetailsViewModel extends SiteViewModel {
  public isExternalSite: boolean;
  public externalSiteSupplierId?: number;
  public externalSiteSupplierLinkHash?: number;
  public externalSupplierId: number;
  public externalSitePermissions: ExternalSitePermission;
}
export class AdminSiteViewModel extends SiteViewModel {
  public preCheckInCode: string;
  public preCheckInPassword: string;
  public regionName: string;
  public integrationConfigAsJson: string;
  public accessControlAutomaticCheckInMode: AccessControlAutomaticAttendanceMode;
  public accessControlAutomaticCheckOutMode: AccessControlAutomaticAttendanceMode;
  public accessControlInductionCheckingMode: AccessControlInductionMode;
  public accessControlSiteBriefingCheckingMode: AccessControlSiteBriefingMode;
  public accessControlSupplierDocumentCheckingMode: AccessControlSupplierDocumentMode;
  public siteAccessControlUnits: AccessControlUnitModel[];
  public checkInLocationOverrideInKioskMode: LocationOverrideMode;
}

export class ImportSiteResponse {
  valid: boolean;
  message: string;
  sites: ImportSiteModel[];
}

export class ImportSiteModel extends SiteViewModel {
  validationStatus: ImportRecordState;
  errorMessages: ValidationResultModel[];
  isUpdateRequest: boolean;
}

export class AnonymousCheckInSiteViewModel extends SiteViewModel {
  country: CountryViewModel;
  companyLogoUrl: string;
  questions: SiteQuestionsViewModel[];
  inductions: SiteAttendanceInductionViewModel[];
  requiredDocuments: UserDocumentType[];
  requiredFields: BaseSiteRequiredFieldViewModel[];
  visitorTypes: VisitorType[];
}
