<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                White List Users
            </div>
            <div class="card-body">

                <obc-loader [show]="inProgress"></obc-loader>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <label>User</label>
                        <div>
                            <obc-user-autocomplete (selectedUser)="onSelectUser($event)"></obc-user-autocomplete>
                        </div>
                    </div>
                    <div class="col-md-5 col-sm-12">
                        <label>Site</label>
                        <div>
                            <app-site-selector [required]="false" [notSelectedSiteLabel]="'All Sites'"
                                (siteIdSelect)="onSiteIdSelect($event)"></app-site-selector>
                        </div>
                    </div>
                    <div class="col-md-1 col-sm-12">
                        <label>&nbsp;</label>
                        <div>
                            <button type="button" class="btn btn-primary " [disabled]="newRecord?.userId == null"
                                (click)="addNewWhiteListUser()">
                                <i class="fa fa-plus"></i> Add
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped mt-3">
                                <thead>
                                    <tr>
                                        <td>
                                            User
                                        </td>
                                        <td>Site</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody *ngIf="whiteListUsers?.length > 0">
                                    <tr *ngFor="let userInfo of whiteListUsers">
                                        <td>
                                            {{ userInfo.userFullName }}
                                        </td>
                                        <td>
                                            <span *ngIf="userInfo.siteId == null"><strong>All Sites</strong></span>
                                            <span *ngIf="userInfo.siteId != null">{{userInfo.siteName }}</span>
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-danger"
                                                (click)="removeWhiteListUser(userInfo.id)">
                                                <i class="fa fa-times"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="whiteListUsers?.length == 0">
                                    <tr>
                                        <td colspan="3">
                                            <div class="text-center" role="alert">
                                                There are not any white list user.
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>