import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {BehaviorSubject, Observable, of} from "rxjs";
import {finalize} from "rxjs/operators";
import {RegionService} from "../../services/region.service";
import {RegionViewModel} from "../../models/region/region-model";
import {LocalStorageKey} from "../../enums/local-storage-key.enum";

@Component({
  selector: 'obc-region-selector',
  templateUrl: './region-selector.component.html',
  styleUrls: ['./region-selector.component.scss']
})
export class RegionSelectorComponent implements OnInit {

  maxItems: number = 10;

  // input & outputs {
  @Output() regionSelect = new EventEmitter<RegionViewModel>();
  @Output() regionIdSelect = new EventEmitter<number>();
  @Output() onRegionLoaded= new EventEmitter<RegionViewModel[]>();

  public selectedRegion = new BehaviorSubject<RegionViewModel>(null);

  _regionId: number = null;
  @Input() set regionId(value: number) {
    this._regionId = value;
    this.formControl.setValue(value);
  };


  regions: RegionViewModel[];
  regionGroups: any[];

  constructor(private regionService: RegionService) {
  }


  formControl = new FormControl(null);
  loading: boolean = false;
  regions$: Observable<RegionViewModel[]>;
  term$ = new BehaviorSubject<string>(null)

  ngOnChanges () {
  }

  ngOnInit() {
    this.formControl.valueChanges.subscribe(res => {

      if (this.regions) {
        if (res == null || res == 0) {
          this.regionSelect.emit(null);
          this.regionIdSelect.emit(null);
          this.selectedRegion.next(null);
        } else {
          let region = this.regions.find(x => x.regionId == res);
          if (region == null) {
          } else {
            this.regionSelect.emit(region);
            this.selectedRegion.next(region);
            this.regionIdSelect.emit(region.regionId);
          }
        }
      }
    });

    setTimeout(() => {
      this.getAccessableRegions();
    }, 250)
  }

  getAccessableRegions() {
    this.loading = true;
    this.regionService.getAccessableRegions()
      .pipe(finalize(() => this.loading = false))
      .subscribe(res => {
        this.setResponse(res.data);
      })

  }

  private setResponse(res: RegionViewModel[]) {

    this.regions = res;

    if (this.selectedRegion.value == null) {
      let region = this.regions.find(x => x.regionId == this.formControl.value);
      if (region) {
        this.selectedRegion.next(region);
      }
    }
    this.regions$ = of(this.regions);
    this.onRegionLoaded.emit(this.regions);
  }


  isMatched(term: string, item: RegionViewModel): boolean {
    if (!term) return true;
    term = term.toLowerCase();
    return (item.regionName && item.regionName.toLowerCase().indexOf(term) > -1)
  }

  reset() {
    this.formControl.reset();
    this.regions = [];
  }

  reachToEnd = false;
  reachToEndTerm = "";

  onScrollToEnd() {
    this.reachToEnd = true;
    this.reachToEndTerm = this.term$.value;
    this.term$.next(this.term$.value)
  }
}
