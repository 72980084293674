import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SiteService } from 'src/app/services/site.service';
import { finalize } from 'rxjs/operators';
import { SiteViewModel } from 'src/app/models/site-viewmodel';
import { ScannableService } from 'src/app/services/scannable.service';
import { FormControl } from '@angular/forms';
import { deepClone, getCountLabel, isEqual } from 'src/app/helpers/general-functions';
import { ManageSiteViewModel } from "../../../models/manage-site-view-model";
import { DirtyComponents } from "../../../models/dirty-components";

@Component({
  selector: 'obc-site-scannable-group',
  templateUrl: './site-scannable-group.component.html',
  styleUrls: ['./site-scannable-group.component.css']
})
export class SiteScannableGroupComponent implements OnInit {

  dirtyComponents: DirtyComponents;
  @Input('dirtyComponents') set setDirtyComponents(dirtyComponents: DirtyComponents) {
    this.dirtyComponents = dirtyComponents;
  }
  makeComponentDirty() {
    this.dirtyComponents.scannableGroups.isDirty = !isEqual(this.dirtyComponents?.scannableGroups?.snapshot, this.myCopy());
  }
  myCopy() {
    return {
      siteGroupIds: deepClone(this.manageSiteModel?.scannableGroups),
    };
  }

  manageSiteModel: ManageSiteViewModel;
  @Input() set manageSiteViewModel(manageSiteModel: ManageSiteViewModel) {
    this.manageSiteModel = manageSiteModel;
    this.siteGroupIds = this.manageSiteModel?.scannableGroups

    if(this.manageSiteModel && this.manageSiteModel.scannableGroups) {
      this.dirtyComponents.scannableGroups.snapshot = this.myCopy();
    }
  }

  @Input() noCard: boolean = false;
  inProgress: boolean = false;
  siteGroupProgress: boolean = false;
  groups: any[] = [];
  siteGroupIds: number[];
  siteList: SiteViewModel[] = [];
  get selectedSiteName(): string {
    if (!this.siteList || this.siteList.length == 0) return "";
    return this.siteList.find(i => i.id == this.siteId).name;
  }
  constructor(private route: ActivatedRoute,
    private bsModalService: BsModalService,
    private router: Router,
    private scannableService: ScannableService,
    private siteService: SiteService) { }

  siteSelectFC = new FormControl(null, []);
  get siteId(): number {
    return +this.siteSelectFC.value;
  }

  get headerInfo() {
    return getCountLabel(this.siteGroups?.length, 'Scannable');
  }

  ngOnInit() {
    this.routeChange();
  }

  routeChange() {
    this.route.params
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe((param: Params) => {
        this.siteSelectFC.setValue(+param['id']);
        this.getGroups();
        this.siteGroupIds = [];
      });
  }

  getSiteList() {
    this.siteService.getAllActiveSites()
      .pipe(finalize(() => {
        if (isNaN(this.siteId) === false) {
          const selectedSite = this.siteList.find(c => c.id === this.siteId);
          if (!selectedSite || selectedSite.isActive === false) {
            this.router.navigate([`sites`], { skipLocationChange: false });
          }
        }
      }))
      .subscribe(result => {
        this.siteList = result;
        this.getGroups();
      }, _ => { });
  }

  getGroups() {
    this.inProgress = true;
    this.scannableService.getGroups()
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        this.groups = res;
      });
  }

  bsModalRef: any;
  onShowSiteScannableGroupModal(template) {
    this.bsModalRef = this.bsModalService.show(template);
  }

  get siteGroups(): any[] {
    if (!this.groups || !this.siteGroupIds) return [];
    return this.groups.filter(i => this.siteGroupIds.indexOf(i.id) > -1);
  }

  removeSiteGroup(group: any) {

    const index = this.siteGroupIds.indexOf(group.id)
    this.siteGroupIds.splice(index, 1);

    this.makeComponentDirty();
  }

  get unAddedSiteGroups(): any[] {
    if (!this.siteGroupIds)
      this.siteGroupIds = [];
    if (!this.groups) return [];
    return this.groups.filter(i => this.siteGroupIds.indexOf(i.id) == -1);
  }

  addSiteGroup(id: number) {
    if(this.siteGroupIds.indexOf(id) === -1) {
      this.siteGroupIds.push(id);
      this.makeComponentDirty();
    }
  }

}
