import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormDataRendererComponent } from "../../form-manage/form-data-renderer/form-data-renderer.component";
import { ActivatedRoute } from "@angular/router";
import { SupplierFormService } from "../../../services/supplier-form.service";
import { ModalService } from "../../../services/modal.service";
import { SupplierFormViewModel, SupplierViewModel } from "../../../models/supplier-form/supplier-forms-view-mode";
import { FormDataStatus } from 'src/app/enums/form-data-status';
import { FormType } from 'src/app/enums/form-type';
import { SiteSupplierWithDocumentTypesViewModel } from "../../../models/supplier-document/site-supplier-models";
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'obc-supplier-fill-form',
  templateUrl: './supplier-fill-form.component.html',
  styleUrls: ['./supplier-fill-form.component.scss']
})
export class SupplierFillFormComponent implements OnInit {

  @Input() hashLink: string;
  @Input() supplier: SupplierViewModel;
  FormDataStatus = FormDataStatus;
  @Input() selectedForm: SupplierFormViewModel;
  FormType = FormType;
  inProgress: boolean;
  @ViewChild("formDataRenderer") public formDataRenderer: FormDataRendererComponent;
  @Output() onHide = new EventEmitter<boolean>();

  constructor(private route: ActivatedRoute,
    private supplierFormService: SupplierFormService,
    private formService: FormService,
    private modalService: ModalService,) { }

  ngOnInit(): void {
  }

  isFormValid: boolean;
  validateForm(formIsValid: boolean) {
    this.isFormValid = formIsValid;
  }

  onSubmitForm() {
    this.inProgress = true;
    var formAnswers = this.formDataRenderer.getFormResponse();
    let subs = null;
    if (this.hashLink?.length)
      subs = this.supplierFormService.saveForm(formAnswers, this.hashLink);
    else
      subs = this.formService.saveForm(formAnswers);

    subs.subscribe(res => {
      if (res.success) {
        this.modalService.hide();
        this.onHide.emit(true);
      } else
        this.modalService.error(res.message);
    }, err => {
      this.modalService.error(err, "Failed");
    }, () => {
      this.inProgress = false;
    })
  }

  onCancel() {
    this.modalService.hide();
  }
}
