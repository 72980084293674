<section class="card">
    <header class="card-header">
        <h5>Induction Details</h5>
        <div class="card-actions" *ngIf="showCloseButton">
            <a (click)="result.emit({ isFinished: true, isSucceeded: false , needParentRefresh: needParentRefresh })"
                class="card-action card-action-dismiss" data-card-dismiss=""></a>
        </div>
    </header>
    <div class="card-body">
        <obc-loader [show]="inProgress"></obc-loader>
        <div *ngIf="errorMessage" class="alert alert-danger mb-2">
            {{ errorMessage }}
        </div>
        <ng-container *ngTemplateOutlet="inductions"></ng-container>

        <ng-template #inductions>
            <div class="mb-3" *ngIf="siteInductionFilledInfo">
              <obc-induction-filled-info
                [onlyDisplayFilledInfo]="onlyDisplayFilledInfo"
                [siteInductionFilledInfo]="siteInductionFilledInfo" [expandInduction]="true"
                [showCheckInDetailsButton]="true" (onApproveOrReject)="onApproveOrReject($event)">
              </obc-induction-filled-info>
            </div>
        </ng-template>
    </div>
</section>


<ng-template #filledInductionTemplate>

</ng-template>
