import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CompanyInventoryConfigModel } from '../models/inventory/company-inventory-config-model';
import { InventoryLocationModel, InventoryLocationViewModel } from '../models/inventory/inventory-location-model';
import { ToolCategoryModel, ToolCategoryViewModel } from '../models/inventory/tool-category-model';
import { BaseToolDynamicFieldViewModel, ToolDynamicFieldModel, ToolDynamicFieldViewModel } from '../models/inventory/tool-dynamic-field-model';
import { ToolDynamicFieldTriggerModel, ToolDynamicFieldTriggerViewModel } from '../models/inventory/tool-dynamic-field-trigger-model';
import { ToolStatusModel, ToolStatusViewModel } from '../models/inventory/tool-status-model';
import { ToolStatusTriggerViewModel, ToolStatusTriggerModel } from '../models/inventory/tool-status-trigger-model';
import { ServerResultData } from '../models/server-result-data';
import { ErrorService } from './error.service';
import { UpdateInventoryLocationModel } from '../models/inventory/update-inventory-location-model';
import { GlobalToolDynamicFieldViewModel } from '../models/inventory/global-tool-dynamic-field-view-model';

@Injectable({
  providedIn: 'root'
})
export class ManageInventoryService {

  apiUrl: string = "/api/ManageInventory";
  constructor(private http: HttpClient, private _errorService: ErrorService) { }

  getToolCategories(): Observable<ToolCategoryViewModel[]> {
    return this.http.get<any>(`${this.apiUrl}/get-tool-categories`)
      .pipe(catchError(this._errorService.handleError));
  }

  addToolCategory(model: ToolCategoryModel): Observable<ToolCategoryViewModel> {
    return this.http.post<any>(`${this.apiUrl}/add-tool-category`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  removeToolCategory(id: number) {
    return this.http.get(`${this.apiUrl}/remove-tool-category/${id}`)
      .pipe(catchError(this._errorService.handleError));
  }
  addToolDynamicField(id: number, model: ToolDynamicFieldModel): Observable<ToolDynamicFieldViewModel> {
    return this.http.post<any>(`${this.apiUrl}/add-tool-dynamic-field/${id}`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  removeToolDynamicField(itemId: number) {
    return this.http.get(`${this.apiUrl}/remove-tool-dynamic-field/${itemId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  getToolStatuses(): Observable<ToolStatusViewModel[]> {
    return this.http.get<any>(`${this.apiUrl}/get-tool-statuses`)
      .pipe(catchError(this._errorService.handleError));
  }

  addToolStatus(model: ToolStatusModel): Observable<ToolStatusViewModel> {
    return this.http.post<any>(`${this.apiUrl}/add-tool-status`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  removeToolStatus(id: number) {
    return this.http.get(`${this.apiUrl}/remove-tool-status/${id}`)
      .pipe(catchError(this._errorService.handleError));
  }

  getInventoryLocations(): Observable<InventoryLocationViewModel[]> {
    return this.http.get<any>(`${this.apiUrl}/get-inventory-locations`)
      .pipe(catchError(this._errorService.handleError));
  }

  addInventoryLocation(model: InventoryLocationModel): Observable<InventoryLocationViewModel> {
    return this.http.post<any>(`${this.apiUrl}/add-inventory-location`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  removeInventoryLocation(id: number) {
    return this.http.get(`${this.apiUrl}/remove-inventory-location/${id}`)
      .pipe(catchError(this._errorService.handleError));
  }

  getCompanyInventoryConfig(): Observable<ServerResultData<CompanyInventoryConfigModel>> {
    return this.http.get<any>(`${this.apiUrl}/get-company-inventory-config`)
      .pipe(catchError(this._errorService.handleError));
  }
  saveCompanyInventoryConfig = (companyInventoryConfigModel: CompanyInventoryConfigModel): Observable<any> => {
    return this.http.post<any>(`${this.apiUrl}/save-company-inventory-config`, companyInventoryConfigModel)
      .pipe(catchError(this._errorService.handleError));
  }

  changeDynamicFieldOrder = (categoryId: number, isAssignField: boolean, dynamicFields: number[]) =>
    this.http.post<any>(`${this.apiUrl}/change-dynamic-field-order/${categoryId}/${isAssignField}`, dynamicFields);

  // status triggers
  getToolStatusTriggers(toolStatusId: number): Observable<ServerResultData<ToolStatusTriggerViewModel[]>> {
    return this.http.get<ServerResultData<ToolStatusTriggerViewModel[]>>(`${this.apiUrl}/toolstatus/${toolStatusId}/get-triggers`)
      .pipe(catchError(this._errorService.handleError));
  }

  removeToolStatusTrigger(toolStatusId: number, triggerId: number): Observable<ServerResultData<ToolStatusTriggerViewModel[]>> {
    return this.http.get<ServerResultData<ToolStatusTriggerViewModel[]>>(`${this.apiUrl}/toolstatus/${toolStatusId}/remove-trigger/${triggerId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  updateToolStatusTrigger(toolStatusId: number, triggerId: number, model: ToolStatusTriggerModel) {
    return this.http.post<ServerResultData<ToolStatusTriggerViewModel[]>>(`${this.apiUrl}/toolstatus/${toolStatusId}/update-trigger/${triggerId}`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  addToolStatusTrigger(toolStatusId: number, model: ToolStatusTriggerModel) {
    return this.http.post<ServerResultData<ToolStatusTriggerViewModel[]>>(`${this.apiUrl}/toolstatus/${toolStatusId}/add-trigger`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  changeStatusToolStatusTrigger(toolStatusId: number, triggerId: number) {
    return this.http.get<boolean>(`${this.apiUrl}/toolstatus/${toolStatusId}/change-trigger-status/${triggerId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  // dynamic field triggers
  getToolDynamicFieldTriggers(toolDynamicFieldId: number): Observable<ServerResultData<ToolDynamicFieldTriggerViewModel[]>> {
    return this.http.get<ServerResultData<ToolDynamicFieldTriggerViewModel[]>>(`${this.apiUrl}/toolDynamicField/${toolDynamicFieldId}/get-triggers`)
      .pipe(catchError(this._errorService.handleError));
  }

  removeToolDynamicFieldTrigger(toolDynamicFieldId: number, triggerId: number): Observable<ServerResultData<ToolDynamicFieldTriggerViewModel[]>> {
    return this.http.get<ServerResultData<ToolDynamicFieldTriggerViewModel[]>>(`${this.apiUrl}/toolDynamicField/${toolDynamicFieldId}/remove-trigger/${triggerId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  updateToolDynamicFieldTrigger(toolDynamicFieldId: number, triggerId: number, model: ToolDynamicFieldTriggerModel) {
    return this.http.post<ServerResultData<ToolDynamicFieldTriggerViewModel[]>>(`${this.apiUrl}/toolDynamicField/${toolDynamicFieldId}/update-trigger/${triggerId}`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  addToolDynamicFieldTrigger(toolDynamicFieldId: number, model: ToolDynamicFieldTriggerModel) {
    return this.http.post<ServerResultData<ToolDynamicFieldTriggerViewModel[]>>(`${this.apiUrl}/toolDynamicField/${toolDynamicFieldId}/add-trigger`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  changeStatusToolDynamicFieldTrigger(toolDynamicFieldId: number, triggerId: number) {
    return this.http.get<boolean>(`${this.apiUrl}/toolDynamicField/${toolDynamicFieldId}/change-trigger-status/${triggerId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  addCategoryOperationForm(categoryId: number, formId: number): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/category/${categoryId}/add-operation-form/${formId}`, null)
      .pipe(catchError(this._errorService.handleError));
  }

  removeCategoryOperationForm(categoryId: number, formId: number): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/category/${categoryId}/remove-operation-form/${formId}`, null)
      .pipe(catchError(this._errorService.handleError));
  }

  setAsDefaultInventoryLocation(id: number) {
    return this.http.get(`${this.apiUrl}/set-default-inventory-location/${id}`)
      .pipe(catchError(this._errorService.handleError));
  }

  updateInventoryLocation(id: number, model: UpdateInventoryLocationModel): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/update-inventory-location/${id}`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  toggleInventoryLocationStatus(id: number, status: boolean): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/toggle-inventory-location-status/${id}/${status}`, {})
      .pipe(catchError(this._errorService.handleError));
  }
  getGlobalToolDynamicFields() {
    return this.http.get<GlobalToolDynamicFieldViewModel[]>(`${this.apiUrl}/global-tool-dynamic-fields`)
      .pipe(catchError(this._errorService.handleError));
  }
  addGlobalToolDynamicField(model: BaseToolDynamicFieldViewModel): Observable<boolean> {
    return this.http.post<any>(`${this.apiUrl}/add-global-tool-dynamic-field`, model)
      .pipe(catchError(this._errorService.handleError));
  }
  removeGlobalToolDynamicField(itemId: number) {
    return this.http.post(`${this.apiUrl}/remove-global-tool-dynamic-field/${itemId}`, {})
      .pipe(catchError(this._errorService.handleError));
  }

}
