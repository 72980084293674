import { Pipe, PipeTransform } from '@angular/core';
import { SiteSupplierDocumentStatus } from "../enums/site-supplier-document-status";
import { SiteSupplierDocumentTypeViewModel } from "../models/supplier-document/site-supplier-document-type-view-model.model";

@Pipe({
  name: 'supplierDocumentTypeStatusTooltip'
})
export class SupplierDocumentTypeStatusTooltipPipe implements PipeTransform {

  transform(value: unknown, ...args: SiteSupplierDocumentTypeViewModel[]): unknown {
    if (value == SiteSupplierDocumentStatus.Unrequested) return 'Unrequested';
    if (value == SiteSupplierDocumentStatus.Requested) return 'Requested';
    if (value == SiteSupplierDocumentStatus.Pending) return 'Pending';
    if (value == SiteSupplierDocumentStatus.Deleted) return 'Deleted';
    if (value == SiteSupplierDocumentStatus.Approved) {
      return 'Approved';
    }
    if (value == SiteSupplierDocumentStatus.Declined) return 'Rejected';
    return 'Pending';
  }

}
