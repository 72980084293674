<header class="card-header">
  <h5>View Form</h5>
  <div class="card-actions">
    <a (click)="closeModal(null);" class="card-action card-action-dismiss" data-card-dismiss=""></a>
  </div>
</header>
<div class="card-body">
  <tabset>
    <tab heading="Details">
      <obc-form-details [formDataId]="selectedFormDataId" (onRefesh)="closeModal(null);"></obc-form-details>
    </tab>
    <tab heading="Signatures">
      <app-base-grid [columns]="signaturesGridColumns" [configs]="signaturesGridConfig" #gridSignature>

        <ng-template obcGridTemplateName="sigTemplate" let-item="data">
          <obc-file-viewer *ngIf="item.signatureUrl" [files]="[item]" [btnTitle]="'View Signature'"
            [btnClass]="'m-1 btn-sm btn btn-success'" [imgClass]="'signature-bg'"
            [prefix]="item.fullName + '-' + item.mobile">
          </obc-file-viewer>
        </ng-template>

        <ng-template obcGridTemplateName="signedBy" let-item="data">
          {{item.signedBy?.fullName}}
        </ng-template>
      </app-base-grid>
    </tab>
  </tabset>
</div>
