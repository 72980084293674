import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ManageSiteViewModel } from "../../models/manage-site-view-model";
import { CheckedVisitorType, VisitorType } from "../../models/visitor-type";

@Component({
  selector: 'obc-manage-site-visitor-type',
  templateUrl: './manage-site-visitor-type.component.html',
  styleUrls: ['./manage-site-visitor-type.component.scss']
})
export class ManageSiteVisitorTypeComponent implements OnInit {
  inprogress = false;

  manageSiteModel: ManageSiteViewModel;
  @Input() set manageSiteViewModel(manageSiteModel: ManageSiteViewModel) {
    this.manageSiteModel = manageSiteModel;
    this.init();
  }

  checkedIds: number[];
  @Output() onchange: EventEmitter<CheckedVisitorType> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.init();
  }

  init() {

    if(this.manageSiteModel) {
      let siteVisitorTypes = this.manageSiteModel?.siteVisitorTypes ?? [];
      this.checkedIds = this.manageSiteModel?.siteVisitorTypes?.map(v => v.visitorTypeId);
      this.limited = siteVisitorTypes.length > 0
    }

  }

  getCheckedType(visitorType: VisitorType) {
    return {
      ...visitorType,
      checked: this.manageSiteModel?.siteVisitorTypes?.findIndex(siteVisitorType => visitorType.id == siteVisitorType.visitorTypeId) > -1
    } as CheckedVisitorType
  }
  toggleCheckedType(item: CheckedVisitorType) {
    item.checked = !item.checked;
    this.updateCheckedIds(item);
    return item;
  }

  onItemClick(item: CheckedVisitorType) {
    this.onchange.emit(item);
    if(this.checkedIds.length == 0) {
      this._limited = false;
      this.isVisitorTypesEnabled.emit(false);
    }
  }

  onClearAll() {
    for(let visitorType of this.manageSiteModel?.visitorTypes) {
      let item = { ...visitorType, checked: false };
      this.updateCheckedIds(item);
      this.onItemClick(item)
    }
  }

  updateCheckedIds(item) {
    if(item.checked)
      this.checkedIds.push(item.id);
    else {
      let index = this.checkedIds.indexOf(item.id);
      if(index > -1)
        this.checkedIds.splice( index, 1)
    }
  }

  _limited: boolean;
  set limited(value: boolean) {
    this._limited = value;
    if (this._limited == false) {
      this.onClearAll();
    }
    this.isVisitorTypesEnabled.emit(this._limited);
  };
  @Output() isVisitorTypesEnabled: EventEmitter<boolean> = new EventEmitter();

  get limited(): boolean {
    return this._limited;
  }
}
