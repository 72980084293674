import { UserService } from 'src/app/services/user.service';
import { Component, OnInit } from '@angular/core';
import { UserPermission } from 'src/app/enums/user-permission.enum';

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss']
})
export class NoAccessComponent implements OnInit {
  UserPermission = UserPermission;
  constructor(public userService : UserService) { }

  ngOnInit() {
  }

}
