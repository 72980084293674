import { Pipe, PipeTransform } from '@angular/core';
import { EvacuationMode } from '../models/evacuation/evacuation-viewmodels';

@Pipe({
  name: 'evacuationMode'
})
export class EvacuationModePipe implements PipeTransform {

  transform(value: any): string {
    if (value == EvacuationMode.Drill) return 'Drill';
    if (value == EvacuationMode.Emergency) return 'Emergency';
    return value?.toString() ?? '?';
  }

}
