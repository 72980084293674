<div class="d-flex flex-column">
  <label *ngIf="label" for="basic-url">{{label}}</label>
  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text pointer"><i (click)="onCopy()" class="fas fa-clipboard"></i></span>
    </div>
    <input id="{{inputId}}" readonly [ngModel]="text" type="text" class="form-control">
  </div>
  <alert type="success" *ngIf="showCopiedMessage">
    <strong>Copied to clipboard</strong>
  </alert>
  <button *ngIf="!showCopiedMessage" (click)="onCopy()" class="fw btn btn-primary">Copy to clipboard</button>
</div>

