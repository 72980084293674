<div class="card">
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <tabset *ngIf="companyInventoryConfig">
      <tab heading="Asset Categories" [active]="selectedTabIndex == 1" (selectTab)="selectedTabIndex = 1">
        <obc-manage-tool-categories *ngIf="selectedTabIndex == 1"></obc-manage-tool-categories>
      </tab>
      <tab heading="Asset Locations" [active]="selectedTabIndex == 0" (selectTab)="selectedTabIndex = 0"
        *ngIf="!isTemplateCompany">
        <obc-manage-inventory-locations *ngIf=" selectedTabIndex==0">
        </obc-manage-inventory-locations>
      </tab>
      <tab heading="Asset Status" [active]="selectedTabIndex == 2" (selectTab)="selectedTabIndex = 2">
        <obc-manage-tool-statuses *ngIf="selectedTabIndex == 2"></obc-manage-tool-statuses>
      </tab>
      <tab heading="Permissions" [active]="selectedTabIndex == 3" (selectTab)="selectedTabIndex = 3">
        <div class="row mb-5 mt-5" *ngIf="!inProgress">
          <div class="col-md-4">
            <label class="form-check-label">
              <input type="checkbox" class="mr-1 ng-untouched ng-pristine ng-valid"
                [checked]="companyInventoryConfig?.endUserCanAddNewTool"
                [(ngModel)]="companyInventoryConfig.endUserCanAddNewTool">Allow
              end-user to add asset
            </label>
          </div>
          <div class="col-md-4">
            <label class="form-check-label">
              <input type="checkbox" class="mr-1 ng-untouched ng-pristine ng-valid"
                [checked]="companyInventoryConfig?.endUserCanAddNewLocation"
                [(ngModel)]="companyInventoryConfig.endUserCanAddNewLocation">Allow
              end-user to add new
              location
            </label>
          </div>
          <div class="col-md-4">
            <label class="form-check-label">
              <input type="checkbox" class="mr-1 ng-untouched ng-pristine ng-valid"
                [checked]="companyInventoryConfig?.endUserCanAssignToAnotherPerson"
                [(ngModel)]="companyInventoryConfig.endUserCanAssignToAnotherPerson">
              Allow end-user to assign asset to another person
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label class="form-check-label">
              <input type="checkbox" class="mr-1 ng-untouched ng-pristine ng-valid"
                [checked]="companyInventoryConfig?.isRequiredAssetImage"
                [(ngModel)]="companyInventoryConfig.isRequiredAssetImage">
              Asset Image Is Required
            </label>
          </div>
          <div class="col-md-4">
            <label class="form-check-label">
              <input type="checkbox" class="mr-1 ng-untouched ng-pristine ng-valid"
                [checked]="companyInventoryConfig?.isRequiredShortTermAssetImage"
                [(ngModel)]="companyInventoryConfig.isRequiredShortTermAssetImage">
              Short Term Asset Image Is Required
            </label>
          </div>
          <div class="col-md-2">
            <button class="btn btn-primary" style="min-width: 140px;" (click)="changeCompanyEnduserPermissions()">
              Save
            </button>
          </div>
        </div>
      </tab>

    </tabset>
  </div>
</div>