import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientAnnouncementFilterViewModel } from "../../../models/announcement-viewmodel";

@Component({
  selector: 'app-filtered-announcement',
  templateUrl: './filtered-announcement.component.html',
  styleUrls: ['./filtered-announcement.component.scss']
})
export class FilteredAnnouncementComponent implements OnInit {
  _announcement: ClientAnnouncementFilterViewModel;
  @Input() set model(value: ClientAnnouncementFilterViewModel) {
    this._announcement = value;
  }
  @Output() remove = new EventEmitter<ClientAnnouncementFilterViewModel>();
  @Output() stateChange = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  onRemove() {
    this.remove.emit(this._announcement);
  }

  onChangeState($event) {
    this.stateChange.emit($event);
  }
}
