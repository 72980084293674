import { Component, OnInit, Input } from '@angular/core';
import { PageSize, CardLayout, CardLayoutElement, getPageSizeName } from 'src/app/models/print-layout/print-layout-model';

@Component({
  selector: 'obc-draggable-element-page',
  templateUrl: './draggable-element-page.component.html',
  styleUrls: ['./draggable-element-page.component.scss']
})
export class DraggableElementPageComponent implements OnInit {
  _cardLayout: CardLayout;
  _pageSize = PageSize;
  @Input() freeze : boolean = false;
  @Input() set printLayout(p: CardLayout) {
    this._cardLayout = p;
  }

  constructor() { }

  ngOnInit(): void {
    this.getPageSizeName = getPageSizeName;
  }

  removeElement(element : CardLayoutElement) {
    var index = this._cardLayout.elements.map(c => c.id).indexOf(element.id)
    this._cardLayout.elements.splice(index, 1);
  }

  openSettingPage(element: CardLayoutElement) {
  }

  getPageSizeName : any;

}
