import { Component, OnInit, Input } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'obc-descriptor',
  templateUrl: './descriptor.component.html',
  styleUrls: ['./descriptor.component.scss']
})
export class DescriptorComponent implements OnInit {

  constructor(private storageService: StorageService) { }
  @Input() text: string = '';
  @Input() closePanelName: string = '';
  embedTypes = EmbedIn;
  @Input() embedIn: EmbedIn = EmbedIn.Card;
  show: boolean = true;
  ngOnInit(): void {
    var isClosed = this.storageService.get(this.closePanelName);
    this.show = isClosed == "1" ? false : true;
  }

  closePanel() {
    this.show = false;
    if (this.closePanelName != '')
      this.storageService.set(this.closePanelName, "1");
  }

}

export enum EmbedIn {
  "Card" = 0,
  "Field" = 1,
}
