import { Component, ElementRef, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DashboardSearchModel } from 'src/app/models/dashboard-search-model';
import { finalize, map } from 'rxjs/operators';
import { ChartModel } from 'src/app/models/chart-model';
import { ChartType } from 'src/app/enums/chart-type';
import { WeekdayPipe } from 'src/app/pipes/weekday.pipe';
import { ChartResponse } from 'src/app/models/chart-response';
import 'src/app/extensions/date.extensions';
//import { getDateRangeFromNowTo } from 'src/app/helpers/datetime-helper';
import { DashboardTotalsInfo } from 'src/app/models/dashboard-total-infos';
import { BsModalService } from 'ngx-bootstrap/modal';
import { WidgetModel, CompanyWidgetModel } from 'src/app/models/widget-model';
import { OrderByPipe } from 'src/app/pipes/order-by.pipe';
import { UserService } from 'src/app/services/user.service';
import { StorageService } from 'src/app/services/storage.service';
import { DashboardReportPeriod } from 'src/app/enums/dashboard-report-period.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { urlValidator } from 'src/app/helpers/url-validator';
import { DashboardWidgetType } from 'src/app/enums/dashboard-widget-type.enum';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { uuid4 } from 'src/app/helpers/uuid';

const key = "dashboard-period";
@Component({
  selector: 'obc-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  DashboardWidgetTypes = DashboardWidgetType;
  externalWidgetFormGroup = new FormGroup({
    title: new FormControl('', [Validators.required]),
    url: new FormControl('', [Validators.required, urlValidator]),
    type: new FormControl(this.DashboardWidgetTypes.ExternalLink),
    width: new FormControl("col-md-12 col-sm-12", [Validators.required]),
    height: new FormControl(null, [Validators.required]),
    unit: new FormControl('pixel', [Validators.required]),
  });
  lineChart: ChartModel = new ChartModel(ChartType.line);
  pieChart_ChekInBySite: ChartModel = new ChartModel(ChartType.pie);
  dashboardTotalInfos: DashboardTotalsInfo = new DashboardTotalsInfo();
  inProgress: boolean = false;
  get period(): number {
    var period = +this.storageService.get(key);
    if (period == null) {
      period = DashboardReportPeriod.LastWeek;
      this.storageService.set(key, period.toString());
    }
    return period;
  }

  set period(value: number) {
    this.storageService.set(key, value.toString());
  }

  constructor(private layoutService: LayoutService,
    private dashboardService: DashboardService,
    private bsModalService: BsModalService,
    private orderByPipe: OrderByPipe,
    public userService: UserService,
    private storageService: StorageService,
    private weekdayPipe: WeekdayPipe) { }
  ngOnInit(): void {
    this.layoutService.header = "Dashboard";
    //this.getChartsInfo();
    this.init();
  }

  getChartsInfo() {
    this.lineChart.inProgress = true;
    this.dashboardService.getCheckInByDateInDateRange()
      .pipe(finalize(() => { this.lineChart.inProgress = false; }))
      .subscribe(res => {
        let points = res;
        this.lineChart.init(points)
      },
        error => { console.log(error); });


    this.pieChart_ChekInBySite.inProgress = true;
    this.dashboardService.getChackInBySiteInDateRange()
      .pipe(finalize(() => { this.pieChart_ChekInBySite.inProgress = false; }))
      .subscribe(res => { this.pieChart_ChekInBySite.init(res) }, error => { console.log(error); });

    this.inProgress = true;
    this.dashboardService.getDashboardTotalInfos()
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(res => { this.dashboardTotalInfos = res; }, error => { console.log(error); });
  }

  widgets: any;
  widgetModels: WidgetModel[] = [];
  baseWidgetModels: WidgetModel[] = [];
  companyWidgets: CompanyWidgetModel[] = [];

  init() {
    this.inProgress = true;
    this.dashboardService.getDashboardWidgets(this.period)
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(res => {
        for (var i = 0; i < res.dashboardWidgets.length; i++) {
          res.dashboardWidgets[i].id = uuid4();
          this.setExternalWidgetHeight(res.dashboardWidgets[i]);
        }
        this.widgets = res.dashboardWidgets;
        this.widgetModels = res.widgetModels;
        this.baseWidgetModels = res.baseWidgetModels;

        var i = 0;
        this.companyWidgets = (res.dashboardWidgets as any[]).map((w) => {
          if (w.widgetModel.type == this.DashboardWidgetTypes.ExternalLink) {
            return {
              type: w.widgetModel.type,
              order: i++,
              title: w.title,
              url: w.url,
              width: w.width,
              height: w.height,
              unit: w.unit,
            } as CompanyWidgetModel;
          }
          else
            return { type: w.widgetModel.type, order: i++, title: w.widgetModel.title } as CompanyWidgetModel;
        })
      }, error => { console.log(error); }
      );
  }

  getChart(widget: any): ChartModel {
    if (!widget.chart)
      widget.chart = ChartModel.getChartModel(widget);
    return widget.chart;
  }
  bsModalRef: any;


  copyBaseWidgetModels: WidgetModel[] = [];
  copyCompanyWidgets: CompanyWidgetModel[] = [];

  manageWidgets(template) {
    this.bsModalRef = this.bsModalService.show(template, {
      ignoreBackdropClick: true
    });
    this.copyCompanyWidgets = [...this.companyWidgets];
    this.copyBaseWidgetModels = [...this.baseWidgetModels];
  }

  closeModal() {
    this.companyWidgets = [...this.copyCompanyWidgets];
    this.copyBaseWidgetModels = [...this.copyBaseWidgetModels];
    this.bsModalRef.hide();
  }

  get orderedCompanyWidgets(): any[] {
    return this.orderByPipe.transform(this.companyWidgets, "order");
  }

  get unusedWidgets(): any[] {
    return this.baseWidgetModels.filter(w => {
      if (w.type == this.DashboardWidgetTypes.ExternalLink) return false;
      var repeated = this.companyWidgets.find(i => i.type == w.type);
      return !repeated;
    })
  }

  changeOrder(companyWidgetModel: any, moveUp: boolean) {
    companyWidgetModel.order += (1.5 * (moveUp ? -1 : +1));
    this.reorderWidgets();
  }

  removeWidget(companyWidgetModel: any) {
    var index = this.companyWidgets.indexOf(companyWidgetModel);
    this.companyWidgets.splice(index, 1);
    this.reorderWidgets();
  }

  addWidget(widgetModel: any) {
    var companyWidgetModel = {
      type: widgetModel.type,
      order: this.companyWidgets ? this.companyWidgets.length : 0,
      title: widgetModel.title,
    };
    this.companyWidgets.push(companyWidgetModel);
    this.reorderWidgets();
  }

  addExternalWidget() {
    var companyWidgetModel = {
      type: this.externalWidgetFormGroup.controls.type.value,
      order: this.companyWidgets ? this.companyWidgets.length : 0,
      title: this.externalWidgetFormGroup.controls.title.value,
      url: this.externalWidgetFormGroup.controls.url.value,
      width: this.externalWidgetFormGroup.controls.width.value,
      height: this.externalWidgetFormGroup.controls.height.value,
      unit: this.externalWidgetFormGroup.controls.unit.value,
    };
    this.companyWidgets.push(companyWidgetModel);
    this.externalWidgetFormGroup.reset();
    this.reorderWidgets();
  }

  private reorderWidgets() {
    for (let index = 0; index < this.orderedCompanyWidgets.length; index++) {
      const element = this.companyWidgets[index];
      element.order = index;
    }
  }

  onSaveChanges() {
    var model = {
      widgetModels: this.orderedCompanyWidgets
    };
    this.dashboardService.updateCompanyWidgetConfig(model).subscribe(res => {
      this.bsModalRef.hide();
      this.init();
    });
  }


  setExternalWidgetHeight(widget) {
    setTimeout(() => {
      var heigth = +widget.height;
      if (!widget.id) return;
      var element = document.getElementById(widget.id);
      if (!element) return;
      var width = element.offsetWidth;
      var unit = widget.unit as string;
      var h = 0;
      switch (unit) {
        case "pixel": {
          h = heigth;
          break;
        }
        case "precent": {
          h = width * heigth / 100
          break;
        }
        default: {
          h = width * 0.5
          break;
        }
      }
      element.style.height = `${h}px`
    }, 1000)
  }
}
