import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiteBriefingService {
  private apiUrl = '/api/SiteBriefing'
  constructor(private http: HttpClient, private _errorService: ErrorService) { }

  getSignedList(siteBriefingQueryModel: any) {
    return this.http.post<any>(`${this.apiUrl}/get-signed-list`, siteBriefingQueryModel)
      .pipe(catchError(this._errorService.handleError));
  }

  deactive(SiteBriefingDeactivateRequestModel: any) {
    return this.http.post<any>(`${this.apiUrl}/deactive`, SiteBriefingDeactivateRequestModel)
      .pipe(catchError(this._errorService.handleError));
  }
}
