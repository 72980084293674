<div class="row">
  <div class="col-md-12">
    <form *ngIf="profileFormGroup" [formGroup]="profileFormGroup" class="row">
      <div class="col-md-6 col-sm-12" style="vertical-align: bottom;">
        <label>Select Company Logo</label>
        <input type="hidden" formControlName="profileImageBase64" />
        <input type="file" accept=".png, .jpg, .jpeg" class="form-control-file" (change)="onPickImage($event)" />
        <label *ngIf="!profileFormGroup.get('profileImageBase64').valid">Please Select Logo Image</label>
      </div>
      <div class="form-group col-md-6 col-sm-12" *ngIf="imageSource || imageSourceBase64">
        <image-cropper *ngIf="imageSourceBase64" style="height: 200px; width: 200px;" [imageBase64]="imageSourceBase64"
          [maintainAspectRatio]="false" format="png" (imageCropped)="imageCropped($event)"
          (loadImageFailed)="loadImageFailed()" [containWithinAspectRatio]="true">
        </image-cropper>
        <img *ngIf="!imageSourceBase64" [src]="imageSource" style="max-width: 100%; max-height: 200px;" />
      </div>

      <div class="form-group col-md-6 mt-3 col-sm-12">
        <label>Title</label>
        <input type="text" class="form-control" formControlName="title" />
      </div>
      <div class="form-group col-md-6 mt-3 col-sm-12">
        <label>Description</label>
        <textarea type="text" class="form-control" formControlName="description"></textarea>
      </div>
      <div class="form-group col-md-6 mt-3 col-sm-12" *ngIf="timezones">
        <label>TimeZone</label>
        <select formControlName="timezone" class="form-control">
          <option *ngFor="
              let tz of timezones | filterByProperty: false:'isOtherTimezone'
            " [ngValue]="tz.id">{{ tz.name }} ({{ tz.offset }})
          </option>
          <optgroup label="OTHER TIMEZONES">
            <option *ngFor="
                let tz of timezones | filterByProperty: true:'isOtherTimezone'
              " [ngValue]="tz.id">{{ tz.name }} ({{ tz.offset }})
            </option>
          </optgroup>
        </select>
      </div>
      <div class="form-group col-md-6 mt-3 col-sm-12">
        <label>Company Supervisor(s) Email Address</label>
        <input type="text" placeholder="supervisor1@company.com,supervisor2@company.com,..." class="form-control"
          formControlName="companySupervisorEmailAddress" />
      </div>
      <div class="form-group col-md-6 mt-3 col-sm-12">
        <app-user-card label="Contact" *ngIf="profileFormGroup.controls.shareDataWithVicVisitation.value"
          (remove)="profileFormGroup.controls.contactUserId.setValue(null)"
          (valueChange)="profileFormGroup.controls.contactUserId.setValue($event)"
          [userId]="profileFormGroup.controls.contactUserId.value"></app-user-card>
      </div>
      <div class="col-md-12 mt-3">
        <p class="button-group">
          <button type="submit" class="btn btn-primary" [disabled]="!profileFormGroup.valid"
            (click)="onSaveProfileChanges()">
            Save Changes
          </button>
        </p>
      </div>
    </form>
  </div>
  <div class="col-md-12">
    <p *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</p>
  </div>
</div>