<div class="pt-2 pb-3 question-file-container" *ngIf="formGroup">
  <form [formGroup]="formGroup">
    <div class="d-flex flex-column" *ngIf="model">
      <div>
        <i *ngIf="valid" style="color: green;" class="fas fa-check-circle  mr-2"></i>
        <i *ngIf="!valid" style="color: red;" class="fas fa-times-circle  mr-2"></i>
        <obc-question-title-renderer [question]="model.question"></obc-question-title-renderer>
      </div>
      <div class="m-3" *ngIf="model.initialAnswers != null">

        <ng-container *ngFor="let fileUrl of model.initialAnswers[0].fileUrls; let index = index;">
          <obc-dropzone-file
            [source]="fileUrl"
            [sourceType]="DropzoneSourceType.URL"
            (removeItem)="removeItem(model.initialAnswers[0].fileStorageKeyList[index])"
          ></obc-dropzone-file>
        </ng-container>

        <ng-container *ngFor="let uploadFileInfo of parsedInitialBase64AnswersJson; let index = index;">
          <obc-dropzone-file
            [source]="uploadFileInfo"
            [sourceType]="DropzoneSourceType.Base64"
            (removeItem)="removeItem(uploadFileInfo.fileAsBase64)"
          ></obc-dropzone-file>
        </ng-container>
        <div *ngIf="parsedInitialBase64AnswersJson?.length > 0" class="mt-3 font-weight-bold d-flex justify-content-end">
          Selected Files Total Size: {{ getTotalBase64FileSize(parsedInitialBase64AnswersJson) }}
        </div>

      </div>
      <div class="m-3" *ngIf="files?.length">
        <div *ngIf="isEditMode">
          New Selected Files:
        </div>

        <ng-container *ngFor="let file of files; let index = index">
          <obc-dropzone-file
            [source]="file"
            [sourceType]="DropzoneSourceType.File"
            (removeItem)="remove(index)"
          ></obc-dropzone-file>
        </ng-container>
        <div *ngIf="files?.length > 1" class="mt-2 font-weight-bold d-flex justify-content-end">
          Selected Files ({{ this.files?.length }}), Total Size: {{ getTotalSize(files) }}
        </div>
      </div>

      <div class="form-group d-flex justify-content-end">
        <div class="d-flex flex-row  justify-content-end">
          <input #inputFile
          [accept]="getAccept()"
          style="max-width: 350px;" (change)="onSelectFile(inputFile, $event);" multiple type="file"
            class="form-control-file">
        </div>
      </div>
      <div class="alert alert-danger mt-2 p-2" *ngIf="displayMimeTypeError">
        The type of the selected file is invalid
      </div>
    </div>
  </form>
</div>
