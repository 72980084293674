import { UserService } from 'src/app/services/user.service';
import { finalize } from 'rxjs/operators';
import { SiteService } from 'src/app/services/site.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { LocalStorageKey } from 'src/app/enums/local-storage-key.enum';
import { SiteListQueryModel } from 'src/app/models/site-list-query-model';
import { SiteDetailsViewModel } from 'src/app/models/site-viewmodel';
import { SiteActiveStatus } from 'src/app/enums/site-active-status.enum';
import { SiteTemplateStatus } from 'src/app/enums/site-template-status.enum';

@Component({
  selector: 'obc-site-selector-withexternal',
  templateUrl: './site-selector-withexternal.component.html',
  styleUrls: ['./site-selector-withexternal.component.scss']
})
export class SiteSelectorWithExternalComponent implements OnInit {
  //variables
  sites: SiteDetailsViewModel[];
  loading: boolean = false;
  sites$: Observable<SiteDetailsViewModel[]>;
  term$ = new BehaviorSubject<string>(null);
  formControl = new FormControl(null, [Validators.required]);
  _required = false;
  _siteId: number = null;
  _siteSupplierId: number = null;
  public selectedSite = new BehaviorSubject<SiteDetailsViewModel>(null);
  onlyActiveSites: boolean = true;
  onlyRealSites: boolean = true;

  //input-outputs
  @Input() maxItems = 10;
  @Input() excludeList: number[] = [];
  @Input() notSelectedSiteLabel: string = null;
  @Input() set required(value: boolean) {
    if (value) {
      this._required = true;
      this.formControl.setValidators(Validators.required);
    }
  };
  @Output() siteSelect = new EventEmitter<SiteDetailsViewModel>();
  @Output() siteIdSelect = new EventEmitter<number>();

  @Input() set siteId(value: number) {
    if (!value) return;
    this._siteId = value;
    this.formControl.setValue(value);
  };

  @Input() set siteSupplierId(value: number) {
    if (!value) return;
    this._siteSupplierId = value;
    this.formControl.setValue(value);
  };

  constructor(private siteService: SiteService, private userService: UserService) { }

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe(res => {
        if (this.sites) {
          if (res == null || res == 0) {
            this.siteSelect.emit(null);
            this.siteIdSelect.emit(null);
            this.selectedSite.next(null);
          } else {
            var site = this.sites.find(x => x.id == res);
            if (site == null) {
            } else {
              this.siteSelect.emit(site);
              this.selectedSite.next(site);
              this.siteIdSelect.emit(site.id);
              this.saveDefaultSite()
            }
          }
        }
      });

    setTimeout(() => {
      this.getSiteList();
    }, 250)
  }

  createQuery(): SiteListQueryModel {
    var query = {
      activeStatus: this.onlyActiveSites ? SiteActiveStatus.Active : SiteActiveStatus.InActive,
      templateStatus: this.onlyRealSites ? SiteTemplateStatus.Normal : SiteTemplateStatus.Templates,
      pagingInfo: {
        pageNumber: null,
        pageSize: null,
      },
      searchTerm: "",
    };
    return query;
  }

  getSiteList() {
    this.loading = true;

    var query = this.createQuery();
    this.siteService.getAllSites(query)
      .pipe(finalize(() => { this.loading = false; }))
      .subscribe(res => {
        this.setReponse(res.data);
      });

  }

  private setReponse(res: SiteDetailsViewModel[]) {
    let that = this;
    res = res.filter(site => this.userService.hasAccessToManageSite(site));
    this.sites = res.filter(function (el) {
      return that.excludeList.filter(function (selected_el) {
        return selected_el == el.id;
      }).length == 0
    });

    if (this._siteSupplierId != null) {
      var site = this.sites?.filter(x => x.externalSiteSupplierId == this._siteSupplierId)[0];
      if (site != null) {
        this._siteId = site.id;
        this.formControl.setValue(site.id);
        this.selectedSite.next(site);
      }
    }

    if (this._siteId == null) // if site set do not change that
      this.loadDefaultSite();

    if (this.selectedSite.value == null) {
      var site = this.sites.find(x => x.id == this.formControl.value);
      if (site) {
        this.selectedSite.next(site);
      }
    }
    this.sites$ = of(this.getNthItems(this.sites, this.maxItems));
    this.term$.subscribe(term => {
      if (term) {
        var filteredSites = this.searchSites(term);
        if (term != this.reachToEndTerm)
          this.reachToEnd = false;
        this.sites$ = of(this.getNthItems(filteredSites, this.reachToEnd ? filteredSites.length : this.maxItems));
      } else {
        this.sites$ = of(this.getNthItems(this.sites, this.reachToEnd ? this.sites.length : this.maxItems))
      }
    });
  }

  searchSites(term: string) {
    return this.sites.filter((item) => {
      return this.isMatched(term, item);
    });
  }

  isMatched(term: string, item: SiteDetailsViewModel): boolean {
    if (!term) return true;
    term = term.toLowerCase();
    return (item.name && item.name.toLowerCase().indexOf(term) > -1) ||
      (item.siteReference && item.siteReference.toLowerCase().indexOf(term) > -1);
  }

  getNthItems(array: SiteDetailsViewModel[], n: number): SiteDetailsViewModel[] {
    if (!array || array.length == 0 || n <= 0) return [];
    var max = Math.min(array.length, n);
    var arr = [];
    for (var i = 0; i < max; i++)
      arr.push(array[i]);
    if (this.selectedSite.value && !arr.find(x => x.id == this.selectedSite.value.id))
      arr.push(this.selectedSite.value);
    return arr;
  }

  loadDefaultSite() {
    if (!this._required) return;

    var selectedSiteId = localStorage
      ? parseInt(localStorage.getItem(LocalStorageKey.LastVisitedSiteId), 10)
      : null;

    if (this.sites?.length) {
      if (this.sites.find(x => x.id == selectedSiteId))
        this.formControl.setValue(selectedSiteId);
      else {
        let id = this.sites[0].id;
        this.formControl.setValue(id);
      }
    }
  }

  saveDefaultSite() {
    if (localStorage && this.selectedSite?.value) {
      localStorage.setItem(
        LocalStorageKey.LastVisitedSiteId,
        this.selectedSite.value.id.toString()
      );
    }
  }

  reachToEnd = false;
  reachToEndTerm = "";
  onScrollToEnd() {
    this.reachToEnd = true;
    this.reachToEndTerm = this.term$.value;
    this.term$.next(this.term$.value)
  }
}
