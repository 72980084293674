<div class="row">
  <div class="col-md-12 mb-3">
    <section class="card">
      <div class="card-header">
        <h5 style="line-height: 32px;">
          View Supplier Forms
        </h5>
      </div>
      <div class="card-body">
        <ng-container *ngIf="suppliers?.length == 1">
          <div class="row">
            <div class="col-md-6 com-sm-12">
              <h4 class="text-center">Supplier: {{suppliers[0].supplierName}}</h4>
            </div>
          </div>
        </ng-container>
        <div class="row" *ngIf="suppliers">
          <div class="col-md-12">
            <div class="table-responsive">

              <table class="table table-bordered table-striped mb-0">
                <thead>
                  <tr>
                    <td>Company</td>
                    <td>Form</td>
                    <td>Last Approved Form</td>
                    <td>Pending Approval Form</td>
                    <td></td>
                  </tr>

                </thead>
                <tbody>
                  <ng-container *ngFor="let supplier of suppliers">

                    <ng-container *ngIf="supplier && supplier.forms.length > 0; ">
                      <ng-container *ngFor="let form of supplier.forms">
                        <tr>
                          <td>
                            <div>
                              {{ supplier.supplierName }}
                            </div>
                          </td>
                          <td>
                            <div>
                              {{ form.formName }}
                            </div>
                          </td>
                          <td>
                            <div *ngIf="form.lastApprovedFormData">
                              <ul>
                                <li>
                                  Version:
                                  <strong>
                                    {{ form.lastApprovedFormData?.displayVersion }}
                                  </strong>
                                </li>
                                <li>
                                  Submit Date:
                                  <strong>
                                    {{ form.lastApprovedFormData?.fillDateTime | datetime }}
                                  </strong>
                                </li>
                                <li *ngIf="form.lastApprovedFormData?.endDate">
                                  Expiry:
                                  <strong>
                                    {{ form.lastApprovedFormData?.endDate | datetime }}
                                  </strong>
                                </li>
                                <li class="text-success">
                                  {{ form.lastApprovedFormData?.status | formDataStatus }}
                                  by:
                                  <strong>{{ form.lastApprovedFormData?.approveRejectByUserFullName }}
                                    ({{ form.lastApprovedFormData?.approveRejectDateTime | datetime }})
                                  </strong>
                                </li>
                              </ul>
                              <button tooltip="View" class="mr-1 btn btn-info"
                                (click)="onViewForm(formDetails,form.lastApprovedFormData)">
                                <span class="fa fa-eye"></span>
                                View
                              </button>
                            </div>
                          </td>
                          <td>
                            <div *ngIf="form.pendingFormData">
                              <ul>
                                <li>
                                  Version:
                                  <strong style="color: #aeb4da">
                                    {{ form.pendingFormData?.displayVersion }}
                                  </strong>
                                </li>
                                <li>
                                  Submit Date:
                                  <strong>
                                    {{ form.pendingFormData?.fillDateTime | datetime }}
                                  </strong>
                                </li>
                                <li *ngIf="form.pendingFormData?.endDate">
                                  Expiry:
                                  <strong>
                                    {{ form.pendingFormData?.endDate | datetime }}
                                  </strong>
                                </li>
                                <li class="text-warning">
                                  {{ form.pendingFormData?.status | formDataStatus }}

                                </li>
                              </ul>
                              <button tooltip="View" class="mr-1 btn btn-info"
                                (click)="onViewForm(formDetails,form.pendingFormData)">
                                <span class="fa fa-eye"></span>
                                View
                              </button>
                            </div>
                          </td>

                          <td>
                            <button tooltip="History" class="mr-1 btn btn-info"
                              (click)="displayHistory(form, historyView, supplier)">
                              <span class="fa fa-history"></span>
                              History
                            </button>

                            <button class="btn btn-success ml-2" (click)="onFillForm(form, fillForm, supplier)">
                              <span class="fa fa-pencil-alt mr-1" style="font-size: 15px"></span>
                              Edit Form
                            </button>
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                </tbody>
              </table>

            </div>
          </div>
          <div class="col-md-12 mt-2" style="text-align: end">
          </div>
        </div>


      </div>
    </section>
  </div>
</div>


<ng-template #fillForm>
  <obc-supplier-fill-form [supplier]="supplier" [hashLink]="hashLink" [selectedForm]="selectedForm"
    (onHide)="getForms()">

  </obc-supplier-fill-form>
</ng-template>

<ng-template #formDetails>
  <obc-form-details [hashLink]="hashLink" [formDataId]="selectedFormDataId"></obc-form-details>
</ng-template>

<ng-template #historyView>
  <div class="card">
    <obc-supplier-form-history [hashLink]="hashLink" [formId]="selectedFormIdToViewHistory"
      [supplierId]="supplier.supplierId">
    </obc-supplier-form-history>
  </div>
</ng-template>