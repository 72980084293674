<obc-loader [show]="inProgress"></obc-loader>
<div class="card">
    <div class="card-header">
        My Scheduled Reports
    </div>
    <div class="card-body">
        <div *ngIf="(!reports || !reports.length) && !inProgress">
            <div class="alert alert-info">There are no scheduled reports!</div>
        </div>
        <div *ngFor="let report of reports">
            <div class="card mb-2">
                <div class="card-header">
                    {{report.scheduleReportMode | scheduleReportMode}} - {{report.scheduleReportType | scheduleReportType}}
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <i class="fas fa-envelope mr-1"></i>
                            <strong>{{report.type | reportDeliveryType}}</strong>
                            <div>Subject: <strong>{{report.parameters.email.subject}}</strong></div>
                            <div>Email Address:</div><strong>
                                <ul>
                                    <li *ngIf="report.parameters?.email?.sendEmailToCompanySupervisor">
                                        <i class="fa fa-person mr-1"></i>
                                        <i>Company Supervisor Email</i>
                                    </li>
                                    <li *ngIf="report.parameters?.email?.sendEmailToSiteSupervisor">
                                        <i>Site Supervisor Email</i>
                                    </li>
                                    <li *ngFor="let email of report.parameters?.email?.recipients">{{email}}</li>
                                </ul>
                            </strong>
                        </div>
                        <div class="col-md-4">
                            <div>
                                <i class="fas fa-hourglass mr-1"></i>
                                <strong>{{report.parameters.period | reportPeriodType}}</strong>
                            </div>
                            <div class="" [ngSwitch]="report.parameters.period">
                                <div>Time: <strong>{{report.parameters?.timeSpan}}</strong></div>
                                <div class="" *ngSwitchCase="ReportPeriodType.Daily">
                                </div>
                                <div class="" *ngSwitchCase="ReportPeriodType.Weekly">
                                    <div>Days:</div>
                                    <strong>
                                        <ul>
                                            <li *ngFor="let day of report.parameters.daysOfWeek">
                                                {{day | numberToWeekday}}
                                            </li>
                                        </ul>
                                    </strong>
                                </div>
                                <div class="" *ngSwitchCase="ReportPeriodType.Monthly">
                                    <div>Days:</div>
                                    <strong>
                                        <ul>
                                            <li *ngFor="let day of report.parameters.daysOfMonth">
                                                {{day}}
                                            </li>
                                        </ul>
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div>
                                <i class="fas fa-play mr-1"></i>
                                Next Run: <strong>{{report.nextExecutionDateTime | datetime}}</strong>
                            </div>
                            <div *ngIf="report.scheduleReportMode == ScheduleReportMode.ScheduleReport && report.scheduleReportType != ScheduleReportType.InventoryReport">Site:
                                <strong>{{report.parameters.siteId ? report.parameters.siteName : 'All Sites'}}</strong>
                            </div>
                            <div *ngIf="report.parameters.mobile">User: <strong>{{report.parameters.mobile}}</strong>
                            </div>
                            <div>
                                <button type="button" class="btn btn-danger"
                                    (click)="removeReport(report)">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>