<div class="card">
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="row">
      <div class="col-md-12">
        <ng-container *ngIf="filterFormTypes?.length != 0">
          <app-filter-list-renderer [filters]="filters" (remove)="onRemoveFilter(filterFormTypes,$event.item)">
          </app-filter-list-renderer>
          <hr />
        </ng-container>
      </div>
    </div>
    <div class="card-header mb-2">
      <span class="mr-5">
        <span class="d-inline-block mt-2 mr-1">Display Mode: </span>
        <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [value]="onlyActiveForms"
          (valueChanged)="onActiveChanged($event,true)">
        </obc-switch>
      </span>
    </div>
    <div class="row">
      <div class="col-12 mb-2">
        <p style="text-align: center;" *ngIf="forms == null">
          Loading ...
        </p>
        <div class="table-responsive" style="min-height:300px;">
          <table class="table table-bordered mb-0">
            <thead>
              <tr>
                <th>Name</th>
                <th>
                  <div class="d-flex flex-row justify-content-between">
                    Type
                    <obc-filter-header [items]="filterFormTypes" [filterType]="FilterType.FormType"
                      popoverTitle="Filter By Type" (update)="onUpdateFilter(filterFormTypes, $event)">
                    </obc-filter-header>
                  </div>
                </th>
                <th>Visitor Types</th>
                <th>Group</th>
                <th>Button Title</th>
                <!-- <th>Ask Mode</th> -->
                <!-- <th>Scope</th> -->
                <th class="text-right">
                  <button class="btn btn-info" (click)="onModalUpsertForm(upsert)">New Form</button>
                </th>
              </tr>
            </thead>
            <tbody *ngIf="forms == null || forms.length == 0">
              <tr>
                <td colspan="100">
                  <strong>There is no form!</strong>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="forms && forms.length">
              <tr>
                <td colspan="6">
                  <input type="text" class="form-control" placeholder="Search by name and button title"
                    [(ngModel)]="formsFilter">
                </td>
              </tr>
              <ng-container *ngFor="let form of filteredForms">
                <tr>
                  <!-- <tr [ngClass]="{'bold-buttom-border' : !(form.questionIds?.length)}"> -->
                  <td>
                    <span>{{form.name}}</span>
                    <span *ngIf="!form.isEnable" class="text-danger"> (Disable)</span>
                  </td>
                  <td>
                    <span>{{form.type | formType}}
                      <span class="text-info" *ngIf="form.type == formType.Generic || form.type == formType.SiteBriefing">({{form.scope | formScope:form.type}})</span>
                      <span class="text-warning ml-1" *ngIf="form.type == formType.Generic">({{form.askMode |
                        formAskMode}})</span>
                      <span class="text-info" *ngIf="form.type == formType.Permit">({{form.applyFor |
                        formApplyFor}})</span>
                    </span>
                  </td>
                  <td>
                    <span>
                      <ng-container *ngIf="form.visitorTypeIds?.length">
                        <ul>
                          <li *ngFor="let id of form.visitorTypeIds">
                            {{getVisitorType(id)?.title}}
                          </li>
                        </ul>
                      </ng-container>
                      <ng-container *ngIf="!form.visitorTypeIds?.length">
                        All Visitors
                      </ng-container>
                    </span>
                  </td>
                  <td>
                    <span>{{form.groupName}}</span>
                  </td>
                  <td>
                    <span>{{form.buttonTitle}}</span>
                  </td>
                  <!-- <td>
                                      <span>{{form.scope | formScope}}</span>
                                    </td> -->
                  <!-- <td>
                                      <span>{{form.askMode | formAskMode}}</span>
                                    </td> -->
                  <td>
                    <div class="d-flex flex-row-reverse mb-0" style="text-align: end;">
                      <button class="btn btn-sm btn-danger  mr-1" (click)="onRemoveForm(form.id)">
                        <i class="fa fa-times"></i>
                      </button>
                      <button class="btn btn-sm btn-dark mr-1" title="Clone" (click)="onCloneForm(clone,form.id)">
                        <i class="fa fa-clone"></i>
                      </button>
                      <button class="btn btn-sm btn-primary  mr-1" (click)="onModalUpsertForm(upsert, form)">
                        <i class="fa fa-edit"></i>
                      </button>
                      <button class="btn btn-sm btn-info  mr-1" (click)="form.visibleDetail = !form.visibleDetail">
                        <i class="fa fa-info"></i>
                      </button>
                      <button class="btn btn-sm btn-success  mr-1" (click)="onModalAutomation(automation)">
                        <i class="fa fa-bars"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="form.visibleDetail">
                  <td colspan="100">
                    <obc-items-mixture *ngIf="form.items" [formId]="form.id" [displayOnly]="true"
                      [orderableItems]="form.items"></obc-items-mixture>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #upsert>
  <obc-upsert-form [form]="formToEdit" (hide)="closeQuestionModal()"></obc-upsert-form>
</ng-template>
<ng-template #automation>
  <obc-company-trigger-action [triggerDefaultValue]="2" [inProgress$]="inProgress$"></obc-company-trigger-action>
</ng-template>
<ng-template #clone>
  <div class="row">
    <div class="col-12">
      <section class="card">
        <div class="card-header">
          Clone Form
          <div class="card-actions">
            <a (click)="closeCloneModal()" class="card-action card-action-dismiss" data-card-dismiss=""></a>
          </div>
        </div>
        <div class="card-body">
          <obc-loader [show]="inProgress"></obc-loader>
          <div class="form-group row">
            <div class="col-md-6 col-12">
              <label class="control-label">Name:</label>
              <input type="text" class="form-control" [(ngModel)]="cloneName">
            </div>
            <div class="col-md-6 col-12">
              <label class="control-label">Button Title:</label>
              <input type="text" class="form-control" [(ngModel)]="cloneButtonTitle">
            </div>
          </div>
        </div>
        <footer class="card-footer text-right">
          <button class="btn btn-primary" (click)="cloneForm()" [disabled]="cloneName?.length === 0">
            Clone
          </button>
          <button type="reset" (click)="closeCloneModal()" class="btn btn-default ml-2">Cancel</button>
        </footer>
      </section>
    </div>
  </div>

</ng-template>
