<ng-container *ngIf="displayAsApproveRejectButton">
  <ng-container *ngTemplateOutlet="button"></ng-container>
</ng-container>
<div class="row" *ngIf="!selectedSiteAsset.isTemporarilyAsset">
  <div class="col-md-12">
    <div class="card">
      <obc-loader [show]="inProgress"></obc-loader>
      <header class="card-header">
        <div class="d-flex flex-row justify-content-between">
          <div>{{isCheckinRequested == true ? 'Check-in':'Check-out'}} Asset</div>
        </div>
      </header>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex align-items-center">
              <obc-switch [value]="isUpdateLocationRequested" (valueChanged)="onActiveChanged($event)">
              </obc-switch>
              <label class="ml-3 mb-0 mt-1">Update Asset Location</label>
            </div>
          </div>
          <div class="col-md-12 mt-2" *ngIf="isUpdateLocationRequested == true">
            <obc-inventory-location-selector [parentSelectedSite]="selectedSite"
              [externalSiteSupplierId]="externalSiteSupplierId" (onSelectLocation)="onLocationChange($event)"
              (onAddNewLocation)="onAddNewLocation($event)">
            </obc-inventory-location-selector>
          </div>
          <div class="form-group col-md-12 col-sm-12 mt-4">
            <ng-container *ngTemplateOutlet="button"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #button>
  <button class="btn btn-info pull-right mr-2 {{ selectedSiteAsset.isTemporarilyAsset ? 'btn-sm' : '' }}"
    [disabled]="!isFormValid" (click)="submit()">{{isCheckinRequested ==
    true ? 'Check-in' : 'Check-out'}}</button>
</ng-template>
