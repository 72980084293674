<div class="card mb-2" *ngIf="_induction">
  <div class="card-header d-flex flex-row justify-content-md-between align-content-center">
    <div class="flex-fill">
      {{_induction.induction.title}}
      <!-- {{_induction.isValid | json}} -->
    </div>
    <div *ngIf="!_induction.induction.isMandatory && !previewRenderer" >
      <div sty class="d-flex flex-row align-content-center">
        <div>
          Perform Induction
        </div>
        <div style="margin-top: -8px;">
          <obc-switch [value]="!_induction.skipped" (valueChanged)="onSkipInductionStatusChanged(_induction, $event)"
            [trueLabel]="'Now'" [falseLabel]="'Later'">
          </obc-switch>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body" *ngIf="!_induction.skipped">
    <div class="row">
      <div class="col-12">
        <obc-items-renderer
          [questionsData]="{
            induction: _induction,
            questions : _induction?.questions,
            validator$: questionContainerValidation$,
            documents: requiredDocumentFormControls,
            documentsFormArray: requiredDocumentFormArray,
            announcements: _induction?.announcements,
            notifyVisits: notifyVisits,
            anonymousAttendanceKey: anonymousAttendanceKey,
            additionalDocumentConfig: _induction?.induction?.additionalDocumentConfig,
            allUserDocumentTypes: allUserDocumentTypes,
            availableDocumentTypes: availableDocumentTypes
          }"
          (addAdditionalDocumentToDocumentTypesOutput)="onAddDocumentToDocuments($event)"
          (removeDocumentType)="removeDocumentFromRequiredDocuments($event)"
          (onCheckedMandatorySignOff)="onAnnouncementCheckedMandatorySignOff($event, _induction.induction.id)"
          (onAnnouncementVisit)="onUpdateVisitedAnnouncements($event, _induction.induction.id)"></obc-items-renderer>
      </div>
    </div>
  </div>
</div>
