import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, Observer, noop, of } from 'rxjs';
import { UserBusinessViewModel } from '../../models/user-business-viewmodel';
import { UserBusinessService } from '../../services/user-business-service';
import { switchMap, map, tap, finalize } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api';
import { result } from 'lodash';

@Component({
  selector: 'obc-user-business',
  templateUrl: './user-business.component.html',
  styleUrls: ['./user-business.component.scss']
})
export class UserBusinessComponent implements OnInit {
  inProgress: boolean = false;
  suggestions$: Observable<any[]>;
  loader: boolean = false;
  searchTerm: string = '';
  typedBusiness: string;

  @Input() placeholder: string = 'Search and add user business';
  @Input() displayNoResult: boolean = true;
  @Input() required: boolean = false;
  @Input() notFoundMessage: string = 'Nothing Found ...';
  @Input() isControlValid: boolean = true;
  @Input() sessionId: string;

  _currentBusiness: UserBusinessViewModel;
  @Input() set currentBusiness(model: UserBusinessViewModel) {
    this._currentBusiness = model;
    if (model != null) {
      this.resetSearchTerm();
      this.noResult = false;
    }
  };
  get currentBusiness(): UserBusinessViewModel {
    return this._currentBusiness;
  }

  @Output() selectedBusiness: EventEmitter<UserBusinessViewModel> = new EventEmitter();
  constructor(private userBusinessService: UserBusinessService) { }

  ngOnInit(): void {
    this.lookUpBusinessList();
  }

  lookUpBusinessList() {
    this.suggestions$ = new Observable((observer: Observer<string>) => {
      observer.next(this.searchTerm);
    }).pipe(
      switchMap((nameOrAbnOrAcn: string) => {
        if (nameOrAbnOrAcn) {
          this.loader = true;
          var request: Observable<UserBusinessViewModel[]>;
          if ((this.sessionId?.length ?? 0) > 0)
            request = this.userBusinessService.getAddressSuggestionListBySessionKey(this.sessionId, nameOrAbnOrAcn);
          else
            request = this.userBusinessService.getAddressSuggestionList(nameOrAbnOrAcn);

          return request.pipe(
            map((data: UserBusinessViewModel[]) => (data && data) || []),
            tap(
              () => noop,
              (err) => {
                var rrr = (err && err.message) || 'Something goes wrong';
              }
            ),
            finalize(() => {
              this.loader = false;
            }));
        }
        return of([]);
      })
    );
  }

  onSelectBusiness(event: TypeaheadMatch): void {
    if (event.item) {
      (event.item as UserBusinessViewModel).mode = "Selected";
      this.currentBusiness = event.item;
      this.loader = false;
      this.selectedBusiness.emit(event.item);
    }
  }

  resetSearchTerm() {
    this.searchTerm = this.currentBusiness?.name ?? "";
    this.loader = false;
  }

  resetAll() {
    this.currentBusiness = null;
    this.resetSearchTerm();
  }

  onChange($event) {
    if ($event) {
      this.selectedBusiness.emit({ abn: '', acn: '', name: this.searchTerm, postcode: '', state: '', mode: "UserValue" });
      this.typedBusiness = $event;
    }
  }

  noResult: boolean = false;
  typeaheadNoResults(event: boolean): void {
    this.noResult = event;
  }
}
