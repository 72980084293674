import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TextOperator } from 'src/app/enums/text-operator.enum';
import { QuestionAnswerFilterViewModel } from 'src/app/models/question-answer-filter-model';
import { TextOperatorPipe } from 'src/app/pipes/text-operator.pipe';

@Component({
  selector: 'obc-question-answer-filter-renderer',
  templateUrl: './question-answer-filter-renderer.component.html',
  styleUrls: ['./question-answer-filter-renderer.component.scss']
})
export class QuestionAnswerFilterRendererComponent implements OnInit {
  @Input() filterQuestions: QuestionAnswerFilterViewModel[] = [];
  @Output() onRefreshFilterQuestions = new EventEmitter<QuestionAnswerFilterViewModel[]>();

  constructor(
    private textOperatorPipe: TextOperatorPipe,
  ) {
  }

  ngOnInit(): void {
  }

  onRemoveQuestionFromFilterByUUID(uuid: string) {
    const question = this.filterQuestions.find((i) => i.uuid == uuid)
    if(question)
      this.onRemoveQuestionFromFilter(question)
  }

  onRemoveQuestionFromFilter(question: any) {
    const index = this.filterQuestions.indexOf(question);
    if (index >= 0) {
      this.filterQuestions.splice(index, 1);
      this.onRefreshFilterQuestions.emit(this.filterQuestions);
    }
  }

  getFilterAnswers(filter: QuestionAnswerFilterViewModel) {
    if (!filter.answers || filter.answers.length == 0)
      return '-- N.A.---'
    else {
      if(filter.operator == TextOperator.isNotNull || filter.operator == TextOperator.isNull) {
        return this.textOperatorPipe.transform(filter.operator);
      }
      if (filter.answers.length == 1 && !filter.answers[0]) {
        return "Not Answered"
      }
      let titles = filter.titles.join(' OR ');
      return (filter.operator != null ? this.textOperatorPipe.transform(filter.operator) : '') + (titles.length ? ": " + titles : '');
    }
  }
}
