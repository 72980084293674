<div class="p-2">
  <div class="row">
    <div class="col-md-12">
      <div class="form-inline">
        <div class="form-group mb-2 mt-2 col-md-12 col-sm-12">
          <div class="row">
            <div class="col-md-12">
              <obc-company-site-selector (onSelectChanged)="onSiteIdChange($event)" [currentSiteId]="siteId">
              </obc-company-site-selector>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-inline">
        <div class="form-group mb-2 mt-2 col-md-12 col-sm-12">
          <div class="row">
            <div class="col-md-12">
              <obc-trigger-selector [inputValue]="triggerDefaultValue" (selectedTrigger)="onSelectedTrigger($event)">
              </obc-trigger-selector>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="table-responsive  pl-1 pr-1 pb-1">
        <table class="table table-bordered table-striped mb-0 obc-table">
          <thead>
            <tr>
              <th>
                Location
              </th>
              <th>
                Trigger
              </th>
              <th>
                Action
              </th>
              <th style="width: 150px;">
                Status
              </th>
              <th style="width: 50px;">
                <button class="btn btn-sm btn-info pull-right  mr-1" (click)="onRefresh()">Refresh</button>
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let ta of companyTriggerActions">
              <tr *ngIf="filterType == null || (filterType != null && ta.type == filterType)">
                <td>
                  <span *ngIf="!ta.siteName">
                    Company
                  </span>
                  <span *ngIf="ta.siteName">
                    {{ta.siteName}}
                  </span>
                </td>
                <td>
                  {{getTriggerTypeFriendlyName(ta.type)}}
                  <br>
                  <span class="company-tag" *ngIf="ta.companyTagId">{{getCompanyTagLabel(ta)}}</span>
                  <span class="company-tag" *ngIf="!ta.companyTagId && ta.type==triggerType.Flag"
                    style="background-color: #ff000094 !important; color: white;">ANY TAG</span>
                </td>
                <td>
                  {{getTriggerActionFriendlyName(ta.action)}}
                  <p *ngIf="ta.parameters">
                    {{getTriggerActionSummery(ta)}}
                  </p>
                </td>
                <td>
                  <obc-switch (objectChanged)="onStatusChanged($event)" [tagged]="ta" [value]="ta.status" [id]="ta.id"
                    [falseLabel]="'Disabled'" [trueLabel]="'Enabled'"></obc-switch>
                </td>
                <td>
                  <p style="margin-top: 5px;" class="button-group">
                    <button class="btn btn-sm btn-info no-min-width" (click)="onShowEditModal(editModal, ta)">
                      <i class="fas fa-edit"></i>
                    </button>
                  </p>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="filteredItems == 0">
              <td colspan="10">
                There is no item available related to the selected trigger filter
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <button class="btn btn-sm btn-primary pull-right mr-1" [disabled]="siteId == -1"
        (click)="onShowEditModal(editModal , null)">
        <span *ngIf="siteId == 0">
          Add global trigger to company '{{currentCompany.title}}'
        </span>
        <span *ngIf="siteId > 0">
          Add trigger to site '{{getSelectedSiteName(siteId)}}'
        </span>
        <span *ngIf="siteId == -1">
          Please Select Location To Add Automation
        </span>
      </button>
    </div>
  </div>
</div>

<ng-template #editModal>
  <div class="card" *ngIf="editFormGroup">
    <obc-loader [show]="inAddEditProgress"></obc-loader>
    <div class="card-header">
      <span>
        <span *ngIf="editFormGroup.get('id').value != 0">Edit</span>
        <span *ngIf="editFormGroup.get('id').value == 0">Add New</span>
      </span>
      <button type="button" class="close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <form [formGroup]="editFormGroup" class="row">
        <input type="hidden" formControlName="id">
        <div class="form-group col-md-12 col-sm-12">
          Scope:
          <strong>
            {{isCompanyWide?'Company':'Site ('+siteName+')'}}
          </strong>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label>Trigger</label>
          <select class="custom-select form-control" formControlName="type" (change)="onTriggerChange()">
            <option [ngValue]="null" disabled>Choose Trigger</option>
            <option [ngValue]="triggerType.Flag">{{getTriggerTypeFriendlyName(triggerType.Flag)}}</option>
            <option [ngValue]="triggerType.Reject">{{getTriggerTypeFriendlyName(triggerType.Reject)}}</option>
            <option [ngValue]="triggerType.SubmitForm">{{getTriggerTypeFriendlyName(triggerType.SubmitForm)}}</option>
            <option [ngValue]="triggerType.ApprovalState">{{getTriggerTypeFriendlyName(triggerType.ApprovalState)}}
            </option>
          </select>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label>Action</label>
          <select class="custom-select form-control" formControlName="action">
            <option [ngValue]="null" disabled>Choose Action</option>
            <option [ngValue]="triggerAction.Email">{{getTriggerActionFriendlyName(triggerAction.Email)}}</option>
          </select>
        </div>
        <div class="form-group col-md-12" *ngIf="editFormGroup.get('type').value == triggerType.Flag">
          <label>Tag</label>
          <select class="custom-select form-control" formControlName="tagId" (ngModelChange)="onTagChange($event)">
            <option [ngValue]="null">Any Tag</option>
            <option *ngFor="let ct of companyTags" [ngValue]="ct.id">{{ct.title}}</option>
          </select>
        </div>
        <div class="form-group col-md-12" *ngIf="editFormGroup.get('type').value == triggerType.SubmitForm">
          <label>Forms</label>
          <select class="custom-select form-control" formControlName="formId">
            <option [ngValue]="null">Any Form</option>
            <option *ngFor="let form of (forms | async)" [ngValue]="form.id">{{form.name}}</option>
          </select>
        </div>
        <div class="form-group col-md-12"
          *ngIf="editFormGroup.get('type').value == triggerType.SubmitForm && editFormGroup.get('formId').value == null">
          <label>Form Type</label>
          <select class="custom-select form-control" formControlName="formType">
            <option *ngFor="let formType of formTypes | enumToArray" [ngValue]="formTypes[formType]">{{formTypes[formType] | formType}}</option>
          </select>
        </div>
        <div class="form-group col-md-12" *ngIf="editFormGroup.get('type').value == triggerType.ApprovalState">
          <label>States</label>
          <div class="row" *ngFor="let index of siteAttendanceApprovalState | enumToArray">
            <div class="col-12">
              <span class="form-group form-check form-check-inline">
                <input class="form-check-input" [value]="siteAttendanceApprovalState[index]" type="checkbox"
                  (change)="approvalStatusItemChanged(siteAttendanceApprovalState[index])"
                  [checked]="editFormGroup.get('approvalStatusList').value != null && editFormGroup.get('approvalStatusList').value?.indexOf(siteAttendanceApprovalState[index]) > -1"
                  id="index_{{siteAttendanceApprovalState[index]}}">
                <label class="form-check-label"
                  for="index_{{siteAttendanceApprovalState[index]}}">{{siteAttendanceApprovalState[index] |
                  siteAttendanceApprovalStatus}}</label>
              </span>

            </div>
          </div>
        </div>
        <div class="col-md-12">
          <obc-email-template *ngIf="editFormGroup.get('action').value == 0" formControlName="parameters">
          </obc-email-template>
        </div>
        <div class="col-md-12 mt-4">
          <p class="button-group">
            <button [disabled]="!editFormGroup.valid" class="card-link btn btn-primary" (click)="onSave()">
              Save Changes
            </button>
          </p>
        </div>
        <div class="col-md-12 mt-4">
          <p *ngIf="errorMessage">
            {{errorMessage}}
          </p>
        </div>
      </form>
    </div>
  </div>
</ng-template>
