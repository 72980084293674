import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { e168ValidatorNullable } from "../../../helpers/mobile-format-validator";
import { EmergencyContactEmitResponse } from "../../../models/emergency-contact-emit-response";
import { EmergencyContactViewModel } from 'src/app/models/emergency-contact-view-model';

@Component({
  selector: 'obc-emergency-contact',
  templateUrl: './emergency-contact.component.html',
  styleUrls: [ './emergency-contact.component.scss' ]
})
export class EmergencyContactComponent implements OnInit {

  form = this.formBuilder.group({
    name: new FormControl(''),
    mobile: new FormControl('', [e168ValidatorNullable]),
    relationship: new FormControl(''),
  })

  @Output() onUpdate: EventEmitter<EmergencyContactEmitResponse> = new EventEmitter<EmergencyContactEmitResponse>()
  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>()

  @Input() data: string;
  @Input() displayInRow: boolean = false;
  @Input() labelStyle: string;
  @Input() displayDots: boolean = true;
  @Input() required: boolean;
  @Input() jsonData: string;

  constructor(private formBuilder: FormBuilder,) {
  }

  ngOnInit(): void {

    let jsonObject: EmergencyContactViewModel = EmergencyContactViewModel.newEmptyAnswer();
    if(this.jsonData) {
      try {
        jsonObject = JSON.parse(this.jsonData) as EmergencyContactViewModel;
      } catch(_) {}
    }

    this.form.controls.name.setValue(jsonObject?.name ?? '');
    this.form.controls.mobile.setValue(jsonObject?.mobile ?? '');
    this.form.controls.relationship.setValue(jsonObject?.relationship ?? '');
    if(this.required) {
      this.addValidators();
    }
    this.form.valueChanges.subscribe((res) => {
      this.emitData(res);
    })

    this.emitData(jsonObject);
  }

  addValidators() {
    this.form.controls.name.addValidators(Validators.required);
    this.form.controls.mobile.addValidators(Validators.required);

    this.form.controls.name.updateValueAndValidity({emitEvent: false});
    this.form.controls.mobile.updateValueAndValidity({emitEvent: false});
  }

  emitData(res) {
    if(this.form.valid) {
      this.onUpdate.emit({value: res, isValid: this.form.valid});
    } else {
      this.onUpdate.emit({value: null, isValid: this.form.valid});
    }
  }

}
