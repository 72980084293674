import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  public handleError(error: HttpErrorResponse): Observable<any> {
    let ErrorMessage: any = "";
    if (error.status == 404)
      ErrorMessage = "Request Not Found";

    else if (error.status == 401 && error.statusText == 'Unauthorized')
      ErrorMessage = "Please Login Again";

    if (error.error instanceof ErrorEvent) {
      ErrorMessage = error.error.message;
    } else {
      ErrorMessage = (error.error) ? ((!error.error.server_error) ? error.error : error.error.server_error) : error;
    }

    ErrorMessage = (ErrorMessage instanceof Array) ? ErrorMessage[0] : ErrorMessage;
    console.error('ErrorMessage : ', ErrorMessage);

    return throwError(ErrorMessage);
  };
}
