<div>
  <ng-select style="padding-bottom: 0 !important;" class="site-selector" [typeaheadOptionsLimit]="7"
    [typeaheadMinLength]="0" [items]="sites$ | async" [placeholder]="getPlaceholder()" bindLabel="name"
    (scrollToEnd)="onScrollToEnd()" bindValue="id" [typeahead]="term$" [loading]="loading" [clearable]="!_required"
    [formControl]="formControl">
    <ng-template ng-option-tmp let-item="item">
      {{item.name}} <span *ngIf="item.siteReference">[{{item.siteReference}}]</span> <strong *ngIf="item.isTemplate"
        class="ml-2 badge badge-danger">Template</strong>
    </ng-template>
  </ng-select>
</div>