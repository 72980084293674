<ng-container *ngIf="noCard">
  <accordion-group>
    <div accordion-heading>
      <obc-accordion-header [isAccordionDirty]="dirtyComponents[dirtyComponentFieldName()]?.isDirty"
        [mainTitle]="getMainTitle()" [icon]="'fa fa-tasks'"
        [iconColor]="formType == FormType.Generic ? '#57831a' : formType == FormType.Permit? '#5880a2' : formType== FormType.AssetRegistration? '#671a83':''"
        [info]="[{'title': '', 'value': headerInfo }]">
      </obc-accordion-header>
    </div>
    <div>
      <div class="d-flex justify-content-end mb-3">
        <a (click)="onOpenAddForm()" class="btn btn-sm btn-info text-decoration-none text-white">
          <i class="fa fa-plus"></i>
          Add {{entityTitle}}
        </a>
      </div>
      <obc-loader [show]="inProgress"></obc-loader>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-responsive-md table-striped mb-0">
              <thead>
                <tr>
                  <th>
                    {{entityTitle}}
                  </th>
                  <th>
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="filterForms()">
                <tr *ngFor="let sf of filterForms()">
                  <td>
                    {{sf.formName}}
                  </td>
                  <td>
                    <div class="d-flex flex-row flex-row-reverse">
                      <button (click)="onRemoveSiteForm(sf)" class="btn btn-danger">Remove</button>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="filterForms()?.length == 0">
                  <td colspan="20">
                    <p class="text-center mb-0">
                      There is no {{entityTitle.toLowerCase()}}, You can add by clicking on `Add {{entityTitle}}` button
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </accordion-group>
</ng-container>

<div *ngIf="!noCard" class="card">
  <div class="card-header">
    <div class="d-flex flex-row justify-content-between">
      <h2 class="card-title">Site Forms</h2>
      <div>
        <button (click)="onOpenAddForm()" type="button" class="btn btn-sm btn-info"><i class="fas fa-plus"></i>
          Add {{entityTitle}}
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  {{entityTitle}}
                </th>
                <th>
                </th>
              </tr>
            </thead>
            <tbody *ngIf="filterForms()">
              <tr *ngFor="let sf of filterForms()">
                <td>
                  {{sf.formName}}
                </td>
                <td>
                  <div class="d-flex flex-row flex-row-reverse">
                    <button (click)="onRemoveSiteForm(sf)" type="button" class="btn btn-danger">Remove</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</div>
