import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileType } from 'src/app/enums/file-type.enum';
import { FileModel, } from 'src/app/models/supplier-document/site-supplier-document-type-view-model.model';
import { BsModalService } from "ngx-bootstrap/modal";
import { detectFileType } from "../../helpers/general-functions";

@Component({
  selector: 'obc-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss'],
})
export class FileViewerComponent implements OnInit {

  @Input() files: FileModel[] = [];
  @Input() urls: string[];
  @Input() prefix: string = null;
  @Input() btnTitle: string;
  @Input() btnClass: string = 'btn btn-sm btn-success';
  @Input() imgClass: string = '';
  @Input() btnIcon: string;
  @Input() btnStyle: string;
  @Input() tooltip: string;
  @Input() previewImage: string;
  @Input() previewImageWidth: number = 100;
  @Input() previewImageHeight: number = 100;
  @Input() showByModal: boolean = true;
  @Input() useOriginalFile: boolean = false;

  FileType = FileType;

  @Output('onFileViewed') onFileViewed = new EventEmitter();

  firstFileViewedAutomatically: boolean = false;

  constructor(private bsModalService: BsModalService) { }

  ngOnInit() {
    if (this.btnStyle) {
      this.btnStyle = this.btnStyle.trim();
      if (this.btnStyle.slice(this.btnStyle.length - 1) !== ';')
        this.btnStyle = this.btnStyle + ';';
    }


    if (this.urls) {
      for (let url of this.urls) {
        this.files.push({
          fileUrl: url,
          originalFileUrl: url,
          fileType: this.detectFileType(url)
        } as FileModel);
      }
    }
  }

  fileHasContent(file: FileModel) {
    let extension = this.getUrl(file).split(/[#?]/)[0].split('.').pop().trim();
    var binaryFileTypesOpen = ['xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx'];
    return file.fileType == FileType.Pdf || file.fileType == FileType.Image ||
      ((file.fileType == FileType.Binary || file.fileType == FileType.ExternalLink) && binaryFileTypesOpen.includes(extension));
  }

  getFilename(index: number) {
    return this.prefix?.length ? `${this.prefix}${(this.files.length > 1 ? `-file #${index + 1}` : '')}` : `file #${index + 1}`;
  }

  detectFileType(url): FileType {
    let result = detectFileType(url);
    if (result != null)
      return result;
    return this.checkYouTubeOrDefault(url);
  }

  checkYouTubeOrDefault(url) {
    return url.replace("http://", "")
      .replace("https://", "")
      .replace("www.", "")
      .replace("youtu.be/", "youtube.com")
      .slice(0, 11) === "youtube.com" ? FileType.Youtube : FileType.ExternalLink;
  }


  selectedFiles: FileModel[] = [];
  bsModalRef = null;
  onOpenFileViewer(template: any, prefix: string) {

    if (this.files && this.files.length > 0 && !this.firstFileViewedAutomatically) {
      this.onFileViewed.emit([...this.files]);
      // this.onFileViewed.emit(this.files[0].fileUrl);
      this.firstFileViewedAutomatically = true;
    }

    this.prefix = prefix;
    this.bsModalRef = this.bsModalService.show(template, {
      class: "modal-xl",
      ignoreBackdropClick: false,
    })
  }

  onFileOpened(fileUrl: string) {
    this.onFileViewed.emit(fileUrl);
  }

  closeModal() {
    if (this.bsModalRef)
      this.bsModalRef.hide();
  }

  googleDriveUrl(url) {
    var googleUrl = 'https://drive.google.com/viewer?embedded=true&hl=en-US&url=' + encodeURIComponent(url);
    return googleUrl;
  }

  getUrl(file: FileModel) {
    return this.useOriginalFile && file.originalFileUrl ? file.originalFileUrl : file.fileUrl;
  }
}
