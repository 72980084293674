import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Observable } from 'rxjs';
import { CompanyTriggerActionViewmodel } from '../models/company-trigger-action-viewmodel';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyTriggerActionService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }
  apiUrl = '/api/CompanyTriggerAction';

  getForSite(siteId: any): Observable<CompanyTriggerActionViewmodel[]> {

    return this._http.get<CompanyTriggerActionViewmodel[]>(`${this.apiUrl}/get-for-site/${siteId}`)
        .pipe(catchError(this._errorService.handleError));
  }

  getForCompany(): Observable<CompanyTriggerActionViewmodel[]> {
    return this._http.get<CompanyTriggerActionViewmodel[]>(`${this.apiUrl}/get-for-company`)
        .pipe(catchError(this._errorService.handleError));
  }

  getForAllLocation(): Observable<CompanyTriggerActionViewmodel[]> {
    return this._http.get<CompanyTriggerActionViewmodel[]>(`${this.apiUrl}/get-for-all-locations/`)
        .pipe(catchError(this._errorService.handleError));
  }

  add = (model: CompanyTriggerActionViewmodel): Observable<number> =>
    this._http.post<number>(`${this.apiUrl}/add`, model)
      .pipe(catchError(this._errorService.handleError));
  edit = (model: CompanyTriggerActionViewmodel): Observable<boolean> =>
    this._http.post<boolean>(`${this.apiUrl}/edit`, model)
      .pipe(catchError(this._errorService.handleError));
  toggleStatus = (id: number): Observable<boolean> =>
    this._http.get<boolean>(`${this.apiUrl}/toggle-status/${id}`)
      .pipe(catchError(this._errorService.handleError));
}
