import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { FormService } from "../../../services/form.service";
import { ModalService } from "../../../services/modal.service";
import { FilledFormInfoViewModel } from "../../../models/filled-form-info-view-model";
import { DocumentProvider } from 'src/app/enums/document-provider.enum';
import { QuestionType } from 'src/app/enums/question-type';
import { SiteAttendanceDocumentViewModel } from "../../../models/site-attendance-document-viewmodel";
import { FormType } from 'src/app/enums/form-type';
import { ApproveEntityType } from 'src/app/enums/approve-entity-type';
import { ApproveRejectQueryModel } from 'src/app/models/approve/approve-reject-query-model';
import { ApproveService } from 'src/app/services/approve-service';
import { finalize } from 'rxjs/operators';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { FormDataStatus } from 'src/app/enums/form-data-status';
import { FormControl } from "@angular/forms";
import { UserService } from 'src/app/services/user.service';
import { SiteSupplierService } from 'src/app/services/site-supplier.service';
import { SiteAssetRequestApproveStatus } from "../../../enums/site-asset-request-approve-status.enum";
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from "ngx-bootstrap/modal";


@Component({
  selector: 'obc-form-data-details',
  templateUrl: './form-data-details.component.html',
  styleUrls: ['./form-data-details.component.scss']
})
export class FormDataDetailsComponent implements OnInit {
  @Input() formDataId: number;
  @Input() entityId: number;
  @Input() formDetail: FilledFormInfoViewModel;
  @Output() onRefresh = new EventEmitter();
  @Input() noCard: boolean = false;

  @Input() set siteSupplierHashlink(value: string) {
    if (value != this._siteSupplierHashlink.value && value != null) {
      this._siteSupplierHashlink.next(value);
    }
  }

  @Input() loadFromWorkflow: boolean = false;

  _siteSupplierHashlink = new BehaviorSubject<string>(null);

  inProgress = false;
  DocumentProvider = DocumentProvider;
  QuestionType = QuestionType;
  newStartDate: Date;
  newEndDate: Date;
  description: FormControl = new FormControl();
  isNewStartDateAvailable: boolean = false;
  isNewEndDateAvailable: boolean = false;
  showPermitPendingControls: boolean = false;
  showSupplierFormPendingControls: boolean = false;
  showPendingControls: boolean = false;
  datetimePickerMode = DatetimePickerMode;
  FormDataStatus = FormDataStatus;
  FormType = FormType;

  updatePendingDisplayMode() {
    this.userService.info.subscribe(res => {
      this.showPendingControls =
        this.formDetail &&
        this.formDetail.companyId == this.userService.currentCompanyId &&
        this.userService.hasUserPermissionForCompany(UserPermission.ApproveRejectSiteInduction) &&
        (((this.formDetail.type == FormType.Permit) &&
          this.formDetail.status == FormDataStatus.PendingApproval) ||
          ((this.formDetail.type == FormType.SupplierForm) &&
            this.formDetail.status == FormDataStatus.PendingApproval) ||
          ((this.formDetail.type == FormType.AssetRegistration ||
            this.formDetail.type == FormType.AssetOperator) &&
            this.formDetail.entityStatusInfo.approveStatus == SiteAssetRequestApproveStatus.Pending));
    });
  }


  get isSupplierUser() {
    return this._siteSupplierHashlink.value?.length > 0;
  }

  constructor(private formService: FormService,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private approveService: ApproveService,
    private userService: UserService,
    private siteSupplierService: SiteSupplierService,
    private toastrService: ToastrService,) {
  }

  ngOnInit(): void {
    if (!this.formDetail && this.formDataId) {
      this.inProgress = true;
      var request = this.isSupplierUser == true
        ? this.siteSupplierService.getFilledForm(this.formDataId, this._siteSupplierHashlink.value)
        : this.formService.getFilledForm(this.formDataId);

      request.subscribe(
        res => {
          if (res) {
            this.formDetail = res;
            this.convertStringDatesToDate();
            this.userService.info.subscribe(res => {
            });
            this.updatePendingDisplayMode();
          } else {
            this.modalService.error('No Details Available');
          }
        }, err => {
          this.modalService.error("Request Failed");
        }, () => {
          this.inProgress = false;
        }
      )
    } else {
      this.userService.info.subscribe(res => {
      });
      if (this.formDetail?.endDate != null && this.formDetail?.type != FormType.Permit) {
        this.newEndDate = new Date(this.formDetail?.endDate);
        this.isNewEndDateAvailable = true;
      }
      this.convertStringDatesToDate();
      this.updatePendingDisplayMode();
    }
  }

  isExpiredDocument(doc: SiteAttendanceDocumentViewModel): boolean {
    return doc.expireDate != null && new Date(doc.expireDate) < new Date();
  }

  convertStringDatesToDate() {
    if (this.formDetail && this.formDetail.minStartDate) {
      this.formDetail.minStartDate = new Date(this.formDetail.minStartDate);
    }
  }

  editDate(isStartDate: boolean) {
    if (isStartDate) {
      this.isNewStartDateAvailable = true;
      this.newStartDate = new Date(this.formDetail.startDate);
    } else {
      this.isNewEndDateAvailable = true;
      this.newEndDate = this.formDetail.endDate ? new Date(this.formDetail.endDate) : new Date();
    }
  }

  datePickerValueChage($event: Date, isStartDate: boolean) {
    if (isStartDate) {
      if ($event == null)
        this.isNewStartDateAvailable = false;
      else
        this.newStartDate = $event;

    } else {
      if ($event == null)
        this.isNewEndDateAvailable = false;
      else
        this.newEndDate = $event;
    }
  }

  approveReject(isApprove: boolean) {

    this.modalService.confirm(`Are you sure you want to ${isApprove ? 'approve' : 'reject'} ?`, `Confirm the action`).subscribe(result => {
      if (result) {
        let entityType: ApproveEntityType = ApproveEntityType.PermitForms;
        if (this.formDetail.type == FormType.AssetRegistration) {
          entityType = ApproveEntityType.SiteAsset;
          this.entityId = this.entityId ?? this.formDetail.siteAssetId;
        } else if (this.formDetail.type == FormType.AssetOperator)
          entityType = ApproveEntityType.SiteAssetOperator
        else if (this.formDetail.type == FormType.SupplierForm)
          entityType = ApproveEntityType.SupplierForms

        if (entityType == ApproveEntityType.PermitForms) {
          if (this.isNewStartDateAvailable)
            this.newStartDate = new Date(this.newStartDate.getFullYear(),
              this.newStartDate.getMonth(),
              this.newStartDate.getDate(),
              0, 0, 0
            );
          if (this.isNewEndDateAvailable)
            this.newEndDate = new Date(this.newEndDate.getFullYear(),
              this.newEndDate.getMonth(),
              this.newEndDate.getDate(),
              23, 59, 59
            );
        }
        this.inProgress = true;
        let model = {
          approveEntityType: entityType,
          entityId: this.entityId ?? this.formDetail?.entityId ?? this.formDetail.formDataId,
          isApprove: isApprove,
          newStartDate: isApprove == true && this.isNewStartDateAvailable ? this.newStartDate : null,
          newEndDate: isApprove == true && this.isNewEndDateAvailable ? this.newEndDate : null,
          description: this.description?.value ?? ''
        } as ApproveRejectQueryModel;

        this.approveService.approveRejectEntity(model)
          .pipe(finalize(() => {
            this.inProgress = false
          }))
          .subscribe(res => {
            if (res == true) {
              this.toastrService.success(`${isApprove == true ? 'Approved' : 'Rejected'} Successfully.`);
              this.onRefresh.emit();
            } else
              this.toastrService.error(`${isApprove == true ? 'Approve' : 'Reject'} Failed.`);
          }, err => {
            this.modalService.error(err)
          })
      }
    });

  }

  isValid(): boolean {
    const startDate = this.isNewStartDateAvailable ? this.newStartDate : new Date(this.formDetail.startDate);
    const endDate = this.isNewEndDateAvailable ? this.newEndDate : new Date(this.formDetail.endDate);
    return startDate <= endDate;
  }

  openFormHistoryModal(formHistory) {
    this.modalService.show(formHistory, 'modal-lg');
  }

  onUpdateForm(result) {
    if (result) {
      this.formService.getFilledForm(this.formDetail?.formDataId)
        .pipe(finalize(() => {
          this.inProgress = false
        }))
        .subscribe(res => {
          if (res) {
            this.formDetail = res;
          }
        })
    }
  }
}
