<div *ngIf="formGroup">
  <form [formGroup]="formGroup">
    <div class="d-flex flex-column" *ngIf="model">
      <div>
        <i *ngIf="valid" style="color: green;" class="fas fa-check-circle  mr-2"></i>
        <i *ngIf="!valid" style="color: red;" class="fas fa-times-circle  mr-2"></i>
        <obc-question-title-renderer [question]="model.question"></obc-question-title-renderer>
      </div>

      <div class="form-group d-flex justify-content-end">
        <div class="d-flex flex-row  justify-content-end">

          <button *ngIf="signaturePadController?.value == null" type="button" class="btn btn-primary align-self-start"
            (click)="sign(signaturePad, signaturePadController)">Sign</button>
          <span *ngIf="signaturePadController && signaturePadController.value">
            <div class="d-flex">
              <button *ngIf="signaturePadController?.value != null" type="button"
                      class="btn btn-sm btn-primary align-self-start" (click)="removeSign()"><i
                      class="fa fa-times"></i></button>
              <img class="ml-2 signature-bg"
                   [src]="isBase64Answer ? (displayBase64() | safe:'url') : signaturePadController.value"
                   style="width: 150px; height: 150px;">
            </div>
          </span>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #signaturePad>
  <obc-signature-pad (onSave)="saveSign($event)"></obc-signature-pad>
</ng-template>
