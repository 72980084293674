import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NgControl } from '@angular/forms';
import { BsDatepickerInlineConfig } from 'ngx-bootstrap/datepicker';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';


@Component({
  selector: 'obc-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() mode = DatetimePickerMode.DateTimePicker;
  DatetimePickerMode = DatetimePickerMode;
  @Input() date: Date;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  onChange: Function;
  isDatePickerDisabled: boolean = false;
  timePickerFormControl = new FormControl();
  @Output() valueChange = new EventEmitter();
  @Output() self = new EventEmitter<DateTimePickerComponent>();
  @Output() select = new EventEmitter();
  @ViewChild("selfRef") public selfRef: any;

  constructor(@Self() private controlDirective: NgControl) {
    controlDirective.valueAccessor = this;
  }
  ngAfterViewInit(): void {
    this.self.emit(this);
  }

  writeValue(date: Date): void {
    this.date = date;
    this.timePickerFormControl.setValue(date);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDatePickerDisabled = isDisabled;
    if (isDisabled) {
      this.timePickerFormControl.disable();
    } else {
      this.timePickerFormControl.enable();
    }
  }

  ngOnInit() {
    this.controlDirective.control.setValidators([this.validate.bind(this)]);
    this.controlDirective.control.updateValueAndValidity();
    this.timePickerFormControl.valueChanges.subscribe(res => {
      this.onDateChange(res);
    })
  }

  validate({ value }: FormControl) {
    if (!this.timePickerFormControl.valid) // mod it later
      return { invalid: true }
  }

  onDateChange($event) {
    if (this.date != $event) {
      this.date = $event;
      this.EmitChanges();
    }
  }

  EmitChanges() {
    this.onChange(this.date);
    this.valueChange.emit(this.date);
  }


}
