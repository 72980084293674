import { Pipe, PipeTransform } from '@angular/core';
import { ExternalSitePermission } from '../enums/external-site-permission';

@Pipe({
  name: 'externalSitePermission'
})
export class ExternalSitePermissionPipe implements PipeTransform {

  transform(value: any): string {
    if (value == ExternalSitePermission.ViewActivity) return 'View Activity';
    if (value == ExternalSitePermission.NoPermission) return 'No Permission';
    if (value == ExternalSitePermission.DefineQuestions) return 'Questions';
    if (value == ExternalSitePermission.DefineAnnouncements) return 'Announcements';
    if (value == ExternalSitePermission.DefineForms) return 'Forms';
    if (value == ExternalSitePermission.DefineInductions) return 'Inductions';
    return value?.toString() ?? '?';
  }

}
