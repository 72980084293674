import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ErrorService } from "./error.service";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { ManageSiteModel, ManageSiteViewModel } from "../models/manage-site-view-model";
import { ServerResultData } from '../models/server-result-data';

@Injectable({
  providedIn: 'root'
})
export class ManageSiteService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getSiteFullData(siteId: number): Observable<ManageSiteViewModel> {
    return this._http.get(`api/site/get-site-full-data/${siteId}`).pipe(catchError(this._errorService.handleError));
  }


  saveSiteFullData(siteId: number, model: ManageSiteModel): Observable<ServerResultData<ManageSiteViewModel>> {
    return this._http.post<ServerResultData<ManageSiteViewModel>>(`api/site/save-site-full-data/${siteId}`, model)
      .pipe(catchError(this._errorService.handleError));
  }

}
