<div style="overflow: auto;" *ngIf="show">
    <div data-loading-overlay class="loading-overlay-showing">
        <div class="loading-overlay">
            <div class="bounce-loader">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
    </div>
</div>
