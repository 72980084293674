<div class="card">
  <div id="top" class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="row">
      <div class="col-md-12">
        <div class="fw table table-responsive">
          <table class="fw mt-1 table table-bordered table-striped mb-0">
            <thead>
              <tr>
                <td>Version</td>
                <td>Files</td>
                <td>Review Form</td>
                <td>Status</td>
                <td>Approve/Reject By</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="!siteSupplierDocumentVersions.length">
                <td colspan="6">
                  <p class="text-center">No Data</p>
                </td>
              </tr>
              <ng-container *ngIf="siteSupplierDocumentVersions.length">
                <tr *ngFor="let item of siteSupplierDocumentVersions; let index = index">
                  <td>{{item.version}}</td>

                  <td>
                    <obc-file-viewer *ngIf="item.files?.length" [files]="item.files" [btnTitle]="'View Files'"
                      [btnClass]="'btn-sm btn btn-primary'" [btnIcon]="null"
                      [prefix]="item.supplierDocumentTypeName+'-'+item.version"></obc-file-viewer>

                    <div class="mt-2">
                      <obc-supplier-document-description-viewer
                        [documentTitle]="item?.supplierDocumentTypeName + ' - ' + item?.version"
                        [description]="item?.description" [classes]="'btn-sm btn btn-tertiary mb-2'">
                      </obc-supplier-document-description-viewer>
                    </div>


                    <obc-site-supplier-document-version-comments
                      *ngIf="item.comments?.length > 0"
                      [siteSupplierDocumentVersionId]="item.siteSupplierDocumentVersionId"
                      [hasPermissionToAddSupplierDocumentComment]="false"
                      [supplierDocCommentAcknowledgementText]="supplierDocCommentAcknowledgementText"
                      [comments]="item.comments"
                      [classes]="'ml-2 btn-sm'"
                      [noIcon]="true"
                    >
                    </obc-site-supplier-document-version-comments>
                  </td>
                  <td>
                    <button *ngIf="item.formDataId > 0" class="btn-sm btn btn-primary ml-2 pull-right"
                      (click)="onViewFilledForm(formView , item.formDataId)"> View Review Form
                    </button>
                  </td>
                  <td>
                    <i [tooltip]="(item.status | supplierDocumentTypeStatusTooltip)"
                      class="{{ item.status | supplierDocumentTypeStatusIcon }} mr-3"></i>
                    {{item.status | supplierDocumentTypeStatusTooltip}}
                  </td>
                  <td>{{item.approveDeclinerUserFullName}} {{item.approveDeclineDateTime | datetime}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #formView>
  <div>
    <div>
      <ul class="simple-post-list">
        <div class="card">
          <obc-form-data-details [siteSupplierHashlink]="_siteSupplierHashlink | async"
            [formDataId]="selectedFormDataId"></obc-form-data-details>
        </div>
      </ul>
    </div>
  </div>
</ng-template>
