import { Component, Input, Output, TemplateRef, EventEmitter, OnInit, forwardRef } from '@angular/core';
import { CdkDragDrop, CdkDragStart, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'obc-multiple-drag-drop-sortable',
	templateUrl: './multiple-drag-drop-sortable.component.html',
	styleUrls: ['./multiple-drag-drop-sortable.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => MultipleDragDropSortableComponent),
		multi: true
	}]
})
export class MultipleDragDropSortableComponent implements OnInit, ControlValueAccessor {

	constructor() { }

	writeValue(value: any): void {
		this.items = value;
		this.updateChanges();
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
	}
	updateChanges() {
		this.onChange(this.items);
	}

	onChange: (_: any) => void = (_: any) => { };
	onTouched: () => void = () => { };


	@Input() template: TemplateRef<any>;
	@Input() displayOnly: boolean = false;
	@Output() onChangeOrder: EventEmitter<any> = new EventEmitter();
	@Output() onScrollEvent: EventEmitter<any> = new EventEmitter();

	messages: string;
	items: any;

	ngOnInit() {
	}

	onScrollEventInternal(event) {
		this.onScrollEvent.emit(event);
	}

	drop(event: CdkDragDrop<string[]>) {
		// If current element has ".selected"
		let itemSelected = event.item.element.nativeElement.classList.contains("selected");
		if (false && itemSelected) {
			this.multiDrag.dropListDropped(event);
		}
		// If dont have ".selected" (normal case)
		else {
			if (event.previousContainer === event.container) {
				if (itemSelected) {
					let previousIndex = event.previousIndex;
					let currentIndex = event.currentIndex;
					while (event.container.data.some(d => (d as any).isSelected)) {
						let index = event.container.data.findIndex(d => (d as any).isSelected);
						let item = event.container.data[index];
						moveItemInArray(event.container.data, index, currentIndex);
						currentIndex = event.container.data.indexOf(item);
						(item as any).isSelected = false;
					}
				}
				else {
					moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
				}
			} else {
				transferArrayItem(event.previousContainer.data,
					event.container.data,
					event.previousIndex,
					event.currentIndex);
			}
		}
	}

	/* TWO OBJECTS */

	// Multi Select
	multiSelect = { // Put ".selected" on elements when clicking after longPress or Ctrl+Click
		// Initial Variables
		longPressTime: 500, // in ms unit
		verifyLongPress: 0,
		multiSelect: false,
		verifyDragStarted: false,
		ctrlMode: false,
		firstContainer: null as unknown as HTMLElement,

		selectDrag(el: HTMLElement) {
			while (!el.classList.contains("cdk-drag")) {
				el = el.parentElement as HTMLElement;
			}
			return el;
		},

		dragStarted() {
			this.verifyDragStarted = true; // shows to mouseDown and mouseUp that Drag started
			clearTimeout(this.verifyLongPress); // cancel longPress
		},

		dragEnded() {
			this.verifyDragStarted = false; // show mouseDown and mouseUp that Drag is over
		},

		dropListDropped(e: CdkDragDrop<string[]>) {
			let el = e.item.element.nativeElement;
			if (el.classList.contains("selected")) { // the dragged element was of the "selected" class
				this.multiSelect = false; // disable multiSelect
			}
		}

	}

	// Multi Drag
	multiDrag = { // Adjusts clicked items that have ".selected" to organize together
		// Initial Variables
		dragList: [""], // has the value of the selected items in sequence from listData
		dragListCopy: [""], // a copy of the listData, but with the selected elements marked with "DragErase" to delete later
		dragErase: Symbol("DragErase") as any, // a symbol to have unique value when deleting

		// dragStarted(e: CdkDragStart) {
		// 	if (e.source.element.nativeElement.classList.contains("selected")) { // If the dragged element has ".selected"
		// 		//prepare
		// 		let listData = e.source.dropContainer.data; // get list data value
		// 		this.dragList = []; // reset the dragList
		// 		this.dragListCopy = [...listData]; // copy listData into variable
		// 		let DOMdragEl = e.source.element.nativeElement; // dragged element
		// 		let DOMcontainer = Array.from(DOMdragEl.parentElement!.children); // container where all draggable elements are
		// 		let DOMdragElIndex = DOMcontainer.indexOf(DOMdragEl); // index of the dragged element
		// 		let allSelected = document.querySelectorAll(".selected"); // get all the ".selected"

		// 		// Goes through all ".selected"
		// 		// allSelected.forEach((eli) => {
		// 		// 	// get index of current element
		// 		// 	let CurrDOMelIndexi = DOMcontainer.indexOf(eli);

		// 		// 	// Add listData of current ".selected" to dragList
		// 		// 	this.dragList.push(listData[CurrDOMelIndexi]);

		// 		// 	// Replaces current position in dragListCopy with "DragErase" (to erase exact position later)
		// 		// 	this.dragListCopy[CurrDOMelIndexi] = this.dragErase;

		// 		// 	// Put opacity effect (by CSS class ".hide") on elements (after starting Drag)
		// 		// 	if (DOMdragElIndex !== CurrDOMelIndexi) {
		// 		// 		eli.classList.add("hide");
		// 		// 	}
		// 		// });

		// 	}
		// },

		dropListDropped(e: CdkDragDrop<string[]>) {

			if (e.previousContainer === e.container) { // If in the same container

				let posAdjust = e.previousIndex < e.currentIndex ? 1 : 0; // Adjusts the placement position
				this.dragListCopy.splice(e.currentIndex + posAdjust, 0, ...this.dragList); // put elements in dragListCopy
				this.dragListCopy = this.dragListCopy.filter((el) => (el !== this.dragErase)); // remove the "DragErase" from the list

				// Pass item by item to final list
				for (let i = 0; i < e.container.data.length; i++) {
					e.container.data[i] = this.dragListCopy[i];
				}
			}
			else { // If in different containers

				// remove the "DragErase" from the list
				this.dragListCopy = this.dragListCopy.filter((el) => (el !== this.dragErase));

				// Pass item by item to initial list
				for (let i = 0; i < e.previousContainer.data.length; i++) {
					e.previousContainer.data[i] = this.dragListCopy[i];
				}
				for (let i = 0; i < this.dragList.length; i++) {
					e.previousContainer.data.pop();
				}

				let otherListCopy = [...e.container.data]; // list of new container
				otherListCopy.splice(e.currentIndex, 0, ...this.dragList); // put elements in otherListCopy

				// Pass item by item to final list
				for (let i = 0; i < otherListCopy.length; i++) {
					e.container.data[i] = otherListCopy[i];
				}
			}

			// Remove ".hide"
			let allHidden = document.querySelectorAll(".hide");
			allHidden.forEach((el) => {
				el.classList.remove("hide");
			});
			// Remove ".selected" after 300ms
			setTimeout(() => {
				let allSelected = document.querySelectorAll(".selected");
				allSelected.forEach((el) => {
					el.classList.remove("selected", "last");
				});
			}, 300);

			this.dragListCopy = []; // reset the dragListCopy
			this.dragList = []; // reset the dragList
		},
	}
	/* END TWO OBJECTS */

	changeOrderTimer = null;
	onChangeOrderInternal(e) {

		//HACK: Sortable call onChange on drag event
		if (this.changeOrderTimer)
			clearTimeout(this.changeOrderTimer);

		this.changeOrderTimer = setTimeout(() => {

			this.items = e.container.data;
			this.onChange(e.container.data);

			if (this.onChangeOrder)
				this.onChangeOrder.emit(e.container.data);
			if (this.changeOrderTimer) clearTimeout(this.changeOrderTimer);
		}, 500);

	}
}


