import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MapMarker } from '@angular/google-maps';

@Component({
  selector: 'obc-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  @ViewChild('#map') map: google.maps.Map;
  zoom = 14;
  marker: google.maps.Marker;
  markerOptions: google.maps.MarkerOptions;
  _center: google.maps.LatLngLiteral = { lat: -37.807406, lng: 145.028364 };

  @Input() markerTitle: string = '';

  _siteRadius = 0
  @Input() set siteRadius(radius: number) {
    this._siteRadius = radius;
    this.setCircle();
  };

  @Input() set location(cordinate: { lat: any, lng: any }) {
    if (!cordinate || !cordinate.lat || !cordinate.lng || isNaN(cordinate.lat) || isNaN(cordinate.lng) ||
      (cordinate.lat === this._center.lat && cordinate.lng === this._center.lng))
      return;

    let cordinate_number = { lat: +cordinate.lat, lng: +cordinate.lng }
    this.setPosition(cordinate_number)
  }


  @Output() locationChange: EventEmitter<google.maps.LatLngLiteral> = new EventEmitter();

  _readOnly: boolean = false;
  @Input() set readonly(_readOnly: boolean) {
    this._readOnly = _readOnly;
    this.options.draggable = !_readOnly;
    this.options.scrollwheel = !_readOnly;
    if (this.markerOptions)
      this.markerOptions.draggable = !_readOnly;
  }

  options: google.maps.MapOptions = {
    draggable: true,
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    minZoom: 7,
    center: this._center
  }

  ngOnInit() {
    this.setCurrentLocation();
  }

  setPosition(cordinate: google.maps.LatLngLiteral) {
    this._center = { lat: cordinate.lat, lng: cordinate.lng };
    this.setMarker();
    this.setCircle();
    this.locationChange.emit(cordinate);
  }

  setCurrentLocation() {
    if (!location) {
      navigator.geolocation.getCurrentPosition(position => {
        console.log('pos : ', position, ' a :', position.coords.longitude, ' -- ', position.coords.latitude);
        if (!location) {
          this.setPosition({ lat: position.coords.latitude, lng: position.coords.longitude });
        }
      }, error => { this.setMarker(); console.log('error ', error) });
    }
  }

  circle: google.maps.Circle;
  circleOption: google.maps.CircleOptions;
  setMarker() {
    this.markerOptions = {
      position: new google.maps.LatLng(this._center.lat, this._center.lng),
      label: {
        text: this.markerTitle,
        color: 'black',
        fontWeight: 'bold'
      },
      title: this.markerTitle,
      draggable: !this._readOnly,
      animation: google.maps.Animation.DROP,
      icon: {
        url: "./assets/img/obc_logo_90px.png",
        scaledSize: new google.maps.Size(50, 50),
      }
    };

    this.marker = new google.maps.Marker(this.markerOptions);
  }

  setCircle() {
    this.circleOption = {
      center: this._center,
      draggable: this._readOnly,
      editable: this._readOnly,
      fillColor: '#A00',
      fillOpacity: 0.5,
      map: this.map,
      radius: this._siteRadius,
      strokeColor: '#733',
      strokeOpacity: 0.3,
      strokeWeight: 1,
      visible: true,
    }

    this.circle = new google.maps.Circle(this.circleOption);
    //this.circle.bindTo('center', this.marker);
  }


  click(event: any) {
    if (this._readOnly === true) return;
    this.setPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() });
  }

  markerDragEnd(event: any) {
    if (this._readOnly === true) return;
    this.setPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() });
  }

  zoomIn() {
    if (this.zoom < this.options.maxZoom) this.zoom++
  }

  zoomOut() {
    if (this.zoom > this.options.minZoom) this.zoom--
  }

}
