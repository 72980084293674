<div class="card">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="card-body" *ngIf="config">
        <div class="alert alert-danger" *ngIf="errorMessage">
            {{errorMessage}}
        </div>
        <div class="row">
            <div class="form-group col-md-12 col-sm-12 d-flex flex-column justify-content-between">
                <label>Enable HandsHQ Integration</label>
                <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [(ngModel)]="config.isHandsHQEnabled"
                    (switchChanged)="inactiveHandsHQ()">
                </obc-switch>
            </div>
        </div>
        <div *ngIf="config.isHandsHQEnabled">
            <div class="row mt-4">
                <div class="col-md-12 col-sm-12">
                    <div *ngIf="config.handsHQCompanyName">
                        <label>Company Name:</label>
                        <strong class="ml-1">{{config.handsHQCompanyName}}</strong>
                    </div>
                    <div *ngIf="config.handsHQCompanyType">
                        <label>Company Type:</label>
                        <strong class="ml-1">{{config.handsHQCompanyType}}</strong>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12">
                    <header class="card-header d-flex  justify-content-end">
                        <button class="btn btn-sm btn-success mr-1" (click)="onAddCompanyApiToken(addNewCompany)">
                            <i class="fas fa-plus mr-1"></i> Add Configuration
                        </button>
                    </header>
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <td>HandsHQ API Token</td>
                                    <td>Supplier</td>
                                    <td>Approval Mode</td>
                                    <td>Signoff Mode</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container>
                                    <tr *ngFor="let item of config.allConfigurations; let i=index">
                                        <td>
                                            {{item.handsHQApiToken | tokenize}}
                                        </td>
                                        <td>
                                            {{item.supplierName}}
                                        </td>
                                        <td>
                                            {{item.handsHQSupplierDocumentApprovalMode |
                                            handsHQSupplierDocumentApprovalMode}}
                                        </td>
                                        <td>
                                            {{item.handsHQDocumentSignOffMode | optionalMandatory}}
                                        </td>
                                        <td>
                                            <div class="d-flex">
                                                <button [tooltip]="'Remove'" class="btn btn-sm btn-danger mr-1"
                                                    (click)="onRemoveCompanyApiToken(i)"><i
                                                        class="fas fa-trash-alt"></i></button>
                                                <!-- <button class="btn btn-primary btn-sm pull-right"
                                                    (click)="onEditCompanyApiToken(addNewCompany, item)">
                                                    <i class="fas fa-edit"></i>
                                                </button> -->
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #addNewCompany>
            <obc-loader [show]="inProgress"></obc-loader>
            <div class="card">
                <div class="card-header">
                    <strong>Add Configuration</strong>
                    <button type="button" class="close" (click)="bsModalHandle.hide();">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="card-body" [formGroup]="formGroup">
                    <div class="row">
                        <div class="form-group col-md-12 col-sm-12">
                            <label>HandsHQ API Token</label>
                            <input type="text" class="form-control" formControlName="handsHQApiToken">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-12 col-sm-12">
                            <label>Supplier</label>
                            <select type="text" class="form-control" formControlName="handsHQSupplierId"
                                (change)="onCompanySupplierChange($event)">
                                <option *ngFor="let c of companySuppliers" value="{{c.id}}">
                                    {{c.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-lg-12 col-md-12">
                            <label>Approval Mode</label>
                            <select required class="form-control" formControlName="handsHQSupplierDocumentApprovalMode">
                                <option *ngFor="let item of HandsHQSupplierDocumentApprovalMode| enumToArray "
                                    [ngValue]="HandsHQSupplierDocumentApprovalMode[item]">
                                    {{HandsHQSupplierDocumentApprovalMode[item] |
                                    handsHQSupplierDocumentApprovalMode}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-12 col-sm-12">
                            <label>Signoff Mode</label>
                            <select required class="form-control" formControlName="handsHQDocumentSignOffMode">
                                <option *ngFor="let item of OptionalMandatoryState| enumToArray "
                                    [ngValue]="OptionalMandatoryState[item]">
                                    {{OptionalMandatoryState[item] | optionalMandatory}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <button type="button" class="btn btn-primary pull-right pr-3 mt-3 col-md-12"
                        [disabled]="!formGroup.valid" (click)="validateAndSaveHandsHQConfig()">Validate and
                        Save</button>
                </div>
            </div>
        </ng-template>
    </div>
</div>