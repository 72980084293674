import { PortalDisplayMode } from "./portal-display-mode.enum";

export const Constants = {
  SELF_ATTENDANCE_TITLE: "Self",
  AUTO_ATTENDANCE_TITLE: "Auto",
  HOST_ATTENDANCE_TITLE: "Host",
  ANONYMOUS_ATTENDANCE_TITLE: "Camera QR",
  KIOSK_ATTENDANCE_TITLE: "Kiosk",
  TEXT_IS_ANYTHING_CONDITION_VALUE: '**/**',
  WORKER_PROFILE_REPORT_PREFIX: "wp_",
}

export const MaxFileSize = 100;
export const InlineRichTextMaxFileSizeInMegaByte = 10;
export const DefaultWorkflowDisplayMode = PortalDisplayMode.PopUp;

