import { Component, Input, OnInit } from '@angular/core';
import { QuestionProfileSubTypeViewModel } from 'src/app/models/question-profile-sub-type-viewmodel';

@Component({
  selector: 'obc-question-profile',
  templateUrl: './question-profile.component.html',
  styleUrls: ['./question-profile.component.scss']
})
export class QuestionProfileComponent implements OnInit {

  questionProfileSubType: QuestionProfileSubTypeViewModel;

  _questionProfileSubTypes: QuestionProfileSubTypeViewModel[] = []
  @Input() set questionProfileSubTypes(subTypes) {
    this._questionProfileSubTypes = subTypes;
    this.setInitialQuestionProfileSubType();
  };
  _questionParam: any;
  @Input() set questionParamInput(_questionParamInput: any) {
    if (!_questionParamInput) { return; }
    this._questionParam = _questionParamInput;
    this.setInitialQuestionProfileSubType();
  }

  setInitialQuestionProfileSubType() {
    if (this._questionParam && this._questionProfileSubTypes?.length > 0) {
      if(!this._questionParam['questionProfileSubType']) {
        this.questionProfileSubType = this._questionProfileSubTypes[0];
      } else {
        this.questionProfileSubType = this._questionProfileSubTypes?.find((item) => item.key == this._questionParam['questionProfileSubType']);
        this._questionParam['questionProfileSubType'] = this.questionProfileSubType.key;
      }
    }
  }


  constructor() { }

  ngOnInit(): void {

  }
  valueChanged() {
    this._questionParam['questionProfileSubType'] = this.questionProfileSubType.key;
  }
}

