import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

    set(name: string, value: string, saveInSessionStorage: boolean = false) {
        if (isPlatformBrowser(this.platformId)) {
            if (saveInSessionStorage)
                sessionStorage.setItem(name, value);
            else
                localStorage.setItem(name, value);
        }
    }

    get(name: string, loadFromSessionStorage: boolean = true): string {
        if (isPlatformBrowser(this.platformId)) {
            var value = loadFromSessionStorage ? sessionStorage.getItem(name) : null;
            return value ?? localStorage.getItem(name);
        }
    }

    remove(name: string) {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem(name);
        }
    }

    clearAll() {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.clear();
        }
    }
}
