import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { MyobSystemType } from 'src/app/enums/myob-system-type.enum';
import { MyobPeriodType } from 'src/app/enums/report-type.enum';
import { popup } from 'src/app/helpers/general-functions';
import { MyobCompanyFile, MyobConfiguration } from 'src/app/models/myob/myob-configuration';
import { CompanySettingService } from 'src/app/services/company-setting.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-myob-integration',
  templateUrl: './myob-integration.component.html',
  styleUrls: ['./myob-integration.component.scss']
})
export class MyobIntegrationComponent implements OnInit {
  inProgress: boolean;
  errorMessage: string;
  config: MyobConfiguration = { isMyobEnabled: false, myobSystemType: null };
  showCompletePanel: boolean;
  paymentCategories: any[];
  companyFiles: MyobCompanyFile[];
  ReportTypes = MyobPeriodType;
  MyobSystemType = MyobSystemType;

  editApiSection: boolean = true;
  editMyobSyncConfig: boolean = true;


  _daysOfMonth: any[];
  get daysOfMonth() {
    if (this._daysOfMonth == null) {
      var days = [];
      days.push({
        value: 1,
        lable: "First day of month"
      });
      days.push({
        value: 31,
        lable: "Last day of month"
      });
      for (let index = 1; index < 32; index++) {
        days.push({
          value: index,
          lable: index.toString(),
        });
      }

      this._daysOfMonth = days;
    }
    return this._daysOfMonth;
  }

  daysOfWeek = [
    { value: 0, lable: "Sunday" },
    { value: 1, lable: "Monday" },
    { value: 2, lable: "Tuesday" },
    { value: 3, lable: "Wednesday" },
    { value: 4, lable: "Thursday" },
    { value: 5, lable: "Friday" },
    { value: 6, lable: "Saturday" }
  ];


  constructor(private companySettingService: CompanySettingService, private modalService: ModalService, private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getConfig();
  }

  updateUiVars() {
    if (this.config.myobSystemType == MyobSystemType.AccountRight) {
      this.editApiSection = !(this.config.myobApiKey?.length && this.config.myobApiSecret?.length);
      this.editMyobSyncConfig = this.editApiSection == false && this.config.isReadyToSyncAttendance == false;
    }
    else if (this.config.myobSystemType == MyobSystemType.Advanced) {
      this.editApiSection = false;
      this.editMyobSyncConfig = !(this.config.isReadyToSyncAttendance ?? false);
    }

  }

  getConfig() {
    this.inProgress = true;
    this.companySettingService.getMyobConfiguration()
      .pipe(finalize(() => this.inProgress = false))
      .subscribe((res) => {
        this.config = res;
        this.updateUiVars();
        if (this.config.isValidToCallApi == true && this.editMyobSyncConfig && this.config.myobSystemType == MyobSystemType.AccountRight)
          this.getMyobCompanies();
      });
  }

  //#region AccountRight

  authorizeOnMyobSite() {
    if (!this.config.myobApiKey?.length) {
      this.modalService.error('Enter Client Id!');
      return;
    }
    if (!this.config.myobApiSecret?.length) {
      this.modalService.error('Enter Client Secret!');
      return;
    }
    if (!this.config.redirectUri?.length || !this.config.autorizeUrlToGetAccessCode?.length) {
      this.modalService.error('Invalid my configuration on server! Please contact system administrator!');
      return;
    }
    this.inProgress = true;
    let url = `${this.config.autorizeUrlToGetAccessCode}?client_id=${this.config.myobApiKey}&redirect_uri=${this.config.redirectUri}&response_type=code&scope=CompanyFile`;
    window['myobConfigCompleted'] = () => { this.onMyobConfigSuccess(); };
    popup(url, 'Myob Authorize');
    //window.open(url);
  }

  onMyobConfigSuccess() {
    this.inProgress = true;
    this.companySettingService.getMyobConfiguration()
      .pipe(finalize(() => this.inProgress = false))
      .subscribe((res) => {
        this.config.myobAccessCode = res.myobAccessCode;
        if (this.config.myobAccessCode?.length)
          this.saveMyobAccountRightConfig(() => {
            this.editApiSection = false;
            this.showConfigurationPanel();
            this.ref.detectChanges();
          });
        this.ref.detectChanges();
      });
  }


  getMyobPaymentCategories() {
    this.inProgress = true;
    this.companySettingService.getMyobPaymentCategories()
      .pipe(finalize(() => this.inProgress = false))
      .subscribe((res) => {
        this.paymentCategories = res;
        this.ref.detectChanges();
      });
  }

  getMyobCompanies() {
    this.inProgress = true;
    this.companySettingService.getMyobCompanies()
      .pipe(finalize(() => this.inProgress = false))
      .subscribe((res) => {
        this.companyFiles = res;
        this.ref.detectChanges();
        if (this.config.myobDefaultPayrolCategoryUid)
          this.getMyobPaymentCategories();
      });
  }

  onCompanyFileChanged() {
    this.config.myobCompanyFile = this.companyFiles.find(f => f.id == this.config.myobCompanyFileUid);
    this.saveMyobAccountRightConfig(() => {
      this.getMyobPaymentCategories();
    });
  }

  showConfigurationPanel() {
    this.getMyobCompanies();
    this.editMyobSyncConfig = true;
  }

  saveMyobAccountRightConfig(onDone: Function = null) {
    this.resetMessages();
    this.inProgress = true;
    if (this.config.myobDefaultPayrolCategoryUid)
      this.config.myobDefaultPayrolCategoryName = this.paymentCategories.find(d => d.key == this.config.myobDefaultPayrolCategoryUid)?.value;
    this.companySettingService.saveMyobConfiguration(this.config)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe((newConfig) => {
        this.config = newConfig;
        this.ref.detectChanges();
        if (onDone)
          onDone();
        else {
          this.editMyobSyncConfig = false;
        }
      });
  }

  //#endregion




  saveAdvancedModeConfig() {
    this.resetMessages();
    if (
      !(this.config.myobAdvancedUrl?.length ?? 0) ||
      !(this.config.myobAdvancedUsername?.length ?? 0) ||
      !(this.config.myobAdvancedPassword?.length ?? 0) ||
      !(this.config.myobAdvancedTenant?.length ?? 0)) {
      this.modalService.error('Please fill all configurations!');
      return;
    }
    this.inProgress = true;
    this.companySettingService.validateAndSaveMyobConfiguration(this.config)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        if (res) {
          this.config = res;
          this.editMyobSyncConfig = false;
          this.modalService.success('Myob configuration saved!');
        }
      }, (error) => {
        this.errorMessage = error;
      });
  }
  editMyobAdvancedConfig() {
    this.editMyobSyncConfig = true;
  }

  private resetMessages() {
    this.errorMessage = '';
  }
}
