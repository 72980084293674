<div *ngIf="formGroup">
    <form [formGroup]="formGroup">
        <div class="d-flex flex-column" *ngIf="model">
            <div>
                <i *ngIf="valid" style="color: green;" class="fas fa-check-circle  mr-2"></i>
                <i *ngIf="!valid" style="color: red;" class="fas fa-times-circle  mr-2"></i>
                <obc-question-title-renderer [question]="model.question"></obc-question-title-renderer>
            </div>
            <div class="form-group d-flex justify-content-around align-items-center">
                <obc-input-date-time-picker [formControl]="formControl" [minDate]="minDate" [maxDate]="maxDate"
                    [mode]="mode === 0 ? DatetimePickerMode.DateTimePicker: DatetimePickerMode.Calender ">
                </obc-input-date-time-picker>
                <!-- <input [owlDateTime]="dt1" class="form-control" [formControl]="formControl" [owlDateTimeTrigger]="dt1"
                    [min]="minDate" [max]="maxDate">
                <owl-date-time [pickerType]="mode === 0 ? 'both' : 'calendar'" #dt1></owl-date-time> -->

                <div class="form-group">
                </div>
            </div>
        </div>
    </form>
</div>