<div class="card">
    <div class="card-body" *ngIf="config">
        <obc-loader [show]="inProgress"></obc-loader>
        <div class="alert alert-danger" *ngIf="errorMessage">
            {{errorMessage}}
        </div>
        <div class="row">
            <div class="form-group col-md-12 d-flex flex-column justify-content-between">
                <label>Enable BioStar2 Integration</label>
                <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [(ngModel)]="config.isBioStarEnabled"
                    (switchChanged)="onEnabledChanged()">
                </obc-switch>
            </div>
        </div>

        <ng-container *ngIf="config.isBioStarEnabled">
            <div class="mt-5">
                <ng-container *ngIf="editBiostarSyncConfig">
                    <ng-container>
                        <div class="row">
                            <div class="form-group col-md-4 col-sm-12">
                                <label>Default BioStar2 Server Url</label>
                                <input type="url" class="form-control" [(ngModel)]="config.bioStarApiServerUrl"
                                    placeholder="">
                            </div>
                            <div class=" form-group col-md-4 col-sm-12">
                                <label>Default Username</label>
                                <input type="text" class="form-control" [(ngModel)]="config.bioStarApiUsername">
                            </div>
                            <div class=" form-group col-md-4 col-sm-12">
                                <label>Default Password</label>
                                <input type="password" class="form-control" [(ngModel)]="config.bioStarApiPassword">
                            </div>
                        </div>
                        <!-- <div class="form-group col-md-2 offset-10 col-sm-12 text-right">
                            <button class="btn btn-block btn-success" (click)="saveAdvancedModeConfig()">
                                <i class="fa fa-save"></i>
                                Save
                            </button>
                        </div> -->
                        <div class="form-group col-md-2 offset-10 col-sm-12 text-right">
                            <button class="btn btn-block btn-info" (click)="saveAdvancedModeConfig()">
                                <i class="fa fa-edit"></i>
                                Save
                            </button>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!editBiostarSyncConfig">
                    <div class="sync-info alert alert-info" *ngIf="config?.bioStarLastSyncDateTime">
                        Last Sync:
                        <span class="sync-date">
                            <strong>{{config?.bioStarLastSyncDateTime | datetime}}</strong>
                        </span>
                        <button class="btn btn-primary btn-sm ml-2" (click)="showLogs(logModal)">
                            Show Last Events Logs
                        </button>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-4 col-sm-12">
                            <label>Default BioStar2 Server Url</label>
                            <input readonly type="url" class="form-control" [(ngModel)]="config.bioStarApiServerUrl"
                                placeholder="">
                        </div>
                        <div class=" form-group col-md-4 col-sm-12">
                            <label>Default Username</label>
                            <input readonly type="text" class="form-control" [(ngModel)]="config.bioStarApiUsername">
                        </div>
                        <div class=" form-group col-md-4 col-sm-12">
                            <label>Default Password</label>
                            <input readonly type="password" class="form-control"
                                [(ngModel)]="config.bioStarApiPassword">
                        </div>
                        <div class="form-group col-md-2 offset-10 col-sm-12 text-right">
                            <button class="btn btn-block btn-info" (click)="editBiostarAdvancedConfig()">
                                <i class="fa fa-edit"></i>
                                Edit
                            </button>
                        </div>
                    </div>

                </ng-container>
            </div>
        </ng-container>
    </div>
</div>
<ng-template #logModal>
    <div class="card">
        <div class="card-header">BioStar Logs
            <button class="btn btn-sm pull-right" (click)="closeLogModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="row-md-12">
                    <div *ngIf="inProgress">
                        <obc-loader [show]="inProgress"></obc-loader>
                    </div>
                    <div *ngIf="!inProgress" class="table-responsive">

                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <td>Process Time</td>
                                    <td>Site</td>
                                    <td>User</td>
                                    <td>Process Result</td>
                                    <td>Process Result Message</td>
                                    <td>Related Attendance Id</td>
                                    <td>BioStar Event (Raw)</td>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let e of logs">
                                    <tr>
                                        <td>{{e.registerDateTime | datetime}}</td>
                                        <td>{{e.siteName}}</td>
                                        <td>{{e.userFullName}}
                                            <span *ngIf="e.userMobile">{{e.userMobile}}</span>

                                        </td>
                                        <td>{{e.result}}</td>
                                        <td>
                                            <div class="small">{{e.resultMessage}}</div>
                                        </td>
                                        <td>{{e.siteAttendanceId}}</td>
                                        <td>
                                            <button type="button" class="btn btn-sm btn-info"
                                                (click)="e.showRawEvent=!e.showRawEvent">Show/Hide</button>
                                        </td>
                                    </tr>
                                    <tr *ngIf="e.showRawEvent">
                                        <td colspan="8">
                                            <div class="small" style="border:1px solid red; padding: 10px;">{{
                                                e.bioEvent }}
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>