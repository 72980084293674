import { WHITE_ON_BLACK_CSS_CLASS } from '@angular/cdk/a11y/high-contrast-mode/high-contrast-mode-detector';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'obc-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements OnInit {
  signaturePad: SignaturePad;

  @Output() onSave = new EventEmitter<SignatureData>();

  constructor() { }

  ngOnInit(): void {
    var canvas = document.getElementById('signaturePad') as HTMLCanvasElement;
    this.signaturePad = new SignaturePad(canvas, {
      minWidth: 5,
      maxWidth: 10,
      //backgroundColor: 'white',
      //penColor: "rgb(66, 133, 244)"
    });
    canvas.width = canvas.offsetWidth;
  }

  clearPad() {
    this.signaturePad.clear();
  }
  savePad() {
    if (this.onSave)
      this.onSave.emit({ signatureAsPng: this.signaturePad.toDataURL().split(",")[1], signatureAsSvg: this.signaturePad.toDataURL("image/svg+xml").split(",")[1] });
  }

}

export class SignatureData {
  signatureAsPng: any;
  signatureAsSvg: any;
}