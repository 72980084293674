<div class="row" *ngIf="portalDisplayMode == PortalDisplayModeEnum.Page">
  <div class="col-12 col-lg-8">
    <button class="btn btn-default" (click)="returnTo()">
      <i class="fas fa-arrow-left mr-2"></i>
      Return to previous page
    </button>
  </div>
</div>
<div class="card no-borders mt-1">
  <div class="card-header">
    {{workflow?.displayName}} ( {{workflow?.currentActivity.title}} )
    <div class="card-actions" *ngIf="portalDisplayMode == PortalDisplayModeEnum.PopUp">
      <a (click)="workflowEningeModalHide.emit()" class="card-action card-action-dismiss" data-card-dismiss=""></a>
    </div>
  </div>
  <div class="card-body" style="min-height:200px">
    <div class="list-group list-group-item">
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
        <div class="col" *ngFor="let d of workflow?.variables">
          {{d.title}}: <strong>{{d.value}}</strong>
        </div>
      </div>
    </div>
    <div id="step-page-container" class="mt-2">
      <obc-loader [show]="inProgress"></obc-loader>
      <ng-template componentLoader></ng-template>
    </div>
  </div>
  <div class="card-body mt-2" *ngIf="!hasError">
    <div>
      <obc-workflow-form-renderer *ngIf="workflow?.hasForm == false"
        [(workflowModel)]="workflow"></obc-workflow-form-renderer>
    </div>
    <div>
      <textarea class="form-control mt-2" style="width: 100%" rows="2" [(ngModel)]="workflow?.currentActivity.note"
        placeholder="Comment"></textarea>
    </div>
    <div class="d-flex justify-content-end mt-2">
      <button type="button" [class]="'btn mr-2 '+ command.cssClass"
        *ngFor="let command of workflow?.currentActivity.actionList; let i =index;" (click)="commandFired(command)">
        {{ command.text }}
      </button>
    </div>
  </div>
</div>