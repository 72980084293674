import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { AccessControlUnitEvacuationStatusModel } from '../models/access-control-unit-evacuation-status-model';
import { AccessControlUnitOpenCloseEvacuationModel } from '../models/access-control-unit-open-close-model';
import { AccessControlUnitGateStatusViewModel } from '../models/access-unit-control-gate-status-view-model';
import { ErrorService } from './error.service';
import { CommandParamModel, BackhaulResponse } from '../models/manage_gates_viewmodel';

@Injectable({
  providedIn: 'root'
})
export class AccessControlUnitService {

  baseUrl = '/api/accessControlUnit';
  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  public openGate(model: AccessControlUnitOpenCloseEvacuationModel) {
    return this._http.post<boolean>(`${this.baseUrl}/open-gate`, model).pipe(catchError(this._errorService.handleError));
  }

  public getEvacMode(model: AccessControlUnitEvacuationStatusModel) {
    return this._http.post<AccessControlUnitGateStatusViewModel>(`${this.baseUrl}/get-evac-mode`, model).pipe(catchError(this._errorService.handleError));
  }
  public setEvacMode(model: AccessControlUnitOpenCloseEvacuationModel) {
    return this._http.post<boolean>(`${this.baseUrl}/set-evac-mode`, model).pipe(catchError(this._errorService.handleError));
  }
  public GetClientLogs(serial: string) {
    return this._http
      .get<boolean>(`${this.baseUrl}/get-client-logs?serial=${serial}`)
      .pipe(catchError(this._errorService.handleError));
  }
  public toggle(model: CommandParamModel) {
    return this._http
      .post<boolean>(`${this.baseUrl}/toggle`, model)
      .pipe(catchError(this._errorService.handleError));
  }
}
