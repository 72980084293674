import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import {
  DateComparisonMode,
  DateQuestionLimit,
  DateQuestionLimitValueMode
} from 'src/app/models/questionParameters/date-question-parameter';

@Component({
  selector: 'obc-date-question-limit',
  templateUrl: './date-question-limit.component.html',
  styleUrls: ['./date-question-limit.component.scss']
})
export class DateQuestionLimitComponent implements OnInit {
  key: string;
  @Input() limit: DateQuestionLimit;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  dateFormControl: FormControl;

  DateQuestionLimitValueMode = DateQuestionLimitValueMode;
  DateComparisonMode = DateComparisonMode;
  DatetimePickerMode = DatetimePickerMode;


  constructor() { }

  ngOnInit(): void {
    this.dateFormControl = new FormControl(this.limit?.compareValue);
    this.dateFormControl.valueChanges.subscribe((res) => {
        this.limit.compareValue = res;
        this.change.emit();
    })
    this.key = 'limit_' + Math.random();
  }

}
