import { UserService } from 'src/app/services/user.service';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { OptionalMandatoryState } from "../../enums/site-document-state.enum";
import { SiteSupplierDocumentTypeViewModel } from "../../models/supplier-document/site-supplier-document-type-view-model.model";
import { SiteSupplierViewModel } from 'src/app/models/supplier-document/site-supplier-view-model.model';
import { finalize } from "rxjs/operators";
import { SupplierDocumentService } from "../../services/supplier-document.service";
import { ModalService } from "../../services/modal.service";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SiteActiveStatus } from 'src/app/enums/site-active-status.enum';
import { LayoutService } from "../../services/layout.service";
import { ApproveDeclineSiteSupplierDocumentVersion } from "../../models/supplier-document/site-supplier-document-type-models";
import { SiteSupplierDocumentStatus } from 'src/app/enums/site-supplier-document-status';
import { BehaviorSubject } from 'rxjs';
import { SupplierFormService } from "../../services/supplier-form.service";
import { FormType } from "../../enums/form-type";
import { FormViewModel } from "../../models/form/form-view-model";
import { AddSupplierFormModel } from "../../models/supplier-form/add-supplier-form-model";
import { SupplierFormViewModel, SupplierViewModel } from "../../models/supplier-form/supplier-forms-view-mode";
import { FormDataStatus } from "../../enums/form-data-status";
import { SendSupplierFormsRequestModel } from "../../models/email-content/site-supplier-hash-link-email-view-model";
import { FullFilledFormInfoViewModel } from 'src/app/models/filled-form-info-view-model';

@Component({
  selector: 'obc-supplier-forms',
  templateUrl: './supplier-forms.component.html',
  styleUrls: ['./supplier-forms.component.scss'],
})
export class SupplierFormsComponent implements OnInit {
  inProgress: boolean = false;
  // if site is global
  formMode: boolean = false;
  formType = FormType;

  selectedSupplier: SupplierViewModel;
  selectedFormIdToViewHistory: number;
  selectedFormIds: number[];
  formDataStatus = FormDataStatus;
  selectedForm: SupplierFormViewModel;

  SiteSupplierDocumentStatus = SiteSupplierDocumentStatus;
  pageYOffset: number;
  SelectedSiteSupplierForSupplierLinkOperations: SiteSupplierViewModel;
  selectedAccordionItemId: number;

  @HostListener('window:scroll', ['$event']) onScroll(_) {
    this.pageYOffset = window.pageYOffset;
  }

  selectedSiteSupplierDocument: SiteSupplierDocumentTypeViewModel;
  SiteActiveStatus = SiteActiveStatus;
  newForm: boolean = false;
  displayForm: boolean = false;
  formId: number;
  selectedSupplierId: number;
  selectedSiteId: number;

  UserPermission = UserPermission;
  supplierFormControl = new FormControl();

  sampleMandatorySiteDocumentState: OptionalMandatoryState =
    OptionalMandatoryState.Mandatory;
  renameNoneStandardSupplierDocumentTypeFormControl = new FormControl(null, [
    Validators.required,
  ]);
  allSuppliers: SupplierViewModel[] = [];
  hasSupplierReviewForm: boolean;
  selectedSuppliers: number[] = [];
  selectedSupplierIds: number[] = [];

  firstEmail = false;
  email = new FormControl();
  emailSubject = new FormControl();
  emailBody = new FormControl();

  selectedFormDataId: number;
  modalReference: BsModalRef;
  bsModalRef: BsModalRef;

  @ViewChild('supplierSelector') supplierSelector;
  @ViewChild('supplierDocumentSelector') supplierDocumentSelector;

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private supplierDocumentService: SupplierDocumentService,
    private supplierFormService: SupplierFormService,
    private layoutService: LayoutService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.layoutService.header = 'Company Forms';
    this.supplierFormControl.valueChanges.subscribe((_) => {
      this._calSupplierForms();
    });
    this.route.params.subscribe((params: Params) => {
      const supplierId = params['supplierId'] ? +params['supplierId'] : null;
      if (params['formId']) {
        this.formMode = true;
        this.changeFormId(+params['formId']);
      }

      if (supplierId && supplierId > 0) {
        this.supplierFormControl.setValue(supplierId);
      }
    });
    //To handle backdrop-click
    // this.bsModalService.onHide.subscribe((_) => {
    //   this.refresh();
    // });
  }

  onSelectForm(formViewModel: FormViewModel) {
    this.formId = formViewModel.id;
  }

  changeFormId(value) {
    if (this.formId != value) {
      this.formId = value;
      this._calSupplierForms();
    }
  }

  forceToResetValues = new BehaviorSubject<boolean>(true);

  onAddForm() {
    let formId = this.formId;
    let supplierId = this.supplierFormControl.value;

    if (!(formId && supplierId)) {
      this._clearForm();
      return;
    }

    let dataToServer = {
      formId: formId,
      supplierId: supplierId,
    } as AddSupplierFormModel;

    let foundItem = this.selectedSupplier.forms.find(
      (item) => item?.formId == formId
    );
    if (!foundItem) {
      let result = this.supplierFormService.addNewForm(dataToServer);
      result.subscribe(
        (_) => {
          this._calSupplierForms();
        },
        (err) => {
          this.modalService.error(err, 'Unable to add');
        }
      );
    }

    this._clearForm();
  }

  onRemoveForm(item: SupplierFormViewModel) {
    this.modalService
      .confirm('Are You Sure?', `Delete ${item.formName}`)
      .subscribe((result) => {
        if (result) {
          let result = this.supplierFormService.removeForm({
            supplierId: this.selectedSupplier.supplierId,
            formId: item.formId,
          } as AddSupplierFormModel);
          result.subscribe(
            (_) => {
              this._calSupplierForms();
            },
            (err) => {
              this.modalService.error(err, 'Unable to delete this item');
            }
          );
        }
      });
  }

  currentLink = null;
  refViewHashLink: any;

  _clearForm() {
    this.displayForm = false;
    this.newForm = false;
    if (this.formMode) this.supplierFormControl.setValue(null);
    else this.formId = null;

    this.modalReference?.hide();
  }

  _openNewForm(template) {
    this.selectedFormIds =
      this.selectedSupplier?.forms?.map((item) => item.formId) ?? [];
    this.modalReference = this.bsModalService.show(template);
  }

  _loadItem(item: SupplierViewModel) {
    if (this.formMode) {
      this.supplierFormControl.setValue(item.supplierId);
    }

    this.selectedSupplier = item;
    this.scrollToTop();
  }

  _calSupplierForms() {
    this.inProgress = true;

    this.supplierFormService
      .getFormList(this.supplierFormControl?.value)
      .pipe(finalize(() => { }))
      .subscribe(
        (res) => {
          this.inProgress = false;
          if (res != null && res) {
            this.selectedSupplier = res;
            this._clearForm();
          } else {
            this.allSuppliers = [];
            this.hasSupplierReviewForm = false;
            this._clearForm();
          }
        },
        (err) => {
          this.inProgress = false;
          this.modalService.error(err, 'Unable to get information');
        },
        () => {
          this.inProgress = false;
        }
      );
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  onViewHashLink(item: SupplierViewModel, template: any) {
    this.currentLink =
      window.location.protocol +
      '//' +
      window.location.host +
      '/anonymous-manage-supplier-forms/' +
      item.hashLink;
    this.email.setValue(item.supplierEmailAddress);
    this.emailBody.setValue(item.supplierEmailBody);
    this.emailSubject.setValue(item.supplierEmailSubject);
    this.refViewHashLink = this.bsModalService.show(template, { class: 'modal-lg' });
    this.firstEmail = item.lastEmailAddress?.length > 0;
  }

  onSendEmail() {
    if (!this.selectedSupplier?.supplierId) return;
    if (!this.email?.value) {
      this.modalService.error('Please enter supplier email address!');
      return;
    }
    if(this.emailBody?.value.length > this.userService.editorMaxLength.value)
    {
      this.modalService.error('Text field length exceeded the maximum limit. Please reduce the length of your input and try again.');
      return;
    }
    this.supplierFormService
      .sendEmail({
        toEmailAddress: this.email?.value,
        supplierLinkBody: this.emailBody?.value,
        supplierLinkSubject: this.emailSubject?.value,
        supplierId: this.selectedSupplier?.supplierId,
      } as SendSupplierFormsRequestModel)
      .subscribe(
        (_) => {
          this._calSupplierForms();
          this.refViewHashLink.hide();
        },
        (err) => {
          this.modalService.error(err, 'Unable to send email');
        }
      );
  }

  onApproveDeclineSiteSupplierVersion(
    isApprove: boolean,
    documentType: SiteSupplierDocumentTypeViewModel
  ) {
    if (!documentType) return;
    this.supplierDocumentService
      .approveDeclineSiteSupplierVersion({
        isApproved: isApprove,
        siteSupplierDocumentVersionId: documentType.lastSupplierVersionId,
      } as ApproveDeclineSiteSupplierDocumentVersion)
      .subscribe(
        (_) => {
          this._calSupplierForms();
        },
        (err) => {
          this.modalService.error(err, 'Unable to change status');
        }
      );
  }

  isInViewMode(item: SiteSupplierViewModel) {
    return !(
      item &&
      this.formId == item.siteId &&
      this.supplierFormControl.value == item.supplierId
    );
  }

  isPending(item: SiteSupplierDocumentTypeViewModel) {
    return item.status == SiteSupplierDocumentStatus.Pending;
  }

  isDocumentTypeIsVisible(
    index,
    docType: SiteSupplierDocumentTypeViewModel,
    siteSupplier: SiteSupplierViewModel
  ) {
    return (
      docType.status == SiteSupplierDocumentStatus.Pending ||
      index < 5 ||
      (siteSupplier != null && siteSupplier._showAll)
    );
  }

  selectedSiteSupplierForRename: SiteSupplierViewModel = null;
  selectedSiteSupplierDocumentTypeForRename: SiteSupplierDocumentTypeViewModel =
    null;

  updateNoneStandardSupplierDocumentType() {
    const model = {
      title: this.renameNoneStandardSupplierDocumentTypeFormControl.value,
      siteSupplierId: this.selectedSiteSupplierForRename.siteSupplierId,
      siteSupplierDocumentTypeId:
        this.selectedSiteSupplierDocumentTypeForRename.id,
    };

    this.supplierDocumentService
      .renameSiteSupplierDocumentType(model)
      .subscribe(
        (_) => {
          this.modalReference.hide();
          this._calSupplierForms();
        },
        (err) => {
          this.modalService.error(err);
        }
      );
  }

  refresh() {
    this._calSupplierForms();
    this.bsModalRef?.hide();
  }


  displayHistory(form: SupplierFormViewModel, template: any) {
    this.selectedFormIdToViewHistory = form.formId;
    this.modalReference = this.bsModalService.show(template, {
      class: 'modal-lg',
      ignoreBackdropClick: false,
    });
  }

  onViewForm(template, formData: FullFilledFormInfoViewModel) {
    this.selectedFormDataId = formData.formDataId;
    this.modalReference = this.bsModalService.show(template, {
      class: 'modal-lg',
    });
  }

  closeFormDetailsModal() {
    this.modalReference.hide();
  }

}
