import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from './error.service';
import { PermissionModeEnum } from "../enums/permission-mode.enum";
import { PermissionModeRequestModel } from "../models/permission-mode-request.model";
import { AddCompanyRoleModel } from "../models/add-company-role.model";
import { CompanyRoleViewModel } from "../models/company-role.view.model";
import { DeleteCompanyRoleModel } from "../models/delete-company-role.model";
import { UpdateCompanyRoleModel } from "../models/update-company-role.model";
import { UpdateRolePermissionsModel } from "../models/update-role-permissions.model";

@Injectable({
  providedIn: 'root'
})
export class CompanyRoleService {


  apiUrl = '/api/CompanyRole';
  constructor(private http: HttpClient, private _errorService: ErrorService) { }

  getCompanyPermissionMode(): Observable<PermissionModeEnum> {
    return this.http.get(`${this.apiUrl}/get-company-permissionmode`)
      .pipe(catchError(this._errorService.handleError));
  }
  setCompanyRoles(model: PermissionModeRequestModel): Observable<PermissionModeEnum> {
    return this.http.post(`${this.apiUrl}/set-company-permissionmode`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  addCompanyRole(model: AddCompanyRoleModel): Observable<any> {
    return this.http.post(`${this.apiUrl}/add-company-role`, model)
      .pipe(catchError(this._errorService.handleError));
  }
  updateCompanyRole(model: UpdateCompanyRoleModel): Observable<any> {
    return this.http.post(`${this.apiUrl}/update-company-role`, model)
      .pipe(catchError(this._errorService.handleError));
  }
  getCompanyRoles(): Observable<CompanyRoleViewModel[]> {
    return this.http.get<CompanyRoleViewModel[]>(`${this.apiUrl}/get-company-role-list`)
      .pipe(catchError(this._errorService.handleError));
  }

  getCompanyRolesByCompanyId(companyId: number): Observable<CompanyRoleViewModel[]> {
    return this.http.get<CompanyRoleViewModel[]>(`${this.apiUrl}/get-company-role-list/${companyId}`)
      .pipe(catchError(this._errorService.handleError));
  }
  deleteCompanyRole(model: DeleteCompanyRoleModel): Observable<string|boolean> {
    return this.http.post<string|boolean>(`${this.apiUrl}/delete-role`, model)
      .pipe(catchError(this._errorService.handleError));
  }
  updateRolePermissions(model: UpdateRolePermissionsModel): Observable<any> {
    return this.http.post(`${this.apiUrl}/update-role-permission`, model)
      .pipe(catchError(this._errorService.handleError));
  }
}
