<div class="row" *ngIf="showPanel">
  <obc-loader [show]="isLoading"></obc-loader>
  <div *ngIf='errorMessage' class='alert alert-danger col-12'>
    {{errorMessage}}
  </div>
  <div class="col-12">
    <tabset *ngIf="this._userId && this.errorMessage === null" #tabset>
      <tab [active]="this.isModalTab" *ngIf="isModalTab" (selectTab)="showTab[0]=1" heading="User Profile" id="tab01">
        <obc-user-information *ngIf="showTab[0]" [isDisputable]="false" [userInformationFields]="cleanedFields"
          [userInformation]="profile"></obc-user-information>
      </tab>
      <tab (selectTab)="showTab[1]=1" heading="Check In History" id="tab02">
        <obc-site-activity-report [selectedUserId]="this._userId" [isReportMode]="true" *ngIf="showTab[1]"
          [siteIdList]="calculatedSelectedSites">
        </obc-site-activity-report>
      </tab>
      <tab (selectTab)="showTab[2]=1" heading="Inductions" id="tab03">
        <obc-induction-report [selectedUserId]="this._userId" [isReportMode]="true" *ngIf="showTab[2]"
          [siteIdList]="calculatedSelectedSites">
        </obc-induction-report>
      </tab>
      <tab (selectTab)="showTab[3]=1" heading="Supplier Document SignOff" id="tab04">
        <obc-supplier-document-signoff-report [selectedUserId]="this._userId" [isReportMode]="true"
          [siteIdList]="calculatedSelectedSites" *ngIf="showTab[3]">
        </obc-supplier-document-signoff-report>
      </tab>
      <tab (selectTab)="showTab[4]=1" heading="Site Briefing SignOff" id="tab05">
        <obc-site-briefing-signoff [selectedUserId]="this._userId" [isReportMode]="true" *ngIf="showTab[4]"
          [siteIdList]="calculatedSelectedSites">
        </obc-site-briefing-signoff>
      </tab>
      <tab (selectTab)="showTab[5]=1" heading="Cards" id="tab06">
        <obc-worker-profile-card-report [selectedUserId]="this._userId" [isReportMode]="true" *ngIf="showTab[5]"
          [siteId]="this.siteId">
        </obc-worker-profile-card-report>
      </tab>
      <tab (selectTab)="showTab[6]=1" heading="History" id="tab07">
        <obc-worker-profile-history [selectedUserId]="this._userId" [isReportMode]="true" *ngIf="showTab[6]"
          [siteId]="this.siteId">
        </obc-worker-profile-history>
      </tab>
    </tabset>
  </div>
</div>