import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-user-detail-card',
  templateUrl: './user-detail-card.component.html'
})
export class UserDetailCardComponent implements OnInit {

  constructor(
    public modalService: ModalService
  ) { }

  @Input() userId: string | undefined;

  ngOnInit(): void {
  }

}
