import { Pipe, PipeTransform } from '@angular/core';
import { SiteAnnouncementForceViewMode } from '../enums/site-announcement-force-view-mode';

@Pipe({
  name: 'siteAnnouncementForceViewMode'
})
export class SiteAnnouncementForceViewModePipe implements PipeTransform {

  transform(value: unknown): string {
    if (value === SiteAnnouncementForceViewMode.Disable) return 'Disable';
    if (value === SiteAnnouncementForceViewMode.MandatoryView) return 'Mandatory View';
    if (value === SiteAnnouncementForceViewMode.MandatoryViewAndSignOff) return 'Mandatory View And SignOff';
    if (value === SiteAnnouncementForceViewMode.SetPlaythroughToMandatory) return 'Set playthrough to mandatory';
    return value?.toString();
  }

}
