import { Component, OnInit, Input } from '@angular/core';
import { ChartModel } from 'src/app/models/chart-model';
import { ChartType } from 'src/app/enums/chart-type';

@Component({
  selector: 'obc-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {

  chartType = ChartType;
  _chartModel: ChartModel;
  @Input() set chartModel(_chart: ChartModel) {
    if (!_chart) return;
    this._chartModel = _chart;
  }

  constructor() { }

  ngOnInit(): void {
  }

}


