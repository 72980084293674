<div class="form-group row">
    <label class="col-sm-4 control-label text-left pt-">Is Required ?</label>
    <div class="col-sm-8 pull-left">
        <obc-descriptor
            [text]="'Do you require an answer to this question for an individual to check in successfully?'">
        </obc-descriptor>
        <obc-switch [value]="_questionParam['isRequired']" (valueChanged)="_questionParam['isRequired'] = $event">
        </obc-switch>
    </div>
</div>
<div class="form-group row">
    <label class="col-sm-4 control-label text-left pt-2">Display Mode : </label>
    <div class="col-sm-8">
        <select required class="form-control" name="renderMode" #drp="ngModel"
            [(ngModel)]="_questionParam['renderMode']">
            <option *ngFor="let item of singleSelectRenderMode|enumToArray " [ngValue]="singleSelectRenderMode[item]">
                {{item}}
            </option>
        </select>
        <div *ngIf="drp.invalid && (drp.dirty || drp.touched)" class="help-block with-errors">
            Please select a Render type
        </div>
    </div>
</div>
<obc-question-items-renderer [items]="_inputItems"
                             [singleItemRenderMode]="_questionParam['renderMode']"
    (onItemsUpdated)="onItemsUpdated($event)"></obc-question-items-renderer>
