import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import {SiteManagementMode} from "../../enums/site-management-mode";
import {finalize} from "rxjs/operators";

@Component({
    selector: 'obc-pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {

    @Input() fitToPage: boolean = false;
    @Input() src: string;
    @Input() renderText: boolean = true;
    @Input() zoom: number = 100;
    @Input() rotation: number = 0;
    loading: boolean = true;
    displayIframe: boolean = true;

    constructor(private route: ActivatedRoute,) {
    }

    ngOnInit(): void {
        this.route.params.subscribe((param: Params) => {
            if(param['src']) {
                this.src = param['src'];
                this.displayIframe = false;
            }
        });
    }

    hideLoader() {
        this.loading = false;
    }
    
    getSrc(src) {
        return encodeURIComponent(src);
    }
}
