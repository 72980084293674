export enum ProductPlanFeature {
  Export = 0,
  RequiredDocumentField = 1,
  SchaduleReport = 2,
  Announcements = 3,
  Scannables = 4,
  VisitorTypes = 5,
  SiteHosts = 6,
  Automation = 7,
  ViewKioskMode = 8,
  IntegrationApi = 9,
  PreCheckIn = 10,
  Inventory = 11,
  Form = 12,
  AccessControl = 13,
  SiteBriefing = 14,
  EarlyAccess = 15,
  SiteBriefingAutoRenew = 16,
}
