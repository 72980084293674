import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { deepCloneJson } from "../../helpers/general-functions";
import { AnnouncementViewModel, ClientAnnouncementFilterViewModel } from "../../models/announcement-viewmodel";
import { uuid4 } from "../../helpers/uuid";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SiteActiveStatus } from "../../enums/site-active-status.enum";
import { finalize } from "rxjs/operators";
import { AnnouncementService } from "../../services/announcement.service";

@Component({
  selector: 'obc-announcement-filter',
  templateUrl: './announcement-filter.component.html',
  styleUrls: ['./announcement-filter.component.scss']
})
export class AnnouncementFilterComponent implements OnInit {

  @Input() set filters(value: ClientAnnouncementFilterViewModel[]) {
    this.filterAnnouncements = [];
    if (value && value?.length)
      this.filterAnnouncements.push(...value);
  }
  @Output() public onClose = new EventEmitter<ClientAnnouncementFilterViewModel[]>();

  clonedFilterAnnouncements: ClientAnnouncementFilterViewModel[] = [];
  filterAnnouncements: ClientAnnouncementFilterViewModel[] = [];
  clonedSelectedAnnouncement: AnnouncementViewModel = null;
  announcements: AnnouncementViewModel[];

  constructor(private bsModalService: BsModalService,
              private announcementService: AnnouncementService,
              ) { }

  ngOnInit(): void {
    this.announcementService.getAll(SiteActiveStatus.All)
      .pipe(finalize(() => {
        this.filteredAnnouncementList();
      }))
      .subscribe({
        next: (res) => {
          this.announcements = res;
        }
      });
  }

  modalRef: BsModalRef;
  openAnswersFilterModal(template) {
    this.setOriginalToCloneAnnouncementAndDoSearch();
    this.modalRef = this.bsModalService.show(template, {
      ignoreBackdropClick: true,
      class: "modal-lg",
    });
    this.modalRef?.onHide.subscribe((_) => {
      this.closeModal();
    })
  }

  filteredAnnouncementList(): AnnouncementViewModel[] {
    if (!this.announcements) return [];
    if (!this.filterAnnouncements) return this.announcements;
    return this.announcements.filter(x => !this.clonedFilterAnnouncements.some(y => y.id == x.id));
  }

  onAddAnnouncementToFilter() {
    if (!this.clonedSelectedAnnouncement) return;
    if (this.clonedFilterAnnouncements.some(x => x.id == this.clonedSelectedAnnouncement.id)) return;
    let clone = deepCloneJson(this.clonedSelectedAnnouncement) as any;
    clone.uuid = uuid4();
    clone.viewed = true;
    this.clonedFilterAnnouncements.push(clone);
    if (this.filteredAnnouncementList()?.length)
      this.clonedSelectedAnnouncement = this.filteredAnnouncementList()[0];
    else
      this.clonedSelectedAnnouncement = null;
  }

  onRemoveAnnouncementFromFilter(announcement: any, isModal: boolean) {
    let list = (isModal == true ? this.clonedFilterAnnouncements : this.filterAnnouncements);
    let oan = list.find(x => x.id == announcement.id);
    if (!oan) return;
    let index = list.indexOf(oan);
    if (index >= 0) {
      list.splice(index, 1);
    }
  }

  closeModal() {
    this.onClose.emit(this.clonedFilterAnnouncements)
  }

  setOriginalToCloneAnnouncementAndDoSearch() {
    this.clonedFilterAnnouncements = deepCloneJson(this.filterAnnouncements);
  }

  setCloneToOriginalAnnouncementAndDoSearch() {
    this.filterAnnouncements = deepCloneJson(this.clonedFilterAnnouncements);
  }

}
