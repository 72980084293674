<div class="card">
  <div id="top" class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="row">
      <div class="col-md-12" [formGroup]="formGroup">
          <div class="form-group">
            <label>Name</label>
            <input class="form-control" formControlName="name"  (keydown.enter)="isValid() ? formGroup.controls.id.value ? onUpdate() : onInsert() : null">
          </div>
          <div class="form-group text-right">
            <button class="btn btn-info" [disabled]="!isValid()"
              (click)="formGroup.controls.id.value ? onUpdate() : onInsert()">{{formGroup.controls.id.value ? "Update"
              :"Add New Region"}}
            </button>
            <button class="btn btn-primary ml-2" (click)="resetFormGroups()">Cancel</button>
          </div>
      </div>
      <div class="col-md-12">
        <hr>
        <div class="fw table table-responsive">
          <table class="fw mt-1">
            <thead>
              <tr>
                <th>Name</th>
                <th>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="!(regions | async).length">
                <td colspan="2">
                  <p class="text-center">No Data</p>
                </td>
              </tr>
              <ng-container *ngIf="(regions | async).length">
                <tr *ngFor="let item of regions | async">
                  <td>{{item.regionName}}</td>
                  <td class="text-right">
                    <button (click)="onRemove(item.regionId)" class="btn btn-danger m-1">Remove</button>
                    <button (click)="onEdit(item)" class="btn btn-info m-1">Edit</button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
