import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SiteAttendanceQuestionAnswer } from 'src/app/models/attendance-request/site-attendance-question-answer';
import { SiteAttendanceService } from 'src/app/services/site-attendance.service';
import { SiteQuestionService } from 'src/app/services/site-question.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RequestAttendanceMode } from '../../../models/attendance-request/attendance-request-viewmodel';
import { finalize } from 'rxjs/operators';
import { QuestionAnswerInputModel } from 'src/app/models/question-answer-inputmodel';
import { ModalService } from 'src/app/services/modal.service';
import { AsyncResult } from 'src/app/models/helper-models';
import { UserService } from 'src/app/services/user.service';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { AttendanceFieldViewModel } from 'src/app/models/attendance-field-model';
import { SiteAttendanceReportViewModel } from 'src/app/models/site-report-models';
import {
  RequiredDocumentViewModel,
} from "../../../models/user-document-type";
import { RequiredDocumentListRendererComponent } from '../../question/renderer/required-document-list-renderer/required-document-list-renderer.component';
import {
  convertDocumentToFormControl,
  getUploadedDocumentsFromFormArray,
  setDocumentFormControlForQuestions,
  isSetSupplierCheckinData,
} from 'src/app/helpers/general-functions';
import { SiteQuestionViewModel } from 'src/app/models/site-question-viewmodel';
import { CustomFormControl } from "../../../models/custom-formcontrol";
import { FormArray } from "@angular/forms";
import {SupplierCheckinViewModel} from "../../../models/supplier-checkin-view-model";
import {SupplierCheckinModel} from "../../../models/supplier-checkin-model";


@Component({
  selector: 'obc-manual-checkout',
  templateUrl: './manual-checkout.component.html',
  styleUrls: ['./manual-checkout.component.scss'],
})
export class ManualCheckoutComponent implements OnInit {
  _questions: SiteQuestionViewModel[] = null;
  _attendees: SiteAttendanceReportViewModel[] = null;
  selectedAttendee: SiteAttendanceReportViewModel = null;
  inProgress: boolean = false;
  selectedUserId: number;
  @Output() needToUpdateAttendanceCount = new EventEmitter();

  requiredDocumentFormControls: CustomFormControl[] = [];
  requiredDocumentFormArray: FormArray;

  @Input() set checkoutSelected(model: BehaviorSubject<boolean>) {
    model.subscribe(tabSelected => {
      if (tabSelected == true)
        this.getSiteAttendees();
    })
  }

  @Output() attendeesCountUpdate: EventEmitter<number> = new EventEmitter();


  @ViewChild('requiredDocumentsList') requiredDocumentsList: RequiredDocumentListRendererComponent;


  set questions(model: SiteQuestionViewModel[]) {
    this._questions = model;
    this._questions = setDocumentFormControlForQuestions(this._questions);
    this.questions$.next(this._questions);
  }

  _siteId: number = null;
  filterListTerm: string = '';

  get attendees(): SiteAttendanceReportViewModel[] {
    return this._attendees;
  }

  questions$: BehaviorSubject<SiteQuestionViewModel[]> = new BehaviorSubject<SiteQuestionViewModel[]>(this._questions);
  questionContainerValidation$: BehaviorSubject<{
    valid: boolean;
    answers: SiteAttendanceQuestionAnswer[];
  }> = new BehaviorSubject<{
    valid: boolean;
    answers: SiteAttendanceQuestionAnswer[];
  }>({valid: true, answers: []});
  questionContainerValid: boolean;
  answers: SiteAttendanceQuestionAnswer[];

  /*Supplier Data*/
  supplierCheckinData: SupplierCheckinViewModel;
  _supplierQuestions: SiteQuestionViewModel[] = null;
  supplierRequiredDocumentFormControls: CustomFormControl[] = [];
  supplierRequiredDocumentFormArray: FormArray;
  supplierQuestions$: BehaviorSubject<SiteQuestionViewModel[]> = new BehaviorSubject<SiteQuestionViewModel[]>(this._supplierQuestions);
  supplierQuestionContainerValidation$: BehaviorSubject<{
    valid: boolean;
    answers: SiteAttendanceQuestionAnswer[];
  }> = new BehaviorSubject<{
    valid: boolean;
    answers: SiteAttendanceQuestionAnswer[];
  }>({valid: true, answers: []});
  supplierQuestionContainerValid: boolean;
  supplierAnswers: SiteAttendanceQuestionAnswer[];
  set supplierQuestions(model: SiteQuestionViewModel[]) {
    this._supplierQuestions = model;
    this._supplierQuestions = setDocumentFormControlForQuestions(this._supplierQuestions);
    this.supplierQuestions$.next(this._supplierQuestions);
  }






  @Input() set siteId(model: number) {
    if (this._siteId != model) {
      this._siteId = model;
      this.getSiteAttendees();
    }
  }

  get siteId(): number {
    return this._siteId;
  }

  errorMessage: string = null;
  bsModalRef: BsModalRef;
  userPermissions = UserPermission;

  constructor(
    private siteAttendanceService: SiteAttendanceService,
    private modalService: ModalService,
    public userService: UserService,
    private siteQuestionService: SiteQuestionService,
    private bsModalService: BsModalService
  ) {
  }

  ngOnInit() {
    this.getSiteAttendees();
    this.questionContainerValidation$.subscribe((res) => {
      this.questionContainerValid = res.valid;
      this.answers = res.answers;
    });

    this.supplierQuestionContainerValidation$.subscribe((res) => {
      this.supplierQuestionContainerValid = res.valid;
      this.supplierAnswers = res.answers;
    });
  }

  get isFormValid(): boolean {

    let isValidDocuments = this.requiredDocumentFormArray == null ||
      this.requiredDocumentFormArray.controls.every(c => {
        let control = (c as CustomFormControl);
        let doc = control.item as RequiredDocumentViewModel;
        return doc.questionId == null ? c.valid : true;
      });

    let isValidSupplierDocuments = this.supplierRequiredDocumentFormArray == null ||
      this.supplierRequiredDocumentFormArray.controls.every(c => {
        let control = (c as CustomFormControl);
        let doc = control.item as RequiredDocumentViewModel;
        return doc.questionId == null ? c.valid : true;
      });

    return this.questionContainerValid && this.supplierQuestionContainerValid && isValidDocuments && isValidSupplierDocuments;
  }

  // siteHasQuestion(): boolean {
  //   return this._questions?.length > 0;
  // }

  openModal(template, attendee: SiteAttendanceReportViewModel) {
    this.selectedAttendee = attendee;
    this.selectedUserId = attendee.userId;
    this.inProgress = true;
    this.siteQuestionService
      // .GetSiteQuestionsForSelectedUser(this.selectedUserId, this._siteId, false, attendee.visitorTypeId)
      .GetHostCheckOutInformation(this.selectedAttendee.mobile, this._siteId, attendee.visitorTypeId)
      .pipe(finalize(() => (this.inProgress = false)))
      .subscribe(
        (res) => {
          if(res && res.success) {
            this.questions = res.data?.siteQuestions;

            this.supplierCheckinData = res.data?.supplierCheckinData;
            this.supplierQuestions = res.data?.supplierCheckinData?.siteQuestions

            this.bsModalRef = this.bsModalService.show(
              template,
              Object.assign({}, {class: 'gray modal-lg'})
            );
            setTimeout(() => {
              this.questions$.next(this._questions);
              this.supplierQuestions$.next(this._supplierQuestions);

              this.ResetAll();

            }, 100);
            this.errorMessage = "";
          } else {
            this.errorMessage = "we can't retrive check-out questions"
          }

        },
        (_) => (this.errorMessage = "we can't retrive check-out questions")
      );
  }

  onhide() {
    if (this.bsModalRef) this.bsModalRef.hide();
  }

  onCheckout() {
    this.inProgress = true;
    this.siteAttendanceService.ManualCheckOut(this.selectedAttendee.mobile, {
      Mode: RequestAttendanceMode.Manual,
      siteId: this.siteId,
      answers: this.answers,
      documents: getUploadedDocumentsFromFormArray(this.requiredDocumentFormArray),
      siteAttendanceId: this.selectedAttendee.siteAttendanceId,
      supplierCheckOutData: {
        supplierAnswers: this.supplierAnswers,
        supplierDocuments: getUploadedDocumentsFromFormArray(this.supplierRequiredDocumentFormArray),
      } as SupplierCheckinModel
    })
      .subscribe(res => {
          if (res.success === true) {
            let index = this._attendees.indexOf(this.selectedAttendee);
            this._attendees.splice(index, 1);
            this.onhide();
            this.needToUpdateAttendanceCount.emit()
          } else {
            this.inProgress = false;
            this.errorMessage = res.message ?? 'Check-out failed';
          }
          setTimeout(() => {
            this.errorMessage = null;
          }, 10000);
        },
        (err) => console.log(err),
        () => this.inProgress = false);
  }

  onQueryChange($event) {
    this.filterListTerm = $event;
  }


  getSiteAttendees() {
    this.inProgress = true;
    this.siteAttendanceService
      .SiteActiveAttendees(this._siteId)
      .subscribe((res) => {
        this._attendees = res;
        this.attendeesCountUpdate.emit(this._attendees.length);
        this.inProgress = false;
        res.forEach(item =>
          item.accompaniersText = item.accompaniers
            ?.map(a => [a.firstName, a.lastName, a.mobile].filter(Boolean).join(' '))
            ?.filter(Boolean).join(', ')
        );
      });
  }

  questionAnswerInputModel: QuestionAnswerInputModel = new QuestionAnswerInputModel();

  openQuestionAnswerModal(
    template,
    siteAttendanceId: number,
    isCheckInAnswer: boolean,
    hasCheckoutDate: boolean
  ) {
    this.questionAnswerInputModel = {
      isCheckInAnswer: isCheckInAnswer,
      siteAttendanceId: siteAttendanceId,
      hasCheckOutDate: hasCheckoutDate,
    };
    this.modalService.show(template, 'gray modal-lg');
  }

  closeModal(_: AsyncResult) {
    this.modalService.hide();
  }

  ResetAll() {
    this.requiredDocumentFormControls = [];
    this.supplierRequiredDocumentFormControls = [];

    let res = convertDocumentToFormControl([], (this._questions?.filter(question => question.documentFormControl != null)?.map(question => question.documentFormControl) ?? []));
    this.requiredDocumentFormArray = res?.formArray;
    this.requiredDocumentFormControls = res?.controls;

    let supRes = convertDocumentToFormControl([], (this._supplierQuestions?.filter(question => question.documentFormControl != null)?.map(question => question.documentFormControl) ?? []));
    this.supplierRequiredDocumentFormArray = supRes?.formArray;
    this.supplierRequiredDocumentFormControls = supRes?.controls;
  }

  isSetSupplierCheckinData() {
    return isSetSupplierCheckinData(this.supplierCheckinData);
  }
}
