<div class="row">
  <div class="col-md-12">
    <div class="card">
      <header class="card-header">Search Forms</header>
      <obc-descriptor [text]="'This allows you to search currently completed forms.'" [closePanelName]="'formReport'">
      </obc-descriptor>
      <div class="card-body">
        <span class="mr-5">
          <span class="d-inline-block mt-2 mr-1">Include Inactive Forms: </span>
          <obc-switch [trueLabel]="'Yes'" [falseLabel]="'No'" [value]="includeInactiveForms"
            (valueChanged)="onIncludeInactiveFormsChanged($event)">
          </obc-switch>
        </span>
        <app-base-grid
          [columns]="gridColumns"
          [configs]="gridConfig" #grid>

          <ng-template obcGridTemplateName="viewDetails" let-data="data">
            <button class="btn btn-info pull-right ml-1 mb-1" (click)="openModal(formDetails, data)">
              View Details
            </button>
            <button *ngIf="data.formDataId > 0" type="button" class="btn btn-success pull-right"
                    (click)="exportPdf(data)">
              <i class="fas fa-file-pdf-o"></i>
              PDF
            </button>
          </ng-template>

          <ng-template #formDetails>
            <obc-form-details [formDataId]="data.formDataId" (onRefesh)="closeModal();"></obc-form-details>
          </ng-template>

        </app-base-grid>
      </div>
    </div>
  </div>
</div>
