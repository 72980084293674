<ng-container *ngIf="variablesList?.length > 0">
  <div class="row mb-3" [formGroup]="formGroup">
    <div class="col-md-6 mt-1" *ngFor="let variable of variablesList">
      <label> {{variable.title}} </label>
      <ng-container *ngIf="variable.type==VariableDefinitionType.Date">
        <obc-input-date-time-picker [formControlName]="variable.key" [mode]="DateTimePickerMode.Calender">
        </obc-input-date-time-picker>
      </ng-container>
      <ng-container *ngIf="variable.type==VariableDefinitionType.Unknown">
        <input [formControlName]="variable.key" class="form-control" type="text">
      </ng-container>
    </div>
  </div>
</ng-container>
