import { ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';

export const emailTemplateValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const subject = control.get('subject');
    const recipients = control.get('recipients');
    const sendEmailToCompanySupervisor = control.get('sendEmailToCompanySupervisor');
    const sendEmailToSiteSupervisor = control.get('sendEmailToSiteSupervisor');

    if(!subject.valid) return { 'subjectIsNotValid': true }
    if(!recipients.valid) return { 'recipientsNotValid': true }

    return (sendEmailToCompanySupervisor && sendEmailToCompanySupervisor.value) ||
        (sendEmailToSiteSupervisor && sendEmailToSiteSupervisor.value) ||
        (recipients && recipients.valid && (recipients.value?.length ?? 0) > 0) ? null : { 'emailIsRequired': true };
};
