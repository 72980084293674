import { Component, Input } from '@angular/core';
import { UserInformation, UserInformationField } from 'src/app/models/userProfile/worker-profile-view-model';

@Component({
  selector: 'obc-user-information',
  templateUrl: './user-information.component.html'
})
export class UserInformationComponent {
  @Input() isDisputable!: boolean;
  @Input() userInformationFields!: UserInformationField[];

  @Input() userInformation: UserInformation;

  @Input() onShowDisputeModal!: () => void
  @Input() showDisputHistory!: (isShow: boolean) => void;
}
