import {Pipe, PipeTransform} from '@angular/core';
import {AutomaticCheckInMode} from '../enums/automatic-checkin-mode';

@Pipe({
  name: 'automaticCheckinMode'
})
export class AutomaticCheckinModePipe implements PipeTransform {

  transform(value: unknown, isShortName: boolean = false): any {
    if(isShortName) {
      if (value === AutomaticCheckInMode.Disable) { return "Disable"; }
      if (value === AutomaticCheckInMode.EnableForFrequentUsers) { return "Enable For Frequent Users"; }
      if (value === AutomaticCheckInMode.EnableForRegularUsers) { return "Enable"; }
    } else {
      if (value === AutomaticCheckInMode.Disable) { return "Disable"; }
      if (value === AutomaticCheckInMode.EnableForFrequentUsers) { return "Enable For Frequent Users (Requires One Self Check-In)"; }
      if (value === AutomaticCheckInMode.EnableForRegularUsers) { return "Enable (Requires One Self Check-In)"; }
    }
    return value;
  }
}
