import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MaxFileSize } from './../../enums/constants';
import { ModalService } from 'src/app/services/modal.service';
import { getFileExtenstion, isValidImageFile, toBase64 } from 'src/app/helpers/general-functions';

@Component({
  selector: 'obc-image-cropper-form-control',
  templateUrl: './image-cropper-form-control.component.html',
  styleUrls: ['./image-cropper-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageCropperFormControlComponent),
      multi: true
    }
  ]
})
export class ImageCropperFormControlComponent implements ControlValueAccessor {
  imageSourceBase64: string;
  croppedImageSourceBase64: string;
  fileType: string;
  onChange: Function;
  @ViewChild('input') input;
  @Output() removeImage = new EventEmitter();
  @Input() currentImageSrcUrl: any;
  @Input() removable = true;
  @Input() header: string = "Select Image";
  constructor(private modalService: ModalService) { }
  writeValue(value: null): void {
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
  ngOnInit() {
  }

  async imageCropped($event) {
    this.croppedImageSourceBase64 = $event.base64;
    await this.setModel();
  }
  loadImageFailed() {

  }

  async onPickImage($event) {
    let file = $event.target.files[0];
    if(!isValidImageFile(file.type)) {
      this.modalService.error("The selected file is not a valid type of image", "Invalid File Type").subscribe(_=> {});
      $event.target.value = null;
      return;
    }

    if (file.size > MaxFileSize * 1024000) {
      this.modalService.error(`Maximum File Size is ${MaxFileSize}mb`, "").subscribe(_=> {});
      $event.target.value = null;
      return;
    }
    this.imageSourceBase64 = (await toBase64(file)) as string;
    this.fileType = getFileExtenstion(file.name);
  }

  private async setModel() {
    this.onChange({
      content: this.croppedImageSourceBase64,
      type: this.fileType,
    });
  }

  private clearModel() {
    this.imageSourceBase64 = null;
    this.fileType = null;
    this.input.nativeElement.value = null;
    this.onChange(null);
  }

  onClearSelectedImage() {
    this.clearModel();
    this.removeImage.emit(null);
  }

  get isFileSelected() {
    if (this.input == null) return false;
    return this.input.nativeElement.value != "";
  }
}
