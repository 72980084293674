export enum DashboardWidgetType {
    CheckInReport = 1,
    SiteVisit = 2,
    TotalSite = 3,
    TotalUsers = 4,
    TotalCheckIns = 5,
    TodayAnsweredQuestions = 6,
    ExternalLink = 7,
    TotalUsersOnsite = 8,
    TotalOpenPermits = 9,
    TotalOnHoldPermits = 10,
    TotalAwaitingApprovalPermits = 11,
    TotalAwaitingApprovalSupplierDocuments = 12,
}
