import { Pipe, PipeTransform } from '@angular/core';
import { AccessControlInductionMode } from '../enums/access-control-automatic-attendance-mode';

@Pipe({
  name: 'accessControlInductionMode'
})
export class AccessControlInductionModePipe implements PipeTransform {

  transform(value: any): string {
    if (value === AccessControlInductionMode.NoRestriction) { return "No Restrictions"; }
    if (value === AccessControlInductionMode.ApprovedInductions) { return "Require Approved Mandatory Inductions"; }
    if (value === AccessControlInductionMode.ApprovedOrPendingInductions) { return "Require Mandatory Inductions (Pending or Approved)"; }
    return value?.toString() ?? '?';
  }

}
