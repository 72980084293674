import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssetOperatorMode } from "../../../enums/asset-operator-mode.enum";
import { ManageSiteAssetMode } from "../../../enums/manage-site-asset-mode.enum";
import { UserService } from "../../../services/user.service";

@Component({
  selector: 'obc-asset-operator-mode-selector',
  templateUrl: './asset-operator-mode-selector.component.html',
  styleUrls: [ './asset-operator-mode-selector.component.scss' ]
})
export class AssetOperatorModeSelectorComponent implements OnInit {

  assetOperatorMode: AssetOperatorMode[] = [];
  @Input() operatorRegistrationMode: number = 0;
  @Input() onlyTempAsset: boolean = false;
  @Input() manageSiteAssetMode: ManageSiteAssetMode;
  @Input() supplierHashLink: string;
  @Input() siteCompanyTitle: string;

  @Output('operatorMode') operatorModeOutput: EventEmitter<AssetOperatorMode> = new EventEmitter<AssetOperatorMode>();

  constructor(
    private userService: UserService) {
  }

  ngOnInit(): void {
    if (this.operatorRegistrationMode > 0) {
      this.operatorRegistrationModeNumberToEnum();
    } else {
      if (this.onlyTempAsset == true) {
        this.assetOperatorMode = [ AssetOperatorMode.SiteCompanyHosts ];
      } else if (this.manageSiteAssetMode == ManageSiteAssetMode.ExternalCompany)
        this.assetOperatorMode = [ AssetOperatorMode.AssetCompanyHosts ];
      else
        this.assetOperatorMode = [ AssetOperatorMode.SiteCompanyHosts ];
    }

    this.emitOperatorMode();
  }

  operatorRegistrationModeNumberToEnum() {
    this.assetOperatorMode = [];
    for (let item in AssetOperatorMode) {
      if((Number(item) & this.operatorRegistrationMode) > 0) {
        this.assetOperatorMode.push(Number(item));
      }
    }
  }

  isModeSelected(mode) {
    return this.assetOperatorMode?.includes(mode);
  }

  get assetOperatorModeToShow() {
    return this.manageSiteAssetMode == ManageSiteAssetMode.ExternalCompany ?
      (this.supplierHashLink?.length ?
        [ AssetOperatorMode.SiteCompanyHosts, AssetOperatorMode.AssetOperators ] :
        [ AssetOperatorMode.AssetCompanyHosts, AssetOperatorMode.SiteCompanyHosts, AssetOperatorMode.AssetOperators ]) :
      [ AssetOperatorMode.SiteCompanyHosts, AssetOperatorMode.AssetOperators ];
  }

  isDisableOperatorMode(mode: AssetOperatorMode): boolean {
    return ((this.manageSiteAssetMode == ManageSiteAssetMode.MainCompany || this.supplierHashLink?.length) && mode == AssetOperatorMode.SiteCompanyHosts) ||
      mode == AssetOperatorMode.AssetCompanyHosts;

  }

  getOperatorModeTitle(mode: AssetOperatorMode): string {
    if (mode == AssetOperatorMode.AssetCompanyHosts)
      return `${ this.userService.companyName } Employees`;
    if (mode == AssetOperatorMode.SiteCompanyHosts)
      return `${ this.manageSiteAssetMode == ManageSiteAssetMode.MainCompany ?
        (this.userService.companyName + ' Employees') :
        ((this.siteCompanyTitle ?? 'Site') + ' Hosts') }`;
    if (mode == AssetOperatorMode.AssetOperators)
      return 'Operators';
    return mode?.toString();
  }

  onChangeOperatorMode(mode: AssetOperatorMode, e) {
    if (e.target.checked)
      this.assetOperatorMode.push(mode);
    else
      this.assetOperatorMode = this.assetOperatorMode.filter(d => d != mode);

    this.emitOperatorMode();
  }

  emitOperatorMode() {
    let operatorMode = 0;
    this.assetOperatorMode.forEach(d => operatorMode |= d);
    this.operatorModeOutput.emit(operatorMode);
  }
}
