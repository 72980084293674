import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, Validators } from '@angular/forms';
import { ToolDynamicFieldType } from 'src/app/enums/tool-dynamic-field-type.enum';
import { CustomFormControl } from 'src/app/models/custom-formcontrol';
import { InventoryDynamicFieldValues } from 'src/app/models/inventory/inventory-dynamic-field-values';
import { ExtendedToolDynamicFieldValueModel, ToolDynamicFieldViewModel } from 'src/app/models/inventory/tool-dynamic-field-model';

@Component({
  selector: 'obc-tool-field-renderer',
  templateUrl: './tool-field-renderer.component.html',
  styleUrls: ['./tool-field-renderer.component.scss']
})
export class ToolFieldRendererComponent implements OnInit {
  formArray = new FormArray([]);
  ToolDynamicFieldType = ToolDynamicFieldType;
  _fields: ToolDynamicFieldViewModel[] = [];
  @Input() set fields(value: ToolDynamicFieldViewModel[]) {
    if (value == null)
      value = [];
    //if (!(value?.length > 0)) return;
    this.formArray.clear();
    this._fields = value;
    for (let index = 0; index < value.length; index++) {
      const field = value[index];
      var validators = [];
      if (field.isRequired)
        validators.push(Validators.required);
      if (field.type != ToolDynamicFieldType.File)
        this.formArray.push(new CustomFormControl(null, field, validators));
      else if (field.type == ToolDynamicFieldType.File) {
        this.formArray.push(new CustomFormControl(null, field, validators));
      }


    }
    this.updateFormValues();
  }
  _values: InventoryDynamicFieldValues[] = [];
  @Input() set values(value: InventoryDynamicFieldValues[]) {
    this._values = value;
    this.updateFormValues();
  }

  updateFormValues() {
    for (let index = 0; index < this.formArray.length; index++) {
      const control = this.formArray.controls[index] as CustomFormControl;
      const item = control.item as ToolDynamicFieldViewModel;
      var value = this._values?.find(x => x.dynamicFieldId == item.id);
      if (value) {
        if (item.type != ToolDynamicFieldType.File)
          control.setValue(value.value);
        else if (item.type == ToolDynamicFieldType.File) {
          // temporary images to control
          item.tempData = value.images;
        }
      }
    }
  }

  get fields(): ToolDynamicFieldViewModel[] {
    return this._fields;
  }

  get valid(): boolean {
    if (this.formArray?.controls.length > 0) {
      for (let index = 0; index < this.formArray?.controls.length; index++) {
        const element = this.formArray?.controls[index];
        var item = this.getCustomFormControlItem(element);
        if (item.type != ToolDynamicFieldType.File) {
          if (!element.valid)
            return false;
        } else {
          var value = element.value;
          if (item.isRequired && !(value?.files?.length > 0 || value?.images?.length > 0))
            return false;
        }
      }
    }
    return true;
  }

  public getFieldValues(): ExtendedToolDynamicFieldValueModel[] {
    var ans: ExtendedToolDynamicFieldValueModel[] = [];
    if (this.formArray?.controls.length > 0) {
      for (let index = 0; index < this.formArray?.controls.length; index++) {
        const item = this.getCustomFormControlItem(this.formArray.controls[index]);
        const control = this.formArray?.controls[index];
        var value = item.type == ToolDynamicFieldType.File ? (control.value?.files?.length > 0 ? JSON.stringify(control.value?.files) : null) : control.value;
        var images = item.type == ToolDynamicFieldType.File ? (control.value?.images?.length > 0 ? control.value?.images : null) : null;
        ans.push({
          dynamicFieldId: item.id,
          value: value,
          images: images,
          uniqueKey: item.uniqueKey,
        } as ExtendedToolDynamicFieldValueModel)
      }
    }
    return ans;
  }

  constructor() { }

  ngOnInit() {
  }

  getCustomFormControlItem(control: AbstractControl) {
    return this.toToolDynamicFieldModel(this.toCustomFormControl(control).item);
  }

  toToolDynamicFieldModel(item: any): ToolDynamicFieldViewModel {
    return item as ToolDynamicFieldViewModel;
  }

  toCustomFormControl(control: AbstractControl): CustomFormControl {
    return control as CustomFormControl;
  }

}
