import { ValidatorFn, AbstractControl } from '@angular/forms';
import { validateEmail } from './general-functions';

export function emailArrayValidator(control: AbstractControl): {
  [key: string]: boolean;
} | null {
  var value = control.value as string[];
  if (value == null || value.length == 0)
      return null;
  var emails = emailArray(value);
  return emails != null ? null : { 'invalidemailList': true };
}

export function emailArray(emails: string[]): string[] {
  for (let index = 0; index < emails.length; index++) {
      const element = emails[index];
      if (!validateEmail(element)) {
          return null;
      }
  }
  return emails;
}


export function emailListValidator(control: AbstractControl): {
    [key: string]: boolean;
} | null {
    var value = control.value as string;
    if (value == null || value.length == 0)
        return null;
    var emails = emailList(value);
    return emails != null ? null : { 'invalidemailList': true };
}

export function emailList(emails: string): string[] {
    var splits = splitEmailList(emails);
    for (let index = 0; index < splits.length; index++) {
        const element = splits[index];
        if (!validateEmail(element)) {
            return null;
        }
    }
    return splits;
}

export function splitEmailList(emails: string): string[] {
    return emails.split(' ').join('').replace('\n', ',').split(',');
}

