<div class="card" style="border:none">
    <div class="card-body" style="padding: 0;">
        <div>
            <tabset>
                <tab heading="Asset Categories">
                    <obc-manage-tool-categories [hideFormOperationPanel]="true"></obc-manage-tool-categories>
                </tab>
                <tab heading="Asset Status">
                    <obc-manage-tool-statuses></obc-manage-tool-statuses>
                </tab>
            </tabset>
        </div>
    </div>
</div>