<div class="card" *ngIf="isInitilize">
    <div class="card-body">
        <obc-loader [show]="inProgress"></obc-loader>
        <div class="row mt-2 {{ !showTabPage ? 'full-screen-mode' : '' }}">
            <div class="col-12">
                <ng-container
                    *ngIf="formDetail && formSelectionMode == SupplierDocumentReviewFromSelectionMode.withForm && showPicPanel">
                    <tabset>
                        <tab heading="Form" class="scroll-form {{ !showTabPage ? 'col-4' : '' }}">
                            <ng-container *ngTemplateOutlet="supplierDocReview">
                            </ng-container>
                        </tab>
                        <tab heading="Documents" class="scroll-form {{ !showTabPage ? 'col-8 pl-2' : '' }}">
                            <ng-container *ngTemplateOutlet="supplierDocReviewFiles">
                            </ng-container>
                        </tab>
                    </tabset>
                </ng-container>
                <ng-container
                    *ngIf="!(formDetail && formSelectionMode == SupplierDocumentReviewFromSelectionMode.withForm)">
                    <ng-container *ngTemplateOutlet="supplierDocReviewFiles">
                    </ng-container>
                </ng-container>

                <ng-container
                    *ngIf="formDetail && formSelectionMode == SupplierDocumentReviewFromSelectionMode.withForm && !showPicPanel">
                    <ng-container *ngTemplateOutlet="supplierDocReview">
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>

    <ng-template #supplierDocReview>
        <div class="card">
            <obc-form-data-details #supplierDocumentDetail [loadFromWorkflow]="true"
                [formDataId]="selectedFormDataId"></obc-form-data-details>
        </div>
    </ng-template>

    <ng-template #supplierDocReviewFiles>
        <obc-file-viewer *ngIf="selectedSiteSupplierDocument?.files?.length"
            [prefix]="selectedSiteSupplierDocument.supplierDocumentTypeName+'-'+selectedSiteSupplierDocument.displayVersion"
            [files]="selectedSiteSupplierDocument.files" [btnTitle]="'View Files'"
            [btnClass]="'btn-sm btn btn-primary ml-2 pull-right'" [showByModal]="false">
        </obc-file-viewer>
    </ng-template>
</div>