import { Component, OnInit, Input } from '@angular/core';
import { BaseSiteViewModel } from 'src/app/models/site-viewmodel';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray, AbstractControl } from '@angular/forms';
import { SiteAttendancePermissionService } from 'src/app/services/site-attendance-permission.service';
import { SiteAttendancePermissionUser } from 'src/app/models/site-attendance-permission-models/site-attendance-permission-user.model';
import { e168Validator } from 'src/app/helpers/mobile-format-validator';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { parseDateTime, parseDateTime2 } from 'src/app/helpers/date-time-format';

@Component({
  selector: 'obc-manage-site-attendance-permission',
  templateUrl: './manage-site-attendance-permission.component.html',
  styleUrls: ['./manage-site-attendance-permission.component.scss']
})
export class ManageSiteAttendancePermissionComponent implements OnInit {
  DatetimePickerMode = DatetimePickerMode;
  [x: string]: any;
  inProgress: boolean = false;
  toggled: boolean = false;
  newAttendeeFormGroup: FormGroup;
  commaSeperatedData: string = "";
  commaSeperatedDataError: string = null;
  pageTitle: string = null;
  pageInfo: string = null;
  selectedSite: BaseSiteViewModel;
  attendeeTable: FormGroup;
  isCompanyMode: boolean = false;

  private _sites: BaseSiteViewModel[];
  @Input() isTemporayInvite = false;
  @Input() set sites(model: BaseSiteViewModel[]) {
    this._sites = model;
    if (this._sites && this._sites.length > 0 && this.selectedSite == null) {
      this.selectedSite = this._sites[0];
      this.onSiteChange();
    }
  };
  get sites(): BaseSiteViewModel[] {
    return this._sites;
  }

  constructor(private fb: FormBuilder,
    private siteAttendancePermissionService: SiteAttendancePermissionService) { }

  ngOnInit(): void {
    if (this.isTemporayInvite) {
      this.pageTitle = "Temporary Users";
      this.pageInfo = 'Adding a temporary user will provide them with access to all company sites or a private site for a period of time that you can decide.';
    }
    else {
      this.pageTitle = "Frequent Users";
      this.pageInfo = 'Add a frequent user will provide the individual with access to all company sites or a private site.';
    }

    this.attendeeTable = this.fb.group({
      tableRows: this.fb.array([])
    });
    this.newAttendeeFormGroup = new FormGroup({
      mobile: new FormControl('', [Validators.required, e168Validator()]),
      firstname: new FormControl(''),
      lastname: new FormControl(''),
      allowCheckInFrom: new FormControl(''),
      allowCheckInTo: new FormControl('')
    });
  }

  onActiveChanged(isCompanyMode: boolean) {
    this.isCompanyMode = isCompanyMode;
    this.onSiteChange();
  }

  toggle() {
    this.toggled = !this.toggled;
  }

  addSinglePerson(): void {
    if (this.newAttendeeFormGroup.valid) {
      var attendee = {
        mobile: this.newAttendeeFormGroup.get("mobile").value,
        firstName: this.newAttendeeFormGroup.get("firstname").value,
        lastName: this.newAttendeeFormGroup.get("lastname").value,
        allowCheckInFrom: this.isTemporayInvite ? this.newAttendeeFormGroup.get("allowCheckInFrom").value : null,
        allowCheckInTo: this.isTemporayInvite ? this.newAttendeeFormGroup.get("allowCheckInTo").value : null,
        siteId: this.isCompanyMode ? null : this.selectedSite.id,
      } as SiteAttendancePermissionUser;

      this.siteAttendancePermissionService.addSinglePerson(attendee).subscribe(res => {
        this.newAttendeeFormGroup.reset();
        this.onSiteChange();
      }, err => console.log(err))

    }
  }

  addMultiplePeople() {
    this.commaSeperatedDataError = null;
    if (this.commaSeperatedData) {
      this.siteAttendancePermissionService.addMultiplePeople(this.isCompanyMode ? null : this.selectedSite.id, this.commaSeperatedData).subscribe(
        res => {
          this.commaSeperatedData = res;
          if (res)
            this.commaSeperatedDataError = "invalid phone numbers";
          this.onSiteChange();
        }, err => {
          console.log(err)
          this.commaSeperatedDataError = "invalid phone numbers";
        }
      )
    }
  }

  deleteRow($event: MouseEvent, index: number) {
    $event.stopPropagation();
    const formArray = this.attendeeTable.get('tableRows') as FormArray;
    var control = formArray.controls[index];
    var user = this.getAttendee(control);
    if (this.isCompanyMode)
      this.siteAttendancePermissionService.removeCompanyAttendee(user.userId).subscribe(
        res => {
          this.onSiteChange();
        },
        err => {
          console.log(err);
        }
      );
    else
      this.siteAttendancePermissionService.removeSiteAttendee(user.siteId, user.userId).subscribe(
        res => {
          this.onSiteChange();
        },
        err => {
          console.log(err);
        }
      );
  }

  editRow(group: FormGroup) {
    if (group.get('sealed').value == false)
      group.get('isEditable').setValue(true);
  }

  doneRow(group: FormGroup) {
    if (!group.valid) return;
    var user = this.getAttendee(group);
    this.inProgress = true;
    this.siteAttendancePermissionService.updateSiteAttendee(user).subscribe(
      res => {
        group.get('isEditable').setValue(false);
        this.inProgress = false;
      },
      err => {
        console.log(err);
        this.inProgress = false;
      }
    )
  }

  get getFormControls() {
    const control = this.attendeeTable.get('tableRows') as FormArray;
    return control;
  }

  getAttendee(ctrl: AbstractControl) {
    var user = {
      mobile: ctrl.get("mobile").value,
      firstName: ctrl.get("firstname").value,
      lastName: ctrl.get("lastname").value,
      siteId: ctrl.get("siteId").value,
      userId: ctrl.get("userId").value,
      id: ctrl.get('id').value,
      sealed: ctrl.get('sealed').value,
      isEditable: [false],
      allowCheckInFrom: ctrl.get('allowCheckInFrom').value,
      allowCheckInTo: ctrl.get('allowCheckInTo').value
    } as SiteAttendancePermissionUser;
    return user;
  }

  addAttendee(user: SiteAttendancePermissionUser) {
    const control = this.attendeeTable.get('tableRows') as FormArray;
    control.push(this.fb.group({
      mobile: [user.mobile, [Validators.required, Validators.minLength(10)]],
      firstname: [user.firstName],
      lastname: [user.lastName],
      siteId: [user.siteId],
      userId: [user.userId],
      id: [user.id],
      sealed: [user.sealed],
      isEditable: [false],
      allowCheckInFrom: this.getDateTime(user.allowCheckInFrom),
      allowCheckInTo: user.allowCheckInTo
    }));
  }

  getDateTime(date: Date) {
    var date1 = parseDateTime(date, true);
    if (date1 == null || date1?.toDateString()?.toLowerCase() == "invalid date")
      date1 = parseDateTime2(date, this.dateFormat);
    return date1;
  }

  getSiteName(siteId: number) {
    if (!this.sites) return;
    return this.sites.filter((s) => s.id == siteId)[0].name;
  }

  nothingFound = false;
  onSiteChange($event?: BaseSiteViewModel) {
    this.nothingFound = false;
    if (this.selectedSite?.id == $event?.id) return;


    this.inProgress = true;
    if ($event)
      this.selectedSite = $event;

    if (this.isCompanyMode == true)
      this.siteAttendancePermissionService.getCompanyAttendees(this.isTemporayInvite).subscribe(res => this.onGetAttendees(res), err => this.onGetAttendeesError(err));
    else
      if (this.selectedSite)
        this.siteAttendancePermissionService.getSiteAttendees(this.selectedSite.id, this.isTemporayInvite).subscribe(res => this.onGetAttendees(res), err => this.onGetAttendeesError(err));
  }

  onGetAttendees(atts: SiteAttendancePermissionUser[]) {
    if (this.getFormControls)
      this.getFormControls.controls = [];
    if (atts != null && atts.length > 0) {
      for (let index = 0; index < atts.length; index++) {
        this.addAttendee(atts[index]);
      }
    } else
      this.nothingFound = true;
    this.inProgress = false;
  }

  onGetAttendeesError(err) {
    console.log(err);
    this.inProgress = false;
  }

  getAllowCheckInFrom(group: any) {
    return (group as FormGroup).controls.allowCheckInFrom
  }

  setSites(sites: any[]) {
    this.sites = sites;
  }
}

