import { FormGroup, ValidatorFn, FormBuilder, FormControl } from '@angular/forms';

export class EditableItem<T> {
    mode: 'edit' | 'view' = 'view';
    formGroup: FormGroup;
    get order() : number {
        return this.model["order"] as number;
    };
    constructor(
        public model: T,
        private validators: { [key: string]: ValidatorFn[] } | {}) {
        this.createFormGroup();
    }

    createFormGroup() {
        var keys = Object.keys(this.model);
        this.formGroup = new FormGroup({});
        for (let i = 0; i < keys.length; i++) {
            var key = keys[i];
            var validators = this.validators[key];
            this.formGroup.addControl(keys[i], new FormControl(this.model[key], validators));
        }
    }

    get valid(): boolean {
        return this.formGroup.valid;
    }

    get currentModel(): T {
        if (!this.valid)
            return null;
        var cm = {} as T;
        var keys = Object.keys(this.model);
        for (let i = 0; i < keys.length; i++) {
            var key = keys[i];
            cm[key] = this.formGroup.get(key).value;
        }
        return cm;
    }

    get valueChanges(): boolean {
        var cm = this.currentModel;
        var keys = Object.keys(this.model);
        for (let i = 0; i < keys.length; i++) {
            var key = keys[i];
            if (cm[key] !== this.model[key])
                return true;
        }
        return false;
    }

    revertToSavedState() {
        this.createFormGroup();
    }

    save() {
        var keys = Object.keys(this.model);
        for (let i = 0; i < keys.length; i++) {
            var key = keys[i];
            this.model[key] = this.currentModel[key];
        }
        return;
    }

    switchMode() {
        if (this.mode == 'view')
            this.mode = 'edit';
        else
            this.mode = 'view';
    }
}
