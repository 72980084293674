import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AnswerDeletionPolicy } from 'src/app/enums/answer-deletion-policy.enum';
import { DataDeletionPolicy } from 'src/app/enums/data-deletion-policy.enum';
import { DataRetentionPolicyStatus } from 'src/app/enums/data-retention-policy-status.enum';
import { DocumentDeletionPolicy } from 'src/app/enums/document-deletion-policy.enum';
import { SiteActiveStatus } from 'src/app/enums/site-active-status.enum';
import { deepCloneJson } from 'src/app/helpers/general-functions';
import { DataRetentionPolicyModel, DataRetentionPolicyViewModel } from 'src/app/models/data-retention-policy';
import { BaseSiteViewModel } from 'src/app/models/site-viewmodel';
import { DataRetentionPolicyService } from 'src/app/services/data-retention-policy.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ModalService } from 'src/app/services/modal.service';
// ******* In the name of GO..............D *******
@Component({
  selector: 'app-data-retention-policy',
  templateUrl: './data-retention-policy.component.html',
  styleUrls: ['./data-retention-policy.component.scss']
})
export class DataRetentionPolicyComponent implements OnInit {
  fakeFormControl = new FormControl();
  inProgress = false;
  SiteActiveStatus = SiteActiveStatus;
  deletionPolicies = new BehaviorSubject<DataRetentionPolicyViewModel[]>([]);
  DataDeletionPolicy = DataDeletionPolicy;
  DocumentDeletionPolicy = DocumentDeletionPolicy;
  AnswerDeletionPolicy = AnswerDeletionPolicy;

  DataDeletionPolicyToLabel(value: DataDeletionPolicy) {
    switch (value) {
      case DataDeletionPolicy.All:
        return "All";
      case DataDeletionPolicy.None:
        return "Disabled"
    }
  }

  DataDeletionPolicyToCssClass(value: DataDeletionPolicy) {
    switch (value) {
      case DataDeletionPolicy.All:
        return "text-danger";
      case DataDeletionPolicy.None:
        return "text-success";
      default:
        return "";
    }
  }

  DocumentDeletionPolicyToLabel(value: DocumentDeletionPolicy) {
    switch (value) {
      case DocumentDeletionPolicy.All:
        return "All";
      case DocumentDeletionPolicy.None:
        return "Disabled"
    }
  }

  DocumentDeletionPolicyToCssClass(value: DocumentDeletionPolicy) {
    switch (value) {
      case DocumentDeletionPolicy.All:
        return "text-danger";
      case DocumentDeletionPolicy.None:
        return "text-success";
      default:
        return "";
    }
  }

  AnswerDeletionPolicyToLabel(value: AnswerDeletionPolicy) {
    switch (value) {
      case AnswerDeletionPolicy.All:
        return "All";
      case AnswerDeletionPolicy.None:
        return "Disabled"
      case AnswerDeletionPolicy.Sensitive:
        return "Sensitive"
    }
  }

  AnswerDeletionPolicyToCssClass(value: AnswerDeletionPolicy) {
    switch (value) {
      case AnswerDeletionPolicy.All:
        return "text-danger";
      case AnswerDeletionPolicy.None:
        return "text-success";
      case AnswerDeletionPolicy.Sensitive:
        return "text-warning";
      default:
        return "";
    }
  }

  formGroup = new FormGroup({
    id: new FormControl(null),
    title: new FormControl(''),
    isCompanyPolicy: new FormControl(true),
    status: new FormControl(true),
    daysBefore: new FormControl(null, [Validators.min(1), Validators.required]),
    deleteAttendance: new FormControl(true),
    dataDeletionPolicy: new FormControl(DataDeletionPolicy.None),
    documentDeletionPolicy: new FormControl(DocumentDeletionPolicy.None),
    answerDeletionPolicy: new FormControl(AnswerDeletionPolicy.None),
  })
  constructor(private dataRetentionPolicyService: DataRetentionPolicyService,
    private bsModalService: BsModalService,
    private layoutService: LayoutService,
    private modalService: ModalService) { }

  ngOnInit() {
    setTimeout(() => {
      this.getPolicies();
    }, 100)
  }

  private getPolicies() {
    this.inProgress = true;
    this.dataRetentionPolicyService.getPolicies()
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(res => {
        this.deletionPolicies.next(res);
      });
  }

  formGroupIsValid() {
    return this.formGroup.valid && (
      (this.formGroup.controls.isCompanyPolicy.value == true || this.sites.some(x => true)) &&
      (this.formGroup.controls.deleteAttendance.value == true || (
        this.formGroup.controls.dataDeletionPolicy.value > 0 ||
        this.formGroup.controls.documentDeletionPolicy.value > 0 ||
        this.formGroup.controls.answerDeletionPolicy.value > 0
      )));
  }

  addPolicy() {
    var isCompanyPolicy = this.formGroup.controls.isCompanyPolicy.value;
    var model = {
      title: this.formGroup.controls.title.value,
      isCompanyPolicy: this.formGroup.controls.isCompanyPolicy.value,
      daysBefore: this.formGroup.controls.daysBefore.value,
      status: DataRetentionPolicyStatus.Disabled,
      deleteAttendance: this.formGroup.controls.deleteAttendance.value,
      dataDeletionPolicy: this.formGroup.controls.dataDeletionPolicy.value,
      documentDeletionPolicy: this.formGroup.controls.documentDeletionPolicy.value,
      answerDeletionPolicy: this.formGroup.controls.answerDeletionPolicy.value,
      siteIds: isCompanyPolicy ? [] : (this.sites?.length ? this.sites.map(x => x.id) : null),
    } as DataRetentionPolicyModel;

    this.inProgress = true;
    this.dataRetentionPolicyService.addPolicy(model)
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(res => {
        this.deletionPolicies.value.push(res);
        this.resetFormGroup();
        this.onCloseModal();
        if (res.status == DataRetentionPolicyStatus.Disabled) {
          var msg = "New Data Retention Policy Was Added Successfully! By default this policy is disabled and you need to activate it.";
          this.modalService.info(msg);
        }
      })
  }

  getPolicyStatus(value: boolean) {
    return value ? DataRetentionPolicyStatus.Active : DataRetentionPolicyStatus.Disabled;
  }

  getPolicyBooleanStatus(value: DataRetentionPolicyStatus) {
    return value == DataRetentionPolicyStatus.Active ? true : false;
  }

  updatePolicy() {
    var isCompanyPolicy = this.formGroup.controls.isCompanyPolicy.value;
    var model = {
      title: this.formGroup.controls.title.value,
      isCompanyPolicy: this.formGroup.controls.isCompanyPolicy.value,
      daysBefore: this.formGroup.controls.daysBefore.value,
      status: this.getPolicyStatus(this.formGroup.controls.status.value),
      deleteAttendance: this.formGroup.controls.deleteAttendance.value,
      dataDeletionPolicy: this.formGroup.controls.dataDeletionPolicy.value,
      documentDeletionPolicy: this.formGroup.controls.documentDeletionPolicy.value,
      answerDeletionPolicy: this.formGroup.controls.answerDeletionPolicy.value,
      siteIds: isCompanyPolicy ? [] : (this.sites?.length ? this.sites.map(x => x.id) : null),
    } as DataRetentionPolicyModel;
    this.inProgress = true;
    this.dataRetentionPolicyService.updatePolicy(this.formGroup.controls.id.value, model)
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(res => {
        this.getPolicies();
        this.onCloseModal();
      })
  }

  onPolicyStatusChange($event, policy: DataRetentionPolicyViewModel) {
    var status = this.getPolicyStatus($event);
    if (policy.status != status) {
      this.inProgress = true;
      this.dataRetentionPolicyService.policyStatusChange(policy.id, status)
        .pipe(finalize(() => { this.inProgress = false; }))
        .subscribe(res => {
          policy.status = res;
        })
    }
  }

  onRemovePolicy(policy: DataRetentionPolicyViewModel) {
    this.inProgress = true;
    this.dataRetentionPolicyService.deletePolicy(policy.id)
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(res => {
        var index = this.deletionPolicies.value.indexOf(policy);
        this.deletionPolicies.value.splice(index, 1);
      })
  }

  sites: BaseSiteViewModel[] = [];
  onSiteSelect($event) {
    this.sites.push($event);
  }

  onRemoveSite(site: BaseSiteViewModel) {
    var index = this.sites.indexOf(site);
    this.sites.splice(index, 1);
  }

  boolToLabel(value: boolean) {
    return value ? "Yes" : "No";
  }

  modalHandle: any = null;
  onAddNewPolicy(template) {
    this.resetFormGroup();
    this.modalHandle = this.bsModalService.show(template, {
      class: 'modal-lg'
    });
  }

  onUpdatePolicy(policy: DataRetentionPolicyViewModel, template) {
    this.formGroup.controls.id.setValue(policy.id);
    this.formGroup.controls.title.setValue(policy.title);
    this.formGroup.controls.isCompanyPolicy.setValue(policy.isCompanyPolicy);
    this.formGroup.controls.daysBefore.setValue(policy.daysBefore);
    this.formGroup.controls.status.setValue(this.getPolicyBooleanStatus(policy.status));
    this.formGroup.controls.deleteAttendance.setValue(policy.deleteAttendance);
    this.formGroup.controls.dataDeletionPolicy.setValue(policy.dataDeletionPolicy);
    this.formGroup.controls.documentDeletionPolicy.setValue(policy.documentDeletionPolicy);
    this.formGroup.controls.answerDeletionPolicy.setValue(policy.answerDeletionPolicy);
    this.sites = deepCloneJson(policy.sites);

    this.modalHandle = this.bsModalService.show(template, {
      class: 'modal-lg'
    });
  }

  resetFormGroup() {
    this.formGroup.reset();
    this.formGroup.controls.id.setValue(null);
    this.formGroup.controls.title.setValue('');
    this.formGroup.controls.isCompanyPolicy.setValue(true);
    this.formGroup.controls.deleteAttendance.setValue(true);

    this.formGroup.controls.dataDeletionPolicy.setValue(DataDeletionPolicy.None);
    this.formGroup.controls.documentDeletionPolicy.setValue(DocumentDeletionPolicy.None);
    this.formGroup.controls.answerDeletionPolicy.setValue(AnswerDeletionPolicy.None);
    this.sites = [];
  }

  onCloseModal() {
    if (this.modalHandle)
      this.modalHandle.hide()
  }

  getFakeSwitchFormControl(policy: DataRetentionPolicyViewModel) {
    if ((policy as any).fakeStatusSwitchFormControl)
      return (policy as any).fakeStatusSwitchFormControl;
    var status = this.getPolicyBooleanStatus(policy.status);
    (policy as any).fakeStatusSwitchFormControl = new FormControl(status);
    return (policy as any).fakeStatusSwitchFormControl;
  }
}
