<div cdkDropListGroup>
  <div *ngIf="items" ngDefaultControl class="sortable-wrapper question-container" id="question-container1"
    (scroll)="onScrollEventInternal($event)" style="width: 100% !important" cdkDropList [cdkDropListData]="items"
    [cdkDropListSortingDisabled]="displayOnly" [cdkDropListDisabled]="displayOnly"
    (cdkDropListDropped)="drop($event);multiSelect.dropListDropped($event);onChangeOrderInternal($event)">
    <div class="sortable-item" style="width: 100% !important" *ngFor="let x of items; let i=index;"
      [ngClass]="{'selected last': x.isSelected}" cdkDrag (cdkDragStarted)="multiSelect.dragStarted();"
      (cdkDragEnded)="multiSelect.dragEnded()">
      <div *ngTemplateOutlet="template;context:{item:x, index:i}" class="d-flex justify-content-between">

      </div>
    </div>
  </div>
</div>