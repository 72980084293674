import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  AnnouncementUpdateModel,
  AnnouncementViewModel,
  SiteAnnouncementUpdateModel,
  SiteAnnouncementViewModel,
  SitesOfAnnouncementQueryModel,
  SitesOfAnnouncementViewModel
} from '../models/announcement-viewmodel';
import { ServerResult } from '../models/server-result-data';
import { SiteActiveStatus } from '../enums/site-active-status.enum';
import { AnnouncementVisitModel } from "../models/announcement-visit";

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {
  constructor(private http: HttpClient, private errorService: ErrorService) { }
  getAll = (activeStatus: SiteActiveStatus = SiteActiveStatus.Active): Observable<AnnouncementViewModel[]> =>
    this.http.get<AnnouncementViewModel[]>(`/api/announcement/get-all/${activeStatus}`)
      .pipe(catchError(this.errorService.handleError));

  getSiteAnnouncements = (siteId): Observable<SiteAnnouncementViewModel[]> =>
    this.http.get<SiteAnnouncementViewModel[]>(`/api/announcement/get-site-announcement/${siteId}`)
      .pipe(catchError(this.errorService.handleError));

  add(model: AnnouncementUpdateModel): Observable<number> {
    return this.http.post<number>('/api/announcement/add', model)
      .pipe(catchError(this.errorService.handleError));
  }

  remove(id: number): Observable<any> {
    return this.http.get<any>(`/api/announcement/remove/${id}`)
      .pipe(catchError(this.errorService.handleError));
  }

  update(id: number, model: AnnouncementUpdateModel): Observable<boolean> {
    return this.http.post<boolean>(`/api/announcement/update/${id}`, model)
      .pipe(catchError(this.errorService.handleError));
  }

  updateSiteAnnouncement(id: number, model: SiteAnnouncementUpdateModel) {
    return this.http.post(`/api/announcement/update-site-announcement/${id}`, model)
      .pipe(catchError(this.errorService.handleError));
  }

  removeSiteAnnouncement(id: number) {
    return this.http.get(`/api/announcement/remove-site-announcement/${id}`)
      .pipe(catchError(this.errorService.handleError));
  }


  addSiteAnnouncement(model: SiteAnnouncementUpdateModel) {
    return this.http.post(`/api/announcement/add-site-announcement`, model)
      .pipe(catchError(this.errorService.handleError));
  }

  RemoveAnnouncementFromAllSitesOfCompany(id: number): Observable<ServerResult> {
    return this.http.post(`/api/announcement/remove-announcement-all-sites/${id}`, {})
      .pipe(catchError(this.errorService.handleError));
  }
  AddAnnouncementToAllSitesOfCompany(model: SiteAnnouncementUpdateModel): Observable<ServerResult> {
    return this.http.post(`/api/announcement/add-announcement-all-sites`, model)
      .pipe(catchError(this.errorService.handleError));
  }
  // NotifyAnonymousAnnouncementVisit(model: any): Observable<boolean> {
  //   return this.http.post(`/api/announcement/notify-anonymous-announcement-visit`, model)
  //     .pipe(catchError(this.errorService.handleError));
  // }
  getAnnouncementGroups(groupName: string): Observable<string[]> {
    return this.http.get(`/api/announcement/announcement-groups/${groupName}`)
      .pipe(catchError(this.errorService.handleError));
  }

  NotifyAnnouncementVisit(model: AnnouncementVisitModel): Observable<boolean> {
    return this.http.post(`/api/announcement/notify-announcement-visit`, model)
      .pipe(catchError(this.errorService.handleError));
  }

  getSitesOfAnnouncement(model: SitesOfAnnouncementQueryModel): Observable<SitesOfAnnouncementViewModel[]> {
    return this.http.post<SitesOfAnnouncementViewModel[]>(`/api/announcement/get-all-sites-of-announcement`, model)
      .pipe(catchError(this.errorService.handleError));
  }
}
