<obc-loader [show]="inprogress"></obc-loader>
<div class="mb-2">
  <obc-additional-company-host #hosts></obc-additional-company-host>
</div>
<div class="mb-2">
  <obc-minimal-add-site #sites></obc-minimal-add-site>
</div>
<div class="row mb-5">
  <div class="col-md-12 text-right">
    <button class="btn btn-success"
    [disabled]="!(hosts.valid && sites.valid)"
    (click)="onQuickSetup(hosts.getHosts(), sites.getSites())">Quick Setup</button>
  </div>
</div>



