import { Component, Input } from '@angular/core';
import { ControlValueAccessor, FormArray, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomFormControl } from 'src/app/models/custom-formcontrol';
import { CheckListItem, CheckListItemViewModel } from './check-list-item';

@Component({
  selector: 'obc-check-list-form-control',
  templateUrl: './check-list-form-control.component.html',
  styleUrls: ['./check-list-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckListFormControlComponent,
      multi: true,
    }
  ]
})
export class CheckListFormControlComponent implements ControlValueAccessor {
  onChange: Function;
  formArray: FormArray = null;
  @Input() formClass: string = '';
  @Input() colClass: string = "col-md-6";
  @Input() set values(data: CheckListItemViewModel[]) {
    this.formArray = new FormArray([]);
    var cpcs = data.map((item) => {
      var cfc = new CustomFormControl(item.value, item);
      cfc.valueChanges.subscribe(res => {
        this.valueChanges();
      });
      return cfc;
    });
    this.formArray.controls.push(...cpcs);
  };
  constructor() { }
  writeValue(obj: CheckListItem[]): void {
    for (let index = 0; index < this.formArray.controls.length; index++) {
      const element = this.formArray.controls[index] as CustomFormControl;
      this.formArray.controls[index].setValue(element.item?.value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  ngOnInit() {
  }

  result: any[] = [];
  valueChanges() {
    this.result = [];
    for (let index = 0; index < this.formArray.controls.length; index++) {
      const element = this.formArray.controls[index] as CustomFormControl;
      element.item.value = element.value;
      this.result.push(element.item);
    }
    if (this.onChange)
      this.onChange(this.result);
  }

  getCustomFormControl(c) {
    return c as CustomFormControl;
  }
}
