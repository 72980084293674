import {Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, SimpleChanges} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {SupplierDocumentTypeViewModel} from "../../models/supplier-document/supplier-document-models";
import {SupplierDocumentService} from "../../services/supplier-document.service";
import {SiteSupplierViewModel} from "../../models/supplier-document/site-supplier-view-model.model";
import {NgSelectComponent} from "@ng-select/ng-select";
import {BaseSiteViewModel} from "../../models/site-viewmodel";
import { SiteSupplierService } from 'src/app/services/site-supplier.service';

@Component({
  selector: 'obc-supplier-document-selector',
  templateUrl: './supplier-document-selector.component.html',
  styleUrls: ['./supplier-document-selector.component.scss']
})
export class SupplierDocumentSelectorComponent implements OnInit {

  @ViewChild('selector') selector: NgSelectComponent;
  @Input() siteId;
  @Input() supplierId;
  @Input() excludedDocumentTypes: SupplierDocumentTypeViewModel[] = [];


  @Input() set control(value: FormControl) {
    this.formControl = value;
    this.formControl.valueChanges.subscribe(res => {
      this.selectedDocumentType.emit(this.supplierDocumentTypes?.find(x => x.id == res));
    })
  };
  formControl: FormControl;
  loading: boolean = false;
  $supplierDocumentTypes: Observable<SupplierDocumentTypeViewModel[]>;
  supplierDocumentTypes: SupplierDocumentTypeViewModel[];
  supplierDocumentTypesBackUp: SupplierDocumentTypeViewModel[];
  term$ = new BehaviorSubject<string>(null);
  @Output() selectedDocumentType = new EventEmitter();
  //@Input() supplierHashLink : string = null;


  constructor(private supplierDocumentService: SupplierDocumentService,
    private siteSupplierService: SiteSupplierService) { }

  ngOnInit() {

    // if(this.supplierHashLink) {
    //   this.siteSupplierService.getSupplierDocumentTypes(this.supplierHashLink).subscribe(res => {
    //     this.supplierDocumentTypesBackUp = res.data;
    //     this.supplierDocumentTypes = JSON.parse(JSON.stringify(res.data));
    //     this._update(this.excludedDocumentTypes);
    //     this.term$.subscribe(term => {
    //       this.$supplierDocumentTypes = of(this.supplierDocumentTypes);
    //     });
    //   })
    // } else {

    // }

    this.supplierDocumentService.getSupplierDocumentTypes().subscribe(res => {
      this.supplierDocumentTypesBackUp = res.data;
      this.supplierDocumentTypes = JSON.parse(JSON.stringify(res.data));
      this._update(this.excludedDocumentTypes);
      this.term$.subscribe(term => {
        this.$supplierDocumentTypes = of(this.supplierDocumentTypes);
      });
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    let exDocTypes = changes['excludedDocumentTypes']?.currentValue;
    if(exDocTypes) {
      this._update(exDocTypes);
    }
  }

  _update(excludedDocumentTypes: SupplierDocumentTypeViewModel[]) {
    this.supplierDocumentTypes = this.supplierDocumentTypesBackUp?.filter(function(el){
      return excludedDocumentTypes.filter(function(selected_el){
        return selected_el.id == el.id;
      }).length == 0
    });
    this.$supplierDocumentTypes = of(this.supplierDocumentTypes);
    setTimeout(() => {
      this.selector?.handleClearClick();
    }, 0);
  }

}
