<ul obc-document-type-viewer-ul
    [canAdd]="canAdd"
    [canUpdate]="canUpdate"
    [canDelete]="canDelete"
    [draggable]="draggable"
    [isRoot]="true"
    [children]="rootDocuments"
    [allDocuments]="documents"
    [cacheUniqueId]="cacheUniqueId"
    (orderedList)="onOrderedList($event)"
></ul>
