import { Pipe, PipeTransform } from '@angular/core';
import { FormRegisterFor } from "../enums/form-register-for";

@Pipe({
  name: 'formRegisterFor'
})
export class FormRegisterForPipe implements PipeTransform {

  transform(value: unknown, ...args: any[]): unknown {
    if (value === FormRegisterFor.Supplier) return args?.length > 0 ? args[0].supplierName ?? '' : "Supplier";
    if (value === FormRegisterFor.MySelf) return args?.length > 0 ? args[0].userName ?? '' : "Self";
    return value?.toString();
  }

}
