import { pairwise } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { Router, NavigationEnd, RoutesRecognized, Scroll } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LayoutService } from "./services/layout.service";
import { CaptchaMode } from './enums/captcha-mode';
import { GoogleAnalyticsService } from './services/google-analytics.service';

@Component({
  selector: 'obc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  pageYOffset: number;
  title = 'obc-portal';

  private store: { [key: string]: [number, number] } = {};
  private captchaCheckThreshold = 50;

  constructor(private router: Router,
    private layoutService: LayoutService,
    public readonly viewportScroller: ViewportScroller,
    private readonly googleAnalyticsService: GoogleAnalyticsService) {

    this.layoutService.toggleDarkMode(this.layoutService.isInDarkMode);

    router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: NavigationEnd[]) => {
        var prevUrl = events[0].urlAfterRedirects;
        this.store[prevUrl] = this.viewportScroller.getScrollPosition();
      });

    this.router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: any) => {
      var currentUrl = e.routerEvent.url;
      if (this.store[currentUrl] != undefined)
        setTimeout(() => {
          this.viewportScroller.scrollToPosition(this.store[currentUrl]);
        }, 1000);
    });

    let removeRecaptcha = localStorage.getItem("captcha-mode");
    if (removeRecaptcha == CaptchaMode.GoogleRecaptchaV3.toString()) {
      let captchaCheckInterval = setInterval(() => {
        if (this.captchaCheckThreshold < 1) {
          clearInterval(captchaCheckInterval);
        }
        else {
          var el = document.querySelector('.grecaptcha-badge') as HTMLElement;
          if (el) {
            el.style.setProperty("visibility", "visible", "important");
            clearInterval(captchaCheckInterval);
          }
        }

        this.captchaCheckThreshold--;
      }, 100);
    }
  }

  ngOnInit() {
    this.googleAnalyticsService.initialize();
  }
}
