import {Component, OnInit, TemplateRef} from '@angular/core';
import {ModalService} from '../../../services/modal.service';
import {AsyncResult} from '../../../models/helper-models';
import {QuestionViewModel} from '../../../models/question-viewmodel';
import {QuestionService} from '../../../services/question.service';
import {debounceTime, finalize} from 'rxjs/operators';
import {LayoutService} from 'src/app/services/layout.service';
import {QuestionType} from 'src/app/enums/question-type';
import {UserService} from 'src/app/services/user.service';
import {FormControl} from '@angular/forms';
import {pagingModel} from 'src/app/models/paging_model';
import {QuestionListQueryModel} from 'src/app/models/question-list-query-model';
import {PaginationInfo} from 'src/app/models/pagination-info';
import {getPageSizeByUrl} from "../../../helpers/general-functions";

@Component({
  selector: 'obc-question-manager',
  templateUrl: './question-manager.component.html',
  styleUrls: ['./question-manager.component.scss']
})
export class QuestionManagerComponent implements OnInit {
  pagingInfo: pagingModel = {
    pageNumber: 0,
    pageSize: getPageSizeByUrl() ?? 30,
    totalCount: 0,
  }
  filterListTerm: string;
  filterFormcontrol = new FormControl();

  modalRef: any;
  questionList: QuestionViewModel[];
  errorMessage = '';
  selectedQuestion: QuestionViewModel;
  inProgress: boolean = false;
  _questionType = QuestionType;
  showActiveQuestions: boolean;
  isCompanyAdmin = false;
  isCompanyTemplate = false;
  addQuestionToAllSites: boolean;

  constructor(private modalService: ModalService, private userService: UserService, private questionService: QuestionService, private layoutService: LayoutService) {
    this.getQuestionList(true);
  }

  ngOnInit(): void {
    this.layoutService.header = "Questions";
    this.isCompanyTemplate = this.userService.isTemplateCompany;
    this.userService.currentCompany$.subscribe(res => this.isCompanyTemplate = res.isTemplate);
    this.isCompanyAdmin = this.userService.isCompanyAdminUser();
    this.userService.info.subscribe(res => this.isCompanyAdmin = res?.userPermissions?.isCompanyAdmin);

    this.filterFormcontrol.valueChanges.pipe(
      debounceTime(500),
    ).subscribe((searchTerm) => {
      this.filterListTerm = searchTerm;
      if (searchTerm == '' || (searchTerm != null && searchTerm.length > 3)) {
        this.getQuestionList(this.showActiveQuestions, true, 0);
      }
    });
  }
  createQuery(): QuestionListQueryModel {
    var query = {
      pagingInfo: {
        pageNumber: this.pagingInfo.pageNumber,
        pageSize: this.pagingInfo.pageSize,
      },
      searchTerm: this.filterListTerm,
    };
    return query;
  }
  getQuestionList(showActiveQuestions, reload?, pageNumber?: number) {
    if (this.showActiveQuestions == showActiveQuestions && reload != true) return;
    this.showActiveQuestions = showActiveQuestions;
    this.errorMessage = '';
    this.inProgress = true;

    if (pageNumber != null && pageNumber != undefined)
      this.pagingInfo.pageNumber = pageNumber;
    var query = this.createQuery();

    if (this.showActiveQuestions) {
      this.questionService.getActiveQuestions(query)
        .pipe(finalize(() => { this.inProgress = false; }))
        .subscribe(res => {
          this.questionList = res.data.map(x => x = new QuestionViewModel(x));
          this.pagingInfo.totalCount = res.totalCount;
        }, error => { this.errorMessage = 'We were not able to retrieve the questions'; }, () => { });

    }
    else {
      this.questionService.getInactiveQuestions(query)
        .pipe(finalize(() => { this.inProgress = false; }))
        .subscribe(res => {
          this.questionList = res.data.map(x => x = new QuestionViewModel(x));
          this.pagingInfo.totalCount = res.totalCount;
        }, error => { this.errorMessage = 'We were not able to retrieve the questions'; }, () => { });
    }
  }

  openModal(template, selectedQuestion: QuestionViewModel = null) {
    this.selectedQuestion = selectedQuestion;
    this.modalService.show(template, "modal-xl");
  }

  closeModal(result: AsyncResult) {
    try {
      this.modalService.hide();
    } catch {

    }
    if (result.isSucceeded)
      this.getQuestionList(this.showActiveQuestions, true);
  }

  deleteQuestion(questionId) {
    this.modalService.confirm('Are you sure?', 'delete question').subscribe(iamsure => {
      if (iamsure === true) {

        this.inProgress = true;
        this.questionService.deleteQuestion(questionId)
          .pipe(finalize(() => this.inProgress = false))
          .subscribe(result => {
            if (result == true)
              this.getQuestionList(this.showActiveQuestions, true);
            else {
              this.modalService.info("Cannot delete the question because it has been used before. The question is now deactivated.")
                .subscribe(modalRes => {
                  this.getQuestionList(this.showActiveQuestions, true);
                })
            }
          }, error => {
            if (error.status == 547) {
              this.modalService.error("This question is used in Sites, CheckIn/Out or ...", "you can't remove this question");
            }

            console.log('error : ', error);
          });
      }
    });
  }

  openAddQuestionToAllSitesModal(question: QuestionViewModel, addQuestion: boolean, template: TemplateRef<any>) {
    this.selectedQuestion = question;
    this.addQuestionToAllSites = addQuestion;
    this.modalRef = this.modalService.show(template, 'modal-lg');
  }
  onPageChange(paginationInfo: PaginationInfo) {
    this.pagingInfo.pageSize = paginationInfo.pageSize;
    this.getQuestionList(this.showActiveQuestions, true, paginationInfo.pageNumber);
  }
}
