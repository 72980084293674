<div class="card-body">
  <obc-loader [show]="inProgress"></obc-loader>
  <div class="row">
    <div class="col-12">
      <button class="btn btn-default mb-1" [routerLink]="['/scannables']">
        <i class="fas fa-arrow-left"></i> Return to Group List
      </button>
      <form class="form-horizontal form-bordered" [formGroup]="formGroup" (ngFormSubmit)="onAddItem()">
        <section class="card">
          <div class="card-body">
            <div class="form-group row">
              <label class="col-sm-4 control-label text-sm-left pt-2">Select Group:</label>
              <div class="col-sm-8">
                <div class="input-group">
                  <select class="form-control" (change)="changeGroup()" formControlName="scannableGroupId">
                    <option *ngFor="let group of groups" [ngValue]="group.id">{{group.name}}</option>
                  </select>
                  <div class="input-group-append">
                    <button (click)="getGroups()" class="btn btn-sm btn-info">Refresh</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="card">
          <div class="card-body">
            <div class="form-group row">
              <label class="col-sm-4 control-label text-sm-left pt-2">Name:</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="name">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 control-label text-sm-left pt-2">Description:</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="description">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 control-label text-sm-left pt-2">Url:</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" formControlName="url">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 control-label text-sm-left pt-2">File:</label>
              <div class="col-sm-8">
                <obc-file-upload
                  [accept]="FileUploadAcceptType.Image"
                  formControlName="file"></obc-file-upload>
              </div>
            </div>
          </div>
          <footer class="card-footer text-right">
            <button type="reset" (click)="resetFormGroup()" class="btn btn-default">Reset Form</button>
            <button class="btn btn-primary" [disabled]="!formGroup.valid" (click)="onAddItem()">
              Add New Item
            </button>
          </footer>
        </section>
      </form>
    </div>
    <hr class="w-100">
    <div class="col-12 mb-2">
      <p style="text-align: center;" *ngIf="items == null">
        Loading ...
      </p>
      <p style="text-align: center;" *ngIf="items != null && items.length == 0">
        Nothing Found
      </p>
      <table *ngIf="items && items.length" class="table table-bordered table-striped mb-0">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Url</th>
            <th>File</th>
            <th>Group</th>
            <th></th>
          </tr>
          <tr>
            <td colspan="6">
              <input type="text" class="form-control" placeholder="Filter Result by Name, Description"
                [(ngModel)]="filterListTerm" />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of (items | filter:filterListTerm:['name','description'])">
            <td>
              <span>{{item.name}}</span>
            </td>
            <td>
              <span>{{item.description}}</span>
            </td>
            <td>
              <a *ngIf="item.url" target="_blank" href="{{item.url}}" class="btn btn-sm btn-info">Open Url</a>
            </td>
            <td>
              <obc-file-viewer
                *ngIf="item.storedFileUrl"
                [urls]="[item.storedFileUrl]"
                [btnTitle]="'View File'"
                [btnClass]="'btn btn-sm btn-info'"
              ></obc-file-viewer>
            </td>
            <td>
              <span>{{getGroupName(item)}} <sub>{{getGroupLabel(item)}}</sub></span>
            </td>
            <td>
              <div class="d-flex flex-row-reverse mb-0" style="text-align: end;">
                <button class="btn btn-sm btn-danger  mr-1" (click)="onRemoveItem(item)">
                  <i class="fa fa-times"></i>
                </button>
                <button class="btn btn-sm btn-primary  mr-1" (click)="onShowUpdateItem(editItemModal, item)">
                  <i class="fa fa-edit"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #editItemModal>
  <div class="card">
    <obc-loader [show]="inEditProgress"></obc-loader>
    <div class="card-header">
      <span>Edit Item</span>
      <button type="button" class="close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <form class="form-horizontal form-bordered" [formGroup]="editFormGroup" (ngFormSubmit)="onUpdateItem()">
            <section class="card">
              <div class="card-body">
                <div class="form-group row">
                  <label class="col-sm-4 control-label text-sm-left pt-2">Name:</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="name">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 control-label text-sm-left pt-2">Description:</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="description">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 control-label text-sm-left pt-2">Gorup:</label>
                  <div class="col-sm-8">
                    <select class="form-control" formControlName="scannableGroupId">
                      <option *ngFor="let group of groups" [ngValue]="group.id">{{group.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 control-label text-sm-left pt-2">Url:</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="url">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 control-label text-sm-left pt-2">File:</label>
                  <div class="col-sm-8">
                    <obc-file-upload
                      [accept]="FileUploadAcceptType.Image"
                      [disabled]="editFormGroup.controls['removeFile'].value"
                      formControlName="file">
                    </obc-file-upload>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 control-label text-sm-left pt-2"></label>
                  <div class="col-sm-8">
                    <input type="checkbox" class="form-check-input" formControlName="removeFile">
                    <label class="form-check-label" for="exampleCheck1">Remove File</label>
                  </div>
                </div>
              </div>
              <footer class="card-footer text-right">
                <button class="btn btn-primary" [disabled]="!editFormGroup.valid" (click)="onUpdateItem()">
                  Save Changes
                </button>
              </footer>
            </section>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
