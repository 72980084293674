import { Pipe, PipeTransform } from '@angular/core';
import { AccessControlInductionMode, AccessControlSiteBriefingMode } from '../enums/access-control-automatic-attendance-mode';

@Pipe({
  name: 'accessControlSiteBriefingMode'
})
export class AccessControlSiteBriefingModePipe implements PipeTransform {

  transform(value: any): string {
    if (value === AccessControlSiteBriefingMode.NoRestriction) { return "No Restrictions"; }
    if (value === AccessControlSiteBriefingMode.RequiredSignOffForAllActiveSiteBriefings) { return "Require Sign-off on All Mandatory Site Briefings"; }
    return value?.toString() ?? '?';
  }

}
