import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from "ngx-bootstrap/modal";
import { FilterType } from 'src/app/enums/filter-type.enum';
import { Table } from 'src/app/enums/table.enum';
import { AttendanceDocumentReportViewModel } from 'src/app/models/attendance-document-report-viewmodel';
import { GridColumn, GridConfig, GridTemplate, RequestPayloadModeEnum } from 'src/app/models/grid/grid.config';
import { TableSetting } from 'src/app/models/table-setting';
import { LayoutService } from 'src/app/services/layout.service';
import { ModalService } from 'src/app/services/modal.service';
import { SupplierDocumentService } from 'src/app/services/supplier-document.service';

@Component({
  selector: 'obc-other-site-supplier-document-report',
  templateUrl: './other-site-supplier-document-report.component.html',
  styleUrls: ['./other-site-supplier-document-report.component.scss']
})
export class OtherSiteSupplierDocumentReportComponent implements OnInit {
  attendanceId: string;

  constructor(
    private route: ActivatedRoute,
    private layoutService: LayoutService,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private supplierDocumentService: SupplierDocumentService
  ) { }

  handleParam(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.route.queryParams.subscribe((param) => {
        this.route.params.subscribe((param) => {
          resolve(param['attId']);
        });
      });
    });
  }

  ngOnInit() {
    this.layoutService.header = `Supplier Document Report (SDS)`;
    this.handleParam().then(id => {
      this.attendanceId = id;
        this.prepareGridConfig();
    });

  }

  @ViewChild('grid') grid: any;
  gridColumns: GridColumn<AttendanceDocumentReportViewModel>[] = [];
  gridConfig: GridConfig = {} as GridConfig;

  prepareGridConfig() {
    this.gridConfig = new GridConfig({
      apiUrl: '/api/SupplierDocument/site-supplier-document-sm',
      tableType: Table.SWMSReport,
      displayGeneralSearch: true,
      generalSearchPlaceholder: "Filter result by Title, Supplier Name, Version Date and Document Type",
      requestPayloadMode: RequestPayloadModeEnum.SeparateQueryKey,
      initialFilters: [{ key: "SiteAttendanceId", value: this.attendanceId }]
    });
    this.gridColumns = [
      {
        name: "Supplier",
        key: "supplierName",
        sortBy: "supplier",
        type: FilterType.Supplier,
        propertyNameInQuery: 'supplierIdList',
      },
      {
        name: "Document Title",
        key: "supplierDocumentTypeName",
        sortBy: "title",
        type: FilterType.DocumentType,
        propertyNameInQuery: 'supplierIdList',
        enableFilter: false,
      },
      {
        name: "Expiration Date",
        key: "expirationDate",
        sortBy: "expirationDate",
        type: FilterType.DateTimeRange,
        isArray: false,
        propertyNameInQuery: 'expirationDateRange',
      },
      {
        name: "Date Submitted",
        type: FilterType.DateTimeRange,
        key: "registerDateTime",
        propertyNameInQuery: 'submitDateRange',
        isArray: false,
      },
      {
        name: "Version",
        key: "displayVersion",
        sortBy: "version",
        propertyNameInQuery: 'statusList',
        enableFilter: false,
      },
      {
        name: "Review Status",
        type: FilterType.SiteSupplierDocumentVersionStatus,
        key: "status",
        sortBy: "status",
        propertyNameInQuery: 'statusList',
      },
      {
        name: "Document History",
        key: 'documentHistory',
        enableSort: false,
        enableFilter: false,
        visible: false,
        showAsColumn: false,
        displayInCheckBoxSettings: true,
      },
      {
        name: "See Deactivated Sites",
        key: 'includeDisableSites',
        enableSort: false,
        enableFilter: false,
        visible: false,
        showAsColumn: false,
        hasIncludeFilter: true,
        propertyNameInQuery: "includeDisableSites",
        displayInCheckBoxSettings: true,
      },
      {
        name: "Details",
        key: 'viewDetails',
        type: FilterType.Template,
        enableSort: false,
        enableFilter: false,
        gridTemplate: new GridTemplate().ComponentTemplateName('viewDetails'),
        visible: true,
      }
    ];
  }

  data;

  openModal(template, data) {
    this.data = data;
    this.modalService.show(template, "modal-lg");
  }

  closeModal() {
    this.grid.loadData(0);
  }

  exportPdf(siteSupplierDocumentVersionId: number) {
    this.supplierDocumentService.exportDataAsPdf(siteSupplierDocumentVersionId);
  }

  siteSupplierDocumentTypeId = null;

  onViewVersions(template: any, siteSupplierDocumentTypeId: number) {
    this.siteSupplierDocumentTypeId = siteSupplierDocumentTypeId;
    this.bsModalService.show(template, {
      class: "modal-lg",
    })
  }

  checkGridSettingsForHistoryButton(settings: TableSetting) {
    return !!settings.columnSettings?.find((item) => item.key == 'documentHistory' && item.visible == true);
  }
}



