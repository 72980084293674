import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { QuestionViewModel } from 'src/app/models/question-viewmodel';
import { QuestionType } from 'src/app/enums/question-type';
import { ItemTitleValue, AsyncResult } from 'src/app/models/helper-models';
import { QuestionTrigger } from 'src/app/models/question-trigger';
import { QuestionService } from 'src/app/services/question.service';
import { CompanyTagService } from 'src/app/services/company-tag.service';
import { Subject } from 'rxjs';
import { ChipModel } from 'src/app/models/chip-model';
import { map } from 'rxjs/operators';
import { CompanyTagViewModel } from 'src/app/models/company-tag';
import { UserService } from 'src/app/services/user.service';
import { TriggerType } from 'src/app/enums/trigger-type.enum';
import { Constants } from 'src/app/enums/constants';


@Component({
  selector: 'obc-question-triggers',
  templateUrl: './question-triggers.component.html',
  styleUrls: ['./question-triggers.component.scss']
})
export class QuestionTriggersComponent implements OnInit, OnDestroy {
  pagTitle = 'please add trigger(s) and press submit.';
  @Output() public result = new EventEmitter<AsyncResult>();
  _question: QuestionViewModel = new QuestionViewModel();
  _questionType = QuestionType;
  inProgress: boolean = false;
  inProgress$: Subject<boolean> = new Subject();
  items: ItemTitleValue[] = [];
  conditionValue: any;
  showLeaveEmptyInTextModeCheckBox: boolean = false;
  textQuestionValueMode: number = 0;
  isCompanyAdmin: boolean = false;
  triggerType = TriggerType;
  trigger: QuestionTrigger = this.newTrigger;
  triggers: QuestionTrigger[] = [];
  Constants = Constants;

  get valueLabel(): string {
    switch (this._question.questionType) {
      case QuestionType.Text: return 'When a user enters:';
      case QuestionType.SingleSelect:
      case QuestionType.File:
      case QuestionType.SignaturePad:
      case QuestionType.Boolean: return 'When a user selects';
      case QuestionType.MultiSelect: return 'When a user selects all of';
    }
    return '';
  }
  @Input() set question(_question: QuestionViewModel) {
    if (_question) {
      this.pagTitle = 'please edit trigger(s) and press submit.'
    }

    this._question = JSON.parse(JSON.stringify(_question ? new QuestionViewModel(_question) : new QuestionViewModel()));
    this.triggers = this._question.questionParametersAsString["triggers"] || [];
    switch (_question.questionType) {
      case QuestionType.Text:
        if (_question.questionParametersAsString?.isRequired === false)
          this.showLeaveEmptyInTextModeCheckBox = true;
        break;
      case QuestionType.Boolean:
        this.items = [{ itemTitle: 'Yes', itemValue: 'Yes' }, { itemTitle: 'No', itemValue: 'No' }]
        if (_question.questionParametersAsString?.isRequired === false)
          this.items.push({ itemTitle: 'No Select', itemValue: null });
        break;
      case QuestionType.File:
        this.items = [{ itemTitle: 'Upload File', itemValue: '1' }, { itemTitle: 'Not Upload File', itemValue: null }]
        break;
      case QuestionType.SignaturePad:
        this.items = [{ itemTitle: 'Sign', itemValue: '1' }, { itemTitle: 'Not Sign', itemValue: null }]
        break;
      case QuestionType.SingleSelect:
      case QuestionType.MultiSelect:
        this.items = QuestionViewModel.getQuestionOptions(this._question, false);
        break;
    }
  }

  get newTrigger(): QuestionTrigger {
    return {
      action: { flagUser: false, rejectMessage: '', rejectUser: false, tags: [] },
      condition: { value: [], operators: '=' }
    }
  }

  companyTags: ChipModel[] = [];
  constructor(private questionService: QuestionService,
    private companyTagService: CompanyTagService,
    private userService: UserService) {
    ///this.condition.value = [];
  }
  ngOnDestroy(): void {
    this.result.emit({ isFinished: true, isSucceeded: true });
  }

  ngOnInit(): void {
    this.isCompanyAdmin = this.userService.isCompanyAdminUser();
    this.userService.info.subscribe(res => this.isCompanyAdmin = res?.userPermissions?.isCompanyAdmin);
    this.companyTagService.getCompanyTags()
      .pipe(
        map(cts => {
          return cts.map((i) => {
            return { label: i.title, tagged: i, removable: true } as ChipModel;
          })
        })
      ).subscribe(res => this.companyTags = res);

    this.inProgress$.subscribe(res => {
      setTimeout(() => {
        this.inProgress = res
      }, 1)
    });
  }

  addAction() {
    if (!this.isValid) return;
    this.trigger.action.rejectMessage = this.trigger.action.rejectUser ? this.trigger.action.rejectMessage : '';
    this.triggers.push(JSON.parse(JSON.stringify(this.trigger)));
    this.trigger = JSON.parse(JSON.stringify(this.newTrigger));
    setTimeout(() => this.addTriggers(), 100);
  }

  setValue(value, event) {
    if (event.srcElement.checked === true)
      this.trigger.condition.value.push(value);
    else {
      let index = this.trigger.condition.value.map(c => c).indexOf(value);
      this.trigger.condition.value.splice(index, 1);
    }
  }

  get conditionValueCanBeNull(): boolean {
    switch (this._question.questionType) {
      case QuestionType.Boolean:
        if (this._question.questionParametersAsString?.isRequired === false)
          return true;
      case QuestionType.Text:
        if (this._question.questionParametersAsString?.isRequired === false)
          return true;
      case QuestionType.SingleSelect:
        if (this._question.questionParametersAsString?.isRequired === false)
          return true;
      case QuestionType.File:
      case QuestionType.SignaturePad:
        if (this._question.questionParametersAsString?.isRequired === false)
          return true;
    }
    return false;
  }

  errorMessage: string = '';
  get isValid(): boolean {
    setTimeout(() => this.errorMessage = null, 10000);

    if (this._question.questionType === this._questionType.Text) {
      if (this.textQuestionValueMode === 1)
        this.conditionValue = '';
      else if (this.textQuestionValueMode === 2) {
        this.conditionValue = Constants.TEXT_IS_ANYTHING_CONDITION_VALUE;
      }
    }

    // 1 - Check Selected/Entered Value
    if (this._question.questionType === this._questionType.MultiSelect) {
      if (this.trigger.condition.value === undefined || this.trigger.condition.value.length === 0) {
        this.errorMessage = 'please select a value';
        return false;
      }
    }
    else if (this.conditionValueCanBeNull === false)
      if (this.conditionValue === undefined || this.conditionValue === null || this.conditionValue.trim() === '') {
        this.errorMessage = 'Please select/enter value!';
        return false;
      }


    // 2 : Check If User Doesn't Select Any Actions
    if (this.trigger.action.flagUser === false && this.trigger.action.rejectUser === false) {
      this.errorMessage = 'please select an action';
      return false;
    }

    if (this._question.questionType !== this._questionType.MultiSelect)
      this.trigger.condition.value.push(this.conditionValue);

    if (this.triggers.length === 0)
      return true;

    if (this.triggers.map(c => this.valuesEqual(c.condition.value, this.trigger.condition.value))
      .find(c => c === true)) {
      this.errorMessage = 'selected value(s) is duplicated';
      this.trigger.condition.value = [];
      return false;
    }

    this.errorMessage = '';
    return true;
  }

  deleteTrigger(value) {
    let index = this.triggers.map(c => this.valuesEqual(c.condition.value, value)).indexOf(true);
    this.triggers.splice(index, 1);
    setTimeout(() => this.addTriggers(), 100);
  }

  addTriggers() {
    this._question.questionParametersAsString["triggers"] = this.triggers;
    this._question.questionParameters = JSON.stringify(this._question.questionParametersAsString);
    this.inProgress = true;
    this.questionService.updateQuestion(this._question)
      .subscribe(res => {
        this.inProgress = false;
        //this.result.emit({ isFinished: true, isSucceeded: true });
      }, err => { this.errorMessage = 'We could not update the question.'; });

  }

  cancel() {
    this.result.emit({ isFinished: true, isSucceeded: true });
  }

  valuesEqual(a, b) {
    if (a == null || b == null) return false;
    if (a.length != b.length) return false;
    if (a === b) return true;
    var arr1 = a.concat().sort();
    var arr2 = b.concat().sort();
    // console.log('a :', arr1);
    // console.log('b :', arr2);

    for (var i = 0; i < arr1.length; ++i) {
      if (!arr1[i] && !arr2[i])
        return true;

      if (!arr1[i] || !arr2[i])
        return false;

      if (arr1[i].trim() !== arr2[i].trim()) return false;
    }
    return true;
  }

  onTagChanges(chips: ChipModel[]) {
    this.trigger.action.tags = chips.map((c) => {
      if (c.tagged)
        return c.tagged as CompanyTagViewModel;
      else
        return { title: c.label, id: null };
    })
  }

}
