import { EvacuationContentEditorType, SupplierDocumentEditorType } from "../enums/supplier-document-editor-type";

export function GetAllPlaceHolders(editorType: SupplierDocumentEditorType | EvacuationContentEditorType): string[] {
  switch (editorType) {
    case SupplierDocumentEditorType.RequestEmailContent:
      return ['[SupplierName]', '[SiteName]', '[UploadLink]'];
    case SupplierDocumentEditorType.FilloutRequestEmailContent:
      return ['[SupplierName]', '[UploadLink]'];
    case SupplierDocumentEditorType.FullOnboardingEmailContent:
      return ['[SupplierName]', '[CompanyTitle]', '[Link]', '[MobileNumber]'];
    case SupplierDocumentEditorType.OnboardingEmailOffsiteLinkTemplate:
      return ['[SiteName]', '[OffsiteLink]', '[OffsiteQR]'];
    case SupplierDocumentEditorType.DocumentVersionCommentEmailContent:
      return ['[UserFullName]', '[CompanyTitle]', '[DocumentTitle]', '[SiteName]', '[Comment]', '[Acknowledgement]'];
    case EvacuationContentEditorType.EvacuationNotificationContent:
      return []
    default: return null;
  }
}
