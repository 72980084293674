<div class="card notification-panel {{isDark?'dark':''}}" *ngIf="!hide">
  <obc-loader [show]="inProgress"></obc-loader>
  <div class="card-header">

    <strong>Notifications <span class="badge badge-danger"
        *ngIf="unreadNotificationCount>0">{{unreadNotificationCount}}</span></strong>

    <button type="button" class="btn btn-sm btn-danger ml-1 pull-right" (click)="hidePanel();">
      <i class="fa fa-times"></i>
    </button>
    <a class="pointerbtn pull-right ml-1" title="Notification Settings" role="menuitem" tabindex="-1"
      [routerLink]="['/user/notification-settings']">
      <button type="button" class="btn btn-sm btn-primary pull-right"><i class="fas fa-cog"></i></button>
    </a>
    <button
      [disabled]="!unreadNotificationCount"
      type="button" class="btn btn-sm btn-success ml-1 pull-right" (click)="MarkAllAsRead();">Mark All As
      Read</button>
    <a class=" btn btn-sm btn-info pointerbtn pull-right" role="menuitem" tabindex="-1" (click)="loadNotifications()"><i
        class="fas fa-sync-alt"></i></a>
  </div>
  <div class="card-body">
    <div class="notification-container" (scroll)="onScroll($event)" #scroll>
      <div class="text-center" *ngIf="notifications.length == 0">
        <span class="text-muted">No notifications</span>
      </div>
      <div *ngFor="let notif of notifications"
        class="notification-item {{notif.isRead ? 'notification-read' : 'notification-unread'}}">
        <div class="row">
          <div class="col-md-12">
            <div>
              <div>
                <strong><i class="{{notif.isRead ? 'fas fa-envelope-open' : 'fas fa-envelope'}}"></i> {{
                  notif.title }}</strong>
                <div class="pull-right">
                  {{ notif.dateTime | datetime }}
                </div>
                <div class="clearfix"></div>
              </div>
              <div>
                {{ notif.content }}
              </div>
              <div style="height: 30px;">
                <span *ngIf="notif.companyName" class="badge badge-info">{{notif.companyName}}</span>
                <i class="pull-right operation-btn pointer text-danger fas fa-trash-alt mr-2"
                  (click)="RemoveNotification(notif);" title="Delete"></i>
                <i class="pull-right operation-btn pointer {{notif.isRead ? 'fas fa-check-circle' : 'fas fa-circle'}}  mr-2"
                  title="Mark as {{notif.isRead ?'Unread':'Read'}}" (click)="SwitchReadState(notif);"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
