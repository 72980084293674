import { Pipe, PipeTransform } from '@angular/core';
import {SiteAssetRequestApproveStatus} from "../enums/site-asset-request-approve-status.enum";

@Pipe({
  name: 'filledFormEntityStatusColor'
})
export class FilledFormEntityStatusColorPipe implements PipeTransform {

  transform(value: SiteAssetRequestApproveStatus): string {
    var cssClass = "";
    if (value == SiteAssetRequestApproveStatus.AutoApproved) cssClass = "";
    if (value == SiteAssetRequestApproveStatus.Pending) cssClass = "text-warning";
    if (value == SiteAssetRequestApproveStatus.Approved) cssClass = "text-success";
    if (value == SiteAssetRequestApproveStatus.Rejected) cssClass = "text-danger";
    return cssClass;
  }

}
