<div class="card">
  <header class="card-header">
    <div class="card-actions">
      <a (click)="cancel()" class="card-action card-action-dismiss" data-card-dismiss=""></a>
    </div>
    <h2 class="card-title">Manage Display Question Conditions</h2>
  </header>
  <div class="card-body" *ngIf="allQuestions && allQuestions.length">
    <div class="row">
      <obc-loader [show]="inprocess"></obc-loader>
      <div class="col-md-12">
        <div [formGroup]="formGroup">
          <div>
            <obc-question-extended-title *ngIf="question" [question]="question"></obc-question-extended-title>
          </div>
          <hr>
          <div>
            <label> If Question </label>
            <div>
              <ng-select class="pb-0" [items]="questions" bindLabel="questionText"
                         [multiple]="false" placeholder="Select A Question" clearAllText="Clear"
                         [clearable]="!question || (question && question.conditions?.length == 0)"
                         formControlName="masterQuestion"
                         >
              </ng-select>
            </div>
          </div>
          <div *ngIf="masterQuestion" class="mt-2">
            <label> Was Answered </label>
            <app-answer-selector [question]="masterQuestion" formControlName="answers"></app-answer-selector>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-end mt-1">
          <button class="btn sm btn-primary" [disabled]="!formGroup.valid" (click)="onAddCondition()">Add Condition</button>
        </div>
      </div>
      <div class="col-md-12 mt-2" *ngIf="question.conditions && question.conditions.length">
        <div class="table-responsive">
          <table class="table table-bordered table-striped mb-0 obc-table">
            <thead>
              <tr>
                <th>
                  Title
                </th>
                <th>
                  Answer(s)
                </th>
                <th>
                  Action
                </th>
              </tr>
            </thead>
            <tr *ngFor="let c of question.conditions">
              <!-- <td>{{c.id}}</td> -->
              <td>{{getQuestionTitle(c.masterQuestionId)}}</td>
              <td>{{c.answerTitles ?  c.answerTitles : c.answers}}</td>
              <td>
                <div class="d-flex flex-row justify-content-center">
                  <a class="on-default remove-row pointer" (click)="onRemoveCondition(c.id)"><i class="far fa-trash-alt"></i></a>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
