<div class="card">
  <div class="card-header">
    <i *ngIf="valid" class="fas fa-check-circle text-success"></i>
    <i *ngIf="!valid" class="fas fa-times-circle text-danger"></i>
    Additional Hosts
    <button class="btn pull-right btn-info btn-sm" (click)="onAdd()">Add New Host</button>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Mobile</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of hosts.controls | toFormGroup">
            <td>
              <div class="input-group">
                <input type="text" class="form-control" [formControl]="row.controls.mobile" placeholder="Mobile">
              </div>
            </td>
            <td>
              <div class="input-group">
                <input type="text" class="form-control" [formControl]="row.controls.firstName" placeholder="First Name">
              </div>
            </td>
            <td>
              <div class="input-group">
                <input type="text" class="form-control" [formControl]="row.controls.lastName" placeholder="Last Name">
              </div>
            </td>
            <td>
              <button class="btn btn-danger btn-sm full-width" (click)="onRemove(row)">
                <i class="fa fa-times"></i> Remove</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
