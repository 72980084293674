<div class="row justify-content-md-center" *ngIf="!isArchiveTab">
  <div [class]=" allowedSupplierDocumentWorkflows.length==1? 'w-50':'col-xl'"
    *ngFor="let type of allowedSupplierDocumentWorkflows ,let i = index">
    <obc-supplier-document-kind-uploder [documentKindTitle]="type.title"
      (filesDragedOrSelected)="filesDragedOrSelected($event, uploadMultipleDocumentTemplate, type.workflowDocumentKindId, type.title, type.variableList)"
      [showAddButton]="selectedSiteId && isArchiveTab === false"
      (addDocumentClicked)="addDocumentClicked(type.workflowDocumentKindId, uploadMultipleDocumentTemplate, type.title, type.variableList)"
      [showRequestButton]="!isSupplierUser && selectedSiteId && isArchiveTab === false"
      (requestDocumentClicked)="requestDocumentClicked(type.workflowDocumentKindId, requestDocument, type.title)">
    </obc-supplier-document-kind-uploder>
  </div>
</div>

<div class="card  mt-4">
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <ng-container *ngIf="supplierDocumentDataModel">
      <div class="row">
        <div class="col-md-12">
          <hr />
          <ng-container *ngIf="filterHasAnyData()">
            <app-filter-list-renderer [filters]="filters" (remove)="onRemoveFilter($event)">
            </app-filter-list-renderer>
            <hr />
          </ng-container>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12 table table-responsive mb-0" style="min-height: 500px;">
          <table class="fw">
            <thead>
              <tr>
                <th>
                  <div class="d-flex flex-row justify-content-between">
                    Title
                    <obc-filter-header [items]="filterDocumentTitles" [filterType]="FilterType.Text"
                      popoverTitle="Filter By Title" (update)="onUpdateFilter(filterDocumentTitles, $event)">
                    </obc-filter-header>
                  </div>
                </th>
                <th>
                  <div class="d-flex flex-row justify-content-between">Type
                    <obc-filter-header [items]="filterDocumentKinds" [filterType]="FilterType.SupplierDocumentKind"
                      popoverTitle="Filter By Type" (update)="onUpdateFilter(filterDocumentKinds, $event)">
                    </obc-filter-header>
                  </div>
                </th>
                <th>Version</th>
                <th>
                  <div class="d-flex flex-row justify-content-between">Expiration Date
                    <obc-filter-header [addButtonLabel]="filterExpireDateRanges?.length ? 'Update' : 'Add To Filter(s)'"
                      [items]="filterExpireDateRanges" [filterType]="FilterType.DateTimeRange"
                      popoverTitle="Filter By Fill Date" (update)="onUpdateFilter(filterExpireDateRanges, $event)">
                    </obc-filter-header>
                  </div>
                </th>
                <th></th>
                <th class="text-right"></th>
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let type of supplierDocumentDataModel.data ,let i = index">
                <td>
                  <div class="d-flex flex-row">
                    <div class="checkbox archive-checkbox">
                      <label for="chkSelectedSupplierDocument{{i}}">
                        <input type="checkbox" [(ngModel)]="type.selected" id="chkSelectedSupplierDocument{{i}}">
                      </label>
                    </div>
                    <div class="d-flex flex-row">
                      <span><span class="badge bg-primary" *ngIf="type.isNewFile">New</span>
                        {{type.supplierDocumentTypeName}}</span>
                      <sup class="m-1"
                        *ngIf="showSignOffConfiguration(type.documentKindId) && getSignOffModeValue(type)">
                        <i [tooltip]="'Mandatory Sign-Off'" class="fas fa-star-of-life text-danger"></i>
                      </sup>
                      <span class="m-1 pointer" *ngIf="!isSupplierUser"
                        (click)="onEditNoneStandardSupplierDocumentType(type, renameNoneStandardSupplierDocumentType)">
                        <i tooltip="Added by supplier" class="fas fa-pen text-danger"></i>
                      </span>
                    </div>
                  </div>
                </td>
                <td>{{type.documentKindName}}</td>
                <td>
                  {{type.version}}
                  <small *ngIf="type.version" id="emailHelp" class="form-text text-muted">({{type.versionDate}})</small>
                  <span class="badge bg-danger text-white" *ngIf="type.inactivated">Inactivated</span>
                </td>
                <td>
                  <span class="text-nowrap" *ngIf="type.expirationDate">
                    {{type.expirationDate | date}}
                    <ng-container *ngIf="type.isExpired">
                      <br>
                      <span class="badge bg-danger text-white">Document Expired</span>
                    </ng-container>
                  </span>
                </td>
                <td>
                  <span class="text-nowrap">
                    <i [tooltip]="(type.status | supplierDocumentTypeStatusTooltip)"
                      class="{{ type.status | supplierDocumentTypeStatusIcon }} mr-1">
                    </i>
                    {{type.status | supplierDocumentTypeStatusTooltip}}
                    <!--When State is mandatory and has not review form-->
                    <i *ngIf="!type.workflowInstanceId && !isSupplierUser && type.status == SiteSupplierDocumentStatus.Pending && hasSupplierReviewForm == false && supplierDocumentReviewMode == SupplierDocumentReviewModeEnum.Mandatory"
                      tooltip="There are no supplier document review forms." class="fas fa-exclamation-triangle mr-1">
                    </i>
                  </span>
                  <div class="justify-content-md-left">
                    <!--When (state is mandatory or optional) and has review form-->
                    <a *ngIf="type.supplierDocumentPermissions?.approvalMode == ApprovalModeEnum.WorkflowReview"
                      (click)="openWorkflowEngine(type.workflowInstanceId, workflowEngine)"
                      class="ml-2 mb-2 btn btn-sm btn-info text-white">
                      <i class="fa fa-file-invoice mr-1" aria-hidden="true"></i>
                      Review
                    </a>
                    <button *ngIf="type.supplierDocumentPermissions?.approvalMode == ApprovalModeEnum.Review"
                      (click)="onOpenformReview(formReview, type)" class="ml-2 mb-2 btn btn-sm btn-info">
                      <i class="fa fa-file-invoice mr-1" aria-hidden="true"></i>
                      Review
                    </button>
                    <!--When (state is disabled) or (state is optioanl and has not review form)-->
                    <div *ngIf="type.supplierDocumentPermissions?.approvalMode == ApprovalModeEnum.ApproveReject">
                      <button (click)="onApproveDeclineSiteSupplierVersion(true, type)"
                        class="ml-2 btn btn-sm btn-success mb-2">
                        Approve
                      </button>
                      <button (click)="onApproveDeclineSiteSupplierVersion(false, type)"
                        class="ml-1 btn btn-sm btn-danger mb-2">
                        Reject
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex">
                    <obc-file-viewer *ngIf="type.supplierDocumentPermissions.hasViewFilePermission"
                      [files]="type.supplierDocumentFiles"
                      [btnTitle]="'View File' + (type.supplierDocumentFiles?.length > 1 ? 's' : '')"
                      [btnClass]="'btn btn-success btn-sm mr-2'" [btnStyle]="'width: 80px;'"
                      [prefix]="type.supplierDocumentTypeName+'-'+type.displayVersion">
                    </obc-file-viewer>
                    <div class="btn-group" dropdown placement="top right" container="body">
                      <button id="button-basic" dropdownToggle type="button" class="btn btn-info btn-sm dropdown-toggle"
                        aria-controls="dropdown-container">
                        <i class="fa fa-solid fa-bars mr-1"></i> More Actions <span class="caret"></span>
                      </button>
                      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right commands"
                        role="menu" aria-labelledby="button-basic">
                        <ng-container
                          *ngIf="type.siteSupplierDocumentVersionId == null || type.supplierDocumentPermissions.hasEditUploadPermission">
                          <li role="menuitem">
                            <a class="dropdown-item" (click)="onUpdateDocuments(uploadDocumentTemplate, type)">
                              <i class="fa fa-upload mr-1"></i>{{type.status == SiteSupplierDocumentStatus.Requested?
                              'Upload' : 'Replace'}} Documents
                            </a>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="type?.description">
                          <li class="divider dropdown-divider"></li>
                          <li role="menuitem">
                            <obc-supplier-document-description-viewer
                              [documentTitle]="type?.supplierDocumentTypeName + ' - ' + type?.displayVersion"
                              [description]="type?.description" [icon]="'fa fa-file-alt mr-1'"
                              [classes]="'dropdown-item'">
                            </obc-supplier-document-description-viewer>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="type.formDataId > 0">
                          <li class="divider dropdown-divider"></li>
                          <li role="menuitem">
                            <a class="dropdown-item" (click)="onViewFilledForm(formView , type.formDataId)">
                              <i class="fa fa-file mr-1"></i>View Review Form
                            </a>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="type.siteSupplierDocumentFiles?.length">
                          <li class="divider dropdown-divider"></li>
                          <li role="menuitem">
                            <obc-file-viewer [files]="type.siteSupplierDocumentFiles" [btnTitle]="'View Files'"
                              [btnClass]="'dropdown-item'" [btnIcon]="'fa fa-eye mr-1'"
                              [prefix]="type.supplierDocumentTypeName+'-'+type.displayVersion"></obc-file-viewer>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="type.supplierDocumentPermissions.hasViewFilePermission">
                          <li class="divider dropdown-divider"></li>
                          <li role="menuitem">
                            <a class="dropdown-item" [href]="type.supplierDocumentFiles[0].originalFileUrl"
                              target="_blank" *ngIf="type.supplierDocumentFiles.length == 1">
                              <i class="fa fa-download mr-1"></i> Download
                            </a>
                            <a class="dropdown-item" (click)="onDownloadFile(viewDownloadFileListTemplate, type)"
                              *ngIf="type.supplierDocumentFiles.length > 1">
                              <i class="fa fa-download mr-1"></i> Download
                            </a>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="type.supplierDocumentPermissions.hasViewCommentsPermission">
                          <li class="divider dropdown-divider"></li>
                          <li role="menuitem">
                            <a class="dropdown-item" (click)="onViewComments(viewVersionCommentsTemplate, type)">
                              <i class="fa fa-comments mr-1"></i> Comments
                            </a>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="type.supplierDocumentPermissions.hasViewHistoryPermission">
                          <li class="divider dropdown-divider"></li>
                          <li role="menuitem">
                            <a class="dropdown-item" (click)="onViewHistory(viewVersionHistoryTemplate, type)">
                              <i class="fa fa-history mr-1"></i> History
                            </a>
                          </li>
                        </ng-container>
                        <ng-container
                          *ngIf="isArchiveTab === true && type.supplierDocumentPermissions.hasDeleteVersionPermission">
                          <li class="divider dropdown-divider"></li>
                          <li role="menuitem">
                            <a class="dropdown-item" (click)="onOpenDeleteForm(type)">
                              <i class="fa fa-times mr-1" aria-hidden="true"></i> Delete
                            </a>
                          </li>
                        </ng-container>

                        <ng-container
                          *ngIf="type.canChangeSupplierDocumentKind && (type.siteSupplierDocumentVersionId == null || type.supplierDocumentPermissions.hasEditUploadPermission)">
                          <li class="divider dropdown-divider"></li>
                          <li role="menuitem">
                            <a class="dropdown-item" (click)="displayChangeDocumentKindModal(changeDocumentKind, type)">
                              <i class="fa fa-retweet mr-1" aria-hidden="true"></i>
                              Change Document Kind
                            </a>
                          </li>
                        </ng-container>

                        <ng-container *ngIf="type.supplierDocumentPermissions.hasDeleteAllVersionPermission">
                          <li class="divider dropdown-divider"></li>
                          <li role="menuitem">
                            <a class="dropdown-item danger-dropdown-item" (click)="onDeleteAllFiles(type)">
                              <i class="fa fa-times mr-1" aria-hidden="true"></i>
                              {{getDeleteAllButtonTitle(type.documentKindSignOffMode)}}
                            </a>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="20">
                  <button (click)="onArchiveDocuments()"
                    *ngIf="supplierDocumentDataModel.data?.length > 0 && isArchiveTab === false && selectedSiteId && hasAnySelectedToArchive()"
                    class="btn btn-info ml-2">
                    <i class="fa fa-file-archive"></i>
                    Archive File
                  </button>
                  <button (click)="onUnarchiveDocuments()"
                    *ngIf="supplierDocumentDataModel.data?.length > 0 && isArchiveTab === true && selectedSiteId && hasAnySelectedToArchive()"
                    class="btn btn-info ml-2">
                    <i class="fa fa-file"></i>
                    Unarchive File
                  </button>
                  &nbsp;
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<!-- <div >
  <div class="p-0 pb-1 pt-1">
    <label for="">Select a Supplier Document Type</label>
    <obc-supplier-document-selector [supplierHashLink]="_siteSupplierHashlink.value"
      #supplierDocumentSelector [siteId]="supplier.siteId" [supplierId]="supplier.supplierId"
      (selectedDocumentType)="onSelectSupplierDocumentType($event)"
      [excludedDocumentTypes]="supplierDocumentTypes" [control]="supplierDocumentFormControl">
    </obc-supplier-document-selector>
    <br>
    <div class="text-right">
      <button class="btn btn-primary btn-sm" [disabled]="!supplierDocumentFormControl?.value"
        style="min-width: 140px;" (click)="onAssignDocumentTypeToSupplier()">
        Add Document
      </button>
    </div>
  </div>
</div> -->


<ng-template #workflowEngine>
  <obc-workflow-engine [workflowInstanceId]="selectedInstanceId" (workflowEningeModalHide)="closeModal()">
  </obc-workflow-engine>
</ng-template>

<ng-template #uploadDocumentTemplate>
  <app-site-supplier-document-uploader [siteSupplierId]="siteSupplierDocumentType.siteSupplierId"
    [variableList]="selectedVariableList" (hide)="uploadFileModalRef.hide()"
    (updateFileDone)="uploadFileModalRef.hide(); onSiteSupplierLoad()"
    [siteSupplierDocumentType]="siteSupplierDocumentType" [siteSupplierHashlink]="_siteSupplierHashlink | async"
    [documentKindId]="selectedDocumentKindId" [documentKindTitle]="selectedWorkflowTitle">
  </app-site-supplier-document-uploader>
</ng-template>

<ng-template #uploadMultipleDocumentTemplate>
  <obc-site-supplier-document-multiple-uploader [workflowTitle]="selectedWorkflowTitle"
    [variableList]="selectedVariableList" [siteSupplierId]="selectedSiteSupplierId" (hide)="uploadFileModalRef.hide()"
    (updateFileDone)="uploadFileModalRef.hide(); onSiteSupplierLoad()"
    [siteSupplierHashlink]="_siteSupplierHashlink | async"
    [siteSupplierDocumentTypes]="siteSupplierDocumentTypes | async" [documentKindId]="selectedDocumentKindId"
    [inputFiles]="selectedFilesToSendToModal">
  </obc-site-supplier-document-multiple-uploader>
</ng-template>

<ng-template #viewVersionHistoryTemplate>
  <obc-view-site-supplier-document-version-history
    [supplierDocCommentAcknowledgementText]="supplierDocCommentAcknowledgementText"
    [siteSupplierDocumentTypeId]="siteSupplierDocumentTypeId" [siteSupplierHashlink]="_siteSupplierHashlink | async">
  </obc-view-site-supplier-document-version-history>
</ng-template>

<ng-template #viewVersionCommentsTemplate>
  <obc-site-supplier-document-version-comments [displayAsModal]="false"
    [siteSupplierDocumentVersionId]="siteSupplierDocumentVersionId"
    [hasPermissionToAddSupplierDocumentComment]="hasPermissionToAddSupplierDocumentComment"
    [supplierDocCommentAcknowledgementText]="supplierDocCommentAcknowledgementText"
    [comments]="supplierDocumentVersionComments">
  </obc-site-supplier-document-version-comments>
</ng-template>

<ng-template #formView>
  <div>
    <div>
      <ul class="simple-post-list">
        <div class="card">
          <obc-form-data-details [siteSupplierHashlink]="_siteSupplierHashlink | async"
            [formDataId]="selectedFormDataId"></obc-form-data-details>
        </div>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #formReview>
  <obc-supplier-document-review [siteId]="selectedSiteId" [supplierId]="selectedSuplierId"
    [siteSupplierId]="selectedSiteSupplierId" [selectedSiteSupplierDocument]="selectedSiteSupplierDocument"
    [supplierDocumentReviewMode]="supplierDocumentReviewMode" (hide)="refresh()">
  </obc-supplier-document-review>
</ng-template>

<ng-template #changeDocumentKind let-data="data">
  <div class="card">
    <div class="card-header">
      <span>
        Change Document Kind
      </span>
      <button type="button" class="close" (click)="closeChangeDocumentKind()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <obc-loader [show]="inProgress"></obc-loader>
      <div class="col-12">
        <div class="d-flex">
          <div class="mr-2">Name: </div>
          <strong>{{ selectedSiteSupplierDocumentTypeToChangeType.supplierDocumentTypeName }}</strong>
        </div>
        <div class="d-flex">
          <div class="mr-2">Document Kind: </div>
          <strong>{{ selectedSiteSupplierDocumentTypeToChangeType.documentKindName }}</strong>
        </div>
      </div>
      <hr>
      <div class="col-12 mt-1" >
        <label>New Document Kind</label>
        <select class="form-control" [formControl]="changeDocumentKindIdFormControl">
          <option [value]="null" disabled>Please select a document kind</option>
          <ng-container *ngFor="let type of allowedSupplierDocumentWorkflows ,let i = index">
            <option *ngIf="type.workflowDocumentKindId !== selectedSiteSupplierDocumentTypeToChangeType.documentKindId"
                    [value]="type.workflowDocumentKindId">{{ type.title }}</option>
          </ng-container>
        </select>
      </div>
      <div class="col-12 mt-3" *ngIf="variablesOfSelectedDocumentKindToChange?.length > 0">
        <div class="mb-1"><strong>Variables</strong></div>
        <div class="p-3 card card-body">
          <obc-workflow-variables-renderer
            [formGroup]="variablesFormGroup"
            [variablesList]="variablesOfSelectedDocumentKindToChange"
          ></obc-workflow-variables-renderer>
        </div>
      </div>
      <div class="col-12 mt-2 text-right">
        <button (click)="updateSiteSupplierDocumentKind()" class="btn btn-primary"
                [disabled]="!changeDocumentKindIdFormControl.valid">
          Update
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #renameNoneStandardSupplierDocumentType>
  <div class="card">
    <div class="card-header">
      <span>
        Update Supplier Document Type Name
      </span>
      <button type="button" class="close" (click)="bsModalRefRenameDOT.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-1">
        <label>Title</label>
        <input [readonly]="this.selectedSiteSupplierDocumentTypeForRename.isStandardSupplierDocumentType"
          [formControl]="renameNoneStandardSupplierDocumentTypeFormControl" type="text" class="form-control">
      </div>
      <div class="col-md-12 mt-1" *ngIf="showDocumentTypeStatus">
        <label>Sign-Off</label>
        <obc-switch [small]="true" (valueChanged)="documentTypeStatusChanged($event)"
          [value]="getSignOffModeValue(selectedSiteSupplierDocumentTypeForRename)" [trueLabel]="'Mandatory'"
          [falseLabel]="'Optional'" [disabled]="!enableDocumentTypeStatus">
        </obc-switch>
      </div>
      <div class="col-md-12 mt-1 text-right">
        <button (click)="updateNoneStandardSupplierDocumentType()" class="btn btn-primary"
          [disabled]="!renameNoneStandardSupplierDocumentTypeFormControl.valid">
          Update
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #requestDocument>
  <div class="card">
    <div class="card-header">
      Request New {{selectedWorkflowTitle}} Document
      <button type="button" class="close" (click)="uploadFileModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="row mt-3 align-items-center align-items-end">
        <div class="col-6 col-sm-12">
          <label for="">Select a Document Type</label>
          <obc-supplier-document-selector #supplierDocumentSelector [siteId]="selectedSiteId"
            [supplierId]="selectedSuplierId" (selectedDocumentType)="onSelectSupplierDocumentType($event)"
            [excludedDocumentTypes]="selectedDocumentTypes" [control]="supplierDocumentFormControl">
          </obc-supplier-document-selector>
        </div>
        <div class="col-3 col-sm-12 col-md-6 mt-2">
          <div>
            <label>Mandatory Acceptance</label>
            <obc-switch (valueChanged)="documentTypeStatusChanged($event)" [value]="true" [trueLabel]="'Mandatory'"
              [falseLabel]="'Optional'"></obc-switch>
          </div>
        </div>
        <div class="col-3 col-sm-12 col-md-6 text-right mt-2">
          <div style=" height: 30px;"></div>
          <button class="btn btn-primary " [disabled]="!supplierDocumentFormControl?.value" style="min-width: 140px;"
            (click)="onRequestNewDocumentType()">
            Request Document
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #viewDownloadFileListTemplate>
  <div class="card">
    <div class="card-header">
      Select file to download
      <button type="button" class="close" (click)="uploadFileModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <obc-file-viewer
            [prefix]="selectedSupplierDocumentVersionViewModel.supplierDocumentTypeName+'-'+selectedSupplierDocumentVersionViewModel.displayVersion"
            [files]="selectedSupplierDocumentVersionViewModel.supplierDocumentFiles" [btnTitle]="'View Files'"
            [btnClass]="'btn-sm btn btn-primary ml-2 pull-right'" [showByModal]="false" [useOriginalFile]="true">
          </obc-file-viewer>
        </div>
      </div>
    </div>
  </div>
</ng-template>
