<div class="widget-summary">
  <div class="widget-summary-col widget-summary-col-icon">
      <div class="summary-icon {{data.backgroundClass}}">
          <i class="{{data.iconClass}}"></i>
      </div>
  </div>
  <div class="widget-summary-col">
      <div class="summary">
          <h4 class="title">{{data.widgetModel.title}}</h4>
          <div class="info">
              <strong class="amount">{{data.amount}}</strong>
          </div>
      </div>
      <div class="summary-footer">
          <a *ngIf="data.route" class="text-muted text-uppercase" [routerLink]="[data.route]">{{data.routeTitle}}</a>
      </div>
  </div>
</div>
