import { ModalService } from '../../../services/modal.service';
import { formGroupToModel } from 'src/app/helpers/general-functions';
import { BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { SiteActiveStatus } from 'src/app/enums/site-active-status.enum';
import { SiteTemplateStatus } from 'src/app/enums/site-template-status.enum';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { BaseSiteViewModel, SiteViewModel } from 'src/app/models/site-viewmodel';
import {
  CompanyUserViewModel,
  ImportCompanyUserResponse,
  SiteUserUpdateRequest,
  SiteUserViewModel
} from 'src/app/models/company-user-view-model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CheckListItemViewModel } from 'src/app/custom-controls/check-list-form-control/check-list-item';
import { UserBasicInfoViewModel } from 'src/app/models/user-basic-info-viewmodel';
import { UserAutoCompleteFilterByFields } from 'src/app/enums/user-auto-complete-target-fields';
import { e168Validator2, mobileIsValid } from 'src/app/helpers/mobile-format-validator';
import { combinePermissions } from 'src/app/helpers/permission-helper';
import { SiteUserService } from 'src/app/services/site-user.service';
import { SiteAttendancePermissionService } from '../../../services/site-attendance-permission.service';
import { SiteAttendancePermissionUser } from '../../../models/site-attendance-permission-models/site-attendance-permission-user.model';
import { SiteListQueryModel } from 'src/app/models/site-list-query-model';
import { PermissionModeEnum } from "../../../enums/permission-mode.enum";
import { CompanyRoleViewModel } from "../../../models/company-role.view.model";
import { CompanyRoleService } from "../../../services/company-role.service";
import { FileUploadAcceptType } from 'src/app/enums/file-upload-accept-type';

@Component({
  selector: 'obc-site-user',
  templateUrl: './site-user.component.html',
  styleUrls: ['./site-user.component.scss']
})
export class SiteUserComponent implements OnInit {
  FileUploadAcceptType = FileUploadAcceptType;
  PermissionModeEnum = PermissionModeEnum;
  companyPermissionMode: PermissionModeEnum;
  userPermissions = UserPermission;
  sites: SiteViewModel[] = [];
  siteId: number;
  public site: BaseSiteViewModel;
  inProgress: boolean;
  filterListTerm: string;
  siteUsers: SiteUserViewModel[] = [];
  bsModalRef: any;
  selectedUser = new UserBasicInfoViewModel();
  filterUserBy = UserAutoCompleteFilterByFields;
  siteUserFormGroup: FormGroup = null;
  siteUserPermissions: CheckListItemViewModel[] = [];
  editMode: boolean = false;

  constructor(
    private siteUserService: SiteUserService,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    public companyRoleService: CompanyRoleService,
    private siteAttendancePermissionService: SiteAttendancePermissionService) { }

  ngOnInit(): void {
    this.initData();
    this.fileFormControl.valueChanges.subscribe(res => {
      if (res == null) return;
      let formData = new FormData();
      formData.append("file", res, res.name);
      this.import(formData);
    });
  }
  createQuery(): SiteListQueryModel {
    return {
      activeStatus: SiteActiveStatus.All,
      templateStatus: SiteTemplateStatus.Normal,
      pagingInfo: null,
      searchTerm: null,
    };
  }

  getShowQrCodeWithInstruction(site: BaseSiteViewModel) {
    return `/General/site-instructions/${site.id}/${site.secretCode}`;
  }

  initData() {
    this.inProgress = true;
    this.siteUserService.getAllPermissions()
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(ret => {
        let keys = Object.keys(ret);
        this.siteUserPermissions = keys.map(up => {
          return {
            key: up,
            label: ret[up]
          } as CheckListItemViewModel;
        });
      });
    this.companyRoleService.getCompanyPermissionMode()
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(res => {
        this.companyPermissionMode = res;
      });
  }

  private getSiteUsers() {
    this.inProgress = true;
    this.siteUserService.getSiteUsers(this.siteId)
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(res => {
        this.siteUsers = res;
      });
  }

  isMobileValid(mobile: string): boolean {
    return (mobile !== undefined && mobileIsValid(mobile));
  }

  get selectedSiteName(): string {
    if (this.sites && this.sites.length)
      return this.sites.find(s => s.id == this.siteId).name;
  }

  deleteUser(user: SiteUserViewModel) {
    this.modalService.confirm("Are you sure you want remove site user?", "Remove Site User", "Yes", "No")
      .subscribe(confirm => {
        if (confirm) {
          this.inProgress = true;
          this.siteUserService.removeSiteUser(user.id, this.siteId)
            .pipe(finalize(() => { this.inProgress = false; }))
            .subscribe(res => {
              if (res) {
                let index = this.siteUsers.indexOf(user);
                this.siteUsers.splice(index, 1);
              }
            });
        }
      });
  }

  fillCompanyUser(cUser: UserBasicInfoViewModel, formGroup: FormGroup) {
    if (cUser.id) {
      this.selectedUser = cUser;

      formGroup.controls['id'].setValue(cUser.id);
      formGroup.controls['mobile'].setValue(cUser.mobile);
      formGroup.controls['firstName'].setValue(cUser.firstName);
      formGroup.controls['lastName'].setValue(cUser.lastName);
    }
  }

  onAddSiteUser(template: any) {
    this.editMode = false;
    this.selectedUser = new UserBasicInfoViewModel();
    this.siteUserFormGroup = new FormGroup({
      id: new FormControl(),
      siteId: new FormControl(this.siteId),
      firstName: new FormControl(),
      mobile: new FormControl(null, [Validators.required, e168Validator2]),
      lastName: new FormControl(),
      isAdmin: new FormControl(false),
      permissions: new FormControl([]),
      isFrequentUser: new FormControl(false),
      companyRoles: new FormControl([]),
    });
    this.bsModalRef = this.bsModalService.show(template, Object.assign({}, { class: 'gray modal-lg' }));
  }

  onUpdateSiteUser(template: any, user: SiteUserViewModel) {
    this.editMode = true;
    this.siteUserFormGroup = new FormGroup({
      id: new FormControl(user.id),
      firstName: new FormControl(user.firstName),
      mobile: new FormControl(user.mobile, [Validators.required, e168Validator2]),
      lastName: new FormControl(user.lastName),
      isAdmin: new FormControl(user.isAdmin),
      companyRoles: new FormControl(user.companyRoles),
      permissions: new FormControl(this.siteUserPermissions

        .map((item: CheckListItemViewModel) => { item.value = (user.permissions & item.key) > 0; return item; })),
      //addToFrequentUsers: new FormControl(user.isFrequentUser),
    });
    this.bsModalRef = this.bsModalService.show(template, Object.assign({}, { class: 'gray modal-lg' }));
  }

  addSiteUser() {
    let siteUser = formGroupToModel(this.siteUserFormGroup) as SiteUserViewModel;
    if (siteUser.isAdmin)
      siteUser.permissions = UserPermission.None;
    else
      siteUser.permissions = combinePermissions((this.siteUserFormGroup.controls["permissions"].value as CheckListItemViewModel[])
        ?.filter((item) => item.value)
        .map(x => x.key))
        ?? UserPermission.None;

    this.inProgress = true;
    this.siteUserService.addSiteUser(this.siteId, siteUser)
      .pipe(finalize(() => {
        this.inProgress = false;
        this.bsModalRef.hide();
      }))
      .subscribe(res => {
        this.modalService.success('Site user added successfully');
        this.siteUsers.push(res);
      }, err => {
        this.modalService.error(err.error);
      });
  }

  updateSiteUser() {
    this.inProgress = true;
    let model = {
      isAdmin: this.siteUserFormGroup.controls['isAdmin'].value,
      roles: this.siteUserFormGroup.controls.companyRoles?.value?.map((item) => item.companyRoleId) ?? [],
      permissions: this.siteUserFormGroup.controls['isAdmin'].value ?
        UserPermission.None : combinePermissions(
          (this.siteUserFormGroup.controls['permissions'].value as CheckListItemViewModel[])
            .filter(c => c.value)
            .map(x => x.key))
    } as SiteUserUpdateRequest;
    this.siteUserService.updateSiteUser(+this.siteUserFormGroup.controls['id'].value, model)
      .pipe(finalize(() => {
        this.inProgress = false;
        this.bsModalRef.hide();
      }))
      .subscribe(res => {
        let index = this.siteUsers.findIndex(x => x.id == +this.siteUserFormGroup.controls['id'].value);
        this.siteUsers[index] = res;
        this.modalService.success('Site user updated successfully');
      });
  }

  siteIdChanged(siteId: number) {
    this.siteId = siteId;
    this.getSiteUsers();
  }

  getPermissionTooltip(user: SiteUserViewModel) {
    return this.siteUserPermissions
      .filter(p => (p.key & user.permissions) > 0);
  }

  getPermissionCount(user: SiteUserViewModel) {
    return this.siteUserPermissions
      .filter(p => (p.key & user.permissions) > 0).length;
  }
  getRoles(user: CompanyUserViewModel) {
    let tips = user.companyRoles?.map(p => p.name) ?? [];
    return tips.join(", ");
  }

  addToFrequentUsers(user: SiteUserViewModel) {
    this.modalService.confirm(`Are you sure you want to add this user as frequent user to ${this.site.name}?`)
      .subscribe(accept => {
        if (accept) {
          this.siteAttendancePermissionService.addSinglePerson({ siteId: this.siteId, mobile: user.mobile, } as SiteAttendancePermissionUser)
            .subscribe(_ => this.getSiteUsers());
        }
      });
  }

  modalHandle: any;
  openImportUserModal(template) {
    this.modalHandle = this.bsModalService.show(template);
  }

  // import users
  importData: ImportCompanyUserResponse;
  fileFormControl = new FormControl(null, [Validators.required]);
  clear() {
    this.fileFormControl.setValue(null);
    this.importData = null;
  }

  onClearForm() {
    this.clear();
  }

  import(formData) {
    this.inProgress = true;
    this.siteUserService.ImportUsers(this.siteId, formData)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        if (res.type == 4 && res.ok) {
          if (res.body.valid) {
            this.modalHandle.hide();
            this.initData();
          } else
            this.importData = res.body;
        }
      });
  }

  updateRoles(roles: CompanyRoleViewModel[]) {
    this.siteUserFormGroup.controls.companyRoles.setValue(roles);
  }
}
