<div class="card" style="border:none">
  <obc-loader [show]="inProgress"></obc-loader>
  <div class="card-body" style="padding: 0;">
    <div>
      <tabset>
        <tab (selectTab)="showTab[0]=1" heading="Basic Information">
          <obc-company-profile [inProgress$]="inProgress$" *ngIf="showTab[0]"></obc-company-profile>
        </tab>
        <tab (selectTab)="showTab[1]=1" heading="Automations"
          *ngIf="userService.hasCompanyFeature(ProductPlanFeature.Automation)">
          <obc-company-trigger-action [inProgress$]="inProgress$" *ngIf="showTab[1]"></obc-company-trigger-action>
        </tab>
        <tab (selectTab)="showTab[2]=1" heading="Data Retention Policies">
          <app-data-retention-policy *ngIf="showTab[2]"></app-data-retention-policy>
        </tab>
        <tab (selectTab)="showTab[3]=1" heading="Asset Manager"
          *ngIf="userService.hasCompanyFeature(ProductPlanFeature.Inventory)">
          <obc-manage-inventory *ngIf="showTab[3]"></obc-manage-inventory>
        </tab>
        <tab (selectTab)="showTab[4]=1" heading="Visitor Types"
          *ngIf="userService.hasCompanyFeature(ProductPlanFeature.VisitorTypes)">
          <obc-manage-visitor-type *ngIf="showTab[4]"></obc-manage-visitor-type>
        </tab>
        <tab (selectTab)="showTab[5]=1" heading="Regions">
          <obc-manage-region *ngIf="showTab[5]"></obc-manage-region>
        </tab>
        <tab (selectTab)="showTab[6]=1" heading="Suppliers">
          <app-manage-supplier-documents *ngIf="showTab[6]"></app-manage-supplier-documents>
        </tab>
        <tab (selectTab)="showTab[7]=1" heading="Document Type">
          <obc-company-document-type *ngIf="showTab[7]"></obc-company-document-type>
        </tab>
        <tab (selectTab)="showTab[8]=1" heading="Feature Configuration">
          <obc-company-Feature-Configuration *ngIf="showTab[8]"></obc-company-Feature-Configuration>
        </tab>
        <tab (selectTab)="showTab[9]=1" heading="Permissions Settings">
          <obc-permissions-settings *ngIf="showTab[9]"></obc-permissions-settings>
        </tab>
      </tabset>
    </div>
  </div>
</div>