import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GlobalViewModel } from 'src/app/models/global-viewmodel';
import { SearchAssetToRegisterModel, SearchToolsQueryModel } from 'src/app/models/inventory/search-tools-query-model';
import { SiteAssetService } from 'src/app/services/site-asset.service';


@Component({
  selector: 'obc-asset-selector',
  templateUrl: './asset-selector.component.html',
  styleUrls: ['./asset-selector.component.scss']
})
export class AssetSelectorComponent implements OnInit {
  @Input() maxItems = 10;

  _required = false;
  @Input() set required(value: boolean) {
    if (value) {
      this._required = true;
      this.formControl.setValidators(Validators.required);
    }
  };

  @Input() siteId: number;
  @Input() excludeList: number[] = [];
  @Input() notSelectedSiteLabel: string = null;

  // input & outputs {
  @Output() onLoadAssets = new EventEmitter<GlobalViewModel[]>();
  @Output() assetSelect = new EventEmitter<GlobalViewModel>();
  @Output() assetIdSelect = new EventEmitter<number>();
  public selectedAsset = new BehaviorSubject<GlobalViewModel>(null);

  _assetId: number = null;
  @Input() set assetId(value: number) {
    if (!value) return;
    this._assetId = value;
    this.formControl.setValue(value);
  };


  assets: GlobalViewModel[];
  siteGroups: any[];

  constructor(private siteAssetService: SiteAssetService) { }


  formControl = new FormControl(null, [Validators.required]);
  loading: boolean = false;
  assets$: Observable<GlobalViewModel[]>;
  term$ = new BehaviorSubject<string>(null)

  ngOnInit() {
    this.formControl.valueChanges.subscribe(res => {
      if (this.assets) {
        if (res == null || res == 0) {
          this.assetSelect.emit(null);
          this.assetIdSelect.emit(null);
          this.selectedAsset.next(null);
        } else {
          var site = this.assets.find(x => x.id == res);
          if (site == null) {
          } else {
            this.assetSelect.emit(site);
            this.selectedAsset.next(site);
            this.assetIdSelect.emit(site.id);
          }
        }
      }
    });

    setTimeout(() => {
      this.getAssetList();
    }, 250)
  }

  getAssetList() {
    this.loading = true;
    let model = {
      searchTerm: '',
      siteId: this.siteId,
    } as SearchAssetToRegisterModel;
    this.siteAssetService.searchToolsToRegister(model)
      .pipe(finalize(() => this.loading = false))
      .subscribe(res => {
        this.setReponse(res);
        this.onLoadAssets?.emit(res);
      })
  }

  private filterExcludes(res: GlobalViewModel[]) {
    let that = this;
    return res.filter(function (el) {
      return that.excludeList.filter(function (selected_el) {
        return selected_el == el.id;
      }).length == 0
    });
  }

  private setReponse(res: GlobalViewModel[]) {
    this.assets = this.filterExcludes(res);

    if (this.selectedAsset.value == null) {
      var asset = this.assets.find(x => x.id == this.formControl.value);
      if (asset) {
        this.selectedAsset.next(asset);
      }
    }

    this.assets$ = of(this.getNthItems(this.assets, this.maxItems));
    this.term$.subscribe(term => {
      term = term ?? '';
      if (this.reachToEndTerm != term) {
        let model = {
          searchTerm: term,
          siteId: this.siteId,
        } as SearchAssetToRegisterModel
        this.siteAssetService.searchToolsToRegister(model)
          .subscribe(filteredSites => {
            this.reachToEndTerm == term;
            this.assets = this.filterExcludes(filteredSites);
            this.reachToEnd = false;
            this.assets$ = of(this.getNthItems(this.assets, this.maxItems));
            this.onLoadAssets?.emit(filteredSites);
          });
      }
      else {
        this.assets$ = of(this.getNthItems(this.assets, this.reachToEnd ? this.assets.length : this.maxItems))
      }
    });
  }

  getNthItems(array: GlobalViewModel[], n: number): GlobalViewModel[] {
    if (!array || array.length == 0 || n <= 0) return [];
    var max = Math.min(array.length, n);
    var arr = [];
    for (var i = 0; i < max; i++)
      arr.push(array[i]);
    if (this.selectedAsset.value && !arr.find(x => x.id == this.selectedAsset.value.id))
      arr.push(this.selectedAsset.value);
    return arr;
  }

  getSiteLabel(asset: GlobalViewModel) {
    return asset.title;
  }

  searchAssets(term: string) {
    return this.assets.filter((item) => {
      return this.isMatched(term, item);
    });
  }

  clearInput() {
    this.formControl.setValue(null);
  }

  isMatched(term: string, item: GlobalViewModel): boolean {
    if (!term) return true;
    term = term.toLowerCase();
    return (item.title && item.title.toLowerCase().indexOf(term) > -1);
  }

  reset() {
    this.formControl.reset();
    this.assets = [];
  }

  reachToEnd = false;
  reachToEndTerm = "";
  onScrollToEnd() {
    this.reachToEnd = true;
    this.reachToEndTerm = this.term$.value;
    this.term$.next(this.term$.value)
  }

}
