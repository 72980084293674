import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'obc-template-company-visitor-types',
  templateUrl: './template-company-visitor-types.component.html',
  styleUrls: ['./template-company-visitor-types.component.scss']
})
export class TemplateCompanyVisitorTypesComponent implements OnInit {

  constructor(private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.layoutService.header = "Visitor Types";
  }

}
