<obc-boolean-question-renderer
  *ngIf="question.question.questionType == QuestionType.Boolean && question.showFlagLastState" [question]="question"
  [tagged]="tagged" (valueChanges)="onValueChanges($event)">
</obc-boolean-question-renderer>
<obc-single-select-question-renderer
  *ngIf="question.question.questionType == QuestionType.SingleSelect && question.showFlagLastState"
  [question]="question" [tagged]="tagged" (valueChanges)="onValueChanges($event)">
</obc-single-select-question-renderer>
<obc-multi-select-question-renderer
  *ngIf="question.question.questionType == QuestionType.MultiSelect && question.showFlagLastState"
  [question]="question" [tagged]="tagged" (valueChanges)="onValueChanges($event)">
</obc-multi-select-question-renderer>
<obc-text-question-renderer *ngIf="question.question.questionType == QuestionType.Text && question.showFlagLastState"
                            [question]="question" [tagged]="tagged" (valueChanges)="onValueChanges($event)">
</obc-text-question-renderer>
<obc-emergency-contact-question-renderer
 *ngIf="question.question.questionType == QuestionType.EmergencyContact && question.showFlagLastState"
                            [question]="question" [tagged]="tagged" (valueChanges)="onValueChanges($event)">
</obc-emergency-contact-question-renderer>
<obc-date-question-renderer *ngIf="question.question.questionType == QuestionType.Date && question.showFlagLastState"
                            [question]="question" [tagged]="tagged" (valueChanges)="onValueChanges($event)">
</obc-date-question-renderer>
<obc-file-question-renderer
  [isEditMode]="isEditMode"
  *ngIf="question.question.questionType == QuestionType.File && question.showFlagLastState && !question.question.document" [question]="question"
                            [tagged]="tagged" (valueChanges)="onValueChanges($event)">
</obc-file-question-renderer>
<obc-question-title-renderer *ngIf="question.question.questionType == QuestionType.RichText && question.showFlagLastState"
                             [question]="question.question"></obc-question-title-renderer>
<obc-signature-pad-question-renderer *ngIf="question.question.questionType == QuestionType.SignaturePad && question.showFlagLastState" [question]="question"
                            [tagged]="tagged" (valueChanges)="onValueChanges($event)">
</obc-signature-pad-question-renderer>
