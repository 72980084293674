<div class="row">
  <div class="col-md-12">
    <div class="card">
      <header class="card-header">
        <h5 class="mb-1">Card History</h5>
        <div class="card-actions">
          <a data-card-dismiss="" class="card-action card-action-dismiss" (click)="closePopup()"></a>
        </div>
      </header>
      <div class="card-body">
        <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>
        </app-base-grid>
      </div>
    </div>
  </div>
</div>
