import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ErrorService } from "./error.service";
import { Observable } from "rxjs/internal/Observable";
import { ServerResult, ServerResultData } from "../models/server-result-data";
import { FormViewModel, FormViewModelRequest } from "../models/form/form-view-model";
import { catchError } from "rxjs/operators";
import { FormDataViewModel } from "../models/form/form-data-view-model";
import { SupplierPermitInfo } from "../models/form/permits-info-model";
import { FormDataModel } from "../models/form/form-data-model";

@Injectable({
  providedIn: 'root'
})
export class SupplierPermitsService {

  apiUrl: string = "/api/SupplierPermits";
  constructor(private http: HttpClient, private _errorService: ErrorService) { }

  getPermitList(hashLink: string, siteSupplierId: number): Observable<SupplierPermitInfo> {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this.http.get<SupplierPermitInfo>(`${this.apiUrl}/permits` + (siteSupplierId ? '/' + siteSupplierId : ''), { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }
  getPermitForms(hashLink: string, siteSupplierId?: number): Observable<ServerResultData<FormViewModel[]>> {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this.http.get<ServerResultData<FormViewModel[]>>(`${this.apiUrl}/permit-forms${siteSupplierId == null ? '' : '/' + siteSupplierId}`, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }
  getFormInformation(formId: number, siteId: number, hashLink: string, siteSupplierId: number, toolId: number = null): Observable<ServerResultData<FormDataViewModel>> {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this.http.post<ServerResultData<FormDataViewModel>>(`${this.apiUrl}/form`,
      {
        formId: formId,
        siteId: siteId,
        siteSupplierId: siteSupplierId,
        toolId: toolId,
      } as FormViewModelRequest, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }
  submitFormData(data: FormDataModel, hashLink: string, siteSupplierId: number): Observable<ServerResult> {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    data.endDate = data.endDate == null ? null :
      new Date(data.endDate.getFullYear()
        , data.endDate.getMonth()
        , data.endDate.getDate()
        , 23, 59, 59);
    return this.http.post<ServerResult>(`${this.apiUrl}/fill-form` + (siteSupplierId ? '/' + siteSupplierId : ''), data, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

}
