<div class="card">
  <obc-loader [show]="inProgress"></obc-loader>
  <div class="card-body">
    <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>

      <!-- Register Time -->
      <ng-template obcGridTemplateName="registerTime" let-item="data">
        {{item.registeredClientViewModel?.registerTime|datetime}}
      </ng-template>

      <!-- Site Name -->
      <ng-template obcGridTemplateName="siteName" let-item="data">
        {{item.siteAccessControlViewModel?.siteName}}
      </ng-template>

      <!-- Additional Info -->
      <ng-template obcGridTemplateName="additionalInfo" let-item="data">
        <ul>
          <li *ngFor="let additionalInfo of item?.registeredClientViewModel?.additionalInfo|keyvalue">
            {{additionalInfo.key}}: {{additionalInfo.value}}
          </li>
        </ul>
      </ng-template>

      <!-- keyboardInputDevice -->
      <ng-template obcGridTemplateName="keyboardInputDevice" let-item="data">
        <p *ngIf="item?.registeredClientViewModel?.keyboardInputDevices">Last Sync: {{item?.registeredClientViewModel?.keyboardInputDeviceRefreshDateTime|datetime}}</p>
        <ul>
          <li *ngFor="let keyboardInputDeviceDic of item?.registeredClientViewModel?.keyboardInputDevices">
            {{keyboardInputDeviceDic.Dev}}: {{keyboardInputDeviceDic.DevPath}}
          </li>
        </ul>
      </ng-template>

      <!-- Open Gate & Show Log -->
      <ng-template obcGridTemplateName="toggle" let-item="data">
        <div *ngFor="let accessControlUnit of item?.siteAccessControlViewModel?.accessControlUnits; let i = index" class="mb-1">
          <div style="display: flex;">
            <span *ngFor="let gate of accessControlUnit.accessControlUnitGates" [attr.type]="gate.accessControlUnitGateType">
              <button type="button"
                      *ngIf="gate.accessControlUnitGateType != 3"
                      class="btn btn-{{!item.registeredClientViewModel?'outline-secondary':gate.accessControlUnitGateType == 1 ? 'success':gate.accessControlUnitGateType == 2 ? 'primary':gate.accessControlUnitGateStatus == 1 ?'danger':'info'}}"
                      (click)="handleGate(accessControlUnit,gate,item.siteAccessControlViewModel.siteId)"
                      [disabled]="!item.registeredClientViewModel">
                <i [class]="gate.accessControlUnitGateType == 3?gate.accessControlUnitGateStatus == 2?'fa fa-door-open':'fa fa-door-closed':''"></i>
                {{getGateButtonTitle(gate)}}
              </button>
            </span>
          </div>
        </div>

        <!-- log -->
        <div style="display: flex;" class="mb-1">
          <span [attr.type]="1">
            <button type="button"
                    class="btn btn-{{!item.registeredClientViewModel?'outline-secondary':'primary-scale-2'}}"
                    [disabled]="!item.registeredClientViewModel"
                    (click)="showClientLogs(logTemplate,item)">
              <i class="fa fa-list-alt"></i>
              Show Log
            </button>
          </span>
        </div>

        <!-- Toggle Docker -->
        <div style="display: flex;" class="mb-1">
          <span [attr.type]="1">
            <input type="text" #name="ngModel" name="name" class="form-control" placeholder="Command Params"
                   [disabled]="!item.registeredClientViewModel"
                   [(ngModel)]="item.toggle">
          </span>
        </div>
        <div style="display: flex;">
          <span [attr.type]="1">
            <button type="button" class="btn btn-{{!item.registeredClientViewModel?'outline-secondary':'warning'}}"
                    [disabled]="!item.registeredClientViewModel"
                    (click)="toggle(item)">
              <i class="fa fa-list-alt"></i>
              Toggle
            </button>
          </span>
        </div>
      </ng-template>

      <!-- log template -->
      <ng-template #logTemplate>
        <section class="card" #modal>
          <header class="card-header">
            <h5>Client Logs ({{identifire}})</h5>
            <div class="card-actions">
            </div>
          </header>
          <div class="card-body">
            <app-base-grid [columns]="logGridColumns" [configs]="logGridConfig" #grid>

              <!-- Show Json button -->
              <ng-template obcGridTemplateName="showJson" let-item="data">
                <button type="button" class="btn btn-primary"
                        (click)="showLogModelJson(logModelJson,item)">
                  <i class="fa fa-code"></i>
                  Show Json
                </button>
              </ng-template>

              <!-- logModelJson -->
              <ng-template #logModelJson>
                <section class="card" #modal>
                  <header class="card-header">
                    <h5>Log.Model</h5>
                    <div class="card-actions">
                    </div>
                  </header>
                  <div class="card-body">
                    {{logModel|json}}
                  </div>
                </section>
              </ng-template>

            </app-base-grid>
          </div>
        </section>
      </ng-template>
    </app-base-grid>
  </div>
</div>
