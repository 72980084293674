import { Component, Input, OnInit } from '@angular/core';
import { EmergencyContactViewModel } from 'src/app/models/emergency-contact-view-model';

@Component({
  selector: 'obc-emergency-contact-viewer',
  templateUrl: './emergency-contact-viewer.component.html',
  styleUrls: ['./emergency-contact-viewer.component.scss']
})
export class EmergencyContactViewerComponent implements OnInit {

  @Input() data: string;
  @Input() displayInRow: boolean = false;
  @Input() labelStyle: string;
  @Input() displayDots: boolean = true;
  dataObject: EmergencyContactViewModel;
  constructor() { }

  ngOnInit(): void {
    if(this.data != null) {
      try {
        this.dataObject = JSON.parse(this.data);
      } catch(_) {
        this.dataObject = EmergencyContactViewModel.newEmptyAnswer();
      }
    }
  }

  isNullOrEmptyEmergencyContact(): boolean {
    return !this.dataObject ||
      (!this.dataObject.mobile && !this.dataObject.name && !this.dataObject.relationship) ||
      (
        this.dataObject.mobile?.trim() == '' &&
        this.dataObject.name?.trim() == '' &&
        this.dataObject.relationship?.trim() == ''
      );
  }

  isNameValid(): boolean {
    return this.dataObject?.name && this.dataObject?.name != '';
  }
  isMobileValid(): boolean {
    return this.dataObject?.mobile && this.dataObject?.mobile != '';
  }
  isRelationshipValid(): boolean {
    return this.dataObject?.mobile && this.dataObject?.mobile != '';
  }

}
