import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: '[obc-radio-form-group]',
  templateUrl: './radio-form-group.component.html',
  styleUrls: ['./radio-form-group.component.scss'],
})
export class RadioFormGroupComponent<T> implements OnInit {
  @Input() options!: T[];
  @Input() title: string;
  @Input() labels: string[];
  @Input() formGroup: FormGroup;
  @Input() controlName: FormGroup;
  @Input() labelTooltip: string;

  constructor() { }

  ngOnInit(): void {
  }

}
