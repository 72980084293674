<obc-loader [show]="inProgress"></obc-loader>
<div class="row">
  <div class="col-md-12">
    <obc-create-announcement [model]="null" (announcementCreated)="onAnnouncementCreated($event)">
    </obc-create-announcement>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card-header mb-0 mt-3">
      <span class="mr-5">
        <span class="d-inline-block mt-2 mr-1">Display Mode: </span>
        <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [value]="true"
          (valueChanged)="onActiveChanged($event)">
        </obc-switch>
      </span>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <td class="sortable" (click)="sortBy('model.title')">Title</td>
            <td class="sortable" (click)="sortBy('model.type')">Type</td>
            <td class="sortable" (click)="sortBy('model.askMode')">Display Mode</td>
            <td>Data / Link</td>
            <td class="sortable" (click)="sortBy('model.groupName')">Group Name</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let an of (announcements | sort: sortColumn :desc)">
            <tr *ngIf="an.mode == 'view'">
              <td>
                {{an.model.title}}
                <span style="color: red;" *ngIf="an.model.processingStatus != announcementProcessStatuses.Completed">
                  [in
                  progress] </span>
              </td>
              <td>{{getAnnouncementTypeLabel(an.model.type)}}
                <div *ngIf="an.model.type == 5" class="text-info" style="font-size: 12px;">
                  ({{an.model.displayMode | announcementDisplayMode }})
                </div>
              </td>
              <td>{{an.model.askMode | askMode}}</td>
              <td>
                <div *ngIf="isDataModel(an.model.type)">
                  <div *ngIf="!isModelDataUrl(an.model.type)">
                    {{an.model.data}}
                  </div>
                  <div *ngIf="isModelDataUrl(an.model.type)" style="text-align: end;">
                    <a target="_blank" *ngIf="an.model.data" class="btn btn-sm btn-info text-white"
                      [href]="an.model.data">Open Link</a>
                  </div>
                </div>
                <div *ngIf="isFileModel(an.model.type)" style="text-align: end;">
                  <a target="_blank" *ngIf="an.model.url" class="btn btn-sm btn-primary text-white"
                    [href]="an.model.url">Download</a>
                </div>
              </td>
              <td>
                {{an.model.groupName}}
              </td>
              <td>
                <div class="d-flex">
                  <button class="btn btn-sm text-primary" (click)="onSwitchMode(an)"><i
                      class="fas fa-edit"></i></button>
                  <button [tooltip]="'View Sites'" class="btn btn-sm text-info navbar-btn includer"
                    (click)="onViewSites(sitesModal, an.model)">
                    <i class="fas fa-archway"> </i>
                    <span class="badge badge-danger">{{an.model.sites.length}}</span>
                  </button>
                  <button *ngIf="!an.model.sites || an.model.sites.length == 0" [tooltip]="'Remove'"
                    class="btn btn-sm text-danger" (click)="onRemove(an.model)"><i
                      class="fas fa-trash-alt"></i></button>
                  <button [tooltip]="'Remove from all sites'" class="btn btn-sm text-danger"
                    (click)="removeFromAllSitesOfCompany(an.model.id)" *ngIf="!isTemplateCompany"><i
                      class="fas fa-trash"></i></button>
                  <button [tooltip]="'Add to all sites'" class="btn btn-sm text-danger"
                    (click)="openAddToAllSitesOfCompanyModal(addToAllSitesOfCompany,an.model)"
                    *ngIf="!isTemplateCompany"><i class="fas fa-plus-circle"></i></button>
                </div>
              </td>
            </tr>
            <ng-container *ngIf="an.mode == 'edit'" [formGroup]="an.formGroup">
              <tr>
                <td colspan="6">
                  <obc-create-announcement [model]="an" (announcementUpdated)="onAnnouncementUpdated($event)">
                  </obc-create-announcement>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #sitesModal>
  <div class="card">
    <div class="card-header">
      <span>
        Sites of announcement: {{selectAnnouncement.title}}
      </span>
      <button type="button" class="close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="card-body">
      <div class="d-flex justify-content-center">
        <button class="btn btn-info align-self-center mr-2"
          (click)="openAddToAllSitesOfCompanyModal(addToAllSitesOfCompany,selectAnnouncement)">Add to all sites</button>
        <button class="btn btn-danger align-self-center mr-2"
          (click)="removeFromAllSitesOfCompany(selectAnnouncement.id)">Remove from all sites</button>
      </div>

      <div class="row mt-2 mb-2">
        <div class="col-md-10">
          <obc-site-selector-withexternal (siteSelect)="onChangeSelectedSite($event)">
          </obc-site-selector-withexternal>
        </div>
        <div class="col-md-2">
          <button class="mr-1 btn btn-success" [disabled]="siteId == null"
            (click)="openAddSitesToAnnouncement(addSiteToAnnouncementDialog,selectAnnouncement)">Add site</button>
        </div>
      </div>

      <div style="overflow: auto; max-height: calc(100vh - 250px);"
        *ngIf="sitesOfAnnouncement && sitesOfAnnouncement.length> 0">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <td>Site</td>
              <td style="width:20%;"></td>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let site of sitesOfAnnouncement">
              <tr>
                <td>{{site.siteName}}</td>
                <td>
                  <button tooltip="Remove from announcement" (click)="RemoveSiteFromAnnouncement(site)"
                    class="mr-1 btn btn-default btn-danger">
                    <span class="fa fa-trash"></span>
                    Remove
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addToAllSitesOfCompany>
  <div class="card">
    <div class="card-header">
      <span>
        Add To All Sites
      </span>
      <button type="button" class="close" (click)="modalService.hide(addToAllSiteModalref)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 mt-3">
          <form [formGroup]="newAnnouncementForAll.formGroup">
            <div class="row">
              <div class="form-group col-12 col-sm-6 col-lg-4">
                <label>Valid From</label>
                <obc-input-date-time-picker [formControl]="newAnnouncementForAll.formGroup.get('validFrom')"
                  [placeholder]="'Pick From Date'" [mode]="datetimePickerMode.Calender">
                </obc-input-date-time-picker>
                <!-- <input [owlDateTime]="dt1" class="form-control"
                  [formControl]="newAnnouncementForAll.formGroup.get('validFrom')" [owlDateTimeTrigger]="dt1"
                  placeholder="Pick From Date">
                <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time> -->
              </div>
              <div class="form-group col-12 col-sm-6 col-lg-4">
                <label>Valid To</label>
                <obc-input-date-time-picker [formControl]="newAnnouncementForAll.formGroup.get('validTo')"
                  [placeholder]="'Pick To Date'" [mode]="datetimePickerMode.Calender">
                </obc-input-date-time-picker>
                <!-- <input style="z-index: 10000;" [owlDateTime]="dt2" class="form-control"
                  [formControl]="newAnnouncementForAll.formGroup.get('validTo')" [owlDateTimeTrigger]="dt2"
                  placeholder="Pick To Date">
                <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time> -->
              </div>
              <div class="form-group col-12 col-sm-6 col-lg-4">
                <label>Display Time</label>
                <select class="custom-select form-control"
                  [formControl]="newAnnouncementForAll.formGroup.get('displayTime')">
                  <option *ngFor="let index of SiteAnnouncementDisplayTime | enumToArray"
                    [ngValue]="SiteAnnouncementDisplayTime[index]">
                    {{SiteAnnouncementDisplayTime[index] | siteAnnouncementDisplayTime }}
                  </option>
                </select>
              </div>
              <div
                *ngIf="newAnnouncementForAll.formGroup.get('displayTime').value != SiteAnnouncementDisplayTime.WhileCheckin"
                class="form-group col-12 col-sm-6 col-lg-4">
                <label>Mode</label>
                <select class="custom-select form-control" [formControl]="newAnnouncementForAll.formGroup.get('mode')">
                  <option *ngFor="let index of announcementMode | enumToArray" [ngValue]="announcementMode[index]">
                    {{announcementMode[index] | announcementMode }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3 col-sm-12">
                <label>Force View Mode</label>
                <select class="custom-select form-control"
                  [formControl]="newAnnouncementForAll.formGroup.get('forceViewMode')">
                  <option *ngFor="let index of SiteAnnouncementForceViewMode | enumToArray"
                    [ngValue]="SiteAnnouncementForceViewMode[index]">
                    {{SiteAnnouncementForceViewMode[index] | siteAnnouncementForceViewMode }}
                  </option>
                </select>
              </div>
            </div>
          </form>
          <div class="form-group" style="text-align: end;">
            <button [disabled]="!newAnnouncementForAll.formGroup.valid" class="btn btn-primary"
              (click)="addToAllSitesOfCompanyModal()">
              Add To All
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addSiteToAnnouncementDialog>
  <div class="card">
    <div class="card-header">
      <span>
        Add site to announcement
      </span>
      <button type="button" class="close" (click)="modalService.hide(addSiteToAnnouncementModalref)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 mt-3">
          <form [formGroup]="newAnnouncementForAll.formGroup">
            <div class="row">
              <div class="form-group col-12 col-sm-6 col-lg-4">
                <label>Valid From</label>
                <obc-input-date-time-picker [formControl]="newAnnouncementForAll.formGroup.get('validFrom')"
                  [placeholder]="'Pick From Date'" [mode]="datetimePickerMode.Calender">
                </obc-input-date-time-picker>
                <!-- <input [owlDateTime]="dt1" class="form-control"
                  [formControl]="newAnnouncementForAll.formGroup.get('validFrom')" [owlDateTimeTrigger]="dt1"
                  placeholder="Pick From Date">
                <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time> -->
              </div>
              <div class="form-group col-12 col-sm-6 col-lg-4">
                <label>Valid To</label>
                <obc-input-date-time-picker [formControl]="newAnnouncementForAll.formGroup.get('validTo')"
                  [placeholder]="'Pick To Date'" [mode]="datetimePickerMode.Calender">
                </obc-input-date-time-picker>
                <!-- <input style="z-index: 10000;" [owlDateTime]="dt2" class="form-control"
                  [formControl]="newAnnouncementForAll.formGroup.get('validTo')" [owlDateTimeTrigger]="dt2"
                  placeholder="Pick To Date">
                <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time> -->
              </div>
              <div class="form-group col-12 col-sm-6 col-lg-4">
                <label>Display Time</label>
                <select class="custom-select form-control"
                  [formControl]="newAnnouncementForAll.formGroup.get('displayTime')">
                  <option *ngFor="let index of SiteAnnouncementDisplayTime | enumToArray"
                    [ngValue]="SiteAnnouncementDisplayTime[index]">
                    {{SiteAnnouncementDisplayTime[index] | siteAnnouncementDisplayTime }}
                  </option>
                </select>
              </div>
              <div
                *ngIf="newAnnouncementForAll.formGroup.get('displayTime').value != SiteAnnouncementDisplayTime.WhileCheckin"
                class="form-group col-12 col-sm-6 col-lg-4">
                <label>Mode</label>
                <select class="custom-select form-control" [formControl]="newAnnouncementForAll.formGroup.get('mode')">
                  <option *ngFor="let index of announcementMode | enumToArray" [ngValue]="announcementMode[index]">
                    {{announcementMode[index] | announcementMode }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3 col-sm-12">
                <label>Force View Mode</label>
                <select class="custom-select form-control"
                  [formControl]="newAnnouncementForAll.formGroup.get('forceViewMode')">
                  <option *ngFor="let index of SiteAnnouncementForceViewMode | enumToArray"
                    [ngValue]="SiteAnnouncementForceViewMode[index]">
                    {{SiteAnnouncementForceViewMode[index] | siteAnnouncementForceViewMode }}
                  </option>
                </select>
              </div>
            </div>
          </form>
          <div class="form-group" style="text-align: end;">
            <button [disabled]="!newAnnouncementForAll.formGroup.valid" class="btn btn-primary"
              (click)="addSiteToAnnouncementClick()">
              Add site to announcement
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
