import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CompanySiteScope } from 'src/app/enums/company-site-scope';
import { InductionMode } from 'src/app/enums/site-induction-mode.enum';
import { deepClone, getCountLabel, isEqual } from 'src/app/helpers/general-functions';
import { InductionViewModel, SiteRequiredInductionViewModel } from 'src/app/models/induction-viewmodel';
import { InductionService } from 'src/app/services/induction-service';
import { ModalService } from 'src/app/services/modal.service';
import { InductionSelectorComponent } from '../../induction/induction-selector/induction-selector.component';
import { ManageSiteViewModel } from "../../../models/manage-site-view-model";
import { DirtyComponents } from "../../../models/dirty-components";

@Component({
  selector: 'app-site-induction',
  templateUrl: './site-induction.component.html',
  styles: []
})
export class SiteInductionComponent implements OnInit {

  dirtyComponents: DirtyComponents;
  @Input('dirtyComponents') set setDirtyComponents(dirtyComponents: DirtyComponents) {
    this.dirtyComponents = dirtyComponents;
  }
  makeComponentDirty() {
    this.dirtyComponents.inductions.isDirty = !isEqual(this.dirtyComponents?.inductions?.snapshot, this.myCopy());
  }
  myCopy() {
    return {
      siteInductions: deepClone(this.manageSiteModel?.inductions),
    };
  }


  manageSiteModel: ManageSiteViewModel;
  @Input() set manageSiteViewModel(manageSiteModel: ManageSiteViewModel) {
    this.manageSiteModel = manageSiteModel;
    this.siteInductions = manageSiteModel?.inductions;

    if (this.manageSiteModel && this.manageSiteModel.inductions) {
      this.dirtyComponents.inductions.snapshot = this.myCopy();
    }
  }

  @Input() noCard: boolean = false;
  _siteId = new BehaviorSubject<number>(0);
  @Input() set siteId(value: number) {
    if (this._siteId.value != value)
      this._siteId.next(value);
  };
  inductions = new BehaviorSubject<InductionViewModel[]>([]);
  siteInductions: SiteRequiredInductionViewModel[];
  inProgress = false;
  InductionMode = InductionMode;
  formGroup = new FormGroup({
    id: new FormControl(null, []),
    inductionId: new FormControl(null, [Validators.required]),
    //InductionMode: new FormControl(null, [Validators.required])
  });

  get headerInfo() {
    return getCountLabel(this.siteInductions?.length, 'Induction');
  }

  constructor(private inductionService: InductionService,
    private modalService: ModalService,
    private bsModalService: BsModalService) { }

  ngOnInit() {
    this.inProgress = true;
    this.inductionService.getInductions(CompanySiteScope.Site, InductionMode.Auto, InductionMode.HostApprove)
      .pipe(
        finalize(() => { this.inProgress = false }),
      )
      .subscribe(res => {
        this.inductions.next(res);

      });
  }


  onRemoveSiteInduction(model: SiteRequiredInductionViewModel) {
    var index = this.siteInductions?.indexOf(model);
    this.siteInductions?.splice(index, 1);

    this.makeComponentDirty();
  }

  onAddSiteInduction(induction: InductionViewModel) {
    var model = {
      inductionId: induction.id,
      induction: induction.title,
      inductionKey: induction.inductionKey,
      mode: induction.mode
    } as SiteRequiredInductionViewModel;
    var index = this.siteInductions?.indexOf(model);
    if (index == -1)
      this.siteInductions?.push(model);
  }

  getInductionModeLabel(mode: InductionMode) {
    switch (mode) {
      case InductionMode.Auto:
        return "Auto Approve";
      case InductionMode.Disable:
        return "Disable";
      case InductionMode.HostApprove:
        return "Manual";
    }
  }

  onOpenAddInduction() {
    this.bsModalService.show(InductionSelectorComponent, {
      class: "modal-lg",
      initialState: {
        title: "Select Induction(s)",
        inductions: this.inductions.value,
        addedInductions: this.siteInductions?.map(x => x.inductionId),
        addInduction: ($event) => {
          this.onAddSiteInduction($event);
          this.makeComponentDirty();
        },
      },
    })
  }
}
