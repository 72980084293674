<div class="card">
  <div class="card-header">
    <h5>Add/Edit Videos</h5>
    <button (click)="onAdd(addEditVideo)" class="btn btn-success card-header-button"><i class="fas fa-plus mr-1"></i>Add
      New Video</button>
  </div>
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="d-flex">
      <form [formGroup]="searchFormGroup" class="form-inline">
        <div class="input-group mb-1 mr-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Field </span>
          </div>
          <select class="custom-select form-control" formControlName="orderByField">
            <option value="order">Order</option>
            <option value="system">System</option>
            <option value="title">Title</option>
            <option value="page">Page</option>
            <option value="category">Category</option>
          </select>
        </div>
        <div class="input-group mb-1 mr-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Order BY</span>
          </div>
          <select class="custom-select form-control" formControlName="orderDesc">
            <option [ngValue]="1">Desc</option>
            <option [ngValue]="-1">Asc</option>
          </select>
        </div>
        <div class="input-group mb-1 mr-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Query</span>
          </div>
          <input type="text" class="form-control" formControlName="query">
        </div>
      </form>
    </div>
    <div style="display: flex;">
      <table *ngIf="_videos" class="table table-bordered table-striped mb-0">
        <thead>
          <tr>
            <th>Order</th>
            <th>System</th>
            <th>Title</th>
            <th>Page</th>
            <th>Category</th>
            <th style="width: 230px;">Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let video of videos">
            <tr >
              <td>{{video.order}}</td>
              <td>{{getSystemName(video.system)}}</td>
              <td>{{video.title}}</td>
              <td>{{video.pageURL}}</td>
              <td>{{video.category}}</td>
              <td style="text-align: center;">
                <img style="width: 100%;" src="{{video.imageURL}}">
              </td>
              <td>
                <p class="button-group">
                  <button (click)="onEdit(addEditVideo, video)" class="btn btn-xs btn-info no-min-width">
                    <i class="fas fa-edit"></i>
                  </button>
                  <button (click)="onRemove(video)" class="btn btn-xs btn-danger no-min-width">
                    <i class="fas fa-times"></i>
                  </button>
                </p>
              </td>
            </tr>
            <!-- <tr>
              <td colspan="7">{{video.description}}</td>
            </tr> -->
          </ng-container>
          
          
        </tbody>
      </table>
    </div>

  </div>
</div>

<ng-template #addEditVideo>
  <div class="card" *ngIf="formGroup">
    <obc-loader [show]="inAddEditProgress"></obc-loader>
    <div class="card-header">
      <span *ngIf="formGroup.get('id').value > 0">Edit Video</span>
      <span *ngIf="formGroup.get('id').value == 0">Add New Video</span>
      <button type="button" class="close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <form [formGroup]="formGroup">
        <input type="hidden" formControlName="id">
        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">System</span>
          </div>
          <select class="custom-select form-control" formControlName="system">
            <option [ngValue]="null" disabled>Choose system</option>
            <option [ngValue]="0">Mobile</option>
            <option [ngValue]="1">Desktop</option>
          </select>
        </div>
        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Title</span>
          </div>
          <input type="text" class="form-control" formControlName="title">
        </div>
        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Category</span>
          </div>
          <input type="text" class="form-control" formControlName="category">
        </div>
        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Description</span>
          </div>
          <textarea type="text" class="form-control" formControlName="description"></textarea>
        </div>
        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Video URL</span>
          </div>
          <input type="text" class="form-control" formControlName="videoURL">
        </div>
        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Image URL</span>
          </div>
          <input type="text" class="form-control" formControlName="imageURL">
        </div>
        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Order</span>
          </div>
          <input type="number" class="form-control" formControlName="order">
        </div>
        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text">Page URL</span>
          </div>
          <input type="text" class="form-control" formControlName="pageURL">
        </div>
        <p class="button-group ">
          <button [disabled]="!formGroup.valid" href="#" class="card-link btn btn-primary"
            (click)="onSubmit()">Submit</button>
        </p>
      </form>
    </div>
  </div>
</ng-template>