import { Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { SiteAttendanceReportViewModel } from '../models/site-report-models';
import { Observable } from 'rxjs';
import { SiteAttendanceViewModel, ManualCheckInAttendanceRequestViewModel, CheckOutAttendanceRequestModel, AnonymousCheckInAttendanceRequestModel, AnonymousCheckOutAttendanceRequestModel } from '../models/attendance-request/attendance-request-viewmodel';
import { SiteAttendanceAttachmentsViewModel } from '../models/site-attendance-attachments-viewmodel';
import { UserProfileViewmodel } from '../models/user-profile-viewmodel';
import { SiteCheckInOutData } from '../models/site-check-in-out-data';
import { CheckinServerResult, ServerResultData, CheckServerResult } from '../models/server-result-data';
import { SaveScheduledReportViewmodel, ScheduledReportViewModel } from '../models/save-scheduled-report-model';
import { AttendanceFieldViewModel } from '../models/attendance-field-model';
import { SendPushNotificationModel } from '../models/send-push-notification-model';
import { PagingViewModel } from '../models/site-list-paging-view-model';

import { PreCheckInListQueryModel } from "../models/pre-check-in/pre-checkin-list-query-model";
import {
  ApprovePreCheckInModel,
  PreAttendanceReportViewModel,
  UserPreAttendanceCheckInsViewModel
} from "../models/pre-check-in/user-pre-attendance-check-ins-view-model";
import { PagingRequest } from "../models/paging-request-model";
import { SendMessageResponseViewModel } from '../models/send-message-response-view-model';
declare var moment: any;

@Injectable({
  providedIn: 'root'
})
export class SiteAttendanceService {

  apiUrl: string = "/api/SiteAttendance";
  constructor(private http: HttpClient, private _errorService: ErrorService) { }

  getSiteAttendanceAttachments(siteAttendanceId: number): Observable<SiteAttendanceAttachmentsViewModel> {
    return this.http.get(`${this.apiUrl}/${siteAttendanceId}/attachments-for-host`)
      .pipe(catchError(this._errorService.handleError));
  }

  ManualCheckIn(model: ManualCheckInAttendanceRequestViewModel): Observable<ServerResultData<CheckinServerResult>> {
    return this.http.post<ServerResultData<CheckinServerResult>>(`${this.apiUrl}/host-manual-checkin`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  ManualCheckOut(mobile: string, model: CheckOutAttendanceRequestModel) {
    return this.http.post<ServerResultData<CheckServerResult>>(`${this.apiUrl}/host-manual-checkout?mobileNumber=${mobile}`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  AnonyousCheckOut(key: any, model: AnonymousCheckOutAttendanceRequestModel) {
    return this.http.post<ServerResultData<CheckServerResult>>(`${this.apiUrl}/anonymous-checkout/${key}`, model)
      .pipe(catchError(this._errorService.handleError));
  }


  AnonymousCheckIn(model: AnonymousCheckInAttendanceRequestModel) {
    return this.http.post<ServerResultData<CheckinServerResult>>(`${this.apiUrl}/anonymous-checkin`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  SiteActiveAttendees(siteId: number): Observable<SiteAttendanceReportViewModel[]> {
    return this.http.get<SiteAttendanceViewModel>(`${this.apiUrl}/site-active-attendees/${siteId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  getAttendanceContactTracing(siteAttendanceId: number): Observable<SiteAttendanceReportViewModel[]> {
    return this.http.get<SiteAttendanceReportViewModel[]>(`${this.apiUrl}/get-attendance-contact-tracing/${siteAttendanceId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  sendEmergencyAlarm(siteId: number, alarmMessage: string): Observable<SendMessageResponseViewModel> {
    return this.http.post(`${this.apiUrl}/send-emergency-alarm`, { siteId: siteId, message: alarmMessage })
      .pipe(catchError(this._errorService.handleError));
  }

  getTotalCountCheckInSite(siteId: number): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/${siteId}/total-siteattendance-count/`)
      .pipe(catchError(this._errorService.handleError));
  }

  getContactInformationByAttendanceId(siteAttendanceId: number): Observable<UserProfileViewmodel> {
    return this.http.get<UserProfileViewmodel>(`${this.apiUrl}/${siteAttendanceId}/contact-information/`)
      .pipe(catchError(this._errorService.handleError));
  }

  // getSiteCheckInOutData(model: any): Observable<SiteCheckInOutData> {
  //   return this.http.post<SiteCheckInOutData>(`${this.apiUrl}/site-check-in-out-data/`, model)
  //     .pipe(catchError(this._errorService.handleError));
  // }

  saveScheduledReport(model: SaveScheduledReportViewmodel): Observable<SiteCheckInOutData> {
    return this.http.post<SiteCheckInOutData>(`${this.apiUrl}/save-scheduled-report/`, model)
      .pipe(catchError(this._errorService.handleError));
  }


  saveAndGetMagicLinkForPowerBI(model: SaveScheduledReportViewmodel): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/get-powerbi-magic-link`, model)
      .pipe(catchError(this._errorService.handleError));
  }


  getMyScheduledReport(): Observable<ScheduledReportViewModel[]> {
    return this.http.get<ScheduledReportViewModel[]>(`${this.apiUrl}/my-scheduled-reports/`)
      .pipe(catchError(this._errorService.handleError));
  }

  deleteMyScheduledReport(reportId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/delete-my-scheduled-reports/${reportId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  sendHostPushNotification(model: SendPushNotificationModel, siteId?: number) {
    return this.http.post<any>(`${this.apiUrl}/send-site-host-push-notification/${siteId}`, model)
      .pipe(catchError(this._errorService.handleError));
  }
  //The report we should show in popup
  exportContactsReportAsCsv(siteAttendanceId: number) {
    return window.open(this.apiUrl + '/export-contacts-report-csv/?siteAttendanceId=' + siteAttendanceId);
  }

  getHostCheckInData(model: any) {
    return this.http.post<any>(`${this.apiUrl}/host-check-in-data`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  getAnonymousCheckInData(model: any) {
    return this.http.post<any>(`${this.apiUrl}/anonymous-check-in-data`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  approveSiteAttentendanceByHost(siteAttendanceId: number) {
    return this.http.get<any>(`${this.apiUrl}/approve-site-attendance/${siteAttendanceId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  rejectSiteAttentendanceByHost(siteAttendanceId: number) {
    return this.http.get<any>(`${this.apiUrl}/reject-site-attendance/${siteAttendanceId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  getPreCheckInsList(model: PagingRequest<PreCheckInListQueryModel>): Observable<PagingViewModel<PreAttendanceReportViewModel>> {
    return this.http.post<PagingViewModel<UserPreAttendanceCheckInsViewModel>>(`${this.apiUrl}/pre-checkin-list`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  approvePreCheckInModel(model: ApprovePreCheckInModel): Observable<ServerResultData<CheckinServerResult>> {
    return this.http.post<ServerResultData<CheckinServerResult>>(`${this.apiUrl}/host-approve-pre-checkin`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  cancelPreCheckInModel(preCheckinId: number): Observable<ServerResultData<CheckinServerResult>> {
    return this.http.get<ServerResultData<CheckinServerResult>>(`${this.apiUrl}/host-cancel-pre-checkin/${preCheckinId}`)
      .pipe(catchError(this._errorService.handleError));
  }

}
