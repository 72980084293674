<select class="custom-select form-control"
        [(ngModel)]="forceViewMode" (change)="emitOutput()">
  <ng-container *ngFor="let index of SiteAnnouncementForceViewMode | enumToArray">
    <option
      *ngIf="index != SiteAnnouncementForceViewMode[SiteAnnouncementForceViewMode.SetPlaythroughToMandatory] ||
                                         (type == FileType.Pdf || type == FileType.Video || type == FileType.Audio)"
      [ngValue]="SiteAnnouncementForceViewMode[index]">
      {{ SiteAnnouncementForceViewMode[index] | siteAnnouncementForceViewMode }}
    </option>
  </ng-container>
</select>
