import { Component, OnInit, ViewChild } from '@angular/core';
import { FormReportViewModel } from 'src/app/models/form/form-report-models';
import { LayoutService } from 'src/app/services/layout.service';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { MobilePipe } from 'src/app/pipes/mobile.pipe';
import { DatetimePipe } from 'src/app/pipes/datetime.pipe';
import { DatePipe } from 'src/app/pipes/date.pipe';
import { Table } from 'src/app/enums/table.enum';
import { ModalService } from 'src/app/services/modal.service';
import { FormService } from "../../../services/form.service";
import { FormDataPdfQueryModel } from 'src/app/models/form/form-data-pdf-query-model';
import { GridColumn, GridConfig, GridTemplate, InitialFilter } from "../../../models/grid/grid.config";
import { AttendanceDocumentReportViewModel } from "../../../models/attendance-document-report-viewmodel";

@Component({
  selector: 'obc-form-report',
  templateUrl: './form-report.component.html',
  providers: [MobilePipe, DatetimePipe, DatePipe],
  styleUrls: ['./form-report.component.css']
})
export class FormReportComponent implements OnInit {
  includeInactiveForms: boolean = false;
  initialFilters: InitialFilter[] = [{ key: 'includeInactiveForms', value: false, }];

  constructor(private layoutService: LayoutService,
    private formService: FormService,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    this.layoutService.header = "Form Report";
    this.prepareGridConfig();
  }

  @ViewChild('grid') grid: any;
  gridColumns: GridColumn<AttendanceDocumentReportViewModel>[] = [];
  gridConfig: GridConfig = {} as GridConfig;

  prepareGridConfig() {
    this.gridConfig = new GridConfig({
      apiUrl: '/api/form/form-report',
      tableType: Table.FormReport,
      displayGeneralSearch: true,
      displayQuestionsFilter: true,
      generalSearchPlaceholder: "Filter result by Form Title, User, Supplier and Site fields",
      initialFilters: this.initialFilters,
      csvReportUrl: '/api/form/export-form-report-csv-as-job',
    });
    this.gridColumns = [
      {
        name: "Form Title",
        key: "formName",
        type: FilterType.Form,
        propertyNameInQuery: 'formIds',
      },
      {
        name: "Type",
        key: "formType",
        type: FilterType.FormType,
        propertyNameInQuery: 'formTypes',
      },
      {
        name: "Full Name",
        key: "user",
        type: FilterType.Mobile,
        propertyNameInQuery: 'userIds',
        gridTemplate: new GridTemplate().FullNameMobile({
          fullName: 'fullName',
          mobile: 'mobile'
        }).CsvFields([
          'fullName',
          'mobile',
        ]),
      },
      {
        name: "Supplier",
        key: "supplierName",
        sortBy: "supplier",
        type: FilterType.Supplier,
        propertyNameInQuery: 'suppliers',
      },
      {
        name: "Site",
        sortBy: 'site',
        key: "siteFullName",
        type: FilterType.Site,
        propertyNameInQuery: 'siteIds',
        gridTemplate: new GridTemplate().SiteNameReference({
          siteName: 'siteName',
          siteReference: 'siteReference',
        }),
        hideEmptyColumnsEmptinessConditionCallback: (res) => {
          return res?.every(item => {
            return item.siteName == undefined || item.siteName?.toString()?.trim() == '';
          });
        }
      },
      {
        name: "Filled In",
        type: FilterType.DateTimeRange,
        key: "fillDate",
        propertyNameInQuery: 'fillDateRanges',
      },
      {
        name: "Details",
        key: 'viewDetails',
        type: FilterType.Template,
        enableSort: false,
        enableFilter: false,
        gridTemplate: new GridTemplate().ComponentTemplateName('viewDetails'),
        visible: true,
      }
    ];
  }

  data;
  openModal(template, data) {
    this.data = data;
    this.modalService.show(template, "modal-lg");
  }

  closeModal() {
    this.grid.loadData(0);
    this.modalService.hide();
  }

  exportPdf(item: FormReportViewModel) {
    this.formService.ExportFormDataAsPdf({ formDataId: item.formDataId, siteId: item.siteId } as FormDataPdfQueryModel);
  }

  onIncludeInactiveFormsChanged(value: boolean) {
    this.gridColumns.find(x => x.key == "formName").filterSettings = { includeInactiveItems: value };
    this.initialFilters.find(x => x.key == "includeInactiveForms").value = value;
    this.grid?.loadData();
  }}
