<div *ngIf="formGroup">
  <form [formGroup]="formGroup">
    <div class="d-flex flex-column" *ngIf="model">
      <div>
        <i *ngIf="valid" style="color: green;" class="fas fa-check-circle  mr-2"></i>
        <i *ngIf="!valid" style="color: red;" class="fas fa-times-circle  mr-2"></i>
        <obc-question-title-renderer [question]="model.question"></obc-question-title-renderer>
      </div>
      <div class="form-group d-flex justify-content-end">
        <obc-emergency-contact
          [required]="model.question?.isRequired ?? false"
          [jsonData]="defaultAnswerStringify"
          (onUpdate)="onUpdateEmergencyContact($event)"
        ></obc-emergency-contact>
      </div>
    </div>
  </form>
</div>