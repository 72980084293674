<div class="card" [ngClass]="activeProductPlan ? 'card-success' : 'card-danger'">
  <div class="card-header">
    <h5>
      Active Plan
    </h5>
  </div>
  <div class="card-body">
    <div *ngIf="activeProductPlan">
      <table style="width: 100%;">
        <tr class="card-row">
          <td class="title">Title</td>
          <td class="value">{{activeProductPlan.title}}</td>
        </tr>
        <tr class="card-row">
          <td class="title">Sites</td>
          <td class="value">{{activeSiteCountLabel}}</td>
        </tr>
        <tr class="card-row">
          <td class="title">Average Check-in per day</td>
          <td class="value">{{averageCheckInCountPerDayLabel}}</td>
        </tr>
        <tr class="card-row">
          <td class="title">Billing Type</td>
          <td class="value">{{activeProductPlan.billingPeriodTitle}}</td>
        </tr>
        <tr class="card-row">
          <td class="title">Price Per Month</td>
          <td class="value" *ngIf="activeProductPlan.pricePerMonth">{{activeProductPlan.pricePerMonth}}
            {{activeProductPlan.priceUnit}}</td>
          <td class="value" *ngIf="!activeProductPlan.pricePerMonth">Not Set</td>
        </tr>
        <tr class="card-row">
          <td class="title">Allow Export</td>
          <td class="value">
            <i *ngIf="activeProductPlan.enableExportFeature" class="fas fa-check-circle green"></i>
            <i *ngIf="!activeProductPlan.enableExportFeature" class="fas fa-times-circle red"></i>
          </td>
        </tr>
        <tr class="card-row">
          <td class="title">Questions</td>
          <td class="value">
            {{numberOfQuestionLabel}}
          </td>
        </tr>
        <tr class="card-row">
          <td class="title">Users</td>
          <td class="value">
            {{numberOfUserLabel}}
          </td>
        </tr>
        <tr class="card-row">
          <td class="title">Email Support</td>
          <td class="value">
            <i *ngIf="activeProductPlan.supportViaEmail" class="fas fa-check-circle green"></i>
            <i *ngIf="!activeProductPlan.supportViaEmail" class="fas fa-times-circle red"></i>
          </td>
        </tr>
        <tr class="card-row">
          <td class="title">Support 24x7</td>
          <td class="value">
            <i *ngIf="activeProductPlan.support24x7" class="fas fa-check-circle green"></i>
            <i *ngIf="!activeProductPlan.support24x7" class="fas fa-times-circle red"></i>
          </td>
        </tr>
        <tr class="card-row">
          <td class="title">Training Academy</td>
          <td class="value">
            <i *ngIf="activeProductPlan.personalisedTraining" class="fas fa-check-circle green"></i>
            <i *ngIf="!activeProductPlan.personalisedTraining" class="fas fa-times-circle red"></i>
          </td>
        </tr>
        <tr class="card-row">
          <td class="title">Integration API</td>
          <td class="value">
            <i *ngIf="activeProductPlan.integrationApi" class="fas fa-check-circle green"></i>
            <i *ngIf="!activeProductPlan.integrationApi" class="fas fa-times-circle red"></i>
          </td>
        </tr>
      </table>
    </div>
    <div *ngIf="!activeProductPlan">
      <p class="center" style="margin-bottom: 0;">You are on the Trial plan</p>
    </div>
  </div>
</div>