import { Pipe, PipeTransform } from '@angular/core';
import { AccessControlUnitProvider } from '../enums/access-control-unit-provider';

@Pipe({
  name: 'accessControlUnitProvider'
})
export class AccessControlUnitProviderPipe implements PipeTransform {

  transform(value: any): string {
    if (value == AccessControlUnitProvider.Disable) return 'Disable';
    if (value == AccessControlUnitProvider.OneBreadCrumb) return '1Breadcrumb';
    if (value == AccessControlUnitProvider.BioStar) return 'BioStar2';
    return value?.toString() ?? '';
  }

}
