import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseGridComponent } from 'src/app/custom-controls/grid/base-grid.component';
import { FileType } from 'src/app/enums/file-type.enum';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { Table } from 'src/app/enums/table.enum';
import { GridColumn, GridConfig, GridTemplate } from 'src/app/models/grid/grid.config';
import { AsyncResult } from 'src/app/models/helper-models';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-site-briefing-form-viewer',
  templateUrl: './site-briefing-form-viewer.component.html',
  styleUrls: ['./site-briefing-form-viewer.component.scss']
})
export class SiteBriefingFormViewerComponent implements OnInit, AfterViewInit {

  _selectedFormDataId: number = null;
  @Input() set selectedFormDataId(id: number) {
    this._selectedFormDataId = id;
    this.initialFilters.find((item) => item.key == 'formDataId').value = this.selectedFormDataId;
    //this.loadData();
  };
  get selectedFormDataId() {
    return this._selectedFormDataId;
  }

  @ViewChild('gridSignature') grid: BaseGridComponent<any>;
  signaturesGridColumns: GridColumn<any>[] = [];
  signaturesGridConfig: GridConfig = {} as GridConfig;
  initialFilters = [{ key: 'formDataId', value: 38425, displayInFilterRenderer: false }];

  constructor(private modalService: ModalService) { }


  loadData() {
    this.grid.loadData(0);
  }

  ngOnInit(): void {
    this.configGrid();
  }

  configGrid(): void {
    this.signaturesGridConfig = new GridConfig({
      apiUrl: '/api/SiteBriefing/get-signed-list',
      tableType: Table.SiteBriefingReportSignature,
      initialFilters: this.initialFilters,
      displayRefreshButton: false,
      apiResultCallback: (res) => {

        res.data.forEach(item => {
          item.fileUrl = item.signatureUrl;
          item.fileType = FileType.Image;
        });

        return {
          data: res?.data,
          totalCount: res?.totalCount,
        }
      }
    });

    this.signaturesGridColumns = [
      {
        name: "Name",
        key: "fullName",
        enableFilter: false,
        enableSort: false,
      },
      {
        name: "Mobile",
        key: "mobile",
        enableFilter: false,
        enableSort: false,
      },
      {
        name: "Sign Date",
        key: "signDateTime",
        type: FilterType.DateTimeRange,
        enableFilter: false,
        enableSort: false,
      },
      {
        name: "Signed By",
        key: "signedBy",
        gridTemplate: new GridTemplate().ComponentTemplateName('signedBy'),
        enableFilter: false,
        enableSort: false,
      },
      {
        name: "Signature",
        key: "signatureUrl",
        gridTemplate: new GridTemplate().ComponentTemplateName('sigTemplate'),
        enableFilter: false,
        enableSort: false,
      },
    ];
  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  closeModal(_: AsyncResult) {
    this.modalService.hide();
  }
}
