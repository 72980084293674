import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AdminSiteViewModel,
  AnonymousCheckInSiteViewModel,
  SiteCloningModel,
  SiteDetailsViewModel,
  SiteViewModel
} from '../models/site-viewmodel';
import { catchError } from 'rxjs/operators';
import { ErrorService } from './error.service';
import { Observable } from 'rxjs';
import { SiteQrCodeViewModel } from '../models/site-qrcode-viewmodel';
import { SiteUpdateLocationModel } from '../models/site-update-location-model';
import { VisitorTypesWithSiteVisitorTypeIdsViewModel as VisitorTypesWithSiteVisitorTypeIdsViewModel } from '../models/visitor-type';
import { SiteActiveStatus } from '../enums/site-active-status.enum';
import { SiteTemplateStatus } from '../enums/site-template-status.enum';
import { UserPermission } from '../enums/user-permission.enum';
import { SiteVisitorTypeAccompanierViewModel } from '../models/site-visitortype-accompanier-viewmodel';
import { QuickSetupModel } from '../models/quick-setup-models/quick-setup-model';
import { CompanySiteListQueryModel, SiteListQueryModel } from '../models/site-list-query-model';
import { PagingViewModel } from '../models/site-list-paging-view-model';
import { SiteAccessControlViewModel } from '../models/site-access-control-unit-view-model';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getSiteBySession(session: string) {
    return this._http.get<AnonymousCheckInSiteViewModel>(`/api/site/get-site-by-session/${session}`)
      .pipe(catchError(this._errorService.handleError));
  }

  getById(siteId: number): Observable<SiteViewModel> {
    return this._http.get<SiteViewModel>('/api/site/' + siteId)
      .pipe(catchError(this._errorService.handleError));
  }
  getFullDataById(siteId: number): Observable<AdminSiteViewModel> {
    return this._http.get<AdminSiteViewModel>('/api/site/get-full-data/' + siteId)
      .pipe(catchError(this._errorService.handleError));
  }
  getAllSites(query: SiteListQueryModel): Observable<PagingViewModel<SiteDetailsViewModel>> {
    return this._http.post<PagingViewModel<SiteDetailsViewModel>>(`/api/site/all-sites`, query)
      .pipe(catchError(this._errorService.handleError));
  }
  //ACCESS TO ONLY GOD USERS
  getAllCompanySites(companyId: number): Observable<SiteViewModel[]> {
    return this._http.post<SiteViewModel[]>(`/api/site/all-company-sites/${companyId}`, {})
      .pipe(catchError(this._errorService.handleError));
  }
  getAllPermissionedSites(permission: UserPermission, activeStatus: SiteActiveStatus, templateStatus: SiteTemplateStatus, includeExternalSites: boolean = false): Observable<SiteViewModel[]> {
    return this._http.post<SiteViewModel[]>(`/api/site/site-list`,
      {
        permission: permission,
        activeStatus: activeStatus,
        templateStatus: templateStatus,
        includeExternalSites: includeExternalSites,
      })
      .pipe(catchError(this._errorService.handleError));
  }
  getAllSitesForExternalCompany(activeStatus: SiteActiveStatus, templateStatus: SiteTemplateStatus): Observable<SiteViewModel[]> {
    return this._http.get<SiteViewModel[]>(`/api/site/all-permissioned-supplier-sites/${activeStatus}/${templateStatus}`)
      .pipe(catchError(this._errorService.handleError));
  }
  //this is used when we need just active sites
  getAllActiveSites(): Observable<SiteViewModel[]> {
    return this._http.get<SiteViewModel[]>(`/api/site/all-active-sites`)
      .pipe(catchError(this._errorService.handleError));
  }

  getAllPermissionedActiveSites(permission: UserPermission): Observable<SiteViewModel[]> {
    return this._http.get<SiteViewModel[]>(`/api/site/all-permissioned-sites/${permission}`)
      .pipe(catchError(this._errorService.handleError));
  }

  add(site: SiteViewModel): Observable<SiteViewModel> {
    return this._http.post(`/api/site/`, site)
      .pipe(catchError(this._errorService.handleError));
  }

  update(site: AdminSiteViewModel) {
    return this._http.put(`/api/site/${site.id}`, site)
      .pipe(catchError(this._errorService.handleError));
  }

  delete(siteId: number) {
    return this._http.delete(`/api/site/${siteId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  regenerateSecretKey = (siteId) =>
    this._http.get<SiteViewModel>(`/api/site/regenerate-secretkey/${siteId}`)
      .pipe(catchError(this._errorService.handleError));


  clearSecretKey = (siteId) =>
    this._http.get(`/api/site/clear-secretkey/${siteId}`)
      .pipe(catchError(this._errorService.handleError));

  showSecretKey = (siteId): Observable<SiteQrCodeViewModel> =>
    this._http.get<SiteQrCodeViewModel>(`/api/site/show-secretkey/${siteId}`)
      .pipe(catchError(this._errorService.handleError));

  showOffsiteSecretKey = (siteId): Observable<SiteQrCodeViewModel> =>
    this._http.get<SiteQrCodeViewModel>(`/api/site/show-offsite-secretkey/${siteId}`)
      .pipe(catchError(this._errorService.handleError));


  changeActiveState = (siteId: number, isActive: boolean): Observable<any> =>
    this._http.post<any>(`/api/site/active/${siteId}`, isActive)
      .pipe(catchError(this._errorService.handleError));

  getVisitorTypesWithSiteVisitorTypeIdsViewModel(SiteId: number): Observable<VisitorTypesWithSiteVisitorTypeIdsViewModel> {
    return this._http.get(`/api/Site/get-site-visitor-types/${SiteId}`).pipe(catchError(this._errorService.handleError));
  }

  removeSiteLogo = (siteId: number): Observable<any> =>
    this._http.get<any>(`/api/site/remove-site-logo/${siteId}`)
      .pipe(catchError(this._errorService.handleError));

  updateLocation(site: SiteUpdateLocationModel) {
    return this._http.post(`/api/site/update-location/${site.id}`, site)
      .pipe(catchError(this._errorService.handleError));
  }
  ValidateImportSites(formData: any, isImportRequest: boolean): Observable<any> {
    return this._http.post(`/api/site/validate-import-sites/${isImportRequest}`, formData,
      { reportProgress: false, observe: 'events', headers: { "do-not-modify-content-type": "" } })
      .pipe(catchError(this._errorService.handleError));
  }
  addEditSiteVisitorTypeAccompaniers(model: SiteVisitorTypeAccompanierViewModel, siteId: number): Observable<SiteVisitorTypeAccompanierViewModel[]> {
    return this._http.post(`/api/site/add-edit-site-visitor-type-accompanier/${siteId}`, model).pipe(catchError(this._errorService.handleError));
  }
  removeSiteVisitorTypeAccompanier(id: number, siteId: number): Observable<SiteVisitorTypeAccompanierViewModel[]> {
    return this._http.delete(`/api/site/delete-site-visitor-type-accompanier/${siteId}/${id}`).pipe(catchError(this._errorService.handleError));
  }

  quickSetup(model: QuickSetupModel) {
    return this._http.post(`/api/site/quick-setup/`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  cloneSite(refSiteId: number, model: SiteCloningModel, cloneAsSite: boolean = true) {
    if (cloneAsSite)
      return this._http.post(`/api/site/clone-site/${refSiteId}`, model)
        .pipe(catchError(this._errorService.handleError));

    return this._http.post(`/api/site/clone-site-to-template/${refSiteId}`, model)
      .pipe(catchError(this._errorService.handleError));
  }
  getSiteAccessControlUnits(): Observable<SiteAccessControlViewModel[]> {
    return this._http.get<SiteAccessControlViewModel[]>('/api/site/get-site-access-control-unit')
      .pipe(catchError(this._errorService.handleError));
  }
}
