export class CardLayout {
    public id: number;
    public name: string;
    public size: PageSize;
    public layout: PageLayout;
    public width?: string;
    public height?: string;
    public elements: CardLayoutElement[];
    public style?: StyleSetting;
}

export class CardLayoutElement {
    id: string;
    name: string;
    position: RectInfo;
    style?: StyleSetting;
}

export class StyleSetting {
    bgColor?: string;
    showBorder?: boolean;
    borderStyle?: string;
    fontStyle?: string;
}

export class RectInfo {
    width: number;
    height: number;
    top: number;
    left: number;
}

export enum PageSize {
    A5 = 0,
    A4 = 1,
    A3 = 2,
    Custom = 100,
}

export function getPageSizeName(size: PageSize) {
    switch (size) {
        case PageSize.A3:
            return "A3";
        case PageSize.A4:
            return "A4";
        case PageSize.A5:
            return "A5";
        case PageSize.Custom:
            return "Custom Size";
    }
}

export function getPageSizeWithLayout(size: PageSize, layout : PageLayout) : {width: number, height: number} {
    var size0 = getPageSize(size);
    if(size0 != null)
    if(layout == PageLayout.Portrait) {
        return size0;
    } else {
        return {width : size0.height, height: size0.width}
    }
}

export function getPageSize(size: PageSize) : {width: number, height: number} {
    switch (size) {
        case PageSize.A3:
            return {width : 29.7, height: 42.0 };
        case PageSize.A4:
            return {width : 21.0, height: 29.7 };
        case PageSize.A5:
            return {width : 14.8, height: 21.0 };
        }
        return null;
}

export enum PageLayout {
    Portrait = 0,
    Landscape = 1,
}

export function getPageLayoutName(layout: PageLayout) {
    switch (layout) {
        case PageLayout.Portrait:
            return "Portrait";
        case PageLayout.Landscape:
            return "Landscape";
    }
}