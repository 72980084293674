<obc-loader [show]="inProgress"></obc-loader>
<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div *ngIf="mode == CheckInMode.Host" class="col-md-12">
        <app-user-selector #userSelector (userChange)="onSelectUser($event)"></app-user-selector>
      </div>
      <div *ngIf="mode == CheckInMode.Anonymous" class="col-md-12">
        <div class="row" [formGroup]="userInfoFormGroup">
          <div class="col-md-4 col-sm-12 mb-1">
            <div class="input-group">
              <div class="input-group-prepend" *ngIf="selectedCountry">
                <span class="input-group-text">+{{selectedCountry.prefixCode}}</span>
              </div>
              <input class="form-control" formControlName="mobile" placeholder="Mobile number"
                style="border-top-right-radius: 4px; border-bottom-right-radius: 4px;">
              <div *ngIf="!userInfoFormGroup.get('mobile').value && !userInfoFormGroup.get('mobile').value"
                class="invalid-feedback" style="display: block;">
                please enter valid mobile number
              </div>
              <div *ngIf="!userInfoFormGroup.get('mobile').valid && userInfoFormGroup.get('mobile').value"
                class="invalid-feedback" style="display: block;">
                Mobile number is not valid
              </div>
              <div *ngIf="!userInfoFormGroup.get('mobile').value && successMessage && successMessage.length > 0"
                class="valid-feedback" style="display: block;">
                {{successMessage}}
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 mb-1">
            <input class="form-control" formControlName="lastName" placeholder="Last Name">
          </div>
          <div class="col-md-4 col-sm-12 mb-1">
            <input class="form-control" formControlName="firstName" placeholder="First Name">
          </div>
        </div>
      </div>

      <div class="col-md-12 col-sm-12 mt-2"
        *ngIf="site?.siteVisitorTypes && site?.siteVisitorTypes.length> 0 && isMobileValid">
        <div btnRadioGroup class="btn-group1 custom-mobile-flex-column" [formControl]="selectedVisitorTypeFormControl">
          <span>Select Visitor Type: </span>
          <label *ngFor="let siteVisitorType of site?.siteVisitorTypes" class="btn btn-primary ml-1 mb-1" tabindex="0"
            [btnRadio]="siteVisitorType" role="button">{{siteVisitorType.visitorTypeTitle}}</label>
        </div>
      </div>
    </div>


    <div class="row mt-3" *ngIf="isMobileValid && isSelectedVisitorTypeValid">
      <div class="col-md-12" *ngIf="requiredFields?.length">
        <obc-required-field-renderer [sessionId]="mode == CheckInMode.Host ? null : sessionId"
          [siteId]="mode == CheckInMode.Host ? siteId : null" [supplierId]="selectedSupplierId"
          (supplierChanged)="onUpdateUserDocumentsbySupplier($event)" [requiredFields]="requiredFields"
          [valueChanged]="requiredFieldRenderer$">
        </obc-required-field-renderer>
        <hr>
      </div>

      <div class="col-md-12">
        <obc-items-renderer [questionsData]="{questions : questions,
            validator$: questionContainerValidation$,
            documents: requiredDocumentFormControls,
            documentsFormArray: requiredDocumentFormArray,
            checkinAnnouncements: announcements,
            notifyVisits: false,
            additionalDocumentConfig: additionalDocumentConfig,
            allUserDocumentTypes: allUserDocumentTypes,
            availableDocumentTypes: availableDocumentTypes
            }" (removeDocumentType)="removeDocumentFromRequiredDocuments($event)"
          (addAdditionalDocumentToDocumentTypesOutput)="addSelectedDocumentToRequiredDocuments($event)"
          (onCheckedMandatorySignOff)="onAnnouncementCheckedMandatorySignOff($event, false)"
          (onAnnouncementVisit)="onAnnouncementVisit($event, false)">
        </obc-items-renderer>
      </div>


      <!--<div class="col-md-12">
        <div *ngIf="questions?.length">
          <hr>
          <obc-question-renderer-container [data]="{questions : questions, validator$: questionContainerValidation$}">
          </obc-question-renderer-container>
        </div>
      </div>
     -->

      <!--      <div class="col-md-12">-->
      <!--        <app-required-document-list-renderer [allUserDocumentTypes]="allUserDocumentTypes"-->
      <!--                                             [allowUsersToAttachAdditionalDocuments]="allowUsersToAttachAdditionalDocuments" #requiredDocumentList-->
      <!--                                             [documents]="documents" (documentSelected)="addSelectedDocumentToRequiredDocuments($event)"-->
      <!--                                             (documentRemoved)="removeDocumentFromRequiredDocuments($event)" [questions]="questions"-->
      <!--                                             [onUpdateQuestionAnswers]="questionContainerValidation$">-->
      <!--        </app-required-document-list-renderer>-->
      <!--      </div>-->

      <div class="col-md-12" *ngIf="isMobileValid && isSelectedVisitorTypeValid">
        <obc-accompany-person [accompanierMode]="accompanierMode" [accompanyData$]="accompanyData$">
        </obc-accompany-person>
      </div>

      <div class="col-md-12">
        <obc-supplier-document-renderer
          [hasPermissionToAddSupplierDocumentComment]="hasPermissionToAddSupplierDocumentComment"
          [supplierDocCommentAcknowledgementText]="supplierDocCommentAcknowledgementText"
          [forceViewSupplierDocumentsBeforeSignOff]="forceViewSupplierDocumentsBeforeSignOff" #supplierDocumentRenderer
          [documents]="supplierDocuments" [supplierDocumentConfigMode]="supplierDocumentConfigMode">
        </obc-supplier-document-renderer>
      </div>
      <div class="col-md-12">
        <div *ngIf="inductions?.length">
          <!-- <h4>Inductions</h4>
          <hr> -->
          <obc-induction-renderer 
            [allUserDocumentTypes]="allUserDocumentTypes"
            (addDocumentToDocumentTypesOutput)="addDocumentToInductionDocuments($event, induction)"
            (removeDocumentFromRequiredDocumentsOutput)="removeDocumentFromInductionDocuments($event, induction)"
            *ngFor="let induction of inductions" [induction]="induction"
            (onCheckedMandatorySignOff)="onInductionAnnouncementCheckedMandatorySignOff($event, false)"
            (onAnnouncementVisit)="onInductionAnnouncementVisit($event, false)">
          </obc-induction-renderer>
        </div>
      </div>

      <div class="col-md-12 pt-2" *ngIf="isSetSupplierCheckinData()">
        <div class="card check-in-supplier-block">
          <div class="card-header">
            <strong>{{supplierCheckinData.supplierName}}</strong>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <obc-items-renderer [questionsData]="{questions : supplierQuestions,
                            documents: supplierRequiredDocumentFormControls,
            documentsFormArray: supplierRequiredDocumentFormArray,
            validator$: supplierQuestionContainerValidation$,
            checkinAnnouncements: supplierCheckinData.announcements}" (onCheckedMandatorySignOff)="onAnnouncementCheckedMandatorySignOff($event, true)"
                  (onAnnouncementVisit)="onAnnouncementVisit($event, true)">
                </obc-items-renderer>
              </div>
              <div class="col-md-12">
                <div *ngIf="supplierCheckinData?.inductions?.length" class="mt-3">
                  <!-- <h4>Inductions</h4>
                  <hr> -->
                  <obc-induction-renderer *ngFor="let induction of supplierCheckinData?.inductions"
                    [allUserDocumentTypes]="supplierCompanyAllUserDocumentTypes"               
                    [induction]="induction"
                    (addDocumentToDocumentTypesOutput)="addDocumentToInductionDocuments($event, induction, true)"
                    (removeDocumentFromRequiredDocumentsOutput)="removeDocumentFromInductionDocuments($event, induction, true)"
                    (onCheckedMandatorySignOff)="onInductionAnnouncementCheckedMandatorySignOff($event, true)"
                    (onAnnouncementVisit)="onInductionAnnouncementVisit($event, true)">
                  </obc-induction-renderer>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">

          </div>
        </div>
      </div>

      <div class="col-md-12 mt-4">
        <p class="button-group">
          <button class="btn btn-primary" [disabled]="!isFormValid" (click)="onCheckin()">
            Check-In
          </button>
        </p>
      </div>
      <div class="col-md-12">
        <div *ngIf="errorMessage && errorMessage.length > 0" class="invalid-feedback" style="display: block;">
          {{errorMessage}}
        </div>
      </div>
    </div>
  </div>
</div>
