<div class="card">
  <div class="card-body">
    <div class="container">
      <div class="row m-4">
        <div class="col-md-12 mb-4">
          <form class="row">
            <div class="input-group input-group col-md-4 col-sm-12 pull-right" style="margin: 0 auto;">
              <input type="text" class="form-control" [(ngModel)]="query" placeholder="Search Videos"
                [ngModelOptions]="{standalone: true}">
            </div>
          </form>
        </div>
        <ng-container *ngFor="let videoList of videoLists">
          <div class="card" style="width: 100%;" *ngIf="getVideos(videoList).length > 0">
            <div class="card-header">
              <h5>{{videoList.category}}</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4 col-sm-6 col-xs-12" *ngFor="let video of getVideos(videoList)">
                  <div class="video-card" (mouseenter)="video.mouseover = true" (mouseleave)="video.mouseover = false"
                    (click)="onVideoCardClick(lightBox, video)">
                    <img class="cover" [src]="video.imageURL | safe :'url'">
                    <p class="title">{{video.title}}</p>
                    <p *ngIf="video.mouseover" class="description text-justify">{{video.description}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-template #lightBox>
      <obc-light-box [resizeSubject]="windowsSize$" [video]="video" (hide)="onhide()"></obc-light-box>
    </ng-template>
  </div>
</div>