import { Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'obc-sortable',
  templateUrl: './sortable.component.html',
  styleUrls: ['./sortable.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SortableComponent),
    multi: true
  }]
})
export class SortableComponent implements OnInit, ControlValueAccessor {

  constructor() { }
  writeValue(value: any): void {
    this.items = value;  
    this.updateChanges();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
  }
  updateChanges() {
    this.onChange(this.items);
  }

  onChange: (_: any) => void = (_: any) => { };
  onTouched: () => void = () => { };

  items: any;
  @Input() template: TemplateRef<any>;
  @Output() onChangeOrder: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
  }

  changeOrderTimer = null;
  onChangeOrderInternal(e) {

    //HACK: Sortable call onChange on drag event
    if (this.changeOrderTimer)
      clearTimeout(this.changeOrderTimer);

    this.changeOrderTimer = setTimeout(() => {

      if (JSON.stringify(e) == JSON.stringify(this.items))
        return;

      this.items = e;
      this.onChange(e);

      if (this.onChangeOrder)
        this.onChangeOrder.emit(e);
      if (this.changeOrderTimer) clearTimeout(this.changeOrderTimer);
    }, 500);

  }
}
