import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { LayoutService } from 'src/app/services/layout.service';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { FormService } from "../../../../services/form.service";
import { GridColumn, GridConfig, GridTemplate, InitialFilter } from "../../../../models/grid/grid.config";
import { Table } from "../../../../enums/table.enum";
import { FormReportViewModel } from "../../../../models/form/form-report-models";
import { FormDataPdfQueryModel } from "../../../../models/form/form-data-pdf-query-model";
import { PermitStatus } from 'src/app/enums/permit-status';
import { PermitViewModel } from "../../../../models/form/permits-info-model";
import { FormViewModel } from "../../../../models/form/form-view-model";
import { PermitUpdateStatusRequestModel } from "../../../../models/permit/permit-update-status-request-model";
import { PermitService } from "../../../../services/permit.service";
import { AttendanceDocumentReportViewModel } from 'src/app/models/attendance-document-report-viewmodel';


@Component({
  selector: 'obc-permit-report',
  templateUrl: './permit-report.component.html',
  styleUrls: ['./permit-report.component.scss']
})
export class PermitReportComponent implements OnInit {
  PermitStatus = PermitStatus;
  selectedFormDataId: number;
  selectedPermit: PermitViewModel;
  permitStatusForm: FormViewModel;
  nextStatus: PermitStatus;
  ApproveRejectPermit = ApproveRejectPermit;
  inProgress: boolean = false;
  includeInactivePermitForms: boolean = false;
  initialFilters: InitialFilter[] = [{ key: 'includeInactivePermitForms', value: false, }];

  constructor(private layoutService: LayoutService,
    private formService: FormService,
    private modalService: ModalService,
    private permitService: PermitService,
  ) {
  }

  ngOnInit() {
    this.layoutService.header = "Permit Report";
    this.prepareGridConfig();
  }

  @ViewChild('grid') grid: any;
  gridColumns: GridColumn<AttendanceDocumentReportViewModel>[] = [];
  gridConfig: GridConfig = {} as GridConfig;

  prepareGridConfig() {
    this.gridConfig = new GridConfig({
      apiUrl: '/api/permit/permit-report',
      tableType: Table.PermitReport,
      displayGeneralSearch: true,
      csvReportUrl: '/api/permit/permit-report-csv-as-job',
      generalSearchPlaceholder: "Filter Result by Name, Site, Apply For",
      initialFilters: this.initialFilters,
    });
    this.gridColumns = [
      {
        name: "Date Submitted",
        key: "fillDateTime",
        type: FilterType.DateTimeRange,
        enableFilter: true,
        enableSort: true,
        propertyNameInQuery: 'fillDateTime',
        isArray: false,
      },
      {
        name: "Permit Title",
        key: "name",
        type: FilterType.PermitForm,
        propertyNameInQuery: 'permitFormIds',
      },
      {
        name: "Site",
        key: "siteName",
        sortBy: 'site',
        type: FilterType.Site,
        propertyNameInQuery: 'siteIds',
        gridTemplate: new GridTemplate().SiteNameReference({
          siteName: 'siteName'
        }),
      },
      {
        name: "Start/End",
        type: FilterType.DateTime,
        key: "startDate",
        propertyNameInQuery: 'dateTime',
        gridTemplate: new GridTemplate().ComponentTemplateName('startEndDate')
          .CsvFields([
            'startDate',
            'endDate'
          ]),
        isArray: false,
        payloadCustomKeyValue: (value) => {
          if (value && value?.length > 0)
            return { dateTime: value[0].from };
          return undefined;
        }
      },

      {
        name: "Apply For",
        key: "registerForTitle",
        sortBy: "supplier",
        type: FilterType.Supplier,
        propertyNameInQuery: 'supplierIds',
      },
      {
        name: "Status",
        key: "permitCurrentStatus",
        sortBy: "status",
        type: FilterType.PermitStatus,
        propertyNameInQuery: 'statuses',
        gridTemplate: new GridTemplate().ComponentTemplateName('permitStatus')
      },
      {
        name: "Exclusive Access",
        key: "zoneName",
        enableFilter: false,
      },
      {
        name: "Today's Schedule",
        key: "scheduleTitle",
        enableFilter: false,
        enableSort: false,
      },
      {
        name: "Details",
        key: 'controls',
        type: FilterType.Template,
        enableSort: false,
        enableFilter: false,
        gridTemplate: new GridTemplate().ComponentTemplateName('controls'),
        visible: true,
      }
    ];
  }

  openModal(template, formDataId: number) {
    this.selectedFormDataId = formDataId;
    this.modalService.show(template, "modal-lg");
  }

  exportPdf(item: FormReportViewModel) {
    this.formService.ExportFormDataAsPdf({ formDataId: item.formDataId, siteId: item.siteId } as FormDataPdfQueryModel);
  }

  closeModal() {
    this.modalService.hide();
    this.grid.loadData(0);
  }

  onOpenPermitStatusForm(template, permit: PermitViewModel, nextStatus: PermitStatus) {
    this.selectedPermit = permit;
    this.nextStatus = nextStatus;
    if (nextStatus == PermitStatus.Close)
      this.permitStatusForm = permit.statusInfo.permitCloseForm;
    else if (nextStatus == PermitStatus.OnHold)
      this.permitStatusForm = permit.statusInfo.permitOnholdForm;
    else if (nextStatus == PermitStatus.Open)
      this.permitStatusForm = permit.statusInfo.permitInUseForm;

    this.modalService.show(template, "modal-lg");
  }

  onConfirm(permit: PermitViewModel, nextStatus: PermitStatus, approveRejectPermit: ApproveRejectPermit = ApproveRejectPermit.Nothing) {
    this.selectedPermit = permit;
    this.nextStatus = nextStatus;
    let confirmMessage = approveRejectPermit == ApproveRejectPermit.Approve ? 'approve' :
      approveRejectPermit == ApproveRejectPermit.Reject ? 'reject' :
        nextStatus == PermitStatus.Close ? 'close' :
          nextStatus == PermitStatus.Open ? 'open' :
            nextStatus == PermitStatus.OnHold ? 'suspend' : 'change status of';
    this.modalService.confirm(`Are you sure you want to ${confirmMessage} this permit?`)
      .subscribe(confirmed => {
        if (confirmed) {
          this.onUpdatePermitStatus();
        }
      });
  }

  onUpdatePermitStatus() {
    this.inProgress = true;
    let model = {
      formData: null,
      permitId: this.selectedPermit.filledFormId,
      status: this.nextStatus,
    } as PermitUpdateStatusRequestModel;
    this.permitService.updatePermitStatus(model).subscribe({
      next: res => {
        if (res.success) {
          this.grid?.loadData(0);
        } else
          this.modalService.error(res.message);
      },
      error: err => {
        this.modalService.error(err, "Failed");
      },
      complete: () => {
        this.inProgress = false;
      }
    })
  }

  isShowTemplateColumns(mainColumn, detailColumn) {
    return this.grid.isShowTemplateColumns(mainColumn, detailColumn);
  }

  onIncludeInactivePermitFormsChanged(value: boolean) {
    this.gridColumns.find(x => x.key == "name").filterSettings = { includeInactiveItems: value };
    this.initialFilters.find(x => x.key == "includeInactivePermitForms").value = value;
    this.grid?.loadData();
  }
}

enum ApproveRejectPermit {
  Nothing,
  Approve,
  Reject,
}
