<section class="card" #modal>
  <header class="card-header">
    <h5>Activity Details</h5>
    <div class="card-actions">
      <a (click)="
          result.emit({
            isFinished: true,
            isSucceeded: false,
            needParentRefresh: needParentRefresh
          });
          this.modalService.hide()
        " class="card-action card-action-dismiss" data-card-dismiss=""></a>
    </div>
  </header>
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div *ngIf="errorMessage" class="alert alert-danger mb-2">
      {{ errorMessage }}
    </div>
    <tabset>
      <tab heading="User Information">
        <obc-site-attendance-approval-status
          *ngIf="siteAttendanceApprovalStatus && siteAttendanceApprovalStatus.status != SiteAttendanceApprovalStatus.NotRequiredApproval"
          (statusChange)="getData();resultOfApproveReject.emit()" [status]="siteAttendanceApprovalStatus">
        </obc-site-attendance-approval-status>
        <ng-container *ngIf="showUserInformationPanel">
          <obc-user-information [isDisputable]="userInformation.isDisputable"
            [userInformationFields]="userInformationFields" [userInformation]="userInformation"
            [onShowDisputeModal]="showDispute" [showDisputHistory]="onShowDisputeHistory"></obc-user-information>
        </ng-container>
      </tab>
      <tab heading="CheckIn Answers" (selectTab)="isCheckInSelected = true">
        <ng-template [ngTemplateOutlet]="answers"></ng-template>
      </tab>
      <tab heading="CheckOut Answers" *ngIf="this.questionAnswer.hasCheckOutDate"
        (selectTab)="isCheckInSelected = false">
        <ng-template [ngTemplateOutlet]="answers"></ng-template>
      </tab>
      <tab heading="Inductions" *ngIf="siteInductions?.length">
        <ng-container *ngTemplateOutlet="inductions"></ng-container>
      </tab>
      <tab heading="Documents" *ngIf="onlySiteAttendanceDocuments?.length">
        <ng-container *ngTemplateOutlet="documentsTemplate"></ng-container>
      </tab>
      <tab heading="Accompanying Persons" *ngIf="accompanyingPersons?.length">
        <ng-container *ngTemplateOutlet="accompanyingPersonsTemplate">Accompanying Persons</ng-container>
      </tab>
      <tab heading="Visited Announcements" *ngIf="visitedAnnouncements?.length">
        <ng-container *ngTemplateOutlet="visitedAnnouncementsTemplate">Visited Announcements</ng-container>
      </tab>
      <tab heading="Scanned Items" *ngIf="scannedItems?.length">
        <ng-container *ngTemplateOutlet="scannedItemsTemplate">Scanned Items</ng-container>
      </tab>
      <tab heading="Forms" *ngIf="forms?.length">
        <ng-container *ngTemplateOutlet="formsTemplate">Forms</ng-container>
      </tab>
      <tab heading="Permits" *ngIf="permitForms?.length">
        <ng-container *ngTemplateOutlet="permitFormsTemplate">Forms</ng-container>
      </tab>
      <tab heading="Supplier Documents" *ngIf="supplierDocuments?.length">
        <ng-container *ngTemplateOutlet="supplierDocumentsTemplate">Supplier Documents</ng-container>
      </tab>
    </tabset>

    <ng-template #inductions>
      <div *ngFor="let induction of siteInductionFilledInfo">
        <div class="mb-3">
          <obc-induction-filled-info [siteInductionFilledInfo]="induction"
            (onApproveOrReject)="onApproveOrReject($event, induction)">
          </obc-induction-filled-info>
        </div>
      </div>
    </ng-template>

    <ng-template #documentsTemplate>
      <div>
        <ul class="simple-post-list">
          <li class="d-flex flex-column justify-content-around" *ngFor="let document of onlySiteAttendanceDocuments">
            <obc-document-answer-renderer [document]="document"></obc-document-answer-renderer>
          </li>
        </ul>
      </div>
    </ng-template>

    <ng-template #answers>
      <div>
        <div>
          <div *ngIf="completeAnswerDate">
            <strong>Answer Date: </strong>{{ completeAnswerDate | datetime }}
          </div>
          <obc-question-list-answer-renderer [answers]="answerResult"></obc-question-list-answer-renderer>
          <div *ngIf="answerResult?.length == 0">There is no Item!</div>
        </div>
      </div>
    </ng-template>

    <ng-template #accompanyingPersonsTemplate>
      <div>
        <ul class="simple-post-list">
          <li class="d-flex flex-column justify-content-around" *ngFor="let accompany of accompanyingPersons">
            <div class="post-image">
              <div class="img-thumbnail">
                <i class="fas fa-user"></i>
              </div>
              <span class="text-primary ml-2">
                {{ accompany.firstName }} {{ accompany.lastName }}
                <span *ngIf="accompany.mobile">({{ accompany.mobile }})</span>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </ng-template>

    <ng-template #visitedAnnouncementsTemplate>
      <div>
        <div>
          <ul class="simple-post-list">
            <li *ngFor="let visited of visitedAnnouncements" class="d-flex flex-row justify-content-around">
              <div class="flex-fill">
                <span>{{ visited.title }}</span>
                <span class="pull-right text-info">
                  <span *ngIf="visited.formTitle">
                    Form: {{ visited.formTitle }} -
                  </span>
                  <span *ngIf="visited.inductionTitle">
                    Induction: ({{ visited.inductionTitle }}) -
                  </span>

                  {{ visited.visitDate | datetime }}
                </span>
                <span>
                  <obc-checkin-announcement-renderer [validateComponent]="true" [largeButton]="largeButton"
                    [ann]="visited" [notifyVisits]="false" [anonymousAttendanceKey]="anonymousAttendanceKey"
                    [validateComponent]="true" (onAnnouncementVisit)="callOnAnnouncementVisitEvent($event)"
                    (onCheckedMandatorySignOff)="callOnAnnouncementCheckedMandatorySignOffEvent($event)">
                  </obc-checkin-announcement-renderer>
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ng-template>

    <ng-template #scannedItemsTemplate>
      <div>
        <div>
          <ul class="simple-post-list">
            <div *ngFor="let grp of scannedItems | groupBy: 'scannableGroupId'">
              <h5>{{ grp.value[0].scannableGroupName }}</h5>
              <li class="d-flex flex-row justify-content-around" *ngFor="let scan of grp.value">
                <div class="flex-fill">
                  <div>{{ scan.name }}</div>
                  <div class="pull-right">
                    <span class="text-success">{{
                      scan.scanDateTime | datetime
                      }}</span>
                  </div>
                  <div class="pull-left" *ngIf="scan.storedFileUrl">
                    <obc-file-viewer [urls]="[scan.storedFileUrl]" [btnTitle]="'More Info'"
                      [btnClass]="'btn btn-sm btn-success'"></obc-file-viewer>
                  </div>
                </div>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </ng-template>
  </div>
</section>

<ng-template #disputeModal>
  <div class="card">
    <div class="card-header">
      <h5>Dispute Site Attendance</h5>
      <div class="card-actions">
        <a (click)="this.disputeModalRef.hide()" class="card-action card-action-dismiss"></a>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <app-dispute-attendance [siteAttendanceId]="this.questionAnswer.siteAttendanceId"
            [userInformation]="userInformation" (updateAttachment)="onUpdateAttachment($event)">
          </app-dispute-attendance>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #disputeHistory>
  <div class="card">
    <div class="card-header">
      <h5>Dispute History</h5>
      <div class="card-actions">
        <a (click)="this.disputeModalRef.hide()" class="card-action card-action-dismiss"></a>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <obc-dispute-history [siteAttendanceId]="this.questionAnswer.siteAttendanceId"
            [type]="showDisputeHistoryType"></obc-dispute-history>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #formsTemplate>
  <div>
    <div>
      <ul class="simple-post-list">
        <div *ngFor="let frm of forms" class="card">
          <obc-form-data-details [formDetail]="frm"></obc-form-data-details>
        </div>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #permitFormsTemplate>
  <div>
    <div>
      <ul class="simple-post-list">
        <div *ngFor="let frm of permitForms" class="card">
          <obc-form-data-details [formDetail]="frm"></obc-form-data-details>
        </div>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #supplierDocumentsTemplate>
  <obc-supplier-document-sign-off-viewer [list]="supplierDocuments"></obc-supplier-document-sign-off-viewer>
</ng-template>