import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[obcGridTemplateName]'
})
export class GridTemplateNameDirective {

  constructor(public readonly template: TemplateRef<any>) { }
  @Input('obcGridTemplateName') templateName: string;
}
