<div class="card">
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="row">
      <div class="col-12">
        <form class="form-horizontal form-bordered" [formGroup]="formGroup" (ngFormSubmit)="addGroup()">
          <section class="card">
            <div class="card-body">
              <div class="form-group row">
                <label class="col-sm-4 control-label text-sm-left pt-2">Name:</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="name">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 control-label text-sm-left pt-2">Label:</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="label">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 control-label text-sm-left pt-2">Allow multi scan:</label>
                <div class="col-sm-8">
                  <obc-switch [value]="formGroup.controls.allowMultiScan.value"
                              (valueChanged)="formGroup.controls.allowMultiScan.setValue($event)"></obc-switch>
                </div>
              </div>
            </div>
            <footer class="card-footer text-right">
              <button class="btn btn-primary" [disabled]="!formGroup.valid" (click)="addGroup()">
                Add New Group
              </button>
              <button type="reset" (click)="resetFormGroup()" class="btn btn-default">Cancel</button>
            </footer>
          </section>
        </form>
      </div>
      <hr class="w-100">
      <div class="col-12 mb-2">
        <p style="text-align: center;" *ngIf="groups == null">
          Loading ...
        </p>
        <p style="text-align: center;" *ngIf="groups != null && groups.length == 0">
          Nothing Found
        </p>
        <div class="table-responsive">
          <table *ngIf="groups && groups.length" class="table table-bordered table-striped mb-0">
            <thead>
              <tr>
                <th>Name</th>
                <th>Label</th>
                <th>Allow Multi Scan</th>
                <th>Items Count</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let group of groups">
                <td>
                  <span>{{group.name}}</span>
                </td>
                <td>
                  <span>{{group.label}}</span>
                </td>
                <td>
                  <span>{{group.allowMultiScan ? "Yes" : "No"}}</span>
                </td>
                <td>
                  <span>{{group.count}}</span>
                </td>
                <td>
                  <div class="d-flex flex-row-reverse mb-0" style="text-align: end;">
                    <a style="color:white !important" class="btn btn-sm btn-success  mr-1" [routerLink]="['/scannables/'+group.id+'/items']">
                      Items
                    </a>
                    <button class="btn btn-sm btn-danger  mr-1" (click)="onRemoveGroup(group)">
                      <i class="fa fa-times"></i>
                    </button>
                    <a style="color:white !important" class="btn btn-sm btn-info mr-1" target="_blank" href="/general/generate-scannable-item-pdfs/{{group.id}}">
                      <i class="fa fa-print"></i>
                    </a>
                    <button class="btn btn-sm btn-primary  mr-1" (click)="onShowEditModal(editGroupModal, group)">
                      <i class="fa fa-edit"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
      </div>
    </div>
  </div>
</div>

<ng-template #editGroupModal>
  <div class="card" *ngIf="selectedGroup">
    <obc-loader [show]="inEditProgress"></obc-loader>
    <div class="card-header">
      <span>Edit Group</span>
      <button type="button" class="close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <section class="card-body">
      <form [formGroup]="editFormGroup">
        <input type="hidden" formControlName="id">
        <div class="form-group">
          <label>Name</label>
          <input type="text" class="form-control" formControlName="name">
        </div>
        <div class="form-group">
          <label>Label</label>
          <input type="text" class="form-control" formControlName="label">
        </div>
        <div class="form-group">
          <label>Allow multi scan</label>
          <obc-switch [value]="editFormGroup.controls.allowMultiScan.value"
                      (valueChanged)="editFormGroup.controls.allowMultiScan.setValue($event)"></obc-switch>
        </div>
      </form>
      <div class="mt-2 pull-right">
        <button [disabled]="!editFormGroup.valid" href="#" class="card-link btn btn-sm btn-primary"
                (click)="onUpdateGroup()">
          Save Changes
        </button>
      </div>
    </section>
  </div>
</ng-template>
