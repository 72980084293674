<div class="row">
  <div class="col-md-12">
    <div class="card">
      <header class="card-header">Supplier Document Report</header>
      <div class="card-body">
        <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>

          <ng-template obcGridTemplateName="viewDetails" let-settings="settings" let-data="data">
            <button *ngIf="data?.status != SiteSupplierDocumentStatus.Deleted" class="btn btn-info pull-right ml-1 mb-1" 
                    (click)="exportPdf(data?.siteSupplierDocumentVersionId)">
              PDF
            </button>
            <button *ngIf="checkGridSettingsForHistoryButton(settings)" type="button" class="btn btn-success pull-right"
              (click)="onViewVersions(viewVersionsTemplate, data?.supplierDocumentTypeId)">
              <i class="fas fa-history"></i>
              Document History
            </button>
          </ng-template>

          <ng-template #viewVersionsTemplate>
            <obc-view-site-supplier-document-version-history [siteSupplierDocumentTypeId]="siteSupplierDocumentTypeId">
            </obc-view-site-supplier-document-version-history>
          </ng-template>

        </app-base-grid>
      </div>
    </div>
  </div>
</div>
