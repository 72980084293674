import { AbstractControl } from '@angular/forms';
import { formatDateTime, parseDateTime } from './date-time-format';


export function datetimeValidator(control: AbstractControl): { [key: string]: boolean; } | null {
  if (!control.value)
    return null;
  var input =   control.value.toString();
  var parsed = parseDateTime(control.value);
  var formated = formatDateTime(parsed)
  if (isNaN(parsed.getTime()) || (formated?.toLowerCase() != input?.toLowerCase() && (formated?.toLowerCase() != input.toLowerCase()+", 12:00 am")))
    return { 'datetimeValidator': true };
}
