import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { FilledFormInfoViewModel } from '../models/filled-form-info-view-model';
import { ServerResult} from '../models/server-result-data';
import { ArchiveSiteSupplierDocumentTypeModel, DeleteSiteSupplierDocumentVersion, SiteSupplierDocumentTypeVersionModel } from '../models/supplier-document/site-supplier-document-type-models';
import {
  SupplierDocumentHistoryViewModel
} from '../models/supplier-document/site-supplier-models';
import { ErrorService } from './error.service';
import { SiteSupplierAccessViewModel } from "../models/link_to_company_request_model";
import { PagingRequest } from '../models/paging-request-model';
import { SupplierDocumentDataModel, SupplierDocumentVersionsQueryModel } from '../models/supplier-document-versions-query-model';
import { WorkflowType } from '../enums/workflow/workflow-type';
import { WorkflowDefinitionViewModel } from '../models/workflow/workflow-definition-view-model';
import { SupplierDocumentVersionCommentModel } from '../models/supplier-document-version-comment-model';
import { SupplierDocumentVersionHistoryModel } from '../models/supplier-document-version-history-model';
import { SiteSupplierInfo } from '../models/supplier-document/site-supplier-info';
import { ChangeSiteSupplierDocumentKindQueryModel } from "../models/change-site-supplier-document-kind-query-model";

@Injectable({
  providedIn: 'root'
})
export class SiteSupplierService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getWorkflowListByHashlink(workflowType: WorkflowType, hashLink: string): Observable<WorkflowDefinitionViewModel[]> {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this._http.post<WorkflowDefinitionViewModel[]>(`api/SiteSupplier/workflow-list/${workflowType}`, null, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

  getSupplierDocumentTypeInfoByHashLink(hashLink: string): Observable<SiteSupplierInfo> {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this._http.get<SiteSupplierInfo>(`api/SiteSupplier/get-site-supplier-document-type-info`, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

  getSiteSupplierDocumentTypesByHashlinkModel(model: PagingRequest<SupplierDocumentVersionsQueryModel>, hashLink: string): Observable<SupplierDocumentDataModel> {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this._http.post<SupplierDocumentDataModel>(`api/SiteSupplier/get-site-supplier-document-types`, model, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

  getSiteSupplierDocumentTypesByModel(model: PagingRequest<SupplierDocumentVersionsQueryModel>): Observable<SupplierDocumentDataModel> {
    return this._http.post<SupplierDocumentDataModel>(`api/SupplierDocument/get-site-supplier-documents`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  GetSupplierDocumentHistory(siteSupplierDocumentTypeId: number): Observable<SupplierDocumentVersionHistoryModel> {
    return this._http.post<SupplierDocumentVersionHistoryModel>(`api/SupplierDocument/get-supplier-document-history/${siteSupplierDocumentTypeId}`, null)
      .pipe(catchError(this._errorService.handleError));
  }

  GetSupplierDocumentVersionComment(siteSupplierDocumentVersionId: number): Observable<SupplierDocumentVersionCommentModel> {
    return this._http.post<SupplierDocumentVersionCommentModel>(`api/SupplierDocument/get-supplier-document-version-comment/${siteSupplierDocumentVersionId}`, null)
      .pipe(catchError(this._errorService.handleError));
  }
  // getSiteSupplierDocumentTypes(model: PagingRequest<SupplierDocumentVersionsQueryModel>): Observable<SiteSupplierWithDocumentTypesViewModel> {
  //   return this._http.post<SiteSupplierWithDocumentTypesViewModel>(`api/SiteSupplier/get-site-supplier-with-document-types/`, model);
  // }

  // getSiteSupplierWithDocumentTypesByModel(model: SiteSupplierWithDocumentTypeQueryModel): Observable<SiteSupplierWithDocumentTypesViewModel> {
  //   return this._http.post<SiteSupplierWithDocumentTypesViewModel>(`api/SiteSupplier/get-site-supplier-with-document-types/`, model);
  // }

  // getSiteSupplierWithDocumentTypes(siteSupplierId: number): Observable<SiteSupplierWithDocumentTypesViewModel> {
  //   return this._http.get<SiteSupplierWithDocumentTypesViewModel>(`api/SiteSupplier/get-site-supplier-with-document-types/${siteSupplierId}`);
  // }

  // getSiteSupplierWithDocumentTypesByHashlinkModel(siteSupplierHashlink: string, model: SiteSupplierWithDocumentTypeQueryModel): Observable<SiteSupplierWithDocumentTypesViewModel> {
  //   let headers = new HttpHeaders({ "hash-link": siteSupplierHashlink ?? '' });
  //   return this._http.post<SiteSupplierWithDocumentTypesViewModel>(`api/SiteSupplier/get-site-supplier-with-document-types`, model, { headers: headers });
  // }

  // getSiteSupplierWithDocumentTypesByHashlink(siteSupplierHashlink: string): Observable<SiteSupplierWithDocumentTypesViewModel> {
  //   let headers = new HttpHeaders({ "hash-link": siteSupplierHashlink ?? '' });
  //   return this._http.get<SiteSupplierWithDocumentTypesViewModel>(`api/SiteSupplier/get-site-supplier-with-document-types`, { headers: headers });
  // }



  addSiteSupplierDocumentTypeVersion(model: SiteSupplierDocumentTypeVersionModel, siteSupplierHashlink: string, siteSupplierId: number, siteSupplierDocumentTypeId?: number): Observable<ServerResult> {
    let headers = new HttpHeaders({ "hash-link": siteSupplierHashlink ?? '' });
    return this._http.post<ServerResult>(siteSupplierDocumentTypeId != null ?
      `api/SiteSupplier/add-site-supplier-document-version/${siteSupplierId}/${siteSupplierDocumentTypeId}` :
      `api/SiteSupplier/add-site-supplier-document-version/${siteSupplierId}`, model, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

  addSiteSupplierDocumentTypeVersionList(model: SiteSupplierDocumentTypeVersionModel[], siteSupplierHashlink: string, siteSupplierId: number): Observable<ServerResult> {
    let headers = new HttpHeaders({ "hash-link": siteSupplierHashlink ?? '' });
    return this._http.post<ServerResult>(
      `api/SiteSupplier/add-site-supplier-document-version-list/${siteSupplierId}`, model, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

  getSiteSupplierDocumentVersionList(siteSupplierDocumentTypeId: number, siteSupplierHashlink: string = null): Observable<SupplierDocumentHistoryViewModel> {
    let headers = new HttpHeaders({ "hash-link": siteSupplierHashlink ?? '' });
    return this._http.get<SupplierDocumentHistoryViewModel>(`api/SiteSupplier/get-site-supplier-document-version-list/${siteSupplierDocumentTypeId}`, { headers: headers });
  }

  getFilledForm(formDataId: number, siteSupplierHashlink: string): Observable<FilledFormInfoViewModel> {
    let headers = new HttpHeaders({ "hash-link": siteSupplierHashlink ?? '' });
    return this._http.get<FilledFormInfoViewModel>(`api/SiteSupplier/get-filled-form/${formDataId}`, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

  validateSiteSupplierWithHashLink(hashLink: string): Observable<SiteSupplierAccessViewModel> {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this._http.get<SiteSupplierAccessViewModel>(`api/SiteSupplier/validate-site-supplier`, { headers });
  }

  deleteSiteSupplierDocumentVersion(request: DeleteSiteSupplierDocumentVersion, hashLink: string) {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this._http.post(`api/SiteSupplier/delete-supplier-document-version`, request, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

  archiveSiteSupplierDocumentTypes(model: ArchiveSiteSupplierDocumentTypeModel, hashLink: string) {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this._http.post(`api/SiteSupplier/archive-site-supplier-document-type-list`, model, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

  unarchiveSiteSupplierDocumentTypes(model: ArchiveSiteSupplierDocumentTypeModel, hashLink: string) {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this._http.post(`api/SiteSupplier/unarchive-site-supplier-document-type-list`, model, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

  // getSiteSupplierDocumentVersionById(siteSupplierDocumentTypeVersionId: number): Observable<SiteSupplierDocumentVersionInfo[]> {
  //   let headers = new HttpHeaders({ "hash-link": siteSupplierHashlink ?? '' });
  //   return this._http.get<SiteSupplierDocumentVersionInfo[]>(`api/SiteSupplier/get-site-supplier-document-version-list/${siteSupplierDocumentTypeId}`, { headers: headers });
  // }

  changeSiteSupplierDocumentKind(model: ChangeSiteSupplierDocumentKindQueryModel): Observable<boolean> {
    return this._http.post<boolean>(`api/SiteSupplier/change-site-supplier-document-kind`, model);
  }
}
