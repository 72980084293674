import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ErrorService } from "./error.service";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { ManageSiteViewModel } from "../models/manage-site-view-model";
import { ServerResultData } from "../models/server-result-data";
import {
  ManageSiteSupplierModel,
} from "../models/site-supplier-management/manage-site-supplier-view-model";

@Injectable({
  providedIn: 'root'
})
export class ManageSupplierSiteService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getSiteSupplierFullData(siteSupplierId: number): Observable<ManageSiteViewModel> {
    return this._http.get(`api/site/get-site-supplier-full-data/${siteSupplierId}`).pipe(catchError(this._errorService.handleError));
  }

  saveSitSupplierFullData(siteSupplierId: number, model: ManageSiteSupplierModel): Observable<ServerResultData<ManageSiteViewModel>> {
    return this._http.post<ServerResultData<ManageSiteViewModel>>(`api/site/save-site-supplier-full-data/${siteSupplierId}`, model)
      .pipe(catchError(this._errorService.handleError));
  }

}
