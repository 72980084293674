import { Component, OnInit, Input, HostBinding, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'obc-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true
    }
  ]
})
export class SwitchComponent implements ControlValueAccessor {

  @Input() small: boolean = false;
  @Input() tagged: any;
  @Input() disabled: boolean = false;
  internalDisabled: boolean = false;
  get isDisabled() {
    return this.disabled || this.internalDisabled;
  }
  @Output() public objectChanged: EventEmitter<{ value: boolean, tagged: any }> = new EventEmitter<{ value: boolean, tagged: any }>();
  @HostBinding('attr.id')
  externalId = '';
  @Output() public valueChanged = new EventEmitter();
  @Output() public switchChanged = new EventEmitter();
  @Input()
  set id(value: string) {
    this._ID = value;
    this.externalId = null;
  }

  @Input() semiActive: boolean = false;

  get id() {
    return this._ID;
  }

  private _ID = '';

  @Input() falseLabel: string = 'No';
  @Input() trueLabel: string = 'Yes';

  @Input() falseValue: any;
  @Input() trueValue: any;


  _value = false;
  onChange: any = () => {
    // this function never call
    this.valueChanged.emit(this.value);
    this.objectChanged.emit({ value: this.value, tagged: this.tagged });
  };
  onTouched: any = () => { };

  get value() {
    return this._value;
  }

  @Input() set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
    //this.switchChanged.emit(val);
  }

  constructor() { }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  toggle() {
    this.value = !this.value;
    //Must call emitter only when user act on UI, not when value was set
    var emitValue = (this.value ? this.trueValue : this.falseValue) ?? this.value;
    this.switchChanged.emit(emitValue);
  }
  setDisabledState(isDisabled: boolean): void {
    this.internalDisabled = isDisabled;
  }
}
