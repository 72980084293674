import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageType } from '@microsoft/signalr';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, finalize } from 'rxjs';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { FormType } from 'src/app/enums/form-type';
import { GodUserPermission } from 'src/app/enums/god-user-permission.enum';
import { SupplierDocumentExpireDateMode } from 'src/app/enums/supplier-document-expire-date-mode.enum';
import { WorkflowTemplateAccessMode } from 'src/app/enums/workflow-template-access-mode';
import { WorkflowType } from 'src/app/enums/workflow/workflow-type';
import { CompanyRoleViewModel } from 'src/app/models/company-role.view.model';
import { SupplierDocumentKindViewModel } from 'src/app/models/supplier-document/supplier-document-kind-viewmodel';
import { CompanyWorkflowTemplateViewModel, PlaceholderItemViewModel, WorkflowTemplateViewModel } from 'src/app/models/workflow/company-workflow-template-view-model';
import { VariableDefinitionMode, VariableDefinitionType, VariableValueDefinition } from 'src/app/models/workflow/variable-difinition';
import { CompanyRoleService } from 'src/app/services/company-role.service';
import { CompanyWorkflowTemplateService } from 'src/app/services/company-workflow-template.service';
import { DocumentKindService } from 'src/app/services/document-kind.service';
import { ModalService } from 'src/app/services/modal.service';
import { UserService } from 'src/app/services/user.service';
import { WorkflowTemplateService } from 'src/app/services/workflow-template.service';

@Component({
  selector: 'obc-workflow-renderer',
  templateUrl: './workflow-renderer.component.html',
  styleUrls: ['./workflow-renderer.component.scss']
})
export class WorkflowRendererComponent implements OnInit, AfterViewInit {

  @Input() companyId: number;
  @Input() isStandAloneMode = true;

  _workflowSubType: number;
  @Input() set workflowSubType(value: number) {
    this._workflowSubType = value;
    this.filterWorkflowTemplates();
  }

  get isEditMode() {
    return this.isInEditMode || this.isLockedWorkflowSection;
  }
  @Input() isInEditMode: boolean = false;
  @Input() dataModel: CompanyWorkflowTemplateViewModel = <CompanyWorkflowTemplateViewModel>{};

  _selectedWorkflowTemplateId: number;
  @Input() set selectedWorkflowTemplateId(id: number) {
    this._selectedWorkflowTemplateId = id;
    if (this.dataModel)
      this.dataModel.workflowTemplateId = id;
    this.manipulatePlaceholders();
  }

  _defaultPlaceHolderValues: any;
  @Input() set defaultPlaceHolderValues(values: any) {
    this._defaultPlaceHolderValues = values;
  }

  _defaultStartVariableValues: any;
  @Input() set defaultStartVariableValues(values: any) {
    this._defaultStartVariableValues = values;
  }

  @Input() currentWorkflowAccessMode: WorkflowTemplateAccessMode;

  get isLockedWorkflowSection() {
    return this.currentWorkflowAccessMode == WorkflowTemplateAccessMode.GodUsersOnly && !this.isGodUser;
  }

  _customWorkflowTitle: string;
  get customWorkflowTitle() {
    return this._customWorkflowTitle;
  }
  set customWorkflowTitle(title: string) {
    this._customWorkflowTitle = title;
    if (this.dataModel)
      this.dataModel.workflowTitle = title;
  }

  _workflowType: WorkflowType;
  @Input() set workflowType(value: WorkflowType) {
    this._workflowType = value;
  }
  get workflowType() {
    return this._workflowType;
  }

  inProgress: boolean = false;
  term$ = new BehaviorSubject<string>(null)

  filteredWorkflowTemplateList: WorkflowTemplateViewModel[] = [];
  WorkflowType = WorkflowType;
  FilterType = FilterType;
  FormType = FormType;
  MessageType = MessageType;
  VariableDefinitionType = VariableDefinitionType;
  documentKinds: SupplierDocumentKindViewModel[];

  _selectedSupplierDocumentExpireDateMode: SupplierDocumentExpireDateMode;
  @Input() set expireDateMode(mode: SupplierDocumentExpireDateMode) {
    this._selectedSupplierDocumentExpireDateMode = mode;
    this.manipulatePlaceholders();
  }
  get selectedExpireDateMode(): SupplierDocumentExpireDateMode {
    if (this._selectedSupplierDocumentExpireDateMode != null)
      return this._selectedSupplierDocumentExpireDateMode;
    return this.documentKinds.find(k => k.id == this.dataModel.workflowSubType)?.expireDateMode;
  }
  workflowTemplateList: WorkflowTemplateViewModel[];
  startVariables: VariableValueDefinition[];
  placeholderItems: PlaceholderItemViewModel[];
  roles: CompanyRoleViewModel[];
  isActiveAdvancedMode: boolean = false;
  hasGodUserPermissionForCompany: boolean = false;
  isGodUser: boolean = false;

  get showBasicAdvancedSwitch() {
    return this.hasGodUserPermissionForCompany && this.dataModel?.workflowInfo?.placeholderList?.some(p => p.accessMode.toLowerCase() == 'advanced');
  }

  constructor(
    private workflowTemplateService: WorkflowTemplateService,
    private companyWorkflowTemplateService: CompanyWorkflowTemplateService,
    private companyRoleService: CompanyRoleService,
    private toastrService: ToastrService,
    private modalService: ModalService,
    private userService: UserService,
    private documentKindService: DocumentKindService) { }


  ngOnInit(): void {
    if (this._workflowType !== undefined && this._workflowType != null && this.dataModel)
      this.dataModel.workflowType = this._workflowType;

    this.workflowTemplateService.getAllWorkflowTemplate()
      .subscribe(
        res => {
          if (res) {
            this.workflowTemplateList = res.workflowTemplateViewModel;
            if (!this.isStandAloneMode || this.isInEditMode)
              this.filterWorkflowTemplates();
          }
        }
      );

    this.companyRoleService.getCompanyRolesByCompanyId(this.companyId).subscribe({
      next: (res) => {
        this.roles = res;
      }
    });

    this.documentKindService.list(this.companyId).subscribe(kindList => this.documentKinds = kindList);
    this.hasGodUserPermissionForCompany = this.userService.hasGodUserPermissionForCompany(GodUserPermission.WorklfowAdvancedManagement);
    this.isGodUser = this.userService.isGodUser();

  }

  ngAfterViewInit(): void {
    // if (this.dataModel?.workflowTemplateId)
    //   this.dataModel.workflowTemplateId = this.dataModel.workflowTemplateId;

    // if (this.workflowTemplateList)
    //   if (!this.isStandAloneMode)
    this.filterWorkflowTemplates();
  }

  @Output() onClose = new EventEmitter<any>();

  closeModal() {
    this.onClose?.emit();
  }

  onWorkflowTypeSubTypeChange() {
    this.dataModel.workflowTemplateId = undefined;
    this.dataModel.workflowInfo = undefined;
    this.dataModel.title = undefined;
    this.dataModel.description = undefined;

    this.filterWorkflowTemplates();
  }



  private filterWorkflowTemplates() {
    if (this._workflowSubType && !this.dataModel.workflowSubType)
      this.dataModel.workflowSubType = this._workflowSubType;

    let workflowType: WorkflowType = this.dataModel.workflowType;
    let workflowSubType: number = this.dataModel.workflowSubType;

    // if (this.dataModel && this._selectedWorkflowTemplateId)
    //   this.dataModel.workflowTemplateId = this._selectedWorkflowTemplateId;
    if (this.isLockedWorkflowSection) {
      this.filteredWorkflowTemplateList = this.workflowTemplateList; //Don't filter to show the GOD user selected template and its values if the workflow is locked
    }
    else {
      this.filteredWorkflowTemplateList = this.workflowTemplateList?.filter(p => p.workflowType == workflowType && workflowSubType != null && p.workflowSubType == workflowSubType) ?? [];
      if (!(this.filteredWorkflowTemplateList?.length ?? 0))
        this.filteredWorkflowTemplateList = this.workflowTemplateList?.filter(p => p.workflowType == workflowType && p.workflowSubType == null);

      this.filteredWorkflowTemplateList = this.filteredWorkflowTemplateList.filter(p => this.isGodUser || p.accessMode == WorkflowTemplateAccessMode.Everyone);

      if (this.filteredWorkflowTemplateList?.length && this.dataModel &&
        (!this.isStandAloneMode || workflowSubType) &&
        (!this.dataModel.workflowTemplateId || !this.filteredWorkflowTemplateList.some(t => t.id == this.dataModel.workflowTemplateId))) {
        this.dataModel.workflowTemplateId = this.filteredWorkflowTemplateList[0].id;
      }
    }
    this.manipulatePlaceholders();
  }

  getPlaceHolderValue(item: PlaceholderItemViewModel, template: WorkflowTemplateViewModel): any {
    return this._defaultPlaceHolderValues?.find(p => p.key == item.key)?.value ??
      template?.workflowInfo?.placeholderList?.find(p => p.key == item.key)?.value ??
      item.value;
  }

  manipulatePlaceholders() {
    if (this.workflowTemplateList?.length && this.dataModel?.workflowTemplateId) {
      var template = this.workflowTemplateList?.find(p => p.id == this.dataModel.workflowTemplateId);

      this.dataModel.workflowInfo = JSON.parse(JSON.stringify(template?.workflowInfo));
      this.dataModel.workflowTitle = this.customWorkflowTitle?.length ? this.customWorkflowTitle : template?.workflowTitle;
      this.dataModel.description = template?.description;

      this.dataModel.workflowInfo?.placeholderList?.map(item => {
        item.disabled = item.accessMode.toLowerCase() == 'advanced' && this.isActiveAdvancedMode == false;
        item.value = this.getPlaceHolderValue(item, template);
        if (item.type == FilterType.Switch)
          item.value = item.value == true || item.value == 'true';
      });


      this.startVariables = this._defaultStartVariableValues ?? this.dataModel.workflowInfo.startVariables ?? [];
      // Add expiry variable to the variables if the kind is in signmode and not existed in the workflow variables
      let expireDateKey = "ExpireDate";
      if (this.selectedExpireDateMode != SupplierDocumentExpireDateMode.Disable &&
        !this.startVariables?.some(v => v.key == expireDateKey)) {
        this.startVariables.push({ value: "null", key: expireDateKey, title: "Expiry", type: 1, typeString: "Date", mode: VariableDefinitionMode.Mandatory } as VariableValueDefinition);
      }
      else if (this.selectedExpireDateMode == SupplierDocumentExpireDateMode.Disable &&
        (this.startVariables?.length ?? 0) &&
        this.startVariables?.some(v => v.key == expireDateKey)) {
        this.startVariables = this.startVariables.filter(v => v.key != expireDateKey);
      }

      this.dataModel.workflowInfo?.placeholderList?.filter(p => p.type == FilterType.ActionList).map(item => {
        let commandList = JSON.parse(item.value.toString())
        item.parsedValue = [];
        commandList.forEach(value => {
          item.parsedValue.push(
            {
              RoleList: value.RoleList,
              ConfirmMessage: value.ConfirmMessage,
              CssClass: value.CssClass,
              Key: value.Key,
              Text: value.Text,
              Placeholder: JSON.parse(JSON.stringify(value.Text)),
            }
          )
        });
      });
    }
  }

  onAdvancedModeChanged(isActive) {
    this.isActiveAdvancedMode = isActive;
    this.dataModel.workflowInfo?.placeholderList?.map(item => {
      if (this.isActiveAdvancedMode) {
        item.disabled = false;
      }
      else {
        item.disabled = item.accessMode.toLowerCase() == 'advanced';
      }

    })
  }


  isNotification(key: string): boolean {
    return key.toLowerCase().indexOf('notification') > -1;
  }


  isValid(showErrorMessage = true,): boolean {
    if (this.isStandAloneMode) {
      if (this.dataModel.workflowType == null ||
        this.dataModel.workflowType == undefined ||
        !this.dataModel.workflowSubType ||
        !this.dataModel.workflowTemplateId ||
        !this.dataModel.workflowTitle ||
        !this.companyId) {
        if (showErrorMessage)
          this.toastrService.warning('Please complete all the required fields!');
        return false;
      }
    }

    if (this.dataModel.workflowInfo?.placeholderList?.filter(p => p.type == FilterType.RoleArray && !this.isNotification(p.key) && (!p.value || (<string[]>p.value).length <= 0)).length > 0) {
      if (showErrorMessage)
        this.toastrService.warning('Please select the roles for workflow steps!');
      return false;
    }

    if (this.dataModel.workflowInfo?.placeholderList?.filter(p => p.type == FilterType.ActionList &&
      (!p.parsedValue || p.parsedValue.findIndex(v => v.Text === null || v.Text === undefined || v.Text === '') > -1)).length > 0) {
      if (showErrorMessage)
        this.toastrService.warning('Please enter the action button titles for workflow steps!');
      return false;
    }

    if (this.dataModel.workflowInfo?.placeholderList?.filter(p => p.type == FilterType.ActionList &&
      (!p.parsedValue || p.parsedValue.findIndex(v => v.Key === 'ResetApproval' && (v.RoleList === null || v.RoleList === undefined || v.RoleList === '' || v.RoleList.length <= 0)) > -1)).length > 0) {
      if (showErrorMessage)
        this.toastrService.warning('Please select the role(s) for Reset Approval button!');
      return false;
    }

    return true;
  }

  getWorkflowTemplateModel(): CompanyWorkflowTemplateViewModel {
    this.dataModel.workflowInfo?.placeholderList?.filter(p => p.type == FilterType.ActionList).map(item => {
      let t = <any>JSON.stringify(item.parsedValue);
      item.value = t;
    });

    let model: CompanyWorkflowTemplateViewModel = JSON.parse(JSON.stringify(this.dataModel));
    model.workflowInfo.startVariables = this.startVariables;
    model.companyId = this.companyId;
    return model;
  }

  saveWorkflowTemplate() {
    if (this.isValid()) {

      let model = this.getWorkflowTemplateModel();
      var subscription = this.companyWorkflowTemplateService.addCompanyWorkflowTemplate(model);
      this.inProgress = true;
      subscription
        .pipe(finalize(() => {
          this.inProgress = false
        }))
        .subscribe(res => {
          if (res.success) {
            this.dataModel = <CompanyWorkflowTemplateViewModel>{};
            this.dataModel.companyId = this.companyId;
            this.toastrService.success('Company workflow template created successfully!');
            this.closeModal();
          } else
            this.modalService.error(res.message);
        }, err => {
          this.modalService.error(err)
        })
    }
  }
}
