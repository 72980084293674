export class UserBasicInfoViewModel {
  public countryRegion?: string;
  public id: number;
  public firstName: string;
  public lastName: string;
  public mobile: string;
  constructor(value?: UserBasicInfoViewModel) {
    if (value == null) return;

    var keys = Object.keys(value);
    keys.forEach(element => {
      if (element == 'isIncompleteRegister')
        return;
      this[element] = value[element];
    });
  }
  public get isIncompleteRegister(): boolean {
    return (this.firstName == undefined || this.lastName == undefined) ? true : false
  };


}
export interface UserBasicInfoModel {
  id?: number;
  firstName?: string;
  lastName?: string;
  mobile?: string;
}
