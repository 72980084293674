<div>
    <div class="">
        <div class="img-thumbnail">
            <i class="fas fa-address-card"></i>
        </div>
        <span class="text-primary ml-2" *ngIf="document.documentTypeTitle">
            <i class="fas fa-industry" *ngIf="document.provider == DocumentProvider.Supplier"></i>
            {{ document.documentTypeTitle }}
            <sub *ngIf="document.siteInductionId"><i class="fas fa-id-badge"></i></sub>
        </span>
    </div>
    <div class="post-info flex-fill">
        <h6>
            <div class="text-dark">{{ document.documentTitle }}</div>
            <small class="text-danger small pull-right" *ngIf="document.expireDate">
                Expire In :
                {{ document.expireDate | datetime }}
                <span class="ml-2 badge badge-danger" *ngIf="isExpiredDocument(document)">EXPIRED</span>
            </small>
        </h6>
    </div>
    <div class="align-self-end">
        <obc-file-viewer
            [urls]="[document.fileUrl].concat(document.additionalFileUrls ? document.additionalFileUrls : [])"
            [btnTitle]="'View Documents'" [btnClass]="'btn btn-sm btn-success'"></obc-file-viewer>
    </div>
</div>
