export class SidebarItem {

  private _route: string;
  public parent?: SidebarItem;

  constructor(title: string);
  constructor(title: string, route: string);
  constructor(title: string, route: string, icon: string);
  constructor(title: string, route: string, icon: string, condition: () => boolean);
  constructor(title: string, route: string, icon: string, condition: () => boolean, allChilds: SidebarItem[], isExpanded?: boolean, target?: string);
  constructor(public title: string, route?: string, public icon?: string, public condition: () => boolean = () => true, public allChilds: SidebarItem[] = null, public isExpanded: boolean = false, public target: string = '') {
    if (allChilds) allChilds.forEach(ch => ch.parent = this);
    this._route = route;
  }

  public get childs(): SidebarItem[] {
    if (!this.allChilds) return []
    return this.allChilds?.filter(ch => ch.condition && ch.condition())
  }

  public get show(): boolean {
    return !this.condition || (this.condition() && (!this.allChilds || this.childs.length > 0))
  }

  public get id(): string {
    return this.childs.length > 0 ? this.title.toLowerCase() : null;
  }

  public get route(): string {
    if (this._route) return this._route;
    return null;
  }

  public get element(): any {
    return document.getElementById(this.id);
  }

  private _routeLinkActive: string[]
  public get routerLinkActive(): string[] {
    if (!this._routeLinkActive) {
      this._routeLinkActive = this.hasChild ? [] : ['link-active', 'nav-active'];
    }
    return this._routeLinkActive
  }

  public get class(): string {
    return ''
  }

  public get hasChild(): boolean {
    return this.childs.length > 0;
  }

  public get href(): string {
    return this.route?.startsWith('http') ? this.route : null;
  }

}
