<div class="row" *ngIf="permitsInfo">
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table table-bordered table-striped mt-3">
        <thead>
        <tr>
          <td></td>
          <td>
            Name
          </td>
          <td>
            Status
          </td>
          <td>
            Start Date
          </td>
          <td>
            End Date
          </td>
          <td></td>
        </tr>
        </thead>
        <tbody>

        <ng-container *ngIf="permitsList?.length > 0">
          <tr *ngFor="let item of permitsList">
            <td>
              <i *ngIf="item.filledFormStatus == FormDataStatus.PendingApproval"
                 class="text-danger fas fa-history"></i>
              <i *ngIf="item.filledFormStatus == FormDataStatus.Approved" class="text-success fas fa-check"></i>
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.filledFormStatus | filledFormStatus }}
            </td>
            <td>
              {{ item.startDate | date }}
            </td>
            <td>
              {{ item.endDate | date }}
            </td>
            <td>
              <button *ngIf="!isSupplierUser" class="btn-sm btn btn-primary ml-2 pull-right"
                      (click)="onViewFormDetails(formDetail , item.filledFormId)"> Details
              </button>
            </td>
          </tr>
        </ng-container>

        </tbody>

        <tfoot *ngIf="permitsInfo.allowToApplyNewPermit">
        <tr>
          <td [attr.colspan]="20">
            <div class="d-flex justify-content-center mt-5">
              <button (click)="onApplyNewPermit(applyNewPermit)" class="btn btn-danger">
                <i class="fa fa-plus"></i>
                Apply For A New Permit
              </button>
            </div>
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
  <div class="col-md-12 mt-2" style="text-align: end">
  </div>
</div>

<ng-template #applyNewPermit>
  <div>
    <div>
      <ul class="simple-post-list">
        <div class="card">
          <obc-supplier-document-review
            [siteId]="siteId"
            (hide)="onHidePopup()" (resultOfApproveReject)="fetchPermitList()"
            [hashLink]="siteSupplierHashLink" [formType]="FormType.Permit" [siteSupplierId]="siteSupplierId">
          </obc-supplier-document-review>
        </div>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #formDetail>
  <div>
    <ul class="simple-post-list">
      <obc-form-data-details
        [siteSupplierHashlink]="siteSupplierHashLink"
        class="card" [formDataId]="selectedFormDataId" (onRefresh)="onApproveOrRejectPermit()">
      </obc-form-data-details>
    </ul>
  </div>
</ng-template>
