import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'askQuestionPeriodMode'
})
export class AskQuestionPeriodModePipe implements PipeTransform {

  transform(value: unknown): any {
    if (value === 0) { return "Always"; }
    if (value === 1) { return "Only One Time"; }
    if (value === 2) { return "One Time Per Site"; }
    if (value === 3) { return "One Time Per Day"; }
    if (value === 4) { return "One Time Per Day Per Site"; }
    return "N/A";
  }

}
