<table class="table table-bordered table-striped">
    <thead>
        <tr>
            <th>Date</th>
            <th>From</th>
            <th>To</th>
            <th>By</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let h of history">
            <td>{{h.disputeDateTime | datetime}}</td>
            <td>{{h.oldValue | datetime}}</td>
            <td>{{h.newValue | datetime}}</td>
            <td>{{h.disputerUserFullName}}</td>
        </tr>
    </tbody>
</table>