import { WeekDay } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToWeekday'
})
export class NumberToWeekdayPipe implements PipeTransform {

  transform(value: number): string {
    if (value == WeekDay.Sunday) return 'Sunday';
    if (value == WeekDay.Monday) return 'Monday';
    if (value == WeekDay.Tuesday) return 'Tuesday';
    if (value == WeekDay.Wednesday) return 'Wednesday';
    if (value == WeekDay.Thursday) return 'Thursday';
    if (value == WeekDay.Friday) return 'Friday';
    if (value == WeekDay.Saturday) return 'Saturday';
    return value?.toString() ?? '?';
  }
}
