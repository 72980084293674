import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formTitle',
})
export class FormTitlePipe implements PipeTransform {
  transform(value: any): string {
    if (value) return value?.toString() + ' - ' ?? '?';
  }
}
