<section class="card">
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="dataTables_wrapper dt-bootstrap4 no-footer">
      <div class="row">
        <div class="col-12">
          <button id="addToTable" class="btn btn-info my-2 pull-left" (click)="onNewCompanyUser()">Add New User To <span
            class="text-light"> {{currentCompanyName}} </span><i class="fas fa-plus pl-2"></i></button>
          <div class="my-2  pull-right" *ngIf="!addNewMode">
            <button id="addToTable" class="btn btn-success mr-1" (click)="openImportUserModal(importModal)">Import
              Users
            </button>
            <a *ngIf="userService.hasCompanyFeature(ProductPlanFeature.Export)" target="_blank" class="btn btn-danger "
               style="color: white !important;" href="/general/export-company-users">
              <i class="fas fa-copy mr-1"></i>Export Users
            </a>
          </div>
        </div>
        <form class="p-4 my-3 new-user-form" *ngIf="addNewMode" [formGroup]="addCompanyUserForm">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-md-4">
                  <label>Mobile: </label>
                  <obc-user-autocomplete #mobile [filterBy]="filterUserBy.Mobile" [required]="true"
                                         (selectedUser)="fillCompanyUser($event)" [currentUser]="selectedUser"
                                         (inputChange)="companyUser.mobile = $event.value" display="mobile"
                                         name="mobile"
                                         [notFoundMessage]="isValidMobile ? 'add as a new user' : ''">
                  </obc-user-autocomplete>
                  <small class="text-warning light-theme" for="mobile" *ngIf="!isValidMobile">Please enter a valid mobile
                    number.</small>
                </div>
                <div class="col-12 col-md-4">
                  <label>First Name: </label>
                  <obc-user-autocomplete
                    [filterBy]="filterUserBy.FirstName" [displayNoResult]="false" [required]="true"
                    (selectedUser)="fillCompanyUser($event)" [currentUser]="selectedUser"
                    (inputChange)="companyUser.firstName = $event.value" display="firstName">
                  </obc-user-autocomplete>
                </div>
                <div class="col-12 col-md-4 mt-1 mt-md-0">
                  <label>Last Name: </label>
                  <obc-user-autocomplete [filterBy]="filterUserBy.LastName" [required]="true"
                                         [displayNoResult]="false"
                                         (selectedUser)="fillCompanyUser($event)" [currentUser]="selectedUser"
                                         (inputChange)="companyUser.lastName = $event.value" display="lastName">
                  </obc-user-autocomplete>
                </div>
              </div>
            </div>
            <div class="col-12 ml-auto">
              <small class="text-warning" for="mobile">&nbsp;</small>
              <div class="d-flex flex-row flex-wrap justify-content-end">
                <label class="mb-0 lh-40 text-black-100 mr-2">Add To Frequent Users?</label>
                <obc-switch [value]="companyUser.addToFrequentUser"
                            (valueChanged)="companyUser.addToFrequentUser = $event">
                </obc-switch>
              </div>
            </div>
            <div class="col-12 mt-2" *ngIf="companyPermissionMode == PermissionModeEnum.Mixed ||
companyPermissionMode == PermissionModeEnum.Role">
              <div class="d-flex flex-column">
                <span class="mb-1">Roles</span>
                <obc-role-selector
                  (result)="updateNewUserRoles($event)"
                  [selectedRoles]="this.companyUser?.companyRoles ?? []"
                ></obc-role-selector>
              </div>
            </div>
            <div class="col-12 mt-2" *ngIf="companyPermissionMode == PermissionModeEnum.Mixed">
              <ng-container>
                <accordion>
                  <accordion-group heading="Permissions">
                    <ng-container
                      *ngTemplateOutlet="addUserPermissionsTemplate;">
                    </ng-container>
                  </accordion-group>
                </accordion>
              </ng-container>
            </div>
            <div class="col-12" *ngIf="companyPermissionMode == PermissionModeEnum.Permission">
              <ng-container>
                <ng-container
                  *ngTemplateOutlet="addUserPermissionsTemplate; context: {formClass: 'p-3'}">
                </ng-container>
              </ng-container>
            </div>
            <div class="col-12 mt-4">
              <div class="d-flex justify-content-end">
                <button
                  [disabled]="!this.isValidate()"
                  class="btn btn-info d-flex align-items-center" (click)="addCompanyUser()">
                  <i class="fas fa-save"></i>
                  <span class="ml-2">Save New User</span>
                </button>
                <button class="btn btn-light ml-2 d-flex align-items-center" (click)="cancel()">
                  <i class="fas fa-times"></i>
                  <span class="ml-2">Cancel</span>
                </button>
              </div>
            </div>
            <ng-template #addUserPermissionsTemplate let-formClass="formClass">
              <div class="d-flex flex-row flex-wrap justify-content-end">
                <label class="mb-0 lh-40 text-black-100 mr-2">Has All Permissions?</label>
                <obc-switch [value]="companyUser.isAdmin" (valueChanged)="companyUser.isAdmin = $event">
                </obc-switch>
              </div>
              <div *ngIf="!companyUser.isAdmin">
                <label><b>Permissions: </b></label>
                <obc-check-list-form-control style="font-size: smaller;"
                                             [formClass]="formClass ?? ''"
                                             [formControl]="newUserPermissionsFormControl"
                                             [values]="companyUserPermissions"></obc-check-list-form-control>
              </div>
            </ng-template>
          </div>
        </form>

        <div class="table-responsive" *ngIf="!addNewMode">
          <table class="table table-bordered table-striped mb-0 dataTable no-footer">
            <thead>
            <tr role="row">
              <th>Mobile</th>
              <th>Name</th>
              <th>Family</th>
              <th>Access Level</th>
              <!-- <th>Company</th> -->
              <th>Actions</th>
            </tr>
            <tr>
              <td colspan="6">
                <input type="text" class="form-control" placeholder="Filter Result by Mobile, Name, Family"
                       [(ngModel)]="filterListTerm"/>
              </td>
            </tr>
            </thead>
            <tbody>
            <ng-container
              *ngFor="let user of (companyUsers | filter:filterListTerm:['mobile','firstName','lastName'])">
              <tr *ngIf="!user.inEdit && !addNewMode">
                <td>
                  {{user.mobile | mobile}}
                  <img *ngIf="user.isObcStaff" src="/assets/img/obc_logo.png" class="obc-logo"
                       title="1Breadcrumb Staff"/>
                </td>
                <td>{{user.firstName}}</td>
                <td>{{user.lastName}}</td>
                <td>
                  <div class="d-flex flex-column"
                       *ngIf="companyPermissionMode == PermissionModeEnum.Mixed ||
companyPermissionMode == PermissionModeEnum.Role">
                    <ng-container *ngIf="user.companyRoles?.length  > 0">
                      <span class="font-weight-bold">Roles:</span>
                      <div class="d-flex role-container ml-3">
                          <div>{{getRoles(user)}}</div>
                      </div>

                    </ng-container>
                  </div>
                  <div class="d-flex flex-column"
                       *ngIf="companyPermissionMode == PermissionModeEnum.Mixed ||
companyPermissionMode == PermissionModeEnum.Permission">
                    <ng-container>
                      <div class="d-flex">
                        <span *ngIf="user.isAdmin" class="font-weight-bold">Has All Permissions</span>
                      </div>
                      <ng-template #tooltipHtml>
                        <div *ngFor="let l of getPermissionLabels(user)">
                        <div>{{l.label}}</div>
                        </div>
                      </ng-template>
                      <div class="d-flex permission-container">
                          <span
                            class="permissions-caption font-weight-semibold"
                            *ngIf="!user.isAdmin && (user.permissions != userPermissions.None)"
                                [tooltip]="tooltipHtml"
                                >{{getPermissionCount(user)}}
                            Permission(s)</span>
<!--                        <span *ngIf="!user.isAdmin && (user.permissions == userPermissions.None)">No Permission</span>-->
                      </div>
                    </ng-container>
                  </div>
                </td>
                <td class="actions">
                  <a class="btn p-1 remove-row" (click)="delete(user)"><i class="far fa-trash-alt"></i></a>
                  <a tooltip="Edit" class="btn btn-succes" (click)="onEdit(editUserPermissions, user)">
                    <i class="fa fa-edit"></i>
                  </a>
                  <a *ngIf="user.addToFrequentUser" tooltip="Add this user to frequent users list for all sites"
                     class="btn btn-succes" (click)="onAddUserToFrequentUserList(user)">
                    <i class="fas fa-user-plus"></i>
                  </a>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #editUserPermissions>
  <form [formGroup]="editUserPermissionFormGroup">

    <div class="card">
      <div class="card-header">
      <span>
        Manage User Permissions
      </span>
        <button type="button" class="close" (click)="bsModalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>First name: </label>
              <span class="ml-1 font-weight-semibold">{{ currentUser.firstName }}</span>
              <!--              <input type="text" disabled class="form-control" [(ngModel)]="currentUser.firstName">-->
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>Last name: </label>
              <span class="ml-1 font-weight-semibold">{{ currentUser.lastName }}</span>
              <!--              <input type="text" disabled class="form-control" [(ngModel)]="currentUser.lastName">-->
            </div>
          </div>
        </div>
        <div class="row">

          <div class="col-12 mt-3" *ngIf="companyPermissionMode == PermissionModeEnum.Mixed ||
companyPermissionMode == PermissionModeEnum.Role">
            <div class="d-flex flex-column">
              <span class="mb-1">Roles: </span>
              <obc-role-selector
                (result)="updateRoles($event)"
                [selectedRoles]="currentUser.companyRoles"
              ></obc-role-selector>
            </div>
          </div>

          <ng-container *ngIf="companyPermissionMode == PermissionModeEnum.Mixed ||
companyPermissionMode == PermissionModeEnum.Permission">
            <div class="col-12">
              <hr>
            </div>
            <div class="col-12">
              <ng-container *ngIf="companyPermissionMode == PermissionModeEnum.Mixed">
                <accordion>
                  <accordion-group heading="Permissions">
                    <ng-container
                      *ngTemplateOutlet="userPermissionsTemplate; context: {isCurrentUserIsAdmin: isCurrentUserIsAdmin}">
                    </ng-container>
                  </accordion-group>
                </accordion>
              </ng-container>
              <ng-container *ngIf="companyPermissionMode == PermissionModeEnum.Permission">
                <ng-container
                  *ngTemplateOutlet="userPermissionsTemplate; context: {isCurrentUserIsAdmin: isCurrentUserIsAdmin}">
                </ng-container>
              </ng-container>
            </div>
          </ng-container>

        </div>
      </div>
      <div class="card-footer">
        <p class="button-group">
          <button class="btn btn-primary" (click)="onUpdate()">
            <i class="fas fa-edit mr-1"></i>
            Update
          </button>
        </p>
      </div>
    </div>

    <ng-template #userPermissionsTemplate>
      <div class="row">
        <div class="col-12 mb-3">
          <div class="d-flex justify-content-end">
            <label class="form-check-label mr-2 mt-2">Has All Permissions?</label>
            <obc-switch formControlName="isAdmin">
            </obc-switch>
          </div>
        </div>
        <div class="col-12">
          <obc-check-list-form-control
            *ngIf="!isCurrentUserIsAdmin"
            style="font-size: smaller;" formControlName="permissions"
            [values]="companyUserPermissions"></obc-check-list-form-control>
        </div>
      </div>
    </ng-template>
  </form>

</ng-template>


<ng-template #importModal>
  <div class="card">
    <div class="card-header">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalHandle.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 mt-1">
          <p>
            Import company user data in CSV file format (comma seperated)
            <a class="btn btn-primary btn-sm" href="../../../../assets/files/company-users-import.csv"
               target="_blank">Download Sample File</a>
          </p>
        </div>
        <div class="col-md-12 mt-1">
          <obc-file-upload [showClearButton]="true" (clear)="onClearForm()"
                           [formControl]="fileFormControl"
                           [accept]="FileUploadAcceptType.CSV">
          </obc-file-upload>
        </div>
        <div class="col-md-12 mt-1" *ngIf="importData && importData.valid && importData.message">
          <div class="alert alert-success mb-1" role="alert">
            {{importData.message}}
          </div>
        </div>
        <div class="col-md-12 mt-1" *ngIf="!users || users.length == 0">
          <!-- <div class="alert alert-primary mb-1" role="alert">
            <p>You can import CSV file</p>
            <p>CSV file (comma seperated) must be provide in following format:</p>
            <p>First line must be header and contain Mobile,IsAdmin,FirstName,LastName which seperated by comma</p>
            <p>Other lines contain user data as follows</p>
            <ul>
              <li>Mobile number is mandatory</li>
              <li>IsAdmin is optional and it's value is Yes or No (default value is No)</li>
              <li>FirstName and LastName are optional. these fields used if user not registed before</li>
            </ul>
            <p>Before import check phone number didn't format by excel as scientific number</p>
            <p></p>
          </div> -->
        </div>
        <div class="col-md-12 mt-1" *ngIf="importData && !importData.valid">
          <div class="alert alert-danger mb-1" role="alert">
            {{importData.message}}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
