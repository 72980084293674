<obc-loader [show]="inProgress"></obc-loader>
<div class="row">
  <div class="col-12 col-lg-8 mb-1">
    <button *ngIf="formMode" class="btn btn-default" [routerLink]="['/sites']">
      <i class="fas fa-arrow-left"></i> Return to List
    </button>
    <button *ngIf="!formMode" class="btn btn-default" [routerLink]="['/supplier']">
      <i class="fas fa-arrow-left"></i> Return to List
    </button>
  </div>
</div>

<section class="card" style="z-index: 10">
  <header class="card-header">
    <h5>Supplier Selection</h5>
    <div class="card-actions">
    </div>
  </header>
  <div class="card-body">
    <div class="row">

      <div *ngIf="!formMode" class="col-12 col-xl-6">
        <label>Select a Supplier</label>
        <obc-company-supplier-selector [required]="true" #supplierSelector [control]="supplierFormControl">
        </obc-company-supplier-selector>
      </div>

      <div *ngIf="formMode" class="col-12 col-xl-6">
        <label>Select a Site</label>

        <app-site-selector [required]="true" [siteId]="formId" (siteIdSelect)="changeFormId($event)"
          [permission]="UserPermission.SupplierManagement">
        </app-site-selector>

      </div>
      <div class="col-md-12 mt-3">
        <span *ngIf="selectedSupplier?.lastEmailSendDate">
          Last Request Email:
          <span *ngIf="selectedSupplier?.lastEmailSendDate" class="text-success">
            <strong>Requested
              ({{selectedSupplier?.lastEmailAddress}} - {{selectedSupplier?.lastEmailSendDate | datetime}})
            </strong>
          </span>
          <!-- <span *ngIf="!selectedSupplier?.lastEmailSendDate" class="text-warning">
          <strong>Unrequested</strong>
        </span> -->
        </span>
        <button *ngIf="selectedSupplier?.forms?.length" (click)="onViewHashLink(selectedSupplier, clipboardTemplate)"
          class="ml-3 btn btn-warning">
          <i class="fa fa-envelope"></i>
          Request form completion by Email
        </button>
      </div>
    </div>
  </div>
</section>

<section class="card">
  <header class="card-header">


    <div class="d-flex">
      <h5>List of Supplier Forms</h5>
      <button class="ml-auto btn btn-info" (click)="_openNewForm(newForm)">
        <span class="fa fa-plus mr-2"></span>
        New
      </button>
    </div>
    <div class="card-actions">
    </div>
  </header>
  <div class="card-body">

    <div class="">
      <table class="table table-bordered table-striped mb-0">
        <thead>
          <tr>
            <td *ngIf="!formMode">Form</td>
            <td *ngIf="formMode">Supplier</td>
            <td>Last Approved Form</td>
            <td>Pending Approval Form</td>
            <td></td>
          </tr>

        </thead>
        <tbody>

          <ng-container *ngIf="selectedSupplier && selectedSupplier.forms.length > 0; ">
            <ng-container *ngFor="let form of selectedSupplier.forms">
              <tr>
                <td *ngIf="!formMode">
                  <div>
                    {{ form.formName }}
                  </div>
                </td>
                <td>
                  <div *ngIf="form.lastApprovedFormData">
                    <ul>
                      <li>
                        Version:
                        <strong style="color: #aeb4da">
                          {{ form.lastApprovedFormData?.displayVersion }}
                        </strong>
                      </li>
                      <li>
                        Submit Date:
                        <strong>
                          {{ form.lastApprovedFormData?.fillDateTime | datetime }}
                        </strong>
                      </li>
                      <li *ngIf="form.lastApprovedFormData?.endDate">
                        Expiry:
                        <strong>
                          {{ form.lastApprovedFormData?.endDate | datetime }}
                        </strong>
                      </li>
                      <li class="text-success">
                        {{ form.lastApprovedFormData?.status | formDataStatus }}
                        by:
                        <strong>{{ form.lastApprovedFormData?.approveRejectByUserFullName }}
                          ({{ form.lastApprovedFormData?.approveRejectDateTime | datetime }})
                        </strong>
                      </li>
                    </ul>
                    <button tooltip="View" class="mr-1 btn btn-warning"
                      (click)="onViewForm(formDetails,form.lastApprovedFormData)">
                      <span class="fa fa-eye"></span>
                      View Details
                    </button>
                  </div>
                </td>
                <td>
                  <div *ngIf="form.pendingFormData">
                    <ul>
                      <li>
                        Version:
                        <strong style="color: #aeb4da">
                          {{ form.pendingFormData?.displayVersion }}
                        </strong>
                      </li>
                      <li>
                        Submit Date:
                        <strong>
                          {{ form.pendingFormData?.fillDateTime | datetime }}
                        </strong>
                      </li>
                      <li *ngIf="form.pendingFormData?.endDate">
                        Expiry:
                        <strong>
                          {{ form.pendingFormData?.endDate | datetime }}
                        </strong>
                      </li>
                      <li style="color: #a6a000">
                        {{ form.pendingFormData?.status | formDataStatus }}

                      </li>
                    </ul>

                    <button *ngIf="form.pendingFormData?.status == formDataStatus.PendingApproval" tooltip="View"
                      class="mr-1 btn btn-info" (click)="onViewForm(formDetails, form.pendingFormData)">
                      <span class="fa fa-eye"></span>
                      Review
                    </button>

                  </div>
                </td>

                <td>
                  <button tooltip="History" class="mr-1 btn btn-info" (click)="displayHistory(form, historyView)">
                    <span class="fa fa-history"></span>
                    History
                  </button>
                  <button tooltip="Remove" class="mr-1 btn btn-danger" *ngIf="form.canDelete"
                    (click)="onRemoveForm(form)">
                    <span class="fa fa-times"></span>
                    Delete
                  </button>


                </td>
              </tr>
            </ng-container>
          </ng-container>

        </tbody>
      </table>
    </div>

  </div>
</section>


<div class="my-5"></div>

<ng-template #clipboardTemplate>
  <div class="card m-1">
    <div class="card-header">
      <span>Supplier Link</span>
      <button type="button" class="close" (click)="refViewHashLink.hide()">
        <i class="fa fa-times" style="font-size: 14px;"></i>
      </button>
    </div>
    <div class="card-body">
      <app-clipboard-box (copied)="refViewHashLink.hide()" [text]="currentLink" label=""></app-clipboard-box>
    </div>
  </div>
  <div class="card m-1">
    <div class="card-body">
      <div class="d-column">
        <div>Send Supplier Link To:</div>
        <input class="form-control" type="email" placeholder="Email Address" [formControl]="email">
        <input class="form-control mt-1" type="text" placeholder="Email Subject" [formControl]="emailSubject">
        <div class="mt-1">
          <obc-markdown-editor [value]="emailBody.value" (valueChanged)="emailBody.setValue($event)"
            [id]="'emailBodyRichBox'">
          </obc-markdown-editor>
        </div>
        <div class="mt-3">
          <button type="button" class="fw btn btn-primary" (click)="onSendEmail()">
            <i class="fa fa-envelope"></i>
            {{ firstEmail ? 'Send Email Again' : 'Send Email' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #newForm>
  <section class="card">
    <header class="card-header">
      <div class="d-flex">
        <h5>List of Supplier Forms</h5>
      </div>
      <div class="card-actions">
      </div>
    </header>
    <div class="card-body">
      <div *ngIf="!formMode" class="col-12 mb-2">
        <label>Select a form</label>
        <obc-form-selector [placeholder]="'Select a form'" [required]="true" [formType]="formType.SupplierForm"
          [formId]="selectedSupplier.forms[0]?.formId" [excludeFormIds]="selectedFormIds"
          [selectFirstItemOfOneItemArray]="true" (selectedForm)="onSelectForm($event)">
        </obc-form-selector>

      </div>

    </div>
    <footer class="card-footer">
      <button class="btn btn-primary ml-auto" style="min-width: 150px;" [disabled]="formId == null"
        (click)="onAddForm()">
        Add

      </button>
      <button class="btn btn-secondary ml-3" style="min-width: 150px;" (click)="_clearForm()">
        Cancel
      </button>
    </footer>
  </section>
</ng-template>

<ng-template #historyView>
  <div class="card">
    <button type="button" class="btn btn-sm modal-close-button" (click)="closeFormDetailsModal()">
      <i class="fa fa-times"></i>
    </button>
    <obc-supplier-form-history [formId]="selectedFormIdToViewHistory" [supplierId]="selectedSupplier.supplierId">
    </obc-supplier-form-history>
  </div>
</ng-template>

<ng-template #formDetails>
  <button type="button" class="btn btn-sm modal-close-button" (click)="closeFormDetailsModal()">
    <i class="fa fa-times"></i>
  </button>
  <obc-form-details (onRefesh)="closeFormDetailsModal(); _calSupplierForms()" [formDataId]="selectedFormDataId">
  </obc-form-details>
</ng-template>
