<li [attr.data-id]="document?.id" obc-document-type-viewer-item
    [canAdd]="canAdd"
    [canDelete]="canDelete"
    [canUpdate]="canUpdate"
    [draggable]="draggable"
    *ngFor="let document of children"
    [document]="document"
    [documents]="allDocuments"
    [cacheUniqueId]="cacheUniqueId"
    (orderedList)="onOrderedList($event)"
></li>
