import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { extractParamsFromModel } from '../helpers/extract-params-from-model';
import { SiteAttendanceTriggerResultSearchModel } from '../models/site-attendance-trigger-result-search-model';
import { ErrorService } from './error.service';
import { SiteAttendanceTriggerResultViewModel } from '../models/site-attendance-trigger-result-view-model';

@Injectable({
  providedIn: 'root'
})
export class SiteAttendanceTriggerResultService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }
  apiUrl = '/api/siteAttendanceTriggerResult';

  getSiteAttendanceTriggerResults = (model: SiteAttendanceTriggerResultSearchModel): Observable<SiteAttendanceTriggerResultViewModel[]> =>
    this._http.get<SiteAttendanceTriggerResultViewModel[]>(`${this.apiUrl}`, { params: extractParamsFromModel(model) })
      .pipe(catchError(this._errorService.handleError));

  updateFlaggState = (id: number, isFlagged: boolean): Observable<any> =>
    this._http.post<any>(`${this.apiUrl}/${id}`, isFlagged)
      .pipe(catchError(this._errorService.handleError));

}
