<div *ngIf="list && list.length > 0">
    <div *ngFor="let doc of list">
        <obc-loader #loader></obc-loader>
        <section class="{{!compactMode?'card':''}} mb-2">
            <div class="card-header clearfix">
                <strong>{{doc.supplierDocumentTypeName}}</strong> {{doc.version}}
                <span *ngIf="doc.signOffDate && doc.isSignedOff" class="pull-right">
                    <i class="text-success fas fa-check-circle"></i>
                    {{doc.signOffDate | datetime}}
                </span>
            </div>
            <div class="d-flex flex-column card-body" *ngIf="!compactMode">
                <div *ngIf="doc.description != null && doc.description != ''" class="d-flex pt-1 px-1 pb-4">
                    <strong class="mr-2">Description:</strong> {{ doc.description }}
                </div>
                <div class="d-flex flex-row">
                    <obc-file-viewer [files]="doc.supplierDocumentFiles" [btnTitle]="'View Files'"
                        [btnClass]="'m-1  btn-sm btn btn-info'" [prefix]="doc.supplierDocumentTypeName+'-'+doc.version">
                    </obc-file-viewer>
                    <obc-file-viewer *ngIf="doc.signatureImage" [files]="[doc.signatureImage]"
                        [btnTitle]="'View User Signature'" [btnClass]="'m-1 btn-sm btn btn-success'"
                        [imgClass]="'signature-bg'" [prefix]="doc.supplierDocumentTypeName+'-'+doc.version">
                    </obc-file-viewer>
                </div>
                <div *ngIf="canApprove && doc.isSignedOff != true">
                    <button style="width: 100%;" (click)="onAcceptSupplier(doc.siteSupplierDocumentVersionId, loader)"
                        class="btn btn-warning">
                        Accept
                    </button>
                </div>
            </div>
        </section>
    </div>
</div>