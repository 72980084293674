import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DisputeHistoryRequest, SiteAttendanceDisputeModel, SiteAttendanceDisputeViewModel } from '../models/site-attendance-dispute-model';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class DisputeSiteAttendanceService {

  constructor(private http: HttpClient, private errorService: ErrorService) { }

  dispute(model: SiteAttendanceDisputeModel) {
    return this.http.post("/api/DisputeSiteAttendance/dispute-site-attendance", model)
      .pipe(catchError(this.errorService.handleError));
  }

  getDisputeHistory(request: DisputeHistoryRequest): Observable<SiteAttendanceDisputeViewModel[]> {
    return this.http.post("/api/DisputeSiteAttendance/dispute-history", request)
      .pipe(catchError(this.errorService.handleError));
  }

}
