import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from "rxjs";
import { SiteSupplierAccessMode } from 'src/app/enums/site-supplier-access-mode.enum';
import { SiteSupplierAccessViewModel } from 'src/app/models/link_to_company_request_model';
import { CompanySupplierService } from 'src/app/services/company-supplier.service';
import { UserService } from 'src/app/services/user.service';
import { SiteSupplierService } from "../../../services/site-supplier.service";
import { CompanyAuthService } from 'src/app/services/company-auth.service';

@Component({
  selector: 'app-site-supplier-document-uploader-container',
  templateUrl: './site-supplier-document-uploader-container.component.html',
  styleUrls: ['./site-supplier-document-uploader-container.component.scss']
})
export class SiteSupplierDocumentUploaderContainerComponent implements OnInit, AfterViewInit, OnDestroy {

  selectedTab = new BehaviorSubject<string>("documents");
  siteSupplierId: number = null;
  hashlink: string = null;
  supplierId: number = null;
  siteId: number = null;
  validateResult: SiteSupplierAccessViewModel;

  referer: string;
  showDocumentsArchiveTab: boolean = false;

  get isHashlinkMode(): boolean {
    return this.hashlink?.length > 0;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserService,
    private companySupplierService: CompanySupplierService,
    private siteSupplierService: SiteSupplierService,
    private companyAuthService: CompanyAuthService
  ) { }

  ngOnDestroy(): void {
    this.companyAuthService.setCurrentCompanyId(this.userService.currentCompanyId);
    this.companyAuthService.setCompanyId();
  }

  fragment: string;
  ngAfterViewInit(): void {
    try {
      if (this.fragment?.length)
        this.selectedTab.next(this.fragment);
    } catch (e) { }
  }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    });
    this.route.params.subscribe(res => {
      this.hashlink = res["hashlink"];
      this.siteSupplierId = res["id"];

      this.supplierId = res['supplierId'];
      this.siteId = res['siteId'] ?? null;
      this.referer = res['ref']?.replace(/_/g, "/");

      if (this.isHashlinkMode) {
        this.companyAuthService.setHashLink(this.hashlink);
      }

      (this.isHashlinkMode ?
        this.siteSupplierService.validateSiteSupplierWithHashLink(this.hashlink) :
        this.companySupplierService.validateSiteSupplier(this.siteSupplierId, this.supplierId, this.siteId)).subscribe(validateResult => {
          if (validateResult) {

            this.validateResult = validateResult;
            if (this.isHashlinkMode) {
              this.companyAuthService.setHashLink(this.hashlink)
              this.siteId = validateResult.siteId;
            }
            else {
              this.companyAuthService.setCompanyId(validateResult?.mainCompanyId);
            }

            //show DocumentsArchiveTab as last tab
            setTimeout(() => {
              this.showDocumentsArchiveTab = true;
            }, 10);

            if (validateResult.accessMode == SiteSupplierAccessMode.Invalid)
              this.router.navigate(['/no-access']);

          } else
            this.router.navigate(['/no-access']);
        });
    });
  }
}
