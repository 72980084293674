<div>
    <ng-select style="padding-bottom: 0 !important;" class="site-selector" [typeaheadOptionsLimit]="7"
        [typeaheadMinLength]="0" [items]="items$ | async"
        [placeholder]="formControl.value ? null : (notSelectedLabel ? notSelectedLabel : (_required ? 'Please Select Item':'All Items'))"
        bindLabel="{{getItemLabelKey()}}" (scrollToEnd)="onScrollToEnd()" bindValue="{{getItemValueKey()}}"
        [typeahead]="term$" [loading]="loading" [clearable]="!_required" [formControl]="formControl">
        <ng-template ng-option-tmp let-item="item">
            {{getItemLabel(item)}}
        </ng-template>
    </ng-select>
</div>