<div class="form-inline">
    <input class="form-control {{cssClass}}" type="number" min="0"
           #inputEl
           (click)="selectValue(inputEl)"
           (input)="setHourMinuteValues($event.target.value, inputEl)"
        [(ngModel)]="value" name="forceCheckOutAnonymousAfterInMinute" required>
    <div class="btn-group" btnRadioGroup >
        <label class="btn" [ngClass]="mode=='Hour'?'btn-info':'btn-default'" btnRadio="Hour"
            (click)="changeMode('Hour')">Hour</label>
        <label class="btn" [ngClass]="mode=='Minute'?'btn-info':'btn-default'" btnRadio="Minute"
            (click)="changeMode('Minute')">Minute</label>
    </div>
</div>
