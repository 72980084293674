<div class="row">
  <div class="col-md-12">
    <button class="btn btn-default" [routerLink]="['/sites']">
      <i class="fas fa-arrow-left"></i> Return to List
    </button>
  </div>
  <div class="col-md-12">
    <div class="card" style="border:none">
      <obc-loader [show]="inProgress"></obc-loader>
      <div class="card-body" style="padding: 0;">
        <obc-company-trigger-action [inProgress$]="inProgress$" [siteId]="siteId" [siteName]="site.name">
        </obc-company-trigger-action>
      </div>
    </div>
  </div>
</div>