import { Component, Input, OnInit } from '@angular/core';
import { SiteQuestionItemFormControl, SiteQuestionRendererViewModel } from 'src/app/models/site-question-viewmodel';
import { QuestionRendererComponent } from '../question-renderer/question-renderer.component';
import { FormBuilder } from '@angular/forms';
import { EmergencyContactEmitResponse } from 'src/app/models/emergency-contact-emit-response';
import { CacheService } from "../../../../services/cache.service";

@Component({
  selector: 'obc-emergency-contact-question-renderer',
  templateUrl: './emergency-contact-question-renderer.component.html',
  styleUrls: ['./emergency-contact-question-renderer.component.scss']
})
export class EmergencyContactQuestionRendererComponent extends QuestionRendererComponent implements OnInit {

  defaultAnswerStringify: string;
  @Input() set question (model: SiteQuestionRendererViewModel){
    this.defaultAnswerStringify = model.initialAnswers && model.initialAnswers.length > 0 ? 
      model.initialAnswers[0]?.answer : null;

    this.model = model;
    this.formGroup = this.fb.group({
      answer : new SiteQuestionItemFormControl(null, null)
    });
  };

  constructor(private fb : FormBuilder, public cacheService: CacheService) {
    super(cacheService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onUpdateEmergencyContact(ecValue: EmergencyContactEmitResponse) {
    this.formGroup.controls.answer.setValue(ecValue);
    this.model.valid;
  }
}