import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { catchError } from 'rxjs/operators';
import { ChartResponse } from '../models/chart-response';
import { DashboardTotalsInfo } from '../models/dashboard-total-infos';
import { DashboardReportPeriod } from '../enums/dashboard-report-period.enum';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  apiUrl: string = '/api/dashboard';
  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  get timeZone(): number {
    return new Date().getTimezoneOffset();
  }

  getChackInBySiteInDateRange = (): Observable<ChartResponse[]> =>
    this._http.get<ChartResponse[]>(`${this.apiUrl}/bysite/${this.timeZone}`)
      .pipe(catchError(this._errorService.handleError));

  //like get check-in in lastweek, in this case labels should be last week days name
  getCheckInByDateInDateRange = (): Observable<ChartResponse[]> =>
    this._http.get<ChartResponse[]>(`${this.apiUrl}/bydate/${this.timeZone}`)
      .pipe(catchError(this._errorService.handleError));


  getDashboardTotalInfos = (): Observable<DashboardTotalsInfo> =>
    this._http.get<DashboardTotalsInfo>(`${this.apiUrl}/totals/${this.timeZone}`)
      .pipe(catchError(this._errorService.handleError));

  getDashboardWidgets = (period : DashboardReportPeriod): Observable<any> =>
    this._http.get<any>(`${this.apiUrl}/get-dashboard-widgets/${this.timeZone}/${period}`)
      .pipe(catchError(this._errorService.handleError));
  updateCompanyWidgetConfig = (model: any): Observable<any> =>
    this._http.post<any>(`${this.apiUrl}/update-dashboard-widgets`, model)
      .pipe(catchError(this._errorService.handleError));
}
