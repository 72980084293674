import { ChartType } from '../enums/chart-type';
import { ChartResponse } from './chart-response';
import { createBgColor, rgbStringFormat } from '../helpers/color-helper';

export class ChartModel {
    chartType: ChartType;
    data: {};
    options: {};
    title: string;
    inProgress: boolean;

    private labels: string[];
    private values: number[];
    colors: string[] = [];
    legendPosition: string = 'top';
    titlePosition: string = 'bottom';

    constructor(type: ChartType) {
        this.chartType = type;
    }

    init(data: ChartResponse[]) {

        this.labels = data.map(c => c.label);
        this.values = data.map(c => c.value);
        this.colors = data.map(c => rgbStringFormat(createBgColor()));

        switch (this.chartType) {
            case ChartType.line:
                this.data = this.lineFormat["data"];
                this.options = this.lineFormat["options"];
                break;
            case ChartType.pie:
                this.data = this.pieFormat["data"];
                this.options = this.pieFormat["options"];
                break;
        }
    }

    public static getChartModel(widget: any): ChartModel {
        var chart = new ChartModel(widget.chartType as ChartType);
        chart.legendPosition = widget.legendPosition;
        chart.titlePosition = widget.titlePosition;
        chart.title = widget.widgetModel.title;
        var colors = widget.labels.map(c => rgbStringFormat(createBgColor()));

        switch (chart.chartType) {
            case ChartType.line:
                chart.data = {
                    labels: widget.labels,
                    datasets: (widget.datasets as any[]).map(p => {
                        return {
                            label: p.label,
                            data: p.data,
                            lineTension: 0,
                            fill: false,
                            backgroundColor: colors,
                            borderColor: rgbStringFormat(createBgColor()),
                        }
                    })
                };
                chart.options = {
                    legend: {
                        display: true,
                        position: widget.legendPosition
                    },
                    title: {
                        display: true,
                        position: widget.titlePosition,
                        text: widget.widgetModel.title
                    },
                    tooltips: {
                        enabled: true,
                        mode: 'index',
                        intersect: true,
                        callbacks : {
                            title : ()=> {
                                return null;
                            },
                            label : (dataPoint, data) => {
                                var tooltips = widget.datasets[dataPoint.datasetIndex].tooltips[dataPoint.index];
                                return `${tooltips} : ${dataPoint.yLabel}`;
                            }
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: true
                }
                break;
            case ChartType.pie:
                chart.data = {
                    labels: widget.labels,
                    datasets: (widget.datasets as any[]).map(p => {
                        return {
                            label: p.label,
                            data: p.data,
                            lineTension: 0,
                            fill: false,
                            backgroundColor: colors,
                            borderColor: rgbStringFormat(createBgColor()),
                        }
                    })
                };
                chart.options = {
                    legend: {
                        display: widget.labels != null && widget.labels.length <= 10,
                        position: widget.legendPosition
                    },
                    title: {
                        display: true,
                        position: widget.titlePosition,
                        text: widget.widgetModel.title
                    },
                    responsive: true,
                    maintainAspectRatio: true
                }
                break;
        }
        return chart;
    }


    get lineFormat(): {} {
        return {
            data: {
                labels: this.labels,
                datasets: [
                    {
                        label: "Daily Check-in ***",
                        lineTension: 0,
                        fill: false,
                        data: this.values,
                        backgroundColor: this.colors,
                        borderColor: rgbStringFormat(createBgColor()),
                    }
                ]
            },
            options: {
                legend: {
                    display: true,
                    position: this.legendPosition
                },
                title: {
                    display: true,
                    position: this.titlePosition,
                    text: this.title
                },
                responsive: true,
                maintainAspectRatio: true
            }
        };
    }

    get pieFormat(): {} {
        return {
            data: {
                labels: this.labels,
                datasets: [
                    {
                        label: "Daily Check-in ***",
                        lineTension: 0,
                        fill: false,
                        data: this.values,
                        backgroundColor: this.colors,
                    }
                ]
            },
            options: {
                legend: {
                    display: this.labels != null && this.labels.length <= 10,
                    position: this.legendPosition,
                },
                title: {
                    display: true,
                    position: this.titlePosition,
                    text: this.title
                },
                responsive: true,
                maintainAspectRatio: true
            }
        };
    }
}

