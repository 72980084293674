import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyViewModel } from '../models/company-viewmodel';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { catchError } from 'rxjs/operators';
import { UpdateCompanyProfileModel } from '../models/company-profile-viewmodel';
import { SendWelcomeEmailUserProfile } from '../models/user-profile-viewmodel';
import { CompanyTypeViewModel } from '../models/company-type-viewmodel';
import { CompaniesQueryModel } from '../models/get-companies-query-model';
import { PagingViewModel } from '../models/site-list-paging-view-model';
import { CompanyStats } from '../models/company-stats';
import { CompanyInductionsAndCheckInConfigurationViewModel, CompanyKioskModeConfigurationViewModel, CompanyPermitConfigurationViewModel, CompanyReportsConfigurationViewModel } from '../models/company-feature-configuration-viewmodel';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getCompanies = (query: CompaniesQueryModel): Observable<PagingViewModel<CompanyViewModel>> =>
    this._http.post<PagingViewModel<CompanyViewModel>>(`/api/company/get-all`, query)
      .pipe(catchError(this._errorService.handleError));

  addCompany = (model, templateCompanyId?: number): Observable<any> =>
    this._http.post<any>(`/api/company/add/${templateCompanyId}`, model)
      .pipe(catchError(this._errorService.handleError));

  editCompany = (model): Observable<any> =>
    this._http.post<any>('/api/company/update', model)
      .pipe(catchError(this._errorService.handleError));

  getCompanyUsers = (id): Observable<any> =>
    this._http.get<any>(`/api/company/get-users/${id}`)
      .pipe(catchError(this._errorService.handleError));

  addUser = (user): Observable<any> =>
    this._http.post<any>(`/api/company/add-user`, user)
      .pipe(catchError(this._errorService.handleError));

  updateUserAdminState = (userId: number, adminState: boolean): Observable<any> =>
    this._http.put<any>(`/api/company/edit-user/${userId}`, adminState)
      .pipe(catchError(this._errorService.handleError));

  removeUser = (id): Observable<any> =>
    this._http.get<any>(`/api/company/remove-user/${id}`)
      .pipe(catchError(this._errorService.handleError));

  getTemplateCompanies = (): Observable<any> =>
    this._http.get<any>('/api/company/template-companies')
      .pipe(catchError(this._errorService.handleError));

  getCurrentCompanyProfile = (): Observable<UpdateCompanyProfileModel> =>
    this._http.get<UpdateCompanyProfileModel>('/api/companyprofile/get-profile')
      .pipe(catchError(this._errorService.handleError));

  setActivePlan = (companyId: number, activePlanId: number): Observable<boolean> =>
    this._http.get<UpdateCompanyProfileModel>(`/api/company/set-active-plan/${companyId}/${activePlanId}`)
      .pipe(catchError(this._errorService.handleError));

  updateCurrentCompanyProfile = (model: UpdateCompanyProfileModel): Observable<UpdateCompanyProfileModel> =>
    this._http.post<UpdateCompanyProfileModel>('/api/companyprofile/update-profile', model)
      .pipe(catchError(this._errorService.handleError));

  sendWelcomeEmail = (model: SendWelcomeEmailUserProfile): Observable<void> =>
    this._http.post<void>('/api/SendMessage/send-welcome-email', model)
      .pipe(catchError(this._errorService.handleError));

  getCompanyTypes = (): Observable<CompanyTypeViewModel[]> =>
    this._http.get<any>('/api/company/company-types')
      .pipe(catchError(this._errorService.handleError));

  getCompanyStats = (companyId: number): Observable<CompanyStats[]> =>
    this._http.get<any>(`/api/company/company-stats/${companyId}`)
      .pipe(catchError(this._errorService.handleError));

  getCompanyPermitConfiguration = (): Observable<CompanyPermitConfigurationViewModel> =>
    this._http.get<CompanyPermitConfigurationViewModel>('/api/companyFeatureConfiguration/get-company-permit-configuration')
      .pipe(catchError(this._errorService.handleError));      

  updateCompanyPermitConfiguration = (model: CompanyPermitConfigurationViewModel): Observable<CompanyPermitConfigurationViewModel> =>
    this._http.post<CompanyPermitConfigurationViewModel>('/api/CompanyFeatureConfiguration/update-company-permit-configuration', model)
      .pipe(catchError(this._errorService.handleError));

  getCompanyInductionsAndCheckInConfiguration = (): Observable<CompanyInductionsAndCheckInConfigurationViewModel> =>
    this._http.get<CompanyInductionsAndCheckInConfigurationViewModel>('/api/companyFeatureConfiguration/get-company-inductions-checkin-configuration')
      .pipe(catchError(this._errorService.handleError));
      
  updateCompanyInductionsAndCheckInConfiguration = (model: CompanyInductionsAndCheckInConfigurationViewModel): Observable<CompanyInductionsAndCheckInConfigurationViewModel> =>
   this._http.post<CompanyInductionsAndCheckInConfigurationViewModel>('/api/CompanyFeatureConfiguration/update-company-inductions-checkin-configuration', model)
     .pipe(catchError(this._errorService.handleError));  
     
  getCompanyKioskModeConfiguration = (): Observable<CompanyKioskModeConfigurationViewModel> =>
    this._http.get<CompanyKioskModeConfigurationViewModel>('/api/companyFeatureConfiguration/get-company-kiosk-mode-configuration')
      .pipe(catchError(this._errorService.handleError));

  updateCompanyKioskModeConfiguration = (model: CompanyKioskModeConfigurationViewModel): Observable<CompanyKioskModeConfigurationViewModel> =>
    this._http.post<CompanyKioskModeConfigurationViewModel>('/api/CompanyFeatureConfiguration/update-company-kiosk-mode-configuration', model)
      .pipe(catchError(this._errorService.handleError));        

  getCompanyReportsConfiguration = (): Observable<CompanyReportsConfigurationViewModel> =>
    this._http.get<CompanyReportsConfigurationViewModel>('/api/companyFeatureConfiguration/get-company-reports-configuration')
      .pipe(catchError(this._errorService.handleError)); 
      
  updateCompanyReportsConfiguration = (model: CompanyReportsConfigurationViewModel): Observable<CompanyReportsConfigurationViewModel> =>
     this._http.post<CompanyReportsConfigurationViewModel>('/api/CompanyFeatureConfiguration/update-company-reports-configuration', model)
       .pipe(catchError(this._errorService.handleError));        
}
