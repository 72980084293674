import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CompanyEmployeeViewModel } from '../models/company-user-view-model';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyEmployeeService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }
  apiUrl = '/api/employee';

getCurrentCompanyEmployees = (): Observable<any> =>
    this._http.get<any>(`${this.apiUrl}/get-current-company-employees`)
      .pipe(catchError(this._errorService.handleError));

  addEmployee = (user: CompanyEmployeeViewModel): Observable<CompanyEmployeeViewModel> =>
    this._http.post<CompanyEmployeeViewModel>(`${this.apiUrl}/add-company-employee`, user)
      .pipe(catchError(this._errorService.handleError));

  removeEmployee = (id: number): Observable<any> =>
    this._http.get<any>(`${this.apiUrl}/delete-company-employee/${id}`)
      .pipe(catchError(this._errorService.handleError));

  ImportUsers(formData: any): Observable<any> {
    return this._http.post(`${this.apiUrl}/import-users`, formData,
      { reportProgress: false, observe: 'events', headers: { "do-not-modify-content-type": "" } })
      .pipe(catchError(this._errorService.handleError));
  }

  getEmployeeDocuments(employeeId: number): Observable<any> {
    return this._http.get(`${this.apiUrl}/get-employee-docs/${employeeId}`)
      .pipe(catchError(this._errorService.handleError));
  }

}
