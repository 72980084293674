import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { IndustryTypeService } from 'src/app/services/industry-type-service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LayoutService } from 'src/app/services/layout.service';
import { IndustryTypeViewModel } from '../../../models/industry-type/industry-type-view-model';
import { IndustryTypeModel } from '../../../models/industry-type/industry-type-model';

@Component({
  selector: 'obc-manage-industry-types',
  templateUrl: './manage-industry-types.component.html',
  styleUrls: ['./manage-industry-types.component.css']
})
export class ManageIndustryTypesComponent implements OnInit {

  newIndustryFormGroup: FormGroup;
  editIndustryFormGroup: FormGroup;
  inProgress: boolean = false;
  inAddEditProgress: boolean = false;
  industryTypes: IndustryTypeViewModel[] = null;
  selectedIndustryType: IndustryTypeViewModel = null;
  bsModalRef: BsModalRef;
  message: string;
  errorMessage: string;
  errorEditModalMessage: string;
  constructor(private layoutService: LayoutService,
    private IndustryTypeService: IndustryTypeService,
    private fb: FormBuilder,
    private bsModalService: BsModalService,
  ) { }

  ngOnInit() {
    this.layoutService.header = "Manage Industry Types";
    this.newIndustryFormGroup = this.fb.group({
      type: new FormControl('', [Validators.required]),
      isActive: new FormControl(true, [Validators.required])
    });

    this.getIndustryTypes();
  }

  private getIndustryTypes() {
    this.inProgress = true;
    this.IndustryTypeService.getIndustryTypes().subscribe(res => {
      this.industryTypes = res;
      this.inProgress = false;
    });
  }

  addIndustryType() {
    this.errorMessage = null;
    var newIndustryType = new IndustryTypeModel();
    newIndustryType.type = this.newIndustryFormGroup.get('type').value;
    newIndustryType.isActive = this.newIndustryFormGroup.get('isActive').value;

    this.inProgress = true;
    this.IndustryTypeService.addIndustryType(newIndustryType).subscribe(res => {
      var industryType = new IndustryTypeViewModel();
      industryType.id = res;
      industryType.type = newIndustryType.type;
      industryType.isActive = newIndustryType.isActive;
      this.industryTypes.push(industryType);
      this.industryTypes.sort((a, b) => a.type.localeCompare(b.type));
      this.inProgress = false;
      this.newIndustryFormGroup.reset();
      this.newIndustryFormGroup.controls.isActive.setValue(true);
    }, err => {
      this.errorMessage = err;
      this.inProgress = false;
      setTimeout(() => {
        this.errorMessage = null;
      }, 10000)

    });
  }

  onShowEditModal(template, industryType: IndustryTypeViewModel) {
    this.errorEditModalMessage = null;
    this.selectedIndustryType = industryType;
    this.editIndustryFormGroup = this.fb.group({
      id: new FormControl(industryType.id),
      type: new FormControl(industryType.type, [Validators.required, Validators.maxLength(500)]),
      isActive: new FormControl(industryType.isActive),
    });
    this.bsModalRef = this.bsModalService.show(template);
  }

  onSubmitEditForm() {
    if (this.editIndustryFormGroup.valid) {
      var model = {
        id: this.selectedIndustryType.id,
        type: this.editIndustryFormGroup.get("type").value,
        isActive: this.editIndustryFormGroup.get("isActive").value,
      } as IndustryTypeViewModel;
      this.inAddEditProgress = true;
      this.IndustryTypeService.editIndustryType(model).subscribe(res => {
        this.errorEditModalMessage = null;
        this.selectedIndustryType.type = model.type;
        this.selectedIndustryType.isActive = model.isActive;
        this.bsModalRef.hide();
        this.inAddEditProgress = false;
      }, err => {
        this.errorEditModalMessage = err;
        this.inAddEditProgress = false;
        setTimeout(() => {
          this.errorEditModalMessage = null;
        }, 10000)

      });
    }
  }
}
