import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormArray, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { QuestionType } from 'src/app/enums/question-type';
import { CustomFormControl } from 'src/app/models/custom-formcontrol';
import { ItemTitleValue } from 'src/app/models/helper-models';
import { QuestionViewModel } from 'src/app/models/question-viewmodel';
export interface FormOption {
  item: ItemTitleValue,
  form: FormControl
}

@Component({
  selector: 'app-answer-selector',
  templateUrl: './answer-selector.component.html',
  styleUrls: ['./answer-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AnswerSelectorComponent,
      multi: true,
    }
  ]
})
export class AnswerSelectorComponent implements ControlValueAccessor, OnInit {
  changeFn: Function = null;
  touchFn: Function = null;
  _question: QuestionViewModel;
  QuestionType = QuestionType;
  get type(): QuestionType {
    return this._question.questionType;
  }
  formControl: FormControl;
  formOptions: FormOption[];
  @Input() set question(value: QuestionViewModel) {
    if (this._question?.id == value.id) return;
    this._question = value;
    switch (value.questionType) {
      case QuestionType.Text:
        {
          this.formControl = new FormControl();
          this.formControl.valueChanges.subscribe(res => {
            this.writeValue([res]);
          })
          break;
        }
      case QuestionType.Boolean:
      case QuestionType.SingleSelect:
        {
          var form = new FormControl(false, [Validators.required]);
          form.valueChanges.subscribe(res => {
            this.writeValue([res]);
          })
          this.formOptions = this._question.getOptions(true).map(e => {
            return {
              item: e,
              form: form,
            } as FormOption
          });
          break;
        }
      case QuestionType.MultiSelect:
        this.formOptions = this._question.getOptions(true).map(e => {
          return {
            item: e,
            form: new FormControl(false, []),
          } as FormOption
        });
        this.formOptions.forEach(i => {
          i.form.valueChanges.subscribe(res => {
            var selected = this.formOptions.filter(x => x.form.value).map(x => x.item.itemValue);
            this.writeValue(selected.length == 0 ? null : selected);
          })
        })
        break;
    }
  }

  constructor() { }

  writeValue(answer: any): void {
    if (this.changeFn)
      this.changeFn(answer);
  }

  registerOnChange(fn: any): void {
    this.changeFn = fn;

  }
  registerOnTouched(fn: any): void {
    this.touchFn = fn;
  }

  setDisabledState?(isDisabled: boolean): void {

  }

  ngOnInit() {

  }

}
