import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DateComparisonMode,
  DateQuestionLimit,
  DateQuestionLimitValueMode,
  DateQuestionParameter
} from 'src/app/models/questionParameters/date-question-parameter';
import { QuestionDateResultViewModel } from "../../../models/question-date-result-model";

@Component({
  selector: 'obc-question-date',
  templateUrl: './question-date.component.html',
  styleUrls: ['./question-date.component.scss']
})
export class QuestionDateComponent implements OnInit {
  _questionParam: DateQuestionParameter;
  hasMinimumLimit: boolean = false;
  hasMaximumLimit: boolean = false;
  _minLimit = null;
  defaultMin = {
    dateComparisonMode: DateComparisonMode.Before, dayCount: 5, valueMode: DateQuestionLimitValueMode.Relative,
    compareValue: undefined
  };
  _maxLimit = null;
  defaultMax = {
    dateComparisonMode: DateComparisonMode.After, dayCount: 5, valueMode: DateQuestionLimitValueMode.Relative,
    compareValue: undefined
  };


  get minLimit(): DateQuestionLimit {
    return this._minLimit;
  }

  set minLimit(value: DateQuestionLimit) {
    this._minLimit = value;
    this._questionParam.limitForMinimumValue = value;
  }

  get maxLimit(): DateQuestionLimit {
    return this._maxLimit;
  }

  set maxLimit(value: DateQuestionLimit) {
    this._maxLimit = value;
    this._questionParam.limitForMaximumValue = value;
  }

  @Output() onChanged: EventEmitter<QuestionDateResultViewModel> =
    new EventEmitter<QuestionDateResultViewModel>();

  constructor() {
  }

  @Input() set questionParamInput(_questionParamInput: any) {
    this._questionParam = _questionParamInput;
    this.minLimit = this._questionParam?.limitForMinimumValue;// ?? this.minLimit;
    this.maxLimit = this._questionParam?.limitForMaximumValue;// ?? this.maxLimit;
    this.hasMinimumLimit = this.minLimit != null;
    this.hasMaximumLimit = this.maxLimit != null;
  }

  emitChanges() {
    this.onChanged.emit({
      hasMinLimit: this.minLimit != null,
      hasMaxLimit: this.maxLimit != null,
      params: this._questionParam
    });
  }

  ngOnInit(): void {
  }

  onToggleLimit(isMinToggle: boolean) {
    if (isMinToggle) {
      if (this.hasMinimumLimit)
        this.minLimit = this.defaultMin;
      else
        this.minLimit = null;
    } else {
      if (this.hasMaximumLimit)
        this.maxLimit = this.defaultMax;
      else
        this.maxLimit = null;
    }
    this.emitChanges();
  }

}
