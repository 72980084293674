import { Component, Input, OnInit } from '@angular/core';
import { SiteQuestionItemFormControl, SiteQuestionRendererViewModel } from "../../../../models/site-question-viewmodel";
import { FormBuilder, FormControl } from "@angular/forms";
import { ModalService } from "../../../../services/modal.service";
import { SignatureData } from "../../../../custom-controls/signature-pad/signature-pad.component";
import { QuestionRendererComponent } from '../question-renderer/question-renderer.component';
import { UploadFileInfo } from "../../../../models/requiredDocumentAnswer";
import { CacheService } from "../../../../services/cache.service";

@Component({
  selector: 'obc-signature-pad-question-renderer',
  templateUrl: './signature-pad-question-renderer.component.html',
  styleUrls: ['./signature-pad-question-renderer.component.scss']
})
export class SignaturePadQuestionRendererComponent extends QuestionRendererComponent implements OnInit {

  answer: string;
  base64FileExtension: string;
  isBase64Answer: boolean;
  parsedInitialBase64AnswersJson: UploadFileInfo[];
  
  files : File[]= [];
  @Input() set question(model: SiteQuestionRendererViewModel) {

    let initialAnswerValue: string;
    this.parsedInitialBase64AnswersJson = model.lastStateInitialFileAnswers ?? [];
    this.isBase64Answer = false;
    let initialAnswer = model.initialAnswers ? model.initialAnswers[0] : null;
    
    if(initialAnswer?.isBase64Answer) {
      this.parsedInitialBase64AnswersJson = [
        ...JSON.parse(initialAnswer.answer),
        ...this.parsedInitialBase64AnswersJson,
      ];
    }

    if(this.parsedInitialBase64AnswersJson?.length) {
      let initialAnswerValues: string[] = [];
      let initialAnswerFileTypes: string[] = [];
      this.parsedInitialBase64AnswersJson?.map((item) => {
        initialAnswerValues.push(item.fileAsBase64);
        initialAnswerFileTypes.push(item.fileExtension);
      });
      initialAnswerValue = initialAnswerValues[0];
      this.base64FileExtension = initialAnswerFileTypes[0];
      this.isBase64Answer = true;
    } else {
      initialAnswerValue = model.initialAnswers && model.initialAnswers?.length > 0 && model.initialAnswers[0]?.fileUrls?.length > 0 ? model.initialAnswers[0]?.fileUrls[0] : null;
    }
    this.answer = initialAnswerValue;
    this.model = model;
    this.formGroup = this.fb.group({
      value: new SiteQuestionItemFormControl(initialAnswerValue, null)
    });
  };

  constructor(private fb: FormBuilder, private modalService: ModalService, public cacheService: CacheService) {
    super(cacheService);
  }

  ngOnInit() {
    this.signaturePadController.setValue(this.answer);
    super.ngOnInit();
  }

  signaturePadController: FormControl = new FormControl(null);

  sign(signTemplate, formControl: FormControl) {
    this.signaturePadController = formControl;
    this.modalService.show(signTemplate);
  }

  removeSign() {
    this.signaturePadController.setValue(null);
    this.formGroup.get('value').setValue(null);
  }

  saveSign(signature: SignatureData) {
    this.signaturePadController.setValue('data:image/png;base64,'+ signature.signatureAsPng);
    this.signaturePadController.updateValueAndValidity();
    this.signaturePadController.setErrors(null);
    this.formGroup.get('value').setValue(signature.signatureAsPng);
    this.modalService?.hide();
  }

  displayBase64() {
    return 'data:image/' + this.base64FileExtension + ';base64,'+this.formGroup.controls.value.value;
  }
}
