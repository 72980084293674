<script src="../../../pipes/question-profile-sub-type.pipe.ts"></script>
<div *ngIf="question" class="flex-fill">
  <i class="fas fa-question-circle mr-1"
    [ngClass]="{'text-success' : question.isActive , 'text-danger' : !question.isActive}" aria-hidden="true"></i>
  <ng-template #tooltip>
    <div *ngIf="question.questionParametersAsString['triggers']?.length > 0">
      <div class="d-flex flex-column">
        <div *ngFor="let t of question.questionParametersAsString['triggers']">
          <span>
            {{conditionValue(t)}}:
          </span>
          <span *ngIf="t.action.flagUser">Tag </span>
          <span *ngIf="t.action.rejectUser">Reject </span>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #condition_tooltip>
    <div *ngIf="question.conditions?.length">
      <div class="d-flex flex-column">
        <div><b>Show If:</b></div>
        <div *ngFor="let condition of question.conditions">
          <span>{{condition.masterQuestionTitle}} : {{condition.answers}}</span>
        </div>
      </div>
    </div>
  </ng-template>
  <i style="cursor: pointer;" class="fas fa-bolt text-danger mr-1" [tooltip]="tooltip" placement="right"
    *ngIf="question.questionParametersAsString?.triggers?.length > 0"></i>
  <i style="cursor: pointer;" class="fas fa-directions mr-1" [tooltip]="condition_tooltip" placement="right"
    *ngIf="question.conditions?.length"></i>
  <a>{{question.questionText}}</a>
  <sup class="text-danger" *ngIf="question.isAnswerRequired()" tooltip="Required">
    <i class="fas fa-star-of-life ml-2"></i>
  </sup>
  <i class="fas fa-exclamation-circle ml-1 text-warning" *ngIf="question.isSensitive" tooltip="Sensitive"></i>
  <small class="text-warning"> {{ question.isActive ? '' : ' - disabled'}}</small>
  <div class="post-meta ml-4 text-primary">
    {{question.questionType|questionType : true}}
    <ng-container *ngIf="question.questionType == QuestionType.ProfileInfo">
      -
      <span class="profile-info-sub-type">{{ question.questionParametersAsString['questionProfileSubType'] |
        questionProfileSubTypePipe }}</span>
    </ng-container>
    <span class="text-danger"
      *ngIf="question.questionType == QuestionType.SingleSelect || question.questionType == QuestionType.MultiSelect">
      ({{question.questionParametersAsString['renderMode'] | questinRenderMode:question.questionType}})
    </span>
    <span class="text-dark" *ngIf="showItems(question)">
      - <span class="font-weight-bold"> Items</span>:
      {{(question.getOptionTitleList(true)).join(", ")}}
    </span>
    <span class="doc-type-file" *ngIf="question.documentTypeIdList?.length > 0">
      - Document Type ( {{question.documentTypeIdList?.length}} items )
    </span>
    <span class="doc-type-file" *ngIf="question.questionParametersAsString['fileType'] != undefined
      && question.documentTypeIdList?.length == 0">
      - {{ question.questionParametersAsString['fileType'] | questionAnswerFileFormat }}
    </span>
  </div>
  <div class="post-meta ml-4">{{ question.questionParametersAsString['regex'] | regex}}</div>
</div>