import { Component, OnInit, HostListener } from '@angular/core';
import { AcademyService } from 'src/app/services/academy.service';
import { VideoViewModel, VideoList } from 'src/app/models/video-viewmodel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, of } from 'rxjs';
import { UserSystem } from 'src/app/enums/user-system.enum';
import { groupBy } from 'rxjs/operators';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'obc-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss']
})
export class VideoListComponent implements OnInit {
  query: string;
  bsModalRef: BsModalRef;
  _videos : VideoList[] = [];
  
  get videoLists () : VideoList[] {
    return this._videos;
  }

  getVideos(videoList: VideoList): VideoViewModel[] {
    if (videoList.videos == null) return null;
    var ret = videoList.videos;
    if (this.query != null && this.query.length > 0) {
      var q = this.query.toLowerCase();
      ret = ret.filter((v) => {
        var f1 = v.title == null ? true : v.title.toLowerCase().indexOf(q) >= 0;
        return f1;
      })
    }
    return ret;
  }

  windowsSize$: Subject<any> = new Subject<any>();
  video: VideoViewModel = null;
  constructor(private academyService : AcademyService,
    private layoutService: LayoutService,
    private bsModalService: BsModalService) { }

  ngOnInit() {
    this.layoutService.header = "Video Gallery";
    this.academyService.getCategorizedList(UserSystem.desktop)
    .subscribe(res => {
      this._videos = res;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowsSize$.next(event);
  }

  onhide() {
    if (this.bsModalRef)
      this.bsModalRef.hide();
  }

  onVideoCardClick(template,video : VideoViewModel) {
    this.video = video;
    this.bsModalRef = this.bsModalService.show(template, Object.assign({}, { class: 'gray modal-lg' }));
    setTimeout(() => {
      this.windowsSize$.next(null);
    }, 100);
  }

}
