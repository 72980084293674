<div class="form-check-inline row">
    <div class="d-flex flex-row col-md-12 justify-content-start">
        <label class="bold">Location :</label>
        <label class="radio-inline ml-3">
            <input type="radio" name="selector" [value]="CompanySiteSelectedType.AllCompanyAndSites" [formControl]="selectedLocationFormControl">
            <b class="ml-1">All</b>
        </label>
        <label class="radio-inline ml-3">
            <input type="radio" name="selector" [value]="CompanySiteSelectedType.Company" [formControl]="selectedLocationFormControl">
                <b class="ml-1">
                    Company
                </b>
        </label>
        <label class="radio-inline ml-3">
            <input type="radio" name="selector" [value]="CompanySiteSelectedType.Site" [formControl]="selectedLocationFormControl">
            <b class="ml-1">
                Site
            </b>
        </label>
    </div>
    <div class="mt-2 col-md-12">
        <div class="fw">
            <app-site-selector class="fw" *ngIf="selectedLocationFormControl.value == CompanySiteSelectedType.Site" [required]="true" [siteId]="currentSiteId" #sitelist
                (siteSelect)="selectedSite = $event; onSiteChange()">
            </app-site-selector>
        </div>
    </div>
</div>
