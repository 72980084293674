import { Pipe, PipeTransform } from '@angular/core';
import { WorkflowType } from 'src/app/enums/workflow/workflow-type';

@Pipe({
  name: 'WorkflowTypeFilter'
})
export class WorkflowTypePipe implements PipeTransform {

  transform(value: any): string {
    if (value == WorkflowType.SupplierDocument) return 'Supplier Document';
    return value?.toString();
  }

}
