<div class="card">
  <div class="card-body">
    <div class="card1 mb-2" style="border: 1px solid #e5e5e5; border-radius: 4px;">
      <header class="card-header">
        <h2 class="card-title">You Can Filter List By Change Below Options</h2>
      </header>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-lg-6 col-xl-4 mb-2 m-sm-0">
            <div class="widget-summary">
              <div class="widget-summary-col">
                <div class="summary">
                  <h4 class="title">Filter List By Review Status</h4>
                  <div class="info">
                    <select class="form-control" name="isReviewed" [(ngModel)]="serachModel.isReviewed">
                      <option selected [ngValue]="false">Not Reviewed</option>
                      <option [ngValue]="true">Reviewed</option>
                      <option [ngValue]="null">All</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 mb-2 m-sm-0">
            <div class="widget-summary">
              <div class="widget-summary-col">
                <div class="summary">
                  <h4 class="title">Filter List By Tag Status</h4>
                  <div class="info">
                    <select class="form-control" name="isFlagged" [(ngModel)]="serachModel.isFlagged">
                      <option selected [ngValue]="true">Tagged Users</option>
                      <option [ngValue]="false">Not Tagged </option>
                      <option [ngValue]="null">All</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 mb-2 m-sm-0">
            <div class="widget-summary">
              <div class="widget-summary-col">
                <div class="summary">
                  <h4 class="title">Filter List By Reject Status</h4>
                  <div class="info">
                    <select class="form-control" name="isRejected" [(ngModel)]="serachModel.isRejected">
                      <option selected [ngValue]="true">Rejected Users</option>
                      <option [ngValue]="false">Not Rejected </option>
                      <option [ngValue]="null">All</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 m-sm-0">
            <div class="widget-summary">
              <div class="widget-summary-col">
                <div class="summary form-group">
                  <h4 class="title">Filter By Site</h4>
                  <div class="info mt-2">
                    <app-site-selector #sl [required]="false" (siteIdSelect)="serachModel.siteId = $event">
                    </app-site-selector>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 mb-2 m-sm-0">
            <div class="widget-summary">
              <div class="widget-summary-col">
                <div class="summary">
                  <h4 class="title">Filter By User</h4>
                  <div class="info mt-2">
                    <obc-user-autocomplete [filterBy]="filterUserBy.All" (selectedUser)="selectUser($event)"
                      [currentUser]="selectedUser">
                    </obc-user-autocomplete>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 col-xl-4 mb-2 m-sm-0">
            <div class="widget-summary">
              <div class="widget-summary-col">
                <div class="summary">
                  <h4 class="title mb-2">Filter By Check-In Date</h4>
                  <div class="form-group">
                    <div class="input-group">
                      <obc-input-date-time-picker [ngModel]="selectedDate" (ngModelChange)="dateChanges($event)"
                        [mode]="DatetimePickerMode.Calender">
                      </obc-input-date-time-picker>
                      <!-- <input [owlDateTime]="dt1" class="form-control" placeholder="" [owlDateTimeTrigger]="dt1"
                        [ngModel]="selectedDate" (ngModelChange)="dateChanges($event)">
                      <owl-date-time #dt1 [pickerType]="'calendar'"></owl-date-time> -->
                      <span class="input-group-append">
                        <button class="btn btn-sm btn-primary" (click)="setNow()"> Now
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="card-footer text-right">
        <button class="btn btn-success mr-2" type="button" (click)="geSiteAttendanceTriggerResults()">Apply Filter
        </button>
        <button class="btn btn-default mr-2" type="button" (click)="reset()">Reset</button>
      </footer>
    </div>
    <div class="card">
      <header class="card-header">
        <div class="row">
          <h2 class="card-title col-lg-6">Manage Tagged Users
            <small class="text-primary">( {{flaggedUsersList?.length}} records found )</small>
          </h2>
          <div class="col-lg-6" *ngIf="userService.hasCompanyFeature(ProductPlanFeature.Export)">
            <button class="pull-right btn btn-info mr-2" type="button" (click)="exportCsv()">
              Export CSV
            </button>
          </div>
        </div>
      </header>
      <div class="card-body">
        <obc-loader [show]="inProgress"></obc-loader>
        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
          <div class="table-responsive">
            <table class="table table-bordered table-striped mb-0 dataTable no-footer">
              <thead>
                <tr role="row">
                  <th>User</th>
                  <th>Mobile</th>
                  <th>Site</th>
                  <th>Check-In Date</th>
                  <th>Is Rejected?</th>
                  <th>Rejected Message(s)</th>
                  <th>Is Tagged / Tag(s)</th>
                  <th>Is Reviewed?</th>
                  <th>Actions</th>
                </tr>
                <tr>
                  <td colspan="9">
                    <input type="text" class="form-control"
                      placeholder="Filter Result by Mobile, First Name, Last Name, Site, Reject Message"
                      [(ngModel)]="filterListTerm" />
                  </td>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="let user of (flaggedUsersList | filter:filterListTerm:['userFullName','mobile','siteName','rejectMessage'])">
                  <tr *ngIf="user">
                    <td>{{user.userFullName}}</td>
                    <td><span *ngIf="user.mobile"></span>{{user.mobile | mobile}}</td>
                    <td>{{user.siteName}}</td>
                    <td>{{user.checkInDate | datetime}}</td>
                    <td class="pull-center">
                      <span class="alert p-1"
                        [ngClass]="user.isRejected ? 'alert-danger' : 'alert-success'">{{user.isRejected ? 'Yes' :
                        'No'}}</span>
                    </td>
                    <td>
                      {{user.rejectMessage}}
                    </td>
                    <td>
                      <span class="alert p-1 mr-2"
                        [ngClass]="user.isFlagged ? 'alert-danger' : 'alert-success'">{{user.isFlagged ? 'Yes' : 'No'}}
                      </span>
                      <span class="company-tag" *ngFor="let tag of user.companyTags">
                        {{tag.title}}
                      </span>
                      <span class="company-tag text-danger" *ngIf="!user.companyTags || !user.companyTags.length">NO
                        TAG</span>
                    </td>
                    <ng-container *ngIf="user.inEdit === true">
                      <td>
                        <obc-switch [value]="user.isReviewed" (valueChanged)="user.isReviewed = $event">
                        </obc-switch>
                      </td>
                      <td>
                        <a (click)="changeReviewStatus(user)" class="btn p-1 on-editing save-row"><i
                            class="fas fa-save text-success"></i></a>
                        <a (click)="cancel(user)" class="btn px-3 py-1"><i class="fas fa-times text-warning"></i></a>
                      </td>
                    </ng-container>
                    <ng-container *ngIf="!user.inEdit || user.inEdit === false">
                      <td>
                        <span class="alert p-1"
                          [ngClass]="user.isReviewed ? 'alert-success' : 'alert-danger'">{{user.isReviewed ? 'Yes' :
                          'No'}}</span>
                      </td>
                      <td class="actions">
                        <a class="btn p-1 remove-row" title="Change Review State" (click)="initEdit(user)"><i
                            class="far fa-edit"></i></a>
                      </td>
                    </ng-container>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
