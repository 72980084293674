<div class="card">
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="mb-2" [formGroup]="formGroup">
      <div class="card">
        <div class="card-header">Add Asset Status</div>
        <div class="card-body">
          <div class="form-group mb-3">
            <label>Title</label>
            <div class="input-group">
              <input class="form-control form-control" formControlName="title" (keydown.enter)="formGroup.valid ? onAdd() : null"/>
            </div>
          </div>
          <div class="text-right">
            <button [disabled]="!formGroup.valid" class="btn btn-primary" (click)="onAdd()">Add</button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered fw">
        <thead>
          <tr>
            <th>Title</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of toolStatuses">
            <td>{{item.title}} <span *ngIf="item.triggersCount" tooltip="Number of Active Triggers"> [{{item.triggersCount}}] </span></td>
            <td class="text-right">
              <button class="btn btn-info m-1" (click)="onShowTriggers(item, triggersModal)">Triggers</button>
              <button class="btn btn-danger m-1" (click)="onRemove(item.id)">Remove</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #triggersModal>
  <div class="card">
    <div class="card-header">
      Add / Update Asset Status Triggers
      <button type="button" class="close float-right" (click)="onCloseStatusTriggers()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <obc-inventory-status-triggers [toolStatus]="currentStatus"></obc-inventory-status-triggers>
      <div>
        <button class="btn btn-primary m-1" (click)="onCloseStatusTriggers()" >Close</button>
      </div>
    </div>
  </div>
</ng-template>
