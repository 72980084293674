import { Pipe, PipeTransform } from '@angular/core';
import { DayOfWeek } from "../enums/DaysOfWeek";

@Pipe({
  name: 'daysOfWeek'
})
export class DaysOfWeekPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === DayOfWeek.Sunday) return "Sunday";
    if (value === DayOfWeek.Monday) return "Monday";
    if (value === DayOfWeek.Tuesday) return "Tuesday";
    if (value === DayOfWeek.Wednesday) return "Wednesday";
    if (value === DayOfWeek.Thursday) return "Thursday";
    if (value === DayOfWeek.Friday) return "Friday";
    if (value === DayOfWeek.Saturday) return "Saturday";
    return value?.toString();
  }

}
