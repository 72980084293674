import { Pipe, PipeTransform } from '@angular/core';
import { MultiSelectRenderMode } from '../models/questionParameters/multi-select-render-type';
import { QuestionType } from '../enums/question-type';
import { SingleSelectRenderMode } from '../models/questionParameters/single-select-render-type';

@Pipe({
  name: 'questinRenderMode'
})
export class QuestinRenderModePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (args[0] == QuestionType.SingleSelect) {//single
      if (value == SingleSelectRenderMode.DropDown) { return "DropDown"; }
      if (value === SingleSelectRenderMode.RadioButton) { return "Radio Button"; }
      if (value === SingleSelectRenderMode.Button) { return "Button"; }
    }
    else if (args[0] == QuestionType.MultiSelect)//multi
    {
      if (value == MultiSelectRenderMode.CheckBoxList) { return "CheckBox List"; }
      if (value == MultiSelectRenderMode.Button) { return "Button"; }
    }
    return "";
  }

}
