<div>
  <ng-select #selector style="padding-bottom: 0 !important;" class="form-selector" [typeaheadOptionsLimit]="7"
             [typeaheadMinLength]="0" [items]="forms$ | async"
             [placeholder]="formControl.value ? formControl.value : (placeholder ? placeholder : 'Not Required')"
             bindLabel="name" (scrollToEnd)="onScrollToEnd()"  bindValue="id" [typeahead]="term$ | async" [clearable]="!_required"
             [formControl]="formControl">
    <ng-template ng-option-tmp let-item="item">
      {{item.name}}
    </ng-template>
  </ng-select>
</div>
