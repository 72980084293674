import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { repeatPasswordValidator } from 'src/app/helpers/repeat-password-validator';
import { ChangePasswordViewmodel } from 'src/app/models/change-password-viewmodel';
import { LayoutService } from 'src/app/services/layout.service';
import { UserService } from 'src/app/services/user.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  passwordInProccess: boolean = false;
  passwordFormGroup: FormGroup;
  constructor(private layoutService: LayoutService, private userService: UserService,private modalService: ModalService ) { }

  ngOnInit() {
    this.layoutService.header = "Change Password";
    this.passwordFormGroup = new FormGroup({
      currentPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
      repeatPasswordGroup: new FormGroup({
        password: new FormControl('', [Validators.required, Validators.minLength(8)]),
        repeatPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
      }, [repeatPasswordValidator])
    })
  }

  onChangePassword() {
    var model = {
      password: this.passwordFormGroup.get("repeatPasswordGroup").get("password").value,
      currentPassword: this.passwordFormGroup.get("currentPassword").value,
    } as ChangePasswordViewmodel;
    this.passwordInProccess = true;
    this.userService.changePassword(model).subscribe(res => {
      if (res.success === true) {
        this.passwordInProccess = false;
        this.passwordFormGroup.reset();
      } else {
        this.modalService.error(res.message, "change password error").subscribe(res => {
        });
        this.passwordInProccess = false;
        this.passwordFormGroup.get("currentPassword").setValue('');
      }
    }, err => {
      this.modalService.error(err.error, "change password error").subscribe(res=> {
        //alert(err.error);
      });
      this.passwordInProccess = false;
      this.passwordFormGroup.get("currentPassword").setValue('');
    },()=>{
    });
  }
}
