import { EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounce, debounceTime } from 'rxjs/operators';
import { UserAutoCompleteFilterByFields } from 'src/app/enums/user-auto-complete-target-fields';
import { e168Validator2 } from 'src/app/helpers/mobile-format-validator';
import { UserBasicInfoViewModel } from 'src/app/models/user-basic-info-viewmodel';
import { UserAutocompleteComponent } from '../user-autocomplete/user-autocomplete.component';

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss']
})
export class UserSelectorComponent implements OnInit {
  userQualifierFiledList = ['id', 'firstName', 'lastName', 'mobile', 'isIncompleteRegister'];
  finalUser: UserBasicInfoViewModel = new UserBasicInfoViewModel();
  emittedUser: UserBasicInfoViewModel = new UserBasicInfoViewModel();
  FilterUserBy = UserAutoCompleteFilterByFields;
  @Input() successMessage = "";
  @Input() filterByCurrentUserRegion?: boolean = false;
  get isMobileValid(): boolean {
    var valid = new FormControl(this.finalUser?.mobile, [e168Validator2])?.valid;
    return valid;
  }

  @ViewChild('mobile') mobile: UserAutocompleteComponent;
  @ViewChild('firstname') firstname: UserAutocompleteComponent;
  @ViewChild('lastname') lastname: UserAutocompleteComponent;

  @Output() userChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.inputChangeObservable
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.userChange.emit(res);
      })
  }
  inputChangeObservable = new BehaviorSubject(null);
  onInputChange($event) {
    if ($event.field == 'mobile' && this.finalUser.mobile != $event.value)
     {
      this.finalUser.id = null;
      this.resetFirstnameLastname();
    }
    this.finalUser[$event.field] = $event.value;
    this.onUserChange();
  }

  onSelectUser($event) {
    // if user selected update all inputs
    if ($event.id != null) {
      this.finalUser = new UserBasicInfoViewModel($event);
      this.onUserChange();
    }
  }

  public reset() {
    this.mobile.reset();
    this.firstname.reset();
    this.lastname.reset();
  }
  public resetFirstnameLastname(){
    this.firstname.reset();
    this.lastname.reset();
  }
  onUserChange() {
    if (!this.isEqual(this.finalUser, this.emittedUser)) {
      this.inputChangeObservable.next(this.finalUser);
      this.emittedUser = new UserBasicInfoViewModel(this.finalUser);
    }
  }

  public isEqual(value1: UserBasicInfoViewModel, value2: UserBasicInfoViewModel): boolean {
    var list = [value2, value1];
    if (list.map(x => !x).length % 2 == 1) return false;
    var keys = Object.keys(value1);
    for (let index = 0; index < keys.length; index++) {
      const element = keys[index];
      if (value1[element] != value2[element])
        return false;
    }
    return true;
  }
}
