<div *ngFor="let variable of workflowVariables" class="workflow-variable-container">
    <div class="row mt-3">
        <div class="col-12">
            <div class="form-group">
                <i style="color: red;" class="fas fa-times-circle mr-2"
                    *ngIf="!this.workflowModel?.variableFormGroup?.get(variable.key).valid"></i>
                <i style="color: green;" class="fas fa-check-circle mr-2"
                    *ngIf="this.workflowModel?.variableFormGroup?.get(variable.key).valid"></i>
                <label class="mr-2">{{variable?.title}}</label>
                <span [ngSwitch]="variable?.type">
                    <div *ngSwitchCase="VariableDefinitionType.Date" style="max-width: 200px; display: inline-block;">
                        <obc-input-date-time-picker [placeholder]="'Pick From Date'"
                            [mode]="DatetimePickerMode.Calender"
                            [formControl]="this.workflowModel?.variableFormGroup?.get(variable.key)">
                        </obc-input-date-time-picker>
                    </div>
                    <div *ngSwitchCase="VariableDefinitionType.Unknown"
                        style="max-width: 200px; display: inline-block;">
                        <input [formControl]="this.workflowModel?.variableFormGroup?.get(variable.key)"
                            class="form-control" type="text">
                    </div>
                </span>
                <p class="hint small-text" *ngIf="checkVariableDateValueIsPast(variable)">Selected date is in the past.</p>
            </div>
        </div>
    </div>
</div>
