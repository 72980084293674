import { QuestionType } from '../enums/question-type';
import { FormControl, FormGroup } from '@angular/forms';
import { QuestionConditionViewModel } from './question-condition-models';
import { RequiredDocumentViewModel } from "./user-document-type";
import { CustomFormControl } from "./custom-formcontrol";
import { AskQuestionPeriodMode } from "../enums/ask-period-mode";
import { SiteQuestionAnswerViewModel } from "./site_question_answer_view_model";
import { EmergencyContactEmitResponse } from './emergency-contact-emit-response';
import { EmergencyContactViewModel } from './emergency-contact-view-model';
import { QuestionListItem } from './questionParameters/question-list-tem';
import { SelectableQuestionParameter } from './questionParameters/selectable-question-parameter';
import { UploadFileInfo } from "./requiredDocumentAnswer";

export class SiteQuestionViewModel {
  public id: Number;
  public questionText: string;
  public questionRichText: string;
  public questionType: QuestionType;
  public companyId: number;
  public questionId: number;
  questionParameters: string;
  public isActive: boolean;
  public isRequired: boolean;
  public conditions: QuestionConditionViewModel[];
  questionParametersAsString: any = {};
  companyName: string;
  public document: RequiredDocumentViewModel;
  public documentTypeId?: number;
  public documentFormControl?: CustomFormControl;
  uniqueKey: string;

  constructor(model?: any) {
    this.loadAllProperties(model);
  }

  loadAllProperties(model) {
    if (!model) return;
    for (const key of Object.keys(model)) {
      if (model.hasOwnProperty(key)) {
        this[key] = model[key];
      }
    }
    this.questionParametersAsString = this.questionParameters ? JSON.parse(this.questionParameters) : {};
  }

  _item = null;

  get items(): QuestionListItem[] {
    try {
      if (this._item == null) {
        this._item = this.questionParametersAsString.itemList;
      }
      return this._item;
    } catch (error) {
      return null;
    }
  }

  get renderMode(): number {
    try {
      return this.questionParametersAsString["renderMode"];
    } catch (error) {
      return null;
    }
  }

  get questionTypeValue(): number {
    try {
      return this.questionParametersAsString["questionType"];
    } catch (error) {
      return null;
    }
  }

  get minimumSelectCount(): number {
    try {
      return this.questionParametersAsString["minimumSelectCount"];
    } catch (error) {
      return null;
    }
  }

  get regex(): string {
    try {
      return this.questionParametersAsString["regex"];
    } catch (error) {
      return null;
    }
  }
}

export class SiteQuestionRendererViewModel {
  showFlagLastState: boolean = true;

  constructor() {

  }

  lastStateInitialFileAnswers: UploadFileInfo[];
  initialAnswers: SiteQuestionAnswerViewModel[];
  formGroup?: FormGroup;
  question: SiteQuestionViewModel;

  resetAnswers() {
    if (this.controls == null || this.controls.length == 0) return;
    this.controls.forEach(x => x.reset());
    return null;
  };

  set answers(answers) {
    if (this.controls && this.controls.length > 0) {
      if (this.question.questionType == QuestionType.MultiSelect) {
        answers.map((i, index) => {
          this.controls[index].setValue(i)
        });
      } else {
        this.controls[0].setValue(answers[0]);
      }
    }
  }

  get answers(): any {
    if (this.controls == null || this.controls.length == 0) return null;
    if (this.question.questionType == QuestionType.MultiSelect) {
      var ans = this.selectedControls.map(i => i.item.value);
      return ans;
    } else if (this.question.questionType == QuestionType.Boolean || this.question.questionType == QuestionType.SingleSelect || this.question.questionType == QuestionType.SignaturePad) {
      return this.controls[0].value;
    } else if (this.question.questionType == QuestionType.Date) {
      if (this.controls[0].value == null || this.controls[0].value.length == 0)
        return null;
      else
        return this.controls[0].value;
    } else if (this.question.questionType == QuestionType.Text || this.question.questionType == QuestionType.File) {
      if (this.controls[0].value == null || this.controls[0].value.length == 0)
        return null;
      else
        return this.controls[0].value;
    } else if (this.question.questionType == QuestionType.EmergencyContact) {
      let qValue = this.controls[0].value as EmergencyContactEmitResponse;
      if (!qValue?.isValid)
        return null;
      else
        return new EmergencyContactViewModel(
          qValue?.value.name,
          qValue?.value.mobile,
          qValue?.value.relationship,
        );
    }
    return null;
  };

  get fileType(): string {
    if (this.question.questionType == QuestionType.File) {
      if (this.controls[1] === null || this.controls[1].value == null || this.controls[1].value.length == 0)
        return null;
      else
        return this.controls[1].value;
    }

    return null;
  }

  get isNotModified(): boolean {
    if (this.question.questionType == QuestionType.File) {
      if (this.controls[2] === null || this.controls[2].value == null || this.controls[2].value.length == 0)
        return false;
      else
        return this.controls[2].value;
    }

    return false;
  }

  getSelectedInvalidItem(): QuestionListItem[] {
    if ((this.question.questionType == QuestionType.MultiSelect || this.question.questionType == QuestionType.SingleSelect) && this.answers) {
      if (this.question.questionType == QuestionType.MultiSelect)
        return (this.question.questionParametersAsString as SelectableQuestionParameter)?.itemList.filter(item => item.isInvalid && this.answers?.some(c => item.value == c)) ?? [];
      else
        return (this.question.questionParametersAsString as SelectableQuestionParameter)?.itemList.filter(item => item.isInvalid && this.answers == item.value) ?? [];
    }
    return [];
  }


  get valid(): boolean {
    if (this.question.questionType == QuestionType.RichText) {
      return true
    }

    if (this.getSelectedInvalidItem()?.length)
      return false;

    if (
      this.question.questionType == QuestionType.MultiSelect ||
      this.question.questionType == QuestionType.Boolean ||
      this.question.questionType == QuestionType.SingleSelect ||
      this.question.questionType == QuestionType.EmergencyContact ||
      this.question.questionType == QuestionType.File ||
      this.question.questionType == QuestionType.Text
    ) {
      if (this.controls == null || this.controls.length == 0) return false;
    }

    if (this.question.questionType == QuestionType.MultiSelect) {
      if (this.selectedControls.length >= this.question.minimumSelectCount)
        return true;
      else
        return false
    } else if (this.question.questionType == QuestionType.Boolean
      || this.question.questionType == QuestionType.SingleSelect
      || this.question.questionType == QuestionType.File
      || this.question.questionType == QuestionType.SignaturePad) {
      if (this.question.isRequired && this.answers == null)
        return false;
      else
        return true;
    } else if (this.question.questionType == QuestionType.Text) {
      if (this.question.regex && this.answers) {
        let re = new RegExp(this.question.regex);
        return re.test(this.answers);
      }
      if (this.question.isRequired && (this.answers == null || this.answers.trim() == ''))
        return false;

      return true;
    } else if (this.question.questionType == QuestionType.EmergencyContact) {
      return (this.controls[0].value as EmergencyContactEmitResponse)?.isValid;
    } else if (this.question.questionType == QuestionType.Date) {
      if (this.question.isRequired && this.answers == null)
        return false;
    }
    return true;
  };

  get controls(): SiteQuestionItemFormControl[] {
    if (this.formGroup?.controls == null) return null;
    return Object.values(this.formGroup.controls) as SiteQuestionItemFormControl[];
  }

  get selectedControls(): any[] {
    return this.controls?.filter(e => e.value);
  }
}

export interface SiteQuestionChangeEvent {
  tagged: any;
  anwsers: string;
}

export class SiteQuestionItemFormControl extends FormControl {
  constructor(state, public item) {
    super(state);
  }
}


export class SiteOneTimeQuestionsViewModel extends SiteQuestionViewModel {
  companyId: number;
  askPeriodMode: AskQuestionPeriodMode;
  document: RequiredDocumentViewModel;
}
