export enum FormType {
  Generic = 0,
  Permit = 1,
  SupplierDocumentReview = 2,
  SupplierForm = 3,
  AssetRegistration = 4,
  AssetOperator = 5,
  AssetOperation = 6,
  SiteBriefing = 7,
}
