import { Pipe, PipeTransform } from '@angular/core';
import { AnnouncementMode } from '../enums/announcement-mode.enum';

@Pipe({
  name: 'announcementMode'
})
export class AnnouncementModePipe implements PipeTransform {

  transform(value: unknown): any {
    if (value === AnnouncementMode.Silent) { return "Silent"; }
    if (value === AnnouncementMode.NotifyAlways) { return "Notify Always"; }
    if (value === AnnouncementMode.NotifyOnce) { return "Notify Once"; }
    
    return value;
  }

}
