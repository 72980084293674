import { Component, Input, OnInit } from '@angular/core';
import { QuestionType } from 'src/app/enums/question-type';
import { EmergencyContactViewModel } from 'src/app/models/emergency-contact-view-model';
import { DateQuestionParameter } from 'src/app/models/questionParameters/date-question-parameter';
import { QuestionParameter } from 'src/app/models/questionParameters/question-parameter';
import { TextRenderMode } from 'src/app/models/questionParameters/text-render-mode-type';
import { TextQuestionParameter } from 'src/app/models/questionParameters/textQuestionParameter';
import { SiteQuestionAnswerViewModel } from 'src/app/models/site_question_answer_view_model';

@Component({
  selector: 'obc-question-list-answer-renderer',
  templateUrl: './question-list-answer-renderer.component.html',
  styleUrls: ['./question-list-answer-renderer.component.scss']
})
export class QuestionListAnswerRendererComponent implements OnInit {
  QuestionType = QuestionType;

  @Input() answers: SiteQuestionAnswerViewModel[];

  constructor() { }

  ngOnInit(): void {}

  isRichTextQuestion(answer: SiteQuestionAnswerViewModel): boolean {
    var parameter = QuestionParameter.getParameter<TextQuestionParameter>(
      answer.questionParameters
    );
    return parameter.textRenderMode == TextRenderMode.RichText && answer.questionType == QuestionType.Text;
  }

  isQuestionTimeIncluded(questionParameters: string): boolean {
    var parameter = QuestionParameter.getParameter<DateQuestionParameter>(questionParameters);
    return parameter.includeTime;
  }
  emergencyContactAnswerIsNotEmpty(answer) {
    try {
      let res = JSON.parse(answer) as EmergencyContactViewModel;
      return !EmergencyContactViewModel.isEmpty(res);
    } catch(_) {
      return false;
    }
  }

}
