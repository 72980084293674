<div class="card">
  <div class="card-header">
    <span>
      {{modalTitle}}
    </span>
    <div class="card-actions" *ngIf="!loadFromWorkflow">
      <a (click)="hide.emit()" class="card-action card-action-dismiss" data-card-dismiss=""></a>
    </div>
  </div>
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <ng-container
      *ngIf="!loadFromWorkflow && formType==FormTypeEnum.SupplierDocumentReview && supplierDocumentReviewMode==SupplierDocumentReviewModeEnum.Optional">
      <div class="form-group ">
        <span class="radio-custom radio-primary">
          <input [value]="supplierDocumentReviewFromSelectionMode.withForm" [formControl]="formSelectionMode"
            name="formSelectionMode" id="supplierDocumentReviewFromSelectionModeWithForm" type="radio" />
          <label for="supplierDocumentReviewFromSelectionModeWithForm">{{
            supplierDocumentReviewFromSelectionMode.withForm}}</label>
        </span>
        <span class="radio-custom radio-primary ml-2">
          <input [value]="supplierDocumentReviewFromSelectionMode.withoutForm" [formControl]="formSelectionMode"
            name="formSelectionMode" id="supplierDocumentReviewFromSelectionModeWithoutForm" type="radio" />
          <label for="supplierDocumentReviewFromSelectionModeWithoutForm">{{
            supplierDocumentReviewFromSelectionMode.withoutForm}}</label>
        </span>
      </div>
    </ng-container>

    <div class="row mt-2" *ngIf="viewFormSelector()">
      <ng-container>
        <div class="col-6 col-xl-6">
          <label for="" *ngIf="formType != FormTypeEnum.SupplierDocumentReview">Select a {{ formType ==
            FormTypeEnum.Permit ? 'Permit Form' : 'Form' }}</label>
          <obc-form-selector [workflowForms]="workflowModel?.currentActivity?.formsToFill" [formType]="formType"
            [selectFirstItemOfOneItemArray]="true" [hashLink]="hashLink" [siteSupplierId]="siteSupplierId"
            [formId]="form?.id"
            [applyForList]="formType == FormTypeEnum.Permit ? [FormApplyFor.Supplier, FormApplyFor.AnyPerson] : []"
            (selectedForm)="onSelectForm($event)" [documentKindId]="selectedSiteSupplierDocument?.documentKindId"
            [required]=true>
          </obc-form-selector>
        </div>
      </ng-container>
    </div>
    <div class="row mt-2 {{ !showTabPage ? 'full-screen-mode' : '' }}">
      <div class="col-12">
        <ng-container *ngIf="showFormAndFile()">
          <tabset>
            <tab heading="Form" class="scroll-form {{ !showTabPage ? 'col-4' : '' }}">
              <ng-container *ngTemplateOutlet="supplierDocReview">
              </ng-container>
            </tab>
            <tab heading="Documents" (selectTab)="loadPDF()" class="scroll-form {{ !showTabPage ? 'col-8 pl-2' : '' }}">
              <ng-container *ngTemplateOutlet="supplierDocReviewFiles">
              </ng-container>
            </tab>
          </tabset>
        </ng-container>
        <ng-container *ngIf="onlyShowFiles()">
          <ng-container *ngTemplateOutlet="supplierDocReviewFiles">
          </ng-container>
        </ng-container>

        <ng-container *ngIf="onlyShowForm()">
          <ng-container *ngTemplateOutlet="supplierDocReview">
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-container
    *ngIf="(form || formSelectionMode.value == supplierDocumentReviewFromSelectionMode?.withoutForm )&& !loadFromWorkflow">
    <div class="card-footer" *ngIf="form?.type == FormTypeEnum.Permit">
      <div class="text-left mt-1">
        <button (click)="onSubmitPermitForm()" [disabled]="!isReviewFormValid" class="ml-2 btn btn-sm btn-success">
          Submit
        </button>
      </div>
    </div>
    <div class="card-footer"
      *ngIf="(form?.type != FormTypeEnum.Permit || formSelectionMode.value == supplierDocumentReviewFromSelectionMode?.withoutForm) && !loadFromWorkflow">
      <div class="text-right mt-1">
        <button (click)="onApproveDeclineSiteSupplierVersionAndFillForm(true)" [disabled]="!isReviewFormValid"
          class="ml-2 btn btn-sm btn-success">
          Approve
        </button>
        <button (click)="onApproveDeclineSiteSupplierVersionAndFillForm(false)" [disabled]="!isReviewFormValid"
          class="ml-1 btn btn-sm btn-danger">
          Reject
        </button>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #supplierDocReview>
  <obc-form-data-renderer #formDataRenderer [formType]="form.type" [hashLink]="hashLink"
    [siteSupplierId]="siteSupplierId" [supplierId]="supplierId" [siteId]="siteId" [formId]="form.id"
    [formName]="form.name" (validate)="validateForm($event)" [(workflowModel)]="workflowModel">
  </obc-form-data-renderer>
</ng-template>

<ng-template #supplierDocReviewFiles>
  <obc-file-viewer *ngIf="selectedSiteSupplierDocument?.files?.length && pdfIsLoaded"
    [prefix]="selectedSiteSupplierDocument.supplierDocumentTypeName+'-'+selectedSiteSupplierDocument.displayVersion"
    [files]="selectedSiteSupplierDocument.files" [btnTitle]="'View Files'"
    [btnClass]="'btn-sm btn btn-primary ml-2 pull-right'" [showByModal]="false">
  </obc-file-viewer>
</ng-template>