import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'obc-anonymous-check',
  templateUrl: './anonymous-check.component.html',
  styleUrls: ['./anonymous-check.component.css']
})
export class AnonymousCheckComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
