import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SiteAttendanceReportViewModel } from 'src/app/models/site-report-models';
import { UserAutoCompleteFilterByFields } from 'src/app/enums/user-auto-complete-target-fields';
import { LayoutService } from 'src/app/services/layout.service';
import { ActivatedRoute, Params } from '@angular/router';
import { SiteActivityReportType } from 'src/app/enums/site-activity-report-type.enum';
import { UserService } from 'src/app/services/user.service';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { MobilePipe } from 'src/app/pipes/mobile.pipe';
import { DatetimePipe } from 'src/app/pipes/datetime.pipe';
import { DatePipe } from 'src/app/pipes/date.pipe';
import { Table } from 'src/app/enums/table.enum';
import { GridColumn, GridConfig, GridTemplate } from 'src/app/models/grid/grid.config';
import { SiteAttendanceApprovalStatus } from 'src/app/enums/site-attendance-approval-status.enum';
import { EmergencyContactViewModel } from 'src/app/models/emergency-contact-view-model';
import { FileType } from 'src/app/enums/file-type.enum';
import { ProductPlanFeature } from 'src/app/enums/product-plan-feature.enum';
import { QuestionType } from 'src/app/enums/question-type';
import { ScheduleReportMode } from 'src/app/enums/schedule-report-mode';
import { ScheduleReportType } from 'src/app/enums/schedule-report-type';
import { AnnouncementVisitReportViewModel } from 'src/app/models/announcement-visit-report-viewmodel';
import { AsyncResult } from 'src/app/models/helper-models';
import { QuestionAnswerInputModel } from 'src/app/models/question-answer-inputmodel';
import { DateQuestionParameter } from 'src/app/models/questionParameters/date-question-parameter';
import { QuestionParameter } from 'src/app/models/questionParameters/question-parameter';
import { ModalService } from 'src/app/services/modal.service';
import { Constants } from "../../../../enums/constants";

@Component({
  selector: 'obc-site-activity-report',
  templateUrl: './site-activity-report.component.html',
  providers: [MobilePipe, DatetimePipe, DatePipe],
  styleUrls: ['./site-activity-report.component.scss']
})
export class SiteActivityReportComponent implements OnInit {
  _selectedUserId = null;
  @Input() set selectedUserId(id: number) {
    this._selectedUserId = id;
    //this.prepareGridConfig();
  }
  get selectedUserId() {
    return this._selectedUserId;
  }
  @Input() siteIdList: number[] = [];

  @Input() isReportMode: boolean = false;

  @ViewChild('grid') grid: any;

  inProgress: boolean = false;
  siteActivityReportType = SiteActivityReportType;
  SiteAttendanceApprovalStatus = SiteAttendanceApprovalStatus;
  Table = Table;
  mode: "answers" | "tracing" | 'supplier' = "answers";
  selectedSiteActivityReport: SiteAttendanceReportViewModel = null;
  searchQueryForm: FormGroup = new FormGroup({
    user: new FormControl(null),
  });
  filterUserBy = UserAutoCompleteFilterByFields;
  questionAnswerInputModel: QuestionAnswerInputModel = new QuestionAnswerInputModel();
  selectedUser: any;
  gridColumns: GridColumn<SiteAttendanceReportViewModel>[];
  gridConfig: GridConfig = {} as GridConfig;

  constructor(private layoutService: LayoutService,
    private route: ActivatedRoute,
    public userService: UserService,
    private modalService: ModalService,
    private datetimePipe: DatetimePipe,
  ) {
  }

  userIdValidator = [];

  ngOnInit() {
    this.route.params.subscribe((res: Params) => {
      if (!this.isReportMode) {
        try {
          if (res['mode'] == "answers")
            this.mode = "answers";
          else this.mode = 'tracing';
        } catch (error) {
          this.mode = "answers";
        }
      }
      else {
        this.mode = 'answers';
      }

      if (!this.isReportMode) {
        if (this.mode == 'answers')
          this.layoutService.header = "Activity Report";
        else
          this.layoutService.header = "Contact Tracing";
      }
      this.userIdValidator = this.mode == "tracing" ? [Validators.required] : [];
      this.searchQueryForm?.controls['user']?.setValidators(this.userIdValidator);
    });

    this.prepareGridConfig();
  }

  prepareGridConfig() {

    let csvReportUrl;
    if (!this.isReportMode) {
      if (this.mode == 'tracing')
        csvReportUrl = '/api/SiteAttendance/export-contact-tracing-report-csv-as-job';
      else
        csvReportUrl = '/api/SiteAttendance/export-activity-report-csv-as-job';
    }

    this.gridConfig = new GridConfig({
      apiUrl: this.isReportMode ? '/api/workerprofile/site-activity-report' : '/api/SiteAttendance/site-activity-report',
      forceHideAllControlButtons: this.isReportMode,
      displayQuestionsFilter: this.reportType == SiteActivityReportType.SiteActivity,
      displayAnnouncementsFilter: this.reportType == SiteActivityReportType.SiteActivity,
      cacheKeyPrefix: this.isReportMode ? Constants.WORKER_PROFILE_REPORT_PREFIX : '',
      scheduleReport: {
        enabled: !this.isReportMode,
        mode: this.reportType == SiteActivityReportType.SiteActivity &&
          this.userService.hasCompanyFeature(
            ProductPlanFeature.SchaduleReport
          ) ? ScheduleReportMode.ScheduleReport : ScheduleReportMode.HashCode,
        type: ScheduleReportType.ActivityReport,
        reportColumns: [
          'siteIds',
          {
            labelCaption: 'User Mobile',
            key: 'userIds',
          }
        ],
      },
      csvReportUrl: csvReportUrl,
      displayEnableMagicLink: !this.isReportMode && this.reportType == SiteActivityReportType.SiteActivity && this.userService.isGodUser(),
      tableType: this.tableSettingKey,
      displayGeneralSearch: true,
      refreshButtonValidity:
        this.reportType != SiteActivityReportType.ContactTracing ||
        (this.reportType == SiteActivityReportType.ContactTracing
          && this.searchQueryForm?.controls.user?.value?.id != undefined),
      generalSearchPlaceholder: "Filter Result by Mobile, First Name, Last Name",
      initialFilters: this.reportType == SiteActivityReportType.ContactTracing ? [
        this.searchQueryForm?.controls.user?.value?.id != undefined ? {
          key: 'userIds',
          value: [this.searchQueryForm?.controls.user?.value],
        } : null,
        {
          key: 'type',
          value: 1, // ContactTracing
          displayInFilterRenderer: false,
        }
      ] :
        [
          this.selectedUserId != null ?
            {
              key: 'userIds',
              value: [this.selectedUserId],
              displayInFilterRenderer: false,
            } : null,
          (this.siteIdList?.length ?? 0) ?
            {
              key: 'siteIds',
              value: this.siteIdList,
              displayInFilterRenderer: false,
            } : null,
          {
            key: 'type',
            value: 0,
            displayInFilterRenderer: false,
          }
        ].filter(f => f),
      apiResultCallback: (res) => {
        return {
          data: res.data,
          totalCount: res.totalCount
        };
      }
    });

    this.gridColumns = [
      !this.isReportMode ? {
        name: "Full Name",
        key: "firstName",
        type: FilterType.Text,
        gridTemplate: new GridTemplate().FullNameAccompanierCount({
          firstName: 'firstName',
          lastName: 'lastName',
          accompaniersText: 'accompaniers',
          accompanierCount: 'accompanierCount',
        })?.CsvFields(['firstName', 'lastName', 'accompaniers', 'accompanierCount']),
        propertyNameInQuery: 'names',
      } : null,
      {
        name: "Supplier",
        key: "supplier",
        type: FilterType.Supplier,
        propertyNameInQuery: 'suppliers',
        visible: this.reportType == SiteActivityReportType.SiteActivity,
      },
      !this.isReportMode ? {
        name: "Mobile", key: "mobile", type: FilterType.Mobile,
        propertyNameInQuery: 'userIds',
      } : null,
      {
        name: "Site", key: "site",
        type: FilterType.Site,
        gridTemplate: new GridTemplate().SiteNameReference({
          siteName: 'site',
          siteReference: 'siteReference',
        }).CsvFields(['site', 'siteReference']),
        propertyNameInQuery: 'siteIds',
      },
      {
        name: "Check In Time",
        key: "checkInDate",
        propertyNameInQuery: 'checkInRanges',
        type: FilterType.DateTimeRange,
        gridTemplate: new GridTemplate().ComponentTemplateName('checkInDateTime').CsvFields([
          'checkInDate',
          'isOfflineCheckIn',
          'offlineCheckInRegisterDate',
          'isDisputedCheckInDate',
          'siteAttendanceApprovalStatus',
          'checkInForm',
          'checkInDistanceTitle'
        ]),
      },
      {
        name: "Check Out Time",
        key: "checkOutDate",
        type: FilterType.DateTimeRange,
        gridTemplate: new GridTemplate().ComponentTemplateName('checkOutDateTime').CsvFields([
          'checkOutDate',
          'isOfflineCheckOut',
          'offlineCheckOutRegisterDate',
          'isDisputedCheckOutDate',
          'checkOutForm',
          'checkOutDistanceTitle'
        ]),
        propertyNameInQuery: 'checkOutRanges',
        hideEmptyColumnsEmptinessConditionCallback: (res) => {
          return res?.every(item => {
            return item.checkOutDate == undefined;
          });
        }
      },
      !this.isReportMode ? {
        name: "Employee No",
        key: "employeeNo",
        type: FilterType.Text,
        propertyNameInQuery: 'employees',
        visible: false,
      } : null,
      !this.isReportMode ? {
        name: "Email",
        key: "email",
        type: FilterType.Text,
        propertyNameInQuery: 'emails',
        visible: false,
      } : null,
      {
        name: "Visitor Type",
        key: "visitorType",
        type: FilterType.VisitorType,
        propertyNameInQuery: 'visitorTypeIds',
        visible: this.reportType == SiteActivityReportType.SiteActivity,
      },
      !this.isReportMode ? {
        name: "Gender", key: "gender", type: FilterType.Gender,
        propertyNameInQuery: 'genders',
        visible: false,
      } : null,
      !this.isReportMode ? {
        name: "Date of Birth", key: "birthDay", type: FilterType.DateRange,
        propertyNameInQuery: 'birthdayRanges',
        visible: false,
      } : null,
      !this.isReportMode ? {
        name: "Industry",
        key: "industryTypeName",
        type: FilterType.IndustryType,
        visible: false,
        propertyNameInQuery: 'industryTypes'
      } : null,
      !this.isReportMode ? {
        name: "Business",
        key: "businessName",
        type: FilterType.Text,
        gridTemplate: new GridTemplate().ComponentTemplateName('business').CsvFields([
          'businessName',
          'businessAbn',
          'businessAcn',
        ]),
        propertyNameInQuery: 'businusses',
        visible: false,
        hideEmptyColumnsEmptinessConditionCallback: (res) => {
          return res?.every(item => {
            return (item.businessName == undefined || item.businessName?.toString()?.trim() == '')
              && (item.businessAcn == undefined || item.businessAcn?.toString()?.trim() == '')
              && (item.businessAbn == undefined || item.businessAbn?.toString()?.trim() == '');
          });
        }
      } : null,
      !this.isReportMode ? {
        name: "Induction",
        key: "siteInductions",
        type: FilterType.InductionId,
        enableSort: false,
        visible: this.reportType == SiteActivityReportType.SiteActivity,
        propertyNameInQuery: 'InductionIds',
        gridTemplate: new GridTemplate().Inductions({
          inductions: 'siteInductions',
        }),
        hideEmptyColumnsEmptinessConditionCallback: (res) => {
          return res?.every(item => {
            return item.siteInductions == undefined || item.siteInductions?.length == 0;
          });
        }
      } : null,
      {
        name: "Duration Onsite",
        key: 'workingDuration',
        type: FilterType.Duration,
        enableSort: false,
        propertyNameInQuery: 'durations',
        visible: this.reportType != SiteActivityReportType.SiteActivity || this.isReportMode,
      },
    ].filter((q) => !!q);

    if (this.reportType == SiteActivityReportType.SiteActivity && !this.isReportMode) {
      this.gridColumns = [
        ...this.gridColumns,
        ...[
          {
            name: "Q&A",
            key: "siteQuestionAnswers",
            type: FilterType.QuestionAndAnswer,
            gridTemplate: new GridTemplate().ComponentTemplateName('QA'),
            enableSort: false,
            enableFilter: false,
            hasIncludeFilter: true,
            visible: false,
            propertyNameInQuery: 'includeQuestionAnswers'
          },
          {
            name: "Supplier Documents",
            key: 'supplierDocuments',
            type: FilterType.SupplierDocuments,
            enableSort: false,
            enableFilter: false,
            propertyNameInQuery: 'includeSupplierDocuments',
            hasIncludeFilter: true,
            visible: false,
            gridTemplate: new GridTemplate().SupplierDocuments({
              supplierDocuments: 'supplierDocuments',
            }),
            hideEmptyColumnsEmptinessConditionCallback: (res) => {
              return res?.every(item => {
                return item.supplierDocuments == undefined || item.supplierDocuments?.length == 0;
              });
            }
          },
          {
            name: "Announcements",
            propertyNameInQuery: 'includeAnnouncements',
            key: 'announcementVisits',
            enableSort: false,
            enableFilter: false,
            hasIncludeFilter: true,
            gridTemplate: new GridTemplate().ComponentTemplateName('announcementsTemplate'),
            hideEmptyColumnsEmptinessConditionCallback: (res) => {
              return res?.every(item => {
                return item.announcementVisits == undefined || item.announcementVisits?.length == 0;
              });
            }
          }
        ]
      ]
    }

    if (!this.isReportMode) {
      this.gridColumns.push({
        name: "Permit",
        key: "permitForms",
        gridTemplate: new GridTemplate().ComponentTemplateName('permits'),
        type: FilterType.PermitForm,
        enableSort: false,
        enableFilter: false,
        visible: false,
        hideEmptyColumnsEmptinessConditionCallback: (res) => {
          return res?.every(item => {
            return item.permitForms == undefined || item.permitForms?.length == 0;
          });
        }
      });
    }

    this.gridColumns.push({
      name: "Actions",
      key: 'viewDetails',
      type: FilterType.Template,
      enableSort: false,
      enableFilter: false,
      gridTemplate: new GridTemplate().ComponentTemplateName('viewDetails'),
      visible: true,
    });
  }

  get tableSettingKey(): Table {
    return this.reportType == SiteActivityReportType.SiteActivity ? Table.ActivityReport :
      Table.ContactTracingReport;
  }

  get reportType(): SiteActivityReportType {
    return this.mode == 'answers' ?
      SiteActivityReportType.SiteActivity
      : SiteActivityReportType.ContactTracing;
  }


  pad(num) {
    if (num < 10) {
      return "0" + num;
    } else {
      return "" + num;
    }
  }

  openModal(template, siteAttendanceId: number, isCheckInAnswer: boolean, hasCheckOutDate: boolean = false) {
    this.questionAnswerInputModel = {
      isCheckInAnswer: isCheckInAnswer,
      siteAttendanceId: siteAttendanceId,
      hasCheckOutDate: hasCheckOutDate
    };
    this.modalService.show(template, "modal-lg");
  }

  openContactTracingModal(template, siteAttendance) {
    this.selectedSiteActivityReport = siteAttendance;
    this.modalService.show(template, "modal-lg");
  }

  closeModal(result: AsyncResult) {
    this.modalService.hide();
    if (result && result.needParentRefresh == true)
      this.grid.loadData(0);
  }

  getAnnouncementIconCSSClass(visit: AnnouncementVisitReportViewModel) {
    switch (visit.type) {
      case FileType.Audio:
        return 'fas fa-file-audio text-primary';
      case FileType.ExternalLink:
        return 'fas fa-link text-primary';
      case FileType.Image:
        return 'fas fa-image text-success';
      case FileType.Pdf:
        return 'fas fa-file-pdf text-danger';
      case FileType.Text:
        return 'fas fa-file-alt text-info';
      case FileType.Video:
        return 'fas fa-video text-warning';
      case FileType.Youtube:
        return 'fas fa-link text-primary';
    }
  }

  onSelectedUser(user) {
    this.searchQueryForm.controls.user.setValue(user);
    this.selectedUser = user;
    this.prepareGridConfig();
  }

  getApprovalStatusFriendlyLabel(item: SiteAttendanceReportViewModel) {
    if (item.siteAttendanceApprovalStatus == SiteAttendanceApprovalStatus.Pending)
      return 'Pending Approval';
    else if (item.siteAttendanceApprovalStatus == SiteAttendanceApprovalStatus.NotRequiredApproval)
      return 'Approval Not Required';
    else if (item.siteAttendanceApprovalStatus == SiteAttendanceApprovalStatus.Rejected)
      return `Rejected By ${item.approvalStatusChangeBy} at ${this.datetimePipe.transform(item.approvalStatusChangeDateTimeOffset)}`;
    else if (item.siteAttendanceApprovalStatus == SiteAttendanceApprovalStatus.Approved)
      return `Approved By ${item.approvalStatusChangeBy} at ${this.datetimePipe.transform(item.approvalStatusChangeDateTimeOffset)}`;
  }


  _questionType = QuestionType;

  getQAs(reportItem: SiteAttendanceReportViewModel, isCheckin) {
    if (reportItem.siteQuestionAnswers == null) return [];
    return reportItem.siteQuestionAnswers.filter(i => i.isCheckInAnswer == isCheckin);
  }

  isQuestionTimeIncluded(questionParameters: string): boolean {
    let parameter = QuestionParameter.getParameter<DateQuestionParameter>(questionParameters);
    return parameter.includeTime;
  }

  isShowTemplateColumns(mainColumn, detailColumn) {
    return this.grid.isShowTemplateColumns(mainColumn, detailColumn);
  }

  emergencyContactAnswerIsNotEmpty(answer) {
    try {
      let res = JSON.parse(answer) as EmergencyContactViewModel;
      return !EmergencyContactViewModel.isEmpty(res);
    } catch (_) {
      return false;
    }
  }
}
