
export function createBgColor(id?: any): number[] {
    return random_bg_color(id);
}

export function getFontColor(rgb: number[]): string {
    var FontColor = '#eee';
    if (colorIsLight(rgb)) {
        FontColor = '#555';
    }
    return FontColor
}

function random_bg_color(id?: any): number[] {
    var r = Math.floor((Math.random()) * 255);
    var g = Math.floor((Math.random()) * 255);
    var b = Math.floor((Math.random()) * 255);

    return [r, g, b];
}

export function rgbStringFormat(x: number[]) {
    return 'rgb(' + x[0] + ',' + x[1] + ',' + x[2] + ')';
};

function colorIsLight(rgb: number[]) {
    var a = 1 - (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;
    return (a < 0.5);
}

