import { Pipe, PipeTransform } from '@angular/core';
import { FormDataStatus } from "../enums/form-data-status";

@Pipe({
  name: 'filledFormStatus'
})
export class FilledFormStatusPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === FormDataStatus.NotRequired) return "Not Required";
    if (value === FormDataStatus.Approved) return "Approved";
    if (value === FormDataStatus.PendingApproval) return "Pending Approval";
    if (value === FormDataStatus.Rejected) return "Rejected";
    return value?.toString();
  }

}
