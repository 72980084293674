<section class="card">
  <header class="card-header">
    <h5>User Document Types</h5>
    <button class="btn btn-success card-header-button" (click)="openModal(addEditUserDocumentType)">
      <i class="fas fa-plus mr-1"></i>Add New Document</button>
  </header>
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>

    <obc-document-type-viewer [documents]="types"
                              [draggable]="true"
                              [cacheUniqueId]="'god-user-document-types-tree'"
                              (orderedList)="onOrderedList($event)"
    >
    </obc-document-type-viewer>

    <div *ngIf='errorMessage' class='alert alert-danger mb-2'>
      {{errorMessage}}
    </div>
  </div>
</section>
<ng-template #addEditUserDocumentType>
  <div class="card" *ngIf="formGroup">
    <obc-loader [show]="inAddEditProgress"></obc-loader>
    <div class="card-header">
      <span *ngIf="formGroup.get('id').value > 0">Edit User Document Type</span>
      <span *ngIf="formGroup.get('id').value == 0">Add New User Document Type</span>
      <span></span>
    </div>
    <div class="card-body">
      <div class="py-3">
        <div class="d-flex align-items-center">
          <b class="mr-2">Parent: </b>
          <obc-document-type-nested-selector
            class="w-100"
            [getAllCompaniesDocumentTypes]="true"
            [onlySelectables]="false"
            [isMultiple]="false"
            [includeNullValue]="true"
            [ignoreUserDocumentIds]="ignoreChildrenOfCurrentDocument(selectedDocumentParent)"
            [firstLoadedSelectedDocumentTypeIds]="selectedDocumentParent?.id"
            (result)="onSelectedDocumentType($event)"
          ></obc-document-type-nested-selector>
        </div>
      </div>

      <form [formGroup]="formGroup">
        <input type="hidden" formControlName="id">
        <input type="hidden" formControlName="parentId">

        <div class="form-group">
          <label>Title</label>
          <input type="text" class="form-control" formControlName="title">
        </div>
        <div class="form-group">
          <label>Document Multi Image Mode</label>
          <select class="form-control" formControlName="multiImageMode">
            <option *ngFor="let item of (documentMultiImageMode | enumToArray)"
              [ngValue]="documentMultiImageMode[item]">
              {{documentMultiImageMode[item] | multiImageModePipe}}
            </option>
          </select>
        </div>
        <div class="form-group form-check">
          <input type="checkbox" id="enableTitleId" class="form-check-input" formControlName="enableTitle">
          <label class="form-check-label" for="enableTitleId">Enable Title</label>
        </div>
        <div class="form-group form-check">
          <input type="checkbox" id="isSelectableId" class="form-check-input"
                 formControlName="isSelectable">
          <label class="form-check-label" for="isSelectableId">Is Selectable</label>
        </div>
        <div class="form-group form-check">
          <input type="checkbox" id="enableExpireDateId" class="form-check-input" formControlName="enableExpireDate">
          <label class="form-check-label" for="enableExpireDateId">Enable Expiry Date</label>
        </div>
        <p class="button-group">
          <button [disabled]="!formGroup.valid" class="card-link btn btn-primary"
            (click)="onSubmit()">Submit</button>
        </p>
      </form>
    </div>
  </div>
</ng-template>
