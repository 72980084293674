import { Component, OnInit, Input, ElementRef, AfterViewInit, ViewChild, Output } from '@angular/core';
import Editor from '@toast-ui/editor';
import { EventEmitter } from '@angular/core';
import { SupplierDocumentEditorType } from 'src/app/enums/supplier-document-editor-type';
import { GetAllPlaceHolders } from 'src/app/helpers/supplier-documents-editor-placeholder';
import { getFileExtenstion, toBase64 } from 'src/app/helpers/general-functions';
import { FileStorageService } from 'src/app/services/file-storage.service';
import { UploadFileInfo } from 'src/app/models/requiredDocumentAnswer';
import { InlineRichTextMaxFileSizeInMegaByte, MaxFileSize } from 'src/app/enums/constants';
import { ModalService } from 'src/app/services/modal.service';


@Component({
  selector: 'obc-markdown-editor',
  templateUrl: './markdown-editor.component.html',
  styleUrls: ['./markdown-editor.component.scss']
})
export class MarkdownEditorComponent implements OnInit, AfterViewInit {

  @ViewChild('editorRef') editorRef: ElementRef;
  @Input() id: string;
  @Input() value: string;
  @Input() type: SupplierDocumentEditorType;
  @Input() height: string = '300px';

  @Output() public valueChanged = new EventEmitter();

  instance: any;

  constructor(private fileStorageService: FileStorageService, private modalService: ModalService) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    let that = this;
    this.instance = new Editor({
      el: document.querySelector('#' + this.editorRef.nativeElement.id),
      previewStyle: 'vertical',
      initialEditType: 'wysiwyg',
      height: this.height,
      initialValue: this.value,
      events: {
        change: function (value) {
          that.valueChanged.emit(that.instance.getMarkdown());
        }
      },
      hooks: {
        addImageBlobHook: function (blob, callback) {
          that.onAddImageBlob(blob, callback);
        }
      },
      usageStatistics: false,
      toolbarItems: [
        'heading',
        'bold',
        'italic',
        'strike',
        // The divider element had to be added to differentiate different groups.
        'divider',
        'hr',
        'quote',
        'divider',
        // ...
        'ul',
        'ol',
        'task',
        'indent',
        'outdent',
        'divider',
        // ...
        'table',
        'link',
        'divider',
        'image'
      ],
    });

    //Change color of placeholders on page load
    // var html = that.instance.getHtml();
    var placeHolders = GetAllPlaceHolders(that.type);
    // placeHolders.forEach(placeholder => {
    //   html = html.replaceAll(placeholder, "<span style='color:green'>" + placeholder + "</span>");
    // });
    // that.instance.setHtml(html, false);

    if (placeHolders != null && placeHolders.length > 0) {
      //Get Toolbar
      const toolbar = this.instance.getUI().getToolbar();

      //Add Placeholder Button to last toolbar item
      const name = 'placeHolders';
      toolbar.addItem({
        type: 'button',
        options: {
          name: name,
          className: 'btn',
          event: 'clickPlaceholderButton',
          tooltip: '',
          text: 'Placeholders',
          style: 'background-image:none;color:black;width:100px;border: 1px solid grey;padding-bottom:10px;'
        }
      });

      //Get PlaceholderButton
      const placeholderButtonIndex = toolbar.indexOfItem(name);
      const { el: button } = toolbar.getItem(placeholderButtonIndex);

      //close all popups
      this.instance.eventManager.listen('closeAllPopup', () => {
        popup.hide();
      });

      this.instance.eventManager.listen('focus', () => {
        popup.hide();
      });

      //click placeholder button
      this.instance.eventManager.addEventType('clickPlaceholderButton');
      this.instance.eventManager.listen('clickPlaceholderButton', () => {
        if (popup.isShow()) {
          popup.hide();
          return;
        }

        const { offsetTop, offsetLeft, offsetHeight } = button;

        this.css(popup.el, {
          top: `${offsetTop + offsetHeight}px`,
          left: `${offsetLeft}px`
        }, null);

        this.instance.eventManager.emit('closeAllPopup');
        popup.show();
      });

      const placeholderContainer = document.createElement('div');

      if (this.type != null) {
        placeHolders.forEach(placeholder => {
          const btn = this.createButton(placeholder, 'btn btn-success');
          placeholderContainer.appendChild(btn);
          const br = document.createElement('br');
          placeholderContainer.appendChild(br);
        });
      }

      const popup = this.instance.getUI().createPopup({
        header: false,
        content: placeholderContainer,
        className: 'tui-popup-color',
        target: this.instance.getUI().getToolbar().el,
        css: {
          width: 'auto',
          position: 'absolute'
        }
      });
    }
  }

  createButton(text, className) {
    const button = document.createElement('button');
    button.setAttribute('type', 'button');
    button.className = className;
    button.style.position = "relative";
    button.style.marginLeft = "0";
    button.style.width = "200px";
    button.style.color = "black";
    button.innerHTML = text;
    button.addEventListener('click', () => {
      this.instance.insertText(text);
      // var html = this.instance.getHtml();
      // html = html.replaceAll(text, "<span style='color:green'>" + text + "</span>");
      // this.instance.setHtml(html);
    })
    return button;
  }

  //Utils
  css(element, key, value) {
    var style = element.style;

    if (this.isString(key)) {
      style[key] = value;

      return;
    }

    this.forEach(key, function (v, k) {
      style[k] = v;
    }, this);
  }

  isString(obj) {
    return typeof obj === 'string' || obj instanceof String;
  }

  forEachOwnProperties(obj, iteratee, context) {
    var key;

    context = context || null;

    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (iteratee.call(context, obj[key], key, obj) === false) {
          break;
        }
      }
    }
  }

  forEachArray(arr, iteratee, context) {
    var index = 0;
    var len = arr.length;

    context = context || null;

    for (; index < len; index += 1) {
      if (iteratee.call(context, arr[index], index, arr) === false) {
        break;
      }
    }
  }

  forEach(obj, iteratee, context) {
    if (this.isArray(obj)) {
      this.forEachArray(obj, iteratee, context);
    } else {
      this.forEachOwnProperties(obj, iteratee, context);
    }
  }

  isArray(obj) {
    return obj instanceof Array;
  }

  //Upload Images
  inProgress: boolean = false;
  onAddImageBlob(blob, callback) {
    this.uploadImage(blob, callback);
  };

  async uploadImage(blob, callback) {
    if (blob.size > InlineRichTextMaxFileSizeInMegaByte * 1024000) {
      this.modalService.error(`Maximum File Size is ${InlineRichTextMaxFileSizeInMegaByte}mb`, "image size").subscribe(_ => { });
    }
    else {
      var body = blob == null ? null : (await toBase64(blob)) as string;
      var fileAsBase64 = body == null ? null : body.split(';base64,')[1];
      var inlineFile: UploadFileInfo = {
        fileAsBase64: fileAsBase64,
        fileExtension: getFileExtenstion(blob.name)
      }

      this.inProgress = true;
      this.fileStorageService
        .uploadInlineFile(inlineFile)
        .subscribe({
          next: res => {
            this.inProgress = false;
            callback(res.uploadedLink, '');
          },
          error: res => {
            this.modalService.error(res)
          }
        });
    };
  }
}
