import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { ProductPlanFeature } from 'src/app/enums/product-plan-feature.enum';
import {dateTimeFormatWithSeconds, formatDate} from "../../helpers/date-time-format";
import {ExportCsvOutputModel} from "../../models/export-csv-output-model";
import {UserInfoViewModel} from "../../models/user-info-viewmodel";
import { UserService } from "../../services/user.service";

@Component({
  selector: 'obc-export-csv-popup',
  templateUrl: './export-csv-popup.component.html',
  styleUrls: ['./export-csv-popup.component.scss'],
})
export class ExportCsvPopupComponent implements OnInit {

  id: string;
  @Input() disabled: boolean = true;
  @Input() isDropUp: boolean = false;
  @Input() displayColumnFilterRadios: boolean = true;
  @Input() set userInfo(data: UserInfoViewModel) {
    this.csvFormGroup.addControl('title', new FormControl(this._csvFileName(), [Validators.required]));
    this.csvFormGroup.addControl('email', new FormControl(data.isEmailVerified ? data.email : "", [Validators.email]));
    this.csvFormGroup.addControl('csvExportOnlyVisible', new FormControl(true, [Validators.required]));
  }
  @Input() title: string;
  @Output() onExport: EventEmitter<ExportCsvOutputModel> = new EventEmitter<ExportCsvOutputModel>();
  csvFormGroup: FormGroup = new FormGroup({});

  constructor(private userService: UserService) { }
  ngOnInit(): void {
    this.id = Math.random().toString();
  }

  updateCsvReportTitle() {
    this.csvFormGroup.controls.title.setValue(this._csvFileName());
    this.csvFormGroup.controls.csvExportOnlyVisible.setValue(true);
  }
  onCsvPopoverClick($event) {
    if($event.target.id != 'cancel') {
      $event.stopPropagation()
    }
  }
  _csvFileName() {
    let formattedDate = formatDate(new Date(), dateTimeFormatWithSeconds);
    return this.title + " - " + formattedDate;
  }

  exportCsv() {
    if(this.csvFormGroup.invalid) {
      return;
    }
    this.onExport.emit({
      exportOnlyVisible: this.csvFormGroup.controls.csvExportOnlyVisible.value,
      title: this.csvFormGroup.controls.title.value,
      email: this.csvFormGroup.controls.email.value,
    } as ExportCsvOutputModel)
  }
  hasExportFeatureOnCompany() {
    return this.userService.hasCompanyFeature(ProductPlanFeature.Export);
  }
}
