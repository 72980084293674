import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { BioStarIntegrationConfiguration } from 'src/app/models/biostar/bio-star-integration-configuration';
import { BioStarLogViewModel } from 'src/app/models/biostar/biostar-log-viewmodel';
import { BiostarService } from 'src/app/services/biostar.service';
import { CompanySettingService } from 'src/app/services/company-setting.service';
import { ModalService } from 'src/app/services/modal.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'obc-biostar-configuration',
  templateUrl: './biostar-configuration.component.html',
  styleUrls: ['./biostar-configuration.component.scss']
})
export class BiostarConfigurationComponent implements OnInit {
  inProgress: boolean;
  errorMessage: string;
  config: BioStarIntegrationConfiguration = { isReadyToConnect: false, isBioStarEnabled: false, isValidToCallApi: false };
  showCompletePanel: boolean;
  paymentCategories: any[];
  logs: BioStarLogViewModel[];

  editApiSection: boolean = true;
  editBiostarSyncConfig: boolean = true;


  constructor(private companySettingService: CompanySettingService,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private userService: UserService,
    private bioStarService: BiostarService) { }

  get isGodUser(): boolean {
    return this.userService.isGodUser();
  }

  ngOnInit(): void {
    this.getConfig();
  }

  updateUiVars() {
    this.editApiSection = false;
    this.editBiostarSyncConfig = !(this.config.isReadyToConnect ?? false);
  }

  getConfig() {
    this.inProgress = true;
    this.companySettingService.getBioStarConfiguration()
      .pipe(finalize(() => this.inProgress = false))
      .subscribe((res) => {
        this.config = res;
        this.updateUiVars();
      });
  }

  onEnabledChanged() {
    if (!this.config.isBioStarEnabled) {
      this.saveAdvancedModeConfig();
    }
    else {
      this.editBiostarAdvancedConfig();
    }
  }

  saveAdvancedModeConfig() {
    this.resetMessages();
    // if (this.config.isBioStarEnabled &&
    //   (!(this.config.bioStarApiServerUrl?.length ?? 0) ||
    //     !(this.config.bioStarApiUsername?.length ?? 0) ||
    //     !(this.config.bioStarApiPassword?.length ?? 0))
    //     ) {
    //   this.modalService.error('Please fill all configurations!');
    //   return;
    // }
    this.inProgress = true;
    this.companySettingService.validateAndSaveBiostarConfiguration(this.config)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        if (res) {
          this.config = res;
          this.editBiostarSyncConfig = false;
          this.modalService.success('BioStar2 configuration saved!');
        }
      }, (error) => {
        this.errorMessage = error;
      });
  }
  editBiostarAdvancedConfig() {
    this.editBiostarSyncConfig = true;
  }

  private resetMessages() {
    this.errorMessage = '';
  }
  logModalRef: BsModalRef;
  showLogs(modalTemplate: any) {
    this.inProgress = true;
    this.logs = [];
    this.bioStarService.getLogs()
      .subscribe(res => {
        this.inProgress = false;
        this.logs = res;
        this.logModalRef = this.bsModalService.show(modalTemplate, { class: "modal-lg modal-full-width" });
      });
  }
  closeLogModal() {
    this.logModalRef.hide();
  }
}
