import { Component, OnInit, Input } from '@angular/core';
import { QuestionViewModel } from '../../../models/question-viewmodel';
import { ControlContainer, NgForm } from '@angular/forms';
import { ItemTitleValue } from 'src/app/models/helper-models';
import { regexLists } from 'src/app/helpers/general-lists';
@Component({
  selector: 'obc-question-signature-pad',
  templateUrl: './question-signature-pad.component.html',
  styleUrls: ['./question-signature-pad.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class QuestionSignaturePadComponent implements OnInit {
  questionParam: any;

  @Input() set questionParamInput(_questionParamInput: any) {
    this.questionParam = _questionParamInput;
  }

  constructor() { }

  ngOnInit(): void {
  }


}
