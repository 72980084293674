import { Pipe, PipeTransform } from '@angular/core';
import { ExpiryMode } from '../enums/expiry-mode.enum';

@Pipe({
  name: 'expiryMode'
})
export class ExpiryModePipe implements PipeTransform {

  transform(value: any): string {
    if (value == ExpiryMode.Custom) return "Custom";
    if (value == ExpiryMode.NoExpiry) return "No Expiry";
    if (value == ExpiryMode.EndDay) return "End of the day";
    if (value == ExpiryMode.EndWeek) return "End of the week";
    if (value == ExpiryMode.EndMonth) return "End of the month";
    if (value == ExpiryMode.EndYear) return "End of the year";
    return value?.toString() ?? "?";
  }

}
