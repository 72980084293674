import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FormType } from 'src/app/enums/form-type';
import { GlobalItemType } from 'src/app/enums/global-item-type.enum';
import { ToolDynamicFieldType } from 'src/app/enums/tool-dynamic-field-type.enum';
import { ToolDynamicFieldUsage } from 'src/app/enums/tool-dynamic-field-usage.enum';
import { FormViewModel } from 'src/app/models/form/form-view-model';
import { GetFormsQueryModel } from 'src/app/models/form/get-forms-query-model';
import { GlobalToolDynamicFieldViewModel } from 'src/app/models/inventory/global-tool-dynamic-field-view-model';
import { ToolCategoryModel, ToolCategoryViewModel } from 'src/app/models/inventory/tool-category-model';
import { ToolDynamicFieldModel, ToolDynamicFieldViewModel } from 'src/app/models/inventory/tool-dynamic-field-model';
import { QuestionAnswerFileFormat } from 'src/app/models/questionParameters/question-answer-file-format';
import { FormService } from 'src/app/services/form.service';
import { ManageInventoryService } from 'src/app/services/manage-inventory.service';
import { ModalService } from 'src/app/services/modal.service';
import { FormInjectorComponent } from '../../form-manage/form-injector/form-injector.component';

@Component({
  selector: 'obc-manage-tool-categories',
  templateUrl: './manage-tool-categories.component.html',
  styleUrls: ['./manage-tool-categories.component.scss']
})
export class ManageToolCategoriesComponent implements OnInit {

  @Input() hideFormOperationPanel: boolean = false;

  openedAccordion: {
    catId: number,
    isAssign: boolean
  } = null;

  formGroup = new FormGroup({
    title: new FormControl(null, [Validators.required])
  });

  GlobalItemType = GlobalItemType;
  ToolDynamicFieldType = ToolDynamicFieldType;
  ToolDynamicFieldUsage = ToolDynamicFieldUsage;
  QuestionAnswerFileFormat = QuestionAnswerFileFormat;

  toolDynamicFieldformGroup = new FormGroup({
    fieldName: new FormControl(null, [Validators.required]),
    target: new FormControl(null, [Validators.required]),
    usage: new FormControl(null, [Validators.required]),
    isRequired: new FormControl(true, []),
    type: new FormControl(0, [Validators.required]),
    fileType: new FormControl(0, [Validators.required]),
    isBarcode: new FormControl(false, []),
    isSearchable: new FormControl(false, []),
    isLongText: new FormControl(false, []),
    isGlobalField: new FormControl(false, [Validators.required])
  });

  toolCategories: ToolCategoryViewModel[] = [];
  forms = new BehaviorSubject<FormViewModel[]>([]);
  handlerToolDynamicField = null;
  selectedGlobalToolDynamicField: GlobalToolDynamicFieldViewModel;

  constructor(
    private manageInventoryService: ManageInventoryService,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private formService: FormService) { }
  inProgress = false;

  get isGlobalField(): boolean {
    return this.toolDynamicFieldformGroup.controls.isGlobalField.value;
  }

  ngOnInit() {
    this.refresh();
    this.getForms();

    this.toolDynamicFieldformGroup.controls['isGlobalField'].valueChanges.subscribe(newValue => {
      if (newValue) {
        this.toolDynamicFieldformGroup.controls['fieldName'].clearValidators();
        this.toolDynamicFieldformGroup.controls['type'].disable();
        this.toolDynamicFieldformGroup.controls['fileType'].disable();
        this.toolDynamicFieldformGroup.controls['isBarcode'].disable();
        this.toolDynamicFieldformGroup.controls['isSearchable'].disable();
        this.toolDynamicFieldformGroup.controls['isLongText'].disable();

      }
      else {
        this.toolDynamicFieldformGroup.controls['fieldName'].setValidators([Validators.required]);
        this.toolDynamicFieldformGroup.controls['type'].enable();
        this.toolDynamicFieldformGroup.controls['fileType'].enable();
        this.toolDynamicFieldformGroup.controls['isBarcode'].enable();
        this.toolDynamicFieldformGroup.controls['isSearchable'].enable();
        this.toolDynamicFieldformGroup.controls['isLongText'].enable();
      }


      this.toolDynamicFieldformGroup.controls['fieldName'].updateValueAndValidity();
      this.toolDynamicFieldformGroup.updateValueAndValidity();
    });
  }

  getUsage(): ToolDynamicFieldUsage {
    return this.toolDynamicFieldformGroup.controls.usage.value;
  }

  private getForms() {
    var model = {
      formType: FormType.AssetOperation,
    } as GetFormsQueryModel;

    this.formService.getForms(model).subscribe(
      res => {
        if (res.success) {
          this.forms.next(res.data);
        } else {
          this.modalService.error(res.message);
          this.inProgress = false;
        }
      }, err => {
        this.modalService.error("Request Failed");
        this.inProgress = false;
      }, () => {
        this.inProgress = false;
      }
    )
  }

  onAdd() {
    this.inProgress = true;
    var model = {
      title: this.formGroup.controls.title.value,
    } as ToolCategoryModel;
    this.manageInventoryService.addToolCategory(model)
      .pipe(finalize(() => { this.inProgress = false }))
      .subscribe(res => {
        this.toolCategories.push(res);
        this.formGroup.reset();
      }, err => {
        this.modalService.error(err)
      })
  }

  onAddCategory() {

  }

  onRemove(id: number) {
    this.modalService.confirm("Are you sure you want to remove this tool category?", "Remove Tool Category").subscribe(res => {
      if (res) {
        this.inProgress = true;
        this.manageInventoryService.removeToolCategory(id)
          .pipe(finalize(() => { this.inProgress = false }))
          .subscribe(res => {
            var loc = this.toolCategories.find(x => x.id == id);
            var index = this.toolCategories.indexOf(loc);
            this.toolCategories.splice(index, 1);
          }, err => {
            this.modalService.error(err)
          })
      }
    })
  }

  refresh() {
    this.inProgress = true;
    this.manageInventoryService.getToolCategories()
      .pipe(finalize(() => { this.inProgress = false }))
      .subscribe(res => {
        this.toolCategories = res;
      }, err => {
        this.modalService.error(err)
      })
  }

  onOpenToolDynamicField(template: any, item: ToolCategoryViewModel, usage: ToolDynamicFieldUsage) {
    this.openedAccordion = { catId: item.id, isAssign: usage == ToolDynamicFieldUsage.ToolAssign };
    this.toolDynamicFieldformGroup.controls.target.setValue(item)
    this.toolDynamicFieldformGroup.controls.usage.setValue(usage)
    this.handlerToolDynamicField = this.bsModalService.show(template);
  }

  onGlobalToolDynamicFieldSelect(field: GlobalToolDynamicFieldViewModel) {
    this.selectedGlobalToolDynamicField = field;

    this.toolDynamicFieldformGroup.controls['type'].setValue(field.type);
    this.toolDynamicFieldformGroup.controls['fileType'].setValue(field.fileType);
    this.toolDynamicFieldformGroup.controls['isBarcode'].setValue(field.isBarcode);
    this.toolDynamicFieldformGroup.controls['isSearchable'].setValue(field.isSearchable);
    this.toolDynamicFieldformGroup.controls['isLongText'].setValue(field.isLongText);
  }

  get isAddToolDynamicFieldButtonDisabled() {
    return !this.toolDynamicFieldformGroup.valid || (this.isGlobalField && this.selectedGlobalToolDynamicField == null);
  }

  onRemoveToolDynamicField(itemId: number, usage: ToolDynamicFieldUsage) {
    this.inProgress = true;

    // *** ANGULAR DOES NOT DETECT CATEGORY FIELD CHANGES
    var cat = this.toolCategories.find(c => {
      return c.toolAssignDynamicFields.some(f => f.id == itemId) || c.toolDynamicFields.some(f => f.id == itemId)
    });
    this.openedAccordion = { catId: cat.id, isAssign: usage == ToolDynamicFieldUsage.ToolAssign };
    var me = this;
    this.manageInventoryService.removeToolDynamicField(itemId)
      .pipe(finalize(() => { me.inProgress = false }))
      .subscribe(res => {
        me.refresh();
      }, err => {
        me.modalService.error(err)
      })
  }

  onAddToolDynamicField() {
    if (this.validateInfos() == false)
      return;

    this.inProgress = true;

    var model = {
      globalToolDynamicFieldId: this.isGlobalField ? this.selectedGlobalToolDynamicField?.id : null,
      fieldName: this.toolDynamicFieldformGroup.controls.fieldName.value,
      isRequired: this.toolDynamicFieldformGroup.controls.isRequired.value ?? false,
      usage: this.toolDynamicFieldformGroup.controls.usage.value,
      type: this.isGlobalField ? null : this.toolDynamicFieldformGroup.controls.type.value,
      fileType: this.isGlobalField? null:  this.toolDynamicFieldformGroup.controls.fileType.value,
      isBarcode: this.isGlobalField ? null : this.toolDynamicFieldformGroup.controls.isBarcode.value,
      isSearchable: this.isGlobalField ? null : this.toolDynamicFieldformGroup.controls.isSearchable.value,
      isLongText: this.isGlobalField ? null : this.toolDynamicFieldformGroup.controls.isLongText.value,
    } as ToolDynamicFieldModel;
    var target = this.toolDynamicFieldformGroup.controls.target.value as ToolCategoryViewModel;
    this.manageInventoryService.addToolDynamicField(target.id, model)
      .pipe(finalize(() => {
        this.inProgress = false;
        this.resetForm();

        this.handlerToolDynamicField.hide();
      }))
      .subscribe(res => {
        this.refresh();
      }, err => {
        this.modalService.error(err)
      });
  }
  validateInfos() {
    var usage = this.toolDynamicFieldformGroup.controls.usage.value;
    var type = this.toolDynamicFieldformGroup.controls.type.value;
    var fileType = this.toolDynamicFieldformGroup.controls.fileType.value;
    var isBarcode = this.toolDynamicFieldformGroup.controls.isBarcode.value;
    var isSearchable = this.toolDynamicFieldformGroup.controls.isSearchable.value;
    var isLongText = this.toolDynamicFieldformGroup.controls.isLongText.value;

    if (isBarcode == true &&
      (type == ToolDynamicFieldType.File ||
        type == ToolDynamicFieldType.Date)) {
      this.modalService.error("This field cannot be a barcode");
      return false;
    }
    if (this.isGlobalField == false &&
      isSearchable == true &&
      (usage == ToolDynamicFieldUsage.ToolAssign ||
        type == ToolDynamicFieldType.File ||
        type == ToolDynamicFieldType.Date)) {
      this.modalService.error("This field cannot be searchable");
      return false;
    }
    if (
      this.isGlobalField == false &&
      isLongText == true &&
      type != ToolDynamicFieldType.Text) {
      this.modalService.error("This field cannot be a long text");
      return false;
    }
    return true;
  }

  resetForm() {
    this.toolDynamicFieldformGroup.reset();
    this.toolDynamicFieldformGroup.controls.type.setValue(ToolDynamicFieldType.Text);
    this.toolDynamicFieldformGroup.controls.fileType.setValue(QuestionAnswerFileFormat.AnyFile);
    this.toolDynamicFieldformGroup.controls.isRequired.setValue(false);
    this.toolDynamicFieldformGroup.controls.isBarcode.setValue(false);
    this.toolDynamicFieldformGroup.controls.isSearchable.setValue(false);
    this.toolDynamicFieldformGroup.controls.isLongText.setValue(false);
    this.toolDynamicFieldformGroup.controls.isGlobalField.setValue(false);
  }
  onToolDynamicFieldTypeChange() {
    this.toolDynamicFieldformGroup.controls.isBarcode.setValue(false);
    this.toolDynamicFieldformGroup.controls.isSearchable.setValue(false);
    this.toolDynamicFieldformGroup.controls.isLongText.setValue(false);
  }

  onChangeDynamicFieldOrder(category, dynamicFields: ToolDynamicFieldViewModel[], isAssignField: boolean) {
    this.manageInventoryService.changeDynamicFieldOrder(category.id, isAssignField, dynamicFields.map((field) => field.id))
      .subscribe(_ => {
        this.refresh();
      });
  }

  isAccordionOpen(catId: number, isAssign: boolean) {
    return this.openedAccordion?.catId == catId && this.openedAccordion?.isAssign == isAssign;
  }

  currentDynamicField: ToolDynamicFieldViewModel = null;
  dynamicFieldTriggersModalRef: any = null;
  onShowTriggers(status: ToolDynamicFieldViewModel, template: any) {
    this.currentDynamicField = status;
    this.dynamicFieldTriggersModalRef = this.bsModalService.show(template, {
      class: "modal-lg",
      ignoreBackdropClick: true,
    })
  }

  onCloseStatusTriggers() {
    this.dynamicFieldTriggersModalRef.hide();
    this.refresh();
  }

  onOpenNewOperationFormModal(item: ToolCategoryViewModel) {
    this.bsModalService.show(FormInjectorComponent, {
      class: "modal-lg",
      initialState: {
        formType: FormType.AssetOperation,
        title: "Select Operation Form(s)",
        forms: this.forms.value,
        addedForms: (item.operationForms ?? [])?.map(x => x.id),
        addForm: ($event) => {
          this.manageInventoryService.addCategoryOperationForm(item.id, $event.id)
            .subscribe(res => {
              this.refresh();
            }, err => {
              this.modalService.error(err);
            });
        },
      },
    })
  }

  removeForm(item: ToolCategoryViewModel, form: FormViewModel) {
    this.modalService.confirm('Are you sure you want to remove this form?')
      .subscribe(res => {
        if (res) {
          this.inProgress = true;
          this.manageInventoryService.removeCategoryOperationForm(item.id, form.id)
            .subscribe(_ => {
              this.inProgress = true;
              this.refresh();
            }, err => {
              this.inProgress = true;
              this.modalService.error(err);
            });
        }
      });
  }
}
