import { Component, OnInit } from '@angular/core';
import { GridColumn, GridConfig, GridTemplate } from 'src/app/models/grid/grid.config';
import { LayoutService } from 'src/app/services/layout.service';
import { Table } from '../../../enums/table.enum';
import { ManageGatesViewModel, CommandParamModel } from '../../../models/manage_gates_viewmodel';
import * as _ from 'lodash';
import { FilterType } from '../../../enums/filter-type.enum';
import { AccessControlUnitService } from '../../../services/access-control-unit.service';
import { AccessControlUnitOpenCloseEvacuationModel } from '../../../models/access-control-unit-open-close-model';
import { AccessControlUnitGateViewModel, SiteAccessControlUnitViewModel } from '../../../models/site-access-control-unit-view-model';
import { AccessControlUnitGateStatus } from '../../../enums/access-control-unit-gate-status.enum';
import { AccessControlUnitGateType } from 'src/app/enums/access-control-unit-gate-type.enum';
import { ModalService } from '../../../services/modal.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'obc-manage-gates',
  templateUrl: './manage-gates.component.html',
  styleUrls: ['./manage-gates.component.css']
})
export class ManageGatesComponent implements OnInit {
  inProgress = false;
  logModel = "";
  identifire = "";
  toggleDocker = "";
  gridConfig: GridConfig = {} as GridConfig;
  gridColumns: GridColumn<ManageGatesViewModel>[];
  logGridConfig: GridConfig = {} as GridConfig;
  logGridColumns: GridColumn<ManageGatesViewModel>[];

  constructor(
    private layoutService: LayoutService,
    private accessControlUnitService: AccessControlUnitService,
    private modalService: ModalService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.layoutService.header = "Manage Gates";
    this.prepareGridConfig();
  }

  prepareGridConfig() {
    this.gridConfig = new GridConfig({
      apiUrl: '/api/AccessControlUnit/get-gate-list',
      tableType: Table.CardReport,
    });

    this.logGridConfig = new GridConfig({
      apiUrl: '/api/AccessControlUnit/get-client-logs',
      tableType: Table.CardReport,
      initialFilters: [{ key: "serial", value: "" }],
    });
    localStorage.setItem("items_per_page_manage-gates", "100");

    this.gridColumns = [
      {
        name: "Identifier",
        key: "identifier",
        enableSort: false,
        enableFilter: false,
      },
      {
        name: "Status",
        key: "isOnline",
        displayFunction: (item) => item.isOnline ? "Online" : "Offline",
        enableSort: false,
        enableFilter: false,
      },
      {
        name: "Company",
        key: "companyTitle",
        enableSort: false,
        enableFilter: false,
      },
      {
        name: "Site",
        key: "siteName",
        enableSort: false,
        enableFilter: false,
        gridTemplate: new GridTemplate().ComponentTemplateName('siteName'),
      },
      {
        name: "RegisterTime",
        key: "registerTime",
        enableSort: false,
        enableFilter: false,
        gridTemplate: new GridTemplate().ComponentTemplateName('registerTime'),
      },
      {
        name: "Additional Info",
        key: 'additionalInfo',
        enableSort: false,
        enableFilter: false,
        type: FilterType.Template,
        gridTemplate: new GridTemplate().ComponentTemplateName('additionalInfo'),
      },
      {
        name: "Keyboard Input Device",
        key: "keyboardInputDevices",
        enableSort: false,
        enableFilter: false,
        type: FilterType.Template,
        gridTemplate: new GridTemplate().ComponentTemplateName('keyboardInputDevice'),
      },
      {
        name: "Details",
        key: "Details",
        enableSort: false,
        enableFilter: false,
        type: FilterType.Template,
        gridTemplate: new GridTemplate().ComponentTemplateName('toggle'),
      },
    ];
    this.logGridColumns = [
      {
        name: "Caller",
        key: "caller",
        enableSort: false,
        enableFilter: false,
      },
      {
        name: "Timestamp",
        key: "timestamp",
        type: FilterType.DateTimeRange,
        enableSort: false,
        enableFilter: false,
      },
      {
        name: "Details",
        key: "Details",
        enableSort: false,
        enableFilter: false,
        type: FilterType.Template,
        gridTemplate: new GridTemplate().ComponentTemplateName('showJson'),
      },
    ];
  }

  getGateButtonTitle(gate: AccessControlUnitGateViewModel) {
    let openClose = 'Open'
    let title = ''
    let gateMode = 'Gate'

    switch (gate.accessControlUnitGateType) {
      case AccessControlUnitGateType.Entry:
        title = 'Entry'
        break;
      case AccessControlUnitGateType.Exit:
        title = 'Exit';
        break;
      case AccessControlUnitGateType.Evacuation:
        title = 'Evacuation'
        openClose = gate.accessControlUnitGateStatus == AccessControlUnitGateStatus.Close ? 'Enable' : 'Disable'
        gateMode = 'Mode'
        break;
    }

    return `${openClose} ${title} ${gateMode}`
  }

  handleGate(accessControlUnit: SiteAccessControlUnitViewModel, gate: AccessControlUnitGateViewModel, siteId: number) {
    let message = gate.accessControlUnitGateType == AccessControlUnitGateType.Entry ?
      'open entry gate' :
      gate.accessControlUnitGateType == AccessControlUnitGateType.Exit ?
        'open exit gate' :
        (`${gate.accessControlUnitGateStatus == AccessControlUnitGateStatus.Open ? 'disable' : 'enable'} evacuation mode`);

    let subscription = this.modalService.confirm(`Are you sure you want to ${message}?`)
      .subscribe(
        confirmResult => {
          if (!confirmResult)
            return;

          if (gate.accessControlUnitGateType == AccessControlUnitGateType.Evacuation) {
            this.handleSetEvac(accessControlUnit, gate, siteId);
          }
          else {
            this.handleOpenGate(accessControlUnit, gate, siteId);
          }
        }, err => { subscription.unsubscribe(); },
        () => { subscription.unsubscribe(); }
      )
  }

  handleSetEvac(accessControlUnit: SiteAccessControlUnitViewModel, gate: AccessControlUnitGateViewModel, siteId: number) {
    this.inProgress = true;

    let subscription = this
      .setEvacMode(accessControlUnit, gate, siteId)
      .subscribe(res => {
        if (res) {
          this.toastr.success(`Evacuation mode was successfully ${gate.accessControlUnitGateStatus == AccessControlUnitGateStatus.Open ? 'disabled' : 'enabled'}.`);

          if (gate.accessControlUnitGateStatus == AccessControlUnitGateStatus.Close) gate.accessControlUnitGateStatus = AccessControlUnitGateStatus.Open;
          else gate.accessControlUnitGateStatus = AccessControlUnitGateStatus.Close
        }
        else {
          this.toastr.error(`Couldn't ${gate.accessControlUnitGateStatus == AccessControlUnitGateStatus.Open ? 'disable' : 'enable'} evacuation mode!`);
        }
      },
        err => {
          this.toastr.error(`Couldn't ${gate.accessControlUnitGateStatus == AccessControlUnitGateStatus.Open ? 'disable' : 'enable'} evacuation mode!`);
          subscription.unsubscribe();
          this.inProgress = false;
        }, () => {
          subscription.unsubscribe();
          this.inProgress = false;
        })
  }

  setEvacMode(accessControlUnit: SiteAccessControlUnitViewModel, gate: AccessControlUnitGateViewModel, siteId: number) {
    return this.accessControlUnitService.setEvacMode(this.getAccessControlUnitOpenCloseModelToToggleEvacMode(accessControlUnit, gate, siteId));
  }

  getAccessControlUnitOpenCloseModelToToggleEvacMode(accessControlUnit: SiteAccessControlUnitViewModel, gate: AccessControlUnitGateViewModel, siteId: number): AccessControlUnitOpenCloseEvacuationModel {
    return {
      siteId: siteId,//this.selectedSiteAccessControlUnit.siteId,
      gate: {
        identifier: gate.identifier,
        accessControlUnitGateStatus: gate.accessControlUnitGateStatus == AccessControlUnitGateStatus.Close ? AccessControlUnitGateStatus.Open : AccessControlUnitGateStatus.Close,
        accessControlUnitGateType: gate.accessControlUnitGateType
      } as AccessControlUnitGateViewModel,
      controlUnitIdentifier: accessControlUnit.controlUnitIdentifier
    }
  }

  handleOpenGate(accessControlUnit: SiteAccessControlUnitViewModel, gate: AccessControlUnitGateViewModel, siteId: number) {
    this.inProgress = true;

    let subscription = this
      .openGate(accessControlUnit, gate, siteId)
      .subscribe(res => {
        if (res)
          this.toastr.success(`Gate opened successfully`);
        else
          this.toastr.error(`Can not open Gate`);
      },
        err => {
          this.toastr.error(`Can not open Gate`);
          subscription.unsubscribe();
          this.inProgress = false;
        }, () => {
          subscription.unsubscribe();
          this.inProgress = false;
        })
  }

  openGate(accessControlUnit: SiteAccessControlUnitViewModel, gate: AccessControlUnitGateViewModel, siteId: number) {
    return this.accessControlUnitService.openGate(this.getAccessControlUnitOpenCloseModel(accessControlUnit, gate, siteId));
  }

  getAccessControlUnitOpenCloseModel(accessControlUnit: SiteAccessControlUnitViewModel, gate: AccessControlUnitGateViewModel, siteId: number): AccessControlUnitOpenCloseEvacuationModel {
    return {
      siteId: siteId,// this.selectedSiteAccessControlUnit.siteId,
      gate: gate,
      controlUnitIdentifier: accessControlUnit.controlUnitIdentifier
    }
  }

  showClientLogs(template, item: ManageGatesViewModel) {
    this.identifire = item.identifier;
    this.logGridConfig.initialFilters.filter(x => x.key == "serial")[0].value = item.registeredClientViewModel.serial;
    this.modalService.show(template, "modal-xl");
  }

  showLogModelJson(template, item) {
    this.logModel = item.model;
    this.modalService.show(template, "bg-warning p-1 mt-5");
  }

  toggle(item: ManageGatesViewModel) {
    if (!item.toggle) {
      this.toastr.error("Please enter command prompt...")
      return
    }

    this.inProgress = true;

    let commandParamModel: CommandParamModel = {
      ControlUnitId: item.identifier,
      Command: `toggle ${item.toggle}`
    };

    let subscription = this
      .accessControlUnitService
      .toggle(commandParamModel)
      .subscribe(res => {
        if (res.success)
          this.toastr.success(`Toggled successfully`);
        else
          this.toastr.error(`Toggle Error: ${res.message}`);
      }, err => {
        this.toastr.error(`Toggle Error`);
        subscription.unsubscribe();
        this.inProgress = false;
      }, () => {
        subscription.unsubscribe();
        this.inProgress = false;
      })
  }
}
