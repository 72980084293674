import { Component, OnInit, Input, Self } from '@angular/core';
import { FormControl, FormGroup, Validators, ControlValueAccessor, NgControl } from '@angular/forms';
import { EmailTemplateViewmodel } from 'src/app/models/email-template-viewmodel';
import { emailArrayValidator } from 'src/app/helpers/email-list-validator';
import { emailTemplateValidator } from 'src/app/helpers/email-template-validator';

@Component({
  selector: 'obc-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css'],
})
export class EmailTemplateComponent implements OnInit, ControlValueAccessor {
  onChange: Function;
  formGroup: FormGroup;

  @Input() showSendEmailToSiteSupervisor: boolean = true;

  get model(): EmailTemplateViewmodel {
    return {
      subject: this.formGroup.controls['subject'].value,
      recipients: this.formGroup.controls['recipients'].value,
      sendEmailToCompanySupervisor: this.formGroup.controls['sendEmailToCompanySupervisor'].value,
      sendEmailToSiteSupervisor: this.formGroup.controls['sendEmailToSiteSupervisor'].value,
    } as EmailTemplateViewmodel;

  };

  set model(value: EmailTemplateViewmodel) {
    if (this.formGroup == null) {
      this.formGroup = new FormGroup({
        subject: new FormControl(value?.subject ?? '', [Validators.required]),
        recipients: new FormControl(value?.recipients ?? [], [emailArrayValidator]),
        sendEmailToCompanySupervisor: new FormControl(value?.sendEmailToCompanySupervisor ?? false, []),
        sendEmailToSiteSupervisor: new FormControl(value?.sendEmailToSiteSupervisor ?? false, []),
      }, { validators: emailTemplateValidator });

      this.formGroup.valueChanges.subscribe(res => {
        this.onChange(this.model);
      })
    }
    else {
      this.formGroup.controls.subject.setValue(value.subject);
      this.formGroup.controls.recipients.setValue(value.recipients);
      this.formGroup.controls.sendEmailToCompanySupervisor.setValue(value.sendEmailToCompanySupervisor);
      this.formGroup.controls.sendEmailToSiteSupervisor.setValue(value.sendEmailToSiteSupervisor);
    }

  }

  writeValue(obj: EmailTemplateViewmodel): void {
    this.model = JSON.parse(JSON.stringify(obj));
  }

  validate({ value }: FormControl) {
    return this.formGroup.valid ? null : {
      invalid: true
    };
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
  constructor(@Self() private controlDirective: NgControl) {
    controlDirective.valueAccessor = this;
  }

  ngOnInit() {
    this.controlDirective.control.setValidators([this.validate.bind(this)]);
    this.controlDirective.control.updateValueAndValidity();
  }

}
