import { Pipe, PipeTransform } from '@angular/core';
import { DocumentExpiryStatus } from '../enums/document-expiry-status.enum';

@Pipe({
  name: 'documentExpiryStatus'
})
export class DocumentExpiryStatusPipe implements PipeTransform {

  transform(value: unknown): string {
    if (value === DocumentExpiryStatus.Valid || value === 0 || value === "Valid") return "Valid";
    if (value === DocumentExpiryStatus.Expired || value === 1 || value === "Expired") return "Expired";
    if (value === DocumentExpiryStatus.ExpireInNext30Day || value === 2 || value === "ExpireInNext30Day") return "Expires In Next 30 Days";
    return value?.toString();
  }

}
