import { Component, Input, OnInit } from '@angular/core';
import { finalize } from "rxjs/operators";
import { SiteSupplierService } from "../../../services/site-supplier.service";
import { SiteSupplierDocumentVersionInfo } from "../../../models/supplier-document/site-supplier-models";
import { ModalService } from "../../../services/modal.service";
import { BehaviorSubject } from 'rxjs';
import { FileModel } from 'src/app/models/supplier-document/site-supplier-document-type-view-model.model';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'obc-view-site-supplier-document-version-history',
  templateUrl: './view-site-supplier-document-version-history.component.html',
  styleUrls: ['./view-site-supplier-document-version-history.component.scss']
})
export class ViewSiteSupplierDocumentVersionHistoryComponent implements OnInit {

  siteSupplierDocumentVersions: SiteSupplierDocumentVersionInfo[] = [];
  @Input() supplierDocCommentAcknowledgementText: string;

  @Input() siteSupplierDocumentTypeId: number;
  inProgress = false;
  selectedFormDataId :number;

  _siteSupplierHashlink = new BehaviorSubject<string>(null);
  @Input() set siteSupplierHashlink(value: string) {
    if (value != this._siteSupplierHashlink.value && value != null) {
      this._siteSupplierHashlink.next(value);
    }
  }

  get isSupplierUser(){
    return this._siteSupplierHashlink.value?.length > 0;
  }

  constructor(
    private siteSupplierService: SiteSupplierService,
    private bsModalService : BsModalService,
    private modalService: ModalService) { }

  ngOnInit(): void {
    this.inProgress = true;
    this.siteSupplierService.getSiteSupplierDocumentVersionList(this.siteSupplierDocumentTypeId, this._siteSupplierHashlink.value)
      .pipe(finalize(() => {
        this.inProgress = false;
      }))
      .subscribe(res => {
        this.supplierDocCommentAcknowledgementText = res.supplierDocCommentAcknowledgementText;
        this.siteSupplierDocumentVersions = res.siteSupplierDocumentVersionsInfo;
      }, err => {
        this.modalService.error(err, "Unable to get information");
      });
  }

  bsModalRef = null;
  selectedFiles : FileModel[] = [];
  prefix : string;
  onOpenFileViewer(files: FileModel[], template: any, prefix : string) {
    this.selectedFiles = files;
    this.prefix = prefix;
    this.bsModalRef = this.bsModalService.show(template, {
      class:"modal-lg",
      ignoreBackdropClick: false,
    })
  }

  getFilePrefix(type: SiteSupplierDocumentVersionInfo) {
    return `${type.supplierDocumentTypeName}-${type.version}`;
  }
  onViewFilledForm(template: any, formDataId: number) {
    this.selectedFormDataId = formDataId;
    this.bsModalRef = this.bsModalService.show(template, {
      class: "modal-lg",
      ignoreBackdropClick: false,
    })
  }
}
