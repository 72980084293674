<div class="card">
  <div class="card-header">Edit Your Profile</div>
  <div class="card-body">
    <obc-loader [show]="profileInProccess"></obc-loader>
    <form *ngIf="profileFormGroup" [formGroup]="profileFormGroup" class="row">
      <div class="form-group col-md-4 mt-3 col-sm-12" *ngIf="userService.info | async">
        <label>Mobile</label>
        <input disabled type="text" [value]="(userService.info | async).mobile" class="form-control">
      </div>
      <div class="form-group col-md-4 mt-3 col-sm-12">
        <label>First Name</label>
        <input type="text" class="form-control" formControlName="firstName">
      </div>
      <div class="form-group col-md-4 mt-3 col-sm-12">
        <label>Last Name</label>
        <input type="text" class="form-control" formControlName="lastName">
      </div>
      <div class="form-group col-md-4 mt-3 col-sm-12">
        <label>Email</label>
        <div class="input-group">
          <input type="text" [readonly]="emailViewMode == 'view'" class="form-control" formControlName="email">
          <div class="input-group-append">
            <ng-container *ngIf="emailViewMode == 'view'">
              <span class="input-group-text" (click)="emailViewMode ='edit'">
                <i class="fa fa-edit" aria-hidden="true"></i>
              </span>
              <span class="input-group-text" *ngIf="isEmailVerified">
                <i tooltip="your email address is verified" class="fa fa-check text-success" aria-hidden="true"></i>
              </span>
              <span class="input-group-text" *ngIf="!isEmailVerified">
                <i tooltip="please verify your email address" class="fa fa-exclamation-triangle text-warning"
                  aria-hidden="true"></i>
              </span>
            </ng-container>
            <ng-container *ngIf="emailViewMode != 'view'">

            </ng-container>
          </div>
        </div>
        <button *ngIf="!isEmailVerified && emailViewMode == 'view'" class="btn btn-sm btn-warning pull-right mt-1"
          (click)="onSendVerificationEmail()">
          Re-Send Verification
          Email
        </button>
      </div>
      <div class="form-group col-md-4 mt-3 col-sm-12">
        <label>Date Of Birth</label>
        <obc-input-date-time-picker formControlName="birthDay" [mode]="DatetimePickerMode.Calender">
        </obc-input-date-time-picker>
        <!-- <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" type="text" class="form-control"
               formControlName="birthDay">
        <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time> -->
      </div>
      <div class="form-group col-md-4 mt-3 col-sm-12">
        <label>Gender</label>
        <select class="form-control" formControlName="gender">
          <option [value]="2">Other</option>
          <option [value]="0">Female</option>
          <option [value]="1">Male</option>
        </select>
      </div>
      <div class="form-group col-md-6 mt-3 col-sm-12">
        <label>Industry Type</label>
        <select class="form-control" formControlName="industryTypeId">
          <option *ngFor="let ind of industryTypeList" [value]="ind.id">{{ind.type}}</option>
        </select>
      </div>
      <div class="form-group col-md-6 mt-3 col-sm-12" *ngIf="isActive2fa">
        <label>Two Factor Verification Mode</label>
        <select class="form-control" formControlName="twoFactorVerificationMode"
          (change)="showQrCodeModal(verifyQrCode)">
          <option *ngFor="let item of twoFactorVerificationModeEnum | enumToArray"
            [value]="twoFactorVerificationModeEnum[item]">{{item|twoFactorVerificationModePipe}}</option>
        </select>
      </div>
      <div class="col-sm-12"></div>
      <div class="form-group col-md-6 mt-3 col-sm-12">
        <label>Profile Image</label>
        <input type="hidden" formControlName="profileImageBase64">
        <input type="file" accept=".png, .jpg, .jpeg" class="form-control-file" (change)="onPickImage($event)">
        <button *ngIf="imageViewMode == 'view' && imageSource && imageSource != 'data:image/png;base64,'"
          class="btn btn-sm btn-danger mt-1" (click)="onRemoveProfilePicture()">Remove Profile Image</button>
        <button *ngIf="imageViewMode == 'edit'" class="btn btn-sm btn-warning mt-1"
          (click)="onDiscardImageChanges()">Undo Profile Image Changes</button>
      </div>
      <div class="form-group col-md-6 mt-3 col-sm-12">
        <div class="col-12">
          <div *ngIf="imageViewMode == 'view' && imageSource && imageSource != 'data:image/png;base64,'">
            <div class="row">
              <div class="col-12" style="position: relative;">
                <img [src]="imageSource" style="width: 200px;height: 200px;">
              </div>
            </div>
          </div>
          <div *ngIf="imageViewMode == 'edit'">
            <div class="row">
              <div class="form-group col-12" style="position: relative;">
                <image-cropper *ngIf="imageSourceBase64 && imageSourceBase64.length"
                  style="height: 200px; width: 200px;" [imageBase64]="imageSourceBase64" [maintainAspectRatio]="true"
                  [aspectRatio]="1" format="png" (imageCropped)="imageCropped($event)"
                  (loadImageFailed)="loadImageFailed()" [containWithinAspectRatio]="true">
                </image-cropper>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="table-responsive" style="overflow: initial;">
          <table class="table table-bordered table-striped mb-0 dataTable no-footer">
            <thead>
              <tr role="row">
                <th>Name</th>
                <th>Abn</th>
                <th>Acn</th>
                <th></th>
              </tr>
              <tr>
                <th colspan="4">
                  <div class="input-group">
                    <input name="userBusiness" placeholder="Search and Add User Business" formControlName="userBusiness"
                      [typeahead]="suggestions$" [isAnimated]="true" [adaptivePosition]="true" [typeaheadAsync]="true"
                      [typeaheadOptionsInScrollableView]="6" (typeaheadOnSelect)="onSelectBusiness($event)"
                      [typeaheadItemTemplate]="customItemTemplate" [typeaheadMinLength]="2" class="form-control" />
                    <div class="input-group-prepend" *ngIf="loader">
                      <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <div>
                      <button class="btn btn-success mb-1" (click)="onAddBusiness()">Add</button>
                    </div>
                  </div>
                  <ng-template #customItemTemplate let-model="item">
                    <div>
                      <strong>{{ model.name }}</strong>
                    </div>
                    <div>
                      <small *ngIf="model.abn">abn: {{model.abn}}</small>
                      <small *ngIf="model.acn"> _ acn: {{model.acn}}</small>
                    </div>
                  </ng-template>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="!userBusinesses || userBusinesses.length == 0">
                <td colspan="5">
                  <p style="text-align: center; margin-bottom: 0;">Nothing Found ...</p>
                </td>
              </tr>
              <ng-container *ngFor="let item of userBusinesses">
                <tr>
                  <td>{{item.name}}</td>
                  <td>{{item.abn}}</td>
                  <td>{{item.acn}}</td>
                  <td class="actions">
                    <a class="btn p-1 remove-row" (click)="onDelete(item)">
                      <i class="far fa-trash-alt"></i>
                    </a>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-12 mt-4">
        <h6>Emergency Contact</h6>
        <obc-emergency-contact [required]="false" [jsonData]="profileFormGroup?.controls.emergencyContact.value"
          (onUpdate)="onUpdateEmergencyContact($event)"></obc-emergency-contact>
      </div>

      <div class="col-12 mt-3">
        <p class="button-group">
          <button type="submit" class="btn btn-primary" [disabled]="!profileFormGroup.valid || !isEmergencyContactValid"
            (click)="onSaveProfileChanges()">
            Save Changes
          </button>
        </p>
      </div>
    </form>
  </div>
</div>

<ng-template #verifyQrCode>
  <div class="card">
    <div class="card-header">
      <span>
        Enable authenticator
      </span>
      <button type="button" class="close" (click)="closeQr()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="form-group row pb-2 mb-1" *ngIf="hasTotpSecretKey">
        <label class="col-sm-4 control-label text-left pt-2">Use current authentication key</label>
        <div class="col-sm-3">
          <div class="input-group mb-3">
            <obc-switch [value]="'true'" [trueLabel]="'Yes'" [falseLabel]="'No'"
              (valueChanged)="setUseAuthentication($event)">
            </obc-switch>
          </div>
        </div>
        <div class="col-sm-2">
            <button *ngIf="useCurrentTotpSecretKey" type="button" class="btn btn-primary" (click)="totpVerifyCode()">Save</button>
        </div>
      </div>
      <div *ngIf="!useCurrentTotpSecretKey">
        <div class="ml-3 mt-2">
          To use an authenticator app go through the following steps:<br />
          1. Download a two-factor authenticator app like Microsoft Authenticator for Windows Phone, Android and iOS or
          Google Authenticator for Android and iOS.<br />
          2. Scan the QR Code or enter below key into your two factor authenticator app.
          <app-clipboard-box [text]="manualEntryKey"></app-clipboard-box>
          <div class="form-group col-md-4 mt-3 col-sm-12" *ngIf="isShowQr()">
            <div>
              <img [src]="qrCode">
            </div>
          </div>
        </div>
        3. Once you have scanned the QR code or input the key above, your two factor authentication app will provide you
        with a unique code. Enter the code in the confirmation box below.<br />
        <div class="form-group col-md-8 mt-3 col-sm-12">
          <label>Verification Code</label>
          <input type="number" class="form-control" [(ngModel)]="verifyCode" required>
        </div>
        <div class="form-group col-md-8 mt-3 col-sm-12">
          <button type="button" class="btn btn-primary" (click)="totpVerifyCode()">Verify</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
