export interface KeyValue<T1, T2> {
  key: T1,
  value: T2
}

export interface KeyValuePair extends KeyValue<number, string> {

}
export interface StringKeyValuePair extends KeyValue<string, string> {

}

export class AsyncResult {
  isFinished: boolean;
  isSucceeded: boolean;
  message?: string = '';
  needParentRefresh?: boolean;
  result?: any
}

export class ItemTitleValue {
  id?: number;
  itemTitle: string;
  itemValue: string;
  constructor(title: string, value: string) {
    this.itemTitle = title;
    this.itemValue = value;
  }
}
