import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CompanySiteSelectedType } from 'src/app/enums/company-site-selected-type';
import { CompanySiteSelectorResponseModel } from 'src/app/models/company-site-selector-response-model';
import { BaseSiteViewModel, SiteViewModel } from 'src/app/models/site-viewmodel';

@Component({
  selector: 'obc-company-site-selector',
  templateUrl: './company-site-selector.component.html',
  styleUrls: ['./company-site-selector.component.scss'],
})
export class CompanySiteSelectorComponent implements OnInit {
  showSiteList: boolean = true;
  selectedSite: BaseSiteViewModel;
  selectedLocationFormControl = new FormControl(this.companySiteSelectedType.AllCompanyAndSites);
  CompanySiteSelectedType = CompanySiteSelectedType;

  public get companySiteSelectedType(): typeof CompanySiteSelectedType {
    return CompanySiteSelectedType;
  }

  @Input() set selectedCompanyType(value: CompanySiteSelectedType) {
    if (value != this.selectedLocationFormControl.value) {
      setTimeout(() => {
        this.selectedLocationFormControl.setValue(value);
      }, 100)
    }
  };

  _currentSiteId: number;
  @Input() set currentSiteId(value: number) {
    this._currentSiteId = value;
    if (value > 0)
      this.selectedLocationFormControl.setValue(this.companySiteSelectedType.Site);
  }

  get currentSiteId(): number {
    return this._currentSiteId;
  }

  constructor() { }

  ngOnInit(): void {
    this.selectedLocationFormControl.valueChanges.subscribe(res => {
      this.onSelect(res, this.selectedSite?.id)
    });
  }

  @Output() onSelectChanged: EventEmitter<CompanySiteSelectorResponseModel> = new EventEmitter<CompanySiteSelectorResponseModel>();

  onSelect(selectedType: CompanySiteSelectedType, siteId?: number) {
    this.onSelectChanged.emit({ CompanySiteSelectedType: selectedType, siteId: siteId, siteName: this.selectedSite?.name } as CompanySiteSelectorResponseModel);
  }
  onSiteChange() {
    this.selectedLocationFormControl.setValue(this.companySiteSelectedType.Site);
  }
}
