<aside id="sidebar-left" class="sidebar-left">
  <div class="sidebar-header">
    <div class="sidebar-title">
      <div class="btn-group" dropdown>
        <button id="button-basic" dropdownToggle type="button" class="btn btn-info btn-sm dropdown-toggle"
          aria-controls="dropdown-basic">
          <span *ngIf="isGod && isAdminMode">Adminstration</span>
          <span class="company-name-button" *ngIf="!isAdminMode && companyName">{{companyName}}</span>
          <span class="caret"></span>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <button *ngIf="isGod && hasCompany" class="dropdown-item" type="button" (click)="isAdminMode = !isAdminMode">
            <span *ngIf="isAdminMode">View Company Panel</span>
            <span *ngIf="!isAdminMode">View Adminstration Panel</span>
          </button>
          <ng-container *ngIf="companies && hasCompany && !isAdminMode">
            <li *ngIf="isGod && hasCompany" class="divider dropdown-divider"></li>
            <li *ngIf="companies.length > 10">
              <input type="text" class="form-control" placeholder="Search" [(ngModel)]="filterCompanyListTerm"
                (click)="preventEventPropagation($event)" />
            </li>
            <div style="max-height: 390px;overflow-y: auto;">
              <button [disabled]="company.id == currentCompanyId"
                *ngFor="let company of ((companies | filter:filterCompanyListTerm:['title']) | orderBy:'title')"
                class="dropdown-item" type="button" (click)="onChangeCompany(company.id)">
                <i *ngIf="company.id == currentCompanyId" class="fa fa-check"></i>
                <span *ngIf="company.id != currentCompanyId" class="ml-3"></span>
                {{company.title}}
                <span *ngIf="company.isTemplate"> [*Template]</span>
              </button>
            </div>
          </ng-container>
        </ul>
      </div>
    </div>
    <div class="sidebar-toggle d-none d-md-block" (click)="layoutService.toggleSideBar()">
      <i class="fas fa-bars" aria-label="Toggle sidebar"></i>
    </div>
  </div>

  <div class="nano" style="max-height: 100%; overflow: auto;">
    <div class="nano-content">
      <nav id="menu" class="nav-main" role="navigation">
        <ul class="nav nav-main">
          <ng-container *ngFor="let sidebarItem of sidebarItems">
            <ng-container *ngIf="sidebarItem.show">
              <ng-template #sidebarItemContent>
                <i *ngIf="sidebarItem.icon" [class]="sidebarItem.icon"></i>
                <span>{{sidebarItem.title}}</span>
              </ng-template>
              <li [routerLinkActive]="sidebarItem.routerLinkActive" [attr.id]="sidebarItem.id"
                [ngClass]="{'nav-parent':sidebarItem.hasChild, 'nav-expanded':sidebarItem.isExpanded}">
                <ng-container *ngIf="sidebarItem.route && !sidebarItem.href">
                  <a [routerLink]="sidebarItem.route">
                    <ng-container [ngTemplateOutlet]="sidebarItemContent"></ng-container>
                  </a>
                </ng-container>
                <ng-container *ngIf="sidebarItem.href">
                  <a [href]="sidebarItem.href" [target]="sidebarItem.target">
                    <ng-container [ngTemplateOutlet]="sidebarItemContent"></ng-container>
                  </a>
                </ng-container>
                <ng-container *ngIf="sidebarItem.hasChild">
                  <a (click)="layoutService.toggleSubMenu($event,sidebarItem.element)" class="nav-link">
                    <ng-container [ngTemplateOutlet]="sidebarItemContent"></ng-container>
                  </a>
                </ng-container>
                <ng-container *ngIf="sidebarItem.hasChild">
                  <ul class="nav nav-children">
                    <ng-container *ngFor="let child of sidebarItem.childs">
                      <ng-container *ngIf="child.show">
                        <li [routerLinkActive]="child.routerLinkActive">
                          <a [routerLink]="child.route">
                            <i *ngIf="child.icon" [class]="child.icon"></i>
                            <span>{{child.title}}</span>
                          </a>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ul>
                </ng-container>
              </li>
            </ng-container>
          </ng-container>
          <!-- <li [routerLinkActive]="['link-active','nav-active']"
            *ngIf="hasCompany && !isAdminMode && !isTemplateCompany && userService.hasUserAnyOfPemissions(userPermissionList)">
            <a [routerLink]="['/dashboard']">
              <i class="fas fa-chart-line"></i>
              <span>Dashboard</span>
            </a>
          </li> -->
          <!--  <li [routerLinkActive]="['link-active','nav-active']" *ngIf="hasCompany && !isAdminMode && !isTemplateCompany && userService.hasUserAnyOfPemissions([userPermissions.AdjustSiteLocation, userPermissions.ManageAutomations,
          userPermissions.ManageSiteQuestion, userPermissions.ModifySite, userPermissions.ManageSiteAnnouncement])">
            <a [routerLink]="['/sites']">
              <i class='fas fa-archway'></i>
              <span>Sites</span>
            </a>
          </li>
          <li [routerLinkActive]="['link-active','nav-active']" *ngIf="isSupplierCompany">
            <a [routerLink]="['/manage-site-supplier-forms']">
              <i class='fas fa-file-alt'></i>
              <span>Supplier Forms</span>
            </a>
          </li>
          <li [routerLinkActive]="['link-active','nav-active']"
            *ngIf="hasCompany && !isAdminMode && !isTemplateCompany && userService.hasUserPermissionForCompany(userPermissions.SupplierManagement)">
            <a [routerLink]="['/supplier']">
              <i class="fas fa-industry"></i>
              <span>Suppliers</span>
            </a>
          </li>
          <li class="nav-parent" #permissions
            *ngIf="hasCompany && !isAdminMode && (userService.isCompanyAdminUser() || userService.hasUserAnyOfPemissions([userPermissions.ManageSiteAnnouncement]))">
            <a class="nav-link" (click)="layoutService.toggleSubMenu($event,permissions)">
              <i class="fas fa-cog"></i>
              <span>Editor</span>
            </a>
            <ul class="nav nav-children">

              <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="hasCompany && !isAdminMode && userService.isCompanyAdminUser()">
                <a [routerLink]="['/question']">
                  <i class='fas fa-question'></i>
                  <span>Questions</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="hasCompany && !isAdminMode && (userService.isCompanyAdminUser() || userService.hasUserAnyOfPemissions([userPermissions.ManageSiteAnnouncement])) && userService.hasCompanyFeature(ProductPlanFeature.Announcements)">
                <a [routerLink]="['/manage-announcements']">
                  <i class='fas fa-bell'></i>
                  <span>Announcements</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="hasCompany && !isAdminMode && userService.isCompanyAdminUser() && (userService.hasCompanyFeature(ProductPlanFeature.Form) || isTemplateCompany)">
                <a [routerLink]="['/forms']">
                  <i class="fas fa-file-alt"></i>
                  <span>Forms</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="hasCompany && !isAdminMode && !isTemplateCompany && userService.isCompanyAdminUser()">
                <a [routerLink]="['/inductions']">
                  <i class="fas fa-id-badge"></i>
                  <span>Inductions</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="isActiveAdvancedMenu && hasCompany && !isAdminMode && !isTemplateCompany && userService.isCompanyAdminUser() && userService.hasCompanyFeature(ProductPlanFeature.Scannables)">
                <a [routerLink]="['/scannables']">
                  <i class='fa fa-object-group'></i>
                  <span>Scannables</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="isTemplateCompany && hasCompany && !isAdminMode && userService.isCompanyAdminUser()">
                <a [routerLink]="['/asset-manager']">
              </li> -->
          <!-- <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="isActiveAdvancedMenu && hasCompany && !isAdminMode && !isTemplateCompany && userService.isCompanyAdminUser() && userService.hasCompanyFeature(ProductPlanFeature.Inventory)">
                <a [routerLink]="['/site-assets']">
                  <i class='fa fa-tools'></i>
                  <span>Asset Manager</span>
                </a>
              </li> -->
          <!-- </ul>
              </li>
            </ul>
          </li>

          <li class="nav-parent" #permissions
            *ngIf="hasCompany && !isAdminMode && !isTemplateCompany && (userService.isCompanyAdminUser() || userService.hasUserAnyOfPemissions([userPermissions.ManageViewTaggedUser, userPermissions.SiteHost , userPermissions.CompanyHost]))">
            <a class="nav-link" (click)="layoutService.toggleSubMenu($event,permissions)">
              <i class="fas fa-users"></i>
              <span>Permissions</span>
            </a>
            <ul class="nav nav-children">
              <li *ngIf="userService.hasUserPermissionForCompany(userPermissions.CompanyHost)"
                [routerLinkActive]="['link-active','nav-active']">
                <a [routerLink]="['/manage-hosts']">
                  <span>Company Hosts</span>
                </a>
              </li>
              <li
                *ngIf="userService.hasUserPermissionForCompany(userPermissions.SiteHost) && userService.hasCompanyFeature(ProductPlanFeature.SiteHosts)"
                [routerLinkActive]="['link-active','nav-active']">
                <a [routerLink]="['/site-users']">
                  <span>Site Hosts</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']" *ngIf="userService.isCompanyAdminUser()">
                <a [routerLink]="['/user-permissions']">
                  <span>Frequent Users</span>
                </a>
              </li>
              <li *ngIf="userService.isCompanyAdminUser()" [routerLinkActive]="['link-active','nav-active']">
                <a [routerLink]="['/employees']">
                  <span>Company Employees</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="isActiveAdvancedMenu && userService.isCompanyAdminUser()">
                <a [routerLink]="['/user-permissions/temporary']">
                  <span>Temporary Invites</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="isActiveAdvancedMenu && userService.isCompanyAdminUser()">
                <a [routerLink]="['/blocked-users']">
                  <span>Blocked Users</span>
                </a>
              </li> -->
          <!-- <li [routerLinkActive]="['link-active','nav-active']" *ngIf="userService.isCompanyAdminUser()">
        <a [routerLink]="['/business-permissions']">
          <span>Frequent Businesses</span>
        </a>
      </li> -->
          <!-- <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="isActiveAdvancedMenu && userService.hasUserPermissionForCompany(userPermissions.ManageViewTaggedUser) && userService.hasCompanyFeature(ProductPlanFeature.Automation)">
                <a [routerLink]="['/flagged-users']">
                  <span>Tagged Users</span>
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-parent" #report
            *ngIf="hasCompany && !isAdminMode && !isTemplateCompany && userService.hasUserPermissionForCompany(userPermissions.ViewReports)">
            <a class="nav-link" (click)="layoutService.toggleSubMenu($event,report)">
              <i class="fas fa-align-left" aria-hidden="true"></i>
              <span>Reports</span>
            </a>
            <ul class="nav nav-children">
              <li [routerLinkActive]="['link-active','nav-active']">
                <a [routerLink]="['/reports/site-attendance']">
                  <span>Live Attendance</span>
                </a>
              </li>
              <li *ngIf="canViewActivityReport == true" [routerLinkActive]="['link-active','nav-active']">
                <a [routerLink]="['/reports/site-activity/answers']">
                  <span>Activity Report</span>
                </a>
              </li> -->
          <!-- <li *ngIf="isSupplierCompany == true" [routerLinkActive]="['link-active','nav-active']">
                <a [routerLink]="['/reports/site-activity/supplier']">
                  <span>Supplier Activity</span>
                </a>
              </li> -->
          <!--
              <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="hasCompany && !isAdminMode && !isTemplateCompany && userService.hasCompanyFeature(ProductPlanFeature.Inventory) && userService.isCompanyAdminUser()">
                <a [routerLink]="['/reports/asset-report']">
                  <span>Asset Report</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']">
                <a [routerLink]="['/reports/card']">
                  <span>Card Report</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="hasCompany && !isAdminMode && !isTemplateCompany && userService.hasUserPermissionForCompany(userPermissions.ViewReports)">
                <a [routerLink]="['/reports/induction-report']">
                  <span>Induction Report</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="hasCompany && !isAdminMode && !isTemplateCompany && userService.hasUserPermissionForCompany(userPermissions.ViewReports) && userService.hasCompanyFeature(ProductPlanFeature.Form)">
                <a [routerLink]="['/reports/form-report']">
                  <span>Form Report</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="userService.hasCompanyFeature(ProductPlanFeature.SchaduleReport)">
                <a [routerLink]="['/reports/my-scheduled-reports']">
                  <span>My Scheduled Reports</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']" *ngIf="isActiveAdvancedMenu">
                <a [routerLink]="['/reports/user-activity/tracing']">
                  <span>Contact Tracing</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="hasCompany && !isAdminMode && !isTemplateCompany && userService.hasUserAnyOfPemissions([userPermissions.ApproveRejectSiteInduction])">
                <a [routerLink]="['/reports/pending-approval']">
                  <span>Approvals</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="hasCompany && !isAdminMode && !isTemplateCompany && userService.hasUserAnyOfPemissions([userPermissions.ApproveRejectSiteInduction])">
                <a [routerLink]="['/reports/permit-report']">
                  <span>Permits</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-parent" #host *ngIf="hasCompany && !isAdminMode && !isTemplateCompany">
            <a class="nav-link" (click)="layoutService.toggleSubMenu($event,host)">
              <i class="fas fa-user-cog"></i>
              <span>Host</span>
            </a>
            <ul class="nav nav-children">
              <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="hasCompany && !isAdminMode && !isTemplateCompany && userService.hasUserPermissionForCompany(userPermissions.ManualCheck)">
                <a [routerLink]="['/manual-check']">
                  <i class='fas fa-check'></i>
                  <span>Check-In/Out</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="!isTemplateCompany && userService.hasUserPermissionForCompany(userPermissions.SendPushNotification)">
                <a [routerLink]="['/host-push-notification']">
                  <i class="far fa-comment"></i>
                  <span>Send Push Notification</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']"
                *ngIf="userService.hasAdminPermissionOnAnySite() && userService.hasCompanyFeature(ProductPlanFeature.AccessControl)">
                <a [routerLink]="['/site-access-control-unit']">
                  <i class="fa fa-door-open"></i>
                  <span>Access Control Unit</span>
                </a>
              </li>
            </ul>
          </li>
          <li [routerLinkActive]="['link-active','nav-active']"
            *ngIf="isGod && isActiveAdvancedMenu && hasCompany && !isAdminMode && !isTemplateCompany && userService.isCompanyAdminUser()">
            <a [routerLink]="['/audit-log-report']">
              <i class="fas fa-user-secret" aria-hidden="true"></i>
              <span>Audit Logs</span>
            </a>
          </li>
          <li [routerLinkActive]="['link-active','nav-active']"
            *ngIf="false && hasCompany && !isAdminMode && !isTemplateCompany">
            <a [routerLink]="['/card-designer']">
              <i class="fas fa-id-card"></i>
              <span>Card Designer</span>
            </a>
          </li> -->
          <!-- <li *ngIf="viewAcademyLink && !isTemplateCompany" [routerLinkActive]="['link-active','nav-active']">
    <a [routerLink]="['/academy']">
      <i class="fas fa-tv"></i>
      <span>Video Tutorials</span>
    </a>
  </li> -->
          <!-- <li *ngIf="!isTemplateCompany">
            <a title="Support" href="https://support.1breadcrumb.com" target="support">
              <i class="fas fa-question"></i>
              <span>Support</span>
            </a>
          </li>

          <li class="nav-parent nav-expanded" #administration *ngIf="isGod && isAdminMode">
            <a class="nav-link" (click)="layoutService.toggleSubMenu($event,administration)">
              <i class="fas fa-crown"></i>
              <span>Administration</span>
            </a>
            <ul class="nav nav-children">
              <li [routerLinkActive]="['link-active','nav-active']">
                <a [routerLink]="['/manage-companies']">
                  <span>Company Management</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']">
                <a [routerLink]="['/manage-plans']">
                  <span>Plan Management</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']">
                <a [routerLink]="['/user-documents']">
                  <span>Document Types</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']">
                <a [routerLink]="['/manage-videos']">
                  <span>Videos</span>
                </a>
              </li>
              <li [routerLinkActive]="['link-active','nav-active']">
                <a [routerLink]="['/manage-industry-types']">
                  <span>Industry Types</span>
                </a>
              </li>
            </ul>
          </li>-->
          <li>
            <a>
              <span class="text-info d-flex align-items-center">
                <span style="font-size:14px;color:#bdbdbd">Menu Type</span>
                <span style="width:20px; margin-left:10px;">
                  <obc-switch [trueLabel]="'Advanced'" [falseLabel]="'Basic'" [small]="true"
                    [value]="isActiveAdvancedMenu" (switchChanged)="onAdvancedMenuChanged($event)"></obc-switch>
                </span>
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>

</aside>
<div class='navbar navbar-default navbar-fixed-left' *ngIf="false">
  <div class='navbar-header'>
    <button type='button' class='navbar-toggle' data-toggle='collapse' data-target='.navbar-collapse'>
      <span class='sr-only'>Toggle navigation</span>
      <span class='icon-bar'></span>
      <span class='icon-bar'></span>
      <span class='icon-bar'></span>
    </button>
    <a class='navbar-brand' [routerLink]="['/dahsboard']">Dashboard</a>
  </div>
  <div class='clearfix'></div>
  <div class='navbar-collapse collapse'>

  </div>
  <div class='clearfix'></div>
</div>
