<div>
  <obc-loader [show]="inProgress"></obc-loader>
  <obc-form-data-renderer #formDataRenderer
                          [formType]="FormType.SupplierForm"
                          [hashLink]="hashLink"
                          [supplierId]="supplier.supplierId"
                          [formId]="selectedForm.formId" [formName]="selectedForm.formName"
                          (validate)="validateForm($event)"></obc-form-data-renderer>
  <div class="card-footer">
    <div class="text-left mt-1">
      <button (click)="onSubmitForm()" [disabled]="!isFormValid"
              class="btn btn-success">
        Submit
      </button>
      <button (click)="onCancel()"
              class="ml-2 btn btn-secondary">
        Cancel
      </button>
    </div>
  </div>
</div>

