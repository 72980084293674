<div class="row">
    <div class="col-md-12">
        <div class="card">
            <header class="card-header">Procore Sync Monitoring</header>
            <obc-descriptor [text]="'You can view all settings of Procore company here.'" [closePanelName]="'procoreMonitoring'">
            </obc-descriptor>
            <div class="card-body">
                <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>
                    <ng-template obcGridTemplateName="companyTitle" let-item="data">
                        {{item?.companyTitle}} ({{item?.companyId}})
                    </ng-template>
                    <ng-template obcGridTemplateName="syncStatus" let-item="data">
                        <div style="padding: 0 10px;">
                            <div class="form-group" *ngIf="item?.procoreIntegrationStartDateTime">
                                Last Integration Start Date
                                <div class="text-info">
                                    {{item?.procoreIntegrationStartDateTime | datetime}}
                                    <span *ngIf="item?.isProcoreIntegrationInProgress">(In Progress)</span>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="item?.procoreIntegrationEndDateTime">
                                Last Integration End Date
                                <div class="text-info">
                                    {{item?.procoreIntegrationEndDateTime | datetime}}
                                </div>
                            </div>
                            <div class="form-group" *ngIf="item?.procoreIntegrationLastFailureDateTime">
                                Last Integration Error Date
                                <div class="text-danger">
                                    {{item?.procoreIntegrationLastFailureDateTime | datetime}}
                                    ({{item?.procoreIntegrationFailureCount}})
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template obcGridTemplateName="syncFilesStatus" let-item="data">
                        <div style="padding: 0 10px;">
                            <div class="form-group" *ngIf="item?.procoreExportFilesIntegrationStartDateTime">
                                Last Integration Start Date
                                <div class="text-info">
                                    {{item?.procoreExportFilesIntegrationStartDateTime | datetime}}
                                    <span *ngIf="item?.isProcoreExportFilesIntegrationInProgress">(In
                                        Progress)</span>
                                </div>
                            </div>
                            <div class="form-group " *ngIf="item?.procoreExportFilesIntegrationEndDateTime">
                                Last Integration End Date
                                <div class="text-info">
                                    {{item?.procoreExportFilesIntegrationEndDateTime | datetime}}
                                </div>
                            </div>
                            <div class="form-group " *ngIf="item?.procoreExportFilesIntegrationLastFailureDateTime">
                                Last Integration Error Date
                                <div class="text-danger">
                                    {{item?.procoreExportFilesIntegrationLastFailureDateTime | datetime}}
                                    ({{item?.procoreExportFilesIntegrationFailureCount}})
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template obcGridTemplateName="importSites" let-item="data">
                        <div style="padding: 0 10px;">
                            <div class="form-group row">
                                <div class="form-group form-check">
                                    <label class="form-check-label">
                                        <i
                                            class="{{item?.procoreImportSitesEnabled? 'fas fa-check-square text-success': 'far fa-square'}}"></i>
                                        Import Sites
                                        <div class="text-info"
                                            *ngIf="item?.procoreImportSitesEnabled == true && item?.procoreLastImportSites">

                                            <span class="sync-date">
                                                {{item?.procoreLastImportSites | datetime}}
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template obcGridTemplateName="importDirectories" let-item="data">
                        <div style="padding: 0 10px;">
                            <div class="form-group row">
                                <div class="form-group form-check">
                                    <label class="form-check-label">
                                        <i
                                            class="{{item?.procoreImportCompanyDirectoryEnabled? 'fas fa-check-square text-success': 'far fa-square'}}"></i>
                                        Import Company Directory
                                        <div class="text-info"
                                            *ngIf="item?.procoreImportCompanyDirectoryEnabled == true && item?.procoreLastImportCompanyDirectory">

                                            <span class="sync-date">
                                                {{item?.procoreLastImportCompanyDirectory | datetime}}
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="form-group form-check">
                                    <label class="form-check-label">
                                        <i
                                            class="{{item?.procoreImportProjectDirectoryEnabled? 'fas fa-check-square text-success': 'far fa-square'}}"></i>
                                        Import Project Directory
                                        <div class="text-info"
                                            *ngIf="item?.procoreImportProjectDirectoryEnabled == true && item?.procoreLastImportProjectDirectory">

                                            <span class="sync-date">
                                                {{item?.procoreLastImportProjectDirectory | datetime}}
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template obcGridTemplateName="importUsers" let-item="data">
                        <div style="padding: 0 10px;">
                            <div class="form-group row">
                                <div class="form-group form-check">
                                    <label class="form-check-label">
                                        <i
                                            class="{{item?.procoreImportUsersEnabled? 'fas fa-check-square text-success': 'far fa-square'}}"></i>
                                        Import Company Users
                                        <div class="text-info"
                                            *ngIf="item?.procoreImportUsersEnabled == true && item?.procoreLastImportUsers">

                                            <span class="sync-date">
                                                {{item?.procoreLastImportUsers | datetime}}
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template obcGridTemplateName="importRoles" let-item="data">
                        <div style="padding: 0 10px;">
                            <div class="form-group row">
                                <div class="form-group form-check">
                                    <label class="form-check-label">
                                        <i
                                            class="{{item?.procoreImportCompanyRolesEnabled? 'fas fa-check-square text-success': 'far fa-square'}}"></i>
                                        Import Company Roles
                                        <div class="text-info"
                                            *ngIf="item?.procoreImportCompanyRolesEnabled == true && item?.procoreImportCompanyRolesLastSyncDate">

                                            <span class="sync-date">
                                                {{item?.procoreImportCompanyRolesLastSyncDate | datetime}}
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="form-group form-check">
                                    <label class="form-check-label">
                                        <i
                                            class="{{item?.procoreImportProjectUserRolesEnabled? 'fas fa-check-square text-success': 'far fa-square'}}"></i>
                                        Import Project User Roles
                                        <div class="text-info"
                                            *ngIf="item?.procoreImportProjectUserRolesEnabled == true && item?.procoreImportProjectUserRolesLastSyncDate">

                                            <span class="sync-date">
                                                {{item?.procoreImportProjectUserRolesLastSyncDate | datetime}}
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template obcGridTemplateName="exportCheckinsAs" let-item="data">
                        <div style="padding: 0 10px;">
                            <div class="row">
                                <label>Timecard
                                    <label class="text-info">
                                        ({{item?.procoreExportSiteAttendanceAsTimeCardMode}})
                                    </label>
                                </label>
                            </div>
                            <div class="row">
                                <label>Visitor Log
                                    <label class="text-info">
                                        ({{item?.procoreExportSiteAttendanceAsVisitorLogMode}})
                                    </label>
                                </label>
                            </div>
                            <div class="row">
                                <label>Manpower Log
                                    <label class="text-info">
                                        ({{item?.procoreExportSiteAttendanceAsManpowerMode}})
                                    </label>
                                </label>
                            </div>
                            <label class="form-check-label" *ngIf="item?.procoreLastExportSiteAttendances">
                                <div class="text-info">
                                    <span class="sync-date">
                                        {{item?.procoreLastExportSiteAttendances | datetime}}
                                    </span>
                                </div>
                            </label>
                        </div>
                    </ng-template>
                    <ng-template obcGridTemplateName="exportDocuments" let-item="data">
                        <div style="padding: 0 10px;">
                            <!-- <div class="form-group row">
                                <label>
                                    Export Folder Template
                                    <span class="text-info">
                                        ({{item?.procoreFolderStructureMode}})
                                    </span>
                                </label>
                            </div> -->
                            <div class="form-group row">
                                <div class="form-group form-check">
                                    <label class="form-check-label">
                                        <i
                                            class="{{item?.procoreUploadSiteSupplierDocumentsEnabled? 'fas fa-check-square text-success': 'far fa-square'}}"></i>
                                        Export Supplier Documents
                                        <div class="text-info"
                                            *ngIf="item?.procoreUploadSiteSupplierDocumentsEnabled == true && item?.procoreLastUploadSiteSupplierDocuments">

                                            <span class="sync-date">
                                                {{item?.procoreLastUploadSiteSupplierDocuments | datetime}}
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="form-group form-check">
                                    <label class="form-check-label">
                                        <i
                                            class="{{item?.procoreUploadSiteInductionsEnabled? 'fas fa-check-square text-success': 'far fa-square'}}"></i>
                                        Export User Inductions
                                        <div class="text-info"
                                            *ngIf="item?.procoreUploadSiteInductionsEnabled == true && item?.procoreLastUploadSiteInductions">

                                            <span class="sync-date">
                                                {{item?.procoreLastUploadSiteInductions | datetime}}
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="form-group form-check">
                                    <label class="form-check-label">
                                        <i
                                            class="{{item?.procoreUploadPermitFormsEnabled? 'fas fa-check-square text-success': 'far fa-square'}}"></i>
                                        Export Permits
                                        <div class="text-info"
                                            *ngIf="item?.procoreUploadPermitFormsEnabled == true && item?.procoreLastUploadPermitForms">

                                            <span class="sync-date">
                                                {{item?.procoreLastUploadPermitForms | datetime}}
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="form-group form-check">
                                    <label class="form-check-label">
                                        <i
                                            class="{{item?.procoreUploadSiteAssetsFormEnabled? 'fas fa-check-square text-success': 'far fa-square'}}"></i>
                                        Export Site Assets
                                        <div class="text-info"
                                            *ngIf="item?.procoreUploadSiteAssetsFormEnabled == true && item?.procoreLastUploadSiteAssetForms">

                                            <span class="sync-date">
                                                {{item?.procoreLastUploadSiteAssetForms | datetime}}
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                              <div class="form-group row">
                                <div class="form-group form-check">
                                  <label class="form-check-label">
                                    <i
                                      class="{{item?.procoreUploadSiteBriefingsEnabled? 'fas fa-check-square text-success': 'far fa-square'}}"></i>
                                    Export Site Briefings
                                    <div class="text-info"
                                      *ngIf="item?.procoreUploadSiteBriefingsEnabled == true && item?.procoreLastUploadSiteBriefings">

                                      <span class="sync-date">
                                        {{item?.procoreLastUploadSiteBriefings | datetime}}
                                      </span>
                                    </div>
                                  </label>
                                </div>
                              </div>
                        </div>
                    </ng-template>
                </app-base-grid>
            </div>
        </div>
    </div>
</div>
