import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { VariableDefinitionMode, VariableDefinitionType, VariableValueDefinition } from 'src/app/models/workflow/variable-difinition';
import { WorkflowInfoViewModel } from 'src/app/models/workflow/workflow-info-viewmodel';

@Component({
  selector: 'obc-workflow-form-renderer',
  templateUrl: './workflow-form-renderer.component.html',
  styleUrls: ['./workflow-form-renderer.component.scss']
})
export class WorkflowFormRendererComponent implements OnInit {
  @Input() workflowModel: WorkflowInfoViewModel;
  currentDate: Date = new Date();
  selectedDate: Date;
  isPastDate: boolean = false;

  workflowVariables: VariableValueDefinition[];

  VariableDefinitionType = VariableDefinitionType;
  DatetimePickerMode = DatetimePickerMode;

  constructor() { }

  ngOnInit(): void {

    if (this.workflowModel?.currentActivity?.variableList?.length) {
      this.workflowVariables = this.workflowModel?.currentActivity?.variableList;
      for (let variable of this.workflowVariables) {
        this.workflowModel.variableFormGroup.registerControl(variable.key, new FormControl(variable.value, variable.mode == VariableDefinitionMode.Mandatory ? [Validators.required] : []));

        this.workflowModel.variableFormGroup.controls[variable.key].valueChanges.subscribe(_ => {
          var dateValue = this.workflowModel.variableFormGroup.controls[variable.key].value;
          this.selectedDate = (dateValue?.length ?? 0) ? null : new Date(dateValue);
          this.isPastDate = this.selectedDate && this.selectedDate < this.currentDate;
        });
      }
    }
  }
  checkVariableDateValueIsPast(variable: VariableValueDefinition) {
    return variable?.type == VariableDefinitionType.Date &&
      this.workflowModel?.variableFormGroup?.controls[variable.key]?.value &&
      this.isPastDate
  }
}
