import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RouteDataModel } from '../models/route-data-model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureGuardService {
  isTemplateCompany: boolean;

  constructor(private userService: UserService, private router: Router) {
    this.userService.currentCompany$.subscribe(res => this.isTemplateCompany = res?.isTemplate ?? false);

  }
  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    var routeData = route.data as RouteDataModel;
    if (!this.userService.hasCompanyFeature(routeData.feature)) {
      if (routeData.allowForTemplates == true && this.isTemplateCompany == true)
        return true;

      if (this.userService.info.value)
        this.router.navigate(['/no-access'], { queryParams: { returnPath: routerState.url } });
      return false;
    } else
      return true;
  }
}
