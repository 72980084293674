<div class="card">
  <div class="card-header">
    Confirm Change Mobile Number
    <button type="button" class="close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        Are you sure you want to change mobile number?
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        First Name: <b>{{selectedUser?.firstName == null? '-' : selectedUser?.firstName}}</b>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        Last Name: <b>{{selectedUser?.lastName == null? '-': selectedUser?.lastName}}</b>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        Email: <b>{{selectedUser?.email == null? '-': selectedUser?.email}}</b>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        Last Login Date: <b>{{selectedUser?.lastLoginDate == null? '-': selectedUser?.lastLoginDate | datetime}}</b>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        Current Mobile Number: <b>{{mobileModel.currentMobileNumber | mobile}}</b>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        New Mobile Number: <b>{{mobileModel.newMobileNumber | mobile}}</b>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 d-flex flex-row-reverse">
        <button class="btn btn-default" (click)="cancelModal()">Cancel</button>&nbsp;
        <button class="btn btn-danger" (click)="editMobileNumber()">Change Mobile</button>
      </div>
    </div>
  </div>
</div>