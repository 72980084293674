import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UserDocumentType } from 'src/app/models/user-document-type';
import { UserDocumentTypeService } from 'src/app/services/user-document-type.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { LayoutService } from 'src/app/services/layout.service';
import { DocumentMultiImageMode } from 'src/app/enums/document-multi-image-mode-enum';
import { OrderedEntity } from 'src/app/models/ordered-entity';
import { finalize } from 'rxjs/operators';
import { ModalService } from "../../services/modal.service";

@Component({
  selector: 'obc-user-document-types',
  templateUrl: './user-document-types.component.html',
  styleUrls: ['./user-document-types.component.css'],
})
export class UserDocumentTypesComponent implements OnInit {

  @ViewChild('addEditUserDocumentType') addEditUserDocumentType: TemplateRef<Element>;

  types: UserDocumentType[];
  documentMultiImageMode = DocumentMultiImageMode;
  nothingFound: boolean = true;
  errorMessage: string = null;
  inProgress: boolean = false;
  inAddEditProgress: boolean = false;

  formGroup: FormGroup = null;
  bsModalRef: BsModalRef;

  constructor(
    private bsModalService: BsModalService,
    private layoutService: LayoutService,
    private fb: FormBuilder,
    private userDocumentTypeService: UserDocumentTypeService,
    private modalService: ModalService,
  ) {}

  addDocTypeReplaySub;
  updateDocTypeReplaySub;
  deleteDocTypeReplaySub;
  ngOnInit() {
    this.layoutService.header = 'Document Types';

    this.addDocTypeReplaySub = this.userDocumentTypeService.addDocumentTypeReplay.asObservable().subscribe((res: UserDocumentType) => {
      if (res && this.types) {
        this.openModal(this.addEditUserDocumentType, null, res);
      }
    });
    this.updateDocTypeReplaySub = this.userDocumentTypeService.updateDocumentTypeReplay.asObservable().subscribe((res: UserDocumentType) => {
      if (res && this.types) {
        let parent = this.types?.find((item) => item.id == res.parentId);
        this.openModal(this.addEditUserDocumentType, res, parent);
      }
    });
    this.deleteDocTypeReplaySub = this.userDocumentTypeService.deleteDocumentTypeReplay.asObservable().subscribe((res: UserDocumentType) => {
      if (res && this.types) {
        this.modalService.confirm('Are you sure?', 'delete document type').subscribe(iamsure => {
          if (iamsure === true) {
            this.inProgress = true;
            this.userDocumentTypeService.delete(res?.id)
              .pipe(finalize(() => { this.inProgress = false; }))
              .subscribe(_ => {
                this.modalService.success('User document deleted successfully');
                this.getTypes();
              }, (err) => {
                this.modalService.error(err);
              });
          }
        });
      }
    });

    this.getTypes();
  }

  ngOnDestroy() {
    this.addDocTypeReplaySub.next();
    this.addDocTypeReplaySub.unsubscribe();
    this.updateDocTypeReplaySub.next();
    this.updateDocTypeReplaySub.unsubscribe();
    this.deleteDocTypeReplaySub.next();
    this.deleteDocTypeReplaySub.unsubscribe();
  }

  private getTypes() {
    this.inProgress = true;
    this.userDocumentTypeService.GetAllCompaniesUserDocumentTypes()
    .pipe(finalize(()=> this.inProgress = false))
    .subscribe((res) => {
      this.types = res;
      if (this.types.length > 0)
        this.nothingFound = false;
    });
  }

  selectedDocument: UserDocumentType;
  selectedDocumentParent: UserDocumentType;
  openModal(template, model: UserDocumentType = null, parent: UserDocumentType = null) {
    this.selectedDocumentParent = parent;
    this.selectedDocument = model;
    this.formGroup = this.fb.group({
      id: new FormControl(0),
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      isSelectable: new FormControl(false, [Validators.required]),
      parentId: new FormControl(parent?.id),
      enableTitle: new FormControl(false, [Validators.required]),
      enableExpireDate: new FormControl(false, [Validators.required]),
      multiImageMode: new FormControl(0, [Validators.required]),
    });
    if (model != null) {
      this.formGroup.get('id').setValue(model.id);
      this.formGroup.get('title').setValue(model.title);
      this.formGroup.get('enableTitle').setValue(model.enableTitle);
      this.formGroup.get('enableExpireDate').setValue(model.enableExpireDate);
      this.formGroup.get('multiImageMode').setValue(model.multiImageMode);
      this.formGroup.get('parentId').setValue(model.parentId);
      this.formGroup.get('isSelectable').setValue(model.isSelectable);
    }
    this.bsModalRef = this.bsModalService.show(template);
  }

  onOrderedList(orderedList: OrderedEntity[]) {
    this.inProgress = true;
    this.userDocumentTypeService.reorder(orderedList)
      .pipe(finalize(()=> this.inProgress = false))
      .subscribe(res=> {
        this.getTypes();
      })
  }

  onSubmit() {
    if (this.formGroup.valid) {
      var model = new UserDocumentType();
      model.id = this.formGroup.get('id').value;
      model.title = this.formGroup.get('title').value;
      model.enableTitle = this.formGroup.get('enableTitle').value;
      model.enableExpireDate = this.formGroup.get('enableExpireDate').value;
      model.multiImageMode = this.formGroup.get('multiImageMode').value;
      model.isSelectable = this.formGroup.get('isSelectable').value;
      model.parentId = this.formGroup.get('parentId').value;

      if(model.parentId && this.ignoreChildrenOfCurrentDocument(this.selectedDocumentParent)?.includes(model.parentId)) {
        this.modalService.error('The selected parent is currently one of the children of the document type');
        return;
      }

      this.inAddEditProgress = true;
      if (model.id > 0) {
        // edit
        this.userDocumentTypeService.edit(model).subscribe((res) => {
          var refModel = this.types.filter((f) => {
            return f.id == model.id;
          })[0];
          refModel.enableExpireDate = model.enableExpireDate;
          refModel.title = model.title;
          refModel.enableTitle = model.enableTitle;
          refModel.multiImageMode = model.multiImageMode;
          this.bsModalRef.hide();
          this.inAddEditProgress = false;
          this.getTypes();
        });

      } else {
        // new
        this.userDocumentTypeService.add(model).subscribe((res) => {
          model.id = res;
          this.types.push(model);
          this.bsModalRef.hide();
          this.inAddEditProgress = false;

          this.getTypes();
        });
      }
    }
  }

  ignoreChildrenOfCurrentDocument(value: UserDocumentType) {
    return this.userDocumentTypeService
        ?.getDocumentTypeChildren(value, this.types)
        ?.map((item) => item.id) ?? [];
  }
  onSelectedDocumentType(value: UserDocumentType) {
      this.formGroup.get('parentId').setValue(value?.id);
  }
}
