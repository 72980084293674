import { Pipe, PipeTransform } from '@angular/core';
import { ItemTitleValue } from '../models/helper-models';

@Pipe({
  name: 'itemValue'
})
export class ItemValuePipe implements PipeTransform {

  transform(list: ItemTitleValue[], val: any[]): unknown {
    try {
      var item = list.filter(c => val.indexOf(c.itemValue) !== -1);
      var titles = item.map(c => c.itemTitle);

      if (titles)
        return titles;
      return val;
    }
    catch{
      return val;
    }
  }

}
