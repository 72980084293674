import { Component, OnInit, Input } from '@angular/core';
import { QuestionRendererComponent } from '../question-renderer/question-renderer.component';
import { FormBuilder } from '@angular/forms';
import { SiteQuestionRendererViewModel, SiteQuestionItemFormControl } from 'src/app/models/site-question-viewmodel';
import { CacheService } from "../../../../services/cache.service";
import { QuestionParameter } from 'src/app/models/questionParameters/question-parameter';
import { TextQuestionParameter } from 'src/app/models/questionParameters/textQuestionParameter';
import { TextRenderMode } from 'src/app/models/questionParameters/text-render-mode-type';
import { uuid4 } from 'src/app/helpers/uuid';

@Component({
  selector: 'obc-text-question-renderer',
  templateUrl: './text-question-renderer.component.html',
  styleUrls: ['./text-question-renderer.component.scss']
})
export class TextQuestionRendererComponent extends QuestionRendererComponent implements OnInit {
  isRichTextQuestion: boolean;
  id:string;
  @Input() set question (model: SiteQuestionRendererViewModel){
    let answer = model.initialAnswers && model.initialAnswers.length > 0 ? model.initialAnswers[0]?.answer : null;
    this.model = model;
    this.formGroup = this.fb.group({
      value : new SiteQuestionItemFormControl(answer, null)
    });
    this.isRichTextQuestion = QuestionParameter.getParameter<TextQuestionParameter>(
      model.question.questionParameters
    ).textRenderMode == TextRenderMode.RichText;
    this.id = 'a' + uuid4();
  };

  constructor(private fb : FormBuilder, public cacheService: CacheService) {
      super(cacheService);
    }

  ngOnInit() {
    super.ngOnInit();
  }


}
