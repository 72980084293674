<obc-loader [show]="inProgress"></obc-loader>
<div *ngIf="formDetail && !noCard">
  <div class="card-header d-flex justify-content-between">
    <h5 class="">{{ formDetail.formName }}</h5>
  </div>
  <div class="card-body">
    <ng-container *ngTemplateOutlet="formDataTemplate">Form</ng-container>
  </div>
</div>

<ng-container *ngIf="formDetail && noCard">
  <ng-container *ngTemplateOutlet="formDataTemplate">Form</ng-container>
</ng-container>

<ng-template #formDataTemplate>
  <div class="d-flex justify-content-end" *ngIf="!loadFromWorkflow">
    <div>
      <button *ngIf="!isSupplierUser" type="button" class="btn btn-warning btn-sm pull-right"
        (click)="openFormHistoryModal(formHistory)">History</button>
      <obc-filled-form-edit *ngIf="formDetail?.isAllowedToEditForm == true" [filledFormDataId]="formDetail.formDataId"
        displayAsModal="true" (onUpdateForm)="onUpdateForm($event)" [classes]="'btn-sm mr-2 pull-right'">
      </obc-filled-form-edit>
    </div>
  </div>
  <div class="pb-3" *ngIf="formDetail.type == FormType.Permit || formDetail.type == FormType.AssetRegistration ||
formDetail.type == FormType.AssetOperator || formDetail.type == FormType.SupplierForm">

    <div class="row p-2" *ngIf="formDetail.siteName">
      <div class="col-12">
        <i class="fas fa-archway pr-2"></i>Site:
        <label class="text-primary">{{formDetail.siteName}}</label>
      </div>
    </div>
    <div class="row p-2" *ngIf="formDetail.type == FormType.Permit">
      <div class="col-12">
        <i class="fas fa-angle-right pr-2"></i>For:
        <label class="text-primary">{{formDetail.registerForTitle}}</label>
      </div>
    </div>

    <div class="row p-2" *ngIf="formDetail.registerByFullName">
      <div class="col-12">
        <i class="fas fa-user pr-2"></i>By:
        <label *ngIf="formDetail.registerByFullName" class="text-primary">{{formDetail.registerByFullName}}</label>
        <label *ngIf="formDetail.registerByMobile" class="text-primary ml-2">({{formDetail.registerByMobile |
          mobile}})</label>
      </div>
    </div>

    <div class="row p-2" *ngIf="formDetail.operatorRegistrationMode">
      <div class="col-12">
        <i class="fas fa-user pr-2"></i>Operators:
        <label class="text-primary">{{formDetail.operatorRegistrationMode | listOfOperatorRegistrationMode}}</label>
      </div>
    </div>

    <div class="row p-2" *ngIf="formDetail.type == FormType.Permit">
      <div class="col-12">
        <i class="fas fa-calendar pr-2"></i>Start Date:
        <label class="text-primary">{{formDetail.startDate | date}}</label>
        <ng-container *ngIf="showPendingControls">
          <i class="fas fa-edit pl-2" (click)="editDate(true)"></i>
          <div *ngIf="isNewStartDateAvailable" class="form-group p-2">
            <label class="bold text-danger">Select Start Date</label>
            <obc-input-date-time-picker [(ngModel)]="newStartDate" 
                                        [minDate]="formDetail?.minStartDate"
                                        [mode]="datetimePickerMode.Calender"
              (valueChange)="datePickerValueChage($event,true)">
            </obc-input-date-time-picker>
            <!-- <input [owlDateTime]="dt1" class="form-control" [owlDateTimeTrigger]="dt1" [(ngModel)]="newStartDate"
              (dateTimeChange)="datePickerValueChage($event.value,true)">
            <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time> -->
          </div>
        </ng-container>
      </div>
    </div>

    <div class="row p-2"
      *ngIf="(formDetail.isEnableFormExpiryDate || formDetail.type == FormType.Permit) && (showPendingControls || formDetail.endDate != null)">
      <div class="col-12">
        <ng-container *ngIf="showPendingControls || formDetail.endDate != null">
          <i class="fas fa-calendar pr-2"></i>End Date:
          <label class="text-primary">{{formDetail.endDate | date}}</label>
        </ng-container>
        <ng-container *ngIf="showPendingControls">
          <i class="fas fa-edit pl-2" (click)="editDate(false)"></i>
          <div *ngIf="isNewEndDateAvailable" class="form-group p-2">
            <label class="bold text-danger">Select End Date</label>
            <obc-input-date-time-picker [(ngModel)]="newEndDate"
                                        [minDate]="formDetail?.minStartDate"
                                        [mode]="datetimePickerMode.Calender"
              (valueChange)="datePickerValueChage($event,false)">
            </obc-input-date-time-picker>
            <!-- <input [owlDateTime]="dt2" class="form-control" [owlDateTimeTrigger]="dt2" [(ngModel)]="newEndDate"
              (dateTimeChange)="datePickerValueChage($event.value,false)">
            <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time> -->
          </div>
        </ng-container>
      </div>
    </div>
    <div class="row p-2">
      <div class="col-12">
        <i class="fas fa-clock pr-2"></i>
        <span *ngIf="formDetail.type == FormType.AssetRegistration">Asset Registration Form Submitted On: </span>
        <span *ngIf="formDetail.type != FormType.AssetRegistration">Submit Date: </span>
        <label class="text-primary">{{formDetail.fillDateTime | datetime}}</label>
      </div>
    </div>
    <ng-container *ngIf="formDetail.type == FormType.Permit">
      <div class="row p-2" *ngIf="formDetail.zoneName">
        <div class="col-12">
          <i class="fas fa-building pr-2"></i>Exclusive Access:
          <label class="text-primary">{{formDetail.zoneName}}</label>
        </div>
      </div>
      <div class="row p-2" *ngIf="formDetail.schedules && formDetail.schedules.length > 0">
        <div class="col-12">
          <i class="fas fa-business-time pr-2"></i>Schedules:
          <ul>
            <li *ngFor="let item of formDetail.schedules">
              <span *ngIf="item.weekDay">{{item.weekDay | numberToWeekday}}</span>
              <span *ngIf="item.dateTime">{{item.dateTime | date}}</span>
              <span *ngIf="item.fromHour || item.toHour"> ({{item.fromHour | time}} - {{item.toHour | time}})</span>
            </li>
          </ul>
        </div>
      </div>
    </ng-container>

    <div *ngIf="showPendingControls" class="row p-2 mt-2">
      <div class="col-12">
        <i class="fas fa-pen pr-1"></i>
        <span class="pr-2">Review comments:</span>
        <textarea rows="5" class="form-control mt-1" placeholder="Enter review comments ..."
          [formControl]="description"></textarea>
      </div>
    </div>
    <div *ngIf="!showPendingControls && formDetail.description && formDetail.description.length > 0" class="row p-2">
      <div class="col-12">
        <span class="pr-2">Review comments:</span>
        <label class="text-primary">{{formDetail.description}}</label>
      </div>
    </div>
    <div class="row p-1" *ngIf="!showPendingControls && formDetail.permitStatus">
      <div class="col-12">
        <i class="{{formDetail.status | permitStatusIcon}}"></i>
        <span class="{{formDetail.status  | permitStatusColor}} ml-1">{{formDetail.status |
          permitStatusTitle}}:</span>
        <span *ngIf="formDetail.permitStatus?.mobile">
          {{formDetail.permitStatus?.fullName}}
          ({{formDetail.permitStatus?.mobile | mobile}})
        </span>
      </div>
    </div>
    <div class="row p-1"
      *ngIf="!showPendingControls && formDetail.entityStatusInfo && formDetail.type != FormType.Permit">
      <div class="col-12">
        <i class="{{formDetail.entityStatusInfo?.approveStatus | filledFormEntityStatusIcon}}"></i>
        <span
          class="{{formDetail.entityStatusInfo?.approveStatus  | filledFormEntityStatusColor}} ml-1">{{formDetail.entityStatusInfo?.approveStatus
          |
          siteAssetRequestApproveStatus}}:</span>
        <span *ngIf="formDetail.entityStatusInfo?.mobile">
          {{formDetail.entityStatusInfo?.fullName}}
          ({{formDetail.entityStatusInfo?.mobile | mobile}})
        </span>
      </div>
    </div>
    <ng-container *ngIf="showPendingControls">
      <div class="row">
        <div class="col-12">
          <button class="btn btn-danger ml-2 pull-right" (click)="approveReject(false)">Reject
          </button>
          <button [disabled]="!isValid()" class="btn btn-success pull-right" (click)="approveReject(true)">Approve
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <accordion>
    <!-- Question(s) -->
    <accordion-group heading="Question(s) [{{formDetail.questionAnswers?.length}}]" [isOpen]="true">
      <obc-question-list-answer-renderer [answers]="formDetail.questionAnswers"></obc-question-list-answer-renderer>
    </accordion-group>

    <!-- Document(s) -->
    <accordion-group *ngIf="formDetail.documents?.length" heading="Document(s) [{{formDetail.documents?.length}}]"
      class="mt-3">
      <ul class="simple-post-list">
        <li class="d-flex flex-column justify-content-around" *ngFor="let document of formDetail.documents">
          <obc-document-answer-renderer [document]="document"></obc-document-answer-renderer>
        </li>
      </ul>
    </accordion-group>


    <!-- Announcement(s) -->
    <accordion-group *ngIf="formDetail.announcements?.length" heading="Announcement(s) [{{formDetail.announcements?.length}}]"
      class="mt-3">
      <ul class="simple-post-list">
        <li class="d-flex flex-column justify-content-around">
          <obc-announcement-renderer [announcements]="formDetail.announcements">
          </obc-announcement-renderer>          
          <!-- [anonymousAttendanceKey]="status.siteAttendance.anonymousCheckInKey" [notifyVisits]="true"
          [isSupplierAnnouncement]="true" -->
        </li>
      </ul>
    </accordion-group>
  </accordion>
</ng-template>

<ng-template #formHistory>
  <obc-form-history [formDataId]="formDetail.formDataId"></obc-form-history>
</ng-template>
