import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToolCategoryViewModel } from "../../../models/inventory/tool-category-model";
import { ToolDynamicFieldViewModel } from "../../../models/inventory/tool-dynamic-field-model";
import { ToolDynamicFieldType } from "../../../enums/tool-dynamic-field-type.enum";
import { FormControl, Validators } from "@angular/forms";
import { TextOperator } from "../../../enums/text-operator.enum";
import { ModalService } from "../../../services/modal.service";
import { DatetimePickerMode } from "../../../enums/datetime-picker-mode.enum";
import { InventoryDynamicFieldValues } from "../../../models/inventory/inventory-dynamic-field-values";
import { finalize } from "rxjs/operators";
import { InventoryService } from "../../../services/inventory.service";
import { DynamicFieldFilterWrapperViewModel } from "../../../models/inventory/dynamic-field-filter-model";
import { QuestionType } from "../../../enums/question-type";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: 'obc-dynamic-fields-filter',
  templateUrl: './dynamic-fields-filter.component.html',
  styleUrls: ['./dynamic-fields-filter.component.scss']
})
export class DynamicFieldsFilterComponent implements OnInit {

  @Input() set filters(value: ToolDynamicFieldViewModel[]) {
    if(!this.isAssignMode) {
      this.filterDynamicFields = [];
      if (value?.length)
        this.filterDynamicFields.push(...value);
    }
    if(this.isAssignMode) {
      this.filterAssignDynamicFields = [];
      if (value?.length)
        this.filterAssignDynamicFields.push(...value);
    }
  }
  @Input() isAssignMode: boolean = false;

  toolCategories: ToolCategoryViewModel[] = [];
  selectedCategory: ToolCategoryViewModel;
  selectedDynamicField: ToolDynamicFieldViewModel;
  selectedAssignDynamicField: ToolDynamicFieldViewModel;
  filterDynamicFields: ToolDynamicFieldViewModel[] = [];
  filterAssignDynamicFields: ToolDynamicFieldViewModel[] = [];
  DynamicFieldType = ToolDynamicFieldType;
  TextOperator = TextOperator;
  DatetimePickerMode = DatetimePickerMode;
  filterModel: DynamicFieldFilterWrapperViewModel[] = [];
  inProgress: boolean = false;

  @Output() public onClose = new EventEmitter<ToolDynamicFieldViewModel[]>();
  @Output('filters') outputFilters = new EventEmitter<DynamicFieldFilterWrapperViewModel[]>();
  @Output('refreshGrid') refreshGridEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private modalService: ModalService,
              private inventoryService: InventoryService,
              private bsModalService: BsModalService,
              ) { }

  ngOnInit(): void {
    this.inventoryService
      .getToolCategories()
      .pipe(
        finalize(() => {
          this.inProgress = false;
        })
      )
      .subscribe({
          next: (res) => {
            this.toolCategories = res.data;
            if (this.toolCategories.length > 0) {
              this.selectedCategory = this.toolCategories[0];
            }
          },
          error: (err) => {
            this.modalService.error(err);
          }
        }
      );
  }

  modalRef: BsModalRef;
  openCategoriesFilterModal(template) {
    this.selectFirstFieldsOfSelectedCategory();
    this.modalRef = this.bsModalService.show(template, {
        ignoreBackdropClick: true,
        class: "modal-lg",
      }
    );
    this.modalRef?.onHide.subscribe((res) => {
      this.onCloseMethod();
    })
  }

  selectFirstFieldsOfSelectedCategory() {
    this.selectedDynamicField = this.selectedCategory?.toolDynamicFields?.find((x: ToolDynamicFieldViewModel) => x.type != ToolDynamicFieldType.File);
    this.selectedAssignDynamicField =
      this.selectedCategory?.toolAssignDynamicFields?.find((x: ToolDynamicFieldViewModel) => x.type != ToolDynamicFieldType.File);
  }

  getFilterModel() {
    this.filterModel = [];
    return {
      assetDynamicFieldFilters: this._innerGetFilterModel(),
      assignDynamicFieldFilters: this._innerGetFilterModel(true),
    };
  }
  _innerGetFilterModel(isAssign: boolean = false, pushInFiltermodel: boolean = true): DynamicFieldFilterWrapperViewModel[] {
    let model = [];
    let filterFields;
    if (isAssign) filterFields = this.filterAssignDynamicFields;
    else filterFields = this.filterDynamicFields;

    for (let index = 0; index < filterFields.length; index++) {
      const _dynamicField = filterFields[index];
      let obj = {
        dynamicFieldId: _dynamicField.id,
        value: _dynamicField.formControl.value ?? '',
        operator:
          _dynamicField.operatorFormControl.value ?? TextOperator.contains,
        type: _dynamicField.type,
      };
      model.push(obj);
      if (pushInFiltermodel == true)
        this.filterModel.push({ ...obj, dynamicField: _dynamicField });
    }
    return model;
  }

  onCategoryChange() {
    this.selectFirstFieldsOfSelectedCategory();
    this.filterDynamicFields = [];
    this.filterAssignDynamicFields = [];
  }
  filterFunction(toolDynamicFieldViewModel: ToolDynamicFieldViewModel[]) {
    return toolDynamicFieldViewModel?.filter(df => df.type != ToolDynamicFieldType.File);
  }
  onAddDynamicFieldToFilters() {
    if (!this.selectedCategory) return;
    if (
      this.filterDynamicFields.some((x) => x.id == this.selectedDynamicField.id || x.type == ToolDynamicFieldType.File)
    )
      return;

    if (this.selectedDynamicField?.type == ToolDynamicFieldType.File)
      return;


    let validators = [Validators.required];
    if(this.selectedDynamicField?.type == ToolDynamicFieldType.Number) {
      validators.push(Validators.pattern("^[0-9]*$"))
    }

    this.selectedDynamicField.formControl = new FormControl(null, validators);
    this.selectedDynamicField.operatorFormControl = new FormControl(
      TextOperator.contains
    );
    this.filterDynamicFields.push(this.selectedDynamicField);
  }
  onAddAssignDynamicFieldToFilters() {
    if (!this.selectedCategory) return;
    if (
      this.filterAssignDynamicFields.some(
        (x) => x.id == this.selectedAssignDynamicField.id
      )
    )
      return;

    if (this.selectedDynamicField?.type == ToolDynamicFieldType.File)
      return;

    this.selectedAssignDynamicField.formControl = new FormControl(null, [Validators.required]);
    this.selectedAssignDynamicField.operatorFormControl = new FormControl(
      TextOperator.contains
    );
    this.filterAssignDynamicFields.push(this.selectedAssignDynamicField);
  }
  onRemoveDynamicFieldFromFilter(dynamicField: any) {
    let index = this.filterAssignDynamicFields.indexOf(dynamicField);
    if (index >= 0) {
      this.filterAssignDynamicFields.splice(index, 1);
    }
    index = this.filterDynamicFields.indexOf(dynamicField);
    if (index >= 0) {
      this.filterDynamicFields.splice(index, 1);
    }
  }
  filterOperators(operator: string, questionType: QuestionType) {
    return questionType == QuestionType.Text || TextOperator[operator] != TextOperator.contains;
  }
  hasOperand(operator: TextOperator): boolean {
    return [TextOperator.isNull, TextOperator.isNotNull].indexOf(
      operator
    ) == -1
  }
  onCloseProcess() {
    this.getFilterModel();
    this.modalRef.hide();

    this.outputFilters.emit( this.filterModel );
    this.onClose.emit( this.isAssignMode ? this.filterAssignDynamicFields : this.filterDynamicFields );
  }
  showImages(field: InventoryDynamicFieldValues) {
    return (
      field.value &&
      (field.value?.length ?? 0) > 0 &&
      field.type == ToolDynamicFieldType.File
    );
  }
  showText(field: InventoryDynamicFieldValues) {
    return (
      field.value &&
      (field.value?.length ?? 0) > 0 &&
      (field.type == ToolDynamicFieldType.Number ||
        field.type == ToolDynamicFieldType.Text)
    );
  }
  isImage(field: ToolDynamicFieldViewModel) {
    return field.type == ToolDynamicFieldType.File;
  }
  showDate(field: InventoryDynamicFieldValues) {
    return field.value && (field.value?.length ?? 0) > 0 && field.type == ToolDynamicFieldType.Date;
  }

  refreshGrid() {
    this.refreshGridEmitter.emit(true);
  }

  isInvalidFormControlValue(assignDynamicField: ToolDynamicFieldViewModel) {
    return !assignDynamicField.formControl?.value || assignDynamicField.formControl?.value == '' || assignDynamicField.formControl?.value == 'Invalid Date';
  }

  onCloseMethod(ignoreInvalidForm: boolean = false) {
    if(this.canCloseModal()) {
      this.getFilterModel();
      this.modalRef.hide();

      this.outputFilters.emit( this.filterModel );
      this.onClose.emit( this.isAssignMode ? this.filterAssignDynamicFields : this.filterDynamicFields );
    }
  }

  canCloseModal() {
    let items = this.isAssignMode ? this.filterAssignDynamicFields : this.filterDynamicFields;
    let anyInvalidItemAvailable = items?.filter((item) => {
      return item.operatorFormControl?.value != TextOperator.isNull && item.operatorFormControl?.value != TextOperator.isNotNull
    })?.find((item: ToolDynamicFieldViewModel) => {
      return (!item.formControl?.value || item.formControl?.value == 'Invalid Date')
    })
    return !anyInvalidItemAvailable;
  }

}
