import { Pipe, PipeTransform } from '@angular/core';
import { InductionAskMode } from '../enums/induction-ask-mode.enum';

@Pipe({
  name: 'inductionAskMode'
})
export class InductionAskModePipe implements PipeTransform {

  transform(value: any): string {
    if (value == InductionAskMode.AnyTime) return "Any Time";
    if (value == InductionAskMode.CheckIn) return "Check In";
    if (value == InductionAskMode.PreCheckIn) return "Pre Check In";
    if (value == InductionAskMode.OffSite) return "Offsite";
    return value?.toString() ?? "?";
  }

}
