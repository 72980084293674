import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GlobalItemType } from 'src/app/enums/global-item-type.enum';
import { GlobalViewModel } from 'src/app/models/global-viewmodel';
import { SearchGlobalToolDynamicFieldsQueryModel } from 'src/app/models/inventory/search-tools-query-model';
import { InventoryService } from 'src/app/services/inventory.service';

@Component({
  selector: 'obc-item-selector',
  templateUrl: './item-selector.component.html',
  styleUrls: ['./item-selector.component.scss']
})
export class ItemSelectorComponent implements OnInit {

  @Input() itemType: GlobalItemType;
  @Input() options: any;


  @Input() maxItems = 10;
  @Input() valueListToHide: number[] = [];
  @Input() notSelectedLabel: string = null;

  _required = false;
  @Input() set required(value: boolean) {
    if (value) {
      this._required = true;
      this.formControl.setValidators(Validators.required);
    }
  };

  @Output() onItemSelect = new EventEmitter<any>();
  public selectedItem = new BehaviorSubject<any>(null);

  _itemId: number = null;
  @Input() set itemId(value: number) {
    if (!value) return;
    this._itemId = value;
    this.formControl.setValue(value);
  };


  itemList: any[];
  items$: Observable<any[]>;
  term$ = new BehaviorSubject<string>(null)
  formControl = new FormControl(null, [Validators.required]);
  loading: boolean = false;

  constructor(
    private inventoryService: InventoryService,
    //private siteAssetService: SiteAssetService,
  ) { }


  ngOnInit() {
    this.formControl.valueChanges.subscribe(res => {
      if (this.itemList) {
        if (res == null || res == 0) {
          this.onItemSelect.emit(null);
          this.selectedItem.next(null);
        } else {
          var item = this.itemList.find(x => this.getValue(x) == res);
          if (item == null) {
          } else {
            this.onItemSelect.emit(item);
            this.selectedItem.next(item);
          }
        }
      }
    });

    this.term$.subscribe(term => {
      term = term ?? '';
      if (this.reachToEndTerm != term) {
        this.getItemList(term);
      }
      else {
        this.items$ = of(this.getNthItems(this.itemList, this.reachToEnd ? this.itemList.length : this.maxItems))
      }
    });

    setTimeout(() => {
      this.getItemList();
    }, 250);
  }

  getItemLabelKey(): string {
    switch (this.itemType) {
      case GlobalItemType.GlobalAssetField:
        return 'fieldName';
      default:
        return 'title';
    }
  }

  getItemValueKey(): string {
    switch (this.itemType) {
      case GlobalItemType.GlobalAssetField:
        return 'id';
      default:
        return 'id';
    }
  }

  getItemLabel(item: any) {
    return item[this.getItemLabelKey()];
  }

  getValue(item: any) {
    return item[this.getItemValueKey()];
  }

  getItemList(term: string = '') {
    this.loading = true;
    switch (this.itemType) {
      case GlobalItemType.GlobalAssetField:
        let globalToolDynamicRequest = { usage: this.options.usage, searchTerm: term } as SearchGlobalToolDynamicFieldsQueryModel;
        this.inventoryService.getGlobalToolDynamicFields(globalToolDynamicRequest)
          .pipe(finalize(() => this.loading = false))
          .subscribe(res => {
            this.reachToEndTerm == term;
            this.reachToEnd = false;
            this.itemList = this.filterExcludes(res);
            this.items$ = of(this.getNthItems(this.itemList, this.itemList.length));
          });
    }
  }

  private filterExcludes(res: any[]) {
    let that = this;
    return res.filter(function (el) {
      return that.valueListToHide.filter(function (selected_el) {
        return selected_el == this.getValue(el);
      }).length == 0
    });
  }

  getNthItems(array: GlobalViewModel[], n: number): GlobalViewModel[] {
    if (!array || array.length == 0 || n <= 0) return [];
    var max = Math.min(array.length, n);
    var arr = [];
    for (var i = 0; i < max; i++)
      arr.push(array[i]);
    if (this.selectedItem.value && !arr.find(x => this.getValue(x) == this.getValue(this.selectedItem.value)))
      arr.push(this.selectedItem.value);
    return arr;
  }

  searchAssets(term: string) {
    return this.itemList.filter((item) => {
      return this.isMatched(term, item);
    });
  }

  clearInput() {
    this.formControl.setValue(null);
  }

  isMatched(term: string, item: any): boolean {
    if (!term) return true;
    term = term.toLowerCase();
    return (this.getItemLabel(item) && this.getItemLabel(item).toLowerCase().indexOf(term) > -1);
  }

  reset() {
    this.formControl.reset();
    this.itemList = [];
  }

  reachToEnd = false;
  reachToEndTerm = "";
  onScrollToEnd() {
    this.reachToEnd = true;
    this.reachToEndTerm = this.term$.value;
    this.term$.next(this.term$.value)
  }

}
