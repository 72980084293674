import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { LayoutService } from 'src/app/services/layout.service';
import { SiteService } from 'src/app/services/site.service';
import { SiteViewModel } from 'src/app/models/site-viewmodel';

@Component({
  selector: 'obc-site-automations',
  templateUrl: './site-automations.component.html',
  styleUrls: ['./site-automations.component.css']
})
export class SiteAutomationsComponent implements OnInit, OnDestroy {
  siteId: number = null;
  site: SiteViewModel = null;
  inProgress: boolean = false;
  inProgress$: Subject<boolean> = new Subject();
  private subscription: any;
  constructor(private route: ActivatedRoute, private layoutService: LayoutService, private siteService: SiteService) { }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.layoutService.header = "Site Automations";

    this.subscription = this.route.params.subscribe((params: Params) => {
      this.siteId = params['id'] ? +params['id'] : 0;
      this.inProgress = true;
      this.siteService.getById(this.siteId).subscribe(res => {
        this.site = res;
        this.inProgress = false;
      })
    });
    this.inProgress$.subscribe(res => {
      setTimeout(() => {
        this.inProgress = res
      }, 1)
    });
  }
}
