import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { QuestionAnswerFileFormat } from 'src/app/models/questionParameters/question-answer-file-format';
import { ItemTitleValue } from 'src/app/models/helper-models';
import { UserDocumentType } from "../../../models/user-document-type";
import { finalize } from "rxjs/operators";
import { UserDocumentTypeService } from "../../../services/user-document-type.service";
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Component({
  selector: 'obc-question-file',
  templateUrl: './question-file.component.html',
  styleUrls: ['./question-file.component.scss']
})
export class QuestionFileComponent implements OnInit {
  QuestionAnswerFileFormat = QuestionAnswerFileFormat;
  _questionParam: any;
  _required = true;
  loading: boolean = false;
  genericFileDocumentTypeSelector: boolean = true;

  constructor() { }

  _documentTypeIdList = [];
  @Input() set documentTypeIdList(value: number[]) {
    if (value) {
      this._documentTypeIdList = value;
    }
  }
  get documentTypeIdList() {
    return this._documentTypeIdList;
  }


  @Input() set questionParamInput(_questionParamInput: any) {
    this._questionParam = _questionParamInput;
  }

  @Output('selectedDocumentTypes') documentTypesListEmitter: EventEmitter<UserDocumentType[]> = new EventEmitter<UserDocumentType[]>();
  @Output() isGenericDocumentType: EventEmitter<boolean> = new EventEmitter<boolean>();

  get fileTypes(): ItemTitleValue[] {
    return [
      new ItemTitleValue('Image', QuestionAnswerFileFormat.Image.toString()),
      // new ItemTitleValue('PDF', QuestionAnswerFileFormat.PDF.toString()),
      // new ItemTitleValue('Excell', QuestionAnswerFileFormat.Excell.toString()),
    ];
  }

  @ViewChild('documentTypeSelector') documentTypeSelector;

  ngOnInit(): void {
    if (this._documentTypeIdList?.length > 0) {
      this.genericFileDocumentTypeSelector = false;
      this.isGenericDocumentType.emit(false);
    } else {
      this.isGenericDocumentType.emit(true);
    }
    if (this._questionParam['fileType'] == undefined) {
      this._questionParam['fileType'] = QuestionAnswerFileFormat.AnyFile;
    }
    if (!this.documentTypeIdList) {
      this.documentTypeIdList = [];
    }
  }

  selectedDocumentTypes: UserDocumentType[] = [];
  onSelectedDocumentTypes(documentTypes: UserDocumentType[]) {
    if (!this.genericFileDocumentTypeSelector) {
      // if(documentTypes?.length > 0) {
      this.selectedDocumentTypes = documentTypes;
      this.documentTypesListEmitter.emit(
        documentTypes
      );
      // }
    }
  }

  onSelectGenericFileDocumentTypeSelector() {
    this.isGenericDocumentType.emit(this.genericFileDocumentTypeSelector);
  }

}
