import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { e168Validator, e168Validator2 } from 'src/app/helpers/mobile-format-validator';
import { CompanyHostModel } from 'src/app/models/quick-setup-models/company-host';

@Component({
  selector: 'obc-additional-company-host',
  templateUrl: './additional-company-host.component.html',
  styleUrls: ['./additional-company-host.component.scss']
})
export class AdditionalCompanyHostComponent implements OnInit {

  ctrl: FormGroup;
  hosts = new FormArray([]);
  constructor() { }

  ngOnInit() {
    this.initFormGroup();
  }

  initFormGroup() {
    this.ctrl = new FormGroup({
      mobile: new FormControl(null, [e168Validator2]),
      firstName: new FormControl(null),
      lastName: new FormControl(null)
    })
  }

  onAdd() {
    this.hosts.controls.unshift(this.ctrl);
    this.initFormGroup();
  }

  onRemove(ctrl: AbstractControl) {
    this.hosts.controls.splice(this.hosts.controls.indexOf(ctrl), 1);
  }

  getHosts() : CompanyHostModel[] {
    var ret = [];
    for (let index = 0; index < this.hosts.controls.length; index++) {
      const element = this.hosts.controls[index];
      ret.push(element.value);
    }
    return ret;
  }

  toFormGroup(ctrl: AbstractControl): FormGroup {
    return ctrl as FormGroup;
  }

  get valid(): boolean {
    for (let index = 0; index < this.hosts.controls.length; index++) {
      const element = this.hosts.controls[index];
      if(!element.valid) return false;
    }
    return true;
  }

}
