import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';

import { SiteAttendanceDisputeType } from 'src/app/enums/site-attendance-dispute-type.enum';
import { SiteAttendanceDisputeModel } from 'src/app/models/site-attendance-dispute-model';
import { UserContactInformation } from 'src/app/models/user-profile-viewmodel';
import { DisputeSiteAttendanceService } from 'src/app/services/dispute-site-attendance.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-dispute-attendance',
  templateUrl: './dispute-attendance.component.html',
  styleUrls: ['./dispute-attendance.component.scss']
})
export class DisputeAttendanceComponent implements OnInit {
  SiteAttendanceDisputeType = SiteAttendanceDisputeType;
  selectedType: SiteAttendanceDisputeType;
  _userInformation: UserContactInformation;
  _siteAttendanceId: number = null;
  returnModel: SiteAttendanceDisputeModel = {};

  @Input() set userInformation(value: UserContactInformation) {
    this._userInformation = value;
  };
  get userInformation(): UserContactInformation {
    return this._userInformation;
  };

  @Input() set siteAttendanceId(value: number) {
    this._siteAttendanceId = value;
    this.returnModel.siteAttendanceId = value;
  };

  @Output() updateAttachment = new EventEmitter();

  constructor(
    private disputeSiteAttendanceService: DisputeSiteAttendanceService,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
  }

  get hasCheckOutDate() {
    return this.userInformation?.checkOutDate != null;
  }

  onDispute() {
    // TODO: VALIDATE!!!

    this.disputeSiteAttendanceService.dispute(this.returnModel).subscribe(res => {
      this.updateAttachment.emit(res);
    }, err => {
      this.modalService.error(err)
    })
  }

  datePickerValueChage($event: Date, isCheckInDate: boolean) {
    if (isCheckInDate)
      this.returnModel.newCheckInDateValue = $event;
    else
      this.returnModel.newCheckOutDateValue = $event;
  }

  isFormValid() {
    return this.returnModel.siteAttendanceId &&
      (
        (this.returnModel.newCheckInDateValue &&
          !this.isInvalidOrEqualDate(this.returnModel.newCheckInDateValue, this.userInformation.checkInDate)) ||
        (this.returnModel.newCheckOutDateValue &&
          !this.isInvalidOrEqualDate(this.returnModel.newCheckOutDateValue, this.userInformation.checkOutDate)));
  }

  onChangeMode(isCheckIn) {
    if (isCheckIn) {
      if (!this.returnModel.newCheckInDateValue)
        this.returnModel.newCheckInDateValue = this.userInformation.checkInDate;
      if (this.isInvalidOrEqualDate(this.returnModel.newCheckOutDateValue, this.userInformation.checkOutDate))
        this.returnModel.newCheckOutDateValue = null;
    }
    else {
      if (!this.returnModel.newCheckOutDateValue)
        this.returnModel.newCheckOutDateValue = this.userInformation.checkOutDate;
      if (this.isInvalidOrEqualDate(this.returnModel.newCheckInDateValue, this.userInformation.checkInDate))
        this.returnModel.newCheckInDateValue = null;
    }
  }

  isInvalidOrEqualDate(date1, date2): boolean {
    if (date1 == null || date2 == null || date1 == "Invalid Date" || date2 == "Invalid Date")
      return true;

    if (typeof date1 === 'string')
      date1 = new Date(Date.parse(date1));

    if (typeof date2 === 'string')
      date2 = new Date(Date.parse(date2));

    return Math.floor(date1.setSeconds(0) / 1000) == Math.floor(date2.setSeconds(0) / 1000);

  }

}
