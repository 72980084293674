<accordion-group>
  <div accordion-heading>
    <obc-accordion-header [isAccordionDirty]="dirtyComponents?.announcements?.isDirty" [mainTitle]="'Announcements'"
      [icon]="'fa fa-bullhorn'" [iconColor]="'#777ccd'" [info]="[{'title': '', 'value': headerInfo}]">
    </obc-accordion-header>
  </div>
  <div>
    <div *ngIf="switchview == 'list'" class="row">
      <div class="col-md-12">
        <button type="button" class="btn btn-sm btn-info pull-right" (click)="initAddEditSiteAnouncement('add');"
          *ngIf="canAddNewAnnouncement">
          <i class="fa fa-plus"></i> Add New
          Announcement
        </button>
        <div class="alert alert-warning" *ngIf="!canAddNewAnnouncement">
          You can't add more announcement to site!
        </div>
      </div>
    </div>
    <obc-loader [show]="progress"></obc-loader>
    <div class="row">
      <div class="col-md-12">
        <ng-container *ngIf="switchview == 'list'">
          <div class="table-responsive">
            <table class="table table-bordered table-striped mt-3">
              <thead>
                <tr>
                  <td>Announcement</td>
                  <td>Type</td>
                  <td>Order</td>
                  <td>Valid From</td>
                  <td>Valid To</td>
                  <td>Display Time</td>
                  <td>Mode</td>
                  <td>Force View Mode</td>
                  <td>Filters</td>
                  <td>Group Name</td>
                  <td>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="siteAnnouncements?.length == 0" style="background: #f0f0f0">
                  <td colspan="20">
                    <p class="text-center mb-0">
                      There is no announcement, You can add by clicking on `Add New Announcement` button
                    </p>
                  </td>
                </tr>
                <ng-container *ngFor="let an of siteAnnouncements">
                  <tr *ngIf="an.mode == 'view'">
                    <td>{{an.model.title}}</td>
                    <td> {{announcementTypes[an.model.type]}} </td>
                    <td>
                      <div class="d-flex justify-content-center">
                        <button type="button" class="border-0 bg-transparent"
                          (click)="changeSiteAnnouncementOrder(an , true)">
                          <i class="fas fa-arrow-up pointer text-success mr-1"></i>
                        </button>
                        <button type="button" class="border-0 bg-transparent"
                          (click)="changeSiteAnnouncementOrder(an , false)">
                          <i class="fas fa-arrow-down text-danger pointer"></i>
                        </button>
                      </div>
                    </td>
                    <td><span *ngIf="an.model.validFrom">{{an.model.validFrom | datetime}}</span></td>
                    <td><span *ngIf="an.model.validTo">{{an.model.validTo | datetime}}</span></td>
                    <td><span *ngIf="an.model.displayTime >= 0">{{an.model.displayTime |
                        siteAnnouncementDisplayTime}}</span></td>
                    <td>
                      <span *ngIf="an.model.mode || an.model.mode == 0">{{an.model.mode |
                        announcementMode}}</span>
                    </td>
                    <td>
                      <span *ngIf="an.model.forceViewMode || an.model.forceViewMode == 0">{{an.model.forceViewMode |
                        siteAnnouncementForceViewMode}}</span>
                    </td>
                    <td>
                      <div>
                        <ng-template #userTooltipHtml>
                          <div *ngFor="let p of an.model.users">
                            <div style='text-align: left;'>
                              {{p.mobile}} ({{p.firstName ?? ''}} {{p.lastName ?? ''}})
                            </div>
                          </div>
                        </ng-template>
                        <span *ngIf="an.model.users && an.model.users.length > 0 " [tooltip]="userTooltipHtml"
                          style="border-bottom: dashed 1px;">
                          {{an.model.users.length}}
                          User(s)
                        </span>
                      </div>
                      <div>
                        <ng-template #industryTypeTooltipHtml>
                          <div *ngFor="let inductryType of an.model.industryTypes">
                            <div>
                              {{inductryType.type}}
                            </div>
                          </div>
                        </ng-template>
                        <span *ngIf="an.model.industryTypes && an.model.industryTypes.length > 0"
                          [tooltip]="industryTypeTooltipHtml" style="border-bottom: dashed 1px;">
                          {{an.model.industryTypes.length}}
                          Industry Type(s)
                        </span>
                      </div>
                      <div>
                        <ng-template #visitorTypeTooltipHtml>
                          <div *ngFor="let vtype of an.model.visitorTypes">
                            <div>{{vtype.title}}</div>
                          </div>
                        </ng-template>
                        <span *ngIf="an.model.visitorTypes && an.model.visitorTypes.length > 0"
                          [tooltip]="visitorTypeTooltipHtml" style="border-bottom: dashed 1px;">
                          {{an.model.visitorTypes.length}}
                          Visitor Type(s)
                        </span>
                      </div>
                      <div>
                        <ng-template #companySupplierTooltipHtml>
                          <div *ngFor="let supplier of an.model.companySuppliers">
                            <div>{{supplier.name}}</div>
                          </div>
                        </ng-template>
                        <span *ngIf="an.model.companySuppliers && an.model.companySuppliers.length > 0"
                          [tooltip]="companySupplierTooltipHtml" style="border-bottom: dashed 1px;">
                          {{an.model.companySuppliers.length}}
                          Company Supplier(s)
                        </span>
                      </div>
                      <span *ngIf="!an.model.users && !an.model.industryTypes">
                        No Filter
                      </span>
                    </td>
                    <td>{{an.model.groupName}}</td>
                    <td>
                      <div class="d-flex">
                        <button type="button" class="btn btn-sm text-primary" (click)="onEditSelected(an.model)">
                          <i class="fas fa-edit"></i>
                        </button>
                        <button type="button" class="btn btn-sm text-danger"
                          (click)="removeSiteAnnouncement(an.model.announcementId)">
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </ng-container>

        <ng-container *ngIf="switchview == 'add' || switchview == 'edit'">
          <div class="d-flex justify-content-end mb-3">
            <button type="button" class="btn btn-danger btn-sm" (click)="switchview = 'list'">
              < Back to announcement list </button>
          </div>
          <div class="card " *ngIf="newSiteAnnouncement">
            <div class="card-header">
              {{switchview == 'add' ? 'Add New Announcement' : 'Edit Announcement'}}
              <button type="button" class="close" (click)="switchview = 'list'">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div [formGroup]="newSiteAnnouncement.formGroup">
                    <div class="form-group">
                      <label>Select Announcement</label>
                      <select class="custom-select form-control"
                        [formControl]="newSiteAnnouncement.formGroup.get('announcementId')">
                        <option *ngIf="userService.hasUserPermissionForCompany(
                             UserPermission.ManageSiteAnnouncement,
                                siteId
                             )" [ngValue]="null">
                          <b>
                            --- Create New Announcement
                            ----
                          </b>
                        </option>
                        <option *ngFor="let a of announcements" [ngValue]="a.announcementId">{{a.title}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <div class="row mb-3">
                    <div class="form-group col-12 col-sm-6 col-lg-4">
                      <label>Valid From</label>
                      <obc-input-date-time-picker [formControl]="newSiteAnnouncement.formGroup.get('validFrom')"
                        [placeholder]="'Pick From Date'" [mode]="DatetimePickerMode.Calender">
                      </obc-input-date-time-picker>
                      <!-- <input [owlDateTime]="dt1" class="form-control"
                        [formControl]="newSiteAnnouncement.formGroup.get('validFrom')" [owlDateTimeTrigger]="dt1"
                        placeholder="Pick From Date">
                      <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time> -->
                    </div>
                    <div class="form-group col-12 col-sm-6 col-lg-4">
                      <label>Valid To</label>
                      <obc-input-date-time-picker style="z-index: 10000;"
                        [formControl]="newSiteAnnouncement.formGroup.get('validTo')" [placeholder]="'Pick To Date'"
                        [mode]="DatetimePickerMode.Calender">
                      </obc-input-date-time-picker>
                      <!-- <input style="z-index: 10000;" [owlDateTime]="dt2" class="form-control"
                        [formControl]="newSiteAnnouncement.formGroup.get('validTo')" [owlDateTimeTrigger]="dt2"
                        placeholder="Pick To Date">
                      <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time> -->
                    </div>
                    <div class="form-group col-12 col-sm-6 col-lg-4">
                      <label>Display Time</label>
                      <select class="custom-select form-control"
                        [formControl]="newSiteAnnouncement.formGroup.get('displayTime')">
                        <option *ngFor="let index of siteAnnouncementDisplayTime | enumToArray"
                          [ngValue]="siteAnnouncementDisplayTime[index]">
                          {{siteAnnouncementDisplayTime[index] | siteAnnouncementDisplayTime }}
                        </option>
                      </select>
                    </div>
                    <div
                      *ngIf="newSiteAnnouncement.formGroup.get('displayTime').value != siteAnnouncementDisplayTime.WhileCheckin"
                      class="form-group col-12 col-sm-6 col-lg-4">
                      <label>Mode</label>
                      <select class="custom-select form-control"
                        [formControl]="newSiteAnnouncement.formGroup.get('mode')">
                        <option *ngFor="let index of announcementMode | enumToArray"
                          [ngValue]="announcementMode[index]">
                          {{announcementMode[index] | announcementMode }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-12 col-sm-6 col-lg-4">
                      <label>Force View Mode</label>
                      <obc-announcement-force-view-mode-selector [type]="newAnnouncementType"
                        [forceViewMode]="newSiteAnnouncement.formGroup.get('forceViewMode').value"
                        (onSelect)="newSiteAnnouncement.formGroup.get('forceViewMode').setValue($event)">
                      </obc-announcement-force-view-mode-selector>
                    </div>
                    <ng-container
                      *ngIf="newSiteAnnouncement.formGroup.get('forceViewMode').value === SiteAnnouncementForceViewMode.SetPlaythroughToMandatory">
                      <div class="col-12">
                        <strong>Force View Mode Hints</strong>
                      </div>
                      <div class="col-12">
                        <strong>Pdf</strong>: User must view all the pages of the pdf file
                      </div>
                      <div class="col-12">
                        <strong>Video</strong>: User must watch the video right through to the end
                      </div>
                      <div class="col-12">
                        <strong>Audio</strong>: User must listen to the audio right through to the end
                      </div>
                      <div class="col-12">
                        <hr>
                      </div>
                    </ng-container>
                  </div>

                  <ng-container *ngIf="siteManagementMode == SiteManagementMode.Site">
                    <div class="card">
                      <div class="card-body">
                        <div class="row">
                          <div class="form-group col-md-6 col-sm-12" style="text-align: start;">
                            <label class="mt-2">Set to specific users? </label>
                            <obc-switch [value]="isEnableAddUser" (valueChanged)="changeEnableAddUser($event)">
                            </obc-switch>
                          </div>
                        </div>
                        <br />
                        <div class="row" *ngIf="isEnableAddUser">
                          <div class="form-group col-md-3 col-sm-12">
                            <obc-user-autocomplete placeholder="Mobile" [displayNoResult]="false" display="mobile"
                              (inputChange)="onInputChange($event)" [currentUser]="currentCompleteUser"
                              [filterBy]="filterUserBy.All" (selectedUser)="onSelectUser($event)">
                            </obc-user-autocomplete>
                            <div *ngIf="currentCompleteUser?.mobile == null" class="invalid-feedback"
                              style="display: block;">
                              Please enter valid mobile number
                            </div>
                            <div *ngIf="currentCompleteUser?.mobile != null && isMobileValid === false"
                              class="invalid-feedback" style="display: block;">
                              Mobile number is not valid
                            </div>
                            <div
                              *ngIf="currentCompleteUser?.mobile == null && successMessage && successMessage.length > 0"
                              class="valid-feedback" style="display: block;">
                              {{successMessage}}
                            </div>
                          </div>
                          <div class="col-md-3 col-sm-12">
                            <obc-user-autocomplete placeholder="Family" [displayNoResult]="false" display="lastName"
                              (inputChange)="onInputChange($event)" [currentUser]="currentCompleteUser"
                              [filterBy]="filterUserBy.All" (selectedUser)="onSelectUser($event)">
                            </obc-user-autocomplete>
                          </div>
                          <div class="col-md-3 col-sm-12">
                            <obc-user-autocomplete placeholder="Name" [displayNoResult]="false" display="firstName"
                              (inputChange)="onInputChange($event)" [currentUser]="currentCompleteUser"
                              [filterBy]="filterUserBy.All" (selectedUser)="onSelectUser($event)">
                            </obc-user-autocomplete>
                          </div>
                          <div class="col-md-3 col-sm-12">
                            <button type="button" class="btn btn-sm btn-primary mt-1" (click)="onPushNewUser()">
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="row" *ngIf="isEnableAddUser && newSiteAnnouncement.formGroup.get('users').value">
                          <div class="form-group col-md-12 col-sm-12">
                            <div class="chips">
                              <button type="button"
                                *ngFor="let user of newSiteAnnouncement.formGroup.get('users').value"
                                class="chip btn btn-sm btn-warning mr-2 mb-2">
                                <div>{{user.mobile}}</div>
                                <div><small>{{getUserFullName(user)}}</small></div>
                                <i class="text-danger fas fa-times" (click)="onRemoveUser(user)"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card" *ngIf="industryTypeList && industryTypeList.length > 0">
                      <div class="card-body">
                        <div class="row">
                          <div class="form-group col-md-3 col-sm-12">
                            <label class="mt-2">Set to specific industry type? </label>
                            <obc-switch [value]="isEnableAddIndustryType"
                              (valueChanged)="changeEnableAddIndustry($event)">
                            </obc-switch>
                          </div>
                          <div class="form-group col-md-4 col-sm-12" *ngIf="isEnableAddIndustryType">
                            <select class="form-control" style="display:inline; width:80%;"
                              [(ngModel)]="selectedIndustryType">
                              <option *ngFor="let ind of industryTypeList" [ngValue]="ind">{{ind.type}}</option>
                            </select>
                            <button type="button" class="btn btn-sm btn-primary ml-2" (click)="onPushNewIndustryType()">
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="row"
                          *ngIf="isEnableAddIndustryType && newSiteAnnouncement.formGroup.get('industryTypes').value">
                          <div class="form-group col-md-12 col-sm-12">
                            <div class="chips">
                              <button type="button"
                                *ngFor="let ind of newSiteAnnouncement.formGroup.get('industryTypes').value"
                                class="chip btn btn-sm btn-primary mr-2 mb-2">
                                {{ind.type}}
                                <i class="fas fa-times" (click)="onRemoveIndustryType(ind)"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card" *ngIf="visitorTypeList && visitorTypeList.length > 0">
                      <div class="card-body">
                        <div class="row">
                          <div class="form-group col-md-3 col-sm-12">
                            <label class="mt-2">Set to specific visitor type? </label>
                            <obc-switch [value]="isEnableAddVisitorType"
                              (valueChanged)="changeEnableAddVisitor($event)">
                            </obc-switch>
                          </div>
                          <div class="form-group col-md-4 col-sm-12" *ngIf="isEnableAddVisitorType">
                            <select class="form-control" style="display:inline; width:80%;"
                              [(ngModel)]="selectedVisitorType">
                              <option *ngFor="let vis of visitorTypeList" [ngValue]="vis">{{vis.title}}</option>
                            </select>
                            <button type="button" class="btn btn-sm btn-primary ml-2" (click)="onPushNewVisitorType()">
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="row"
                          *ngIf="isEnableAddVisitorType && newSiteAnnouncement.formGroup.get('visitorTypes').value">
                          <div class="form-group col-md-12 col-sm-12">
                            <div class="chips">
                              <button type="button"
                                *ngFor="let vis of newSiteAnnouncement.formGroup.get('visitorTypes').value"
                                class="chip btn btn-sm btn-primary mr-2 mb-2">
                                {{vis.title}}
                                <i class="fas fa-times" (click)="onRemoveVisitorType(vis)"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card" *ngIf="companySupplierList && companySupplierList.length > 0">
                      <div class="card-body">
                        <div class="row">
                          <div class="form-group col-md-4 col-sm-12">
                            <label class="mt-2">Set to specific company supplier? </label>
                            <obc-switch [value]="isEnableAddCompanySupplier"
                              (valueChanged)="changeEnableAddCompanySupplier($event)">
                            </obc-switch>
                          </div>
                          <div class="form-group col-md-4 col-sm-12" *ngIf="isEnableAddCompanySupplier">
                            <select class="form-control" style="display:inline; width:80%;"
                              [(ngModel)]="selectedCompanySupplier">
                              <option *ngFor="let sup of companySupplierList" [ngValue]="sup">{{sup.name}}</option>
                            </select>
                            <button type="button" class="btn btn-sm btn-primary ml-2"
                              (click)="onPushNewCompanySupplier()">
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="row"
                          *ngIf="isEnableAddCompanySupplier && newSiteAnnouncement.formGroup.get('companySuppliers').value">
                          <div class="form-group col-md-12 col-sm-12">
                            <div class="chips">
                              <button type="button"
                                *ngFor="let sup of newSiteAnnouncement.formGroup.get('companySuppliers').value"
                                class="chip btn btn-sm btn-primary mr-2 mb-2">
                                {{sup.name}}
                                <i class="fas fa-times" (click)="onRemoveCompanySupplier(sup)"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>


                  <div *ngIf="newSiteAnnouncement.formGroup.get('announcementId').value" class="form-group mt-2"
                    style="text-align: end;">
                    <button type="button" [disabled]="!newSiteAnnouncement.formGroup.valid" class="btn btn-primary"
                      (click)="onSave(newSiteAnnouncement)">
                      {{switchview == 'add' ? 'Add New Announcement' : 'Edit Announcement'}}
                    </button>
                    <button type="button" class="btn btn-info ml-2" (click)="switchview = 'list'">
                      Cancel
                    </button>
                  </div>
                </div>
                <hr />
                <div class="col-md-12 mt-3" *ngIf="!newSiteAnnouncement.formGroup.get('announcementId').value">
                  <obc-create-announcement [model]="null" [isActiveStatusChangable]="false"
                    [whiteListFileTypes]="getWhiteListFileTypes()" (onTypeChanged)="onAnnouncementTypeChanged($event)"
                    (announcementCreated)="onAnnouncementCreated($event)">
                  </obc-create-announcement>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</accordion-group>