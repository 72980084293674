<section class="card">
  <header class="card-header">
    <span class="d-inline-block mt-2 mr-1">Show</span>
    <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [value]="showActiveQuestions"
                (valueChanged)="getQuestionList($event)"></obc-switch>
    <span class="d-inline-block mt-2 ml-1"> Questions</span>
  </header>
  <header class="card-header">
    <h5>Question Repository</h5>
    <button class="btn btn-success card-header-button" (click)="openModal(addQuestion)">
      <i class="fas fa-plus mr-1"></i>Add New Question</button>
  </header>
  <obc-descriptor [closePanelName]="'questionManager'"
    [text]="'Adding a new question to your hub allows you to use this specific check in or check out question in your site(s).'">
  </obc-descriptor>
  <div class="card-body">

    <div>
      <input type="text" class="form-control" placeholder="Search" [formControl]="filterFormcontrol" />
    </div>

    <obc-loader [show]="inProgress"></obc-loader>
    <div *ngIf='errorMessage' class='alert alert-danger mb-2'>
      {{errorMessage}}
    </div>
    <div>
      <div>
        <ul class="simple-post-list">
          <li class="d-flex flex-column justify-content-around" *ngFor="let question of questionList">
            <obc-question-extended-title [question]="question"></obc-question-extended-title>
            <div class="mt-1">
              <div class="pull-right align-self-center ml-2">
                <button (click)="deleteQuestion(question.id)" class="btn btn-sm btn-danger">
                  Delete
                  question
                </button>
              </div>
              <div class="pull-right align-self-center ml-2">
                <button (click)="openAddQuestionToAllSitesModal(question, false, addQuestionToAllSite)"
                  class="btn btn-sm btn-danger">
                  Remove From All Sites
                </button>
              </div>
              <div class="pull-right align-self-center ml-2">
                <button (click)="openAddQuestionToAllSitesModal(question, true, addQuestionToAllSite)"
                  class="btn btn-sm btn-warning">
                  Add To All Sites
                </button>
              </div>
              <div class="pull-right align-self-center ml-2">
                <button (click)="openModal(addCondition,question)" class="btn btn-sm btn-dark-scale-2">
                  Display Conditions
                </button>
              </div>
              <div class="pull-right align-self-center ml-2"
                *ngIf="question.questionType !== _questionType.RichText && !isCompanyTemplate">
                <button (click)="openModal(questionTriggers,question)" class="btn btn-sm btn-info">
                  Manage Triggers <span *ngIf="isCompanyAdmin"> & Automations</span>
                </button>
              </div>
              <div class="pull-right align-self-center">
                <button (click)="openModal(editQuestion,question)" class="btn btn-sm btn-primary">
                  Edit
                  question
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div *ngIf="questionList && questionList.length" class="simple-post-list pt-8">
      <obc-pagination [totalCount]="pagingInfo.totalCount" [pageSize]="pagingInfo.pageSize"
        [pageNumber]="pagingInfo.pageNumber" (pageChanged)="onPageChange($event)"></obc-pagination>
    </div>
  </div>
</section>
<ng-template #addCondition>
  <app-conditional-question (result)="closeModal($event)" [question]="selectedQuestion">
  </app-conditional-question>
</ng-template>
<ng-template #addQuestion>
  <obc-question-add (result)="closeModal($event)"></obc-question-add>
</ng-template>
<ng-template #editQuestion>
  <obc-question-add (result)="closeModal($event)" [question]="selectedQuestion"></obc-question-add>
</ng-template>
<ng-template #questionTriggers>
  <obc-question-triggers (result)="closeModal($event)" [question]="selectedQuestion"></obc-question-triggers>
</ng-template>
<ng-template #addQuestionToAllSite>
  <obc-add-remove-question-all-sites [question]="selectedQuestion" [addQuestionToAllSites]="addQuestionToAllSites">
  </obc-add-remove-question-all-sites>
</ng-template>
