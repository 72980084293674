<div [formGroup]="formGroup">
  <div class="form-group col-md-12 col-sm-12">
    <label>Action</label>
    <select class="custom-select form-control" formControlName="action">
      <option [ngValue]="null" disabled>Choose Action</option>
      <option [ngValue]="TriggerAction.Email">{{getTriggerActionFriendlyName(TriggerAction.Email)}}</option>
    </select>
  </div>

  <div class="col-md-12">
    <obc-email-template [showSendEmailToSiteSupervisor]="false" formControlName="parameters"> </obc-email-template>
  </div>

  <div class="col-md-12 text-right mt-2">
    <button class="btn btn-danger m-1" (click)="close.emit()">Close</button>
    <button (click)="upsert()" [disabled]="!formGroup.valid" class="btn btn-primary m-1">
      <ng-container *ngIf="_trigger?.id">Update</ng-container>
      <ng-container *ngIf="!_trigger?.id">Add Trigger</ng-container>
    </button>
  </div>
</div>
