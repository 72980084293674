import { Pipe, PipeTransform } from '@angular/core';
import { ToolDynamicFieldType } from '../enums/tool-dynamic-field-type.enum';

@Pipe({
  name: 'toolDynamicFieldType'
})
export class ToolDynamicFieldTypePipe implements PipeTransform {

  transform(value: unknown): any {
    if (value === ToolDynamicFieldType.Text) { return "Text"; }
    if (value === ToolDynamicFieldType.Number) { return "Number"; }
    if (value === ToolDynamicFieldType.File) { return "File"; }
    if (value === ToolDynamicFieldType.Date) { return "Date"; }
    return value;
  }

}
