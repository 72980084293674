<div class="row">
  <div class="col-md-12">
    <div class="card">
      <!-- <header class="card-header">User Activity History</header> -->
      <div class="card-body">
        <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>
          <ng-template obcGridTemplateName="actor" let-data="data">
            <div *ngIf="data.actor?.type == ActorType.Anonymous">
              Anonymous User
            </div>
            <div *ngIf="data.actor?.type == ActorType.User">
              <strong>{{data.actor.firstName + ' ' + data.actor.lastName}}</strong>
              <strong> ({{ data.actor.mobile|mobile }})</strong>
            </div>
          </ng-template>
          <ng-template obcGridTemplateName="logType" let-data="data">
            <strong>{{data.eventType | workerProfileLogType}}</strong>
          </ng-template>
          <ng-template obcGridTemplateName="details" let-data="data">
            <strong>{{(data.userProfileType ?? data.userActivityType) | workerProfileSubType:data.eventType}}</strong>
            <div *ngIf="data.updateDetails">
              <ul>
                <ng-container *ngFor="let item of data.updateDetails | objectToListKeyValue">
                  <li>
                    <span>{{item.key}}: </span>
                    <span *ngIf="item.value"><strong>{{item.value}}</strong></span>
                    <span *ngIf="!item.value"><em>[Empty]</em></span>
                  </li>
                </ng-container>
              </ul>
            </div>
            <div *ngIf="data.details">
              <ul>
                <ng-container *ngFor="let item of data.details | objectToListKeyValue">
                  <li *ngIf="item.value">
                    <span>{{item.key}}: </span>
                    <span><strong>{{item.value}}</strong></span>
                  </li>
                </ng-container>
              </ul>
            </div>

          </ng-template>
        </app-base-grid>
      </div>
    </div>
  </div>
</div>