import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CardLayout } from '../models/print-layout/print-layout-model';

@Injectable({
  providedIn: 'root'
})
export class CardLayoutService {
  apiUrl: string = "/api/CardLayout";
  constructor(private http: HttpClient, private _errorService: ErrorService) { }
  getCardLayouts = (): Observable<any> =>
    this.http.get<any>(`${this.apiUrl}/get-card-layouts`)
      .pipe(catchError(this._errorService.handleError));

  addCardLayout = (model: CardLayout): Observable<any> =>
    this.http.post<any>(`${this.apiUrl}/add-card-layout`, model)
      .pipe(catchError(this._errorService.handleError));

  removeCardLayout = (id: number): Observable<any> =>
    this.http.get<any>(`${this.apiUrl}/remove-card-layout/${id}`)
      .pipe(catchError(this._errorService.handleError));

  updateCardLayout = (model: CardLayout): Observable<any> =>
    this.http.post<any>(`${this.apiUrl}/update-card-layout/${model.id}`, model)
      .pipe(catchError(this._errorService.handleError));

}
