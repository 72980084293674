import { Pipe, PipeTransform } from '@angular/core';
import { SiteAssetRequestApproveStatus } from '../enums/site-asset-request-approve-status.enum';

@Pipe({
  name: 'filledFormEntityStatusIcon'
})
export class FilledFormEntityStatusIconPipe implements PipeTransform {

  transform(value: SiteAssetRequestApproveStatus): string {
    var cssClass = "";
    if (value == SiteAssetRequestApproveStatus.Pending) cssClass = "history text-warning";
    if (value == SiteAssetRequestApproveStatus.AutoApproved) cssClass = "check-circle text-success";
    if (value == SiteAssetRequestApproveStatus.Approved) cssClass = "check-circle text-success";
    if (value == SiteAssetRequestApproveStatus.Rejected) cssClass = "times text-danger";
    return !(cssClass?.length) ? "" : "fas fa-" + cssClass;
  }

}
