import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';
import { ApproveEntityType } from 'src/app/enums/approve-entity-type';
import { ManageSiteAssetMode } from 'src/app/enums/manage-site-asset-mode.enum';
import { SiteAssetRequestApproveStatus } from 'src/app/enums/site-asset-request-approve-status.enum';
import { NicknameModel, SiteAssetViewModel } from 'src/app/models/site-asset/site-asset-view-model';
import { LayoutService } from 'src/app/services/layout.service';
import { ModalService } from 'src/app/services/modal.service';
import { SiteAssetService } from 'src/app/services/site-asset.service';
import { UserService } from "../../../services/user.service";
import { UserPermission } from "../../../enums/user-permission.enum";
import { BaseSiteViewModel } from 'src/app/models/site-viewmodel';
import { SiteAssetOperatorViewModel } from 'src/app/models/site-asset/site-asset-operator-view-model';
import { ApproveService } from 'src/app/services/approve-service';
import { ApproveRejectQueryModel } from 'src/app/models/approve/approve-reject-query-model';
import { AssetCheckInHistoryModel } from 'src/app/models/site-asset/asset-check-in-history-model';
import { FormType } from 'src/app/enums/form-type';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { GridColumn, GridConfig, GridTemplate, InitialFilter } from 'src/app/models/grid/grid.config';
import { Table } from 'src/app/enums/table.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { ProductPlanFeature } from 'src/app/enums/product-plan-feature.enum';

@Component({
  selector: 'obc-manage-site-asset',
  templateUrl: './manage-site-asset.component.html',
  styleUrls: ['./manage-site-asset.component.scss'],
})
export class ManageSiteAssetComponent implements OnInit {
  @ViewChild('grid') grid: any;
  inProgress: boolean = false;
  SiteAssetRequestApproveStatus = SiteAssetRequestApproveStatus;
  ApproveEntityType = ApproveEntityType;
  assetRegisterFormgroup: FormGroup = null;
  siteAssets: SiteAssetViewModel[] = [];
  selectedSiteAsset: SiteAssetViewModel;
  selectedSiteAssetId: number;
  selectedAssetId: number;
  selectedAssetIds: AssetCheckInHistoryModel;
  selectedEntityTypeForReviewModal: ApproveEntityType;
  selectedItemIsEnableFormExpiryDate: boolean;
  selectedEntityEndDate: Date;
  selectedFormDataId: number;
  manageSiteAssetMode: ManageSiteAssetMode = ManageSiteAssetMode.MainCompany;
  isInSupplierMode: boolean = false;
  ManageSiteAssetMode = ManageSiteAssetMode;
  externalSiteSupplierId: number;
  selectedSite: BaseSiteViewModel;
  siteCompanyName: string;
  siteCompanyId?: number;
  isCheckinRequested: boolean;
  referer: string;
  hashLink: any;
  onlyTempAssets: boolean = false;
  formType: FormType;
  FormType = FormType;

  //base-grid filters
  gridColumns: GridColumn<SiteAssetViewModel>[];
  gridConfig: GridConfig = {} as GridConfig;
  initialFilters: InitialFilter[] = [];
  showAddNewAssetButton: boolean;
  nickname: string;

  get selectedSiteId(): number {
    return this.selectedSite?.id;
  };

  @Input() siteId: number;
  _siteName: string;
  get siteName() {
    return this.selectedSite?.name ?? this._siteName;
  }

  @Input() set siteName(value: string) {
    this._siteName = value;
    if (this.selectedSite)
      this.selectedSite.name = value;
  }

  @Input() siteSupplierId: number;
  @Input() supplierId: number;
  @Input() mainCompanyId?: number = null;

  get hasUserApproveRejectPermissions(): boolean {
    return this.userService.hasUserAnyOfPermissions([UserPermission.ApproveRejectSiteInduction]);
  }

  constructor(
    private siteAssetService: SiteAssetService,
    private bsModalService: BsModalService,
    private modalService: ModalService,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    public userService: UserService,
    private approveService: ApproveService) { }

  ngOnInit(): void {
    this.layoutService.header = 'Site Assets';
    this.assetRegisterFormgroup = new FormGroup({
      siteId: new FormControl(null, Validators.required),
      queryMode: new FormControl(null),
    });

    //this.userService.initUserInfo();
    this.userService.info.subscribe((_) => {
      setTimeout(() => {
        this.inProgress = false;
      }, 1000);
    });

    this.route.params.subscribe((res: Params) => {
      try {
        this.referer = res['referer'];

        let siteSupplierId = this.siteSupplierId;
        let hashLink = res['hashlink'];
        //From hashLink onlyTempAssets= true
        //From any where without access  onlyTempAssets= true
        //From Site menu for Linked Company onlyTempAssets= false
        //From Site menu for Current Company onlyTempAssets= false
        //From Supplier menu onlyTempAssets= true
        this.onlyTempAssets = hashLink?.length > 0 || !this.hasAccessToSiteAsset() || (siteSupplierId > 0 && this.mainCompanyId == null);

        //Supplier Mode
        if (hashLink?.length) {
          let siteId = this.siteId ? this.siteId : res['siteId'];
          this.isInSupplierMode = true;
          this.externalSiteSupplierId = +siteSupplierId;
          this.hashLink = hashLink;
          let site = { id: +siteId, name: this.siteName };
          this.changeSiteId(site);
        }
        else if (siteSupplierId) {
          let siteId = this.siteId ? this.siteId : res['siteId'];
          this.isInSupplierMode = true;
          this.externalSiteSupplierId = +siteSupplierId;
          let site = { id: +siteId, name: this.siteName };
          this.changeSiteId(site);
        }
        else {
          let siteId = res['siteId'];
          //Site Mode
          if (siteId) {
            const externalInfo = history.state.data;
            if (externalInfo != undefined)
              this.siteName = externalInfo.siteName;
            else
              this.siteName = "";
            let site = { id: +siteId, name: this.siteName };
            this.isInSupplierMode = false;
            this.changeSiteId(site);
          } else {
            //this.router.navigateByUrl('/sites');
          }
        }
      } catch (error) {

        this.manageSiteAssetMode = ManageSiteAssetMode.MainCompany;
      }
    }
    );

    this.assetRegisterFormgroup?.controls.siteId?.valueChanges?.subscribe((res) => {
      let filter = this.initialFilters?.find((item) => item.key == 'siteId');
      if (res) {
        filter.value = res;
      } else {
        filter.value = null;
      }
      this.initialFilters = [
        ...this.initialFilters?.filter((item) => item.key != 'siteId'),
        filter,
      ];
    });

    this.prepareGridConfig();
  }

  prepareGridConfig() {
    this.initialFilters = [];
    this.initialFilters.push({
      key: 'siteId',
      value: this.assetRegisterFormgroup.controls.siteId.value,
      displayInFilterRenderer: false,
    });

    this.initialFilters.push({
      key: 'queryMode',
      value: this.assetRegisterFormgroup.controls.queryMode.value,
      displayInFilterRenderer: false,
    });

    this.initialFilters.push({
      key: 'siteSupplierId',
      value: this.externalSiteSupplierId,
      displayInFilterRenderer: false,
    });

    this.initialFilters.push({
      key: 'hashLink',
      value: this.hashLink,
      displayInFilterRenderer: false,
    });

    const apiUrl = this.hashLink?.length ? '/api/AnonymousSiteAsset/supplier-assets' :
      (this.isInSupplierMode == false
        ? '/api/SiteAsset/site-assets' :
        '/api/SiteAsset/supplier-assets');

    let csvReportUrl = this.hashLink?.length ? '/api/AnonymousSiteAsset/supplier-assets-csv' : (this.isInSupplierMode == false
      ? '/api/SiteAsset/site-assets-csv-as-job' : '/api/SiteAsset/supplier-assets-csv-as-job');

    this.gridConfig = new GridConfig({
      headers: this.hashLink?.length ? { "hash-link": this.hashLink ?? '' } : null,
      loadDataOnInitialFilterChange: true,
      apiUrl: apiUrl,
      csvReportUrl: csvReportUrl,
      displayQuestionsFilter: false,
      displayAnnouncementsFilter: false,
      scheduleReport: null,
      displayEnableMagicLink: false,
      tableType: this.isInSupplierMode == false ? Table.SiteAsset : Table.SupplierAsset,
      displayGeneralSearch: true,
      refreshButtonValidity: true,
      generalSearchPlaceholder: "Filter Result By Asset Name, Asset Category",
      initialFilters: this.initialFilters,
      apiResultCallback: (res) => {
        this.manageSiteAssetMode = res.isExternalCompanyMode == true ? ManageSiteAssetMode.ExternalCompany : ManageSiteAssetMode.MainCompany;
        this.showAddNewAssetButton = res.showAddNewAssetButton;
        return {
          data: res.siteAssets,
          totalCount: res.totalCount,
        };
      }
    });

    this.gridColumns = [
      {
        key: "operatorNames",
        name: "Registered Operators",
        enableSort: false,
        enableFilter: false,
        gridTemplate: new GridTemplate().ComponentTemplateName('registeredOperators'),
        visible: true,
      },
      {
        key: "assetName",
        name: "Asset Name",
        type: FilterType.Text,
        gridTemplate: new GridTemplate().ComponentTemplateName('assetName'),
        propertyNameInQuery: 'assetNames',
        visible: true,
      },
      {
        name: "Asset Category",
        key: "assetCategory",
        type: FilterType.AssetCategoryType,
        gridTemplate: new GridTemplate().ComponentTemplateName('siteAssetCategory'),
        propertyNameInQuery: 'assetCategories',
        enableSort: true,
        enableFilter: true,
        visible: true,
      },
      {
        key: "statusData",
        name: "Status",
        type: FilterType.AssetStatus,
        gridTemplate: new GridTemplate().ComponentTemplateName('siteAssetStatus'),
        propertyNameInQuery: 'assetStatuses',
        visible: true,
      },
      {
        key: "checkinData",
        name: "Check-In History",
        gridTemplate: new GridTemplate().ComponentTemplateName('checkinHistory'),
        enableSort: false,
        enableFilter: false,
        visible: true,
      },
      {
        key: "viewDetails",
        name: "Actions",
        gridTemplate: new GridTemplate().ComponentTemplateName('viewDetails'),
        enableSort: false,
        enableFilter: false,
        visible: true,
        displayInCheckBoxSettings: false
      },
    ];

    // show ownerCompanyTitle column, only if it's main company user and no supplier is selected
    if (!this.siteSupplierId) {
      this.gridColumns.splice(0, 0, {
        name: "Owner",
        key: "ownerCompanyTitle",
        type: FilterType.Text,
        propertyNameInQuery: 'owners',
      });
    }
  }

  get allowToApproveAnything() {
    return this.manageSiteAssetMode == ManageSiteAssetMode.MainCompany;
  }

  changeSiteId(site: BaseSiteViewModel) {
    this.selectedSite = site;
    this.assetRegisterFormgroup.controls.siteId.setValue(site.id);
    this.onFetchSiteAssets();
  }

  onFetchSiteAssets() {
    this.grid.loadData(0);
  }

  bsModalRef = null;
  onOpenRegisterComponent(template) {
    this.bsModalRef = this.bsModalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  onSubmitSiteAsset(_: number) {
    this.onFetchSiteAssets();
    this.bsModalRef?.hide();
    this.operatorBsModal?.hide();
  }

  operatorBsModal = null;

  onRegisterOperator(template, selectedSiteAsset: SiteAssetViewModel) {
    this.selectedSiteAsset = selectedSiteAsset;
    this.operatorBsModal = this.bsModalService.show(template, {
      class: 'modal-lg',
      ignoreBackdropClick: true
    })
  }

  onRegisterOperatorDone() {
    this.bsModalRef?.hide();
    this.operatorBsModal?.hide();
    this.onFetchSiteAssets();
  }

  closeModal() {
    try {
      this.modalService.hide();
    } catch {
    }
  }

  onOpenFormDetail(template: any, formDataId: number, entityId: number, formType: FormType = null) {
    this.selectedFormDataId = formDataId;
    this.selectedSiteAssetId = entityId;
    this.formType = formType;
    this.modalService.show(template, 'modal-lg');
  }

  async onCheckInOutSiteAsset(template, siteAsset: SiteAssetViewModel, isCheckin: boolean) {
    this.isCheckinRequested = isCheckin;
    this.selectedSiteAssetId = siteAsset.siteAssetId;
    this.selectedSiteAsset = siteAsset;
    this.modalService.show(template, 'modal-lg');
  }

  onShowAssetCheckInOutHistory(template, asset: SiteAssetViewModel) {
    this.selectedAssetIds = {
      assetId: asset.assetId,
      siteAssetId: asset.siteAssetId,
      tempAssetId: asset.tempAssetId,
    } as AssetCheckInHistoryModel;
    this.modalService.show(template, 'modal-lg');
  }

  onApproveRejectOperator(operator: SiteAssetOperatorViewModel, isApproved: boolean) {
    this.modalService.confirm(`Are you sure you want to ${isApproved ? 'approve' : 'reject'} this operator?`)
      .subscribe(confirm => {
        if (confirm) {
          this.inProgress = true;
          this.approveService.approveRejectEntity({
            approveEntityType: ApproveEntityType.SiteAssetOperator,
            entityId: operator.siteAssetOperatorId,
            isApprove: isApproved,
          } as ApproveRejectQueryModel)
            .pipe(finalize(() => {
              this.inProgress = false
            }))
            .subscribe(_ => {
              this.onFetchSiteAssets();
            });
        }
      });
  }

  saveNickname(item: SiteAssetViewModel, nickname: string) {
    if (!nickname.trim()) {
      this.modalService.error("Asset name can not be empty!")
    }
    else {
      let setNicknameModel = <NicknameModel>{
        SiteAssetId: item.siteAssetId,
        NewNickname: nickname
      }

      this.modalService.confirm(`Are you sure you want to rename this asset?`).subscribe(confirm => {
        if (confirm) {
          this.inProgress = true;
          this.siteAssetService.setNickname(setNicknameModel)
            .pipe(
              finalize(() => {
                this.inProgress = false;
                item.isNickNameEditing = false;
              }),
              catchError((error: HttpErrorResponse) => {
                if (error.error) {
                  this.modalService.error(error.error.message)
                }

                throw error;
              }))
            .subscribe((res) => {
              if (res) {
                this.grid.loadData();
              }
            });
        }
      });
    }
  }

  hasAccessToSiteAsset() {
    let isExternalSite = this.manageSiteAssetMode == ManageSiteAssetMode.ExternalCompany;
    return this.userService.hasCompanyFeature(ProductPlanFeature.Inventory) &&
      (
        (
          isExternalSite &&
          this.userService.hasPermissionOnCompany(UserPermission.AssetTrackerAdmin)
        ) ||
        (
          !isExternalSite &&
          this.userService.hasUserPermissionForCompany(UserPermission.AssetTrackerAdmin, this.selectedSiteId)
        )
      )
  }
}
