<div class="card">
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="card-header mb-2">
      <span class="mr-5">
        <span class="d-inline-block mt-2 mr-1">Display Mode: </span>
        <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [value]="onlyActiveInductions"
          (valueChanged)="onActiveChanged($event,true)">
        </obc-switch>
      </span>
    </div>
    <div class="row">
      <div class="col-12 mb-2">
        <p style="text-align: center;" *ngIf="inductions == null">
          Loading ...
        </p>
        <div class="table-responsive">
          <table class="table table-bordered mb-0">
            <thead>
              <tr>
                <th>Name</th>
                <th>Scope</th>
                <th>Visitor Types</th>
                <th>Expiry</th>
                <th>Approval Mode</th>
                <th>Perform</th>
                <th class="text-right">
                  <button class="btn btn-info" (click)="onModalUpsertInduction(upsert)">New Induction</button>
                </th>
              </tr>
            </thead>
            <tbody *ngIf="inductions == null || inductions.length == 0">
              <tr>
                <td colspan="100">
                  <strong>There is no induction!</strong>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="inductions && inductions.length">
              <ng-container *ngFor="let group of inductions">
                <!--                <tr-->
                <!--                  [ngClass]="{'bold-buttom-border' : !(group.items?.length)}">-->
                <tr>
                  <td>
                    <span>{{group.title}}</span>
                    <span class="ml-2" *ngIf="group.inductionKey" style="font-size: small;"> ({{group.inductionKey}})
                    </span>
                    <span class="text-info ml-2">({{group.isMandatory ? 'Mandatory':'Optional'}})</span>
                  </td>
                  <td>
                    <span>{{group.scope | companySiteScope}}</span>
                  </td>
                  <td>
                    <span>
                      <ng-container *ngIf="group.visitorTypeIds?.length">
                        <div class="btn-group">
                          <button class="btn btn-dark no-cursor" [disabled]="true"
                            *ngFor="let id of group.visitorTypeIds">
                            {{getVisitorType(id)?.title}}
                          </button>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!group.visitorTypeIds?.length">
                        All Visitors
                      </ng-container>
                    </span>
                  </td>
                  <td>
                      <span *ngIf="group.expiryMode !== expiryModeEnum.Custom">{{group.expiryMode | expiryMode}}</span>
                      <span *ngIf="group.expiryMode == expiryModeEnum.Custom">{{group.expiryCount}} {{group.expiryUnit | expiryUnit}}</span>
                  </td>
                  <td>
                    <span>{{getModeTitle(group.mode)}}</span>
                  </td>
                  <td>
                    <span>{{group.askMode | inductionAskMode}}</span>
                  </td>
                  <td>
                    <div class="d-flex flex-row-reverse mb-0" style="text-align: end;">
                      <button class="btn btn-sm btn-danger mr-1" (click)="onRemoveInduction(group)">
                        <i class="fa fa-times"></i>
                      </button>
                      <button class="btn btn-sm btn-dark mr-1" title="Clone Induction"
                        (click)="onModalClone(clone, group)">
                        <i class="fa fa-clone"></i>
                      </button>
                      <button class="btn btn-sm btn-primary mr-1" (click)="onModalUpsertInduction(upsert, group)">
                        <i class="fa fa-edit"></i>
                      </button>
                      <button class="btn btn-sm btn-info mr-1" (click)="showInductionDetails(group)">
                        <i class="fa fa-info"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr class="bold-buttom-border" *ngIf="showDetailsConfig[group.id] && (group.items?.length)">
                  <td colspan="100">
                    <obc-items-mixture *ngIf="group.items" [inductionId]="group.id" [displayOnly]="true"
                      [orderableItems]="group.items">
                    </obc-items-mixture>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #upsert>
  <app-upsert-induction [induction]="inductionToEdit" (hide)="closeQuestionModal($event)">
  </app-upsert-induction>
</ng-template>

<ng-template #clone>

  <div class="row">
    <div class="col-12">
      <section class="card">
        <div class="card-header">
          Clone Induction
          <div class="card-actions">
            <a (click)="closeCloneModal()" class="card-action card-action-dismiss" data-card-dismiss=""></a>
          </div>
        </div>
        <div class="card-body">
          <obc-loader [show]="inProgress"></obc-loader>
          <div class="form-group row">
            <div class="col-md-6 col-12">
              <label class="control-label">Name:</label>
              <input type="text" class="form-control" [(ngModel)]="cloneName"
                (keydown.enter)="cloneName?.length > 0 ? cloneInduction() : null">
            </div>
          </div>
        </div>
        <footer class="card-footer text-right">
          <button class="btn btn-primary" (click)="cloneInduction()" [disabled]="cloneName?.length === 0">
            Clone
          </button>
          <button type="reset" (click)="closeCloneModal()" class="btn btn-default ml-2">Cancel</button>
        </footer>
      </section>
    </div>
  </div>

</ng-template>
