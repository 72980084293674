<div class="row">
  <div class="col-md-12">
    <div class="card">
      <!-- <header class="card-header">Supplier Document SignOff Report</header> -->
      <div class="card-body">
        <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>
          <ng-template obcGridTemplateName="actions" let-viewDetails let-item="data">

            <div class="btn-group" dropdown placement="bottom right" container="body">
              <obc-file-viewer [urls]="item.fileUrls" [btnTitle]="'View Details'"
                [btnClass]="'btn btn-info no-right-border-radius'">
              </obc-file-viewer>
              <button id="button-basic" dropdownToggle type="button" class="btn btn-info dropdown-toggle"
                aria-controls="dropdown-container">
                <span class="caret"></span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right commands" role="menu"
                aria-labelledby="button-basic">
                <li role="menuitem">
                  <a class="dropdown-item cursor-pointer " (click)="openModal(cardHistory, item.cardId)">
                    <i class="fa fa-upload mr-1"></i> View History
                  </a>
                </li>
              </ul>
            </div>

          </ng-template>
          <ng-template #cardHistory>
            <obc-worker-profile-card-history [modalReference]="historyPopUpReference"
              [companyUserCardId]="companyUserCardId"></obc-worker-profile-card-history>
          </ng-template>
        </app-base-grid>
      </div>
    </div>
  </div>
</div>
