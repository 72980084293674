import { BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { SiteService } from 'src/app/services/site.service';
import { SiteCloningModel, SiteDetailsViewModel, SiteViewModel } from 'src/app/models/site-viewmodel';
import { ModalService } from 'src/app/services/modal.service';
import { AsyncResult } from 'src/app/models/helper-models';
import { debounceTime, finalize } from 'rxjs/operators';
import { LayoutService } from 'src/app/services/layout.service';
import { UserService } from 'src/app/services/user.service';
import { SiteActiveStatus } from 'src/app/enums/site-active-status.enum';
import { SiteTemplateStatus } from 'src/app/enums/site-template-status.enum';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { CompanyStatus } from 'src/app/enums/company-status.enum';
import { ProductPlanFeature } from 'src/app/enums/product-plan-feature.enum';
import { pagingModel } from 'src/app/models/paging_model';
import { SiteListQueryModel } from 'src/app/models/site-list-query-model';
import { PaginationInfo } from 'src/app/models/pagination-info';
import { CompanyViewModel } from 'src/app/models/company-viewmodel';
import { getPageSizeByUrl } from "../../helpers/general-functions";

@Component({
  selector: 'obc-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  pagingInfo: pagingModel = {
    pageNumber: 0,
    pageSize: getPageSizeByUrl() ?? 30,
    totalCount: 0,
  }
  filterListTerm: string;
  filterFormcontrol = new FormControl();
  siteList: SiteDetailsViewModel[];
  templateSiteListForClone: SiteViewModel[];
  inProgress: boolean = false;
  isCompanyOwner: boolean = false;
  filterSiteListTerm: string;
  CompanyStatus = CompanyStatus;
  iscloneSiteFromTemplateList: boolean;
  get cloneAsSite(): boolean {
    return this.cloneSiteFormGroup.controls["cloneAsSite"].value ?? true;
  }

  cloneSiteFormGroup = new FormGroup({
    cloneAsSite: new FormControl(true, [Validators.required]),
    site: new FormControl(null, [Validators.required]),
    name: new FormControl(null, [Validators.required]),
    address: new FormControl(null, []),
    rawAddress: new FormControl(null, [Validators.required]),
    latitude: new FormControl(null, [Validators.required]),
    longitude: new FormControl(null, [Validators.required]),
  });


  inCloningProgress = false;
  onlyActiveSites: boolean = true;
  onlyRealSites: boolean = true;
  locationVerifyModel: SiteViewModel = new SiteViewModel();
  userPermissions = UserPermission;
  ProductPlanFeature = ProductPlanFeature;

  currentCompany: CompanyViewModel;
  get isTemplateCompany(): boolean {
    return this.currentCompany?.isTemplate ?? false;
  }

  constructor(private siteService: SiteService, private layoutService: LayoutService,
    private bsModalService: BsModalService,
    private modalService: ModalService, public userService: UserService) { }

  ngOnInit(): void {
    this.getSiteList();
    this.layoutService.header = "Sites";
    this.isCompanyOwner = this.userService.isCompanyAdminUser();
    this.userService.info.subscribe(res => this.isCompanyOwner = res?.userPermissions?.isCompanyAdmin)
    this.userService.currentCompany$.subscribe(res => this.currentCompany = res);
    this.cloneSiteFormGroup.controls.address.valueChanges.subscribe(res => {
      this.cloneSiteFormGroup.controls.rawAddress.setValue(res.address);
      if (res.latitude != null && res.longitude != null) {
        this.cloneSiteFormGroup.controls.latitude.setValue(res.latitude);
        this.cloneSiteFormGroup.controls.longitude.setValue(res.longitude);
      }
    });

    this.cloneSiteFormGroup.controls.cloneAsSite.valueChanges.subscribe(res => {
      if (this.cloneAsSite) {
        this.cloneSiteFormGroup.controls['rawAddress'].setValidators([Validators.required]);
        this.cloneSiteFormGroup.controls['latitude'].setValidators([Validators.required]);
        this.cloneSiteFormGroup.controls['longitude'].setValidators([Validators.required]);
      }
      else {
        this.cloneSiteFormGroup.controls['rawAddress'].clearValidators();
        this.cloneSiteFormGroup.controls['latitude'].clearValidators();
        this.cloneSiteFormGroup.controls['longitude'].clearValidators();
      }
      this.cloneSiteFormGroup.controls['rawAddress'].updateValueAndValidity();
      this.cloneSiteFormGroup.controls['latitude'].updateValueAndValidity();
      this.cloneSiteFormGroup.controls['longitude'].updateValueAndValidity();
      this.cloneSiteFormGroup.updateValueAndValidity();
    });

    this.filterFormcontrol.valueChanges.pipe(
      debounceTime(500),
    ).subscribe((searchTerm) => {
      this.filterListTerm = searchTerm;
      if (searchTerm == '' || (searchTerm != null && searchTerm.length > 3)) {
        this.getSiteList(0);
      }
    });
  }

  onActiveChanged(value, isActiveMode) {
    if (isActiveMode)
      this.onlyActiveSites = value;
    else
      this.onlyRealSites = value;
    this.getSiteList();
  }
  createQuery(): SiteListQueryModel {
    var query = {
      activeStatus: this.onlyActiveSites ? SiteActiveStatus.Active : SiteActiveStatus.InActive,
      templateStatus: this.onlyRealSites ? SiteTemplateStatus.Normal : SiteTemplateStatus.Templates,
      pagingInfo: {
        pageNumber: this.pagingInfo.pageNumber,
        pageSize: this.pagingInfo.pageSize,
      },
      searchTerm: this.filterListTerm,
    };
    return query;
  }
  siteListInProgress = false;

  getSiteManageRouterLink(site: SiteDetailsViewModel) {
    return site.isExternalSite ? '/manage-site/supplier/' + site.externalSiteSupplierId : '/manage-site/' + site.id;
  }

  hasAccessToManageSite(site: SiteDetailsViewModel) {
    return this.userService.hasAccessToManageSite(site);
  }

  getSiteList(pageNumber?: number) {
    if (this.siteListInProgress == true)
      return;

    if (pageNumber != null && pageNumber != undefined)
      this.pagingInfo.pageNumber = pageNumber;

    this.inProgress = true;
    this.siteListInProgress = true;

    var query = this.createQuery();
    this.siteService.getAllSites(query)
      .pipe(finalize(() => { this.siteListInProgress = this.inProgress = false; }))
      .subscribe(res => {
        this.siteList = res.data;
        this.pagingInfo.totalCount = res.totalCount;
      });
  }

  openModal(template) {
    this.modalService.show(template, 'modal-lg');
  }

  closeModal(result: AsyncResult) {
    this.modalService.hide();
    if (result.isSucceeded)
      this.getSiteList();
  }

  deleteSite(siteId: number) {
    this.modalService.confirm('Are you sure?', 'delete question').subscribe(iamsure => {
      if (iamsure === true) {

        this.inProgress = true;
        this.siteService.delete(siteId)
          .pipe(finalize(() => this.inProgress = false))
          .subscribe(result => {
            this.getSiteList();
          }, error => {
            var errorMsg = error ?? 'This site contains saved information like check-in/out users.';
            this.modalService.error(errorMsg, "you can't remove this site");
            console.log('error : ', error);
          });
      }
    });
  }

  changeActiveState(site: SiteViewModel) {
    var header = site.isActive ? `Deactive ${site.name}` : `Activate ${site.name}`
    this.modalService.confirm("Are You Sure?", header).subscribe(result => {
      if (result) {
        this.inProgress = true;
        this.siteService.changeActiveState(site.id, !site.isActive)
          .pipe(finalize(() => this.inProgress = false))
          .subscribe(result => {
            this.getSiteList();
          }, error => {
            console.log('error : ', error);
            this.modalService.error(error, "active/deactive site error").subscribe(res => {
              //alert(error);
            });
          });
      }
    })
  }
  getShowQrCodeWithInstruction(site: SiteViewModel) {
    return `/General/site-instructions/${site.id}/${site.secretCode}`;
  }
  openLocationVerifyModal(template, site: SiteViewModel) {
    this.locationVerifyModel = site;
    this.modalService.show(template, 'modal-lg');
  }
  closeLocationVerifyModal(result: AsyncResult) {
    this.modalService.hide();
    if (result.isSucceeded)
      this.getSiteList();
  }

  bsModalHandle = null;
  onCloneSiteFromTemplate(template: any) {
    this.iscloneSiteFromTemplateList = true;
    this.inProgress = true;
    var query = {
      activeStatus: SiteActiveStatus.Active,
      templateStatus: SiteTemplateStatus.Templates,
      pagingInfo: null,
      searchTerm: null,
    };

    this.siteService.getAllSites(query)
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(res => {
        this.templateSiteListForClone = res.data;
      });

    this.cloneSiteFormGroup.controls.site.setValue(null);
    this.cloneSiteFormGroup.controls.cloneAsSite.setValue(true);
    this.bsModalHandle = this.bsModalService.show(template);
    this.bsModalHandle.setClass("modal-lg");
  }

  onCloneSite(site: SiteViewModel, template: any) {
    this.iscloneSiteFromTemplateList = false;
    this.cloneSiteFormGroup.controls.site.setValue(site);
    this.bsModalHandle = this.bsModalService.show(template);
    this.bsModalHandle.setClass("modal-lg");
  }

  clone() {
    var refSiteId = this.cloneSiteFormGroup.controls.site.value.id;

    var model = {
      name: this.cloneSiteFormGroup.controls.name.value,
      address: !this.cloneAsSite ? null : this.cloneSiteFormGroup.controls.rawAddress.value ?? this.cloneSiteFormGroup.controls.address.value.address,
      latitude: !this.cloneAsSite ? null : this.cloneSiteFormGroup.controls.latitude.value,
      longitude: !this.cloneAsSite ? null : this.cloneSiteFormGroup.controls.longitude.value,
    } as SiteCloningModel;
    this.inCloningProgress = true;

    this.siteService.cloneSite(refSiteId, model, this.cloneAsSite)
      .pipe(finalize(() => { this.inCloningProgress = false; }))
      .subscribe(res => {
        this.bsModalHandle.hide();
        this.getSiteList();
        this.cloneSiteFormGroup.reset({ cloneAsSite: true });
      }, err => {
        this.modalService.error(err);
      })
  }
  onPageChange(paginationInfo: PaginationInfo) {
    this.pagingInfo.pageSize = paginationInfo.pageSize;
    this.getSiteList(paginationInfo.pageNumber);
  }

  hasAccessToSiteAsset(site: SiteDetailsViewModel) {
    return site.isActive === true &&
      !site.isTemplate &&
      ((site.isExternalSite && this.userService.hasAnyAccess()) ||
        (!site.isExternalSite && this.userService.hasAdminPermissionOnSite(site.id)));
  }
}
