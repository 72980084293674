<div class="card">
  <div class="card-header">Reset Password</div>
  <div class="card-body">
    <obc-loader [show]="passwordInProccess"></obc-loader>
    <form [formGroup]="passwordFormGroup">
      <div class="form-group">
        <label>Current Password</label>
        <input type="password" class="form-control" formControlName="currentPassword">
      </div>
      <div [formGroup]="passwordFormGroup.get('repeatPasswordGroup')">
        <div class="form-group">
          <label>New Password</label>
          <input type="password" class="form-control" formControlName="password">
        </div>
        <div class="form-group">
          <label>Repeat New Password</label>
          <input type="password" class="form-control" formControlName="repeatPassword">
        </div>
      </div>
      <div class="col-md-12 mt-3">
        <p class="button-group">
          <button type="submit" class="btn btn-primary" [disabled]="!passwordFormGroup.valid" (click)="onChangePassword()">Save Changes</button>
        </p>
      </div>
    </form>
  </div>
</div>