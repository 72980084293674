import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'obc-successfull-anonymous-checkin',
  templateUrl: './successfull-anonymous-checkin.component.html',
  styleUrls: ['./successfull-anonymous-checkin.component.css']
})
export class SuccessfullAnonymousCheckinComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
