import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Subject } from "rxjs";
import { NotificationServerResponseViewModel } from "../models/notification-server-response-view-model";
@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  serverMessage: Subject<NotificationServerResponseViewModel> = new Subject<NotificationServerResponseViewModel>();
  private connection: signalR.HubConnection;
  constructor() { }

  connect() {
    if(!this.connection) {
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(`hub/notifications`)
        .withAutomaticReconnect()
        .build();
    }
    if(this.connection?.state == signalR.HubConnectionState.Disconnected) {
      this.start();
      this.connection?.on('ServerMessage', (data: NotificationServerResponseViewModel) => {
        this.serverMessage.next(data)
      });
    }
  }

  start() {
    this.connection?.start().then(() => {});
  }

  closeConnection() {
    if(this.connection &&
      this.connection?.state != signalR.HubConnectionState.Disconnected &&
      this.connection?.state != signalR.HubConnectionState.Disconnecting) {
      this.connection?.stop().then();
    }
  }
}
