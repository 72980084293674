import { Component, OnInit } from '@angular/core';
import { ProductPlanFeature } from 'src/app/enums/product-plan-feature.enum';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { Dictionary } from 'src/app/models/dictionaty';
import { LayoutService } from 'src/app/services/layout.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'obc-permission-container',
  templateUrl: './permission-container.component.html',
  styleUrls: ['./permission-container.component.scss']
})
export class PermissionContainerComponent implements OnInit {
  PermissionTab = PermissionTab;
  showTabContentFlags: Dictionary<boolean> = {};
  currentTab: number = 0;
  constructor(
    private userService: UserService,
    private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.layoutService.header = "Permissions";

    if (this.isCompanyHost)
      this.setTabAsShown(PermissionTab.CompanyHost);
    else if (this.isSiteHost)
      this.setTabAsShown(PermissionTab.SiteHost);
    else if (this.isCompanyAdmin)
      this.setTabAsShown(PermissionTab.Frequents);
    else if (this.isCompanyAdmin)
      this.setTabAsShown(PermissionTab.TaggedList);
  }

  setTabAsShown(tab: PermissionTab) {
    this.currentTab = tab;
    this.showTabContentFlags[tab.toString()] = true;
  }

  displayTab(tab: PermissionTab) {
    return this.showTabContentFlags[tab.toString()] == true;
  }


  get isCompanyHost() {
    return this.userService.hasUserPermissionForCompany(UserPermission.CompanyHost);
  }
  get isSiteHost() {
    return this.userService.hasUserPermissionForCompany(UserPermission.SiteHost) && this.userService.hasCompanyFeature(ProductPlanFeature.SiteHosts);
  }

  get isCompanyAdmin() {
    return this.userService.isCompanyAdminUser();
  }

  get showTagedList() {
    return this.userService.hasUserPermissionForCompany(UserPermission.ManageViewTaggedUser) && this.userService.hasCompanyFeature(ProductPlanFeature.Automation);
  }

}

enum PermissionTab {
  CompanyHost,
  SiteHost,
  Frequents,
  Employees,
  TaggedList,
  Temporarily,
  Blocked,
}