<div class="row mb-1">
  <div class="col-12 col-lg-8">
    <button class="btn btn-default" [routerLink]="['/manage-companies']">
      <i class="fas fa-arrow-left mr-1"></i>
      Return to Company Management
    </button>
  </div>
</div>
<div class="card">
  <div class="card-header">
    <div class="d-flex flex-row justify-content-between">
      <div>Company Workflow Management for <strong>{{companyTitle}}</strong></div>
    </div>
  </div>
  <div class="card-body mt-2">
    <div class="row">
      <div class="col-md-12">
        <button class="btn btn-success" (click)="onAddWorkflow(addWorkflowForm)">
          Add/Update Workflow
        </button>
        <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>
          <ng-template obcGridTemplateName="workflowType" let-item="data">
            {{ item?.workflowType | WorkflowTypeFilter }}
          </ng-template>
          <ng-template obcGridTemplateName="creatorUserInfo" let-item="data">
            {{item?.creatorUserInfo?.fullName}}
            ({{item?.creatorUserInfo?.mobile}})
          </ng-template>
          <ng-template obcGridTemplateName="actions" let-item="data">
            <div class="d-flex align-items-start">

              <button class="btn btn-warning btn-sm" (click)="viewDetails(item,addWorkflowForm)">View
                Details
              </button>
            </div>
          </ng-template>
        </app-base-grid>
      </div>
    </div>
  </div>
</div>
<ng-template #addWorkflowForm>
  <obc-workflow-renderer [companyId]="companyId" [dataModel]="dataModel" [isInEditMode]="isEditMode"
    [selectedWorkflowTemplateId]="dataModel.workflowTemplateId"
    [defaultPlaceHolderValues]="dataModel.workflowInfo?.placeholderList"
    [defaultStartVariableValues]="dataModel.workflowInfo?.startVariables"
    (onClose)="onCloseWorkflowPopup()"></obc-workflow-renderer>
</ng-template>
