import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from './error.service';
import {
  SupplierFormHistoryViewModel,
  SupplierFormViewModel,
  SupplierViewModel
} from "../models/supplier-form/supplier-forms-view-mode";
import { AddSupplierFormModel } from "../models/supplier-form/add-supplier-form-model";
import { ServerResult, ServerResultData } from "../models/server-result-data";
import { FormDataViewModel } from "../models/form/form-data-view-model";
import { FormDataModel } from "../models/form/form-data-model";
import { SendSupplierFormsRequestModel } from "../models/email-content/site-supplier-hash-link-email-view-model";
import { FormDataDetailViewModel } from "../models/form/form-data-detail-view-model";
import { FormViewModelRequest } from '../models/form/form-view-model';

@Injectable({
  providedIn: 'root'
})
export class SupplierFormService {
  apiUrl: string = "/api/SupplierForms";

  constructor(private _http: HttpClient, private _errorService: ErrorService) {
  }

  getFormList(supplierId: number): Observable<SupplierViewModel> {
    return this._http.get<SupplierViewModel>(`${this.apiUrl}/forms/${supplierId}`);
  }

  addNewForm = (model: AddSupplierFormModel): Observable<SupplierFormViewModel> =>
    this._http.post<SupplierFormViewModel>(`${this.apiUrl}/add-form`, model)
      .pipe(catchError(this._errorService.handleError));

  removeForm = (model: AddSupplierFormModel): Observable<ServerResult> =>
    this._http.post<ServerResult>(`${this.apiUrl}/remove-form`, model)
      .pipe(catchError(this._errorService.handleError));

  getFormHistory(supplierId: number, formId: number): Observable<SupplierFormHistoryViewModel[]> {
    return this._http.get<SupplierFormHistoryViewModel[]>(`${this.apiUrl}/supplier-form-history/${supplierId}/${formId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  sendEmail = (model: SendSupplierFormsRequestModel) =>
    this._http.post(`${this.apiUrl}/send-supplier-forms-email`, model)
      .pipe(catchError(this._errorService.handleError));


  getAnonymousForms(hashLink: string): Observable<SupplierViewModel> {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this._http.get<SupplierViewModel>(`/api/AnonymousSupplierForms/forms`, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

  getSupplierForms(): Observable<SupplierViewModel[]> {
    return this._http.get<SupplierViewModel>(`/api/supplierforms/supplier-forms`)
      .pipe(catchError(this._errorService.handleError));
  }

  getFormsDetailsToFill(formId: number, hashLink: string, toolId: number): Observable<ServerResultData<FormDataViewModel>> {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this._http.post<ServerResultData<FormDataViewModel>>(`/api/AnonymousSupplierForms/form`, { formId: formId, toolId: toolId } as FormViewModelRequest, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

  saveForm(model: FormDataModel, hashLink: string): Observable<ServerResultData<number>> {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this._http.post<ServerResultData<number>>(`/api/AnonymousSupplierForms/fill-form`, model, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

  getAnonymousFilledForm(formId: number, hashLink: string): Observable<ServerResultData<FormDataDetailViewModel>> {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this._http.get<ServerResultData<FormDataDetailViewModel>>(`/api/AnonymousSupplierForms/get-filled-form/${formId}`, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

  getAnonymousFormHistory(formId: number, hashLink: string): Observable<SupplierFormHistoryViewModel[]> {
    let headers = new HttpHeaders({ "hash-link": hashLink ?? '' });
    return this._http.get<SupplierFormHistoryViewModel[]>(`/api/AnonymousSupplierForms/supplier-form-history/${formId}`, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

}
