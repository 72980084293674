import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SiteService } from 'src/app/services/site.service';
import { SiteViewModel } from 'src/app/models/site-viewmodel';
import { finalize } from 'rxjs/operators';
import { SiteAttendanceService } from 'src/app/services/site-attendance.service';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { ModalService } from 'src/app/services/modal.service';
import { SendMessageStatus } from 'src/app/enums/send-message-status';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'obc-emergency-alarm',
  templateUrl: './emergency-alarm.component.html',
  styleUrls: ['./emergency-alarm.component.scss']
})
export class EmergencyAlarmComponent implements OnInit {
  inProgress: boolean = false;
  selectedSite: SiteViewModel;
  totalCount: number;
  message: string;
  resultMessage: string = '';
  UserPermission = UserPermission;
  isAllSiteMode: boolean = false;
  isCompanyAdminOrGod: boolean = false;

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private siteAttendanceService: SiteAttendanceService,
    private modalService: ModalService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.isCompanyAdminOrGod = this.userService.isCompanyAdminUser();
  }

  getTotalCountOfCheckInSiteUser() {
    this.inProgress = true;
    this.siteAttendanceService.getTotalCountCheckInSite(this.selectedSite.id)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        this.totalCount = res;
      }, err => console.log(err));
  }

  sendMessage() {
    if (!this.message || this.message.trim() == '') {
      this.resultMessage = 'Please enter a message';
      return false;
    }
    this.inProgress = true;
    this.siteAttendanceService.sendEmergencyAlarm(this.selectedSite?.id, this.message)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(result => {
        if (result.status == SendMessageStatus.Success) {
          this.modalService.success(result.message);
          this.reset();
        }
        else if (result.status == SendMessageStatus.Warning) {
          this.modalService.warn(result.message);
          this.reset();
        }
        else if (result.status == SendMessageStatus.Failed) {
          this.modalService.error(result.message);
        }
      },
        error => this.resultMessage = "We can't send message, please try again")
  }
  changeSite($event: SiteViewModel) {
    this.totalCount = undefined;
    this.selectedSite = $event;
    if (this.selectedSite)
      this.getTotalCountOfCheckInSiteUser();
  }

  closeModal() {
    this.close.emit(true);
  }

  reset() {
    this.resultMessage = '';
    this.message = '';
  }

  onAllSiteModeSelected(value: boolean) {
    this.isAllSiteMode = value;
    this.totalCount = undefined;

    if (value)
      this.selectedSite = null;
  }
}
