import { SupplierDocumentReviewMode } from 'src/app/enums/supplier-document-review-mode.enum';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { SupplierDocumentApprovalMode } from 'src/app/enums/supplier-document-approval-mode.enum';
import { SupplierDocumentConfigMode } from 'src/app/enums/supplier-document-config-mode.enum';
import { CompanySupplierDocumentConfiguration } from 'src/app/models/supplier-document/company-supplier-document-configuration';
import { SupplierDocumentEmailReminder, SupplierDocumentExpiryNotificationReminderModel } from 'src/app/models/supplier-document/supplier-document-email-reminder-model';
import { CompanySettingService } from 'src/app/services/company-setting.service';
import { SupplierDocumentEditorType } from 'src/app/enums/supplier-document-editor-type';
import { UserService } from 'src/app/services/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SupplierDocumentExpiryNotificationPeriodType } from 'src/app/enums/supplier-document-expiry-notification-priod-type.enum';
import { WorkflowType } from 'src/app/enums/workflow/workflow-type';
import { WorkflowService } from 'src/app/services/workflow.service';
import { CheckListItemViewModel } from 'src/app/custom-controls/check-list-form-control/check-list-item';
import { WorkflowDefinitionViewModel } from 'src/app/models/workflow/workflow-definition-view-model';
import { DocumentKindService } from 'src/app/services/document-kind.service';

@Component({
  selector: 'obc-supplier-document-reminder',
  templateUrl: './supplier-document-reminder.component.html',
  styleUrls: ['./supplier-document-reminder.component.scss']
})
export class SupplierDocumentReminderComponent implements OnInit {
  supplierDocumentExpiryNotificationReminderModel: SupplierDocumentExpiryNotificationReminderModel;
  SupplierDocumentExpiryNotificationPeriodType = SupplierDocumentExpiryNotificationPeriodType;
  SupplierDocumentEditorType = SupplierDocumentEditorType;

  SupplierDocumentApprovalMode = SupplierDocumentApprovalMode;
  SupplierDocumentReviewMode = SupplierDocumentReviewMode;
  inProgress: boolean;
  supplierDocumentEmailReminder: SupplierDocumentEmailReminder;
  companySupplierDocumentConfiguration: CompanySupplierDocumentConfiguration;

  workflowDocumentKinds: WorkflowDefinitionViewModel[] = [];
  documentKindCheckList: CheckListItemViewModel[] = [];

  formGroup = new FormGroup({
    isActiveSupplierDocEmailReminder: new FormControl(false, [Validators.required]),
    supplierDocEmailReminderAtDaysCount: new FormControl(null, [Validators.required]),
    supplierDocEmailReminderSubject: new FormControl('', [Validators.required]),
    supplierDocEmailReminderBody: new FormControl('', [Validators.required]),
    supplierDocumentApprovalMode: new FormControl(SupplierDocumentApprovalMode.AutoApproveIfUploadedByStaff, [Validators.required]),
    forceViewSupplierDocumentsBeforeSignOff: new FormControl(false, [Validators.required]),
    forceEndUserToSign: new FormControl(false, [Validators.required]),
    supplierDocumentReviewMode: new FormControl(SupplierDocumentReviewMode.Mandatory, [Validators.required]),
    supplierDocumentRequestEmailBody: new FormControl(''),
    supplierFormsSubmitRequestEmailBody: new FormControl(''),
    supplierFullOnboardingEmailBody: new FormControl(''),
    supplierDocCommentEmailBody: new FormControl(''),
    supplierDocCommentAcknowledgementText: new FormControl(''),
    offsiteOnboardingEmailContent: new FormControl(''),
    supplierDocumentExpiryNotificationPeriod: new FormControl(SupplierDocumentExpiryNotificationPeriodType.Monthly),
    supplierDocumentExpiryNotificationAnyWorkflows: new FormControl(true),
    supplierDocumentWorkflowKinds: new FormControl(null),
    supplierDocExpiryNotificationEmailReminderSubject: new FormControl(),
    supplierDocExpiryNotificationEmailReminderBody: new FormControl(),
  });


  constructor(private companySettingService: CompanySettingService,
    private userService: UserService,
    private toastrService: ToastrService,
    private bsModalService: BsModalService,
    private workflowService: WorkflowService,
    private documentKindService: DocumentKindService) {
  }

  ngOnInit(): void {
    this.getData();

    this.formGroup.controls.supplierDocumentExpiryNotificationPeriod.valueChanges?.subscribe((_) => {
      this.UpdateFormValidity();
    });
  }

  UpdateFormValidity() {
    if (this.formGroup.controls.supplierDocumentExpiryNotificationPeriod.value == SupplierDocumentExpiryNotificationPeriodType.None) {
      this.formGroup.controls.supplierDocExpiryNotificationEmailReminderSubject.clearValidators();
      this.formGroup.controls.supplierDocExpiryNotificationEmailReminderBody.clearValidators();
    }
    else {
      this.formGroup.controls.supplierDocExpiryNotificationEmailReminderSubject.setValidators(Validators.required);
      this.formGroup.controls.supplierDocExpiryNotificationEmailReminderBody.setValidators(Validators.required);
    }

    this.formGroup.controls.supplierDocExpiryNotificationEmailReminderSubject.updateValueAndValidity();
    this.formGroup.controls.supplierDocExpiryNotificationEmailReminderBody.updateValueAndValidity();
  }

  getData() {
    this.inProgress = true;
    this.companySettingService.getSupplierDocumentConfiguration()
      .pipe(finalize(() => {
        this.inProgress = false
      }))
      .subscribe(res => {
        this.supplierDocumentEmailReminder = res.emailReminderInfo;
        this.companySupplierDocumentConfiguration = res.companySupplierDocumentConfiguration;
        this.supplierDocumentExpiryNotificationReminderModel = res.documentExpiryNotificationReminderInfo;

        this.fillFormGroup();
        this.getWorkflows();

      }, err => {
        this.toastrService.error(err);
      });
  }

  getWorkflows() {
    this.workflowService.getWorkflowListFull(WorkflowType.SupplierDocument)
      .subscribe(res => {
        this.workflowDocumentKinds = [];
        if (res) {
          res.filter(w => !w.isLegacyMode).forEach((value) => {
            this.workflowDocumentKinds.push(value)
          });

          this.documentKindService.prepareDocumentKindCheckList(this.supplierDocumentExpiryNotificationReminderModel.supplierDocumentKindIdList)
            .subscribe(res => {
              this.documentKindCheckList = res;
              this.formGroup.controls.supplierDocumentWorkflowKinds.setValue(this.documentKindCheckList);
            });
        }
      })
  }

  fillFormGroup() {
    this.formGroup.controls.isActiveSupplierDocEmailReminder.setValue(this.supplierDocumentEmailReminder.isActiveSupplierDocEmailReminder);
    this.formGroup.controls.supplierDocEmailReminderAtDaysCount.setValue(this.supplierDocumentEmailReminder.supplierDocEmailReminderAtDaysCount);
    this.formGroup.controls.supplierDocEmailReminderSubject.setValue(this.supplierDocumentEmailReminder.supplierDocEmailReminderSubject);
    this.formGroup.controls.supplierDocEmailReminderBody.setValue(this.supplierDocumentEmailReminder.supplierDocEmailReminderBody);
    this.formGroup.controls.supplierDocumentApprovalMode.setValue(this.companySupplierDocumentConfiguration.supplierDocumentApprovalMode);
    this.formGroup.controls.supplierDocumentReviewMode.setValue(this.companySupplierDocumentConfiguration.supplierDocumentReviewMode);
    this.formGroup.controls.forceViewSupplierDocumentsBeforeSignOff.setValue(this.companySupplierDocumentConfiguration.forceViewSupplierDocumentsBeforeSignOff ?? false);
    this.formGroup.controls.forceEndUserToSign.setValue(this.companySupplierDocumentConfiguration.supplierDocumentConfigMode != SupplierDocumentConfigMode.DisableSign);
    this.formGroup.controls.supplierDocumentRequestEmailBody.setValue(this.companySupplierDocumentConfiguration.supplierDocumentRequestEmailBody);
    this.formGroup.controls.supplierFormsSubmitRequestEmailBody.setValue(this.companySupplierDocumentConfiguration.supplierFormsSubmitRequestEmailBody);
    this.formGroup.controls.supplierFullOnboardingEmailBody.setValue(this.companySupplierDocumentConfiguration.supplierFullOnboardingEmailBody);
    this.formGroup.controls.supplierDocCommentEmailBody.setValue(this.companySupplierDocumentConfiguration.supplierDocCommentEmailBody);
    this.formGroup.controls.supplierDocCommentAcknowledgementText.setValue(this.companySupplierDocumentConfiguration.supplierDocCommentAcknowledgementText);
    this.formGroup.controls.offsiteOnboardingEmailContent.setValue(this.companySupplierDocumentConfiguration.offsiteOnboardingEmailContent);

    if (this.supplierDocumentExpiryNotificationReminderModel == null) {
      this.formGroup.controls.supplierDocumentExpiryNotificationPeriod.setValue(SupplierDocumentExpiryNotificationPeriodType.Monthly);
      this.formGroup.controls.supplierDocumentWorkflowKinds.setValue(null);
      this.formGroup.controls.supplierDocExpiryNotificationEmailReminderSubject.setValue('');
      this.formGroup.controls.supplierDocExpiryNotificationEmailReminderBody.setValue('');
    }
    else {
      this.formGroup.controls.supplierDocumentExpiryNotificationPeriod.setValue(this.supplierDocumentExpiryNotificationReminderModel.supplierDocumentExpiryNotificationPeriod);
      this.formGroup.controls.supplierDocumentExpiryNotificationAnyWorkflows.setValue(this.supplierDocumentExpiryNotificationReminderModel.supplierDocumentExpiryNotificationAnyWorkflows);
      this.formGroup.controls.supplierDocumentWorkflowKinds.setValue(this.supplierDocumentExpiryNotificationReminderModel.supplierDocumentKindIdList);
      this.formGroup.controls.supplierDocExpiryNotificationEmailReminderSubject.setValue(this.supplierDocumentExpiryNotificationReminderModel.supplierDocExpiryNotificationEmailReminderSubject);
      this.formGroup.controls.supplierDocExpiryNotificationEmailReminderBody.setValue(this.supplierDocumentExpiryNotificationReminderModel.supplierDocExpiryNotificationEmailReminderBody);
    }
  }

  getFromFormGroup() {
    this.supplierDocumentEmailReminder.isActiveSupplierDocEmailReminder = this.formGroup.controls.isActiveSupplierDocEmailReminder.value;
    this.supplierDocumentEmailReminder.supplierDocEmailReminderAtDaysCount = this.formGroup.controls.supplierDocEmailReminderAtDaysCount.value;
    this.supplierDocumentEmailReminder.supplierDocEmailReminderSubject = this.formGroup.controls.supplierDocEmailReminderSubject.value;
    this.supplierDocumentEmailReminder.supplierDocEmailReminderBody = this.formGroup.controls.supplierDocEmailReminderBody.value;

    this.companySupplierDocumentConfiguration.supplierDocumentApprovalMode = this.formGroup.controls.supplierDocumentApprovalMode.value;
    this.companySupplierDocumentConfiguration.supplierDocumentReviewMode = this.formGroup.controls.supplierDocumentReviewMode.value;
    this.companySupplierDocumentConfiguration.forceViewSupplierDocumentsBeforeSignOff = this.formGroup.controls.forceViewSupplierDocumentsBeforeSignOff.value;
    this.companySupplierDocumentConfiguration.supplierDocumentConfigMode = this.formGroup.controls.forceEndUserToSign.value == true ? SupplierDocumentConfigMode.MandatorySign : SupplierDocumentConfigMode.DisableSign;
    this.companySupplierDocumentConfiguration.supplierDocumentRequestEmailBody = this.formGroup.controls.supplierDocumentRequestEmailBody.value;
    this.companySupplierDocumentConfiguration.supplierFormsSubmitRequestEmailBody = this.formGroup.controls.supplierFormsSubmitRequestEmailBody.value;
    this.companySupplierDocumentConfiguration.supplierFullOnboardingEmailBody = this.formGroup.controls.supplierFullOnboardingEmailBody.value;
    this.companySupplierDocumentConfiguration.supplierDocCommentEmailBody = this.formGroup.controls.supplierDocCommentEmailBody.value;
    this.companySupplierDocumentConfiguration.supplierDocCommentAcknowledgementText = this.formGroup.controls.supplierDocCommentAcknowledgementText.value;
    this.companySupplierDocumentConfiguration.offsiteOnboardingEmailContent = this.formGroup.controls.offsiteOnboardingEmailContent.value;

    this.supplierDocumentExpiryNotificationReminderModel.supplierDocumentExpiryNotificationPeriod = this.formGroup.controls.supplierDocumentExpiryNotificationPeriod.value;
    this.supplierDocumentExpiryNotificationReminderModel.supplierDocumentExpiryNotificationAnyWorkflows = this.formGroup.controls.supplierDocumentExpiryNotificationAnyWorkflows.value;
    this.supplierDocumentExpiryNotificationReminderModel.supplierDocumentKindIdList =
      this.supplierDocumentExpiryNotificationReminderModel.supplierDocumentExpiryNotificationAnyWorkflows == true ?
        [] :
        this.formGroup.controls.supplierDocumentWorkflowKinds.value?.filter(item => item.value)?.map(item => item.key);
    this.supplierDocumentExpiryNotificationReminderModel.supplierDocExpiryNotificationEmailReminderSubject = this.formGroup.controls.supplierDocExpiryNotificationEmailReminderSubject.value;
    this.supplierDocumentExpiryNotificationReminderModel.supplierDocExpiryNotificationEmailReminderBody = this.formGroup.controls.supplierDocExpiryNotificationEmailReminderBody.value;
  }

  onActiveChanged(value) {
    this.formGroup.controls.isActiveSupplierDocEmailReminder.setValue(value);
  }

  onForceViewSupplierDocumentsBeforeSignOffChanged(value) {
    this.formGroup.controls.forceViewSupplierDocumentsBeforeSignOff.setValue(value);
  }

  onSaveSupplierDocumentEmailReminder() {
    this.inProgress = true;
    this.getFromFormGroup();
    this.companySettingService.saveSupplierDocumentEmailReminderInfo(this.supplierDocumentEmailReminder)
      .pipe(finalize(() => {
        this.inProgress = false
      }))
      .subscribe(res => {
        this.toastrService.success('Saved Successfully');
      }, err => {
        this.toastrService.error(err);
      });
  }

  isValidForm: boolean = true;
  maxLengthErrorList = [];

  ValidateForms() {
    this.isValidForm = true;
    this.maxLengthErrorList = [];
    if (this.companySupplierDocumentConfiguration?.supplierDocumentRequestEmailBody.length > this.userService.editorMaxLength.value) {
      this.isValidForm = false;
      this.maxLengthErrorList.push("Document request");
    }

    if (this.companySupplierDocumentConfiguration?.supplierFormsSubmitRequestEmailBody.length > this.userService.editorMaxLength.value) {
      this.isValidForm = false;
      this.maxLengthErrorList.push("Form fill out request");
    }

    if (this.companySupplierDocumentConfiguration?.supplierFullOnboardingEmailBody.length > this.userService.editorMaxLength.value) {
      this.isValidForm = false;
      this.maxLengthErrorList.push("Full onboarding");
    }

    if (this.companySupplierDocumentConfiguration?.offsiteOnboardingEmailContent.length > this.userService.editorMaxLength.value) {
      this.isValidForm = false;
      this.maxLengthErrorList.push("Onboarding email offsite");
    }

    if (this.companySupplierDocumentConfiguration?.supplierDocCommentEmailBody.length > this.userService.editorMaxLength.value) {
      this.isValidForm = false;
      this.maxLengthErrorList.push("Document version comment");
    }
  }

  bsModalRef: any;
  onSaveCompanySupplierDocumentConfiguration(template) {
    this.getFromFormGroup();
    this.ValidateForms();
    if (!this.isValidForm)
      this.bsModalRef = this.bsModalService.show(template, {
        class: "modal-lg",
        ignoreBackdropClick: false,
      });
    else {
      this.inProgress = true;
      this.companySettingService.saveSupplierDocumentConfiguration(this.companySupplierDocumentConfiguration)
        .pipe(finalize(() => {
          this.inProgress = false
        }))
        .subscribe(res => {
          this.toastrService.success('Saved Successfully');
        }, err => {
          this.toastrService.error(err);
        });
    }
  }

  onChangeSupplierDocumentConfigMode(value) {
    this.formGroup.controls.forceEndUserToSign.setValue(value == true ? SupplierDocumentConfigMode.MandatorySign : SupplierDocumentConfigMode.DisableSign);
  }


  supplierDocumentExpiryNotificationEmailIsValid(): boolean {
    this.getFromFormGroup();

    if (this.supplierDocumentExpiryNotificationReminderModel?.supplierDocumentExpiryNotificationPeriod != SupplierDocumentExpiryNotificationPeriodType.None &&
      (this.supplierDocumentExpiryNotificationReminderModel?.supplierDocExpiryNotificationEmailReminderSubject.length == 0 ||
        this.supplierDocumentExpiryNotificationReminderModel?.supplierDocExpiryNotificationEmailReminderBody.length == 0)) {
      return false;
    }
    return true;
  }

  onSaveSupplierDocumentExpiryNotificationEmailReminder() {

    if (this.supplierDocumentExpiryNotificationEmailIsValid()) {
      this.inProgress = true;
      this.companySettingService.saveSupplierDocumentexpiryEmailnotificationReminderInfo(this.supplierDocumentExpiryNotificationReminderModel)
        .pipe(finalize(() => {
          this.inProgress = false
        }))
        .subscribe(res => {
          this.toastrService.success('Saved Successfully');
        }, err => {
          this.toastrService.error(err);
        });
    }
    else {
      this.toastrService.error("Please fill supplier document expiry notification configuration");
    }
  }

  IsSpecificWorkflow(): boolean {
    return !(this.formGroup.controls.supplierDocumentExpiryNotificationAnyWorkflows.value === true);
  }
}


