import { Component, OnInit, Input, Output } from '@angular/core';
import { ChipModel } from 'src/app/models/chip-model';
import { EventEmitter } from '@angular/core';
import { Subscription, Observable } from 'rxjs';

@Component({
  selector: 'obc-chip-container',
  templateUrl: './chip-container.component.html',
  styleUrls: ['./chip-container.component.scss']
})
export class ChipContainerComponent implements OnInit {
  @Input() label: string = "Chip Container";
  @Output() onChipsChanged = new EventEmitter<ChipModel[]>();
  @Input() chips: ChipModel[] = [];
  @Input() chipLists: ChipModel[] = [];
  newChip: string = "";
  constructor() { }

  ngOnInit() {

  }

  onKeyPress($event: KeyboardEvent) {
    $event.stopPropagation();
    if ($event.keyCode == 13) { // press enter 
      this.pushNewChip();
    }
  }

  onSelectChip($event) {
    this.pushNewChip($event.item);
  }

  onRemoveChip(chip: ChipModel) {
    var index = this.chips.indexOf(chip);
    this.chips.splice(index, 1);
    this.onChipsChanged.emit(this.chips);
  }

  pushNewChip(selected?: ChipModel) {
    var isNewChipChipAdd = false;
    if (!this.chips)
      this.chips = [];
    if (selected && !this.chips.filter(i => i.label == selected.label).length) {
      this.chips.push(selected);
      this.newChip = "";
      isNewChipChipAdd = true;
    } else if (this.newChip && !this.chips.filter(i => i.label.toLowerCase() == this.newChip.toLowerCase()).length) {
      this.chips.push({
        label: this.newChip,
        removable: true,
        tagged: null,
      });
      this.newChip = "";
      isNewChipChipAdd = true;
    }
    if (isNewChipChipAdd)
      this.onChipsChanged.emit(this.chips);
  }


}
