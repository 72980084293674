import { Component, Input, OnInit } from '@angular/core';
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import { QuestionType } from 'src/app/enums/question-type';
import { uuid4 } from 'src/app/helpers/uuid';
import { QuestionParameter } from 'src/app/models/questionParameters/question-parameter';
import { TextRenderMode } from 'src/app/models/questionParameters/text-render-mode-type';
import { TextQuestionParameter } from 'src/app/models/questionParameters/textQuestionParameter';
import { SiteQuestionAnswerViewModel } from 'src/app/models/site_question_answer_view_model';

@Component({
  selector: 'obc-rich-text-renderer',
  templateUrl: './rich-text-renderer.component.html',
  styleUrls: ['./rich-text-renderer.component.scss']
})
export class RichTextRendererComponent implements OnInit {
  id: any;
  constructor() { }
  @Input() answer: SiteQuestionAnswerViewModel;
  ngOnInit() {
    this.id = 'a' + uuid4();
  }
  ngAfterViewInit(): void {
    var parameter = QuestionParameter.getParameter<TextQuestionParameter>(
      this.answer.questionParameters
    );
    if (
      parameter.textRenderMode == TextRenderMode.RichText &&
      this.answer.questionType == QuestionType.Text
    ) {
      new Viewer({
        el: document.querySelector(`#${this.id}`),
        initialValue: this.answer.answer,
      });
    }
  }

}
