import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'obc-procore-configuration-access-code-callback',
  templateUrl: './procore-configuration-access-code-callback.component.html',
  styleUrls: ['./procore-configuration-access-code-callback.component.scss']
})
export class ProcoreConfigurationAccessCodeCallbackComponent implements OnInit {
  success: boolean;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((res) => {
      this.success = res['success'] == '1';
      window.opener.procoreConfigCompleted();
      setTimeout(() => window.close(), 5000);
    });
  }

}
