import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseSiteRequiredFieldViewModel, SiteRequiredFieldState, SiteRequiredFieldType, SiteRequiredRenderType, SiteRequiredFieldValue } from 'src/app/models/site-required-field-viewmodel';
import { FormArray, ValidatorFn, Validators } from '@angular/forms';
import { CustomFormControl } from 'src/app/models/custom-formcontrol';
import { e168ValidatorNullable } from 'src/app/helpers/mobile-format-validator';
import { BehaviorSubject } from 'rxjs';
import { SiteRequiredFieldWithAnswerViewModel } from '../../../models/site-required-field-with-answer-view-model';
import { DatetimePipe } from 'src/app/pipes/datetime.pipe';
import { UserBusinessViewModel } from '../../../models/user-business-viewmodel';
import { validatedMandatoryValidator } from 'src/app/helpers/validate-mandatory-business-validator';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { EmergencyContactEmitResponse } from "../../../models/emergency-contact-emit-response";

@Component({
  selector: 'obc-required-field-renderer',
  templateUrl: './required-field-renderer.component.html',
  styleUrls: ['./required-field-renderer.component.css']
})
export class RequiredFieldRendererComponent implements OnInit {
  DatetimePickerMode = DatetimePickerMode;
  formArray: FormArray;
  siteRequiredFieldTypes = SiteRequiredFieldType;
  siteRequiredFieldStates = SiteRequiredFieldState;
  siteRequiredRenderTypes = SiteRequiredRenderType;
  newBusiness: UserBusinessViewModel;

  today: Date;

  _valueChanged: BehaviorSubject<{ valid: boolean, values: SiteRequiredFieldValue[] }>;
  @Output() supplierChanged = new EventEmitter();
  @Input() sessionId: string = null;
  @Input() siteId;
  @Input() supplierId;
  @Input() set valueChanged(model: BehaviorSubject<{ valid: boolean, values: SiteRequiredFieldValue[] }>) {
    this._valueChanged = model;
    this.notifyChanges();
  };
  @Input() set requiredFields(models: SiteRequiredFieldWithAnswerViewModel[]) {
    if (models == null || models.length == 0) return;
    this.formArray = new FormArray([]);
    for (let i = 0; i < models.length; i++) {
      const element = models[i];
      var ctrl: CustomFormControl = null;
      if (element.fieldType === SiteRequiredFieldType.Business) {
        if (element.answer) {
          var item = JSON.parse(element.answer);
          item.mode = 'Selected';
          element.answer = item;
          ctrl = new CustomFormControl(item.name, element, this.getValidator(element));
        } else {
          ctrl = new CustomFormControl('', element, this.getValidator(element));
          element.answer = ({ name: '', mode: 'UserValue' } as UserBusinessViewModel as any);
        }
      }
      else
        ctrl = new CustomFormControl(element.answer, element, this.getValidator(element));

      ctrl.valueChanges.subscribe(res => {
        this.notifyChanges();
      });

      if (element.fieldType === SiteRequiredFieldType.Supplier) {
        ctrl.setValue(this.supplierId);
        ctrl.valueChanges.subscribe(res => {
          this.supplierChanged.emit(res);
        });
      }

      if (element.fieldType === SiteRequiredFieldType.Date && element.fieldKey == "BirthDay") {
        this.today = new Date();
      }
      this.formArray.controls.push(ctrl);
    }
    this.notifyChanges();
  }

  constructor(private datetimePipe: DatetimePipe) { }

  private notifyChanges() {
    if (this._valueChanged)
      this._valueChanged.next({
        valid: this.isValid,
        values: this.answers,
      });
  }

  ngOnInit() {
  }

  getRenderType(fieldType: SiteRequiredFieldType) {
    if (fieldType == this.siteRequiredFieldTypes.Email ||
      fieldType == this.siteRequiredFieldTypes.Mobile ||
      fieldType == this.siteRequiredFieldTypes.Text)
      return this.siteRequiredRenderTypes.Input;
    else if (fieldType == this.siteRequiredFieldTypes.SingleSelect)
      return this.siteRequiredRenderTypes.DropDown;
    else if (fieldType == this.siteRequiredFieldTypes.Date)
      return this.siteRequiredRenderTypes.Date;
    else if (fieldType == this.siteRequiredFieldTypes.Business)
      return this.siteRequiredRenderTypes.Business;
    else if (fieldType == this.siteRequiredFieldTypes.Supplier)
      return this.siteRequiredRenderTypes.Supplier;
    else if (fieldType == this.siteRequiredFieldTypes.EmergencyContact)
      return this.siteRequiredRenderTypes.EmergencyContact;
  }

  isRequired(model: BaseSiteRequiredFieldViewModel) {
    return model.state == this.siteRequiredFieldStates.mandatory;
  }

  private getValidator(model: SiteRequiredFieldWithAnswerViewModel): ValidatorFn[] {
    var list: ValidatorFn[] = [];
    if (model.state == this.siteRequiredFieldStates.mandatory || model.state == this.siteRequiredFieldStates.validatedMandatory)
      list.push(Validators.required);

    if (model.fieldKey == "Business" && model.state == this.siteRequiredFieldStates.validatedMandatory)
      list.push(validatedMandatoryValidator);

    switch (model.fieldType) {
      case this.siteRequiredFieldTypes.Email:
        list.push(Validators.email);
        break;
      case this.siteRequiredFieldTypes.Mobile:
        list.push(e168ValidatorNullable);
        break;
    }
    return list;
  }

  get isValid(): boolean {
    return this.formArray.controls.every((c) => c.valid);
  }

  getEntries(items) {
    if (items == null) return null;
    return Object.entries(items);
  }
  get answers(): SiteRequiredFieldValue[] {
    if (!this.isValid)
      return null;
    var answers: SiteRequiredFieldValue[] = [];
    this.formArray.controls.forEach(element => {
      var cctrl = element as CustomFormControl;
      var value = this.getFixedValue(cctrl);
      answers.push({
        fieldKey: cctrl.item.fieldKey,
        value: cctrl.item.fieldType != this.siteRequiredFieldTypes.Business ? value : JSON.stringify(cctrl.item.answer),
      } as SiteRequiredFieldValue);
    });
    return answers;
  }

  getFixedValue(control: CustomFormControl): any {
    if (this.getRenderType(control.item.fieldType) == this.siteRequiredRenderTypes.Date) {
      var value = this.datetimePipe.transform(control.value);
      return value;
    }
    return control.value;
  }
  onShowBusinessAutoComplete(control: CustomFormControl) {
    var businessControl = this.formArray.controls.find(c => c == control) as CustomFormControl;
    businessControl.item.answer = <UserBusinessViewModel>{ abn: '', acn: '', name: '', postcode: '', state: '' };
    businessControl.setValue(businessControl.item.answer.name);
  }
  onSelectBusiness($event: UserBusinessViewModel, control: CustomFormControl): void {
    this.newBusiness = $event;
    if (this.newBusiness.abn || this.newBusiness.acn || this.newBusiness.name) {
      var businessControl = this.formArray.controls.find(c => c == control) as CustomFormControl;
      businessControl.item.answer = this.newBusiness;
      businessControl.setValue(this.newBusiness.name);
    }
  }

  onUpdate(response: EmergencyContactEmitResponse, control: CustomFormControl) {
    let emergencyControl = this.formArray.controls.find(c => c == control) as CustomFormControl;
    if(!emergencyControl) return;
    if(response.value) {
      emergencyControl.item.answer = response.value;
      emergencyControl.setValue(JSON.stringify(response.value));
    } else {
      emergencyControl.setValue(null);
      if(!response.isValid) {
        emergencyControl.addValidators(Validators.required);
      } else {
        emergencyControl.clearValidators();
      }
      emergencyControl.updateValueAndValidity();
    }
  }
}
