<div class="card">
  <div class="card-body">
      <div class="row">
          <div class="col-md-12 d-flex justify-content-between mb-3" dir="rtl">
              <button dir="ltr" tooltip="Select / Remove Widget(s)" class="btn btn-lg btn-info"
                  *ngIf="userService.isCompanyAdminUser()" (click)="manageWidgets(manageWidgetsModal)">
                  <i class="fa fa-cog pr-1"></i> Manage Widgets
              </button>
              <div class="form-inline" dir="ltr">
                  <div class="form-group">
                      <label class="mr-1">Report Range</label>
                      <select [(ngModel)]="period" (change)="init()" style="width: 160px;"
                          class="custom-select form-control" dir="ltr">
                          <option [ngValue]="0">Week</option>
                          <option [ngValue]="1">1 Month</option>
                          <option [ngValue]="2">3 Month</option>
                          <option [ngValue]="3">6 Month</option>
                          <option [ngValue]="4">Year</option>
                      </select>
                  </div>
              </div>

          </div>
          <div class="col-md-12" *ngIf="widgets">
              <div class="row">
                  <ng-container *ngFor="let widget of widgets">
                      <div class="col-lg-6 col-sm-12" *ngIf="widget.type == 0">
                          <section class="card card-featured-left card-featured-secondary mb-3">
                              <div class="card-body">
                                  <obc-summary-widget [data]="widget"></obc-summary-widget>
                              </div>
                          </section>
                      </div>
                      <div class="col-lg-6 col-sm-12 mb-3" *ngIf="widget.type == 1">
                          <div class="chart">
                              <obc-chart [chartModel]="getChart(widget)"></obc-chart>
                          </div>
                      </div>
                      <div [id]="widget.id" [ngClass]="widget.width" ngStyle class="mb-3" *ngIf="widget.type == 2 && widget?.url">
                        <iframe class="fw fh" [src]="widget.url | safe: 'resourceUrl'"></iframe>
                      </div>
                      <!--  -->
                  </ng-container>
              </div>
          </div>

          <ng-template #manageWidgetsModal>
              <div class="card">
                  <div class="card-header">
                      <span>
                          Select / Remove Widget(s)
                      </span>
                      <button type="button" class="close" (click)="closeModal();">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="card-body">
                      <div class="table-responsive">
                          <table class="table table-bordered table-striped mt-3 modal-table">
                              <thead>
                                  <tr>
                                      <td>Widget</td>
                                      <td></td>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let widgetModel of unusedWidgets">
                                      <td>{{widgetModel.title}}</td>
                                      <td style="text-align: right;"><button class="btn btn-sm btn-success"
                                              (click)="addWidget(widgetModel)" tooltip="add widget"><i
                                                  class="fa fa-plus"></i></button></td>
                                  </tr>
                                  <tr *ngIf="!unusedWidgets || unusedWidgets.length == 0">
                                      <td colspan="2">
                                          <p class="text-center">all widgets are selected</p>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <accordion>
                          <accordion-group>
                              <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                                  <div class="pull-left float-left">Add External Widget</div>
                              </button>
                              <div [formGroup]="externalWidgetFormGroup">
                                  <div class="form-group col-md-12">
                                      <label>Widget Title:</label>
                                      <input class="form-control" formControlName="title">
                                  </div>
                                  <div class="form-group col-md-12">
                                      <label>Url:</label>
                                      <input class="form-control" formControlName="url">
                                  </div>
                                  <div class="form-group col-md-12">
                                      <label>Width:</label>
                                      <select class="custom-select form-control" formControlName="width">
                                          <option value="col-lg-12 col-sm-12">Full Width</option>
                                          <option value="col-lg-6 col-sm-12">Half Width</option>
                                      </select>
                                  </div>
                                  <div class="form-group col-md-12">
                                      <label>Height:</label>
                                      <input type="number" class="form-control"
                                          formControlName="height">
                                  </div>
                                  <div class="form-group col-md-12">
                                      <label>Unit:</label>
                                      <select class="custom-select form-control" formControlName="unit">
                                          <option value="pixel">Fix Height</option>
                                          <option value="precent">% Reletive to width</option>
                                      </select>
                                  </div>
                                  <div class="col-md-12 mt-3">
                                      <p class="button-group">
                                          <button type="submit" class="btn btn-primary"
                                              [disabled]="!externalWidgetFormGroup.valid"
                                              (click)="addExternalWidget()">Add</button>
                                      </p>
                                  </div>
                              </div>
                          </accordion-group>
                      </accordion>
                      <div class="table-responsive">
                          <table class="table table-bordered table-striped mt-3 modal-table">
                              <thead>
                                  <tr>
                                      <td>Company Widget(s)</td>
                                      <td>Order</td>
                                      <td></td>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let companyWidgetModel of orderedCompanyWidgets">
                                      <td>{{companyWidgetModel.title}}</td>
                                      <td>
                                          <div class="d-flex justify-content-center">
                                              <button class="border-0 bg-transparent" tooltip="move up"
                                                  (click)="changeOrder(companyWidgetModel , true)">
                                                  <i class="fas fa-arrow-up pointer text-success mr-1"></i>
                                              </button>
                                              <button class="border-0 bg-transparent" tooltip="move down"
                                                  (click)="changeOrder(companyWidgetModel , false)">
                                                  <i class="fas fa-arrow-down text-danger pointer"></i>
                                              </button>
                                          </div>
                                      </td>
                                      <td style="text-align: right;"><button class="btn btn-sm btn-danger"
                                              (click)="removeWidget(companyWidgetModel)" tooltip="remove widget"><i
                                                  class="fa fa-times"></i></button></td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <div style="text-align: right;">
                          <button class="btn btn-success" (click)="onSaveChanges()">Save Changes</button>
                      </div>
                  </div>
              </div>
          </ng-template>
      </div>
  </div>
</div>
