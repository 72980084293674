import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserAutoCompleteFilterByFields } from 'src/app/enums/user-auto-complete-target-fields';
import { UserBasicInfoViewModel } from 'src/app/models/user-basic-info-viewmodel';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  model: UserBasicInfoViewModel = null;
  _userId = null;
  @Input() set userId(value : number) {
    if(this._userId != value && value > 0)
    {
      this._userId = value;
      this.userService.getAccessibleUserBasicProfile(value).subscribe(res=> {
        this.model = res;
      })
    } 
  }
  @Input() label = "Select User";
  @Output() remove = new EventEmitter();
  @Output() valueChange = new EventEmitter();
  @Input() hideRemoveButton = false;
  @Input() required = true;
  filterUserBy = UserAutoCompleteFilterByFields;
  formControl = new FormControl();
  constructor(private userService : UserService) { }

  ngOnInit() {
    this.formControl.valueChanges.subscribe(res=> {
      this.valueChange.emit(res);
    });
  }

  onRemove() {
    this.model = null;
    this.formControl.reset();
    this.remove.emit();
  }

  onSelectUser($event : UserBasicInfoViewModel) {
    if($event.id > 0) {
      this.formControl.setValue($event.id); 
      this.model = $event; 
      this._userId = $event.id
    }
  }

}
