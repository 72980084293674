<form [formGroup]="form" class="clearfix">
  <div *ngIf="!displayInRow" class="row flex-column">
    <div class="d-flex align-items-center mt-1">
      <ng-container *ngTemplateOutlet="nameTemplate"></ng-container>
    </div>
    <div class="d-flex align-items-center mt-1">
      <ng-container *ngTemplateOutlet="mobileTemplate"></ng-container>
    </div>
    <div class="d-flex align-items-center mt-1">
      <ng-container *ngTemplateOutlet="relationshipTemplate"></ng-container>
    </div>
  </div>
  <div *ngIf="displayInRow">
    <div class="row">
      <div class="col-12 col-md-4 d-flex flex-column align-content-start">
        <ng-container *ngTemplateOutlet="nameTemplate"></ng-container>
      </div>
      <div class="col-12 col-md-4 d-flex flex-column align-content-start">
        <ng-container *ngTemplateOutlet="mobileTemplate"></ng-container>
      </div>
      <div class="col-12 col-md-4 d-flex flex-column align-content-start">
        <ng-container *ngTemplateOutlet="relationshipTemplate"></ng-container>
      </div>
    </div>
  </div>
</form>

<ng-template #nameTemplate>
  <ng-container [formGroup]="form">
    <div class="title mr-2 {{ displayInRow ? 'mb-1' : ''}}" style="{{ labelStyle }}">Name {{ displayDots ? ':' : ''}}</div>
    <input type="text" class="form-control" formControlName="name">
  </ng-container>
</ng-template>
<ng-template #mobileTemplate>
  <ng-container [formGroup]="form">
    <div class="title mr-2 {{ displayInRow ? 'mb-1' : ''}}" style="{{ labelStyle }}">Mobile {{ displayDots ? ':' : ''}}</div>
    <input type="text" class="form-control" formControlName="mobile">
  </ng-container>
</ng-template>
<ng-template #relationshipTemplate>
  <ng-container [formGroup]="form">
    <div class="title mr-2 {{ displayInRow ? 'mb-1' : ''}}" style="{{ labelStyle }}">Relationship {{ displayDots ? ':' : ''}}</div>
    <input type="text" class="form-control" formControlName="relationship">
  </ng-container>
</ng-template>

