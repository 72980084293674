import { Component, ComponentFactoryResolver, ContentChild, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewContainerRef } from '@angular/core';
import { QuestionRepository } from "../../../enums/question-repo";
import { SiteViewModel } from "../../../models/site-viewmodel";
import { AccompanierMode } from "../../../enums/accompanier-mode";
import { SiteQuestionsViewModel, SiteQuestionWithVisitorTypeViewModel } from "../../../models/sitequestion-viewmodel";
import { QuestionViewModel } from "../../../models/question-viewmodel";
import { SiteRequiredUserDocumentWithVisitorTypeViewModel } from "../../../models/site-required-user-document-viewmodel";

import {
  SiteVisitorTypeAccompanierViewModel,
  SiteVisitorTypeViewModel
} from "../../../models/site-visitortype-accompanier-viewmodel";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { QuestionService } from "../../../services/question.service";
import { ModalService } from "../../../services/modal.service";
import { UserService } from "../../../services/user.service";
import { VisitorTypeService } from "../../../services/visitor-type.service";
import { SiteRequiredUserDocumentService } from "../../../services/site-required-user-document.service";
import { SiteRequiredFieldService } from "../../../services/site-required-field.service";
import { SiteQuestionService } from "../../../services/site-question.service";
import { finalize } from "rxjs/operators";
import { QuestionType } from "../../../enums/question-type";
import { ProductPlanFeature } from 'src/app/enums/product-plan-feature.enum';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { SiteTemplateStatus } from 'src/app/enums/site-template-status.enum';
import { ManageSiteQuestionVisitorTypeMode } from 'src/app/enums/manage-site-question-visitor-type-mode.enum';
import { deepClone, getCountLabel, isEqual } from 'src/app/helpers/general-functions';
import { SiteRequiredFieldState, SiteRequiredFieldType, SiteRequiredFieldWithVisitorTypeViewModel, } from "../../../models/site-required-field-viewmodel";
import { ManageSiteViewModel } from "../../../models/manage-site-view-model";
import { CheckedVisitorType } from "../../../models/visitor-type";
import { UserDocumentTypeService } from "../../../services/user-document-type.service";
import { UserDocumentType } from "../../../models/user-document-type";
import { DirtyComponentItem, DirtyComponents } from "../../../models/dirty-components";
import { FormType } from "../../../enums/form-type";
import { SiteManagementMode } from "../../../enums/site-management-mode";
import { BehaviorSubject, of, Subject } from 'rxjs';

@Component({
  selector: 'obc-site-questions-management',
  templateUrl: './site-questions-management.component.html',
  styleUrls: ['./site-questions-management.component.scss']
})
export class SiteQuestionsManagementComponent implements OnInit {

  @Input() siteId: number;
  @Input() siteSupplierId: number;
  @Input() siteManagementMode: SiteManagementMode;
  SiteManagementModeEnum = SiteManagementMode;

  FormType = FormType;

  dirtyComponents: DirtyComponents;

  @Input('dirtyComponents') set setDirtyComponents(dirtyComponents: DirtyComponents) {
    this.dirtyComponents = dirtyComponents;
  }

  manageSiteModel: ManageSiteViewModel;

  @Input() set manageSiteViewModel(manageSiteModel: ManageSiteViewModel) {
    this.manageSiteModel = manageSiteModel;
    this.getSiteRelatedData();

    this.initializeCopies();
  }

  @Output() manageSiteViewModelChange = new EventEmitter<ManageSiteViewModel>()


  ProductPlanFeature = ProductPlanFeature;
  qRepository = QuestionRepository;
  siteList: SiteViewModel[] = [];
  progress = false;
  filterCheckinQuestionTerm: string = '';
  filterCheckoutQuestionTerm: string = '';
  filterDocumentsTerm: string = '';
  accompanierMode = AccompanierMode;
  UserPermission = UserPermission;
  SiteTemplateStatus = SiteTemplateStatus;
  _siteQuestions: SiteQuestionsViewModel[] = [];
  ManageSiteQuestionVisitorTypeMode = ManageSiteQuestionVisitorTypeMode;
  activeModalRef: BsModalRef
  getQuestionCompleted: Subject<void>

  get siteQuestions(): SiteQuestionsViewModel[] {
    return this._siteQuestions.sort((p, n) => p.displayOrder - n.displayOrder);
  };

  isDeprecated(field: SiteRequiredFieldWithVisitorTypeViewModel) {
    return field.fieldType !== SiteRequiredFieldType.Supplier;
  }

  shouldDisplay(field: SiteRequiredFieldWithVisitorTypeViewModel) {
    return this.userService.isGodUser() ||
      !this.isDeprecated(field) ||
      field.state !== SiteRequiredFieldState.ignore;
  }

  set siteQuestions(questions: SiteQuestionsViewModel[]) {
    this._siteQuestions = questions;
  };

  _companyQuestions: QuestionViewModel[] = [];
  get companyQuestions(): QuestionViewModel[] {
    return this._companyQuestions.filter(function (x) {
      return this.indexOf(x.id) < 0
    }, this.siteQuestions.map(c => c.questionId));
  };

  set companyQuestions(questions: QuestionViewModel[]) {
    this._companyQuestions = questions;
  };

  get allDocuments(): SiteRequiredUserDocumentWithVisitorTypeViewModel[] {
    return this.siteRequiredUserDocuments?.filter(function (x) {
      return this.indexOf(x.id) < 0
    }, this.requiredDocuments.map(c => c.id));
  };

  set allDocuments(data: SiteRequiredUserDocumentWithVisitorTypeViewModel[]) {
    this.siteRequiredUserDocuments = JSON.parse(JSON.stringify(data));
  };

  get allDocumentsOriginal(): SiteRequiredUserDocumentWithVisitorTypeViewModel[] {
    return this.originalSiteRequiredUserDocuments?.filter(function (x) {
      return this.indexOf(x.id) < 0
    }, this.requiredDocuments.map(c => c.id));
  };

  get requiredDocuments(): SiteRequiredUserDocumentWithVisitorTypeViewModel[] {
    return this.originalSiteRequiredUserDocuments?.filter(c => c.checked === true);
  };

  get visitorTypesHeaderInfo() {
    const data =
      [{ 'title': 'Status: ', 'value': this.visitorTypesStatus ? 'Enabled' : 'Disabled' }];
    if (this.visitorTypesStatus == true)
      data.push({ 'title': 'Visitor Types: ', 'value': this.displayVisitorTypes() });
    return data;
  }

  get documentExtraInfoHeaderInfo() {
    return [
      { 'title': '', 'value': getCountLabel(this.requiredDocuments?.length, 'Document') },
      { 'title': '', 'value': getCountLabel(this.countRequiredFields(), 'Extra Information', true) }
    ];
  }

  get checkOutQuestionHeaderInfo() {
    return [{ 'title': '', 'value': getCountLabel(this.siteCheckOutQuestions?.length, 'Question') }];
  }

  get checkInQuestionHeaderInfo() {
    return [{ 'title': '', 'value': getCountLabel(this.siteCheckInQuestions?.length, 'Question') }];
  }

  selectedSiteName: string = "";
  siteRequiredUserDocuments: SiteRequiredUserDocumentWithVisitorTypeViewModel[];
  originalSiteRequiredUserDocuments: SiteRequiredUserDocumentWithVisitorTypeViewModel[];
  visitorTypes: any[];
  //siteRequiredField: ResponseSiteRequiredFieldViewModel;
  requiredDocumentProcess: boolean = false;
  requiredFieldProcess: boolean = false;
  visitorTypeAccompaniers: SiteVisitorTypeAccompanierViewModel[];
  siteVisitorTypeAccompanierProcess: boolean = false;

  @Input() isCheckIn: boolean = true;

  maxOrder(isCheckIn: boolean) {
    if (isCheckIn) {
      return (!this.siteCheckInQuestions || this.siteCheckInQuestions.length === 0) ? 1 :
        Math.max(...this.siteCheckInQuestions.map(c => c.displayOrder)) + 1;
    } else {
      return (!this.siteCheckOutQuestions || this.siteCheckOutQuestions.length === 0) ? 1 :
        Math.max(...this.siteCheckOutQuestions.map(c => c.displayOrder)) + 1;
    }
  }

  _isAllowedToManageVisitorTypes: boolean = null;
  get isAllowedToManageVisitorTypes(): boolean {
    if (this._isAllowedToManageVisitorTypes == null)
      this._isAllowedToManageVisitorTypes = this.userService.isCompanyAdminUser() && this.userService.hasCompanyFeature(ProductPlanFeature.VisitorTypes);
    return this._isAllowedToManageVisitorTypes;
  }

  constructor(
    private bsModalService: BsModalService,
    private router: Router,
    private questionService: QuestionService,
    private modalService: ModalService,
    public userService: UserService,
    private visitorTypeService: VisitorTypeService,
    private siteRequiredUserDocumentService: SiteRequiredUserDocumentService,
    private siteRequiredFieldService: SiteRequiredFieldService,
    private siteQuestionService: SiteQuestionService,
    private userDocumentTypeService: UserDocumentTypeService,
    private componentFactoryResolver: ComponentFactoryResolver) {
    this.getQuestionCompleted = new Subject<void>();
  }

  ngOnInit(): void {
    this.getSiteRelatedData();
    this.reDrawSortable();
  }

  onChangeVisitor(checkedVisitorType: CheckedVisitorType) {
    if (this.manageSiteModel) {
      if (!checkedVisitorType.checked)
        this.manageSiteModel.siteVisitorTypes = this.manageSiteModel?.siteVisitorTypes?.filter(siteVisitorType => siteVisitorType.visitorTypeId != checkedVisitorType.id)
      else
        this.manageSiteModel?.siteVisitorTypes?.push({
          visitorTypeId: checkedVisitorType.id,
          visitorTypeTitle: checkedVisitorType.title,
          siteVisitorTypeId: null
        } as SiteVisitorTypeViewModel)

      this.updateVisitorTypesAndAllPropertiesVisitorTypes();
    }

    this.getSiteQuestions();
    this.getRequiredDocuments();
    this.reDrawSortable();
  }

  getSiteRelatedData() {
    this.getSiteQuestions();
    this.getQuestions();
    this.getRequiredDocuments();
    //this.getRequiredFields();
    this.getVisitorTypes();
    this.getAllUserRequiredDocuments();
  }

  allUserDocumentTypes: UserDocumentType[];

  allRequiredUserDocuments: UserDocumentType[];

  getAllUserRequiredDocuments() {
    this.progress = true;
    this.userDocumentTypeService.getSelectable()
      .pipe(finalize(() => {
        this.progress = false
      }))
      .subscribe(res => {
        this.allRequiredUserDocuments = this.allUserDocumentTypes = res;
        this.updateUserSelectedDocuments();
      })
  }

  getVisitorTypes() {
    this.progress = true;
    this.visitorTypeService.getAllVisitorTypes()
      .pipe(finalize(() => {
        this.progress = false
      }))
      .subscribe(res => {
        this.visitorTypes = res;
      })
  }

  bsRequiredDocumentsModalRef: BsModalRef;

  onShowRequiredDocumentsModal(template) {
    this.bsRequiredDocumentsModalRef = this.bsModalService.show(template);
  }

  bsCheckinQuestionsModalRef: BsModalRef;

  onShowCheckinQuestionsModal(template) {
    this.bsCheckinQuestionsModalRef = this.bsModalService.show(template);
    this.activeModalRef = this.bsCheckinQuestionsModalRef;
  }

  bsCheckoutQuestionsModalRef: BsModalRef;

  onShowCheckoutQuestionsModal(template) {
    this.bsCheckoutQuestionsModalRef = this.bsModalService.show(template);
    this.activeModalRef = this.bsCheckoutQuestionsModalRef;
  }

  bsQuestionModalRef: BsModalRef
  onAddQuestionClick(template) {
    this.bsQuestionModalRef = this.bsModalService.show(template, { class: 'modal-lg' })
  }
  onResultQuestionAdd($event, checkInOut: string) {
    this.bsQuestionModalRef.hide();
    if (!$event.isSucceeded) return;
    this.getQuestions();
    let sub = this.getQuestionCompleted.subscribe(() => {
      sub.unsubscribe();
      this.addQuestionToSite($event.result.id, checkInOut == 'checkIn');
      if (this.activeModalRef) {
        this.activeModalRef.hide();
        this.activeModalRef = null;
      }
    })
  }

  getRequiredDocuments() {
    this.siteRequiredUserDocuments = this.manageSiteModel?.documentInfo?.requiredDocuments;
    this.originalSiteRequiredUserDocuments = this.manageSiteModel?.documentInfo?.requiredDocuments;
    this.updateUserSelectedDocuments();
  }

  updateUserSelectedDocuments() {
    let userSelectedDocuments = this.manageSiteModel?.documentInfo?.requiredDocuments?.map(c => c.userDocumentTypeId) ?? [];
    this.allUserDocumentTypes = this.allRequiredUserDocuments?.filter((x) => {
      return userSelectedDocuments.indexOf(x.id) < 0
    });
  }

  addRequiredDocument(doc: UserDocumentType) {
    let alreadyExists = this.manageSiteModel?.documentInfo?.requiredDocuments?.findIndex(function (item) {
      return item.userDocumentTypeId == doc.id;
    });
    if (alreadyExists == -1)
      this.manageSiteModel?.documentInfo?.requiredDocuments?.push({
        visitorTypeIds: [],
        userDocumentTypeId: doc.id,
        userDocumentTypeTitle: doc.title,
        state: doc.state,
        checked: true,
        checkIn: true,
        siteId: this.manageSiteModel?.site?.id,
      } as SiteRequiredUserDocumentWithVisitorTypeViewModel);
    this.getRequiredDocuments();
    this.makeComponentDirty(this.dirtyComponents.documentInfo);
  }

  removeRequiredDocument(doc: SiteRequiredUserDocumentWithVisitorTypeViewModel) {

    if (this.manageSiteModel) {
      this.manageSiteModel.documentInfo.requiredDocuments =
        this.manageSiteModel?.documentInfo?.requiredDocuments?.filter(item => item.userDocumentTypeId != doc.userDocumentTypeId);
      this.getRequiredDocuments();

      this.makeComponentDirty(this.dirtyComponents.documentInfo);
    }


    /*    this.requiredDocumentProcess = true;
        this.siteRequiredUserDocumentService.remove(doc.id)
          .pipe(finalize(() => this.requiredDocumentProcess = false))
          .subscribe(res => {
            doc.id = 0;
            this.getRequiredDocuments();
          }, error => { this.modalService.error("We can't remove document from list, please contacts admin") });*/
  }

  // getRequiredFields() {
  //   if(this.manageSiteModel) {
  //     this.siteId = this.manageSiteModel?.site?.id;
  //     if (this.isCheckIn) {
  //       this.requiredFieldProcess = true;
  //       this.siteRequiredFieldService.getAll(this.siteId).subscribe(res => {
  //         this.siteRequiredField = res;
  //         this.requiredFieldProcess = false;
  //       });
  //     }
  //   }
  // }

  onRequiredFieldChange() {
    this.makeComponentDirty(this.dirtyComponents.requiredFields);
  }

  onRequiredDocumentChange() {
    this.makeComponentDirty(this.dirtyComponents.documentInfo);
  }

  checkinQuestionProgress: boolean = false;
  checkoutQuestionProgress: boolean = false;
  siteCheckInQuestions: SiteQuestionWithVisitorTypeViewModel[];
  siteCheckOutQuestions: SiteQuestionWithVisitorTypeViewModel[];


  get companyCheckinQuestions(): QuestionViewModel[] {
    return this._companyQuestions.filter(function (x) {
      return this.indexOf(x.id) < 0
    }, this.siteCheckInQuestions.map(c => c.questionId));
  };

  get companyCheckoutQuestions(): QuestionViewModel[] {
    return this._companyQuestions.filter(function (x) {
      return this.indexOf(x.id) < 0
    }, this.siteCheckOutQuestions.map(c => c.questionId));
  };

  getSiteQuestions() {
    this.siteCheckInQuestions = this.manageSiteModel?.checkInQuestions ?? [];
    this.siteCheckOutQuestions = this.manageSiteModel?.checkOutQuestions ?? [];
  }

  /*  searchSiteQuestions(siteId: number, isCheckIn: boolean) {
      if (isCheckIn) {
        this.checkinQuestionProgress = true;
      } else {
        this.checkoutQuestionProgress = true;
      }

      this.siteQuestionService.searchSiteQuestion({ siteId: this.siteId, isCheckInQuestion: isCheckIn })
        .pipe(finalize(() => {
          if (isCheckIn) {
            this.checkinQuestionProgress = false;
          } else {
            this.checkoutQuestionProgress = false;
          }
        }))
        .subscribe(res => {
          if (isCheckIn) {
            this.siteCheckInQuestions = res;
          } else {
            this.siteCheckOutQuestions = res;
          }
        });
    }*/

  getQuestions() {
    this.progress = true;
    this.questionService.getActiveQuestions()
      .pipe(finalize(() => {
        this.progress = false;
      }))
      .subscribe(res => {
        this.companyQuestions = res.data.map(q => new QuestionViewModel(q));
        this.progress = false;
        this.getQuestionCompleted.next();
      });
  }

  removeFromSiteQuestion(sqId: number, isCheckIn: boolean) {
    let questions = this.manageSiteModel?.checkInQuestions;
    if (!isCheckIn)
      questions = this.manageSiteModel?.checkOutQuestions;

    this.progress = true;
    let targetSiteQuestion = questions.find(x => x.questionId == sqId);

    let hasChild = questions
      .some(x => this.getQuestionViewModel(x.questionId).conditions?.some(c => c.masterQuestionId == targetSiteQuestion.questionId) ?? false)

    if (hasChild)
      this.modalService.confirm("This question is a master question. Are you sure you want to remove it?").subscribe(res => {
        if (res)
          this.removeQuestion(targetSiteQuestion, isCheckIn);
      });
    else {
      this.removeQuestion(targetSiteQuestion, isCheckIn);
    }
  }

  private removeQuestion(targetSiteQuestion: SiteQuestionWithVisitorTypeViewModel, isCheckIn: boolean) {
    if (isCheckIn)
      this.manageSiteModel.checkInQuestions = this.manageSiteModel.checkInQuestions.filter(i => i.questionId != targetSiteQuestion.questionId);
    else
      this.manageSiteModel.checkOutQuestions = this.manageSiteModel.checkOutQuestions.filter(i => i.questionId != targetSiteQuestion.questionId);
    this.emitToParent();

    this.makeComponentDirty(isCheckIn ? this.dirtyComponents.checkInQuestions : this.dirtyComponents.checkOutQuestions);
  }

  addQuestionToSite(qid, isCheckIn: boolean, isMasterQuestion: boolean = false) {
    let question = this.companyQuestions.find(c => c.id == qid);
    let qText = question.questionText;
    let model: SiteQuestionWithVisitorTypeViewModel = {
      id: 0,
      questionId: qid,
      isCheckInQuestion: isCheckIn,
      siteId: this.manageSiteModel?.site?.id,
      siteName: this.manageSiteModel?.site?.name,
      questionType: QuestionType.Boolean,
      displayOrder: this.maxOrder(isCheckIn),
      questionParameters: '',
      questionText: qText,
      parameters: '',
      visitorTypeIds: [],
      document: question.document
    };

    let questions;
    if (!isCheckIn)
      questions = this.manageSiteModel?.checkOutQuestions;
    else
      questions = this.manageSiteModel?.checkInQuestions;

    if (isMasterQuestion) {
      const slave = questions?.filter(q => {
        let original = this.companyQuestions.find(c => c.id == q.questionId);
        return original.conditions != null && original.conditions.filter(c => c.masterQuestionId == model.questionId).length > 0;
      });
      if (slave && slave.length > 0) {
        const order = slave[0].displayOrder;
        const others = questions?.filter(q => q.displayOrder >= order);
        if (others && others.length)
          others.forEach(q => q.displayOrder++);
        model.displayOrder = order;
      }
    }

    let checkIfAlreadyExists = questions?.filter(q => q.questionId == model.questionId) ?? [];
    if (checkIfAlreadyExists.length == 0) {
      questions?.push(model);
      questions = questions?.sort((item1, item2) => item1.displayOrder - item2.displayOrder);
      this.addMasterQuestions(question, isCheckIn, isMasterQuestion);
      this.emitToParent();
      this.makeComponentDirty(isCheckIn ? this.dirtyComponents.checkInQuestions : this.dirtyComponents.checkOutQuestions);
    }

  }

  addMasterQuestion(model: QuestionViewModel, isCheckIn: boolean) {
    const masterQuestionIds = model.conditions.map(c => c.masterQuestionId);
    this.companyQuestions.filter(q => masterQuestionIds.indexOf(q.id) >= 0)
      .forEach(mq => {
        this.addQuestionToSite(mq.id, isCheckIn, true);
      });
  }

  addMasterQuestions(model: QuestionViewModel, isCheckIn: boolean, isAddingMasterQuestionsProgress: boolean) {
    if (model.conditions == null || model.conditions.length == 0)
      return;
    if (isAddingMasterQuestionsProgress) {
      this.addMasterQuestion(model, isCheckIn);
    } else {
      this.modalService.confirm('Selected question has master question. Do you want to add master question?')
        .subscribe(accept => {
          if (!accept)
            return;
          this.addMasterQuestion(model, isCheckIn);
        });
    }
  }

  getQuestionViewModel(id: number) {
    if (this.companyQuestions && this.companyQuestions.length > 0)
      return this.companyQuestions.filter((q) => q.id == id)[0];
    else
      return null;
  }

  onsiteVisitorAccompanierChange(visitorTypeAccompanier: SiteVisitorTypeAccompanierViewModel, value) {
    visitorTypeAccompanier.accompanierMode = value.value;
    this.makeComponentDirty(this.dirtyComponents.visitorTypeAccompaniers)
  }

  toggleDefaultSiteMode(visitorTypeAccompanier: SiteVisitorTypeAccompanierViewModel) {
    if (visitorTypeAccompanier.accompanierMode != null)
      visitorTypeAccompanier.accompanierMode = null;
    else
      visitorTypeAccompanier.accompanierMode = 0;
    this.makeComponentDirty(this.dirtyComponents.visitorTypeAccompaniers)
  }

  onChangeOrder(isCheckIn) {
    let questions = isCheckIn ? this.siteCheckInQuestions : this.siteCheckOutQuestions;

    for (let i = 0; i < questions?.length; i++) {
      questions[i].displayOrder = (i + 1);
    }

    this.makeComponentDirty(isCheckIn ? this.dirtyComponents.checkInQuestions : this.dirtyComponents.checkOutQuestions);
  }


  visitorTypesStatus: boolean = false;

  displayVisitorTypes() {
    let _this = this;
    let onlyTitles = this.manageSiteModel?.siteVisitorTypes?.map(function (x) {
      return _this.manageSiteModel?.visitorTypes?.find(v => v.id == x.visitorTypeId)?.title
    });
    let output = onlyTitles.slice(0, 5);
    if (onlyTitles.length > 5)
      output.push('...');

    if (output.length > 0)
      return output.join(", ");

    return "No item selected";
  }

  countRequiredFields() {
    return this.manageSiteModel?.requiredFields?.fields?.filter(i => i.state != 0).length ?? 0;
  }

  updateVisitorTypesAndAllPropertiesVisitorTypes() {

    let _this = this;
    this.makeComponentDirty(this.dirtyComponents.siteVisitorTypes)


    this.manageSiteModel.visitorTypeAccompaniers = this.manageSiteModel?.siteVisitorTypes?.map(function (siteVisitorType) {
      let accompanierObjFromAccompaniers = _this.manageSiteModel?.visitorTypeAccompaniers?.find(function (a) {
        return a.siteVisitorTypeId == siteVisitorType.siteVisitorTypeId && a.visitorTypeId == siteVisitorType.visitorTypeId;
      });

      return accompanierObjFromAccompaniers ?? {
        visitorTypeId: siteVisitorType?.visitorTypeId,
        visitorTypeTitle: siteVisitorType?.visitorTypeTitle,
        accompanierMode: null,
        siteVisitorTypeId: siteVisitorType?.siteVisitorTypeId
      } as SiteVisitorTypeAccompanierViewModel
    })
    this.makeComponentDirty(this.dirtyComponents.visitorTypeAccompaniers)


    let siteVisitorTypesIds = this.manageSiteModel?.siteVisitorTypes?.map(i => i.visitorTypeId);

    // update visitor types of other properties
    this.manageSiteModel?.documentInfo?.requiredDocuments?.map(function (item) {
      item.visitorTypeIds = item.visitorTypeIds.filter(item => siteVisitorTypesIds.includes(item));
      _this.makeComponentDirty(_this.dirtyComponents.documentInfo)
    })
    this.manageSiteModel?.checkInQuestions?.map(function (item) {
      item.visitorTypeIds = item.visitorTypeIds.filter(item => siteVisitorTypesIds.includes(item));
      _this.makeComponentDirty(_this.dirtyComponents.checkInQuestions)
    })
    this.manageSiteModel?.checkOutQuestions?.map(function (item) {
      item.visitorTypeIds = item.visitorTypeIds.filter(item => siteVisitorTypesIds.includes(item));
      _this.makeComponentDirty(_this.dirtyComponents.checkOutQuestions)
    })
    this.manageSiteModel?.requiredFields?.fields?.map(function (item) {
      item.visitorTypeIds = item.visitorTypeIds.filter(item => siteVisitorTypesIds.includes(item));
      _this.makeComponentDirty(_this.dirtyComponents.requiredFields)
    })
  }

  emitToParent() {
    this.manageSiteViewModelChange.emit(this.manageSiteModel);
    this.reDrawSortable();
  }

  showObcSortable: boolean;

  reDrawSortable() {
    this.showObcSortable = false;
    setTimeout(() => {
      this.showObcSortable = true;
    }
      , 50);
  }

  questionVisitorTypeClicked(item: SiteQuestionWithVisitorTypeViewModel, value, isCheckInQuestion: boolean) {
    (isCheckInQuestion ? this.manageSiteModel?.checkInQuestions : this.manageSiteModel?.checkOutQuestions)?.map(function (q) {
      if (q.questionId == item.questionId) {
        let index = q.visitorTypeIds.indexOf(value.id);
        if (index > -1)
          q.visitorTypeIds.splice(index, 1);
        else
          q.visitorTypeIds.push(value.id);
      }
      return q
    });
    this.makeComponentDirty(isCheckInQuestion ? this.dirtyComponents.checkInQuestions : this.dirtyComponents.checkOutQuestions);
    this.reDrawSortable();
  }

  documentVisitorTypeClicked(item: SiteRequiredUserDocumentWithVisitorTypeViewModel, value) {
    let _this = this;
    this.manageSiteModel?.documentInfo?.requiredDocuments?.map(function (q) {
      if (q.userDocumentTypeId == item.userDocumentTypeId) {
        let index = q.visitorTypeIds.indexOf(value.id);
        if (index > -1)
          q.visitorTypeIds.splice(index, 1);
        else
          q.visitorTypeIds.push(value.id);
      }
      _this.makeComponentDirty(_this.dirtyComponents.documentInfo)
      return q;
    });
  }

  fieldVisitorTypeClicked(item: SiteRequiredFieldWithVisitorTypeViewModel, value) {
    let _this = this;
    this.manageSiteModel?.requiredFields?.fields.map(function (q) {
      if (q.id == item.id) {
        let index = q.visitorTypeIds.indexOf(value.id);
        if (index > -1)
          q.visitorTypeIds.splice(index, 1);
        else
          q.visitorTypeIds.push(value.id);
      }
      _this.makeComponentDirty(_this.dirtyComponents.requiredFields)
      return q;
    });
  }


  initializeCopies() {
    if (this.manageSiteModel) {

      let components: DirtyComponentItem[] = [
        this.dirtyComponents.checkInQuestions,
        this.dirtyComponents.checkOutQuestions,
        this.dirtyComponents.requiredFields,
        this.dirtyComponents.documentInfo,
        this.dirtyComponents.siteVisitorTypes,
        this.dirtyComponents.visitorTypeAccompaniers
      ];

      for (const component of components) {
        let itemKey = this.findDirtyComponentKey(component);
        if (this.manageSiteModel[itemKey]) {
          component.snapshot = component?.snapshot || this.myCopy(itemKey);
        }
      }
    }
  }

  findDirtyComponentKey(item: DirtyComponentItem) {
    let itemKey;
    Object.keys(this.dirtyComponents).forEach((key) => {
      if (this.dirtyComponents[key] == item)
        itemKey = key;
    });
    return itemKey;
  }

  makeComponentDirty(item: DirtyComponentItem) {
    let itemKey = this.findDirtyComponentKey(item);
    item.isDirty = !isEqual(item?.snapshot, this.myCopy(itemKey));
  }

  myCopy(name) {
    if (!(this.manageSiteModel && this.manageSiteModel[name]))
      return {};
    let returnObj = {};
    returnObj[name] = deepClone(this.manageSiteModel[name]);
    return returnObj;
  }

  toggleAttachAdditionalDocuments() {
    if (this.manageSiteModel) {
      this.manageSiteModel.documentInfo.allowUsersToAttachAdditionalDocuments =
        !this.manageSiteModel.documentInfo.allowUsersToAttachAdditionalDocuments;
      this.makeComponentDirty(this.dirtyComponents.documentInfo);
    }
  }
}
