import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'obc-summary-widget',
  templateUrl: './summary-widget.component.html',
  styleUrls: ['./summary-widget.component.css']
})
export class SummaryWidgetComponent implements OnInit {
  @Input() data : any;
  constructor() { }

  ngOnInit() {
  }

}
