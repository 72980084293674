import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'toFormGroup'
})
export class ToFormGroupPipe implements PipeTransform {

  transform(value: any, args?: any): FormGroup {
    return value as FormGroup;
  }

}
