interface Date {
    addDays(days: number): Date;
    addYears(years: number): Date;
    UTCDate(isoDate: string): Date;
    UTCDate2(): Date;
}

Date.prototype.addDays = function (days): Date {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

Date.prototype.addYears = function (years): Date {
    var date = new Date(this.valueOf());
    date.setFullYear(date.getFullYear() + years);
    return date;
};

Date.prototype.UTCDate = function (isoString): Date {
    console.log('isoString : ', isoString);
    var c = isoString.split(/\D+/);
    console.log('c : ', c);

    var b = c.map(c => +c);
    console.log('b : ', b);

    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
};

Date.prototype.UTCDate2 = function (): Date {
    var date = new Date(this.valueOf());
    var now_utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),
        date.getHours(), date.getMinutes(), date.getSeconds());

    return new Date(now_utc);
};
