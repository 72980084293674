<div class="row">
  <div class="col-md-12">
    <div class="card">
      <!-- <header class="card-header">Supplier Document SignOff Report</header> -->
      <div class="card-body">
        <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>]
          <ng-template obcGridTemplateName="viewDetails" let-viewDetails let-data="data">
            <obc-dropdown-button>
              <obc-file-viewer dropdown-button [urls]="data.fileUrls" [btnTitle]="'View Details'"
                [btnClass]="'btn btn-info no-right-border-radius'">
              </obc-file-viewer>
              <ul id="dropdown-split" class="dropdown-menu" role="menu" aria-labelledby="button-split" dropdown-items>
                <li role="menuitem">

                  <div *ngIf="data.signatureUrl; else noSignature">
                    <a id="view-signture" class="cursor-pointer d-flex align-items-center">
                      <obc-file-viewer dropdown-button [btnTitle]="'View Signature'" [urls]="[data.signatureUrl]"
                        [btnClass]="'bg-transparent border-0 dropdown-item'">
                      </obc-file-viewer>
                    </a>
                  </div>
                  <ng-template #noSignature>
                    <a class="dropdown-item cursor-pointer d-flex align-items-center disable-pointer">
                      Signature not available
                    </a>
                  </ng-template>

                </li>
              </ul>
            </obc-dropdown-button>
          </ng-template>
        </app-base-grid>
      </div>
    </div>
  </div>
</div>