import {Component, Input, OnInit} from '@angular/core';
import { LayoutService } from "../../services/layout.service";

@Component({
  selector: 'obc-css-spinner',
  templateUrl: './css-spinner.component.html',
  styleUrls: ['./css-spinner.component.scss']
})
export class CssSpinnerComponent implements OnInit {

  @Input() size: number = 24;
  @Input() color: string = "#7A320A";
  @Input() darkColor: string = "#efefef";
  @Input() backgroundColor: string = "rgba(255,255,255,0.4)";
  @Input() darkBackgroundColor: string = "rgba(255,255,255,0.3)";
  @Input() speed: number = 1;
  @Input() class: string = "";
  @Input() thickness: number = 3;
  constructor(public layoutService: LayoutService) { }

  ngOnInit(): void {
  }

}
