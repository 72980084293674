import { Injectable } from '@angular/core';
import { ExportAsFileType } from '../enums/export-as-file-type';
import { getCurrentDateTime } from '../helpers/datetime-helper';
import { DatetimePipe } from '../pipes/datetime.pipe';
import { GenderPipe } from '../pipes/gender.pipe';

@Injectable({
  providedIn: 'root'
})
export class ExportAsService {
  constructor(private dateTimePipe: DatetimePipe, private genderPipe: GenderPipe) { }

  exportAs(rows: Object[], config: ExportAsConfig) {
    config.preFileName = (config.preFileName ? config.preFileName : 'obc-report') + '-' + getCurrentDateTime() + '.csv';

    switch (config.fileType) {
      case ExportAsFileType.CSV: this.exportToCsv(rows, config); return;
    }
  }

  private exportToCsv(rows: object[], config: ExportAsConfig) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = config.separator ? config.separator : ',';
    const keys = config.fields ? config.fields.filter(f => f != null).map(c => c) :
      Object.keys(rows[0]).map(c => { return { fieldName: c, title: '' } as ExportAsFields });
    const headers = config.fields ? config.fields.filter(f => f != null).map(c => c.title).join(separator) : keys.join(separator);
    const csvContent =
      headers +
      '\n' +
      rows.filter(f => f != null).map(row => {
        return keys.map(k => {
          let cell = row[k.fieldName] === null || row[k.fieldName] === undefined ? '' : row[k.fieldName];
          try {
            let cellType = k.type === null || k.type === undefined ? '' : k.type;
            if (cell === '') return '';
            if (cellType === FieldType.Date)
              cell = this.dateTimePipe.transform(cell, 'd/M/yyyy')
            else if (cell instanceof Date || cellType === FieldType.DateTime)
              cell = this.dateTimePipe.transform(cell)
            else if (cellType === FieldType.Mobile)
              cell = `+${cell.substring(0, 2)} ${cell.substring(2)}`; //we can use regex
            else if (cellType === FieldType.Gender)
              cell = this.genderPipe.transform(cell);
            else if (cellType === FieldType.Boolean)
              cell = cell ? 'Yes' : 'No';
            else if (cellType === FieldType.Object) {
              cell = k.function(cell);
            }
            else
              cell = cell.toString().replace(/"/g, '""');

            if (cell.search(/("|,|\n)/g) >= 0 && cellType !== FieldType.Object)
              cell = `"${cell}"`;
            return cell;
          }
          catch {
            return cell;
          }
        }).join(separator);
      }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, config.preFileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', config.preFileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  is_date(value: any): boolean {
    if (Object.prototype.toString.call(value) === "[object Date]")
      return true;

    if (!isNaN(value))
      return false;

    if (isNaN(new Date(value).getTime())) {
      return false;
    } else {
      return true;
    }
  };
}

export interface ExportAsConfig {
  fields?: ExportAsFields[];
  separator?: string;
  fileType: ExportAsFileType;
  preFileName?: string;
}

export interface ExportAsFields {
  title: string,
  fieldName: any,
  type?: FieldType,
  function?: Function
}


export enum FieldType {
  DateTime = 0,
  Mobile = 1,
  Gender = 2,
  Date = 3,
  Boolean = 4,
  Object = 5,
}
