import { QuestionType } from "../enums/question-type";
import { RequiredDocumentViewModel } from "./user-document-type";
import { CustomFormControl } from "./custom-formcontrol";

export class SiteQuestionsViewModel {
  public id: number;
  public siteId: number;
  public siteName: string;
  public isCheckInQuestion: boolean;
  public questionId: number;
  public questionText: string;
  public questionType: QuestionType;
  public displayOrder: number;
  public questionParameters: string;
  public parameters: any; // TODO  take it after merge QuestionParameter;
  public document: RequiredDocumentViewModel;
  public documentFormControl?: CustomFormControl;
}

export interface SiteQuestionWithVisitorTypeViewModel extends SiteQuestionsViewModel {
  visitorTypeIds: number[];
}
