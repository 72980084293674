import { Pipe, PipeTransform } from '@angular/core';
import { ObcEventLogTypeEnum, UserActivityEventType, UserProfileField } from '../enums/obc-event-log-type.enum';

@Pipe({
  name: 'workerProfileSubType'
})
export class WorkerProfileSubTypePipe implements PipeTransform {

  transform(value: unknown, eventType: ObcEventLogTypeEnum): string {
    if (eventType == ObcEventLogTypeEnum.UserActivity) {
      switch (value) {
        case UserActivityEventType.CheckIn: return 'CheckIn';
        case UserActivityEventType.FinaliseCheckIn: return 'Finalise CheckIn';
        case UserActivityEventType.HostCheckIn: return 'Host CheckIn';
        case UserActivityEventType.CheckOut: return 'CheckOut';
        case UserActivityEventType.FinaliseCheckOut: return 'Finalise CheckOut';
        case UserActivityEventType.HostCheckOut: return 'Host CheckOut';
        case UserActivityEventType.SubmitInduction: return 'Submit Induction';
        case UserActivityEventType.SignOffSiteBriefing: return 'SignOff Site Briefing';
        case UserActivityEventType.SignOffSupplierDocument: return 'SignOff Supplier Document';
        case UserActivityEventType.PreCheckIn: return 'Pre CheckIn';
        case UserActivityEventType.FillForm: return 'Fill Form';
      }
    }

    if (eventType == ObcEventLogTypeEnum.UpdateUserProfile) {
      switch (value) {
        case UserProfileField.CompanySupplierId: return 'Company Supplier';
        case UserProfileField.VisitorTypeId: return 'Visitor Type';
        case UserProfileField.FirstName: return 'First Name';
        case UserProfileField.LastName: return 'Last Name';
        case UserProfileField.Email: return 'Email';
        case UserProfileField.ProfileImage: return 'Profile Image';
        case UserProfileField.DateOfBirth: return 'Date Of Birth';
        case UserProfileField.EmergencyContact: return 'Emergency Contact';
        case UserProfileField.Gender: return 'Gender';
      }
    }

    return value?.toString() ?? '';
  }
}
