import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { CompanyRoleService } from "../../services/company-role.service";
import { CompanyRoleViewModel } from "../../models/company-role.view.model";

@Component({
  selector: 'obc-role-selector',
  templateUrl: './role-selector.component.html',
  styleUrls: ['./role-selector.component.scss'],
})
export class RoleSelectorComponent implements OnInit {

  constructor(
    public companyRoleService: CompanyRoleService,
  ) {
  }

  formControl = new FormControl(null);
  term$ = new BehaviorSubject<string>(null)
  loading: boolean = false;
  roles: CompanyRoleViewModel[] = [];

  @Input() controlName: string;
  @Input() selectedRoles: CompanyRoleViewModel[] = [];
  @Output() result: EventEmitter<CompanyRoleViewModel[]> = new EventEmitter<CompanyRoleViewModel[]>();

  public form: FormGroup;
  ngOnInit(): void {
    this.companyRoleService.getCompanyRoles().subscribe({
      next: (res) => {
        this.roles = res;
      }
    })
    this.formControl.setValue(this.selectedRoles?.map((item) => item.companyRoleId) ?? []);
    this.formControl.valueChanges?.subscribe((_) => {
      let result = this.roles?.filter((item) =>
        (this.formControl.value ?? []).includes(item.companyRoleId)
      );
      this.result.emit(result);
    })
  }
}
