<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>
          <ng-template obcGridTemplateName="actions" let-viewDetails let-item="data">
            <div *ngIf="item.status != SiteBriefingStatus.Draft">
              <button class="btn btn-info" (click)="openModal(formDetails,item.formDataId)">
                View Details
              </button>
            </div>
          </ng-template>
          <ng-template #formDetails>
            <obc-site-briefing-form-viewer [selectedFormDataId]="selectedFormDataId"></obc-site-briefing-form-viewer>
          </ng-template>
        </app-base-grid>
      </div>
    </div>
  </div>
</div>
