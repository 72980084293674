import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from "../../../services/modal.service";
import { HtmlNewLinePipe } from 'src/app/pipes/html-new-line.pipe';

@Component({
  selector: 'obc-supplier-document-description-viewer',
  templateUrl: './supplier-document-description-viewer.component.html',
  styleUrls: ['./supplier-document-description-viewer.component.scss']
})
export class SupplierDocumentDescriptionViewerComponent implements OnInit {

  @Input() buttonTitle: string = 'Amendment';
  @Input() documentTitle: string;
  @Input() description: string;
  @Input() icon: string;
  @Input() classes: string = 'btn btn-info'
  @Input() displayAsModal: boolean = true;

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  getDescriptionSplittedByNewLine() {
    return this.description?.split('\n');
  }

  onDisplayDescription(template: any) {
    this.modalService.show(template, "modal-lg");
  }

}
