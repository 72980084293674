<div class="card" *ngIf='permitHistory'>
  <obc-loader [show]="inProgress"></obc-loader>
  <div class="card-header">
    <span>
      {{permitHistory.permitTitle}}
    </span>
    <div class="card-actions">
      <a (click)="onHide.emit()" class="card-action card-action-dismiss" data-card-dismiss=""></a>
    </div>
  </div>
  <div class="card-body">
    <div class="row mt-2">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-bordered table-striped obc-table">
            <thead>
              <tr>
                <th>Status</th>
                <th>By</th>
                <th>At</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="permitHistory.events && permitHistory.events.length == 0">
                <td colspan="20">
                  There is no history.
                </td>
              </tr>
              <tr *ngFor="let event of permitHistory.events">
                <td>{{event.currentPermitStatus | permitStatus}}</td>
                <td>{{event.userFullName}}</td>
                <td>{{event.dateTime | datetime}}</td>
                <td>
                  <button *ngIf="event.relatedFormDataId" class="btn btn-info ml-1 pull-left"
                    (click)="openModal(formDetails, event.relatedFormDataId)">
                    View Details
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #formDetails>
  <obc-form-details [formDataId]="generalFormDataId" (onRefesh)="closeModal();">
  </obc-form-details>
</ng-template>