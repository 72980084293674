import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompanyAuthService {

  hashLink: string = null;
  activeCompanyId: number = null;
  currentCompanyId: number = null;

  constructor() { }

  setCurrentCompanyId = (currentCompanyId?: number) => this.currentCompanyId = currentCompanyId;

  setCompanyId = (companyId?: number) => this.activeCompanyId = companyId;

  getCompanyId = (): number => this.activeCompanyId ?? this.currentCompanyId;

  setHashLink = (hashLink: string) => this.hashLink = hashLink;

  getHashLink = (): string => this.hashLink;
}