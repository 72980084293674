<div class="row">
    <div class="col-xl-6 col-lg-12 offset-xl-3">

        <div class="card">
            <div class="card-header">
                <h5>Procore Force ReSync</h5>
            </div>
            <div class="card-body">
                <obc-loader [show]="inProgress"></obc-loader>
                <div class="{{resetData ? 'hidden':''}}">

                    <div class="row">
                        <div class="form-group col-md-6 col-sm-12 mb-3">
                            <label><strong>Company</strong></label>
                            <obc-company-selector (selectedCompany)="onSelectedCompany($event)"></obc-company-selector>
                        </div>
                    </div>
                    <div *ngIf="selectedCompany">
                        <div class="row">
                            <div class="form-group col-md-6 col-sm-12 mb-3">
                                <label><strong>Sites</strong></label>
                                <obc-specific-company-site-selector [companyId]="selectedCompany.id"
                                    (siteSelect)="onSiteSelect($event)"></obc-specific-company-site-selector>
                                <div>
                                    <span *ngFor="let site of selectedSites" class="mr-3 badge bg-warning m-1"
                                        style="white-space: nowrap;">
                                        <i class="fas fa-close"></i>
                                        <i class="fa-times-circle fas btn btn-xs btn-danger mr-1"
                                            (click)="removeSelectedSite(site.id)"></i>
                                        <span class="">{{site.name}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-12 col-sm-12 mb-3">
                                <label><strong>Procore Sync Types</strong></label>
                                <div>
                                    <div class="ml-3" *ngFor="let type of procoreSyncTypes">
                                        <label><input type="checkbox" class="" [value]="type"
                                                (change)="onSelectSyncType(type, $event)">
                                            {{type | integrationOperation}}
                                            <span class="badge bg-info">{{getIntegrationDescription(type)}}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6 col-sm-12">
                                <label><strong>Date From</strong></label>
                                <obc-input-date-time-picker [mode]="DatetimePickerMode.Calender"
                                    (valueChange)="onSelectFromDate($event)"></obc-input-date-time-picker>
                            </div>
                            <div class="form-group col-md-6 col-sm-12">
                                <label><strong>Date To</strong></label>
                                <obc-input-date-time-picker [mode]="DatetimePickerMode.Calender"
                                    (valueChange)="onSelectToDate($event)"></obc-input-date-time-picker>
                            </div>
                        </div>
                        <div class="row">
                            <div class="button-group col-md-4 offset-md-4">
                                <button [disabled]="!isValid" href="#" class="card-link btn btn-info btn-block"
                                    (click)="getProcoreResetData()">
                                    Display Reset Statistics
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3" *ngIf="resetData">
                    <div class="col-md-8 offset-md-2">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>
                                        <strong>Sync Type</strong>
                                    </th>
                                    <th>
                                        Total Record Count To ReSync
                                    </th>

                                    <th>
                                        Total Record Count to Sync
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="button-group col-md-12" *ngFor="let item of resetData.items">
                                    <td>
                                        <strong>{{item.type | integrationOperation }}</strong>
                                    </td>
                                    <td>
                                        <strong>{{item.itemCountToResync }}</strong>
                                    </td>
                                    <td>
                                        <strong>{{item.itemCountToSync}}</strong>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="3" style="text-align: center;">
                                        <button [disabled]="!isValid" href="#"
                                            class="card-link btn btn-danger btn-block" (click)="resetSyncStatus()">
                                            Reset Records to Resync
                                        </button>
                                        <br>
                                        <button href="#" class="card-link btn btn-primary btn-block"
                                            (click)="cancelSync()">
                                            Cancel
                                        </button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>