import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'obc-god',
  templateUrl: './god.component.html',
  styleUrls: ['./god.component.css']
})
export class GodComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
