export enum SiteInductionStatus {
  AutoApproved = 0,
  PendingToApprove = 1,
  ApprovedByHost = 2,
  RejectByHost = 3,
}
export enum InductionReviewMode {
  ApproveReject = 0,
  RequestRevision = 1,
  ReadOnly = 2,
  Hidden = 3,
}
