import { Pipe, PipeTransform } from '@angular/core';
import { SupplierDocumentExpireDateMode } from '../enums/supplier-document-expire-date-mode.enum';

@Pipe({
  name: 'supplierDocumentExpiryDateMode'
})
export class SupplierDocumentExpiryDateModePipe implements PipeTransform {

  transform(value: unknown): string {
    if (value == SupplierDocumentExpireDateMode.Disable) return 'Disable';
    if (value == SupplierDocumentExpireDateMode.Mandatory) return 'Mandatory';
    if (value == SupplierDocumentExpireDateMode.Optional) return 'Optional';
    return value?.toString() ?? '?';
  }

}
