<section class="card">
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="dataTables_wrapper dt-bootstrap4 no-footer">
      <div class="row">
        <div class="col-12">
          <button id="addToTable" class="btn btn-info my-2 pull-left" (click)="onNewCompanyEmployee()">Add New Employee
            To <span class="text-light"> {{currentCompanyName}} </span><i class="fas fa-plus pl-2"></i></button>
          <div class="my-2  pull-right">
            <button id="addToTable" class="btn btn-success mr-1" (click)="openImportEmployeeModal(importModal)">Import
              Employees</button>
            <a *ngIf="userService.hasCompanyFeature(ProductPlanFeature.Export)" target="_blank" class="btn btn-danger "
              style="color: white !important;" href="/general/export-company-employees">
              <i class="fas fa-copy mr-1"></i>Export Employees
            </a>
          </div>
        </div>
        <div class="col-12">
          <table class="table table-bordered table-striped mb-0 dataTable no-footer">
            <thead>
              <tr role="row">
                <th>Mobile</th>
                <th>Name</th>
                <th>Family</th>
                <th>Employee No.</th>
                <th>Region</th>
                <th>Actions</th>
              </tr>
              <tr>
                <td colspan="6">
                  <input type="text" class="form-control" placeholder="Filter Result by Mobile, Name, Family"
                    [(ngModel)]="filterListTerm" />
                </td>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="addNewMode">
                <ng-container *ngTemplateOutlet="addUserTemplate"></ng-container>
              </ng-container>
              <ng-container
                *ngFor="let employee of (companyEmployees | filter:filterListTerm:['mobile','firstName','lastName','employeeNo'])">
                <tr *ngIf="!employee.inEdit && !addNewMode">
                  <td>{{employee.mobile | mobile}}</td>
                  <td>{{employee.firstName}}</td>
                  <td>{{employee.lastName}}</td>
                  <td>{{employee.employeeNo}}</td>
                  <td>{{employee.regionName}}
                    <span *ngIf="!employee.regionName"><strong>All Regions</strong></span>
                  </td>
                  <td class="actions">
                    <a class="btn p-1 remove-row btn-info btn-sm"
                      (click)="onViewEmployeeDocuments(employee, documents)">View Documents</a>
                    <a class="btn p-1 remove-row" (click)="delete(employee)"><i class="far fa-trash-alt"></i></a>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>



<ng-template #documents>
  <section class="card">
    <div class="card-header">
      <span><strong>{{employeeToViewDocuments.firstName}} {{employeeToViewDocuments.lastName}}
          ({{employeeToViewDocuments.mobile}}) (#{{employeeToViewDocuments.employeeNo}})
        </strong></span>
      <button type="button" class="close" (click)="modalHandle.hide();">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <obc-loader [show]="inProgress"></obc-loader>
      <div class="row">
        <div class="col-sm-12 col-md-6">

        </div>
        <div class="col-sm-12 col-md-6">

        </div>
      </div>
      <ng-container *ngIf="docs && docs.length">
        <div class="employee-cards-container">
          <div *ngFor="let doc of docs" class="user-doc-view">
            <div class="post-image mb-2">
              <div class="img-thumbnail">
                <i class="fas fa-address-card"></i>
              </div>

              <span class="text-primary ml-1" *ngIf="doc.documentTypeTitle">
                {{ doc.documentTypeTitle }}
                <small>{{doc.title}} <span *ngIf="doc.expireDate"
                    [ngClass]="{'text-danger': doc.isExpired,'text-success': !doc.isExpired}">
                    Expire: {{doc.expireDate | date}}</span></small>
              </span>
              <span class="text-primary pull-right mt-1 mr-1">
                <small>CheckIn: {{doc.checkInDate | date}}</small>
              </span>
            </div>
            <div class="d-flex flex-column justify-content-center">
              <div class="image-container text-center">
                <a [href]="doc.fileUrl" target="_blank">
                  <img class="fw" [attr.src]="doc.fileUrl">
                </a>
              </div>
              <ng-container *ngIf="doc?.additionalFileUrlList.length">
                <div class="d-flex flex-row">
                  <div *ngFor="let addfile of doc.additionalFileUrlList;let i = index" class="p-1">
                    <a class="btn btn-primary" [href]="addfile" target="_blank">Additional File #{{i+1}}</a>
                  </div>
                </div>
              </ng-container>
            </div>
            <hr>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="docs && docs.length ==0">
        <p class="text-center">
          Employee has no documents!
        </p>
      </ng-container>
      <ng-container *ngIf="docs == null">
        <p class="text-center">
          Searching User Documents ...
        </p>
      </ng-container>
    </div>
  </section>
</ng-template>

<ng-template #addUserTemplate>
  <tr role="row" class="adding odd">
    <td>
      <obc-user-autocomplete #mobile [filterBy]="filterUserBy.Mobile" [required]="true"
        (selectedUser)="fillCompanyEmployee($event)" [currentUser]="selectedUser"
        (inputChange)="companyEmployee.mobile = $event.value" display="mobile" name="mobile"
        [notFoundMessage]="isValidMobile ? 'add as a new user' : ''">
      </obc-user-autocomplete>
      <small class="text-warning" for="mobile" *ngIf="!isValidMobile">Please enter a valid mobile number.</small>
    </td>
    <td>
      <obc-user-autocomplete [filterBy]="filterUserBy.FirstName" [displayNoResult]="false" [required]="true"
        (selectedUser)="fillCompanyEmployee($event)" [currentUser]="selectedUser"
        (inputChange)="companyEmployee.firstName = $event.value" display="firstName">
      </obc-user-autocomplete>
    </td>
    <td>
      <obc-user-autocomplete [filterBy]="filterUserBy.LastName" [required]="true" [displayNoResult]="false"
        (selectedUser)="fillCompanyEmployee($event)" [currentUser]="selectedUser"
        (inputChange)="companyEmployee.lastName = $event.value" display="lastName">
      </obc-user-autocomplete>
    </td>
    <td>
      <div class="input-group">
        <input type="text" class="form-control" [(ngModel)]="companyEmployee.employeeNo">
      </div>
    </td>
    <td>
      <obc-region-selector (regionIdSelect)="onRegionSelect($event)"></obc-region-selector>
    </td>
    <td>
      <a (click)="addCompanyEmployee()" class="btn p-1 on-editing save-row"><i class="fas fa-save text-success"></i></a>
      <a (click)="cancel()" class="btn px-3 py-1"><i class="fas fa-times text-warning"></i></a>
      <a class="btn p-1 on-default edit-row hidden"><i class="far fa-pencil-alt"></i></a>
      <a class="btn p-1 on-default remove-row hidden"><i class="far fa-trash-alt"></i></a>
    </td>
  </tr>
</ng-template>

<ng-template #importModal>
  <div class="card">
    <div class="card-header">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalHandle.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 mt-1">
          <p>
            Import company employee data in CSV file format (comma seperated)
            <a class="btn btn-primary btn-sm" href="../../../../assets/files/company-employees-import.csv"
              target="_blank">Download Sample File</a>
          </p>
        </div>
        <div class="col-md-12 mt-1">
          <obc-file-upload [showClearButton]="true" (clear)="onClearForm()" [formControl]="fileFormControl"
                           [accept]="FileUploadAcceptType.CSV">
          </obc-file-upload>
        </div>
        <div class="col-md-12 mt-1" *ngIf="importData && importData.valid && importData.message">
          <div class="alert alert-success mb-1" role="alert">
            {{importData.message}}
          </div>
        </div>
        <div class="col-md-12 mt-1" *ngIf="importData && !importData.valid">
          <div class="alert alert-danger mb-1" role="alert">
            {{importData.message}}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
