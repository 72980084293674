<div *ngIf="!displayInRow" class="emergency-contact d-flex flex-column">
  <div *ngIf="isNameValid()" class="d-flex align-items-center mt-1">
    <ng-container *ngTemplateOutlet="nameTemplate"></ng-container>
  </div>
  <div *ngIf="isMobileValid()" class="d-flex align-items-center mt-1">
    <ng-container *ngTemplateOutlet="mobileTemplate"></ng-container>
  </div>
  <div *ngIf="isRelationshipValid()" class="d-flex align-items-center mt-1">
    <ng-container *ngTemplateOutlet="relationshipTemplate"></ng-container>
  </div>
</div>
<div *ngIf="displayInRow">
  <div class="emergency-contact">
    <div class="row">
      <div class="col-12 col-md-4 d-flex flex-column align-content-start">
        <ng-container *ngTemplateOutlet="nameTemplate"></ng-container>
      </div>
      <div class="col-12 col-md-4 d-flex flex-column align-content-start">
        <ng-container *ngTemplateOutlet="mobileTemplate"></ng-container>
      </div>
      <div class="col-12 col-md-4 d-flex flex-column align-content-start">
        <ng-container *ngTemplateOutlet="relationshipTemplate"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #nameTemplate>
  <div class="title mr-2" style="{{ labelStyle }}">Name {{ displayDots ? ':' : ''}}</div>
  <div>{{ dataObject?.name }}</div>
</ng-template>
<ng-template #mobileTemplate>
  <div class="title mr-2" style="{{ labelStyle }}">Mobile {{ displayDots ? ':' : ''}}</div>
  <div>{{ dataObject?.mobile | mobile }}</div>
</ng-template>
<ng-template #relationshipTemplate>
  <div class="title mr-2" style="{{ labelStyle }}">Relationship {{ displayDots ? ':' : ''}}</div>
  <div>{{ dataObject?.relationship }}</div>
</ng-template>

<div *ngIf="isNullOrEmptyEmergencyContact()">
  <strong>N/A</strong>
</div>



