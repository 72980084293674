<obc-loader [show]="inProgress"></obc-loader>
<div class="row">
  <div class="col-12 col-lg-8 mb-1">
    <button *ngIf="siteMode" class="btn btn-default" [routerLink]="['/sites']">
      <i class="fas fa-arrow-left"></i> Return to List
    </button>
    <button *ngIf="!siteMode" class="btn btn-default" [routerLink]="['/supplier']">
      <i class="fas fa-arrow-left"></i> Return to List
    </button>
  </div>
</div>

<section class="card" style="z-index: 10">
  <header class="card-header">
    <h5>{{ siteMode ? 'Site Selection' : 'Supplier Selection' }}</h5>
    <div class="card-actions">
    </div>
  </header>
  <div class="card-body">
    <div class="row">

      <div *ngIf="!siteMode" class="col-12 col-xl-6">
        <label for="">Select a Supplier</label>
        <obc-company-supplier-selector #supplierSelector [control]="supplierFormControl">
        </obc-company-supplier-selector>
      </div>

      <div *ngIf="siteMode" class="col-12 col-xl-6">
        <label for="">Select a Site</label>

        <app-site-selector [required]="true" [siteId]="siteId" (siteIdSelect)="changeSiteId($event)"
          [permission]="UserPermission.SupplierManagement">
        </app-site-selector>
      </div>

    </div>

  </div>

</section>

<section class="card">
  <header class="card-header">
    <div class="d-flex">
      <h5>List of Site Suppliers</h5>
      <button class="ml-auto btn btn-info" (click)="_openNewForm(SDTForm)">
        <span class="fa fa-plus mr-2"></span>
        New
      </button>
    </div>
    <div class="card-actions">
    </div>
  </header>
  <div class="card-body">

    <div class="">
      <ng-container *ngTemplateOutlet="paging"></ng-container>
      <table class="table table-bordered table-striped mb-0">
        <thead>
          <tr>
            <td style="cursor: pointer" (click)="toggleSort()">
              <div class="d-flex justify-content-between align-items-center">
                <span>{{ !siteMode ? 'Site' : 'Supplier' }}</span>
                <i *ngIf="paginationInfo.sortOrder == null" class="fa fa-sort"></i>
                <i *ngIf="paginationInfo.sortOrder == SortOrder.ASC" class="fa fa-sort-alpha-down"></i>
                <i *ngIf="paginationInfo.sortOrder == SortOrder.DESC" class="fa fa-sort-alpha-up"></i>
              </div>
            </td>
            <td>Documents/Permits/Assets</td>
            <td>Permissions</td>
            <td>Last Request Email</td>
          </tr>

        </thead>
        <tbody>
          <tr>
            <td colspan="100">
              <input type="text" class="form-control" placeholder="Search by {{ siteMode ? 'supplier' : 'site' }}"
                [formControl]="generalSearcControl">
            </td>
          </tr>

          <ng-container *ngIf="allSiteSupplierDocumentTypes && allSiteSupplierDocumentTypes.length > 0; ">
            <ng-container *ngFor="let item of allSiteSupplierDocumentTypes">
              <tr [ngClass]="{'disabled': !item.isEnable}">

                <!-- <td *ngIf="!siteMode">
                  <div>
                    {{ item.siteName }}
                     <span class="pull-right">
                       <ng-container [ngTemplateOutlet]="removeFromSiteButton"></ng-container>
                     </span>
                  </div>
                  <label *ngIf="!item.isEnable" class="badge badge-danger">
                    Disabled
                  </label>
                </td> -->
                <td>
                  <ng-container *ngIf="siteMode">
                    <div><strong> {{ item.supplierName }}</strong></div>
                    <div style="color: dimgray;">
                      <div>{{ item.supplierEmailAddress }}</div>
                      <div>{{ item.supplierMobile | mobile }}</div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!siteMode">
                    <span>{{ item.siteName }}</span>
                  </ng-container>

                  <span style="display: flex; flex-direction: column; align-items: flex-end; justify-self: flex-end;">
                    <ng-container *ngIf="siteMode">
                      <button class="btn btn-primary btn-sm" style="margin-bottom: 2px;"
                        (click)="onEditSupplier(upsertSupplier, item)">
                        <i class="fas fa-edit"></i>
                      </button>
                    </ng-container>
                  </span>
                  <label *ngIf="!item.isEnable" class="badge badge-danger">
                    Disabled
                  </label>
                </td>
                <td>
                  <div class="d-flex flex-column"
                    *ngIf="!(item && siteId == item.siteId && supplierFormControl.value == item.supplierId )">


                    <div class="card mb-2" [ngClass]="item.siteSupplierDocumentTypes?.length?'':'alert alert-warning'">
                      <div class="card-header">
                        <a [routerLink]="[getmanageItemsRouteLink(item)]" fragment="documents"
                          class="btn btn-primary btn-sm pull-right">View</a>
                        <div *ngIf="!item.siteSupplierDocumentTypes?.length">
                          There are no supplier documents.
                        </div>
                        <div *ngIf="item.siteSupplierDocumentTypes?.length">
                          <div class=""><strong>{{(item.siteSupplierDocumentTypes ?
                              item.siteSupplierDocumentTypes.length
                              : 0)}}
                              Document(s)</strong>
                          </div>
                          <div class="">
                            Approved: <span class="badge badge-secondary">
                              {{getSupplierDocumentListCount(item,SiteSupplierDocumentStatus.Approved)}}
                            </span>
                            Rejected: <span class="badge badge-secondary">
                              {{getSupplierDocumentListCount(item,SiteSupplierDocumentStatus.Declined)}}
                            </span>
                            <span
                              class="{{getSupplierDocumentListCount(item,SiteSupplierDocumentStatus.Pending)>0?'text-danger':''}}">
                              Pending: <span
                                class="badge badge-{{getSupplierDocumentListCount(item,SiteSupplierDocumentStatus.Pending)>0?'danger':'secondary'}}">
                                {{getSupplierDocumentListCount(item,SiteSupplierDocumentStatus.Pending)}}
                              </span>
                            </span>
                            Others: <span class="badge badge-secondary">
                              {{getSupplierDocumentListCount(item)}}
                            </span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-column"
                    *ngIf="!(item && siteId == item.siteId && supplierFormControl.value == item.supplierId )">
                    <div class="card mb-2" [ngClass]="item.permitStatistics?.length?'':'alert alert-warning'">
                      <div class="card-header">
                        <a [routerLink]="[getmanageItemsRouteLink(item)]" fragment="permits"
                          class="btn btn-primary btn-sm pull-right">View</a>
                        <div *ngIf="!item.permitStatistics?.length">
                          There are no permits.
                        </div>
                        <div *ngIf="item.permitStatistics?.length">
                          <div class=""><strong>{{getPermitCount(item)}} Permit(s)</strong></div>
                          <div class="">
                            Approved: <span class="badge badge-secondary">
                              {{getPermitCount(item,FormDataStatus.Approved)}}
                            </span>
                            Rejected: <span class="badge badge-secondary">
                              {{getPermitCount(item,FormDataStatus.Rejected)}}
                            </span>
                            <span class="{{getPermitCount(item,FormDataStatus.PendingApproval)>0?'text-danger':''}}">
                              Pending: <span
                                class="badge badge-{{getPermitCount(item,FormDataStatus.PendingApproval)>0?'danger':'secondary'}}">
                                {{getPermitCount(item,FormDataStatus.PendingApproval)}}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="item.isEnableAssets">
                    <div class="d-flex flex-column"
                      *ngIf="!(item && siteId == item.siteId && supplierFormControl.value == item.supplierId )">
                      <div class="card" [ngClass]="item.assetStatistics?.length?'':'alert alert-warning'">
                        <div class="card-header">
                          <a [routerLink]="[getmanageItemsRouteLink(item)]" fragment="assets"
                            class="btn btn-primary btn-sm pull-right">View</a>
                          <div *ngIf="!item.assetStatistics?.length">
                            There are no assets.
                          </div>
                          <div *ngIf="item.assetStatistics?.length">
                            <div class=""><strong>{{getAssetCount(item)}} Asset(s)</strong></div>
                            <div class="">
                              Approved: <span class="badge badge-secondary">
                                {{getAssetCount(item,SiteAssetRequestApproveStatus.Approved)}}
                              </span>
                              Rejected: <span class="badge badge-secondary">
                                {{getAssetCount(item,SiteAssetRequestApproveStatus.Rejected)}}
                              </span>
                              <span
                                class="{{getAssetCount(item,SiteAssetRequestApproveStatus.Pending)>0?'text-danger':''}}">
                                Pending: <span
                                  class="badge badge-{{getAssetCount(item,SiteAssetRequestApproveStatus.Pending)>0?'danger':'secondary'}}">
                                  {{getAssetCount(item,SiteAssetRequestApproveStatus.Pending)}}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                </td>
                <td>
                  <span *ngIf="!item.linkToCompanyId">
                    <strong>Not Linked</strong>
                  </span>
                  <span *ngIf="item.linkToCompanyId">
                    <span *ngIf="item.externalSitePermissions == 0">No Permissions</span>
                    <ul>
                      <ng-container *ngFor="let p of ExternalSitePermission | enumToArray">
                        <li *ngIf="hasSiteSupplierPermission(p,item.externalSitePermissions)">
                          {{ExternalSitePermission[p] | externalSitePermission}}
                        </li>
                      </ng-container>
                    </ul>
                    <button type="button" class="btn btn-primary btn-sm"
                      (click)="openExternalPermissionModal(editExternalSitePermissionsTemplate, item)">
                      <i class="fas fa-edit"></i> Edit
                    </button>
                  </span>
                </td>
                <td>
                  <div class="text-success">
                    {{ item.lastEmailAddress }}
                  </div>
                  <div class="text-info" *ngIf="item.lastEmailSendDate">
                    ({{ item.lastEmailSendDate | datetime}})
                  </div>
                  <ng-container *ngIf="isInViewMode(item)">
                    <button [disabled]="!item.isEnable" (click)="onViewHashLink(item, clipboardTemplate)"
                      class="mb-3 btn btn-warning">
                      <i class="fa fa-envelope"></i>
                      {{item.lastEmailAddress || item.linkToCompanyId ? 'Re-Send Onboarding Email': 'Onboard'}}

                    </button>
                    <br>
                    <button class="btn btn-info" type="button"
                      (click)="showEmailHistory(item,emailHistoryTemplate)">View Email History</button>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </ng-container>

        </tbody>
      </table>
      <ng-container *ngTemplateOutlet="paging"></ng-container>
    </div>

    <ng-template #paging>
      <obc-pagination [pageNumber]="paginationInfo.pageNumber" [pageSize]="paginationInfo.pageSize"
        [totalCount]="totalCount" (pageChanged)="onPagingUpdate($event)"></obc-pagination>
    </ng-template>

    <ng-template #emptyTmp>
      <tr>
        <td colspan="20">
          <div class="alert alert-secondary">
            No item
          </div>
        </td>
      </tr>
    </ng-template>

  </div>
</section>

<ng-template #SDTForm>
  <div class="card">
    <div class="card-body">
      <div class="row">

        <div *ngIf="siteMode" class="col-12 mb-2">
          <label for="">Select a Supplier</label>
          <obc-company-supplier-selector [excludeList]="selectedSupplierIds"
            (selectSupplier)="onSelectSiteOrSupplier($event)" [control]="supplierFormControl">
          </obc-company-supplier-selector>
        </div>

        <div *ngIf="!siteMode" class="col-12 mb-2">
          <label for="">Select a Site</label>
          <app-site-selector #sl [excludeList]="selectedSites" [activeStatus]="SiteActiveStatus.InActive"
            [siteId]="siteId" [notSelectedSiteLabel]="'Select Site'" (siteIdSelect)="onSelectSiteOrSupplier($event)">
          </app-site-selector>
        </div>
      </div>
      <hr>
      <div class="d-flex justify-content-end">
        <button class="btn btn-secondary pull-right mr-3" (click)="closeNewSiteSupplierModal()">
          Cancel
        </button>
        <button class="btn btn-primary pull-right" [disabled]="siteId == null" (click)="onSaveSDT()">
          Submit </button>
      </div>
    </div>
  </div>

</ng-template>

<div class="my-5"></div>
<ng-template #clipboardTemplate>
  <div class="card m-1">
    <div class="card-body">
      <div class="d-flex align-items-center">
        <label class="mb-0">
          <input type="radio" [value]="OnboardingType.Quick" [formControl]="onboardingFormControl">
          Quick onboarding
        </label>
        <label class="ml-3 mb-0">
          <input type="radio" [value]="OnboardingType.Full" [formControl]="onboardingFormControl">
          Full onboarding
        </label>
        <button type="button" class="close ml-auto position-relative" style="top: -2px;" (click)="refViewHashLink.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
  <div class="card m-1">
    <div class="card-body">
      <div class="d-column">
        <div>Send Supplier Link To:</div>
        <input class="form-control" type="email" placeholder="Supplier Email Address" [formControl]="email">
        <input class="form-control mt-1" type="text" placeholder="Supplier Email Subject" [formControl]="emailSubject">
        <input *ngIf="onboardingFormControl.value == OnboardingType.Full" class="form-control mt-1" type="text"
          placeholder="Supplier Mobile" [formControl]="mobile">
        <div class="mt-1">
          <obc-markdown-editor *ngIf="showMarkdownEditor" [value]="emailBody.value"
            [height]="addOffsiteTemplate.value == true ? '200px' : '300px'"
            (valueChanged)="onEmailBodyChanged($event,'emailBodyRichBox')" [id]="'emailBodyRichBox'">
          </obc-markdown-editor>
        </div>
        <div *ngIf="SelectedSiteSupplierForSupplierLinkOperations.isOffsiteEnabled">
          <label class="mt-1">
            <input type="checkbox" class="checkbox" [formControl]="addOffsiteTemplate"
              (change)="onIncludeOffsiteInductionChange($event)">
            Include Offsite Induction
          </label>
          <div *ngIf="addOffsiteTemplate.value == true">
            <obc-markdown-editor *ngIf="showMarkdownEditor" [value]="offsiteEmailTemplate.value" [height]="'200px'"
              (valueChanged)="onEmailBodyChanged($event,'offsiteEmailBodyRichBox')" [id]="'offsiteEmailBodyRichBox'">
            </obc-markdown-editor>
          </div>
        </div>
        <div class="mt-3">
          <button type="button" class="fw btn btn-primary" (click)="onSendEmail()">
            <i class="fa fa-envelope"></i>
            {{ firstEmail ? 'Send Email Again' : 'Send Email' }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card m-1" *ngIf="onboardingFormControl.value == OnboardingType.Quick">
    <div class="card-header">
      <span>Supplier Link</span>
    </div>
    <div class="card-body">
      <app-clipboard-box (copied)="refViewHashLink.hide()" [text]="currentLink" label=""></app-clipboard-box>
    </div>
  </div>
</ng-template>

<ng-template #renameNoneStandardSupplierDocumentType>
  <div class="card">
    <div class="card-header">
      <span>
        Update Supplier Document Type Name
      </span>
      <button type="button" class="close" (click)="bsModalRefRenameDOT.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-1">
        <label>New Title</label>
        <input [formControl]="renameNoneStandardSupplierDocumentTypeFormControl" type="text" class="form-control">
      </div>
      <div class="col-md-12 mt-1 text-right">
        <button (click)="updateNoneStandardSupplierDocumentType()" class="btn btn-primary"
          [disabled]="!renameNoneStandardSupplierDocumentTypeFormControl.valid">Update</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #formReview>
  <obc-supplier-document-review [siteId]="selectedSiteId" [supplierId]="selectedSupplierId"
    [siteSupplierId]="selectedSiteSupplierId" [selectedSiteSupplierDocument]="selectedSiteSupplierDocument"
    (hide)="refresh()">
  </obc-supplier-document-review>
</ng-template>

<ng-template #formView>
  <div>
    <div>
      <ul class="simple-post-list">
        <div class="card">
          <obc-form-data-details [formDataId]="selectedFormDataId"></obc-form-data-details>
        </div>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #editExternalSitePermissionsTemplate>
  <div class="card">
    <div class="card-header">
      Edit Permissions
    </div>
    <div class="card-body">
      <div>
        <input type="checkbox" [value]="ExternalSitePermission.ViewActivity"
          (change)="updateExternalSitePermissionsValue(ExternalSitePermission.ViewActivity,$event)"
          [checked]="hasSiteSupplierPermission(ExternalSitePermission.ViewActivity, siteSupplierToEditExternalSitePermissions.externalSitePermissions)">
        {{ExternalSitePermission.ViewActivity | externalSitePermission}}
      </div>
      <div class="mt-2"
        *ngIf="hasSiteSupplierPermission(ExternalSitePermission.ViewActivity, siteSupplierToEditExternalSitePermissions.externalSitePermissions)">
        <input type="checkbox" [value]="ExternalSitePermission.DefineQuestions"
          (change)="updateExternalSitePermissionsValue(ExternalSitePermission.DefineQuestions,$event)"
          [checked]="hasSiteSupplierPermission(ExternalSitePermission.DefineQuestions, siteSupplierToEditExternalSitePermissions.externalSitePermissions)">
        {{ExternalSitePermission.DefineQuestions | externalSitePermission}}
      </div>
      <div class="mt-2"
        *ngIf="hasSiteSupplierPermission(ExternalSitePermission.ViewActivity, siteSupplierToEditExternalSitePermissions.externalSitePermissions)">
        <input type="checkbox" [value]="ExternalSitePermission.DefineAnnouncements"
          (change)="updateExternalSitePermissionsValue(ExternalSitePermission.DefineAnnouncements,$event)"
          [checked]="hasSiteSupplierPermission(ExternalSitePermission.DefineAnnouncements, siteSupplierToEditExternalSitePermissions.externalSitePermissions)">
        {{ExternalSitePermission.DefineAnnouncements | externalSitePermission}}
      </div>
      <div class="mt-2"
        *ngIf="hasSiteSupplierPermission(ExternalSitePermission.ViewActivity, siteSupplierToEditExternalSitePermissions.externalSitePermissions)">
        <input type="checkbox" [value]="ExternalSitePermission.DefineForms"
          (change)="updateExternalSitePermissionsValue(ExternalSitePermission.DefineForms,$event)"
          [checked]="hasSiteSupplierPermission(ExternalSitePermission.DefineForms, siteSupplierToEditExternalSitePermissions.externalSitePermissions)">
        {{ExternalSitePermission.DefineForms | externalSitePermission}}
      </div>
      <div class="mt-2"
        *ngIf="hasSiteSupplierPermission(ExternalSitePermission.ViewActivity, siteSupplierToEditExternalSitePermissions.externalSitePermissions)">
        <input type="checkbox" [value]="ExternalSitePermission.DefineInductions"
          (change)="updateExternalSitePermissionsValue(ExternalSitePermission.DefineInductions,$event)"
          [checked]="hasSiteSupplierPermission(ExternalSitePermission.DefineInductions, siteSupplierToEditExternalSitePermissions.externalSitePermissions)">
        {{ExternalSitePermission.DefineInductions | externalSitePermission}}
      </div>
      <button type="button" class="btn btn-primary mt-3" (click)="updateExternalSitePermissions()">
        Update Permissions
      </button>
    </div>
  </div>
</ng-template>


<ng-template #upsertSupplier>
  <div class="card">
    <div class="card-header">
      <span>
        Update Supplier
      </span>
      <button type="button" class="close" (click)="bsModalHandle.hide();">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="row" [formGroup]="updateSupplierFormGroup">
        <div class="form-group col-md-12">
          <label>Supplier Name: </label>
          <input class="form-control" formControlName="name">
        </div>
        <div class="form-group col-md-12">
          <label>Email: </label>
          <input class="form-control" formControlName="email">
        </div>
        <div class="form-group col-md-12">
          <label>Phone: </label>
          <input class="form-control" formControlName="mobile">
        </div>
        <input hidden formControlName="id">
      </div>
      <div class="pt-1 text-right">
        <hr />
        <div class="d-flex justify-content-between">
          <button *ngIf="isInViewMode(selectedSupplierViewModel)"
            class="btn btn-{{ selectedSupplierViewModel.isEnable ? 'danger' : 'success' }} btn-sm"
            (click)="onSiteSupplierStatusChange(!selectedSupplierViewModel.isEnable, selectedSupplierViewModel)">
            <i class="fa fa-times "></i>
            Remove from site
          </button>
          <button class="btn btn-primary" [disabled]="!updateSupplierFormGroup.valid" (click)="updateSupplier()">
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #emailHistoryTemplate>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          Content
          <button type="button" class="btn-close btn btn-default pull-right" aria-label="Close"
            (click)="modalRef.hide()">&times;</button>
        </div>
        <div class="card-body">
          <obc-message-history [request]="emailHistoryRequest"></obc-message-history>
        </div>
      </div>
    </div>
  </div>

</ng-template>
