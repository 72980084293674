import { Pipe, PipeTransform } from '@angular/core';
import { SupplierDocumentApprovalMode } from '../enums/supplier-document-approval-mode.enum';

@Pipe({
  name: 'supplierDocumentApprovalMode'
})
export class SupplierDocumentApprovalModePipe implements PipeTransform {

  transform(value: any): string {
    if (value == SupplierDocumentApprovalMode.AutoApproveIfUploadedByStaff) return 'Auto Approve If Uploaded By Company Staff';
    if (value == SupplierDocumentApprovalMode.AutoApproveForAll) return 'Auto Approve For All';
    if (value == SupplierDocumentApprovalMode.ManualApprove) return 'Manual';
    return value?.toString();
  }

}
