<div class="" *ngIf="_documents.length">
  <div *ngFor="let fc of formArray.controls">
    <section class="card mb-2">
      <div class="card-header">
        <strong>{{fc.item.supplierDocumentTypeName}}</strong>
        <sup class="text-danger" *ngIf="fc.item.signOffMode == SiteDocumentState.Mandatory" tooltip="Required">
          <i class="fas fa-star-of-life ml-2"></i>
        </sup>
        <span class="pull-right">{{fc.item.version}}</span>
      </div>
      <div class="d-flex flex-column card-body">
        <div class="d-flex flex-row">
          <obc-file-viewer [files]="fc.item.supplierDocumentFiles" [btnTitle]="'View Files'"
            [btnClass]="!validateFCView(fc) ? 'm-1 btn btn-sm btn-info' : 'm-1 btn-sm btn btn-success'"
            [btnIcon]="!validateFCView(fc) ? 'mr-2 fa fa-exclamation-triangle text-warning' : ''"
            [prefix]="fc.item.supplierDocumentTypeName+'-'+fc.item.version" (onFileViewed)="onFileViewed($event)">
          </obc-file-viewer>
        </div>
        <div>
          <div class="{{isSignMode?'':'form-check'}} mt-3 position-relative">
            <div *ngIf="!isSignMode">
              <label class="form-check-label font-weight-semibold" for="{{ fc.item.supplierDocumentTypeId }}">
                <input [hidden]="validateFCView(fc)" type="checkbox" disabled="disabled" class="form-check-input">
                <input [hidden]="!validateFCView(fc)" [formControl]="fc" type="checkbox" class="form-check-input"
                  id="{{ fc.item.supplierDocumentTypeId }}">
                I have
                read,
                understood and agree
                to the document above
              </label>
            </div>
            <button type="button" class="btn btn-primary" disabled="{{validateFCView(fc)?'':'disabled'}}"
              *ngIf="isSignMode" (click)="sign(signaturePad, fc)">Sign to
              Accept</button>
            <span *ngIf="isSignMode && fc.value">
              <img class="ml-2 signature-bg" src="{{'data:image/png;base64,'+fc.value}}"
                style="width: 100px; height: 100px;">
            </span>
            <div [hidden]="validateFCView(fc)" class="text-info">You must view all files to enable</div>
          </div>
        </div>
        <obc-site-supplier-document-version-comments
          *ngIf="hasPermissionToAddSupplierDocumentComment || fc.item.supplierDocumentVersionComments?.length > 0"
          [siteSupplierDocumentVersionId]="fc.item.siteSupplierDocumentVersionId"
          [hasPermissionToAddSupplierDocumentComment]="hasPermissionToAddSupplierDocumentComment"
          [supplierDocCommentAcknowledgementText]="supplierDocCommentAcknowledgementText"
          [comments]="fc.item.supplierDocumentVersionComments"
          [classes]="'ml-2'"
          [displayAsModal]="false"
        >
        </obc-site-supplier-document-version-comments>
        <obc-supplier-document-description-viewer
          [documentTitle]="'Amendments'" [description]="fc.item?.description"
          [displayAsModal]="false"
          [classes]="'btn btn-tertiary ml-2'">
        </obc-supplier-document-description-viewer>
      </div>
    </section>
  </div>
  <hr>
</div>

<ng-template #signaturePad>
  <obc-signature-pad (onSave)="saveSign($event)"></obc-signature-pad>
</ng-template>
