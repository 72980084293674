import { Self } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NgControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { toInteger } from 'lodash';

@Component({
  selector: 'app-duration-form-control',
  templateUrl: './duration-form-control.component.html',
  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     useExisting: DurationFormControlComponent,
  //     multi: true,
  //   }
  // ],
  styleUrls: ['./duration-form-control.component.scss']
})
export class DurationFormControlComponent implements ControlValueAccessor, OnInit {
  formGroup = new FormGroup({
    hours: new FormControl(null, [Validators.min(0)]),
    minutes: new FormControl(null, [Validators.min(0), Validators.max(59)])
  });
  onChange: Function;
  constructor(@Self() private controlDirective: NgControl) {
    controlDirective.valueAccessor = this;
  }
  ngOnInit(): void {
    this.controlDirective.control.setValidators([this.validate.bind(this)]);
    this.controlDirective.control.updateValueAndValidity();
    this.formGroup.statusChanges.subscribe(res => {
      if (this.onChange)
        this.onChange(this.totalMinutes)
    });
  }
  writeValue(totalMinutes: number): void {
    var minutes = toInteger((totalMinutes % 60));
    var hours = toInteger((totalMinutes - minutes) / 60);
    this.formGroup.controls.hours.setValue(hours ? hours : null);
    this.formGroup.controls.minutes.setValue(minutes? minutes : null);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.controls.hours.disable();
      this.formGroup.controls.minutes.disable();
    } else {
      this.formGroup.controls.hours.enable();
      this.formGroup.controls.minutes.enable();
    }
  }

  get totalMinutes(): number {
    return +(this.formGroup.controls.hours.value * 60) +
      (+this.formGroup.controls.minutes.value);
  }

  validate({ value }: FormControl) {
    if (!this.formGroup.valid)
      return { invalid: true }
  }
}
