import { Component, OnInit, Input } from '@angular/core';
import { Constants } from 'src/app/enums/constants';
import { QuestionType } from 'src/app/enums/question-type';
import { QuestionViewModel } from 'src/app/models/question-viewmodel';

@Component({
  selector: 'obc-question-extended-title',
  templateUrl: './question-extended-title.component.html',
  styleUrls: ['./question-extended-title.component.css']
})
export class QuestionExtendedTitleComponent implements OnInit {
  Constants = Constants;
  @Input() question: QuestionViewModel;
  QuestionType = QuestionType;
  constructor() { }

  ngOnInit() {
  }

  showItems(question: QuestionViewModel) {
    return QuestionViewModel.hasOptions(question);
  }

  conditionValue(t: any): string {
    if (this.question.questionType == QuestionType.Text && t.condition.value[0] == Constants.TEXT_IS_ANYTHING_CONDITION_VALUE)
      return 'Anything';
    if (!t.condition.value[0])
      return this.question.questionType == QuestionType.Text ? 'Nothing' : 'No Selection';
    return t.condition.value.join(', ');
  }

}
