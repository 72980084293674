import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { ResetProcoreSyncData, ResetProcoreSyncDataItem, ResetProcoreSyncModel } from '../models/procore/reset-procore-sync-model';
import { Observable, catchError } from 'rxjs';
import { ServerResult, ServerResultData } from '../models/server-result-data';

@Injectable({
  providedIn: 'root'
})
export class ProcoreService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getProcoreResetData(request: ResetProcoreSyncModel): Observable<ServerResultData<ResetProcoreSyncData>> {
    return this._http.post<ServerResultData<ResetProcoreSyncData>>(`/api/procore/procore-sync-reset-data`, request)
      .pipe(catchError(this._errorService.handleError));
  }

  procoreSyncReset(request: ResetProcoreSyncModel): Observable<ServerResult> {
    return this._http.post<ServerResult>(`/api/procore/procore-sync-reset`, request)
      .pipe(catchError(this._errorService.handleError));
  }
}
