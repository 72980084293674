<div class="card">
  <div class="card-header">
    <h5>Add/Edit Product Plans</h5>
  </div>
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="row">
      <div class="col-12">
        <p class="button-group mb-3">
          <button class="btn btn-success" (click)="onShowEditModal(editModal , null)">
            Add New Product Plan
          </button>
        </p>
      </div>
      <div class="col-12 mb-2">
        <p style="text-align: center;" *ngIf="plans == null">
          Loading ...
        </p>
        <p style="text-align: center;" *ngIf="plans != null && plans.length == 0">
          Nothing Found
        </p>
        <table *ngIf="plans" class="table table-bordered table-striped mb-0">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Subscription Type</th>
              <th>Billing Period</th>
              <th>Active Sites</th>
              <th>Average Check-In Per Day</th>
              <th>Stripe Plan Id</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let plan of plans">
              <td><span>{{plan.id}}</span></td>
              <td><span>{{plan.title}}</span></td>
              <td><span>{{plan.subscriptionTypeTitle == null ? 'General' : plan.subscriptionTypeTitle}}</span></td>
              <td>{{plan.billingPeriodTitle}}</td>
              <td>
                <span *ngIf="plan.activeSiteCount">{{plan.activeSiteCount}}</span>
                <span *ngIf="!plan.activeSiteCount">Unlimited</span>
              </td>
              <td>
                <span *ngIf="plan.averageCheckInCountPerDay">{{plan.averageCheckInCountPerDay}}</span>
                <span *ngIf="!plan.averageCheckInCountPerDay">Unlimited</span>
              </td>
              <td><span>{{plan.stripePlanId}}</span></td>
              <td>
                <p class="button-group">
                  <button class="btn btn-sm btn-primary" (click)="onShowEditModal(editModal, plan)">
                    Edit
                  </button>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<ng-template #editModal>
  <div class="card" *ngIf="editFormGroup">
    <obc-loader [show]="inEditProgress"></obc-loader>
    <div class="card-header">
      <span *ngIf="editFormGroup.get('id').value == 0">Add</span>
      <span *ngIf="editFormGroup.get('id').value != 0">Edit</span>
      <span> Plan</span>
      <button type="button" class="close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <form [formGroup]="editFormGroup" class="row">
        <input type="hidden" class="form-control" formControlName="id">
        <input type="hidden" formControlName="id">
        <div class="form-group col-md-6 col-sm-12">
          <label>Title</label>
          <input type="text" class="form-control" formControlName="title">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label>Active Site Count</label>
          <input type="number" placeholder="Leave Blank For Unlimited" class="form-control"
            formControlName="activeSiteCount">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label>Average CheckIn Count Per Day</label>
          <input type="number" placeholder="Leave Blank For Unlimited" class="form-control"
            formControlName="averageCheckInCountPerDay">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label>Stripe Plan Id</label>
          <input type="text" class="form-control" formControlName="stripePlanId">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label>Price Per Month</label>
          <input type="number" class="form-control" formControlName="pricePerMonth">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label>Price Unit</label>
          <select class="custom-select form-control" formControlName="priceUnit">
            <option [value]="'AUD'">AUD</option>
          </select>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label>Billing Period</label>
          <select class="custom-select form-control" formControlName="billingPeriod">
            <option [ngValue]="0">Yearly</option>
            <option [ngValue]="1">Monthly</option>
            <option [ngValue]="2">Semiannually</option>
          </select>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label>Number of Questions</label>
          <input type="number" placeholder="Leave Blank For Unlimited" class="form-control"
            formControlName="numberOfQuestions">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label>Number of Users</label>
          <input type="number" placeholder="Leave Blank For Unlimited" class="form-control"
            formControlName="numberOfUsers">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label>Subscription Type</label>
          <select class="form-control" formControlName="subscriptionType">
            <option [ngValue]="null">General</option>
            <option *ngFor="let index of SubscriptionPlans | enumToArray" [ngValue]="SubscriptionPlans[index]">
              {{ SubscriptionPlans[index] | subscriptionTypePipe }}
            </option>
          </select>
        </div>

        <div class="col-md-12 ml-3 mr-3">
          <div class="row mb-3 mt-2">
            <div class="form-group col-md-12 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="earlyAccess">
                <strong>Early Access</strong>
                <span class="badge badge-danger ml-2">Experimental Features</span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="supportViaEmail">
                Support Via Email</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="support24x7">
                Support 24x7</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="personalisedTraining">
                Personalised Training</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="integrationApi">
                Integration Api</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="isPublic">
                Is Public?</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="exportFeature">
                Export</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="requiredDocumentFieldFeature">
                Required Document/Field</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="schaduleReportFeature">
                Schedule Report</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="announcementsFeature">
                Announcements</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="scannablesFeature">
                Scannables</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="visitorTypesFeature">
                Visitor Types</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="siteHostsFeature">
                Site Hosts</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="automationFeature">
                Automations</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="kioskFeature">
                View Kiosk Mode</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="preCheckInFeature">
                Pre Check-In</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="inventory">
                Inventory</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="form">
                Form</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="accessControl">
                Access Control</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="siteBriefing">
                Site Briefing</label>
            </div>
            <div class="form-group col-md-6 col-sm-12 form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" formControlName="autoRenew">
                Site Briefing Auto Renew</label>
            </div>

          </div>
        </div>
        <p class="button-group col-md-12">
          <button [disabled]="!editFormGroup.valid" href="#" class="card-link btn btn-primary" (click)="onSave()">
            Save Changes
          </button>
        </p>
      </form>
    </div>
  </div>
</ng-template>