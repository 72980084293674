import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { Observable, catchError } from 'rxjs';
import { WorkerProfileViewModel } from '../models/userProfile/worker-profile-view-model';
import { WorkerProfileRequestModel } from "../models/userProfile/worker-profile-request-model";

@Injectable({
  providedIn: 'root'
})
export class WorkerProfileService {
  apiUrl: string = "/api/workerprofile";
  constructor(private http: HttpClient, private _errorService: ErrorService) { }

  loadWorkerProfile(userId: number): Observable<WorkerProfileViewModel> {
    return this.http.get<WorkerProfileViewModel>(`${this.apiUrl}/get/${userId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  updateUserProfile(model: WorkerProfileRequestModel): Observable<WorkerProfileViewModel> {
    return this.http.post<WorkerProfileViewModel>(`${this.apiUrl}/update-user-profile`, model)
      .pipe(catchError(this._errorService.handleError));
  }
}
