<obc-top-bar [showEmergencyAlaram]="false"></obc-top-bar>
<div class="inner-wrapper">
  <obc-side-bar *ngIf="displayInfo != null"></obc-side-bar>
  <section role="main" class="content-body pb-0">
    <div class="d-flex justify-content-center align-items-center min-vh-100 --bg">
      <obc-loader [show]="inProgress"></obc-loader>

      <div *ngIf="isPageFullyLoaded" class="row justify-content-center w-100 my-5">
        <div class="card col-12 col-md-6 col-lg-5 col-xl-4 --container-- p-0">
          <header class="card-header">
            <h5 class="text-center">Link {{supplierName}} to {{hostCompanyTitle}}</h5>
            <div class="card-actions">
            </div>
          </header>
          <div class="card-body">

            <div *ngIf="joinSupplierInfo == null || !isValidatedSmsToken"
              class="d-flex flex-column justify-content-center">
              <ng-container [ngTemplateOutlet]="verificationFirstPanel" *ngIf="showFirstPanel"></ng-container>
              <ng-container [ngTemplateOutlet]="verificationSecondPanel" *ngIf="!showFirstPanel"></ng-container>
            </div>

            <div *ngIf="joinSupplierInfo != null && isValidatedSmsToken">
              <div *ngIf="displayInfo != null">

                <label>You are logged in as <strong>{{ displayInfo?.displayName }}</strong></label>

              </div>

              <div *ngIf="displayInfo == null && !loginStateNotAMember">
                <!-- <div class="alert alert-success">
                    You are not logged in. If you are a 1Breadcrumb user, login to view your company.
                  </div> -->

                <obc-login [returnPath]="'/supplier-join/' + urlToken" [mobile]="joinSupplierInfo?.mobileNumber"
                  [minimalView]="true" (done)="loginState($event)">
                </obc-login>
              </div>

              <ng-container *ngIf="displayInfo != null || loginStateNotAMember">

                <div class="alert alert-success" *ngIf="loginStateNotAMember">
                  You are not a 1Breadcrumb member. But you can create a new company and join
                </div>

                <ng-container *ngIf="!loginStateNotAMember">
                  <div class="alert alert-info">
                    <span *ngIf="hasAnyCompany">Please select a company or create a new one
                    </span>
                    <span *ngIf="!hasAnyCompany">Please create a new company
                    </span>
                  </div>

                  <ng-container *ngIf="this.companies != null">
                    <div class="mt-1" *ngFor="let item of this.companies | keyvalue">
                      <label for="radio_{{item.key}}}">
                        <input id="radio_{{item.key}}}" type="radio" [formControl]="selectedCompany"
                          [checked]="selectedCompany.value == item.key" value="{{ item.key }}">
                        {{ item.value }}
                      </label>
                    </div>
                  </ng-container>
                </ng-container>

                <div class="mt-3" *ngIf="displayInfo != null && !isEmptyObject(this.companies)">
                  <label for="createnewcompany" class="font-italic text-color-quaternary">
                    <input id="createnewcompany" class="mt-1" type="radio" [formControl]="selectedCompany"
                      value="{{ -1 }}">
                    Create New Company
                  </label>
                </div>

                <ng-container *ngIf="selectedCompany.value == -1" [ngTemplateOutlet]="createNewCompany">
                </ng-container>

                <div *ngIf="selectedCompany.value != -1" class="pt-1 text-right">
                  <hr />
                  <button class="btn btn-primary" [disabled]="selectedCompany.value == null"
                    (click)="sendJoinRequest()">
                    Submit
                  </button>
                </div>
              </ng-container>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<ng-template #verificationFirstPanel>
  <div class="alert alert-info">
    Activation token will be sent to {{mobileNumber | mobile}}
  </div>
  <button class="btn btn-info mt-3 mx-auto d-block" (click)="onSendSMS()">
    Send SMS
  </button>
</ng-template>

<ng-template #verificationSecondPanel>
  <div class="alert alert-info">
    Please enter the token you received on {{mobileNumber | mobile}}
  </div>
  <input class="form-control --sms-token--" required type="text" placeholder="SMS Token here" [formControl]="smsToken"
    (keydown.enter)="isSMSTokenEntered() ? validateToken() : null">
  <button class="btn btn-info mt-3 mx-auto d-block" [disabled]="!isSMSTokenEntered()" (click)="validateToken()">
    Submit
  </button>
  <div class="text-center">
    <div class="mt-2">
      Haven't received the SMS yet?
    </div>
    <div *ngIf="disableGoBackLink" style="font-weight:bold ;"> Please wait
      ({{timeLeft}})</div>
    <a (click)="backToFirst()" *ngIf="!disableGoBackLink" class="link-primary"> Go back</a>
  </div>
</ng-template>

<ng-template #createNewCompany>
  <div *ngIf="displayInfo == null" class="card mt-4">
    <div class="card-header">
      <span>
        User Info
      </span>
    </div>
    <div class="card-body">
      <div class="alert alert-warning">Please fill in the information required below</div>

      <label class="mt-3">Phone Number: </label>
      <div class="d-flex align-items-center">
        <div class="pr-2 py-2 font-weight-bold">
          + {{ newCompanyMobileCountryCodeDisplay }}
        </div>
        <div class="flex-fill py-2 --display-mobile--">
          {{ newCompanyMobileDisplay }}
        </div>
      </div>

      <label class="mt-3">First Name: </label>
      <input class="form-control" required type="text" placeholder="First Name"
        [formControl]="newCompanyOwnerFirstName">

      <label class="mt-3">Last Name: </label>
      <input class="form-control" required type="text" placeholder="Last Name" [formControl]="newCompanyOwnerLastName">

    </div>
  </div>
  <div class="card mt-4">
    <div class="card-header">
      <span>
        Create a new company in 1Breadcrumb
      </span>
    </div>
    <div class="card-body">
      <div class="alert alert-warning">Please enter company information</div>

      <label>Name of the Company: </label>
      <input class="form-control" required type="text" placeholder="Company Name" [formControl]="newCompanyTitle">

      <label class="mt-3">Timezone: </label>
      <div class="form-group row pb-2 mb-1">
        <div class="col-12">
          <div class="input-group mb-3">
            <select class="form-control" [formControl]="newCompanyTimeZoneId">
              <option *ngFor="let tz of (timezones | filterByProperty:false:'isOtherTimezone')" value="{{ tz.id }}">
                {{tz.name}} ({{tz.offset}})
              </option>
              <optgroup label="OTHER TIMEZONES">
                <option *ngFor="let tz of (timezones | filterByProperty:true:'isOtherTimezone')" value="{{ tz.id }}">
                  {{tz.name}} ({{tz.offset}})
                </option>
              </optgroup>
            </select>
            <span class="input-group-append">
              <obc-tooltip
                message="Set the Company timezone. This is used as the default timezone for 1Breadcrumb sites.">
              </obc-tooltip>
            </span>
          </div>
        </div>
      </div>

      <div class="pt-1 text-right">
        <hr />
        <button class="btn btn-primary" [disabled]="!validateNewCompanyFields()" (click)="sendJoinRequest()">
          Submit
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #successLinkToCompanyMessage>
  <div class="card">
    <div class="card-header bg-success">
      &nbsp;
    </div>
    <div class="card-body">
      <h4>
        You have succesfully linked <strong>{{supplierName}}</strong> to <strong>{{this.hostCompanyTitle}}</strong>.
      </h4>
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-success pull-right" (click)="closeModal()">Let's Go</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #successLinkToNewCompany>
  <div class="card">
    <div class="card-header bg-success">&nbsp;</div>
    <div class="card-body">
      <h4>
        You have successfully created <strong>{{this.newCompanyTitle?.value != null ?this.newCompanyTitle?.value :
          this.supplierName}}</strong> and linked
        this to
        <strong>{{this.hostCompanyTitle}}</strong>.
      </h4>
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-success pull-right" (click)="closeModal()">Let's Go</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
