<form id="form" action="forms-validation.html" class="form-horizontal" novalidate="novalidate">
  <div class="card">
    <header class="card-header">
      <div class="card-actions">
        <a (click)="cancel()" class="card-action card-action-dismiss" data-card-dismiss=""></a>
      </div>
      <h2 class="card-title">Manage Triggers <span *ngIf="isCompanyAdmin"> & Automations</span></h2>
      <p class="card-subtitle">
        {{pagTitle}}
      </p>
    </header>
    <div class="card-body">

      <tabset>
        <tab heading="Define Triggers">
          <obc-loader [show]="inProgress"></obc-loader>
          <form class="row" name="triggerForm" #triggerForm="ngForm">
            <div class="col-md-12">
              <div class="alert alert-default nomargin">
                <h6>{{_question.questionText}}</h6>
                <h6><small>{{valueLabel}}</small> </h6>
                <div class="row">
                  <div class="col-auto" *ngFor="let item of items">
                    <div
                      *ngIf="_question.questionType === _questionType.Boolean ||
                         _question.questionType === _questionType.SingleSelect ||
                         _question.questionType === _questionType.File ||
                         _question.questionType === _questionType.SignaturePad"
                      class="radio-custom radio-primary">
                      <input [(ngModel)]="conditionValue" [id]="item.itemValue" name="singleSelect" type="radio"
                        [value]="item.itemValue">
                      <label [for]="item.itemValue">{{item.itemTitle}}</label>
                    </div>
                    <div *ngIf="_question.questionType === _questionType.MultiSelect"
                      class="checkbox-custom chekbox-primary">
                      <input (change)="setValue(item.itemValue,$event)" [id]="item.itemValue" name="multiSelect"
                        type="checkbox" [checked]="trigger.condition.value.indexOf(item.itemValue) !== -1"
                        [value]="item.itemValue">
                      <label [for]="item.itemValue">{{item.itemTitle}}</label>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="_question.questionType === _questionType.Text">
                    <div class="radio-custom radio-primary">
                      <input id="isUserText" #isUserText name="isUserText" type="radio" [value]="0"
                        [(ngModel)]="textQuestionValueMode">
                      <label for="isEmptyText">
                        <span>Text</span>
                        <input *ngIf="textQuestionValueMode == 0" [(ngModel)]="conditionValue" id="textQuestion"
                          name="textQuestion" type="text" class="form-control inline ml-1">
                      </label>
                    </div>
                    <div class="radio-custom radio-primary" *ngIf="showLeaveEmptyInTextModeCheckBox">
                      <input id="isEmptyText" #isEmptyText name="isEmptyText" type="radio" [value]="1"
                        [(ngModel)]="textQuestionValueMode">
                      <label for="isEmptyText">Nothing</label>
                    </div>
                    <div class="radio-custom radio-primary">
                      <input id="isAnyText" #isAnyText name="isAnyText" type="radio" [value]="2"
                        [(ngModel)]="textQuestionValueMode">
                      <label for="isAnyText">Anything</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="alert alert-info nomargin">
                <h6>Do...</h6>
                <div class="checkbox-custom chekbox-primary">
                  <input [(ngModel)]="trigger.action.flagUser" #flagUser id="flagUser" name="flagUser" type="checkbox">
                  <label for="flagUser">Tag User</label>
                </div>
                <div *ngIf="trigger.action.flagUser">
                  <obc-chip-container label="Enter tag and press enter" (onChipsChanged)="onTagChanges($event)"
                    [chipLists]="companyTags">
                  </obc-chip-container>
                </div>
                <div class="checkbox-custom chekbox-primary">
                  <input [(ngModel)]="trigger.action.rejectUser" #rejectUser id="rejectUser" name="rejectUser"
                    type="checkbox">
                  <label for="rejectUser">Reject User With This Message</label>
                </div>
                <div>
                  <input [(ngModel)]="trigger.action.rejectMessage" *ngIf="rejectUser.checked" id="rejectMessage"
                    name="rejectMessage" class="form-control" type="text" placeholder="Reject Message">
                </div>
              </div>
            </div>
          </form>
          <div class="row">
            <div class="col-md-12">
              <button class="btn btn-info pull-right mb-3" type="submit" [disabled]="!triggerForm.form.valid"
                (click)="addAction()">Add Action</button>
            </div>
            <div class="col-md-12">
              <div *ngIf="errorMessage" class="alert alert-warning nomargin">
                {{errorMessage}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                  (click)="errorMessage = null">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <table class="table table-bordered table-striped mb-0 obc-table" id="datatable-editable">
                  <thead>
                    <tr role="row">
                      <th>Condition</th>
                      <th>Tag User?</th>
                      <th>Reject User?</th>
                      <th>Reject Message</th>
                      <th style="width: 40px">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr role="row" class="odd" *ngFor="let trigger of triggers">
                      <td *ngIf="_question.questionType === _questionType.Text">
                        {{trigger.condition.value[0] === '' ? 'Nothing' : trigger.condition.value[0] ==
                        Constants.TEXT_IS_ANYTHING_CONDITION_VALUE ? 'Anything': trigger.condition.value[0]}}
                      </td>
                      <td *ngIf="_question.questionType !== _questionType.Text">
                        <ul [ngClass]="_question.questionType !== _questionType.MultiSelect ? 'list-unstyled' : ''">
                          <li *ngFor="let item of items | itemValue : trigger.condition.value">
                            {{item}}</li>
                        </ul>
                      </td>
                      <td>
                        {{trigger.action.flagUser ? 'Yes' : 'No'}}
                        <br>
                        <ng-container *ngIf="trigger.action.tags">
                          <span class="company-tag" *ngFor="let tag of trigger.action.tags">{{tag?.title}}</span>
                        </ng-container>
                        <!-- <code>{{trigger | json}}</code> -->
                        <ng-container *ngIf="!trigger.action.tags || trigger.action.tags.length == 0">
                          <span class="company-tag" *ngIf="trigger.action['flagUser']"
                            style="background-color: #ff000094 !important; color: white;">
                            NO TAG
                          </span>
                        </ng-container>
                      </td>
                      <td>{{trigger.action.rejectUser? 'Yes' : 'No'}}</td>
                      <td>{{trigger.action.rejectMessage}}</td>
                      <td class="actions text-center" style="width: 40px">
                        <a (click)="deleteTrigger(trigger.condition.value)" class="on-default remove-row pointer"><i
                            class="far fa-trash-alt"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </tab>
        <tab *ngIf="isCompanyAdmin" heading="Automations">
          <obc-loader [show]="inProgress"></obc-loader>
          <obc-company-trigger-action [siteId]="-1" [inProgress$]="inProgress$"></obc-company-trigger-action>
        </tab>
      </tabset>


    </div>
  </div>
</form>
