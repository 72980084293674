<obc-loader [show]="inProgress"></obc-loader>
<div class="row">
  <div class="col-md-12">
    <div class="card row">
      <div class="card-body">
        <div>
          <button class="btn btn-default" routerLink="/supplier"><i _ngcontent-lwv-c188=""
              class="fas fa-arrow-left"></i> Return to List </button>
        </div>
        <div class="col-md-12">
          <div class="mb-3">
            <label class="form-label">Select Supplier</label>
            <select class="form-control" [formControl]="formControl">
              <option *ngFor="let supplier of (suppliers | async)" [ngValue]="supplier.id">
                {{supplier.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-12">
          <tabset>
            <tab heading="Businesses" (selectTab)="selectedTab.next('business')">

              <div class="row">
                <div class="form-group col-md-6 col-sm-12">
                  <obc-user-business #lookupUserBusiness placeholder="Search And Add User Business"
                    [displayNoResult]="false" [required]="false" (selectedBusiness)="temporaryAddBusiness($event)">
                  </obc-user-business>
                </div>
                <div class="form-group col-md-5 col-sm-12">
                  <input type="text" class="form-control ml-2" placeholder="License Number" [(ngModel)]="license">
                </div>
                <div class="form-group col-md-1 col-sm-12">
                  <button class="btn btn-success mb-1 ml-2" [disabled]="!canAddBusiness()"
                    (click)="addBusiness()">Add</button>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-bordered table-striped mt-3">
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Abn</td>
                      <td>Acn</td>
                      <td>License Number</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let business of (businesses | async)">
                      <td>{{business.name}}</td>
                      <td>{{business.abn}}</td>
                      <td>{{business.acn}}</td>
                      <td>{{business.licenseNumber}}</td>
                      <td class="text-right">
                        <button class="btn btn-danger" (click)="removeBusiness(business)">Remove</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </tab>
            <tab *ngIf="false" heading="Personal Documents" (selectTab)="selectedTab.next('document')">
              <div class="row" [formGroup]="documentFormGroup">

                <div class="col-md-6 col-12">
                  <div class="mb-3">
                    <label class="form-label">Type</label>
                    <select class="form-control" formControlName="userDocumentType">
                      <option [ngValue]="documentType" *ngFor="let documentType of (documentTypes | async)">
                        {{documentType.title}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <label for="firstname">Document File</label>
                    <obc-file-upload formControlName="file"></obc-file-upload>
                  </div>
                </div>

                <div class="col-md-6 col-12" *ngIf="documentFormGroup.controls.userDocumentType?.value?.enableTitle">
                  <div class="form-group">
                    <label for="firstname">Title</label>
                    <input type="text" class="form-control" formControlName="title">
                  </div>
                </div>

                <div class="col-md-6 col-12"
                  *ngIf="documentFormGroup.controls.userDocumentType?.value?.enableExpireDate">
                  <div class="form-group">
                    <label for="firstname">Expiration Date</label>
                    <obc-input-date-time-picker formControlName="expireDate" [mode]="datetimePickerMode.Calender"
                      [placeholder]="'Pick From Date Time'">
                    </obc-input-date-time-picker>
                    <!-- <input [owlDateTime]="dt" class="form-control" formControlName="expireDate"
                      [owlDateTimeTrigger]="dt" placeholder="Pick From Date Time">
                    <owl-date-time pickerType="calendar" #dt></owl-date-time> -->
                  </div>
                </div>


                <div class="col-md-12 text-right mt-2">
                  <button [disabled]="!documentFormGroup.valid" (click)="addDocument()" class="btn btn-primary">Add
                    Document</button>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-bordered table-striped mt-3">
                  <thead>
                    <tr>
                      <td>Type</td>
                      <td>View</td>
                      <td>Title</td>
                      <td>Expirtion Date</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let document of (documents | async)">
                      <td>{{getTypeLabel(document.userDocumentTypeId)}}</td>
                      <td class="text-center">
                        <a class="btn btn-success" target="_blank" href="{{document.url}}">View</a>
                      </td>
                      <td>{{document.title}}</td>
                      <td>{{document.expireDate | date:"short"}}</td>
                      <td class="text-right">
                        <button class="btn btn-danger" (click)="removeDocument(document)">Delete</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>
