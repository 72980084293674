import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyGuardService {
  constructor(private userService: UserService, private router: Router) {

  }
  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    if(!this.userService.currentCompanyId) {
      this.router.navigate(['/not-found'], { queryParams: { returnPath: routerState.url } });
    }
    return this.userService.info?.value?.currentCompanyId != 0;
  }

}
