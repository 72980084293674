<obc-loader [show]="inProgress"></obc-loader>

<div class="card">
    <div class="card-header">Form Logs
        <button type="button" class="btn-close btn pull-right" data-bs-dismiss="modal" aria-label="Close"
            (click)="modalService.hide()">
            <i class="fa fa-times"></i>
        </button>
    </div>
    <div class="card-body">

        <table class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>By</th>
                    <th>Operations</th>
                </tr>
            </thead>
            <tbody *ngIf="logs?.length">
                <tr *ngFor="let log of logs">
                    <td>{{log.dateTime | datetime}}</td>
                    <td>
                        <div class="d-flex flex-column">
                            {{log.userFullName}} <span *ngIf="log.userMobile">({{log.userMobile | mobile}})</span>
                        </div>
                        <div class="mt-2 company-name" *ngIf="log.userExtraInfo">
                            {{log.userExtraInfo}}
                        </div>
                    </td>
                    <td>
                        <div *ngFor="let l of log.logItemDescriptions">
                            <strong>{{l.title}}</strong>

                            <div *ngIf="showLink(l)">
                                <a href="{{l.oldValue}}" target="_blank" *ngIf="l.oldValue">
                                    <img src="{{l.oldValue}}" class="log" />
                                </a>
                                <span *ngIf="!l.oldValue">-</span>
                                →
                                <a href="{{l.newValue}}" target="_blank" *ngIf="l.newValue">
                                    <img src="{{l.newValue}}" class="log" />
                                </a>
                                <span *ngIf="!l.newValue">-</span>
                            </div>
                            <div *ngIf="isDateLogValue(l)">{{l.oldValue | date}} → {{l.newValue | date}}</div>
                            <div *ngIf="!showLink(l) && !isDateLogValue(l)">{{getLogDescription(l)}}</div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="!logs?.length">
                <tr>
                    <td colspan="3">No logs found</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>