import { Pipe, PipeTransform } from '@angular/core';
import {PermitStartDatePolicyEnum} from "../enums/permit-start-date-policy.enum";

@Pipe({
  name: 'permitStartDatePolicy'
})
export class PermitStartDatePolicyPipe implements PipeTransform {

  transform(value: unknown): any {
    if (value === null) { return "Any start date"; }
    if (value === PermitStartDatePolicyEnum.AfterTheApplicationDate) { return "After the application date"; }
    return value;
  }

}
