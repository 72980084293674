export class SiteAttendanceBlockedUserViewModel {
    public id: number;
    public userId: number;
    public fullName: string;
    public firstName: string;
    public lastName: string;
    public mobile: string;
    public siteId: number;
    public siteName: string;
    public inEdit : boolean;
}