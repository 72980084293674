import { Component, Input, OnInit } from '@angular/core';
import { finalize } from "rxjs/operators";
import { SupplierFormService } from "../../../services/supplier-form.service";
import { SupplierFormHistoryViewModel } from "../../../models/supplier-form/supplier-forms-view-mode";
import { ModalService } from "../../../services/modal.service";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'obc-supplier-form-history',
  templateUrl: './supplier-form-history.component.html',
  styleUrls: ['./supplier-form-history.component.scss']
})
export class SupplierFormHistoryComponent implements OnInit {

  inProgress: boolean = false;
  @Input() supplierId: number;
  @Input() formId: number;
  formHistory: SupplierFormHistoryViewModel[] = [];
  selectedFormDataId: number;

  @Input() hashLink: string;

  constructor(private supplierFormService: SupplierFormService,
    private modalService: ModalService,
    private bsModalService: BsModalService,
  ) {
  }

  ngOnInit(): void {
    (this.hashLink != null ? this.supplierFormService.getAnonymousFormHistory(this.formId, this.hashLink)
      : this.supplierFormService.getFormHistory(this.supplierId, this.formId))
      .pipe(finalize(() => {
      }))
      .subscribe(res => {
        this.inProgress = false;
        if (res != null && res) {
          this.formHistory = res;
        }
      }, err => {
        this.inProgress = false;
        this.modalService.error(err, "Unable to get information");
      }, () => {
        this.inProgress = false;
      });
  }

  bsModalRef: BsModalRef;
  onViewForm(template, formDataId: number) {
    this.selectedFormDataId = formDataId;
    this.bsModalRef = this.bsModalService.show(template, { class: "modal-lg" });
  }

  closeFormDetailsModal() {
    this.bsModalRef.hide();
  }

}
