export class EmergencyContactViewModel {
  public mobile: string
  public relationship: string
  public name: string

  constructor(name, mobile, relationship) {
    this.mobile = mobile;
    this.relationship = relationship;
    this.name = name;
  }

  static newEmptyAnswer() {
    return {name: '', mobile: '', relationship: ''} as EmergencyContactViewModel;
  }
  static isEmpty(data: EmergencyContactViewModel) {
    return !(data.mobile?.toString().length > 0 ||
      data.name?.toString().length > 0 ||
      data.relationship?.toString().length > 0);
  }
}
