import { Pipe, PipeTransform } from '@angular/core';
import { AskMode } from '../enums/ask-mode';

@Pipe({
  name: 'askMode'
})
export class AskModePipe implements PipeTransform {

  transform(value: any): string {
    if (value == AskMode.AnyTime) return "Any Time";
    if (value == AskMode.CheckIn) return "Check In";
    if (value == AskMode.PreCheckIn) return "Pre Check In";
    return value?.toString() ?? "?";
  }

}
