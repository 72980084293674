<div *ngIf="filters?.length">
  <label>Filter Info</label>
  <div class="">
    <ng-container *ngFor="let item of filters">
      <div class="alert alert-default d-flex flex-row-reverse m-2 filter-card">
        <button type="button" class="close ml-2 align-self-start" data-dismiss="alert" aria-label="Close"
                (click)="
                        onRemoveDynamicFieldFromFilter(item.dynamicFieldId);
                      ">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="mr-1 flex-fill">
          <div class="mb-2">
            <strong>{{ item.dynamicField.fieldName }}</strong>
          </div>
          <div class="mb-2">
            {{ item.operator | textOperator }}
          </div>
          <small *ngIf="hasOperand(item.operator)">{{ item.value }}</small>
        </div>
      </div>
    </ng-container>
  </div>
</div>
