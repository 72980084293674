import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ErrorService } from './error.service';
import { FullProductPlanViewmodel, RequestProductPlanModel } from '../models/product-plan-viewmodel';

@Injectable({
  providedIn: 'root'
})
export class ProductPlanService {
  apiUrl: string = "/api/ProductPlan";
  constructor(private http: HttpClient, private _errorService: ErrorService) { }
  getActivePlan = (): Observable<FullProductPlanViewmodel> =>
    this.http.get<any>(`${this.apiUrl}/get-activeplan`)
      .pipe(catchError(this._errorService.handleError));
  getAllPlans = (): Observable<FullProductPlanViewmodel[]> =>
    this.http.get<any>(`${this.apiUrl}/get-all-plans`)
      .pipe(catchError(this._errorService.handleError));
  addUpdatePlan = (model: RequestProductPlanModel): Observable<number> =>
    this.http.post<any>(`${this.apiUrl}/add-update-plan`, model)
      .pipe(catchError(this._errorService.handleError));
}
