import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { noop, Observable, Observer, of } from 'rxjs';
import { UserBasicInfoViewModel } from 'src/app/models/user-basic-info-viewmodel';
import { debounceTime, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api';
import { UserAutoCompleteFilterByFields } from 'src/app/enums/user-auto-complete-target-fields';
import { MobilePipe } from 'src/app/pipes/mobile.pipe';
import { UserSearchModel } from 'src/app/models/user-search-model';
import { SiteAssetService } from 'src/app/services/site-asset.service';

@Component({
  selector: 'obc-user-autocomplete',
  templateUrl: './user-autocomplete.component.html',
  styleUrls: ['./user-autocomplete.component.scss'],
  providers: [MobilePipe]
})
export class UserAutocompleteComponent implements OnInit {
  inProgress: boolean = false;
  suggestions$: Observable<any[]>;
  loader: boolean = false;
  _currentUser: UserBasicInfoViewModel;
  @Input() isValid: boolean = true;
  @Input() container: string = "body";
  @Input() placeholder: string = '';
  @Input() display: string = null;
  @Input() disabled: boolean = false;
  @Input() displayNoResult: boolean = true;
  @Input() filterBy: UserAutoCompleteFilterByFields;
  @Input() required: boolean = false;
  @Input() loadForExternalCompany: boolean = false;
  @Input() notFoundMessage: string = 'user not found'
  @Input() hashLink: string;
  @Input() set currentUser(model: UserBasicInfoViewModel) {
    this._currentUser = model;
    if (model != null) {
      if (model.id) {
        this._selectedUserForValidator = model;
      }
      this.resetSearchTerm();
      this.noResult = false;
    }
  };

  @Input() filterByCurrentUserRegion?: boolean = false;

  get currentUser(): UserBasicInfoViewModel {
    return this._currentUser;
  }

  _selectedUserForValidator: UserBasicInfoViewModel;
  @Output()
  selectedUser: EventEmitter<UserBasicInfoViewModel> = new EventEmitter();
  @Output() inputChange: EventEmitter<{ field: string, value: string }> = new EventEmitter();
  searchTerm: string = '';

  constructor(
    private userService: UserService,
    private mobilePipe: MobilePipe,
    private siteAssetService: SiteAssetService) { }

  ngOnInit(): void {
    this.getUserList();

    this.selectedUser.asObservable().subscribe((res) => {
      this._selectedUserForValidator = res?.id ? res : null;
    });
  }

  get isEnabled(): boolean {
    return this.hashLink?.length > 0 || this.userService.currentCompanyId > 0;
  }

  get isInvalid(): boolean {
    return !this.isValid || (this.required && !this._selectedUserForValidator && !this.searchTerm?.length);
  }

  getUserList() {
    this.suggestions$ = new Observable((observer: Observer<string>) => {
      observer.next(this.searchTerm);
    }).pipe(
      filter(x => this.searchTerm != null && this.searchTerm.length > 3 && this.isEnabled),
      debounceTime(500),
      switchMap((query: string) => {
        if (query) {
          this.loader = true;
          var model = {
            searchterm: query,
            targetField: this.filterBy,
            filterByCurrentUserRegion: this.filterByCurrentUserRegion,
            isSupplierMode: this.loadForExternalCompany ?? false,
            hashLink: this.hashLink
          } as UserSearchModel;

          return (this.hashLink?.length ? this.siteAssetService : this.userService)
            .searchUser(model)
            .pipe(
              map((data: UserBasicInfoViewModel[]) => data && data || []),
              tap(() => noop, err => {
                var rrr = err && err.message || 'Something goes wrong';
              }),
              finalize(() => { this.loader = false })
            );
        }

        return of([]);
      })
    );
  }

  onSelectUser(event: TypeaheadMatch): void {
    this.currentUser = event.item;
    this.currentUser.mobile = "+" + this.currentUser.mobile;
    this.resetSearchTerm();
    this.selectedUser.emit(event.item);
  }

  getSearchTerm() {
    if (this.currentUser)
      return (this.currentUser?.firstName ?? " ") + ' ' + (this.currentUser?.lastName ?? " ") + ' ' + ((this.currentUser?.mobile ? this.mobilePipe.transform(this.currentUser?.mobile) : ''));
    return '';
  }

  resetSearchTerm() {
    if (this.display)
      this.searchTerm = this.currentUser[this.display];
    else
      this.searchTerm = this.getSearchTerm();
    this.inputChange.emit({ field: this.display, value: this.searchTerm });
  }

  reset() {
    this.searchTerm = null;
    this.currentUser = new UserBasicInfoViewModel();
    this.onChange(null);
  }

  onChange($event) {
    if (this._currentUser && this._currentUser?.id != undefined && this.getSearchTerm() != this.searchTerm) {
      this.selectedUser.emit(this.noUserData());
    }

    if (this.searchTerm != '[object Object]')
      this.inputChange.emit({ field: this.display, value: this.searchTerm });
  }

  noResult: boolean = false;
  typeaheadNoResults(event: boolean): void {
    this.noResult = event;
    this.selectedUser.emit(this.noUserData());
  }

  noUserData() {
    return { id: null, firstName: '', lastName: '', mobile: '', isIncompleteRegister: false };
  }
}
