<div class="row align-items-center mt-5">
  <div class="col-12 col-md-9">Answer Options</div>
  <div class="col-12 col-md-3">
    <button type="button" class="btn btn-primary btn-block"
            [disabled]="disabledAddNewRow()"
            (click)="addNewItemRow()">
      <i class="fas fa-plus"></i>
      Add Answer
    </button>
  </div>
</div>
<div class="row mt-2 align-items-center styles-header text-center font-weight-bold">
  <div class="col-12 col-md-3">Answers</div>
  <div class="col-12 col-md-3">Hints</div>
  <div class="col-12 col-md-3">Prevent Submission</div>
  <div class="col-12 col-md-3">Actions</div>
</div>
<div id="sortable-list-{{ sortableUniqueId }}">
  <ng-container *ngFor="let item of items;let i=index">
    <div [attr.data-id]="item._clientRandomId" class="row mt-2 align-items-center">
      <div class="col-12 col-md-3">
        <ng-container *ngTemplateOutlet="buttonWrapperStyle, context: {item: item}"></ng-container>
      </div>
      <div class="col-12 col-md-3">
        <ng-container *ngTemplateOutlet="hintWrapperStyle, context: {item: item}"></ng-container>
      </div>
      <div class="col-12 col-md-3">
        <div class="checkbox-wrapper">
          <input tabindex="3" type="checkbox" id="checkbox-{{ item._clientRandomId }}"
                 (ngModelChange)="updatePreventSubmission()"
                 [(ngModel)]="item.isInvalid" />
          <label for="checkbox-{{ item._clientRandomId }}" class="check-box"></label>
        </div>
      </div>
      <div class="col-12 col-md-3">
        <div class="d-flex no-gutters align-items-center justify-content-center">
          <div dropdown [dropup]="true" class="mx-1">
            <button
              dropdownToggle
              type="button" class="btn btn-primary btn-item-feature change-style-button">
              <svg style="position: relative; top: -3px" width="16" height="16" viewBox="0 0 14 14" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M4.05324 1.54133L1.72387 10.2347C1.48566 11.1237 2.01332 12.0376 2.90253 12.2758C3.79164 12.5141 4.70553 11.9864 4.94377 11.0973C4.94377 11.0973 4.94377 11.0973 4.94377 11.0973L7.27297 2.40391L4.05324 1.54133ZM7.35418 2.10082C7.35413 2.10097 7.35422 2.10066 7.35418 2.10082ZM4.0951 0.17219C3.55078 0.0263562 2.99217 0.349693 2.84653 0.89321L0.435967 9.88963C0.00711401 11.4901 0.957144 13.1349 2.55744 13.5637C2.82142 13.6345 3.08661 13.6677 3.34736 13.6667H12.6469C13.2095 13.6667 13.6667 13.2108 13.6667 12.6471L13.6665 8.68617C13.6665 8.61023 13.6538 8.53726 13.6304 8.46926C13.6236 8.34286 13.5929 8.21563 13.5361 8.09391L11.8622 4.50439C11.6246 3.99481 11.0183 3.77259 10.5072 4.01094L7.89658 5.22838L8.64208 2.44589C8.78786 1.90183 8.46488 1.34289 7.92109 1.19719L4.0951 0.17219C4.0951 0.172192 4.09509 0.172187 4.0951 0.17219ZM7.44612 6.90964L6.32 11.1128L12.1952 8.37312L10.7863 5.35197L7.44612 6.90964ZM12.3332 9.77991L6.85738 12.3333H12.3333L12.3332 9.77991ZM2.66651 10.7334C2.66651 10.3652 2.96499 10.0667 3.33318 10.0667H3.33451C3.51403 10.0667 3.68597 10.1391 3.81142 10.2676C3.93686 10.396 4.00521 10.5696 4.00099 10.7491L4.00096 10.7504C3.99681 10.9272 3.92257 11.0951 3.79458 11.2172C3.66659 11.3392 3.49534 11.4054 3.31853 11.4012L3.31723 11.4012C2.95536 11.3925 2.66651 11.0967 2.66651 10.7347V10.7334Z"
                      fill="white"/>
              </svg>
            </button>
            <div class="dropdown-menu" *dropdownMenu>
              <div class="row no-gutters align-items-center justify-content-center" style="width: 140px;">
                <ng-container *ngTemplateOutlet="styleButton, context: {style: DisplayStyle.Error, item: item}"></ng-container>
                <ng-container *ngTemplateOutlet="styleButton, context: {style: DisplayStyle.Warning, item: item}"></ng-container>
                <ng-container *ngTemplateOutlet="styleButton, context: {style: DisplayStyle.Success, item: item}"></ng-container>
              </div>
            </div>
          </div>
          <button type="button" class="btn btn-primary btn-item-feature mx-1 move-handler move-button">
            <i class="fa fa-arrows-alt"></i>
          </button>
          <button type="button" class="btn btn-primary btn-item-feature mx-1 remove-button" (click)="removeItemRow(item)">
            <i class="fas fa-times"></i>
          </button>
          <i class="fa fa-exclamation-triangle item-error-icon"
             [tooltip]="getTooltipText(item._clientRandomId)"
             style="opacity: {{ invalidRows[item._clientRandomId] ? 1: 0}}"></i>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="text-center my-4" *ngIf="items?.length === 0">
  There is no items added yet
</div>

<ng-template #styleButton let-style="style" let-item="item">
  <div class="col-4">
    <button
      (click)="chooseQuestionStyle($event, style, item)"
      type="button" class="mx-auto d-block btn btn-primary btn-item-feature
      {{ item.displayStyle === style ? 'btn-style-selected' : '' }}"
      style="background: {{ buttonStyles(style)?.background ?? getDefaultStyleControlColor() }}; border-color: {{ buttonStyles(style).border }};">
      <i *ngIf="item.displayStyle === style" class="fas fa-check" style="color: #000"></i>
    </button>
  </div>
</ng-template>

<ng-template #titleInputTemplate let-item="item" let-textColor="textColor">
  <textarea
    #input
    ondragstart="return false;" ondrop="return false;"
    spellcheck="false"
    placeholder="Type answer here"
    style="height: {{input.scrollHeight}}px; {{ textColor ? 'color: ' + textColor : '' }}"
    (keydown)="detectPressedKey($event)"
    (paste)="updateTitle(item, input)"
    (keyup)="updateTitle(item, input)"
    (input)="updateTitle(item, input)"
    (change)="updateTitle(item, input)"
    [textContent]="item.title">
    {{ item.title }}
  </textarea>
</ng-template>

<ng-template #buttonWrapperStyle let-item="item">
  <ng-container [ngSwitch]="itemRenderMode">
    <div *ngSwitchCase="ItemRendererDisplayMode.Button"
         class="title-wrapper-class title-wrapper-button" style="background: {{ buttonStyles(item.displayStyle)?.background ?? getDefaultStyleControlColor() }}">
      <ng-container *ngTemplateOutlet="titleInputTemplate; context: {item: item} "></ng-container>
    </div>
    <div *ngSwitchCase="ItemRendererDisplayMode.CheckBox" class="d-flex title-wrapper-class">
      <i class="title-wrapper-checkbox fa fa-check mr-2"
         style="background: {{ buttonStyles(item.displayStyle)?.background ?? getDefaultStyleControlColor() }}"></i>
      <ng-container *ngTemplateOutlet="titleInputTemplate; context: {item: item} "></ng-container>
    </div>
    <div *ngSwitchCase="ItemRendererDisplayMode.RadioButton" class="d-flex title-wrapper-class align-items-center">
      <div class="title-wrapper-radio-container">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 7C10 8.65685 8.65685 10 7 10C5.34315 10 4 8.65685 4 7C4 5.34315 5.34315 4 7 4C8.65685 4 10 5.34315 10 7Z"
                [attr.fill]="buttonStyles(item.displayStyle)?.background ?? getDefaultStyleControlColor()"
                />
          <circle cx="7" cy="7" r="6"
                  [attr.stroke]="buttonStyles(item.displayStyle)?.background ?? getDefaultStyleControlColor()"
                  stroke-width="2"/>
        </svg>
      </div>
      <ng-container *ngTemplateOutlet="titleInputTemplate; context: {item: item} "></ng-container>
    </div>
    <div *ngSwitchCase="ItemRendererDisplayMode.Dropdown" class="d-flex title-wrapper-class">
      <div class="mr-2 title-wrapper-dropdown-container d-flex align-items-center p-2" style="border: 2px solid {{ buttonStyles(item.displayStyle)?.background ?? getDefaultStyleControlColor() }};">
        <ng-container *ngTemplateOutlet="titleInputTemplate; context: {item: item, textColor: buttonStyles(item.displayStyle)?.border} "></ng-container>
        <i class="fa fa-chevron-down ml-1"
           style="color: {{ buttonStyles(item.displayStyle)?.background ?? getDefaultStyleControlColor() }};"></i>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #hintWrapperStyle let-item="item">
  <div class="hint-wrapper-textarea">
    <textarea
         #hintInput
         style="height: {{hintInput.scrollHeight}}px;"
         ondragstart="return false;" ondrop="return false;"
         spellcheck="false"
         placeholder="Type a hint here (optional)"
         (keydown)="detectPressedKey($event)"
         (paste)="updateHint(item, hintInput)"
         (keyup)="updateHint(item, hintInput)"
         (input)="updateHint(item, hintInput)"
         (change)="updateHint(item, hintInput)"
         [textContent]="item.hint">
      {{ item.hint }}
    </textarea>
  </div>
</ng-template>
