import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserPermission } from '../enums/user-permission.enum';
import { RouteDataModel } from '../models/route-data-model';
import { UserService } from './user.service';

class BasePermissionGuardService {
  constructor(protected userService: UserService, protected router: Router) {

  }

  handleNoAccess(routerState: RouterStateSnapshot, redirectUrl?: string) {
    if (!this.userService.info.value)
      return;
    this.router.navigate([redirectUrl ?? '/no-access'], { queryParams: { returnPath: routerState.url } });
  }
}


@Injectable({
  providedIn: 'root'
})
export class SecureGuardService {

  constructor(private userService: UserService, private router: Router) {

  }
  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    if (!this.userService.info.value) {
      this.router.navigate(['landing'], { queryParams: { returnPath: routerState.url } });
      return false;
    } else
      return true;
  }
}

@Injectable({
  providedIn: 'root'
})
export class PermissionGuardService extends BasePermissionGuardService {

  constructor(userService: UserService, router: Router) {
    super(userService, router);
  }

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    var routeData = route.data as RouteDataModel;

    if (!this.userService.hasUserPermissionForCompany(routeData.permission)) {
      this.handleNoAccess(routerState);
      return false;
    } else
      return true;
  }
}

@Injectable({
  providedIn: 'root'
})
export class OverLappedPermissionGuardService extends BasePermissionGuardService {

  constructor(userService: UserService, router: Router) {
    super(userService, router);
  }

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    var routeData = route.data as RouteDataModel;
    if (!this.userService.hasUserAnyOfPermissions(routeData.permissions)) {
      this.handleNoAccess(routerState);
      return false;
    } else
      return true;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SitesPageGuardService extends BasePermissionGuardService {

  constructor(userService: UserService, router: Router) {
    super(userService, router);
  }


  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    var permissions = [UserPermission.AdjustSiteLocation,
    UserPermission.ModifySite,
    UserPermission.ManageAutomations,
    UserPermission.ManageSiteAnnouncement,
    UserPermission.ManageSiteQuestion];
    if (!this.userService.hasUserAnyOfPermissions(permissions)) {
      this.handleNoAccess(routerState, "/dashboard");
      return false;
    } else
      return true;
  }
}


@Injectable({
  providedIn: 'root'
})
export class CompanyAdminGuardService extends BasePermissionGuardService {

  constructor(userService: UserService, router: Router) {
    super(userService, router);
  }

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    if (!this.userService.currentCompanyId) {
      this.router.navigate(['/not-found'], { queryParams: { returnPath: routerState.url } });
    }
    else {
      if (!this.userService.isCompanyAdminUser()) {
        this.handleNoAccess(routerState);
        return false;
      } else
        return true;
    }
  }
}


@Injectable({
  providedIn: 'root'
})
export class SiteAdminGuardService extends BasePermissionGuardService {

  constructor(userService: UserService, router: Router) {
    super(userService, router);
  }

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    if (!this.userService.currentCompanyId) {
      this.router.navigate(['/not-found'], { queryParams: { returnPath: routerState.url } });
    }
    else {
      if (!this.userService.hasAdminPermissionOnAnySite()) {
        this.handleNoAccess(routerState);
        return false;
      } else
        return true;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class GodUserGuardService extends BasePermissionGuardService {

  constructor(userService: UserService, router: Router) {
    super(userService, router);
  }

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    var routeData = route.data as RouteDataModel;

    if (!this.userService.isGodUser() ||
      (routeData.godPermission && !this.userService.hasGodUserPermissionForCompany(routeData.godPermission))) {
      this.handleNoAccess(routerState);
      return false;
    } else
      return true;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CompanyOwnerGuardService extends BasePermissionGuardService {

  constructor(userService: UserService, router: Router) {
    super(userService, router);
  }

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    if (!this.userService.isCompanyOwnerUser()) {
      this.router.navigate(['/not-found'], { queryParams: { returnPath: routerState.url } });
      // this.handleNoAccess(routerState);
      // return false;
    } else
      return true;
  }
}
