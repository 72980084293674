import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ErrorService } from "./error.service";
import { AddCompanyDocumentTypeModel } from "../models/company-document-type-model";
import { Observable } from "rxjs";
import { ServerResultData } from "../models/server-result-data";
import { UserDocumentType } from "../models/user-document-type";

@Injectable({
  providedIn: 'root'
})
export class CompanyDocumentTypeService {

  constructor(private http: HttpClient, private errorService: ErrorService) { }

  add(model: AddCompanyDocumentTypeModel): Observable<ServerResultData<any>> {
    return this.http.post<any>("/api/CompanyDocumentType/add",model);
  }

  list(): Observable<UserDocumentType[]> {
    return this.http.get<any>("/api/CompanyDocumentType/list");
  }

  onlySelectableList(): Observable<UserDocumentType[]> {
    return this.http.get<any>("/api/CompanyDocumentType/only-selectable-list");
  }

  remove(id: number): Observable<ServerResultData<any>> {
    return this.http.delete<any>(`/api/CompanyDocumentType/remove/${id}`);
  }

}
