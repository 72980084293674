import { ValidatorFn, AbstractControl } from '@angular/forms';
import { CheckListItemViewModel } from '../custom-controls/check-list-form-control/check-list-item';

export function e168ValidatorNullableCompanyPhoneNumber(control: AbstractControl): { [key: string]: boolean } | null {
    let value = control.value as string;
    if (value == null || value.length == 0) return null;
    let valid = value.length >= 3;
    return valid ? null : { 'invalidE168': true };
}

export function e168ValidatorNullable(control: AbstractControl): { [key: string]: boolean } | null {
    var value = control.value as string;
    if (value == null || value.length == 0) return null;
    var valid = true;
    var valid = mobileIsValid(value);
    return valid ? null : { 'invalidE168': true };
}

export function e168Validator2(control: AbstractControl): { [key: string]: boolean } | null {
    var value = control.value as string;
    var valid = true;
    var valid = mobileIsValid(value);
    return valid ? null : { 'invalidE168': true };
}

export function e168Validator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        var value = control.value as string;
        var valid = mobileIsValid(value);
        return valid ? null : { 'invalidE168': { value: control.value } };
    };
}

export function mobileIsValid(value) {
    var valid = true;
    if (!value || !(value.length >= 9 && value.length <= 15))
        valid = false;
    if (!isNormalInteger(value?.replace(/\+/g, '')))
        valid = false;

    return valid;
}

// export function e168Validator(control: AbstractControl): { [key: string]: any } | null {
//     var value = control.value as string;
//     var valid = true;
//     if (!value || !(value.length >= 10 && value.length <= 15)) valid = false;
//     if (!isNormalInteger(value)) valid = false;
//     return valid ? null : { 'invalidE168': { value: control.value } };
// }

function isNormalInteger(str) {
    if (!str) { // for empty
        return false;
    }
    str = str.trim();
    if (!str) { // for spaces
        return false;
    }
    str = str.replace(/^0+/, "") || "0";
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
}

export function documentKindValidator(control: AbstractControl): { [key: string]: boolean } | null {
    var value = control.value as CheckListItemViewModel[];
    let data = value?.find(p => p.value == true);
    return data ? null : { 'invalidE168': true };
}
