<div class="row">
  <div class="col-md-12">
    <div class="card">
      <header class="card-header">Permits</header>
      <div class="card-body">
        <span class="mr-5">
          <span class="d-inline-block mt-2 mr-1">Include Inactive Permits: </span>
          <obc-switch [trueLabel]="'Yes'" [falseLabel]="'No'" [value]="includeInactivePermitForms"
            (valueChanged)="onIncludeInactivePermitFormsChanged($event)">
          </obc-switch>
        </span>
        <app-base-grid
          [columns]="gridColumns"
          [configs]="gridConfig" #grid>

          <ng-template obcGridTemplateName="viewDetails" let-data="data">
            <button class="btn btn-info pull-right ml-1 mb-1" (click)="openModal(formDetails, data)">
              View Details
            </button>
            <button *ngIf="data.formDataId > 0" type="button" class="btn btn-success pull-right"
                    (click)="exportPdf(data)">
              <i class="fas fa-file-pdf-o"></i>
              PDF
            </button>
          </ng-template>

          <ng-template obcGridTemplateName="startEndDate" let-data="data">
            <div *ngIf="isShowTemplateColumns('startDate','startDate') && data.startDate">Start: {{data.startDate | date}}</div>
            <div *ngIf="isShowTemplateColumns('startDate','endDate') && data.endDate">End: {{data.endDate | date}}</div>
          </ng-template>

          <ng-template obcGridTemplateName="permitStatus" let-data="data">
            {{data.statusInfo?.currentStatus | permitStatus}}
          </ng-template>

          <ng-template obcGridTemplateName="controls" let-data="data">
            <button *ngIf="data.statusInfo?.canOpen == true" class="btn btn-success ml-2 btn-sm mb-2"
                    (click)=" data.statusInfo?.permitInUseForm == null
                      ? onConfirm(data,PermitStatus.Open)
                      : onOpenPermitStatusForm(showFormDataRenderer ,data, PermitStatus.Open)">
              Open
            </button>

            <button *ngIf="data.statusInfo?.canOnhold == true" class="btn btn-danger ml-2 btn-sm mb-2"
                    (click)=" data.statusInfo?.permitOnholdForm == null
                      ? onConfirm(data,PermitStatus.OnHold)
                      : onOpenPermitStatusForm(showFormDataRenderer ,data, PermitStatus.OnHold)">
              Suspend
            </button>

            <button *ngIf="data.statusInfo?.canClose == true" class="btn btn-primary ml-2 btn-sm mb-2"
                    (click)=" data.statusInfo?.permitCloseForm == null
                      ? onConfirm(data,PermitStatus.Close)
                      : onOpenPermitStatusForm(showFormDataRenderer ,data,PermitStatus.Close)">
              Close
            </button>

            <button *ngIf="data.statusInfo?.statusChangeRequest != null"
                    class="btn btn-success ml-2 btn-sm mb-2"
                    (click)="onConfirm(data,data.statusInfo.statusChangeRequest,ApproveRejectPermit.Approve)">
              Approve
            </button>

            <button *ngIf="data.statusInfo?.statusChangeRequest != null"
                    class="btn btn-danger ml-2 btn-sm mb-2"
                    (click)="onConfirm(data,data.statusInfo.currentStatus,ApproveRejectPermit.Reject)">
              Reject
            </button>

            <a target="_blank" href="{{'/api/Permit/export-pdf/'+data.filledFormId}}"
            class="btn btn-sm btn-success ml-2 mb-2">PDF</a>

            <button class="btn btn-info ml-2 btn-sm mb-2 pull-left"
                    (click)="openModal(formDetails, data.filledFormId)">
              View Details
            </button>

            <button class="btn btn-warning ml-2 btn-sm mb-2 pull-left"
                    (click)="openModal(permitHistory,data.filledFormId)">
              History
            </button>
          </ng-template>

          <ng-template #formDetails>
            <obc-form-details [formDataId]="selectedFormDataId" (onRefesh)="closeModal();"></obc-form-details>
          </ng-template>

          <ng-template #showFormDataRenderer>
            <obc-update-permit-status [siteId]="selectedPermit.siteId" [permitStatusForm]="permitStatusForm"
                                      [permitId]="selectedPermit.filledFormId" [nextStatus]="nextStatus" (onHide)="closeModal()"
                                      (onSubmitted)="closeModal()">
            </obc-update-permit-status>
          </ng-template>

          <ng-template #permitHistory>
            <obc-permit-history [permitId]='selectedFormDataId' (onHide)='closeModal()'></obc-permit-history>
          </ng-template>

        </app-base-grid>
      </div>
    </div>
  </div>
</div>



