import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { LoaderComponent } from 'src/app/custom-controls/loader/loader.component';
import { AnnonymousAcceptSupplierDocument } from 'src/app/models/supplier-document/accept-supplier-document';
import { SupplierDocumentViewModel } from 'src/app/models/supplier-document/site-supplier-document-version-signoff-viewModel';
import { ModalService } from 'src/app/services/modal.service';
import { SupplierDocumentService } from 'src/app/services/supplier-document.service';

@Component({
  selector: 'obc-supplier-document-sign-off-viewer',
  templateUrl: './supplier-document-sign-off-viewer.component.html',
  styleUrls: ['./supplier-document-sign-off-viewer.component.scss']
})
export class SupplierDocumentSignOffViewerComponent implements OnInit {

  @Input() list: SupplierDocumentViewModel[];
  @Input() canApprove: boolean;
  @Input() compactMode: boolean = false;
  @Input() AnonymousCheckInKey: any;
  @Output() onAccept: EventEmitter<boolean> = new EventEmitter();

  constructor(private supplierDocumentService: SupplierDocumentService, private modalService: ModalService) { }

  ngOnInit(): void {
  }

  onAcceptSupplier(siteSupplierDocumentVersionId: number, loader: LoaderComponent) {
    loader.show = true;
    var model = {
      supplierDocumentVersionId: siteSupplierDocumentVersionId,
      AnonymousCheckInKey: this.AnonymousCheckInKey
    } as AnnonymousAcceptSupplierDocument;
    this.supplierDocumentService.acceptAnonymousSupplierDocument(model)
      .pipe(finalize(() => loader.show = false))
      .subscribe(res => {
        if (res == true) {
          this.onAccept.emit(true);
        } else this.modalService.error('Request Failed');
      }, err => this.modalService.error('Request Failed'));
  }
}
