import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnnouncementViewModel } from 'src/app/models/announcement-viewmodel';
import { AnnouncementModel } from 'src/app/models/induction-viewmodel';
import { SiteAnnouncementForceViewMode } from "../../enums/site-announcement-force-view-mode";

@Component({
  selector: 'app-announcement-selector',
  templateUrl: './announcement-selector.component.html',
  styleUrls: []
})
export class AnnouncementSelectorComponent implements OnInit {
  title = "Select Announcement(s)";
  filterTerm = null;
  SiteAnnouncementForceViewMode = SiteAnnouncementForceViewMode;
  @Input() announcements: AnnouncementViewModel[] = [];
  @Input() addedAnnouncements: AnnouncementModel[] = [];
  @Output() addAnnouncement = new EventEmitter();
  @Output() public hide = new EventEmitter();

  get availableAnnouncements(): AnnouncementViewModel[] {

    let filteredArray  = this.announcements.filter((array_el) => {
      return this.addedAnnouncements.filter((anotherOne_el) => {
        return anotherOne_el.announcementId == array_el.announcementId;
      }).length == 0
    });

    return filteredArray.filter(function (x) { return x.isActive });
  };

  constructor() { }

  ngOnInit() {
  }

  setState(announcement, $event) {
    announcement.forceViewMode = $event
  }

  onAdd(announcement: AnnouncementViewModel) {
    var pendingAnnouncement = this.announcements.find(x => x.id == announcement.id);
    pendingAnnouncement.forceViewMode = announcement.forceViewMode;
    if (pendingAnnouncement && this.addAnnouncement) {
      this.addAnnouncement.emit(pendingAnnouncement);
    }
  }
}
