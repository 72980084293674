import { OtpRequestMode } from "../enums/otp-request-mode.enum"
import { TwoFactorVerificationMode } from "../enums/two-factor-verification-mode"

export class UserCredentialsViewModel {
    mobile: string;
    securityToken: string;
    regionCode: string;
    password: string;
    mode: OtpRequestMode = OtpRequestMode.Register;
    sendMode?: TwoFactorVerificationMode;
    isPersisted: boolean;
}
