import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { SupplierDocumentReviewMode } from 'src/app/enums/supplier-document-review-mode.enum';
import { ISiteSupplierDocumentTypeVersion } from 'src/app/models/supplier-document/site-supplier-document-type';
import { ModalService } from 'src/app/services/modal.service';
import { IWorkflowStep } from 'src/app/workflow/IWorkflowStep';
import { SupplierDocumentReviewComponent } from '../../supplier-document-review.component';
import { WorkflowInfoViewModel } from 'src/app/models/workflow/workflow-info-viewmodel';
import { CommandView } from 'src/app/models/workflow/command-view';
import { FormDataModel } from 'src/app/models/form/form-data-model';
import { SaveStepViewModel } from 'src/app/models/workflow/continue-workflow-query-viewModel';
import { SupplierDocumentService } from 'src/app/services/supplier-document.service';

@Component({
  selector: 'obc-supplier-document-review-workflow',
  templateUrl: './supplier-document-review-workflow.component.html',
  styleUrls: ['./supplier-document-review-workflow.component.scss']
})
export class SupplierDocumentReviewWorkflowComponent implements OnInit, IWorkflowStep {

  @ViewChild('supplierDocument') supplierDocument: SupplierDocumentReviewComponent;

  isInitilize: boolean = false;
  inProgress: boolean;

  supplierId: number;
  siteId: number;
  siteSupplierId: number;
  supplierDocumentReviewMode: SupplierDocumentReviewMode;
  selectedSiteSupplierDocument: ISiteSupplierDocumentTypeVersion;
  _workflow: WorkflowInfoViewModel;
  get workflow(): WorkflowInfoViewModel {
    return this._workflow;
  }

  set workflow(value: WorkflowInfoViewModel) {
    this._workflow = value;
  }

  constructor(
    private siteSupplierService: SupplierDocumentService,
    private modalService: ModalService,
    private toastrService: ToastrService) { }

  ngOnInit(): void {
  }


  initWorkflow(workflow: WorkflowInfoViewModel): Promise<boolean> | boolean {
    this.workflow = workflow;
    return new Promise<boolean>((resolve, _) => {
      this.inProgress = true;
      this.siteSupplierService.getSiteSupplierDocumentVersionByWorkflowInstanceId(workflow.id)
        .pipe(finalize(() => this.inProgress = false))
        .subscribe(res => {
          if (res && res.siteId) {
            this.selectedSiteSupplierDocument = {
              files: res.documentTypes[0].siteSupplierDocumentFiles,
              displayVersion: res.documentTypes[0].displayVersion,
              lastSupplierVersionId: res.documentTypes[0].siteSupplierDocumentVersionId,
              supplierDocumentTypeName: res.documentTypes[0].supplierDocumentTypeName,
              documentKindId: res.documentTypes[0].documentKindId,
            } as ISiteSupplierDocumentTypeVersion;

            this.siteId = res.siteId;
            this.supplierId = res.supplierId;
            this.siteSupplierId = res.siteSupplierId;
            this.supplierDocumentReviewMode = res.supplierDocumentReviewMode;
            this.isInitilize = true;
            resolve(true);
          }
          else {
            this.toastrService.warning("There is not workflow");
            resolve(false);
          }
        }, err => {
          this.modalService.error(err);
          resolve(false);

        })
    });

  }

  validateStep<T>(command: CommandView, note: string): boolean | Promise<boolean> {
    if (this.workflow?.currentActivity?.formsToFill?.length > 0 && !this.supplierDocument?.formDataRenderer){
      this.toastrService.warning("Please select a valid form");
      return false;
    }
    else if (this.workflow?.currentActivity?.formsToFill?.length == 0 || this.supplierDocument.isReviewFormValid)
      return true;
    else {
      this.toastrService.warning("Review Form is not valid");
      return false;
    }
  }


  saveStep(command: CommandView, note: string): SaveStepViewModel | Promise<SaveStepViewModel> {

    let formDataModel: FormDataModel;
    if (this.workflow?.currentActivity?.formsToFill?.length > 0) {
      formDataModel = this.supplierDocument.formDataRenderer.getFormResponse();
      formDataModel.siteSupplierDocumentVersionId = this.selectedSiteSupplierDocument.lastSupplierVersionId;
    }

    return <SaveStepViewModel>{
      continue: true,
      data: formDataModel
    };

  }

}
