import { Pipe, PipeTransform } from '@angular/core';
import { ProcoreExportCheckInAsManpowerMode } from '../enums/procore-export-modes.enum';

@Pipe({
  name: 'procoreExportSiteAttendanceAsManpowerModePipe'
})
export class ProcoreExportSiteAttendanceAsManpowerModePipe implements PipeTransform {

  transform(value: any): unknown {
    if (value === ProcoreExportCheckInAsManpowerMode.Disabled) return 'Disabled';
    if (value === ProcoreExportCheckInAsManpowerMode.Everyone) return 'Everyone';
    if (value === ProcoreExportCheckInAsManpowerMode.EveryoneExceptEmployees) return 'Everyone Except Employees';
    if (value === ProcoreExportCheckInAsManpowerMode.SubcontractorsOnly) return 'Subcontractors Only';
    if (value === ProcoreExportCheckInAsManpowerMode.AggregatedSubcontractors) return 'Aggregated For Subcontractors';
    if (value === ProcoreExportCheckInAsManpowerMode.AggregatedSubcontractorsAndEmployees) return 'Aggregated For Subcontractors And Employees';
    return value;
  }

}
