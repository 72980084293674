import { Pipe, PipeTransform } from '@angular/core';
import { MyobPeriodType } from '../enums/report-type.enum';

@Pipe({
  name: 'myobPeriodType'
})
export class MyobPeriodTypePipe implements PipeTransform {

  transform(value: any): string {
    return value == MyobPeriodType.Weekly ? "Weekly" :
      value == MyobPeriodType.Monthly ? "Monthly" :
        (value?.toString() ?? "?");
  }

}
