export enum AccessControlAutomaticAttendanceMode {
  Disabled = 0,
  Enabled = 1,
}

export enum AccessControlInductionMode {
  NoRestriction = 0,
  ApprovedInductions = 1,
  ApprovedOrPendingInductions = 2,
}
export enum AccessControlSiteBriefingMode {
  NoRestriction = 0,
  RequiredSignOffForAllActiveSiteBriefings = 1,
}
export enum AccessControlSupplierDocumentMode {
  NoRestriction = 0,
  RequiredSignOffForAllActiveSupplierDocuments = 1,
}
export enum AccessControlUnitDirection {
  InOut = 0,
  In = 1,
  Out = 2,
}
