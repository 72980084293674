import { Component, OnInit, Input } from '@angular/core';
import { QuestionViewModel } from 'src/app/models/question-viewmodel';
import { ModalService } from 'src/app/services/modal.service';
import { QuestionService } from 'src/app/services/question.service';

@Component({
  selector: 'obc-add-remove-question-all-sites',
  templateUrl: './add-remove-question-all-sites.component.html',
  styleUrls: ['./add-remove-question-all-sites.component.scss']
})
export class AddRemoveQuestionAllSitesComponent implements OnInit {
  @Input() question: QuestionViewModel;
  @Input() addQuestionToAllSites: boolean;

  get title() {
    return this.addQuestionToAllSites ? 'Add Question To All Site' : 'Remove Question From All Site';
  }
  get message() {
    return `This question will be ${this.addQuestionToAllSites ? 'added to' : 'removed from'} all sites. Choose one of CheckIn, CheckOut or both to ${this.addQuestionToAllSites ? 'add' : 'remove'}.`;
  }

  constructor(private modalService: ModalService, private questionService: QuestionService) { }

  ngOnInit(): void {
  }
  addQuestionToAllSite(state: number) {
    // console.log(state);
    // console.log(this.question.questionText);
    // console.log(this.addQuestionToAllSites);

    this.questionService.addRemoveQuestionToAllSites(this.question.id, this.addQuestionToAllSites, state)
      .subscribe(result => {
        if (result)
          this.closeAddQuestionToAllSiteModel();
        else
          this.modalService.error('Operation failed!');
      });
  }
  closeAddQuestionToAllSiteModel() {
    this.modalService.hide();
  }
}
