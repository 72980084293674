import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EvacuationContentEditorType } from 'src/app/enums/supplier-document-editor-type';
import { constructFormGroup } from 'src/app/extensions/form.helper';
import { CompanyEvacuationSettings, emptyCompanyEvacuationSettings, MessageType } from './evacuation-setting-models/CompanyEvacuationSettings';
import { EvacuationService } from 'src/app/services/evacuation.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'obc-evacuation-setting-editor',
  templateUrl: './evacuation-setting-editor.component.html',
  styleUrls: ['./evacuation-setting-editor.component.scss']
})
export class EvacuationSettingEditorComponent implements OnInit {

  EvacuationSettingFormControl: FormGroup;
  evacuationSetting: CompanyEvacuationSettings;
  MessageType = MessageType;
  isLoading = true;

  constructor(
    private evacuationService: EvacuationService,
    private toastrService: ToastrService,
  ) {
  
   }

  EvacuationContentEditorType =  EvacuationContentEditorType;

  private loadEvacuationSettings(): void {
    this.isLoading = true;
    this.evacuationService.getSettings().subscribe(
      settings => {
          this.EvacuationSettingFormControl.patchValue(settings);
          this.evacuationSetting =  this.EvacuationSettingFormControl.value;
          this.isLoading = false;
    });
  }


  ngOnInit(): void {
    this.EvacuationSettingFormControl = constructFormGroup<CompanyEvacuationSettings>(emptyCompanyEvacuationSettings);
    this.loadEvacuationSettings();
  }
  updateEvacuation(){
    this.isLoading = true;
      const updatedSettings: CompanyEvacuationSettings = this.EvacuationSettingFormControl.value;

      this.evacuationService.updateSettings(updatedSettings).subscribe({
        next: () => {
          this.isLoading = false;
          this.toastrService.success('Saved Successfully');
          this.evacuationSetting = updatedSettings;
        }
      });
  }

}
