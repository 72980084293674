<div class="card">
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="row">
      <div class="col-12">
        <form class="form-horizontal form-bordered" [formGroup]="newVisitorTypeGroup" (ngFormSubmit)="addNewVisitorType()">
          <section class="card">
            <div class="card-body">
              <div class="form-group row">
             
                  <label class="control-label text-sm-right pt-2">Type :</label>
               
                <div class="col-sm-11">
                  <input type="text" class="form-control" formControlName="title">
                </div>
              </div>
            </div>
            <footer class="card-footer text-right">
              <button class="btn btn-primary"
                [disabled]="!newVisitorTypeGroup.valid"
                (click)="addNewVisitorType()">
                Add New Type
              </button>
              <button type="reset" class="btn btn-default">Cancel</button>
            </footer>
          </section>
        </form>
        <div class="alert alert-warning mt-1" *ngIf="errorMessage">
          <strong>{{errorMessage}}</strong>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="errorMessage = null">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <hr class="w-100">
      <div class="col-12 mb-2">
        <p style="text-align: center;" *ngIf="visitorTypes == null">
          Loading ...
        </p>
        <p style="text-align: center;" *ngIf="visitorTypes != null && visitorTypes.length == 0">
          Nothing Found
        </p>
        <div class="table-responsive">
          <table *ngIf="visitorTypes != null && visitorTypes.length > 0" class="table table-bordered table-striped mb-0">
            <thead>
              <tr>
                <th>Title</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let visitorType of visitorTypes">
                <td><span>{{visitorType.title}}</span></td>
                <td>
                  <div style="text-align: end;">
                    <button class="btn btn-sm btn-primary" (click)="onShowEditModal(editVisitorTypeModal, visitorType)">
                      <i class="fa fa-edit"></i>
                    </button>
                    <button class="btn btn-sm btn-danger ml-1" (click)="onRemoveModal(visitorType)">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #editVisitorTypeModal>
  <div class="card">
    <div class="card-body">
      <obc-loader [show]="inProgress"></obc-loader>
      <div class="row">
        <div class="col-12">
          <form class="form-horizontal form-bordered" [formGroup]="editVisitorTypeGroup" (ngFormSubmit)="onUpdateVisitorType()">
            <section class="card">
              <div class="card-body">
                <div class="form-group row">
                  <label class="col-sm-4 control-label text-sm-right pt-2">Type :</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="title">
                  </div>
                </div>
              </div>
              <footer class="card-footer text-right">
                <button type="reset" class="btn btn-default" (click)="this.bsModalRef.hide()">Close</button>
                <button class="btn btn-primary"
                  [disabled]="!editVisitorTypeGroup.valid"
                  (click)="onUpdateVisitorType()">
                  Update
                </button>
              </footer>
            </section>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
