import { UserService } from 'src/app/services/user.service';
import { Component , EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuestionViewModel } from '../../../models/question-viewmodel';
import { QuestionType } from '../../../enums/question-type';
import { QuestionService } from '../../../services/question.service';
import { AsyncResult } from '../../../models/helper-models';
import { finalize } from 'rxjs/operators';
import { AskQuestionPeriodMode } from 'src/app/enums/ask-period-mode';
import { QuestionDefaultMode } from 'src/app/enums/question-default-mode';
import { AskMode } from 'src/app/enums/ask-mode';
import { QuestionAssetFieldComponent } from '../question-asset-field/question-asset-field.component';
import { DateQuestionLimitValueMode } from "../../../models/questionParameters/date-question-parameter";
import { QuestionDateResultViewModel } from "../../../models/question-date-result-model";
import { UserDocumentType } from "../../../models/user-document-type";
import { QuestionProfileSubTypeViewModel } from 'src/app/models/question-profile-sub-type-viewmodel';

@Component({
  selector: 'obc-question-add',
  templateUrl: './question-add.component.html',
  styleUrls: ['./question-add.component.scss']
})
export class QuestionAddComponent implements OnInit {

  isGenericDocumentType: boolean = false;
  useRichTextTitle: boolean = false;
  displayAskQuestionField: boolean = true;
  displayQuestionIsSensitive: boolean = true;
  _documentTypeModeStateIsValid: boolean = true;
  QuestionType = QuestionType;

  selectedDocumentTypeIdList: number[] = [];
  pageTitle = 'please add question and press submit.';
  @Output() public result = new EventEmitter<AsyncResult>();
  _question: QuestionViewModel = this.getDefaultQuestion();
  @Input() withResult = false;
  @Input() set question(_question: QuestionViewModel) {
    if (_question) {
      this.pageTitle = 'Please edit question and press submit.'
    }

    let questionViewModel: QuestionViewModel;
    if (_question)
      questionViewModel = new QuestionViewModel(_question);
    else
      questionViewModel = this.getDefaultQuestion();


    this._question = JSON.parse(JSON.stringify(questionViewModel));
    this.useRichTextTitle = (this._question.questionRichText?.length ?? 0) > 0;

    this._questionSingle = JSON.parse(JSON.stringify(this._question.questionParametersAsString));
    this._questionMulti = JSON.parse(JSON.stringify(this._question.questionParametersAsString));
    this._questionText = JSON.parse(JSON.stringify(this._question.questionParametersAsString));
    this._questionBoolean = JSON.parse(JSON.stringify(this._question.questionParametersAsString));
    this._questionFile = JSON.parse(JSON.stringify(this._question.questionParametersAsString));
    this._questionRichText = JSON.parse(JSON.stringify(this._question.questionParametersAsString));
    this._questionDate = JSON.parse(JSON.stringify(this._question.questionParametersAsString));
    this._questionSignaturePad = JSON.parse(JSON.stringify(this._question.questionParametersAsString));
    this._questionAssetField = JSON.parse(JSON.stringify(this._question.questionParametersAsString));
    this._questionProfileInfo = JSON.parse(JSON.stringify(this._question.questionParametersAsString));
    this.selectedDocumentTypeIdList = this._question?.documentTypeIdList;
  }
  _questionSingle: any = {};
  _questionMulti: any = {};
  _questionText: any = {};
  _questionBoolean: any = {};
  _questionFile: any = {};
  _questionRichText: any = {};
  _questionDate: any = {};
  _questionSignaturePad: any = {};
  _questionAssetField: any = {};
  _questionProfileInfo: any = {};

  questionType = QuestionType;
  askPeriodMode = AskQuestionPeriodMode;
  questionDefaultMode = QuestionDefaultMode;
  askMode = AskMode;


  _formIsvalid = true;
  errorMessage = '';
  inProgress: boolean = false;

  onSingleMultiQuestionTypeItemsUpdate(isFormValid: boolean) {
    if (this._question.questionType == this.questionType.MultiSelect ||
      this._question.questionType == this.questionType.SingleSelect) {
      this._formIsvalid = isFormValid;
    }
  }
  onAssetFieldItemsUpdatee(isFormValid: boolean) {
    if (this._question.questionType == this.QuestionType.AssetField) {
      this._formIsvalid = isFormValid;
      this.errorMessage = '';
    }
  }
  formIsvalid() {
    this._formIsvalid = !this.inProgress;
    if (this._question.questionType == this.questionType.MultiSelect ||
      this._question.questionType == this.questionType.SingleSelect) {
      let keys = Object.keys(this._question.questionParametersAsString['itemList'] ?? {});
      if (this._question.questionParametersAsString['itemList'] == undefined ||
        keys == null || keys.length == 0) {
        this._formIsvalid = false;
        this.errorMessage = 'Please Enter question Items';
      }
    }

    if (this._question.questionType == this.questionType.MultiSelect) {
      if (this._question.questionParametersAsString['minimumSelectCount'] == undefined ||
        this._question.questionParametersAsString['minimumSelectCount'] == {}
        || isNaN(this._question.questionParametersAsString['minimumSelectCount'])
        || +this._question.questionParametersAsString['minimumSelectCount'] < 0
        || +this._question.questionParametersAsString['minimumSelectCount'] > Object.keys(this._questionMulti["itemList"]).length) {
        this._formIsvalid = false;
        this.errorMessage = 'Required items count must be between 0 and question items count';
      }
    }

    if (this._question.questionType == QuestionType.AssetField) {
      if (!this._question.questionParametersAsString[QuestionAssetFieldComponent.globalToolDynamicFieldIdKeynewLocal]) {
        this._formIsvalid = false;
        this.errorMessage = 'Please select one of global asset fields.';
      }
    }

    if (this.useRichTextTitle == true && this._question.questionRichText?.length > this.userService.editorMaxLength.value) {
      this._formIsvalid = false;
      this.errorMessage = 'Text field length exceeded the maximum limit. Please reduce the length of your input and try again.';
    }
  }

  constructor(private questionService: QuestionService, private userService: UserService) { }

  questionProfileSubTypes: QuestionProfileSubTypeViewModel[] = [];
  ngOnInit(): void {
    if (this._question.questionType === QuestionType.RichText) {
      this.displayAskQuestionField = false;
      this.displayQuestionIsSensitive = false;
    }
    this.questionService.getQuestionProfileSubTypes().subscribe((res) => {
      if (res) {
        this.questionProfileSubTypes = res;
      }
    })
  }

  getDefaultQuestion(): QuestionViewModel {
    let questionViewModel = new QuestionViewModel();
    //Set default values
    questionViewModel.askPeriodMode = AskQuestionPeriodMode.always;
    questionViewModel.askMode = AskMode.AnyTime;
    questionViewModel.defaultMode = QuestionDefaultMode.Disabled;
    return questionViewModel;
  }

  addNewQuestion() {

    this.selectChangedParameterObj();
    this.formIsvalid();
    if (!this._formIsvalid) {
      return;
    }

    this.inProgress = true;
    this._question.questionParameters = JSON.stringify(this._question.questionParametersAsString);
    if (this.useRichTextTitle == false)
      this._question.questionRichText = '';

    if (this._question.questionType != QuestionType.File || this.isGenericDocumentType) {
      this._question.documentTypeId = null;
      this._question.documentTypeIdList = [];
    } else {
      this._question.documentTypeIdList = this.selectedDocumentTypeIdList;
    }

    if (this._question.questionType == this.questionType.MultiSelect ||
      this._question.questionType == this.questionType.SingleSelect) {
      let trimmedItems = [...this._question.questionParametersAsString['itemList']];
      trimmedItems.forEach(item => {
        item.title = item.title.trim();
        item.value = item.value.trim();
        delete item._clientRandomId;
      });
      this._question.questionParametersAsString['itemList'] = trimmedItems;
    }


    if (!this._question.id) {
      (this.withResult ? this.questionService.addWithResultNewQuestion(this._question) : this.questionService.addNewQuestion(this._question))
        .pipe(finalize(() => { this.inProgress = false; }))
        .subscribe(_ => {
          this.result.emit({ isFinished: true, isSucceeded: true, result: _ });
        }, _ => { this.errorMessage = 'We could not save the question.'; });
    }
    else if (this._question.id > 0) {
      this.questionService.updateQuestion(this._question)
        .pipe(finalize(() => { this.inProgress = false; }))
        .subscribe(_ => {
          this.result.emit({ isFinished: true, isSucceeded: true });
        }, _ => { this.errorMessage = 'We could not update the question.'; });
    }
  }
  cancel() {
    this.result.emit({ isFinished: true, isSucceeded: false });
  }


  clearError() {
    this.errorMessage = '';
  }

  oldQuestionType: QuestionType;
  questionTypeChanged(selectedValue) {
    this.displayAskQuestionField = true;
    this.displayQuestionIsSensitive = true;
    if (parseInt(selectedValue) === this.questionType.RichText) {
      this.displayAskQuestionField = false;
      this.displayQuestionIsSensitive = false;
    }
    if (this._question.questionType === QuestionType.MultiSelect || this._question.questionType === QuestionType.SingleSelect) {
      if (this.oldQuestionType === QuestionType.MultiSelect) {
        this._questionSingle.itemList = this._questionMulti.itemList;
      } else if (this.oldQuestionType === QuestionType.SingleSelect) {
        this._questionMulti.itemList = this._questionSingle.itemList;
      }
    }
    this.oldQuestionType = selectedValue;
    this._documentTypeModeStateIsValid = parseInt(selectedValue) !== this.questionType.File;
  }

  selectChangedParameterObj() {
    switch (this._question.questionType) {
      case this.questionType.MultiSelect: {
        this._question.questionParametersAsString = this._questionMulti;
        break;
      }
      case this.questionType.SingleSelect: {
        this._question.questionParametersAsString = this._questionSingle;
        break;
      }
      case this.questionType.Text: {
        this._question.questionParametersAsString = this._questionText;
        break;
      }
      case this.questionType.Boolean: {
        this._question.questionParametersAsString = this._questionBoolean;
        break;
      }
      case this.questionType.File: {
        this._question.questionParametersAsString = this._questionFile;
        break;
      }
      case this.questionType.RichText: {
        this._question.questionParametersAsString = this._questionRichText;
        break;
      }
      case this.questionType.Date: {
        this._question.questionParametersAsString = this._questionDate;
        break;
      }
      case this.questionType.SignaturePad: {
        this._question.questionParametersAsString = this._questionSignaturePad;
        break;
      }
      case this.questionType.AssetField: {
        this._question.questionParametersAsString = this._questionAssetField;
        break;
      }
      case this.questionType.ProfileInfo: {
        this._question.questionParametersAsString = this._questionProfileInfo;
        break;
      }
      default: {
        break;
      }
    }
  }

  onSelectedDocumentTypes(documents: UserDocumentType[]) {
    this.selectedDocumentTypeIdList = documents?.filter((item) => item.id)?.map((item) => item.id);
    this._documentTypeModeStateIsValid = this.selectedDocumentTypeIdList?.length > 0;
  }

  onDocumentTypeModeChange(isGenericDocumentType: boolean) {
    this.isGenericDocumentType = isGenericDocumentType;
    this._documentTypeModeStateIsValid = this.isGenericDocumentType;
    // this._documentTypeModeStateIsValid = (!isGenericDocumentType && this.onSelectedDocumentTypes?.length > 0) || isGenericDocumentType;
  }

  _datesAreValid = true;
  onDateChange(data: QuestionDateResultViewModel) {
    let limitDateMaxValue = data?.params?.limitForMaximumValue?.compareValue;
    let limitDateMinValue = data?.params?.limitForMinimumValue?.compareValue;
    if (data?.hasMinLimit && data?.params?.limitForMinimumValue?.valueMode == DateQuestionLimitValueMode.Absolute &&
      (!limitDateMinValue || limitDateMinValue == 'Invalid Date')) {
      this._datesAreValid = false;
      return;
    }
    if (data?.hasMaxLimit && data?.params?.limitForMaximumValue?.valueMode == DateQuestionLimitValueMode.Absolute &&
      (!limitDateMaxValue || limitDateMaxValue == 'Invalid Date')) {
      this._datesAreValid = false;
      return;
    }
    if (
      data?.params?.limitForMinimumValue?.valueMode == DateQuestionLimitValueMode.Absolute &&
      data?.params?.limitForMaximumValue?.valueMode == DateQuestionLimitValueMode.Absolute &&
      data?.hasMaxLimit &&
      data?.hasMinLimit &&
      limitDateMaxValue && limitDateMinValue) {
      this._datesAreValid = new Date(Date.parse(limitDateMaxValue)) >= new Date(Date.parse(limitDateMinValue));
      return;
    }
    this._datesAreValid = true;
  }

}
