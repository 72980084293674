import { MaxFileSize } from './../../../enums/constants';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { finalize } from 'rxjs/operators';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { dateFormat } from 'src/app/helpers/date-time-format';
import { getFileExtenstion, getFilename, toBase64 } from 'src/app/helpers/general-functions';
import { uuid4 } from 'src/app/helpers/uuid';
import { SiteSupplierDocumentTypeFileModel, SiteSupplierDocumentTypeVersionModel, SiteSupplierDocumentTypeWithCurrentVersionViewModel } from 'src/app/models/supplier-document/site-supplier-document-type-models';
import { VariableDefinitionMode, VariableDefinitionType, VariableValueDefinition } from 'src/app/models/workflow/variable-difinition';
import { WorkflowVariableValue } from 'src/app/models/workflow/workflow-variable-value';
import { DatetimePipe } from 'src/app/pipes/datetime.pipe';
import { ModalService } from 'src/app/services/modal.service';
import { SiteSupplierService } from 'src/app/services/site-supplier.service';

@Component({
  selector: 'obc-site-supplier-document-multiple-uploader',
  templateUrl: './site-supplier-document-multiple-uploader.component.html',
  styleUrls: ['./site-supplier-document-multiple-uploader.component.scss']
})
export class SiteSupplierDocumentMultipleUploaderComponent implements OnInit {
  currentDate: Date = new Date();
  fiveYearsLaterDate: Date = (new Date()).addYears(5);
  inProgress = false;
  DateTimePickerMode = DatetimePickerMode;

  model: SiteSupplierDocumentTypeUploadFormModel[] = [];

  @Output() hide = new EventEmitter()
  @Output() updateFileDone = new EventEmitter();

  @Input() variableList: VariableValueDefinition[];
  @Input() workflowTitle: string = null;
  @Input() documentKindId: number;
  @Input() siteSupplierId: number = null;
  @Input() siteSupplierHashlink: string = null;
  @Input() siteSupplierDocumentTypes: SiteSupplierDocumentTypeWithCurrentVersionViewModel[] = [];
  @Input() inputFiles: NgxFileDropEntry[] = null;

  VariableDefinitionType = VariableDefinitionType;

  constructor(
    private siteSupplierService: SiteSupplierService,
    private datetimePipe: DatetimePipe,
    private modalService: ModalService) { }

  ngOnInit() {
    this.initUploadModelArray();
    this.siteSupplierDocumentTypes = this.siteSupplierDocumentTypes.filter(x => x.documentKindId == this.documentKindId);

    if (this.inputFiles) {
      this.dropped(this.inputFiles);
    }
  }

  private initUploadModelArray() {
    this.model = [];
  }

  isCheckAnyItem(checkItemCount: number) {
    return this.model.filter(item => item.formGroup.controls['checked'].value == true).length >= checkItemCount;
  }

  addNewUploadModel(file: UploadFileInfo = null, title: string = null, versionDate: Date = null) {
    var newModel = {
      checked: false,
      formGroup: new FormGroup({
        checked: new FormControl(false),
        documentTypeMode: new FormControl(0),
        versionDate: new FormControl(versionDate),
        siteSupplierDocumentTypeTitle: new FormControl(title ?? (file ? getFilename(file.filename) : null), [Validators.required]),
        siteSupplierDocumentTypeId: new FormControl(null, []),
      }),
      variableFormGroups: new FormGroup({}),
      selectedFiles: file ? [file] : []
    } as SiteSupplierDocumentTypeUploadFormModel;
    this.variableList.forEach(v => newModel.variableFormGroups.addControl(v.key, new FormControl(v.value, v.mode == VariableDefinitionMode.Mandatory ? [Validators.required] : [])));
    this.model.push(newModel);
  }

  onRemoveFile(record: SiteSupplierDocumentTypeUploadFormModel, sortedFile: any) {
    var file = record.selectedFiles.find(x => {
      return sortedFile.guid == x.guid;
    });
    if (file == null) return;
    var index = record.selectedFiles.indexOf(file);
    if (index >= 0)
      record.selectedFiles.splice(index, 1);
    if (record.selectedFiles.length == 0)
      this.model.splice(this.model.indexOf(record), 1);
  }

  updateDocumentTypeMode(record: SiteSupplierDocumentTypeUploadFormModel, isNewDocument: boolean) {
    if (isNewDocument) {
      record.formGroup.controls.siteSupplierDocumentTypeId.setValue(null);
      record.formGroup.controls.siteSupplierDocumentTypeId.clearValidators();

      record.formGroup.controls.siteSupplierDocumentTypeTitle.setValue('');
      record.formGroup.controls.siteSupplierDocumentTypeTitle.setValidators([Validators.required]);
    }
    else {
      record.formGroup.controls.siteSupplierDocumentTypeTitle.setValue(null);
      record.formGroup.controls.siteSupplierDocumentTypeTitle.clearValidators();
      if (this.siteSupplierDocumentTypes.length > 0)
        record.formGroup.controls.siteSupplierDocumentTypeId.setValue(this.siteSupplierDocumentTypes[0].siteSupplierDocumentTypeId);
      else
        record.formGroup.controls.siteSupplierDocumentTypeId.setValue(null);
      record.formGroup.controls.siteSupplierDocumentTypeId.setValidators([Validators.required]);
    }
    record.formGroup.controls.siteSupplierDocumentTypeId.updateValueAndValidity();
    record.formGroup.controls.siteSupplierDocumentTypeTitle.updateValueAndValidity();
    record.formGroup.updateValueAndValidity();
  }

  reset() {
    this.initUploadModelArray();
  }

  formIsValid() {
    return this.model &&
      this.model.length &&
      this.model.every(item => item.selectedFiles?.length > 0 && item.formGroup.valid && item.variableFormGroups.valid);
  }

  groupCheckedItems() {
    let checkedItems = this.model.filter(d => d.formGroup.controls['checked'].value);
    if (checkedItems.length < 2)
      return;
    var mergedToItem = checkedItems[0];

    let files = [];
    for (let index = 1; index < checkedItems.length; index++) {
      if (checkedItems[index].selectedFiles)
        files.push(...checkedItems[index].selectedFiles);
    }

    if (!mergedToItem.selectedFiles)
      mergedToItem.selectedFiles = files;
    else
      mergedToItem.selectedFiles.push(...files);
    mergedToItem.formGroup.controls['checked'].setValue(false);

    for (let index = 1; index < checkedItems.length; index++) {
      this.model.splice(this.model.indexOf(checkedItems[index]), 1);
    }
  }

  unGroupCheckedItems() {
    let checkedItems = this.model.filter(d => d.formGroup.controls['checked'].value);
    if (checkedItems.length == 0)
      return;

    for (let item of checkedItems) {
      item.formGroup.controls['checked'].setValue(false);
      while (item.selectedFiles.length > 1) {
        var file = item.selectedFiles.splice(item.selectedFiles.length - 1, 1)[0];
        this.addNewUploadModel(
          file,
          item.formGroup.controls['siteSupplierDocumentTypeTitle'].value,
          item.formGroup.controls['versionDate'].value);
      }
    }
  }
  removeCheckedItems() {
    this.model = this.model.filter(d => !d.formGroup.controls['checked'].value);
  }

  lovalVariables?: WorkflowVariableValue[];
  upload() {
    var data = this.model.map(record => {

      let workflowVariableValues = [];
      this.variableList.forEach(variable => {
        workflowVariableValues.push(<WorkflowVariableValue>{
          key: variable.key,
          value: record.variableFormGroups.value[variable.key]
        });
      });

      return {
        documentKindId: this.documentKindId,
        variables: workflowVariableValues,
        versionDate: record.formGroup.controls.versionDate.value ? this.datetimePipe.transform(record.formGroup.controls.versionDate.value, dateFormat) : null,
        title: record.formGroup.controls.siteSupplierDocumentTypeTitle.value,
        siteSupplierDocumentTypeId: record.formGroup.controls.siteSupplierDocumentTypeId.value,
        files: record.selectedFiles.map(x => {
          return {
            base64: x.base64.split(';base64,')[1],
            filename: x.filename,
            mimeType: x.mimeType,
            extension: x.extension,

          } as SiteSupplierDocumentTypeFileModel
        }),
      } as SiteSupplierDocumentTypeVersionModel;
    });
    this.inProgress = true;
    this.siteSupplierService.addSiteSupplierDocumentTypeVersionList(data, this.siteSupplierHashlink, this.siteSupplierId)
      .pipe(finalize(() => { this.inProgress = false }))
      .subscribe(res => {
        this.reset();
        this.updateFileDone.emit();
      }, err => {
        this.modalService.error(err);
      })
  }

  public files: NgxFileDropEntry[] = [];

  public dropped(files: NgxFileDropEntry[]) {

    this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file(async (file: File) => {
          if (file.size > MaxFileSize * 1024000) {
            this.modalService.error(`maximum file size is ${MaxFileSize}mb`);
            return;
          }

          var fileModel = {
            base64: (await toBase64(file)).toString(),
            extension: (getFileExtenstion(file.name)),
            filename: file.name,
            length: file.size,
            mimeType: file.type,
            guid: uuid4(),
          };
          this.addNewUploadModel(fileModel, null, this.currentDate);
        });
      }
    }
  }

}


interface SiteSupplierDocumentTypeUploadFormModel {
  formGroup: FormGroup;
  selectedFiles: UploadFileInfo[];
  variableFormGroups: FormGroup;
}

interface UploadFileInfo {
  base64: string;
  extension: string;
  filename: string;
  length: number;
  mimeType: string;
  guid: string;
}
