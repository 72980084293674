import { Pipe, PipeTransform } from '@angular/core';
import { AssetCategoryType } from '../enums/asset-category-type-enum';

@Pipe({
  name: 'assetCategoryType'
})
export class AssetCategoryTypePipe implements PipeTransform {

  transform(value: unknown): string {

    if (value === AssetCategoryType.Inveventory || value === 0 || value === "Inveventory") return 'Inventory';
    if (value === AssetCategoryType.ShortTerm || value === 1 || value === "ShortTerm") return 'Short Term';
    return value?.toString();
  }
}

