import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { finalize, switchMap, map, tap } from 'rxjs/operators';
import { AsyncResult } from '../../../models/helper-models';
import { SiteViewModel } from '../../../models/site-viewmodel';
import { SiteService } from '../../../services/site.service';
import { AddressLookupSuggestion } from '../../../models/gmap/address-lookup-suggestion';
import { Observable, Observer, noop, of } from 'rxjs';
import { AddressLookupService } from '../../../services/address-lookup.service';
import { AddressLookupGetSuggestionModel } from '../../../models/gmap/address-lookup-get-suggestion-model';
import { uuid4 } from '../../../helpers/uuid';
import { TypeaheadMatch, TypeaheadOrder } from 'ngx-bootstrap/typeahead/public_api';
import { SiteUpdateLocationModel } from '../../../models/site-update-location-model';
@Component({
  selector: 'obc-site-location-verify',
  templateUrl: './site-location-verify.component.html',
  styleUrls: ['./site-location-verify.component.scss'],
})
export class SiteLocationVerifyComponent implements OnInit {
  @Output() public result = new EventEmitter<AsyncResult>();
  site: SiteViewModel;
  @Input() set locationVerifyInput(_site: SiteViewModel) {
    if (!_site) return;
    this.site = _site;
  }

  suggestions$: Observable<AddressLookupSuggestion[]>;
  loader: boolean = false;
  uuid: string;
  errorMessage: string = '';
  selectedAddress: AddressLookupSuggestion;
  inProgress: boolean = false;
  sortConfig: TypeaheadOrder = {
    direction: 'asc',
    field: 'primaryTitle',
  };

  get latitude(): number {
    return isNaN(+this.site.latitude) ? null : +this.site.latitude;
  }

  set latitude(lat: number) {
    if (lat && !isNaN(lat)) this.site.latitude = lat.toString();
  }

  get longitude(): number {
    return isNaN(+this.site.longitude) ? null : +this.site.longitude;
  }

  set longitude(lng: number) {
    if (lng && !isNaN(lng)) this.site.longitude = lng.toString();
  }
  constructor(private siteService: SiteService, private addressLookupService: AddressLookupService) { }

  ngOnInit(): void {
    this.uuid = uuid4();
    this.getAddressList();
  }
  getAddressList() {
    this.suggestions$ = new Observable((observer: Observer<string>) => {
      observer.next(this.site?.address);
    }).pipe(
      switchMap((query: string) => {
        if (query) {
          this.loader = true;
          let model: AddressLookupGetSuggestionModel = {
            address: query,
            sessionToken: this.uuid,
          };
          return this.addressLookupService.getAddressSuggestionList(model).pipe(
            map((data: AddressLookupSuggestion[]) => (data && data) || []),
            tap(
              () => noop,
              (err) => {
                var rrr = (err && err.message) || 'Something goes wrong';
              }
            ),
            finalize(() => {
              this.loader = false;
            })
          );
        }

        return of([]);
      })
    );
  }
  get isValid(): boolean {
    if (!this.site.latitude || isNaN(parseFloat(this.site.latitude))) {
      this.errorMessage =
        'Please select a valid address from list or pick a place on map';
      return false;
    }

    return true;
  }
  cancel() {
    this.result.emit({ isFinished: true, isSucceeded: false });
    this.errorMessage = '';
  }
  onSelectAddress(event: TypeaheadMatch): void {
    this.selectedAddress = event.item;
    this.site.address = this.site.address =
      this.selectedAddress.primaryTitle +
      ' ' +
      this.selectedAddress.secondaryTitle;
    this.inProgress = true;
    this.addressLookupService
      .getDetailsOfSelectedPlace(this.selectedAddress.id, this.uuid)
      .pipe(
        finalize(() => {
          this.inProgress = false;
        })
      )
      .subscribe(
        (result) => {
          this.latitude = +result.latitude;
          this.longitude = +result.longitude;
        },
        (error) => { }
      );
  }

  setSiteLocationByChangeMarker(cordinate) {
    this.site.latitude = cordinate.lat.toString();
    this.site.longitude = cordinate.lng.toString();
  }

  addressNotFound: any;
  typeaheadNoResults(event) {
    this.addressNotFound = event;
  }

  updateLocation() {
    this.errorMessage = '';
    if (this.isValid === false) return;

    var inEdit = this.site.id && this.site.id > 0;

    if (inEdit) {
      var siteLocation: SiteUpdateLocationModel = {
        id: this.site.id,
        address: this.site.address,
        latitude: this.site.latitude,
        longitude: this.site.longitude,
      }
      this.siteService.updateLocation(siteLocation).subscribe(

        (success) => {
          this.result.emit({ isFinished: true, isSucceeded: true });
        },
        (error) => {
          this.errorMessage = 'A problem detected on updating site';
        }, () => {
          this.inProgress = false;
        }
      );
    }
  }
}
