<obc-loader [show]="inProgress"></obc-loader>
<div class="card">
  <div class="card-header">
    Evacuation
    <button type="button" class="btn btn-close pull-right" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalService.hide()">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="card-body" *ngIf="evacuation">
    <div class="row">
      <label class="col-md-2">Mode</label>
      <span
        class="col-md-4 {{evacuation.evacuationViewModel.mode == EvacuationMode.Drill ? 'text-warning':'text-danger'}}">{{evacuation.evacuationViewModel.mode
        |
        evacuationMode}}</span>
      <label class="col-md-2">Status</label>
      <span
        class="col-md-4 {{evacuation.evacuationViewModel.status == EvacuationStatus.Draft ? 'text-warning':'text-success'}}">{{evacuation.evacuationViewModel.status
        | evacuationStatus}}</span>
      <label class="col-md-2">Created By</label>
      <span class="col-md-4">{{evacuation.evacuationViewModel.createByUserFullName }}</span>
      <label class="col-md-2">Created At</label>
      <span class="col-md-4">{{evacuation.evacuationViewModel.createDateTime | datetime}}</span>
      <label class="col-md-2">Total People</label>
      <span class="col-md-4">{{evacuation.evacuationViewModel.totalPeople}}</span>
      <label class="col-md-2">Confirmed People</label>
      <span class="col-md-4">{{evacuation.evacuationViewModel.totalPresentPeople}}</span>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <button type="button" class="btn btn-warning" (click)="openLogModal(logTemplate)">View
          Logs</button>&nbsp;
        <button type="button" class="btn btn-success" (click)="exportCsv()" >
          <i class="fas fa-file-excel-o"></i>
          Export As CSV
        </button>

      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive" *ngIf="evacuation?.evacuationPersonViewModels?.length>0">
          <table class="table table-bordered fw">
            <thead>
              <tr>
                <th colspan="2">Evacuation People</th>
              </tr>
            </thead>
            <tbody>
              <ng-container>
                <tr>
                  <td>
                    <input type="text" class="form-control" [formControl]="filterFormcontrol"
                      placeholder="Search by supplier name, name and mobile" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <accordion *ngFor="let item of searchEvacuationPerson | groupBy:'supplierName' | orderBy:'key'">
                      <accordion-group class="mt-2" (click)="setAdvancedIsOpen($event,item.key)" [isOpen]="advancedIsOpen[item.key]">
                        <div accordion-heading>
                          <div class="d-flex flex-row justify-content-between">
                            <div class="pull-left float-left">{{item.key== "" ? "No Supplier": item.key}} </div>
                            <div>{{getPresentCount(item.value)}}/{{item.value?.length}}
                            </div>
                          </div>
                        </div>
                        <table class="table table-bordered table-striped mt-1">
                          <thead>
                            <tr>
                              <td>Name</td>
                              <td>Mobile</td>
                              <td>Present</td>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let person of item.value">
                              <td>{{person.firstName}} {{person.lastName}}</td>
                              <td>{{person.mobile | mobile}}</td>
                              <td><i class="fas fa-check-circle {{person.isPresent ? 'text-success':'light-grey'}}"></i>
                              </td>
                              <td>
                                <button id="btnViewCheckin" *ngIf="person.siteAttendanceId" type="button"
                                  class="btn btn-primary btn-sm"
                                  (click)="openPersonModal(person, personDetailsTemplate)">View
                                  Check In</button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </accordion-group>
                    </accordion>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #personDetailsTemplate>
  <obc-question-answer [questionAnswerInput]="siteAttendanceToShowCheckIn">
  </obc-question-answer>
</ng-template>

<ng-template #logTemplate>
  <div class="card">
    <div class="card-header">Evacuation Logs
      <button type="button" class="btn btn-close pull-right" data-bs-dismiss="modal" aria-label="Close"
        (click)="modalService.hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="card-body">

      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Date</th>
            <th>By</th>
            <th>Operations</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let log of evacuationLogs">
            <td>{{log.dateTime | datetime}}</td>
            <td>{{log.userFullName}} <span *ngIf="log.userMobileNumber">({{log.userMobileNumber |
                mobile}})</span>
            </td>
            <td>
              <ul>
                <li *ngFor="let l of log.logItemDescriptions">
                  {{l.key}}<ng-container *ngIf="l.value">: {{l.value}}</ng-container>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
