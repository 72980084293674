<input
  *ngIf="children.length > 0"
  type="checkbox"
  [attr.checked]="checked ? 'checked' : null"
  (change)="saveDocumentCheckedStatusOnCache($event)"><span class="tree-control"></span>
<div class="label" style="opacity: {{ opacity }}">
  <div class="document-container {{ draggable ? 'canDrag' : ''}} ml-1">
    <div class="move-handler">
      <i class="fa fa-arrows-alt"></i>
    </div>
    <div class="d-flex w-100 align-items-start">
      <div class="d-flex flex-column w-100">
        <div class="flex-fill d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center justify-content-between">
            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
              <path
                d="M7 15h6v-1.5H7Zm0-3h6v-1.5H7Zm-1.5 6q-.625 0-1.062-.438Q4 17.125 4 16.5v-13q0-.625.438-1.062Q4.875 2 5.5 2H12l4 4v10.5q0 .625-.438 1.062Q15.125 18 14.5 18ZM11 7V3.5H5.5v13h9V7ZM5.5 3.5v3.938V3.5v13-13Z"/>
            </svg>
            <span class="ml-1">{{ document.title }}</span>
          </div>
          <div class="d-flex align-items-center">
            <button type="button"
                    *ngIf="canAdd"
                    style="width: 26px;height: 26px;"
                    class="p-0 btn btn-success ml-3 d-flex justify-content-between align-items-center"
                    (click)="emitAddNew(document)">
              <i class="fa fa-plus"></i>
            </button>
            <button type="button"
                    *ngIf="canUpdate"
                    style="width: 26px;height: 26px;"
                    class="p-0 btn btn-info ml-1 d-flex justify-content-between align-items-center"
                    (click)="emitUpdate(document)">
              <i class="fa fa-pen"></i>
            </button>
            <button type="button"
                    *ngIf="canDelete"
                    style="width: 26px;height: 26px;"
                    class="p-0 btn btn-danger ml-1 d-flex justify-content-between align-items-center"
                    (click)="emitDelete(document)">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
        <ng-container *ngIf="document.isSelectable">
          <div class="d-flex mt-1 align-items-center justify-content-between">
            <div class="document-type-badge document-image-mode-badge mr-3">
              {{ document?.multiImageMode | multiImageModePipe }}
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <div *ngIf="document.enableTitle" class="document-type-badge document-title-badge">Title</div>
              <div *ngIf="document.enableExpireDate" class="document-type-badge document-expiry-date-badge">Expiry Date</div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>



  </div>
</div>
<ng-container *ngIf="children.length > 0">
  <ul obc-document-type-viewer-ul
      [canAdd]="canAdd"
      [canDelete]="canDelete"
      [canUpdate]="canUpdate"
      [draggable]="draggable"
      [children]="children"
      [allDocuments]="documents"
      [cacheUniqueId]="cacheUniqueId"
      (orderedList)="onOrderedList($event)"
  ></ul>
</ng-container>

