<div class="card" style="{{ !isTemplateCompany ? 'border:none' : '' }}">
  <obc-loader [show]="inProgress"></obc-loader>
  <div class="card-body" style="{{ !isTemplateCompany ? 'padding: 0;' : '' }}">
    <div>
      <tabset *ngIf="!isTemplateCompany">
        <tab heading="Permission Mode">
          <form [formGroup]="form" (ngSubmit)="submitPermissionMode()" class="clearfix">
            <div class="row justify-content-center">
              <div class="col-12 col-lg-8">
                <div class="form-group ">
                  <div class="d-flex mx-auto align-items-center">
                    <label class="mb-0 mr-2">Mode</label>
                    <select formControlName="permissionMode" class="form-control">
                      <option *ngFor="let perm of PermissionModeEnum | enumToArray"
                        [ngValue]="PermissionModeEnum[perm]">
                        {{PermissionModeEnum[perm] | permissionMode}}</option>
                    </select>
                    <button type="submit" class="ml-2 btn btn-primary" [disabled]="!form.valid">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="row justify-content-center"
              [hidden]="form.controls.permissionMode.value == PermissionModeEnum.Mixed">
              <div class="col-12 col-lg-8">
                <div class="alert alert-danger">
                  <div class="d-flex mx-auto align-items-center">
                    <i class="fas  fa-exclamation-triangle mr-1"></i>
                    {{form.controls.permissionMode.value == PermissionModeEnum.Permission ? "Roles will be disabled" :
                    (form.controls.permissionMode.value == PermissionModeEnum.Role?"Permissions assigned directly to
                    users will be revoked.":"")}}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </tab>
        <tab heading="Role Definition">
          <ng-container [ngTemplateOutlet]="roleDefinition"></ng-container>
        </tab>
      </tabset>
      <ng-container *ngIf="isTemplateCompany" [ngTemplateOutlet]="roleDefinition"></ng-container>
    </div>
  </div>
</div>

<ng-template #roleDefinition>
  <form [formGroup]="roleDefinitionForm" (ngSubmit)="upsertCompanyRole()" class="clearfix">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Name</label>
          <input class="form-control" formControlName="roleName">
        </div>
        <div class="form-group text-right">
          <button type="submit" class="btn btn-info" [disabled]="!roleDefinitionForm.valid">
            {{ roleDefinitionForm.controls.companyRoleId?.value != null ? 'Update Role' : 'Add New Role' }}
          </button>
          <button type="reset" class="btn btn-primary ml-2" (click)="reset()">Cancel</button>
        </div>
      </div>
    </div>
  </form>
  <table class="table table-bordered table-striped mt-3 mb-0 dataTable no-footer">
    <thead>
      <tr role="row">
        <th>Name</th>
        <th>Permissions</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let role of companyRoles">
        <tr>
          <td>
            <div class="d-flex align-items-center"
              *ngIf="selectedRoleToUpdateName?.companyRoleId == role.companyRoleId">
              <input class="form-control" type="text"
                (keydown.enter)="selectedCompanyRoleName != '' ? upsertCompanyRole(true) : null"
                [(ngModel)]="selectedCompanyRoleName">
              <button type="button" class="btn btn-success ml-1" (click)="upsertCompanyRole(true)"
                [disabled]="selectedCompanyRoleName == '' || selectedCompanyRoleName == role.name">
                <span class="fa fa-check text-white"></span>
              </button>
              <button type="button" class="btn btn-info ml-1" (click)="reset()">
                <span class="fa fa-times text-white"></span>
              </button>
            </div>
            <div class="d-flex align-items-center"
                 *ngIf="selectedRoleToUpdateName?.companyRoleId != role.companyRoleId">
              <span>{{role.name}}</span>
              <span *ngIf="role.importSource === null" class="fa fa-edit ml-2 p-2 cursor-pointer " (click)="displayUpdateRoleNameInput(role)"></span>
              <span *ngIf="role.importSource !== null" class="badge badge-info ml-2" tooltip="Ref Code: {{role.refCode}}">{{ role.importSource | importSource }} Imported</span>
            </div>
          </td>
          <td>
            <ng-template #tooltipHtml>
              <div *ngFor="let label of getPermissionLabels(role)">
                <div>{{label}}</div>
              </div>
            </ng-template>
            <span *ngIf="role.isFullAccess" class="font-weight-semibold">Full Access</span>
            <span *ngIf="!role.isFullAccess && role.permissions != UserPermissions.None"
              style="border-bottom: dashed 1px;" [tooltip]="tooltipHtml">{{getPermissionCount(role)}}
            </span>
            <span *ngIf="role.permissions == UserPermissions.None">No
              Permission</span>
          </td>
          <td class="actions d-flex justify-content-end">
            <a class="btn btn-danger mr-1 remove-row" (click)="deleteRole(role)"><i
                class="text-white far fa-trash-alt"></i></a>
            <a class="btn btn-success" (click)="displayUpdateRolePermissionsModal(updateRolePermissionsTemplate, role)">
              <i class="fa fa-list-ul text-white"></i>
            </a>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="!companyRoles || companyRoles?.length == 0">
        <tr>
          <td colspan="20">
            <div class="d-flex justify-content-center align-items-center">
              There are no roles defined for this company
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-template>

<ng-template #updateRolePermissionsTemplate>
  <form [formGroup]="rolePermissionsForm" (ngSubmit)="updateRolePermissions()" class="clearfix">
    <div class="card">
      <div class="card-header">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            Role Permissions
          </div>
          <button type="button" (click)="close()" class="close float-right">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-end">
              <label class="m-0 pr-2">Full Access</label>
              <obc-switch formControlName="fullAccess"
                (valueChanged)="rolePermissionsForm.controls['fullAccess'].setValue($event)">
              </obc-switch>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center mb-3">
                <h6 class="mr-2 mb-0">Company Permissions</h6>
                <span style="opacity: {{hasAnyCompanyPermissionSelected ? 1 : 0}}"
                  class="text-danger text-small cursor-pointer" (click)="resetCompanyPermissions()">(Reset)</span>
              </div>
            </div>
            <obc-check-list-form-control formControlName="companyPermissions"
              *ngIf="rolePermissionsForm.controls['fullAccess'].value == false" style="font-size: smaller;"
              [values]="companyUserPermissions">
            </obc-check-list-form-control>
            <ng-container *ngIf="rolePermissionsForm.controls['fullAccess'].value == true"
              [ngTemplateOutlet]="fullAccessMessage"></ng-container>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center mb-3">
                <h6 class="mr-2 mb-0">Site Permissions</h6>
                <span style="opacity: {{ hasAnySitePermissionSelected ? 1 : 0}}"
                  class="text-danger text-small cursor-pointer" (click)="resetSitePermissions()">(Reset)</span>
              </div>
            </div>
            <obc-check-list-form-control *ngIf="rolePermissionsForm.controls['fullAccess'].value == false"
              style="font-size: smaller;" formControlName="sitePermissions" [values]="siteUserPermissions">
            </obc-check-list-form-control>
            <ng-container *ngIf="rolePermissionsForm.controls['fullAccess'].value == true"
              [ngTemplateOutlet]="fullAccessMessage"></ng-container>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-end mt-4">
              <button type="button" class="btn btn-success ml-1" (click)="updateRolePermissions()" [disabled]="false">
                Update Permissions
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #fullAccessMessage>
  <div class="fa-message text-info">
    <span class="fa-badge">Full Access</span> is enabled and all permissions are assigned
  </div>
</ng-template>
