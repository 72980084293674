import { Pipe, PipeTransform } from '@angular/core';
import { HandsHQSupplierDocumentApprovalMode } from '../enums/handshq-supplier-document-approval-mode';

@Pipe({
  name: 'handsHQSupplierDocumentApprovalMode'
})
export class HandsHQSupplierDocumentApprovalModePipe implements PipeTransform {

  transform(value: any): string {
    if (value == HandsHQSupplierDocumentApprovalMode.BasedOnCompanySetting) return 'Based on the settings of the company';
    if (value == HandsHQSupplierDocumentApprovalMode.AutoApproveForAll) return 'Auto Approve For All';
    if (value == HandsHQSupplierDocumentApprovalMode.ManualApprove) return 'Manual';
    return value?.toString();
  }

}
