import { EmergencyContactViewModel } from "../emergency-contact-view-model";

export class WorkerProfileViewModel {
  userId: number;
  companyId: number;
  visitorTypeId: number;
  companySupplierId: number;
  visitorTypeTitle: string;
  companySupplierTitle: string;
  lastName: string;
  firstName: string;
  email: string;
  profileImageUrl: string;
  dateOfBirth: Date;
  emergencyContact: string;
  gender: number;
  mobile: string;
}
export class UserProfileCardViewModel {
  cardId: number;
  userDocumentId: number;
  documentTypeId: number;
  documentTypeTitle: string;
  documentTitle: string;
  saveDateTime: Date;
  expireDate: Date;
  fileUrls: string[];
}
export class ObcEventLogViewModel {
  dateTime: Date;
  dataAsJson: string;
  eventTypeString: string;
  message: string;
}

export type UserInformationField = {
  title: string;
  key: string;
  icon: string;
  type?: undefined | string;
 
}

export type UserInformation = UserInformationField & {
  isEmailVerified?: undefined | boolean;
  isDisputedCheckOutDate?: undefined | boolean
  isDisputedCheckInDate?: undefined | boolean
  checkOutDistanceTitle?: string
  checkInDistanceTitle?: string 
}

export const userInformationFields: UserInformationField[] = [
  { title: 'Site', key: 'siteName', icon: 'map-marker-alt' },
  { title: 'Supplier', key: 'companySupplier', icon: 'industry' },
  { title: 'CheckIn', key: 'checkInDate', icon: 'calendar', type: 'datetime' },
  { title: 'CheckOut', key: 'checkOutDate', icon: 'calendar', type: 'datetime' },
  { title: 'First Name', key: 'firstName', icon: 'user' },
  { title: 'Last Name', key: 'lastName', icon: 'user' },
  { title: 'Mobile', key: 'mobile', icon: 'phone', type: 'mobile' },
  { title: 'Date of Birth',key: 'birthDay', icon: 'calendar', type: 'date'},
  { title: 'Gender', key: 'gender', icon: 'male', type: 'gender' },
  { title: 'Industry Type', key: 'industryTypeText', icon: 'industry' },
  { title: 'Emergency Contact', key: 'emergencyContactAsJson', icon: 'phone-square', type: 'emergencyContact' },
  { title: 'Business Name', key: 'businessName', icon: 'id-card' },
  { title: 'Email', key: 'email', icon: 'envelope', type: 'email' },
  { title: 'Visitor Type', key: 'visitorTypeTitle', icon: 'user-circle' },
  // { title: 'Induction', key: 'induction', type: 'induction', icon: 'id-card' },
];

export const cleanAndGetField = (profile: WorkerProfileViewModel) => {
  return userInformationFields.filter((f) =>
        (profile[f.key] != null &&
          (f.key != 'emergencyContactAsJson' || !EmergencyContactViewModel.isEmpty(JSON.parse(profile[f.key])))));
}
