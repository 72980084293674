import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataRetentionPolicyStatus } from '../enums/data-retention-policy-status.enum';
import { DataRetentionPolicyModel, DataRetentionPolicyViewModel } from '../models/data-retention-policy';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class DataRetentionPolicyService {

  constructor(private http: HttpClient, private errorService: ErrorService) {

  }

  getPolicies() {
    return this.http.get("/api/DataRetentionPolicy/get-all")
      .pipe(catchError(this.errorService.handleError));
  }

  deletePolicy(policyId: number) {
    return this.http.get(`/api/DataRetentionPolicy/remove/${policyId}`)
      .pipe(catchError(this.errorService.handleError));
  }

  policyStatusChange(policyId: number, status: DataRetentionPolicyStatus) {
    return this.http.get(`/api/DataRetentionPolicy/change-status/${policyId}/${status}`)
      .pipe(catchError(this.errorService.handleError));
  }

  updatePolicy(policyId: number, model: DataRetentionPolicyModel) {
    return this.http.post<DataRetentionPolicyViewModel>(`/api/DataRetentionPolicy/update/${policyId}`, model)
      .pipe(catchError(this.errorService.handleError));
  }

  addPolicy(model: DataRetentionPolicyModel): Observable<DataRetentionPolicyViewModel> {
    return this.http.post<DataRetentionPolicyViewModel>(`/api/DataRetentionPolicy/add`, model)
      .pipe(catchError(this.errorService.handleError));
  }

}
