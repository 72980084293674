import { Pipe, PipeTransform } from '@angular/core';
import { AccessControlAutomaticAttendanceMode } from '../enums/access-control-automatic-attendance-mode';

@Pipe({
  name: 'accessControlAutomaticAttendanceMode'
})
export class AccessControlAutomaticAttendanceModePipe implements PipeTransform {

  transform(value: any): string {
    if (value == AccessControlAutomaticAttendanceMode.Disabled || value === false) return 'Disabled';
    if (value == AccessControlAutomaticAttendanceMode.Enabled || value === true) return 'Enabled';
    return value?.toString() ?? '?';
  }

}
