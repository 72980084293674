import { debounceTime, filter } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EvacuationItemViewModel, EvacuationLogViewModel, EvacuationMode, EvacuationPersonViewModel, EvacuationStatus } from 'src/app/models/evacuation/evacuation-viewmodels';
import { QuestionAnswerInputModel } from 'src/app/models/question-answer-inputmodel';
import { EvacuationService } from 'src/app/services/evacuation.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-evacuation-details',
  templateUrl: './evacuation-details.component.html',
  styleUrls: ['./evacuation-details.component.scss']
})
export class EvacuationDetailsComponent implements OnInit {
  EvacuationMode = EvacuationMode;
  EvacuationStatus = EvacuationStatus;

  searchEvacuationPerson: EvacuationPersonViewModel[];
  evacuation: EvacuationItemViewModel;
  evacuationLogs: EvacuationLogViewModel[];
  _evacuationId: number;
  siteAttendanceToShowCheckIn: QuestionAnswerInputModel;
  inProgress: boolean;
  filterFormcontrol = new FormControl();

  get evacuationId(): number {
    return this._evacuationId;
  }

  @Input() set evacuationId(evacId: number) {
    if (this._evacuationId != evacId) {
      this._evacuationId = evacId;
      this.loadEvacuation();
    }
  }

  constructor(private evacuationService: EvacuationService, public modalService: ModalService) { }

  ngOnInit(): void {
    this.loadEvacuation();
    this.filterFormcontrol.valueChanges.pipe(
      debounceTime(500),
    ).subscribe((searchTerm) => {
      if (searchTerm == '')
        this.searchEvacuationPerson = this.evacuation.evacuationPersonViewModels;
      else {
        this.searchEvacuationPerson = this.evacuation.evacuationPersonViewModels.filter(x =>
          x.supplierName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          x.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          x.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          x.mobile.toLowerCase().includes(searchTerm.toLowerCase()));
      }
    });
  }

  private loadEvacuation() {
    if (this.evacuationId) {
      this.inProgress = true;
      this.evacuationService.getEvacuation(this.evacuationId).subscribe(response => {
        this.inProgress = false;
        this.evacuation = response;
        this.searchEvacuationPerson = response.evacuationPersonViewModels;
        this.evacuation.evacuationPersonViewModels.forEach(item => {
          if (item.supplierName == null)
            item.supplierName = "";
        });
      });
    }
  }

  openPersonModal(person: EvacuationPersonViewModel, personDetailsTemplate) {
    this.siteAttendanceToShowCheckIn = { siteAttendanceId: person.siteAttendanceId } as QuestionAnswerInputModel;
    if (this.siteAttendanceToShowCheckIn)
      this.modalService.show(personDetailsTemplate, 'modal-lg');
  }

  openLogModal(logTemplate) {
    this.inProgress = true;
    this.evacuationService.getEvacuationLogs(this.evacuationId).subscribe(response => {
      this.inProgress = false;
      this.evacuationLogs = response;
      this.modalService.show(logTemplate, 'modal-lg');
    });
  }

  getPresentCount(items: EvacuationPersonViewModel[]): number {
    return items?.filter(x => x.isPresent == true).length;
  }

  advancedIsOpen: Record<string, Partial<boolean>> = {};
  setAdvancedIsOpen(e, groupTitle) {
    if (e.target.id != "btnViewCheckin") {

      if (this.advancedIsOpen[groupTitle] == null)
        this.advancedIsOpen[groupTitle] = true;
      else
        this.advancedIsOpen[groupTitle] = !this.advancedIsOpen[groupTitle];
      const keys = (Object.keys(this.advancedIsOpen) as Array<string>);
      keys.forEach(item => {
        if (item != groupTitle) {
          this.advancedIsOpen[item] = false;
        }
      })
    }
  }

  exportCsv() {
    this.evacuationService.exportEvacuationPeople(this.evacuationId);
  }

}
