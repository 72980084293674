import { Component, Input, OnInit } from '@angular/core';
import { CompanyInventoryConfigModel } from 'src/app/models/inventory/company-inventory-config-model';
import { LayoutService } from 'src/app/services/layout.service';
import { ManageInventoryService } from 'src/app/services/manage-inventory.service';
import { ModalService } from 'src/app/services/modal.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'obc-manage-inventory',
  templateUrl: './manage-inventory.component.html',
  styleUrls: ['./manage-inventory.component.scss']
})
export class ManageInventoryComponent implements OnInit {

  companyInventoryConfig: CompanyInventoryConfigModel;
  inProgress: boolean;
  isTemplateCompany: boolean;

  @Input() selectedTabIndex = 1;

  constructor(private layoutService: LayoutService,
    private manageInventoryService: ManageInventoryService,
    private modalService: ModalService,
    private userService: UserService) { }

  ngOnInit() {
    this.inProgress = true;
    this.userService.currentCompany$.subscribe(res => this.isTemplateCompany = res?.isTemplate);
    this.manageInventoryService.getCompanyInventoryConfig().subscribe(
      res => {
        if (res.success) {
          this.companyInventoryConfig = res.data;
        } else {
          this.modalService.error(res.message);
        }
      }, err => {
        this.modalService.error("Request Failed");
        this.inProgress = false;
      }, () => {
        this.inProgress = false;
      }
    )
  }

  changeCompanyEnduserPermissions() {
    this.inProgress = true;

    this.manageInventoryService.saveCompanyInventoryConfig(this.companyInventoryConfig).subscribe(
      res => {
        if (res.success) {
          this.modalService.success("Saved Successfully");
        } else {
          this.modalService.error(res.message);
        }
      }, err => {
        this.modalService.error("Request Failed");
        this.inProgress = false;
      }, () => {
        this.inProgress = false;
      }
    )
  }
}
