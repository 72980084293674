export enum SupplierDocumentEditorType {
  RequestEmailContent = 0,
  FilloutRequestEmailContent = 1,
  FullOnboardingEmailContent = 2,
  OnboardingEmailOffsiteLinkTemplate = 3,
  DocumentVersionCommentEmailContent = 4,
}

export enum EvacuationContentEditorType {
  EvacuationNotificationContent = 5
}