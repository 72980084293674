import { SitesOfAnnouncementViewModel } from './../../../models/announcement-viewmodel';
import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { ClientAnnouncementViewModel, SiteAnnouncementAddModel, SiteAnnouncementUpdateModel, SiteAnnouncementViewModel, SitesOfAnnouncementQueryModel } from 'src/app/models/announcement-viewmodel';
import { FormControl, Validators } from '@angular/forms';
import { EditableItem } from 'src/app/models/editable-item';
import { HttpEventType } from '@angular/common/http';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AnnouncementProcessStatus } from 'src/app/enums/announcement-process-status.enum';
import { ModalService } from '../../../services/modal.service';
import { urlValidator } from '../../../helpers/url-validator';
import { SiteActiveStatus } from '../../../enums/site-active-status.enum';
import { finalize } from 'rxjs/operators';
import { AnnouncementMode } from '../../../enums/announcement-mode.enum';
import { FileType } from 'src/app/enums/file-type.enum';
import { UserService } from 'src/app/services/user.service';
import { CompanyViewModel } from 'src/app/models/company-viewmodel';
import { ToastrService } from 'ngx-toastr';
import { SiteAnnouncementForceViewMode } from 'src/app/enums/site-announcement-force-view-mode';
import { SiteAnnouncementDisplayTime } from 'src/app/enums/site-announcement-display-time.enum';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';

@Component({
  selector: 'obc-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.css']
})
export class AnnouncementComponent implements OnInit {
  datetimePickerMode = DatetimePickerMode;

  inProgress: boolean = false;
  announcementProcessStatuses = AnnouncementProcessStatus;
  announcements: EditableItem<ClientAnnouncementViewModel>[];
  newAnnouncement: EditableItem<ClientAnnouncementViewModel>;
  announcementType = FileType;
  newAnnouncementForAll: EditableItem<SiteAnnouncementUpdateModel>;
  announcementMode = AnnouncementMode;
  isActiveMode: boolean = true;
  currentCompany: CompanyViewModel;
  SiteAnnouncementForceViewMode = SiteAnnouncementForceViewMode;
  SiteAnnouncementDisplayTime = SiteAnnouncementDisplayTime;
  siteId: number = null;
  sitesOfAnnouncement: SitesOfAnnouncementViewModel[];

  constructor(private layoutService: LayoutService,
    private bsModalService: BsModalService,
    private announcementService: AnnouncementService,
    public modalService: ModalService,
    private toastr: ToastrService,
    private userService: UserService
  ) { }

  sortColumn: string;
  desc: boolean = true;
  sortBy(column: string) {
    if (column == this.sortColumn) {
      this.desc = !this.desc;
    } else {
      this.sortColumn = column;
    }
  }

  ngOnInit() {
    this.layoutService.header = "Announcements";
    this.init();
    this.userService.currentCompany$.subscribe(res => this.currentCompany = res);
  }

  get isTemplateCompany(): boolean {
    return this.currentCompany?.isTemplate ?? false;
  }

  get validators() {
    return {
      'title': [Validators.required]
    };
  }

  private init() {
    this.loadAnnouncements();
    this.newAnnouncement = new EditableItem({
      title: null,
      url: null,
      data: null,
      type: this.announcementType.Pdf,
      isActive: true,
      file: null,
      progress: "0",
      groupName: "",
    } as ClientAnnouncementViewModel, this.validators);
    this.newAnnouncement.mode = "view";
    setTimeout(() => {
      this.newAnnouncement.mode = "edit";
    }, 500);
  }

  private loadAnnouncements(onlyActiveItems: boolean = null) {
    onlyActiveItems = onlyActiveItems ?? this.isActiveMode;
    this.inProgress = true;
    var state = onlyActiveItems ? SiteActiveStatus.Active : SiteActiveStatus.InActive;
    this.announcementService.getAll(state).subscribe(res => {
      this.announcements = res.map((i) => {
        return new EditableItem(i as ClientAnnouncementViewModel, this.validators);
      });
    }, err => { this.inProgress = false; }, () => { this.inProgress = false; });
  }


  onActiveChanged(value) {
    this.isActiveMode = value;
    this.loadAnnouncements(value);
  }

  saveChanges(editableModel: EditableItem<ClientAnnouncementViewModel>) {
    this.inProgress = true;
    var model = editableModel.currentModel;
    var uploadEventHandler = (event) => {
      if (this.isFileModel(model.type)) {
        if (event.type === HttpEventType.UploadProgress)
          editableModel.formGroup.get('progress').setValue(`${Math.round(100 * event.loaded / event.total)}%`);
        else if (event.type === HttpEventType.Response) {
          editableModel.formGroup.get('progress').setValue(`100%`);
        }
      }
    };

    var errorHandler = (err) => {
      this.modalService.error(err, "unable to save changes");
      console.log('error : ', err);
      this.inProgress = false;
      this.init();
    }

    if (model.id) // update
      this.announcementService.update(model.id, model).subscribe(
        event => { uploadEventHandler(event); },
        err => { errorHandler(err) }, () => {
          this.inProgress = false;
          this.init();
        });
    else  // add
      this.announcementService.add(model).subscribe(
        event => { uploadEventHandler(event); },
        err => { errorHandler(err) }, () => {
          this.inProgress = false;
          this.init();
        });
  }

  onSwitchMode(editableModel: EditableItem<ClientAnnouncementViewModel>) {
    editableModel.switchMode();
    editableModel.revertToSavedState();
    this.resetTemporyProperties(editableModel);
  }

  isDataModel(type: number) {
    if (type == this.announcementType.Text ||
      type == this.announcementType.ExternalLink ||
      type == this.announcementType.Youtube)
      return true;
    return false;
  }

  isFileModel(type: number) {
    if (type == this.announcementType.Audio ||
      type == this.announcementType.Image ||
      type == this.announcementType.Pdf ||
      type == this.announcementType.Video
    )
      return true;
    return false;
  }

  onFileChange($event: any, editableModel: EditableItem<ClientAnnouncementViewModel>) {
    if (!$event.target.files) return;
    this.resetTemporyProperties(editableModel);

    const formData = new FormData();
    var file = $event.target.files[0];
    formData.append('file', file, file.name);
    editableModel.formGroup.get('file').setValue(formData);
  }

  onSave(editableModel: EditableItem<ClientAnnouncementViewModel>) {
    if (this.validateModel(editableModel))
      this.saveChanges(editableModel);
  }

  onAdd(editableModel: EditableItem<ClientAnnouncementViewModel>) {
    if (this.validateModel(editableModel))
      this.saveChanges(editableModel);
  }

  validateModel(editableModel: EditableItem<ClientAnnouncementViewModel>): boolean {
    var currentModel = editableModel.currentModel;
    if (this.isFileModel(currentModel.type) && (currentModel.file == null && currentModel.url == null)) {
      this.modalService.warn("The Type of Announcement selected requires a file to be uploaded in the Data / Link Section. Please upload a file!", "Required");
      return false;
    }
    else if (this.isDataModel(currentModel.type) && !currentModel.data) {
      this.modalService.warn("The Type of Announcement selected requires an external link in the Data / Link Section. Please enter text or a URL!", "Required");
      return false;
    }
    else if (currentModel.type == this.announcementType.Youtube ||
      currentModel.type == this.announcementType.ExternalLink) {
      var isvalid = new FormControl(currentModel.data, [urlValidator]).valid;
      if (!isvalid) {
        this.modalService.warn("URL is not in correct format(https://domain.com/...) !", "Valid Format");
        return false;
      }
    }
    return true;
  }

  selectAnnouncement: ClientAnnouncementViewModel;
  bsModalRef: any;
  addToAllSiteModalref: any;
  addSiteToAnnouncementModalref: any;
  onViewSites(template, model: ClientAnnouncementViewModel) {
    this.selectAnnouncement = model;
    this.getSitesOfAnnouncement(model.id);
    this.bsModalRef = this.bsModalService.show(template, {
      class: "modal-lg",
      ignoreBackdropClick: false,
    });
  }

  getSitesOfAnnouncement(announcementId) {
    var obj: SitesOfAnnouncementQueryModel = { announcementId: announcementId };
    this.announcementService.getSitesOfAnnouncement(obj).subscribe(res => {
      this.sitesOfAnnouncement = res;
    });
  }

  onRemove(model: ClientAnnouncementViewModel) {
    this.modalService.confirm("Are you sure you want to delete selected announcement?").subscribe(del => {
      if (del === true) {
        this.inProgress = true;
        this.announcementService.remove(model.id)
          .pipe(finalize(() => { this.inProgress = false; }))
          .subscribe(
            res => {
              if (res == false) // has multiple related record
              {
                this.modalService.info("This announcement has multiple relations to another records, announcement status set to in-active")
                  .subscribe(modalRes => {
                    this.init();
                  })
              }
              else
                this.init();
            },
            err => {
              this.modalService.error(err)
                .subscribe(modalRes => {
                  this.init();
                })
            }, () => {
            });
      }
    });
  }

  resetTemporyProperties(editableModel: EditableItem<ClientAnnouncementViewModel>) {
    editableModel.model.file = null;
    editableModel.model.progress = "0";
    if (!editableModel.formGroup.get('file'))
      editableModel.formGroup.addControl('file', new FormControl(null));
    if (!editableModel.formGroup.get('progress'))
      editableModel.formGroup.addControl('progress', new FormControl("0"));
  }

  onAnnouncementCreated($event) {
    this.init();
  }

  onAnnouncementUpdated($event) {
    if ($event != null)
      this.init();
  }

  isModelDataUrl(type: FileType) {
    return type == this.announcementType.Youtube ||
      type == this.announcementType.ExternalLink;
  }

  getAnnouncementTypeLabel(type: FileType) {
    if (type == this.announcementType.ExternalLink)
      return "External Link";
    else
      return this.announcementType[type];
  }

  removeFromAllSitesOfCompany(id: number) {
    this.modalService.confirm('Are you sure you want to delete selected announcement from all sites?', 'Delete').subscribe(del => {
      if (del === true) {
        this.inProgress = true;
        this.announcementService.RemoveAnnouncementFromAllSitesOfCompany(id).subscribe(
          res => {
            if (res.success) {
              this.toastr.info(res.message);
              this.getSitesOfAnnouncement(id);
            }
            else
              this.modalService.error(res.message);
          },
          err => {
            this.modalService.error(err);
          }, () => {
            this.inProgress = false;
            this.init();
          });
      }
    });
  }

  openAddToAllSitesOfCompanyModal(template, selectedAnnouncement: ClientAnnouncementViewModel) {
    this.newAnnouncementForAll = new EditableItem({
      announcementId: selectedAnnouncement.id,
      validFrom: null,
      validTo: null,
      mode: this.announcementMode.Silent,
      forceViewMode: this.SiteAnnouncementForceViewMode.Disable,
      displayTime: this.SiteAnnouncementDisplayTime.AfterCheckin,
    } as SiteAnnouncementUpdateModel, {
    });
    this.addToAllSiteModalref = this.modalService.show(template, 'modal-lg');
  }

  addToAllSitesOfCompanyModal() {
    var validTo = this.newAnnouncementForAll.formGroup.get('validTo').value;
    var validFrom = this.newAnnouncementForAll.formGroup.get('validFrom').value;

    if (this.newAnnouncementForAll.formGroup.get('displayTime').value == this.SiteAnnouncementDisplayTime.WhileCheckin) {
      this.newAnnouncementForAll.formGroup.get('mode').setValue(this.announcementMode.Silent);
    }
    if (validTo && validFrom && Date.parse(validTo) <= Date.parse(validFrom))
      this.modalService.error('The beginning date of validation cannot be after end date of validation!')
        .subscribe(modalRes => {
          this.init();
        });
    else {
      this.modalService.hide(this.addToAllSiteModalref);
      this.inProgress = true;
      this.announcementService.AddAnnouncementToAllSitesOfCompany(this.newAnnouncementForAll.formGroup.value).subscribe(
        res => {
          if (res.success) {
            this.toastr.info(res.message);
            this.getSitesOfAnnouncement(this.newAnnouncementForAll.formGroup.get("announcementId").value);
          }
          else
            this.modalService.error(res.message);
        },
        err => {
          this.modalService.error(err);
        }, () => {
          this.inProgress = false;
          this.init();
        });
    }
  }

  onChangeSelectedSite(site) {
    this.siteId = site.id;
  }

  openAddSitesToAnnouncement(template, selectedAnnouncement: ClientAnnouncementViewModel) {
    this.newAnnouncementForAll = new EditableItem({
      siteId: this.siteId,
      announcementId: selectedAnnouncement.id,
      validFrom: null,
      validTo: null,
      mode: this.announcementMode.Silent,
      forceViewMode: this.SiteAnnouncementForceViewMode.Disable,
      displayTime: this.SiteAnnouncementDisplayTime.AfterCheckin,
    } as SiteAnnouncementAddModel, {
    });
    this.addToAllSiteModalref = this.modalService.show(template, 'modal-lg');
  }

  addSiteToAnnouncementClick() {
    var validTo = this.newAnnouncementForAll.formGroup.get('validTo').value;
    var validFrom = this.newAnnouncementForAll.formGroup.get('validFrom').value;

    if (this.newAnnouncementForAll.formGroup.get('displayTime').value == this.SiteAnnouncementDisplayTime.WhileCheckin) {
      this.newAnnouncementForAll.formGroup.get('mode').setValue(this.announcementMode.Silent);
    }
    if (validTo && validFrom && Date.parse(validTo) <= Date.parse(validFrom))
      this.modalService.error('The beginning date of validation cannot be after end date of validation!')
        .subscribe(modalRes => {
          this.init();
        });
    else {
      this.modalService.hide(this.addToAllSiteModalref);
      this.inProgress = true;
      this.announcementService.addSiteAnnouncement(this.newAnnouncementForAll.formGroup.value).subscribe(
        res => {
          this.getSitesOfAnnouncement(this.newAnnouncementForAll.formGroup.get("announcementId").value);
        },
        err => {
          this.inProgress = false;
          this.modalService.error(err);

        }, () => {
          this.inProgress = false;
          this.init();
        });
    }
  }

  RemoveSiteFromAnnouncement(site) {
    this.modalService.confirm('Are you sure you want to delete selected site from announcement?', 'Delete').subscribe(del => {
      if (del === true) {
        this.inProgress = true;
        this.announcementService.removeSiteAnnouncement(site.siteAnnouncementId).subscribe(res => {
          this.getSitesOfAnnouncement(site.announcementId);
        }, err => {
          this.inProgress = false;
        },
          () => {
            this.inProgress = false;
            this.init();
          });
      }
    });
  }
}

