import { Pipe, PipeTransform } from '@angular/core';
import { FormType } from '../enums/form-type';

@Pipe({
  name: 'formType'
})
export class FormTypePipe implements PipeTransform {

  transform(value: unknown): string {
    if (value == FormType.Generic) return "Generic";
    if (value == FormType.Permit) return "Permit";
    if (value == FormType.SupplierDocumentReview) return "Supplier Document Review";
    if (value == FormType.SupplierForm) return "Supplier Form";
    if (value == FormType.AssetRegistration) return "Asset Registration";
    if (value == FormType.AssetOperator) return "Asset Operator";
    if (value == FormType.AssetOperation) return "Asset Operation";
    if (value == FormType.SiteBriefing) return "Site Briefing";
    return value?.toString();
  }

}
