import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { uuid4 } from 'src/app/helpers/uuid';
import { StorageService } from 'src/app/services/storage.service';
import { UserDocumentType } from "../../../models/user-document-type";
import { UserDocumentTypeService } from "../../../services/user-document-type.service";
import { OrderedEntity } from "../../../models/ordered-entity";

@Component({
  selector: 'li[obc-document-type-viewer-item]',
  templateUrl: './document-type-viewer-item.component.html',
  styleUrls: ['./document-type-viewer-item.component.scss'],
})
export class DocumentTypeViewerItemComponent implements OnInit {

  @Input() documents: UserDocumentType[] = [];
  @Input() document: UserDocumentType;
  @Input() canDelete: boolean;
  @Input() draggable: boolean;
  @Input() canAdd: boolean;
  @Input() canUpdate: boolean;
  checked: boolean = true;
  @Input() cacheUniqueId: string = '';
  opacity: number = 1;

  constructor(private userDocumentTypeService: UserDocumentTypeService,
    public storageService: StorageService,
    ) { }

  uuid = uuid4();

  @HostBinding('class.has-child') hasChild: boolean = false;

  children: UserDocumentType[] = [];

  ngOnInit(): void {

    if(this.document?.isAvailable === true)
      this.opacity = 1;
    else
      this.opacity = 0.5

    this.children = this.documents?.filter((item) => item.parentId == this.document.id);
    if(this.children?.length > 0)
      this.hasChild = true;

    let companyTree = JSON.parse(this.storageService.get(this.cacheUniqueId));
    if(companyTree && companyTree.find((item) => item == this.document?.id)) {
      this.checked = true;
    } else {
      this.checked = false;
    }
  }

  emitAddNew(document: UserDocumentType) {
    this.userDocumentTypeService.addDocumentTypeReplay.next(document);
  }
  emitUpdate(document: UserDocumentType) {
    this.userDocumentTypeService.updateDocumentTypeReplay.next(document);
  }
  emitDelete(document: UserDocumentType) {
    this.userDocumentTypeService.deleteDocumentTypeReplay.next(document);
  }

  saveDocumentCheckedStatusOnCache(value) {
    if(value) {
      let companyTree = JSON.parse(this.storageService.get(this.cacheUniqueId)) ?? [];
      if(!companyTree.find((item) => item == this.document?.id)) {
        companyTree.push(this.document.id);
      } else {
        companyTree = companyTree?.filter((item) => item != this.document.id);
      }
      this.storageService.set(this.cacheUniqueId, JSON.stringify(companyTree));
    }
  }

  @Output() orderedList: EventEmitter<OrderedEntity[]> = new EventEmitter<OrderedEntity[]>();
  onOrderedList(orderedList: OrderedEntity[]) {
    this.orderedList.emit(orderedList);
  }
}
