import { Pipe, PipeTransform } from '@angular/core';
import { FormScope } from '../enums/form-scope';
import { FormType } from '../enums/form-type';

@Pipe({
  name: 'formScope'
})
export class FormScopePipe implements PipeTransform {

  transform(value: unknown , formType: FormType): string {
    if (value === FormScope.Company)
      return formType == FormType.SiteBriefing ? 'Automatically add to all sites' : 'Company Wide';
    if (value === FormScope.Site)
      return formType == FormType.SiteBriefing ? 'Manually add to specific sites' : 'Site Specific';
    return value?.toString();
  }

}
