import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CompanyViewModel } from 'src/app/models/company-viewmodel';
import { SupplierDocumentKindViewModel } from 'src/app/models/supplier-document/supplier-document-kind-viewmodel';
import { CompanyAuthService } from 'src/app/services/company-auth.service';
import { DocumentKindService } from 'src/app/services/document-kind.service';
import { LayoutService } from 'src/app/services/layout.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'obc-document-kind-manager',
  templateUrl: './document-kind-manager.component.html',
  styleUrls: ['./document-kind-manager.component.scss']
})
export class DocumentKindManagerComponent implements OnInit {

  @Input() isCurrentCompanyManagement = false;

  inProgress: boolean = false;
  selectedCompany: CompanyViewModel;
  initialCompanyId: number;
  kindList: SupplierDocumentKindViewModel[];
  bsModalRef: BsModalRef;
  selectedKind: SupplierDocumentKindViewModel;
  isGodUserMode: boolean;


  constructor(
    private documentKindService: DocumentKindService,
    private bsModalService: BsModalService,
    private layoutService: LayoutService,
    private companyAuthService: CompanyAuthService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.isGodUserMode = this.userService.isGodUser();
    this.layoutService.header = "Manage Document Kinds";
    this.initialCompanyId = this.companyAuthService.getCompanyId();
    this.loadKindList();
  }

  get selectedCompanyId() {
    return this.isCurrentCompanyManagement ? this.initialCompanyId : (this.selectedCompany?.id ?? this.initialCompanyId);
  }

  get showNewKindButton() {
    return this.selectedCompanyId && this.isGodUserMode;
  }

  loadKindList() {
    this.kindList = [];

    if (this.selectedCompanyId) {
      this.inProgress = true;
      this.documentKindService.documentKindToManage(this.selectedCompanyId)
        .subscribe({
          next: (res) => {
            this.kindList = res;
          },
          error: (err) => {
            this.inProgress = false;
          },
          complete: () => {
            this.inProgress = false;
          }
        });
    }
  }


  onShowEditModal(template: any, kind?: SupplierDocumentKindViewModel) {
    this.selectedKind = kind;
    var config = {
      class: "modal-lg"
    };

    this.bsModalRef = this.bsModalService.show(template, config);
  }

  onSaveDocumentKind() {
    this.loadKindList();
    this.bsModalRef.hide();
  }

}
