import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TriggerType} from "../../enums/trigger-type.enum";
import {Form, FormControl} from "@angular/forms";

@Component({
  selector: 'obc-trigger-selector',
  templateUrl: './trigger-selector.component.html',
  styleUrls: ['./trigger-selector.component.scss']
})
export class TriggerSelectorComponent implements OnInit {

  @Output() selectedTrigger = new EventEmitter<TriggerType>();
  @Input() inputValue: any = 'All';

  triggers = [
    "All",
    TriggerType.Flag,
    TriggerType.Reject,
    TriggerType.SubmitForm,
    TriggerType.ApprovalState
  ];

  triggersFC: FormControl = new FormControl();

  constructor() { }

  ngOnInit(): void {
    this.triggersFC.valueChanges.subscribe(trigger => {
      if(typeof trigger != typeof undefined) {

        let triggerType: TriggerType;
        if(trigger == TriggerType.Flag)
          triggerType = TriggerType.Flag;
        else if(trigger == TriggerType.Reject)
          triggerType = TriggerType.Reject;
        else if(trigger == TriggerType.SubmitForm)
          triggerType = TriggerType.SubmitForm;
        else if(trigger == TriggerType.ApprovalState)
          triggerType = TriggerType.ApprovalState;
        else
          triggerType = null;

        this.selectedTrigger.emit(triggerType);
      }
    });
    this.triggersFC.setValue(this.inputValue);
  }

  getTriggerTypeFriendlyName(type): string {
    switch (type) {
      case 'All':
        return 'All';
      case TriggerType.Flag:
        return 'Flag';
      case TriggerType.Reject:
        return 'Rejected';
      case TriggerType.SubmitForm:
        return 'Form';
      case TriggerType.ApprovalState:
        return 'Approval Changed';
      default:
        return TriggerType[type];
    }
  }

  ngOnDestroy() {
    if (this.selectedTrigger) {
      this.selectedTrigger.unsubscribe()
    }
  }

}
