import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SiteQuestionsViewModel} from "../../models/sitequestion-viewmodel";
import {CustomFormControl} from "../../models/custom-formcontrol";
import {BehaviorSubject} from "rxjs";
import {SiteAttendanceQuestionAnswer} from "../../models/attendance-request/site-attendance-question-answer";
import {SiteQuestionRendererViewModel, SiteQuestionViewModel} from "../../models/site-question-viewmodel";
import {QuestionType} from "../../enums/question-type";
import {
  approximateFileSizeFromBase64String,
  getMimeTypeFromExtension,
  isQuestionIsVisibleByCheckingConditions
} from 'src/app/helpers/general-functions';
import {
  BaseSiteAnnouncementViewModel,
  InternalAnnouncementVisitViewModel
} from 'src/app/models/announcement-viewmodel';
import { UserDocumentType } from "../../models/user-document-type";
import { AdditionalDocumentConfig } from "../../models/induction-viewmodel";
import { ModalService } from "../../services/modal.service";
import { FormService } from 'src/app/services/form.service';
import {SiteAttendanceInductionViewModel} from "../../models/site-attendance-induction-view-model";
import {FormArray} from "@angular/forms";
import {FilledFormInfoViewModel} from "../../models/filled-form-info-view-model";
import {DocumentViewModel} from "../../models/document-viewmodel";
import {EmergencyContactViewModel} from 'src/app/models/emergency-contact-view-model';
import {UploadFileInfo} from "../../models/requiredDocumentAnswer";
import {SiteQuestionAnswerViewModel} from "../../models/site_question_answer_view_model";
import {CacheService} from "../../services/cache.service";
import {ItemRendererDirtyControlsModel} from "../../models/item-renderer-dirty-controls-model";

@Component({
  selector: 'obc-items-renderer',
  templateUrl: './items-renderer.component.html',
  styleUrls: [ './items-renderer.component.scss' ]
})
export class ItemsRendererComponent implements OnInit {

  SiteQuestionsViewModel = SiteQuestionsViewModel;

  additionalDocumentConfig: AdditionalDocumentConfig;
  availableDocumentTypes: UserDocumentType[] = [];
  allUserDocumentTypes: UserDocumentType[] = [];

  announcements: InternalAnnouncementVisitViewModel[] = [];
  checkinAnnouncements: BaseSiteAnnouncementViewModel[] = [];
  documents: CustomFormControl[] = [];
  questions: SiteQuestionRendererViewModel[] = [];

  /*Induction Inputs*/
  notifyVisits = false;
  anonymousAttendanceKey = null;
  @Input() largeButton = true;
  @Output() onAnnouncementVisit = new EventEmitter<number>();
  @Output() onCheckedMandatorySignOff = new EventEmitter<number>();
  @Output() removeDocumentType = new EventEmitter();
  @Output() addAdditionalDocumentToDocumentTypesOutput = new EventEmitter<UserDocumentType>();


  // any means following types:
  // Questions: SiteQuestionRendererViewModel
  // Documents: item.item => UserDocumentType
  items: any[] = [];

  induction: SiteAttendanceInductionViewModel;
  constructor(private modalService: ModalService, private formService: FormService, private cacheService: CacheService) {
  }

  cacheServiceDirtyItemsListener;
  rendererDirtyItems: ItemRendererDirtyControlsModel[] = [];
  ngOnInit(): void {
    this.cacheServiceDirtyItemsListener = this.cacheService.formControlDirtySubject?.asObservable().subscribe(item => {
      if(!this.isInDirtyRendererItems(item))
        this.rendererDirtyItems.push(item);
    })
  }
  ngOnDestroy() {
    this.cacheServiceDirtyItemsListener?.unsubscribe();
  }

  init() {

    if (this.availableDocumentTypes?.length > 0)
      this.selectedDocumentTypeId = this.availableDocumentTypes[0].id;

    let tmpArrayItems: any[] = [ ...this.questions, ...this.announcements, ...this.checkinAnnouncements, ...this.documents ];
    if (this.additionalDocumentConfig)
      tmpArrayItems.push(this.additionalDocumentConfig);

    tmpArrayItems.sort(function (a, b) {
      let a_order, b_order;
      if (a.question) a_order = a.question.displayOrder;
      if (a.allowUsersToAttachAdditionalDocuments) a_order = a.order;
      if (a.announcementId) a_order = a.order;
      else if (a.item) a_order = a.item.displayOrder;

      if (b.question) b_order = b.question.displayOrder;
      if (b.allowUsersToAttachAdditionalDocuments) b_order = b.order;
      if (b.announcementId) b_order = b.order;
      else if (b.item) b_order = b.item.displayOrder;

      return a_order > b_order ? 1 : a_order < b_order ? -1 : 0
    });

    let groupList = {announcements: [], groupName: null, isAnnouncementGroup: true};

    this.items = tmpArrayItems.map(function (item, index) {
      if (item.question || item.item || item.allowUsersToAttachAdditionalDocuments) {
        return item;
      } else if (item.announcementId) {
        if (groupList.announcements.includes(item))
          return null;

        groupList.groupName = item.groupName;
        groupList.announcements = [];
        for (let i = index; i <= tmpArrayItems.length - 1; i++) {
          let innerItem = tmpArrayItems[i];
          if (innerItem.announcementId && innerItem.groupName == item.groupName)
            groupList.announcements.push(innerItem);
          else
            break;
        }
        return Object.assign({}, groupList);
      }
    }).filter(item => item != null);
  }

  _validation$ = new BehaviorSubject<{ valid: boolean, answers: SiteAttendanceQuestionAnswer[], isUpdatingByUser?: boolean }>({
    valid: false,
    answers: []
  });

  isEditMode: boolean = false;

  @Input() set questionsData(model: {
    induction: SiteAttendanceInductionViewModel,
    questions: SiteQuestionViewModel[],
    draftFilledForm: FilledFormInfoViewModel,
    documents: CustomFormControl[],
    documentsFormArray: FormArray,
    announcements: InternalAnnouncementVisitViewModel[],
    checkinAnnouncements: BaseSiteAnnouncementViewModel[],
    notifyVisits: boolean,
    anonymousAttendanceKey: string,
    validator$: BehaviorSubject<{ valid: boolean, answers: SiteAttendanceQuestionAnswer[] }>,
    additionalDocumentConfig: AdditionalDocumentConfig,
    availableDocumentTypes: UserDocumentType[],
    allUserDocumentTypes: UserDocumentType[],
  }) {
    if(model.draftFilledForm != null) {
      this.isEditMode = model.draftFilledForm?.formDataId && model.draftFilledForm?.formDataId > 0;
    }

    if (model.questions) {
      let qs = model.questions.map(x => {
        let qm = new SiteQuestionRendererViewModel();
        qm.formGroup = null;
        qm.question = new SiteQuestionViewModel(x);

        let answers = model.draftFilledForm?.questionAnswers?.filter(e => e.questionId == qm.question?.questionId
          && e.documentTypeId == qm.question?.document?.documentType?.id,
        );
        
        let lastStateAnswer = model?.validator$?.value?.answers?.find((item) => item.questionId == qm.question?.questionId);
        if(model.induction) {
          lastStateAnswer = model.induction?.answers?.answers?.questions?.find((item) => item.questionId == qm.question?.questionId);
        }
        if(lastStateAnswer && lastStateAnswer.answer?.length > 0) {
          let finalAnswer = {
            questionId: qm.question?.questionId,
            inductionId: model.induction?.induction?.id,
            documentTypeId: qm.question?.documentTypeId,
            answer: "",
            isBase64Answer: false
          } as SiteQuestionAnswerViewModel;
          if(answers) {
            finalAnswer = { ...answers[0] };
          }
          finalAnswer.isBase64Answer = false;
          finalAnswer.answer = lastStateAnswer.answer;
          
          let pipeSplitAnswer = lastStateAnswer?.answer?.split("|");
          let pipeSplitExtensions = lastStateAnswer?.fileType?.split("|");
          if(pipeSplitAnswer && pipeSplitAnswer.length > 0) {
            let base64Answers = [];
            for (let i = 0; i < pipeSplitAnswer.length; i++) {
              try {
                window.atob(pipeSplitAnswer[i]);
                let mimeType = getMimeTypeFromExtension(pipeSplitExtensions[i]);
                if(pipeSplitExtensions[i] && pipeSplitAnswer[i]) {
                  base64Answers.push({
                    fileExtension: pipeSplitExtensions[i],
                    fileAsBase64: pipeSplitAnswer[i],
                    fileSize: approximateFileSizeFromBase64String(`data:${mimeType};base64,${pipeSplitAnswer[i]}`)
                  } as UploadFileInfo);
                }
              } catch(e) {}
            }
            if(base64Answers.length) {
              qm.lastStateInitialFileAnswers = base64Answers;
            }
          }
          answers = [finalAnswer];
          if (answers.length > 0)
            qm.initialAnswers = [ ...answers ];
        } else {
          if(!this.isInDirtyRendererItems({questionId: qm.question?.questionId})) {
            if (answers && answers.length > 0)
              qm.initialAnswers = [ ...answers ];
          }
        }
        return qm;
      });

      this.updateQuestionShowFlags(qs, []);
      this.questions = qs;
      this._validation$ = model.validator$;
      this.onValueChanges();
    }

    if (model.documents) {
      model.documents.map(docController => {
        let answer: DocumentViewModel;
        if(!this.isInDirtyRendererItems({documentTypeId: docController.item?.documentType?.id})) {
          answer = model.draftFilledForm?.documents?.find(e => e.documentTypeId == docController.item?.documentType?.id);
        }
        if (answer)
          docController.item.initialAnswer = answer;
        
        let sourceDocuments = this.documents;
        if(model.induction) {
          sourceDocuments = model.induction?.answers?.answers?.documents;
        }
        let lastStateAnswer = sourceDocuments?.find((control) => {
          return (control as CustomFormControl).item?.documentType?.id == docController.item?.documentType?.id });
        
        if(lastStateAnswer && lastStateAnswer.value) {
          docController.item.initialAnswer = lastStateAnswer.value;
          docController.setValue(lastStateAnswer?.value);
        }
      });

      // attach initial answer to document form control field of question documents.
      model.questions?.map(questionDocument => {
        let answer: DocumentViewModel;
        
        let sourceDocuments = model.documentsFormArray?.controls;
        if(model.induction) {
          if(questionDocument.documentFormControl)
            sourceDocuments = [questionDocument.documentFormControl];
          else
            sourceDocuments = [];
        }
        let lastStateAnswer = sourceDocuments?.find((control) => {
          let relatedDoc = (control as CustomFormControl).item.documentTypes?.find((controlDoc) => 
              controlDoc.id == questionDocument.documentFormControl?.item?.documentType?.id);
          if(relatedDoc && (control as CustomFormControl).item?.questionId == questionDocument.questionId)
            return control;
          return null;
        });

        if(lastStateAnswer?.value) {
          answer = lastStateAnswer?.value;
        } else {
          if (!this.isInDirtyRendererItems({
            questionId: questionDocument.questionId,
            documentTypeId: questionDocument.documentFormControl?.item?.documentType?.id
          })) {
            let result = model.draftFilledForm?.questionAnswers?.find(e => e.documentTypeId == questionDocument.documentFormControl?.item?.documentType?.id
                && e.questionId == questionDocument.documentFormControl?.item?.question?.questionId,
            );

            if (result) {
              let cloneFileUrls = [...result.fileUrls];
              cloneFileUrls.shift();
              answer = {
                additionalFileUrls: cloneFileUrls,
                documentTypeId: result.documentTypeId,
                userDocumentId: result.userDocumentId,
                expireDate: result.documentExpireDate,
                documentTitle: result.documentTitle,
                documentTypeTitle: result.documentTypeTitle,
                fileUrl: result.fileUrls != null && result.fileUrls.length > 0 ? result.fileUrls[0] : null,
              } as DocumentViewModel;
            }
          }
        }
        if (answer && questionDocument.documentFormControl)
          questionDocument.documentFormControl.item.initialAnswer = answer;
      });
    }

    this.induction = model.induction ?? null;
    this.documents = model.documents ?? [];
    this.announcements = model.announcements ?? [];
    this.checkinAnnouncements = model.checkinAnnouncements ?? [];
    this.notifyVisits = model.notifyVisits ?? false;
    this.anonymousAttendanceKey = model.anonymousAttendanceKey ?? null;
    this.additionalDocumentConfig = model.additionalDocumentConfig ?? null;
    this.availableDocumentTypes = model.availableDocumentTypes ?? [];
    this.allUserDocumentTypes = model.allUserDocumentTypes ?? [];

    model.documentsFormArray?.valueChanges?.subscribe(_ => {
      this.onValueChanges(1);
    });

    this.init();
  }


  _clearDocumentQuestionsByFlag() {
    this.questions?.forEach(question => {
      if (!question.showFlagLastState) {
        question?.question?.documentFormControl?.setValue(null);
      }
    });
  }
  isInDirtyRendererItems(newItem: ItemRendererDirtyControlsModel) {
    return this.rendererDirtyItems?.find((dirtyItem) => dirtyItem.questionId == newItem.questionId && dirtyItem.documentTypeId == newItem.documentTypeId)
  }
  
  onValueChanges(isRefresh = 0) {
    if (this.questions == null) return;
    if (this._validation$ != null) {

      let answers: SiteAttendanceQuestionAnswer[] = [];
      for (let i = 0; i < this.questions.length; i++) {
        let initialAnswers = this.questions[i]?.initialAnswers;
        let storageKeyList = initialAnswers != null && initialAnswers.length > 0 ? initialAnswers[0]?.fileStorageKeyList : [];
        let isNotModified = false;

        if (this.questions[i].showFlagLastState &&
             (this.questions[i].answers != null && this.questions[i].answers.length) > 0 || 
             (this.questions[i].answers === true || this.questions[i].answers === false) || 
             this.questions[i].answers instanceof Date ||
             this.questions[i].answers instanceof EmergencyContactViewModel) {
          let answer = "";
          let fileType = "";
          if (this.questions[i].question.questionType == QuestionType.SingleSelect || this.questions[i].question.questionType == QuestionType.Text)
            answer = this.questions[i].answers;
          else if (this.questions[i].question.questionType == QuestionType.File) {
            answer = this.questions[i].answers;
            fileType = this.questions[i].fileType;
            isNotModified = this.questions[i]?.isNotModified;
          } else if (this.questions[i].question.questionType == QuestionType.SignaturePad) {
            answer = this.questions[i].answers;
            fileType = 'png';
          } else if (this.questions[i].question.questionType == QuestionType.Boolean) {
            answer = this.questions[i].answers ? "Yes" : "No";
          } else if (this.questions[i].question.questionType == QuestionType.Date) {
            try {
              answer = this.questions[i].answers ? this.questions[i]?.answers?.toISOString() : null;
            } catch(e) {
              answer = null;
            }
          } else if (this.questions[i].question.questionType == QuestionType.MultiSelect) {
            answer = (this.questions[i].answers as []).join(',')
          } else {
            answer = JSON.stringify(this.questions[i].answers);
          }

          if (this.questions[i].question?.questionType == QuestionType.SignaturePad) {
            try {
              // check if answer is base64 or not. if base64, empty storage key list
              window.atob(answer);
              storageKeyList = [];
            } catch (e) {
              if (storageKeyList.length > 1)
                storageKeyList = [ storageKeyList[0] ];
            }
          }

          answers.push({
            questionId: this.questions[i].question.questionId,
            isNotModified: isNotModified,
            answer: answer, fileType: fileType,
            notModifiedStorageKeyList: storageKeyList,
          });
          this.formService.isFormDirty.next(true);
        }
      }

      let finalAnswers = this.updateQuestionShowFlags(this.questions, answers);
      let valid = this.validate();
      
      // isUpdatingByUser is only used for inductions. to detect auto emits and keep induction answers when induction is reset. 
      // by additional document or remove document
      this._validation$.next({valid: valid, answers: finalAnswers, isUpdatingByUser: true});

      if (!isRefresh) {
        setTimeout(() => {
          this.onValueChanges(1);
          this._clearDocumentQuestionsByFlag();
        }, 500);
      }
    }

  }

  // private getQuestion(questionId: number): SiteQuestionRendererViewModel {
  //   if (this.questions)
  //     return this.questions.find(x => x.question.questionId == questionId);
  // }


  updateQuestionShowFlags(questions: SiteQuestionRendererViewModel[], answers: SiteAttendanceQuestionAnswer[]): SiteAttendanceQuestionAnswer[] {
    if (!questions?.length) return;
    if (!answers) answers = [];
    let exit = true;
    do {
      exit = true;
      for (let i = 0; i < questions.length; i++) {
        let ret = this.getQuestionShowFlag(questions[i], answers);
        questions[i].showFlagLastState = ret;
        if (!ret) {
          let ans = answers.find(x => x.questionId == questions[i].question.questionId);
          if (ans) {
            let index = answers.indexOf(ans);
            answers.splice(index, 1);
            exit = false;
            break;
          }
        }
      }
    } while (!exit);
    return answers;
  }

  getQuestionShowFlag(question: SiteQuestionRendererViewModel, answers: SiteAttendanceQuestionAnswer[]): boolean {
    return isQuestionIsVisibleByCheckingConditions(question.question,
      this.questions?.map(q => q.question),
      answers);
  }

  private validate(): boolean {
    for (let index = 0; index < this.questions?.length; index++) {
      if (this.questions[index]?.showFlagLastState && (
        (this.questions[index]?.question?.document == null && this.questions[index]?.valid != true) ||
        (this.questions[index]?.question?.document != null &&
          (
            (!this.isEditMode && this.questions[index]?.question?.documentFormControl?.valid != true) ||
            (this.isEditMode && !this.questions[index]?.initialAnswers && this.questions[index]?.question?.documentFormControl?.valid != true) ||
            (this.isEditMode && this.questions[index]?.initialAnswers && this.questions[index]?.question?.documentFormControl?.item?.isDirty == true && this.questions[index]?.question?.documentFormControl?.valid != true)
          )
        )
      ))
        return false;
    }
    return true;
  }

  callOnAnnouncementVisitEvent(announcementId: number) {
    this.onAnnouncementVisit.emit(announcementId);
  }

  callOnAnnouncementCheckedMandatorySignOffEvent(announcementId: number) {
    this.onCheckedMandatorySignOff.emit(announcementId);
  }

  onClickAdditionalDocumentButton(template: any) {
    this.modalService.show(template);
  }


  selectedDocumentTypeId: number;

  onAddAdditionalDocumentToDocuments() {
    if (this.selectedDocumentTypeId) {
      let selectedDocument = this.allUserDocumentTypes?.find(item => item.id == this.selectedDocumentTypeId);
      if (selectedDocument) {
        this.formService.isFormDirty.next(true);
        this.addAdditionalDocumentToDocumentTypesOutput.emit(selectedDocument);
      }
      this.selectedDocumentTypeId = null;
    }
    this.modalService.hide();
  }

  onRemoveAdditionalDocumentType(documentType: UserDocumentType) {
    this.removeDocumentType.emit(documentType);
  }

}
