import { Pipe, PipeTransform } from '@angular/core';
import { ImportSource } from '../enums/import-source.enum';

@Pipe({
  name: 'importSource'
})
export class ImportSourcePipe implements PipeTransform {
  transform(source: ImportSource | null): String{
    switch (source) {
      case ImportSource.Procore:
        return 'Procore';
      default:
        return '1Breadcrumb';
    }
  }
}
