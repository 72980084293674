import { Component, OnInit } from '@angular/core';
import { FormControl } from "@angular/forms";
import { finalize } from "rxjs/internal/operators/finalize";
import { ModalService } from "../../../services/modal.service";
import { UserDocumentTypeService } from "../../../services/user-document-type.service";
import { UserDocumentType } from "../../../models/user-document-type";
import { AddCompanyDocumentTypeModel } from "../../../models/company-document-type-model";
import { CompanyDocumentTypeService } from "../../../services/company-document-type.service";
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'obc-company-document-type',
  templateUrl: './company-document-type.component.html',
  styleUrls: ['./company-document-type.component.scss']
})
export class CompanyDocumentTypeComponent implements OnInit {

  companyDocumentTypes: UserDocumentType[] = [];
  documentFormControl = new FormControl();
  selectedDocumentType: number;
  backupDocumentTypes: UserDocumentType[] = [];
  inProgress: boolean = false;

  constructor(private companyDocumentTypeService: CompanyDocumentTypeService,
    private modalService: ModalService,
    private userDocumentTypeService: UserDocumentTypeService,
    public userService: UserService) { }

  deleteDocTypeSub;
  ngOnInit(): void {
    this.deleteDocTypeSub = this.userDocumentTypeService.deleteDocumentTypeReplay.asObservable().subscribe((res: UserDocumentType) => {
      if (res && this.companyDocumentTypes?.length > 0) {
        this.removeCompanyDocumentType(res.id);
      }
    });
    this.fetchCompanyDocumentTypes();
  }
  ngOnDestroy() {
    this.deleteDocTypeSub.next();
    this.deleteDocTypeSub.unsubscribe();
  }

  onSelectedDocumentType(event) {
    this.selectedDocumentType = event.id;
  }

  ignoreDocumentTypeIds: number[] = [];
  fetchCompanyDocumentTypes() {
    this.inProgress = true;
    this.companyDocumentTypeService.list()
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(result => {
        this.companyDocumentTypes = result;
        this.companyDocumentTypes.sort(function(a, b) {
          let keyA = new Date(a.order),
            keyB = new Date(b.order);
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        this.ignoreDocumentTypeIds = result?.filter((item) => item.isAvailable)?.map((item) => item.id);
      });
  }

  addCompanyDocumentType() {
    if (!this.selectedDocumentType)
      return;

    this.inProgress = true;
    let model: AddCompanyDocumentTypeModel = { documentTypeId: this.selectedDocumentType };
    this.companyDocumentTypeService.add(model)
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(_ => {
        this.fetchCompanyDocumentTypes();
      });
  }

  removeCompanyDocumentType(documentTypeId: number) {
    this.modalService.confirm('Are you sure?', 'delete document type').subscribe(iamsure => {
      if (iamsure === true) {
        this.inProgress = true;
        this.companyDocumentTypeService.remove(documentTypeId)
          .pipe(finalize(() => { this.inProgress = false; }))
          .subscribe(_ => {
            this.fetchCompanyDocumentTypes();
          });
      }
    });
  }
}
