import { Pipe, PipeTransform } from '@angular/core';
import { CheckinLocationMode } from '../enums/checkin-location-mode.enum';

@Pipe({
  name: 'checkinLocationMode'
})
export class CheckinLocationModePipe implements PipeTransform {

  transform(value: unknown): string {
    if (value === CheckinLocationMode.Disable) return "Disable";
    if (value === CheckinLocationMode.Optional) return "Optional";
    if (value === CheckinLocationMode.Mandatory) return "Mandatory";
    if (value === CheckinLocationMode.MandatoryOnSite) return "Mandatory (on-site only)";
    return value?.toString() ?? '?';
  }

}
