import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
import { SiteFormViewModel, } from 'src/app/models/form/site-form-view-model';
import { ModalService } from 'src/app/services/modal.service';
import { FormInjectorComponent } from '../../form-manage/form-injector/form-injector.component';
import { FormService } from 'src/app/services/form.service';
import { FormViewModel } from 'src/app/models/form/form-view-model';
import { FormScope } from 'src/app/enums/form-scope';
import { deepClone, getCountLabel, isEqual } from 'src/app/helpers/general-functions';
import { ManageSiteViewModel } from "../../../models/manage-site-view-model";
import { DirtyComponents } from "../../../models/dirty-components";
import { GetFormsQueryModel } from 'src/app/models/form/get-forms-query-model';
import { FormType } from "../../../enums/form-type";

@Component({
  selector: 'app-site-form',
  templateUrl: './site-forms.component.html',
  styleUrls: ['./site-forms.component.scss']
})
export class SiteFormsComponent implements OnInit {

  dirtyComponents: DirtyComponents;
  FormType = FormType;

  @Input('dirtyComponents') set setDirtyComponents(dirtyComponents: DirtyComponents) {
    this.dirtyComponents = dirtyComponents;
  }

  @Input() formType: FormType = FormType.Generic;

  makeComponentDirty() {
    this.dirtyComponents[this.dirtyComponentFieldName()].isDirty = !isEqual(this.dirtyComponents[this.dirtyComponentFieldName()]?.snapshot, this.myCopy());
  }

  dirtyComponentFieldName() {
    return (
      this.formType == FormType.Generic ? 'generic' :
        this.formType == FormType.Permit ? 'permit' :
          this.formType == FormType.AssetRegistration ? 'assetRegistration' :
            this.formType == FormType.SiteBriefing ? 'siteBriefing' :
              '') + 'Forms';
  }

  myCopy() {
    return {
      siteForms: deepClone(this.manageSiteModel?.forms?.filter(form => form.formType == this.formType) ?? []),
    };
  }


  firstRun: boolean = true;

  manageSiteModel: ManageSiteViewModel;

  @Input() set manageSiteViewModel(manageSiteModel: ManageSiteViewModel) {
    this.manageSiteModel = manageSiteModel;

    if (this.manageSiteModel && this.filterForms()) {
      this.dirtyComponents[this.dirtyComponentFieldName()].isDirty = false;
      this.dirtyComponents[this.dirtyComponentFieldName()].snapshot = this.myCopy();
    }
  }

  @Input() noCard: boolean = false;
  _siteId = new BehaviorSubject<number>(0);
  @Input() set siteId(value: number) {
    if (this._siteId.value != value) {

      this._siteId.next(value);

    }
  };

  forms = new BehaviorSubject<FormViewModel[]>([]);
  inProgress = false;
  formGroup = new FormGroup({
    id: new FormControl(null, []),
    formId: new FormControl(null, [Validators.required]),
    // siteFormMode: new FormControl(null, [Validators.required])
  });

  get entityTitle() {
    return this.formType == FormType.Generic ? 'Form' :
      this.formType == FormType.Permit ? 'Permit' :
        this.formType == FormType.AssetRegistration ? 'Asset Registration Forms' :
          this.formType == FormType.SiteBriefing ? 'Site Briefing' : '';
  }

  get headerInfo() {
    return getCountLabel(this.filterForms()?.length ?? 0, this.entityTitle);
  }

  constructor(private formService: FormService,
    private modalService: ModalService,
    private bsModalService: BsModalService) {
  }

  ngOnInit() {
    this.firstRun = false;
    this.getForms();
  }

  filterForms() {
    return this.manageSiteModel?.forms?.filter(form => form.formType == this.formType) ?? [];
  }

  private getForms() {
    var model = {
      formScope: FormScope.Site,
      formType: this.formType
    } as GetFormsQueryModel;

    this.formService.getForms(model).subscribe(
      res => {
        if (res.success) {
          this.forms.next(res.data);
        } else {
          this.modalService.error(res.message);
          this.inProgress = false;
        }
      }, err => {
        this.modalService.error("Request Failed");
        this.inProgress = false;
      }, () => {
        this.inProgress = false;
      }
    )
  }


  onRemoveSiteForm(model: SiteFormViewModel) {
    let index = this.manageSiteModel?.forms?.indexOf(model);
    this.manageSiteModel?.forms?.splice(index, 1);

    this.makeComponentDirty();
  }

  onAddSiteForm(form: FormViewModel) {
    var model = {
      formId: form.id,
      formName: form.name,
      siteFormId: null,
      formType: form.type
    } as SiteFormViewModel;

    let index = this.manageSiteModel?.forms?.indexOf(model);
    if (index == -1)
      this.manageSiteModel?.forms?.push(model);

    this.makeComponentDirty();
  }

  onOpenAddForm() {

    this.bsModalService.show(FormInjectorComponent, {
      class: "modal-lg",
      initialState: {
        formType: this.formType,
        title: `Select ${this.entityTitle}(s)`,
        forms: this.forms.value,
        addedForms: this.manageSiteModel?.forms?.map(x => x.formId),
        addForm: ($event) => {
          this.onAddSiteForm($event)
        },
      },
    })
  }

  getMainTitle() {
    switch (this.formType) {
      case FormType.Generic: return 'Generic Forms'
      case FormType.Permit: return 'Permits'
      case FormType.AssetRegistration: return 'Asset Registration Forms'
      case FormType.SiteBriefing: return 'Site Briefings'
      default: return '';
    }
  }
}
