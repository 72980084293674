export enum TextOperator {
    equals = 0,
    contains = 1,
    isGreaterThan = 2,
    isGreaterThanOrEqualTo = 3,
    isLessThan = 4,
    isLessThanOrEqualTo = 5,
    isNotNull = 6,
    isNull = 7,
}
