import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/enums/constants';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'obc-anonymous',
  templateUrl: './anonymous.component.html',
  styleUrls: ['./anonymous.component.css'],
})
export class AnonymousComponent implements OnInit {
  header: string = '';
  constructor() {}

  ngOnInit() {
    this.header = Constants.ANONYMOUS_ATTENDANCE_TITLE;
  }
}
