<div *ngIf="!form" style="min-height: 50px;">
  <obc-loader [show]="true"></obc-loader>
</div>
<div class="card mb-2" *ngIf=" (formId || previewRenderer) && form">
  <div class="card-header d-flex flex-row justify-content-md-between align-items-center">
    <div class="flex-fill">
      {{formName}}
    </div>
    <div *ngIf="displayCloseBtn">
      <span class="fa fa-times p-3" (click)="onCloseButton()"></span>
    </div>
  </div>
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>

    <div class="row mb-5" *ngIf="isEditMode && form?.type == FormTypeEnum.AssetOperator">
      <div class="col-12">
        <span>Operator: </span>
        <div class="d-flex justify-content-between">
          <strong>{{ operatorFullName }}</strong>
          <span>{{ form?.draftFilledForm?.operatorMobile | mobile }}</span>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="formType == FormTypeEnum.Permit">
      <div class="col-12 col-md-6">
        <div *ngIf="startDate" class="form-group">
          <label>Start Date</label>
          <obc-input-date-time-picker [formControl]="startDate" [mode]="DatetimePickerMode.Calender"
                                      [minDate]="minStartDate"
            (valueChange)="setMinAndMaxAvailableDates();validateAnswers();" [maxDate]="startMaxAvailableDate">
          </obc-input-date-time-picker>
          <!-- <input [owlDateTime]="dt1" class="form-control" [formControl]="startDate" [owlDateTimeTrigger]="dt1"
            (dateTimeChange)="setMinAndMaxAvailableDates();validateAnswers();" [max]="startMaxAvailableDate">
          <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time> -->
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div *ngIf="endDate" class="form-group">
          <label>End Date</label>
          <obc-input-date-time-picker [formControl]="endDate" [mode]="DatetimePickerMode.Calender"
            (valueChange)="setMinAndMaxAvailableDates();validateAnswers();" [minDate]="endMinAvailableDate">
          </obc-input-date-time-picker>
          <!-- <input [owlDateTime]="dt2" class="form-control" [formControl]="endDate" [owlDateTimeTrigger]="dt2"
            (dateTimeChange)="setMinAndMaxAvailableDates();validateAnswers();" [min]="endMinAvailableDate">
          <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time> -->
        </div>
      </div>
    </div>

    <div class="mt-3 mb-4" *ngIf="formType == FormTypeEnum.Permit">

      <div *ngIf="isEditMode" class="form-group">
        <label>Submit For</label>
        <select class="custom-select" [formControl]="registerFor">
          <option *ngFor="let formRegisterFor of validFormRegisterForValues;" value="{{ formRegisterFor }}">{{
            formRegisterFor | formRegisterFor:{supplierName: form?.draftFilledForm?.supplierName, userName:
            form?.draftFilledForm?.registerByFullName } }}</option>
        </select>
      </div>

      <obc-permit-schedule-selector *ngIf="form.permitFormInfo.isEnablePermitSchedule" [form]="form"
        (permitSchedulesOutput)="updatePermitSchedules($event)" [permitSchedules]="permitSchedules"
        (validateRes)="updatePermitSchedulesValidationResult($event)"></obc-permit-schedule-selector>

      <div class="row" *ngIf="form.permitFormInfo.isEnablePermitZone">
        <div class="col-12">
          <label>Request Exclusive Access:</label>
          <select class="custom-select" [formControl]="zoneControl">
            <option disabled value="{{ null }}" [ngValue]="null"> Not Required </option>
            <option *ngFor="let zone of form?.permitFormInfo?.locations;" value="{{ zone.id }}">{{ zone.name }}</option>
          </select>
        </div>
      </div>
    </div>


    <div class="row" *ngIf="isVisibleExpiryDate || isVisibleVersion">
      <div class="col-12 col-md-6" *ngIf="isVisibleExpiryDate">
        <div class="form-group">
          <label>Expiry Date</label>
          <obc-input-date-time-picker [formControl]="endDate" [mode]="DatetimePickerMode.Calender"
            (valueChange)="validateAnswers();">
          </obc-input-date-time-picker>
          <!-- <input [owlDateTime]="dt3" class="form-control" [formControl]="endDate" [owlDateTimeTrigger]="dt3"
            (dateTimeChange)="validateAnswers();" [min]="today">
          <owl-date-time [pickerType]="'calendar'" #dt3></owl-date-time> -->
        </div>
      </div>
      <div class="col-12 col-md-6" *ngIf="isVisibleVersion">
        <div class="form-group">
          <label>Version</label>
          <obc-input-date-time-picker [formControl]="versionDate" [mode]="DatetimePickerMode.Calender"
            (valueChange)="validateAnswers();">
          </obc-input-date-time-picker>
          <!-- <input [owlDateTime]="dt4" class="form-control" [formControl]="versionDate" [owlDateTimeTrigger]="dt4"
            (dateTimeChange)="validateAnswers();">
          <owl-date-time [pickerType]="'calendar'" #dt4></owl-date-time> -->
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <obc-items-renderer [questionsData]="{questions : form?.questions,
            draftFilledForm: form?.draftFilledForm,
            validator$: questionContainerValidation$,
            documents: requiredDocumentFormControls,
            documentsFormArray: requiredDocumentFormArray,
            announcements: form?.announcements,
            notifyVisits: notifyVisits,
            anonymousAttendanceKey: anonymousAttendanceKey,
            additionalDocumentConfig: form?.additionalDocumentConfig,
            allUserDocumentTypes: allUserDocumentTypes,
            availableDocumentTypes: availableDocumentTypes
            }" (removeDocumentType)="removeDocumentFromRequiredDocuments($event)"
          (addAdditionalDocumentToDocumentTypesOutput)="addSelectedDocumentToRequiredDocuments($event)"
          (onAnnouncementVisit)="onAnnouncementVisit($event)"></obc-items-renderer>
      </div>
    </div>

    <obc-asset-operator-mode-selector *ngIf="isEditMode && form?.type == FormTypeEnum.AssetRegistration"
      [manageSiteAssetMode]="this.form?.draftFilledForm?.manageSiteAssetMode" [supplierHashLink]="null"
      [onlyTempAsset]="false" [siteCompanyTitle]="this.form?.draftFilledForm?.siteCompanyTitle"
      [operatorRegistrationMode]="this.form?.draftFilledForm?.operatorRegistrationMode"
      (operatorMode)="setOperatorMode($event)"></obc-asset-operator-mode-selector>

    <div class="mt-3">
      <obc-workflow-form-renderer [(workflowModel)]="workflowModel"></obc-workflow-form-renderer>
    </div>

    <div *ngIf="innerSubmitButton">
      <div class="d-flex justify-content-center p-3">
        <button class="btn btn-success" [disabled]="!isFormValid" (click)="innerSubmitButtonMethod()">{{
          innerSubmitButtonText }}</button>
      </div>
    </div>

  </div>
</div>
