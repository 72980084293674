import { Pipe, PipeTransform } from '@angular/core';
import { QuestionType } from '../enums/question-type';

@Pipe({
  name: 'questionType'
})
export class QuestionTypePipe implements PipeTransform {

  transform(value: unknown, listFormat: boolean = false): any {
    if (value == QuestionType.Boolean) { return "Yes/No"; }
    if (value == QuestionType.SingleSelect) { return listFormat === true ? 'Single Select' : "Options (Single Select)"; }
    if (value == QuestionType.MultiSelect) { return listFormat === true ? 'Multi Select' : "Options (Multi Select)"; }
    if (value == QuestionType.Text) { return "Text"; }
    if (value == QuestionType.File) { return "File"; }
    if (value == QuestionType.RichText) { return "Static Text"; }
    if (value == QuestionType.Date) { return "Date"; }
    if (value == QuestionType.SignaturePad) { return "Signature Pad"; }
    if (value == QuestionType.AssetField) { return "Asset Field"; }
    if (value == QuestionType.ProfileInfo) { return "Profile Info"; }
    if (value == QuestionType.EmergencyContact) { return "Emergency Contact"; }
    return value.toString();
  }

}
