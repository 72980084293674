import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToolAssignHistoryRequestModel} from 'src/app/models/inventory/tool-assign-history-request-model';
import {ToolAssignHistoryViewModel} from 'src/app/models/inventory/tool-assign-history-view-model';
import {ToolFullInfoViewModel} from 'src/app/models/inventory/tool-full-info-view-model';
import {BasePagingModel, pagingModel} from 'src/app/models/paging_model';
import {InventoryService} from 'src/app/services/inventory.service';
import {ModalService} from 'src/app/services/modal.service';
import {getPageSizeByUrl} from "../../../../helpers/general-functions";

@Component({
  selector: 'obc-tool-assign-history',
  templateUrl: './tool-assign-history.component.html',
  styleUrls: ['./tool-assign-history.component.scss']
})
export class ToolAssignHistoryComponent implements OnInit {
  @Output() public hide = new EventEmitter();

  @Input() tool: ToolFullInfoViewModel;
  inProgress: boolean = false;
  pagingInfo: pagingModel = {
    pageNumber: 0,
    pageSize: getPageSizeByUrl() ?? 10,
    totalCount: 0,
  }
  report: ToolAssignHistoryViewModel[];
  constructor(private inventoryService: InventoryService, private modalService: ModalService) { }

  ngOnInit(): void {
    if (this.tool == null || this.tool == undefined) {
      this.modalService.error("Select a tool");
      return;
    }
    this.getToolHistory(0);
  }

  getToolHistory(pageNumber?: number) {
    if (pageNumber != null && pageNumber != undefined)
      this.pagingInfo.pageNumber = pageNumber;
    var requestModel = this.createRequestModel();
    this.inProgress = true;

    this.inventoryService.getToolAssignHistory(requestModel).subscribe(
      res => {
        if (res.success) {
          this.pagingInfo.totalCount = res.data.totalCount;
          this.report = res.data.data;
        } else {
          this.modalService.error(res.message);
        }
      }, err => {
        this.modalService.error("Request Failed");
        this.inProgress = false;
      }, () => {
        this.inProgress = false;
      }
    )
  }

  createRequestModel(): ToolAssignHistoryRequestModel {
    var query = {
      toolId: this.tool?.id,
      pagingInfo: {
        pageNumber: this.pagingInfo.pageNumber,
        pageSize: this.pagingInfo.pageSize,
      },
    } as ToolAssignHistoryRequestModel;
    return query;
  }
  onPageChange(paginationInfo: BasePagingModel) {
    this.pagingInfo.pageSize = paginationInfo.pageSize;
    this.getToolHistory(paginationInfo.pageNumber);
  }
}
