import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from "../../services/modal.service";
import { UserService } from "../../services/user.service";
import { finalize } from "rxjs/operators";
import { FormType } from "../../enums/form-type";
import { SupplierPermitsService } from "../../services/supplier-permits.service";
import { PermitsInfo, PermitViewModel } from "../../models/form/permits-info-model";
import { FormDataStatus } from "../../enums/form-data-status";
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'obc-anonymous-permit',
  templateUrl: './anonymous-permit.component.html',
  styleUrls: ['./anonymous-permit.component.scss']
})
export class AnonymousPermitComponent implements OnInit {

  @Input() siteSupplierId: number;
  @Input() siteId: number;
  @Input() siteSupplierHashLink: string;

  inProgress = false;
  permitsInfo: PermitsInfo;
  permitsList: PermitViewModel[] = [];
  selectedFormDataId: number;
  FormDataStatus = FormDataStatus;
  FormType = FormType;
  modalRef: any;

  constructor(public supplierPermitService: SupplierPermitsService,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    public userService: UserService,
  ) {
  }

  get isSupplierUser() {
    return this.siteSupplierHashLink?.length > 0;
  }

  ngOnInit() {
    this.fetchPermitList();
  }
  fetchPermitList() {
    this.inProgress = true;
    let siteSupplierId = this.siteSupplierHashLink ? null : this.siteSupplierId;
    this.supplierPermitService.getPermitList(this.siteSupplierHashLink, siteSupplierId)
      .pipe(finalize(() => {
        this.inProgress = false
      }))
      .subscribe(res => {
        if (res) {
          this.permitsInfo = res.permitsInfo;
          this.permitsList = [...this.permitsInfo.permits, ...this.permitsInfo.pendingPermits];
        } else
          this.modalService.error('Error in fetching permit forms')
      }, err => {
        this.modalService.error(err)
      })
  }

  onApplyNewPermit(template: any) {
    this.modalService.show(template, "modal-lg")
  }

  onHidePopup() {
    this.modalService.hide();
  }

  onViewFormDetails(template, formDataId: number) {
    this.selectedFormDataId = formDataId;
    this.modalRef = this.bsModalService.show(template, { class: "modal-lg" });
  }

  onApproveOrRejectPermit() {
    this.fetchPermitList();
    this.modalRef.hide();
  }
}
