<div class="card" style="border:none">
    <div class="card-body" style="padding: 0;">
        <div class="row">
            <div class="col-md-12">
                <tabset>
                    <tab heading="Company Hosts" *ngIf="isCompanyHost"
                        (selectTab)="setTabAsShown(PermissionTab.CompanyHost)">
                        <obc-company-user *ngIf="displayTab(PermissionTab.CompanyHost)"></obc-company-user>
                    </tab>
                    <tab heading="Site Hosts" *ngIf="isSiteHost" (selectTab)="setTabAsShown(PermissionTab.SiteHost)">
                        <obc-site-user *ngIf="displayTab(PermissionTab.SiteHost)"></obc-site-user>
                    </tab>
                    <tab heading="Frequent Users" *ngIf="isCompanyAdmin"
                        (selectTab)="setTabAsShown(PermissionTab.Frequents)">
                        <obc-site-attendance-permission *ngIf="displayTab(PermissionTab.Frequents)">
                        </obc-site-attendance-permission>
                    </tab>
                    <tab heading="Company Employees" *ngIf="isCompanyAdmin"
                        (selectTab)="setTabAsShown(PermissionTab.Employees)">
                        <obc-company-employee *ngIf="displayTab(PermissionTab.Employees)"></obc-company-employee>
                    </tab>
                    <tab heading="Temporary Invites" *ngIf="isCompanyAdmin"
                        (selectTab)="setTabAsShown(PermissionTab.Temporarily)" >
                        <obc-manage-site-attendance-permission [isTemporayInvite]="true" 
                            *ngIf="displayTab(PermissionTab.Temporarily)">
                        </obc-manage-site-attendance-permission>
                    </tab>
                    <tab heading="Blocked Users" *ngIf="isCompanyAdmin"
                        (selectTab)="setTabAsShown(PermissionTab.Blocked)">
                        <obc-site-attendance-blocked-user *ngIf="displayTab(PermissionTab.Blocked)">
                        </obc-site-attendance-blocked-user>
                    </tab>
                    <tab heading="Tagged Users" *ngIf="showTagedList"
                        (selectTab)="setTabAsShown(PermissionTab.TaggedList)">
                        <obc-flagged-user *ngIf="displayTab(PermissionTab.TaggedList)"></obc-flagged-user>
                    </tab>
                </tabset>
            </div>
        </div>
    </div>
</div>