import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FileType } from 'src/app/enums/file-type.enum';
import { AnnouncementBaseViewModel } from 'src/app/models/announcement-viewmodel';

@Component({
  selector: 'obc-announcement-renderer',
  templateUrl: './announcement-renderer.component.html',
  styleUrls: ['./announcement-renderer.component.scss']
})
export class AnnouncementRendererComponent implements OnInit {
  _announcements = new BehaviorSubject<AnnouncementBaseViewModel[]>([]);
  AnnouncementType = FileType;
  @Input() set announcements(value: AnnouncementBaseViewModel[]) {
    this._announcements.next(value);
  }
  @Input() largeButton = true;
  @Input() isSupplierAnnouncement: boolean = false;
  @Input() notifyVisits = true;
  @Input() anonymousAttendanceKey: string = null;
  constructor(
  ) { }

  ngOnInit() {
  }


}
