import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SiteService } from 'src/app/services/site.service';
import { AnonymousCheckInSiteViewModel } from 'src/app/models/site-viewmodel';
import { CheckInMode } from 'src/app/enums/checkin-mode.enum';


@Component({
  selector: 'obc-anonymous-checkin',
  templateUrl: './anonymous-checkin.component.html',
  styleUrls: ['./anonymous-checkin.component.scss']
})
export class AnonymousCheckinComponent implements OnInit {

  CheckInMode = CheckInMode;

  constructor(private route: ActivatedRoute,
    private siteService: SiteService) { }

  site: AnonymousCheckInSiteViewModel;
  sessionId: string;
  inProgress: boolean = false;
  initErrorMessage: string = null;

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.sessionId = params['session-id'];
      this.inProgress = true;
      this.siteService.getSiteBySession(this.sessionId)
        .pipe(finalize(() => { this.inProgress = false; }))
        .subscribe(res => {
          this.site = res ?? {};
        }, err => {
          this.initErrorMessage = err;
        });
    });
  }

}
