import { Pipe, PipeTransform } from '@angular/core';
import { FormApprovalMode } from "../enums/form-approval-mode";

@Pipe({
  name: 'formApprovalMode'
})
export class FormApprovalModePipe implements PipeTransform {

  transform(value: unknown): string {
    if (value === FormApprovalMode.AutoApprove) return "Auto Approve";
    if (value === FormApprovalMode.HostApprove) return "Host Approve";
    return value?.toString();
  }

}
