import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { copyToClipboard } from 'src/app/helpers/general-functions';

@Component({
  selector: 'app-clipboard-box',
  templateUrl: './clipboard-box.component.html',
  styleUrls: ['./clipboard-box.component.scss']
})
export class ClipboardBoxComponent implements OnInit {
  @Input() text = null;
  @Input() label = null;
  @Output() copied = new EventEmitter();
  showCopiedMessage = false;
  constructor() { }

  ngOnInit() {
  }
  inputId = 'clipboard-input';
  onCopy() {
    copyToClipboard(this.inputId);
    this.showCopiedMessage = true;
    setTimeout(()=>{
      this.copied.emit();
    }, 1000)
  }
}
