import { Pipe, PipeTransform } from '@angular/core';
import { SiteAssetQueryMode } from '../enums/site-asset-query-mode.enum';

@Pipe({
  name: 'siteAssetQueryMode'
})
export class SiteAssetQueryModePipe implements PipeTransform {

  transform(value: any): string {
    if (value == SiteAssetQueryMode.CheckedIn || value == 0 || value == "CheckedIn") return 'Checked In';
    if (value == SiteAssetQueryMode.CheckedOut || value == 1 || value == "CheckedOut") return 'Checked Out';
    if (value == SiteAssetQueryMode.AllApproved || value == 2 || value == "AllApproved") return 'All Approved';
    if (value == SiteAssetQueryMode.PendingApproval || value == 3 || value == "PendingApproval") return 'Pending Approval';
    if (value == SiteAssetQueryMode.RejectedApproval || value == 4 || value == "RejectedApproval") return 'Rejected Approval';
    return value?.toString() ?? '?';
  }

}
