<div *ngIf="manageSiteModel?.siteVisitorTypes && manageSiteModel?.siteVisitorTypes?.length" class="card"
  style="background-color: transparent; padding: 0.25rem; padding-left: 1rem;">
  <obc-loader [show]="inprogress"></obc-loader>
  <div class="row">
    <div class="col-md-12">
      <p style="line-height: 42px; margin-bottom: 0;">Ask based on visitor type? <obc-switch falseLabel="No"
          trueLabel="Yes" [value]="limited" (switchChanged)="onVisitorTypeStatusChanged($event)"></obc-switch>
      </p>
      <div *ngIf="_limitedPrivate">
        <div class="btn-group1">
          <label (click)="onItemClick(getCheckedType(visitorType))"
            *ngFor="let visitorType of manageSiteModel?.siteVisitorTypes; let x = index"
            class="btn btn-primary ml-1 mb-1" [ngModel]="getCheckedType(visitorType).checked" btnCheckbox tabindex="0"
            role="button">{{visitorType.visitorTypeTitle}}</label>
        </div>
      </div>
    </div>
  </div>
</div>
