import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SiteBasicInfo } from 'src/app/models/site-basicinfo';
import { SiteAttendanceService } from 'src/app/services/site-attendance.service';
import { ModalService } from '../../services/modal.service';
import { AsyncResult } from '../../models/helper-models';
import { QuestionAnswerInputModel } from '../../models/question-answer-inputmodel';
import { ExportAsConfig, FieldType, ExportAsService } from 'src/app/services/export-as.service';
import { ExportAsFileType } from 'src/app/enums/export-as-file-type';
import { UserService } from 'src/app/services/user.service';
import { SiteAttendanceReportViewModel } from 'src/app/models/site-report-models';

@Component({
  selector: 'obc-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.css']
})
export class UserActivityComponent implements OnInit {
  @Output() public result = new EventEmitter<AsyncResult>();
  inProgress: boolean = false;
  selectedModel: SiteAttendanceReportViewModel;
  @Input() set siteAttendance(model: SiteAttendanceReportViewModel) {
    this.selectedModel = model;
    if (this.selectedModel == null) return;
    this.inProgress = true;
    this.siteAttendanceService.getAttendanceContactTracing(model.siteAttendanceId).subscribe(
      res => {
        this.report = res;
      }, err => {
        console.log(err);
      }, () => {
        this.inProgress = false;
      }
    );
  };
  sites: SiteBasicInfo[];
  selectedSite: SiteBasicInfo;
  report: SiteAttendanceReportViewModel[] = null;
  searchQueryForm: FormGroup = null;
  questionAnswerInputModel: QuestionAnswerInputModel = new QuestionAnswerInputModel();
  pageInfo = `Identification of user who may have come into contact with another user. By Entering a
  particular user’s phone number, you will be able to see who they were in contact with
  between a date and time rage.`;
  filterListTerm: string;
  constructor(private siteAttendanceService: SiteAttendanceService,
    private modalService: ModalService,
    public userService: UserService,
    private exportAsService: ExportAsService
  ) { }

  ngOnInit() {

  }

  openModal(template, siteAttendanceId: number, isCheckInAnswer: boolean, hasCheckoutDate: boolean) {
    this.questionAnswerInputModel = { isCheckInAnswer: isCheckInAnswer, siteAttendanceId: siteAttendanceId, hasCheckOutDate: hasCheckoutDate };
    this.modalService.show(template, "modal-lg");
  }

  closeModal(result: AsyncResult) {
    this.modalService.hide();
  }

  //exportAsCSV() {
  //  let config: ExportAsConfig = {
  //    fileType: ExportAsFileType.CSV,
  //    preFileName: 'attendance-report',
  //    fields: [
  //      { fieldName: 'firstName', title: 'First Name' },
  //      { fieldName: 'lastName', title: 'Last Name' },
  //      { fieldName: 'mobile', title: 'Mobile', type: FieldType.Mobile },
  //      { fieldName: 'site', title: 'Site Name' },
  //      { fieldName: 'siteReference', title: 'Site Reference' },
  //      { fieldName: 'checkInDate', title: 'Check in Date', type: FieldType.DateTime },
  //    ],
  //  }
  //  this.exportAsService.exportAs(this.report, config);
  //}
  exportAsCSV() {
    var query = {
      siteAttendanceId: this.selectedModel.siteAttendanceId
    };
    this.siteAttendanceService.exportContactsReportAsCsv(this.selectedModel.siteAttendanceId);
  }
}
