<div class="row">
  <div class="col-md-12">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="card">
      <div class="card-body">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-bordered table-striped mt-3">
              <thead>
                <tr>
                  <td>Supplier
                    <button *ngIf="onlyActiveSites" class="btn btn-success pull-right"
                      (click)="onAddSupplier(upsertSupplier)">
                      Add Supplier
                    </button>
                  </td>
                  <td>Business Info</td>
                  <td>Link to Company</td>
                  <td class="text-right">
                    <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [value]="onlyActiveSites"
                      (valueChanged)="onActiveChanged($event)">
                    </obc-switch>
                    <button class="btn btn-success pull-right" (click)="exportCSVReport()">
                      Export as CSV
                    </button>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="100">
                    <input type="text" class="form-control" placeholder="Search by name and business"
                      [(ngModel)]="supplierFilter">
                  </td>
                </tr>
                <tr *ngFor="let supplier of filteredSuppliers">
                  <td>
                    <b>{{supplier.name}}</b>
                    <button class="btn btn-primary btn-sm pull-right" *ngIf="onlyActiveSites"
                      (click)="onEditSupplier(upsertSupplier, supplier)">
                      <i class="fas fa-edit"></i>
                    </button>
                    <br />
                    <span style="color: dimgray;">
                      {{supplier.email}}
                      <br
                        *ngIf="supplier.email != null && supplier.mobile != null && supplier.email !== '' && supplier.mobile !== ''" />
                      {{supplier.mobile}}
                      <br
                        *ngIf="supplier.email != null && supplier.contactName!=null && supplier.email !== '' && supplier.contactName !== ''" />
                      {{supplier.contactName}}
                    </span>
                  </td>
                  <td>
                    <button *ngIf="onlyActiveSites" class="btn btn-primary btn-sm pull-right"
                      routerLink="/supplier/{{supplier.id}}/edit">
                      <i class="fas fa-edit"></i>
                    </button>
                    <ul>
                      <li *ngFor="let biz of supplier.companySupplierBusinesses">
                        <strong>{{ biz.name }} </strong>
                        <span class="mr-1" *ngIf="biz.licenseNumber || biz.abn || biz.acn">
                          (<span *ngIf="biz.abn">Abn: {{ biz.abn }}</span>
                          <span class="mr-1" *ngIf="biz.abn && biz.acn">,</span>
                          <span *ngIf="biz.acn">Acn: {{ biz.acn }}</span>
                          <span class="mr-1" *ngIf="biz.licenseNumber && (biz.abn || biz.acn)">,</span>
                          <span *ngIf="biz.licenseNumber">License Number: {{ biz.licenseNumber }}</span>)
                        </span>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <button
                      *ngIf="supplier?.linkToCompanyCurrentStatus == LinkToCompanyStatus.NotLinked && onlyActiveSites"
                      class="btn btn-info pull-right"
                      (click)="openInvitationModal(sendInvitationToCompanyBlock, supplier)">
                      Link
                    </button>
                    <div *ngIf="supplier?.linkToCompanyCurrentStatus == LinkToCompanyStatus.Linked">
                      Linked to <strong style="color: #00a3f5">{{ supplier.linkToCompanyTitle }}</strong>
                      <div>
                        <button
                          *ngIf="supplier?.linkToCompanyCurrentStatus == LinkToCompanyStatus.Linked && onlyActiveSites"
                          class="btn btn-danger pull-right" (click)="unlinkSupplierFromCompany(supplier)">
                          Unlink
                        </button>
                      </div>
                    </div>
                    <div *ngIf="supplier?.linkToCompanyCurrentStatus == LinkToCompanyStatus.Pending">
                      <div>Status: <strong class="text-warning">Pending</strong></div>
                      <div>Email: <strong class="text-success">{{ supplier.linkToCompanyEmail }}</strong></div>
                      <div>Mobile: <strong class="text-success">{{ supplier.linkToCompanyMobile }}</strong></div>
                      <button class="btn btn-danger ml-1 pull-right" (click)="cancelSupplierLinking(supplier)"
                        *ngIf="onlyActiveSites">
                        Cancel
                      </button>
                      <button class="btn btn-warning pull-right" (click)="resendSupplierEmail(supplier)"
                        *ngIf="onlyActiveSites">
                        Resend
                      </button>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-row flex-row-reverse">
                      <button class="btn btn-danger" (click)="deleteSupplier(supplier)" *ngIf="onlyActiveSites">
                        Deactivate
                      </button>
                      <button class="btn btn-info mr-1" routerLink="/supplier-documents/{{supplier.id}}"
                        *ngIf="onlyActiveSites">
                        Site Documents
                      </button>
                      <button class="btn btn-success mr-1" routerLink="/supplier-forms/{{supplier.id}}"
                        *ngIf="onlyActiveSites">
                        Forms
                      </button>
                      <button class="btn btn-warning mr-1" (click)="activateSupplier(supplier)"
                        *ngIf="!onlyActiveSites">
                        Activate
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #upsertSupplier>
  <div class="card">
    <div class="card-header">
      <span>
        Add/Update Supplier
      </span>
      <button type="button" class="close" (click)="bsModalHandle.hide();">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="row" [formGroup]="formGroup">
        <div class="form-group col-md-12">
          <label>Supplier Name: </label>
          <input class="form-control" formControlName="name">
        </div>
        <div class="form-group col-md-12">
          <label>Email: </label>
          <input class="form-control" formControlName="email">
        </div>
        <div class="form-group col-md-12">
          <label>Phone: </label>
          <input class="form-control" formControlName="mobile">
        </div>
        <div class="form-group col-md-12">
          <label>Contact Name: </label>
          <input class="form-control" formControlName="contactName">
        </div>
        <input hidden formControlName="id">
      </div>
      <div class="pt-1 text-right">
        <hr />
        <button class="btn btn-primary" [disabled]="!formGroup.valid"
          (click)="formGroup.controls.id.value ? updateSupplier(): addSupplier()">
          {{formGroup.controls.id.value ? "Update": "Add"}}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sendInvitationToCompanyBlock>
  <div class="card">
    <div class="card-header">
      <span>
        Send Invitation To <strong>{{selectedSupplier.name}}</strong>
      </span>
      <button type="button" class="close" (click)="bsModalHandle.hide();">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="alert alert-info">Please Enter the contact details for <strong>{{selectedSupplier.name}}</strong>.
        <br>
        These details will be used to verify their account.
      </div>

      <input class="form-control" required type="email" placeholder="Supplier Email Address"
        [formControl]="invitationEmail">
      <input class="form-control mt-1" required type="tel" placeholder="Supplier Mobile"
        [formControl]="invitationMobile">

      <div class="pt-1 text-right">
        <hr />
        <button class="btn btn-primary" [disabled]="!validateInvitationFields()" (click)="sendInvitationToCompany()">
          Send
        </button>
      </div>
    </div>
  </div>
</ng-template>