<div class="card">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="card-body" style="padding: 0;">
        <div>
            <tabset>
                <tab heading="Personal" (selectTab)="isPersonalMode = true">
                    <template [ngTemplateOutlet]="settingsTemplate"></template>
                </tab>
                <tab heading="{{currentCompanyUserNotificationSettings.title}}"
                    *ngIf="currentCompanyUserNotificationSettings" (selectTab)="isPersonalMode = false">
                    <template [ngTemplateOutlet]="settingsTemplate"></template>
                </tab>
            </tabset>
        </div>
    </div>
</div>

<ng-template #settingsTemplate>
    <div *ngIf="currentSetting">
        <template [ngTemplateOutlet]="saveButton"></template>
        <div
            *ngFor="let g of currentSetting.items | groupBy:'groupTitle' | orderByFuction:getOrderOfGroupedNotificationItems">
            <div class="card notification-group-container">
                <div class="card-header">
                    <template
                        *ngTemplateOutlet="settingTemplate; context: { $implicit: getAggregatedSetting(g.key,g.value) }"></template>

                </div>
                <div class="card-body1">

                    <accordion *ngIf="currentSetting">
                        <accordion-group [panelClass]="'panel-heading-hide'" [isOpen]="advancedIsOpen[g.key]">
                            <div accordion-heading></div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12" *ngFor="let s of g.value">
                                    <template *ngTemplateOutlet="settingTemplate; context: { $implicit: s }"></template>
                                </div>
                            </div>
                        </accordion-group>
                    </accordion>
                </div>
            </div>
        </div>
        <template [ngTemplateOutlet]="saveButton"></template>
    </div>
</ng-template>

<ng-template #saveButton>
    <div class="row">
        <div class="col-md-2 offset-md-10">
            <button type="button" class="btn btn-block btn-success pull-right mt-1 mb-1" (click)="save()">Save</button>
        </div>
    </div>
</ng-template>

<ng-template #settingTemplate let-setting>
    <div class="row setting-container {{setting.isAggregated?'aggregated':''}}">
        <div class="col-md-4">
            <label><strong>{{setting.title}}</strong></label>
            <button type="button" *ngIf="setting.isAggregated" class="ml-2 btn btn-link"
                (click)="setAdvancedIsOpen(setting.title)">Advanced</button>
        </div>
        <div class="col-md-2">
            <span class="pull-right">
                <obc-switch [trueLabel]="' Enable '" [falseLabel]="' Disable '" [value]="setting.status.isEnabled"
                    [semiActive]="setting.status.isEnabledPartially"
                    (switchChanged)="onSettingEnabledChanged(setting,$event)">
                </obc-switch>
            </span>
        </div>
        <div class="col-md-6">
            <span *ngIf="setting.status.isEnabled">
                <div class="pull-right ml-2">
                    <label>Push Notification</label>
                    <obc-switch [trueLabel]="' Yes '" [falseLabel]="' No '"
                        [value]="setting.status.isPushNotificationEnabled" [disabled]="!setting.status.isEnabled"
                        (switchChanged)="onSettingWayEnabledChanged(setting,$event,false)"
                        [semiActive]="setting.status.isPushNotificationEnabledPartially">
                    </obc-switch>
                </div>
                <div class="pull-right">
                    <label>Email</label>
                    <obc-switch [trueLabel]="' Yes '" [falseLabel]="' No '" [value]="setting.status.isEmailEnabled"
                        [disabled]="!setting.status.isEnabled"
                        (switchChanged)="onSettingWayEnabledChanged(setting,$event,true)"
                        [semiActive]="setting.status.isEmailEnabledPartially">
                    </obc-switch>
                </div>
            </span>
        </div>
    </div>
</ng-template>