import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isValidCSVFile, isValidImageFile, isValidPDFFile } from 'src/app/helpers/general-functions';
import { ModalService } from 'src/app/services/modal.service';
import { FileUploadAcceptType } from "../../enums/file-upload-accept-type";

@Component({
  selector: 'obc-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true,
    }
  ]
})

export class FileUploadComponent implements ControlValueAccessor {
  onChange: Function;

  private file: File | null = null;
  @HostListener('change', ['$event.target.files']) async emitFiles(event: FileList) {
    let localFile = event && event.item(0);

    switch(this.accept) {
      case FileUploadAcceptType.Image:
        if(!isValidImageFile(localFile.type))
        {
          this.modalService.error("The selected file is not a valid type of Image");
          this.onClear(this.input);
          return;
        }
        break;
      case FileUploadAcceptType.CSV:
        if(!isValidCSVFile(localFile.type))
        {
          this.modalService.error("The selected file is not a valid type of CSV");
          this.onClear(this.input);
          return;
        }
        break;
      case FileUploadAcceptType.PDF:
        if(!isValidPDFFile(localFile.type))
        {
          this.modalService.error("The selected file is not a valid type of PDF");
          this.onClear(this.input);          
          return;
        }
        break;
    }

    if(this.maxSize && localFile.size > this.maxSize)
      {
        this.modalService.error("Maximum Size of File Exceed");
        return;
      }
    this.file = localFile;
    this.onChange(localFile);
  }

  getAccept() {
    switch(this.accept) {
      case FileUploadAcceptType.Image:
        return '.png, .jpg, .jpeg';
      case FileUploadAcceptType.CSV:
        return 'text/csv';
      case FileUploadAcceptType.PDF:
        return 'application/pdf';
    }
    return '*/*';
  }

  @Input() disabled: boolean = false;
  @Input() showClearButton: boolean = false;
  @Input() accept: FileUploadAcceptType = FileUploadAcceptType.Image;
  @Input() maxSize: number = null;
  @Output() clear = new EventEmitter();
  constructor(private host: ElementRef<HTMLInputElement>, private modalService: ModalService) {
  }
  writeValue(obj: any): void {
    this.host.nativeElement.value = obj;
    if (this.input)
    {
        this.input.value = obj;
        if(obj == null)
        this.input.nativeElement.value = "";
    }
    this.file = obj;
  }

  @ViewChild('input') input;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }

  onClear(element) {
    element.value = null;
    this.clear.emit();
  }
}
