import { MaxFileSize } from './../../enums/constants';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { LayoutService } from 'src/app/services/layout.service';
import { getFileExtenstion, isValidImageFile, toBase64 } from 'src/app/helpers/general-functions';
import { RequestUserProfileUpdateViewmodel } from 'src/app/models/user-profile-viewmodel';
import { IndustryTypeService } from '../../services/industry-type-service';
import { IndustryTypeViewModel } from '../../models/industry-type/industry-type-view-model';
import { noop, Observable, Observer, of } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { UserBusinessViewModel } from '../../models/user-business-viewmodel';
import { UserBusinessService } from '../../services/user-business-service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api';
import { ModalService } from 'src/app/services/modal.service';
import * as moment from 'moment';
import { dateFormat } from 'src/app/helpers/date-time-format';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { EmergencyContactEmitResponse } from "../../models/emergency-contact-emit-response";
import { EmergencyContactViewModel } from 'src/app/models/emergency-contact-view-model';
import { TwoFactorVerificationMode } from '../../enums/two-factor-verification-mode';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TotpVerifyViewModel } from 'src/app/models/totp-verify-view-model';

@Component({
  selector: 'obc-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  DatetimePickerMode = DatetimePickerMode;
  profileFormGroup: FormGroup;
  profileInProccess: boolean = false;
  croppedImage: string;
  imageSourceBase64: string;
  fileType: string;
  industryTypeList: IndustryTypeViewModel[];
  suggestions$: Observable<UserBusinessViewModel[]>;
  userBusinesses: UserBusinessViewModel[] = [];
  selectedSuggestion: string = '';
  isEmailVerified: boolean = false;
  imageViewMode: ('view' | 'edit') = 'view';
  isEmergencyContactValid: boolean = true;
  isActive2fa: boolean = false;
  twoFactorVerificationModeEnum = TwoFactorVerificationMode;
  currentTwoFactorMode?: TwoFactorVerificationMode;
  verifyCode: string;

  constructor(
    private layoutService: LayoutService,
    public userService: UserService,
    public bsModalService: BsModalService,
    public modalService: ModalService,
    private industryTypeService: IndustryTypeService,
    private userBusinessService: UserBusinessService) {
  }

  ngOnInit() {
    this.layoutService.header = "User Profile";
    this.profileInProccess = true;

    this.userService
      .userProfile()
      .subscribe(res => {
        this.profileFormGroup = new FormGroup({
          firstName: new FormControl(res.firstName, [Validators.required]),
          lastName: new FormControl(res.lastName, [Validators.required]),
          email: new FormControl(res.email, [Validators.email]),
          birthDay: new FormControl(res.birthDay != null ? new Date(res.birthDay) : null),
          gender: new FormControl(res.gender, null),
          industryTypeId: new FormControl(res.industryTypeId, null),
          profileImageBase64: new FormControl(res.profileImage, null),
          userBusiness: new FormControl('', null),
          emergencyContact: new FormControl(res.emergencyContactAsJson, null),
          twoFactorVerificationMode: new FormControl(res.twoFactorVerificationMode, null),
        });

        this.initEmailViewMode();
        this.isEmailVerified = res.isEmailVerified;
        this.profileInProccess = false;
        this.userBusinesses = res.userBusinesses;
        this.isActive2fa = res.isActive2fa;
        this.currentTwoFactorMode = res.twoFactorVerificationMode;
        this.hasTotpSecretKey = this.useCurrentTotpSecretKey = res.hasTotpSecretKey;
      });
    this.industryTypeService.getActiveIndustryTypes()
      .subscribe(res => this.industryTypeList = res);
    this.lookUpBusinessList();
  }

  get imageSource(): string {
    if (this.profileFormGroup === null || this.profileFormGroup.get("profileImageBase64").value == null)
      return null;
    let src = this.profileFormGroup.get("profileImageBase64").value as string;
    if (src.startsWith("http://") || src.startsWith("https://"))
      return src;
    else
      return `data:image/png;base64,${src}`
  }

  async onPickImage($event) {
    let file = $event.target.files[0];
    if (!isValidImageFile(file.type)) {
      this.modalService.error("The selected file must be a valid type of image").subscribe(_ => { });
      $event.target.value = null;
      return;
    }
    if (file.size > MaxFileSize * 1024000) {
      this.modalService.error(`Maximum File Size is ${MaxFileSize}mb`, "").subscribe(_ => { });
      $event.target.value = null;
      return;
    }
    this.imageSourceBase64 = (await toBase64(file)) as string;
    this.fileType = getFileExtenstion(file.name);
    this.imageViewMode = 'edit';
  }

  onDiscardImageChanges() {
    this.imageViewMode = 'view';
    this.croppedImage = null;
  }

  onSaveProfileChanges() {
    this.profileInProccess = true;
    let source = null;
    if (this.croppedImage != null) {
      source = this.croppedImage;
    }
    let industryStr = this.profileFormGroup.get("industryTypeId").value;
    let industryId = !industryStr || industryStr == '' ? null : parseInt(industryStr);
    let birthday = this.profileFormGroup.get("birthDay").value;
    let model = {
      firstName: this.profileFormGroup.get("firstName").value,
      lastName: this.profileFormGroup.get("lastName").value,
      email: this.profileFormGroup.get("email").value,
      //Actually birthday is datetime, but to remove timezone we set it to any and use below code
      //moment date without timezone:moment().utcOffset(0, true).format()
      birthDay: birthday == null ? null : moment(birthday, dateFormat).utcOffset(0, true).format(),
      gender: parseInt(this.profileFormGroup.get("gender").value),
      industryTypeId: industryId,
      mobile: '',
      profileImageBase64: source,
      userBusinesses: this.userBusinesses,
      fileType: this.fileType,
      emergencyContactAsJson: JSON.stringify(this.emergencyContact),
      twoFactorVerificationMode: this.profileFormGroup.get("twoFactorVerificationMode").value
    } as RequestUserProfileUpdateViewmodel;

    this.userService.editProfile(model)
      .subscribe({
        next: res => {
          this.userService.initUserInfo();
          this.profileInProccess = false;
          this.initEmailViewMode();
          if (res.isVerificationEmailSent)
            this.modalService.info("Verification email sent to your email address");
          this.isEmailVerified = res.isEmailVerified;
          this.imageViewMode = 'view';
          if (source != null) {
            this.profileFormGroup.get("profileImageBase64").setValue(`${source}`);
            //this.imageSourceBase64 = `data:image/png;base64,${source}`;
          }
          this.currentTwoFactorMode = model.twoFactorVerificationMode;
          this.modalService.success("Successfully saved");
        },
        error: (err) => {
          this.profileInProccess = false;
        },
        complete: () => {
          this.profileInProccess = false;
        }
      });
  }

  imageCropped($event) {
    let base64 = $event.base64.split(';base64,')[1];
    this.croppedImage = base64;
  }

  loadImageFailed() {
    this.modalService.error("unable to load image", "").subscribe(res => {
      //alert("unable to load image");
    });
  }

  onRemoveProfilePicture() {
    let header = "Remove Profile Picture"
    this.modalService.confirm("Your image will be completely removed from the server. Are you sure?", header).subscribe(result => {
      if (result) {
        this.profileInProccess = true;
        this.userService.removeProfilePicture().subscribe(res => {
          this.profileInProccess = false;
          this.profileFormGroup.get("profileImageBase64").setValue(null);
          this.croppedImage = null;
        });
      }
    })
  }

  loader: boolean = false;

  lookUpBusinessList() {
    this.suggestions$ = new Observable((observer: Observer<string>) => {
      observer.next(this.profileFormGroup.get("userBusiness").value);
    }).pipe(
      switchMap((nameOrAbnOrAcn: string) => {
        if (nameOrAbnOrAcn) {
          this.loader = true;
          return this.userBusinessService.getAddressSuggestionList(nameOrAbnOrAcn).pipe(
            map((data: UserBusinessViewModel[]) => (data && data) || []),
            tap(
              () => noop,
              (_) => {
                // var rrr = (err && err.message) || 'Something goes wrong';
              }
            ),
            finalize(() => {
              this.loader = false;
            })
          );
        }

        return of([]);
      })
    );
  }

  onSelectBusiness(event: TypeaheadMatch): void {
    if (event.item) {
      this.userBusinesses.push(event.item);
      this.profileFormGroup.get("userBusiness").setValue('');
    }
  }

  onAddBusiness() {
    let newItem = this.profileFormGroup.get("userBusiness").value;
    if (newItem === null || newItem.match(/^ *$/) !== null || (newItem as string).length <= 1)
      return;
    let typedBusiness = this.profileFormGroup.get("userBusiness").value as string;
    let newItemModel = <UserBusinessViewModel>({ name: typedBusiness });
    this.userBusinesses.push(newItemModel);
    this.profileFormGroup.get("userBusiness").setValue('');
    this.loader = false;
  }

  onDelete(record: UserBusinessViewModel) {
    this.userBusinesses = this.userBusinesses.filter(obj => obj !== record);
  }

  onSendVerificationEmail() {
    this.userService.sendVerificationEmail().subscribe(res => {
      this.modalService.info("Verification email sent successfully", "").subscribe(res => {
        //alert("verification email sent successfully");
      });
    });
  }

  initEmailViewMode() {
    if (this.profileFormGroup)
      this.emailViewMode = this.profileFormGroup.get('email').value && this.profileFormGroup.get('email').valid ? 'view' : 'edit';
  }

  emailViewMode: ('view' | 'edit') = 'view';


  emergencyContact: EmergencyContactViewModel;
  onUpdateEmergencyContact(response: EmergencyContactEmitResponse) {
    this.isEmergencyContactValid = response.isValid;
    this.emergencyContact = response.value;
    if (this.emergencyContact == null) {
      this.emergencyContact = EmergencyContactViewModel.newEmptyAnswer();
    }
  }

  secretKey: string;
  manualEntryKey: string;
  qrCode: string;

  isShowQr() {
    return this.isActive2fa && this.profileFormGroup.get("twoFactorVerificationMode").value == TwoFactorVerificationMode.Totp;
  }

  qrCodeModalref: any;
  showQrCodeModal(template) {
    if (this.isActive2fa && this.profileFormGroup.get("twoFactorVerificationMode").value == TwoFactorVerificationMode.Totp && this.currentTwoFactorMode != TwoFactorVerificationMode.Totp) {
      this.qrCodeModalref = this.bsModalService.show(template, {
        class: "modal-lg",
        ignoreBackdropClick: true,
      });
      this.userService.totpGenerateQrCode().subscribe(
        (res) => {
          this.manualEntryKey = res.manualEntryKey;
          this.qrCode = res.qrCodeSetupImageUrl;
          this.secretKey = res.secretKey;
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  totpVerifyCode() {
    var model: TotpVerifyViewModel;
    if (this.useCurrentTotpSecretKey) {
      model = {
        userCode: this.verifyCode,
        secretKey: this.secretKey,
        useCurrentSecretKey: true
      }
    }
    else {
      if (this.verifyCode != "" && this.verifyCode != undefined) {
        model = {
          userCode: this.verifyCode,
          secretKey: this.secretKey,
          useCurrentSecretKey: false
        }
      }
    }
    this.userService.totpVerifyCode(model).subscribe(res => {
      if (!res) {
        this.modalService.error("Verification failed");
      }
      else {
        this.modalService.success("Successfully validated");
        this.qrCodeModalref.hide();
      }
    });
  }

  closeQr() {
    this.profileFormGroup.get("twoFactorVerificationMode").setValue(this.currentTwoFactorMode);
    this.qrCodeModalref.hide();
  }

  hasTotpSecretKey: boolean;
  useCurrentTotpSecretKey: boolean = true;
  setUseAuthentication(event) {
    this.useCurrentTotpSecretKey = event;
  }
}
