import { Pipe, PipeTransform } from '@angular/core';
import { QrCodeCheckinMode } from '../enums/qr-code-checkin-mode';

@Pipe({
  name: 'qRCodeCheckinMode'
})
export class QRCodeCheckinModePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === QrCodeCheckinMode.Disable) return "Disable"
    else if (value === QrCodeCheckinMode.Anonymous) return "Quick (Without Login)"
    else if (value === QrCodeCheckinMode.AnonymousFlutterWeb) return "Full (With Login)"
    else return "All";
  }

}
