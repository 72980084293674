import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UserBusinessComponent } from 'src/app/custom-controls/user-business/user-business.component';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { getFileExtenstion, toBase64 } from 'src/app/helpers/general-functions';
import { CompanySupplierViewModel, CompanySupplierQuery } from 'src/app/models/supplier-models';
import { CompanySupplierBusinessModel, CompanySupplierBusinessViewModel, CompanySupplierDocumentViewModel, UserBusinessViewModel } from 'src/app/models/user-business-viewmodel';
import { UserDocumentType } from 'src/app/models/user-document-type';
import { CompanySupplierService } from 'src/app/services/company-supplier.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ModalService } from 'src/app/services/modal.service';
import { UserDocumentTypeService } from 'src/app/services/user-document-type.service';

@Component({
  selector: 'obc-edit-company-supplier',
  templateUrl: './edit-company-supplier.component.html',
  styleUrls: ['./edit-company-supplier.component.scss']
})
export class EditCompanySupplierComponent implements OnInit {
  datetimePickerMode = DatetimePickerMode;
  selectedTab = new BehaviorSubject<"business" | "document">("document");
  selectedBusiness: UserBusinessViewModel;
  license: string;
  get supplierId(): number {
    return this.formControl.value;
  };
  suppliers = new BehaviorSubject<CompanySupplierViewModel[]>([]);
  businesses = new BehaviorSubject<CompanySupplierBusinessViewModel[]>([]);
  documentTypes = new BehaviorSubject<UserDocumentType[]>([]);
  documents = new BehaviorSubject<CompanySupplierDocumentViewModel[]>([]);
  formControl = new FormControl();
  inProgress = false;
  @ViewChild('lookupUserBusiness') lookupUserBusiness: UserBusinessComponent;

  canAddBusiness(): boolean { return (this.selectedBusiness?.name?.length > 0 || this.license?.length > 0); }

  documentFormGroup = new FormGroup({
    title: new FormControl(null, []),
    expireDate: new FormControl(null, []),
    file: new FormControl(null, [Validators.required]),
    userDocumentType: new FormControl(null, [Validators.required])
  })

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private layoutService: LayoutService,
    private companySupplierService: CompanySupplierService,
    private userDocumentTypeService: UserDocumentTypeService,
  ) { }

  ngOnInit() {
    this.layoutService.header = "Supplier Businesses and Documents";

    // this.selectedTab.subscribe(res => {
    //   if (res == 'business' && this.suppliers.value)
    //     this.getBusinesses(this.supplierId);
    //   else if (res == 'document' && this.suppliers.value) {
    //     this.getUserDocumentTypes();
    //     this.getDocuments();
    //   }
    // })

    this.formControl.valueChanges.subscribe(res => {
      this.getBusinesses(this.supplierId);
      this.getUserDocumentTypes();
      this.getDocuments();
    });

    this.companySupplierService.getSuppliers(null, null)
      .subscribe(res => {
        this.suppliers.next(res.data);
        this.route.params

          .subscribe(res => {
            this.formControl.setValue(+res['id']);
          })
      })
  }

  inGettingBussiness = false;
  getBusinesses(supplierId: number) {
    this.inProgress = true;
    if (this.inGettingBussiness == true || supplierId == null) return;
    this.inGettingBussiness = true;
    this.companySupplierService.getBusinesses(supplierId, {
      pagingInfo: {

      },
      query: null,
    })
      .pipe(finalize(() => {
        this.inGettingBussiness = false;
        this.inProgress = false;
      }))
      .subscribe(res => {
        this.businesses.next(res.data)
      })
  }

  temporaryAddBusiness(business: UserBusinessViewModel) {
    if (business.mode == "UserValue")
      this.selectedBusiness = null;
    else
      this.selectedBusiness = business;
  }

  addBusiness() {
    var supplierId = this.formControl.value;
    this.inProgress = true;
    var business = {
      abn: this.selectedBusiness?.abn,
      acn: this.selectedBusiness?.acn,
      name: this.selectedBusiness?.name,
      licenseNumber: this.license,
    } as CompanySupplierBusinessModel;
    this.companySupplierService.addBusiness(supplierId, business)
      .pipe(finalize(() => {
        this.inProgress = false;
      }))
      .subscribe(res => {
        this.businesses.value.push(res);
        this.selectedBusiness = null;
        this.license = '';
        this.lookupUserBusiness.resetAll();
      }, err => {
        this.modalService.error(err.error)
      })
  }

  removeBusiness(business: CompanySupplierBusinessViewModel) {
    var supplierId = this.formControl.value;
    this.inProgress = true;
    this.companySupplierService.removeBusiness(supplierId, business.id)
      .pipe(finalize(() => {
        this.inProgress = false;
      }))
      .subscribe(res => {
        var index = this.businesses.value.indexOf(business);
        this.businesses.value.splice(index, 1);
      })
  }

  inGettingDocumentTypes = false;
  getUserDocumentTypes() {
    if (this.inGettingDocumentTypes == true) return;
    this.inGettingDocumentTypes = true;
    this.inProgress = true;
    this.userDocumentTypeService.getAll()
      .pipe(finalize(() => {
        this.inGettingDocumentTypes = false;
        this.inProgress = false;
      }))
      .subscribe(res => {
        this.documentTypes.next(res)
      })
  }

  async addDocument() {
    var supplierId = this.formControl.value;
    var file = this.documentFormGroup.controls.file.value as File;
    var model = {
      content: (await toBase64(file)) as string,
      fileType: getFileExtenstion(file.name),
      title: this.documentFormGroup.controls.title.value,
      expireDate: this.documentFormGroup.controls.expireDate.value,
      userDocumentTypeId: this.documentFormGroup.controls.userDocumentType.value.id,
    }
    this.inProgress = true;
    this.companySupplierService.addDocument(supplierId, model)
      .pipe(finalize(() => {
        this.inProgress = false;
      }))
      .subscribe(res => {
        this.documents.value.push(res);
        this.documentFormGroup.reset();
      }, err => {
        this.modalService.error(err.error)
      })
  }

  getDocuments() {
    var supplierId = this.supplierId;
    if (!supplierId) return;
    this.inProgress = true;
    this.companySupplierService.getDocuments(supplierId)
      .pipe(finalize(() => {
        this.inProgress = false;
      }))
      .subscribe(res => {
        this.documents.next(res.data);
      })
  }

  removeDocument(document) {
    var supplierId = this.supplierId;
    this.inProgress = true;
    this.companySupplierService.removeDocument(supplierId, document.id)
      .pipe(finalize(() => {
        this.inProgress = false;
      }))
      .subscribe(res => {
        var index = this.documents.value.indexOf(document);
        this.documents.value.splice(index, 1);
      })
  }
  getTypeLabel(userDocumentTypeId: number) {
    return this.documentTypes.value.find(x => x.id == userDocumentTypeId)?.title;
  }
}
