import { Pipe, PipeTransform } from '@angular/core';
import { AccessControlUnitDirection } from '../enums/access-control-automatic-attendance-mode';

@Pipe({
  name: 'accessControlUnitDirection'
})
export class AccessControlUnitDirectionPipe implements PipeTransform {

  transform(value: any): string {
    if (value == AccessControlUnitDirection.In) { return "Entry"; }
    if (value == AccessControlUnitDirection.Out) { return "Exit"; }
    if (value == AccessControlUnitDirection.InOut) { return "Entry/Exit"; }
    return value?.toString() ?? '?';
  }

}
