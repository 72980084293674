import { Pipe, PipeTransform } from '@angular/core';
import { SupplierDocumentExpiryNotificationPeriodType } from '../enums/supplier-document-expiry-notification-priod-type.enum';

@Pipe({
  name: 'supplierDocumentExpiryNotificationPeriodTypePipe'
})
export class SupplierDocumentExpiryNotificationPeriodTypePipe implements PipeTransform {

  transform(value: any): string {
    if (value == SupplierDocumentExpiryNotificationPeriodType.Daily) return 'Daily';
    if (value == SupplierDocumentExpiryNotificationPeriodType.Weekly) return 'Weekly';
    if (value == SupplierDocumentExpiryNotificationPeriodType.Monthly) return 'Monthly';
    if (value == SupplierDocumentExpiryNotificationPeriodType.None) return 'None';
    return value?.toString();
  }

}
