import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { SingleSelectRenderMode } from '../../../models/questionParameters/single-select-render-type';
import { ControlContainer, NgForm } from '@angular/forms';
import {QuestionListItem, QuestionListItemOutputModel} from 'src/app/models/questionParameters/question-list-tem';
import { ItemsListKey } from '../question-multi-select/question-multi-select.component';
@Component({
  selector: 'obc-question-single-select',
  templateUrl: './question-single-select.component.html',
  styleUrls: ['./question-single-select.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class QuestionSingleSelectComponent implements OnInit {
  singleSelectRenderMode = SingleSelectRenderMode;
  _inputItems: QuestionListItem[] = [];
  drpIsValid = false;
  _questionParam: any;
  @Input() set questionParamInput(_questionParamInput: any) {
    if (!_questionParamInput) { return; }
    if (_questionParamInput) {
      this._questionParam = _questionParamInput;
      this._questionParam['renderMode'] ??= SingleSelectRenderMode.DropDown;
      this.checkIfDrpIsValid();
      if (this._questionParam) {//edit mode
        delete this._questionParam['regex'];
        this.fillItemsArrsyByQuestionParametersAsString();
      }
    }
  }
  @Output('onItemsUpdated') onItemsUpdatedOutput = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {

  }
  onItemsUpdated(itemsModel: QuestionListItemOutputModel) {
    this.onItemsUpdatedOutput.emit(itemsModel.isValidList);
    this._inputItems = itemsModel.items;
    this._questionParam[ItemsListKey] = itemsModel.items;
  }

  fillItemsArrsyByQuestionParametersAsString() {
    if (this._questionParam[ItemsListKey]) {
      let items = this._questionParam[ItemsListKey];
      this._inputItems.push(...items ?? []);
    }
  }

  checkIfDrpIsValid() {
    this.drpIsValid = this.singleSelectRenderMode[this._questionParam['renderMode']] != undefined ? true : false;
    if (!this.drpIsValid)
      delete this._questionParam['renderMode']
  }

}
