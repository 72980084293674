import { Pipe, PipeTransform } from '@angular/core';
import { SupplierDocumentAccessMode } from '../enums/supplier-document-access-mode.enum';

@Pipe({
  name: 'supplierDocumentAccessMode'
})
export class SupplierDocumentAccessModePipe implements PipeTransform {

  transform(value: unknown): string {
    if (value == SupplierDocumentAccessMode.Anyone) return 'Anyone';
    if (value == SupplierDocumentAccessMode.HostOnly) return 'Hosts Only';
    if (value == SupplierDocumentAccessMode.Supplier) return 'Supplier';
    return value?.toString() ?? '?';
  }

}
