import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { AskMode } from 'src/app/enums/ask-mode';
import { CompanySiteScope } from 'src/app/enums/company-site-scope';
import { InductionAskMode } from 'src/app/enums/induction-ask-mode.enum';
import { InductionMode } from 'src/app/enums/site-induction-mode.enum';
import { InductionModel, InductionViewModel } from 'src/app/models/induction-viewmodel';
import { InductionService } from 'src/app/services/induction-service';
import { ModalService } from 'src/app/services/modal.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OffsiteSupplierCollectionModeEnum } from "../../../enums/offsite-supplier-collection-mode.enum";
import { ExpiryMode, ExpiryUnit } from 'src/app/enums/expiry-mode.enum';

@Component({
  selector: 'app-upsert-induction',
  templateUrl: './upsert-induction.component.html',
  styleUrls: [ './upsert-induction.component.scss' ]
})
export class UpsertInductionComponent implements OnInit {
  @Output() public hide = new EventEmitter();

  InductionScopeEnum = CompanySiteScope;
  AskModeEnum = InductionAskMode;
  InductionMode = InductionMode;
  inProgress: boolean;
  expiryModeEnum = ExpiryMode;
  expiryUnitEnum = ExpiryUnit;

  formGroup: FormGroup;
  isActiveFormControl = new FormControl();

  @Input() set induction(value: InductionViewModel) {
    this.resetFormGroup(value);
  }

  inductionToEdit: InductionViewModel;
  offsiteSupplierCollectionModeEnum = OffsiteSupplierCollectionModeEnum;

  constructor(private inductionService: InductionService,
              private modalService: ModalService,
              private bsModalService: BsModalService) {
  }

  ngOnInit() {
  }

  resetFormGroup(induction: InductionViewModel = null) {
    this.isActiveFormControl.setValue(induction ? (induction.mode != InductionMode.Disable) : true);
    this.formGroup = new FormGroup({
      title: new FormControl(induction?.title ?? '', [ Validators.required ]),
      askMode: new FormControl(induction?.askMode ?? AskMode.AnyTime, [ Validators.required ]),
      scope: new FormControl(induction?.scope ?? CompanySiteScope.Company, [ Validators.required ]),
      items: new FormControl(induction?.items ?? []),
      visitorTypeIds: new FormControl(induction?.visitorTypeIds ?? []),
      mode: new FormControl(induction?.mode ?? InductionMode.Auto),
      isMandatory: new FormControl(induction?.isMandatory ?? true, [ Validators.required ]),
      isActive: this.isActiveFormControl,
      allowUsersToAttachAdditionalDocuments: new FormControl(induction?.allowUsersToAttachAdditionalDocuments ?? false),
      expiryMode: new FormControl(induction?.expiryMode ?? ExpiryMode.EndDay, [ Validators.required ]),
      expiryCount: new FormControl(induction?.expiryCount , [ Validators.min(1) ]),
      expiryUnit: new FormControl(induction?.expiryUnit ?? ExpiryUnit.Day, [ Validators.required ]),
    });
    this.inductionToEdit = induction;
  }

  get isFormGroupValid(): boolean {
    let b1 = this.formGroup.valid;
    let b2 = this.formGroup.controls.isActive.value == true && this.formGroup.controls.mode.value > 0;
    let b3 = this.formGroup.controls.isActive.value == false;
    return b1 &&
      (
        (b2)
        ||
        (b3)
      )
  }

  createInductionModel() {
    let ivm = {
      title: this.formGroup.controls['title'].value,
      scope: this.formGroup.controls['scope'].value,
      askMode: this.formGroup.controls['askMode'].value,
      visitorTypeIds: this.formGroup.controls['visitorTypeIds'].value,
      items: this.formGroup.controls['items'].value,
      mode: this.formGroup.controls['isActive'].value ? (this.formGroup.controls['mode'].value) : InductionMode.Disable,
      isMandatory: this.formGroup.controls['isMandatory'].value,
      allowUsersToAttachAdditionalDocuments: this.formGroup.controls['allowUsersToAttachAdditionalDocuments'].value,
      expiryMode: this.formGroup.controls['expiryMode'].value,
      expiryCount: this.formGroup.controls['expiryMode'].value === this.expiryModeEnum.Custom ? this.formGroup.controls['expiryCount'].value : null,
      expiryUnit: this.formGroup.controls['expiryMode'].value === this.expiryModeEnum.Custom ? this.formGroup.controls['expiryUnit'].value : null,
    } as InductionModel;

    if(!ivm.items?.length) {
      this.modalService.error('Induction must have at least one question or document or announcement!');
      return null;
    }
    return ivm;
  }

  addInduction() {
    let ivm = this.createInductionModel();
    if(!ivm)
      return;

    this.inProgress = true;
    if(this.inductionToEdit) {
      this.inductionService.editInduction(this.inductionToEdit.id, ivm)
        .pipe(finalize(() => {
          this.inProgress = false;
        }))
        .subscribe(res => {
          if(res.success) {
            this.hide.emit();
          }
        }, _ => {
          this.modalService.error("Request Failed");
          this.inProgress = false;
        }, () => {
          this.inProgress = false;
        });
    } else
      this.inductionService.addInduction(ivm)
        .pipe(finalize(() => {
          this.inProgress = false;
        }))
        .subscribe(_ => {
          this.hide.emit();
        }, _ => {
          this.modalService.error("Request Failed");
          this.inProgress = false;
        }, () => {
          this.inProgress = false;
        });
  }


  inductionModel: InductionModel;
  bsModalRef: BsModalRef;

  onModalPreview(template: any) {
    this.inductionModel = this.createInductionModel();
    if(this.inductionModel) {
      this.bsModalRef = this.bsModalService.show(template, {
        class: "modal-xl",
        backdrop: true,
        ignoreBackdropClick: false,
      })
    }
  }


  onSelectedVisitorTypeChange($event) {
    this.formGroup.controls.visitorTypeIds.setValue($event);
  }

  orderedItems(items) {
    this.formGroup.controls['items'].setValue(items);
  }

  toggleAttachAdditionalDocuments() {
    this.formGroup.controls['allowUsersToAttachAdditionalDocuments']
      .setValue(!this.formGroup.controls['allowUsersToAttachAdditionalDocuments'].value);
  }
}
