import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import PhoneNumber from 'awesome-phonenumber';
import { finalize } from 'rxjs/operators';
import { OtpRequestMode } from 'src/app/enums/otp-request-mode.enum';
import { CountryViewModel } from 'src/app/models/country-view-model';
import { PreLoginStatusViewModel } from 'src/app/models/pre-login-status-view-model';
import { ServerViewModel } from 'src/app/models/server_view_model';
import { CountryService } from 'src/app/services/country.service';
import { UserService } from 'src/app/services/user.service';
import { UserAuthenticationResult } from '../../enums/userAuthenticationResult.enum';
import { UserCredentialsViewModel } from '../../models/user-credentials-viewModel';
import { LayoutService } from "../../services/layout.service";
import { SignInService } from '../../services/sign-in.service';
import { SendOtpModel } from './../../models/send-otp-model';
import { TwoFactorAuthenticationModel } from './../../models/two-factor-authentication-model';
import { TwoFactorVerificationMode } from '../../enums/two-factor-verification-mode';
import { CaptchaService } from '../../services/captcha.service';
import { LoginStateEnum } from '../../enums/login-state-enum';
import { CaptchaMode } from 'src/app/enums/captcha-mode';

@Component({
  selector: 'obc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  // This is only for fallback.
  // The main number will receive from server.
  readonly DEFAULT_TIMEOUT = 120;

  @Input() minimalView: boolean = false;
  @Input() returnPath: string;
  @Input('mobile') inputMobile: string;
  @Output() done = new EventEmitter();
  countries: CountryViewModel[];
  serverList: ServerViewModel[];
  selectedCountry: CountryViewModel;
  loginForm: FormGroup;
  inProgress: boolean = false;
  errorMessage = '';
  state: LoginStateEnum = LoginStateEnum.PreLogin;
  loginStateEnum = LoginStateEnum;
  mobileNumberIsCorrect = false;
  editMobileNumber: boolean = true;
  isDarkMode = false;
  year: number;
  twoFactorAuthentication: TwoFactorAuthenticationModel;
  disableResendLink: boolean = true;
  resendResponse: PreLoginStatusViewModel;
  twoFactorVerificationMode = TwoFactorVerificationMode;

  TwoFactorVerificationModeEnum = TwoFactorVerificationMode;

  get showMobileNumber(): boolean {
    return this.editMobileNumber || !this.mobileNumberIsCorrect;
  }

  constructor(
    private signInService: SignInService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private countryService: CountryService,
    private layoutService: LayoutService,
    private userService: UserService,
    private router: Router,
    private _captchaService: CaptchaService) {
    this.isDarkMode = this.layoutService.isInDarkMode;
    this.layoutService.toggleDarkMode(false, false, false);

    this.loginForm = this.fb.group({
      country: '',
      mobile: [
        '',
        [
          Validators.required,
          Validators.min(100000),
          Validators.max(99999999999999),
        ],
      ],
      smsCode: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(6)],
      ],
      password: ['', [Validators.required, Validators.minLength(8)]],
      repeatpassword: ['', [Validators.required, Validators.minLength(8)]],
      isPersisted: [false, []],
    });
  }

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.inProgress = true;
    this.countryService.getCoutries().subscribe((coutries) => {
      this.countries = coutries;
      this.selectedCountry = this.countries[0];
      this.inProgress = false;

      this.route.queryParams.subscribe((params) => {
        var mobile = params['m'];
        if (mobile) {
          this.loadMobileNumber(mobile);
        }
      });

      if (this.inputMobile != null) {
        this.loadMobileNumber(this.inputMobile);
      }

      if (coutries && coutries.length > 0) {
        this._captchaService.captchaMode = coutries[0].captchaMode;
      }

      localStorage.setItem("captcha-mode", this.selectedCountry.captchaMode?.toString());
    });

    this.countryService.getOtherServerList().subscribe((servers) => {
      this.serverList = servers;
    });
  }

  private loadMobileNumber(mobile: any) {
    if (mobile.substring(0, 1) != '+')
      mobile = '+' + mobile;
    var phone = new PhoneNumber(mobile);

    var number = phone.getNumber('significant');
    var regionCode = phone.getRegionCode();
    if (number) {
      this.loginForm.controls['mobile'].setValue(number);
      this.selectedCountry =
        this.countries.find((c) => c.alpha2 == regionCode) ??
        this.countries[0];
    }
    return mobile;
  }

  sendUserNumber() {
    this.inProgress = true;
    this.mobileNumberIsCorrect = false;
    this.editMobileNumber = false;

    this.signInService
      .webPreLogin(
        this.loginForm.controls['mobile'].value + '',
        this.selectedCountry.alpha2
      )
      .pipe(
        finalize(() => {
          this.inProgress = false;
          this.loginForm.controls.smsCode.setValue('');
        })
      )
      .subscribe(
        (res) => {
          // login page = 1,
          // password page = 2,
          // token page = 3,
          this.done.emit({
            "state": res.state,
            "country": this.selectedCountry.prefixCode,
            "mobile": this.loginForm.controls['mobile'].value
          });

          if (res.error) {
            this.errorMessage = res.message;
          } else {
            this.errorMessage = null;
            this.state = res.state;
          }
        },
        (error) => {
          this.errorMessage = error;
        }
      );
  }

  private handleAuthenticationResult(response: any) {
    switch (response) {
      case UserAuthenticationResult.Found:
        this.mobileNumberIsCorrect = true;
        this.errorMessage = null;
        break;
      case UserAuthenticationResult.NotFound:
        this.errorMessage = "Couldn't find your mobile number";
        this.mobileNumberIsCorrect = false;
        break;
      case UserAuthenticationResult.IncompleteRegister:
        this.errorMessage = 'Your registration is incomplete';
        this.mobileNumberIsCorrect = false;
        break;
      case UserAuthenticationResult.NoCompanyRecord:
        this.errorMessage = 'You are not assigned to a company';
        this.mobileNumberIsCorrect = false;
        break;
      default:
        this.mobileNumberIsCorrect = false;
        this.errorMessage = 'Something went wrong';
        break;
    }
  }

  signIn() {
    this.inProgress = true;
    this.errorMessage = null;
    const returnPath = this.returnPath ?? this.route.snapshot.params['returnPath'];
    let userCredentialsViewModel = new UserCredentialsViewModel();
    userCredentialsViewModel.mobile = this.loginForm.controls['mobile'].value + '';
    userCredentialsViewModel.securityToken = this.loginForm.controls['smsCode'].value.split('-').join('');
    userCredentialsViewModel.regionCode = this.selectedCountry.alpha2;
    userCredentialsViewModel.password = this.twoFactorAuthentication == null ? this.loginForm.controls['password'].value : this.twoFactorAuthentication.token;
    userCredentialsViewModel.sendMode = this.twoFactorAuthentication?.verificationMode;
    userCredentialsViewModel.isPersisted = this.loginForm.controls['isPersisted'].value;

    if (this.tokenFormIsValid())
      userCredentialsViewModel.mode = OtpRequestMode.ResetPassword;

    if (this.twoFactorAuthentication != null && this.state == LoginStateEnum.TwoFactor) {
      userCredentialsViewModel.mode = OtpRequestMode.TwoFactorVerification;
    }

    this.signInService
      .signIn(returnPath, userCredentialsViewModel)
      .pipe(
        finalize(() => {
          this.inProgress = false;
        }))
      .subscribe(
        (res) => {
          this.handleRedirection(res);
          this.done.emit();
        },
        (error) => {
          this.handleErroredSignIn(error);
        }
      );
  }

  private handleRedirection(response: any) {
    const ajaxRedirectStatusCode = 200;
    if (response.status === ajaxRedirectStatusCode) {
      if (response.headers.get('Location') != null) {
        this.layoutService.toggleDarkMode(this.isDarkMode);
        this.router.navigate([response.headers.get('Location')]);
      }
      else if (response.body != null) {
        this.state = LoginStateEnum.TwoFactor;
        this.twoFactorAuthentication = response.body as TwoFactorAuthenticationModel;
        this.timeLeft = this.twoFactorAuthentication.verificationTime == null ?
          this.DEFAULT_TIMEOUT :
          this.twoFactorAuthentication.verificationTime * 60;
        this.startTimer();
      }
    }
  }

  resendToken() {
    this.inProgress = true;
    var model: SendOtpModel = {
      countryCode: this.selectedCountry.alpha2,
      mobileNumber: this.loginForm.controls['mobile'].value + '',
      mode: OtpRequestMode.TwoFactorVerification,
      twoFactorToken: this.twoFactorAuthentication.token,
      onlyCheckUserName: false
    }

    this.userService
      .resendOtp(model)
      .pipe(
        finalize(() => {
          this.inProgress = false;
        })
      )
      .subscribe(
        (result) => {
          this.resendResponse = result;
          if (!this.resendResponse?.error) {
            this.timeLeft = this.twoFactorAuthentication.verificationTime == null ? this.DEFAULT_TIMEOUT : this.twoFactorAuthentication.verificationTime * 60;
            this.disableResendLink = true;
            this.startTimer();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  timeLeft: number = this.DEFAULT_TIMEOUT;
  interval;
  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.pauseTimer();
      }
    }, 1000)
  }

  pauseTimer() {
    clearInterval(this.interval);
    this.disableResendLink = false;
    this.twoFactorAuthentication?.otherWaysList.forEach(item => {
      item.enabled = true;
    });
  }

  resetPassword() {
    this.inProgress = true;
    this.mobileNumberIsCorrect = false;
    this.editMobileNumber = false;
    this.twoFactorAuthentication = null;

    this.signInService
      .requestSendToken(
        this.loginForm.controls['mobile'].value + '',
        this.selectedCountry.alpha2)
      .pipe(
        finalize(() => {
          this.inProgress = false;
          this.loginForm.controls.smsCode.setValue('');
        }))
      .subscribe(
        (res: PreLoginStatusViewModel) => {
          this.handleAuthenticationResult(res.userStatus);
          this.resendResponse = res;
          this.state = LoginStateEnum.ResendOTP;
        },
        (error) => {
          this.errorMessage = error;
        }
      );
  }

  editPhoneNumber() {
    this.state = LoginStateEnum.PreLogin;
    this.twoFactorAuthentication = null;
    clearInterval(this.interval);
  }

  private handleErroredSignIn(response: any) {
    if (response.status == 403) {
      this.errorMessage = 'You do not have access to this service.';
    } else if (response.status == 423) {
      let responseError = response.error;
      let lockoutMessage = `Your account has been locked because you have entered an incorrect password ${responseError.wrongPasswordAttemptCountLockout} times.`;
      lockoutMessage += ` For security of your profile please try again in ${responseError.lockoutInSeconds / 60
        } minutes`;
      this.errorMessage = lockoutMessage;
    }
    else if (response.status == 406) {
      this.errorMessage = 'You must change your password to match the required complexity!';
    } else
      this.errorMessage =
        'We were not able to sign you in with the provided credentials.';
  }

  tokenFormIsValid() {
    return (
      this.loginForm.controls['smsCode'].value.split('-').join('').length == 6 &&
      this.loginForm.controls['password'].valid &&
      this.loginForm.controls['password'].value ==
      this.loginForm.controls['repeatpassword'].value
    );
  }

  twoFactorFormIsValid() {
    return (
      this.loginForm.controls['smsCode'].value.split('-').join('').length == 6
    );
  }

  isRepeatPasswordValid() {
    return (
      this.loginForm.controls['password'].value ==
      this.loginForm.controls['repeatpassword'].value
    );
  }

  showProgress() {
    this.inProgress = true;
  }

  sendOtherType(type: TwoFactorVerificationMode) {
    this.inProgress = true;
    this.errorMessage = null;
    const returnPath = this.returnPath ?? this.route.snapshot.params['returnPath'];
    let userCredentialsViewModel = new UserCredentialsViewModel();
    userCredentialsViewModel.mobile = this.loginForm.controls['mobile'].value + '';
    userCredentialsViewModel.securityToken = null;
    userCredentialsViewModel.regionCode = this.selectedCountry.alpha2;
    userCredentialsViewModel.password = this.loginForm.controls['password'].value;
    userCredentialsViewModel.sendMode = type;
    userCredentialsViewModel.mode = OtpRequestMode.Register;

    this.signInService
      .signIn(returnPath, userCredentialsViewModel)
      .pipe(
        finalize(() => {
          this.inProgress = false;
          this.loginForm.controls.smsCode.setValue('');
        }))
      .subscribe(
        (response) => {
          this.twoFactorAuthentication = response.body as TwoFactorAuthenticationModel;
          clearInterval(this.interval);
          this.disableResendLink = true;
          this.timeLeft = this.twoFactorAuthentication.verificationTime == null ? this.DEFAULT_TIMEOUT : this.twoFactorAuthentication.verificationTime * 60;
          this.startTimer();
        },
        (error) => {
          this.handleErroredSignIn(error);
        }
      );
  }
}
