export enum ExternalSitePermission {
    NoPermission = 0,
    ViewActivity = 1 << 0,
    DefineQuestions = 1 << 1,
    DefineAnnouncements = 1 << 2,
    DefineForms = 1 << 3,
    DefineInductions = 1 << 4,
}
export enum AggregatedExternalSitePermission {
    AccessToManageSiteItems = ExternalSitePermission.DefineQuestions | ExternalSitePermission.DefineAnnouncements | ExternalSitePermission.DefineForms | ExternalSitePermission.DefineInductions,

}
