import { Pipe, PipeTransform } from '@angular/core';
import { CompanyCategory } from '../enums/company-category';

@Pipe({
  name: 'companyCategory'
})
export class CompanyCategoryPipe implements PipeTransform {

  transform(value:unknown): string {
    if(value === CompanyCategory.Private || value === 0) return "Private";
    if(value === CompanyCategory.Industry || value === 1) return "Industry";
    if (value === CompanyCategory.MBAV || value === 2) return "MBAV";
    return value?.toString();
  }

}
