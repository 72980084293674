import { Pipe, PipeTransform } from '@angular/core';
import { OptionalMandatoryState } from '../enums/site-document-state.enum';

@Pipe({
  name: 'optionalMandatory'
})
export class OptionalMandatoryPipe implements PipeTransform {

  transform(value: unknown): any {
    if (value === OptionalMandatoryState.Optional) { return "Optional"; }
    if (value === OptionalMandatoryState.Mandatory) { return "Mandatory"; }
    return value;
  }

}
