<div class="row">
    <div class="col-md-12">
        <div class="card">
            <obc-loader [show]="inProgress"></obc-loader>
            <header class="card-header">
                <h5>Assign History</h5>
                <div class="card-actions">
                    <a (click)="hide.emit()" class="card-action card-action-dismiss" data-card-dismiss=""></a>
                </div>
            </header>
            <div class="card-body">
                <div class="row" *ngIf="report">
                    <div class="col-md-12">
                        <h6 class="pull-left">
                            <h5>
                                {{tool.name}}
                            </h5>
                        </h6>
                    </div>
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="modal-table table table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th><span>Status</span></th>
                                        <th><span>Assign Date</span></th>
                                        <th><span>From</span></th>
                                        <th><span>To</span></th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="report">
                                    <ng-container *ngFor="let item of report">
                                        <tr>
                                            <td>
                                                {{item.statusTitle}}
                                            </td>
                                            <td>
                                                {{item.assignDateTime | datetime}}
                                            </td>
                                            <td>
                                              <span *ngIf="item.fromUser"><i class="fas fa-user-circle"></i>
                                                  {{item.fromUser}} ({{item.fromUserMobile}})</span>

                                              <span class="d-flex align-items-start" *ngIf="item.fromLocation">
                                                <i class="fas fas fa-map-marker-alt mt-1 mr-2"></i>
                                                <div class="d-flex flex-column">
                                                  <div *ngIf="item.fromLocationSite">
                                                     {{ item.fromLocationSite }}
                                                  </div>
                                                  <div>
                                                     {{item.fromLocation}}
                                                  </div>
                                                </div>
                                              </span>
                                            </td>
                                            <td>
                                                <span *ngIf="item.toUser">
                                                    <i class="fas fa-user-circle"></i> {{item.toUser}}
                                                    ({{item.toUserMobile}})</span>
                                                <span class="d-flex align-items-start" *ngIf="item.toLocation">
                                                  <i class="fas fas fa-map-marker-alt mt-1 mr-2"></i>
                                                  <div class="d-flex flex-column">
                                                    <div *ngIf="item.toLocationSite">
                                                       {{ item.toLocationSite }}
                                                    </div>
                                                    <div>
                                                       {{item.toLocation}}
                                                    </div>
                                                  </div>
                                                </span>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                                <tbody *ngIf="report && !pagingInfo.totalCount">
                                    <tr>
                                        <td colspan="4">
                                            <div class="alert alert-warning">
                                                No records found.
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot
                                    *ngIf="report && pagingInfo.totalCount>0 && pagingInfo.totalCount > pagingInfo.pageSize">
                                    <tr>
                                        <td colspan="4">
                                            <obc-pagination [totalCount]="pagingInfo.totalCount"
                                                [pageSize]="pagingInfo.pageSize" [pageNumber]="pagingInfo.pageNumber"
                                                (pageChanged)="onPageChange($event)"></obc-pagination>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
