export enum ReportDeliveryType {
  Email = 0,
}

export enum ReportPeriodType {
  OneTime = -1,
  Daily = 0,
  Weekly = 1,
  Monthly = 2,
  HalfYearly = 3,
  Yearly = 4,
}

export enum MyobPeriodType {
  Weekly = 0,
  Monthly = 1,
}

