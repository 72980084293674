import { Pipe, PipeTransform } from '@angular/core';
import {TextRenderType} from "../enums/text-render-type";

@Pipe({
  name: 'textRenderType'
})
export class TextRenderTypePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === TextRenderType.SingleLine) { return "Single Line"; }
    if (value === TextRenderType.MultiLine) { return "Multi Line"; }
    if (value === TextRenderType.RichText) { return "Rich Text"; }
    return value;
  }

}
