<ng-container *ngFor="let item of _announcements | async | groupBy:'groupName' | orderBy:'key'">
  <div class="card">
    <div class="card-header">
      <span>{{item.key == null ? "Announcement" : item.key}}</span>
    </div>
    <div class="card-body">
      <ng-container *ngFor="let ann of item.value | orderBy:'order'">
        <obc-announcement-item-renderer [largeButton]="largeButton" [ann]="ann" [notifyVisits]="notifyVisits"
          [anonymousAttendanceKey]="anonymousAttendanceKey" [isSupplierAnnouncement]="isSupplierAnnouncement">
        </obc-announcement-item-renderer>
      </ng-container>
    </div>
  </div>
</ng-container>