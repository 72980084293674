import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ContinueWorkflowViewModel } from '../models/workflow/continue-workflow-viewmodel';
import { ContinueWorkflowQueryViewModel } from '../models/workflow/continue-workflow-query-viewModel';
import { WorkflowInfoViewModel } from '../models/workflow/workflow-info-viewmodel';
import { WorkflowHistoryViewModel } from '../models/workflow/workflow-history-view-model';
import { WorkflowType } from '../enums/workflow/workflow-type';
import { WorkflowDefinitionViewModel } from '../models/workflow/workflow-definition-view-model';
import { FormDataDetailViewModel } from '../models/form/form-data-detail-view-model';
import { GetWorkflowFilledFormModel } from '../models/supplier-document/get-workflow-filled-form-model ';
import { SiteSupplierService } from './site-supplier.service';
import { CompanyAuthService } from './company-auth.service';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  apiUrl: string = "/api/workflow";

  constructor(private _http: HttpClient,
    private _errorService: ErrorService,
    private siteSupplierService: SiteSupplierService,
    private companyAuthService: CompanyAuthService
  ) { }

  continueWorkflow = (workflowInstanceId: string, model: ContinueWorkflowQueryViewModel): Observable<ContinueWorkflowViewModel> =>
    this._http.post<ContinueWorkflowViewModel>(`${this.apiUrl}/continue-workflow/${workflowInstanceId}`, model)
      .pipe(catchError(this._errorService.handleError));

  getWorkflowByInstanceId = (workflowInstanceId: string): Observable<WorkflowInfoViewModel> =>
    this._http.get<WorkflowInfoViewModel>(`${this.apiUrl}/get-workflow/${workflowInstanceId}`)
      .pipe(catchError(this._errorService.handleError));

  getWorkflowInstanceHistory = (workflowInstanceId: string): Observable<WorkflowHistoryViewModel> =>
    this._http.post<WorkflowHistoryViewModel>(`${this.apiUrl}/workflow-instance-history/${workflowInstanceId}`, null)
      .pipe(catchError(this._errorService.handleError));

  private internalGetWorkflowList(workflowType: WorkflowType, companyId?: number): Observable<WorkflowDefinitionViewModel[]> {
    return this._http.post<ContinueWorkflowViewModel>(`${this.apiUrl}/workflow-list/${workflowType}/${companyId}`, null)
      .pipe(catchError(this._errorService.handleError));
  }

  private WorkflowDefinitionList: Map<string, WorkflowDefinitionViewModel[]> = new Map();

  getCacheKey(workflowType: WorkflowType, companyId?: number, hashLink?: string) {
    return workflowType + '-' + (companyId ?? 'null') + '-' + (hashLink ?? 'null');
  }

  clearWorkflowCache() {
    this.WorkflowDefinitionList.clear();
  }

  getWorkflowListFull = (workflowType: WorkflowType): Observable<WorkflowDefinitionViewModel[]> => {
    let companyId = this.companyAuthService.getCompanyId();
    let hashLink = this.companyAuthService.getHashLink();

    let key = this.getCacheKey(workflowType, companyId, hashLink);

    if (this.WorkflowDefinitionList && this.WorkflowDefinitionList.get(key)) {
      return of(this.WorkflowDefinitionList.get(key));
    }

    let workflowListService = hashLink ?
      this.siteSupplierService.getWorkflowListByHashlink(workflowType, hashLink) :
      this.internalGetWorkflowList(workflowType, companyId);

    return new Observable<WorkflowDefinitionViewModel[]>((subscriber) => {
      workflowListService
        .subscribe({
          next: response => {
            this.WorkflowDefinitionList.set(key, response);
            subscriber.next(response);
          },
          error: error => {
            console.log('had an error');
          },
          complete: () => {
            subscriber.complete();
          }
        });
    });
  }


  getWorkflow = (workflowType: WorkflowType, documentKindId: number): Observable<WorkflowDefinitionViewModel> => {
    return new Observable<WorkflowDefinitionViewModel>((subscriber) => {
      this.getWorkflowListFull(workflowType)
        .subscribe({
          next: result => {
            subscriber.next(result.find(p => p.workflowSubtype == documentKindId));
          }
        })
    });
  }

  getWorkflowFilledForm = (model: GetWorkflowFilledFormModel): Observable<FormDataDetailViewModel> =>
    this._http.post<FormDataDetailViewModel>(`${this.apiUrl}/get-workflow-filled-form`, model)
      .pipe(catchError(this._errorService.handleError));

}
