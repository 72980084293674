<div class="card">
  <div class="card-body">
    <div *ngIf="status.status == SiteAttendanceApprovalStatus.NotRequiredApproval">
      <p class="text-danger">Approval Not Required</p>
    </div>
    <div *ngIf="status.status == SiteAttendanceApprovalStatus.Pending">
      <div class="d-flex flex-row justify-content-between">
        <div>
          <p class="text-warning mb-1">
            <i class="fas fa-history"></i>
            Pending Approval
          </p>
        </div>
        <div class="d-flex flex-row flex-row-reverse">
          <button class="btn btn-danger m-1" (click)="onStatusChange(false)">Reject</button>
          <button class="btn btn-success m-1" (click)="onStatusChange(true)">Approve</button>
        </div>
      </div>
    </div>
    <div *ngIf="status.status == SiteAttendanceApprovalStatus.Rejected">
      <div class="text-center">
        <p class="text-danger mb-1"><i class="fas fa-times-circle"></i> Rejected By {{status.changeBy}} at
          {{status.dateTimeOffset | datetime}}</p>
      </div>
    </div>
    <div *ngIf="status.status == SiteAttendanceApprovalStatus.Approved">
      <div class="text-center">
        <p class="text-success mb-1"> <i class="fas fa-check-circle"></i> Approved By {{status.changeBy}} at
          {{status.dateTimeOffset | datetime}}</p>
      </div>
    </div>
  </div>
</div>