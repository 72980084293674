import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Observable, catchError, of } from 'rxjs';
import { SupplierDocumentKindViewModel } from '../models/supplier-document/supplier-document-kind-viewmodel';
import { CheckListItemViewModel } from '../custom-controls/check-list-form-control/check-list-item';
import { CompanyAuthService } from './company-auth.service';
import { WorkflowService } from './workflow.service';
@Injectable({
  providedIn: 'root',
})
export class DocumentKindService {

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private companyAuthService: CompanyAuthService,
    private workflowService: WorkflowService
  ) { }

  _list: SupplierDocumentKindViewModel[];
  _companyId?: number;
  _hashLink?: string;
  listObservable;
  list(companyId?: number): Observable<SupplierDocumentKindViewModel[]> {
    let companyIdToLoadKindList = companyId ?? this.companyAuthService.getCompanyId();
    let hashLink = this.companyAuthService.getHashLink();
    if (
      this._list &&
      (companyIdToLoadKindList === this._companyId || hashLink === this._hashLink)
    ) {
      return of(this._list);
    }
    if (
      this.listObservable &&
      (companyIdToLoadKindList === this._companyId || hashLink === this._hashLink)
    )
      return this.listObservable;

    if (hashLink) {
      this.listObservable = new Observable<SupplierDocumentKindViewModel[]>(
        (observer) => {
          let headers = new HttpHeaders({ 'hash-link': hashLink ?? '' });
          this.http
            .get<SupplierDocumentKindViewModel[]>(
              `api/SiteSupplier/supplier-document-kind-list`,
              { headers: headers }
            )
            .subscribe({
              next: (response) => {
                this._list = response;
                this._companyId = undefined;
                this._hashLink = hashLink;
                observer.next(response);
              },
              error: () => { },
              complete() {
                observer.complete();
              },
            });
        }
      );
    } else {
      this.listObservable = new Observable<SupplierDocumentKindViewModel[]>(
        (observer) => {
          this.http
            .get<SupplierDocumentKindViewModel[]>(
              `/api/SupplierDocumentKind/list/${companyIdToLoadKindList?.toString() ?? ''}`
            )
            .subscribe({
              next: (response) => {
                this._list = response;
                this._companyId = companyIdToLoadKindList;
                this._hashLink = undefined;
                observer.next(response);
              },
              error: () => { },
              complete() {
                observer.complete();
              },
            });
        }
      );
    }

    return this.listObservable;
  }

  get(documentKindId: number): Observable<SupplierDocumentKindViewModel> {
    return new Observable<SupplierDocumentKindViewModel>(observer => {
      this.list()
        .subscribe(kindList => {
          observer.next(kindList.find(k => k.id == documentKindId));
          observer.complete();
        });
    });
  }

  prepareDocumentKindCheckList(kindIdList: number[]): Observable<CheckListItemViewModel[]> {
    return new Observable<CheckListItemViewModel[]>(observer => {
      this.list().subscribe(kindList => observer.next(kindList?.map(k => {
        return {
          value: kindList.length == 1 || ((kindIdList && kindIdList?.some(kind => kind == k.id)) ?? false),
          key: +k.id,
          label: k.name,
          validators: [],
        } as CheckListItemViewModel;
      })));
    });
  }

  addUpdateDocumentKind(model: SupplierDocumentKindViewModel): Observable<any> {
    this.workflowService.clearWorkflowCache();
    return this.http.post<any>(`/api/SupplierDocumentKind/add-update`, model)
      .pipe(catchError(this.errorService.handleError));
  }

  documentKindToManage(companyId: number) {
    return this.http.get<SupplierDocumentKindViewModel[]>(`/api/SupplierDocumentKind/list-to-manage/${companyId?.toString() ?? ''}`)
      .pipe(catchError(this.errorService.handleError));
  }
}
