<obc-loader [show]="inProgress"></obc-loader>
<div [formGroup]="formGroup" *ngIf="companySupplierDocumentConfiguration">
  <div class="card">
    <div class="card-header">Supplier Document Configuration</div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="d-inline-block mt-2 mr-1">Supplier Document Approval Mode</label>
            <select class="custom-select form-control" [formControl]="formGroup.controls.supplierDocumentApprovalMode">
              <option *ngFor="let type of SupplierDocumentApprovalMode | enumToArray"
                [ngValue]="SupplierDocumentApprovalMode[type]">
                {{ SupplierDocumentApprovalMode[type] | supplierDocumentApprovalMode}}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label class="d-inline-block mt-2 mr-1">
              Force end users to view supplier documents before sign
              off?
            </label>
            <obc-switch [trueLabel]="' Yes '" [falseLabel]="' No '"
              [value]="formGroup.controls.forceViewSupplierDocumentsBeforeSignOff.value"
              (valueChanged)="onForceViewSupplierDocumentsBeforeSignOffChanged($event)">
            </obc-switch>
          </div>
          <div class="form-group">
            <label class="d-inline-block mt-2 mr-1">
              Require signature for supplier document
              sign-off?
            </label>
            <obc-switch [trueLabel]="' Yes '" [falseLabel]="' No '"
              [value]="formGroup.controls.forceEndUserToSign.value"
              (valueChanged)="onChangeSupplierDocumentConfigMode($event)">
            </obc-switch>
          </div>
          <div class="form-group">
            <label class="d-inline-block mt-2 mr-1">Supplier Document Approval Form</label>
            <select class="custom-select form-control" [formControl]="formGroup.controls.supplierDocumentReviewMode">
              <option *ngFor="let type of SupplierDocumentReviewMode | enumToArray"
                [ngValue]="SupplierDocumentReviewMode[type]">
                {{ SupplierDocumentReviewMode[type] | supplierDocumentReviewModePipe}}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label class="d-inline-block mt-2 mr-1">Supplier document request email content</label>
            <obc-markdown-editor [type]="SupplierDocumentEditorType.RequestEmailContent"
              [value]="companySupplierDocumentConfiguration?.supplierDocumentRequestEmailBody"
              (valueChanged)="formGroup.controls.supplierDocumentRequestEmailBody.setValue($event)"
              [id]="'supplierDocumentRequestEmailBodyRichBox'">
            </obc-markdown-editor>
          </div>
          <div class="form-group">
            <label class="d-inline-block mt-2 mr-1">Supplier form fill out request email content</label>
            <obc-markdown-editor [type]="SupplierDocumentEditorType.FilloutRequestEmailContent"
              [value]="companySupplierDocumentConfiguration?.supplierFormsSubmitRequestEmailBody"
              (valueChanged)="formGroup.controls.supplierFormsSubmitRequestEmailBody.setValue($event)"
              [id]="'supplierFormsSubmitRequestEmailBodyRichBox'">
            </obc-markdown-editor>
          </div>
          <div class="form-group">
            <label class="d-inline-block mt-2 mr-1">Supplier full onboarding email content</label>
            <obc-markdown-editor [type]="SupplierDocumentEditorType.FullOnboardingEmailContent"
              [value]="companySupplierDocumentConfiguration?.supplierFullOnboardingEmailBody"
              (valueChanged)="formGroup.controls.supplierFullOnboardingEmailBody.setValue($event)"
              [id]="'supplierFullOnboardingEmailBodyRichBox'">
            </obc-markdown-editor>
          </div>
          <div class="form-group">
            <label class="d-inline-block mt-2 mr-1">Supplier onboarding email offsite link template</label>
            <obc-markdown-editor [type]="SupplierDocumentEditorType.OnboardingEmailOffsiteLinkTemplate"
              [value]="companySupplierDocumentConfiguration?.offsiteOnboardingEmailContent"
              (valueChanged)="formGroup.controls.offsiteOnboardingEmailContent.setValue($event)"
              [id]="'offsiteOnboardingEmailContentRichBox'">
            </obc-markdown-editor>
          </div>
          <div class="form-group">
            <label class="d-inline-block mt-2 mr-1">Supplier document version comment email content</label>
            <obc-markdown-editor [type]="SupplierDocumentEditorType.DocumentVersionCommentEmailContent"
              [value]="companySupplierDocumentConfiguration?.supplierDocCommentEmailBody"
              (valueChanged)="formGroup.controls.supplierDocCommentEmailBody.setValue($event)"
              [id]="'supplierDocCommentEmailBodyRichBox'">
            </obc-markdown-editor>
          </div>
          <div class="form-group">
            <label class="d-inline-block mt-2 mr-1">
              Supplier document version comment acknowledgement
              text
            </label>
            <input [formControl]="formGroup.controls?.supplierDocCommentAcknowledgementText" type="text"
              class="form-control">
          </div>
        </div>
        <div class="col-md-2 offset-10 mt-2 ">
          <button class="btn btn-info btn-block"
            (click)="onSaveCompanySupplierDocumentConfiguration(maxLengthErrorModal)">Save</button>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">Supplier Document Reminder</div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="d-inline-block mt-2 mr-1">Send Reminder Email To Supplier To Upload Requested
              Documents?</label>
            <obc-switch [trueLabel]="' Yes '" [falseLabel]="' No '"
              [value]="formGroup.controls.isActiveSupplierDocEmailReminder.value"
              (valueChanged)="onActiveChanged($event)">
            </obc-switch>
          </div>
        </div>

        <ng-container *ngIf="formGroup.controls.isActiveSupplierDocEmailReminder.value">
          <div class="col-md-12">
            <label class="control-label">Send Reminder Email After (Days):</label>

            <input class="form-control mt-1" type="number" placeholder="Day Count"
              [formControl]="formGroup.controls.supplierDocEmailReminderAtDaysCount">
          </div>
          <div class="col-md-12">
            <input class="form-control mt-1" type="text" placeholder="Supplier Email Subject"
              [formControl]="formGroup.controls.supplierDocEmailReminderSubject">
          </div>
          <div class="col-md-12">
            <obc-markdown-editor [value]="this.supplierDocumentEmailReminder.supplierDocEmailReminderBody"
              (valueChanged)="formGroup.controls.supplierDocEmailReminderBody.setValue($event)"
              [id]="'supplierDocEmailReminderBodyRichBox'">
            </obc-markdown-editor>
          </div>
        </ng-container>
        <div class="col-md-2 offset-10 mt-2">
          <button [disabled]="!formGroup.valid || formGroup.controls.supplierDocEmailReminderAtDaysCount.value <= 0"
            class="btn btn-info btn-block" (click)="onSaveSupplierDocumentEmailReminder()">Save</button>
        </div>
      </div>
    </div>
  </div>

  <div class="card" *ngIf="workflowDocumentKinds.length > 0">
    <div class="card-header">Supplier Document Expiry Notification Configuration</div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="d-inline-block mt-2 mr-1">Notification Period</label>
            <select class="custom-select form-control"
              [formControl]="formGroup.controls.supplierDocumentExpiryNotificationPeriod">
              <option *ngFor="let type of SupplierDocumentExpiryNotificationPeriodType | enumToArray"
                [ngValue]="SupplierDocumentExpiryNotificationPeriodType[type]">
                {{ SupplierDocumentExpiryNotificationPeriodType[type] | supplierDocumentExpiryNotificationPeriodTypePipe
                }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <div class="form-group">
            <label>Workflows:</label>
            <label class="radio-inline ml-3">
              <input type="radio" name="selector" [value]="true"
                [formControl]="formGroup.controls.supplierDocumentExpiryNotificationAnyWorkflows">
              <b class="ml-1">Any</b>
            </label>
            <label class="radio-inline ml-3">
              <input type="radio" name="selector" [value]="false"
                [formControl]="formGroup.controls.supplierDocumentExpiryNotificationAnyWorkflows">
              <b class="ml-1">
                Specific
              </b>
            </label>
          </div>
          <div class="form-group pl-5 row" *ngIf="IsSpecificWorkflow()">
            <div class="col-md-6">
              <obc-check-list-form-control [formControl]="formGroup.controls.supplierDocumentWorkflowKinds"
                [values]="documentKindCheckList">
              </obc-check-list-form-control>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-2">
          <label>Supplier Document Expiry Subject:</label>
          <input class="form-control" type="text" placeholder="Supplier Document Expiry Subject"
            [formControl]="formGroup.controls.supplierDocExpiryNotificationEmailReminderSubject">
        </div>
        <div class="col-md-12 mt-3">
          <obc-markdown-editor
            [value]="this.supplierDocumentExpiryNotificationReminderModel.supplierDocExpiryNotificationEmailReminderBody"
            (valueChanged)="formGroup.controls.supplierDocExpiryNotificationEmailReminderBody.setValue($event)"
            [id]="'supplierDocExpiryNotificationEmailReminderBodyRichBox'">
          </obc-markdown-editor>
        </div>
        <div class="col-md-2 offset-10 mt-2">
          <button [disabled]="!supplierDocumentExpiryNotificationEmailIsValid()" class="btn btn-info btn-block"
            (click)="onSaveSupplierDocumentExpiryNotificationEmailReminder()">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #maxLengthErrorModal>
  <div class="card">
    <div class="card-header" style="background-color: #ff0000 !important; color: white;">
      <span>
        Error
      </span>
      <button type="button" class="close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="card-body">
      Text field length exceeded the maximum limit. Please reduce the length of your input and try again.<br />
      <ul>
        <li *ngFor="let item of maxLengthErrorList">{{item}}</li>
      </ul>
      <button type="button" class="btn btn-success pull-right" (click)="bsModalRef.hide()">Ok</button>
    </div>
  </div>
</ng-template>