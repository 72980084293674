
export enum MessageType {
  Email = 0,
  PushNotification = 5,
  SMS = 10,
}

export interface CompanyEvacuationSettings {
  evacuationDefaultSMSMessage: string;
  evacuationDefaultNotificationMessage: string;
  evacuationIsByDefaultOn: boolean;
  evacuationMessageType?: MessageType | null;
}

export const emptyCompanyEvacuationSettings: CompanyEvacuationSettings = {
  evacuationDefaultSMSMessage: 'ca',
  evacuationDefaultNotificationMessage: 'ca',
  evacuationIsByDefaultOn: false,
  evacuationMessageType: null,
};

