<section class="card">
  <header class="card-header">
      <div class="card-actions">
          <a class="card-action card-action-dismiss" data-card-dismiss=""></a>
      </div>
      <h2 class="card-title">{{title}}</h2>

      <p class="card-subtitle">
          <code>{{question.questionText}}</code>
      </p>
  </header>
  <div class="card-body">
    <h5>
      {{message}}
    </h5>
    <div>
      <button class="btn btn-sm btn-primary mr-1" (click)=" addQuestionToAllSite(1)">CheckIn Questions</button>
      <button class="btn btn-sm btn-primary mr-1" (click)="addQuestionToAllSite(2)">CheckOut Questions</button>
      <button class="btn btn-sm btn-danger mr-1" (click)="addQuestionToAllSite(3)">Both CheckIn/CheckOut Questions</button>
      <button class="btn btn-sm btn-default mr-1" (click)="closeAddQuestionToAllSiteModel()">Cancel</button>
    </div>
  </div>
</section>