<div class="form-group row">
  <label class="col-sm-4 control-label text-left pt-2">Is Required ?</label>
  <div class="col-sm-8 pull-left">
    <obc-descriptor [text]="'Do you require an answer to this question for an individual to check in successfully?'">
    </obc-descriptor>
    <obc-switch [value]="_questionParam['isRequired']" (valueChanged)="_questionParam['isRequired'] = $event">
    </obc-switch>
  </div>
</div>
<div class="form-group row">
  <label class="col-sm-4 control-label text-left pt-2">Document Type</label>
  <div class="col-sm-8 pull-left">
    <div class="d-flex flex-column flex-md-row">
      <label class="control-label text-left pt-2">
        <input type="radio" name="genericFileDocumentTypeSelector" [(ngModel)]="genericFileDocumentTypeSelector"
          [value]="true" [checked]="genericFileDocumentTypeSelector"
          (change)="onSelectGenericFileDocumentTypeSelector()">
        Generic File</label>
      <label class="ml-md-4 ml-0 control-label text-left pt-2">
        <input type="radio" name="genericFileDocumentTypeSelector" [(ngModel)]="genericFileDocumentTypeSelector"
          [value]="false" [checked]="!genericFileDocumentTypeSelector"
          (change)="onSelectGenericFileDocumentTypeSelector()">
        Document Type</label>
    </div>

    <ng-container *ngIf="!genericFileDocumentTypeSelector">
      <obc-document-type-nested-selector
        [getAllCompaniesDocumentTypes]="false"
        [onlySelectables]="true"
        [isMultiple]="true"
        [firstLoadedSelectedDocumentTypeIds]="documentTypeIdList"
        [selectedDocumentTypes]="selectedDocumentTypes"
        (result)="onSelectedDocumentTypes($event)"
      ></obc-document-type-nested-selector>
    </ng-container>

  </div>
</div>
<div class="form-group row" *ngIf="genericFileDocumentTypeSelector == true" >
  <label class="col-sm-4 control-label text-left pt-2">File Type</label>
  <div class="col-sm-8 pull-left">    
    <select #fileTypeSelect 
    [(ngModel)]="_questionParam['fileType']"
    required class="form-control">
      <option
      [ngValue]="QuestionAnswerFileFormat[type]"
      *ngFor="let type of QuestionAnswerFileFormat | enumToArray">
        {{ QuestionAnswerFileFormat[type] | questionAnswerFileFormat }}
      </option>
    </select>
  </div>
</div>
