<div class="layout-container" style="overflow: auto;">
    <div class="printLayout position-relative border-dashed" [attr.layout]="_cardLayout.layout"
        [style.background-color]="_cardLayout?.style?.bgColor" [style.width.cm]="_cardLayout.width"
        [style.height.cm]="_cardLayout.height">
        <ng-container *ngIf="freeze">
            <div [style.width.px]="element.position.width" [style.height.px]="element.position.height"
                [style.transform]="'translate(' + element.position.left + 'px, ' + element.position.top + 'px)'"
                *ngFor="let element of _cardLayout.elements" class="border-dashed d-inline-block position-absolute">
                {{element.name}}</div>
        </ng-container>
        <ng-container *ngIf="!freeze">
            <section obcDraggable [rectInfo]="element.position" [style.width.px]="element.position.width"
                [style.height.px]="element.position.height" [attr.data-x]="element.position.left"
                [attr.data-y]="element.position.top"
                [style.transform]="'translate(' + element.position.left + 'px, ' + element.position.top + 'px)'"
                *ngFor="let element of _cardLayout.elements"
                class="simple-compose-box mb-3 d-inline-block draggableElement">
                <div class="d-inline-block border-dashed">{{element.name}}</div>
                <div class="compose-box-footer">
                    <ul class="compose-toolbar">
                        <li>
                            <a class="small" (click)="removeElement(element)"><i
                                    class="fas fa-times text-danger"></i></a>
                        </li>
                        <li>
                            <a class="small" (click)="openSettingPage(element)"><i class="fas fa-cog text-info"></i></a>
                        </li>
                        <!-- <li>
                        <span class="small">({{element.position.top}},{{element.position.left}})</span>
                    </li>
                    <li>
                        <span class="small">({{element.position.width}},{{element.position.height}})</span>
                    </li> -->
                    </ul>
                </div>
            </section>
        </ng-container>
    </div>
</div>