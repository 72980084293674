import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs';
import { FormDataDetailViewModel } from 'src/app/models/form/form-data-detail-view-model';
import { GetWorkflowFilledFormModel } from 'src/app/models/supplier-document/get-workflow-filled-form-model ';
import { WorkflowHistoryViewModel } from 'src/app/models/workflow/workflow-history-view-model';
import { ModalService } from 'src/app/services/modal.service';
import { WorkflowService } from 'src/app/services/workflow.service';

@Component({
  selector: 'obc-workflow-instance-history',
  templateUrl: './workflow-instance-history.component.html',
  styleUrls: ['./workflow-instance-history.component.scss']
})
export class WorkflowInstanceHistoryComponent implements OnInit {
  @Input() workflowInstanceId: string;
  @Output() public hide = new EventEmitter();

  inProgress: boolean = false;
  workflowInstanceHistory: WorkflowHistoryViewModel = <WorkflowHistoryViewModel>{};
  filledFormData: FormDataDetailViewModel;

  constructor(
    private modalService: ModalService,
    private workflowService: WorkflowService,
  ) { }

  ngOnInit(): void {
    this.getWorkflowInstanceHistory();
  }

  getWorkflowInstanceHistory() {
    this.inProgress = true;
    this.workflowService
      .getWorkflowInstanceHistory(this.workflowInstanceId)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe({
        next: (result) => {
          if (result != null) {
            this.workflowInstanceHistory = result;
          }
        },
        error: (error) => {
        }
      });
  }
  viewHistoryFilledForm(formDetails, formDataId: number) {
    this.inProgress = true;
    this.workflowService.getWorkflowFilledForm({ formId: formDataId, workflowInstanceId: this.workflowInstanceId } as GetWorkflowFilledFormModel)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(
        (result) => {
          this.filledFormData = result;
          this.modalService.show(formDetails, "modal-lg");
        });
  }
}
