<div class="row" *ngIf="nocheckinFound">
  <div class="col-md-12">
    <div class="alert alert-success text-center" role="alert">
      You've been successfully checked out!
    </div>
  </div>
</div>
<div class="row anonymous-checkout-questions" *ngIf="!nocheckinFound">
  <div class="col-md-12" style="min-height: 250px;">
    <obc-loader [show]="inprocess"></obc-loader>
    <ng-container *ngIf="mode == 'answer'">
      <div class="row">
        <div class="col-md-12">
          <div class="bk-image-container">
            <img *ngIf="selectedStatus.siteAttendance.logoUrl" [src]="selectedStatus.siteAttendance.logoUrl">
          </div>
          <div class="card p-3">
            <obc-loader #loader></obc-loader>
            <div class="card-body">
              <p>
                <button class="btn btn-info btn-sm btn-default" (click)="mode = 'view'">
                  <i class="fa fa-chevron-left"></i> Return to Check-In List
                </button>
              </p>
              <obc-items-renderer [questionsData]="{questions : _questions,
            validator$: questionContainerValidation$,
            documents: requiredDocumentFormControls,
            documentsFormArray: requiredDocumentFormArray,
            notifyVisits: false
            }"></obc-items-renderer>

              <div class="col-md-12 pt-2" *ngIf="isSetSupplierCheckinData()">
                <div class="card check-in-supplier-block">
                  <div class="card-header">
                    <strong>{{supplierCheckinData.supplierName}}</strong>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <obc-items-renderer [questionsData]="{questions : _supplierQuestions,
            validator$: supplierQuestionContainerValidation$,
            documents: supplierRequiredDocumentFormControls,
            documentsFormArray: supplierRequiredDocumentFormArray
            }">
                        </obc-items-renderer>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">

                  </div>
                </div>
              </div>

              <p class="text-right mt-4">
                <button style="min-width: 200px;" class="btn btn-danger" [disabled]="!isFormValid"
                  (click)="checkout(loader)">
                  Check-Out
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="mode == 'view'">
      <div class="row" *ngIf="model | async">
        <div class="offset-md-3 col-md-6 col-sm-12 mb-3" *ngFor="let status of model.value.userAttendances">
          <div class="bk-image-container">
            <img *ngIf="status.siteAttendance.logoUrl" [src]="status.siteAttendance.logoUrl">
          </div>
          <div class="card anonymous-checkout-card">
            <obc-loader #loader></obc-loader>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <ng-container *ngIf="status.user.firstName && status.user.lastName">
                  <p class="text-center text-w700"> {{status.user.firstName}} {{status.user.lastName}}</p>
                  <hr>
                </ng-container>
                <p class="text-center  text-w700"> {{status.user.mobile | mobile}}</p>
              </div>
              <p>You are check-in at:</p>
              <div class="d-flex justify-content-start align-items-stretch">
                <div class="checkin-info" style="width: 100%;">
                  <div class="d-flex justify-content-between">
                    <span>Site</span>
                    <span>{{status.siteAttendance.siteName}}</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>Entry</span>
                    <span>{{status.siteAttendance.checkInDate | datetime}}</span>
                  </div>
                  <div class="d-flex align-items-stretch pt-2">
                    <button style="width: 100%;" (click)="onCheckOut(status, loader)" class="btn btn-danger">
                      Check-Out
                    </button>
                  </div>
                  <ng-container *ngIf="status.announcements.length">
                    <hr>
                    <div style="width: 100%;" class="dashed-bordered">
                      <p class="text-center">Announcements</p>
                      <div class="d-flex flex-column align-content-stretch">
                        <obc-announcement-renderer [announcements]="status.announcements"
                          [anonymousAttendanceKey]="status.siteAttendance.anonymousCheckInKey" [notifyVisits]="true">
                        </obc-announcement-renderer>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="status.supplierDocuments.length">
                    <hr>
                    <div style="width: 100%;" class="dashed-bordered">
                      <p class="text-center">Supplier Documents</p>
                      <div class="d-flex flex-column align-content-stretch">
                        <obc-supplier-document-sign-off-viewer (onAccept)="onAcceptSupplier()"
                          [AnonymousCheckInKey]="status.siteAttendance.anonymousCheckInKey" [canApprove]="true"
                          [list]="status.supplierDocuments">
                        </obc-supplier-document-sign-off-viewer>
                      </div>
                    </div>
                  </ng-container>
                  <div class="card" *ngIf="status.supplierItems?.announcements?.length">
                    <div class="card-header">
                      <h3>{{status.supplierItems.supplierName}}</h3>
                    </div>
                    <div class="card-body">
                      <obc-announcement-renderer [announcements]="status.supplierItems?.announcements"
                        [anonymousAttendanceKey]="status.siteAttendance.anonymousCheckInKey" [notifyVisits]="true"
                        [isSupplierAnnouncement]="true">
                      </obc-announcement-renderer>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
