import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { finalize } from "rxjs/operators";
import { FormService } from "../../../services/form.service";
import { ModalService } from "../../../services/modal.service";
import { FormDataModel } from "../../../models/form/form-data-model";
import { EditFormInformationModel } from "../../../models/form/edit-form-information-model";
import { FillFormMode } from "../../../enums/fill-form-mode";
import { SiteAssetOperationModel } from "../../../models/site-asset/site-asset-operation-model";
import { SiteAssetService } from "../../../services/site-asset.service";
import { SiteAssetOperatorModel } from "../../../models/site-asset/site-asset-view-model";
import { SiteAssetRegistrationModel } from "../../../models/site-asset/site-asset-registration-model";
import { ServerResult, ServerResultData } from "../../../models/server-result-data";

@Component({
  selector: 'obc-filled-form-edit',
  templateUrl: './filled-form-edit.component.html',
  styleUrls: ['./filled-form-edit.component.scss']
})
export class FilledFormEditComponent implements OnInit {

  @Input() displayAsModal: boolean;
  @Input() filledFormDataId: number;
  @Input() classes: string;
  @Input() noIcon: boolean = false;
  @Output('onUpdateForm') onUpdateFormEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  inProgress: boolean = false;
  bsEditModalRef: BsModalRef;

  constructor(private bsModalService: BsModalService,
    private formService: FormService,
    private siteAssetService: SiteAssetService,
    private modalService: ModalService) {
  }

  ngOnInit(): void {
  }

  displayModal(template: any) {
    this.bsEditModalRef = this.bsModalService.show(template, {
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });
  }

  closeModal() {
    this.bsEditModalRef.hide();
  }

  isFormValid: boolean = false;

  formInformationModel: EditFormInformationModel;

  updateFormInformationModel(value: EditFormInformationModel) {
    this.formInformationModel = value;
  }

  validateForm(formIsValid: boolean) {
    this.isFormValid = formIsValid
  }

  updateFilledForm(data: FormDataModel) {
    this.inProgress = true;

    let request;

    switch (this.formInformationModel?.fillFormMode) {
      case FillFormMode.General:
      case FillFormMode.SupplierDocument:
      case FillFormMode.Permit:
        request = this.formService.saveForm(data);
        break;
      case FillFormMode.AssetOperator:
        request = this.siteAssetService.registerAssetOperator({
          firstName: this.formInformationModel?.draft?.operatorFirstName,
          lastName: this.formInformationModel?.draft?.operatorLastName,
          mobile: this.formInformationModel?.draft?.operatorMobile,
          formData: data,
          siteAssetId: this.formInformationModel?.draft?.siteAssetId,
        } as SiteAssetOperatorModel)
        break;
      case FillFormMode.AssetRegistration:
        request = this.siteAssetService.registerSiteAsset({
          formDatamodel: data,
          siteAssetId: this.formInformationModel?.draft?.siteAssetId,
          siteId: this.formInformationModel?.draft?.siteId,
          toolId: this.formInformationModel?.draft?.assetId,
          operatorRegistrationMode: this.formInformationModel?.operatorMode,
          temporarilyTool: null
        } as SiteAssetRegistrationModel)
        break;
      case FillFormMode.AssetOperation:
        request = this.siteAssetService.submitAssetOperation({
          formData: data,
        } as SiteAssetOperationModel, this.formInformationModel?.draft?.siteAssetId,)
        break;
    }


    request
      .pipe(finalize(() => {
        this.inProgress = false;
      }))
      .subscribe(res => {
        if (res != null && res instanceof Object) {
          if (res.success == true) {
            this.modalService.success("Form Updated Successfully");
            this.bsEditModalRef.hide();
            this.onUpdateFormEmitter.emit(true);
          } else {
            this.modalService.error(res.message);
          }
        } else {
          if (res) {
            this.modalService.success("Form Updated Successfully");
            this.bsEditModalRef.hide();
            this.onUpdateFormEmitter.emit(true);
          } else {
            this.modalService.error("Form not saved");
          }
        }
      },
        (err) => {
          this.modalService.error(err);
          this.onUpdateFormEmitter.emit(false);
        }, () => {
          this.inProgress = false;
        })
      ;
  }
}
