import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { IntegrationOperation, ProcoreIntegrationType } from 'src/app/enums/procore-integration-type.enum';
import { CompanyViewModel } from 'src/app/models/company-viewmodel';
import { DateRangeModel } from 'src/app/models/filter-models/filter-date-range-model';
import { ResetProcoreSyncData, ResetProcoreSyncDataItem, ResetProcoreSyncModel } from 'src/app/models/procore/reset-procore-sync-model';
import { ServerResultData } from 'src/app/models/server-result-data';
import { SiteViewModel } from 'src/app/models/site-viewmodel';
import { ModalService } from 'src/app/services/modal.service';
import { ProcoreService } from 'src/app/services/procore.service';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'obc-procore-force-to-sync',
  templateUrl: './procore-force-to-sync.component.html',
  styleUrls: ['./procore-force-to-sync.component.scss']
})
export class ProcoreForceToSyncComponent implements OnInit {
  inProgress: boolean = false;

  DatetimePickerMode = DatetimePickerMode;
  procoreSyncTypes: IntegrationOperation[] = [
    IntegrationOperation.ExportTimeCards,
    IntegrationOperation.ExportVisitorLogs,
    IntegrationOperation.ExportManpowerLogs,
    //IntegrationOperation.ExportAggregatedManpower,
    IntegrationOperation.ExportSiteSupplierDocuments,
    IntegrationOperation.ExportPermitForms,
    IntegrationOperation.ExportSiteInductions,
    IntegrationOperation.ExportSiteAssetForms,
    IntegrationOperation.ExportSiteBriefings,
  ];

  selectedCompany: CompanyViewModel;
  selectedSites: SiteViewModel[] = [];
  selectedProcoreIntegrationType: IntegrationOperation[] = [];
  dateRange: DateRangeModel = { from: null, to: null };
  resetData: ResetProcoreSyncData;

  get isValid() {
    return this.selectedCompany?.id &&
      this.selectedSites?.length &&
      this.selectedProcoreIntegrationType?.length &&
      this.dateRange?.from &&
      this.dateRange?.to &&
      this.dateRange.from < this.dateRange.to;
  }

  get requestModel(): ResetProcoreSyncModel {
    return {
      companyId: this.selectedCompany.id,
      siteIdList: this.selectedSites.map(s => s.id),
      dateRange: this.dateRange,
      integrationOperationsToReset: this.selectedProcoreIntegrationType,
    } as ResetProcoreSyncModel;
  }

  constructor(private siteService: SiteService, private procoreService: ProcoreService, private modalService: ModalService) { }

  ngOnInit(): void {

  }
  onSelectedCompany($event: CompanyViewModel) {
    this.selectedCompany = $event;
    this.selectedSites = [];
    this.resetData = null;
    this.loadSites();
  }
  loadSites() {
    this.siteService.getAllCompanySites(this.selectedCompany.id);
  }

  onSiteSelect(site: SiteViewModel) {
    if (!this.selectedSites.some(s => s.id == site.id))
      this.selectedSites.push(site);
  }

  removeSelectedSite(siteId: any) {
    this.selectedSites = this.selectedSites.filter(s => s.id != siteId);
  }

  onSelectSyncType(procoreIntegrationType: IntegrationOperation, values: any) {
    if (values.currentTarget.checked && !this.selectedProcoreIntegrationType.some(d => d == procoreIntegrationType))
      this.selectedProcoreIntegrationType.push(procoreIntegrationType);
    else
      this.selectedProcoreIntegrationType = this.selectedProcoreIntegrationType.filter(d => d != procoreIntegrationType);
  }
  getIntegrationDescription(type: IntegrationOperation) {
    if (type == IntegrationOperation.ExportPermitForms)
      return 'Include Permit Events';
    if (type == IntegrationOperation.ExportSiteAssetForms)
      return 'Site Asset, Asset Operator and Asset Operation Forms';
    return '';

  }
  onSelectFromDate(date: Date) {
    this.dateRange.from = date;
  }
  onSelectToDate(date: Date) {
    this.dateRange.to = date;
  }

  getProcoreResetData() {
    this.resetData = null;
    this.procoreService.getProcoreResetData(this.requestModel)
      .subscribe(
        res => {
          if (res.success)
            this.resetData = res.data;
          else
            this.modalService.error(res.message ?? 'Fetching data was failed!');
        });
  }

  resetSyncStatus() {
    this.procoreService.procoreSyncReset(this.requestModel)
      .subscribe(res => {
        if (res.success) {
          this.resetData = null;
          this.modalService.success('Reset request was registered.')
        }
      });
  }
  cancelSync() {
    this.resetData = null;
  }

}
