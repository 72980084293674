import { Pipe, PipeTransform } from '@angular/core';
import { Gender } from '../enums/gender.enum';
import { isNumber } from '../helpers/general-functions';

@Pipe({
  name: 'gender',
})
export class GenderPipe implements PipeTransform {
  transform(value: unknown): string {
    if (isNumber(value)) {
      switch (value) {
        case Gender.female:
          return 'Female';
        case Gender.male:
          return 'Male';
        case Gender.other:
          return 'Other';
        default:
          return '?';
      }
    }
    return value?.toString();
  }
}
