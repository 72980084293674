import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { LocalStorageKey } from '../enums/local-storage-key.enum';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  i: number = 0;
  isDark = new BehaviorSubject<boolean>(null);
  get leftMenuIsOpen(): boolean {
    return (this.storageService.get(LocalStorageKey.LeftMenuState) === null);
  }

  get isInDarkMode(): boolean {
    if (this.isDark.value == null)
      this.isDark.next(this.storageService.get(LocalStorageKey.DarkClassName) !== null);
    return this.isDark.value;
  }

  header$: Subject<string> = new Subject<string>();
  set header(_val: string) {
    this.header$.next(_val);
  };

  constructor(private storageService: StorageService) {
    this.header$.next('');
  }

  toggleSideBar() {
    document.documentElement.classList.toggle('sidebar-left-collapsed');
    if (this.leftMenuIsOpen) {
      this.storageService.set(LocalStorageKey.LeftMenuState, 'close');
    }
    else {
      this.storageService.remove(LocalStorageKey.LeftMenuState);
    }
  }

  closeSideBar() {
    document.documentElement.classList.add('sidebar-left-collapsed');
  }

  toggleSideBarInMobileMode() {
    document.documentElement.classList.toggle('sidebar-left-opened');
  }

  closeSideBarInMobileMode() {
    document.documentElement.classList.remove('sidebar-left-opened');
  }

  toggleSubMenu(event, ul) {
    ul.classList.toggle('nav-expanded');
    event.preventDefault();
    event.stopPropagation();
  }

  //#region top bar
  public toggleDarkMode(isDark, saveInSessionStorage: boolean = false, updateStorages: boolean = true) {
    if (isDark) {
      if(updateStorages)
        this.storageService.set(LocalStorageKey.DarkClassName, 'dark', saveInSessionStorage);
      document.documentElement.classList.add('dark');
    }
    else {
      if(updateStorages)
        this.storageService.remove(LocalStorageKey.DarkClassName);
      document.documentElement.classList.remove('dark');
    }
    this.isDark.next(isDark);
  }


  public get getLogoPath(): string {
    if (this.isInDarkMode)
      return "assets/img/dealer-drive-logo-silver.png";
    else
      return "assets/img/dealer-drive-logo-light.png";
  }

  public get getLogoClass(): string {
    if (this.isInDarkMode)
      return "dark";
    else
      return "light";
  }

  public get getBackgroundColor(): string {
    if (this.isInDarkMode)
      return "#1D2127";
    else
      return "#5bc0de";
  }

  public get getColor(): string {
    if (this.isInDarkMode)
      return "white";
    else
      return "white";
  }

  //#endregion top bar
}
