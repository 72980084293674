<div class="px-1">
  <div class="row">
    <div class="col-lg-9 col-12">
      <pagination [totalItems]="totalCount" [itemsPerPage]="pageSize" [ngModel]="_pageNumber" *ngIf="!hidePagination()"
        (pageChanged)="localPageChanged($event)" [boundaryLinks]="showBoundaryLinks" [maxSize]="maxSize">
      </pagination>
    </div>
    <div class="col-lg-3 col-12 mt-2 mt-lg-0 ml-auto">
      <select class="form-control" (change)="onItemPerPageChange()" [(ngModel)]="pageSize">
        <option *ngFor="let item of itemsPerPageArray" [ngValue]="item">{{item}} Items Per Page
        </option>
      </select>
    </div>
  </div>
</div>
