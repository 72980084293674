import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { SiteAssetCheckInOutModel } from 'src/app/models/site-asset/site-asset-check-in-out-model';
import { BaseSiteViewModel } from 'src/app/models/site-viewmodel';
import { ModalService } from 'src/app/services/modal.service';
import { SiteAssetService } from 'src/app/services/site-asset.service';
import { InventoryLocationSelectorModel } from '../../../models/inventory/inventory-location-selector-model';
import { SiteAssetViewModel } from "../../../models/site-asset/site-asset-view-model";

@Component({
  selector: 'obc-site-asset-check-in-out',
  templateUrl: './site-asset-check-in-out.component.html',
  styleUrls: ['./site-asset-check-in-out.component.scss'],
})
export class SiteAssetCheckInOutComponent implements OnInit {
  @Input() isCheckinRequested: boolean;
  @Input() selectedSiteAsset: SiteAssetViewModel;
  @Input() selectedSite: BaseSiteViewModel;
  @Input() externalSiteSupplierId: number;
  @Input() displayAsApproveRejectButton: boolean = true;

  @Output() hide = new EventEmitter();
  @Output() onRefresh = new EventEmitter();

  inProgress: boolean = false;
  isUpdateLocationRequested: boolean = false;
  newLocation: string;
  toLocationId: number;

  constructor(
    private siteAssetService: SiteAssetService,
    private modalService: ModalService,
    private toastrService: ToastrService,
  ) {
  }

  ngOnInit(): void {
  }

  get isFormValid(): boolean {
    return (
      this.isUpdateLocationRequested == false ||
      this.toLocationId > 0 ||
      (this.newLocation != null && this.newLocation?.trim() != '')
    );
  }

  onActiveChanged(value) {
    this.isUpdateLocationRequested = value;
    if (this.isUpdateLocationRequested == false) {
      this.toLocationId = null;
      this.newLocation = null;
    }
  }

  onLocationChange($event: InventoryLocationSelectorModel) {
    this.toLocationId = $event?.selectedLocation?.id;
    this.newLocation = null;
  }

  onAddNewLocation(newLocation: InventoryLocationSelectorModel) {
    this.toLocationId = null;
    this.newLocation = newLocation?.newLocationTitle;
  }

  submit() {
    if (this.selectedSiteAsset.isTemporarilyAsset) {
      this.modalService.confirm(`Are you sure you want to ${this.isCheckinRequested ? 'check in' : 'check out'}?`, 'Confirm').subscribe(result => {
        if (result) {
          this.submitConfirmed();
        }
      })
    } else
      this.submitConfirmed();
  }

  submitConfirmed() {
    this.inProgress = true;

    let model =
      this.isUpdateLocationRequested == true
        ? ({
          toLocationId: this.toLocationId,
          newLocation: this.newLocation,
        } as SiteAssetCheckInOutModel)
        : null;

    var request = this.isCheckinRequested
      ? this.siteAssetService.checkInAsset(this.selectedSiteAsset.siteAssetId, model)
      : this.siteAssetService.checkOutAsset(this.selectedSiteAsset.siteAssetId, model);

    request
      .pipe(
        finalize(() => {
          this.inProgress = false;
        })
      )
      .subscribe(
        (res) => {
          if (res) {
            this.toastrService.success(
              this.isCheckinRequested == true
                ? 'The Asset checked in successfully'
                : 'The Asset checked out successfully'
            );
          } else {
            this.toastrService.error(
              `The asset is already checked ${this.isCheckinRequested == true ? 'in' : 'out'
              }!`
            );
          }
          this.onRefresh.emit();
          if (res) this.hide.emit();
        },
        (err) => {
          this.modalService.error(err ?? 'Request Failed!');
        }
      );
  }
}

