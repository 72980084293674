import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { convertObjectToUrlParameters } from '../helpers/extract-params-from-model';
import { EvacuationItemViewModel, EvacuationLogViewModel, EvacuationReportQueryModel, EvacuationRepotViewModel } from '../models/evacuation/evacuation-viewmodels';
import { ErrorService } from './error.service';
import {CsvReportRequestModel} from "../models/csv-report-request-model";
import { CompanyEvacuationSettings } from '../components/evacuation-setting-editor/evacuation-setting-models/CompanyEvacuationSettings';

@Injectable({
  providedIn: 'root'
})
export class EvacuationService {

  apiUrl: string = "/api/evacuation";
  constructor(private http: HttpClient, private _errorService: ErrorService) { }

  getEvacuations(model: EvacuationReportQueryModel): Observable<EvacuationRepotViewModel> {
    return this.http.post<EvacuationRepotViewModel>(`${this.apiUrl}/list`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  getEvacuation(evacuationId: number): Observable<EvacuationItemViewModel> {
    return this.http.get<EvacuationItemViewModel>(`${this.apiUrl}/get/${evacuationId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  getEvacuationLogs(evacuationId: number): Observable<EvacuationLogViewModel[]> {
    return this.http.get<EvacuationLogViewModel[]>(`${this.apiUrl}/${evacuationId}/logs`)
      .pipe(catchError(this._errorService.handleError));
  }

  exportEvacuations(model: CsvReportRequestModel) {
    const params = convertObjectToUrlParameters(model.requestParams);
    return this.http.post<any>(`${this.apiUrl}/evacuations-report-csv-as-job?${params}`, model)
        .pipe(catchError(this._errorService.handleError));
  }

  getSettings(): Observable<CompanyEvacuationSettings> {
    return this.http.get<CompanyEvacuationSettings>(`${this.apiUrl}/settings`)
      .pipe(catchError(this._errorService.handleError));
  }

  updateSettings(settings: CompanyEvacuationSettings): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/settings`, settings)
      .pipe(catchError(this._errorService.handleError));
  }

  exportEvacuationPeople(evacuationId: number) {
    return window.open(`/api/Evacuation/evacuation-people-report-csv/${evacuationId}`);
  }
}
