import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';
import { SignInService } from 'src/app/services/sign-in.service';
import { UserService } from 'src/app/services/user.service';
import { UserInfoViewModel } from 'src/app/models/user-info-viewmodel';
import { CompanyViewModel } from 'src/app/models/company-viewmodel';
import { ModalService } from 'src/app/services/modal.service';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { ProductPlanFeature } from 'src/app/enums/product-plan-feature.enum';
import { ScheduledReportLogViewModel } from "../../models/scheduled-report-log-view-model";
import { SignalRService } from "../../services/signal-r.service";
import { ToastrService } from "ngx-toastr";
import { EventType } from "../../enums/event-type";
import { NotificationServerResponseViewModel } from "../../models/notification-server-response-view-model";

@Component({
  selector: 'obc-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  @Input() showEmergencyAlaram: boolean = true;
  @Input() isAnonymous: boolean = false;

  @ViewChild('notificationButton') notificationButton: ElementRef;
  @ViewChild('notificationPanel') notificationPanel: ElementRef;
  isNotificationMenuOpen: boolean = false;
  @ViewChild('userbox') userbox;
  showUserBox: boolean = false;
  notificationCount: string;

  fullName: string = "User Full Name";
  displayInfo: UserInfoViewModel = null;
  isCompanyOwner: boolean = false
  userPermissions = UserPermission;
  ProductPlanFeature = ProductPlanFeature;
  loadingNotification: boolean = false;

  constructor(public layoutService: LayoutService, private signInService: SignInService,
    private signalRService: SignalRService,
    public userService: UserService,
    public toastr: ToastrService,
    private modalService: ModalService, private renderer: Renderer2) {
    document.addEventListener('click', this.closeUserBoxOnClickOutside.bind(this));

    //Show/hide Notification Panel
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.notificationButton?.nativeElement && e.target !== this.notificationPanel?.nativeElement && !this.notificationPanel?.nativeElement!.contains(e.target)) {
        this.isNotificationMenuOpen = false;
      }
    });
  }

  toggleNotificationMenu() {
    this.isNotificationMenuOpen = !this.isNotificationMenuOpen;
  }

  _currentCompany: CompanyViewModel = null;
  get currentCompany(): CompanyViewModel {
    return this._currentCompany;
  }

  userScheduledReportLogs: ScheduledReportLogViewModel[];

  ngOnInit(): void {
    this.signalRService.serverMessage.asObservable().subscribe((res: NotificationServerResponseViewModel) => {
      switch (res?.eventType) {
        case EventType.SubmittingScheduledReport:
          this.signalRScheduledReportsRequest();
          break;
        case EventType.ScheduleReportTaskResult:
          if (res?.message) {
            if (res?.isSuccess) {
              this.toastr.success(res?.message)
            } else {
              this.toastr.error(res?.message)
            }
          }
          this.signalRScheduledReportsRequest();
          break;
        case EventType.Notification:
          this.loadingNotification = true;
          break;
        case EventType.ShowNotification:
          if (res?.message) {
            if (res?.isSuccess) {
              this.toastr.success(res?.message)
            } else {
              this.toastr.error(res?.message)
            }
          }
      }
    });

    this.isCompanyOwner = this.userService.isCompanyAdminUser();

    this.userService.info.subscribe(res => {
      this.displayInfo = res;
      this.isCompanyOwner = res?.userPermissions?.isCompanyAdmin;
      if (res) {
        this.getScheduledReports();
      }
    });

    this.userService.currentCompany$.subscribe(res => this._currentCompany = res);
  }
  hasExportFeatureOnCompany() {
    return this.userService.hasCompanyFeature(ProductPlanFeature.Export);
  }

  signalRScheduledReportsRequest() {
    this.csvExportTitle = 'Fetching';
    this.getScheduledReports();
  }

  loadingScheduledReports: boolean = false;
  csvExportTitle: string = 'Exports';

  getScheduledReports() {
    if (this.isAnonymous)
      return;

    this.loadingScheduledReports = true;
    this.userService.getScheduledReports().subscribe({
      next: (res) => {
        if (res.success && res.data) {
          this.userScheduledReportLogs = res.data;
        }
        this.loadingScheduledReports = false;
        this.csvExportTitleFunc();
      },
      error: (_) => {
        this.loadingScheduledReports = false;
        this.csvExportTitleFunc();
      },
      complete: () => {
        this.loadingScheduledReports = false;
        this.csvExportTitleFunc();
      }
    });
  }

  csvExportTitleFunc() {
    if (this.userScheduledReportLogs.find((s) => s?.isInProgressState)) {
      this.csvExportTitle = "Exporting";
      this.loadingScheduledReports = true;
    } else {
      this.csvExportTitle = "Exports";
      this.loadingScheduledReports = false;
    }
  }

  onCsvPopoverClick($event) {
    if ($event.target.id != 'cancel') {
      $event.stopPropagation()
    }
  }

  closeUserBoxOnClickOutside(event: any) {
    if (!this.userbox?.nativeElement?.contains(event.target)) {
      this.showUserBox = false;
    }
  }

  signOut() {
    this.signInService.signOut();
  }

  get isTemplateCompany(): boolean {
    if (this._currentCompany == null)
      return false;
    else
      return this._currentCompany.isTemplate;
  }

  get companyName(): string {
    if (this._currentCompany == null)
      return null;
    else
      return this._currentCompany.title;
  }

  openModal(template) {
    this.modalService.show(template, 'modal-lg');
  }

  closeModal() {
    this.modalService.hide();
  }

  onUpdateNotificationCount(notificationCount: string) {
    this.notificationCount = notificationCount;
    this.loadingNotification = false;
  }

  displayRecipients(recipients: string[]) {
    let outputArray = [...recipients];
    let output = outputArray.join(", ");
    if (outputArray.length > 2) {
      outputArray.splice(2);
      output = outputArray.join(", ");
      if ((recipients.length - 2) > 0)
        output += (` +${recipients.length - 2} more`)
    }
    return output;
  }
}
