<div class="card">
  <obc-loader [show]="inProgress"></obc-loader>
  <div class="card-header">
    <h4>Access Control Unit</h4>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12 mb-4">
        <label>Select Site</label>
        <ng-select #select [items]="siteAccessControlUnits" bindLabel="siteName" bindValue="siteId"
          [formControl]="formControl" [clearable]="false" placeholder="Please select a site">
        </ng-select>
      </div>
      <div class="col-md-12">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Display Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody *ngIf="selectedSiteAccessControlUnit">
            <tr *ngFor="let item of selectedSiteAccessControlUnit.accessControlUnits; let i = index">
              <td>{{item.displayName}}</td>
              <td style="display: flex;">
                <span *ngFor="let gate of item.accessControlUnitGates" [attr.type]="gate.accessControlUnitGateType">
                  <button type="button"
                    class="btn btn-{{!gate.identifier? 'outline-secondary':gate.accessControlUnitGateType == AccessControlUnitGateType.Entry?'success':gate.accessControlUnitGateType == AccessControlUnitGateType.Exit?'primary':gate.accessControlUnitGateStatus == AccessControlUnitGateStatus.Open ?'danger':'info'}}"
                    (click)="handleGate(item,gate)" [disabled]="!gate.identifier">
                    <i
                      [class]="gate.accessControlUnitGateType == AccessControlUnitGateType.Evacuation?gate.accessControlUnitGateStatus == AccessControlUnitGateStatus.Close?'fa fa-door-open':'fa fa-door-closed':''"></i>
                    {{getGateButtonTitle(gate)}}
                  </button>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>