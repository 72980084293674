import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SiteAttendancePermissionUser } from '../models/site-attendance-permission-models/site-attendance-permission-user.model';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class SiteAttendancePermissionService {

  apiUrl: string = "/api/SiteAttendancePermission";
  constructor(private http: HttpClient, private _errorService: ErrorService) { }

  addSinglePerson(attendee: SiteAttendancePermissionUser): Observable<SiteAttendancePermissionUser> {
    return this.http.post<SiteAttendancePermissionUser>(`${this.apiUrl}/add-single-person`, attendee)
      .pipe(catchError(this._errorService.handleError));
  }

  addMultiplePeople(siteId: number, phones: string): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}/add-multiple-people/${siteId ? siteId : ''}`, { data: phones })
      .pipe(catchError(this._errorService.handleError));
  }

  importSiteData(data: SiteAttendancePermissionUser[]): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/import-site-attendees`, data)
      .pipe(catchError(this._errorService.handleError));
  }

  updateSiteAttendee(attendee: SiteAttendancePermissionUser): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/update-site-attendee`, attendee)
      .pipe(catchError(this._errorService.handleError));
  }

  removeSiteAttendee(siteId: number, userId: number): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/remove-site-attendee`, { siteId: siteId, userId: userId })
      .pipe(catchError(this._errorService.handleError));
  }

  removeCompanyAttendee(userId: number): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/remove-company-attendee`, { userId: userId })
      .pipe(catchError(this._errorService.handleError));
  }

  getSiteAttendees(siteId: number, isTemporary: boolean): Observable<SiteAttendancePermissionUser[]> {
    return this.http.get<SiteAttendancePermissionUser[]>(`${this.apiUrl}/get-site-attendees/${siteId}/${isTemporary}`)
      .pipe(catchError(this._errorService.handleError));
  }

  getCompanyAttendees(isTemporary: boolean): Observable<SiteAttendancePermissionUser[]> {
    return this.http.get<SiteAttendancePermissionUser[]>(`${this.apiUrl}/get-company-attendees/${isTemporary}`)
      .pipe(catchError(this._errorService.handleError));
  }

}
