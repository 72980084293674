import { Component, OnInit } from '@angular/core';
import { UserAutoCompleteFilterByFields } from 'src/app/enums/user-auto-complete-target-fields';
import { ModalService } from 'src/app/services/modal.service';
import { finalize } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { SiteAttendanceTriggerResultViewModel } from 'src/app/models/site-attendance-trigger-result-view-model';
import { SiteAttendanceTriggerResultService } from 'src/app/services/site-attendance-trigger-result.service';
import { SiteAttendanceTriggerResultSearchModel } from 'src/app/models/site-attendance-trigger-result-search-model';
import { SiteService } from 'src/app/services/site.service';
import { SiteViewModel } from 'src/app/models/site-viewmodel';
import { UserBasicInfoViewModel } from 'src/app/models/user-basic-info-viewmodel';
import { ExportAsConfig, FieldType, ExportAsService } from '../../../services/export-as.service';
import { ExportAsFileType } from '../../../enums/export-as-file-type';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { ProductPlanFeature } from 'src/app/enums/product-plan-feature.enum';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';

@Component({
  selector: 'obc-flagged-user',
  templateUrl: './flagged-user.component.html',
  styleUrls: ['./flagged-user.component.scss']
})
export class FlaggedUserComponent implements OnInit {
  DatetimePickerMode = DatetimePickerMode;
  ProductPlanFeature = ProductPlanFeature;
  inProgress: boolean = false;
  addNewMode: boolean = false;
  filterUserBy = UserAutoCompleteFilterByFields;
  flaggedUsersList: SiteAttendanceTriggerResultViewModel[] = [];
  originalFlaggedUsersList: SiteAttendanceTriggerResultViewModel[] = [];
  flaggedUser: SiteAttendanceTriggerResultViewModel = null;
  serachModel: SiteAttendanceTriggerResultSearchModel = new SiteAttendanceTriggerResultSearchModel();
  siteList: SiteViewModel[] = [];
  selectedDate: Date;
  filterListTerm: string;
  constructor(public userService: UserService,
    private siteAttendanceTriggerResultService: SiteAttendanceTriggerResultService,
    private modalService: ModalService,
    private siteService: SiteService,
    private exportAsService: ExportAsService) { }

  ngOnInit(): void {
    this.getSiteList();
  }

  getSiteList() {
    this.siteService.getAllPermissionedActiveSites(UserPermission.ManageViewTaggedUser)
      .subscribe(result => {
        this.siteList = result;
        this.serachModel.isFlagged = null;
        this.serachModel.isRejected = null;
        this.serachModel.isReviewed = false;
        this.serachModel.siteId = null;
        this.geSiteAttendanceTriggerResults();
      }, error => { });
  }

  geSiteAttendanceTriggerResults() {
    this.inProgress = true;
    //this.setFilterLabel();
    this.siteAttendanceTriggerResultService.getSiteAttendanceTriggerResults(this.serachModel)
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(res => {
        this.flaggedUsersList = res;
        this.originalFlaggedUsersList = res;
      }, err => console.log(err));
  }

  selectedUserIsReviewed: boolean = false;
  initEdit(user) {
    this.selectedUserIsReviewed = user.isReviewed;
    user.inEdit = true;
    this.flaggedUser = user;
  }

  changeReviewStatus(user: SiteAttendanceTriggerResultViewModel) {
    if (!this.isValidate(user)) return;
    if (this.selectedUserIsReviewed === user.isReviewed) return;
    this.inProgress = true;
    this.siteAttendanceTriggerResultService.updateFlaggState(user.id, user.isReviewed)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(result => {
        this.geSiteAttendanceTriggerResults();
        user.inEdit = false;
      },
        error => { this.modalService.error(error) });
  }

  isValidate(flaggedUser: SiteAttendanceTriggerResultViewModel) {
    if (!flaggedUser.id)
      return false;

    if (!flaggedUser.userId)
      return false;

    if (flaggedUser.isReviewed === undefined || flaggedUser.isReviewed === null)
      return false;

    return true;
  }

  selectedUser: UserBasicInfoViewModel
  selectUser(user: UserBasicInfoViewModel) {
    if (!user || !user.id) return
    this.serachModel.userId = user.id;
    this.selectedUser = user;
  }

  setFilterLabel() {
    this.serachModel['isFlaggedText'] = this.serachModel.isFlagged ? 'Flagged Users' : 'UnFlagged Users';
    this.serachModel['selectedSiteName'] = this.serachModel.siteId ? this.siteList.find(c => c.id === this.serachModel.siteId).name : undefined;
    this.serachModel["fullName"] = this.selectedUser.firstName + ' ' + this.selectedUser.lastName;
    this.serachModel['isFlaggedText'] = this.serachModel.isFlagged ? 'Flagged Users' : 'UnFlagged Users';
  }

  reset() {
    this.serachModel = new SiteAttendanceTriggerResultSearchModel();
    this.selectedDate = undefined;
    this.selectedUser = JSON.parse(JSON.stringify(new UserBasicInfoViewModel()));
    this.serachModel.isFlagged = null;
    this.serachModel.isRejected = null;
    this.serachModel.isReviewed = false;
    this.geSiteAttendanceTriggerResults();
  }

  cancel(user) {
    user.inEdit = false
    user.isFlagged = this.serachModel.isFlagged;
  }

  setNow() {
    this.serachModel.checkInDate = new Date().toLocaleString();
    this.selectedDate = new Date();
  }

  dateChanges($event: Date) {
    this.serachModel.checkInDate = $event?.toLocaleString();
    this.selectedDate = $event;
  }

  exportCsv() {
    let config: ExportAsConfig = {
      fileType: ExportAsFileType.CSV,
      preFileName: 'tagged-users-report',
      fields: [
        { fieldName: 'userFullName', title: 'Full Name' },
        { fieldName: 'mobile', title: 'Mobile', type: FieldType.Mobile },
        { fieldName: 'siteName', title: 'Site' },
        { fieldName: 'checkInDate', title: 'CheckIn Date', type: FieldType.DateTime },
        { fieldName: 'isFlagged', title: 'Tagged', type: FieldType.Boolean },
        { fieldName: 'isRejected', title: 'Rejected', type: FieldType.Boolean },
        { fieldName: 'rejectMessage', title: 'Reject Message' },
        { fieldName: 'isReviewed', title: 'Reviewed', type: FieldType.Boolean },
      ],
    }
    this.exportAsService.exportAs(this.flaggedUsersList, config);
  }
}
