import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dicToArray'
})
export class DicToArrayPipe implements PipeTransform {

  transform(value: any, args?: any): any[] {
    var keys = Object.keys(value);
    var ret = [];
    for (let index = 0; index < keys.length; index++) {
      const element = keys[index];
      ret.push({key : keys[index] , value : value[element]})  
    }
    return ret;
  }

}
