<div>
  <label *ngIf="label">{{label}}</label>
  <div *ngIf="!model">
    <obc-user-autocomplete [filterBy]="filterUserBy.All" 
      (selectedUser)="onSelectUser($event)" [required]="required"></obc-user-autocomplete>
    <span>Select Registered User</span>
  </div>
  <div *ngIf="model">
    <div class="d-flex flex-row justify-content-between">
      <div>
        <div>Name : {{model.firstName}} {{model.lastName}}</div>
        <div>Mobile : {{model.mobile | mobile}}</div>
      </div>
      <div *ngIf="!hideRemoveButton">
        <button class="btn btn-info" (click)="onRemove()"><i class="fas fa-edit"></i></button>
      </div>
    </div>
  </div>
</div>