<obc-loader [show]="inProgress"></obc-loader>
<div class="row" *ngIf="!currentLayout">
    <div class="col-sm-12">
        <section class="card card-featured-left card-featured-tertiary mb-4">
            <div class="card-body">
                <div class="widget-summary">
                    <div class="widget-summary-col widget-summary-col-icon">
                        <div class="summary-icon bg-tertiary">
                            <i class="fas fa-building"></i>
                        </div>
                    </div>
                    <div class="widget-summary-col d-flex align-items-center justify-content-end" style="height: 90px;">
                        <button (click)="openConfigLayout(layoutpage,null)" class="pull-right btn btn-info">
                            <i class="fas fa-plus"></i>
                            Add New Layout
                        </button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<div class="row" *ngIf="currentLayout">
    <div class="col-sm-12">
        <section class="card card-featured-left card-featured-tertiary mb-4">
            <div class="card-body">
                <div class="widget-summary">
                    <div class="widget-summary-col widget-summary-col-icon">
                        <div class="summary-icon bg-tertiary">
                            <i class="fas fa-building"></i>
                        </div>
                    </div>
                    <div class="widget-summary-col">
                        <div class="summary">
                            <h4 class="title">Select Layout: </h4>
                            <div class="info mt-2 input-group">
                                <select (ngModelChange)="modelchange($event)" [(ngModel)]="selectedLayoutId"
                                    class="form-control">
                                    <option *ngFor="let layout of layoutList" [ngValue]="layout.id">{{layout.name}}
                                    </option>
                                </select>
                                <span class="input-group-prepend pointer"
                                    (click)="openConfigLayout(layoutpage,currentLayout)">
                                    <span class="input-group-text">
                                        <i class="fas text-info fa-edit"></i>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="summary-footer">
                            <div class="row">
                                <a
                                    class="col-3 pull-left text-left text-danger text-uppercase">{{currentLayout.name}}{{layoutChanged? '*':''}}</a>
                                <div class="col-9 ">
                                    <button (click)="openConfigLayout(layoutpage,null)"
                                        class="pull-right ml-1 btn btn-info">
                                        <i class="fas fa-plus"></i>
                                        Add New Layout
                                    </button>
                                    <button (click)="confirmRemoveLayout()" class="pull-right btn btn-danger">
                                        <i class="fas fa-times"></i>
                                        Remove Layout</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<div class="row" *ngIf="currentLayout">
    <div class="col-lg-4 mb-4 mb-xl-0">
        <div class="card">
            <div class="card-header border-bottom-0">
                <h4 class="card-title">
                    <a class="accordion-toggle">
                        <i class="fas fa-check mr-1"></i> Select Elements
                    </a>
                </h4>
            </div>
            <div id="collapse1One" class="accordion-body collapse show">
                <div class="card-body">
                    <table class="table table-responsive-md table-striped mb-0">
                        <thead>
                            <tr>
                                <th>Element Name</th>
                                <th>Add to layout</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let element of elementRepository">
                                <td> {{element.name }}</td>
                                <td><button class="btn btn-success pull-right" (click)="addElementToLayout(element)"
                                        title="Click To Add This Element To Layout">
                                        <i class="fas fa-arrow-right"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <hr class="solid mt-3 mb-3">
                    <div *ngIf="false" class="form-horizontal form-bordered">
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control">
                                    <div class="input-group-append">
                                        <button type="button" class="btn btn-primary" tabindex="-1">Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="col-lg-8">
        <div class="tabs">
            <ul class="nav nav-tabs tabs-primary">
                <li class="nav-item pointer" [ngClass]="designerSelected === true ? 'active' : ''">
                    <a class="nav-link pointer" (click)="designerSelected = true">Designer</a>
                </li>
                <li class="nav-item pointer" [ngClass]="designerSelected === false ? 'active' : ''">
                    <a class="nav-link pointer" (click)="designerSelected = false">Preview</a>
                </li>
                <li *ngIf="layoutChanged && designerSelected" class="">
                    <a (click)="saveLayout()" class="btn btn-success">
                        <i class="fa fa-save"></i>
                        Save Changes
                    </a>
                </li>
            </ul>
            <div class="tab-content" [ngSwitch]="designerSelected">
                <div class="tab-pane" *ngSwitchCase="true" [ngClass]="designerSelected === true ? 'active' : ''">
                    <section class="simple-compose-box mb-3 container h-100">
                        <obc-draggable-element-page class="row h-100 justify-content-center align-items-center"
                            [printLayout]="currentLayout"></obc-draggable-element-page>
                        <div class="compose-box-footer">
                            <!-- <ul class="compose-toolbar">
                                <li>
                                    <a href="#"><i class="fas fa-save"></i></a>
                                </li>
                            </ul> -->
                            <!-- <ul class="compose-btn">
                                <li>
                                    <a (click)="saveLayout()" class="btn btn-success">Save Layout</a>
                                </li>
                            </ul> -->
                        </div>
                    </section>
                </div>
                <div class="tab-pane" *ngSwitchCase="false" [ngClass]="designerSelected === false ? 'active' : ''">
                    <section class="simple-compose-box mb-3 container h-100">
                        <obc-draggable-element-page class="row h-100 justify-content-center align-items-center"
                            [printLayout]="currentLayout" [freeze]="true"></obc-draggable-element-page>
                        <div class="compose-box-footer">
                            <ul class="compose-toolbar">
                                <li>
                                    <a href="#"><i class="fas fa-print"></i></a>
                                </li>
                            </ul>
                            <!-- <ul class="compose-btn">
                                    <li>
                                        <a href="#" class="btn btn-primary btn-xs">Post</a>
                                    </li>
                                </ul> -->
                        </div>
                    </section>

                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #layoutpage>
    <obc-layout-page [layout]="layoutForConfig"></obc-layout-page>
</ng-template>