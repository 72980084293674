import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { UserDocumentType } from "../../models/user-document-type";
import { FormControl, FormGroup } from "@angular/forms";
import { UserDocumentTypeService } from "../../services/user-document-type.service";
import { CompanyDocumentTypeService } from 'src/app/services/company-document-type.service';

@Component({
  selector: 'obc-document-type-nested-selector',
  templateUrl: './document-type-nested-selector.component.html',
  styleUrls: [ './document-type-nested-selector.component.scss' ]
})
export class DocumentTypeNestedSelectorComponent implements OnInit {
  constructor(private userDocumentTypeService: UserDocumentTypeService,
              private companyDocumentTypeService: CompanyDocumentTypeService) {
  }

  formControl = new FormControl(null);
  term$ = new BehaviorSubject<string>(null)
  loading: boolean = false;
  allRequiredUserDocuments: UserDocumentType[];
  mainRequestResult: UserDocumentType[];

  @Input() getAllCompaniesDocumentTypes: boolean = false;
  @Input() onlySelectables: boolean = false;
  @Input() isMultiple: boolean = false;
  @Input() controlName: string;
  @Input() includeNullValue: boolean;
  @Input() nullValueCaption: string = 'No Parent';
  allCompaniesDocumentTypes: UserDocumentType[];

  _ignoreUserDocumentIds: number[];
  @Input() set ignoreUserDocumentIds(value: number[]) {
    this._ignoreUserDocumentIds = value;
    this.filterDocuments();
  }

  @Input() firstLoadedSelectedDocumentTypeIds: (number[] | number);

  @Input() set selectedDocumentTypes(value: UserDocumentType[]) {
    if(value) {
      let valueToSet;
      if(!this.isMultiple) {
        valueToSet = value[0]?.id;
      } else {
        valueToSet = value?.map((item) => item?.id);
      }
      this.formControl?.setValue(valueToSet);
    }
  }

  @Output() result: EventEmitter<UserDocumentType[] | UserDocumentType> = new EventEmitter<UserDocumentType[] | UserDocumentType>();

  public form: FormGroup;

  ngOnInit(): void {
    this.userDocumentTypeService.GetAllCompaniesUserDocumentTypes()?.subscribe((res) => {
      this.allCompaniesDocumentTypes = res;
      this.mainRequestResult = [ ...res ];
      if(this.getAllCompaniesDocumentTypes) {
        this.process();
      } else {
        this.fetchCompanyDocumentTypes();
      }
    })
  }

  fetchCompanyDocumentTypes() {
    (this.onlySelectables ?
      this.companyDocumentTypeService.onlySelectableList() :
      this.userDocumentTypeService.getAll())
      .subscribe({
        next: (res) => {
          this.mainRequestResult = res;
          this.process();
        }
      });
  }

  filterDocuments() {
    this.allRequiredUserDocuments = this.mainRequestResult?.filter((item) => !(this._ignoreUserDocumentIds ?? [])?.includes(item?.id)) ?? [];
    if(this.includeNullValue && !this.isMultiple)
      this.allRequiredUserDocuments = [
        null,
        ...this.allRequiredUserDocuments
      ];
  }

  completelyProcess: boolean = false;
  process() {

    this.completelyProcess = false;
    this.filterDocuments();

    let value: any = this.firstLoadedSelectedDocumentTypeIds;
    if(Array.isArray(this.firstLoadedSelectedDocumentTypeIds)) {
      value = this.firstLoadedSelectedDocumentTypeIds?.filter((id) => !(this._ignoreUserDocumentIds ?? [])?.includes(id)) ?? [];
      if(!this.isMultiple) {
        if(value?.length > 0) {
          value = value[0];
        } else {
          value = this.allRequiredUserDocuments[0]?.id;
        }
      }
    } else {
      if(!this.isMultiple) {
        if(value == undefined || this._ignoreUserDocumentIds?.includes(value)) {
          value = this.allRequiredUserDocuments[0]?.id;
        }
      }
    }

    this.attachGlobalScopeSelectedItems(value);

    this.formControl.setValue(value ?? {"$ngOptionValue": null, "$ngOptionLabel": this.nullValueCaption, "disabled": false});
    if(this.isMultiple) {
      this.result.emit(this.allCompaniesDocumentTypes?.filter((item) => value?.includes(item?.id)));
    } else {
      this.result.emit(this.allCompaniesDocumentTypes?.find((item) => value == item?.id));
    }

    this.completelyProcess = true;
  }

  globalScopeSelectedItems: UserDocumentType[] = [];

  attachGlobalScopeSelectedItems(value: number | number[]) {
    let needToAttachIds = [];

    // adding those document types which are not in company customized document types anymore but they are still
    // exist as selected document types for this question
    let globalScopeSelectedDocumentTypesIds = value;
    if(!Array.isArray(globalScopeSelectedDocumentTypesIds)) {
      globalScopeSelectedDocumentTypesIds = [ globalScopeSelectedDocumentTypesIds ];
    }

    let companyCustomizedDocumentsIds = this.allRequiredUserDocuments?.map((item) => item?.id);
    globalScopeSelectedDocumentTypesIds.map((oldId) => {
      if(!companyCustomizedDocumentsIds?.includes(oldId)) {
        needToAttachIds.push(oldId);
      }
    })

    if(needToAttachIds.length > 0) {
      needToAttachIds?.map((id) => {
        let matchedObj = this.allCompaniesDocumentTypes?.find((obj) => obj.id == id);
        if(matchedObj) {
          this.globalScopeSelectedItems.push(matchedObj);
        }
      })
    }
  }

  findUserDocumentChildren(latestSelectedItem: number) {
    this.userDocumentTypeService.getUserDocumentTypeChildren({
      documentTypeId: latestSelectedItem,
      onlySelectables: this.onlySelectables,
    }).subscribe({
      next: (res) => {
        let uniqueIds = this.formControl.value;
        if(!Array.isArray(uniqueIds))
          uniqueIds = [ uniqueIds ];

        res?.map((item) => {
          let itemExistsInCompanySelectableItems = this.allRequiredUserDocuments?.find((companyItem) =>
            item?.id == companyItem.id
          );
          if(itemExistsInCompanySelectableItems)
            uniqueIds.push(item?.id)
        });
        uniqueIds = [ ...new Set(uniqueIds) ];
        this.result.emit(
          this.allCompaniesDocumentTypes?.filter((item) => {
            return uniqueIds?.includes(item?.id)
          })
        )
      }
    })
  }

  onAdd(selectedItemId: number) {
    // for some unknown reasons. on change send a list to this method which should not
    if(Array.isArray(selectedItemId)) return;

    if(selectedItemId && this.isMultiple) {
      this.formControl.setValue(this.formControl.value.filter((item) => item != selectedItemId));
      this.findUserDocumentChildren(selectedItemId);
    } else {
      let selectedItemObj = this.getDocumentTypeFromId(selectedItemId);
      this.result.emit(selectedItemObj)
    }
  }

  onRemove(item) {
    let selectedItemObj = this.getDocumentTypeFromId(item.value);
    let excludeItems = this.formControl.value?.filter((item) => item?.id != selectedItemObj.id);
    this.formControl.setValue(excludeItems);

    this.result.emit(
      this.allCompaniesDocumentTypes?.filter((item) => {
        return this.formControl.value?.includes(item?.id)
      })
    );
  }

  getDocumentTypeFromId(id) {
    return this.allCompaniesDocumentTypes?.find((item) => item?.id == id);
  }
}
