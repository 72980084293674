export enum SeatingMode {
    Disabled = 0,
    HostOnly = 1,
    HostAndUser = 2,
}


export const SeatingModeLabels = {
    0: "Disabled",
    1: "Host Only",
    2: "Host And User"
};
