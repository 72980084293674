import { Pipe, PipeTransform } from '@angular/core';
import { FormDataStatus } from '../enums/form-data-status';

@Pipe({
  name: 'permitStatusColor'
})
export class PermitStatusColorPipe implements PipeTransform {

  transform(value: FormDataStatus): string {
    var cssClass = "";
    if (value == FormDataStatus.NotRequired) cssClass = "";
    if (value == FormDataStatus.PendingApproval) cssClass = "text-warning";
    if (value == FormDataStatus.Approved) cssClass = "text-success";
    if (value == FormDataStatus.Rejected) cssClass = "text-danger";
    return cssClass;
  }

}
