<div class="row" *ngIf="formArray">
  <div class="col-md-6 col-12 mt-3" *ngFor="let control of formArray.controls | toAny">
    <div class="row">
      <div class="col-12" *ngIf="getRenderType(control.item.fieldType) == siteRequiredRenderTypes.EmergencyContact">
        <div class="d-flex flex-column">
          <ng-container
          *ngTemplateOutlet="fieldTitle, context: {control: control}"
          ></ng-container>
          <obc-emergency-contact
            [required]="isRequired(control.item)"
            (onUpdate)="onUpdate($event, control)"
          ></obc-emergency-contact>
        </div>
      </div>
      <div class="col-12" *ngIf="getRenderType(control.item.fieldType) == siteRequiredRenderTypes.Input">
        <div class="d-flex flex-column">
          <ng-container
          *ngTemplateOutlet="fieldTitle, context: {control: control}"
          ></ng-container>
          <div class="form-group d-flex justify-content-end">
            <input style="max-width: 350px;" [formControl]="control" class="form-control">
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="getRenderType(control.item.fieldType) == siteRequiredRenderTypes.DropDown">
        <div class="d-flex flex-column">
          <ng-container
          *ngTemplateOutlet="fieldTitle, context: {control: control}"
          ></ng-container>
          <div class="form-group d-flex justify-content-end">
            <select style="max-width: 350px;" class="form-control" [formControl]="control">
              <option [ngValue]="null">Not Selected</option>
              <option *ngFor="let item of getEntries(control.item.items)" [ngValue]="item[0]">{{item[1]}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="getRenderType(control.item.fieldType) == siteRequiredRenderTypes.Date">
        <div class="d-flex flex-column">
          <ng-container
          *ngTemplateOutlet="fieldTitle, context: {control: control}"
          ></ng-container>
          <div class="form-group d-flex justify-content-end">
            <obc-input-date-time-picker style="max-width: 350px;" [placeholder]="'Select Date'" [formControl]="control"
              [maxDate]="control.item.fieldKey == 'BirthDay' ? today : null"
              [mode]="DatetimePickerMode.Calender">
            </obc-input-date-time-picker>
            <!-- <input style="max-width: 350px;" [owlDateTime]="dt2" class="form-control" [formControl]="control"
              [owlDateTimeTrigger]="dt2" placeholder="Select Date"
              max="{{control.item.fieldKey == 'BirthDay' ? today.toISOString() : ''}}" />
            <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time> -->
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="getRenderType(control.item.fieldType) == siteRequiredRenderTypes.Supplier">
        <div class="d-flex flex-column">
          <ng-container
          *ngTemplateOutlet="fieldTitle, context: {control: control}"
          ></ng-container>
          <div class="form-group d-flex justify-content-end">
            <obc-company-supplier-selector [sessionId]="sessionId" class="fw" [siteId]="siteId" [control]="control">
            </obc-company-supplier-selector>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="getRenderType(control.item.fieldType) == siteRequiredRenderTypes.Business">
        <div class="d-flex flex-column">
          <ng-container
          *ngTemplateOutlet="fieldTitle, context: {control: control}"
          ></ng-container>
          <div class="form-group d-flex justify-content-end">
            <span *ngIf="control.value && control.item.answer && control.item.answer.mode == 'Selected'"
              style="max-width: 350px; width: 100%;">
              <input disabled style="max-width: 260px; display:inline-block;" [formControl]="control"
                class="form-control">
              <button class="btn btn-info" (click)="onShowBusinessAutoComplete(control)">Change</button>
            </span>
            <!--TODO:check box size-->
            <obc-user-business [isControlValid]="control.valid"
              *ngIf="!control.value || !control.item.answer || control.item.answer.mode != 'Selected'"
              style="max-width: 350px; width: 100%;" placeholder="Search and Add User Business"
              [displayNoResult]="false" [required]="isRequired(control.item)" [sessionId]="sessionId"
              (selectedBusiness)="onSelectBusiness($event,control)">
            </obc-user-business>
            <!-- <button *ngIf="!control.value" class="btn btn-primary" (click)="onSetBusiness(control)">Set</button> -->

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #fieldTitle let-control="control">
  <div class="mb-1">
    <i *ngIf="control.valid" style="color: green;" class="fas fa-check-circle  mr-2"></i>
    <i *ngIf="!control.valid" style="color: red;" class="fas fa-times-circle  mr-2"></i>
    <span>{{control.item.title}}</span>
  </div>
</ng-template>
