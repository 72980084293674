<button class="btn btn-dark-scale-2"
        (click)="openAnswersFilterModal(card)" style="width: 100%;">
  Announcements Filter(s)
</button>


<ng-template #card>
  <div class="card">
    <div class="card-header">
      <h5>Define Filter</h5>
      <div class="card-actions">
        <a
          (click)="
              modalRef.hide(); setCloneToOriginalAnnouncementAndDoSearch()
            "
          class="card-action card-action-dismiss"
        ></a>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="input-group mb-3">
            <select class="form-control" [(ngModel)]="clonedSelectedAnnouncement">
              <option [ngValue]="null" selected disabled>
                Select Announcement
              </option>
              <option *ngFor="let announcement of filteredAnnouncementList()" [ngValue]="announcement">
                {{ announcement.title }}
              </option>
            </select>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" [disabled]="!clonedSelectedAnnouncement" type="button"
                      tooltip="add announcement to filter" (click)="onAddAnnouncementToFilter()">
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngFor="let _announcement of clonedFilterAnnouncements">
          <app-filtered-announcement [model]="_announcement"
                                     (remove)="onRemoveAnnouncementFromFilter($event, true)"></app-filtered-announcement>
        </div>
      </div>
    </div>
    <div class="card-body">
      <button class="btn btn-danger pull-right" (click)="modalRef.hide(); setCloneToOriginalAnnouncementAndDoSearch()">
        {{ this.clonedFilterAnnouncements?.length > 0 ? 'Update' : 'Close'}}
      </button>
    </div>
  </div>
</ng-template>

