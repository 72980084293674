import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CompareOperator } from 'src/app/enums/compare-operator.enum';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { ToolDynamicFieldType } from 'src/app/enums/tool-dynamic-field-type.enum';
import { TriggerAction } from 'src/app/enums/trigger-action.enum';
import { formatDate, triggerDateTimeFormat } from 'src/app/helpers/date-time-format';
import { EnumToArray } from 'src/app/helpers/enum-to-array';
import { CompareOperatorFriendlyLabel } from 'src/app/helpers/friendly-label';
import { ToolDynamicFieldViewModel } from 'src/app/models/inventory/tool-dynamic-field-model';
import { ToolDynamicFieldTriggerModel, ToolDynamicFieldTriggerViewModel } from 'src/app/models/inventory/tool-dynamic-field-trigger-model';
import { DatePipe } from 'src/app/pipes/date.pipe';
import { ManageInventoryService } from 'src/app/services/manage-inventory.service';

@Component({
  selector: 'obc-inventory-dynamic-field-trigger-editor',
  templateUrl: './inventory-dynamic-field-trigger-editor.component.html',
  styleUrls: [],
  providers: [DatePipe]
})
export class InventoryDynamicFieldTriggerEditorComponent implements OnInit {
  @Input() toolDynamicField: ToolDynamicFieldViewModel = null;
  ToolDynamicFieldType = ToolDynamicFieldType;
  _trigger: ToolDynamicFieldTriggerViewModel;
  DatetimePickerMode = DatetimePickerMode;
  currentDate = Date.now();
  TriggerAction = TriggerAction;
  formGroup = new FormGroup({
    action: new FormControl(null, [Validators.required]),
    operator: new FormControl(null, [Validators.required]),
    value: new FormControl(null, [Validators.required]),
    parameters: new FormControl(null, [Validators.required])
  });
  @Input() set trigger(value: ToolDynamicFieldTriggerViewModel) {
    if (this._trigger?.id != value?.id) {
      this.formGroup.controls.action.setValue(value?.action);
      this.formGroup.controls.value.setValue(value?.value);
      this.formGroup.controls.operator.setValue(value?.operator);
      this.formGroup.controls.parameters.setValue(JSON.parse(value?.parameters));
    }
    this._trigger = value;
  }

  @Output() close = new EventEmitter();
  constructor(private manageInventoryService: ManageInventoryService, private datePipe: DatePipe) { }

  ngOnInit() {
  }

  upsert() {
    var model = {
      action: this.formGroup.controls.action.value,
      parameters: JSON.stringify(this.formGroup.controls.parameters.value),
      operator: this.formGroup.controls.operator.value,
      value: this.toolDynamicField.type == ToolDynamicFieldType.Date ? formatDate(this.formGroup.controls.value.value, triggerDateTimeFormat) : this.formGroup.controls.value.value,
    } as ToolDynamicFieldTriggerModel;
    if (this._trigger?.id) {
      this.manageInventoryService.updateToolDynamicFieldTrigger(this.toolDynamicField.id, this._trigger.id, model).subscribe(res => {
        this.close.emit();
      })
    } else {
      this.manageInventoryService.addToolDynamicFieldTrigger(this.toolDynamicField.id, model).subscribe(res => {
        this.close.emit();
      })
    }
  }

  getTriggerActionFriendlyName(action: TriggerAction): string {
    switch (action) {
      case TriggerAction.Email: return 'Send an Email';
      default: return TriggerAction[action];
    }
  }

  getCompareOperatorItems() {
    return EnumToArray(CompareOperator)
      .filter(x => {
        return this.toolDynamicField.type == ToolDynamicFieldType.Date ? x.id != CompareOperator.Contains : true;
      })
      .map(x => {
        return {
          id: x.id,
          name: CompareOperatorFriendlyLabel(CompareOperator[CompareOperator[x.id]]),
        };
      });
  }

}
