import { AbstractControl } from '@angular/forms';


export function urlValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if(!control.value)
        return null;
    var value = control.value as string;
    var valid = validURL(value);
    return valid ? null : { 'urlValidator': true };
}

function validURL(str) {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(str);

    // var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    //     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    //     '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    //     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    //     '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    //     '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    // return !!pattern.test(str);
}

