import { QuestionType } from "../enums/question-type";
import { AskQuestionPeriodMode } from '../enums/ask-period-mode';
import { QuestionDefaultMode } from '../enums/question-default-mode';
import { ItemTitleValue } from "./helper-models";
import { QuestionConditionViewModel } from "./question-condition-models";
import { AskMode } from "../enums/ask-mode";
import { FormControl } from "@angular/forms";
import { RequiredDocumentViewModel } from "./user-document-type";

export class QuestionViewModel {
  public id: number;
  public questionText: string;
  public questionRichText: string;
  public questionType: QuestionType;
  public companyId: number;
  public askPeriodMode: AskQuestionPeriodMode;
  public askMode: AskMode;
  public defaultMode: QuestionDefaultMode;
  public isSensitive: boolean;
  public conditions: QuestionConditionViewModel[];
  questionParameters: string;
  questionParametersAsString: any = {};
  companyName: string;
  public isActive: boolean = true;
  public documentTypeId: number;
  public document: RequiredDocumentViewModel;

  formControl: FormControl;
  items: { itemTitle: string, itemValue: string, formControl: FormControl, disabled?: boolean }[];
  disabled: boolean;
  operatorFormControl?: FormControl;
  uuid: string;
  leaveEmptyInTextMode: boolean;
  documentTypeIdList: number[];

  constructor(model?: any) {
    this.loadAllProperties(model);
  }

  loadAllProperties(model) {
    if (!model) return;
    for (const key of Object.keys(model)) {
      if (model.hasOwnProperty(key)) {
        this[key] = model[key];
      }
    }
    this.questionParametersAsString = this.questionParameters ? JSON.parse(this.questionParameters) : {};
    delete this.questionParametersAsString?.documentTypeId;
  }

  isAnswerRequired(): boolean {
    return this.questionParametersAsString["isRequired"] || (this.questionType == 2 && this.questionParametersAsString["minimumSelectCount"] > 0);
  }

  getOptionTitleList(ignoreRequird: boolean): string[] {
    return this.getOptions(ignoreRequird).map(e => e.itemTitle);
  }
  getOptions(ignoreRequird: boolean): ItemTitleValue[] {
    return QuestionViewModel.getQuestionOptions(this, ignoreRequird);
  }

  static hasOptions(question: QuestionViewModel) {
    return [QuestionType.Boolean, QuestionType.SingleSelect, QuestionType.MultiSelect]
      .indexOf(question.questionType) >= 0;
  }

  static getQuestionOptions(question: QuestionViewModel, ignoreRequird: boolean): ItemTitleValue[] {
    if (!QuestionViewModel.hasOptions(question))
      return [];

    switch (question.questionType) {
      case QuestionType.Boolean: {
        let items = [{ itemTitle: 'Yes', itemValue: 'Yes' }, { itemTitle: 'No', itemValue: 'No' }] as ItemTitleValue[];

        if (!ignoreRequird && question.questionParametersAsString?.isRequired === false)
          items.push({ itemTitle: 'No Select', itemValue: null });
        return items;
      }
      case QuestionType.SingleSelect:
      case QuestionType.MultiSelect: {
        if(!question.questionParametersAsString['itemList']) {
          return [];
        }
        let items =
          question.questionParametersAsString.itemList
            .map(c => new ItemTitleValue(c.title, c.value));

        if (!ignoreRequird && question.questionType === QuestionType.SingleSelect && question.questionParametersAsString?.isRequired === false)
          items.push({ itemTitle: 'Not Select Item', itemValue: null });
        else if (!ignoreRequird && question.questionType === QuestionType.MultiSelect && question.questionParametersAsString?.minimumSelectCount === 0)
          items.push({ itemTitle: 'Not Select Item', itemValue: null });
        return items;
      }
      default:
        return [];
    }
  }
}
