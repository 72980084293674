import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ScannableService } from 'src/app/services/scannable.service';
import { finalize } from 'rxjs/operators';
import { ModalService } from 'src/app/services/modal.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ScannableGroupModel } from '../../../models/scannable-group-model';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'obc-scannable-groups',
  templateUrl: './scannable-groups.component.html',
  styleUrls: ['./scannable-groups.component.css']
})
export class ScannableGroupsComponent implements OnInit {
  formGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    label: new FormControl(null, [Validators.required]),
    allowMultiScan: new FormControl(false, [Validators.required]),
  });

  editFormGroup: FormGroup;
  inProgress = false;
  inEditProgress = false;
  errorEditModalMessage = "";
  groups: ScannableGroupModel[] = [];
  sites: any[] = [];
  selectedGroup: ScannableGroupModel;
  bsModalRef: any;
  constructor(private scannableService: ScannableService,
    private bsModalService: BsModalService,
    private modalService: ModalService,
    private layoutService: LayoutService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.layoutService.header = "Manage Scannable Group";
    this.formGroup = this.fb.group({
      name: new FormControl(null, [Validators.required]),
      label: new FormControl(null, [Validators.required]),
      allowMultiScan: new FormControl(false, [Validators.required]),
    });
    this.getGroups();
  }

  getGroups() {
    this.inProgress = true;
    this.scannableService.getGroups()
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        this.groups = res;
      });
  }

  addGroup() {
    var model = {
      name: this.formGroup.controls['name'].value,
      label: this.formGroup.controls['label'].value,
      allowMultiScan: this.formGroup.controls['allowMultiScan'].value,
    } as ScannableGroupModel;
    this.inProgress = true;
    this.scannableService.addGroup(model)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        this.resetFormGroup();
        this.getGroups();
      });
  }

  resetFormGroup() {
    this.formGroup.reset();
  }

  onRemoveGroup(group) {
    this.modalService.confirm(`Are you sure you want to delete '${group.name}' and it's items`, "Delete Group", "Yes, I'm Sure", "Cancel").subscribe(res => {
      if (res) {
        this.scannableService.removeGroup(group.id)
          .pipe(finalize(() => this.inProgress = false))
          .subscribe(res => {
            this.getGroups();
          }, err => {
            if (err.status == 547) {
              this.modalService.error("This scannable group is used in user attendance or ...", "You can't remove this scannable group").subscribe(res => {
                this.getGroups();
              });
            }
          }, () => {
          });
      }
    })
  }

  onUpdateGroup() {
    var model = {
      name: this.editFormGroup.controls['name'].value,
      label: this.editFormGroup.controls['label'].value,
      allowMultiScan: this.editFormGroup.controls['allowMultiScan'].value,
    };
    var id = +this.editFormGroup.controls['id'].value;
    this.inProgress = true;
    this.scannableService.updateGroup(id, model)
      .pipe(finalize(() => {
        this.inProgress = false;
        this.bsModalRef.hide();
      }))
      .subscribe(res => {
        this.selectedGroup.name = model.name;
        this.selectedGroup.label = model.label;
        this.selectedGroup.allowMultiScan = model.allowMultiScan;
      });
  }

  onShowEditModal(template, group) {
    this.selectedGroup = group;
    this.editFormGroup = new FormGroup({
      id: new FormControl(this.selectedGroup.id, [Validators.required]),
      name: new FormControl(this.selectedGroup.name, [Validators.required]),
      label: new FormControl(this.selectedGroup.label, [Validators.required]),
      allowMultiScan: new FormControl(this.selectedGroup.allowMultiScan, [Validators.required]),
    });
    this.bsModalRef = this.bsModalService.show(template);
  }

  onManageSitesModal(template, group) {

  }

  onRemoveSite(site) {

  }
}
