<div class="btn-group" dropdown placement="bottom left" container="body">
  <!-- Projected Button Content -->
  <ng-content select="[dropdown-button]"></ng-content>

  <button
    id="button-split"
    type="button"
    dropdownToggle
    class="btn btn-info dropdown-toggle dropdown-toggle-split"
    aria-controls="dropdown-split"
  >
    <span class="caret"></span>
    <span class="sr-only visually-hidden">Split button!</span>
  </button>

  <div *dropdownMenu>
    <ng-content select="[dropdown-items]"></ng-content>
  </div>
</div>
