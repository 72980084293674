import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';
import { FormControl, Validators } from '@angular/forms';
import { SiteService } from 'src/app/services/site.service';
import { ImportSiteModel, ImportSiteResponse } from 'src/app/models/site-viewmodel';
import { AccompanierMode } from 'src/app/enums/accompanier-mode';
import { MultipleCheckinType } from 'src/app/enums/multiple-checkin-type.enum';
import { AutomaticCheckInMode } from 'src/app/enums/automatic-checkin-mode';
import { MaxPeopleOnSiteMode } from 'src/app/enums/max-people-on-site-mode.enum';
import { AutoCheckOutType } from 'src/app/enums/auto-check-out-type.enum';
import { finalize } from 'rxjs/operators';
import { ImportRecordState } from 'src/app/enums/import-record-status.enum';
import { AddressLookupService } from 'src/app/services/address-lookup.service';
import { ModalService } from 'src/app/services/modal.service';
import { Constants } from 'src/app/enums/constants';
import {
  getCheckinApprovalModeFriendlyMode,
  getcheckInLocationOverrideInKioskModeFriendlyMode
} from 'src/app/helpers/friendly-label';
import { CheckInApprovalMode } from 'src/app/enums/check-in-approval-mode.enum';
import { QrCodeCheckinMode } from 'src/app/enums/qr-code-checkin-mode';
import { LocationOverrideMode } from 'src/app/enums/location-override-mode-enum';
import { FileUploadAcceptType } from 'src/app/enums/file-upload-accept-type';

@Component({
  selector: 'app-import-sites',
  templateUrl: './import-sites.component.html',
  styleUrls: ['./import-sites.component.scss']
})
export class ImportSitesComponent implements OnInit {
  FileUploadAcceptType = FileUploadAcceptType;
  fileFormControl = new FormControl(null, [Validators.required]);
  importData: ImportSiteResponse;
  get sites(): ImportSiteModel[] {
    if (this.importData == null) return [];
    return this.importData.sites;
  };
  accompanierModes = AccompanierMode;
  multipleCheckinTypes = MultipleCheckinType;
  automaticCheckInModes = AutomaticCheckInMode;
  maxPeopleOnSiteModes = MaxPeopleOnSiteMode;
  autoCheckOutTypes = AutoCheckOutType;
  importRecordStates = ImportRecordState;
  qrCodeCheckinMode = QrCodeCheckinMode;
  public constants = Constants;

  geolocations = [];
  inProgress = false;
  constructor(private layoutService: LayoutService,
    private siteService: SiteService,
    private modalService: ModalService,
    private addressLookupService: AddressLookupService
  ) { }

  ngOnInit() {
    this.layoutService.header = "Import Sites",
      this.fileFormControl.valueChanges.subscribe(res => {
        if (res == null) return;
        let formData = new FormData();
        formData.append("file", res, res.name);

        this.import(formData, false);
      });
  }

  import(formData, isImport) {
    formData.append("geo-location", JSON.stringify(this.geolocations));
    this.inProgress = true;
    this.siteService.ValidateImportSites(formData, isImport)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        if (res.type == 4 && res.ok) {
          if (isImport) {
            this.importData = res.body;
            if (!this.importData.valid)
              this.modalService.error(this.importData.message ? this.importData.message : "unbale to import sites");
          } else {
            this.importData = res.body;
          }
        }
      });
  }

  get isDataValid(): boolean {
    if (this.sites == null || this.sites.length == 0) return false;
    return this.sites.find(i => i.validationStatus != this.importRecordStates.Valid) == null;
  }

  get isImportModelValid(): boolean {
    if (this.sites == null || this.sites.length == 0) return false;
    for (let index1 = 0; index1 < this.sites.length; index1++) {
      const site = this.sites[index1];
      if (site.errorMessages == null || site.errorMessages.length == 0) continue;
      for (let index2 = 0; index2 < site.errorMessages.length; index2++) {
        const error = site.errorMessages[index2];
        if (error.memberNames == null || error.memberNames.length == 0)
          continue;
        else
          return false;
      }
    }
    return true;
  }

  clear() {
    this.fileFormControl.setValue(null);
    this.importData = null;
  }

  onClearForm() {
    this.clear();
  }

  onImport() {
    let formData = new FormData();
    formData.append("file", this.fileFormControl.value, this.fileFormControl.value.name);

    this.import(formData, true);
  }

  fieldIsValid(site: ImportSiteModel, name: string): boolean {
    return site.errorMessages == null || !(site.errorMessages?.find(err => err.memberNames != null && err.memberNames?.find(f => f.toLowerCase() == name.toLowerCase()) != null) != null);
  }

  isValidSite(site: ImportSiteModel): boolean {
    if (site.errorMessages == null || site.errorMessages.length == 0) return true;
    for (let index2 = 0; index2 < site.errorMessages.length; index2++) {
      const error = site.errorMessages[index2];
      if (error.memberNames == null || error.memberNames.length == 0)
        continue;
      else
        return false;
    }
    return true;
  }

  showGetGoegrathicData(site: ImportSiteModel) {
    // valid model
    if (this.isValidSite(site))
      return false;

    // another errors
    for (let index1 = 0; index1 < this.sites.length; index1++) {
      const site = this.sites[index1];
      if (site.errorMessages == null || site.errorMessages.length == 0) continue;
      for (let index2 = 0; index2 < site.errorMessages.length; index2++) {
        const error = site.errorMessages[index2];
        if (error.memberNames == null || site.errorMessages.length == 0) continue;
        for (let index3 = 0; index3 < error.memberNames.length; index3++) {
          const member = error.memberNames[index3];
          if (member.toLowerCase() != "latitude" && member.toLowerCase() != "longitude")
            return false;
        }
      }
    }

    return true;
  }

  getCheckinApprovalModeFriendlyMode(mode?: CheckInApprovalMode): string {
    return mode != null && mode != undefined ? getCheckinApprovalModeFriendlyMode(mode) : null;
  }
  getcheckInLocationOverrideInKioskMode(mode?: LocationOverrideMode): string {
    return mode != null && mode != undefined ? getcheckInLocationOverrideInKioskModeFriendlyMode(mode) : null;
  }
  getLocation(site: ImportSiteModel) {
    this.inProgress = true;
    this.addressLookupService.getLocation(site.address)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        site.latitude = res.latitude;
        site.longitude = res.longitude;

        for (let index2 = 0; index2 < site.errorMessages.length; index2++) {
          const error = site.errorMessages[index2];
          if (error.memberNames == null || site.errorMessages.length == 0) continue;
          // remove lat error
          ['Latitude', 'Longitude'].forEach((e) => {
            var index = error.memberNames.indexOf(e);
            if (index >= 0)
              error.memberNames.splice(index, 1);
          })
        }

        this.geolocations.push({
          latitude: res.latitude,
          longitude: res.longitude,
          index: this.sites.indexOf(site)
        });
      }, err => {
        this.modalService.error("unable to retrive geographic location at this time")
      });
  }
}
