import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { DocumentExpiryStatus } from '../enums/document-expiry-status.enum';
import { DocumentExpiryStatusPipe } from '../pipes/document-expiry-status.pipe';

@Directive({
  selector: '[obcDocumentExpiryStatus]',
  providers: [DocumentExpiryStatusPipe]
})
export class DocumentExpiryStatusDirective implements OnInit {
  @Input() expiryStatus: DocumentExpiryStatus;

  constructor(private el: ElementRef,
    private documentExpiryStatusPipe: DocumentExpiryStatusPipe
  ) {

  }
  ngOnInit(): void {
    this.el.nativeElement.innerHTML = this.documentExpiryStatusPipe.transform(this.expiryStatus);
    let cssClassType = this.expiryStatus == DocumentExpiryStatus.Valid ? 'success' :
      this.expiryStatus == DocumentExpiryStatus.ExpireInNext30Day ? 'warning' :
        this.expiryStatus == DocumentExpiryStatus.Expired ? 'danger' :
          '';

    this.el.nativeElement.className = `p-3 badge obc-badge badge-${cssClassType}`;
  }

}
