import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { SiteQuestionViewModel } from 'src/app/models/site-question-viewmodel';
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import { uuid4 } from 'src/app/helpers/uuid';

@Component({
  selector: 'obc-question-title-renderer',
  templateUrl: './question-title-renderer.component.html',
  styleUrls: ['./question-title-renderer.component.scss']
})
export class QuestionTitleRendererComponent implements OnInit, AfterViewInit {

  id: any;
  model: SiteQuestionViewModel;
  get isRichTextQuestion() {
    return (this.model?.questionRichText?.trim()?.length ?? 0) > 0;
  }

  @Input() set question(model: SiteQuestionViewModel) {
    this.model = model;
  }

  constructor() {
  }

  ngOnInit() {
    this.id = 'a' + uuid4();
  }

  ngAfterViewInit(): void {

    if (this.isRichTextQuestion) {
      let content = this.model.questionRichText;

      new Viewer({
        el: document.querySelector(`#${this.id}`),
        initialValue: content,

      });
    }
  }
}
