import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SignedUploadInfoRequest, FileUploadInfo } from '../models/signed-upload-info-request';
import { UploadFileInfo } from '../models/requiredDocumentAnswer';
import { InlineFileViewModel } from '../view-models/inline-file-view-model';


@Injectable({
  providedIn: 'root'
})
export class FileStorageService {

  constructor(private http: HttpClient, private errorService: ErrorService) { }
  getSignedUploadInfo(model: SignedUploadInfoRequest): Observable<FileUploadInfo> {
    return this.http.post<FileUploadInfo>('/api/fileStorage/get-signed-upload-info', model)
      .pipe(catchError(this.errorService.handleError));
  }

  uploadInlineFile(model: UploadFileInfo): Observable<InlineFileViewModel> {
    return this.http.post<InlineFileViewModel>('/api/fileStorage/upload-inline-file', model)
      .pipe(catchError(this.errorService.handleError));
  }

  uploadFile(formData: FormData, uploadInfo: FileUploadInfo) {
    var file = formData.get("file");
    var headers = uploadInfo.headers;
    headers["do-not-modify-content-type"] = '';
    if (uploadInfo.httpMethod.toUpperCase() == "PUT") {
      return this.http.put(uploadInfo.url, file , { reportProgress: true, observe: 'events', headers: headers })
    } else if (uploadInfo.httpMethod.toUpperCase() == "POST") {
      return this.http.post(uploadInfo.url, file, { reportProgress: true, observe: 'events', headers: headers })
    } else {
      throw "upload method not implemented";
    }
  }
}
