<div class="card">
    <div class="card-header">
        <div class="d-flex flex-row justify-content-between">
            <h2 class="card-title">Workflow Instance History ({{workflowInstanceHistory?.workflowName}})</h2>
            <div>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="hide.emit()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="card">
        <div class="card-body">
            <div class="list-group list-group-item">
                <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-4">
                    <div class="col" *ngFor="let d of workflowInstanceHistory?.variables">
                        {{d.title}}: <strong>{{d.value}}</strong>
                    </div>
                    <div class="col">
                        Current Step: <strong>{{workflowInstanceHistory?.currentActivity?.title}}</strong>
                    </div>
                </div>
            </div>
            <div class="card-header mt-2">
                History Records
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>
                                        Step Title
                                    </th>
                                    <th>
                                        User
                                    </th>
                                    <th>
                                        Date
                                    </th>
                                    <th>
                                        Note
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of workflowInstanceHistory?.activityList; let index = index">
                                    <td>{{index + 1}}</td>
                                    <td>
                                        {{item.activityTitle}}
                                        <button type="button" class="btn btn-xs btn-info pull-right"
                                            *ngIf="item.filledFormDataId"
                                            (click)="viewHistoryFilledForm(formDetails,item.filledFormDataId)">View
                                            Form</button>
                                    </td>
                                    <td>
                                        {{item.user?.fullName}} <span
                                            *ngIf="item.user?.mobile">({{item.user?.mobile}})</span>
                                    </td>
                                    <td>
                                        {{item.resumedDate | datetime}}
                                    </td>
                                    <td>
                                        {{item.note}}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="workflowInstanceHistory?.currentActivity">
                                <tr>
                                    <td>{{workflowInstanceHistory?.activityList?.length+1}}</td>
                                    <td>
                                        <strong>{{workflowInstanceHistory?.currentActivity?.title}}</strong>
                                    </td>
                                    <td>
                                        <i>Pending</i>
                                    </td>
                                    <td><i>Pending</i>
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #formDetails>
    <obc-form-details [formData]="filledFormData"></obc-form-details>
</ng-template>