import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUploadAcceptType } from 'src/app/enums/file-upload-accept-type';
import { ProductPlanFeature } from 'src/app/enums/product-plan-feature.enum';
import { removeContentTypeFromBase64, toBase64 } from 'src/app/helpers/general-functions';
import { urlValidator } from 'src/app/helpers/url-validator';
import { MinimalSiteModel } from 'src/app/models/quick-setup-models/minimal-site-model';
import { ModalService } from 'src/app/services/modal.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'obc-minimal-add-site',
  templateUrl: './minimal-add-site.component.html',
  styleUrls: ['./minimal-add-site.component.scss']
})
export class MinimalAddSiteComponent implements OnInit {
  ctrl: FormGroup;
  ProductPlanFeature = ProductPlanFeature;
  FileUploadAcceptType = FileUploadAcceptType;

  sites = new FormArray([]);

  constructor(public userService: UserService,
              private modalService: ModalService) {
  }

  ngOnInit() {
    this.initFormGroup();
  }

  initFormGroup() {
    this.ctrl = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      address: new FormControl(null, [Validators.required]),
      logoFile: new FormControl(null, []),
      menuPdfFile: new FormControl(null, []),
      serviceOrderingWebSite: new FormControl(null, [urlValidator]),
    })
  }

  onAdd() {
    let newFormGroup = new FormGroup({
      name: new FormControl(this.ctrl.controls.name.value, [Validators.required]),
      address: new FormControl(this.ctrl.controls.address.value, [Validators.required]),
      logoFile: new FormControl(this.ctrl.controls.logoFile.value, []),
      menuPdfFile: new FormControl(this.ctrl.controls.menuPdfFile.value, []),
      serviceOrderingWebSite: new FormControl(this.ctrl.controls.serviceOrderingWebSite.value, [urlValidator]),
    })

    this.sites.controls.unshift(newFormGroup);
    this.initFormGroup();
  }

  onRemove(row: FormGroup) {
    this.modalService.confirm("Are You Sure You Want Remove this Site?", "Remove Site").subscribe(res => {
      if (res)
        this.sites.controls.splice(this.sites.controls.indexOf(row), 1);
    })
  }

  async getSites(): Promise<MinimalSiteModel[]> {
    let ret = [];
    for (let index = 0; index < this.sites.controls.length; index++) {
      const element = this.sites.controls[index] as FormGroup;
      let entry = {
        name: element.controls.name.value,
        address: element.controls.address.value.address,
        longitude: element.controls.address.value.longitude,
        latitude: element.controls.address.value.latitude,
        logoBase64: element.controls.logoFile.value == null ? null : removeContentTypeFromBase64((await toBase64(element.controls.logoFile.value)) as string),
        menuPdfBase64: element.controls.menuPdfFile.value == null ? null : removeContentTypeFromBase64((await toBase64(element.controls.menuPdfFile.value)) as string),
        serviceOrderingWebSite: element.controls.serviceOrderingWebSite.value,
      } as MinimalSiteModel;
      ret.push(entry);
    }
    return ret;
  }

  get canAddSite(): number {
    return this.userService.currentCompany.maxSites - (this.userService.currentCompany.currentActiveSites + this.sites.controls.length);
  }

  get valid(): boolean {
    if (!this.sites.controls?.length)
      return false;
    for (let index = 0; index < this.sites.controls.length; index++) {
      const element = this.sites.controls[index];
      if (!element.valid)
        return false;
    }
    return true;
  }
}
