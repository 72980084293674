<ngx-file-drop [dropZoneClassName]="'alert my-alert-info'" [browseBtnClassName]="'btn btn-block btn-success'"
  (onFileDrop)="dropped($event)" [accept]="'.png, .jpg, .jpeg, application/pdf'">
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <div class="d-flex flex-column w-100">
      <p class="text-center" style="min-height: 48px;">
        Drag and drop your
        <span class="font-weight-bolder">{{documentKindTitle}}</span> files here
        <br>
        or
      </p>
      <div class="d-flex m-1">
        <button type="button" class="btn btn-light btn-browse flex-fill" (click)="openFileSelector()">
          Browse <span class="font-weight-bolder">{{documentKindTitle}}</span>
          Files
        </button>
      </div>
      <p class="hint small-text" style="font-size: 12px;margin-top: 41px;text-align: center;"> Supported file types
        include PDF and images.</p>
      <!-- <div class="d-flex">
        <div class="d-flex flex-fill m-1">
          <button (click)="addClicked()" class="btn btn-danger flex-fill" *ngIf="showAddButton">
            <i class="fa fa-plus"></i>
            Add Document
          </button>
        </div>
        <div class="d-flex flex-fill m-1">
          <button (click)="requestClicked()" class="btn btn-info flex-fill" *ngIf="showRequestButton">
            <i class="fa fa-plus"></i>
            Request Document
          </button>
        </div>
      </div> -->
    </div>
  </ng-template>
</ngx-file-drop>
