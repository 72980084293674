export enum SecurityLogType {
  ResetSiteInductionStatus = 0,
  CancelSupplierLinking = 1,
  ResetAttendanceStatus = 2,
  ResetSupplierFormStatus = 3,
  ResetPermitStatus = 4,
  ResetSiteAssetStatus = 5,
  ResetSiteAssetOperatorStatus = 6,
  DeleteSiteSupplierDocumentVersion = 7,
  SiteAlert = 8,
  ChangeMobileNumber = 9,
  DeleteSiteSupplierDocumentType = 10,
  UpdateProcoreIntegartionsConfig = 11,
  SiteUpdates = 12,
}
