import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApproveEntityType } from 'src/app/enums/approve-entity-type';
import { SiteInductionStatus } from 'src/app/enums/site-induction-status';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { SiteInductionViewModel } from 'src/app/models/site-induction-view-model';
import { InductionService } from 'src/app/services/induction-service';
import { ModalService } from 'src/app/services/modal.service';
import { SiteAttendanceService } from 'src/app/services/site-attendance.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'obc-induction-status',
  templateUrl: './induction-status.component.html',
  styleUrls: ['./induction-status.component.scss']
})
export class InductionStatusComponent implements OnInit {

  constructor(private inductionService: InductionService, private userService: UserService, private modalService: ModalService) { }

  @Input() siteInduction: SiteInductionViewModel;
  @Input() readOnly: boolean = true;
  @Output() onApproveOrReject: EventEmitter<any> = new EventEmitter();

  siteInductionStatusEnum = SiteInductionStatus;
  hasApprovePermiossion: boolean = false;
  approveEntityType = ApproveEntityType.Induction;

  ngOnInit(): void {
    this.hasApprovePermiossion = this.userService.hasUserPermissionForCompany(UserPermission.ApproveRejectSiteInduction, this.siteInduction.siteId);
  }
  showApproveRejectButtons(): boolean {
    return !this.readOnly && this.hasApprovePermiossion;
  }
  closeModal() {
    this.modalService.hide();
  }
  onOpenApproveRejectTemplate(template: any) {
    this.modalService.show(template, "modal-lg")
  }

  fetchinduction(){
    this.inductionService.getSiteInductionFilledInfo(this.siteInduction.siteInductionId)
      .subscribe(response => {
        if (response.success == false)
          this.modalService.error(`Request failed!`);

        if (this.onApproveOrReject)
          this.onApproveOrReject.emit(response.data);
      });
  }
}
