import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { FormType } from 'src/app/enums/form-type';
import { UserAutoCompleteFilterByFields } from 'src/app/enums/user-auto-complete-target-fields';
import { mobileIsValid } from 'src/app/helpers/mobile-format-validator';
import { SiteAssetOperatorModel, SiteAssetViewModel } from 'src/app/models/site-asset/site-asset-view-model';
import { UserBasicInfoModel, UserBasicInfoViewModel } from 'src/app/models/user-basic-info-viewmodel';
import { SiteAssetService } from 'src/app/services/site-asset.service';
import { FormDataRendererComponent } from '../../form-manage/form-data-renderer/form-data-renderer.component';
import { ModalService } from "../../../services/modal.service";

@Component({
  selector: 'obc-register-site-asset-operator',
  templateUrl: './register-site-asset-operator.component.html',
  styleUrls: ['./register-site-asset-operator.component.scss']
})
export class RegisterSiteAssetOperatorComponent implements OnInit {
  @Input() selectedSiteAsset: SiteAssetViewModel;
  @Input() siteId: number;
  @Input() supplierId: number;
  @Output() onRegisterOperator = new EventEmitter<SiteAssetViewModel>();
  @Input() modalReference;
  @Input() hashLink: string;

  @ViewChild('formDataRenderer') formDataRenderer: FormDataRendererComponent;

  inProgress: boolean;
  filterUserBy = UserAutoCompleteFilterByFields;
  selectedUser: UserBasicInfoModel = {};
  formType = FormType;
  isFormValid: boolean;
  operatorFormIsValid: boolean;
  constructor(private siteAssetService: SiteAssetService,
    private modalService: ModalService,) { }

  ngOnInit(): void {
  }

  get isValidMobile(): boolean {
    return (this.selectedUser.mobile !== undefined && mobileIsValid(this.selectedUser.mobile));
  }
  updateMobile(mobile) {
    this.selectedUser.mobile = mobile;
    this.updateValidState();
  }
  updateFirstName(firstName) {
    this.selectedUser.firstName = firstName;
    this.updateValidState();
  }
  updateLastName(lastName) {
    this.selectedUser.lastName = lastName;
    this.updateValidState();
  }

  fillCompanyUser(cUser: UserBasicInfoViewModel) {
    if (cUser.id) this.selectedUser = cUser;
    this.updateValidState();
  }
  validateForm(formIsValid: boolean) {
    this.operatorFormIsValid = formIsValid;
    this.updateValidState();
  }
  updateValidState() {
    this.isFormValid = this.isValidMobile && (this.selectedSiteAsset.assetOperatorFormId == null || this.operatorFormIsValid) && this.selectedSiteAsset?.siteAssetId != null;
  }
  registerOperator() {
    let formData = this.selectedSiteAsset.assetOperatorFormId == null ? null : this.formDataRenderer.getFormResponse();
    let model = {
      siteAssetId: this.selectedSiteAsset.siteAssetId,
      formData: formData,
      firstName: this.selectedUser.firstName,
      lastName: this.selectedUser.lastName,
      mobile: this.selectedUser.mobile,
    } as SiteAssetOperatorModel;
    this.inProgress = true;
    this.siteAssetService.registerAssetOperator(model, this.hashLink)
      .pipe(finalize(() => {
        this.inProgress = false;
      }))
      .subscribe(_ => {
        this.onRegisterOperator.emit(this.selectedSiteAsset);
      },
        (err) => {
          this.modalService.error(err);
        });
  }

  closeModal() {
    this.modalReference?.hide();
  }
}
