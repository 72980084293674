import { Pipe, PipeTransform } from '@angular/core';
import { MessageType } from '../enums/message-type.enum';

@Pipe({
  name: 'messageTypeFilter'
})
export class MessageTypePipe implements PipeTransform {

  transform(value: any): string {
    if (value == MessageType.Email || value == 0 || value=="Email") return "Email";
    if (value == MessageType.PushNotification || value == 5 || value=="PushNotification") return "PushNotification";
    if (value == MessageType.SMS || value == 10 || value=="SMS") return "SMS";
    return value?.toString() ?? "";
  }
}
