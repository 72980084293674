import { Component, OnInit, Input } from '@angular/core';
import { SiteAttendanceAccompanierModel } from '../../models/site-attendance-accompanier-model';
import { AccompanierMode } from '../../enums/accompanier-mode';
import { timer, Subject } from 'rxjs';
import { AccompanyPeopleResult } from 'src/app/models/accompany-people-result';
import { FormArray, FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { e168Validator2, e168ValidatorNullable } from 'src/app/helpers/mobile-format-validator';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'obc-accompany-person',
  templateUrl: './accompany-person.component.html',
  styleUrls: ['./accompany-person.component.scss'],
})
export class AccompanyPersonComponent implements OnInit {
  peopleFormArray: FormArray = new FormArray([]);
  get peopleCount(): number {
    return this.mode == 1 ?
      Math.max(this.userInputPeopleCountFormControl.value, this.peopleFormArray.controls.length) :
      this.peopleFormArray.controls.length;
  };

  updatePeopleCount() {
    if (this.mode == AccompanierMode.OptionalNameAndMobile) {
      this.userInputPeopleCountFormControl.setValue(Math.max(this.userInputPeopleCountFormControl.value, this.peopleFormArray.controls.length));
    }
  }

  editPeopleCount() {
    this.updatePeopleCount();
  }

  userInputPeopleCountFormControl = new FormControl(0, [Validators.min(0)]);
  get userInputPeopleCount(): number {
    return this.userInputPeopleCountFormControl.value;
  };
  get personList(): SiteAttendanceAccompanierModel[] {
    return this.peopleFormArray.controls.map((c) => {
      var person = new SiteAttendanceAccompanierModel();
      person.firstName = c.get('firstName').value;
      person.lastName = c.get('lastName').value;
      person.mobile = c.get('mobile').value;
      return person;
    })
  };
  AccompanierMode = AccompanierMode;
  mode: AccompanierMode = AccompanierMode.Disable;
  errorMessage: string;
  timeOut: any;

  get isDisablePeopleCount(): boolean {
    return (
      this.mode == AccompanierMode.NameAndOptionalMobile ||
      this.mode == AccompanierMode.NameAndMobile
    );
  }
  _accompanyData$: Subject<AccompanyPeopleResult>;
  @Input() set accompanyData$(subject: Subject<AccompanyPeopleResult>) {
    this._accompanyData$ = subject;
    this.pushData();
  };

  get accompanyData$(): Subject<AccompanyPeopleResult> {
    return this._accompanyData$;
  }

  @Input() set accompanierMode(mode: AccompanierMode) {
    this.mode = mode;
    this.peopleFormArray.clear();
  }

  constructor(private fb: FormBuilder, private modalService: ModalService) { }

  ngOnInit(): void {

  }

  addNewPerson() {
    var formGroup: FormGroup = null;
    if (this.mode == AccompanierMode.OptionalNameAndMobile) {
      formGroup = new FormGroup({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        mobile: new FormControl('', [e168ValidatorNullable]),
      });

    } else if (this.mode == AccompanierMode.NameAndOptionalMobile) {
      formGroup = new FormGroup({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        mobile: new FormControl('', [e168ValidatorNullable]),
      });
    } else if (this.mode == AccompanierMode.NameAndMobile) {
      formGroup = new FormGroup({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        mobile: new FormControl('', [Validators.required, e168ValidatorNullable]),
      });
    } else {
      this.modalService.warn("unable to add accompanier to this site mode! " + this.mode);
    }

    if (formGroup != null) {
      formGroup.valueChanges.subscribe(res => {
        this.pushData();
      });
      this.peopleFormArray.controls.push(formGroup);
      this.pushData();
    }
  }
  pushData() {
    var data = new AccompanyPeopleResult();
    if (!this.isFormArrayValid()) {
      data.valid = false;
      this.accompanyData$.next(data);
    } else {
      data.count = this.mode == 1 ? this.userInputPeopleCount : this.peopleCount;
      data.persons = this.personList;
      data.valid = true;
      this.accompanyData$.next(data);
    }
    this.updatePeopleCount();
  }

  isFormArrayValid(): boolean {
    if ((this.mode == 1 && !this.userInputPeopleCountFormControl.valid)) return false;
    for (let index = 0; index < this.peopleFormArray.controls.length; index++) {
      if (!this.peopleFormArray.controls[index].valid)
        return false;
    }
    return true;
  }

  removePerson(personFormControl: FormGroup) {
    var index = this.peopleFormArray.controls.indexOf(personFormControl);
    this.peopleFormArray.controls.splice(index, 1);
    this.pushData();
  }
}
