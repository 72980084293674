import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DefaultWorkflowDisplayMode } from 'src/app/enums/constants';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { PortalDisplayMode } from 'src/app/enums/portal-display-mode.enum';
import { Table } from 'src/app/enums/table.enum';
import { GridColumn, GridConfig, GridTemplate } from 'src/app/models/grid/grid.config';
import { ObcEntityType, WorkflowListViewModel } from 'src/app/models/workflow/workflow-list-viewmodel';
import { FormService } from 'src/app/services/form.service';
import { ModalService } from 'src/app/services/modal.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'obc-workflow-cartable',
  templateUrl: './workflow-cartable.component.html',
  styleUrls: ['./workflow-cartable.component.scss']
})
export class WorkflowCartableComponent implements OnInit {
  gridColumns: GridColumn<WorkflowListViewModel>[] = [];
  gridConfig: GridConfig = {} as GridConfig;
  inProgress: boolean;
  portalDisplayMode: PortalDisplayMode = DefaultWorkflowDisplayMode;
  selectedInstanceId: string;
  dirtyCheckSubscribe;
  selectedFormDirtyState: boolean = false;
  @ViewChild('grid') grid: any;
  
  constructor(
    private bsModalService: BsModalService,
    private userService: UserService,
    private formService: FormService,
    private modalService: ModalService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.portalDisplayMode = this.userService.getCompanyWorkflowDisplayMode();
    this.prepareGridConfig();
  }


  openWorkflowEngine(instanceId: string, modalTemplate: any) {
    this.selectedInstanceId = instanceId;

    if (this.portalDisplayMode == PortalDisplayMode.PopUp) {
      this.formService.isFormDirty.next(false);
      this.bsModalService.show(modalTemplate, {
        class: "modal-lg modal-full-width",
        ignoreBackdropClick: true,
        keyboard: false,
      });
      this.dirtyCheckSubscribe = this.formService.isFormDirty.asObservable().subscribe(e => {
        this.selectedFormDirtyState = e;
      });
    }
    else if (this.portalDisplayMode == PortalDisplayMode.Page) {
      this.router.navigate(['/workflow-engine/' + this.selectedInstanceId]);
    }
  }

  prepareGridConfig() {
    this.gridConfig = new GridConfig({
      apiUrl: '/api/workflow/cartable/',
      tableType: Table.Workflow,
      displayGeneralSearch: true,
      generalSearchPlaceholder: "Filter result by Workflow Name",
      generalSearchMinimumCharsToSearch: 1,
    });

    this.gridColumns = [
      {
        name: "Workflow Name",
        key: "title",
        sortBy: "title",
        type: FilterType.Text,
        propertyNameInQuery: 'title',
        isArray: false,
        enableFilter: true,
        enableSort: true,
        displayFunction: (data) => { return data.title + (data.version ? ` (v${data.version})` : '') },
      },
      {
        name: "Current Step",
        key: "activityTitle",
        type: FilterType.SingleText,
        enableFilter: false,
        enableSort: false
      },
      {
        name: "Supplier",
        key: "supplier",
        //sortBy: "title",
        type: FilterType.Supplier,
        propertyNameInQuery: 'supplier',
        isArray: true,
        enableFilter: false,
        enableSort: false,
        displayFunction: (data) => { return data.variables.find(d => d.dataType == ObcEntityType.Supplier)?.value; }
      },
      {
        name: "Additional Info",
        key: "AdditionalInfo",
        type: FilterType.Template,
        enableFilter: false,
        enableSort: false,
        gridTemplate: new GridTemplate().ComponentTemplateName('additional-info-template'),
      },
      {
        name: "Created By",
        key: "workflowStarter",
        sortBy: "workflowStarter",
        type: FilterType.Text,
        propertyNameInQuery: 'workflowStarter',
        isArray: false,
        enableFilter: true,
        enableSort: true,
      },
      {
        name: "Created At",
        key: "createdAt",
        sortBy: "createdAt",
        type: FilterType.DateTimeRange,
        propertyNameInQuery: 'createdAtDateRange',
        isArray: false,
        enableFilter: true,
        enableSort: true,
      },
      {
        name: "Last Executed At",
        key: "lastExecutedAt",
        sortBy: "lastExecutedAt",
        type: FilterType.DateTimeRange,
        isArray: false,
        propertyNameInQuery: 'lastExecutedAtDateRange',
        enableFilter: true,
        enableSort: true,
      },
      {
        name: "Details",
        key: 'viewDetails',
        type: FilterType.Template,
        enableSort: false,
        enableFilter: false,
        gridTemplate: new GridTemplate().ComponentTemplateName('viewDetails'),
        visible: true,
      }
    ];

  }

  instanceId: string;
  onOpenHistory(template: any, workflowInstanceId: string) {
    this.instanceId = workflowInstanceId;
    this.bsModalService.show(template, { class: 'modal-xl' });
  }

  closeModal() {
    try {
     
      if (!this.selectedFormDirtyState) {
        this.dirtyCheckSubscribe?.unsubscribe();
        this.bsModalService.hide();
        this.reloadCartable();
      } else {
        this.modalService.confirm('Exit and lose progress?', '', 'Yes, exit', 'No, go back and continue')
          .subscribe(res => {
            if (res == true) {
              this.dirtyCheckSubscribe.next(false);
              this.dirtyCheckSubscribe?.unsubscribe();
              this.bsModalService.hide();
              this.reloadCartable();
            }
          });
      }
    } catch {
    }
  }

  showTerminateButton(item) {
    return false && this.userService.isCompanyAdminUser();
  }

  reloadCartable(){
    this.grid.reloadData();
  }
}
