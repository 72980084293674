import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccessControlWhiteListUserModel, AccessControlWhiteListUserViewModel } from '../models/access-control/access-control-white-list-user-model';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class AccessControlService {

  apiUrl: string = "/api/AccessControlManager";
  constructor(private http: HttpClient, private _errorService: ErrorService) { }

  getWhiteListUsers(): Observable<AccessControlWhiteListUserViewModel[]> {
    return this.http.get<AccessControlWhiteListUserViewModel[]>(`${this.apiUrl}/get-white-list-users`)
      .pipe(catchError(this._errorService.handleError));
  }

  addNewWhiteListUser(newRecord: AccessControlWhiteListUserModel) {
    return this.http.post<AccessControlWhiteListUserViewModel>(`${this.apiUrl}/add-white-list-user`, newRecord)
      .pipe(catchError(this._errorService.handleError));
  }

  removeNewWhiteListUser(whiteUserId: number) {
    return this.http.post<boolean>(`${this.apiUrl}/remove-white-list-user/${whiteUserId}`, {})
      .pipe(catchError(this._errorService.handleError));
  }
}
