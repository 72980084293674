import { Pipe, PipeTransform } from '@angular/core';
import { ObcEventLogTypeEnum } from '../enums/obc-event-log-type.enum';

@Pipe({
  name: 'workerProfileLogType'
})
export class WorkerProfileLogTypePipe implements PipeTransform {

  transform(value: unknown): string {

    if (value == ObcEventLogTypeEnum.NewCard) return 'New Card';
    if (value == ObcEventLogTypeEnum.UpdateUserProfile) return 'Update User Profile';
    if (value == ObcEventLogTypeEnum.UserActivity) return 'User Activity';
    return value?.toString() ?? '';
  }

}
