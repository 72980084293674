import { Pipe, PipeTransform } from '@angular/core';
import { FormDataStatus } from '../enums/form-data-status';

@Pipe({
  name: 'formDataStatus'
})
export class FormDataStatusPipe implements PipeTransform {

  transform(value: unknown): string {
    if (value === FormDataStatus.NotRequired) return "";
    if (value === FormDataStatus.Approved) return "Approved";
    if (value === FormDataStatus.PendingApproval) return "Pending Approval";
    if (value === FormDataStatus.Rejected) return "Rejected";
    return value?.toString();
  }


}
