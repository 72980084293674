import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { Table } from 'src/app/enums/table.enum';
import { GridColumn, GridConfig, GridTemplate } from 'src/app/models/grid/grid.config';
import { UserProfileCardViewModel } from 'src/app/models/userProfile/worker-profile-view-model';
import { Constants } from "../../../../enums/constants";
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-worker-profile-card-report',
  templateUrl: './worker-profile-card-report.component.html',
  styleUrls: ['./worker-profile-card-report.component.scss']
})
export class WorkerProfileCardReportComponent implements OnInit {
  @Input() selectedUserId: number = null;
  @Input() isReportMode: boolean = false;
  @Input() siteId: number = null;

  @ViewChild('grid') grid: any;
  gridColumns: GridColumn<UserProfileCardViewModel>[] = [];
  gridConfig: GridConfig = {} as GridConfig;
  companyUserCardId: number;

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
    this.prepareGridConfig();
  }

  prepareGridConfig() {

    let initialFilters = [];
    if (this.selectedUserId) {
      initialFilters.push({ key: 'userId', value: this.selectedUserId });
    }
    if (this.siteId) {
      initialFilters.push({ key: 'siteId', value: this.siteId });
    }

    this.gridConfig = new GridConfig({
      apiUrl: '/api/workerprofile/get-user-cards',
      tableType: Table.WorkerProfileCards,
      forceHideAllControlButtons: this.isReportMode,
      displayQuestionsFilter: false,
      displayGeneralSearch: false,
      initialFilters: initialFilters,
      cacheKeyPrefix: this.isReportMode ? Constants.WORKER_PROFILE_REPORT_PREFIX : '',
    });
    this.gridColumns = [
      {
        name: "Type",
        key: "documentTypeTitle",
        type: FilterType.DocumentType,
        propertyNameInQuery: 'documentTypeIdList',
        enableFilter: true,
        enableSort: true,
      },
      {
        name: "Update Date",
        key: "dateTime",
        propertyNameInQuery: 'updateDateTime',
        isArray: false,
        type: FilterType.DateTimeRange,
        enableFilter: true,
        enableSort: true,
      },
      {
        name: "Title",
        key: "documentTitle",
        enableFilter: false,
        enableSort: false,
      },
      {
        name: "Expiry Date",
        key: "expireDate",
        propertyNameInQuery: 'expireDate',
        type: FilterType.DateTimeRange,
        isArray: false,
        enableFilter: true,
        enableSort: true,
      },
      {
        name: "Status",
        key: "documentExpiryStatus",
        propertyNameInQuery: 'documentExpiryStatusList',
        type: FilterType.DocumentExpiryStatus,
        enableFilter: true,
        enableSort: true,
      },
      {
        name: "Actions",
        key: "",
        gridTemplate: new GridTemplate().ComponentTemplateName('actions'),
        enableFilter: false,
        enableSort: false,
      },
    ]
  };

  historyPopUpReference: any;
  openModal(template: any, companyUserCardId: number) {
    this.companyUserCardId = companyUserCardId;
    this.historyPopUpReference = this.modalService.show(template, 'modal-lg');
  }
}
