export enum ExpiryMode {
  Custom = 0,
  NoExpiry = 1,
  EndDay = 2,
  EndWeek = 3,
  EndMonth = 4,
  EndYear = 5
}

export enum FormExpiryMode {
  NoExpiry = ExpiryMode.NoExpiry,
  EndDay = ExpiryMode.EndDay,
  EndWeek = ExpiryMode.EndWeek,
  EndMonth = ExpiryMode.EndMonth,
}

export enum ExpiryUnit {
  Hour = 0,
  Day = 1,
  Week = 2,
  Month = 3,
  Year = 4
}
