<div class="row">
  <ng-container *ngFor="let control of formArray.controls">
    <div class="col-md-6 mt-3" *ngIf="getCustomFormControlItem(control).type != ToolDynamicFieldType.File">
      <obc-text-tool-field-renderer [control]="control" [type]="getCustomFormControlItem(control).type"></obc-text-tool-field-renderer>
    </div>
    <div class="col-md-12 mt-3" *ngIf="getCustomFormControlItem(control).type == ToolDynamicFieldType.File">
      <ng-container>
        <obc-image-tool-field-renderer [control]="control"></obc-image-tool-field-renderer>
      </ng-container>
    </div>
  </ng-container>
</div>
