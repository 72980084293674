import { Pipe, PipeTransform } from '@angular/core';
import { EvacuationStatus } from '../models/evacuation/evacuation-viewmodels';

@Pipe({
  name: 'evacuationStatus'
})
export class EvacuationStatusPipe implements PipeTransform {

  transform(value: any): string {
    if (value == EvacuationStatus.Draft) return 'Draft';
    if (value == EvacuationStatus.Finalized) return 'Finalised';
    return value?.toString() ?? '?';
  }

}
