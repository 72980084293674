import { Directive } from '@angular/core';
import { AbstractControl, FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { emailListValidator } from '../helpers/email-list-validator';

@Directive({
  selector: '[emailList]',
  providers: [{provide: NG_VALIDATORS, useExisting: EmailListDirective, multi: true}]
})
export class EmailListDirective  implements Validator {

  constructor() { }
  validate(control: FormControl): ValidationErrors {
    return emailListValidator(control);
  }
}
