<div class="row">
  <div class="col-12">
    <div class="form-group">
      <label>Who can add operators?</label>
      <div>
          <span
            *ngFor="let mode of assetOperatorModeToShow"
            class="ml-3"
          >
            <label>
              <input
                type="checkbox"
                [checked]="isModeSelected(mode)"
                value="{{ mode }}"
                [disabled]="isDisableOperatorMode(mode)"
                (change)="onChangeOperatorMode(mode, $event)"
              />
              <strong class="ml-2">{{
                getOperatorModeTitle(mode)
                }}</strong>
            </label>
          </span>
      </div>
    </div>
  </div>
</div>
