import { Component, OnInit } from '@angular/core';
import { CompanyViewModel } from 'src/app/models/company-viewmodel';
import { UserAutoCompleteFilterByFields } from 'src/app/enums/user-auto-complete-target-fields';
import {
  CompanyUserUpdateRequest,
  CompanyUserViewModel,
  ImportCompanyUserModel,
  ImportCompanyUserResponse
} from 'src/app/models/company-user-view-model';
import { CompanyUserService } from 'src/app/services/company-user.service';
import { ModalService } from 'src/app/services/modal.service';
import { finalize } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { mobileIsValid } from 'src/app/helpers/mobile-format-validator';
import { UserBasicInfoViewModel } from 'src/app/models/user-basic-info-viewmodel';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { CheckListItemViewModel } from 'src/app/custom-controls/check-list-form-control/check-list-item';
import { forkJoin } from 'rxjs';
import { combinePermissions } from 'src/app/helpers/permission-helper';
import { ProductPlanFeature } from 'src/app/enums/product-plan-feature.enum';
import { CompanyRoleService } from "../../../services/company-role.service";
import { PermissionModeEnum } from "../../../enums/permission-mode.enum";
import { CompanyRoleViewModel } from "../../../models/company-role.view.model";
import { FileUploadAcceptType } from 'src/app/enums/file-upload-accept-type';


@Component({
  selector: 'obc-company-user',
  templateUrl: './company-user.component.html',
  styleUrls: ['./company-user.component.scss']
})
export class CompanyUserComponent implements OnInit {
  FileUploadAcceptType = FileUploadAcceptType;
  PermissionModeEnum = PermissionModeEnum;
  companyPermissionMode: PermissionModeEnum;
  ProductPlanFeature = ProductPlanFeature;
  inProgress: boolean = false;
  addNewMode: boolean = false;
  filterUserBy = UserAutoCompleteFilterByFields;
  companyUsers: CompanyUserViewModel[] = [];
  companyUser: CompanyUserViewModel = null;
  permissions = null;
  inEdit = {};
  filterListTerm: string;
  userPermissions = UserPermission;
  companyUserPermissions: CheckListItemViewModel[] = [];
  companyUserPermissionsFormControl: FormControl = new FormControl([]);

  // currentCompanyId: number;
  // currentCompanyName: string;
  selectedUser: UserBasicInfoViewModel;
  constructor(public userService: UserService,
    public companyRoleService: CompanyRoleService,
    private companyUserService: CompanyUserService,
    private bsModalService: BsModalService,
    private modalService: ModalService) { }

  ngOnInit(): void {
    this.initComponentData();
    this.userService.currentCompany$.subscribe(c => this._currentCompany = c);
    this.fileFormControl.valueChanges.subscribe(res => {
      if (res == null) return;
      let formData = new FormData();
      formData.append("file", res, res.name);
      this.import(formData);
    });
  }

  _currentCompany: CompanyViewModel = null;
  get currentCompany(): CompanyViewModel {
    return this._currentCompany;
  }

  get currentCompanyId(): number {
    if (this._currentCompany == null)
      return null;
    else
      return this._currentCompany.id;
  }

  get currentCompanyName(): string {
    if (this._currentCompany == null)
      return null;
    else
      return this._currentCompany.title;
  }

  initComponentData() {
    this.inProgress = true;
    const companyUsers = this.companyUserService.getCurrentCompanyUsers();
    const companyPermissions = this.companyUserService.getPermissions();
    const companyPermissionMode = this.companyRoleService.getCompanyPermissionMode();

    forkJoin({
      users: companyUsers,
      permissions: companyPermissions,
      companyPermissionMode: companyPermissionMode,
    })
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(ret => {
        this.companyPermissionMode = ret["companyPermissionMode"];
        this.companyUsers = ret["users"];
        var keys = Object.keys(ret["permissions"]);
        this.companyUserPermissions = keys.map(up => {
          return {
            value: false,
            key: +up,
            label: ret["permissions"][up],
            validators: [],
          } as CheckListItemViewModel;
        });
      });
  }

  addCompanyUserForm: FormGroup = new FormGroup({
    roles: new FormControl([]),
  });
  newUserPermissionsFormControl: FormControl;
  addNewUserToFrequentUser: boolean;
  onNewCompanyUser() {
    this.cancel();
    this.addNewMode = true;
    this.companyUser = new CompanyUserViewModel();
    this.companyUser.companyId = this.currentCompanyId;
    this.companyUser.isAdmin = false;
    this.companyUser.userId = null;
    this.companyUser.addToFrequentUser = false;
    this.selectedUser = new UserBasicInfoViewModel();
    this.newUserPermissionsFormControl = new FormControl([]);
    this.addCompanyUserForm.reset();
  }

  fillCompanyUser(cUser: UserBasicInfoViewModel) {
    if (cUser.id) this.selectedUser = cUser;
  }


  addCompanyUser() {

    if (!this.isValidate()) return;
    this.inProgress = true;
    this.companyUser.permissions = combinePermissions(
      (this.newUserPermissionsFormControl.value as CheckListItemViewModel[])
        ?.filter((item) => item.value)
        ?.map(x => x.key));
    this.companyUserService.addUser(this.companyUser)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(result => {
        this.initComponentData();
        this.cancel();
      },
        error => { this.modalService.error(error) });
  }

  onAddUserToFrequentUserList(user: CompanyUserViewModel) {
    this.modalService.confirm('Are you sure you want to add this user as frequent to all sites?')
      .subscribe(res => {
        if (res) {
          this.inProgress = true;
          this.companyUserService.onAddUserToFrequentUserList(user.userId)
            .pipe(finalize(() => { this.inProgress = false; }))
            .subscribe(res => {
              this.initComponentData();
            },
              error => {
                this.modalService.error(error).subscribe(errResponse => {
                })
              });
        }
      });
  }

  delete(user) {
    this.modalService.confirm("Are you sure you want to delete this user? ", "Delete ").subscribe(res => {
      if (res) {
        this.inProgress = true;
        this.companyUserService.removeUser(user.id)
          .pipe(finalize(() => this.inProgress = false))
          .subscribe(result => this.initComponentData(),
            error => { this.modalService.error(error) });
      }
    })
  }

  bsModalRef: any;
  editUserPermissionFormGroup: FormGroup;
  currentUser: CompanyUserViewModel;
  currentCompanyUserPermissions: any[];
  onEdit(template: any, user: CompanyUserViewModel) {
    this.currentUser = { ...user };
    var selectedPermissions = this.companyUserPermissions.map((item: CheckListItemViewModel) => { item.value = (user.permissions & item.key) > 0; return item; });

    this.editUserPermissionFormGroup =
      new FormGroup({
        isAdmin: new FormControl(user.isAdmin),
        permissions: new FormControl(selectedPermissions),
        addToFrequentUser: new FormControl(false),
        roles: new FormControl([]),
      });
    this.bsModalRef =
      this.bsModalService.show(template);
  }

  onUpdate() {
    var permissions = combinePermissions(
      (this.editUserPermissionFormGroup.controls["permissions"].value as CheckListItemViewModel[])
        .filter(c => c.value)
        .map(x => x.key));
    var isAdmin = this.editUserPermissionFormGroup.controls["isAdmin"].value;
    var model = {
      userId: this.currentUser.userId,
      isAdmin: isAdmin,
      permissions: isAdmin ? UserPermission.None : permissions,
      roles: this.currentUser.companyRoles?.map((item) => item.companyRoleId) ?? []
    } as CompanyUserUpdateRequest;

    this.inProgress = true;
    this.companyUserService.UpdateCompanyUser(model)
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(res => {
        this.bsModalRef.hide();
        this.initComponentData();
      },
        error => {
          this.modalService.error(error).subscribe(errResponse => {
            this.bsModalRef.hide();
          })
        })

  }

  get isCurrentUserIsAdmin(): boolean {
    return this.editUserPermissionFormGroup.controls["isAdmin"].value;
  }

  isValidate() {
    if (!this.companyUser.companyId)
      return false;

    if (!this.companyUser.userId && this.isValidMobile === false)
      return false;

    if (this.companyUser.isAdmin === undefined)
      return false;

    var duplicated = this.companyUsers.find(c => c.userId === this.companyUser.userId && c.companyId === this.companyUser.companyId);
    if (duplicated) {
      this.modalService.warn('this user added before.');
      return false;
    }

    return true;
  }

  get isValidMobile(): boolean {
    return (this.companyUser.mobile !== undefined && mobileIsValid(this.companyUser.mobile));
  }

  cancel() {
    this.addNewMode = false;
  }

  // import users
  importData: ImportCompanyUserResponse;
  fileFormControl = new FormControl(null, [Validators.required]);
  clear() {
    this.fileFormControl.setValue(null);
    this.importData = null;
  }

  onClearForm() {
    this.clear();
  }

  get users(): ImportCompanyUserModel[] {
    if (this.importData == null) return [];
    return this.importData.users;
  };

  import(formData) {
    this.inProgress = true;
    this.companyUserService.ImportUsers(formData)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        if (res.type == 4 && res.ok) {
          if (res.body.valid) {
            this.modalHandle.hide();
            this.initComponentData();
          } else
            this.importData = res.body;
        }
      });
  }

  modalHandle: any;
  openImportUserModal(template) {
    this.modalHandle = this.bsModalService.show(template);
  }

  getPermissionLabels(user: CompanyUserViewModel) {
    return this.companyUserPermissions
      .filter(p => (p.key & user.permissions) > 0);
  }
  getRoles(user: CompanyUserViewModel) {
    let tips = user.companyRoles?.map(p => p.name) ?? [];
    return tips.join(", ");
  }
  getToolTipCount(user: CompanyUserViewModel) {
    return user.companyRoles?.length ?? 0;
  }

  getPermissionCount(user: CompanyUserViewModel) {
    return this.companyUserPermissions
      .filter(p => (p.key & user.permissions) > 0).length;
  }

  updateRoles(roles: CompanyRoleViewModel[]) {
    this.currentUser.companyRoles = roles;
  }
  updateNewUserRoles(roles: CompanyRoleViewModel[]) {
    this.companyUser.companyRoles = roles;
  }
}
