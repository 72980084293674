import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PermitScheduleModel } from "../../models/form/permits-info-model";
import { PermitScheduleType } from "../../enums/permit-schedule-type";
import { DayOfWeek } from "../../enums/DaysOfWeek";
import { FormDataViewModel } from "../../models/form/form-data-view-model";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ModalService } from "../../services/modal.service";
import { prependZero } from "../../helpers/general-functions";
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';

@Component({
  selector: 'obc-permit-schedule-selector',
  templateUrl: './permit-schedule-selector.component.html',
  styleUrls: ['./permit-schedule-selector.component.scss']
})
export class PermitScheduleSelectorComponent implements OnInit {
  datetimePickerMode = DatetimePickerMode;
  formGroup: FormGroup = new FormGroup({
    weekDay: new FormControl(1, [Validators.required]),
    dateTime: new FormControl(new Date()),
    fromHour: new FormControl(null),
    toHour: new FormControl(null),
  });

  @Input() form: FormDataViewModel;

  @Output() validateRes = new EventEmitter();
  @Output() permitSchedulesOutput = new EventEmitter();


  _permitSchedules: PermitScheduleModel[] = [];

  @Input() set permitSchedules(value: PermitScheduleModel[]) {
    if (value != null)
      this._permitSchedules = value;
  }
  get permitSchedules() {
    return this._permitSchedules;
  }

  permitScheduleType: PermitScheduleType = PermitScheduleType.WeekDay;
  PermitScheduleTypeEnum = PermitScheduleType;

  daysOfWeek = DayOfWeek;

  constructor(private bsModalService: BsModalService, private modalService: ModalService,) {
  }

  ngOnInit(): void {
    this.formGroup?.controls['fromHour']?.valueChanges?.subscribe((_) => {
      this.updateFromTime();
    });
    this.formGroup?.controls['toHour']?.valueChanges?.subscribe((_) => {
      this.updateToTime();
    });
  }

  bsAddScheduleModalRef: BsModalRef;

  addSchedule(template) {
    this.bsAddScheduleModalRef = this.bsModalService.show(template);
  }

  /*Selector*/
  changeScheduleMode(value) {
    this.permitScheduleType = value;
    if (
      this.permitScheduleType == PermitScheduleType.Date
    ) {
      this.formGroup?.controls['weekDay']?.clearValidators();
      this.formGroup?.controls['dateTime']?.setValidators(Validators.required);

      this.formGroup?.controls['weekDay']?.setValue(null);
      this.formGroup?.controls['dateTime']?.setValue(new Date());
    } else {
      this.formGroup?.controls['dateTime']?.clearValidators();
      this.formGroup?.controls['weekDay']?.setValidators(Validators.required);

      this.formGroup?.controls['weekDay']?.setValue(1);
      this.formGroup?.controls['dateTime']?.setValue(null);
    }
    this.formGroup?.controls['dateTime']?.updateValueAndValidity();
    this.formGroup?.controls['weekDay']?.updateValueAndValidity();
  }

  updateFromTime() {

    if (
      this.formGroup?.controls['toHour']?.value == null &&
      this.formGroup?.controls['fromHour']?.value != null
    )
      this.formGroup?.controls['toHour']?.setValue(
        this.formGroup?.controls['fromHour']?.value,
        { emitEvent: false }
      );

    if (
      this.formGroup?.controls['toHour']?.value <
      this.formGroup?.controls['fromHour']?.value
    )
      this.formGroup?.controls['toHour']?.setValue(
        this.formGroup?.controls['fromHour']?.value,
        { emitEvent: false }
      );

  }

  updateToTime() {

    if (
      this.formGroup?.controls['fromHour']?.value == null &&
      this.formGroup?.controls['toHour']?.value != null
    )
      this.formGroup?.controls['fromHour']?.setValue(
        this.formGroup?.controls['toHour']?.value,
        { emitEvent: false }
      );

    if (
      this.formGroup?.controls['toHour']?.value <
      this.formGroup?.controls['fromHour']?.value
    )
      this.formGroup?.controls['toHour']?.setValue(
        this.formGroup?.controls['fromHour']?.value,
        { emitEvent: false }
      );

  }

  clearTimes() {
    this.formGroup?.controls['fromHour']?.setValue(null);
    this.formGroup?.controls['toHour']?.setValue(null);
  }

  saveSchedule() {
    if (this.validateSchedule()) {
      let fromHour = this.formGroup?.controls['fromHour']?.value;
      let toHour = this.formGroup?.controls['toHour']?.value;

      this.permitSchedules?.push(
        {
          weekDay: this.permitScheduleType == PermitScheduleType.WeekDay ?
            this.formGroup?.controls['weekDay']?.value : null,
          dateTime: this.permitScheduleType == PermitScheduleType.Date ?
            this.formGroup?.controls['dateTime']?.value : null,
          fromHour: fromHour ? prependZero(fromHour.getHours()) + ':' + prependZero(fromHour.getMinutes()) : null,
          toHour: toHour ? prependZero(toHour.getHours()) + ':' + prependZero(toHour.getMinutes()) : null,
        } as PermitScheduleModel
      );
      this.permitSchedulesOutput.emit(this.permitSchedules);
      this.changeScheduleMode(PermitScheduleType.WeekDay);
      this.bsAddScheduleModalRef.hide()
    }

  }

  removeSchedule(schedule) {
    this.modalService.confirm("Are You Sure?", `Remove Schedule`).subscribe(result => {
      if (result) {
        this.permitSchedules = this.permitSchedules?.filter(item => item != schedule);
        this.permitSchedulesOutput.emit(this.permitSchedules);
      }
    });
  }

  validateSchedule() {
    let resArray: boolean[] = []
    if (this.permitScheduleType == PermitScheduleType.Date)
      resArray.push(this.formGroup?.controls['dateTime']?.value != null && this.formGroup?.controls['dateTime']?.value != 'Invalid Date');
    else
      resArray.push(this.formGroup?.controls['weekDay']?.valid);
    resArray.push(this.formGroup?.controls['fromHour']?.valid && this.formGroup?.controls['toHour']?.valid);
    return this._validateBooleanArray(resArray);
  }

  _validateBooleanArray(arr) {
    return arr != null && arr.indexOf(false) == -1
  }
}
