import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { Table } from 'src/app/enums/table.enum';
import { GridColumn, GridConfig, GridTemplate, InitialFilterUsage } from 'src/app/models/grid/grid.config';
import { SupplierDocumentSignOff } from 'src/app/models/supplier-document/supplier-document-signoff';
import { Constants } from "../../../../enums/constants";

@Component({
  selector: 'obc-supplier-document-signoff-report',
  templateUrl: './supplier-document-signoff-report.component.html',
  styleUrls: ['./supplier-document-signoff-report.component.scss']
})
export class SupplierDocumentSignoffReportComponent implements OnInit {
  @Input() selectedUserId: number = null;
  @Input() siteIdList: number[] = [];
  @Input() isReportMode: boolean = false;

  @ViewChild('grid') grid: any;
  gridColumns: GridColumn<SupplierDocumentSignOff>[] = [];
  gridConfig: GridConfig = {} as GridConfig;


  constructor() { }

  ngOnInit(): void {
    this.prepareGridConfig();
  }

  prepareGridConfig() {
    let currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 1);

    let initialFilters = [];
    if (this.selectedUserId) {
      initialFilters.push({ key: 'userId', value: this.selectedUserId });
    }
    if (this.siteIdList?.length) {
      initialFilters.push({ key: 'siteIdList', value: this.siteIdList });
    }

    this.gridConfig = new GridConfig({
      apiUrl: '/api/supplierdocument/get-supplier-document-signoff',
      tableType: Table.SupplierDocumentSignOffReport,
      forceHideAllControlButtons: this.isReportMode,
      displayQuestionsFilter: false,
      displayGeneralSearch: false,
      initialFilters: initialFilters,
      cacheKeyPrefix: this.isReportMode ? Constants.WORKER_PROFILE_REPORT_PREFIX : '',
    });
    this.gridColumns = [
      {
        name: "Site",
        key: "siteName",
        type: FilterType.Site,
        propertyNameInQuery: 'siteIdList',
        enableFilter: true,
        enableSort: true,
        order: 0,
      },
      {
        name: "Supplier",
        key: "supplierName",
        type: FilterType.Supplier,
        propertyNameInQuery: 'supplierIdList',
        enableFilter: true,
        enableSort: true,
        order: 1,
      },
      {
        name: "Document Type",
        key: "siteSupplierDocumentType",
        type: FilterType.Text,
        propertyNameInQuery: 'siteSupplierDocumentTypeSearchTerm',
        isArray: false,
        enableFilter: true,
        enableSort: true,
        order: 2,
      },
      {
        name: "Version",
        key: "siteSupplierDocumentVersion",
        enableFilter: false,
        enableSort: false,
        order: 3,
      },
      {
        name: "SignOff Date",
        key: "signOffDate",
        type: FilterType.DateTime,
        enableFilter: false,
        enableSort: false,
        order: 4,
      },
      {
        name: "Actions",
        key: "fileUrls",
        type: FilterType.Template,
        enableFilter: false,
        enableSort: false,
        order: 5,
        gridTemplate: new GridTemplate().ComponentTemplateName('viewDetails')
      },
    ]
  };
}
