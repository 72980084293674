<div class="card">
  <div class="card-body">
    <table class="table table-bordered fw">
      <thead>
      <tr>
        <th colspan="2">Global Dynamic Fields</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          <!-- <accordion>
              <accordion-group>
                  <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                      <div class="d-flex flex-row justify-content-between">
                          <div class="pull-left float-left">Asset Field(s) </div>
                          <div>{{toolDynamicFields?.length}} items</div>
                      </div>
                  </button>-->
          <div>
            <div class="row">
              <div class="col-md-3">
                Name
              </div>
              <div class="col-md-2">
                Type
              </div>
              <div class="col-md-2">
                Is Barcode?
              </div>
              <div class="col-md-2">
                Use In Search?
              </div>
              <div class="col-md-2">
                <button class="btn btn-info pull-right"
                        (click)="onOpenToolDynamicField(toolDynamicField, ToolDynamicFieldUsage.Tool)">+Add
                </button>
              </div>
            </div>
            <ng-container *ngIf="toolDynamicFields?.length">

              <div class="row" *ngFor="let item of toolDynamicFields">
                <div class="col-md-3">
                  {{item.fieldName}}{{item.isLongText ? ' (Long Text)' : ''}}
                </div>

                <div class="col-md-2">
                  {{item.type | toolDynamicFieldType}}{{item.type == ToolDynamicFieldType.File ? "(" + (item.fileType| questionAnswerFileFormat) + ")" : ""}}
                </div>
                <div class="col-md-2">
                  {{item.isBarcode ? "Yes" : "No"}}
                </div>
                <div class="col-md-2">
                  {{item.isSearchable ? "Yes" : "No"}}
                </div>
                <div class="col-md-2">
                  <button class="pl-1 pr-1" (click)="onRemoveToolDynamicField(item.id, ToolDynamicFieldUsage.Tool)"
                          class="btn btn-sm btn-danger m-1">
                    <i class="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- </accordion-group>
                    </accordion> -->
        </td>
      </tr>
      <!-- <tr>
                <td>
                    <accordion>
                        <accordion-group>
                            <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                                <div class="d-flex flex-row justify-content-between">
                                    <div class="pull-left float-left">Assignment Field(s) </div>
                                    <div>{{toolAssignDynamicFields?.length}} items</div>
                                </div>
                            </button>
                            <div>
                                <div class="row field-list-header">
                                    <div class="col-md-4">
                                        Name
                                    </div>
                                    <div class="col-md-2">
                                        Type
                                    </div>
                                    <div class="col-md-2">
                                        Is Barcode?
                                    </div>
                                    <div class="col-md-2">
                                        <button class="btn btn-info pull-right"
                                            (click)="onOpenToolDynamicField(toolDynamicField, ToolDynamicFieldUsage.ToolAssign)">+
                                            Add</button>
                                    </div>
                                </div>
                                <ng-container *ngIf="toolAssignDynamicFields?.length">
                                    <div class="row" *ngFor="let item of toolAssignDynamicFields">
                                        <div class="col-md-4">
                                            {{item.fieldName}}{{item.isLongText ? ' (Long Text)' : ''}}
                                        </div>
                                        <div class="col-md-2">
                                            {{item.type | toolDynamicFieldType}}
                                        </div>
                                        <div class="col-md-2">
                                            {{item.isBarcode ? "Yes":"No"}}
                                        </div>
                                        <div class="col-md-2">
                                            <button
                                                (click)="onRemoveToolDynamicField( item.id, ToolDynamicFieldUsage.ToolAssign)"
                                                class="btn btn-sm btn-danger m-1">
                                                <i class="fa fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </accordion-group>
                    </accordion>
                </td>
            </tr> -->

      </tbody>
    </table>
  </div>
</div>

<ng-template #toolDynamicField>
  <div class="card">
    <div class="card-header">
      {{toolDynamicFieldformGroup.controls.usage.value == ToolDynamicFieldUsage.ToolAssign ? "Add Tool Assign Field" : "Add Tool Field"}}
    </div>
    <div class="card-body">
      <div [formGroup]="toolDynamicFieldformGroup">

        <div class="form-group mb-3">
          <label>Field Name</label>
          <div class="input-group">
            <input class="form-control" formControlName="fieldName"/>
          </div>
        </div>
        <ng-container>
          <div class="form-group">
            <label>Type</label>
            <select class="custom-select form-control" formControlName="type" (change)="onToolDynamicFieldTypeChange()">
              <option *ngFor="let index of ToolDynamicFieldType | enumToArray" [ngValue]="ToolDynamicFieldType[index]">
                {{ ToolDynamicFieldType[index] | toolDynamicFieldType }}
              </option>
            </select>
          </div>
          <div *ngIf="toolDynamicFieldformGroup.controls.type.value == ToolDynamicFieldType.Text ||
            toolDynamicFieldformGroup.controls.type.value == ToolDynamicFieldType.Number"
               class="form-group form-check">
            <input type="checkbox" class="form-check-input" formControlName="isBarcode">
            <label class="form-check-label">Show barcode scanner to read information?</label>
          </div>
          <div *ngIf="toolDynamicFieldformGroup.controls.usage.value == ToolDynamicFieldUsage.Tool &&
                (toolDynamicFieldformGroup.controls.type.value == ToolDynamicFieldType.Text ||
                  toolDynamicFieldformGroup.controls.type.value == ToolDynamicFieldType.Number)"
               class="form-group form-check">
            <input type="checkbox" class="form-check-input" formControlName="isSearchable">
            <label class="form-check-label">Use in search?</label>
          </div>
          <div *ngIf="toolDynamicFieldformGroup.controls.type.value == ToolDynamicFieldType.Text"
               class="form-group form-check">
            <input type="checkbox" class="form-check-input" formControlName="isLongText">
            <label class="form-check-label">Is long text?</label>
          </div>

          <div *ngIf="toolDynamicFieldformGroup.controls.type.value == ToolDynamicFieldType.File" class="form-group">
            <label>File Type</label>
            <select class="custom-select form-control" formControlName="fileType">
              <option [ngValue]="QuestionAnswerFileFormat[type]"
                      *ngFor="let type of QuestionAnswerFileFormat | enumToArray">
                {{ QuestionAnswerFileFormat[type] | questionAnswerFileFormat }}
              </option>
            </select>
          </div>
        </ng-container>
        <div class="text-right">
          <button class="btn btn-primary" [disabled]="isAddToolDynamicFieldButtonDisabled"
                  (click)="onAddToolDynamicField()">Add
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
