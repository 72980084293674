<div *ngIf="siteSupplierDocumentVersionId == null" class="text-danger">
  Site Supplier Document Version Id is needed
</div>
<ng-container *ngIf="siteSupplierDocumentVersionId != null">
  <accordion class="p-3" *ngIf="displayAsModal == false">
    <accordion-group [isOpen]="true">
      <div accordion-heading>
        <ng-template [ngTemplateOutlet]="headerAndNewCommentTemplate"></ng-template>
      </div>
      <ng-template [ngTemplateOutlet]="commentsListTemplate"></ng-template>
    </accordion-group>
  </accordion>

  <ng-container *ngIf="displayAsModal">
    <button class="{{ classes?.indexOf('dropdown-item') > -1 ? classes : 'btn btn-info ' + classes }}"
      (click)="displayCommentsModal(commentsListModalTemplate)">
      <span *ngIf="!noIcon" class="fa fa-comments"></span>
      Comments
    </button>
  </ng-container>
</ng-container>

<ng-template #commentsListModalTemplate>
  <div class="card">
    <div class="card-header">
      <ng-template [ngTemplateOutlet]="headerAndNewCommentTemplate"></ng-template>
    </div>
    <div class="card-body">
      <ng-template [ngTemplateOutlet]="commentsListTemplate"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #headerAndNewCommentTemplate>
  <div class="d-flex justify-content-between align-items-center">
    Comments {{commentsList?.length > 0 ? '(' + commentsList?.length + ')' : ''}}
    <div class="d-flex align-items-center">
      <button *ngIf="commentsList?.length > 0 && hasPermissionToAddSupplierDocumentComment" class="btn btn-info mr-3"
        (click)="displayAddNewCommentModal(addNewComment)">
        Add New Comment
      </button>
      <span *ngIf="displayAsModal == true" class="fa fa-times p-1" style="cursor: pointer"
        (click)="closeCommentsModal()"></span>
    </div>
  </div>
</ng-template>

<ng-template #commentsListTemplate>
  <div *ngIf="commentsList?.length > 0">
    <div class="comment-record" *ngFor="let comment of commentsList; let index = index;">
      <div class="p-3">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <h6 class="m-0">{{ comment.fullName }}</h6>
            <div class="ml-3">({{comment.mobile | mobile}})</div>
          </div>
          <div class="d-flex flex-column align-items-center">
            <span>{{ comment.commentDate | datetime}}</span>

          </div>
        </div>
        <div *ngIf="comment.hasAcknowledgement" class="d-flex align-items-center">
          <span class="fa fa-check text-success" style="font-size: 12px;"></span>
          <span class="ml-1" style="font-size: 12px;">{{ supplierDocCommentAcknowledgementText }}</span>
        </div>
        <div class="mt-2" style="white-space: pre-wrap;">
          {{ comment.comment }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="commentsList?.length == 0"
    class="d-flex justify-content-center p-5 text-center align-items-center flex-column">
    <span class="text-muted">There is no comment submitted yet.</span>
    <div *ngIf="hasPermissionToAddSupplierDocumentComment" class="d-flex mt-3 flex-column">
      <button class="btn btn-success" (click)="displayAddNewCommentModal(addNewComment)">Submit a new comment</button>
    </div>
  </div>
</ng-template>
<ng-template #addNewComment>
  <div class="card">
    <div class="card-header">
      <div class="d-flex justify-content-between align-items-center">
        Add New Comment
        <div class="d-flex">
          <span class="fa fa-times p-1" style="cursor: pointer" (click)="closeNewCommentModal()"></span>
        </div>
      </div>
    </div>
    <div class="card-body">
      <obc-loader [show]="inProgress"></obc-loader>
      <textarea [formControl]="comment" placeholder="Write your comment here ..." class="form-control" cols="30"
        rows="6"></textarea>
      <div class="d-flex align-items-center mt-3">
        <obc-switch [value]="hasAcknowledgment" (valueChanged)="hasAcknowledgment = $event">
        </obc-switch>
        <span class="ml-2">{{ supplierDocCommentAcknowledgementText }}</span>
      </div>
      <div class="d-flex align-items-center mt-3">
        <obc-switch [value]="notifySupplier" (valueChanged)="notifySupplier = $event">
        </obc-switch>
        <span class="ml-2">Notify Supplier</span>
      </div>
      <div class="text-right">
        <button class="btn btn-success mt-3" [disabled]="!isNewCommentFormValid()" (click)="submitNewComment()">
          Submit Comment
        </button>
      </div>
    </div>
  </div>
</ng-template>