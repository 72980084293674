import { Pipe, PipeTransform } from '@angular/core';
import { MobilePipe } from './mobile.pipe';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  constructor(private mobilePipe: MobilePipe) { }

  transform(list: any[], filterTerm: any, filterProperties: string[] = null): any[] {
    if (!filterTerm || filterTerm == '' || !list || !list.length)
      return list;

    var term = filterTerm.toLowerCase();
    if (!filterProperties)
      filterProperties = Object.keys(list[0]);

    return list.filter(item => {
      var isAny = filterProperties.filter(key => {
        var v = item[key];
        if (v && v.toString().toLowerCase().indexOf(term) >= 0)
          return true;
        if (key == 'mobile') {
          v = this.mobilePipe.transform(item[key]);
          return v && v.toString().toLowerCase().split(' ').join('').indexOf(term) >= 0;
        }
        return false;
      });
      return isAny.length > 0;
    });

    //if (!filterObject)
    //  return list;
    //var keys = Object.keys(filterObject);
    //if (keys.length == 0 || keys.every(k => !filterObject[k] || filterObject[k] == ''))
    //  return list;

    //return list.filter(item => keys.some(k => item[k] && item[k].toString().contains(filterObject[k].toString())));

  }

}
