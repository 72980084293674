import { ProcorePermission } from './../models/procore/procore-permission';
import { ProcoreProjectModel } from './../models/procore/procore-project-model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcoreIntegrationType } from '../enums/procore-integration-type.enum';
import { BaseBioStarIntegrationConfiguration, BioStarIntegrationConfiguration } from '../models/biostar/bio-star-integration-configuration';
import { IntDictionary } from '../models/dictionary';
import { HandsHQCheckTokenResponse, HandsHQConfigurationModel } from '../models/handsHQ/handshq-configuration';
import { BaseMyobConfiguration, MyobCompanyFile, MyobConfiguration } from '../models/myob/myob-configuration';
import {
  BaseProcoreIntegrationConfigModel,
  ProcoreCheckTokenResponse,
  ProcoreIntegrationAuthorizationModel,
  ProcoreIntegrationTypeInfo
} from '../models/procore-integration-models';
import { CompanyPermissionsProjects } from '../models/procore/company-permissions-projects';
import { CompanySupplierConfiguration } from '../models/supplier-document/company-supplier-configuration';
import { CompanySupplierDocumentConfiguration } from '../models/supplier-document/company-supplier-document-configuration';
import { SupplierDocumentEmailReminder, SupplierDocumentExpiryNotificationReminderModel } from '../models/supplier-document/supplier-document-email-reminder-model';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class CompanySettingService {


  apiUrl = '/api/companySetting';
  constructor(private http: HttpClient, private _errorService: ErrorService) { }

  checkAndSaveAuthorization(model: ProcoreIntegrationAuthorizationModel)
    : Observable<ProcoreCheckTokenResponse> {
    return this.http.post(`${this.apiUrl}/check-and-save-procore-authorization`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  updateProcoreConfig(model: BaseProcoreIntegrationConfigModel): Observable<ProcoreCheckTokenResponse> {
    return this.http.post(`${this.apiUrl}/update-procore-config`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  getTokenInfo(): Observable<ProcoreCheckTokenResponse> {
    return this.http.get(`${this.apiUrl}/get-procore-token-info`)
      .pipe(catchError(this._errorService.handleError));
  }

  toggleProcoreEnable(): Observable<ProcoreCheckTokenResponse> {
    return this.http.get(`${this.apiUrl}/toggle-procore-enable`)
      .pipe(catchError(this._errorService.handleError));
  }

  resetProcoreSchedule(type: ProcoreIntegrationType): Observable<IntDictionary<ProcoreIntegrationTypeInfo>> {
    return this.http.get(`${this.apiUrl}/reset-procore-schedule/${type}`)
      .pipe(catchError(this._errorService.handleError));
  }
  saveMyobConfiguration(config: BaseMyobConfiguration): Observable<MyobConfiguration> {
    return this.http.post(`${this.apiUrl}/save-myob-configuration`, config)
      .pipe(catchError(this._errorService.handleError));
  }
  validateAndSaveMyobConfiguration(config: BaseMyobConfiguration): Observable<MyobConfiguration> {
    return this.http.post(`${this.apiUrl}/validate-save-myob-configuration`, config)
      .pipe(catchError(this._errorService.handleError));
  }
  getMyobConfiguration(): Observable<MyobConfiguration> {
    return this.http.get(`${this.apiUrl}/get-myob-configuration`)
      .pipe(catchError(this._errorService.handleError));
  }
  getMyobPaymentCategories(): Observable<any[]> {
    return this.http.get(`${this.apiUrl}/get-myob-payment-categories`)
      .pipe(catchError(this._errorService.handleError));
  }
  getMyobCompanies(): Observable<MyobCompanyFile[]> {
    return this.http.get(`${this.apiUrl}/get-myob-companies`)
      .pipe(catchError(this._errorService.handleError));
  }
  getSupplierDocumentConfiguration(): Observable<CompanySupplierConfiguration> {
    return this.http.get(`${this.apiUrl}/get-supplier-configuration`)
      .pipe(catchError(this._errorService.handleError));
  }
  saveSupplierDocumentConfiguration(model: CompanySupplierDocumentConfiguration): Observable<void> {
    return this.http.post(`${this.apiUrl}/save-supplier-document-configuration`, model)
      .pipe(catchError(this._errorService.handleError));
  }
  saveSupplierDocumentEmailReminderInfo(model: SupplierDocumentEmailReminder): Observable<void> {
    return this.http.post(`${this.apiUrl}/save-supplier-doc-email-reminder`, model)
      .pipe(catchError(this._errorService.handleError));
  }
  saveSupplierDocumentexpiryEmailnotificationReminderInfo(model: SupplierDocumentExpiryNotificationReminderModel): Observable<void> {
    return this.http.post(`${this.apiUrl}/save-supplier-doc-expiry-notification-email-reminder`, model)
      .pipe(catchError(this._errorService.handleError));
  }
  // updateProcoreCompanyIdForDmsaMode(companyId: any) {
  //   return this.http.post(`${this.apiUrl}/save-procore-dmsa-company-id`, { companyId: companyId })
  //     .pipe(catchError(this._errorService.handleError));
  // }

  getHandsHQConfiguration(): Observable<HandsHQCheckTokenResponse> {
    return this.http.get(`${this.apiUrl}/get-handshq-configuration`)
      .pipe(catchError(this._errorService.handleError));
  }
  validateAndSaveHandsHQConfiguration(model: HandsHQConfigurationModel): Observable<HandsHQConfigurationModel> {
    return this.http.post(`${this.apiUrl}/validate-and-save-handshq-configuration`, model)
      .pipe(catchError(this._errorService.handleError));
  }
  inactiveHandsHQIntegration(): Observable<boolean> {
    return this.http.post(`${this.apiUrl}/inactive-handshq-integration`, null)
      .pipe(catchError(this._errorService.handleError));
  }
  getBioStarConfiguration(): Observable<BioStarIntegrationConfiguration> {
    return this.http.get(`${this.apiUrl}/biostar-configuration`)
      .pipe(catchError(this._errorService.handleError));
  }
  validateAndSaveBiostarConfiguration(config: BaseBioStarIntegrationConfiguration): Observable<BioStarIntegrationConfiguration> {
    return this.http.post(`${this.apiUrl}/validate-save-biostar-configuration`, config)
      .pipe(catchError(this._errorService.handleError));
  }

  GetProcorePermissionsProjects(): Observable<CompanyPermissionsProjects> {
    return this.http.post(`${this.apiUrl}/procore-company-permissions-projects`, null)
      .pipe(catchError(this._errorService.handleError));
  }

  GetProjectPermissions(model: ProcoreProjectModel): Observable<ProcorePermission[]> {
    return this.http.post(`${this.apiUrl}/procore-project-permissions`, model)
      .pipe(catchError(this._errorService.handleError));
  }
}
