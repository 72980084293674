<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <obc-announcement></obc-announcement>
      </div>
    </div>
  </div>
</div>

