import { Component, OnInit } from '@angular/core';
import { ReportPeriodType } from 'src/app/enums/report-type.enum';
import { ScheduleReportMode } from 'src/app/enums/schedule-report-mode';
import { ScheduleReportType } from 'src/app/enums/schedule-report-type';
import { SaveScheduledReportViewmodel, ScheduledReportViewModel } from 'src/app/models/save-scheduled-report-model';
import { LayoutService } from 'src/app/services/layout.service';
import { ModalService } from 'src/app/services/modal.service';
import { SiteAttendanceService } from 'src/app/services/site-attendance.service';

@Component({
  selector: 'obc-my-scheduled-reports',
  templateUrl: './my-scheduled-reports.component.html',
  styleUrls: ['./my-scheduled-reports.component.scss']
})
export class MyScheduledReportsComponent implements OnInit {
  inProgress: boolean = false;
  reports: ScheduledReportViewModel[];
  ReportPeriodType = ReportPeriodType;
  ScheduleReportType = ScheduleReportType;
  ScheduleReportMode = ScheduleReportMode;
  
  constructor(
    private siteAttendanceService: SiteAttendanceService,
    private layoutService: LayoutService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.layoutService.header = 'My Scheduled Reports';
    this.loadReports();
  }

  private loadReports() {
    this.inProgress = true;
    this.siteAttendanceService.getMyScheduledReport()
      .subscribe(result => {
        this.reports = result;
        this.inProgress = false;
      });
  }

  removeReport(report: ScheduledReportViewModel) {
    this.modalService.confirm('Are you sure you want to delete this report?')
      .subscribe(confirmResult => {
        if (confirmResult == true) {
          this.inProgress = true;
          this.siteAttendanceService.deleteMyScheduledReport(report.id)
            .subscribe(result => {
              this.inProgress = false;
              this.loadReports();
            });
        }
      });
  }
}
