<div class="form-group">
  <label>{{item?.fieldName}} {{item?.isRequired ? "*":""}}</label>
  <div class="fw d-flex flex-column mb-2 p-3" style="overflow-x: hidden;" *ngIf="images?.length>0">
    <div *ngFor="let image of images;">
      <obc-dropzone-file [source]="image.url" [sourceType]="DropzoneSourceType.URL"
        (removeItem)="removeImage(image)"></obc-dropzone-file>
    </div>
  </div>
  <div class="d-flex flex-row mb-1" *ngFor="let c of formArray.controls; let i = index">
    <div class="flex-fill">
      <obc-file-upload [showClearButton]="true" (clear)="c.reset();updateValues()" [accept]=" fileAcceptType"
        [formControl]="c">
      </obc-file-upload>
    </div>
    <div>
      <button *ngIf="formArray.controls.length > 1" (click)="removeImageInput(c)"
        class="ml-1 btn btn-lg btn-danger">Remove</button>
    </div>
  </div>
</div>
<div class="text-right">
  <button (click)="addImageInput()" class="btn btn-sm btn-primary">Add</button>
</div>
