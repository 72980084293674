export class EvacuationViewModel {
    evacuationId: number;
    createByUserFullName: string;
    createDateTime: Date;
    mode: EvacuationMode;
    status: EvacuationStatus;
    totalPresentPeople: number;
    totalPeople: number;
}
export class EvacuationRepotViewModel {
    evacuationViewModels: EvacuationViewModel[];
    allowToCreateEmergencyEvacuation: boolean;
    allowToCreateDrillEvacuation: boolean;
}
export class EvacuationPersonViewModel {
    evacuationPersonId: number;
    userId: number;
    siteAttendanceId: number;
    mobile: string;
    firstName: string;
    lastName: string;
    supplierName: string;
    isPresent: boolean;
}
export class EvacuationItemViewModel {
    evacuationViewModel: EvacuationViewModel;
    evacuationPersonViewModels: EvacuationPersonViewModel[];
    allowToEditEvacuation: boolean;
}
export class EvacuationLogViewModel {
    evacuationId: number;
    dateTime: Date;
    userFullName: string;
    userMobileNumber: string;
    logItemDescriptions: any[];
}
export enum EvacuationMode {
    Drill = 0,
    Emergency = 1,
}
export enum EvacuationStatus {
    Draft = 0,
    Finalized = 1,
}
export class EvacuationReportQueryModel {
    siteId: number;
    mode: EvacuationMode;
    status: EvacuationStatus;
}