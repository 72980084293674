import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CompanyTagViewModel } from '../models/company-tag';

@Injectable({
  providedIn: 'root'
})
export class CompanyTagService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getCompanyTags = (): Observable<CompanyTagViewModel[]> =>
    this._http.get<CompanyTagViewModel[]>('/api/companytag/get-company-tags')
      .pipe(catchError(this._errorService.handleError));

}
