import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SiteService } from 'src/app/services/site.service';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { EditableItem } from 'src/app/models/editable-item';
import { AnnouncementMode } from 'src/app/enums/announcement-mode.enum';
import { FormControl, Validators } from '@angular/forms';
import { FileType } from 'src/app/enums/file-type.enum';
import { ModalService } from '../../../services/modal.service';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { UserService } from 'src/app/services/user.service';
import { UserAutoCompleteFilterByFields } from '../../../enums/user-auto-complete-target-fields';
import { e168Validator2 } from '../../../helpers/mobile-format-validator';
import { PrimitiveUserInformation } from '../../../models/user-profile-viewmodel';
import { IndustryTypeViewModel } from '../../../models/industry-type/industry-type-view-model';
import { IndustryTypeService } from '../../../services/industry-type-service';
import { VisitorType } from 'src/app/models/visitor-type';
import { SiteTemplateStatus } from 'src/app/enums/site-template-status.enum';
import { deepClone, getCountLabel, isEqual } from 'src/app/helpers/general-functions';
import { ManageSiteViewModel } from "../../../models/manage-site-view-model";
import { AnnouncementViewModel, SiteAnnouncementViewModel } from "../../../models/announcement-viewmodel";
import { SiteActiveStatus } from 'src/app/enums/site-active-status.enum';
import { DirtyComponents } from "../../../models/dirty-components";
import { SiteAnnouncementForceViewMode } from 'src/app/enums/site-announcement-force-view-mode';
import { SiteAnnouncementDisplayTime } from 'src/app/enums/site-announcement-display-time.enum';
import { CompanySupplierQuery, CompanySupplierViewModel } from 'src/app/models/supplier-models';
import { CompanySupplierService } from 'src/app/services/company-supplier.service';
import { SiteManagementMode } from "../../../enums/site-management-mode";
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';

@Component({
  selector: 'obc-site-announcement-manage',
  templateUrl: './site-announcement-manage.component.html',
  styleUrls: ['./site-announcement-manage.component.css']
})
export class SiteAnnouncementManageComponent implements OnInit {

  FileType = FileType;
  DatetimePickerMode = DatetimePickerMode;
  dirtyComponents: DirtyComponents;
  @Input('dirtyComponents') set setDirtyComponents(dirtyComponents: DirtyComponents) {
    this.dirtyComponents = dirtyComponents;
  }
  @Input() siteManagementMode: SiteManagementMode = SiteManagementMode.Site;
  SiteManagementMode = SiteManagementMode;

  makeComponentDirty() {
    this.dirtyComponents.announcements.isDirty = !isEqual(this.dirtyComponents?.announcements?.snapshot, this.myCopy());
  }
  myCopy() {
    return {
      siteAnnouncements: deepClone(this.manageSiteModel?.announcements),
    };
  }

  maxAnnouncementCount = 50;
  get canAddNewAnnouncement(): boolean {
    return (this.siteAnnouncements?.length ?? 0) < this.maxAnnouncementCount;
  }

  manageSiteModel: ManageSiteViewModel;
  @Input() set manageSiteViewModel(manageSiteModel: ManageSiteViewModel) {
    this.manageSiteModel = manageSiteModel;
    this.loadSiteAnnouncements();

    if (this.manageSiteModel && this.manageSiteModel.announcements) {
      this.dirtyComponents.announcements.snapshot = this.myCopy();
    }
  }
  @Output() manageSiteViewModelChange = new EventEmitter<ManageSiteViewModel>()

  _switchview: 'list' | 'add' | 'edit' = 'list';
  get switchview() { return this._switchview; }
  set switchview(value) {
    if (value == 'edit' && this.newSiteAnnouncement && this.newSiteAnnouncement.formGroup) {
      this.newSiteAnnouncement.formGroup.controls.announcementId.disable();
    } else
      this.newSiteAnnouncement.formGroup.controls.announcementId.enable();
    this._switchview = value;
  }
  siteId: number;
  progress = false;
  announcementTypes = FileType;
  siteAnnouncements: EditableItem<SiteAnnouncementViewModel>[];
  newSiteAnnouncement: EditableItem<SiteAnnouncementViewModel>;
  currentCompleteUser: PrimitiveUserInformation = new PrimitiveUserInformation();
  filterUserBy = UserAutoCompleteFilterByFields;
  successMessage: string = null;
  industryTypeList: IndustryTypeViewModel[];
  visitorTypeList: VisitorType[];
  companySupplierList: CompanySupplierViewModel[];
  selectedIndustryType: IndustryTypeViewModel;
  SiteTemplateStatus = SiteTemplateStatus;
  selectedVisitorType: VisitorType;
  selectedCompanySupplier: CompanySupplierViewModel;
  isEnableAddUser: boolean = false;
  isEnableAddIndustryType: boolean = false;
  isEnableAddVisitorType: boolean = false;
  isEnableAddCompanySupplier: boolean = false;
  UserPermission = UserPermission;
  SiteAnnouncementForceViewMode = SiteAnnouncementForceViewMode;
  get headerInfo() {
    return getCountLabel(this.siteAnnouncements?.length, 'Announcement');
  }

  announcements: AnnouncementViewModel[];
  announcementMode = AnnouncementMode;
  siteAnnouncementDisplayTime = SiteAnnouncementDisplayTime;
  constructor(private siteService: SiteService,
    public userService: UserService,
    private announcementService: AnnouncementService,
    private router: Router,
    private modalService: ModalService,
    private industryTypeService: IndustryTypeService,
    private companySupplierService: CompanySupplierService) { }

  ngOnInit() {
    this.loadSiteAnnouncements();
  }

  loadSiteAnnouncements() {
    this.progress = true;

    let validators = {
      "announcementId": [Validators.required]
    };
    this.siteAnnouncements = this.manageSiteModel?.announcements?.map((i) => {
      return new EditableItem(i, validators);
    });
    this.reOrderSiteAnnouncements();
    this.progress = false;
  }

  reOrderSiteAnnouncements() {
    for (let i = 0; i < (this.siteAnnouncements?.length ?? 0); i++) {
      this.siteAnnouncements.find(j => j.model.announcementId == this.siteAnnouncements[i].model.announcementId).model.order = i;
    }
    this.sortSiteAnnouncements();
  }

  sortSiteAnnouncements() {
    this.siteAnnouncements = this.siteAnnouncements?.sort((p, n) => p.model.order - n.model.order);
  }


  //#region ADD/Edit Site Announcement
  async initAddEditSiteAnouncement(switchView: any, id?: number) {
    return new Promise<void>(async (resolve) => {
      if (switchView == "add") {
        this.newSiteAnnouncement = new EditableItem({
          siteId: this.manageSiteModel?.site?.id,
          siteName: null,
          announcementId: null,
          validFrom: null,
          validTo: null,
          mode: this.announcementMode.Silent,
          users: [],
          industryTypes: [],
          visitorTypes: [],
          companySuppliers: [],
          title: null,
          groupName: null,
          order: (this.siteAnnouncements?.length ?? 0) == 0 ? 0 : this.siteAnnouncements?.length,
          type: FileType.Pdf,
          forceViewMode: this.SiteAnnouncementForceViewMode.Disable,
          displayTime: this.siteAnnouncementDisplayTime.AfterCheckin,
        } as SiteAnnouncementViewModel, {
        });
      }
      this.switchview = switchView;
      await this.getAnnouncementList(id);

      this.newSiteAnnouncement?.formGroup?.get('announcementId')?.valueChanges?.subscribe((res) => {
        if (res) {
          let relatedAnnouncement = this.announcements?.find((ann) => ann.id == res);
          if (relatedAnnouncement) {
            this.onAnnouncementTypeChanged(relatedAnnouncement.type);
          }
        }
      });

      this.getIndustryTypeList();
      this.getVisitorTypeList();
      this.getCompanySupplierList();
      this.currentCompleteUser = new PrimitiveUserInformation();
      this.isEnableAddUser = false;
      this.isEnableAddIndustryType = false;
      this.isEnableAddVisitorType = false;
      this.isEnableAddCompanySupplier = false;
      resolve();
    });
  }

  async onEditSelected(selectedSiteAnnouncement: SiteAnnouncementViewModel) {
    this.newAnnouncementType = selectedSiteAnnouncement.type;
    this.newSiteAnnouncement = new EditableItem({
      siteId: this.manageSiteModel?.site?.id,
      siteName: selectedSiteAnnouncement.siteName,
      announcementId: selectedSiteAnnouncement.announcementId,
      siteAnnouncementId: selectedSiteAnnouncement.siteAnnouncementId,
      validFrom: selectedSiteAnnouncement.validFrom,
      validTo: selectedSiteAnnouncement.validTo,
      mode: selectedSiteAnnouncement.mode,
      users: selectedSiteAnnouncement.users ? [...selectedSiteAnnouncement.users] : [],
      industryTypes: selectedSiteAnnouncement.industryTypes ? [...selectedSiteAnnouncement.industryTypes] : [],
      visitorTypes: selectedSiteAnnouncement.visitorTypes ? [...selectedSiteAnnouncement.visitorTypes] : [],
      title: selectedSiteAnnouncement.title,
      groupName: selectedSiteAnnouncement.groupName,
      order: selectedSiteAnnouncement.order,
      type: selectedSiteAnnouncement.type,
      forceViewMode: selectedSiteAnnouncement.forceViewMode,
      displayTime: selectedSiteAnnouncement.displayTime,
      companySuppliers: selectedSiteAnnouncement.companySuppliers ? [...selectedSiteAnnouncement.companySuppliers] : [],
    } as SiteAnnouncementViewModel, {
    });
    this.switchview = "edit";
    await this.initAddEditSiteAnouncement(this.switchview, selectedSiteAnnouncement.announcementId);
    if ((selectedSiteAnnouncement.users?.length ?? 0) > 0)
      this.isEnableAddUser = true;
    if ((selectedSiteAnnouncement.industryTypes?.length ?? 0) > 0)
      this.isEnableAddIndustryType = true;
    if ((selectedSiteAnnouncement.visitorTypes?.length ?? 0) > 0)
      this.isEnableAddVisitorType = true;
    if ((selectedSiteAnnouncement.companySuppliers?.length ?? 0) > 0)
      this.isEnableAddCompanySupplier = true;
  }

  async getAnnouncementList(selectedAnnouncementId?: number) {
    this.progress = true;
    return new Promise<void>((resolve) => {
      this.announcementService.getAll(SiteActiveStatus.Active)
        .subscribe(res => {
          this.announcements = res;

          let formControl = this.newSiteAnnouncement.formGroup.get('announcementId');
          if (selectedAnnouncementId)
            formControl.setValue(+selectedAnnouncementId);
          else if (formControl.value == null && !this.userService.isCompanyAdminUser() && this.announcements?.length > 0) {
            formControl.setValue(this.announcements[0].id)
          }
          this.progress = false;
          resolve();
        });
    });
  }


  //#region Site Announcement Users
  changeEnableAddUser(value) {
    this.isEnableAddUser = value;
  }
  onSelectUser($event) {
    // if user selected update all inputs
    if ($event.id != null) {
      this.currentCompleteUser = $event;
    }
  }
  onInputChange($event) {
    if (!this.currentCompleteUser)
      this.currentCompleteUser = new PrimitiveUserInformation();
    this.currentCompleteUser[$event.field] = $event.value;
  }
  onPushNewUser() {
    if (this.isMobileValid === true) {
      let list = this.newSiteAnnouncement.formGroup.controls.users.value;
      if (list.find(i => i.mobile === this.currentCompleteUser.mobile) == undefined) {//Don't add duplicate records with same mobile number
        this.newSiteAnnouncement.formGroup.controls.users.value.push(this.currentCompleteUser);
        this.currentCompleteUser = new PrimitiveUserInformation();
      }
    }
  }
  onRemoveUser(user: PrimitiveUserInformation) {
    let list = this.newSiteAnnouncement.formGroup.controls.users.value;
    this.newSiteAnnouncement.formGroup.controls.users.value.splice(list.indexOf(user), 1);
  }
  get isMobileValid(): boolean {
    return new FormControl(this.currentCompleteUser?.mobile, [e168Validator2])?.valid;
  }
  getUserFullName(user: PrimitiveUserInformation): string {
    if (user == null)
      return "";
    if (user.firstName == undefined && user.lastName == undefined)
      return "";
    return "( " +
      (user.firstName == undefined ? "" : user.firstName) +
      " " +
      (user.lastName == undefined ? "" : user.lastName) +
      " )";
  }
  //#endregion

  //#region Site Announcement Industry Types
  changeEnableAddIndustry(value) {
    this.isEnableAddIndustryType = value;
  }

  changeEnableAddVisitor(value) {
    this.isEnableAddVisitorType = value;
  }

  changeEnableAddCompanySupplier(value) {
    this.isEnableAddCompanySupplier = value;
  }

  getIndustryTypeList() {
    this.industryTypeService.getActiveIndustryTypes()
      .subscribe(res =>
        this.industryTypeList = res
      );
  }

  getVisitorTypeList() {
    this.siteService.getVisitorTypesWithSiteVisitorTypeIdsViewModel(this.manageSiteModel?.site?.id)
      .subscribe(res => {
        this.visitorTypeList = res.visitorTypes
      });
  }

  getCompanySupplierList() {
    this.companySupplierService.getCachedSuppliers({ siteId: this.manageSiteModel?.site?.id, term: null } as CompanySupplierQuery, null)
      .subscribe(res => {
        this.companySupplierList = res.data
      });
  }

  onPushNewIndustryType() {
    if (this.selectedIndustryType) {
      let list = this.newSiteAnnouncement.formGroup.controls.industryTypes.value;
      if (list.find(i => i.id === this.selectedIndustryType.id) == undefined) {//Don't add duplicate records with same id
        this.newSiteAnnouncement.formGroup.controls.industryTypes.value.push(this.selectedIndustryType);
        this.selectedIndustryType = null;
      }
    }
  }

  onPushNewVisitorType() {
    if (this.selectedVisitorType) {
      let list = this.newSiteAnnouncement.formGroup.controls.visitorTypes.value;
      if (list.find(i => i.id === this.selectedVisitorType.id) == undefined) {//Don't add duplicate records with same id
        this.newSiteAnnouncement.formGroup.controls.visitorTypes.value.push(this.selectedVisitorType);
        this.selectedVisitorType = null;
      }
    }
  }

  onPushNewCompanySupplier() {
    if (this.selectedCompanySupplier) {
      let list = this.newSiteAnnouncement.formGroup.controls.companySuppliers.value;
      if (list.find(i => i.id === this.selectedCompanySupplier.id) == undefined) {//Don't add duplicate records with same id
        this.newSiteAnnouncement.formGroup.controls.companySuppliers.value.push(this.selectedCompanySupplier);
        this.selectedCompanySupplier = null;
      }
    }
  }

  onRemoveIndustryType(industryTpe: IndustryTypeViewModel) {
    let list = this.newSiteAnnouncement.formGroup.controls.industryTypes.value;
    this.newSiteAnnouncement.formGroup.controls.industryTypes.value.splice(list.indexOf(industryTpe), 1);
  }
  onRemoveVisitorType(visitorType: VisitorType) {
    let list = this.newSiteAnnouncement.formGroup.controls.visitorTypes.value;
    this.newSiteAnnouncement.formGroup.controls.visitorTypes.value.splice(list.indexOf(visitorType), 1);
  }
  onRemoveCompanySupplier(companySupplier: CompanySupplierViewModel) {
    let list = this.newSiteAnnouncement.formGroup.controls.companySuppliers.value;
    this.newSiteAnnouncement.formGroup.controls.companySuppliers.value.splice(list.indexOf(companySupplier), 1);
  }
  //#endregion

  //When Create New Announcement
  async onAnnouncementCreated(model: AnnouncementViewModel) {
    if (model.announcementId) {
      await this.getAnnouncementList(model.announcementId)
      // this.newSiteAnnouncement.formGroup.get("announcementId").setValue(model.id);
      // this.newSiteAnnouncement.formGroup.get("title").setValue(model.title);
      // this.newSiteAnnouncement.formGroup.get("groupName").setValue(model.groupName);
      // this.newSiteAnnouncement.formGroup.get("type").setValue(model.type);
      // this.newSiteAnnouncement.formGroup.get("order").setValue(model.order);
      this.onSave(this.newSiteAnnouncement);
    }
  }

  validateModel(editableModel: EditableItem<SiteAnnouncementViewModel>): boolean {
    if (this.switchview != "edit" && editableModel.currentModel.announcementId &&
      this.siteAnnouncements?.find(f => f.model.announcementId == editableModel.currentModel.announcementId)) {
      this.modalService.warn("Unable to add duplicate site announcement.");
      return false;
    }

    if (editableModel.currentModel.validFrom && editableModel.currentModel.validTo) {
      if (new Date(editableModel.currentModel.validFrom) >= new Date(editableModel.currentModel.validTo)) {
        this.modalService.warn("Selected date range is not correct");
        return false;
      }
    }
    if (editableModel.currentModel.announcementId && editableModel.currentModel.mode == null) {
      this.modalService.warn("Please select announcement mode");
      return false;
    }
    if (!editableModel.currentModel.announcementId && !editableModel.currentModel.siteAnnouncementId) {
      this.modalService.warn("Please select announcement & mode");
      return false;
    }
    if (this.isEnableAddUser == false) {
      editableModel.formGroup.get("users").setValue([]);
    }
    if (this.isEnableAddIndustryType == false) {
      editableModel.formGroup.get("industryTypes").setValue([]);
    }
    if (this.isEnableAddVisitorType == false) {
      editableModel.formGroup.get("visitorTypes").setValue([]);
    }
    if (editableModel.currentModel.displayTime == this.siteAnnouncementDisplayTime.WhileCheckin) {
      editableModel.formGroup.get("mode").setValue(this.announcementMode.Silent);
    }
    if (this.isEnableAddCompanySupplier == false) {
      editableModel.formGroup.get("companySuppliers").setValue([]);
    }
    return true;
  }

  //Both Edit SiteAnnouncement and Add SiteAnnouncement(new annoumcement or old announcement) call this
  onSave(editableModel: EditableItem<SiteAnnouncementViewModel>) {
    if (this.validateModel(editableModel))
      this.saveChanges(editableModel);
  }

  saveChanges(editableModel: EditableItem<SiteAnnouncementViewModel>) {
    let model = editableModel.currentModel;
    if (model.siteAnnouncementId || this.switchview == "edit") // update
    {
      if (model.announcementId) // modify
        this.manageSiteModel.announcements =
          this.manageSiteModel?.announcements?.map(ann => ann.announcementId == model.announcementId ? model : ann);
    }
    else  // add
    {
      let announcement = this.announcements?.find(i => i.id == model.announcementId);
      model.title = announcement.title;
      model.type = announcement.type;
      model.groupName = announcement.groupName;
      model.askMode = announcement.askMode;

      this.manageSiteModel?.announcements?.push(model);
    }

    this.switchview = "list";
    this.loadSiteAnnouncements();
    this.makeComponentDirty();
  }

  removeSiteAnnouncement(announcementId) {
    this.progress = true;

    this.manageSiteModel.announcements =
      this.manageSiteModel?.announcements?.filter(item => item.announcementId != announcementId);

    this.loadSiteAnnouncements();
    this.makeComponentDirty();
    this.progress = false;
  }

  changeSiteAnnouncementOrder(editableObject: EditableItem<SiteAnnouncementViewModel>, moveUp: boolean) {
    this.sortSiteAnnouncements();
    let oldIndex = this.siteAnnouncements?.findIndex(f => f.model.announcementId == editableObject.model.announcementId);
    let newIndex = moveUp ? oldIndex - 1 : oldIndex + 1;

    while (oldIndex < 0) {
      oldIndex += this.siteAnnouncements?.length;
    }
    while (newIndex < 0) {
      newIndex += this.siteAnnouncements?.length;
    }
    if (newIndex >= this.siteAnnouncements?.length) {
      let k = newIndex - this.siteAnnouncements?.length + 1;
      while (k--) {
        // orderedSiteAnnouncements.push(undefined);
      }
    }
    this.siteAnnouncements?.splice(newIndex, 0, this.siteAnnouncements?.splice(oldIndex, 1)[0]);
    this.reOrderSiteAnnouncements();

    this.manageSiteModel.announcements =
      this.siteAnnouncements?.map(ann => ann.model);
    this.makeComponentDirty();
  }
  //#endregion

  newAnnouncementType: FileType = FileType.Pdf;
  playThroughLimitedFileTypes = [FileType.Video, FileType.Audio, FileType.Pdf];
  getWhiteListFileTypes() {
    if (this.newSiteAnnouncement.formGroup.get('forceViewMode').value === SiteAnnouncementForceViewMode.SetPlaythroughToMandatory)
      return this.playThroughLimitedFileTypes;
    return [];
  }
  onAnnouncementTypeChanged(type: FileType) {
    this.newAnnouncementType = type;
  }
}
