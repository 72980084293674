import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Table } from '../enums/table.enum';
import { TableSetting } from '../models/table-setting';
import { StorageService } from './storage.service';
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class TableSettingService {
  Table = Table;
  private tableSettings: {}

  constructor(private storageService: StorageService, private userService: UserService) {
  }

  get(key: string): BehaviorSubject<TableSetting> {
    if (this.tableSettings[key])
      return this.tableSettings[key];
  }

  set(key: string, setting: TableSetting) {
    this.tableSettings[key].next(setting);
    this.setToLocalStorage(key, setting);
  }

  private getTableStorageKey(key: string): string {
    return `c_${this.userService?._currentCompany?.id}_report_${key}`;
  }

  getTableSettingsFromCache(tableKey: string, defaultConfig: TableSetting) : TableSetting {
    let table;
    if(this.userService?._currentCompany) {
      table = this.storageService.get(this.getTableStorageKey(tableKey));
      if(!table) {
        table = this.storageService.get(`table_${tableKey}`);
        this.setToLocalStorage(tableKey, !!table ? JSON.parse(table) : defaultConfig);
      }
    } else {
      table = this.storageService.get(`table_${tableKey}`);
    }
    return !!table ? JSON.parse(table) : defaultConfig;
  }

  setToLocalStorage(key: string, setting: TableSetting) {
    this.storageService.set(this.getTableStorageKey(key), JSON.stringify(setting));
  }
}
