import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { forkJoin } from 'rxjs';
import { AccessControlUnitGateStatus } from 'src/app/enums/access-control-unit-gate-status.enum';
import { AccessControlUnitGateType } from 'src/app/enums/access-control-unit-gate-type.enum';
import { AccessControlUnitOpenCloseEvacuationModel } from 'src/app/models/access-control-unit-open-close-model';
import { AccessControlUnitGateStatusViewModel } from 'src/app/models/access-unit-control-gate-status-view-model';
import { AccessControlUnitGateViewModel, SiteAccessControlUnitViewModel, SiteAccessControlViewModel } from 'src/app/models/site-access-control-unit-view-model';
import { AccessControlUnitService } from 'src/app/services/access-control-unit.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ModalService } from 'src/app/services/modal.service';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'obc-site-access-control-unit-manual',
  templateUrl: './site-access-control-unit-manual.component.html',
  styleUrls: ['./site-access-control-unit-manual.component.scss']
})
export class SiteAccessControlUnitManualComponent implements OnInit {

  inProgress = false;
  @ViewChild('select') select: NgSelectComponent
  constructor(private layoutService: LayoutService, private siteService: SiteService, private modalService: ModalService, private accessControlUnitService: AccessControlUnitService) { }

  siteAccessControlUnits: SiteAccessControlViewModel[]
  selectedSiteAccessControlUnit: SiteAccessControlViewModel

  formControl: FormControl

  AccessControlUnitGateType = AccessControlUnitGateType
  AccessControlUnitGateStatus = AccessControlUnitGateStatus
  ngOnInit(): void {
    this.layoutService.header = 'Access Control Unit'
    this.formControl = new FormControl();

    this.formControl.valueChanges.subscribe(value => {
      this.inProgress = true;
      this.selectedSiteAccessControlUnit = this.siteAccessControlUnits.find(item => item.siteId == value);
      var evacuationGateStatuses = forkJoin(this.selectedSiteAccessControlUnit.accessControlUnits
        .map(item => item.accessControlUnitGates
          .filter(acug => acug.accessControlUnitGateType == AccessControlUnitGateType.Evacuation)
          .map(res => this.accessControlUnitService.getEvacMode({ evacuationIdentifier: res.identifier, siteId: this.selectedSiteAccessControlUnit.siteId, controlUnitIdentifier: item.controlUnitIdentifier }))[0])
        .filter(item => item))
      let subscription = evacuationGateStatuses.subscribe((res: AccessControlUnitGateStatusViewModel[]) => {
        res.forEach(item => {
          var acus = this.selectedSiteAccessControlUnit.accessControlUnits.find(acu => acu.accessControlUnitGates.find(acug => acug.identifier == item.identifier))
          let acug = acus.accessControlUnitGates.find(acug => acug.identifier == item.identifier)
          acug.accessControlUnitGateStatus = item.accessControlUnitGateStatus
        })
      },
        err => {
          this.modalService.error('Can not get Evacuation Status!')
          subscription.unsubscribe();
          this.inProgress = false;
        },
        () => {
          subscription.unsubscribe();
          this.inProgress = false;
        })

    })
    this.getSiteAccessControlUnits();
  }

  getSiteAccessControlUnits() {
    let subscription = this.siteService.getSiteAccessControlUnits().subscribe(res => {
      this.siteAccessControlUnits = res;
      this.select.element.focus();
    }, err => {
      this.modalService.error('Can not get site with access control units!');
      this.inProgress = false;
      subscription.unsubscribe();
    },
      () => {
        this.inProgress = false;
        subscription.unsubscribe();
      })
  }
  openGate(accessControlUnit: SiteAccessControlUnitViewModel, gate: AccessControlUnitGateViewModel) {
    return this.accessControlUnitService.openGate(this.getAccessControlUnitOpenCloseModel(accessControlUnit, gate));
  }
  setEvacMode(accessControlUnit: SiteAccessControlUnitViewModel, gate: AccessControlUnitGateViewModel) {
    return this.accessControlUnitService.setEvacMode(this.getAccessControlUnitOpenCloseModelToToggleEvacMode(accessControlUnit, gate));
  }

  handleGate(accessControlUnit: SiteAccessControlUnitViewModel, gate: AccessControlUnitGateViewModel) {
    let message = gate.accessControlUnitGateType == AccessControlUnitGateType.Entry ? 'open entry gate' :
      gate.accessControlUnitGateType == AccessControlUnitGateType.Exit ? 'open exit gate' :
        (`${gate.accessControlUnitGateStatus == AccessControlUnitGateStatus.Open ? 'disable' : 'enable'} evacuation mode`);
    let subscription = this.modalService.confirm(`Are you sure you want to ${message}?`).subscribe(confirmResult => {
      if (!confirmResult) return;
      this.inProgress = true;
      if (gate.accessControlUnitGateType == AccessControlUnitGateType.Evacuation) {
        this.handleSetEvac(accessControlUnit, gate);
      }
      else {
        this.handleOpenGate(accessControlUnit, gate);
      }
    }, err => { subscription.unsubscribe(); },
      () => { subscription.unsubscribe(); })
  }

  handleSetEvac(accessControlUnit: SiteAccessControlUnitViewModel, gate: AccessControlUnitGateViewModel) {
    let subscription = this.setEvacMode(accessControlUnit, gate).subscribe(res => {
      if (res) {
        this.modalService.success(`Evacuation mode was successfully ${gate.accessControlUnitGateStatus == AccessControlUnitGateStatus.Open ? 'disabled' : 'enabled'}.`);
        if (gate.accessControlUnitGateStatus == AccessControlUnitGateStatus.Close) gate.accessControlUnitGateStatus = AccessControlUnitGateStatus.Open;
        else gate.accessControlUnitGateStatus = AccessControlUnitGateStatus.Close
      }
      else {
        this.modalService.error(`Couldn't ${gate.accessControlUnitGateStatus == AccessControlUnitGateStatus.Open ? 'disable' : 'enable'} evacuation mode!`);
      }
    },
      err => {
        this.modalService.error(`Couldn't ${gate.accessControlUnitGateStatus == AccessControlUnitGateStatus.Open ? 'disable' : 'enable'} evacuation mode!`);
        subscription.unsubscribe();
        this.inProgress = false;
      }, () => {
        subscription.unsubscribe();
        this.inProgress = false;
      })
  }
  handleOpenGate(accessControlUnit: SiteAccessControlUnitViewModel, gate: AccessControlUnitGateViewModel) {
    let subscription = this.openGate(accessControlUnit, gate).subscribe(res => {
      if (res)
        this.modalService.success(`Gate opened successfully`);
      else
        this.modalService.error(`Can not open Gate`);
    },
      err => {
        this.modalService.error(`Can not open Gate`);
        subscription.unsubscribe();
        this.inProgress = false;
      }, () => {
        subscription.unsubscribe();
        this.inProgress = false;
      })
  }
  getAccessControlUnitOpenCloseModel(accessControlUnit: SiteAccessControlUnitViewModel, gate: AccessControlUnitGateViewModel): AccessControlUnitOpenCloseEvacuationModel {
    return {
      siteId: this.selectedSiteAccessControlUnit.siteId,
      gate: gate,
      controlUnitIdentifier: accessControlUnit.controlUnitIdentifier
    }
  }
  getAccessControlUnitOpenCloseModelToToggleEvacMode(accessControlUnit: SiteAccessControlUnitViewModel, gate: AccessControlUnitGateViewModel): AccessControlUnitOpenCloseEvacuationModel {
    return {
      siteId: this.selectedSiteAccessControlUnit.siteId,
      gate: {
        identifier: gate.identifier,
        accessControlUnitGateStatus: gate.accessControlUnitGateStatus == AccessControlUnitGateStatus.Close ? AccessControlUnitGateStatus.Open : AccessControlUnitGateStatus.Close,
        accessControlUnitGateType: gate.accessControlUnitGateType
      } as AccessControlUnitGateViewModel,
      controlUnitIdentifier: accessControlUnit.controlUnitIdentifier
    }
  }
  getGateButtonTitle(gate: AccessControlUnitGateViewModel) {
    let openClose = 'Open'
    let title = ''
    let gateMode = 'Gate'

    switch (gate.accessControlUnitGateType) {
      case AccessControlUnitGateType.Entry:
        title = 'Entry'
        break;
      case AccessControlUnitGateType.Exit:
        title = 'Exit';
        break;
      case AccessControlUnitGateType.Evacuation:
        title = 'Evacuation'
        openClose = gate.accessControlUnitGateStatus == AccessControlUnitGateStatus.Close ? 'Enable' : 'Disable'
        gateMode = 'Mode'
        break;
    }

    return `${openClose} ${title} ${gateMode}`
  }

}
