import { AbstractControl } from '@angular/forms';
import { CheckListItem } from '../custom-controls/check-list-form-control/check-list-item';
import { UserPermission } from '../enums/user-permission.enum';


export function combinePermissions(permissions : UserPermission[]): UserPermission {
    if (permissions == null || permissions.length == 0)
      return UserPermission.None;
    else {
      var permission = UserPermission.None;
      for (var i = 0; i < permissions.length; i++) {
        permission |= permissions[i];
      }
      return permission;
    }
  }
