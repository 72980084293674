import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RequiredDocument } from 'src/app/models/induction-viewmodel';
import { UserDocumentType } from 'src/app/models/user-document-type';

@Component({
  selector: 'app-document-selector',
  templateUrl: './document-selector.component.html',
  styleUrls: []
})
export class DocumentSelectorComponent implements OnInit {
  title = "Select Document(s)";
  filterTerm = null;

  @Input() documents: UserDocumentType[] = [];
  @Input() addedDocuments: RequiredDocument[] = [];
  @Output() addDocument = new EventEmitter<RequiredDocument>();
  @Output() public hide = new EventEmitter();

  get availableDocuments(): UserDocumentType[] {
    return this.documents.filter((x) =>
      this.addedDocuments.some(ad => ad.documentTypeId == x.id) == false
    );
  };
  constructor() { }

  ngOnInit() {
  }

  onAddDocument(doc: UserDocumentType) {
    var question = this.availableDocuments.find(x => x.id == doc.id);
    if (question && this.addDocument) {
      this.addDocument.emit({ documentTypeId: doc.id, state: doc.state } as RequiredDocument);
    }
  }

}
