import { Component, Input, OnInit } from '@angular/core';
import { SiteSelectorComponent } from '../site-selector.component';
import { SiteService } from 'src/app/services/site.service';
import { Observable } from 'rxjs';
import { SiteViewModel } from 'src/app/models/site-viewmodel';

@Component({
  selector: 'obc-specific-company-site-selector',
  templateUrl: '../site-selector.component.html',
  styleUrls: ['../site-selector.component.scss']
})
export class SpecificCompanySiteSelectorComponent extends SiteSelectorComponent implements OnInit {
  _companyId: number;
  @Input() set companyId(value: number) {
    this._companyId = value;
    super.loadSiteList();
  }

  constructor(private _siteService: SiteService) { super(_siteService); }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getSiteList(): Observable<SiteViewModel[]> {
    return this._siteService.getAllCompanySites(this._companyId);
  }

}
