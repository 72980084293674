<div>
  <ng-select style="padding-bottom: 0 !important;" class="region-selector" [typeaheadOptionsLimit]="7"
             [typeaheadMinLength]="0" [items]="regions$ | async"
             [placeholder]="formControl.value ? formControl.value : 'All Regions'" bindLabel="regionName"
             (scrollToEnd)="onScrollToEnd()"
             bindValue="regionId" [typeahead]="term$ | async" [loading]="loading" [clearable]="true" [formControl]="formControl">
    <ng-template ng-option-tmp let-item="item">
      {{item.regionName}}
    </ng-template>
  </ng-select>
</div>
