<accordion class="px-3 py-2" *ngIf="displayAsModal == false && description">
  <accordion-group [isOpen]="false">
    <div accordion-heading>
      <ng-template [ngTemplateOutlet]="headerTemplate"></ng-template>
    </div>
    <ng-template [ngTemplateOutlet]="descriptionTemplate"></ng-template>
  </accordion-group>
</accordion>
<ng-container *ngIf="displayAsModal">
  <button *ngIf="description" class="{{ classes }}" (click)="onDisplayDescription(descriptionTemplateModal)">
    <i *ngIf="icon" class="{{ icon }} mr-1"></i>
    {{ buttonTitle }}
  </button>
</ng-container>

<ng-template #descriptionTemplateModal>
  <div class="card">
    <div class="card-header">
      <ng-template [ngTemplateOutlet]="headerTemplate"></ng-template>
    </div>
    <div class="card-body">
      <ng-template [ngTemplateOutlet]="descriptionTemplate"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #headerTemplate>
  <div class="d-flex justify-content-between align-items-center">
    {{ documentTitle }}
  </div>
</ng-template>

<ng-template #descriptionTemplate>
  <div *ngFor="let part of getDescriptionSplittedByNewLine()">{{part}} <br></div>
</ng-template>