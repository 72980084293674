<div class="card">
  <div class="card-body">

    <obc-loader [show]="inProgress"></obc-loader>
    <div class="mb-2" [formGroup]="formGroup">
      <div class="card">
        <div class="card-header">Add Inventory Location</div>
        <div class="card-body">
          <app-site-selector [required]="false" [notSelectedSiteLabel]="'Company Wide Location'"
            (siteIdSelect)="formGroup.controls.siteId.setValue($event)"></app-site-selector>
          <div class="form-group mb-3">
            <label>Name</label>
            <div class="input-group">
              <input class="form-control form-control" formControlName="name"
                (keydown.enter)="formGroup.valid ? onAdd() : null" />
            </div>
          </div>
          <div class="text-right">
            <button [disabled]="!formGroup.valid" class="btn btn-primary" (click)="onAdd()">Add</button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="fw table table-bordered ">
        <thead>
          <tr>
            <th>Name</th>
            <th>Site</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of invertoryLocations">
            <td>
              <div class="d-flex justify-content-between align-items-center">
                <span>
                  {{item.name}}
                  <i *ngIf="item.isDefaultLocation" class="fa fa-home ml-2" title="Default Location"></i>
                </span>
                <button class="btn btn-sm btn-info ml-2" (click)="onClickUpdateItem(updateItemTemplate, item)"><i
                    class="fa fa-pen"></i></button>
              </div>
            </td>
            <td>{{item.siteFullName ? item.siteFullName : "Company Wide Location"}}</td>
            <td class="text-right">
              <button *ngIf="item.isDefaultLocation != true"
                class="btn ml-2 {{ item.isActive ? 'btn-warning' : 'btn-success' }} " (click)="toggleStatus(item)">
                {{ item.isActive ? 'Deactivate' : 'Activate' }}
              </button>
              <button *ngIf="item.isDefaultLocation != true && item.isActive" class="btn btn-info ml-2"
                (click)="onSetAsDefaultLocation(item.id)">Set As Default</button>
              <button *ngIf="item.isDefaultLocation != true" class="btn btn-danger ml-2"
                (click)="onRemove(item.id)">Remove</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>



<ng-template #updateItemTemplate>
  <div class="card">
    <div class="card-header">
      Update Name
    </div>
    <div class="card-body">
      <input type="text" class="form-control"
        (keydown.enter)="selectedInventoryLocationNameFormControl.value.length > 0 ? updateName() : null"
        required="required" [formControl]="selectedInventoryLocationNameFormControl">
      <div class="d-flex">
        <button type="button" class="mt-2 btn btn-success ml-auto"
          [disabled]="!selectedInventoryLocationNameFormControl.value.length" (click)="updateName()">
          <i class="fa fa-pen"></i>
          Update
        </button>
      </div>

    </div>
  </div>
</ng-template>