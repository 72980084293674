import { Pipe, PipeTransform } from '@angular/core';
import { ProcoreExportCheckInAsTimeCardMode } from '../enums/procore-export-modes.enum';

@Pipe({
  name: 'procoreExportSiteAttendanceAsTimeCardModePipe'
})
export class ProcoreExportSiteAttendanceAsTimeCardModePipePipe implements PipeTransform {


  transform(value: any): unknown {
    if (value === ProcoreExportCheckInAsTimeCardMode.Disabled) return 'Disabled';
    if (value === ProcoreExportCheckInAsTimeCardMode.EmployeesOnly) return 'Employees Only';
    return value;
  }

}
