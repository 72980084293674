<div class="form-group row">
    <label class="col-sm-4 control-label text-left pt-2">Is Required ?</label>
    <div class="col-sm-8 pull-left">
        <obc-descriptor
            [text]="'Do you require an answer to this question for an individual to check in successfully?'">
        </obc-descriptor>
        <obc-switch [value]="questionParam['isRequired']" (valueChanged)="questionParam['isRequired'] = $event">
        </obc-switch>
    </div>
</div>
<div class="form-group row">
    <label class="col-sm-4 control-label text-left pt-2">Global Asset Field :</label>
    <div class="col-sm-8 pull-left">
        <obc-item-selector [itemType]="GlobalItemType.GlobalAssetField" [options]="{usage:ToolDynamicFieldUsage.Tool}"
            [itemId]="selectedGlobalToolDynamicFieldId" [required]="true" (onItemSelect)="onSelectGlobalField($event)">
        </obc-item-selector>
    </div>
</div>