<div class="form-group row">
  <label class="col-sm-4 control-label text-left pt-">Is Required ?</label>
  <div class="col-sm-8 pull-left">
    <obc-descriptor
      [text]="'Do you require an answer to this question for an individual to check in successfully?'">
    </obc-descriptor>
    <obc-switch [value]="_questionParam['isRequired']" (valueChanged)="_questionParam['isRequired'] = $event">
    </obc-switch>
  </div>
</div>
<div class="form-group row">
  <label class="col-sm-4 control-label text-left pt-2">Sub Type:</label>
  <div class="col-sm-8">
    <select required class="form-control"
            [(ngModel)]="questionProfileSubType" name="questionType"
            (change)="valueChanged()"
            #drp="ngModel">
      <option
        *ngFor="let subType of _questionProfileSubTypes" [ngValue]="subType">
        {{subType.title}}
      </option>
    </select>
    <div *ngIf="drp.invalid && (drp.dirty || drp.touched)" class="help-block with-errors">
      Please select a question profile info sub type
    </div>
  </div>
</div>

