export class DirtyComponentItem {
  snapshot: any;
  isDirty: boolean;
}

export class DirtyComponents {
  inductions: DirtyComponentItem = new DirtyComponentItem();
  genericForms: DirtyComponentItem = new DirtyComponentItem();
  permitForms: DirtyComponentItem = new DirtyComponentItem();
  assetRegistrationForms: DirtyComponentItem = new DirtyComponentItem();
  siteBriefingForms: DirtyComponentItem = new DirtyComponentItem();
  announcements: DirtyComponentItem = new DirtyComponentItem();
  scannableGroups: DirtyComponentItem = new DirtyComponentItem();
  checkInQuestions: DirtyComponentItem = new DirtyComponentItem();
  checkOutQuestions: DirtyComponentItem = new DirtyComponentItem();
  requiredFields: DirtyComponentItem = new DirtyComponentItem();
  documentInfo: DirtyComponentItem = new DirtyComponentItem();
  visitorTypes: DirtyComponentItem = new DirtyComponentItem();
  siteVisitorTypes: DirtyComponentItem = new DirtyComponentItem();
  visitorTypeAccompaniers: DirtyComponentItem = new DirtyComponentItem();
}
