import { ImportRecordState } from '../enums/import-record-status.enum';
import { UserPermission } from '../enums/user-permission.enum';
import { ValidationResultModel } from './validation-result-model';
import {RegionViewModel} from "./region/region-model";
import { CompanyRoleViewModel } from "./company-role.view.model";

// export const CompanyUserPermissions = {
//   0: "Check In/Check Out",
//   1: "View Attendance Details",
//   2: "Manage Hosts",
//   3: "Adjust Site Location",
//   4: "Add New Site By Template",
//   5: "Add Site Announcement",
//   6: "Send Emergency Alarm"
// }



export class BaseSiteCompanyViewModel {
  mobile: string;
  firstName: string;
  lastName: string;
  isFrequentUser: boolean;
}

export class CompanyEmployeeViewModel extends BaseSiteCompanyViewModel {
  id: number;
  userId: number;
  companyId: number;
  inEdit: boolean;
  employeeNo: string;
  regionId: number;
  regionName: string;
}

export class CompanyUserViewModel extends BaseSiteCompanyViewModel {
  id: number;
  userId: number;
  companyId: number;
  inEdit: boolean;
  isAdmin: boolean;
  permissions: UserPermission;
  addToFrequentUser: boolean;
  isObcStaff: boolean;
  companyRoles: CompanyRoleViewModel[];
}

export class SiteUserViewModel extends BaseSiteCompanyViewModel {
  id: number;
  isAdmin: boolean;
  permissions: UserPermission;
  siteId: number;
  companyRoles: CompanyRoleViewModel[];
}

export class SiteUserUpdateRequest {
  isAdmin: boolean;
  permissions: UserPermission;
}

export class CompanyUserUpdateRequest {
  isAdmin: boolean;
  userId: number;
  permissions: UserPermission;
  roles: number[]
}

export class ImportCompanyUserModel extends CompanyUserViewModel {
  validationStatus: ImportRecordState;
  errorMessages: ValidationResultModel[];
  isUpdateRequest: boolean;
}

export class ImportCompanyUserResponse {
  valid: boolean;
  message: string;
  users: ImportCompanyUserModel[];
}
