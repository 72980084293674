import { Pipe, PipeTransform } from '@angular/core';
import { IntegrationOperation, ProcoreIntegrationType } from '../enums/procore-integration-type.enum';

@Pipe({
  name: 'integrationOperation'
})
export class IntegrationOperationPipe implements PipeTransform {

  transform(value: IntegrationOperation): string {
    if (value == IntegrationOperation.ImportSites) return 'Import Site';
    if (value == IntegrationOperation.ImportProjectUsers) return 'Import User';
    if (value == IntegrationOperation.ExportSiteAttendances) return 'Export Site Attendance';
    if (value == IntegrationOperation.ExportSiteSupplierDocuments) return 'Upload Site Supplier Documents';
    if (value == IntegrationOperation.ExportSiteInductions) return 'Upload Site Inductions';
    if (value == IntegrationOperation.ImportCompanyDirectory) return 'Import Company Directory';
    if (value == IntegrationOperation.ImportProjectDirectory) return 'Import Project Directory';
    if (value == IntegrationOperation.ExportPermitForms) return 'Upload Permit Forms';
    if (value == IntegrationOperation.ExportSiteAssetForms) return 'Upload Site Asset Forms';
    if (value == IntegrationOperation.ExportSiteBriefings) return 'Upload Site Briefings';
    if (value == IntegrationOperation.ImportCompanyRoles) return 'Import Company Roles';
    if (value == IntegrationOperation.ImportProjectUserRoles) return 'Import Project User Roles';



    if (value == IntegrationOperation.ExportTimeCards) return 'Export Time Cards';
    if (value == IntegrationOperation.ExportAggregatedManpower) return 'Export Aggregated Manpower';
    if (value == IntegrationOperation.ExportVisitorLogs) return 'Export Visitor Logs';
    if (value == IntegrationOperation.ExportManpowerLogs) return 'Export Manpower Logs';
    if (value == IntegrationOperation.ImportAttendances) return 'Import Attendances';

  }
}
