import { Pipe, PipeTransform } from '@angular/core';
import { ProductPlanSubscriptionType } from '../enums/product-plan-subscription-type.enum';

@Pipe({
  name: 'subscriptionTypePipe'
})
export class SubscriptionTypePipePipe implements PipeTransform {

  transform(value: any): string {
    if (value == ProductPlanSubscriptionType.Basic) return 'Basic';
    if (value == ProductPlanSubscriptionType.Enterprise) return 'Enterprise';
    return value?.toString();
  }

}
