import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { SupplierFormService } from "../../../services/supplier-form.service";
import { SupplierFormViewModel, SupplierViewModel } from "../../../models/supplier-form/supplier-forms-view-mode";
import { ModalService } from "../../../services/modal.service";
import { FormDataStatus } from 'src/app/enums/form-data-status';
import { FormType } from "../../../enums/form-type";
import { FormDataRendererComponent } from "../../form-manage/form-data-renderer/form-data-renderer.component";
import { FullFilledFormInfoViewModel } from "../../../models/filled-form-info-view-model";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: 'obc-supplier-anonymous-forms',
  templateUrl: './supplier-anonymous-forms.component.html',
  styleUrls: ['./supplier-anonymous-forms.component.scss']
})
export class SupplierAnonymousFormsComponent implements OnInit {

  hashLink: string;
  suppliers: SupplierViewModel[];
  supplier: SupplierViewModel;
  FormDataStatus = FormDataStatus;
  selectedForm: SupplierFormViewModel;
  FormType = FormType;
  inProgress: boolean;
  selectedFormDataId: number;
  selectedFormIdToViewHistory: number;
  bsModalRefRenameDOT: any = null;

  @ViewChild("formDataRenderer") public formDataRenderer: FormDataRendererComponent;

  constructor(private route: ActivatedRoute,
    private bsModalService: BsModalService,
    private supplierFormService: SupplierFormService,
    private modalService: ModalService
    ) {}

  ngOnInit() {
    this.route.params.subscribe(res => {
      this.hashLink = res["hashlink"];
      
      this.getForms();
    })
  }

  getForms() {

    if (this.hashLink?.length)
      this.supplierFormService.getAnonymousForms(this.hashLink)
        .subscribe(res => {
          this.suppliers = [res];
          console.log(res);
        }, err => {
          this.modalService.error(err);
        });
    else
      this.supplierFormService.getSupplierForms()
        .subscribe(res => {
          this.suppliers = res;
          console.log(res);
        }, err => {
          this.modalService.error(err);
        });
  }

  get isSupplierUser() {
    return this.hashLink?.length > 0;
  }

  onFillForm(form, template: any, supplier: SupplierViewModel) {
    this.supplier = supplier;
    this.selectedForm = form;
    this.modalService.show(template)
  }

  onViewForm(template, formData: FullFilledFormInfoViewModel) {
    this.selectedFormDataId = formData.formDataId;
    this.modalService.show(template, "modal-lg");
  }

  displayHistory(form: SupplierFormViewModel, template: any, supplier: SupplierViewModel) {
    this.supplier = supplier;
    this.selectedFormIdToViewHistory = form.formId;
    this.bsModalRefRenameDOT = this.bsModalService.show(template, {
      class: "modal-lg",
      ignoreBackdropClick: false,
    })
  }
}
