import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { MultiSelectRenderMode } from '../../../models/questionParameters/multi-select-render-type';
import { ControlContainer, NgForm } from '@angular/forms';
import { QuestionListItem, QuestionListItemOutputModel } from 'src/app/models/questionParameters/question-list-tem';
import { isNumber } from "../../../helpers/general-functions";

export const ItemsListKey = 'itemList';
@Component({
  selector: 'obc-question-multi-select',
  templateUrl: './question-multi-select.component.html',
  styleUrls: ['./question-multi-select.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class QuestionMultiSelectComponent implements OnInit {
  multiSelectRenderMode = MultiSelectRenderMode;
  drpIsValid = false;
  _questionParam: any;
  @Input() set questionParamInput(_questionParamInput: any) {
    if (!_questionParamInput) { return; }
    if (_questionParamInput) {
      this._questionParam = _questionParamInput;
      this._questionParam['renderMode'] ??= MultiSelectRenderMode.Button;
      this._questionParam['minimumSelectCount'] ??= 0;
      this.checkIfDrpIsValid();
      if (this._questionParam) {
        delete this._questionParam['regex'];
        this.fillItemsArrsyByQuestionParametersAsString();
      }
    }
  }
  @Output('onItemsUpdated') onItemsUpdatedOutput = new EventEmitter<boolean>();


  constructor() { }

  _inputItems: QuestionListItem[] = [];

  ngOnInit(): void {

  }

  onItemsUpdated(itemsModel: QuestionListItemOutputModel) {
    this.onItemsUpdatedOutput.emit(itemsModel.isValidList);
    this._inputItems = itemsModel.items;
    this._questionParam[ItemsListKey] = itemsModel.items;
  }

  fillItemsArrsyByQuestionParametersAsString() {
    if (this._questionParam[ItemsListKey]) {
      let items = this._questionParam[ItemsListKey];
      this._inputItems.push(...items ?? []);
    }
  }
  checkIfDrpIsValid() {
    this.drpIsValid = this.multiSelectRenderMode[this._questionParam['renderMode']] != undefined ? true : false;
    if (!this.drpIsValid)
      delete this._questionParam['renderMode']
  }

  validateMinimumSelectCount() {
    let minCount = this._questionParam['minimumSelectCount'];
    if(!minCount || !isNumber(minCount)) {
      this._questionParam['minimumSelectCount'] = 0;
    }
  }

  selectValue(input: HTMLInputElement) {
    input.select();
  }
}


