import { Pipe, PipeTransform } from '@angular/core';
import { AnnouncementDisplayModeEnum } from '../enums/announcement-display-mode.enum';

@Pipe({
  name: 'announcementDisplayMode'
})
export class AnnouncementDisplayModePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value == AnnouncementDisplayModeEnum.Button) return 'Button';
    if (value == AnnouncementDisplayModeEnum.Preview) return 'Preview';

    return 'Button';
  }

}
