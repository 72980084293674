import { Component, OnInit, ViewChild } from '@angular/core';
import { UserAutoCompleteFilterByFields } from 'src/app/enums/user-auto-complete-target-fields';
import { LayoutService } from 'src/app/services/layout.service';
import { ActivatedRoute, Params } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProductPlanFeature } from 'src/app/enums/product-plan-feature.enum';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { Table } from 'src/app/enums/table.enum';
import { TableSetting } from 'src/app/models/table-setting';
import { TableSettingService } from 'src/app/services/table-setting.service';
import { ToolFullInfoViewModel } from 'src/app/models/inventory/tool-full-info-view-model';
import { InventoryService } from 'src/app/services/inventory.service';
import { ModalService } from 'src/app/services/modal.service';
import { AsyncResult } from 'src/app/models/helper-models';
import { InventoryDynamicFieldValues } from 'src/app/models/inventory/inventory-dynamic-field-values';
import { ToolDynamicFieldType } from 'src/app/enums/tool-dynamic-field-type.enum';
import { UserService } from 'src/app/services/user.service';
import { ToolCategoryViewModel } from 'src/app/models/inventory/tool-category-model';
import { ToolDynamicFieldViewModel } from 'src/app/models/inventory/tool-dynamic-field-model';
import { TextOperator } from 'src/app/enums/text-operator.enum';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { GridColumn, GridConfig, GridTemplate, InitialFilter } from "../../../../models/grid/grid.config";
import { AttendanceDocumentReportViewModel } from "../../../../models/attendance-document-report-viewmodel";

@Component({
  selector: 'obc-tool-report',
  templateUrl: './inventory-tool-report.component.html',
  styleUrls: ['./inventory-tool-report.component.scss'],
})
export class InventoryToolReportComponent implements OnInit {
  datetimePickerMode = DatetimePickerMode;

  ProductPlanFeature = ProductPlanFeature;
  Table = Table;
  inProgress: boolean = false;

  report: ToolFullInfoViewModel[] = null;

  filterUserBy = UserAutoCompleteFilterByFields;
  filterListTerm: string;

  columnCount: number = 11;

  selectedItemToViewHistory: ToolFullInfoViewModel;
  toolCategories: ToolCategoryViewModel[] = [];
  selectedCategory: ToolCategoryViewModel;
  selectedDynamicField: ToolDynamicFieldViewModel;
  selectedAssignDynamicField: ToolDynamicFieldViewModel;
  TextOperator = TextOperator;
  filterModel: any = [];

  initialFilters: InitialFilter[] = [];

  tableSetting: TableSetting = null;

  constructor(
    private layoutService: LayoutService,
    private tableSettingService: TableSettingService,
    private route: ActivatedRoute,
    private inventoryService: InventoryService,
    private bsModalService: BsModalService,
    private modalService: ModalService,
    public userService: UserService,
  ) {
  }

  ngOnInit() {

    this.route.params.subscribe((_: Params) => {
      this.layoutService.header = 'Asset Report';
    });

    this.prepareGridConfig();
  }


  @ViewChild('grid') grid: any;
  gridColumns: GridColumn<AttendanceDocumentReportViewModel>[] = [];
  gridConfig: GridConfig = {} as GridConfig;


  prepareGridConfig() {
    this.gridConfig = new GridConfig({
      apiUrl: `/api/inventory/${this.userService.currentCompanyId}/tool-report`,
      tableType: Table.AssetReport,
      displayGeneralSearch: true,
      initialFilters: this.initialFilters,
      displayDynamicAssignFieldsFilter: true,
      displayDynamicFieldsFilter: true,
      generalSearchPlaceholder: "Filter result by Name and Searchable Fields",
      csvReportUrl: `/api/inventory/${this.userService.currentCompanyId}/export-tool-report-csv-as-job`,
      apiResultCallback: (res) => {
        res.data.data.forEach((item) => {
          if (item.dynamicFieldValues) {
            let allDynamicFieldValues = [...item.assignDynamicFieldValues, ...item.dynamicFieldValues];
            allDynamicFieldValues.forEach((innerItem)=> {
              if (innerItem.type === ToolDynamicFieldType.File && innerItem.images.length > 0) {
                innerItem.imagesURLs = [];
                innerItem.images.forEach((image) => {
                  innerItem.imagesURLs.push(image.url);
                });
              }
            });
          }
        });
        return {
          data: res?.data?.data,
          totalCount: res?.data?.totalCount,
        }
      }
    });
    this.gridColumns = [
      {
        name: "Main Image",
        key: "mainImageUrl",
        type: FilterType.Image,
        enableSort: false,
        enableFilter: false,
        visible: false,
      },
      {
        name: "Asset Category",
        key: "categoryTitle",
        sortBy: "category",
        type: FilterType.InventoryCategory,
        propertyNameInQuery: 'categoryIds',
      },
      {
        name: "Asset Name",
        key: "name",
        type: FilterType.SingleText,
        propertyNameInQuery: 'name',
        isArray: false,
      },
      {
        name: "Region",
        key: "regionName",
        sortBy: "region",
        gridTemplate: new GridTemplate().ComponentTemplateName('regionName'),
        type: FilterType.SingleText,
        propertyNameInQuery: 'regionName',
        isArray: false,
      },
      {
        name: "Assigned Date",
        type: FilterType.DateTimeRange,
        key: "assignDate",
        propertyNameInQuery: 'assignDateRange',
        isArray: false,
      },
      {
        name: "Current User",
        key: "currentAssigneduser",
        type: FilterType.User,
        gridTemplate: new GridTemplate().ComponentTemplateName('currentAssignedUser')
          .CsvFields([
            'currentAssignedUserMobile',
            'currentAssignedUser',
          ]),
        propertyNameInQuery: 'currentAssignedUserIds',
        hideEmptyColumnsEmptinessConditionCallback: (res) => {
          return res?.every(item => {
            return item.currentAssignedUserMobile == undefined || item.currentAssignedUserMobile?.toString()?.trim() == '';
          });
        }
      },
      {
        name: "Current Location",
        key: "currentAssignedLocation",
        type: FilterType.InventoryLocation,
        gridTemplate: new GridTemplate().ComponentTemplateName('currentAssignedLocation')
          .CsvFields([
            'currentAssignedLocation',
            'currentAssignedLocationSite',
          ]),
        propertyNameInQuery: 'currentAssignedLocationIds',
        hideEmptyColumnsEmptinessConditionCallback: (res) => {
          return res?.every(item => {
            return item.currentAssignedLocation == undefined || item.currentAssignedLocation?.toString()?.trim() == '';
          });
        }
      },
      {
        name: "Status",
        key: "currentStatusTitle",
        sortBy: "currentStatus",
        type: FilterType.InventoryToolStatus,
        propertyNameInQuery: 'currentStatusIds',
      },
      {
        name: "Fields",
        key: 'dynamicFieldValues',
        enableSort: false,
        enableFilter: false,
        gridTemplate: new GridTemplate().ComponentTemplateName('includeDynamicFieldsTemplate'),
        propertyNameInQuery: "includeDynamicFields",
        hasIncludeFilter: true,
        isArray: false,
        visible: false,
        hideEmptyColumnsEmptinessConditionCallback: (res) => {
          return res?.every(item => {
            return item.dynamicFieldValues == undefined || item.dynamicFieldValues?.length == 0;
          });
        }
      },
      {
        name: "Current Assignment Fields",
        key: 'assignDynamicFieldValues',
        enableSort: false,
        enableFilter: false,
        gridTemplate: new GridTemplate().ComponentTemplateName('includeAssignmentDynamicFieldsTemplate'),
        propertyNameInQuery: "includeAssignmentDynamicFields",
        hasIncludeFilter: true,
        isArray: false,
        visible: false,
        hideEmptyColumnsEmptinessConditionCallback: (res) => {
          return res?.every(item => {
            return item.assignDynamicFieldValues == undefined || item.assignDynamicFieldValues?.length == 0;
          });
        }
      },
      {
        name: "Details",
        key: 'viewDetails',
        type: FilterType.Template,
        enableSort: false,
        enableFilter: false,
        gridTemplate: new GridTemplate().ComponentTemplateName('viewDetails'),
        visible: true,
      }
    ];
  }


  upsertAssetModalRef: any = null;
  currentAsset: ToolFullInfoViewModel = null;

  openUpsertAssetModal(template: any, asset?: ToolFullInfoViewModel) {
    this.currentAsset = asset;
    this.upsertAssetModalRef = this.bsModalService.show(template, {
      class: 'modal-lg',
      backdrop: 'static',
    });
  }

  hideUpsertAssetModal() {
    this.upsertAssetModalRef.hide();
    this.grid.loadData(0);
  }

  openModal(template, item: ToolFullInfoViewModel) {
    this.selectedItemToViewHistory = item;
    this.modalService.show(template, 'modal-lg');
  }

  closeModal(_: AsyncResult) {
    this.modalService.hide();
  }

  onRemoveAsset(assetId: number) {
    this.modalService.confirm("Are you sure you want to delete selected asset?").subscribe(del => {
      if (del === true) {
        this.inProgress = true;
        this.inventoryService.removeAsset(assetId)
          .pipe(finalize(() => {
            this.inProgress = false;
          }))
          .subscribe({
            next: res => {
              if (res.success) {
                this.modalService.info("The asset removed successfully");
                this.grid.loadData(0);
              } else
                this.modalService.error(res.message);
            },
            error: _ => {
              this.modalService.error("Request Failed!")
            },
            complete: () => {
              this.inProgress = false;
            }
          });
      }
    });
  }

  showImages(field: InventoryDynamicFieldValues) {
    return (
      field.value &&
      (field.value?.length ?? 0) > 0 &&
      field.type == ToolDynamicFieldType.File
    );
  }

  showText(field: InventoryDynamicFieldValues) {
    return (
      field.value &&
      (field.value?.length ?? 0) > 0 &&
      (field.type == ToolDynamicFieldType.Number ||
        field.type == ToolDynamicFieldType.Text)
    );
  }

  showDate(field: InventoryDynamicFieldValues) {
    return field.value && (field.value?.length ?? 0) > 0 && field.type == ToolDynamicFieldType.Date;
  }

  isShowTemplateColumns(mainColumn, detailColumn) {
    return this.grid.isShowTemplateColumns(mainColumn, detailColumn);
  }
}
