import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { FormModel } from '../models/form/form-model';
import { FormViewModel, FullFormViewModel } from '../models/form/form-view-model';
import { ServerResult, ServerResultData } from '../models/server-result-data';
import { ErrorService } from './error.service';
import { PagingViewModel } from "../models/site-list-paging-view-model";
import { FormReportViewModel, FormReportQueryModel } from '../models/form/form-report-models';
import { convertObjectToUrlParameters } from "../helpers/extract-params-from-model";
import { FilledFormInfoViewModel } from "../models/filled-form-info-view-model";
import { FormDataViewModel } from '../models/form/form-data-view-model';
import { FormDataModel } from '../models/form/form-data-model';
import { FormDataDetailViewModel, FormDataLogViewModel } from '../models/form/form-data-detail-view-model';
import { FormDataPdfQueryModel } from '../models/form/form-data-pdf-query-model';
import { GetExternalCompanyFormsQueryModel, GetFormsQueryModel } from '../models/form/get-forms-query-model';
import { CloneFormQueryModel } from '../models/induction-viewmodel';
import { FilledFormRequestModel } from "../models/form/filled-form-request-model";
import { Subject } from 'rxjs';
import { FormType } from '../enums/form-type';
import { UserService } from './user.service';
import { ProductPlanFeature } from '../enums/product-plan-feature.enum';
import { EnumToArrayPipe } from '../pipes/enum-to-array.pipe';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  apiUrl: string = "/api/form";
  constructor(private http: HttpClient, private _errorService: ErrorService, private userService: UserService, private enumToArrayPipe: EnumToArrayPipe) { }
  isFormDirty: Subject<boolean> = new Subject();

  getActiveFormTypes(): FormType[] {
    var isSbActive = this.userService.hasCompanyFeature(ProductPlanFeature.SiteBriefing);
    return this.enumToArrayPipe.transform(FormType).filter(type => isSbActive || FormType[type] != FormType.SiteBriefing).map(type => FormType[type]);
  }

  getAllForms(): Observable<ServerResultData<FullFormViewModel[]>> {
    return this.http.get<ServerResultData<FullFormViewModel[]>>(`${this.apiUrl}/get-all-forms`)
      .pipe(catchError(this._errorService.handleError));
  }
  changeItemsOrder(id: number, questions: number[]): Observable<ServerResult> {
    return this.http.post<ServerResult>(`${this.apiUrl}/change-order/${id}`, questions)
      .pipe(catchError(this._errorService.handleError));
  }

  previewForm(form: FormModel): Observable<ServerResultData<FormDataViewModel>> {
    return this.http.post<ServerResult>(`${this.apiUrl}/get-form-preview`, form)
      .pipe(catchError(this._errorService.handleError));
  }

  addForm(form: FormModel): Observable<ServerResult> {
    return this.http.post<ServerResult>(`${this.apiUrl}/add-form`, form)
      .pipe(catchError(this._errorService.handleError));
  }
  editForm(formId: number, form: FormModel): Observable<ServerResult> {
    return this.http.post<ServerResult>(`${this.apiUrl}/edit-form/${formId}`, form)
      .pipe(catchError(this._errorService.handleError));
  }
  removeForm(formId: number): Observable<ServerResult> {
    return this.http.get<ServerResult>(`${this.apiUrl}/remove-form/${formId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  getForms(model: GetFormsQueryModel): Observable<ServerResultData<FormViewModel[]>> {
    return this.http.post<FormViewModel[]>(`${this.apiUrl}/get-forms`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  getExternalCompanyForms(model: GetExternalCompanyFormsQueryModel): Observable<ServerResultData<FormViewModel[]>> {
    return this.http.post<FormViewModel[]>(`${this.apiUrl}/get-external-company-forms`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  getFormGroups(groupName: string): Observable<string[]> {
    return this.http.get(`${this.apiUrl}/form-groups/${groupName}`)
      .pipe(catchError(this._errorService.handleError));
  }

  formReport(query: FormReportQueryModel): Observable<PagingViewModel<FormReportViewModel>> {
    return this.http.post<PagingViewModel<FormReportViewModel>[]>(`${this.apiUrl}/form-report`, query)
      .pipe(catchError(this._errorService.handleError));
  }

  saveForm(formData: FormDataModel): Observable<ServerResult> {
    return this.http.post<ServerResult>(`${this.apiUrl}/save-form-response`, formData)
      .pipe(catchError(this._errorService.handleError));
  }

  exportFormReportAsCsv(model: FormReportQueryModel) {
    const params = convertObjectToUrlParameters(model);
    return window.open(this.apiUrl + '/export-form-report-csv/?' + params);
  }

  getFilledForm(formDataId: number): Observable<FilledFormInfoViewModel> {
    return this.http.get<FilledFormInfoViewModel>(`${this.apiUrl}/get-filled-form/${formDataId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  getFillFormViewModel(formId: number, siteId: number, toolId: number = null): Observable<ServerResultData<FormDataViewModel>> {
    return this.http.post<ServerResultData<FormDataViewModel>>(`${this.apiUrl}/fill-form-view-model`, { formId: formId, siteId: siteId, toolId: toolId })
      .pipe(catchError(this._errorService.handleError));
  }

  getFilledFormViewModel(filledFormRequestModel: FilledFormRequestModel): Observable<ServerResultData<FormDataViewModel>> {
    return this.http.post<ServerResultData<FormDataViewModel>>(`${this.apiUrl}/get-filled-form-view-model`, filledFormRequestModel)
      .pipe(catchError(this._errorService.handleError));
  }

  getFormDataDetails(formDataId: number): Observable<ServerResultData<FormDataDetailViewModel>> {
    return this.http.get<ServerResultData<FormDataDetailViewModel>>(`${this.apiUrl}/get-form-data-details/${formDataId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  ExportFormDataAsPdf(model: FormDataPdfQueryModel) {
    const params = convertObjectToUrlParameters(model);
    return window.open(this.apiUrl + '/export-form-data-pdf/?' + params);
  }

  cloneForm(data: CloneFormQueryModel): Observable<boolean> {
    return this.http.post<ServerResult>(`${this.apiUrl}/clone-form`, data)
      .pipe(catchError(this._errorService.handleError));
  }

  getFormHistory(formDataId: any) {
    return this.http.get<FormDataLogViewModel[]>(`${this.apiUrl}/${formDataId}/history`)
      .pipe(catchError(this._errorService.handleError));
  }


}
