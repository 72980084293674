import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'obc-duration-selector',
  templateUrl: './duration-selector.component.html',
  styleUrls: ['./duration-selector.component.scss']
})
export class DurationSelectorComponent implements OnInit {

  @Input() durationInHour: number;
  @Output() durationSelectedChanged: EventEmitter<number> = new EventEmitter();

  durationList = [
    { title: 'No Expiry', hour: null },
    { title: 'One Hour', hour: 1 },
    { title: '2 Hours', hour: 2 },
    { title: '4 Hours', hour: 4 },
    { title: '8 Hours', hour: 8 },
    { title: '24 Hours (1 Day)', hour: 24 },
    { title: '72 Hours (3 Days)', hour: 72 },
    { title: '168 Hours (7 Days)', hour: 168 },
  ];

  constructor() { }

  ngOnInit(): void {
  }
  valueChanged() {
    this.durationSelectedChanged.emit(this.durationInHour);
  }
}

