import { Component, EventEmitter, Input, OnInit, Output, Type, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WORKFLOW_COMPONENT } from 'src/app/app.module';
import { ModalService } from 'src/app/services/modal.service';
import { ComponentLoaderDirective } from '../ComponentLoaderDirective';
import { WorkflowService } from '../../services/workflow.service';
import { IWorkflowStep } from '../IWorkflowStep';
import { CommandView } from 'src/app/models/workflow/command-view';
import { ContinueWorkflowQueryViewModel, SaveStepViewModel } from 'src/app/models/workflow/continue-workflow-query-viewModel';
import { WorkflowInfoViewModel } from 'src/app/models/workflow/workflow-info-viewmodel';
import { finalize } from 'rxjs';
import { VariableDefinitionType, VariableValueDefinition } from 'src/app/models/workflow/variable-difinition';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { FormGroup } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { CompanyStatus } from 'src/app/enums/company-status.enum';
import { PortalDisplayMode } from 'src/app/enums/portal-display-mode.enum';
import { WorkflowVariableValue } from 'src/app/models/workflow/workflow-variable-value';
import { reject } from 'lodash';
import { DefaultWorkflowDisplayMode } from 'src/app/enums/constants';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'obc-workflow-engine',
  templateUrl: './workflow-engine.component.html',
  styleUrls: ['./workflow-engine.component.scss']
})
export class WorkflowEngineComponent implements OnInit {
  formSelected: boolean = false;
  @ViewChild(ComponentLoaderDirective, { static: true }) componentLoader!: ComponentLoaderDirective;

  @Input() workflowInstanceId: string;
  @Output() public workflowEningeModalHide = new EventEmitter();
  step: IWorkflowStep;
  workflow: WorkflowInfoViewModel;
  note: string;
  loadErrorMessage: string;
  hasError: boolean = false;
  backtopageParam: string;
  returnQueryParam: Params;
  inProgress: boolean = false;
  returnUrl: string = '';
  VariableDefinitionType = VariableDefinitionType;
  DatetimePickerMode = DatetimePickerMode;
  PortalDisplayModeEnum = PortalDisplayMode;

  portalDisplayMode: PortalDisplayMode = DefaultWorkflowDisplayMode;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private workflowService: WorkflowService,
    private modalService: ModalService,
    private toastrService: ToastrService,
    private formService: FormService,
    private userService: UserService) {
  }

  handleParam(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.route.queryParams.subscribe((param) => {
        this.returnUrl = param?.returnPath
        this.route.params.subscribe((param) => {
          resolve(param['id']);
        });
      });

    });
  }

  public getComponentByName(componentName: string): Component {
    let foundComponent = WORKFLOW_COMPONENT[componentName];
    return foundComponent as Component;
  }

  ngOnInit(): void {
    this.portalDisplayMode = this.userService.getCompanyWorkflowDisplayMode();

    if (this.portalDisplayMode == PortalDisplayMode.Page) {
      this.handleParam().then(tId => {
        this.workflowInstanceId = tId;
        this.loadStepComponent();
      });
    }
    else if (this.portalDisplayMode == PortalDisplayMode.PopUp) {
      if (this.workflowInstanceId)
        this.loadStepComponent();
      else
        this.workflowEningeModalHide.emit();
    }
  }

  /**
   * Load step's info
   */
  loadStepComponent() {

    this.inProgress = true;
    this.workflowService
      .getWorkflowByInstanceId(this.workflowInstanceId)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(
        (result) => {
          if (result != null) {
            this.workflow = result;
            this.workflow.hasForm = false;
            this.workflow.variableFormGroup = new FormGroup({});
            let component = this.getComponentByName(this.workflow.currentActivity.userControlAddress);
            let viewContainerRef = this.componentLoader.viewContainerRef;
            viewContainerRef.clear();

            const stepComponentData = viewContainerRef.createComponent<Component>(component as Type<Component>);
            this.step = stepComponentData.instance as IWorkflowStep;
            this.initWorkflow().then((res) => {
              if (res == false) {
                //back to cartable
                this.handleUnknownException();
              }
            });
          }
          else {
            this.hasError = true;
            this.loadErrorMessage = "There is no form!";
            this.handleUnknownException();
          }
        },
        (error) => {
          this.hasError = true;
          this.loadErrorMessage = "There is an error!";
          this.handleUnknownException();
        });
  }

  private handleUnknownException() {

    if (this.portalDisplayMode == PortalDisplayMode.PopUp) {
      this.formService.isFormDirty.next(false);
      //this.workflowEningeModalHide.emit();
    }
    else if (this.portalDisplayMode == PortalDisplayMode.Page) {
      //back to cartable
      if (this.returnUrl)
        this.router.navigate([this.returnUrl]);
      else
        this.router.navigate(['/workflow-cartable']);
    }
  }

  initWorkflow(): Promise<boolean> {
    return new Promise<boolean>((resolve, _) => {
      var initialResult = this.step.initWorkflow(this.workflow);
      if (!initialResult) {
        resolve(initialResult);
      }
      else {
        if ((<Promise<boolean>>initialResult).then !== undefined) {
          (<Promise<boolean>>initialResult).then((res) => {
            resolve(res);
          });
        }
        else {
          resolve(initialResult);
        }
      }
    });
  }

  commandFired(command: CommandView) {
    this.inProgress = true;
    this.validateCommand(command).then(() => {
      this.confirmCommand(command).then(() => {

        this.saveStep(command).then((res) => {
          if (res && res.continue) {
            let workflowVariableValues = [];

            this.workflow.currentActivity.variableList?.forEach(variable => {
              workflowVariableValues.push(<WorkflowVariableValue>{
                key: variable.key,
                value: this.workflow.variableFormGroup.value[variable.key]
              });
            });

            this.workflowService
              .continueWorkflow(
                this.workflowInstanceId,
                <ContinueWorkflowQueryViewModel>{
                  activityId: this.workflow.currentActivity.id,
                  workflowInstanceId: this.workflowInstanceId,
                  commandKey: command.key,
                  commandRoles: command.roleList,
                  form: res?.data,
                  note: this.workflow.currentActivity.note,
                  variables: workflowVariableValues,
                })
              .pipe(finalize(() => this.inProgress = false))
              .subscribe((response) => {
                if (response != null && response.executed) {
                  this.toastrService.success('Workflow has been done successfully');

                  if (this.portalDisplayMode == PortalDisplayMode.PopUp) {
                    this.formService.isFormDirty.next(false);
                    this.workflowEningeModalHide.emit();
                  }
                  else if (this.portalDisplayMode == PortalDisplayMode.Page) {
                    //back to cartable
                    if (this.returnUrl)
                      this.router.navigate([this.returnUrl]);
                    else
                      this.router.navigate(['/workflow-cartable']);
                  }
                }
                else {
                  this.toastrService.error((response.message?.length ?? 0) ? response.message : 'Continuing the workflow encountered an error');
                  this.inProgress = false
                }
              });
          }
          else {
            this.inProgress = false;
            this.toastrService.warning('Saving step and continuing the workflow encountered an error');
          }
        });
      }, reject => {
        this.inProgress = false
      });
    }, (errorMessage: string) => {
      this.toastrService.warning((errorMessage?.length ?? 0) ? errorMessage : "Something is wrong");
      this.inProgress = false;
    });
  }

  saveStep(command: CommandView): Promise<SaveStepViewModel> {
    return new Promise<SaveStepViewModel>((resolve, reject) => {
      var saveStepResult = this.step.saveStep(command, this.note);
      if (!saveStepResult) {
        resolve(null);
      }
      else {
        if ((<Promise<SaveStepViewModel>>saveStepResult).then !== undefined) {
          (<Promise<SaveStepViewModel>>saveStepResult).then((res) => {
            resolve(res);
          });
        }
        else {
          resolve(saveStepResult);
        }
      }
    });
  }


  confirmCommand(command: CommandView): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (command.confirmMessage) {
        this.modalService
          .confirm(command.confirmMessage)
          .subscribe(response => {
            if (response === true) {
              resolve()
            }
            else {
              reject('')
            }
          });
      }
      else
        resolve();
    })
  }


  validateCommand(command: CommandView): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      var isValidVariables = this.workflow.variableFormGroup.valid;
      if (!isValidVariables) {
        reject('Answer all required questions.');
        return;
      }
      var validationResult = this.step.validateStep(command, this.note);

      if (typeof validationResult == 'boolean') {
        if (validationResult)
          resolve();
        else
          reject();
      }
      else {
        validationResult
          .then((result) => {
            if (result)
              resolve();
            else
              reject();
          }, () => {
            reject();
          });
      }
    })
  }

  returnTo() {
    var returnPath = this.route.snapshot.queryParams['returnPath'];
    this.router.navigate((returnPath?.length ?? 0) ? [returnPath] : ['/workflow-cartable']);
  }
}
