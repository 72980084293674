import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { AccessControlWhiteListUserModel, AccessControlWhiteListUserViewModel } from 'src/app/models/access-control/access-control-white-list-user-model';
import { UserBasicInfoViewModel } from 'src/app/models/user-basic-info-viewmodel';
import { AccessControlService } from 'src/app/services/access-control.service';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-access-control-white-list-users',
  templateUrl: './access-control-white-list-users.component.html',
  styleUrls: ['./access-control-white-list-users.component.scss']
})
export class AccessControlWhiteListUsersComponent implements OnInit {
  inProgress: boolean = false;
  newRecord: AccessControlWhiteListUserModel = {};
  whiteListUsers: AccessControlWhiteListUserViewModel[];


  constructor(private accessControlService: AccessControlService,
    private toastr: ToastrService,
    private modelService: ModalService) { }

  ngOnInit(): void {
    this.loadWhiteListUsers();
  }

  loadWhiteListUsers() {
    this.inProgress = true;
    this.accessControlService.getWhiteListUsers()
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => this.whiteListUsers = res);
  }

  onSelectUser(user: UserBasicInfoViewModel) {
    this.newRecord.userId = user?.id;
  }

  onSiteIdSelect(siteId: number) {
    this.newRecord.siteId = siteId;
  }

  addNewWhiteListUser() {
    if (this.newRecord?.userId == null) {
      this.toastr.error('Please select user!');
      return;
    }
    this.inProgress = true;
    this.accessControlService.addNewWhiteListUser(this.newRecord)
      .pipe((finalize(() => this.inProgress = false)))
      .subscribe(res => {
        this.newRecord = {};
        this.loadWhiteListUsers();
      });
  }

  removeWhiteListUser(id: number) {
    this.modelService.confirm('Are you sure you want to remove this record?')
      .subscribe(res => {
        if (res == true) {
          this.inProgress = true;
          this.accessControlService.removeNewWhiteListUser(id)
            .pipe((finalize(() => this.inProgress = false)))
            .subscribe(res => {
              this.loadWhiteListUsers();
            });
        }
      });
  }
}
