import { Pipe, PipeTransform } from '@angular/core';
import { SupplierDocumentAmendmentMode } from '../enums/supplier-document-amendment-mode.enum';

@Pipe({
  name: 'supplierDocumentAmendmentMode'
})
export class SupplierDocumentAmendmentModePipe implements PipeTransform {

  transform(value: unknown): string {
    if (value == SupplierDocumentAmendmentMode.Disable) return 'Disable';
    if (value == SupplierDocumentAmendmentMode.Enable) return 'Enable';
    return value?.toString() ?? '?';
  }

}
