<div class="form-group row">
    <label class="col-sm-4 control-label text-left pt-2">Is Required?</label>
    <div class="col-sm-8 pull-left">
        <obc-descriptor
            [text]="'Do you require an answer to this question for an individual to check in successfully?'">
        </obc-descriptor>
        <obc-switch [value]="_questionParam['isRequired']" (valueChanged)="_questionParam['isRequired'] = $event">
        </obc-switch>
    </div>
</div>
<div class="form-group row">
    <label class="col-sm-4 control-label text-left pt-2">Include Time?</label>
    <div class="col-sm-8 pull-left">
        <obc-switch [value]="_questionParam['includeTime']" (valueChanged)="_questionParam['includeTime'] = $event">
        </obc-switch>
    </div>
</div>
<div class="form-group row">
    <label class="col-sm-4 control-label text-left pt-2">Minimum</label>
    <div class="col-sm-8 pull-left">
        <div>
            <label>
                <input type="radio" name="hasMinLimit" [value]="false" [(ngModel)]="hasMinimumLimit"
                    (change)="onToggleLimit(true)">
                Unrestricted
            </label>
            <label>
                <input class="ml-2" type="radio" name="hasMinLimit" [value]="true" [(ngModel)]="hasMinimumLimit"
                    (change)="onToggleLimit(true)"> Select Range
            </label>
        </div>
        <obc-date-question-limit
          (change)="emitChanges()"
          *ngIf="hasMinimumLimit && _minLimit" [(limit)]="_minLimit"></obc-date-question-limit>
    </div>
</div>
<div class="form-group row">
    <label class="col-sm-4 control-label text-left pt-2">Maximum</label>
    <div class="col-sm-8 pull-left">
        <div>
            <label>
                <input type="radio" name="hasMaxLimit" [value]="false" [(ngModel)]="hasMaximumLimit"
                    (change)="onToggleLimit(false)">
                Unrestricted
            </label>
            <label>
                <input class="ml-2" type="radio" name="hasMaxLimit" [value]="true" [(ngModel)]="hasMaximumLimit"
                    (change)="onToggleLimit(false)"> Select Range
            </label>
        </div>
        <obc-date-question-limit
          (change)="emitChanges()"
          *ngIf="hasMaximumLimit && _maxLimit" [(limit)]="_maxLimit"></obc-date-question-limit>
    </div>
</div>
