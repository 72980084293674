<div class="row">
  <div class="col-md-6  col-sm-12 mt-3">
    <div class="row">
      <div class="col-md-12">
        <div class="input-group ">
          <label>{{header}}</label>
          <input type="file" #input accept=".png, .jpg, .jpeg" class="form-control-file" (change)="onPickImage($event)" />
        </div>
      </div>
      <div class="col-md-12 mt-2">
        <button *ngIf="isFileSelected" class="btn btn-sm btn-info" type="button" (click)="onClearSelectedImage()">Undo Changes</button>
        <button *ngIf="currentImageSrcUrl && !isFileSelected && removable" class="btn btn-sm btn-danger" type="button" (click)="onClearSelectedImage()">Remove Image</button>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-sm-12">
    <div *ngIf="imageSourceBase64">
      <image-cropper style="height: 200px; width: 200px; float: right;" [imageBase64]="imageSourceBase64" [maintainAspectRatio]="false" format="png" (imageCropped)="imageCropped($event)" (loadImageFailed)="loadImageFailed()"
        [containWithinAspectRatio]="true">
      </image-cropper>
    </div>
    <div *ngIf="!imageSourceBase64">
      <div class="d-flex align-items-center" style="min-height: 100px" *ngIf="currentImageSrcUrl">
        <img class="site-logo" [src]="currentImageSrcUrl">
      </div>
    </div>
  </div>
</div>
