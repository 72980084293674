<div class="card">
  <div class="card-header d-flex  justify-content-between">
    <h5>Add/Edit Companies Info</h5>
  </div>
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="row">
      <div class="col-12">
        <form class="form-horizontal form-bordered" [formGroup]="newCompanyFormGroup" (ngFormSubmit)="addCompany()">
          <section class="card">
            <div class="card-body">
              <div class="form-group row">
                <label class="col-sm-4 control-label text-sm-right pt-2">Company Name :</label>
                <div class="col-sm-8">
                  <input type="text" name="name" class="form-control" formControlName="title">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-md-4 control-label text-md-right pt-2">
                  Company Type :
                </label>
                <div class="col-12 col-md-8 pull-left ">
                  <select class="form-control" formControlName="companyTypeId">
                    <option [ngValue]="null">General</option>
                    <option *ngFor="let item of companyTypes" [ngValue]="item.id">{{item.title}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row" *ngIf="timezones">
                <label class="col-sm-4 control-label text-sm-right pt-2">TimeZone :</label>
                <div class="col-sm-8">
                  <select class="form-control" formControlName="timezone">
                    <option [ngValue]="null" disabled>Choose Timezone</option>
                    <option *ngFor="let tz of (timezones | filterByProperty:false:'isOtherTimezone')" [ngValue]="tz.id">
                      {{tz.name}} ({{tz.offset}})
                    </option>
                    <optgroup label="OTHER TIMEZONES">
                      <option *ngFor="let tz of (timezones | filterByProperty:true:'isOtherTimezone')"
                        [ngValue]="tz.id">
                        {{tz.name}} ({{tz.offset}})
                      </option>
                    </optgroup>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 control-label text-sm-right pt-2">
                  Max Sites (for unlimited leave it blank)
                  :
                </label>
                <div class="col-sm-8">
                  <input type="number" class="form-control" formControlName="maxSites">
                </div>
              </div>
              <ng-container>
                <div class="form-group row">
                  <label class="col-12 col-md-4 control-label text-md-right pt-2">
                    Selecte A Template
                    Company:
                  </label>
                  <div class="col-12 col-md-8 pull-left ">
                    <select class="form-control" [(ngModel)]="templateCompanyId" [ngModelOptions]="{standalone: true}"
                      #templateCompany>
                      <option selected [ngValue]="null">Without A Company Template</option>
                      <option *ngFor="let item of templateCompanies" [ngValue]="item.id">{{item.title}}</option>
                    </select>
                  </div>
                </div>
              </ng-container>
              <div class="form-group row">
                <label class="col-sm-4 control-label text-sm-right pt-2">Is This A Template Company ? </label>
                <div class="col-sm-8">
                  <obc-switch [value]="newCompanyFormGroup.controls.isTemplate.value"
                    (valueChanged)="newCompanyFormGroup.controls.isTemplate.setValue($event)">
                  </obc-switch>
                </div>
              </div>
              <div class="form-group row" *ngIf="newCompanyFormGroup.controls.isTemplate.value">
                <label class="col-sm-4 control-label text-sm-right pt-2">Company Category :</label>
                <div class="col-sm-8">
                  <select name="description" class="form-control" formControlName="description">
                    <option *ngFor="let index of companyCategory | enumToArray">
                      {{companyCategory[index] | companyCategory }}
                    </option>
                  </select>
                </div>
              </div>

            </div>
            <footer class="card-footer text-right">
              <button class="btn btn-primary"
                [disabled]="!newCompanyFormGroup.valid || (cloneACompanyTemplate && !templateCompanyId)"
                (click)="addCompany()">
                Add New Company
              </button>
              <button type="reset" class="btn btn-default">Cancel</button>
            </footer>
          </section>
        </form>
        <div class="alert alert-warning mt-1" *ngIf="errorMessage">
          <strong>{{errorMessage}}</strong>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="errorMessage = null">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <hr class="w-100">
      <div class="col-12 mb-2">
        <p style="text-align: center;" *ngIf="companies == null">
          Loading ...
        </p>
        <p style="text-align: center;" *ngIf="companies != null && companies.length == 0">
          Nothing Found
        </p>
        <table *ngIf="companies" class="table table-bordered table-striped mb-0">
          <thead>
            <tr>
              <th class="sortable" (click)="sortBy('id')">#</th>
              <th class="sortable" (click)="sortBy('title')">Title</th>
              <th class="sortable" (click)="sortBy('companyType')">Type</th>
              <th class="sortable" (click)="sortBy('isActive')">Status</th>
              <th class="sortable" (click)="sortBy('isTemplate')">Is Template ?</th>
              <th class="sortable" (click)="sortBy('activePlan')">Plan</th>
              <th class="sortable" (click)="sortBy('currentActiveSites')">Active Sites</th>
              <th class="sortable" (click)="sortBy('averageDailyCheckIns')">Average CheckIns</th>
              <th>SMS Limit</th>
              <th></th>
            </tr>
            <tr>
              <td [attr.colspan]="20">
                <input [attr.disabled]="companies == null ? true : null" type="text" class="form-control"
                  [formControl]="filterFormcontrol" placeholder="Search by title" />
              </td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let company of companies"
              [ngClass]="{'bk-light-danger': (company.maxSites != null && company.currentActiveSites > company.maxSites) ||
              ((company.maxAverageDailyCheckIns != null && company.averageDailyCheckIns > company.maxAverageDailyCheckIns) && company.averageDailyCheckIns != null )}">
              <td><span>{{company.id}}</span></td>
              <td><span>{{company.title}}</span></td>
              <td><span *ngIf="!company.isTemplate"
                  class="{{company.companyTypeId ? 'font-weight-bold':''}}">{{getCompanyTypeTitle(company.companyTypeId)}}</span>
              </td>
              <td>
                <span *ngIf="company.isActive">Enable</span>
                <span *ngIf="!company.isActive">Disable</span>
              </td>
              <td>
                {{ company.isTemplate ? 'Yes' : 'No' }}
              </td>
              <td>
                <div *ngIf="!company.isTemplate">
                  <ng-container *ngIf="company.tempdata[1] == 'view'">
                    <div class="d-flex justify-content-between align-items-center">
                      <span>{{company.activePlan ? company.activePlan : 'No Plan'}}</span>
                      <button class="btn btn-sm btn-primary" (click)="switchViewMode('edit', company)">
                        <i class="fa fa-edit"></i>
                      </button>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="company.tempdata[1] == 'edit'">
                    <div class="input-group mb-3">
                      <select class="form-control" id="plan" name="plan" [formControl]="company.tempdata[0]">
                        <option [value]="null"> No Plan </option>
                        <option *ngFor="let plan of plans" [ngValue]="plan.id">{{getPlanTitle(plan)}}</option>
                      </select>
                      <div class="input-group-append">
                        <button class="btn btn-sm btn-info" (click)="setActivePlan(company)">
                          <i class="fa fa-save"></i>
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </td>
              <td>
                <div *ngIf="!company.isTemplate">
                  {{company.currentActiveSites}} of {{company.maxSites != null ? company.maxSites : 'unlimited'}}
                </div>
              </td>
              <td>
                <div *ngIf="!company.isTemplate">
                  <ng-container *ngIf="company.averageDailyCheckIns != null">
                    <div class="d-flex flex-row justify-content-between">
                      <span>
                        {{company.averageDailyCheckIns}} of {{company.maxAverageDailyCheckIns != null ?
                        company.maxAverageDailyCheckIns : 'unlimited'}}
                      </span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="company.averageDailyCheckIns == null">
                    -
                  </ng-container>
                  <button (click)="onGetCompanyStats(companyStats, company.id)" class="btn btn-sm btn-info">
                    <i class="fa fa-info"></i>
                  </button>
                </div>
              </td>
              <td>
                <div *ngIf="company.smsHardLimit || company.smsSoftLimit">
                  <span class="text-success">{{company.smsSoftLimit ? company.smsSoftLimit:'-'}}</span>
                  /
                  <span class="text-danger">{{company.smsHardLimit ? company.smsHardLimit:'-'}}</span>
                </div>
              </td>
              <td>
                <div class="d-flex justify-content-start">
                  <button class="btn btn-sm btn-primary mr-1" (click)="onShowEditModal(editCompanyModal, company)">
                    Edit
                  </button>
                  <button *ngIf="!company.isTemplate" class="btn btn-sm btn-info  mr-1"
                    (click)="onShowUsersModal(manageUserCompanyModal, company)">
                    Manage Users
                  </button>
                  <button *ngIf="!company.isTemplate" class="btn btn-sm btn-warn mr-1 btn-secondary"
                    (click)="onSendWelcomeEmail(sendWelcomeEmail, company.id)">
                    Send Welcome Email
                  </button>
                  <button *ngIf="enableWorkflowManagement" class="btn btn-sm btn-success mr-1"
                    [routerLink]="getWorkflowManagementRoute(company)">
                    Workflow Management
                  </button>
                </div>
                <div class="d-flex justify-content-end">
                  <!-- <obc-switch [tagged]="company" (objectChanged)="onCampanyIsActiveChange($event)"
                    [value]="company.isActive" falseLabel="Enable" trueLabel="Disable"></obc-switch> -->

                </div>
              </td>
            </tr>
          </tbody>
          <tfoot *ngIf="companies && pagingInfo.totalCount>0">
            <tr>
              <td colspan="20">
                <obc-pagination [totalCount]="pagingInfo.totalCount" [pageSize]="pagingInfo.pageSize"
                  [pageNumber]="pagingInfo.pageNumber" (pageChanged)="onPageChange($event)"></obc-pagination>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>
<ng-template #editCompanyModal>
  <div class="card" *ngIf="editCompanyFormGroup">
    <obc-loader [show]="inAddEditProgress"></obc-loader>
    <div class="card-header">
      <span>Edit Company</span>
      <button type="button" class="close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <form [formGroup]="editCompanyFormGroup">
        <input type="hidden" formControlName="id">
        <div class="form-group">
          <label>Title</label>
          <input type="text" class="form-control" formControlName="title">
        </div>
        <div class="form-group" *ngIf="!editCompanyFormGroup.controls.isTemplate.value">
          <label>Company Type :</label>
          <select class="form-control" formControlName="companyTypeId">
            <option selected [ngValue]="null">General</option>
            <option *ngFor="let item of companyTypes" [ngValue]="item.id">{{item.title}}</option>
          </select>
        </div>
        <div class="form-group" *ngIf="editCompanyFormGroup.controls.isTemplate.value">
          <label>Company Category :</label>
          <select name="description" class="form-control" formControlName="description">
            <option
              selected="{{(companyCategory[index] | companyCategory) == editCompanyFormGroup.controls.description.value ? 'selected':''}}"
              *ngFor="let index of companyCategory | enumToArray">
              {{companyCategory[index] | companyCategory }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label>Company Supervisor(s) Email Address :</label>
          <input type="text" class="form-control" formControlName="companySupervisorEmailAddress">
        </div>
        <div class="form-group">
          <label>Max Sites (for unlimited leave it blank) :</label>
          <input type="number" class="form-control" formControlName="originalMaxSites">
        </div>
        <div class="form-group">
          <label>SMS Soft Limit:</label>
          <input type="number" class="form-control" formControlName="smsSoftLimit">
        </div>
        <div class="form-group">
          <label>SMS Hard Limit:</label>
          <input type="number" class="form-control" formControlName="smsHardLimit">
        </div>
        <div class="form-group form-check">
          <input type="checkbox" class="form-check-input" formControlName="isActive">
          <label class="form-check-label">Enable</label>
        </div>
        <p class="button-group">
          <button [disabled]="!editCompanyFormGroup.valid" href="#" class="card-link btn btn-primary"
            (click)="onSubmitEditForm()">
            Save Changes
          </button>
        </p>
      </form>
      <div class="alert alert-warning mt-1" *ngIf="errorEditModalMessage">
        <strong>{{errorEditModalMessage}}</strong>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"
          (click)="errorEditModalMessage = null">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #manageUserCompanyModal>
  <div class="card">
    <obc-loader [show]="inAddEditProgress"></obc-loader>
    <div class="card-header">
      <span>
        Edit User(s) of Company
        <br>
        <small>{{selectedCompany.title}}</small>
      </span>
      <button type="button" class="close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 pt-2">
          <form class="form-inline" [formGroup]="newUserFormGroup" (ngFormSubmit)="addNewUser()">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <div class="form-group mb-1 col-md-6 col-sm-12">
                    <input style="width: 100%;" type="text" formControlName="mobile" class="form-control"
                      placeholder="Mobile (International format like +61xxxxxxxxx)">
                  </div>
                  <div class="form-group mb-1  col-md-6 col-sm-12">
                    <input style="width: 100%;" type="text" formControlName="firstname" class="form-control"
                      placeholder="Name">
                  </div>
                  <div class="form-group mb-1  col-md-6 col-sm-12">
                    <input style="width: 100%;" type="text" formControlName="lastname" class="form-control"
                      placeholder="Family">
                  </div>
                  <div style="padding-left: 15px;" class="form-group mb-1 form-check col-md-6 col-sm-12">
                    <label class="form-check-label">
                      <input type="checkbox" formControlName="isAdmin" class="form-control mr-2" placeholder="">
                      Is Admininstrator?
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <button [disabled]="!newUserFormGroup.valid" type="button" class="btn btn-primary  mb-2 mr-1"
                  (click)="addNewUser()">
                  Add
                </button>
              </div>
            </div>
            <div class="row" *ngIf="message">
              <div class="col-md-12">
                <span class="alert-danger"><small>{{message}}</small></span>
              </div>
            </div>

          </form>
        </div>
        <hr style="width: 100%;">
        <table class="table table-bordered table-striped mb-0 col-md-12 modal-table">
          <thead>
            <tr>
              <td>Mobile</td>
              <td>Name</td>
              <td>Family</td>
              <td>Is Admininstrator?</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="users.length == 0">
              <td colspan="5">
                <p style="text-align: center; margin-bottom: 0;">Nothing Found ...</p>
              </td>
            </tr>
            <tr *ngFor="let user of users">
              <td>{{user.mobile | mobile}}</td>
              <td>{{user.firstName}}</td>
              <td>{{user.lastName}}</td>
              <ng-container *ngIf="user.inEdit !== true">
                <td>
                  <span *ngIf="user.isAdmin">Yes</span>
                  <span *ngIf="!user.isAdmin">No</span>
                </td>
                <td class="text-right" ng>
                  <button class="btn btn-info btn-sm mr-2" (click)="initEdit(user)">
                    <i class="fa fa-edit"> </i>
                  </button>
                  <button class="btn btn-danger btn-sm" (click)="onRemoveUser(user)">
                    <i class="fa fa-times"> </i>
                  </button>
                </td>
              </ng-container>
              <ng-container *ngIf="user.inEdit === true">
                <td>
                  <obc-switch [value]="selectedUserIsAdmin" (valueChanged)="selectedUserIsAdmin = $event">
                  </obc-switch>
                </td>
                <td class="actions text-right">
                  <a class="btn btn-success  save-row mr-2" (click)="updateUserAdminState(user)">
                    <i class="fas fa-save"></i>
                  </a>
                  <a class="btn btn-warning cancel-row" (click)="user.inEdit = false"><i class="fas fa-times"></i></a>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #sendWelcomeEmail>
  <div class="card">
    <div class="card-header">
      Send Welcome Email
    </div>
    <div class="card-body">
      <form [formGroup]="sendWelcomeFormGroup">
        <div class="row">
          <div class="form-group mb-1  col-md-12">
            <label>First name :</label>
            <input style="width: 100%;" type="text" formControlName="firstName" class="form-control">
          </div>
          <div class="form-group mb-1  col-md-12">
            <label>Last name :</label>
            <input style="width: 100%;" type="text" formControlName="lastName" class="form-control">
          </div>
          <div class="form-group mb-1  col-md-12">
            <label>Email Address :</label>
            <input style="width: 100%;" type="text" formControlName="email" class="form-control">
          </div>
          <div class="col-md-12 text-right">
            <button [disabled]="!sendWelcomeFormGroup.valid" (click)="sendingWelcomeEmail()"
              class="btn btn-sm btn-primary">Send</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #companyStats>
  <div class="card">
    <div class="card-header">
      Company Statistics
    </div>
    <div class="card-body">
      <obc-loader [show]="inGettingStatsProgress"></obc-loader>
      <div class="responsive-table">
        <table class="table table-bordered table-striped mb-0 col-md-12 modal-table">
          <thead>
            <tr>
              <td>Month</td>
              <td>Average Check-Ins Per Days</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let stat of stats">
              <td>{{GetYearMonth(stat)}}</td>
              <td>{{stat.avarageDailyCheckIns}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>