<div class="card">
  <div class="card-header">
    {{title}}
    <div class="card-actions">
      <a (click)="hide.emit()" class="card-action card-action-dismiss" data-card-dismiss=""></a>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <table class="table table-responsive-md table-striped mb-0 obc-table">
          <thead>
            <tr>
              <th>
                Question
                <!-- <a target="_blank" class="pointer" role="menuitem" tabindex="-1" [routerLink]="['/question']">
                  <small>(Add New Question)</small>
                </a> -->
              </th>
              <th *ngIf="showAddButton"> <span class="pull-right btn btn-primary" (click)="onAddNewQuestionClick(questionAddTemplate)">Add</span> </th>
            </tr>
            <tr>
              <td colspan="2">
                <input type="text" class="form-control" placeholder="Search in questions"
                  [(ngModel)]="filterQuestionTerm" />
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div class="row" style="padding-right: 15px;">
      <div class="col-md-12 scrollable-list" style="max-height: 400px;overflow-y: scroll;">
        <table class="table table-responsive-md table-striped mb-0 obc-table">
          <tbody>
            <tr *ngIf="!companyAvailableQuestions || companyAvailableQuestions.length == 0">
              <td colspan="2" style="width: 100%; float: none;border-top: none;">
                <p class="text-center mb-0">there is no question to add</p>
              </td>
            </tr>
            <tr
              *ngFor="let queze of (companyAvailableQuestions | filter:filterQuestionTerm:['questionText'])">
              <td>
                <obc-question-extended-title [question]="queze">
                </obc-question-extended-title>
              </td>
              <td>
                <button *ngIf="isMultiSelect" class="pull-right btn btn-xs btn-info"
                  (click)="onAddQuestion(queze.id)">
                  <i class="fas fa-plus"></i>
                </button>
                <div *ngIf="!isMultiSelect" class="pull-right text-primary cursor-pointer"
                  (click)="onAddQuestion(queze.id)">
                  <i>Select</i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<ng-template #questionAddTemplate>
  <obc-question-add [withResult]="true" (result)="onResultQuestionAdd($event)" ></obc-question-add>
</ng-template>
