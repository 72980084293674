import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nicyNull'
})
export class NicyNullPipe implements PipeTransform {

  transform(value: any): any {
    return value != null ? value : "[NOT SPECIFIED]";
  }

}
