import { Component, Input, OnInit } from '@angular/core';
import { QuestionType } from 'src/app/enums/question-type';
import { FormDataLogDetail, FormDataLogViewModel } from 'src/app/models/form/form-data-detail-view-model';
import { FormService } from 'src/app/services/form.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-form-history',
  templateUrl: './form-history.component.html',
  styleUrls: ['./form-history.component.scss']
})
export class FormHistoryComponent implements OnInit {
  inProgress: boolean;

  _formDataId: number;
  logs: FormDataLogViewModel[];
  get formDataId(): number {
    return this._formDataId;
  };

  @Input() set formDataId(value: number) {
    if (this._formDataId != value) {
      this._formDataId = value;
      this.getHistory();
    }
  }

  constructor(private formService: FormService, public modalService: ModalService) { }

  ngOnInit(): void {
    if (this.formDataId)
      this.getHistory();
  }

  getHistory() {
    this.inProgress = true;
    this.formService.getFormHistory(this.formDataId)
      .subscribe(res => {
        this.inProgress = false;
        this.logs = res;
      });
  }

  isDateLogValue(log: FormDataLogDetail) {
    return log.questionType == QuestionType.Date;
  }
  showLink(log: FormDataLogDetail) {
    return log.type == 1 || log.questionType == QuestionType.File || log.questionType == QuestionType.SignaturePad;
  }
  getLogDescription(log: FormDataLogDetail) {
    return `${(log.oldValue?.length ?? false) ? log.oldValue : '-'} → ${(log.newValue?.length ?? false) ? log.newValue : '-'}`;
  }




}

