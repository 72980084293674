<div class="card">
  <div class="card-header">
    <i *ngIf="valid" class="fas fa-check-circle text-success"></i>
    <i *ngIf="!valid" class="fas fa-times-circle text-danger"></i>
    Venues ({{sites.controls.length}} of
    {{userService.currentCompany.maxSites - userService.currentCompany.currentActiveSites}})
    <button [disabled]="canAddSite<=0" (click)="onAdd()" class="btn btn-sm pull-right btn-info">Add New Venue</button>
  </div>
  <div class="card-body">
    <div *ngIf="!sites.controls?.length">
      <p class="text-center text-danger">please add new venue(s)</p>
    </div>
    <div *ngIf="sites.controls?.length">
      <div class="card" *ngFor="let row of sites.controls | toFormGroup">
        <div class="card-body">
          <form class="row">
            <div class="form-group col-md-6">
              <label>Name</label>
              <div class="input-group">
                <input type="text" class="form-control" [formControl]="row.controls.name" placeholder="Venue Name">
              </div>
            </div>
            <div class="form-group col-md-6">
              <label>Logo</label>
              <div class="input-group">
                <obc-file-upload class="full-width"
                                 [accept]="FileUploadAcceptType.Image"
                                 [formControl]="row.controls.logoFile"></obc-file-upload>
              </div>
            </div>
            <ng-container *ngIf="userService.hasCompanyFeature(ProductPlanFeature.Announcements)">
              <div class="form-group col-md-6">
                <label>PDF Menu </label>
                <div class="input-group">
                  <obc-file-upload class="full-width" accept="application/pdf" [formControl]="row.controls.menuPdfFile"></obc-file-upload>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Service Ordering website</label>
                <div class="input-group">
                  <input type="text" class="form-control" [formControl]="row.controls.serviceOrderingWebSite"
                    placeholder="Table Service Ordering Url">
                </div>
              </div>
            </ng-container>
            <div class="form-group col-md-12">
              <label>Address</label>
              <div class="input-group">
                <obc-address-form-control [isGeolocationRequierd]="true" [formControl]="row.controls.address"></obc-address-form-control>
              </div>
            </div>
            <div class="col-md-12 text-right">
              <button (click)="onRemove(row)" class="btn btn-sm btn-danger pull-right">
                <i class="fa fa-times"></i> Remove
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
