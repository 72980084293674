import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SiteRequiredFieldViewModel } from '../models/site-required-field-viewmodel';

@Injectable({
  providedIn: 'root'
})
export class SiteRequiredFieldService {
  apiUrl: string = "/api/SiteRequiredField";
  constructor(private http: HttpClient, private _errorService: ErrorService) { }
  getAll(siteId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/get-site-required-fields/${siteId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  update(siteId: number, model: SiteRequiredFieldViewModel): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/update-filed-state/${siteId}`, model)
      .pipe(catchError(this._errorService.handleError));
  }
}
