import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { QuestionType } from 'src/app/enums/question-type';
import { TextOperator } from 'src/app/enums/text-operator.enum';
import { uuid4 } from 'src/app/helpers/uuid';
import { QuestionViewModel } from 'src/app/models/question-viewmodel';
import { QuestionService } from 'src/app/services/question.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import {
  MixedQuestionAndDisplayFilterViewModel,
  QuestionAnswerFilterViewModel
} from "../../../models/question-answer-filter-model";
import { DatetimePipe } from "../../../pipes/datetime.pipe";
import { ItemTitleValue } from 'src/app/models/helper-models';
import { InventoryService } from 'src/app/services/inventory.service';
import { GlobalToolDynamicFieldViewModel } from 'src/app/models/inventory/global-tool-dynamic-field-view-model';
import { ToolDynamicFieldType } from 'src/app/enums/tool-dynamic-field-type.enum';

@Component({
  selector: 'obc-question-answer-filter',
  templateUrl: './question-answer-filter.component.html',
  styleUrls: ['./question-answer-filter.component.scss']
})
export class QuestionAnswerFilterComponent implements OnInit {
  isAllQuestionFilter: boolean = true;
  @Input() set filters(value: MixedQuestionAndDisplayFilterViewModel) {
    this.filterQuestions = [];
    if (value && value.questionFilters?.length > 0)
      this.filterQuestions.push(...value.questionFilters);
  }
  @Output() public onClose = new EventEmitter<MixedQuestionAndDisplayFilterViewModel>();
  questions: QuestionViewModel[];
  globalAssetFields: GlobalToolDynamicFieldViewModel[];
  inProgress: boolean;
  selectedQuestion: any = null;
  filterQuestions: QuestionViewModel[] = [];
  _questionType = QuestionType;
  QuestionTypesToShow = [
    QuestionType.Boolean,
    QuestionType.SingleSelect,
    QuestionType.MultiSelect,
    QuestionType.Text,
    QuestionType.Date,
  ];
  TextOperator = TextOperator;
  totalErrors = [];
  questionModalRef: BsModalRef;

  mandatoryValueValidators = [Validators.required];
  modalRef: BsModalRef;
  thisComponentInstance: QuestionAnswerFilterComponent;

  constructor(
    private questionService: QuestionService,
    private inventoryService: InventoryService,
    private bsModalService: BsModalService,
    private datetimePipe: DatetimePipe,
  ) {
    this.thisComponentInstance = this;
  }

  ngOnInit(): void {
    this.getGlobalAssetFields();
  }

  onChangeMode(value) {
    this.isAllQuestionFilter = value;
  }

  getAssetQuestionType(question: QuestionViewModel): ToolDynamicFieldType {
    return QuestionType.AssetField == question.questionType ?
      this.globalAssetFields?.find(x => x.id == JSON.parse(question.questionParameters)['globalToolDynamicFieldId'])?.type : null;
  }

  isTextQuestion(question: QuestionViewModel): boolean {
    return question.questionType === QuestionType.Text ||
      (question.questionType === QuestionType.AssetField && [ToolDynamicFieldType.Text, ToolDynamicFieldType.Number].some(t => t == this.getAssetQuestionType(question)));
  }
  isDateQuestion(question: QuestionViewModel): boolean {
    return question.questionType === QuestionType.Date ||
      (question.questionType === QuestionType.AssetField && this.getAssetQuestionType(question) == ToolDynamicFieldType.Date);
  }
  isTextOrDateQuestion(question: QuestionViewModel): boolean {
    return this.isTextQuestion(question) || this.isDateQuestion(question);
  }


  openAnswersFilterModal(template) {
    this.modalRef = this.bsModalService.show(template, {
      ignoreBackdropClick: true,
      class: "modal-lg",
    });
    this.modalRef?.onHide.subscribe((_) => {
      this.onCloseMethod();
    })
  }

  onCloseMethod(ignoreInvalidForm: boolean = false) {
    if (this.canCloseModal()) {
      this.modalRef.hide();
      this.onClose.emit({
        questionFilters: this.filterQuestions,
        displayFilters: this.filterQuestions?.map((item) => {
          return this.getFilterModelItem(item);
        }),
        isAllFilter: this.isAllQuestionFilter
      } as MixedQuestionAndDisplayFilterViewModel);
    }
  }
  canCloseModal() {
    let anyInvalidItemAvailable = this.filterQuestions?.filter((item) => {
      return this.isTextOrDateQuestion(item) &&
        (item.operatorFormControl?.value != TextOperator.isNull && item.operatorFormControl?.value != TextOperator.isNotNull)
    })?.find((item: QuestionViewModel) => {
      return (!item.formControl?.value || item.formControl?.value == 'Invalid Date')
    });
    return !anyInvalidItemAvailable;
  }

  getGlobalAssetFields() {
    this.inventoryService.getGlobalToolDynamicFields({})
      .subscribe({
        next: assetFields => {
          this.globalAssetFields = assetFields;
          this.getQuestions();
        }
      });
  }

  getQuestions() {
    this.questionService.getActiveQuestions()
      .pipe(finalize(() => {
        this.inProgress = false;
      }))
      .subscribe({
        next: (res) => {
          this.questions = res.data.filter(_question =>
            this.QuestionTypesToShow.some(qt => qt === _question.questionType) ||
            (_question.questionType == QuestionType.AssetField && ((this.getAssetQuestionType(_question) ?? ToolDynamicFieldType.File) != ToolDynamicFieldType.File))
          ).map(x => x = new QuestionViewModel(x));
        }
      });
  }

  getQuestionItems(question: QuestionViewModel): ItemTitleValue[] {
    return QuestionViewModel.getQuestionOptions(question, false);
  }

  getQuestionTitle(id: number) {
    return this.questions.filter(q => q.id == id)[0].questionText;
  }
  onAddQuestionToFilter() {
    if (!this.selectedQuestion) return;
    var clone = new QuestionViewModel(this.selectedQuestion);
    this.initFilterQuestion(clone);
    clone.uuid = uuid4();

    if (this.isTextOrDateQuestion(clone)) {
      clone.formControl?.updateValueAndValidity();
      clone.operatorFormControl?.valueChanges?.subscribe((res) => {
        if (res != TextOperator.isNull && res != TextOperator.isNotNull) {
          clone.formControl?.setValidators(this.mandatoryValueValidators);
        } else {
          clone.formControl?.clearValidators();
        }
        clone.formControl?.updateValueAndValidity();
      })
    }
    this.filterQuestions.push(clone);
  }

  onRemoveQuestionFromFilter(question: any) {
    var index = this.filterQuestions.indexOf(question);
    if (index >= 0) {
      this.filterQuestions.splice(index, 1);
    }
  }

  filterOperators(operator: string, that: QuestionAnswerFilterComponent, question: QuestionViewModel) {
    return that.isTextQuestion(question) || TextOperator[operator] != TextOperator.contains;
  }

  onMultiSelectValueChange(question, value, event) {
    if (value.itemValue == null && event.target.checked)
      question.items.forEach(item => {
        if (item.itemValue != null) {
          item.disabled = true;
          item.formControl.setValue(false);
        }
      });
    else
      question.items.forEach(item => {
        item.disabled = false;
      });
  }
  initFilterQuestion(question: QuestionViewModel) {
    question.items = [];

    if (this.isTextQuestion(question)) {
      question.disabled = false;
      question.formControl = new FormControl(null, this.mandatoryValueValidators);
      question.operatorFormControl = new FormControl(TextOperator.contains);
    }
    else if (this.isDateQuestion(question)) {
      question.disabled = false;
      question.formControl = new FormControl(null, this.mandatoryValueValidators);
      question.operatorFormControl = new FormControl(TextOperator.equals);
    }
    else {
      switch (question.questionType) {
        case QuestionType.Boolean:
          var formControl = new FormControl(question.questionParametersAsString?.isRequired === false ? null : "Yes");
          question.items = [{ itemTitle: 'Yes', itemValue: 'Yes', formControl: formControl }, { itemTitle: 'No', itemValue: 'No', formControl: formControl }]
          if (question.questionParametersAsString?.isRequired === false)
            question.items.push({ itemTitle: 'No Select', itemValue: null, formControl: formControl });
          break;
        case QuestionType.SingleSelect:
          var formControl = new FormControl();
          question.items = QuestionViewModel.getQuestionOptions(question, false)
            .map(c => {
              return { itemTitle: c.itemTitle, itemValue: c.itemValue, formControl: formControl }
            });
          formControl.setValue(question.items[0].itemValue);
          break;
        case QuestionType.MultiSelect:
          question.items = QuestionViewModel.getQuestionOptions(question, false)
            .map(c => {
              return { itemTitle: c.itemTitle, itemValue: c.itemValue, formControl: new FormControl(false), disabled: false }
            });
          break;
      }
    }
  }

  getFilterModelItem(_question: QuestionViewModel, notitle: boolean = false): QuestionAnswerFilterViewModel {
    let answer: { title: any, value: any, operator?: TextOperator };
    if (this.isTextQuestion(_question)) {
      answer = {
        title: _question.formControl.value ?? "Not Answered",
        value: _question.formControl.value ?? "",
        operator: _question.operatorFormControl.value
      };
    }
    else if (this.isDateQuestion(_question)) {
      answer = {
        title: _question.operatorFormControl.value == TextOperator.isNotNull || _question.operatorFormControl.value == TextOperator.isNull ?
          '' :
          (_question.formControl.value ? this.datetimePipe.transform(_question.formControl.value) : "Select Date"),
        value: _question.formControl.value ?? new Date(),
        operator: _question.operatorFormControl.value
      };
    }
    else {
      switch (_question.questionType) {
        case QuestionType.Boolean:
        case QuestionType.SingleSelect:
          const item = _question.items.filter(i => i.itemValue === _question.items[0].formControl.value)[0];
          answer = { title: item.itemTitle, value: item.itemValue ?? "" };
          break;
        case QuestionType.MultiSelect:
          const titles = _question.items.filter((item) => item.formControl.value)
            .map((item) => item.itemTitle ?? "Not Select Item").join();

          const values = _question.items.filter((item) => item.formControl.value)
            .map((item) => item.itemValue ? item.itemValue : "").join();
          answer = { title: titles, value: values ?? "" };
          break;
      }
    }

    let result = {
      questionId: _question.id,
      answers: [answer.value],
      titles: [answer.title],
      operator: answer.operator,
      uuid: _question.uuid,
      text: _question.questionText,
    } as QuestionAnswerFilterViewModel;

    if (notitle)
      result.titles = null;
    return result;
  }

  onShowAddQuestionsModal(template) {
    this.questionModalRef = this.bsModalService.show(template);
  }

  onCloseAddQuestionsModal() {
    this.questionModalRef.hide();
  }

  addQuestion(res: QuestionViewModel) {
    if (res != null) {
      this.selectedQuestion = res;
      this.onAddQuestionToFilter();
    }
  }

  getAddedQuestions() {
    return this.filterQuestions ? this.filterQuestions.map(q => q.id) : [];
  }

}

