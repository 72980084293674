import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SiteVisitorTypeAccompanierViewModel, VisitorType } from '../models/site-visitortype-accompanier-viewmodel';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class VisitorTypeService {
  apiUrl: string = '/api/VisitorType';
  constructor(private http: HttpClient, private _errorService: ErrorService) {

  }

  _cachedVisitorTypes: VisitorType[];
  cacheGetAllVisitorTypes(): Observable<VisitorType[]> {
    if (this._cachedVisitorTypes?.length > 0)
      return of(this._cachedVisitorTypes);
    else
      return this.getAllVisitorTypes();
  }

  getAllVisitorTypes = (): Observable<VisitorType[]> =>
    this.http.get<VisitorType[]>(`${this.apiUrl}/get-all-visitor-type`).pipe(
      tap((res) => this._cachedVisitorTypes = res),
    );
  addVisitorType = (model: any): Observable<any[]> =>
    this.http.post<any>(`${this.apiUrl}/add-visitor-type`, model);
  updateVisitorType = (id: number, model: any): Observable<any[]> =>
    this.http.post<any>(`${this.apiUrl}/update-visitor-type/${id}`, model);
  removeVisitorType = (id: number): Observable<any[]> =>
    this.http.get<any>(`${this.apiUrl}/remove-visitor-type/${id}`);

  getSiteVisitorTypeAccompaniers(siteId: number): Observable<SiteVisitorTypeAccompanierViewModel[]> {
    return this.http.get(`${this.apiUrl}/get-site-visitor-type-accompanier/${siteId}`).pipe(catchError(this._errorService.handleError));
  }
}
