import {Component, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormScope } from 'src/app/enums/form-scope';
import { SupplierDocumentReviewMode } from 'src/app/enums/supplier-document-review-mode.enum';
import { SupplierDocumentReviewFormSelectionMode } from 'src/app/enums/supplier-documnet-review-form-selection-mode.enum';
import { FormViewModel } from 'src/app/models/form/form-view-model';
import { ServerResult } from 'src/app/models/server-result-data';
import { ApproveDeclineSiteSupplierDocumentVersion } from 'src/app/models/supplier-document/site-supplier-document-type-models';
import { SiteSupplierVersionFormReviewModel } from 'src/app/models/supplier-document/site-supplier-version-form-review-model';
import { ModalService } from 'src/app/services/modal.service';
import { SupplierDocumentService } from 'src/app/services/supplier-document.service';
import { FormApplyFor } from "../../../enums/form-apply-for";
import { FormRegisterFor } from "../../../enums/form-register-for";
import { FormType } from "../../../enums/form-type";
import { ISiteSupplierDocumentTypeVersion } from "../../../models/supplier-document/site-supplier-document-type";
import { SupplierPermitsService } from "../../../services/supplier-permits.service";
import { FormDataRendererComponent } from '../../form-manage/form-data-renderer/form-data-renderer.component';
import { WorkflowInfoViewModel } from 'src/app/models/workflow/workflow-info-viewmodel';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'obc-supplier-document-review',
  templateUrl: './supplier-document-review.component.html',
  styleUrls: ['./supplier-document-review.component.scss']
})
export class SupplierDocumentReviewComponent implements OnInit {
  SupplierDocumentReviewModeEnum = SupplierDocumentReviewMode
  @Output() public hide = new EventEmitter();
  @Output() public resultOfApproveReject = new EventEmitter();
  @Input() selectedSiteSupplierDocument: ISiteSupplierDocumentTypeVersion;
  @Input() supplierDocumentReviewMode: SupplierDocumentReviewMode;
  @ViewChild("formDataRenderer") public formDataRenderer: FormDataRendererComponent;
  @Input() formType: FormType = FormType.SupplierDocumentReview;
  @Input() hashLink: string;
  @Input() siteSupplierId: number;
  @Input() supplierId: number;
  @Input() siteId: number;
  @Input() isReadonly: boolean = false;
  @Input() workflowModel: WorkflowInfoViewModel;

  loadFromWorkflow: boolean = false;

  FormTypeEnum = FormType;
  FormApplyFor = FormApplyFor;

  inProgress: boolean = false;
  formScope = FormScope;
  form: FormViewModel;
  isFormValid: boolean;
  registerFor: FormRegisterFor = FormRegisterFor.Supplier;
  showSelectForm: boolean
  supplierDocumentReviewFromSelectionMode = SupplierDocumentReviewFormSelectionMode
  supplierDocumentReviewFromSelectionModeValue = SupplierDocumentReviewFormSelectionMode.withForm
  formSelectionMode: FormControl
  showPicPanel: boolean = true;


  constructor(
    private supplierDocumentService: SupplierDocumentService,
    private supplierPermitsService: SupplierPermitsService,
    private formService: FormService,
    private modalService: ModalService,
    private renderer: Renderer2,
    ) {
    this.formSelectionMode = new FormControl({});
    this.formSelectionMode.setValue(SupplierDocumentReviewFormSelectionMode.withForm);
    this.renderer.addClass(document.body, 'no-scroll');
  }

  ngOnInit(): void {
    this.onResize();

    this.formSelectionMode.valueChanges.subscribe((value) => {
      this.formService.isFormDirty.next(false);
      this.toggleShowPic();
      if(value == this.supplierDocumentReviewFromSelectionMode.withoutForm) {
        this.unloadPDF();
        this.loadPDF();
      }
    });

    if (this.workflowModel && this.workflowModel.id) {
      this.loadFromWorkflow = true;
      if (this.workflowModel?.currentActivity?.formsToFill?.length > 0) {
        this.workflowModel.hasForm = true;
        this.form = this.workflowModel.currentActivity.formsToFill[0];
        this.form.name = this.form.buttonTitle && this.form.buttonTitle.length > 0 ? this.form.buttonTitle : this.form.name;

      }

    }
  }

  onlyShowForm(): boolean {
    return (this.form &&
      (this.loadFromWorkflow || this.formSelectionMode.value == SupplierDocumentReviewFormSelectionMode.withForm) &&
      !this.showPicPanel);
  }
  onlyShowFiles(): any {
    return (!this.form ||
      (!this.loadFromWorkflow && this.formSelectionMode.value != this.supplierDocumentReviewFromSelectionMode.withForm));
  }
  showFormAndFile(): any {

    return (this.form &&
      (this.loadFromWorkflow || this.formSelectionMode.value == SupplierDocumentReviewFormSelectionMode.withForm) &&
      this.showPicPanel);

  }

  viewFormSelector() {
    return ((this.loadFromWorkflow && this.workflowModel?.currentActivity?.formsToFill?.length > 1) ||
      (!this.loadFromWorkflow && this.supplierDocumentReviewMode != SupplierDocumentReviewMode.Disabled &&
        this.formSelectionMode.value == SupplierDocumentReviewFormSelectionMode.withForm))
  }

  get modalTitle(): string {
    return this.formType == this.FormTypeEnum.Permit ? 'Permit Form' : (this.selectedSiteSupplierDocument != null ? 'Review Supplier Document' : 'Form')
  }

  onSelectForm(form: FormViewModel) {
    this.form = form;
    this.toggleShowPic();
    this.unloadPDF();
    this.formService.isFormDirty.next(false);
  }

  validateForm(formIsValid: boolean) {

    this.isFormValid = formIsValid
  }

  get isReviewFormValid(): boolean {
    return (this.isFormValid && this.formSelectionMode.value == SupplierDocumentReviewFormSelectionMode.withForm) ||
      this.formSelectionMode.value == SupplierDocumentReviewFormSelectionMode.withoutForm
  }

  approveDecline(isApprove: boolean) {
    return new Promise<ServerResult>((resolve, reject) => {
      this.inProgress = true;
      var formAnswers = null;
      if (this.formSelectionMode.value == SupplierDocumentReviewFormSelectionMode.withForm) {
        formAnswers = this.formDataRenderer.getFormResponse();
        formAnswers.siteSupplierDocumentVersionId = this.selectedSiteSupplierDocument.lastSupplierVersionId;
      }
      var query = {
        formDataModel: formAnswers,
        ApproveDeclineSiteSupplierDocumentVersion: {
          isApproved: isApprove,
          siteSupplierDocumentVersionId: this.selectedSiteSupplierDocument.lastSupplierVersionId,
          expirationDate: this.selectedSiteSupplierDocument.expirationDate
        } as ApproveDeclineSiteSupplierDocumentVersion,
      } as SiteSupplierVersionFormReviewModel;

      this.supplierDocumentService
        .siteSupplierVersionFormReview(query)
        .subscribe(res => {
          if (res.success) {
            resolve(res);
            this.formService.isFormDirty.next(false);

          } else {
            this.modalService.error(res.message);
            reject();
          }
        }, err => {
          this.modalService.error(err, "Failed");
          reject();

        }, () => {
          this.inProgress = false;
        })
    });
  }

  onApproveDeclineSiteSupplierVersionAndFillForm(isApprove: boolean) {
    this.approveDecline(isApprove)
      .then(res => {
        this.hide.emit();
        this.resultOfApproveReject.emit();
      })
      .catch();
  }

  saveFromWorkflow() {
    return new Promise<void>((resolve, reject) => {
      this.inProgress = true;
      var formAnswers = this.formDataRenderer.getFormResponse();

      let siteSupplierId = this.hashLink ? null : this.siteSupplierId;

      this.supplierPermitsService.submitFormData(formAnswers, this.hashLink, siteSupplierId).subscribe(res => {
        if (res.success) {
          resolve();
        }
        else {
          this.modalService.error(res.message);
          reject();
        }
      }, err => {
        this.modalService.error(err, "Failed");
        reject();
      }, () => {
        this.inProgress = false;
      })
    });
  }

  onSubmitPermitForm() {
    this.saveFromWorkflow()
      .then(res => {
        this.hide.emit();
        this.resultOfApproveReject.emit();
      })
      .catch();
  }

  showTabPage: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    setTimeout(() => {
      this.showTabPage = false;
      if (window.innerWidth < 1200)
        this.showTabPage = true;

      this.showPicPanel = true;
      if (this.selectedSiteSupplierDocument == null || this.selectedSiteSupplierDocument.files == null || this.selectedSiteSupplierDocument.files.length == 0) {
        this.showPicPanel = false;
      }
    }, 500);
  }


  toggleShowPic() {
    this.inProgress = true;
    this.showPicPanel = !this.showPicPanel;
    setTimeout(() => {
      this.showPicPanel = !this.showPicPanel;
      this.inProgress = false;
    }, 50);
  }

  pdfIsLoaded = true;
  loadPDF() {
    if(!this.pdfIsLoaded) {
      setTimeout(() => {
        this.pdfIsLoaded = true;
      }, 100)
    }
  }
  unloadPDF() {
    this.pdfIsLoaded = false;
    if(!this.showTabPage) {
      this.loadPDF();
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'no-scroll');
  }
}
