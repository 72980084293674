<section class="card">
  <header class="card-header">
    <div class="d-flex">
      <h5>List of Supplier Forms</h5>
    </div>
    <div class="card-actions">
    </div>
  </header>
  <div class="card-body">

    <div class="">
      <table class="table table-bordered table-striped mb-0">
        <thead>
          <tr>
            <td>Datetime</td>
            <td>Version</td>
            <td>Status</td>
            <td>Approve/Reject By</td>
            <td></td>
          </tr>

        </thead>
        <tbody>
          <ng-container *ngIf="formHistory && formHistory.length > 0; ">
            <ng-container *ngFor="let history of formHistory">
              <tr>
                <td>
                  {{ history.fillDateTime | datetime }}
                </td>
                <td>
                  <div class="text-success">
                    {{ history.displayVersion }}
                  </div>
                </td>
                <td>
                  {{ history.status | formDataStatus }}
                </td>
                <td>
                  {{ history.approveRejectByUserFullName }}
                </td>
                <td>

                  <button tooltip="View" class="mr-1 btn btn-default btn-info"
                    (click)="onViewForm(formDetails, history.formDataId)">
                    <span class="fa fa-eye"></span>
                    View
                  </button>

                </td>
              </tr>
            </ng-container>
          </ng-container>

        </tbody>
      </table>
    </div>

  </div>
</section>

<ng-template #formDetails>
  <button type="button" class="btn btn-sm modal-close-button" (click)="closeFormDetailsModal()">
    <i class="fa fa-times"></i>
  </button>
  <obc-form-details [hashLink]="hashLink" [formDataId]="selectedFormDataId"></obc-form-details>
</ng-template>
