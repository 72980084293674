<form name="newQusestionForm" (ngSubmit)="newQusestionForm.form.valid && addNewQuestion()" #newQusestionForm="ngForm"
    class="form-horizontal form-bordered">
    <section class="card">
        <header class="card-header">
            <div class="card-actions">
                <a (click)="result.emit({ isFinished: true, isSucceeded: false })"
                    class="card-action card-action-dismiss" data-card-dismiss=""></a>
            </div>

            <h2 class="card-title">Question</h2>

        </header>
        <div class="card-body">
            <div *ngIf='errorMessage' class='alert alert-danger mb-2'>
                {{errorMessage}}
            </div>
            <div class="form-group row">
                <label class="col-sm-4 control-label text-left pt-2">Question:</label>
                <div class="col-sm-8">
                    <input type="text" #name="ngModel" name="name" class="form-control" required
                        [(ngModel)]="_question.questionText">
                    <div *ngIf="name.invalid && (name.dirty || name.touched)" class="help-block with-errors">
                        Please Enter question
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 control-label text-left pt-2">
                    <input type="checkbox" [checked]="useRichTextTitle"
                        (change)="useRichTextTitle=$event.target.checked">
                    Use Rich Text Title</label>
                <div class="col-sm-12">
                    <obc-markdown-editor *ngIf="useRichTextTitle" [value]="_question.questionRichText"
                        (valueChanged)="_question.questionRichText = $event" [id]="'questionTitleRichEditor'">
                    </obc-markdown-editor>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 control-label text-left pt-2">Question Type:</label>
                <div class="col-sm-8">
                    <select required class="form-control" [(ngModel)]="_question.questionType" name="questionType"
                        #drp="ngModel" (ngModelChange)="clearError();questionTypeChanged($event)">
                        <ng-container *ngFor="let item of questionType|enumToArray ">
                            <option *ngIf="questionType[item] != QuestionType.EmergencyContact.toString()"
                                [ngValue]="questionType[item]">
                                {{questionType[item]|questionType}}
                            </option>
                        </ng-container>

                    </select>
                    <div *ngIf="drp.invalid && (drp.dirty || drp.touched)" class="help-block with-errors">
                        Please select a question type
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 control-label text-left pt-2">Question Is Active:</label>
                <div class="col-sm-8 pull-left">
                    <obc-switch [value]="_question.isActive" (valueChanged)="_question.isActive = $event">
                    </obc-switch>
                </div>
            </div>
            <div *ngIf="displayAskQuestionField" class="form-group row">
                <label class="col-sm-4 control-label text-left pt-2">Ask Question:</label>
                <div class="col-sm-8 pull-left">
                    <select required class="form-control" [(ngModel)]="_question.askPeriodMode" name="askPeriodMode"
                        #drp="ngModel" (ngModelChange)="clearError()">
                        <option *ngFor="let item of askPeriodMode|enumToArray " [ngValue]="askPeriodMode[item]">
                            {{askPeriodMode[item] | askQuestionPeriodMode}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 control-label text-left pt-2">Ask Mode:</label>
                <div class="col-sm-8 pull-left">
                    <select required class="form-control" [(ngModel)]="_question.askMode" name="askMode"
                        (ngModelChange)="clearError()">
                        <option *ngFor="let item of askMode | enumToArray " [ngValue]="askMode[item]">
                            {{askMode[item] | askMode}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 control-label text-left pt-2">Question Default Mode:</label>
                <div class="col-sm-8 pull-left">
                    <select required class="form-control" [(ngModel)]="_question.defaultMode" name="defaultMode"
                        #drp="ngModel" (ngModelChange)="clearError()">
                        <option *ngFor="let item of questionDefaultMode|enumToArray "
                            [ngValue]="questionDefaultMode[item]">
                            {{questionDefaultMode[item] | questionDefaultMode}}
                        </option>
                    </select>
                </div>
            </div>
            <div *ngIf="displayQuestionIsSensitive" class="form-group row">
                <label class="col-sm-4 control-label text-left pt-2">Question Is Sensitive:</label>
                <div class="col-sm-8 pull-left">
                    <obc-switch [value]="_question.isSensitive" (valueChanged)="_question.isSensitive = $event">
                    </obc-switch>
                    <obc-tooltip
                        message="Viewing answers to sensitive questions is restricted to authorised staff only.">
                    </obc-tooltip>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-sm-12">
                    <ng-container [ngSwitch]="_question.questionType">
                        <div *ngSwitchCase="questionType.Text">
                            <obc-question-text [questionParamInput]="_questionText"></obc-question-text>
                        </div>
                        <div *ngSwitchCase="questionType.SingleSelect">
                            <obc-question-single-select
                              (onItemsUpdated)="onSingleMultiQuestionTypeItemsUpdate($event)"
                              [questionParamInput]="_questionSingle">
                            </obc-question-single-select>
                        </div>
                        <div *ngSwitchCase="questionType.MultiSelect">
                            <obc-question-multi-select
                              (onItemsUpdated)="onSingleMultiQuestionTypeItemsUpdate($event)"
                              [questionParamInput]="_questionMulti">
                            </obc-question-multi-select>
                        </div>
                        <div *ngSwitchCase="questionType.Boolean">
                            <obc-question-boolean [questionParamInput]="_questionBoolean"></obc-question-boolean>
                        </div>
                        <div *ngSwitchCase="questionType.File">
                            <obc-question-file (isGenericDocumentType)="onDocumentTypeModeChange($event)"
                                (selectedDocumentTypes)="onSelectedDocumentTypes($event)"
                                [documentTypeIdList]="selectedDocumentTypeIdList"
                                [questionParamInput]="_questionFile"></obc-question-file>
                        </div>
                        <div *ngSwitchCase="questionType.Date">
                            <obc-question-date (onChanged)="onDateChange($event)"
                                [questionParamInput]="_questionDate"></obc-question-date>
                        </div>
                        <div *ngSwitchCase="questionType.SignaturePad">
                            <obc-question-signature-pad [questionParamInput]="_questionSignaturePad">
                            </obc-question-signature-pad>
                        </div>
                        <div *ngSwitchCase="questionType.AssetField">
                            <obc-question-asset-field
                             (onItemsUpdated)="onAssetFieldItemsUpdatee($event)"
                             [questionParamInput]="_questionAssetField">
                            </obc-question-asset-field>
                        </div>
                        <div *ngSwitchCase="questionType.ProfileInfo">
                            <obc-question-profile [questionProfileSubTypes]="questionProfileSubTypes"
                                [questionParamInput]="_questionProfileInfo"></obc-question-profile>
                        </div>
                    </ng-container>
                </div>
            </div>

        </div>
        <footer class="card-footer text-right">
            <button class="btn btn-primary mr-2" type="submit"
                [disabled]="!_formIsvalid || !newQusestionForm.valid || !_documentTypeModeStateIsValid || !_datesAreValid || inProgress">Submit</button>
            <button type="reset" class="btn btn-default" (click)="cancel()">Cancel</button>
        </footer>
    </section>
</form>
