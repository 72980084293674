import { Component, OnInit, Input } from '@angular/core';
import { CardLayout, PageSize, PageLayout, getPageSizeName, getPageLayoutName, getPageSize } from 'src/app/models/print-layout/print-layout-model';
import { StorageService } from 'src/app/services/storage.service';
import { ModalService } from 'src/app/services/modal.service';
import { LocalStorageKey } from '../../../enums/local-storage-key.enum';

@Component({
  selector: 'obc-layout-page',
  templateUrl: './layout-page.component.html',
  styleUrls: ['./layout-page.component.scss']
})
export class LayoutPageComponent implements OnInit {
  inProgress: boolean = false;
  _layout: CardLayout = null;
  _pageSize = PageSize;
  _pageLayout = PageLayout;
  
  @Input() set layout(page: CardLayout) {
    if (page === null) {
      this._layout = new CardLayout();
      this._layout.id = this.newId;
      this._layout.elements = [];
    }
    else
      this._layout = JSON.parse(JSON.stringify(page));
  }

  getPageSizeName : any;
  getPageLayoutName : any;

  get newId(): number {
    var savedLayouts: CardLayout[] = JSON.parse(this.storageService.get(LocalStorageKey.PrintLayoutStorageKey));
    if (!savedLayouts || savedLayouts.length === 0)
      return 1;
    return Math.max.apply(Math, savedLayouts.map(function (o) { return o.id; })) + 1;
  }

  constructor(private storageService: StorageService, private modalService : ModalService) { }

  ngOnInit(): void {
    this.getPageSizeName = getPageSizeName;
    this.getPageLayoutName = getPageLayoutName;
  }

  updateInsert() {
    this.modalService.hide({saved : true, layout : this._layout});
  }

  cancel() {
    this.modalService.hide({saved : false, layout : null});
  }

  pageSizeChange() {
    var size = getPageSize(this._layout.size);
    if(size != null)
      if(this._layout.layout == this._pageLayout.Portrait) {
        this._layout.height = size.height.toString();
        this._layout.width = size.width.toString();
      } else {
        this._layout.width = size.height.toString();
        this._layout.height = size.width.toString();
      }
  }

}
