<div class="card">
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-md-12">
            <app-site-selector [required]="true" #sl (siteIdSelect)="siteIdChanged($event)"
              (siteSelect)="site = $event">
            </app-site-selector>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <button class="btn btn-info my-2 pull-left" [disabled]="!site" (click)="onAddSiteUser(addEditUserTemplate)">Add
          New User to
          <span class="text-light"> {{selectedSiteName}} </span><i class="fas fa-plus pl-2"></i></button>
        <div class="my-2  pull-right">
          <a *ngIf="site && site.secretCode" target="_blank" [href]="getShowQrCodeWithInstruction(site)"
            class="btn btn-primary mr-1">
            Print Instructions
          </a>
          <button id="addToTable" class="btn btn-success mr-1" (click)="openImportUserModal(importModal)">Import
            Users</button>
        </div>
      </div>
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table table-bordered table-striped mb-0 dataTable no-footer">
            <thead>
              <tr role="row">
                <th>Mobile</th>
                <th>Name</th>
                <th>Family</th>
                <th>Access Level</th>
                <th>Actions</th>
              </tr>
              <tr>
                <td colspan="6">
                  <input type="text" class="form-control" placeholder="Filter Result by Mobile, Name, Family"
                    [(ngModel)]="filterListTerm" />
                </td>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let user of (siteUsers | filter:filterListTerm:['mobile','firstName','lastName'])">
                <tr *ngIf="!user?.inEdit">
                  <td>{{user.mobile | mobile}}</td>
                  <td>{{user.firstName}}</td>
                  <td>{{user.lastName}}</td>
                  <td>
                    <ng-template #tooltipHtml>
                      <div *ngFor="let u of getPermissionTooltip(user)">
                        <div>{{u.label}}</div>
                      </div>
                    </ng-template>
                    <div class="d-flex flex-column" *ngIf="companyPermissionMode == PermissionModeEnum.Mixed ||
companyPermissionMode == PermissionModeEnum.Role">
                      <ng-container *ngIf="user.companyRoles?.length  > 0">
                        <span class="font-weight-bold">Roles:</span>
                        <div class="d-flex role-container ml-3">
                          <div>{{getRoles(user)}}</div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="d-flex flex-column" *ngIf="companyPermissionMode == PermissionModeEnum.Mixed ||
companyPermissionMode == PermissionModeEnum.Permission">
                      <ng-container>
                        <ng-template #tooltipHtml>
                          <div *ngFor="let u of getPermissionTooltip(user)">
                            <div>{{u.label}}</div>
                          </div>
                        </ng-template>
                        <span class="font-weight-bold" *ngIf="user.isAdmin">Has All Permissions</span>
                        <div class="d-flex permission-container">
                          <span *ngIf="!user.isAdmin && (user.permissions != userPermissions.None)"
                            [tooltip]="tooltipHtml"
                            class="permissions-caption font-weight-semibold">{{getPermissionCount(user)}}
                            Permission(s)</span>
                        </div>
                      </ng-container>
                    </div>
                  </td>
                  <td class="actions">
                    <a class="btn p-1 remove-row" (click)="deleteUser(user)"><i class="far fa-trash-alt"></i></a>
                    <a tooltip="Edit" class="btn btn-succes" (click)="onUpdateSiteUser(addEditUserTemplate, user)">
                      <i class="fa fa-edit"></i>
                    </a>
                    <a *ngIf="!user.isFrequentUser" tooltip="Add To Frequent Users" class="btn btn-default"
                      (click)="addToFrequentUsers(user)">
                      <i class="fas fa-user-plus"></i>
                    </a>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #addEditUserTemplate>
  <div class="card" *ngIf="site">
    <div class="card-header">
      <span *ngIf="!editMode">
        Add Site User to <strong>{{site.name}}<small> [{{site.siteReference}}]</small></strong>
      </span>
      <span *ngIf="editMode">
        Edit Site User of <strong>{{site.name}}<small> [{{site.siteReference}}]</small></strong>
      </span>
      <button type="button" class="close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <form [formGroup]="siteUserFormGroup">
        <div class="row">
          <div class="form-group col-md-4 col-sm-12">
            <label>Mobile</label>
            <ng-container *ngIf="!editMode">
              <obc-user-autocomplete #mobile [filterBy]="filterUserBy.Mobile" [required]="true"
                (selectedUser)="fillCompanyUser($event, siteUserFormGroup)" [currentUser]="selectedUser"
                (inputChange)="siteUserFormGroup.controls['mobile'].setValue($event.value)" display="mobile"
                name="mobile"
                [notFoundMessage]="isMobileValid(siteUserFormGroup.controls['mobile'].value) ? 'add as a new user' : ''">
              </obc-user-autocomplete>
              <small class="text-warning light-theme" for="mobile"
                *ngIf="!isMobileValid(siteUserFormGroup.controls['mobile'].value)">
                Please enter a valid
                mobile number.
              </small>
            </ng-container>
            <ng-container *ngIf="editMode">
              <input type="text" class="form-control" disabled formControlName="mobile">
            </ng-container>
          </div>
          <div class="form-group col-md-4 col-sm-12">
            <label>First Name</label>
            <ng-container *ngIf="!editMode">
              <obc-user-autocomplete [filterBy]="filterUserBy.FirstName" [displayNoResult]="false" [required]="true"
                (selectedUser)="fillCompanyUser($event, siteUserFormGroup)" [currentUser]="selectedUser"
                (inputChange)="siteUserFormGroup.controls['firstName'].setValue($event.value)" display="firstName">
              </obc-user-autocomplete>
            </ng-container>
            <ng-container *ngIf="editMode">
              <input type="text" class="form-control" disabled formControlName="firstName">
            </ng-container>

          </div>
          <div class="form-group col-md-4 col-sm-12">
            <label>Last Name</label>
            <ng-container *ngIf="!editMode">
              <obc-user-autocomplete [filterBy]="filterUserBy.LastName" [displayNoResult]="false" [required]="true"
                (selectedUser)="fillCompanyUser($event, siteUserFormGroup)" [currentUser]="selectedUser"
                (inputChange)="siteUserFormGroup.controls['lastName'].setValue($event.value)" display="lastName">
              </obc-user-autocomplete>
            </ng-container>
            <ng-container *ngIf="editMode">
              <input type="text" class="form-control" disabled formControlName="lastName">
            </ng-container>
          </div>
          <div class="form-check col-12" *ngIf="!editMode">
            <div class="d-flex justify-content-end">
              <label class="form-check-label mt-2 mr-2">Add To Frequent Users?</label>
              <obc-switch [value]="siteUserFormGroup.controls['isFrequentUser'].value"
                (valueChanged)="siteUserFormGroup.controls['isFrequentUser'].setValue($event)">
              </obc-switch>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-3" *ngIf="companyPermissionMode == PermissionModeEnum.Mixed ||
companyPermissionMode == PermissionModeEnum.Role">
            <div class="d-flex flex-column">
              <span class="mb-1">Roles</span>
              <obc-role-selector (result)="updateRoles($event)"
                [selectedRoles]="siteUserFormGroup.controls['companyRoles'].value"></obc-role-selector>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="companyPermissionMode == PermissionModeEnum.Mixed ||
            companyPermissionMode == PermissionModeEnum.Permission">
          <div class="col-12">
            <hr>
          </div>
          <div class="col-12">
            <ng-container *ngIf="companyPermissionMode == PermissionModeEnum.Mixed">
              <accordion>
                <accordion-group heading="Permissions">
                  <ng-container *ngTemplateOutlet="userPermissionsTemplate">
                  </ng-container>
                </accordion-group>
              </accordion>
            </ng-container>
            <ng-container *ngIf="companyPermissionMode == PermissionModeEnum.Permission">
              <ng-container *ngTemplateOutlet="userPermissionsTemplate">
              </ng-container>
            </ng-container>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <hr>
          </div>
          <div class="col-md-12">
            <p class="button-group text-right">
              <button *ngIf="!editMode" [disabled]="!isMobileValid(siteUserFormGroup.controls['mobile'].value)"
                class="btn btn-primary" (click)="addSiteUser()">
                <i class="fas fa-plus mr-1"></i>
                Add New User
              </button>
              <button *ngIf="editMode" class="btn btn-primary" (click)="updateSiteUser()">
                <i class="fas fa-edit mr-1"></i>
                Update Site User
              </button>
            </p>
          </div>
        </div>
        <ng-template #userPermissionsTemplate>
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-end">
                <label class="form-check-label mr-2 mt-2">Has All Permissions?</label>
                <obc-switch [value]="siteUserFormGroup.controls['isAdmin'].value"
                  (valueChanged)="siteUserFormGroup.controls['isAdmin'].setValue($event)">
                </obc-switch>
              </div>
            </div>
            <div class="col-12 mt-3" *ngIf="!siteUserFormGroup.controls['isAdmin'].value">
              <obc-check-list-form-control style="font-size: smaller;" formControlName="permissions"
                [values]="siteUserPermissions">
              </obc-check-list-form-control>
            </div>
          </div>
        </ng-template>


      </form>
    </div>
  </div>
</ng-template>
<ng-template #importModal>
  <div class="card">
    <div class="card-header">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalHandle.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 mt-1">
          <p>
            Import site user data in CSV file format (comma seperated)
            <a class="btn btn-primary btn-sm" href="../../../../assets/files/site-users-import.csv"
              target="_blank">Download Sample File</a>
          </p>
        </div>
        <div class="col-md-12 mt-1">
          <obc-file-upload [showClearButton]="true" (clear)="onClearForm()" [formControl]="fileFormControl"
            [accept]="FileUploadAcceptType.CSV">
          </obc-file-upload>
        </div>
        <div class="col-md-12 mt-1" *ngIf="importData && importData.valid && importData.message">
          <div class="alert alert-success mb-1" role="alert">
            {{importData.message}}
          </div>
        </div>
        <div class="col-md-12 mt-1" *ngIf="importData && !importData.valid">
          <div class="alert alert-danger mb-1" role="alert">
            {{importData.message}}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>