<div *ngIf="displayIframe; else displayPdf" style="height: 100vh">
    <iframe
            [src]="('/load-pdf/' + getSrc(src)) | safe: 'resourceUrl'" frameborder="0"
            height="100%" width="100%"></iframe>
</div>
<ng-template #displayPdf>
    <div class="pdf-loader" *ngIf="loading">
        <obc-loader [show]="true"></obc-loader>
    </div>
    <div class="pdf-overlay">
        <ngx-extended-pdf-viewer
                [src]="src"
                [height]="'auto'"
                [showRotateButton]="'xxs'"
                [showDrawEditor]="false"
                [showTextEditor]="false"
                [showOpenFileButton]="false"
                [useBrowserLocale]="true"
                [showSecondaryToolbarButton]="true"
                [showPresentationModeButton]="true"
                [showInfiniteScrollButton]="false"
                [showSpreadButton]="false"
                [showScrollingButton]="false"
                [showHorizontalScrollButton]="false"
                [showVerticalScrollButton]="false"
                [showWrappedScrollButton]="false"
                [showBookModeButton]="false"
                [showSinglePageModeButton]="false"
                [textLayer]="true"
                [showStampEditor]="false"
                [showPagingButtons]="false"
                [showHandToolButton]="renderText"
                (pdfLoaded)="hideLoader()"
                (pagesLoaded)="hideLoader()"
        >
        </ngx-extended-pdf-viewer>
    </div>
</ng-template>