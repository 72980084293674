<obc-loader [show]="inProgress && !_layout"></obc-loader>
<form name="layoutForm" (ngSubmit)="layoutForm.form.valid && updateInsert()" #layoutForm="ngForm"
    class="form-horizontal form-bordered" autocomplete="off">

    <section class="card">
        <header class="card-header">
            <h2 class="card-title">Layout Config</h2>
        </header>
        <div class="card-body" *ngIf="_layout">
            <div class="form-group">
                <label for="layoutName">Layout Name</label>
                <input required type="text" class="form-control" id="layoutName" name="layoutName"
                    [(ngModel)]="_layout.name">
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="layoutSize">Layout Size </label>
                    <select required id="layoutSize" name="layoutSize" (change)="pageSizeChange()" class="form-control" [(ngModel)]="_layout.size">
                        <option *ngFor="let item of _pageSize | enumToArray" [ngValue]="_pageSize[item]">{{getPageSizeName(_pageSize[item])}}</option>
                    </select>
                </div>
                <div class="form-group col-md-6" *ngIf="_layout?.size !== _pageSize.Custom">
                    <label for="layoutSize">Page Layout</label>
                    <select required id="pageLayout" name="pageLayout" (change)="pageSizeChange()" class="form-control" [(ngModel)]="_layout.layout">
                        <option *ngFor="let item of _pageLayout | enumToArray" [ngValue]="_pageLayout[item]">{{getPageLayoutName(_pageLayout[item])}}</option>
                    </select>
                </div>
            </div>
            <div class="form-row" *ngIf="_layout?.size === _pageSize.Custom">
                <div class="form-group col-md-6">
                    <label for="width">Width (cm)</label>
                    <input type="number" min="4" class="form-control" id="width" name="width" [(ngModel)]="_layout.width">
                </div>
                <div class="form-group col-md-6">
                    <label for="height">Height (cm)</label>
                    <input type="number" min="4" class="form-control" id="height" name="height" [(ngModel)]="_layout.height">
                </div>
            </div>
        </div>
        <footer class="card-footer">
            <div class="row">
                <div class="col-md-12 text-right">
                    <button [disabled]="!layoutForm.form.valid" type="submit" class="btn btn-primary modal-confirm">Submit</button>
                    <button class="btn btn-default modal-dismiss" (click)="cancel()">Cancel</button>
                </div>
            </div>
        </footer>
    </section>
</form>