import { Component, OnInit } from '@angular/core';
import {
  NotificationSettingItemModel,
  NotificationSettingItemViewModel,
  NotificationSettingViewModel,
  UserNotificationSettingModel,
  UserNotificationSettingViewModel
} from 'src/app/models/notifications/viewmodels';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { minNumber } from 'src/app/helpers/general-functions';
import { LayoutService } from "../../services/layout.service";

@Component({
  selector: 'obc-notification-setting',
  templateUrl: './notification-setting.component.html',
  styleUrls: ['./notification-setting.component.scss']
})
export class NotificationSettingComponent implements OnInit {
  inProgress: boolean;
  userNotificationSettings: UserNotificationSettingViewModel;


  currentCompanyUserNotificationSettings: NotificationSettingViewModel;
  personalUserNotificationSettings: NotificationSettingViewModel;

  _isPersonalMode: boolean = true;
  advancedIsOpen: Record<string, Partial<boolean>> = {};

  get isPersonalMode(): boolean {
    return this._isPersonalMode;
  }

  set isPersonalMode(value: boolean) {
    this._isPersonalMode = value;
    this.currentSetting = null;
    setTimeout(() => {
      this.currentSetting = this._isPersonalMode == true ? this.personalUserNotificationSettings : this.currentCompanyUserNotificationSettings;
    }, 10);
  }

  currentSetting: NotificationSettingViewModel;

  constructor(private notificationService: NotificationService,
    private userService: UserService,
    private layoutService: LayoutService,) { }


  ngOnInit(): void {
    this.loadSettings();
    //of(this.personalUserNotificationSettings.items).pipe(grou)
    this.layoutService.header = "Notification Settings";
  }

  loadSettings() {
    this.inProgress = true;
    this.notificationService.getUserNotificationSettings()
      .subscribe(res => {
        this.inProgress = false;
        this.userNotificationSettings = res;
        this.currentCompanyUserNotificationSettings =
          this.userNotificationSettings?.notificationSettings.filter(x => x.companyId === this.userService.currentCompanyId)[0];
        this.personalUserNotificationSettings =
          this.userNotificationSettings?.notificationSettings.filter(x => x.companyId === null)[0];
        this.currentSetting = this.isPersonalMode == true ? this.personalUserNotificationSettings : this.currentCompanyUserNotificationSettings;
      });
  }

  getOrderOfGroupedNotificationItems(groupedItem: { key: string, value: NotificationSettingItemViewModel[] }) {
    return minNumber(groupedItem.value.map(i => i.order));
  }

  getAggregatedSetting(title: string, list: NotificationSettingItemViewModel[]) {
    var count = list.length;
    var enabledCount = list.filter(x => x.status.isEnabled).length;
    var pushEnabledCount = list.filter(x => x.status.isEnabled && x.status.isPushNotificationEnabled).length;
    var emailEnabledCount = list.filter(x => x.status.isEnabled && x.status.isEmailEnabled).length;

    var enabledPartialyEnabled = enabledCount > 0 && enabledCount < count;
    var pushEnabledPartialyEnabled = pushEnabledCount > 0 && pushEnabledCount < count;
    var emailEnabledPartialyEnabled = emailEnabledCount > 0 && emailEnabledCount < count;

    let result = {
      isAggregated: true,
      title: title,
      list: list,
      status:
      {
        isEnabled: enabledCount > 0,
        isPushNotificationEnabled: pushEnabledCount > 0,
        isEmailEnabled: emailEnabledCount > 0,
        isEnabledPartially: enabledPartialyEnabled,
        isPushNotificationEnabledPartially: pushEnabledPartialyEnabled,
        isEmailEnabledPartially: emailEnabledPartialyEnabled,
      }
    }
    return result;
  }

  setAdvancedIsOpen(groupTitle) {
    this.advancedIsOpen[groupTitle] = !this.advancedIsOpen[groupTitle];
  }

  onSettingEnabledChanged(setting: NotificationSettingItemViewModel, isEnabled: boolean) {
    if (setting.isAggregated) {
      setting.list.forEach(s => s.status.isEnabled = isEnabled);
    }
    else {
      setting.status.isEnabled = isEnabled;
    }
  }

  onSettingWayEnabledChanged(setting: NotificationSettingItemViewModel, isEnabled: boolean, isEmailWay: boolean) {
    if (setting.isAggregated) {
      if (isEmailWay)
        setting.list.forEach(s => s.status.isEmailEnabled = isEnabled);
      else
        setting.list.forEach(s => s.status.isPushNotificationEnabled = isEnabled);
    }
    else {
      if (isEmailWay)
        setting.status.isEmailEnabled = isEnabled;
      else
        setting.status.isPushNotificationEnabled = isEnabled;
    }
  }
  save() {
    this.inProgress = true;
    var data = this.currentSetting.items.map(s => { return { notificationType: s.notificationType, status: s.status } as NotificationSettingItemModel; });
    this.notificationService.updateSettings({ companyId: this.currentSetting.companyId, items: data } as UserNotificationSettingModel)
      .subscribe(res => {
        this.inProgress = false;
        this.loadSettings();
      });
  }

}
