import { Pipe, PipeTransform } from '@angular/core';
import { PermitStatus } from '../enums/permit-status';
@Pipe({
  name: 'permitStatus'
})
export class PermitStatusPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === PermitStatus.Close) return "Close"
    else if (value === PermitStatus.OnHold) return "On-Hold"
    else if (value === PermitStatus.Open) return "Open"
    else return "All";
  }

}
