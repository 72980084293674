import { Component, Input, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CompareOperator } from 'src/app/enums/compare-operator.enum';
import { ToolDynamicFieldType } from 'src/app/enums/tool-dynamic-field-type.enum';
import { TriggerAction } from 'src/app/enums/trigger-action.enum';
import { dateFormat, formatDate } from 'src/app/helpers/date-time-format';
import { CompareOperatorFriendlyLabel } from 'src/app/helpers/friendly-label';
import { ToolDynamicFieldViewModel } from 'src/app/models/inventory/tool-dynamic-field-model';
import { ToolDynamicFieldTriggerViewModel } from 'src/app/models/inventory/tool-dynamic-field-trigger-model';
import { DatePipe } from 'src/app/pipes/date.pipe';
import { ManageInventoryService } from 'src/app/services/manage-inventory.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-inventory-dynamic-field-triggers',
  templateUrl: './inventory-dynamic-field-triggers.component.html',
  styleUrls: [],
  providers:[DatePipe]
})
export class InventoryDynamicFieldTriggersComponent implements OnInit {
  _dynamicField: ToolDynamicFieldViewModel;
  inProgress: boolean = false;
  triggers = new BehaviorSubject<ToolDynamicFieldTriggerViewModel[]>([])
  @Input() set toolDynamicField(value: ToolDynamicFieldViewModel) {
    if (this._dynamicField?.id != value?.id) {
      this._dynamicField = value;
      this.refreshTriggers();
    }
  }
  constructor(private manageInventoryService: ManageInventoryService,
    private modalService: ModalService,
    private datePipe: DatePipe,
    private bsModalService: BsModalService) { }

  ngOnInit() {
  }

  refreshTriggers() {
    this.inProgress = true;
    this.manageInventoryService.getToolDynamicFieldTriggers(this._dynamicField.id)
      .pipe(finalize(() => { this.inProgress = false }))
      .subscribe(res => {
        this.triggers.next(res.data);
      });
  }

  getTriggerActionFriendlyName(action: TriggerAction): string {
    switch (action) {
      case TriggerAction.Email: return 'Send an Email';
      default: return TriggerAction[action];
    }
  }

  getTriggerConditionFriendlyName(trigger: ToolDynamicFieldTriggerViewModel) {
    return `${this.getDynamicFieldTriggerFriendlyName(trigger.operator)} ${ this._dynamicField.type ==  ToolDynamicFieldType.Date ? formatDate(trigger.value, dateFormat) : trigger.value  }`
  }

  getDynamicFieldTriggerFriendlyName(operator: CompareOperator): string {
    return CompareOperatorFriendlyLabel(operator)
  }

  onToolDynamicFieldTriggerStateChange($event: boolean, trigger: ToolDynamicFieldTriggerViewModel) {
    if ($event != trigger.status) {
      this.inProgress = true;
      this.manageInventoryService.changeStatusToolDynamicFieldTrigger(this._dynamicField.id, trigger.id)
        .pipe(finalize(() => { this.inProgress = false }))
        .subscribe(res => {
          this.refreshTriggers();
        });
    }
  }

  onAddToolDynamicFieldTrigger(modalTemplate: any) {
    this.currentTrigger = null;
    this.editModalHandle = this.bsModalService.show(modalTemplate, {
      ignoreBackdropClick: true,
    })
  }

  currentTrigger: ToolDynamicFieldTriggerViewModel = null;
  editModalHandle: any;
  onEditToolDynamicFieldTrigger(trigger: ToolDynamicFieldTriggerViewModel, modalTemplate: any) {
    this.currentTrigger = trigger;
    this.editModalHandle = this.bsModalService.show(modalTemplate, {
      ignoreBackdropClick: true,
    })
  }

  onRemoveToolDynamicFieldTrigger(trigger: ToolDynamicFieldTriggerViewModel) {
    this.modalService.confirm("Are You Sure You Want Remove This Trigger?", "Remove Trigger").subscribe(res => {
      if (res) {
        this.inProgress = true;
        this.manageInventoryService.removeToolDynamicFieldTrigger(this._dynamicField.id, trigger.id)
          .pipe(finalize(() => { this.inProgress = false }))
          .subscribe(res => {
            this.refreshTriggers();
          });
      }
    })
  }

  onCloseInventoryDynamicFieldTriggerEditor() {
    this.editModalHandle.hide();
    this.refreshTriggers();
  }

}
