import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CompanyUserViewModel, CompanyUserUpdateRequest } from '../models/company-user-view-model';

@Injectable({
  providedIn: 'root'
})
export class CompanyUserService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }
  apiUrl = '/api/companyUser';

  onAddUserToFrequentUserList = (userId: number): Observable<any> =>
  this._http.get<any>(`${this.apiUrl}/add-user-to-frequent-user-list/${userId}`)
    .pipe(catchError(this._errorService.handleError));

  getPermissions = (): Observable<any> =>
    this._http.get<any>(`${this.apiUrl}/get-permissions`)
      .pipe(catchError(this._errorService.handleError));

  getCurrentCompanyUsers = (): Observable<any> =>
    this._http.get<any>(`${this.apiUrl}/get-current-company-users`)
      .pipe(catchError(this._errorService.handleError));

  addUser = (user: CompanyUserViewModel): Observable<CompanyUserViewModel> =>
    this._http.post<CompanyUserViewModel>(`${this.apiUrl}`, user)
      .pipe(catchError(this._errorService.handleError));

  removeUser = (id: number): Observable<any> =>
    this._http.delete<any>(`${this.apiUrl}/${id}`)
      .pipe(catchError(this._errorService.handleError));

  ImportUsers(formData: any): Observable<any> {
    return this._http.post(`/api/CompanyUser/import-users`, formData,
      { reportProgress: false, observe: 'events', headers: { "do-not-modify-content-type": "" } })
      .pipe(catchError(this._errorService.handleError));
  }

  UpdateCompanyUser(model : CompanyUserUpdateRequest) : Observable<any>  {
    return this._http.post<any>(`/api/CompanyUser/update-company-user`, model)
      .pipe(catchError(this._errorService.handleError));
  }
}
