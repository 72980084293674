<div>
  <ng-select style="padding-bottom: 0 !important;" class="supplier-selector" [typeaheadOptionsLimit]="10"
      [typeaheadMinLength]="0" [items]="$assetCategories | async" [placeholder]="'Please Select Asset Category'"
      bindLabel="title" [ngClass]="{'ng-valid' : !formControl.valid, 'valid' : formControl.valid}" bindValue="id"
      [typeahead]="term$" [loading]="loading" [clearable]="!_required" [formControl]="formControl"
      #selector>
      <ng-template ng-option-tmp let-item="item">
          {{item.title}}
      </ng-template>
  </ng-select>
</div>
