<div class="file-input-tmp p-2" *ngIf="sourceType == DropzoneSourceType.URL">
  <div class="row">
    <div class="col-12 col-md-9">
      <div class="d-flex">
        <div class="mr-3">
          <ng-container *ngTemplateOutlet="fileTypeDisplay; context: { source: source, sourceType: DropzoneSourceType.URL }"></ng-container>
        </div>
        <div class="flex flex-column">
          <div>
            <a target="_blank" [href]="source">
              {{getNameFromUrl(source)}}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="d-flex justify-content-end">
        <button
          (click)="removeItem.emit(source)" class="btn btn-sm btn-danger">
          <span class="fa fa-times"></span>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="file-input-tmp p-2" *ngIf="sourceType == DropzoneSourceType.File">
  <div class="row">
    <div class="col-12 col-md-9">
      <div class="d-flex">
        <div class="mr-3">
          <ng-container *ngTemplateOutlet="fileTypeDisplay; context: { source: source, sourceType: DropzoneSourceType.File }"></ng-container>
        </div>
        <div class="flex flex-column">
          <div>{{source.name}}</div>
          <i>{{ getReadableSize(source.size) }}</i>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="d-flex justify-content-end">
        <button class="btn btn-danger" (click)="removeItem.emit(source)">Remove</button>
      </div>
    </div>
  </div>
</div>
<div class="file-input-tmp p-2" *ngIf="sourceType == DropzoneSourceType.Base64">
  <div class="row">
    <div class="col-12 col-md-9">
      <div class="d-flex">
        <div class="mr-3">
          <ng-container *ngTemplateOutlet="fileTypeDisplay; context: { source: source, sourceType: DropzoneSourceType.Base64 }"></ng-container>
        </div>
        <div class="flex flex-column">
          <div>{{source.fileName}}</div>
          <i>{{ getReadableSize(source.fileSize) }}</i>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="d-flex justify-content-end">
        <button
          (click)="removeItem.emit(source)" class="btn btn-sm btn-danger">
          <span class="fa fa-times"></span>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #fileTypeDisplay
             let-url="url"
             let-source="source" let-sourceType="sourceType">
  <ng-container *ngIf="getFileTypeAny(source, sourceType) == FileType.Image; else checkIfIsPDF">
    <a href="" *ngIf="sourceType == 'url'" target="_blank">
      <img [src]="displayFile(source, sourceType)" class="input-image" alt="file">
    </a>
    <img *ngIf="sourceType != 'url'" [src]="displayFile(source, sourceType)" class="input-image" alt="file">
  </ng-container>
  <ng-template #checkIfIsPDF>
    <ng-container *ngIf="getFileTypeAny(source, sourceType) == FileType.Pdf; else checkIfIsAudio">
      <i class="fa fa-file-pdf"></i>
    </ng-container>
  </ng-template>
  <ng-template #checkIfIsAudio>
    <ng-container *ngIf="getFileTypeAny(source, sourceType) == FileType.Audio; else checkIfIsVideo">
      <i class="fa fa-music"></i>
    </ng-container>
  </ng-template>
  <ng-template #checkIfIsVideo>
    <ng-container *ngIf="getFileTypeAny(source, sourceType) == FileType.Video; else checkIfIsText">
      <i class="	fa fa-video-camera"></i>
    </ng-container>
  </ng-template>
  <ng-template #checkIfIsText>
    <ng-container *ngIf="getFileTypeAny(source, sourceType) == FileType.Text; else checkIfIsBinary">
      <i class="fa fa-sticky-note"></i>
    </ng-container>
  </ng-template>
  <ng-template #checkIfIsBinary>
    <i class="fa fa-file-archive"></i>
  </ng-template>
</ng-template>

