import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicFieldFilterWrapperViewModel } from "../../../models/inventory/dynamic-field-filter-model";
import { TextOperator } from "../../../enums/text-operator.enum";

@Component({
  selector: 'obc-dynamic-fields-filter-renderer',
  templateUrl: './dynamic-fields-filter-renderer.component.html',
  styleUrls: ['./dynamic-fields-filter-renderer.component.scss']
})
export class DynamicFieldsFilterRendererComponent implements OnInit {

  @Input() filters: DynamicFieldFilterWrapperViewModel[] = [];
  @Output() removeDynamicField: EventEmitter<number> = new EventEmitter<number>();
  constructor() { }

  ngOnInit(): void {
  }

  hasOperand(operator: TextOperator): boolean {
    return [TextOperator.isNull, TextOperator.isNotNull].indexOf(
      operator
    ) == -1
  }

  onRemoveDynamicFieldFromFilter(dynamicFieldId: number) {
    this.removeDynamicField.emit(dynamicFieldId)
  }
}
