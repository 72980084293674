<div class="card" *ngIf='permitStatusForm && permitId > 0 && nextStatus !=null && siteId > 0'>
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="card-header">
        <span>
            Fill {{permitStatusForm.name}}
        </span>
        <div class="card-actions">
            <a (click)="onHide.emit()" class="card-action card-action-dismiss" data-card-dismiss=""></a>
        </div>
    </div>
    <div class="card-body">
        <div class="row mt-2">
            <div class="col-12">
                <obc-form-data-renderer #formDataRenderer [formType]="permitStatusForm.type" [formId]="permitStatusForm.id"
                    [formName]="permitStatusForm.name" [siteId]="siteId"
                    (validate)="validatePermitStatusForm($event)">
                </obc-form-data-renderer>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <div class="text-left mt-1">
            <button (click)="onUpdatePermitStatus()" [disabled]="!isPermitStatusFormValid"
                class="ml-2 btn btn-sm btn-success">
                Submit
            </button>
        </div>
    </div>
</div>
