<div *ngIf="isXLarge" class="pl-2 " style="min-height: 350px">
  <ng-container *ngTemplateOutlet="XLargeTemplate"></ng-container>
</div>
<div *ngIf="!isXLarge">
  <ng-container *ngTemplateOutlet="LargeTemplate"></ng-container>
</div>

<ng-template #MainPartTemplate>
  <form action="" [formGroup]="formGroup">
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-info d-flex align-items-center mb-2" *ngIf="!displayOnly">
          <i class="fas fa-info mr-3"></i>
          <span>
            <small>
              You are able to change the order of items such as Questions, Documents & Announcements by
              dragging them.
            </small>
          </span>
        </div>
        <div class="d-flex justify-content-between mb-2" *ngIf="!displayOnly">
          <span class="d-flex justify-content-start">
            <span class="bold ml-3">Item(s)</span>
          </span>
          <div class="d-flex justify-content-end">
            <span class="mr-2"
              *ngIf="(selectedDocumentIds.length + selectedAnnouncementIds.length + selectedQuestionIds.length) > 0">
              <button (click)="onRemoveSelected()" class="btn btn-sm btn-danger">
                <i class="fa fa-trash"></i>
                Remove Selected
              </button>
            </span>
            <span class=" mr-2">
              <button (click)="onShowAddQuestionsModal(questionSelector)" class="btn btn-sm btn-info">
                <i class="fa fa-plus"></i>
                Add Question(s)
              </button>
            </span>
            <span class="" *ngIf="showAddDocumentButton">
              <button (click)="onShowAddDocumentsModal(documentSelector)" class="btn btn-sm btn-warning">
                <i class="fa fa-plus"></i>
                Add Document(s)
              </button>
            </span>
            <span class=" ml-2">
              <button (click)="onShowAddAnnouncementsModal(announcementSelector)" class="btn btn-sm btn-success">
                <i class="fa fa-plus"></i>
                Add Announcement(s)
              </button>
            </span>
          </div>
        </div>
        <table class="table table-responsive-md table-striped mb-0">
          <tbody>
            <tr *ngIf="!displayOnly">
              <td *ngIf="!items || items?.length == 0" style="width: 100%; float: none;border-top: none;" colspan="3">
                <p class="text-center mb-0">
                  Please Select Items
                </p>
              </td>
            </tr>
            <tr>
              <td colspan="100">
                <obc-multiple-drag-drop-sortable formControlName="items" *ngIf="showObcSortable"
                  [displayOnly]="displayOnly" [template]="itemsTemplate" (onChangeOrder)="onChangedOrder()"
                  (onScrollEvent)="onScroll($event)">
                </obc-multiple-drag-drop-sortable>
              </td>
            </tr>
            <!-- <tr>
                <td colspan="100">
                  <obc-sortable *ngIf="showObcSortable" formControlName="items" (onChangeOrder)="onChangedOrder()"
                    [template]="itemsTemplate">
                  </obc-sortable>
                </td>
              </tr> -->
          </tbody>
        </table>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #XLargeTemplate>
  <ng-container *ngTemplateOutlet="MainPartTemplate"></ng-container>
</ng-template>

<ng-template #LargeTemplate>
  <accordion class="mt-2">
    <accordion-group [isOpen]="displayOnly">
      <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
        <div class="d-flex flex-row justify-content-between">
          <div class="pull-left float-left">Item(s)</div>
          <div>{{items?.length}} item(s)</div>
        </div>
      </button>
      <ng-container *ngTemplateOutlet="MainPartTemplate"></ng-container>
    </accordion-group>
  </accordion>
</ng-template>

<ng-template #itemsTemplate let-item="item" let-index="index">
  <div class="d-flex pt-2" [ngClass]="index == 0 ? '' : 'border-top'">
    <div class="d-flex justify-content-start" style="width:3%;" *ngIf="!displayOnly">
      <input [(ngModel)]="item.isSelected" type="checkbox">
    </div>
    <div class="d-flex align-items-center" style="width:97%;">
      <!-- Questions -->
      <ng-container *ngIf="item.type == ItemsMixtureTypeEnum.Question">

        <obc-question-extended-title class="flex-fill" [question]="getQuestionById(item.id)">
        </obc-question-extended-title>
        <button *ngIf="!displayOnly" (click)="removeSelectedQuestionConfirmation(item.id)"
          class="pull-right btn btn-xs btn-warning">
          <i class="fas fa-times"></i>
        </button>
      </ng-container>
      <!-- Announcements -->
      <ng-container *ngIf="item.type == ItemsMixtureTypeEnum.Announcement">

        <obc-announcement-item-renderer class="mr-2" [largeButton]="false" [ann]="getAnnouncementById(item.id)"
          [notifyVisits]="false" [anonymousAttendanceKey]="null"></obc-announcement-item-renderer>

        <div *ngIf="displayOnly">
          <i>
            <span>({{item.announcementState | siteAnnouncementForceViewMode}})</span>
          </i>
        </div>

        <obc-announcement-force-view-mode-selector
          *ngIf="!displayOnly"
          [forceViewMode]="item.announcementState"
          [type]="getAnnouncementById(item.id)?.type"
          (onSelect)="updateAnnouncementState(item.id,$event,ItemsMixtureTypeEnum.Announcement)">
        </obc-announcement-force-view-mode-selector>

        <button *ngIf="!displayOnly" (click)="removeSelectedAnnouncementConfirmation(item.id)"
          class="pull-right btn btn-xs btn-warning ml-2">
          <i class="fas fa-times"></i>
        </button>
      </ng-container>
      <!-- Documents -->
      <ng-container *ngIf="item.type == ItemsMixtureTypeEnum.Document">

        <div class="mr-2">
          <i class="fas fa-id-card"></i> {{getDocumentById(item.id)?.title}}
        </div>
        <div *ngIf="displayOnly">
          <i>
            <span>({{item.documentState | optionalMandatory}})</span>
          </i>
        </div>

        <obc-optional-mandatory-selector style="width: 100%;" *ngIf="!displayOnly" [state]="item.documentState"
          (onChangeSelectedState)="updateState(item.id,$event, ItemsMixtureTypeEnum.Document)">
        </obc-optional-mandatory-selector>

        <button *ngIf="!displayOnly" (click)="removeSelectedDocumentConfirmation(item.id)"
          class="pull-right btn btn-xs btn-warning ml-2">
          <i class="fas fa-times"></i>
        </button>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #questionSelector>
  <app-question-selector [companyQuestions]="allQuestions" [addedQuestions]="getAddedQuestions()"
    (addQuestion)="addQuestion($event)" (hide)="onCloseAddQuestionsModal()">
  </app-question-selector>
</ng-template>
<ng-template #documentSelector>
  <app-document-selector [documents]="selectedDocuments" [addedDocuments]="getAddedDocuments()"
                         (addDocument)="addDocument($event)" (hide)="onCloseAddDocumentsModal()">
  </app-document-selector>
</ng-template>
<ng-template #announcementSelector>
  <app-announcement-selector [announcements]="allAnnouncements" [addedAnnouncements]="getAddedAnnouncements()"
    (addAnnouncement)="addAnnouncement($event)" (hide)="onCloseAddAnnouncementsModal()">
  </app-announcement-selector>
</ng-template>
