import { MaxFileSize } from '../../../enums/constants';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getFileExtenstion, isValidImageFile, toBase64 } from 'src/app/helpers/general-functions';
import { UpdateCompanyProfileModel } from 'src/app/models/company-profile-viewmodel';
import { CountryService } from 'src/app/services/country.service';
import { Timezone } from 'src/app/models/time-zone';
import { CompanyService } from 'src/app/services/company.service';
import { Subject } from 'rxjs';
import { ModalService } from 'src/app/services/modal.service';
import { emailListValidator } from 'src/app/helpers/email-list-validator';
import { UserAutoCompleteFilterByFields } from 'src/app/enums/user-auto-complete-target-fields';
import { CompanySiteContactInformationModel } from "../../../models/company-site-contact-information.view.model";
import {
  e168ValidatorNullableCompanyPhoneNumber,
} from "../../../helpers/mobile-format-validator";
import {UserService} from "../../../services/user.service";
import {ProductPlanFeature} from "../../../enums/product-plan-feature.enum";

@Component({
  selector: 'obc-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.css']
})
export class CompanyProfileComponent implements OnInit {
  errorMessage: string = null;
  profileFormGroup: FormGroup;
  @Input() inProgress$: Subject<boolean>;
  croppedImage: string;
  imageSourceBase64: string;
  timezones: Timezone[];
  imageChange: boolean = false;
  fileType: string;
  filterUserBy = UserAutoCompleteFilterByFields;

  constructor(private companyService: CompanyService, private countryService: CountryService,
    private modalService: ModalService, public userService: UserService) { }

  get companyHasFormFeature() {
    return this.userService.hasCompanyFeature(ProductPlanFeature.Form);
  }
  ngOnInit() {
    this.inProgress$.next(true);
    this.countryService.getTimeZones().subscribe(res => this.timezones = res);
    this.companyService.getCurrentCompanyProfile().subscribe(res => {

      this.profileFormGroup = new FormGroup({
        title: new FormControl(res.title, [Validators.required]),
        description: new FormControl(res.description),
        timezone: new FormControl(res.timezoneId, [Validators.required]),
        profileImageBase64: new FormControl(res.profileImageBase64),
        companySupervisorEmailAddress: new FormControl(res.companySupervisorEmailAddress, [emailListValidator]),
        contactUserId: new FormControl(res.contactUserId),
        shareDataWithVicVisitation: new FormControl(res.shareDataWithVicVisitation),
        // forceViewSupplierDocumentsBeforeSignOff: new FormControl(res.forceViewSupplierDocumentsBeforeSignOff),
      });
      this.inProgress$.next(false);
    });
  }
  
  get imageSource(): string {
    if (this.profileFormGroup === null || this.profileFormGroup.get("profileImageBase64").value == null)
      return null;
    let src = this.profileFormGroup.get("profileImageBase64").value as string;
    if (src.startsWith("http://") || src.startsWith("https://"))
      return src;
    else
      return `data:image/png;base64,${src}`
  }

  async onPickImage($event) {
    let file = $event.target.files[0];
    if (!isValidImageFile(file.type)) {
      this.modalService.error("The selected file must be a valid type of image").subscribe(_ => { });
      $event.target.value = null;
      return;
    }
    if (file.size > MaxFileSize * 1024000) {
      this.modalService.error(`Maximum File Size is ${MaxFileSize}mb`, "company profile image").subscribe(_ => { });
      $event.target.value = null;
      return;
    }
    this.imageSourceBase64 = (await toBase64(file)) as string;
    this.imageChange = true;
    this.fileType = getFileExtenstion(file.name);
  }

  onSaveProfileChanges() {
    this.errorMessage = null;
    let source = null;
    if (this.croppedImage != null && this.imageChange) {
      source = this.croppedImage;
    }

    let model = {
      title: this.profileFormGroup.get("title").value,
      description: this.profileFormGroup.get("description").value,
      timezoneId: parseInt(this.profileFormGroup.get("timezone").value),
      profileImageBase64: source,
      fileType: this.fileType,
      companySupervisorEmailAddress: this.profileFormGroup.get("companySupervisorEmailAddress").value,
      contactUserId: +this.profileFormGroup.get("contactUserId").value,
      shareDataWithVicVisitation: this.profileFormGroup.get("shareDataWithVicVisitation").value,
      // forceViewSupplierDocumentsBeforeSignOff: this.profileFormGroup.get("forceViewSupplierDocumentsBeforeSignOff").value as boolean,
    } as UpdateCompanyProfileModel;

    if (model.shareDataWithVicVisitation && !model.contactUserId) {
      this.modalService.error("You Must Select Company Contact");
      return;
    }
    this.inProgress$.next(true);
    this.companyService.updateCurrentCompanyProfile(model).subscribe(_ => {
      this.inProgress$.next(false);
      if (source != null)
        this.imageSourceBase64 = `data:image/png;base64,${source}`;
      this.modalService.success("Company setting updated successfully").subscribe((_) => {
        if (model.profileImageBase64 != null) {
          window.location.reload();
        }
      });
    }, err => {
      this.inProgress$.next(false);
      this.errorMessage = err;
      setTimeout(() => {
        this.errorMessage = null;
      }, 5000);
    }, () => {
      this.imageChange = false;
    });
  }

  imageCropped($event) {
    this.croppedImage = $event.base64.split(';base64,')[1];
  }

  loadImageFailed() {
    this.modalService.error("unable to load image", "company profile image").subscribe(_ => {
      //alert("unable to load image");
    });
  }

}
