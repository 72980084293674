<div class="card">
  <div class="card-header">
    Upload {{documentKindTitle}} Documents
    <div class="card-actions">
      <a (click)="hide.emit()" class="card-action card-action-dismiss" data-card-dismiss=""></a>
    </div>
  </div>
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="row">
      <div class="col-md-12 mt-2">
        <input *ngIf="showFileInput" multiple #input accept=".png, .jpg, .jpeg, application/pdf"
          style="height: 43px; border-bottom-right-radius: 0; border-top-right-radius: 0;" class="form-control"
          type="file">
      </div>
      <div class="col-md-12 mt-1" *ngIf="showFileInput && selectedFiles?.length">
        <obc-sortable [ngModel]="selectedFiles" [template]="fileTemplate" (onChangeOrder)="onChangeFilesOrder($event)">
        </obc-sortable>
      </div>
      <ng-container [formGroup]="formGroup">
        <div class="col-md-12 mt-1">
          <label>Version Date</label>
          <obc-input-date-time-picker formControlName="versionDate" [(ngModel)]="currentDate"
            [mode]="DatetimePickerMode.Calender">
          </obc-input-date-time-picker>

          <!-- <input [owlDateTime]="dt1" class="form-control" formControlName="versionDate" [owlDateTimeTrigger]="dt1"
            [(ngModel)]="currentDate">
          <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time> -->
        </div>
        <ng-container *ngIf="variableList?.length > 0">
          <div class="col-md-12 mt-1" [formGroup]="variableFormGroups">
            <ng-container *ngFor="let variable of variableList">
              <label> {{variable.title}} </label>
              <ng-container *ngIf="variable.type==VariableDefinitionType.Date">
                <obc-input-date-time-picker [formControlName]="variable.key" [mode]="DatetimePickerMode.Calender">
                </obc-input-date-time-picker>
              </ng-container>
              <ng-container *ngIf="variable.type==VariableDefinitionType.Unknown">
                <input [formControlName]="variable.key" class="form-control" type="text">
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
        <div class="col-md-12 mt-1">
          <label>Title</label>
          <input formControlName="siteSupplierDocumentTypeTitle" class="form-control" type="text">
        </div>
        <div class="col-md-12 mt-1">
          <label>Description</label>
          <input formControlName="siteSupplierDocumentTypeDescription" class="form-control" type="text">
        </div>
      </ng-container>
      <div class="col-md-12 mt-1">
        <div class="d-flex flex-row flex-row-reverse">
          <button class="btn btn-primary m-1" [disabled]="!formIsValid()" (click)="onAddSDTVersion()">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #fileTemplate let-item="item" let-index="index">
  <div class="col-md-12 d-flex flex-row mt-1">
    <div class="flex-fill">{{item.initData.filename}} [{{item.initData.length}} bytes]</div>
    <div>
      <div class="text-right">
        <button (click)="onRemoveFile(item.initData)" class="btn btn-danger m-1">Remove</button>
      </div>
    </div>
  </div>
</ng-template>