<div *ngIf="filledFormDataId == null" class="text-danger">
  Form Data Id is needed
</div>

<ng-container *ngIf="filledFormDataId != null">

  <ng-template *ngIf="displayAsModal == false" [ngTemplateOutlet]="bodyTemplate"></ng-template>

  <ng-container *ngIf="displayAsModal">
    <button class="btn btn-info {{ classes }}" (click)="displayModal(bodyTemplate)">
      <span *ngIf="!noIcon" class="fa fa-edit"></span>
      Edit
    </button>
  </ng-container>
</ng-container>

<ng-template #bodyTemplate>
  <obc-loader [show]="inProgress"></obc-loader>
  <obc-form-data-renderer [filledFormDataId]="filledFormDataId" [innerSubmitButton]="true"
    (innerSubmitButtonCallback)="updateFilledForm($event)" (validate)="validateForm($event)"
    (onCloseButtonClicked)="closeModal()" [displayCloseBtn]="true"
    (editFormInformationModel)="updateFormInformationModel($event)"
  >
  </obc-form-data-renderer>
</ng-template>
