import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  constructor() { }

  public get defaultLocale(): string {
    //return 'en-AU';
    let userSettingLanguage = false;
    return (userSettingLanguage ? 'readme from user setting' :
      (Intl?.DateTimeFormat()?.resolvedOptions()?.locale ? Intl.DateTimeFormat().resolvedOptions().locale :
        (navigator.language ? navigator.language : 'en-AU')));
  };

}
