import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EditableItem } from 'src/app/models/editable-item';
import { ClientAnnouncementViewModel } from 'src/app/models/announcement-viewmodel';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { FileStorageService } from 'src/app/services/file-storage.service';
import { FileType } from 'src/app/enums/file-type.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileCategory } from 'src/app/enums/file-category.enum';
import { contentAccepts } from 'src/app/enums/accept-type.enum';
import { AnnouncementDisplayModeEnum } from 'src/app/enums/announcement-display-mode.enum';
import { urlValidator } from 'src/app/helpers/url-validator';
import { ModalService } from '../../../services/modal.service';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { noop, Observable, Observer, of } from 'rxjs';
import { SasUploadService } from '../../../services/sas-store';
import { AskMode } from 'src/app/enums/ask-mode';
import { error } from 'console';

@Component({
  selector: 'obc-create-announcement',
  templateUrl: './create-announcement.component.html',
  styleUrls: ['./create-announcement.component.css']
})
export class CreateAnnouncementComponent implements OnInit {

  defaultFileType: FileType = FileType.Pdf;
  @Input() whiteListFileTypes: FileType[] = [];
  announcementDisplayMode = AnnouncementDisplayModeEnum;
  inProgress: boolean = false;
  announcementType = FileType;
  askMode = AskMode;
  uploadSuccess = true;
  reseted = true;
  announcementGroups$: Observable<string[]>;
  announcementGroupLoader: boolean = false;
  _model: EditableItem<ClientAnnouncementViewModel> = null;
  @Input() set model(model: EditableItem<ClientAnnouncementViewModel>) {
    this._model = model;
    if (this._model == null) {
      this.createNewEditableObject();
    } else {
      if (this.isFileModel(model.model.type))
        model.formGroup.controls["type"].disable();
    }
    this._model.mode = "edit";
  };
  @Input() isActiveStatusChangable?: boolean = true;
  @Output() announcementCreated: EventEmitter<ClientAnnouncementViewModel> = new EventEmitter();
  @Output() announcementUpdated: EventEmitter<ClientAnnouncementViewModel> = new EventEmitter();
  @Output() onTypeChanged: EventEmitter<FileType> = new EventEmitter();
  constructor(
    private announcementService: AnnouncementService,
    private fileStorageService: FileStorageService,
    private modalService: ModalService,
    private sasUploadService: SasUploadService
  ) { }

  ngOnInit() {
    this.getAnnouncementGroups();
    this._model.formGroup.get('type').valueChanges?.subscribe((res) => {
      this.onTypeChanged.emit(res);
    })
    this.onTypeChanged.emit(this.defaultFileType);
  }

  private init() {
    this.createNewEditableObject();
    this.getAnnouncementGroups();
  }

  private createNewEditableObject() {
    var validators = {
      'title': [Validators.required]
    };
    this._model = new EditableItem({
      announcementId: null,
      title: null,
      url: null,
      data: null,
      type: this.defaultFileType,
      askMode: this.askMode.AnyTime,
      displayMode: this.announcementDisplayMode.Button,
      isActive: true,
      file: null,
      progress: "0",
      groupName: "",
    } as ClientAnnouncementViewModel, validators);
  }

  private callbackResult(model: ClientAnnouncementViewModel) {
    // send call back response
    this.announcementUpdated.emit(model);
    this.announcementCreated.emit(model);
    this.uploadSuccess = true;
    this.resetForm();
  }

  private resetForm() {
    this.reseted = false;
    setTimeout(() => { this.reseted = true; }, 100);
  }

  saveChanges(editableModel: EditableItem<ClientAnnouncementViewModel>) {
    this.inProgress = true;
    var model = editableModel.currentModel;

    var uploadEventHandler = (event) => {
      if (this.isFileModel(model.type)) {
        let progress = Math.round(100 * event.loaded / event.total);
        editableModel.formGroup.get('progress').setValue(`${progress}%`);
      }
    };

    var errorEventHandler = (err) => {
      this.modalService.error(err, "Unable to save changes");
      this.inProgress = false;
    }

    this.inProgress = true;
    if (model.file != null) { // filedata
      var data = model.file.get('file') as File;
      var signedUploadInfoModel = {
        filename: data.name,
        fileCategory: FileCategory.AnnouncementFile,
      };
      this.fileStorageService.getSignedUploadInfo(signedUploadInfoModel)
        .subscribe({
          next: (uploadInfo) => {
            if (uploadInfo == null) {
              errorEventHandler("Unable get file url");
              this.inProgress = false
              this.uploadSuccess = false;
            }
            else {
              this.sasUploadService.upload(data, uploadInfo, uploadEventHandler)
                .then(
                  (uploadResult) => {
                    let isSuccessUpload = uploadResult?._response && uploadResult._response.status >= 200 && uploadResult._response.status < 300;
                    if (isSuccessUpload) {
                      model.dataStorageKey = uploadInfo.storageKey;
                      model.file = null;
                      this.inProgress = true;
                      if (model.announcementId != null) {
                        this.announcementService.update(model.announcementId, model).subscribe(res => {
                          this.callbackResult(model);
                        }, err => {
                          this.inProgress = false;
                          this.modalService.error(err, "Unable to update");
                        }, () => {
                          this.inProgress = false;
                          this.init();
                        })
                      } else {
                        this.announcementService.add(model).subscribe(res => {
                          model.announcementId = res;
                          this.callbackResult(model);
                        }, err => {
                          this.inProgress = false;
                          this.modalService.error(err, "Unable to add");
                        }, () => {
                          this.inProgress = false;
                          this.init();
                        })
                      }

                    } else {
                      this.modalService.error("Unable to upload file", "Upload File");
                    }
                  }
                  ,
                  _ => {
                    errorEventHandler("Unable to save changes");
                    this.inProgress = false
                    this.uploadSuccess = false;
                  },
                )
            }
          },
          error: (err) => {
            errorEventHandler(err);
          }
        });
    } else { // text data
      model.file = null;
      model.dataStorageKey = null;
      if (model.announcementId) {
        this.announcementService.update(model.announcementId, model).subscribe(res => {
          this.callbackResult(model);
        }, err => {
          this.inProgress = false;
          this.modalService.error(err, "Unable to update");
        }, () => {
          this.inProgress = false;
          this.init();
        })
      } else {
        this.announcementService.add(model).subscribe(res => {
          model.announcementId = res;
          this.callbackResult(model);
        }, err => {
          this.inProgress = false;
          this.modalService.error(err, "Unable to add");
        }, () => {
          this.inProgress = false;
          this.init();
        })
      }

    }
    // if (model.id) {
    //   model.file = null;
    //   model.dataStorageKey = null;
    //   this.announcementService.update(model.id, model).subscribe(res => {
    //     this.callbackResult(model);
    //   }, err => {
    //     this.inProgress = false;
    //     this.modalService.error(err, "Unable to update");
    //   }, () => {
    //     this.inProgress = false;
    //     this.init();
    //   })
    // }
    // else  // add
    // {

    // }
  }

  typeLabels = {
    0: "PDF File",
    1: "Video",
    2: "Text",
    3: "Audio",
    4: "Youtube Video",
    5: "Image",
    6: "External Link",
  };

  get types(): number[] {
    var ctype = this._model.formGroup.get('type').value;
    if (this.isUpdateMode)
      return this.textTypes.indexOf(ctype) >= 0 ? this.textTypes : this.fileTypes;
    else {
      if (this.whiteListFileTypes?.length > 0) {
        return this.fileTypes.filter((item) => this.whiteListFileTypes.includes(item));
      }
      return this.textTypes.concat(this.fileTypes);
    }
  }

  typeExtensions = {
    0: ["pdf"],
    1: ["ogm", "wmv", "mpg", "webm", "ogv", "mov", "asx", "mpeg", "mp4", "m4v", "avi"],
    3: ["opus", "oga", "flac", "webm", "weba", "wav", "ogg", "m4a", "mp3", "mid", "amc", "aiff", "wma", "au", "aac"],
    5: ["xbm", "tif", "pjp", "pjpeg", "svg", "jpg", "jpeg", "ico", "tiff", "git", "svg", "bmp", "png", "jfif", "webp"]
  };

  getValidFileExtensions(): string {
    return (this.typeExtensions[this._model.formGroup.get('type').value] as [])
      .map((e) => '.' + e)
      .join(',');
  }

  isSelectedFileCompatibleWithType(formGroup: FormGroup) {
    var file = formGroup.get('file').value.get('file') as File;
    if (file == null) return true;
    var extension = file.name.split(".").reverse()[0].toLowerCase();
    var type = formGroup.get('type').value;
    var extensions = this.typeExtensions[type];
    if (extensions.indexOf(extension) > -1)
      return true;
    return false;
  }

  fileTypes: FileType[] = [
    FileType.Audio,
    FileType.Image,
    FileType.Pdf,
    FileType.Video
  ];

  textTypes: FileType[] = [
    FileType.ExternalLink,
    FileType.Text,
    FileType.Youtube
  ];


  isDataModel(type: number) {
    if (this.textTypes.indexOf(type) >= 0)
      return true;
    return false;
  }



  isFileModel(type: number) {
    if (this.fileTypes.indexOf(type) >= 0)
      return true;
    return false;
  }



  onFileChange($event: any, editableModel: EditableItem<ClientAnnouncementViewModel>) {
    if (!$event.target.files) return;
    this.resetTemporyProperties(editableModel);
    const formData = new FormData();
    var file = $event.target.files[0];
    formData.append('file', file, file.name);
    editableModel.formGroup.get('file').setValue(formData);
  }

  onAdd(editableModel: EditableItem<ClientAnnouncementViewModel>) {
    if (this.validateModel(editableModel))
      this.saveChanges(editableModel);
  }

  onDiscardChanges(editableModel: EditableItem<ClientAnnouncementViewModel>) {
    editableModel.revertToSavedState();
    editableModel.mode = "view";
    this.announcementUpdated.emit(null);
  }

  validateModel(editableModel: EditableItem<ClientAnnouncementViewModel>): boolean {
    var currentModel = editableModel.currentModel;
    if (this.isFileModel(currentModel.type) && (currentModel.file == null && (currentModel.announcementId == null || currentModel.announcementId == 0))) {
      this.modalService.warn("The Type of Announcement selected requires a file to be uploaded in the Data / Link Section. Please upload a file!", "Required");
      return false;
    }
    else if (this.isDataModel(currentModel.type) && !currentModel.data) {
      this.modalService.warn("The Type of Announcement selected requires an external link in the Data / Link Section. Please enter text or a URL!", "Required");
      return false;
    }
    else if (currentModel.type == this.announcementType.Youtube ||
      currentModel.type == this.announcementType.ExternalLink) {
      var isvalid = new FormControl(currentModel.data, [urlValidator]).valid;
      if (!isvalid) {
        this.modalService.warn("URL is not in correct format(https://domain.com/...) !", "Valid Format");
        return false;
      }
    }

    return true;
  }

  resetTemporyProperties(editableModel: EditableItem<ClientAnnouncementViewModel>) {
    editableModel.model.file = null;
    editableModel.model.progress = "0";
    if (!editableModel.formGroup.get('file'))
      editableModel.formGroup.addControl('file', new FormControl(null));
    if (!editableModel.formGroup.get('progress'))
      editableModel.formGroup.addControl('progress', new FormControl("0"));
  }

  getFileInputAccept(type: number) {
    return contentAccepts[type];
  }

  get isUpdateMode(): boolean {
    return this._model.model.announcementId > 0;
  }
  getAnnouncementGroups() {
    this.announcementGroups$ = new Observable((observer: Observer<string>) => {
      observer.next(this._model.formGroup.get('groupName').value);
    }).pipe(
      switchMap((groupName: string) => {
        if (groupName) {
          this.announcementGroupLoader = true;
          return this.announcementService.getAnnouncementGroups(groupName).pipe(
            map((data: string[]) => (data && data) || []),
            tap(
              () => noop,
              (err) => {
                var rrr = (err && err.message) || 'Something goes wrong';
              }
            ),
            finalize(() => {
              this.announcementGroupLoader = false;
            })
          );
        }

        return of([]);
      })
    );
  }
  onSelectAnnouncementGroup(event) {
    this._model.formGroup.get('groupName').setValue(event.value);
  }

}
