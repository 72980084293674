import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InductionModel } from 'src/app/models/induction-viewmodel';
import { InductionService } from 'src/app/services/induction-service';
import { finalize } from 'rxjs/operators';
import { SiteAttendanceInductionViewModel } from 'src/app/models/site-attendance-induction-view-model';
import { ModalService } from 'src/app/services/modal.service';
import { FormService } from 'src/app/services/form.service';
import { FormModel } from 'src/app/models/form/form-model';
import { FormDataViewModel } from 'src/app/models/form/form-data-view-model';

@Component({
  selector: 'obc-preview-upsert',
  templateUrl: './preview-upsert.component.html',
  styleUrls: ['./preview-upsert.component.scss']
})
export class PreviewUpsertComponent implements OnInit {

  @Input() previewType: 'induction' | 'form';
  @Input() inductionModel: InductionModel = <InductionModel>{};
  @Input() formModel: FormModel = <FormModel>{};
  @Input() modalHeader: string = '';
  @Output() public hide = new EventEmitter();

  inductionViewModel: SiteAttendanceInductionViewModel = <SiteAttendanceInductionViewModel>{};
  formViewModel: FormDataViewModel = <FormDataViewModel>{};
  inProgress: boolean;

  constructor(private inductionService: InductionService,
    private formService: FormService,
    private modalService: ModalService) { }

  ngOnInit(): void {

    switch (this.previewType) {
      case 'induction': {

        this.inProgress = true;
        this.inductionService.previewInduction(this.inductionModel)
          .pipe(finalize(() => {
            this.inProgress = false;
          }))
          .subscribe(res => {
            if (res.success) {
              this.inductionViewModel = res.data;
            } else {
              this.modalService.error(res.message);
            }
          }, _ => {
            this.modalService.error("Request Failed");
            this.inProgress = false;
          }, () => {
            this.inProgress = false;
          });

          break;
      }
      case 'form': {
        
        this.inProgress = true;
        this.formService.previewForm(this.formModel)
          .pipe(finalize(() => {
            this.inProgress = false;
          }))
          .subscribe(res => {
            if (res.success) {
              this.formViewModel = res.data;
            } else {
              this.modalService.error(res.message);
            }
          }, _ => {
            this.modalService.error("Request Failed");
            this.inProgress = false;
          }, () => {
            this.inProgress = false;
          });
        break;
      }
    }
  }

}
