<div class="card">
  <div class="card-header">
    {{title}}
    <button type="button" class="close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <table class="table table-responsive-md table-striped mb-0 obc-table">
          <thead>
            <tr>
              <th>
                Induction
              </th>
              <th> <span class="pull-right">Add</span> </th>
            </tr>
            <tr>
              <td colspan="2">
                <input type="text" class="form-control" placeholder="Search in Inductions" [(ngModel)]="filterTerm" />
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div class="row" style="padding-right: 15px;">
      <div class="col-md-12 scrollable-list" style="max-height: 400px;overflow-y: scroll;">
        <table class="table table-responsive-md table-striped mb-0 obc-table">
          <tbody>
            <tr *ngIf="!availableInductions || availableInductions.length == 0">
              <td colspan="2" style="width: 100%; float: none;border-top: none;">
                <p class="text-center mb-0">There are currently no more available site inductions to add to this site
                </p>
              </td>
            </tr>
            <tr *ngFor="let queze of (availableInductions | filter:filterTerm:['title'])">
              <td>
                <i class="fas fa-id-card"></i> {{queze?.title}}
                <span *ngIf="queze?.inductionKey" style="font-size: small;"> ({{queze.inductionKey}}) </span>
              </td>
              <td>
                <button class="pull-right btn btn-xs btn-info" (click)="onAddInduction(queze.id)">
                  <i class="fas fa-plus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>