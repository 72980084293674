export enum ObcEventLogTypeEnum {
    UserActivity = 0,
    UpdateUserProfile = 1,
    NewCard = 2
}
export enum UserProfileField {
    CompanySupplierId = 0,
    VisitorTypeId = 1,
    FirstName = 2,
    LastName = 3,
    Email = 4,
    ProfileImage = 5,
    DateOfBirth = 6,
    EmergencyContact = 7,
    Gender = 8,
}
export enum UserActivityEventType {
    CheckIn = 100,
    FinaliseCheckIn = 101,
    HostCheckIn = 102,
    CheckOut = 103,
    FinaliseCheckOut = 104,
    HostCheckOut = 105,
    SubmitInduction = 106,
    SignOffSiteBriefing = 107,
    SignOffSupplierDocument = 108,
    PreCheckIn = 109,
    FillForm = 110,
}