import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermitHistoryViewModel } from 'src/app/models/permit/permit-history-view-model';
import { ModalService } from 'src/app/services/modal.service';
import { PermitService } from 'src/app/services/permit.service';

@Component({
  selector: 'obc-permit-history',
  templateUrl: './permit-history.component.html',
  styleUrls: ['./permit-history.component.scss']
})
export class PermitHistoryComponent implements OnInit {
  @Input() permitId: number;
  @Output() onHide = new EventEmitter();
  inProgress: boolean = false;
  permitHistory: PermitHistoryViewModel;
  generalFormDataId: any;
  constructor(private permitService: PermitService, private modalService: ModalService) { }

  ngOnInit(): void {
    this.fetchHistory();
  }

  fetchHistory() {
    this.permitService.permitHistory(this.permitId)
      .subscribe(res => {
        this.permitHistory = res;
      }, err => {
        this.modalService.error("Failed to load history");
      }, () => {
        this.inProgress = false;
      })
  }

  openModal(template, generalFormDataId) {
    this.generalFormDataId = generalFormDataId;
    this.modalService.show(template, "modal-lg");
  }
  closeModal() {
    this.modalService.hide();
  }
}

