import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { cleanAndGetField, WorkerProfileViewModel } from 'src/app/models/userProfile/worker-profile-view-model';
import { ModalService } from 'src/app/services/modal.service';
import { WorkerProfileService } from 'src/app/services/worker-profile.service';

@Component({
  selector: 'obc-work-profile-tab',
  templateUrl: './work-profile-tab.component.html',
  styleUrls: ['./work-profile-tab.component.scss']
})
export class WorkProfileTabComponent implements OnInit {

  showTab = [1];
  @Input() siteId: number | null = null;
  @Input() selectedSites: number[] = null;

  get calculatedSelectedSites() {
    // the default value foe seletedSites can be null as well I guess?
    return this.selectedSites ?? [];
  }
  _userId;
  showPanel = true;
  isLoading: boolean = true;
  profile: WorkerProfileViewModel | undefined;
  cleanedFields: any[] = [];
  errorMessage: string | null = null;

  @Input() set userId(userId: number) {
    this._userId = userId;
    if (userId) {
      this.loadWorkerProfile();
    }
  }

  @ViewChild('tabset') tabset: TabsetComponent;

  @Input() isModalTab: boolean = false;

  constructor(private workerProfileService: WorkerProfileService, private modalService: ModalService) { }
  ngOnInit(): void {

  }

  sortAndShow() {
    this.tabset.tabs.sort((first: TabDirective, second: TabDirective) => { return (first.id > second.id) ? 1 : -1 });
    this.showTab = this.isModalTab ? [1, 0] : [0, 1]; // Because of first tab (User Info) is hidden in non-modal view
  }

  private loadWorkerProfile(): void {
    if (!this._userId) {
      console.error('User ID is undefined. Cannot load worker profile.');
      return;
    }

    this.isLoading = true;
    this.errorMessage = null;
    this.workerProfileService.loadWorkerProfile(this._userId).subscribe({
      next: (profile: any) => {
        this.profile = profile;
        this.cleanedFields = cleanAndGetField(profile);  // Get cleaned fields
        this.isLoading = false;
        this.sortAndShow();
      },
      error: (error) => {
        this.isLoading = false;
        this.errorMessage = "loading user detail fail, please try again";
      }
    });
  }

}

