<div class="card">
  <div class="card-header">
    {{title}}
    <div class="card-actions">
      <a (click)="hide.emit()" class="card-action card-action-dismiss" data-card-dismiss=""></a>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <table class="d-table table table-responsive-md table-striped mb-0 obc-table">
          <thead>
            <tr>
              <th>
                Announcement
              </th>
              <th> <span class="pull-right">Add</span> </th>
            </tr>
            <tr>
              <td colspan="2">
                <input type="text" class="form-control" placeholder="Search in Announcements"
                  [(ngModel)]="filterTerm" />
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div class="row" style="padding-right: 15px;">
      <div class="col-12 pr-0 scrollable-list" style="max-height: 400px;overflow-y: scroll; overflow-x: hidden">
        <table class="table table-responsive-md table-striped mb-0 obc-table">
          <tbody>
            <tr *ngIf="!availableAnnouncements || availableAnnouncements.length == 0">
              <td colspan="3" style="width: 100%; float: none;border-top: none;">
                <p class="text-center mb-0">There is no announcement to add</p>
              </td>
            </tr>
            <tr *ngFor="let announcement of (availableAnnouncements | filter:filterTerm:['title'])">
              <td colspan="3">
                <div class="row">
                  <div class="col-12 col-md-5">
                    <obc-announcement-item-renderer [largeButton]="false" [ann]="announcement" [notifyVisits]="false"
                                                    [anonymousAttendanceKey]="null"></obc-announcement-item-renderer>
                  </div>
                  <div class="col-12 col-md-5">
                    <obc-announcement-force-view-mode-selector
                      [forceViewMode]="announcement.forceViewMode"
                      [type]="announcement.type"
                      (onSelect)="setState(announcement, $event)">
                    </obc-announcement-force-view-mode-selector>
                  </div>
                  <div class="col-12 col-md-2">
                    <button class="pull-right btn btn-xs btn-info" (click)="onAdd(announcement)">
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
