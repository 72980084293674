import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ItemTitleValue } from 'src/app/models/helper-models';
import { regexLists } from 'src/app/helpers/general-lists';
import { TextRenderType } from 'src/app/enums/text-render-type';
@Component({
  selector: 'obc-question-text',
  templateUrl: './question-text.component.html',
  styleUrls: ['./question-text.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class QuestionTextComponent implements OnInit {
  showRegexInput: boolean;
  questionParam: any;
  regexItems: ItemTitleValue[] = regexLists;
  TextRenderType = TextRenderType;

  @Input() set questionParamInput(_questionParamInput: any) {
    if (!_questionParamInput) {
      this.onTextModeChanged(this.regexItems.find(c => c.itemTitle === "General").id); //set general mode in init
      this.onTextRenderModeChanged(TextRenderType.SingleLine); //set single line render mode in init
      return;
    }
    else {
      this.questionParam = _questionParamInput;

      delete this.questionParam['items'];

      if (!this.questionParam['regexType']) {
        var regexId = this.regexItems.find(r => r.itemValue == this.questionParam['regex'])?.id ?? 1; //set general mode in init
        this.onTextModeChanged(regexId);
      }

      this.showRegexInput = this.questionParam['regex']?.length > 0 && (!this.regexItems.some(r => r.itemValue == this.questionParam['regex']) || this.questionParam['regex'] == "(.*?)");
      // this.questionParam.questionParameters = JSON.stringify(this.question.questionParametersAsString);
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  onTextRenderModeChanged(item) {
    this.questionParam['textRenderMode'] = item.target.value;
  }

  onTextModeChanged(newItemId) {
    var item = this.regexItems.find(r => r.id == newItemId);
    this.questionParam['regex'] = '';
    this.questionParam['regexType'] = item.id;
    this.questionParam['regex'] = item.itemValue;
    this.questionParam['textRenderMode'] = TextRenderType.SingleLine; // set defualt mode 'singleLine'
    this.showRegexInput = newItemId == 0;
  }

}
