import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InductionViewModel } from 'src/app/models/induction-viewmodel';
import { UserDocumentType } from 'src/app/models/user-document-type';

@Component({
  selector: 'app-induction-selector',
  templateUrl: './induction-selector.component.html',
  styleUrls: []
})
export class InductionSelectorComponent implements OnInit {
  title = "Select Document(s)";
  filterTerm = null;
  inductions :  InductionViewModel[] = [];
  addedInductions : number[] = [];
  addInduction : Function;
  get availableInductions(): InductionViewModel[] {
    return this.inductions.filter(function (x) { return this.indexOf(x.id) < 0 }, this.addedInductions);
  };
  constructor(public bsModalRef: BsModalRef,) { }

  ngOnInit() {
  }

  onAddInduction(id: number) {
    var induction = this.availableInductions.find(x=> x.id == id);
    if(induction && this.addInduction)
    {
      this.addedInductions.push(id);
      this.addInduction(induction);
    } 
  }

}
