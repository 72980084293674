import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { AssetOperatorMode } from 'src/app/enums/asset-operator-mode.enum';
import { ManageSiteAssetMode } from 'src/app/enums/manage-site-asset-mode.enum';
import { RegisterAssetAsType, RegisterAssetMode } from 'src/app/enums/register-asset-mode.enum';
import { GeneralAnswerModel, UniqueGeneralAnswerModel } from 'src/app/models/form/general-answer-model';
import { TemporarilyToolModel, ToolModel } from 'src/app/models/inventory/tool-view-model';
import { SiteAssetRegistrationModel } from 'src/app/models/site-asset/site-asset-registration-model';
import { BaseSiteViewModel } from 'src/app/models/site-viewmodel';
import { ModalService } from 'src/app/services/modal.service';
import { SiteAssetService } from 'src/app/services/site-asset.service';
import { FormType } from '../../../enums/form-type';
import { FormViewModel } from '../../../models/form/form-view-model';
import { FormDataRendererComponent } from '../../form-manage/form-data-renderer/form-data-renderer.component';
import { UpsertInventoryAssetComponent } from '../../inventory/upsert-inventory-asset/upsert-inventory-asset.component';
import { SiteSupplierInfoFull } from 'src/app/models/supplier-document/site-supplier-info';
import { UserService } from 'src/app/services/user.service';
import { SupplierDocumentService } from 'src/app/services/supplier-document.service';

@Component({
  selector: 'obc-register-site-asset',
  templateUrl: './register-site-asset.component.html',
  styleUrls: ['./register-site-asset.component.scss']
})

export class RegisterSiteAssetComponent implements OnInit {

  RegisterAssetAsType = RegisterAssetAsType;
  registerAssetAs: RegisterAssetAsType = RegisterAssetAsType.Company;
  siteSuppliers: SiteSupplierInfoFull[] = [];
  selectedSupplier: SiteSupplierInfoFull = null;



  RegisterAssetMode = RegisterAssetMode;
  registerAssetMode: RegisterAssetMode = RegisterAssetMode.SelectAsset;
  formType = FormType;
  assetRegistrationForms: FormViewModel[];
  selectedForm: FormViewModel = null;
  inProgress: boolean;
  isFormValid: boolean;

  @Input() supplierId: number;
  @Input() site: BaseSiteViewModel;
  @Input() siteCompanyTitle: string;
  @Input() siteCompanyId: number;
  @Input() manageSiteAssetMode: ManageSiteAssetMode = ManageSiteAssetMode.MainCompany;
  @Input() externalSiteSupplierId: number;
  @Input() supplierHashLink: string;
  @Input() modalReference;
  @Input() mainCompanyId?: number = null;
  enableTempAssets = false;

  _onlyTempAssets: boolean = false;
  initialAnswers: UniqueGeneralAnswerModel[];
  @Input() set onlyTempAssets(value: boolean) {
    this._onlyTempAssets = value;
    if (this._onlyTempAssets == true)
      this.registerAssetMode = RegisterAssetMode.CreateTemporarilyAsset;
  }
  get onlyTempAssets() {
    return this._onlyTempAssets;
  }

  @Output() onSubmitSiteAsset = new EventEmitter<number>();
  @ViewChild("formDataRenderer") public formDataRenderer: FormDataRendererComponent;
  @ViewChild('insertAssetRenderer') public insertAssetRenderer: UpsertInventoryAssetComponent;
  @ViewChild('insertTempAssetRenderer') public insertTempAssetRenderer: UpsertInventoryAssetComponent;
  selectedAssetId: number;
  newAsset: ToolModel;
  newTemporarilyAsset: TemporarilyToolModel;
  showRegisterPanel: boolean;

  get siteId() {
    return this.site?.id;
  }

  get siteName() {
    return this.site?.name;
  }

  get CompanyName() {
    return this.userService.companyName;
  }

  get activeRegisterAssetAs(): boolean {
    if ((this.site && this.supplierId) || this.supplierHashLink) {
      this.registerAssetAs = RegisterAssetAsType.CompanySupplier;
      return false;
    }
    return true;
  };

  get showRegisterAssetAs(): boolean {
    return !(this.supplierHashLink || this.mainCompanyId);
  };

  ngOnInit(): void {
    this.enableTempAssets = JSON.parse(JSON.stringify(this.onlyTempAssets));
    if (this._onlyTempAssets == true) {
      this.registerAssetMode = RegisterAssetMode.CreateTemporarilyAsset;
    }
    this.registerAssetAs = RegisterAssetAsType.Company;

    this.loadAssetRegistrationForms();
    this.loadSiteSuppliers();
  }

  constructor(private siteAssetService: SiteAssetService,
    private modalService: ModalService,
    private supplierDocumentService: SupplierDocumentService,
    private userService: UserService) {
  }

  loadAssetRegistrationForms() {
    //if (!this._onlyTempAssets) {
    this.inProgress = true;
    this.siteAssetService.getAssetRegistrationForms(this.siteId, this.supplierHashLink)
      .subscribe(res => {
        this.inProgress = false;
        this.assetRegistrationForms = res.data;
      });
    //}
  }

  supplierName: string = "";
  loadSiteSuppliers() {
    this.selectedSupplier = null;

    if (this.showRegisterAssetAs) {
      this.inProgress = true;
      this.supplierDocumentService.getSiteSuppliersBySiteId(this.siteId)
        .pipe(finalize(() => {
          this.inProgress = false;
        }))
        .subscribe(res => {
          if (res.success) {
            this.siteSuppliers = res.data;
            let supplier = this.supplierId ? this.siteSuppliers.find(s => s.supplierId == this.supplierId && s.siteId == this.siteId) : null;

            this.selectedSupplier = supplier
            this.supplierName = supplier?.supplierName
          }
        });
    }
  }

  registerAssetAsChanged(value: RegisterAssetAsType) {
    this.registerAssetAs = value;
    if (this.registerAssetAs == RegisterAssetAsType.CompanySupplier) {
      this.onlyTempAssets = true;
      this.registerAssetMode = RegisterAssetMode.CreateTemporarilyAsset;
    }
    else {
      this.selectedSupplier = null;
      this.onlyTempAssets = JSON.parse(JSON.stringify(this.enableTempAssets));

      if (this.onlyTempAssets)
        this.registerAssetMode = RegisterAssetMode.CreateTemporarilyAsset;
      else
        this.registerAssetMode = RegisterAssetMode.SelectAsset;
    }
  }

  onChangeAssetId(selectedAssetid: number) {
    if (this.registerAssetMode == RegisterAssetMode.SelectAsset)
      this.selectedAssetId = selectedAssetid;
  }

  onLoadAssetsToRegisterCalled = false;
  onLoadAssetsToRegister(assets: any[]) {
    if (this.onLoadAssetsToRegisterCalled == true) {
      return;
    }
    this.onLoadAssetsToRegisterCalled = true;
    if ((assets?.length ?? 0) == 0) {
      this.registerAssetMode = RegisterAssetMode.CreateAsset;
    }
  }

  get isValidAsset(): boolean {
    return ((this.registerAssetMode == RegisterAssetMode.SelectAsset && this.selectedAssetId > 0) ||
      (this.registerAssetMode == RegisterAssetMode.CreateAsset && this.insertAssetRenderer?.isAddFormValid() == true) ||
      (this.registerAssetMode == RegisterAssetMode.CreateTemporarilyAsset && this.insertTempAssetRenderer?.isTempAddFormValid() == true)) &&
      (!this.activeRegisterAssetAs ||
        (
          this.activeRegisterAssetAs && this.showRegisterAssetAs &&
          (
            (this.registerAssetAs == RegisterAssetAsType.CompanySupplier && this.selectedSupplier != null) ||
            (this.registerAssetAs == RegisterAssetAsType.Company && this.selectedSupplier == null)
          )
        )
      );
  }

  async onClickNextButton() {
    if (this.isValidAsset == true) {
      if (this.registerAssetMode == RegisterAssetMode.CreateAsset) {
        this.newAsset = await this.insertAssetRenderer?.createQueryModel() as ToolModel;
        this.initialAnswers = this.newAsset?.toolDynamicFieldValues?.filter((element) => element.uniqueKey?.length)
          ?.map((e) => {
            return {
              uniqueKey: e.uniqueKey,
              answer: {
                answer: e.value,
                files: e.value,
                isBase64Answer: true,
              } as GeneralAnswerModel
            } as UniqueGeneralAnswerModel;
          });

        this.newTemporarilyAsset = null;
        this.selectedAssetId = null;
        this.showRegisterPanel = true;
      }
      else if (this.registerAssetMode == RegisterAssetMode.CreateTemporarilyAsset) {
        this.siteAssetService.checkSiteTempAssetExistence({
          toolName: this.insertTempAssetRenderer?.formGroup?.controls?.name?.value,
          supplierId: this.selectedSupplier?.supplierId,
          siteId: this.siteId,
        }, this.supplierHashLink)
          .pipe(finalize(() => {
            this.inProgress = false;
          }))
          .subscribe(async (res) => {
            if (res === true) {
              this.modalService.confirm('Warning: Another asset with the same name is already registered on this site.', '', 'Update Asset name', 'Continue anyway')
                .subscribe(async (res) => {
                  if (res === true) {
                    this.insertTempAssetRenderer?.assetName?.nativeElement?.focus();
                  } else if (res === false) {
                    await this.tempAssetShowRegisterPanel();
                  }
                });
            } else {
              await this.tempAssetShowRegisterPanel();
            }
          }, err => {
            this.modalService.error(err ? err : 'Check Existence Failed');
          });
      }
      else if (this.registerAssetMode == RegisterAssetMode.SelectAsset) {
        this.newTemporarilyAsset = null;
        this.newAsset = null;
        this.showRegisterPanel = true;
      }
    }
  }

  async tempAssetShowRegisterPanel() {
    this.newTemporarilyAsset = await this.insertTempAssetRenderer?.createQueryModel() as TemporarilyToolModel;
    this.newAsset = null;
    this.selectedAssetId = null;
    this.showRegisterPanel = true;
  }

  validateForm(formIsValid: boolean) {
    this.isFormValid = formIsValid && this.siteId > 0 &&
      (!this.activeRegisterAssetAs ||
        (
          this.activeRegisterAssetAs && this.showRegisterAssetAs &&
          (
            (this.registerAssetAs == RegisterAssetAsType.CompanySupplier && this.selectedSupplier != null) ||
            (this.registerAssetAs == RegisterAssetAsType.Company && this.selectedSupplier == null)
          )
        )
      );
  }

  operatorMode: AssetOperatorMode;
  setOperatorMode(value) {
    this.operatorMode = value;
  }

  async registerSiteAsset() {
    if (!this.showRegisterAssetAs)
      this.selectedSupplier = null;

    if (this.isFormValid) {
      var formDatamodel = this.formDataRenderer.getFormResponse();
      var model = {
        siteId: this.siteId,
        toolId: this.selectedAssetId,
        newTool: this.newAsset,
        temporarilyTool: this.newTemporarilyAsset,
        operatorRegistrationMode: this.operatorMode,
        formDatamodel: formDatamodel,
        supplierId: this.selectedSupplier?.supplierId
      } as SiteAssetRegistrationModel;

      this.inProgress = true;

      this.siteAssetService.registerSiteAsset(model, this.supplierHashLink)
        .pipe(finalize(() => {
          this.inProgress = false;
        }))
        .subscribe(res => {
          this.onSubmitSiteAsset.emit(res.data);
        }, err => {
          this.modalService.error(err ? err : 'Request Failed');
        });
    }
  }

  closeModal() {
    this.modalReference?.hide();
  }
}
