import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageQueryModel, MessageShowModel } from '../models/message-query-model';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getMesasgeHistory(request: MessageQueryModel): Observable<MessageShowModel[]> {
    return this._http.post<MessageShowModel[]>(`api/company/get-message-history`, request)
      .pipe(catchError(this._errorService.handleError));
  }

}
