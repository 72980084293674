import { Pipe, PipeTransform } from '@angular/core';
import { SupplierDocumentCommentMode } from '../enums/supplier-document-comment-mode.enum';

@Pipe({
  name: 'supplierDocumentCommentMode'
})
export class SupplierDocumentCommentModePipe implements PipeTransform {

  transform(value: unknown): string {
    if (value == SupplierDocumentCommentMode.Disable) return 'Disable';
    if (value == SupplierDocumentCommentMode.Enable) return 'Enable';
    return value?.toString() ?? '?';
  }

}
