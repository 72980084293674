<div *ngIf="_question.questionType == QuestionType.Text">
  <div class="form-group">
    <label for="exampleInputEmail1">Text Question Answers</label>
    <input class="form-control" [formControl]="formControl" placeholder="type answer">
  </div>
</div>
<div *ngIf="_question.questionType == QuestionType.Boolean || _question.questionType == QuestionType.SingleSelect">
  <div class="form-check" *ngFor="let option of formOptions">
    <input id="input-{{option.item.itemTitle}}" type="radio" [value]="option.item.itemValue" class="form-check-input" [formControl]="option.form">
    <label for="input-{{ option.item.itemTitle }}" class="form-check-label">{{option.item.itemTitle}}</label>
  </div>
</div>
<div *ngIf="_question.questionType == QuestionType.MultiSelect">
  <div class="form-check d-flex align-items-center" *ngFor="let option of formOptions">
    <input id="input-{{option.item.itemTitle}}" type="checkbox" class="form-check-input" [formControl]="option.form">
    <label for="input-{{ option.item.itemTitle }}" class="form-check-label">{{option.item.itemTitle}}</label>
  </div>
</div>
