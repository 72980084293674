<ng-container *ngIf="noCard">
  <accordion-group>
    <div accordion-heading>
      <obc-accordion-header [isAccordionDirty]="dirtyComponents?.inductions?.isDirty" [mainTitle]="'Inductions'"
        [icon]="'fa fa-book'" [iconColor]="'#ff3a2f'" [info]="[{'title': '', 'value': headerInfo}]">
      </obc-accordion-header>
    </div>
    <div>
      <div class="d-flex justify-content-end mb-3">
        <a (click)="onOpenAddInduction()" class="btn btn-sm btn-info text-decoration-none text-white">
          <i class="fa fa-plus"></i>
          Add Induction
        </a>
      </div>
      <obc-loader [show]="inProgress"></obc-loader>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-responsive-md table-striped mb-0">
              <thead>
                <tr>
                  <th>
                    Induction
                  </th>
                  <th>
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="siteInductions">
                <tr *ngFor="let si of siteInductions">
                  <td>
                    {{si.induction}}<span *ngIf="si.mode==InductionMode.Disable" style="font-size: small;color:gray">
                      (Disable) </span>
                    <span *ngIf="si.inductionKey" style="font-size: small;"> ({{si.inductionKey}}) </span>
                  </td>
                  <td>
                    <div class="d-flex flex-row flex-row-reverse">
                      <button (click)="onRemoveSiteInduction(si)" class="btn btn-danger">Remove</button>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="siteInductions?.length == 0">
                  <td colspan="20">
                    <p class="text-center mb-0">
                      There are currently no site based inductions applied to this site.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </accordion-group>
</ng-container>
<div class="card" *ngIf="!noCard">
  <div class="card-header">
    <div class="d-flex flex-row justify-content-between">
      <h2 class="card-title">Site Inductions</h2>
      <div>
        <button (click)="onOpenAddInduction()" class="btn btn-sm btn-info"><i class="fas fa-plus"></i> Add
          Induction</button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="row">

      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>
                  Induction
                </th>
                <th>
                </th>
              </tr>
            </thead>
            <tbody *ngIf="siteInductions">
              <tr *ngFor="let si of siteInductions">
                <td>
                  {{si.induction}}
                  <span *ngIf="si.inductionKey" style="font-size: small;"> ({{si.inductionKey}}) </span>
                </td>
                <td>
                  <div class="d-flex flex-row flex-row-reverse">
                    <button (click)="onRemoveSiteInduction(si)" class="btn btn-danger">Remove</button>
                  </div>
                </td>
              </tr>
              <tr *ngIf="siteInductions.length == 0">
                <td colspan="20">
                  <p class="text-center mb-0 bg-light">
                    There are currently no site based inductions applied to this site.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</div>
