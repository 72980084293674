export enum FileType {
    Pdf = 0,
    Video = 1,
    Text = 2,
    Audio = 3,
    Youtube = 4,
    Image = 5,
    ExternalLink = 6,
    Binary = 7
}
