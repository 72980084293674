import { Component, OnInit, ViewChild } from '@angular/core';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { Table } from 'src/app/enums/table.enum';
import { GridColumn, GridConfig, GridTemplate } from 'src/app/models/grid/grid.config';
import { ProcoreSyncMonitoringReportViewModel } from 'src/app/models/procore-sync-monitoring-report-view-model';

@Component({
  selector: 'obc-procore-sync-monitoring',
  templateUrl: './procore-sync-monitoring.component.html',
  styleUrls: ['./procore-sync-monitoring.component.scss']
})
export class ProcoreSyncMonitoringComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    this.prepareGridConfig();
  }

  @ViewChild('grid') grid: any;
  gridColumns: GridColumn<ProcoreSyncMonitoringReportViewModel>[] = [];
  gridConfig: GridConfig = {} as GridConfig;

  prepareGridConfig() {
    this.gridConfig = new GridConfig({
      apiUrl: '/api/procore/procore-sync-monitoring-report',
      tableType: Table.ProcoreSyncMonitoringReport,
      displayGeneralSearch: true,
      displayQuestionsFilter: false,
      generalSearchPlaceholder: 'Filter result by Company Title, Connection Mode',
      csvReportUrl: '/api/procore/export-procore-sync-monitoring-report-csv',
    });
    this.gridColumns = [
      {
        name: "Company Title",
        key: "companyTitles",
        propertyNameInQuery: 'companyTitles',
        enableFilter:true,
        enableSort:true,
        sortBy:'companyTitle',
        type:FilterType.Text,
        gridTemplate: new GridTemplate().ComponentTemplateName('companyTitle').CsvFields([
          'companyId',
          'companyTitle',
        ]),
      },
      {
        name: "Connection Mode",
        key: "procoreConnectionMode",
        propertyNameInQuery: 'procoreConnectionMode',
        enableFilter:false,
        enableSort:true,
        sortBy:'procoreConnectionMode',
        type:FilterType.Text,
      },
      {
        name: "Sync Status",
        key: "syncStatus",
        enableSort:true,
        enableFilter:false,
        type: FilterType.DateTimeRange,
        sortBy:'procoreIntegrationStartDateTime',
        gridTemplate: new GridTemplate().ComponentTemplateName('syncStatus').CsvFields([
          'procoreIntegrationStartDateTime',
          'isProcoreIntegrationInProgress',
          'procoreIntegrationEndDateTime',
          'procoreIntegrationLastFailureDateTime',
          'procoreIntegrationFailureCount',
        ])
      },
      {
        name: "Export Files Sync Status",
        key: "syncFilesStatus",
        enableFilter:false,
        enableSort:false,
        gridTemplate: new GridTemplate().ComponentTemplateName('syncFilesStatus').CsvFields([
          'procoreExportFilesIntegrationStartDateTime',
          'isProcoreExportFilesIntegrationInProgress',
          'procoreExportFilesIntegrationEndDateTime',
          'procoreExportFilesIntegrationLastFailureDateTime',
          'procoreExportFilesIntegrationFailureCount',
        ])
      },
      {
        name: "Import Sites",
        key: "importSites",
        enableFilter:false,
        enableSort:false,
        gridTemplate: new GridTemplate().ComponentTemplateName('importSites').CsvFields([
          'procoreImportSitesEnabled',
          'procoreLastImportSites'
        ])
      },
      {
        name: "Import Directories",
        key: "importDirectories",
        enableFilter:false,
        enableSort:false,
        gridTemplate: new GridTemplate().ComponentTemplateName('importDirectories').CsvFields([
          'procoreImportCompanyDirectoryEnabled',
          'procoreLastImportCompanyDirectory',
          'procoreImportProjectDirectoryEnabled',
          'procoreLastImportProjectDirectory'
        ])
      },
      {
        name: "Import Users",
        key: "importUsers",
        enableFilter:false,
        enableSort:false,
        gridTemplate: new GridTemplate().ComponentTemplateName('importUsers').CsvFields([
          'procoreImportUsersEnabled',
          'procoreLastImportUsers'
        ]),
      },
      {
        name: "Import Roles",
        key: "importRoles",
        enableFilter:false,
        enableSort:false,
        gridTemplate: new GridTemplate().ComponentTemplateName('importRoles').CsvFields([
          'procoreImportCompanyRolesEnabled',
          'procoreImportCompanyRolesLastSyncDate',
          'procoreImportProjectUserRolesEnabled',
          'procoreImportProjectUserRolesLastSyncDate'
        ]),
      },
      {
        name: "Export Checkins As",
        key: "exportCheckinsAs",
        enableFilter:false,
        enableSort:false,
        gridTemplate: new GridTemplate().ComponentTemplateName('exportCheckinsAs').CsvFields([
          'procoreExportSiteAttendanceAsTimeCardMode',
          'procoreExportSiteAttendanceAsVisitorLogMode',
          'procoreExportSiteAttendanceAsManpowerMode',
          'procoreLastExportSiteAttendances'
        ]),
      },
      {
        name: "Export Documents",
        key: "exportDocuments",
        enableFilter:false,
        enableSort:false,
        gridTemplate: new GridTemplate().ComponentTemplateName('exportDocuments').CsvFields([
          'procoreUploadSiteSupplierDocumentsEnabled',
          'procoreLastUploadSiteSupplierDocuments',
          'procoreUploadSiteSupplierDocumentVersionSignOffsEnabled',
          'procoreLastUploadSiteSupplierDocumentVersionSignOffs',
          'procoreUploadSiteInductionsEnabled',
          'procoreLastUploadSiteInductions',
          'procoreUploadPermitFormsEnabled',
          'procoreLastUploadPermitForms',
          'procoreUploadSiteAssetsFormEnabled',
          'procoreLastUploadSiteAssetForms'
        ]),
      }
    ];
  }
}
