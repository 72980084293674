import { Component, OnInit, Input } from '@angular/core';
import { QuestionRendererComponent } from '../question-renderer/question-renderer.component';
import { FormBuilder } from '@angular/forms';
import { SiteQuestionRendererViewModel, SiteQuestionItemFormControl } from 'src/app/models/site-question-viewmodel';
import { CacheService } from "../../../../services/cache.service";

@Component({
  selector: 'obc-multi-select-question-renderer',
  templateUrl: './multi-select-question-renderer.component.html',
  styleUrls: ['./multi-select-question-renderer.component.scss']
})
export class MultiSelectQuestionRendererComponent extends QuestionRendererComponent implements OnInit {
  selectedItemHint: string[] = [];
  formControls: SiteQuestionItemFormControl[] = null;
  @Input() set question(model: SiteQuestionRendererViewModel) {
    this.model = model;

    let initialAnswers = [];
    if (model.initialAnswers && model.initialAnswers.length > 0) {
      initialAnswers = model.initialAnswers[0]?.answer?.split(",");
    }
    try {
      this.formControls = this.model.question.items.map((e, _) => {
        let checked = initialAnswers.find(answer => answer == e.value);
        return new SiteQuestionItemFormControl(checked != null, { title: e.title, value: e.value, hint: e.hint, displayStyle: e.displayStyle });
      });
    } catch {
      throw ("unknown item list");
    }

    var fg = this.fb.group({});
    for (let index = 0; index < this.formControls.length; index++) {
      const element = this.formControls[index];
      fg.addControl(element.item.title, element);
      let checked = initialAnswers.find(answer => answer == element.item.value);
      if(checked) {
        this.selectedItemHint.push(element.item.hint);
        element.item.selectedDisplayStyle = element.item.displayStyle;
      }
    }
    this.formGroup = fg;
  };

  constructor(private fb: FormBuilder, public cacheService: CacheService) {
    super(cacheService)
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onClick(item: any) {
    if (item == null) return;

    let value = this.formGroup.controls[item.title].value;
    if (!value && this.selectedItemHint.length > 0) {
      let itemIndex = this.selectedItemHint.findIndex(p => p == item.hint);
      if (itemIndex > -1)
        this.selectedItemHint.splice(itemIndex, 1);
    }
    if (value && item?.hint)
      this.selectedItemHint.push(item.hint);

    item.selectedDisplayStyle = (value && item?.displayStyle != undefined) ? item.displayStyle : "";
  }

  onCheckChange(item: any) {
    if (item == null) return;

    let value = this.formGroup.controls[item.title].value;
    if (value && this.selectedItemHint.length > 0) {
      let itemIndex = this.selectedItemHint.findIndex(p => p == item.hint);
      if (itemIndex > -1)
        this.selectedItemHint.splice(itemIndex, 1);
    }
    if (!value && item?.hint)
      this.selectedItemHint.push(item.hint);

    item.selectedDisplayStyle = (!value && item?.displayStyle != undefined) ? item.displayStyle : "";
  }
}
