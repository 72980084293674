<div class="d-flex justify-content-end" style="width: 100%;">
  <button class="btn btn-info"
          (click)="openCategoriesFilterModal(dynamicFieldsFilterModal)"
          *ngIf="!isAssignMode" style="width: 100%;">
    Dynamic Fields Filter(s)
  </button>
  <button class="btn btn-warning"
          (click)="openCategoriesFilterModal(assignDynamicFieldsFilterModal)"
          *ngIf="isAssignMode" style="width: 100%;">
    Dynamic Assign Fields Filter(s)
  </button>
</div>

<ng-template #dynamicFieldsFilterModal>
  <div class="card">
    <div class="card-header">
      <h5>Define Dynamic Field Filter</h5>
      <div class="card-actions">
        <a (click)="onCloseMethod(true)" class="card-action card-action-dismiss"></a>
      </div>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-md-12">
          <div class="alert alert-info">Only non image fields are filterable</div>
          <div>
            <label for="">Select Category</label>
          </div>
          <select class="form-control" [(ngModel)]="selectedCategory" (change)="onCategoryChange()">
            <option [ngValue]="null" selected disabled>
              Select a Category
            </option>
            <option selected *ngFor="let category of toolCategories" [ngValue]="category">
              {{ category.title }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div>
            <label>Select Field</label>
          </div>
          <div class="input-group mb-3">
            <select class="form-control" [(ngModel)]="selectedDynamicField">
              <option [ngValue]="null" selected disabled>
                Select a Dynamic Field
              </option>
              <ng-container *ngFor="let dynamicField of filterFunction(this.selectedCategory?.toolDynamicFields)">
                <option *ngIf="!isImage(dynamicField)" [ngValue]="dynamicField">
                  {{ dynamicField.fieldName }}
                </option>
              </ng-container>
            </select>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" [disabled]="!selectedDynamicField" type="button"
                      tooltip="add dynamic field to filter" (click)="onAddDynamicFieldToFilters()">
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="col-md-12" *ngFor="let _dynamicField of filterDynamicFields">
            <div class="alert alert-default nomargin">
              <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                      (click)="onRemoveDynamicFieldFromFilter(_dynamicField)">
                <span aria-hidden="true">&times;</span>
              </button>
              <h6>{{ _dynamicField.fieldName }}</h6>
              <div class="row">
                <div class="form-group" style="width: 100%; padding-left: 15px; padding-right: 15px" *ngIf="
                    _dynamicField.type === DynamicFieldType.Text ||
                    _dynamicField.type === DynamicFieldType.Number
                  ">
                  <div class="row">
                    <label class="col-md-2" for="operator">Answer</label>
                    <div class="col-md-4">
                      <select id="operator" required class="form-control"
                              [formControl]="_dynamicField.operatorFormControl" name="operatorFormControl">
                        <option *ngFor="let item of TextOperator | enumToArray" [ngValue]="TextOperator[item]">
                          {{ TextOperator[item] | textOperator }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-6" *ngIf="hasOperand(_dynamicField.operatorFormControl?.value)">


                      <div class="d-flex flex-column">
                        <input [formControl]="_dynamicField.formControl"
                               [type]="_dynamicField.type === DynamicFieldType.Number ? 'number':'text'"
                               class="form-control
{{ isInvalidFormControlValue(_dynamicField) ? 'ng-invalid' : 'ng-valid'}}
" />
                        <span class="mt-1" *ngIf="isInvalidFormControlValue(_dynamicField)"
                              style="font-size: 12px; color: red">This input field is required</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group" style="width: 100%; padding-left: 15px; padding-right: 15px"
                     *ngIf=" _dynamicField.type === DynamicFieldType.Date">
                  <div class="row">
                    <label class="col-md-2" for="operator">Answer</label>
                    <div class="col-md-4">
                      <select id="operator" required class="form-control"
                              [formControl]="_dynamicField.operatorFormControl" name="operatorFormControl">
                        <option *ngFor="let item of TextOperator | enumToArray" [ngValue]="TextOperator[item]">
                          {{ TextOperator[item] | textOperator }}
                        </option>
                      </select>
                    </div>


                    <div class="col-md-6" *ngIf="hasOperand(_dynamicField.operatorFormControl?.value)">
                      <div class="input-group">

                        <div class="d-flex flex-column">
                          <obc-input-date-time-picker [formControl]="_dynamicField.formControl"
                                                      [placeholder]=""
                                                      [value]="_dynamicField.formControl.value"
                                                      [mode]="DatetimePickerMode.Calender">
                          </obc-input-date-time-picker>
                          <span class="mt-1" *ngIf="isInvalidFormControlValue(_dynamicField)"
                                style="font-size: 12px; color: red">This input field is required</span>
                        </div>


                        <!-- <input [owlDateTime]="dt1" class="form-control" placeholder="Now"
                          [formControl]="_dynamicField.formControl" [owlDateTimeTrigger]="dt1">
                        <owl-date-time #dt1 [pickerType]="'calendar'"></owl-date-time> -->
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12"></div>
      </div>
    </div>
    <div class="card-body">
      <button class="btn btn-danger pull-right"
              [disabled]="!canCloseModal()"
              (click)="modalRef.hide()">
        {{ this.filterDynamicFields?.length > 0 ? 'Update' : 'Close'}}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #assignDynamicFieldsFilterModal>
  <div class="card">
    <div class="card-header">
      <h5>Define Assign Dynamic Field Filter</h5>
      <div class="card-actions">
        <a (click)="onCloseMethod(true)" class="card-action card-action-dismiss"></a>
      </div>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-md-12">
          <div class="alert alert-info">Only non image fields are filterable</div>
          <div>
            <label for="">Select Category</label>
          </div>
          <select class="form-control" [(ngModel)]="selectedCategory" (change)="onCategoryChange()">
            <option [ngValue]="null" selected disabled>Select Category</option>
            <option *ngFor="let category of toolCategories" [ngValue]="category">
              {{ category.title }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div>
            <label>Select Field</label>
          </div>
          <div class="input-group mb-3">

            <select class="form-control" [(ngModel)]="selectedAssignDynamicField">
              <option [ngValue]="null" selected disabled>
                Select a Dynamic Field
              </option>
              <ng-container
                *ngFor="let assignDynamicField of filterFunction(this.selectedCategory?.toolAssignDynamicFields)">
                <option *ngIf="!isImage(assignDynamicField)" [ngValue]="assignDynamicField">
                  {{ assignDynamicField.fieldName }}
                </option>
              </ng-container>
            </select>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" [disabled]="!selectedAssignDynamicField" type="button"
                      tooltip="add assign dynamic field to filter" (click)="onAddAssignDynamicFieldToFilters()">
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>



      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="col-md-12" *ngFor="let _assignDynamicField of filterAssignDynamicFields">
            <div class="alert alert-default nomargin">
              <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                      (click)="onRemoveDynamicFieldFromFilter(_assignDynamicField)">
                <span aria-hidden="true">&times;</span>
              </button>
              <h6>{{ _assignDynamicField.fieldName }}</h6>
              <div class="row">
                <div class="form-group" style="width: 100%; padding-left: 15px; padding-right: 15px" *ngIf="
                    _assignDynamicField.type === DynamicFieldType.Text ||
                    _assignDynamicField.type === DynamicFieldType.Number
                  ">
                  <div class="row">
                    <label class="col-md-2" for="operator">Answer</label>
                    <div class="col-md-4">
                      <select id="operator" required class="form-control"
                              [formControl]="_assignDynamicField.operatorFormControl" name="operatorFormControl">
                        <option *ngFor="let item of TextOperator | enumToArray" [ngValue]="TextOperator[item]">
                          {{ TextOperator[item] | textOperator }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-6" *ngIf="hasOperand(_assignDynamicField.operatorFormControl?.value)">
                      <div class="d-flex flex-column">
                        <input [formControl]="_assignDynamicField.formControl"

                               [type]="_assignDynamicField.type === DynamicFieldType.Number ? 'number':'text'"
                               class="form-control
{{ isInvalidFormControlValue(_assignDynamicField) ? 'ng-invalid' : 'ng-valid'}}"
                        />
                          <span class="mt-1" *ngIf="isInvalidFormControlValue(_assignDynamicField)"
                                style="font-size: 12px; color: red">This input field is required</span>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="form-group" style="width: 100%; padding-left: 15px; padding-right: 15px" *ngIf="
                  _assignDynamicField.type === DynamicFieldType.Date
                ">
                  <div class="row">
                    <label class="col-md-2" for="operator">Answer</label>
                    <div class="col-md-4">
                      <select id="operator" required class="form-control"
                              [formControl]="_assignDynamicField.operatorFormControl" name="operatorFormControl">
                        <option *ngFor="let item of (TextOperator | enumToArray | filterByCondition:filterOperators:_assignDynamicField.questionType)" [ngValue]="TextOperator[item]">
                          {{ TextOperator[item] | textOperator }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-6" *ngIf="hasOperand(_assignDynamicField.operatorFormControl?.value)">
                      <div class="input-group">
                        <div class="d-flex flex-column">
                          <obc-input-date-time-picker [formControl]="_assignDynamicField.formControl"
                                                      [value]="_assignDynamicField.formControl.value"
                                                      [mode]="DatetimePickerMode.Calender">
                          </obc-input-date-time-picker>
                          <span class="mt-1" *ngIf="isInvalidFormControlValue(_assignDynamicField)"
                                style="font-size: 12px; color: red">This input field is required</span>
                        </div>
                        <!-- <input [owlDateTime]="dt1" class="form-control" placeholder="Now"
                          [formControl]="_assignDynamicField.formControl" [owlDateTimeTrigger]="dt1">
                        <owl-date-time #dt1 [pickerType]="'calendar'"></owl-date-time> -->
                      </div>
                    </div>
                  </div>


                </div>


              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12"></div>
      </div>
    </div>
    <div class="card-body">
      <button class="btn btn-danger pull-right"
              [disabled]="!canCloseModal()"
              (click)="modalRef.hide()">
        {{ this.filterAssignDynamicFields?.length > 0 ? 'Update' : 'Close'}}
      </button>
    </div>
  </div>
</ng-template>
