import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DayOfWeek } from '../../../enums/DaysOfWeek';
import { PermitScheduleModel } from "../../../models/form/permits-info-model";

@Component({
  selector: 'obc-permit-schedule-item',
  templateUrl: './permit-schedule-item.component.html',
  styleUrls: ['./permit-schedule-item.component.scss'],
})
export class PermitScheduleItemComponent implements OnInit {
  @Input() permitSchedule: PermitScheduleModel;
  @Output() remove = new EventEmitter();

  daysOfWeek = DayOfWeek;

  constructor() {}

  ngOnInit(): void {
  }

  onRemove() {
    this.remove.emit(this.permitSchedule);
  }
}
