<obc-top-bar></obc-top-bar>
<div class="inner-wrapper">
  <obc-side-bar></obc-side-bar>
  <section role="main" class="content-body pb-0">
    <div class="d-flex justify-content-center">
        <div class="bg-company-container d-flex justify-content-center align-items-center">
          <img *ngIf="userService?.currentCompany?.logoUrl" src="{{userService?.currentCompany?.logoUrl}}"
               class="bg-company-logo">
        </div>
    </div>
    <header class="page-header">
      <h2>
        <span>
          {{header}}
        </span>
      </h2>
      <div class="right-wrapper text-right d-none">
        <ol class="breadcrumbs">
          <li>
            <a href="index.html">
              <i class="fas fa-home"></i>
            </a>
          </li>
          <li><span>Layouts</span></li>
        </ol>

        <a class="sidebar-right-toggle" data-open="sidebar-right"><i class="fas fa-chevron-left"></i></a>
      </div>
    </header>
    <div *ngIf="(userService.info | async)">
      <router-outlet></router-outlet>
    </div>
    <div *ngIf="!(userService.info | async)">
      <div class="card" style="height: 100px;">
        <obc-loader [show]="true"></obc-loader>
        <div style="height: 100%;" class="d-flex justify-content-center align-items-end">
          <span style="z-index: 10001;
          color: white;
          font-weight: 700;" class="pb-3 text-center">Loading...<br> Please wait... </span>
        </div>
      </div>
    </div>
  </section>
</div>
