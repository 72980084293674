import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SignatureData } from 'src/app/custom-controls/signature-pad/signature-pad.component';
import { OptionalMandatoryState } from 'src/app/enums/site-document-state.enum';
import { SupplierDocumentConfigMode } from 'src/app/enums/supplier-document-config-mode.enum';
import { CustomFormControl } from 'src/app/models/custom-formcontrol';
import { SignedSupplierDocumentTypeVersionModel } from 'src/app/models/supplier-document/signed-supplier-document-type-version-model';
import { SupplierDocumentViewModel } from 'src/app/models/supplier-document/site-supplier-document-version-signoff-viewModel';
import { ApproveEntityType } from 'src/app/enums/approve-entity-type';

@Component({
  selector: 'obc-supplier-document-renderer',
  templateUrl: './supplier-document-renderer.component.html',
  styleUrls: ['./supplier-document-renderer.component.scss']
})
export class SupplierDocumentRendererComponent implements OnInit {
  _documents: SupplierDocumentViewModel[] = [];
  formArray = new FormArray([]);
  SiteDocumentState = OptionalMandatoryState;
  SupplierDocumentConfigMode = SupplierDocumentConfigMode;
  currentCustomFormControlToSign: CustomFormControl;
  signModalRef: any;
  get isSignMode(): boolean { return this.supplierDocumentConfigMode == SupplierDocumentConfigMode.MandatorySign; }

  @Input() hasPermissionToAddSupplierDocumentComment: boolean;
  @Input() supplierDocCommentAcknowledgementText: string;

  ApproveEntityType = ApproveEntityType;

  @Input() set documents(value: SupplierDocumentViewModel[]) {
    this._documents = value;
    if (this._documents.length > 0) {
      this.formArray.clear();
      let reqValidators = [Validators.requiredTrue];
      for (let index = 0; index < this._documents.length; index++) {
        const document = this._documents[index];

        let fc = new CustomFormControl(this.isSignMode ? '' : false, document, document.signOffMode == OptionalMandatoryState.Mandatory ? reqValidators : []);
        this.formArray.push(fc);
      }
    }
    else {
      this.formArray.clear();
    }
  }

  @Output('onFileViewed') onFileViewedOutput = new EventEmitter();

  constructor(private modalService: BsModalService) { }

  ngOnInit() {
  }

  getAnswers(): SignedSupplierDocumentTypeVersionModel[] {
    let list = this.formArray.controls.map(x => {
      let fc = x as CustomFormControl;
      return {
        siteSupplierDocumentVersionId: (fc.item as SupplierDocumentViewModel).siteSupplierDocumentVersionId,
        signatureBase64: this.isSignMode ? fc.value : '',
        isConfirmed: this.isSignMode ? !!fc.value : fc.value
      } as SignedSupplierDocumentTypeVersionModel;
    }
    ).filter(x => x.isConfirmed == true);
    return list;
    //    return this.formArray.controls.filter(x => x.value == true).map(x => ((x as CustomFormControl).item as SupplierDocumentViewModel).siteSupplierDocumentVersionId)
  }

  valid(): boolean {
    return this.formArray.valid;
  }

  @Input() forceViewSupplierDocumentsBeforeSignOff: boolean = true;
  @Input() supplierDocumentConfigMode: SupplierDocumentConfigMode = SupplierDocumentConfigMode.DisableSign;

  forcedViewSupplierDocumentFilesList: string[] = [];
  onFileViewed(viewedFiles: any) {
    if (this.forceViewSupplierDocumentsBeforeSignOff) {
      if (viewedFiles && viewedFiles.length > 0) {
        for (const fileUrlElement of viewedFiles) {
          if (!this.forcedViewSupplierDocumentFilesList.includes(fileUrlElement.fileUrl)) {
            this.forcedViewSupplierDocumentFilesList.push(fileUrlElement.fileUrl);
          }
        }
      }
    }
    /*if(!this.forcedViewSupplierDocumentFilesList.includes(fileUrl)) {
      this.forcedViewSupplierDocumentFilesList.push(fileUrl);
    }*/
  }



  validateFCView(fc) {
    if (!this.forceViewSupplierDocumentsBeforeSignOff)
      return true;

    let viewedItems = fc.item?.supplierDocumentFiles?.filter(item => this.forcedViewSupplierDocumentFilesList?.includes(item.fileUrl));
    return viewedItems?.length == fc.item?.supplierDocumentFiles?.length;
  }

  sign(signTemplate, formControl: CustomFormControl) {
    this.currentCustomFormControlToSign = formControl;
    this.signModalRef = this.modalService.show(signTemplate);
  }
  saveSign(signature: SignatureData) {
    this.currentCustomFormControlToSign.setValue(signature.signatureAsPng);
    this.currentCustomFormControlToSign.updateValueAndValidity();
    this.currentCustomFormControlToSign.setErrors(null);
    this.formArray.updateValueAndValidity();
    this.currentCustomFormControlToSign = null;
    this.signModalRef.hide();
  }
}
