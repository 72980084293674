<div class="card">
  <obc-loader [show]="inProgress"></obc-loader>
  <div class="card-header">
    <h4>Host Check-In/Out</h4>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12 mb-4">
        <app-site-selector [required]="true" #sitelist [permission]="userPermissions.ManualCheck"
          (siteSelect)="selectedSite = $event; onSiteChange()">
        </app-site-selector>
      </div>
      <div class="col-md-12">
        <tabset *ngIf="selectedSite">
          <tab heading="Check-In" (selectTab)="mode='checkin'" *ngIf="tabs[0]">
            <obc-manual-checkin #manualCheckinRef [site]="selectedSite" [site]="selectedSite"
              (selectTab)="checkoutSelected.next(false)" (needToUpdateAttendanceCount)="updateAttendanceCount()">
            </obc-manual-checkin>
          </tab>
          <tab heading="Check-Out ({{checkInCount}})" (selectTab)="mode='checkout'" *ngIf="tabs[1]"
            (selectTab)="checkoutSelected.next(true)">
            <obc-manual-checkout [siteId]="selectedSite.id" (needToUpdateAttendanceCount)="updateAttendanceCount()"
              [checkoutSelected]="checkoutSelected"></obc-manual-checkout>
          </tab>
          <tab heading="Pre Check-In" *ngIf="tabs[2]">
            <obc-pre-check-in-list [siteId]="selectedSite.id"></obc-pre-check-in-list>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
</div>