import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApproveEntityType } from "../../../enums/approve-entity-type";
import { InductionService } from "../../../services/induction-service";
import {
  SiteInductionHistoryLogModel,
  SiteInductionHistoryLogViewModel
} from "../../../models/induction/site-induction-history-log-model";
import { BasePagingModel } from "../../../models/paging_model";
import { InductionReviewMode, SiteInductionStatus } from "../../../enums/site-induction-status";
import { ModalService } from "../../../services/modal.service";
import { SiteInductionStatusPipe } from 'src/app/pipes/site-induction-status.pipe';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'obc-request-revision',
  templateUrl: './request-revision.component.html',
  styleUrls: ['./request-revision.component.scss']
})
export class RequestRevisionComponent implements OnInit {

  @Input() siteInductionId: number;
  currentSiteInductionId: number;
  currentSiteInductionHistoryLog: SiteInductionHistoryLogViewModel;
  ApproveEntityType = ApproveEntityType;
  SiteInductionStatus = SiteInductionStatus;
  hasPermissionToReview: boolean = false;

  @Input() modalRef: any;
  @Output() onRefresh: EventEmitter<void> = new EventEmitter<void>();

  refreshParent: boolean = false;
  displayHistory: boolean = false;
  requestorUserFullname: string = '';

  constructor(private inductionService: InductionService,
    private modalService: ModalService,
    private siteInductionStatusPipe: SiteInductionStatusPipe,
    private userService: UserService) { }

  historyLogData: SiteInductionHistoryLogViewModel[];
  companyUserProfile: any;
  ngOnInit(): void {
    this.currentSiteInductionId = this.siteInductionId;
    this.inductionService.inductionHistoryLog({
      siteInductionId: this.siteInductionId,
      pagingInfo: {
        pageNumber: 0,
        pageSize: 20,
      } as BasePagingModel,
    } as SiteInductionHistoryLogModel).subscribe((res) => {
      if (res.success && res.data) {
        this.historyLogData = res.data.data;
        this.requestorUserFullname = this.historyLogData[0].requestorUserFullname;
        this.currentSiteInductionHistoryLog = this.historyLogData?.find(x => x.siteInductionId == this.currentSiteInductionId);
        this.hasPermissionToReview = this.historyLogData[0].hasApprovePermission;
      }
    });
  }

  getHistoryItemButtonText(historyData: SiteInductionHistoryLogViewModel) {
    return this.hasPermissionToReview && historyData.status == SiteInductionStatus.PendingToApprove ? 'Review' : 'View'
  }

  displayHistoryFunc() {
    this.displayHistory = !this.displayHistory;
  }
  closeModal() {
    this.modalService.hide(this.modalRef);

    if (this.refreshParent)
      this.onRefresh.emit();
  }

  isRequestRevision() {
    return this.currentSiteInductionHistoryLog?.reviewMode == InductionReviewMode.RequestRevision;
  }

  onRefreshFunc() {
    this.refreshParent = true;
    this.closeModal();
  }

  showSiteInduction(historyData: SiteInductionHistoryLogViewModel) {
    this.currentSiteInductionHistoryLog = null;
    this.currentSiteInductionId = null;
    setTimeout(() => {
      this.currentSiteInductionId = historyData.siteInductionId;
      this.currentSiteInductionHistoryLog = historyData;
    }, 100);
  }

  getSiteInductionIcon(siteInductionLog: SiteInductionHistoryLogViewModel) {
    switch (siteInductionLog.status) {
      case SiteInductionStatus.ApprovedByHost:
      case SiteInductionStatus.AutoApproved:
        return "fa-envelope";
      case SiteInductionStatus.RejectByHost:
        return "fa-times";
      case SiteInductionStatus.PendingToApprove:
        return "fa-history";
      default:
        return "";
    }
  }

  showEntireReviewPanel() {
    return this.currentSiteInductionHistoryLog?.reviewMode != InductionReviewMode.Hidden;
  }

  showReviewPanel() {
    return this.hasPermissionToReview &&
      (this.currentSiteInductionHistoryLog?.reviewMode == InductionReviewMode.RequestRevision ||
        this.currentSiteInductionHistoryLog?.reviewMode == InductionReviewMode.ApproveReject);
  }

  getCommentPanelContent() {
    return (this.currentSiteInductionHistoryLog?.requestRevisionDescription?.length ?? 0) ?
      this.currentSiteInductionHistoryLog?.requestRevisionDescription :
      (this.currentSiteInductionHistoryLog?.approveDescription?.length ?? 0) ?
        this.currentSiteInductionHistoryLog?.approveDescription :
        (this.currentSiteInductionHistoryLog?.rejectDescription?.length ?? 0) ?
          this.currentSiteInductionHistoryLog?.rejectDescription :
          "No Comment";
  }

  getStyleCssClass() {
    return this.currentSiteInductionHistoryLog?.status == SiteInductionStatus.PendingToApprove ? 'warning' :
      this.currentSiteInductionHistoryLog?.status == SiteInductionStatus.ApprovedByHost ||
        this.currentSiteInductionHistoryLog?.status == SiteInductionStatus.AutoApproved ? 'success' :
        'danger';
  }

  isHistoryPanelOpen(historyData: SiteInductionHistoryLogViewModel) {
    if (historyData.siteInductionId == this.siteInductionId)
      return true;
    var historyDataIndex = this.historyLogData.findIndex(x => x.siteInductionId == historyData.siteInductionId);
    if (historyDataIndex == 1 && this.historyLogData[0].status == SiteInductionStatus.PendingToApprove)
      return true;
    return false;
  }

  getStatusText(status: SiteInductionStatus) {
    return this.siteInductionStatusPipe.transform(status);
  }

}
