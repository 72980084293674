import { Pipe, PipeTransform } from '@angular/core';
import { SiteSupplierDocumentStatus } from "../enums/site-supplier-document-status";

@Pipe({
  name: 'supplierDocumentTypeStatusIcon'
})
export class SupplierDocumentTypeStatusIconPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    if (value == SiteSupplierDocumentStatus.Unrequested) return 'fa fa-envelope text-muted';
    if (value == SiteSupplierDocumentStatus.Requested) return 'fa fa-envelope text-dark';
    if (value == SiteSupplierDocumentStatus.Pending) return 'fa fa-history text-info';
    if (value == SiteSupplierDocumentStatus.Approved) return 'fa fa-check text-success';
    if (value == SiteSupplierDocumentStatus.Deleted) return 'fa fa-trash-alt text-danger';
    return 'fa fa-times text-danger';
  }

}
