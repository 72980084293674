export enum Table {
  ActivityReport = 0,
  ContactTracingReport = 1,
  InventoryReport = 2,
  InductionReport = 3,
  ExternalCompanyReport = 4,
  CardReport = 5,
  FormReport = 6,
  Approval = 7,
  SiteAsset = 8,
  PermitReport = 9,
  AssetReport = 10,
  SWMSReport = 11,
  ProcoreSyncMonitoringReport = 12,
  Workflow = 13,
  LiveAttendance = 14,
  SupplierAsset = 15,
  SiteBriefingReport = 16,
  SiteBriefingReportSignature = 17,
  SupplierDocumentSignOffReport = 18,
  SiteBriefingSignOffReport = 19,
  WorkerProfileCards = 20,
  WorkerProfileHistory = 21,
  UserDirectory = 22,
}
