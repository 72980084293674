import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { deepCloneJson } from 'src/app/helpers/general-functions';
import { CheckedVisitorType } from 'src/app/models/visitor-type';
import { VisitorTypeService } from 'src/app/services/visitor-type.service';
import { FormControl } from "@angular/forms";

@Component({
  selector: 'app-visitor-type-selector',
  templateUrl: './visitor-type-selector.component.html',
  styleUrls: ['./visitor-type-selector.component.scss']
})
export class VisitorTypeSelectorComponent implements OnInit {
  visitorTypes: CheckedVisitorType[];
  limited: boolean = false;

  @Input() displayAsSingleSelectBox: boolean = false;
  @Input() emptyItemText: string = 'Please select a visitor';

  _selectedVisitorIds: number[] = [];

  @Input()
  set selectedVisitorIds(value: number[]) {
    this._selectedVisitorIds = deepCloneJson(value);
    if ((value?.length ?? 0) > 0) {
      if (this.displayAsSingleSelectBox) {
        this.selectedVisitorType.setValue(value[0]);
      }
      this.limited = true;
    }
  }

  selectedVisitorType: FormControl = new FormControl();

  @Output() selectedVisitorIdsChange = new EventEmitter();
  constructor(private visitorTypeService: VisitorTypeService) { }


  emitChanges() {
    if (!this.displayAsSingleSelectBox) {
      var value = this.visitorTypes.filter(x => x.checked).map(x => x.id);
      this.selectedVisitorIdsChange.emit(value);
    } else {
      this.selectedVisitorIdsChange.emit(this.visitorTypes.find((vt) => vt.id == this.selectedVisitorType.value));
    }
  }

  ngOnInit() {
    this.visitorTypeService.cacheGetAllVisitorTypes().subscribe(res => {
      this.visitorTypes = res.map(x => {
        return {
          id: x.id,
          title: x.title,
          checked: this._selectedVisitorIds?.some(y => x.id == y)
        } as CheckedVisitorType;
      });
    })
    this.selectedVisitorType.valueChanges.subscribe((res) => {
      if (res === null || res === undefined) {
        this.selectedVisitorType.setValue(null, { emitEvent: false });
      }
    })
  }


  onSwitchStateChange($event) {
    this.limited = $event;
    if ($event == false) {
      for (let index = 0; index < this.visitorTypes.length; index++) {
        const element = this.visitorTypes[index];
        element.checked = false;
      }
    }

    this.emitChanges();
  }

}
