import { Component, Input, OnInit } from '@angular/core';
import { DocumentProvider } from 'src/app/enums/document-provider.enum';
import { SiteAttendanceDocumentViewModel } from 'src/app/models/site-attendance-document-viewmodel';
import { SiteQuestionAnswerViewModel } from 'src/app/models/site_question_answer_view_model';

@Component({
  selector: 'obc-document-answer-renderer',
  templateUrl: './document-answer-renderer.component.html',
  styleUrls: ['./document-answer-renderer.component.scss']
})
export class DocumentAnswerRendererComponent implements OnInit {
  DocumentProvider = DocumentProvider;
  @Input() document: SiteAttendanceDocumentViewModel;
  @Input() set questionAnswer(value: SiteQuestionAnswerViewModel) {
    this.document = {
      fileUrl: value.fileUrls?.length > 0 ? value.fileUrls[0] : '',
      additionalFileUrls: value.fileUrls?.length > 1 ? value.fileUrls.slice(1, value.fileUrls.length) : [],
      documentTitle: value.documentTitle,
      userDocumentId: value.userDocumentId,
      expireDate: value.documentExpireDate,
      siteInductionId: value.siteInductionId,
      documentTypeTitle: value.documentTypeTitle,
    };
  }

  constructor() { }

  ngOnInit(): void {
  }

  isExpiredDocument(doc: SiteAttendanceDocumentViewModel): boolean {
    return doc.expireDate != null && new Date(doc.expireDate) < new Date();
  }

}
