export enum SiteSupplierDocumentStatus {
  Unrequested = -1,
  Requested = 0,
  Pending = 1,
  Approved = 2,
  Declined = 3,
  Deleted = 4,
}

export enum SiteSupplierDocumentVersionStatus {
  Pending = 1,
  Approved = 2,
  Declined = 3,
  Deleted = 4,
}
