export enum QuestionType {
  Boolean = 0,
  SingleSelect = 1,
  MultiSelect = 2,
  Text = 3,
  File = 4,
  RichText = 5,
  Date = 6,
  SignaturePad = 7,
  AssetField = 8,
  ProfileInfo = 9,
  EmergencyContact = 10,
}
