import * as moment from 'moment';
export const timeFormat = "HH:mm";
export const dateFormat = "DD/MM/YYYY";
export const dateTimeFormat = "DD/MM/YYYY, hh:mm a";
export const dateTimeFormatWithSeconds = "DD/MM/YYYY, hh:mm:ss a";
export const triggerDateTimeFormat = "YYYY-MM-DDT00:00:00.000";

export function parseDate(str) {
  try {
    return moment(str, dateFormat).toDate();
  } catch {
    return null;
  }
}

export function formatDate(date: any, format: string) {
  return moment(date).format(format);
}

export function parseDateTime(str, ignoreFormat?: boolean) {
  try {
    var m = ignoreFormat == true ? new Date(Date.parse(str)) : moment(str, dateTimeFormat).toDate();
    return  m;
  } catch {
    return null;
  }
}

export function parseDateTime2(str, format: string) {
  try {
    var m = moment(str, format ?? dateTimeFormat).toDate();
    return  m;
  } catch {
    return null;
  }
}

export function formatDateTime(date) {
  try {
    return moment(date).format(dateTimeFormat);
  } catch {
    return null;
  }
}

export function parseUTCDateTimeToLocalDateTime(str) {
  try {
    return moment.utc(str).local().toDate();
  } catch {
    return null;
  }
}
