<div class="row">
  <div *ngIf="referer && referer.length > 0" class="col-12 col-lg-8 mb-1">
    <button class="btn btn-default" [routerLink]="['/'+referer]">
      <i class="fas fa-arrow-left mr-2"></i>
      Return to Sites
    </button>
  </div>
  <div class="col-md-12">
    <div class="card">
      <obc-loader [show]="inProgress"></obc-loader>
      <header class="card-header">
        <div class="d-flex flex-row justify-content-between">
          <div>Site Asset Selection</div>
        </div>
      </header>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <form [formGroup]="assetRegisterFormgroup" class="row">
              <div class="col-md-4 col-12">
                <div class="form-group" *ngIf="isInSupplierMode == false">
                  <label>Select Site</label>
                  <app-site-selector #sl [required]="true" (siteSelect)="changeSiteId($event)" [siteId]="selectedSiteId">
                  </app-site-selector>
                </div>
              </div>
              <div class="col-md-4"></div>
              <div class="form-group col-md-4 col-12 d-flex align-items-center justify-content-end">
                <button type="button" *ngIf="showAddNewAssetButton" [disabled]="!assetRegisterFormgroup.valid"
                        style="margin-top:15px;" class="btn btn-info pull-right" (click)="onOpenRegisterComponent(assetRegisterPanel)">
                  Register New Asset
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="row mt-1">

        </div>
        <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>
          <ng-template obcGridTemplateName="registeredOperators" let-item="data">
            <div class="smaller-font" *ngIf="item.operators">
              <ul class="simple-post-list">
                <li class="p-1 d-flex flex-row justify-content-between align-items-center"
                    *ngFor="let operator of item.operators">
                  <div class="d-flex flex-fill align-items-center">
                    <i tooltip="Rejected" *ngIf="operator.status == SiteAssetRequestApproveStatus.Rejected"
                       class="fas fa-times text-danger"></i>
                    <i tooltip="Approved"
                       *ngIf="operator.status == SiteAssetRequestApproveStatus.Approved || operator.status == SiteAssetRequestApproveStatus.AutoApproved"
                       class="fas fa-check text-success"></i>
                    <i tooltip="Pending" *ngIf="operator.status == SiteAssetRequestApproveStatus.Pending"
                       class="fa fa-history text-info"></i>
                    <div class="ml-2">
                      {{operator.operatorUserFullName}}
                      {{operator.operatorUserMobile ? ' (' + (operator.operatorUserMobile | mobile) + ')' :
                      ''}}
                    </div>
                  </div>
                  <div class="d-flex justify-content-end align-self-start">
                    <button
                      *ngIf="operator.formDataId && allowToApproveAnything && operator.status == SiteAssetRequestApproveStatus.Pending"
                            type="button" class="btn btn-info btn-sm mr-1" style="min-width: 65px"
                            (click)="onOpenFormDetail(formDetails, operator.formDataId, operator.siteAssetOperatorId)">
                      Review
                    </button>
                    <button
                      *ngIf="!operator.formDataId && allowToApproveAnything && operator.status == SiteAssetRequestApproveStatus.Pending"
                            type="button" class="btn btn-success btn-sm mr-1" style="min-width: 65px"
                            (click)="onApproveRejectOperator(operator,true)">
                      Approve
                    </button>
                    <button
                      *ngIf="!operator.formDataId && allowToApproveAnything && operator.status == SiteAssetRequestApproveStatus.Pending"
                            type="button" class="btn btn-danger btn-sm mr-1" style="min-width: 65px"
                            (click)="onApproveRejectOperator(operator,false)">
                      Reject
                    </button>
                    <button *ngIf="operator.formDataId && operator.status != SiteAssetRequestApproveStatus.Pending"
                            type="button" class="btn btn-warning btn-sm mr-1" style="min-width: 95px"
                            (click)="onOpenFormDetail(formDetails, operator.formDataId, operator.siteAssetOperatorId,FormType.AssetOperator)">
                      View Details
                    </button>
                  </div>
                </li>
              </ul>
            </div>
            <div class="smaller-font" *ngIf="item.operators?.length == 0" style="color: #dbabab">
              There is no operator registered yet
            </div>
            <div class="d-flex justify-content-end mt-2" *ngIf="item.allowAddOperators">
              <button type="button" *ngIf="item.allowAddOperators" class="btn btn-primary btn-sm mr-1"
                      (click)="onRegisterOperator(operatorRegisterPanel, item)">
                Register Operator
              </button>
            </div>
          </ng-template>
          <ng-template obcGridTemplateName="siteAssetCategory" let-item="data">
            {{item.assetCategory}}
            <obc-temporarily-asset-icon *ngIf="item.isTemporarilyAsset"></obc-temporarily-asset-icon>
          </ng-template>
          <ng-template obcGridTemplateName="siteAssetStatus" let-item="data">
            <div>
              <i tooltip="Rejected" *ngIf="item.status == SiteAssetRequestApproveStatus.Rejected"
                 class="fas fa-times text-danger"></i>
              <i tooltip="Approved"
                 *ngIf="item.status == SiteAssetRequestApproveStatus.Approved || item.status == SiteAssetRequestApproveStatus.AutoApproved"
                 class="fas fa-check text-success"></i>
              <i tooltip="Pending" *ngIf="item.status == SiteAssetRequestApproveStatus.Pending"
                 class="fa fa-history text-info"></i>
              {{item.status | siteAssetRequestApproveStatus}}
              {{item.approveDateTime &&
              (item.status == SiteAssetRequestApproveStatus.Approved ||
              item.status == SiteAssetRequestApproveStatus.AutoApproved ||
              item.status == SiteAssetRequestApproveStatus.Rejected) ?
              ' (' + (item.approveDateTime | datetime) + ')' : ''}}
            </div>
            <div *ngIf="(item.status == SiteAssetRequestApproveStatus.Approved ||
          item.status == SiteAssetRequestApproveStatus.Rejected) && item.approverUserFullName">
              <i tooltip="Approved By" class="fa fa-user text-info"></i>
              {{item.approverUserFullName}}
            </div>
            <div class="col-md-12 d-flex justify-content-end"
                 *ngIf="item.status == SiteAssetRequestApproveStatus.Pending && hasUserApproveRejectPermissions">
              <button *ngIf="allowToApproveAnything" type="button" class="btn btn-info btn-sm mr-1"
                      (click)="onOpenFormDetail(formDetails, item.formDataId, item.siteAssetId)">
                Review
              </button>
            </div>
          </ng-template>
          <ng-template obcGridTemplateName="checkinHistory" let-checkinHistory let-item="data">
            <div class="smaller-font" *ngIf="item.siteAssetStatusInfo">
              <div *ngIf="item.siteAssetStatusInfo.isCheckedIn">
                {{item.siteAssetStatusInfo.checkInByUserFullName}}
                {{item.siteAssetStatusInfo.checkInByUserMobile ? ' (' +
                (item.siteAssetStatusInfo.checkInByUserMobile | mobile) + ')' : ''}}
              </div>
              <div *ngIf="item.siteAssetStatusInfo.isCheckedIn">
                <i tooltip="Check In Date" class="fas fa-calender text-warning"></i>
                {{item.siteAssetStatusInfo.checkInDate | datetime}}
              </div>
              <div *ngIf="!item.siteAssetStatusInfo.isCheckedIn && item.siteAssetStatusInfo.lastCheckInDate != null">
                <i tooltip="Last Check In Date" class="fas fa-sign-in-alt text-success"></i>
                {{item.siteAssetStatusInfo.lastCheckInDate | datetime}}
              </div>
              <div *ngIf="!item.siteAssetStatusInfo.isCheckedIn && item.siteAssetStatusInfo.lastCheckOutDate != null">
                <i tooltip="Last Check Out Date" class="fas fa-sign-out-alt text-danger"></i>
                {{item.siteAssetStatusInfo.lastCheckOutDate | datetime}}
              </div>
            </div>
            <div
              *ngIf="item.status == SiteAssetRequestApproveStatus.Approved || item.status == SiteAssetRequestApproveStatus.AutoApproved"
                 class="col-md-12 d-flex justify-content-end">

              <ng-container *ngIf="hashLink == null || hashLink === undefined || hashLink?.length == 0">
                <!--[hashLink]="hashLink"-->
                <obc-site-asset-check-in-out *ngIf="item.isTemporarilyAsset" [selectedSiteAsset]="item"
                                             [isCheckinRequested]="!item.siteAssetStatusInfo?.isCheckedIn" [selectedSite]="selectedSite"
                                             [externalSiteSupplierId]="externalSiteSupplierId" (hide)="closeModal()"
                                             (onRefresh)="onFetchSiteAssets()">
                </obc-site-asset-check-in-out>
                <ng-container *ngIf="!item.isTemporarilyAsset">
                  <button type="button" class="btn btn-info btn-sm mr-1"
                          *ngIf="item.siteAssetStatusInfo?.isCheckedIn != true"
                          (click)="onCheckInOutSiteAsset(checkInOutTemplate, item, true)">
                    Check-In
                  </button>
                  <button type="button" class="btn btn-info btn-sm mr-1" *ngIf="item.siteAssetStatusInfo?.isCheckedIn"
                          (click)="onCheckInOutSiteAsset(checkInOutTemplate, item, false)">
                    Check-Out
                  </button>
                </ng-container>

                <button type="button" class="btn btn-warning btn-sm mr-1"
                        (click)="onShowAssetCheckInOutHistory(assetCheckInOutHistory, item)">
                  History
                </button>
              </ng-container>
            </div>
          </ng-template>
          <ng-template obcGridTemplateName="viewDetails" let-viewDetails let-item="data">
            <button type="button" class="btn btn-warning btn-sm mr-1"
                    (click)="onOpenFormDetail(formDetails, item.formDataId, item.siteAssetId,FormType.AssetRegistration)">
              View Details
            </button>
          </ng-template>
          <ng-template obcGridTemplateName="assetName" let-item="data">
            <span *ngIf="!item.isNickNameEditing">{{item.assetName}}</span>
            <input type="text" class="form-control"
                   *ngIf="item.isNickNameEditing"
                   [(ngModel)]="nickname">
            <button class="btn" (click)="nickname=item.assetName;item.isNickNameEditing = true;" *ngIf="!item.isNickNameEditing"><i class="fa fa-edit"></i></button>
            <button class="btn" (click)="saveNickname(item,nickname)" *ngIf="item.isNickNameEditing"><i class="fa fa-check"></i></button>
            <button class="btn" (click)="item.isNickNameEditing = false;" *ngIf="item.isNickNameEditing"><i class="fa fa-times"></i></button>
          </ng-template>
        </app-base-grid>
      </div>
    </div>
  </div>
</div>


<ng-template #assetRegisterPanel>
  <obc-register-site-asset [site]="selectedSite" [supplierId]="supplierId"
                           [siteCompanyTitle]="manageSiteAssetMode == ManageSiteAssetMode.ExternalCompany? siteCompanyName:''"
                           [siteCompanyId]="manageSiteAssetMode == ManageSiteAssetMode.ExternalCompany? siteCompanyId : null"
                           [externalSiteSupplierId]="externalSiteSupplierId" [manageSiteAssetMode]="manageSiteAssetMode"
                           [modalReference]="bsModalRef" [onlyTempAssets]="onlyTempAssets" [supplierHashLink]="hashLink" [mainCompanyId]="mainCompanyId"
                           (onSubmitSiteAsset)="onSubmitSiteAsset($event)">
  </obc-register-site-asset>
</ng-template>
<ng-template #operatorRegisterPanel>
  <obc-register-site-asset-operator [selectedSiteAsset]="selectedSiteAsset" [supplierId]="supplierId"
                                    [modalReference]="operatorBsModal" [siteId]="selectedSite?.id" 
                                    (onRegisterOperator)="onRegisterOperatorDone()" [hashLink]="hashLink">
  </obc-register-site-asset-operator>
</ng-template>
<ng-template #approveRejectTemplate>
  <obc-approve-reject [entityId]="selectedSiteAssetId" [endDate]="selectedEntityEndDate"
                      [entityType]="selectedEntityTypeForReviewModal" [showEndDate]="selectedItemIsEnableFormExpiryDate"
                      (onRefresh)="closeModal(); onFetchSiteAssets();">
  </obc-approve-reject>
</ng-template>

<ng-template #formDetails>
  <obc-form-details [entityId]="selectedSiteAssetId" [formDataId]="selectedFormDataId" [hashLink]="hashLink"
                    [formType]="formType" (onRefesh)="closeModal(); onFetchSiteAssets()">
  </obc-form-details>
</ng-template>

<ng-template #assetCheckInOutHistory>
  <obc-site-asset-checkin-history [assetIdsHistoryModel]="selectedAssetIds">
  </obc-site-asset-checkin-history>
</ng-template>

<ng-template #checkInOutTemplate>
  <obc-site-asset-check-in-out [displayAsApproveRejectButton]="false" [selectedSiteAsset]="selectedSiteAsset"
                               [isCheckinRequested]="isCheckinRequested" [selectedSite]="selectedSite"
                               [externalSiteSupplierId]="externalSiteSupplierId" (hide)="closeModal()" (onRefresh)="onFetchSiteAssets()">
  </obc-site-asset-check-in-out>
</ng-template>
