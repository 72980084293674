<div class="row">
  <div class="col-md-12">
    <div class="card">
      <obc-loader [show]="inProgress"></obc-loader>
      <header class="card-header" *ngIf="!isReportMode">Induction Report</header>
      <div class="card-body">
        <div *ngIf="!isReportMode">
          <div class="row">
            <b> Include : </b>
          </div>
          <div class="row">
            <div class="col-xl-3 col-md-6">
              <span class="d-inline-block mt-2 mr-1">Expired Inductions: </span>
              <obc-switch [trueLabel]="'Yes'" [falseLabel]="'No'" [value]="includeExpiredInductions"
              (valueChanged)="onIncludeExpiredInductionsChanged($event)">
              </obc-switch>
            </div>
            <div class="col-xl-3 col-md-6">
              <span>
                <span class="d-inline-block mt-2 mr-1"
                  >Inactive Inductions:
                </span>
                <obc-switch
                  [trueLabel]="'Yes'"
                  [falseLabel]="'No'"
                  [value]="includeInactiveInductions"
                  (valueChanged)="onIncludeInactiveInductionsChanged($event)"
                >
                </obc-switch>
              </span>
            </div>
          </div>
        </div>
        <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>
          <ng-template obcGridTemplateName="inductionTitle" let-item="data">
            <span *ngIf="isShowTemplateColumns('inductionTitle','inductionTitle')">
              {{item.inductionTitle}}
            </span>

            <i *ngIf="isShowTemplateColumns('inductionTitle','isExpired') && item.isExpired" class="m-1"
              style="font-size: small;color: red;"> Expired </i>
          </ng-template>
          <ng-template
            obcGridTemplateName="viewDetails"
            let-viewDetails
            let-item="data"
          >
            <obc-dropdown-button>
              <button
                dropdown-button
                class="btn btn-info"
                (click)="
                  openModal(
                    inductionRequestRevision,
                    item.siteInductionId,
                    'modal-90p'
                  )
                "
              >
                {{
                  isReadyToRequestRevision(item)
                    ? "Request Revision"
                    : item.status == SiteInductionStatus.PendingToApprove
                    ? "Review"
                    : "View Induction"
                }}
              </button>
              <ul
                id="dropdown-split"
                dropdown-items
                class="dropdown-menu"
                role="menu"
                aria-labelledby="button-split"
              >
                <li role="menuitem">
                  <a id="button-basic" target="_blank" href="{{'/api/Induction/export-pdf/'+item.siteInductionId}}"
                  class="dropdown-item cursor-pointer d-flex align-items-center">
                    <i class="fa fa-file-pdf mr-2"></i> Download PDF
                  </a>
                </li>
              </ul>
            </obc-dropdown-button>
          </ng-template>
          <ng-template #inductionInfo>
            <obc-site-induction-detail [siteInductionId]="selectedSiteInductionId" (result)="closeModal($event)">
            </obc-site-induction-detail>
          </ng-template>
          <ng-template #inductionRequestRevision>
            <obc-request-revision [siteInductionId]="selectedSiteInductionId"
              (onRefresh)="onInductionUpdated()"></obc-request-revision>
          </ng-template>
        </app-base-grid>
      </div>
    </div>
  </div>
</div>

<ng-template #questionAnswer>
  <obc-question-answer [questionAnswerInput]="questionAnswerInputModel" (result)="closeModal($event)">
  </obc-question-answer>
</ng-template>
