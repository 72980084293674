import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FileType } from 'src/app/enums/file-type.enum';
import { SiteAnnouncementDisplayTime } from 'src/app/enums/site-announcement-display-time.enum';
import { SiteAnnouncementForceViewMode } from 'src/app/enums/site-announcement-force-view-mode';
import { OptionalMandatoryState } from 'src/app/enums/site-document-state.enum';
import { BaseSiteAnnouncementViewModel } from 'src/app/models/announcement-viewmodel';
import { AnonymousAnnouncementVisitModel, VisitStatus } from 'src/app/models/announcement-visit';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-checkin-announcement-renderer',
  templateUrl: './checkin-announcement-renderer.component.html',
  styleUrls: ['./checkin-announcement-renderer.component.scss']
})
export class CheckinAnnouncementRendererComponent implements OnInit {
  @Input() ann: BaseSiteAnnouncementViewModel;
  @Input() notifyVisits = true;
  @Input() anonymousAttendanceKey: string = null;
  @Input() largeButton: boolean = true;
  @Input() validateComponent: boolean = false;
  @Output() onAnnouncementVisit = new EventEmitter<number>();
  @Output() onCheckedMandatorySignOff = new EventEmitter<number>();
  bsModalRef = null;

  AnnouncementType = FileType;
  optionalMandatoryState = OptionalMandatoryState;
  forceViewMode = SiteAnnouncementForceViewMode;
  constructor(private announcementSertvice: AnnouncementService,
    private modalService: ModalService,
    private bsModalService: BsModalService
  ) { }
  ngOnInit() {
  }

  @Output('onFileViewed') onFileViewed = new EventEmitter();
  FileType = FileType;

  fileHasContent(url, type) {
    let extension = url.split(/[#?]/)[0].split('.').pop().trim();
    var binaryFileTypesOpen = ['xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx'];
    this.onFileOpened(url);

    return type == FileType.Pdf || type == FileType.Image ||
      (type == FileType.Binary && binaryFileTypesOpen.includes(extension));
  }

  onOpenFileViewer(template: any) {
    //
    this.bsModalRef = this.bsModalService.show(template, {
      class: "modal-xl modal-full-width",
      ignoreBackdropClick: false,
    })
  }

  onFileOpened(fileUrl: string) {
    this.onFileViewed.emit(fileUrl);
    this.ann.isVisited = true;
    this.onAnnouncementVisit.emit(this.ann.announcementId);
  }

  closeModal() {
    if (this.bsModalRef)
      this.bsModalRef.hide();
  }

  onClickAnnouncement() {
    this.ann.isVisited = true;
    this.onAnnouncementVisit.emit(this.ann.announcementId);

    // TODO display text annoncement
    if (this.ann.type == FileType.Text)
      this.modalService.info(this.ann.data);

    // if (this.notifyVisits) {
    //   var model = {
    //     announcementId: this.ann.announcementId,
    //     anonymousAttendanceKey: this.anonymousAttendanceKey,
    //     visitStatus: VisitStatus.Full,
    //   } as AnonymousAnnouncementVisitModel;

    //   this.announcementSertvice.NotifyAnonymousAnnouncementVisit(model)
    //     .subscribe(res => {
    //       if (!res) // expired key
    //       {
    //         this.modalService.error("your session key is expired, reloading page ...");
    //         setTimeout(() => {
    //           window.location.reload();
    //         }, 5000);
    //       }
    //     });
    // }
  }
  onCheckSignOff() {
    this.ann.isCheckedMandatorySignOff = true;
    this.onCheckedMandatorySignOff.emit(this.ann.announcementId);
  }
}
