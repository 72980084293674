<div [formGroup]="formGroup">
  <div class="form-group col-md-12 col-sm-12">
    <label>Action</label>
    <select class="custom-select form-control" formControlName="action">
      <option [ngValue]="null" disabled>Choose Action</option>
      <option [ngValue]="TriggerAction.Email">{{getTriggerActionFriendlyName(TriggerAction.Email)}}</option>
    </select>
  </div>
  <div class="col-md-12">
    <label>Operator</label>
    <select class="custom-select form-control" formControlName="operator">
      <option [ngValue]="null" disabled>Choose Operator</option>
      <option *ngFor="let item of getCompareOperatorItems()" [ngValue]="+item.id">{{item.name}}</option>
    </select>
  </div>
  <div class="form-group col-md-12 mt-3">
    <label>Value</label>
    <ng-container *ngIf="toolDynamicField.type === ToolDynamicFieldType.Date; else toolDynamicFieldElse">
      <obc-input-date-time-picker formControlName="value" [ngModel]="formGroup.controls.value.value"
        [mode]="DatetimePickerMode.Calender">
      </obc-input-date-time-picker>
      <!-- <input [owlDateTime]="dt1" class="form-control" formControlName="value" [owlDateTimeTrigger]="dt1"
        [ngModel]="formGroup.controls.value.value">
      <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time> -->
    </ng-container>
    <ng-template #toolDynamicFieldElse>
      <input [attr.type]="toolDynamicField.type == ToolDynamicFieldType.Number ? 'number': 'text'" class="form-control"
        formControlName="value" />
    </ng-template>
  </div>
  <div class="col-md-12">
    <obc-email-template [showSendEmailToSiteSupervisor]="false" formControlName="parameters"> </obc-email-template>
  </div>

  <div class="col-md-12 text-right mt-2">
    <button class="btn btn-danger m-1" (click)="close.emit()">Close</button>
    <button (click)="upsert()" [disabled]="!formGroup.valid" class="btn btn-primary m-1">
      <ng-container *ngIf="_trigger?.id">Update</ng-container>
      <ng-container *ngIf="!_trigger?.id">Add Trigger</ng-container>
    </button>
  </div>
</div>