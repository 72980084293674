import { Component, Input, OnInit } from '@angular/core';
import { finalize } from "rxjs/operators";
import { FormControl, Validators } from "@angular/forms";
import {
  SiteSupplierDocumentVersionCommentModel,
  SiteSupplierDocumentVersionCommentViewModel
} from "../../../models/supplier-document/site-supplier-document-version-comment-model";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SupplierDocumentService } from "../../../services/supplier-document.service";
import { ToastrService } from "ngx-toastr";
import { ModalService } from "../../../services/modal.service";

@Component({
  selector: 'obc-site-supplier-document-version-comments',
  templateUrl: './site-supplier-document-version-comments.component.html',
  styleUrls: ['./site-supplier-document-version-comments.component.scss']
})
export class SiteSupplierDocumentVersionCommentsComponent implements OnInit {

  @Input() set comments(value: SiteSupplierDocumentVersionCommentViewModel[]) {
    this.commentsList = value ?? [];
  }

  commentsList: SiteSupplierDocumentVersionCommentViewModel[] = [];
  @Input() displayAsModal: boolean = true;
  @Input() siteSupplierDocumentVersionId: number;
  @Input() hasPermissionToAddSupplierDocumentComment: boolean;
  @Input() supplierDocCommentAcknowledgementText: string = 'Acknowledge';
  @Input() classes: string;
  @Input() noIcon: boolean = false;

  inProgress: boolean = false;
  commentsModalRef: BsModalRef;

  constructor(private bsModalService: BsModalService,
    private modalService: ModalService,
    private supplierDocumentService: SupplierDocumentService,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {
  }

  displayCommentsModal(template: any) {
    this.commentsModalRef = this.bsModalService.show(template, {
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });
  }

  closeCommentsModal() {
    this.commentsModalRef.hide();
  }

  /*Add New Comment*/
  hasAcknowledgment: boolean = false;
  notifySupplier: boolean = false;
  comment: FormControl = new FormControl('', [Validators.required]);
  addNewCommentModalRef: BsModalRef;

  displayAddNewCommentModal(template: any) {
    this.addNewCommentModalRef = this.bsModalService.show(template, {
      ignoreBackdropClick: true,
      class: 'modal-lg'
    });
  }

  closeNewCommentModal() {
    this.addNewCommentModalRef.hide();
  }

  isNewCommentFormValid(): boolean {
    return this.comment.valid || this.hasAcknowledgment === true;
  }

  submitNewComment() {
    if (this.isNewCommentFormValid()) {

    }
    this.supplierDocumentService.addSiteSupplierDocumentVersionComment({
      siteSupplierDocumentVersionId: this.siteSupplierDocumentVersionId,
      comment: this.comment.value,
      hasAcknowledgement: this.hasAcknowledgment,
      notifySupplier: this.notifySupplier,
    } as SiteSupplierDocumentVersionCommentModel)
      .pipe(finalize(() => {
        this.inProgress = false
      }))
      .subscribe(res => {
        if (res.success == true) {
          this.toastr.success('Comment Submitted Successfully.');
          this.commentsList = res.data ?? [];
          this.closeNewCommentModal();
          this.resetNewCommentControls();
        } else
          this.modalService.error(`Failed.`);
      }, err => {
        this.modalService.error(err)
      })
  }

  resetNewCommentControls() {
    this.comment.setValue('');
    this.hasAcknowledgment = false;
    this.notifySupplier = false;
  }
}
