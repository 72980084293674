import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { SecurityLogType } from 'src/app/enums/security-log-type.enum';
import { PaginationInfo } from 'src/app/models/pagination-info';
import { PagingViewModel } from 'src/app/models/site-list-paging-view-model';
import { LayoutService } from 'src/app/services/layout.service';
import { ModalService } from 'src/app/services/modal.service';
import { SecurityLogService } from 'src/app/services/security-log-service.service';
import { SecurityLogDetailsViewModel, SecurityLogQuery, SecurityLogViewModel } from 'src/app/view-models/security-log-view-model';

@Component({
  selector: 'obc-security-log-report',
  templateUrl: './security-log-report.component.html',
  styleUrls: ['./security-log-report.component.scss']
})
export class SecurityLogReportComponent implements OnInit {
  DatetimePickerMode = DatetimePickerMode;
  inProgress: boolean = false;
  selectedEntityType: SecurityLogType = null;
  SecurityLogType = SecurityLogType;
  startDate: Date;
  endDate: Date;

  siteId?: number = null;

  list: SecurityLogViewModel[];
  totalRecords: number;

  pageNumber: number = 0;
  pageSize: number = 30;
  details: SecurityLogDetailsViewModel;
  mode: string;

  constructor(
    private securityLogService: SecurityLogService,
    private layoutService: LayoutService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.layoutService.header = "Audit Logs";

    this.route.params.subscribe((res: Params) => {
      try {
        if (res['mode'] == "system")
          this.mode = "system";
        else
          this.mode = "company";
      } catch (error) {
        this.mode = "company";
      }
      this.loadReport();
    });
  }

  loadReport() {
    this.inProgress = true;

    let request: Observable<PagingViewModel<SecurityLogViewModel>>;
    if (this.mode == "company") {
      request = this.securityLogService.report({
        type: this.selectedEntityType,
        startDate: this.startDate,
        endDate: this.endDate,
        siteId: this.siteId,
        pagingInfo: { pageNumber: this.pageNumber, pageSize: this.pageSize, }
      } as SecurityLogQuery);
    }
    else if (this.mode == "system") {
      request = this.securityLogService.systemReport({
        type: this.selectedEntityType,
        startDate: this.startDate,
        endDate: this.endDate,
        siteId: this.siteId,
        pagingInfo: { pageNumber: this.pageNumber, pageSize: this.pageSize, }
      } as SecurityLogQuery);
    }

    request.subscribe(res => {
      this.inProgress = false;
      this.list = res.data;
      this.totalRecords = res.totalCount;
    });
  }

  onPageChange(paginationInfo: PaginationInfo) {
    this.pageSize = paginationInfo.pageSize;
    this.pageNumber = paginationInfo.pageNumber;
    this.loadReport();
  }

  showDetails(item: SecurityLogViewModel, template: any) {
    this.inProgress = true;

    let request: Observable<SecurityLogDetailsViewModel>;
    if (this.mode == "company") {
      request = this.securityLogService.getLogDetails(item.id);
    }
    else if (this.mode == "system") {
      request = this.securityLogService.getSystemLogDetails(item.id);
    }

    request.subscribe(res => {
      this.inProgress = false;
      this.details = res;
      if (this.details)
        this.modalService.show(template, 'modal-lg');
      else
        this.toastr.error('Access denied!');
    });
  }

  onSiteIdSelect(siteId: number) {
    this.siteId = siteId;
  }

}

