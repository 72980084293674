<obc-loader [show]="loading"></obc-loader>
<div *ngIf="siteLocations && parentSelectedSite == null">
  <select style="padding-bottom: 0 !important;" class="form-control" [(ngModel)]="selectedSite"
    (change)="siteChanged()">
    <option [ngValue]="null">Select a site</option>
    <option *ngFor="let siteLocation of siteLocations" [ngValue]="siteLocation">
      {{ siteLocation.siteName }}
    </option>
  </select>
</div>
<div *ngIf="parentSelectedSite != null" class="bold">
  <i class="fas fa-archway ml-1 text-primary"></i>
  {{parentSelectedSite.name}}
</div>
<div *ngIf="(siteLocations || parentSelectedSite) && selectedSite && isNewLocationRequested == false" class="row pt-2">
  <div class="col-md-12 d-flex justify-content-between">

      <select *ngIf="selectedSite.locations?.length > 1 || selectedSite.locations?.length == 0" style="height: 43px; border-bottom-right-radius: 0; border-top-right-radius: 0;"
              class="form-control"
               [formControl]="selectedLocation" >
        <option [value]="null">Select a location</option>
        <option *ngFor="let location of selectedSite.locations" [value]="location.id">
          {{ location.name }}
        </option>
      </select>
      <ng-container *ngIf="selectedSite.locations?.length == 1">
        <label class="mt-3 d-flex align-items-center" style="font-size: 17px;color: #5c5c5c;padding-left: 8px;">
          <div class="d-flex flex-column text-center mr-3">
            <i class="fa fa-chevron-right mr-2"></i>
            <span style="font-size: 11px;">Location</span>
          </div>
          <span>{{ selectedLocationModel?.name }}</span>
        </label>
      </ng-container>
      <button *ngIf="canAddNewLocation == true"
              style="width:43px;height:43px;border-bottom-left-radius: 0; border-top-left-radius: 0;" class="btn btn-sm btn-success align-self-center"
              (click)="onRequestToAddNewLocation()"><i class="fas fa-plus"></i></button>
  </div>
</div>
<div class="row mt-3" *ngIf="canAddNewLocation == true && isNewLocationRequested == true">
  <div class="col-md-12 d-flex justify-content-between">
    <input style="height: 43px; border-bottom-right-radius: 0; border-top-right-radius: 0;" class="form-control"
      type="text" placeholder="New Location" (input)="onAddLocation($event.target.value)">
    <button style="width:43px;border-bottom-left-radius: 0; border-top-left-radius: 0;" class="btn btn-primary"
      (click)="onRequestToSelectLocation()"><i class="fas fa-undo"></i></button>
  </div>
</div>
