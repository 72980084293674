<div *ngIf="mode != AccompanierMode.Disable">
  <h4>
    <div class="pull-left" style="transform: scale(0.7);">
      <i *ngIf="isFormArrayValid()" style="color: green;" class="fas fa-check-circle  mr-2"></i>
      <i *ngIf="!isFormArrayValid()" style="color: red;" class="fas fa-times-circle  mr-2"></i>
    </div>Accompanying Persons
  </h4>
  <hr />
  <div class="row">
    <div class="col-md-12">
      How many people do you want to add in this check-in?
      <input *ngIf="mode == 1" class="form-control ml-2" style="display: inline-block; width: 100px;" type="number"
        [formControl]="userInputPeopleCountFormControl" (change)="pushData()" />
      <input *ngIf="mode != 1" class="form-control ml-2" style="display: inline-block; width: 100px;" type="number"
        [disabled]="isDisablePeopleCount" [value]="peopleCount" />
    </div>
  </div>
  <div>
    <div class="row mt-2">
      <div class="col-md-12 table-responsive" *ngIf="peopleFormArray.controls?.length > 0">
        <table class="table table-bordered table-striped" style="width: 100%;">
          <thead>
            <tr>
              <td>Accompanier Info</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let personFormGroup of peopleFormArray.controls">
              <tr>
                <td>
                  <div class="row">
                    <div class="form-group col-md-4 col-sm-12 mb-0">
                      <input placeholder="First Name" class="form-control"
                        [formControl]="personFormGroup.get('firstName')">
                    </div>
                    <div class="form-group col-md-4 col-sm-12 mb-0">
                      <input placeholder="Last Name" class="form-control"
                        [formControl]="personFormGroup.get('lastName')">
                    </div>
                    <div class="form-group col-md-4 col-sm-12 mb-0">
                      <input placeholder="Mobile" class="form-control" [formControl]="personFormGroup.get('mobile')">
                    </div>
                  </div>
                  <div class="row" *ngIf="!personFormGroup.valid">
                    <div class="col-md-12">
                      <label style="font-size: small; color: red;" class="mb-0">Please Enter
                        <span *ngIf="!personFormGroup.get('firstName').valid"> First Name,</span> <span
                          *ngIf="!personFormGroup.get('lastName').valid"> Last Name,</span> <span
                          *ngIf="!personFormGroup.get('mobile').valid"> Valid Mobile</span></label>
                    </div>
                  </div>
                </td>
                <td>
                  <button class="btn btn-sm" (click)="removePerson(personFormGroup)">
                    <i class="fa fa-times"></i>
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="col-md-12">
        <button type="button" class="btn btn-info pull-right" (click)="addNewPerson()">
          Add Accompanying Person
        </button>
      </div>
    </div>
  </div>
  <hr />
</div>