<header class="header" [style.background-color]="layoutService.getBackgroundColor"
    [style.border-bottom-color]="layoutService.getBackgroundColor"
    [style.border-top-color]="layoutService.getBackgroundColor">
    <div class="logo-container" style="background: none;">
        <div>

            <h2 class="brand"><a href="/" class="logo">
                    <div [ngClass]="[layoutService.getLogoClass]"></div>
                </a><b>1</b><span>Bread</span><b>crumb</b></h2>
        </div>
        <div *ngIf="isAnonymous === false" (click)="layoutService.toggleSideBarInMobileMode()" class="d-md-none pointer toggle-sidebar-left">
            <i class="fas fa-bars" aria-label="Toggle sidebar"></i>
        </div>
        <div *ngIf="isAnonymous" class="d-flex mx-2 my-1">
          <obc-switch (switchChanged)="layoutService.toggleDarkMode($event)"
            [value]="layoutService.isInDarkMode" id="darklight" falseLabel="Light" trueLabel="Dark" class="ml-auto">
          </obc-switch>
        </div>
    </div>

    <div class="company-logo-container">
        <img *ngIf="userService?.currentCompany?.logoUrl" src="{{userService?.currentCompany?.logoUrl}}" />
    </div>

    <!-- start: search & user box -->
    <div class="header-right" style="background: none;">
        <span [style.background-image]="'none'" class="separator"></span>
        <!-- <button
            *ngIf="showEmergencyAlaram && userService.hasUserPermissionForCompany(userPermissions.SendEmergencyAlarm) && !isTemplateCompany"
            class="btn border-no transparent text-white" (click)="openModal(emergencyalaram)" title="Send Site Alert">
            <i class="fas fa-envelope pr-1"></i>
            Alarm
        </button> -->
      <div class="btn-group csv-header-container" dropdown placement="bottom right">
            <div *ngIf="(displayInfo && hasExportFeatureOnCompany()) || userScheduledReportLogs?.length" dropdownToggle
                 class="btn csv-header-popover-button dropdown-toggle" aria-controls="dropdown-basic">
                <div *ngIf="loadingScheduledReports" class="position-absolute" style="left: -10px; top: 8px;">
                    <obc-css-spinner [size]="17" [thickness]="2" [class]="'mr-2'"></obc-css-spinner>
                </div>
                <i *ngIf="!loadingScheduledReports" class="fas fa-file-export pr-1"></i>
                {{ csvExportTitle }}
            </div>
            <div class="p-4 csv-header-popover dropdown-menu dropdown-menu-right" (click)="onCsvPopoverClick($event)"
                 *dropdownMenu>
                <div class="no-data" *ngIf="userScheduledReportLogs?.length == 0">
                    There is no report to display
                </div>
                <div *ngFor="let scheduleReportLog of userScheduledReportLogs; let last = last">
                    <div class="d-flex">
                        <div class="w-100">
                            <div class="schedule-report-date">
                                <span>Requested at </span>
                                <span>
                                    <strong>{{ scheduleReportLog.createdAt | datetime }}</strong>
                                </span>
                            </div>
                            <div class="csv-header-item-title font-weight-bold">
                                {{ scheduleReportLog.csvTitle ?? 'No Title' }}
                            </div>
                            <div class="d-flex justify-content-between align-items-end">
                              <div class="d-flex flex-column">
                                <div>
                                  <span class="badge
                                  {{ scheduleReportLog.isInProgressState ? 'badge-danger' : 'badge-success' }}">
                                      {{ scheduleReportLog.status }}
                                    <ng-container *ngIf="scheduleReportLog.nextExecutionDateTime">
                                      {{ scheduleReportLog.nextExecutionDateTime | datetime:'MMMM Do YYYY, h:mm a' }}
                                    </ng-container>
                                  </span>
                                </div>
                                <div class="d-flex mt-2" *ngIf="scheduleReportLog.emails?.length">
                                  <span class="badge badge-info flex-wrap email-badge">
                                      Sent email to: {{ displayRecipients(scheduleReportLog.emails) }}
                                  </span>
                                </div>
                              </div>
                              <div class="ml-2">
                                <a *ngIf="scheduleReportLog.fileUrl" href="{{ scheduleReportLog.fileUrl}}" target="_blank">
                                  <i class="fa fa-download"></i>
                                </a>
                                <obc-css-spinner *ngIf="scheduleReportLog.isInProgressState" [size]="17" [thickness]="2"></obc-css-spinner>
                              </div>
                            </div>
                        </div>
                    </div>
                    <hr *ngIf="!last">
                </div>
            </div>
        </div>

        <button *ngIf="displayInfo" class="btn border-no transparent text-white" #notificationButton
                (click)="toggleNotificationMenu()" title="Notifications">
            <obc-css-spinner *ngIf="loadingNotification" [size]="17" [thickness]="2"></obc-css-spinner>
            <i *ngIf="!loadingNotification" class="fas fa-bell pr-1"></i>
            Notifications
            <span class="badge badge-danger"
                  style="position: relative; top:-7px;right:3px;">{{notificationCount}}</span>
        </button>
        <div *ngIf="displayInfo" id="userbox" #userbox class="userbox" [ngClass]="{'show' : showUserBox}">
            <a class="pointer" (click)="showUserBox = !showUserBox">
                <figure class="profile-picture">
                    <!-- <img src={{userPhoto}} class="rounded-circle" [alt]="fullName" placement="bottom"
                        onerror="this.setAttribute('class', this.attributes['class'].value + ' user-profile')"> -->
                    <i class="fas fa-user pr-1"></i>
                </figure>
                <div class="profile-info">
                    <span [style.color]="layoutService.getColor" class="name" style="text-align: center;">
                        {{displayInfo?.displayName}}
                        <span *ngIf="companyName"> <br> {{companyName}} <span *ngIf="isTemplateCompany">
                                [*Template]</span></span>
                    </span>
                    <!-- <span [style.color]="layoutService.getColor" class="role">&nbsp;</span> -->
                </div>

                <i [style.color]="layoutService.getColor" class="fa custom-caret"></i>
            </a>

            <div class="dropdown-menu" [ngClass]="{'show' : showUserBox}">
                <ul class="list-unstyled mb-2 mt-4">
                    <li [routerLinkActive]="['link-active']">
                        <a [style.color]="layoutService.getColor" class="pointer" role="menuitem" tabindex="-1"
                            [routerLink]="['/user/profile']"><i class="fas fa-id-card"></i> User Profile</a>
                    </li>
                    <li [routerLinkActive]="['link-active']">
                        <a [style.color]="layoutService.getColor" class="pointer" role="menuitem" tabindex="-1"
                            [routerLink]="['/user/change-password']"><i class="fas fa-key"></i> Change Password</a>
                    </li>
                    <ng-container *ngIf="userService.isCompanyAdminUser() && !isTemplateCompany">
                        <div class="divider"></div>
                        <li [routerLinkActive]="['link-active']" *ngIf="userService.isCompanyOwnerUser()">
                            <a [style.color]="layoutService.getColor" class="pointer" role="menuitem" tabindex="-1"
                                [routerLink]="['/billing']"><i class="fas fa-file-invoice-dollar"></i> Billing</a>
                        </li>
                        <li [routerLinkActive]="['link-active']">
                            <a [style.color]="layoutService.getColor" class="pointer" role="menuitem" tabindex="-1"
                                [routerLink]="['/company-settings']"><i class="fas fa-cogs"></i> Company Settings</a>
                        </li>
                        <li [routerLinkActive]="['link-active']">
                            <a [style.color]="layoutService.getColor" class="pointer" role="menuitem" tabindex="-1"
                                [routerLink]="['/permissions']"><i class="fas fa-users"></i> Permissions</a>
                        </li>
                        <li [routerLinkActive]="['link-active']"
                            *ngIf="!isTemplateCompany && userService.hasCompanyFeature(ProductPlanFeature.IntegrationApi)">
                            <a [style.color]="layoutService.getColor" class="pointer" role="menuitem" tabindex="-1"
                                [routerLink]="['/company-integrations']"><i class="fas fa-link"></i> Integrations</a>
                        </li>
                        <div class="divider"></div>
                    </ng-container>

                    <li>
                        <a [style.color]="layoutService.getColor" class="pointer cursor-pointer" role="menuitem"
                            tabindex="-1" (click)='signOut()'><i class="fas fa-power-off"></i> Logout</a>
                    </li>
                    <li>
                        <obc-switch (switchChanged)="layoutService.toggleDarkMode($event)"
                            [value]="layoutService.isInDarkMode" id="darklight" falseLabel="Light" trueLabel="Dark">
                        </obc-switch>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div #notificationPanel *ngIf="displayInfo">
        <obc-notification-center [hide]="!isNotificationMenuOpen"
            (onUpdateNotificationCount)="onUpdateNotificationCount($event)"></obc-notification-center>
    </div>
    <!-- end: search & user box -->
</header>
<!-- <ng-template #emergencyalaram>
    <obc-emergency-alarm (close)="closeModal()"></obc-emergency-alarm>
</ng-template> -->
