import { Component, OnInit } from '@angular/core';
import { SiteService } from 'src/app/services/site.service';
import { SiteBasicInfo } from 'src/app/models/site-basicinfo';

@Component({
  selector: 'obc-site-attendance-permission',
  templateUrl: './site-attendance-permission.component.html',
  styleUrls: ['./site-attendance-permission.component.scss']
})
export class SiteAttendancePermissionComponent implements OnInit {
  sites : SiteBasicInfo[];
  constructor(private siteService: SiteService) { }
  ngOnInit(): void {    
    // this.siteService.getAllActiveSites().subscribe(res => {
    //   this.sites = res;
    // }, err => console.log(err));
  }
}
