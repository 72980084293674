import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/internal/operators/finalize';
import { ExpiryMode } from 'src/app/enums/expiry-mode.enum';
import { InductionMode } from 'src/app/enums/site-induction-mode.enum';
import { AsyncResult } from 'src/app/models/helper-models';
import { CloneInductionQueryModel, InductionViewModel } from 'src/app/models/induction-viewmodel';
import { QuestionViewModel } from 'src/app/models/question-viewmodel';
import { UserDocumentType } from 'src/app/models/user-document-type';
import { VisitorType } from 'src/app/models/visitor-type';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { InductionService } from 'src/app/services/induction-service';
import { LayoutService } from 'src/app/services/layout.service';
import { ModalService } from 'src/app/services/modal.service';
import { QuestionService } from 'src/app/services/question.service';
import { UserDocumentTypeService } from 'src/app/services/user-document-type.service';
import { VisitorTypeService } from 'src/app/services/visitor-type.service';

@Component({
  selector: 'obc-induction-manage',
  templateUrl: './induction-manage.component.html',
  styleUrls: ['./induction-manage.component.scss']
})
export class InductionManageComponent implements OnInit, OnDestroy {
  inProgress: boolean;
  showDetailsConfig: any = {};
  upsertModalRef: BsModalRef;
  onlyActiveInductions: boolean = true;

  inductions: InductionViewModel[];
  inductionToEdit: InductionViewModel;
  inductionToClone: InductionViewModel;

  documents: UserDocumentType[] = [];
  questions: QuestionViewModel[] = [];
  visitorTypes: VisitorType[] = [];
  subscription: Subscription;
  expiryModeEnum = ExpiryMode;

  constructor(private inductionService: InductionService,
    private documentTypeService: UserDocumentTypeService,
    private questionService: QuestionService,
    private visitorTypeService: VisitorTypeService,
    private announcementService: AnnouncementService,
    private layoutService: LayoutService,
    private bsModalService: BsModalService,
    private modalService: ModalService) { }

  ngOnInit(): void {
    this.layoutService.header = "Inductions";
    this.loadInductions();
    this.InitPage();

    //To handle backdrop-click
    this.subscription = this.bsModalService.onHide.subscribe((_) => {
      this.loadInductions();
    })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private InitPage() {
    forkJoin({
      visitorTypes: this.visitorTypeService.cacheGetAllVisitorTypes(),
    }).subscribe(res => {
      this.visitorTypes = (res.visitorTypes);
    })
  }

  loadInductions() {
    this.inProgress = true;
    this.inductionService.getInductions()
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(inds => this.inductions = this.onlyActiveInductions == true ? inds.filter(f => f.mode != InductionMode.Disable) : inds.filter(f => f.mode == InductionMode.Disable));
  }

  onModalUpsertInduction(template: any, induction?: InductionViewModel) {
    this.inductionToEdit = induction;
    this.upsertModalRef = this.bsModalService.show(template, {
      ignoreBackdropClick: true,
      class: 'modal-xl modal-full-width'
    });
  }

  closeQuestionModal(_: AsyncResult) {
    this.upsertModalRef.hide();
  }

  onRemoveInduction(induction: InductionViewModel) {
    this.inProgress = true;
    this.inductionService.removeInduction(induction)
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(result => {
        if (!result.success)
          this.modalService.error(result.message ?? 'Remove induction failed!');
        else
          this.loadInductions();
      });
  }

  getModeTitle(mode: InductionMode) {
    switch (mode) {
      case InductionMode.Disable: return "Disable";
      case InductionMode.Auto: return "Automatic";
      case InductionMode.HostApprove: return "Host Approval";
    }
  }

  getVisitorType(visitorTypeId: number) {
    return this.visitorTypes.find(x => x.id == visitorTypeId);
  }

  showInductionDetails(ind: InductionViewModel) {
    this.showDetailsConfig[ind.id] = !(this.showDetailsConfig[ind.id] ?? false);
  }

  onActiveChanged(value, isActiveMode) {
    if (isActiveMode)
      this.onlyActiveInductions = value;
    this.loadInductions();
  }


  cloneName: string = '';
  onModalClone(template: any, induction?: InductionViewModel) {
    this.inductionToClone = induction;
    this.modalService.show(template, 'modal-lg');
  }
  closeCloneModal() {
    this.modalService.hide();
    this.cloneName = '';
  }
  cloneInduction() {
    this.inProgress = true;
    this.inductionService.cloneInduction({
      inductionId: this.inductionToClone?.id,
      name: this.cloneName
    } as CloneInductionQueryModel)
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(result => {
        this.inProgress = false;
        this.loadInductions();
        if (result)
          this.modalService.error('Clone induction failed!');
        else {
          this.closeCloneModal();
        }
      });
  }
}
