import { Pipe, PipeTransform } from '@angular/core';
import { TextOperator } from '../enums/text-operator.enum';

@Pipe({
  name: 'textOperator'
})
export class TextOperatorPipe implements PipeTransform {

  transform(value: unknown): string {
    if (value === TextOperator.equals) return 'Equals';
    if (value === TextOperator.contains) return 'Contains';
    if (value === TextOperator.isGreaterThan) return 'Is Greater Than';
    if (value === TextOperator.isGreaterThanOrEqualTo) return 'Is Greater Than Or Equal To';
    if (value === TextOperator.isLessThan) return 'Is Less Than';
    if (value === TextOperator.isLessThanOrEqualTo) return 'Is Less Than Or Equal To';
    if (value === TextOperator.isNull) return 'No Answer';
    if (value === TextOperator.isNotNull) return 'Has Answer';
    return value?.toString();
  }

}
