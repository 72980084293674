import { Pipe, PipeTransform } from '@angular/core';
import { SupplierDocumentSignOffViewerComponent } from '../components/supplier-documents/supplier-document-sign-off-viewer/supplier-document-sign-off-viewer.component';
import { SignOffMode } from '../enums/signoff-mode.enum';

@Pipe({
  name: 'supplierDocumentSignOffMode'
})
export class SupplierDocumentSignOffModePipe implements PipeTransform {

  transform(value: unknown): string {
    if (value == SignOffMode.Disable) return 'Disable';
    if (value == SignOffMode.Mandatory) return 'Mandatory';
    if (value == SignOffMode.Optional) return 'Optional';
    return value?.toString() ?? '?';
  }

}
