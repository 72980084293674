import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserNotificationInfo, UserNotificationModel, UserNotificationQueryModel, UserNotificationViewModel } from '../models/notifications/models';
import { UserNotificationSettingModel, UserNotificationSettingViewModel } from '../models/notifications/viewmodels';
import { InfoPagingViewModel } from '../models/site-list-paging-view-model';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getUserNotificationSettings(): Observable<UserNotificationSettingViewModel> {
    return this._http.get<UserNotificationSettingViewModel>(`api/NotificationSetting/settings`)
      .pipe(catchError(this._errorService.handleError));
  }

  updateSettings(data: UserNotificationSettingModel) {
    return this._http.post<boolean>(`api/NotificationSetting/update`, data)
      .pipe(catchError(this._errorService.handleError));
  }

  getUserNotificationList(request: UserNotificationQueryModel): Observable<InfoPagingViewModel<UserNotificationViewModel,UserNotificationInfo>> {
    return this._http.post<InfoPagingViewModel<UserNotificationViewModel,UserNotificationInfo>>(`api/Notification/list`, request)
      .pipe(catchError(this._errorService.handleError));
  }

  deleteNotification(data: UserNotificationModel) {
    return this._http.post<boolean>(`api/Notification/delete`, data)
      .pipe(catchError(this._errorService.handleError));
  }

  readNotification(data: UserNotificationModel) {
    return this._http.post<boolean>(`api/Notification/read`, data)
      .pipe(catchError(this._errorService.handleError));
  }

  unreadNotification(data: UserNotificationModel) {
    return this._http.post<boolean>(`api/Notification/unread`, data)
      .pipe(catchError(this._errorService.handleError));
  }

  readAllNotification() {
    return this._http.post<boolean>(`api/Notification/read-all`,null)
      .pipe(catchError(this._errorService.handleError));
  }


}
