import { Pipe, PipeTransform } from '@angular/core';
import { AssetOperatorMode } from '../enums/asset-operator-mode.enum';

@Pipe({
  name: 'assetOperatorMode'
})
export class AssetOperatorModePipe implements PipeTransform {

  transform(value: any): string {
    if (value == AssetOperatorMode.Nobody) return 'Nobody';
    if (value == AssetOperatorMode.AssetCompanyHosts) return 'Owner Company Employees';
    if (value == AssetOperatorMode.SiteCompanyHosts) return 'Site Company Hosts';
    if (value == AssetOperatorMode.AssetOperators) return 'Operators';
    return value?.toString() ?? '?';
  }

}
