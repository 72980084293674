import { CompanyEmployeeViewModel } from './../../../models/company-user-view-model';
import { Component, OnInit } from '@angular/core';
import { CompanyViewModel } from 'src/app/models/company-viewmodel';
import { UserAutoCompleteFilterByFields } from 'src/app/enums/user-auto-complete-target-fields';
import { CompanyEmployeeService } from 'src/app/services/company-employee.service';
import { ModalService } from 'src/app/services/modal.service';
import { finalize } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { mobileIsValid } from 'src/app/helpers/mobile-format-validator';
import { UserBasicInfoViewModel } from 'src/app/models/user-basic-info-viewmodel';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { ProductPlanFeature } from 'src/app/enums/product-plan-feature.enum';
import { FileUploadAcceptType } from 'src/app/enums/file-upload-accept-type';


@Component({
  selector: 'obc-company-employee',
  templateUrl: './company-employee.component.html',
  styleUrls: ['./company-employee.component.scss']
})
export class CompanyEmployeeComponent implements OnInit {
  FileUploadAcceptType = FileUploadAcceptType;
  ProductPlanFeature = ProductPlanFeature;
  inProgress: boolean = false;
  addNewMode: boolean = false;
  filterUserBy = UserAutoCompleteFilterByFields;
  companyEmployees: CompanyEmployeeViewModel[] = [];
  companyEmployee: CompanyEmployeeViewModel = null;
  employeeToViewDocuments: CompanyEmployeeViewModel = null;
  inEdit = {};
  filterListTerm: string;
  selectedUser: UserBasicInfoViewModel;

  _currentCompany: CompanyViewModel = null;
  constructor(public userService: UserService,
    private companyEmployeeService: CompanyEmployeeService,
    private bsModalService: BsModalService,
    private modalService: ModalService) { }

  ngOnInit(): void {
    this.initComponentData();
    this.userService.currentCompany$.subscribe(c => this._currentCompany = c);
    this.fileFormControl.valueChanges.subscribe(res => {
      if (res == null) return;
      let formData = new FormData();
      formData.append("file", res, res.name);
      this.import(formData);
    });
  }

  onRegionSelect(value) {
    if (!this.companyEmployee) return;
    this.companyEmployee.regionId = value;
  }

  get currentCompany(): CompanyViewModel {
    return this._currentCompany;
  }

  get currentCompanyId(): number {
    if (this._currentCompany == null)
      return null;
    else
      return this._currentCompany.id;
  }

  get currentCompanyName(): string {
    if (this._currentCompany == null)
      return null;
    else
      return this._currentCompany.title;
  }

  initComponentData() {
    this.inProgress = true;
    const companyEmployees = this.companyEmployeeService.getCurrentCompanyEmployees();

    forkJoin({
      users: companyEmployees,
    })
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(ret => {
        this.companyEmployees = ret["users"] as CompanyEmployeeViewModel[];
      });
  }

  onNewCompanyEmployee() {
    this.cancel();
    this.addNewMode = true;
    this.companyEmployee = new CompanyEmployeeViewModel();
    this.companyEmployee.companyId = this.currentCompanyId;
    this.companyEmployee.userId = null;
    this.companyEmployee.employeeNo = '';
    this.selectedUser = new UserBasicInfoViewModel();
  }

  fillCompanyEmployee(cUser: UserBasicInfoViewModel) {
    if (cUser.id) this.selectedUser = cUser;
  }

  addCompanyEmployee() {
    if (!this.isValidate()) return;
    this.inProgress = true;
    this.companyEmployeeService.addEmployee(this.companyEmployee)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(result => {
        this.initComponentData();
        this.cancel();
      },
        error => { this.modalService.error(error) });
  }

  delete(employee) {
    this.modalService.confirm("Are you sure you want to delete employee? ", "Delete ").subscribe(res => {
      if (res) {
        this.inProgress = true;
        this.companyEmployeeService.removeEmployee(employee.id)
          .pipe(finalize(() => this.inProgress = false))
          .subscribe(result => this.initComponentData(),
            error => { this.modalService.error(error) });
      }
    })
  }

  bsModalRef: any;
  editUserPermissionFormGroup: FormGroup;

  isValidate() {
    if (!this.companyEmployee.companyId)
      return false;

    if (!this.companyEmployee.userId && this.isValidMobile === false)
      return false;

    // if (!this.companyEmployee.employeeNo) {
    //   this.modalService.warn('Employee No is required.');
    //   return false;
    // }

    var duplicated = this.companyEmployees.find(c => c.userId === this.companyEmployee.userId && c.companyId === this.companyEmployee.companyId);
    if (duplicated) {
      this.modalService.warn('this user added before.');
      return false;
    }

    return true;
  }

  get isValidMobile(): boolean {
    return (this.companyEmployee.mobile !== undefined && mobileIsValid(this.companyEmployee.mobile));
  }

  cancel() {
    this.addNewMode = false;
  }

  // import users
  importData: any;
  fileFormControl = new FormControl(null, [Validators.required]);
  clear() {
    this.fileFormControl.setValue(null);
    this.importData = null;
  }

  onClearForm() {
    this.clear();
  }

  import(formData) {
    this.inProgress = true;
    this.companyEmployeeService.ImportUsers(formData)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        if (res.type == 4 && res.ok) {
          if (res.body.valid) {
            this.modalHandle.hide();
            this.initComponentData();
          } else
            this.importData = res.body;
        }
      });
  }

  modalHandle: any;
  openImportEmployeeModal(template) {
    this.modalHandle = this.bsModalService.show(template);
  }

  inDocProgress = false;
  docs: any[] = null;
  onViewEmployeeDocuments(employee: CompanyEmployeeViewModel, template) {
    this.inDocProgress = true;
    let employeeId = employee.id;
    this.employeeToViewDocuments = employee;
    this.docs = null;
    this.modalHandle = this.bsModalService.show(template);
    this.companyEmployeeService.getEmployeeDocuments(employeeId)
      .pipe(finalize(() => this.inDocProgress = false))
      .subscribe(res => {
        this.docs = res;
      }, err => { this.modalService.error(err) })
  }
}
