import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { FormType } from 'src/app/enums/form-type';
import { MessageType } from 'src/app/enums/message-type.enum';
import { Table } from 'src/app/enums/table.enum';
import { WorkflowType } from 'src/app/enums/workflow/workflow-type';
import { GridColumn, GridConfig, GridTemplate } from 'src/app/models/grid/grid.config';
import { CompanyWorkflowTemplateViewModel as CompanyWorkflowTemplateViewModel } from 'src/app/models/workflow/company-workflow-template-view-model';
import { VariableDefinitionType } from 'src/app/models/workflow/variable-difinition';

@Component({
  selector: 'obc-company-workflow-template',
  templateUrl: './company-workflow-template.component.html',
  styleUrls: ['./company-workflow-template.component.scss']
})
export class CompanyWorkflowTemplateComponent implements OnInit {
  dataModel: CompanyWorkflowTemplateViewModel = <CompanyWorkflowTemplateViewModel>{};


  @ViewChild('grid') grid: any;
  gridColumns: GridColumn<CompanyWorkflowTemplateViewModel>[] = [];
  gridConfig: GridConfig = {} as GridConfig;
  isActiveAdvancedMode: boolean = false;
  inProgress: boolean = false;
  selectedInstanceId: string;
  WorkflowType = WorkflowType;
  FilterType = FilterType;
  FormType = FormType;
  MessageType = MessageType;
  VariableDefinitionType = VariableDefinitionType;

  isEditMode: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bsModalService: BsModalService) {
  }

  onCloseWorkflowPopup() {
    this.bsModalService.hide();
    this.dataModel = <CompanyWorkflowTemplateViewModel>{};
    this.dataModel.companyId = this.companyId;

    this.grid.loadData();
  }


  companyId: number;
  companyTitle: string;
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.companyId = params['companyId'] ? +params['companyId'] : null;
      this.companyTitle = decodeURIComponent(params['companyTitle'] ? params['companyTitle'] : '');

      if (!this.companyId) {
        this.router.navigate(['/manage-companies']);
      }
      else {
        this.prepareGridConfig();
      }
    });
  }


  prepareGridConfig() {
    this.gridConfig = new GridConfig({
      apiUrl: `/api/companyWorkflowTemplate/get-company-workflow-templates/${this.companyId}`,
      tableType: Table.Workflow,
      displayGeneralSearch: true,
      generalSearchPlaceholder: "Filter result by Workflow Name",
      generalSearchMinimumCharsToSearch: 1,
    });

    this.gridColumns = [
      {
        name: "Workflow Title",
        key: "title",
        sortBy: "title",
        type: FilterType.SingleText,
        propertyNameInQuery: 'title',
        isArray: false,
        enableFilter: true,
        enableSort: true,
      },
      {
        name: "Workflow Type",
        key: "workflowType",
        sortBy: "workflowType",
        gridTemplate: new GridTemplate().ComponentTemplateName('workflowType'),
        propertyNameInQuery: 'workflowType',
        isArray: false,
        enableFilter: false,
        enableSort: true,
      },
      {
        name: "Workflow Sub Type",
        key: "workflowSubTitle",
        sortBy: "workflowSubTitle",
        propertyNameInQuery: 'workflowSubTitle',
        enableFilter: false,
        enableSort: true,
      },
      {
        name: "Version",
        key: "version",
        sortBy: "version",
        type: FilterType.SingleText,
        propertyNameInQuery: 'version',
        isArray: false,
        enableFilter: true,
        enableSort: true,
      },
      {
        name: "Created By",
        key: "creatorUserInfo",
        sortBy: "creatorUserInfo",
        type: FilterType.Text,
        gridTemplate: new GridTemplate().ComponentTemplateName('creatorUserInfo'),
        propertyNameInQuery: 'creatorUserInfo',
        isArray: false,
        enableFilter: true,
        enableSort: true,
      },
      {
        name: "Created At",
        key: "createDate",
        sortBy: "createDate",
        type: FilterType.DateTimeRange,
        propertyNameInQuery: 'createDateDateRange',
        isArray: false,
        enableFilter: true,
        enableSort: true,
      },
      {
        key: "actions",
        name: "Actions",
        gridTemplate: new GridTemplate().ComponentTemplateName('actions'),
        enableFilter: false,
        enableSort: false,
        visible: true,
      }
    ];

  }

  onAddWorkflow(template: any) {
    this.dataModel = <CompanyWorkflowTemplateViewModel>{};
    this.isEditMode = false;
    this.dataModel.companyId = this.companyId;
    this.bsModalService.show(template, { class: 'modal-xl modal-full-width', ignoreBackdropClick: true });
  }



  viewDetails(item, template: any) {
    this.dataModel = <CompanyWorkflowTemplateViewModel>{};
    this.isEditMode = true;
    this.dataModel = item;
    this.dataModel.workflowInfo?.placeholderList?.map(item => {
      item.disabled = item.accessMode.toLowerCase() == 'advanced' && this.isActiveAdvancedMode == false;
    });

    this.dataModel.workflowInfo?.placeholderList?.filter(p => p.type == FilterType.ActionList).map(item => {
      let commandList = JSON.parse(item.value.toString())
      item.parsedValue = [];
      commandList.forEach(value => {
        item.parsedValue.push(
          {
            RoleList: value.RoleList,
            ConfirmMessage: value.ConfirmMessage,
            CssClass: value.CssClass,
            Key: value.Key,
            Text: value.Text,
            Placeholder: JSON.parse(JSON.stringify(value.Text))
          }
        )
      });
    });
    this.bsModalService.show(template, { class: 'modal-xl modal-full-width', ignoreBackdropClick: true });
  }

}
