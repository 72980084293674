import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/internal/operators/finalize';
import {
  BaseInventoryLocationViewModel,
  InventoryLocationViewModel
} from 'src/app/models/inventory/inventory-location-model';
import { InventorySiteLocationsViewModel } from 'src/app/models/inventory/inventory-site-locations-view-model';
import { BaseSiteViewModel } from 'src/app/models/site-viewmodel';
import { InventoryService } from 'src/app/services/inventory.service';
import { ModalService } from 'src/app/services/modal.service';
import { UserService } from 'src/app/services/user.service';
import {InventoryLocationSelectorModel} from "../../../models/inventory/inventory-location-selector-model";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'obc-inventory-location-selector',
  templateUrl: './inventory-location-selector.component.html',
  styleUrls: ['./inventory-location-selector.component.scss']
})

export class InventoryLocationSelectorComponent implements OnInit {
  @Input() parentSelectedSite: BaseSiteViewModel;
  @Input() externalSiteSupplierId: number;
  @Input() canAddNewLocation: boolean;
  @Output() onSelectLocation: EventEmitter<InventoryLocationSelectorModel> = new EventEmitter();
  @Output() onAddNewLocation: EventEmitter<InventoryLocationSelectorModel> = new EventEmitter();
  loading: boolean = false;
  siteLocations: InventorySiteLocationsViewModel[];
  selectedSite: InventorySiteLocationsViewModel = null;
  selectedLocation: FormControl = new FormControl(); //BaseInventoryLocationViewModel = null;
  selectedLocationModel: BaseInventoryLocationViewModel; //BaseInventoryLocationViewModel = null;
  isNewLocationRequested: boolean = false;

  ngOnInit(): void {
    this.getInventorySiteLocations();
    this.selectedLocation.valueChanges.subscribe(_ => this.emitSelectedLocation());

    this.canAddNewLocation = this.canAddNewLocation ?? this.userService.canAddNewLocation;

  }
  constructor(private inventoryService: InventoryService,
    public userService: UserService,) { }

  getInventorySiteLocations() {
    this.loading = true;
    if (this.parentSelectedSite == null || this.parentSelectedSite.id == null) {
      this.inventoryService.inventorySiteLocations
        .subscribe(data => {
          this.siteLocations = data;
          if(data && data.length > 0) {
            this.selectedSite = data[0];
            this.siteChanged();
          }
          this.loading = false;
        });
    }
    else if (this.externalSiteSupplierId == null) {
      this.inventoryService.getInventoryLocationsBySiteId(this.parentSelectedSite.id)
        .pipe(finalize(() => { this.loading = false; }))
        .subscribe(res => {
          this.selectedSite = res.data;
          this.siteChanged();
        });
    } else {
      this.inventoryService.getInventoryLocationsBySiteSupplierId(this.externalSiteSupplierId)
        .pipe(finalize(() => { this.loading = false; }))
        .subscribe(res => {
          this.selectedSite = res.data;
          this.siteChanged();
        });
    }
  }
  selectFirstLocation(site: InventorySiteLocationsViewModel) {
    setTimeout(() => {
      if(site?.locations?.length == 0) {
        this.selectedLocation.setValue(null);
      } else {
        this.selectedLocation.setValue(site?.locations[0]?.id);
      }
      this.emitSelectedLocation();
    }, 100)
  }

  emitSelectedLocation() {
    this.selectedLocationModel = this.selectedSite?.locations?.find((e) => e.id == this.selectedLocation.value);
    this.onSelectLocation.emit({selectedLocation: this.selectedLocationModel, selectedSite: this.selectedSite} as InventoryLocationSelectorModel)
  }

  siteChanged() {
      this.selectFirstLocation(this.selectedSite);
  }
  onRequestToAddNewLocation() {
    this.isNewLocationRequested = true;
    this.onSelectLocation.emit(null);
    this.onAddNewLocation.emit(null);
  }
  onAddLocation(newLocation: string) {
    this.onAddNewLocation.emit({newLocationTitle: newLocation, selectedSite: this.selectedSite} as InventoryLocationSelectorModel);
  }
  onRequestToSelectLocation() {
    this.isNewLocationRequested = false;
    this.selectFirstLocation(this.selectedSite);
    this.onAddNewLocation.emit(null);
  }

}
