<div class="card">
    <div class="card-body" *ngIf="config">
        <obc-loader [show]="inProgress"></obc-loader>
        <div class="alert alert-danger" *ngIf="errorMessage">
            {{errorMessage}}
        </div>
        <div class="row">
            <div class="form-group col-md-12 col-sm-12 d-flex flex-column justify-content-between">
                <label>Enable Myob Integration</label>
                <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [(ngModel)]="config.isMyobEnabled">
                </obc-switch>
            </div>
        </div>
        <div class="row mt-5" *ngIf="config.isMyobEnabled">
            <div class="form-group col-md-12 col-sm-12 d-flex flex-column justify-content-between">
                <label>Myob System Type</label>
                <div>
                    <span>
                        <label>
                            <input class="mr-1" type="radio" [value]="MyobSystemType.Advanced"
                                [(ngModel)]="config.myobSystemType" (change)="updateUiVars()">
                            <strong>Myob Advanced</strong>
                        </label>
                    </span>
                    <span class="ml-3">
                        <label>
                            <input class="mr-1" type="radio" [value]="MyobSystemType.AccountRight"
                                [(ngModel)]="config.myobSystemType" (change)="updateUiVars()">
                            <strong>Myob AccountRight</strong>
                        </label>
                    </span>
                </div>
            </div>
        </div>
        <ng-container *ngIf="config.isMyobEnabled">
            <div class="row mt-5" *ngIf="config.myobSystemType === MyobSystemType.AccountRight">
                <ng-container *ngIf="editApiSection">
                    <div class="form-group col-md-12 col-sm-12">
                        <label>Client Id</label>
                        <input type="text" class="form-control" [(ngModel)]="config.myobApiKey">
                    </div>
                    <div class=" form-group col-md-12 col-sm-12">
                        <label>Client Secret</label>
                        <input type="text" class="form-control" [(ngModel)]="config.myobApiSecret">
                    </div>
                    <div class="form-group col-md-12 col-sm-12 text-right">
                        <button class="btn btn-success" (click)="authorizeOnMyobSite()">
                            Authorize On Myob Website
                        </button>
                        <button class="btn btn-default" *ngIf="config.isValidToCallApi" (click)="editApiSection=false">
                            Cancel
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="!editApiSection">
                    <div class="form-group col-md-10">
                        <label>Client Id</label>
                        <input type="text" class="form-control" [(ngModel)]="config.myobApiKey" readonly>
                    </div>
                    <div class="form-group col-md-2">
                        <label>&nbsp;</label>
                        <button class="btn btn-block btn-info" (click)="editApiSection=true;">Update Client
                            Id/Secret</button>
                    </div>
                </ng-container>
            </div>

            <div class="mt-5" *ngIf="!editApiSection">
                <ng-container *ngIf="editMyobSyncConfig">
                    <ng-container *ngIf="config.myobSystemType === MyobSystemType.Advanced">
                        <div class="form-group col-md-12 col-sm-12">
                            <label>Myob Advanced Url</label>
                            <input type="url" class="form-control" [(ngModel)]="config.myobAdvancedUrl"
                                placeholder="https://yourcompany.myobadvanced.com">
                        </div>
                        <div class=" form-group col-md-12 col-sm-12">
                            <label>Username</label>
                            <input type="text" class="form-control" [(ngModel)]="config.myobAdvancedUsername">
                        </div>
                        <div class=" form-group col-md-12 col-sm-12">
                            <label>Password</label>
                            <input type="password" class="form-control" [(ngModel)]="config.myobAdvancedPassword">
                        </div>
                        <div class=" form-group col-md-12 col-sm-12">
                            <label>Tenant Name</label>
                            <input type="text" class="form-control" [(ngModel)]="config.myobAdvancedTenant">
                        </div>
                        <!-- <div class="form-group col-md-2 offset-10 col-sm-12 text-right">
                            <button class="btn btn-block btn-success" (click)="saveAdvancedModeConfig()">
                                <i class="fa fa-save"></i>
                                Save
                            </button>
                        </div> -->
                    </ng-container>
                    <ng-container *ngIf="config.myobSystemType == MyobSystemType.AccountRight">
                        <div class="row">
                            <div class="col-md-3">
                                <label>Select Default Company</label>
                                <select class="form-control" [(ngModel)]="config.myobCompanyFileUid"
                                    (change)="onCompanyFileChanged()">
                                    <option *ngFor="let cat of companyFiles" [ngValue]="cat.id">{{cat.name}}</option>
                                </select>
                            </div>
                        </div>
                        <ng-container *ngIf="config.myobCompanyFileUid">
                            <div class="row mt-3">
                                <div class="col-md-4">
                                    <label>Default Payroll Category Wage Type</label>
                                    <select class="form-control" [(ngModel)]="config.myobDefaultPayrolCategoryUid">
                                        <option *ngFor="let cat of paymentCategories" [ngValue]="cat.key">{{cat.value}}
                                        </option>
                                    </select>
                                    <div class="alert alert-info">
                                        <small>
                                            Only Hourly Wage Types Are
                                            Available
                                        </small>
                                    </div>
                                </div>
                            </div>

                        </ng-container>
                    </ng-container>
                    <div class="row">
                        <div class="col-md-3">
                            <label>Period To Send CheckIns To Myob</label>
                            <select class="custom-select form-control" [(ngModel)]="config.myobSyncPeriod">
                                <option *ngFor="let type of ReportTypes | enumToArray" [ngValue]="ReportTypes[type]">
                                    {{ type }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label>Day</label>
                            <div *ngIf="config.myobSyncPeriod == ReportTypes.Monthly">
                                <ng-select class="pb-0" [items]="daysOfMonth" bindLabel="lable" bindValue="value"
                                    [multiple]="false" placeholder="Select Days of Month" clearAllText="Clear"
                                    [(ngModel)]="config.dayOfSync">
                                </ng-select>
                            </div>
                            <div *ngIf="config.myobSyncPeriod == ReportTypes.Weekly">
                                <ng-select class="pb-0" [items]="daysOfWeek" bindLabel="lable" bindValue="value"
                                    [multiple]="false" placeholder="Select Days of Week" clearAllText="Clear"
                                    [(ngModel)]="config.dayOfSync">
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label>Delay To Send CheckIns (Hour)</label>
                            <input type="number" class="form-control" [(ngModel)]="config.myobSyncDelayInHour">
                        </div>

                        <div class="col-md-2 offset-10">
                            <button class="btn btn-block btn-primary"
                                (click)="config.myobSystemType === MyobSystemType.Advanced ? saveAdvancedModeConfig() : saveMyobAccountRightConfig()">Save</button>
                        </div>
                    </div>
                </ng-container>


                <ng-container *ngIf="!editMyobSyncConfig">
                    <ng-container *ngIf="config.myobSystemType == MyobSystemType.Advanced">
                        <div class="form-group col-md-12 col-sm-12">
                            <label>Myob Advanced Url</label>
                            <input readonly type="url" class="form-control" [(ngModel)]="config.myobAdvancedUrl"
                                placeholder="https://yourcompany.myobadvanced.com">
                        </div>
                        <div class=" form-group col-md-12 col-sm-12">
                            <label>Username</label>
                            <input readonly type="text" class="form-control" [(ngModel)]="config.myobAdvancedUsername">
                        </div>
                        <div class=" form-group col-md-12 col-sm-12">
                            <label>Password</label>
                            <input readonly type="password" class="form-control"
                                [(ngModel)]="config.myobAdvancedPassword">
                        </div>
                        <div class=" form-group col-md-12 col-sm-12">
                            <label>Tenant Name</label>
                            <input readonly type="text" class="form-control" [(ngModel)]="config.myobAdvancedTenant">
                        </div>

                    </ng-container>

                    <ng-container *ngIf="config.myobSystemType == MyobSystemType.AccountRight">
                        <div class="row">
                            <div class="col-md-12">
                                <label>Company</label>
                                <div class="config-value">
                                    {{config.myobCompanyFile?.name}}
                                </div>
                            </div>
                            <div class="col-md-3 mt-3">
                                <label>Default Payroll Category Wage Type</label>
                                <div class="config-value">
                                    {{config.myobDefaultPayrolCategoryName}}
                                </div>
                            </div>
                            <div class="col-md-2 offset-10">
                                <button class="btn btn-block btn-info" (click)="showConfigurationPanel()">
                                    Update Myob Configuration</button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container>
                        <div class="col-md-3">
                            <label>Period To Send CheckIns To Myob</label>
                            <div class="config-value">{{config.myobSyncPeriod | myobPeriodType}}
                                (Day: {{config.dayOfSync}})
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label>Delay To Send CheckIns (Hour)</label>
                            <div class="config-value">{{config.myobSyncDelayInHour}} Hour(s)</div>
                        </div>
                    </ng-container>
                    <div class="form-group col-md-2 offset-10 col-sm-12 text-right"
                        *ngIf="config.myobSystemType == MyobSystemType.Advanced">
                        <button class="btn btn-block btn-info" (click)="editMyobAdvancedConfig()">
                            <i class="fa fa-edit"></i>
                            Edit
                        </button>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>