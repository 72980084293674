import { Pipe, PipeTransform } from '@angular/core';
import {SiteAttendanceApprovalStatus} from "../enums/site-attendance-approval-status.enum";

@Pipe({
  name: 'siteAttendanceApprovalStatus'
})
export class SiteAttendanceApprovalStatusPipe implements PipeTransform {

  transform(value: unknown): string {
    if (value === SiteAttendanceApprovalStatus.NotRequiredApproval || value === 0) return "Not Required";
    if (value === SiteAttendanceApprovalStatus.Pending || value === 1) return "Pending";
    if (value === SiteAttendanceApprovalStatus.Approved || value === 2) return "Approved";
    if (value === SiteAttendanceApprovalStatus.Rejected || value === 3) return "Rejected";
    return value?.toString();
  }

}
