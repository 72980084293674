import { Component, OnInit } from '@angular/core';
import { SiteViewModel } from 'src/app/models/site-viewmodel';
import { LayoutService } from 'src/app/services/layout.service';
import { BehaviorSubject } from 'rxjs';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import { UserService } from 'src/app/services/user.service';
import { SiteAttendanceService } from 'src/app/services/site-attendance.service';
import { CheckInMode } from 'src/app/enums/checkin-mode.enum';

@Component({
  selector: 'obc-manual-check',
  templateUrl: './manual-check.component.html',
  styleUrls: ['./manual-check.component.scss'],
})
export class ManualCheckComponent implements OnInit {
  inProgress: boolean = false;
  selectedSite: SiteViewModel = null;
  mode: 'checkin' | 'checkout' = 'checkin';
  checkInCount: number = 0;
  userPermissions = UserPermission;
  checkoutSelected = new BehaviorSubject<boolean>(false);
  CheckInMode = CheckInMode;
  constructor(
    private layoutService: LayoutService,
    public userService: UserService,
    private siteAttendanceService: SiteAttendanceService,
  ) { }

  ngOnInit() {
    this.layoutService.header = 'Host Check-In/Out';
  }

  get siteListPermission() {
    var hasCheckInPermission = this.userService.hasUserPermissionForCompany(this.userPermissions.ManualCheckIn);
    return hasCheckInPermission ? this.userPermissions.ManualCheckIn : this.userPermissions.ManualCheckOut;
  }

  get siteId(): number {
    return this.selectedSite.id;
  }

  tabs = [];
  onSiteChange() {
    this.tabs = [false, false, false];
    setTimeout(() => {
      this.tabs = [
        this.userService.hasUserPermissionForCompany(UserPermission.ManualCheckIn, this.selectedSite.id),
        this.userService.hasUserPermissionForCompany(UserPermission.ManualCheckOut, this.selectedSite.id),
        this.userService.isCompanyAdminUser(),
      ];
    }, 0)
    this.updateAttendanceCount();
  }


  attendeesCountUpdated(checkInCount: number) {
    this.checkInCount = checkInCount;
  }

  updateAttendanceCount() {
    this.siteAttendanceService.getTotalCountCheckInSite(this.selectedSite.id).subscribe(res => {
      this.checkInCount = res;
    })
  }

  onSelectCheckout() {
    this.checkoutSelected.next(true);
  }
}
