import { Pipe, PipeTransform } from '@angular/core';
import { ProcoreExportCheckInAsVisitorLogMode } from '../enums/procore-export-modes.enum';

@Pipe({
  name: 'procoreExportSiteAttendanceAsVisitorLogModePipe'
})
export class ProcoreExportSiteAttendanceAsVisitorLogModePipePipe implements PipeTransform {

  transform(value: any): unknown {
    if (value === ProcoreExportCheckInAsVisitorLogMode.Disabled) return 'Disabled';
    if (value === ProcoreExportCheckInAsVisitorLogMode.Everyone) return 'Everyone';
    if (value === ProcoreExportCheckInAsVisitorLogMode.EveryoneExceptEmployees) return 'Everyone Except Employees';
    if (value === ProcoreExportCheckInAsVisitorLogMode.EveryoneExceptSubcontractorsAndEmployees) return 'Everyone Except Subcontractors And Employees';
    return value;
  }

}
