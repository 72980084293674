import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { VisitorTypeService } from 'src/app/services/visitor-type.service';
import { finalize } from 'rxjs/operators';
import { ModalService } from 'src/app/services/modal.service';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'obc-manage-visitor-type',
  templateUrl: './manage-visitor-type.component.html',
  styleUrls: ['./manage-visitor-type.component.scss']
})
export class ManageVisitorTypeComponent implements OnInit {
  inProgress = false;
  errorMessage = "";
  newVisitorTypeGroup = new FormGroup({
    title: new FormControl(null, [Validators.required]),
  });
  editVisitorTypeGroup: FormGroup;
  visitorTypes = null;
  constructor(
    private bsModalSerive: BsModalService,
    private modalService: ModalService,
    private layoutService : LayoutService,
    private visitorTypeService: VisitorTypeService
  ) { }

  ngOnInit() {
    this.getAllVisitorType();
  }

  getAllVisitorType() {
    this.inProgress = true;
    this.visitorTypeService.getAllVisitorTypes()
      .pipe(finalize(() => { this.inProgress = false }))
      .subscribe(res => {
        this.visitorTypes = res ?? [];
      });
  }

  addNewVisitorType() {
    var model = {
      title: this.newVisitorTypeGroup.get("title").value
    };
    this.inProgress = true;
    this.visitorTypeService.addVisitorType(model)
      .pipe(finalize(() => { this.inProgress = false }))
      .subscribe(res => {
        this.visitorTypes.push(res);
        this.newVisitorTypeGroup.reset();
      });
  }

  onRemoveModal(model: any) {
    this.modalService.confirm("Are you sure you want to delete this visitor type?","", "Yes", "No").subscribe(res => {
      if (res) {
        this.inProgress = true;
        this.visitorTypeService.removeVisitorType(model.id)
          .pipe(finalize(() => { this.inProgress = false }))
          .subscribe(res => {
            var index = this.visitorTypes.indexOf(model);
            this.visitorTypes.splice(index, 1);
          });
      }
    })
  }

  bsModalRef = null;
  onShowEditModal(template: any, model: any) {
    this.editVisitorTypeGroup = new FormGroup({
      id: new FormControl(model.id, [Validators.required]),
      title: new FormControl(model.title, [Validators.required]),
    });
    this.bsModalRef = this.bsModalSerive.show(template);
  }

  onUpdateVisitorType() {
    var model = {
      title: this.editVisitorTypeGroup.get("title").value,
    };
    var id = this.editVisitorTypeGroup.get("id").value;
    this.visitorTypeService.updateVisitorType(id, model)
      .pipe(finalize(() => { this.inProgress = false }))
      .subscribe(res => {
        var entity = this.visitorTypes.find(i => i.id == id);
        var index = this.visitorTypes.indexOf(entity);
        this.visitorTypes.splice(index, 1, res);
        this.bsModalRef.hide();
      });
  }

}
