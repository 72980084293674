import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuestionType } from 'src/app/enums/question-type';
import { SiteQuestionRendererViewModel } from "../../../../models/site-question-viewmodel";

@Component({
  selector: 'obc-single-question-renderer',
  templateUrl: './single-question-renderer.component.html',
  styleUrls: ['./single-question-renderer.component.scss']
})
export class SingleQuestionRendererComponent implements OnInit {

  QuestionType = QuestionType;

  @Output('onValueChanges') public valueChange = new EventEmitter();
  @Input() question: SiteQuestionRendererViewModel;
  @Input() tagged: number;
  @Input() isEditMode: boolean = false;

  constructor() { }

  ngOnInit(): void {}

  onValueChanges(value) {
    this.valueChange.emit(value);
  }
}
