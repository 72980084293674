import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UserDocumentType } from "../../../models/user-document-type";
import Sortable from 'sortablejs';
import { uuid4 } from 'src/app/helpers/uuid';
import { OrderedEntity } from "../../../models/ordered-entity";
import { finalize } from "rxjs/operators";
import { UserDocumentTypeService } from "../../../services/user-document-type.service";

@Component({
  selector: 'ul[obc-document-type-viewer-ul]',
  templateUrl: './document-type-viewer-ul.component.html',
  styleUrls: ['./document-type-viewer-ul.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'[id]': 'randIdPrefix'}
})
export class DocumentTypeViewerUlComponent implements OnInit {

  @Input('isRoot') isRootInput: boolean = false;
  @Input() children: UserDocumentType[] = [];
  @Input() allDocuments: UserDocumentType[] = [];
  @Input() canDelete: boolean;
  @Input() draggable: boolean;
  @Input() canAdd: boolean;
  @Input() canUpdate: boolean;
  @Input() cacheUniqueId: string = '';

  @Output() orderedList: EventEmitter<OrderedEntity[]> = new EventEmitter<OrderedEntity[]>();

  randIdPrefix = uuid4();
  @HostBinding('class.document-type-tree') isRoot: boolean = false;

  constructor(private userDocumentTypeService: UserDocumentTypeService) { }

  ngOnInit(): void {
    this.isRoot = this.isRootInput;
  }

  sortableOptions = {
    ghostClass: 'ghost-class',
    fallbackClass: "ghost-class",
    handle: ".move-handler",
    dragoverBubble: true,
    forceFallback: true,
    onEnd: (event: any) => {

      let orderedList: OrderedEntity[] = [];
      let order = 0;
      for (let child of event.target.children) {
        orderedList.push({
          id: +child?.dataset?.id,
          order: order,
        })
        order++;
      }

      this.orderedList.emit(orderedList);

    }
  };

  ngAfterViewInit() {
    if(this.draggable) {
      let el = document.getElementById(`${this.randIdPrefix}`);
      Sortable.create(el, this.sortableOptions);
    }
  }

  onOrderedList(orderedList: OrderedEntity[]) {
    this.orderedList.emit(orderedList);
  }
}
