<div class="row align-items-center">
    <div class="col-12 col-md-3 col-lg-2">
        Type
    </div>
    <div class="col-12 col-md-9 col-lg-10">
      <div class="p-2">
        <label>
          <input name="{{key}}" type="radio" class="radio" [(ngModel)]="limit.valueMode"
                 [value]="DateQuestionLimitValueMode.Relative" /> Relative
        </label>
        <label class="ml-3">
          <input name="{{key}}" type="radio" class="radio" [value]="DateQuestionLimitValueMode.Absolute"
                 [(ngModel)]="limit.valueMode" /> Absolute
        </label>
      </div>
    </div>
</div>
<div class="row">
  <div class="col-12 col-lg-6 mb-2 mb-lg-0" *ngIf="limit.valueMode == DateQuestionLimitValueMode.Relative">
    <div class="row align-items-center">
      <div class="col-4">
        <input type="number" class="form-control" [(ngModel)]="limit.dayCount">
      </div>
      <div class="col-8">
        <label>Days</label>
      </div>
    </div>
  </div>

    <div class="col-12 col-lg-6 mb-2 mb-lg-0">
        <select class="form-control" [(ngModel)]="limit.dateComparisonMode">
            <option [value]="DateComparisonMode.Before">Before</option>
            <option [value]="DateComparisonMode.After">After</option>
        </select>
    </div>
    <div class="col-12 col-lg-6" *ngIf="limit.valueMode == DateQuestionLimitValueMode.Relative">
        <label>Answer Date</label>
    </div>
    <div class="col-12 col-lg-6" *ngIf="limit.valueMode == DateQuestionLimitValueMode.Absolute">
        <obc-input-date-time-picker [formControl]="dateFormControl" (valueChange)="limit.compareValue=$event.value"
            [mode]="DatetimePickerMode.Calender">
        </obc-input-date-time-picker>
        <!-- <input [owlDateTime]="dt1" class="form-control" [formControl]="dateFormControl" [owlDateTimeTrigger]="dt1"
            (dateTimeChange)="limit.compareValue=$event.value">
        <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time> -->
    </div>

</div>
