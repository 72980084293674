import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { Table } from 'src/app/enums/table.enum';
import { GridColumn, GridConfig, GridTemplate } from 'src/app/models/grid/grid.config';
import { ObcEventLogViewModel } from 'src/app/models/userProfile/worker-profile-view-model';
import { Constants } from "../../../../enums/constants";
import { ActorType } from 'src/app/enums/actor-type';

@Component({
  selector: 'obc-worker-profile-history',
  templateUrl: './worker-profile-history.component.html',
  styleUrls: ['./worker-profile-history.component.scss']
})
export class WorkerProfileHistoryComponent implements OnInit {
  ActorType = ActorType;

  @Input() selectedUserId: number = null;
  @Input() siteId: number = null;
  @Input() isReportMode: boolean = false;

  @ViewChild('grid') grid: any;
  gridColumns: GridColumn<ObcEventLogViewModel>[] = [];
  gridConfig: GridConfig = {} as GridConfig;

  constructor() { }

  ngOnInit(): void {
    this.prepareGridConfig();
  }

  prepareGridConfig() {

    let initialFilters = [];
    if (this.selectedUserId) {
      initialFilters.push({ key: 'userId', value: this.selectedUserId });
    }
    if (this.siteId) {
      initialFilters.push({ key: 'siteId', value: this.siteId });
    }

    this.gridConfig = new GridConfig({
      apiUrl: '/api/workerprofile/get-user-history',
      tableType: Table.WorkerProfileHistory,
      forceHideAllControlButtons: this.isReportMode,
      displayQuestionsFilter: false,
      displayGeneralSearch: false,
      initialFilters: initialFilters,
      cacheKeyPrefix: this.isReportMode ? Constants.WORKER_PROFILE_REPORT_PREFIX : '',
    });
    this.gridColumns = [
      {
        name: "Date/Time",
        key: "dateTime",
        type: FilterType.DateTimeRange,
        propertyNameInQuery: 'date',
        isArray: false,
        enableFilter: true,
        enableSort: false,
      },
      {
        name: "Performed by",
        key: "actor",
        type: FilterType.Template,
        enableFilter: false,
        enableSort: false,
        gridTemplate: new GridTemplate().ComponentTemplateName('actor'),

      },
      {
        name: "Activity Type",
        key: "eventType",
        type: FilterType.EventLogType,
        propertyNameInQuery: 'eventLogType',
        gridTemplate: new GridTemplate().ComponentTemplateName('logType'),
        isArray: false,
        enableFilter: true,
        enableSort: false,
      },
      {
        name: "Activity Details",
        key: 'dataAsJson',
        type: FilterType.Template,
        enableSort: false,
        enableFilter: false,
        gridTemplate: new GridTemplate().ComponentTemplateName('details'),
        visible: true,
      }
    ]
  };

  getSubType(dataAsJson: string, eventType: number): string {
    let jsonParsed = JSON.parse(dataAsJson);
    if (eventType == 0)
      return jsonParsed.userActivityEventType;
    else
      return jsonParsed.userProfileField;
  }
}
