import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PermitViewModel } from '../models/form/permits-info-model';
import { PermitHistoryViewModel } from '../models/permit/permit-history-view-model';
import { PermitReportQueryModel } from '../models/permit/permit-report-query-model';
import { PermitUpdateStatusRequestModel } from '../models/permit/permit-update-status-request-model';
import { ServerResult } from '../models/server-result-data';
import { PagingViewModel } from '../models/site-list-paging-view-model';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class PermitService {

  apiUrl: string = "/api/permit";
  constructor(private http: HttpClient, private _errorService: ErrorService) { }
  permitReport = (query: PermitReportQueryModel): Observable<PagingViewModel<PermitViewModel>> =>
    this.http.post<PagingViewModel<PermitViewModel>>(`${this.apiUrl}/permit-report`, query)
      .pipe(catchError(this._errorService.handleError));

  updatePermitStatus(model: PermitUpdateStatusRequestModel): Observable<ServerResult> {
    return this.http.post(`${this.apiUrl}/update-permit-status`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  permitHistory(permitId: number): Observable<PermitHistoryViewModel> {
    return this.http.get(`${this.apiUrl}/permit-history/${permitId}`)
      .pipe(catchError(this._errorService.handleError));
  }
}
