<div [ngClass]="{'body-content': !minimalView}">
  <section class="body-sign" [ngStyle]="minimalView?{'height.px': '0' } : {}">
    <div class="center-sign">
      <!-- <a href="/" class="logo float-left">
          <img src="img/logo.png" height="54" alt="Porto Admin" />
      </a> -->
      <div *ngIf="!minimalView" style="display: flex; justify-content: center;">
        <div class="large-logo"></div>
      </div>

      <div class="panel card-sign">
        <div class="card-title-sign mt-3 text-right">
          <h2 class="title text-uppercase font-weight-bold m-0"><i class="fas fa-user mr-1"></i> Sign In</h2>
        </div>
        <div class="card-body col-md-12">
          <obc-loader [show]="inProgress"></obc-loader>
          <div *ngIf='errorMessage' class='alert alert-danger mb-2'>
            {{errorMessage}}
          </div>
          <form class="" action="index.html" [formGroup]="loginForm" *ngIf="countries">

            <div *ngIf="state == loginStateEnum.PreLogin">
              <div class="form-group mb-3">
                <label><b>Country</b></label>
                <div class="input-group">
                  <select name="country" required class="form-control form-control-lg" formControlName="country"
                          [(ngModel)]='selectedCountry'>
                    <option *ngFor="let country of countries" [ngValue]="country">{{country.name}}</option>
                  </select>
                  <span class="input-group-append">
                    <span class="input-group-text">
                      <img src="assets/flags/{{selectedCountry.alpha2}}.png">
                      <i *ngIf="false" class="fas fa-flag"></i>
                    </span>
                  </span>
                </div>
              </div>
              <div class="form-group mb-3">
                <label><b>Mobile Number</b></label>
                <div class="input-group">
                  <div class="input-group-prepend" *ngIf="selectedCountry">
                    <span class="input-group-text">+{{selectedCountry.prefixCode}}</span>
                  </div>
                  <input name="mobile" type="tel" required class="form-control form-control-lg"
                         formControlName="mobile" />
                  <span class="input-group-append">
                    <span class="input-group-text">
                      <i class="fas fa-user"></i>
                    </span>
                  </span>
                </div>
              </div>

              <div class="form-group mb-3">
                <div class="input-group">
                  <label>
                    <input name="isPersisted" type="checkbox" class="mr-2" formControlName="isPersisted" />
                    <b>Keep me signed in</b>
                    <sup class="mt-3 text-left" tooltip="Choosing “Keep me signed in” checkbox to be enabled, reduces the amount of times you are asked to sign in on this device for up to a week.">
                      <i class="fas fa-question-circle pt-2 ml-1"></i>
                    </sup>
                  </label>
                </div>
              </div>

              <div class="text-center">
                <button (click)="sendUserNumber()" class='btn btn-lg btn-twitter btn-block'
                        [disabled]="!loginForm.controls['mobile'].valid">
                  Next
                </button>
              </div>
              <div *ngIf="serverList && serverList.length" class="mt-2">
                <div>Can't find your country? Select another server:</div>
                <div class="d-flex flex-row justify-content-end">
                  <a class="btn btn-primary btn-sm ml-1" *ngFor="let server of serverList" (click)="showProgress()"
                     href="{{server.baseUrl}}">{{server.title}}</a>
                </div>
              </div>
            </div>

            <div *ngIf="state == loginStateEnum.Password">
              <div class="form-group mb-3">
                <label>Enter password</label>
                <div class="input-group">
                  <input type="password" required class="form-control form-control-lg" formControlName="password" />
                </div>
              </div>

              <div class="form-group mb-3">
                <div class="input-group">
                  <label>
                    <input name="isPersisted" type="checkbox" class="mr-2" formControlName="isPersisted" />
                    <b>Keep me signed in</b>
                    <sup class="mt-3 text-left" tooltip="Choosing “Keep me signed in” checkbox to be enabled, reduces the amount of times you are asked to sign in on this device for up to a week.">
                      <i class="fas fa-question-circle pt-2 ml-1"></i>
                    </sup>
                  </label>
                </div>
              </div>

              <div class="form-group text-center">
                <button (click)="signIn()" class='btn btn-lg btn-twitter btn-block'
                        [disabled]="!loginForm.controls['password'].valid">
                  Login
                </button>
                <button (click)="state = loginStateEnum.PreLogin" class="btn btn-lg btn-light btn-block">
                  <i class="fas fa-arrow-left mr-1"></i>
                  Change Phone Number
                </button>
                <button (click)="resetPassword()" class="btn btn-lg btn-light btn-block">
                  <i class="fas fa-unlock-alt mr-1"></i>
                  Forgot Password
                </button>
              </div>
            </div>

            <div *ngIf="state == loginStateEnum.ResendOTP">
              <div class="form-group mb-3">
                <label>Please enter 6 digit verification code</label>
                <div class="input-group">
                  <input name="smsCode" type="text" required maxlength='6' minlength='6'
                    class="form-control form-control-lg" #smsCode formControlName="smsCode"
                    style="letter-spacing: 17px;text-align: center;" />
                  <span class="input-group-append">
                    <span class="input-group-text">
                      <i class="fas fa-lock"></i>
                    </span>
                  </span>
                </div>
              </div>

              <div style="padding: 10px;
              border: 3px solid darkorange;
              border-radius: 5px;
              margin-bottom: 10px;">
                <div class="alert alert-success mb-2" style="background-color: #ff8c00;
              border-color: #ff8c00;
              color: white;">
                  <span>For next login please enter password</span>
                </div>
                <div class="form-group mb-3">
                  <label>Enter password</label>
                  <div class="input-group">
                    <input type="password" required class="form-control form-control-lg" formControlName="password" />
                  </div>
                </div>
                <div class="form-group mb-3">
                  <label>Repeat password</label>
                  <div class="input-group">
                    <input type="password" required class="form-control form-control-lg"
                      formControlName="repeatpassword" />
                  </div>
                  <label class="text-danger"
                    *ngIf="this.loginForm.controls['password'].valid && !isRepeatPasswordValid()">
                    Passwords not same
                  </label>
                </div>
              </div>
              <div class="text-center">
                <button (click)="signIn()" class='btn btn-lg btn-twitter btn-block' [disabled]="!tokenFormIsValid()">
                  Login
                </button>
              </div>
              <div class="form-group mt-3">
                <ul>
                  <li *ngIf="resendResponse?.message?.length">
                    {{resendResponse?.message}}
                  </li>
                  <li>
                    Minimum password length is 8 characters
                  </li>
                </ul>
              </div>
              <button (click)="state = loginStateEnum.PreLogin" class="btn btn-lg btn-light btn-block">
                Edit Phone Number
              </button>
            </div>

            <div *ngIf="state == loginStateEnum.TwoFactor">
              <div class="form-group mb-3">
                <label>Please enter {{twoFactorAuthentication.pinLength}} digit verification code</label>
                <div class="input-group">
                  <input name="smsCode" type="text" required maxlength='{{twoFactorAuthentication.pinLength}} '
                    minlength='{{twoFactorAuthentication.pinLength}} ' class="form-control form-control-lg" #smsCode
                    formControlName="smsCode" style="letter-spacing: 17px;text-align: center;" />
                  <span class="input-group-append">
                    <span class="input-group-text">
                      <i class="fas fa-lock"></i>
                    </span>
                  </span>
                </div>
              </div>
              <div class="text-center">
                <button (click)="signIn()" class='btn btn-lg btn-twitter btn-block'
                  [disabled]="!twoFactorFormIsValid()">
                  Login
                </button>
              </div>
              <div class="form-group mt-3">
                <ul>
                  <li>
                    {{twoFactorAuthentication.message}}
                  </li>
                </ul>
              </div>

              <div class="form-group mt-3 text-center"
                *ngIf="twoFactorAuthentication.verificationMode != TwoFactorVerificationModeEnum.Totp">
                Haven't received the
                {{twoFactorAuthentication.verificationMode==TwoFactorVerificationModeEnum.Email?'Email':'sms'}} yet?
                <div *ngIf="disableResendLink" style="font-weight:bold ;"> Please wait ({{timeLeft}})</div>
                <a (click)="sendOtherType(twoFactorAuthentication.verificationMode)" *ngIf="!disableResendLink"
                  class="d-block link-primary">
                  {{twoFactorAuthentication.resendButtonText}}
                </a>
              </div>
              <div class="form-group mt-3" *ngIf="resendResponse?.error">
                <span class="text-danger"><i class="fas fa-exclamation-triangle"></i>{{resendResponse?.message}}</span>
              </div>
              <button (click)="editPhoneNumber();" class="btn btn-lg btn-light btn-block">Edit Phone Number</button>
              <accordion class="site-management" *ngIf="twoFactorAuthentication?.otherWaysList?.length">
                <accordion-group>
                  <div accordion-heading>
                    <obc-accordion-header
                      [mainTitle]="twoFactorAuthentication?.otherWaysMessage?.length?twoFactorAuthentication?.otherWaysMessage:'Can\'t sign in?'"
                      [icon]="'fa fa-sign-in-alt'" [iconColor]="'#2196F3'"></obc-accordion-header>
                  </div>

                  <button *ngFor="let item of twoFactorAuthentication?.otherWaysList" (click)="sendOtherType(item.type)"
                    class='btn btn-lg btn-twitter btn-block' [disabled]="!item.enabled">
                    {{item.title}}{{!item.enabled?' ('+timeLeft+')':''}}
                  </button>
                </accordion-group>
              </accordion>
            </div>

          </form>
        </div>
      </div>

      <p class="text-center text-muted mt-3 mb-3">&copy; Copyright {{year}}. All Rights Reserved.</p>
    </div>
  </section>
</div>
