<div class="card">
  <div class="card-header">
    <h5>Add/Edit Document Kinds</h5>
  </div>
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="row">
      <div class="offset-md-9 col-3">
        <p class="button-group mb-3">
          <button class="btn btn-success" *ngIf="showNewKindButton" (click)="onShowEditModal(editModal , null)">
            Add New Document Kind
          </button>
        </p>
      </div>
      <div class="col-12 mb-2">
        <p style="text-align: center;" *ngIf="kindList == null">
          Loading ...
        </p>
        <p style="text-align: center;" *ngIf="kindList != null && kindList.length == 0">
          Nothing Found
        </p>
        <table *ngIf="kindList" class="table table-bordered table-striped mb-0">
          <thead>
            <tr>
              <th>Title</th>
              <th>Access Mode</th>
              <th>Expiry Date Mode</th>
              <th>SignOff Mode</th>
              <th>Comment Mode</th>
              <th>Amendment Mode</th>
              <th>Is Active</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let kind of kindList">
              <td>
                <span><strong>{{kind.name}}</strong></span>
                <i class="fas fa-lock ml-2" *ngIf="!kind.companyId"></i>
                <button class="btn btn-sm btn-primary ml-2" (click)="onShowEditModal(editModal, kind)">
                  Edit
                </button>
              </td>
              <td><span>{{kind.accessMode | supplierDocumentAccessMode}}</span></td>
              <td><span>{{kind.expireDateMode | supplierDocumentExpiryDateMode}}</span></td>
              <td><span>{{kind.signOffMode | supplierDocumentSignOffMode}}</span></td>
              <td><span>{{kind.commentMode | supplierDocumentCommentMode}}</span></td>
              <td><span>{{kind.amendmentMode | supplierDocumentAmendmentMode}}</span></td>
              <td><span class="{{kind.isActive?'':'text-danger'}}">{{kind.isActive | yesNo}}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<ng-template #editModal>
  <obc-document-kind-add-edit [companyId]="selectedCompanyId" [documentKind]="selectedKind"
    (onSaveDocumentKind)="onSaveDocumentKind()"></obc-document-kind-add-edit>
</ng-template>