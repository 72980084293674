<div class="card" style="background-color: transparent; padding: 0.25rem; padding-left: 1rem;">
  <obc-loader [show]="inprogress"></obc-loader>
  <div class="row mb-2">
    <div class="col-md-12">
      <span>Do you want to limit visitors of this site based on types?</span>
    </div>
    <div class="col-md-12">
      <obc-switch falseLabel="No" trueLabel="Yes" [value]="limited" (valueChanged)="limited = $event" ></obc-switch>
    </div>
  </div>
  <div class="row" *ngIf="this.manageSiteModel?.visitorTypes && limited">
    <div class="col-md-12">
      <div>
        <div class="btn-group1">
          <label (click)="onItemClick(toggleCheckedType(getCheckedType(visitorType)))" *ngFor="let visitorType of this.manageSiteModel?.visitorTypes; let x = index"
            class="btn btn-primary ml-1 mb-1" [ngModel]="getCheckedType(visitorType).checked" btnCheckbox tabindex="0"
            role="button">{{visitorType.title}}</label>
        </div>
      </div>
    </div>
  </div>
</div>
