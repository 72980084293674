<obc-loader [show]="inProgress"></obc-loader>
<div class="row">
  <div class="col-md-12">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">Filter </span>
      </div>
      <input type="text" class="form-control" [disabled]="!attendees" placeholder="Mobile or Firstname or Lastname"
             [(ngModel)]="filterListTerm">
    </div>
  </div>
  <div class="col-md-12">
    <div class="table-responsive">

      <table class="table table-bordered table-striped mt-3">
        <thead>
        <tr>
          <td>Full Name</td>
          <td>Mobile</td>
          <td>Check In Date</td>
          <td>
            <button class="btn btn-sm btn-info" style="color: white;float: right;" (click)="getSiteAttendees()">
              <i class="fas fa-redo"></i>
              Refresh
            </button>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of (attendees | filter:filterListTerm:['mobile','firstName','lastName'])">
          <td>
            {{item.firstName}} {{item.lastName}}
            <span class="text-success" tooltip="{{item.accompaniersText}}"
                  *ngIf="item.accompanierCount">(+{{item.accompanierCount}})</span>
          </td>
          <td>
            {{item.mobile | mobile}}
          </td>
          <td>
            {{item.checkInDate | datetime}}
          </td>
          <td>
            <p class="button-group">
              <button class="btn btn-sm btn-danger" (click)="openModal(checkoutQuestions,item)">
                Check-Out
              </button>
              <button class="btn btn-sm btn-info"
                      *ngIf=" userService.hasUserPermissionForCompany(userPermissions.ViewAttendanceAnswers, item.siteId)"
                      (click)="openQuestionAnswerModal(questionAnswer,item.siteAttendanceId,true, false)">
                View Details
              </button>
            </p>
          </td>
        </tr>
        <tr *ngIf="( attendees == null || attendees.length == 0) && filterListTerm.length > 0">
          <td colspan="4">
            <div class="invalid-feedback" style="display: block;text-align: center;">
              There is no filtered attendance
            </div>
          </td>
        </tr>
        <tr *ngIf="( attendees == null || attendees.length == 0) && (filterListTerm.length ==0 || !filterListTerm)">
          <td colspan="4">
            <div class="invalid-feedback" style="display: block;text-align: center;">
              There is no attendance
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #checkoutQuestions>
  <div class="card">
    <div class="card-header">
      <span>Questions</span>
      <button type="button" class="close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table" style="text-align: center;">
              <thead style="font-weight: 600;">
              <tr>
                <td>Full Name</td>
                <td>Mobile</td>
                <td>Check In Date</td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{selectedAttendee.firstName}} {{selectedAttendee.lastName}}</td>
                <td>{{selectedAttendee.mobile | mobile}}</td>
                <td>
                  {{selectedAttendee.checkInDate | datetime}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr style="width: 100%;">
        <div class="col-md-12">
          <obc-items-renderer
            [questionsData]="{questions : _questions,
            validator$: questionContainerValidation$,
            documents: requiredDocumentFormControls,
            documentsFormArray: requiredDocumentFormArray,
            notifyVisits: false
            }"
          ></obc-items-renderer>
        </div>

        <div class="col-md-12 pt-2" *ngIf="isSetSupplierCheckinData()">
          <div class="card check-in-supplier-block">
            <div class="card-header">
              <strong>{{supplierCheckinData.supplierName}}</strong>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <obc-items-renderer [questionsData]="{questions : _supplierQuestions,
            validator$: supplierQuestionContainerValidation$,
            documents: supplierRequiredDocumentFormControls,
            documentsFormArray: supplierRequiredDocumentFormArray
            }">
                  </obc-items-renderer>
                </div>
              </div>
            </div>
            <div class="card-footer">

            </div>
          </div>
        </div>

        <div class="col-md-12 mt-4">
          <p class="button-group">
            <button class="btn btn-danger" [disabled]="!isFormValid" (click)="onCheckout()">Check-Out
            </button>
          </p>
        </div>
        <div class="col-md-12">
          <div *ngIf="errorMessage && errorMessage.length > 0" class="invalid-feedback" style="display: block;">
            {{errorMessage}}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #questionAnswer>
  <obc-question-answer [questionAnswerInput]="questionAnswerInputModel" (result)="closeModal($event)">
  </obc-question-answer>
</ng-template>
