<div class="full-width">
  <div class="input-group">
    <input [(ngModel)]="address"
      [typeahead]="suggestions" [isAnimated]="true" [adaptivePosition]="true" [typeaheadAsync]="true"
      [typeaheadItemTemplate]="customItemTemplate" [typeaheadOptionsInScrollableView]="6"
      [typeaheadOrderBy]="sortConfig" (typeaheadOnSelect)="onSelectAddress($event)" [typeaheadMinLength]="2"
      class="form-control {{formGroup.valid ? 'ng-valid':'ng-invalid'}}" />
    <div class="input-group-prepend" *ngIf="addressLoader">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
  <ng-template #customItemTemplate let-model="item">
    <span>
      <strong>{{ model.primaryTitle }}</strong> -
      {{ model.secondaryTitle }} -
      <small>{{ model.description }}</small>
    </span>
  </ng-template>
</div>
