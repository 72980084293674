import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from "@angular/forms";
import {
  JoinSupplierCompanyInfo, JoinSupplierInfo,
  JoinSupplierRequestModel,
} from "../../models/link_to_company_request_model";
import { finalize } from "rxjs/operators";
import { CompanySupplierService } from "../../services/company-supplier.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ModalService } from "../../services/modal.service";
import { UserService } from "../../services/user.service";
import { UserInfoViewModel } from "../../models/user-info-viewmodel";
import { Timezone } from "../../models/time-zone";
import { CountryService } from "../../services/country.service";
import { LayoutService } from "../../services/layout.service";

@Component({
  selector: 'obc-supplier-join-to-company',
  templateUrl: './supplier-join-to-company.component.html',
  styleUrls: ['./supplier-join-to-company.component.scss']
})
export class SupplierJoinToCompanyComponent implements OnInit {

  @ViewChild('successLinkToCompanyMessage') successLinkToCompanyMessage: any;
  @ViewChild('successLinkToNewCompany') successLinkToNewCompany: any;

  smsToken: FormControl = new FormControl(null, [
    Validators.required,
    Validators.minLength(5),
    Validators.maxLength(8),
  ]);
  urlToken: string;
  inProgress: boolean = true;

  companies: Record<number, string> = {};
  showFirstPanel: boolean = true;
  disableGoBackLink: boolean = true;

  get hasAnyCompany() {
    return this.companies != null && Object.keys(this.companies).length > 0;
  }

  joinSupplierInfo: JoinSupplierInfo;
  isValidatedSmsToken = false;

  get hostCompanyTitle() { return this.joinSupplierInfo?.companyTitle; }
  get supplierName() { return this.joinSupplierInfo?.supplierName; }
  get mobileNumber() { return this.joinSupplierInfo?.mobileNumber; }

  displayInfo: UserInfoViewModel = null;
  isCompanyOwner: boolean = false

  selectedCompany: FormControl = new FormControl();
  timezones: Timezone[] = null;

  newCompanyTitle: FormControl = new FormControl(null, [
    Validators.required,
    Validators.minLength(3)
  ]);
  newCompanyTimeZoneId: FormControl = new FormControl(null, [Validators.required]);
  newCompanyOwnerMobile: FormControl = new FormControl(null, [
    Validators.required,
    Validators.maxLength(15),
    Validators.minLength(10)
  ]);
  newCompanyOwnerFirstName: FormControl = new FormControl(null, [
    Validators.required,
    Validators.minLength(2),
  ]);
  newCompanyOwnerLastName: FormControl = new FormControl(null, [Validators.required]);
  newCompanyMobileCountryCodeDisplay: string;
  newCompanyMobileDisplay: string;

  isPageFullyLoaded: boolean = false;

  loginStateNotAMember: boolean = false;

  currentTimeZone: string;

  constructor(
    private companySupplierService: CompanySupplierService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private userService: UserService,
    private countryService: CountryService,
    private layoutService: LayoutService
  ) {
  }

  ngOnInit(): void {
    this.layoutService.closeSideBar();

    try {
      this.currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
    }

    this.route.params.subscribe((params: Params) => {
      this.urlToken = params['token'] ? params['token'] : null;

      this.getjoinInfo();
      this.userService.initUserInfo();

      this.userService.info.subscribe(res => {
        this.displayInfo = res;

        this.updateSelectedCompany();


        setTimeout(() => {
          this.inProgress = false;
          this.isPageFullyLoaded = true;
        }, 1000);
      });

      this.countryService
        .getTimeZones()
        .subscribe((res) => {
          this.timezones = res;
          this.timezones.forEach((value) => {
            if (value.name == this.currentTimeZone) {
              this.newCompanyTimeZoneId.setValue(value.id);
            }
          })
        });
    });

  }

  getjoinInfo() {
    this.companySupplierService.getjoinInfo(this.urlToken)
      .pipe((finalize(() => this.inProgress = false)))
      .subscribe(res => {
        this.joinSupplierInfo = res;

        if (this.joinSupplierInfo == null || !this.isValidatedSmsToken)
          this.showFirstPanel = true;

      }, _ => {
        this.modalService.error("Get information about join to company Failed. Please make sure your link is correct.");
      });
  }

  loginState(result?: number) {
    if (result != null) {
      if (result['state'] == 0) {
        this.loginStateNotAMember = true;
        this.newCompanyOwnerMobile.setValue(result['country'] + result['mobile']);
        this.newCompanyMobileDisplay = result['mobile'];
        this.newCompanyMobileCountryCodeDisplay = result['country'];
      }
    } else {
      this.userService.initUserInfo();
      this.validateToken();
    }
  }


  isSMSTokenEntered() {
    return this.smsToken.valid;
  }

  validateToken() {
    this.inProgress = true;
    this.companySupplierService.validateJoinLink(this.urlToken, this.smsToken.value)
      .pipe((finalize(() => this.inProgress = false)))
      .subscribe(res => {
        this.joinSupplierInfo = res;
        this.companies = res.companies ?? {};
        this.updateSelectedCompany();
        this.isValidatedSmsToken = true;
      }, _ => {
        this.modalService.error("Verification Failed. Please make sure you entered the correct SMS token");
      });
  }
  updateSelectedCompany() {
    if (this.displayInfo == null || this.isEmptyObject(this.companies)) {
      this.selectedCompany.setValue(-1);
      this.newCompanyTitle.setValue(this.supplierName);
    } else {
      let firstCompanyId = Object.keys(this.companies)[0];
      this.selectedCompany.setValue(firstCompanyId);
    }
  }

  validateNewCompanyFields() {
    return (
      this.newCompanyTitle.valid
      && this.newCompanyTimeZoneId.valid
    ) && (
        (
          this.displayInfo == null &&
          this.newCompanyOwnerFirstName.valid &&
          this.newCompanyOwnerLastName.valid &&
          this.newCompanyOwnerMobile.valid
        ) || this.displayInfo != null
      );
  }


  sendJoinRequest() {
    let newCompanyMode = this.selectedCompany?.value == -1;
    let model = {
      token: this.urlToken,
      smsToken: this.smsToken?.value ?? '',
      linkToCompanyId: this.selectedCompany?.value,
    } as JoinSupplierRequestModel;
    if (newCompanyMode) {
      if (!this.validateNewCompanyFields())
        return;

      model['linkToCompany'] = {
        timeZoneId: this.newCompanyTimeZoneId?.value,
        title: this.newCompanyTitle?.value,
      } as JoinSupplierCompanyInfo;

      if (this.displayInfo == null) {
        model['linkToCompany']['ownerFirstName'] = this.newCompanyOwnerFirstName.value;
        model['linkToCompany']['ownerLastName'] = this.newCompanyOwnerLastName.value;
        model['linkToCompany']['ownerMobileNumber'] = this.newCompanyOwnerMobile.value;
      }

      model['linkToCompanyId'] = null;
    }

    this.companySupplierService.joinSupplier(model)
      .pipe((finalize(() => this.inProgress = false)))
      .subscribe(res => {
        if (res) {
          let createdCompanyId = +res;
          let msgModal = null;
          if (newCompanyMode == false)
            msgModal = this.successLinkToCompanyMessage;
          else {
            msgModal = this.successLinkToNewCompany;
          }

          this.modalService.show(msgModal).subscribe(_ => {
            if (this.displayInfo == null || this.displayInfo?.currentCompanyId == createdCompanyId) {
              this.router.navigate(['/']);
            }
            else {
              this.userService.changeCompany(createdCompanyId).subscribe(_ => this.router.navigate(['/']));
            }
          });

        } else {
          this.modalService.error("Join to company failed");
        }
      }, _ => {
        this.modalService.error("Join to company failed");
      });
  }

  closeModal() {
    this.modalService.hide();
  }

  isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }


  onSendSMS() {
    this.inProgress = true;
    if (!this.mobileNumber) {
      this.modalService.error("Please check supplier mobile number!");
      return;
    }
    this.companySupplierService.sendSMSSupplierActivationToken(this.urlToken)
      .subscribe(result => {
        this.inProgress = false;
        if (result) {
          this.showFirstPanel = false;
          this.disableGoBackLink = true;
          this.timeLeft = 60;
          this.startTimer();
        }
        else {
          this.modalService.warn('', 'Send sms failed!');
        }
      }, err => {
        this.modalService.error(err, "Unable to send sms");
      }, () => {
        this.inProgress = false;
      });
  }

  timeLeft: number = 60;
  interval;

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.pauseTimer();
      }
    }, 1000)
  }

  pauseTimer() {
    clearInterval(this.interval);
    this.disableGoBackLink = false;
  }

  backToFirst() {
    if (!this.disableGoBackLink) {
      this.pauseTimer();
      this.showFirstPanel = true;
      this.smsToken= new FormControl(null, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(8),
      ]);
    }
  }

}
