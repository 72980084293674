import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { SiteAssetCheckInViewModel } from 'src/app/models/site-asset/site-asset-checkin-view-model';
import { ModalService } from 'src/app/services/modal.service';
import { SiteAssetService } from 'src/app/services/site-asset.service';
import { AssetCheckInHistoryModel } from "../../../models/site-asset/asset-check-in-history-model";

@Component({
  selector: 'obc-site-asset-checkin-history',
  templateUrl: './site-asset-checkin-history.component.html',
  styleUrls: ['./site-asset-checkin-history.component.scss']
})
export class SiteAssetCheckinHistoryComponent implements OnInit {
  @Input() assetIdsHistoryModel: AssetCheckInHistoryModel;
  inProgress: boolean;
  checkedInSiteAssetHistory: SiteAssetCheckInViewModel[];

  constructor(private siteAssetService: SiteAssetService,
              private modalService: ModalService) {
  }

  ngOnInit(): void {
    this.fetchHistory();
  }

  fetchHistory() {
    this.inProgress = true;
    this.siteAssetService.getAssetCheckinHistory(this.assetIdsHistoryModel)
      .pipe(finalize(() => {
        this.inProgress = false;
      }))
      .subscribe(
        (res) => {
          this.checkedInSiteAssetHistory = res;
        },
        (err) => {
          this.modalService.error('Request Failed!');
        }
      )
  }

}
