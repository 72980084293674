import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, switchMap, tap } from "rxjs/operators";
import { CaptchaService } from './captcha.service';
import { UserService } from './user.service';
import { CaptchaMode } from '../enums/captcha-mode';

@Injectable()
export class HttpHelperInterceptor implements HttpInterceptor {
  static WhiteListRouteRegexListFor401StatusCode: string[] = ['/sign-in.*', '/anonymous-check-in/.+', '/supplier-join/.*', '/anonymous-manage-site-supplier/.+'];
  private urlsCalledCount: { [url: string]: number } = {};
  private readonly API_MAX_TRY_COUNT: number = 5;

  constructor(
    private router: Router,
    private userService: UserService,
    private _captchaService: CaptchaService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.urlsCalledCount[request.url] = 1;

    if (!request.headers.has('Content-Type') && !request.headers.has('do-not-modify-content-type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    let xsrfToken =  this.userService.getXsrfToken();
    if (xsrfToken?.length) {
      request = request.clone({ headers: request.headers.set('X-XSRF-TOKEN', xsrfToken) });
    }

    if (this._captchaService.isCaptchaActive() && this._captchaService.UrlNeedToken(request.url)) {
      return this.getTokenAndCallRequest(request, next);
    }
    else {
      return this.nextHandle(next, request);
    }
  }

  nextHandle(next, request) {
    return next.handle(request)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            let xsrfToken = event?.headers?.get('XSRF-TOKEN');
            if (xsrfToken?.length) {
              this.userService.setXsrfToken(xsrfToken);
            }

            if (event.status === 205) {
              window.location.reload();
            }
          }
        }),
        catchError(err => {
          var url = this.router.url;
          if (err.status == 401 && HttpHelperInterceptor.WhiteListRouteRegexListFor401StatusCode.some((regex) => {
            var matched = new RegExp(regex).test(url);
            console.log('Current route(' + url + ') matched by ' + regex + ':' + matched);
            return matched;
          })) {
            return observableThrowError(err);
          }

          if (err instanceof HttpErrorResponse && err.status === 401) {
            if (this.userService.info.value && err.headers['invalid-session'] == 'false')
              this.router.navigate(['no-access', { 'returnPath': this.router.url }]);
            else
              this.router.navigate(['sign-in', { 'returnPath': this.router.url }]);
          }

          if (err instanceof HttpErrorResponse && err.status === 317) {
            this.urlsCalledCount[request.url]++;
            return this.getTokenAndCallRequest(request, next);
          }
          return observableThrowError(err);
        }),
      );
  }

  getTokenAndCallRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this._captchaService
      .getToken()
      .pipe(
        switchMap((token: string) => {
          request = request.clone({ headers: request.headers.set("X-CAPTCHA-TOKEN", token) });
          return next.handle(request);
        }),
        catchError(error => {
          if (error instanceof HttpErrorResponse && error.status == 317) {
            this.urlsCalledCount[request.url]++;
            if (this.urlsCalledCount[request.url] < this.API_MAX_TRY_COUNT) {
              return this.getTokenAndCallRequest(request, next);
            }
          }

          return observableThrowError(error);
        }));
  }
}
