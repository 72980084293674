import { Pipe, PipeTransform } from '@angular/core';
import { AutoCheckOutType } from '../enums/auto-check-out-type.enum';

@Pipe({
  name: 'autoCheckOutType'
})
export class AutoCheckOutTypePipe implements PipeTransform {

  transform(value: any): string {
    if (value == AutoCheckOutType.Disable) return "Disable";
    if (value == AutoCheckOutType.Optional) return "Optional";
    if (value == AutoCheckOutType.Mandatory) return "Mandatory";
    return value?.toString() ?? "?";
  }
}
