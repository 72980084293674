import { HttpParams, HttpParameterCodec } from '@angular/common/http';

export class CustomeQueryEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
        return this.encode(key);
    }
    encodeValue(value: string): string {
        return this.encode(value);
    }
    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }
    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
    private encode(v: string): string {
        return encodeURIComponent(v)
            .replace('+', '%2B');
    }
}

export function extractParamsFromModel(searchModel): HttpParams {
    //        const params: URLSearchParams = new URLSearchParams('', new CustomQueryEncoder());
    let params: HttpParams = new HttpParams({ fromString: '', encoder: new CustomeQueryEncoder() });
    for (const key of Object.keys(searchModel)) {
        const value = searchModel[key];
        if (value instanceof Array) {
            value.forEach(d => {
                if (d != null && d != undefined)
                    params = params.append(key, d)
            });
        } else if (value instanceof Object && Object.keys(value)) {
            Object.keys(value).forEach(k => {
                // if (value[k] instanceof Date) {
                //     if (value[k] != null && value[k] != undefined)
                //         params = params.set(k, moment(value[k]).format());
                // } else 
                if (value[k] instanceof Array) {
                    value[k].forEach(d => {
                        if (value[k] != null && value[k] != undefined)
                            params = params.append(k, d);
                    });
                }
                else if (value[k] != null && value[k] != undefined) {
                    params = params.set(k, value[k]);
                }
            });
        } else if (value != null && value != undefined) {
            params = params.set(key, value);
        }
    }
    return params;
}

export function convertObjectToUrlParameters(o) {
    function iter(o, path) {
        if (o === null || typeof o === typeof undefined) {
            data.push(path + '=');
            return;
        }
        if (Array.isArray(o)) {
            o.forEach(function (a, i) {
                iter(a, path + '[' + i + ']');
            });
            return;
        }
        if (o instanceof Date) {
            data.push(path + '=' + o.toISOString());
            return;
        }
        if (o !== null && typeof o === 'object') {
            Object.keys(o).forEach(function (k) {
                iter(o[k], path + '.' + k);
            });
            return;
        }
        data.push(path + '=' + o);
    }

    var data = [];
    Object.keys(o).forEach(function (k) {
        iter(o[k], k);
    });
    return data.join('&');
}