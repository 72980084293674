import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { catchError } from 'rxjs/operators';
import { IndustryTypeViewModel } from '../models/industry-type/industry-type-view-model';
import { IndustryTypeModel } from '../models/industry-type/industry-type-model';

@Injectable({
  providedIn: 'root'
})
export class IndustryTypeService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getActiveIndustryTypes = (): Observable<any> =>
    this._http.get<IndustryTypeViewModel[]>('/api/IndustryType/get-all')
      .pipe(catchError(this._errorService.handleError));

  getIndustryTypes = (): Observable<any> =>
    this._http.get<IndustryTypeViewModel[]>('/api/IndustryType/all')
      .pipe(catchError(this._errorService.handleError));


  addIndustryType = (model: IndustryTypeModel): Observable<any> =>
    this._http.post<any>('/api/IndustryType/add', model)
      .pipe(catchError(this._errorService.handleError));

  editIndustryType = (model: IndustryTypeModel): Observable<any> =>
    this._http.post<any>('/api/IndustryType/edit', model)
      .pipe(catchError(this._errorService.handleError));
}
