import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SiteSupplierDocumentListenerModel } from './site-supplier-document-listener-model';

@Injectable({
  providedIn: 'root'
})
export class SiteSupplierDocumentListenerService {

  public dataChanged: BehaviorSubject<SiteSupplierDocumentListenerModel> = new BehaviorSubject(null);
  onChangeData(model?: SiteSupplierDocumentListenerModel) {
    this.dataChanged.next(model);
  }

  constructor() { }


}