<ng-container>
  <div class="row" *ngIf="site?.companyLogoUrl">
    <div class="col-md-12 mb-1">
      <div class="d-flex flex-row">
        <img [src]="site.companyLogoUrl" style="height: 128px;border-radius: 5px;">
        <span class="ml-1 h4  pl-2 mt-0">
          {{site.companyName}}
        </span>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" *ngIf="site">
      Check-In to site '{{site?.name}}'
    </div>
    <div class="card-body">
      <div class="alert alert-danger" role="alert" *ngIf="initErrorMessage?.length > 0">
        <p style="text-align: center;" class="mb-0"><strong>{{initErrorMessage}}</strong></p>
      </div>
      <ng-container *ngIf="site">
        <obc-manual-checkin [selectedCountry]="site.country" [sessionId]="sessionId" [mode]="CheckInMode.Anonymous" #manualCheckinRef [site]="site"></obc-manual-checkin>
      </ng-container>
    </div>
  </div>
</ng-container>
