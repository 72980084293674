<div class="modal-table">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <obc-loader [show]="inProgress"></obc-loader>
        <div class="card-header">
          <div class="d-flex flex-row justify-content-between">
            <div>Site Asset Check-In History</div>
          </div>
        </div>
        <div class="card-body mt-2">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-bordered table-striped" *ngIf="checkedInSiteAssetHistory">
                  <thead>
                  <tr>
                    <th>Check-in By</th>
                    <th>Check-in Date</th>
                    <th>Check-out By</th>
                    <th>Check-out Date</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngIf="checkedInSiteAssetHistory && checkedInSiteAssetHistory.length == 0">
                    <td colspan="20">
                      There are no registered assets.
                    </td>
                  </tr>
                  <tr *ngFor="let item of checkedInSiteAssetHistory">
                    <td>
                      {{item.checkInByUserFullName +
                    (item.checkInByUserMobile ? ' ('+(item.checkInByUserMobile | mobile)+')' :
                      '')}}
                    </td>
                    <td>
                      {{item.checkInDate | datetime}}
                    </td>
                    <td>
                      {{ item.checkOutByUserFullName != null ?
                      item.checkOutByUserFullName +
                      (item.checkOutByUserMobile ? ' ('+(item.checkOutByUserMobile | mobile)+')' :
                        '') : ''}}
                    </td>
                    <td>
                      {{item.checkOutDate | datetime}}
                    </td>
                  </tr>
                  </tbody>
                  <tfoot *ngIf="checkedInSiteAssetHistory && checkedInSiteAssetHistory.length > 0">
                  <tr>
                    <td colspan="20">
                      <!-- <obc-pagination [totalCount]="pagingInfo.totalCount" [pageSize]="pagingInfo.pageSize"
                [pageNumber]="pagingInfo.pageNumber" (pageChanged)="onPageChange($event)"></obc-pagination> -->
                    </td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

