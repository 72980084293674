import { Component, Input, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { MessageType } from 'src/app/enums/message-type.enum';
import { MessageQueryModel, MessageShowModel } from 'src/app/models/message-query-model';
import { MessageService } from 'src/app/services/message.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-message-history',
  templateUrl: './message-history.component.html',
  styleUrls: ['./message-history.component.scss']
})
export class MessageHistoryComponent implements OnInit {

  @Input() request: MessageQueryModel;
  inProgress: boolean;
  messageList: MessageShowModel[];

  MessageType = MessageType;
  currentMessage: MessageShowModel;
  modalRef: any;

  constructor(private messageService: MessageService,
    private modalService: BsModalService) { }

  ngOnInit(): void {
    this.loadData();
  }
  loadData() {
    if (this.request) {
      this.inProgress = true;
      this.messageService.getMesasgeHistory(this.request)
        .pipe(finalize(() => {
          this.inProgress = false;
        }))
        .subscribe(res => this.messageList = res);
    }
  }

  showContent(msg: MessageShowModel, template: any) {
    this.currentMessage = msg;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    setTimeout(() => {
      document.getElementById('contentElement').innerHTML = this.currentMessage.body
    }, 0);
  }

}
