import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SiteSupplierDocumentTypeViewModel } from 'src/app/models/supplier-document/site-supplier-document-type-models';
import { BasicSiteSupplierViewModel } from 'src/app/models/supplier-document/site-supplier-models';
import { LayoutService } from 'src/app/services/layout.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-manage-supplier-documents',
  templateUrl: './manage-supplier-documents.component.html',
  styleUrls: ['./manage-supplier-documents.component.scss']
})
export class ManageSupplierDocumentsComponent implements OnInit {
  siteSupplierDocumentTypes = new BehaviorSubject<SiteSupplierDocumentTypeViewModel[]>([]);
  siteSuppliers = new BehaviorSubject<BasicSiteSupplierViewModel[]>([]);
  selectedsiteSupplierDocumentType = null;
  selectedsiteSupplierId: number = null;
  constructor(private userService: UserService) { }

  ngOnInit() {
  }

  showDocumentKindTab(): boolean {
    return this.userService.isGodUser();
  }
}
