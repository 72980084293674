<div class="row">
  <div class="col-md-12">
    <div class="card">
      <obc-loader [show]="inProgress"></obc-loader>
      <header class="card-header">
        <div class="d-flex flex-row justify-content-between">
          <div>Asset Report</div>
          <div>
            <button type="button" class="btn btn-primary mr-1" (click)="openUpsertAssetModal(upsertAssetModal, null)">
              Add New Asset
            </button>
          </div>
        </div>
      </header>
      <div class="card-body">
        <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>

          <ng-template obcGridTemplateName="regionName" let-item="data">
            <div *ngIf="isShowTemplateColumns('regionName','regionName')"
              class="d-flex flex-row justify-content-between">
              {{ item.regionName }}
              <span *ngIf="!item.regionName"><strong>All Regions</strong></span>
            </div>
          </ng-template>

          <ng-template obcGridTemplateName="currentAssignedUser" let-item="data">
            <span *ngIf="isShowTemplateColumns('currentAssigneduser','currentAssignedUser')">
              {{ item.currentAssignedUser }}
            </span>
            <span *ngIf="isShowTemplateColumns('currentAssigneduser','currentAssignedUserMobile') &&
              item.currentAssignedUserMobile">
              ({{ item.currentAssignedUserMobile }})
            </span>
          </ng-template>

          <ng-template obcGridTemplateName="currentAssignedLocation" let-item="data">
            <span *ngIf="isShowTemplateColumns('currentAssignedLocation','currentAssignedLocationSite')">
              {{ item.currentAssignedLocationSite }}
            </span>
            <span *ngIf="isShowTemplateColumns('currentAssignedLocation','currentAssignedLocation') &&
                          item.currentAssignedLocation">
              - {{ item.currentAssignedLocation }}
            </span>
          </ng-template>

          <ng-template obcGridTemplateName="viewDetails" let-item="data">
            <button class="btn btn-sm btn-info ml-2 m-1" (click)="openModal(toolHistory, item)">
              History
            </button>
            <button class="btn btn-sm btn-primary ml-2 m-1" (click)="openUpsertAssetModal(upsertAssetModal, item)">
              <i class="fas fa-edit"></i>
            </button>
            <button class="btn btn-sm btn-danger ml-2 m-1" (click)="onRemoveAsset(item.id)">
              <i class="fas fa-times"></i>
            </button>
          </ng-template>

          <ng-template obcGridTemplateName="includeDynamicFieldsTemplate" let-item="data">
            <div *ngIf="isShowTemplateColumns('includeDynamicFieldsCol','dynamicFieldValues') &&
                        item.dynamicFieldValues && item.dynamicFieldValues.length > 0">
              <ul>
                <ng-container *ngFor="let field of item.dynamicFieldValues">
                  <li *ngIf="showText(field) || showImages(field) || showDate(field)">
                    {{ field.fieldName }}:
                    <span *ngIf="showText(field)" class="text-danger">
                      {{ field.value }}
                    </span>
                    <span *ngIf="showDate(field)" class="text-danger">
                      {{ field.value | date}}
                    </span>
                    <obc-file-viewer *ngIf="showImages(field)" [tooltip]="'View Images'"
                      [urls]="field.imagesURLs ? field.imagesURLs : []" [btnIcon]="'fas fa-image'"
                      [btnClass]="'btn btn-success'" [btnStyle]="' height: 38px; '"></obc-file-viewer>
                  </li>
                </ng-container>
              </ul>
            </div>
          </ng-template>

          <ng-template obcGridTemplateName="includeAssignmentDynamicFieldsTemplate" let-item="data">
            <div *ngIf="isShowTemplateColumns('includeAssignmentDynamicFieldsCol','assignDynamicFieldValues') &&
                            item.assignDynamicFieldValues &&
                            item.assignDynamicFieldValues.length > 0">
              <ul>
                <ng-container *ngFor="let field of item.assignDynamicFieldValues">
                  <li *ngIf="showText(field) || showImages(field) || showDate(field)">
                    {{ field.fieldName }}:
                    <span *ngIf="showText(field)" class="text-danger">
                      {{ field.value }}
                    </span>
                    <span *ngIf="showDate(field)" class="text-danger">
                      {{ field.value | date}}
                    </span>
                    <obc-file-viewer *ngIf="showImages(field)" [tooltip]="'View Images'"
                      [urls]="field.imagesURLs ? field.imagesURLs : []" [btnIcon]="'fas fa-image'"
                      [btnClass]="'btn btn-success'" [btnStyle]="' height: 38px; '"></obc-file-viewer>
                  </li>
                </ng-container>
              </ul>
            </div>
          </ng-template>

        </app-base-grid>

      </div>
    </div>
  </div>
</div>

<ng-template #upsertAssetModal>
  <div class="card">
    <div class="card-header">
      <h5>
        <ng-container *ngIf="!currentAsset">Submit New</ng-container>
        <ng-container *ngIf="currentAsset">Edit</ng-container> Asset
      </h5>
      <div class="card-actions">
        <a (click)="upsertAssetModalRef.hide()" class="card-action card-action-dismiss"></a>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <app-upsert-inventory-asset [asset]="currentAsset" (closeMe)="hideUpsertAssetModal()">
          </app-upsert-inventory-asset>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #toolHistory>
  <obc-tool-assign-history [tool]="selectedItemToViewHistory" (hide)="closeModal($event)">
  </obc-tool-assign-history>
</ng-template>
