
export enum ToolTipPlace {
    top = 'top',
    right = 'right',
    left = 'left',
    bottom = 'bottom',
    auto = 'auto',
}

export enum ToolTipTriggers {
    hover = 'hover',
    focus = 'focus',
    click = 'click'
}

export enum ToolTipMode {
    info = 'info',
    danger = 'danger',
    success = 'success'
}