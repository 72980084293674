import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AnnouncementModel, RequiredDocument } from "../../models/induction-viewmodel";
import { UserDocumentType } from "../../models/user-document-type";
import { QuestionViewModel } from "../../models/question-viewmodel";
import { AnnouncementViewModel } from "../../models/announcement-viewmodel";
import { FormControl, FormGroup } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ModalService } from "../../services/modal.service";
import { forkJoin } from "rxjs";
import { SiteActiveStatus } from "../../enums/site-active-status.enum";
import { UserDocumentTypeService } from "../../services/user-document-type.service";
import { QuestionService } from "../../services/question.service";
import { AnnouncementService } from "../../services/announcement.service";
import { Orderable } from 'src/app/models/orderable-model';
import { MixtureItemModel } from 'src/app/models/mixture-item-model';
import { ItemsMixtureTypeEnum } from 'src/app/enums/items-mixture-type.enum';
import { InductionService } from "../../services/induction-service";
import { FormService } from "../../services/form.service";
import { OptionalMandatoryState } from 'src/app/enums/site-document-state.enum';
import { SiteAnnouncementForceViewMode } from "../../enums/site-announcement-force-view-mode";
import { ToastrService } from 'ngx-toastr';
import { FormType } from 'src/app/enums/form-type';

@Component({
  selector: 'obc-items-mixture',
  templateUrl: './items-mixture.component.html',
  styleUrls: ['./items-mixture.component.scss']
})
export class ItemsMixtureComponent implements OnInit {

  @Output('items') public result = new EventEmitter();

  formGroup: FormGroup;
  allDocuments: UserDocumentType[] = [];
  selectedDocuments: UserDocumentType[] = [];
  allQuestions: QuestionViewModel[] = [];
  allAnnouncements: AnnouncementViewModel[] = [];

  questionModalRef: BsModalRef;
  documentModalRef: BsModalRef;
  announcementModalRef: BsModalRef;

  @Input() orderableItems: Orderable[];
  @Input() displayOnly: boolean = false;
  @Input() inductionId: number;
  @Input() formId: number;
  @Input() showAddDocumentButton: boolean = true;
  @Input() formType: FormType;

  items: MixtureItemModel[] = [];

  ItemsMixtureTypeEnum = ItemsMixtureTypeEnum;

  constructor(private inductionService: InductionService,
    private formService: FormService,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private documentTypeService: UserDocumentTypeService,
    private questionService: QuestionService,
    private announcementService: AnnouncementService,
    private toastrService: ToastrService,
  ) { }

  isXLarge: boolean = false;
  winSize: number;
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.winSize = window.innerWidth;
    this.isXLarge = window.innerWidth >= 1200;
  }

  scrollTop: number = 0;
  scrollLocked: boolean = false;
  onScroll(event) {
    if (this.scrollLocked)
      return

    this.scrollTop = document.getElementById(event.target.id).scrollTop;
  }
  scrollToByElementId() {
    setTimeout(() => {
      document.getElementById('question-container1').scrollTo(0, this.scrollTop);
      this.scrollLocked = false;
    }, 100);
  }

  get selectedDocumentIds() {
    return this.items.filter(p => p.type == ItemsMixtureTypeEnum.Document && p.isSelected).map(p => p.id);
  }
  get selectedAnnouncementIds() {
    return this.items.filter(p => p.type == ItemsMixtureTypeEnum.Announcement && p.isSelected).map(p => p.id);
  }
  get selectedQuestionIds() {
    return this.items.filter(p => p.type == ItemsMixtureTypeEnum.Question && p.isSelected).map(p => p.id);
  }

  ngOnInit(): void {

    this.onResize();

    this.formGroup = new FormGroup({
      items: new FormControl([]),
    });

    let _this = this;

    forkJoin({
      selectedDocs: this.documentTypeService.getSelectable(),
      allDocs: this.documentTypeService.GetAllCompaniesUserDocumentTypes(),
      questions: this.questionService.getQuestions(),
      announcements: this.announcementService.getAll(SiteActiveStatus.All)
    }).subscribe(res => {
      this.allDocuments = (res.allDocs);
      this.selectedDocuments = (res.selectedDocs);
      this.allQuestions = res.questions.map(x => new QuestionViewModel(x));
      this.allAnnouncements = (res.announcements);
    })

    this.orderableItems?.forEach(function (item, _) {
      _this.items?.push({
        id: item.id,
        type: item.type,
        order: item.order,
        documentState: item.documentState,
        announcementState: item.announcementState,
      } as MixtureItemModel)
    });
    this.items.sort((a, b) => a.order > b.order ? 1 : a.order < b.order ? -1 : 0);

    this.formGroup.controls['items'].setValue(this.items)
  }

  getAddedQuestions() {
    const questions = this.items?.filter(item => item.type == ItemsMixtureTypeEnum.Question)
    return questions ? questions.map(x => x.id) : [];
  }

  getAddedAnnouncements() {
    const announcements = this.items?.filter(item => item.type == ItemsMixtureTypeEnum.Announcement)
    return announcements ? announcements.map(x => {
      let announcement = this.getAnnouncementById(x.id);
      return {
        announcementId: announcement.id,
        state: announcement.state,
        displayOrder: announcement.order
      } as AnnouncementModel
    }) : [];
  }

  getAddedDocuments() {
    const documents = this.items?.filter(item => item.type == ItemsMixtureTypeEnum.Document);
    return documents ? documents.map(x => {
      let doc = this.getDocumentById(x.id);
      return {
        documentTypeId: doc.id,
        state: doc.state,
        displayOrder: doc.order,
      } as RequiredDocument
    }) : [];
  }

  onShowAddQuestionsModal(template) {
    this.questionModalRef = this.bsModalService.show(template);
  }

  onShowAddDocumentsModal(template) {
    this.documentModalRef = this.bsModalService.show(template);
  }

  onShowAddAnnouncementsModal(template) {
    this.announcementModalRef = this.bsModalService.show(template, { class: 'modal-lg' });
  }

  removeSelectedAnnouncementConfirmation(announcementId: number) {
    this.modalService
      .confirm("Are you sure you want to delete selected announcement?")
      .subscribe(del => {
        if (del === true) {
          this.removeSelectedAnnouncement(announcementId);
        }
      });
  }

  removeSelectedAnnouncement(announcementId: number) {
    const index = this.items?.findIndex(item => item.id == announcementId && item.type == ItemsMixtureTypeEnum.Announcement);
    if (index > -1) {
      this.scrollLocked = true;

      this.items?.splice(index, 1);
      this.setValuesAndEmit();
      this.reDrawSortable();
    }
  }

  removeSelectedQuestionConfirmation(questionId: number) {
    this.modalService
      .confirm("Are you sure you want to delete selected question?")
      .subscribe(del => {
        if (del === true) {
          this.removeSelectedQuestion(questionId);
        }
      });
  }

  removeSelectedQuestion(questionId: number) {
    const index = this.items?.findIndex(item => item.id == questionId && item.type == ItemsMixtureTypeEnum.Question);
    if (index > -1) {
      this.scrollLocked = true;

      this.items?.splice(index, 1);
      this.setValuesAndEmit();
      this.reDrawSortable();
    }
  }

  removeSelectedDocumentConfirmation(documentId: number) {
    this.modalService
      .confirm("Are you sure you want to delete selected document?")
      .subscribe(del => {
        if (del === true) {
          this.removeSelectedDocument(documentId);
        }
      });
  }

  removeSelectedDocument(documentId: number) {
    const index = this.items?.findIndex(item => item.id === documentId && item.type === ItemsMixtureTypeEnum.Document);
    if (index > -1) {
      this.scrollLocked = true;

      this.items?.splice(index, 1);
      this.setValuesAndEmit();
      this.reDrawSortable();
    }
  }

  addDocument(doc: RequiredDocument) {
    if (doc != null) {
      const index = this.items.findIndex(item => item.id == doc.documentTypeId && item.type == ItemsMixtureTypeEnum.Document);

      if (index === -1) {
        this.scrollLocked = true;

        this.items.push({
          id: doc.documentTypeId,
          type: ItemsMixtureTypeEnum.Document,
          order: this.getMaxOrderIndex(),
          documentState: doc.state
        } as MixtureItemModel);
        this.reDrawSortable();
        this.setValuesAndEmit();
      }
    }
  }

  addAnnouncement(res: AnnouncementViewModel) {
    if (res != null) {

      const index = this.items.findIndex(item => item.id == res.id && item.type == ItemsMixtureTypeEnum.Announcement);
      if (index === -1) {
        this.scrollLocked = true;

        this.items.push({
          id: res.id,
          type: ItemsMixtureTypeEnum.Announcement,
          order: this.getMaxOrderIndex(),
          documentState: res.state,
          announcementState: res.forceViewMode,
        } as MixtureItemModel);
        this.reDrawSortable();
        this.setValuesAndEmit();
      }
    }
  }

  addQuestion(res: QuestionViewModel) {
    if (res != null) {   
      if(res.isSensitive && this.formType == FormType.SiteBriefing) 
         this.toastrService.warning('The answers of sensitive questions will not be seen by end users.');
      const index = this.items.findIndex(item => item.id == res.id && item.type == ItemsMixtureTypeEnum.Question);
      if (index === -1) {
        this.scrollLocked = true;

        this.items.push({
          id: res.id,
          type: ItemsMixtureTypeEnum.Question,
          order: this.getMaxOrderIndex(),
        } as MixtureItemModel);

        this.reDrawSortable();
        this.setValuesAndEmit();
      }
    }
  }

  getMaxOrderIndex() {
    let orderIndex = Object.assign([], this.items).sort(function (a, b) {
      return b.order - a.order;
    });

    return orderIndex.length > 0 ? orderIndex[0].order + 1 : 0;
  }

  onCloseAddQuestionsModal() {
    this.questionModalRef.hide();
  }

  onCloseAddDocumentsModal() {
    this.documentModalRef.hide();
  }

  onCloseAddAnnouncementsModal() {
    this.announcementModalRef.hide();
  }

  getAnnouncementById(announcementId: number) {
    return this.allAnnouncements.find(x => x.id == announcementId);
  }

  getQuestionById(questionId: number) {
    return this.allQuestions.find(x => x.id == questionId);
  }

  getDocumentById(documentId: number) {
    return this.allDocuments.find(x => x.id == documentId);
  }

  onChangedOrder() {
    let tmpItems = this.formGroup.controls['items'].value;
    for (let i = 0; i < this.formGroup.controls['items'].value.length; i++) {
      tmpItems[i].order = i;
      tmpItems[i].isSelected = false;
    }
    this.items = tmpItems;
    this.setValuesAndEmit();
  }

  setValuesAndEmit() {
    this.formGroup.controls['items'].setValue(this.items);
    if (this.displayOnly)
      this.sendOrdersToServer();
    else
      this.result.emit(this.items);
  }

  sendOrdersToServer() {
    if (this.inductionId && this.displayOnly) {
      this.inductionService.changeItemsOrder(this.inductionId, this.formGroup.controls['items'].value)
        .subscribe(_ => {
        });
    } else if (this.formId && this.displayOnly) {
      this.formService.changeItemsOrder(this.formId, this.formGroup.controls['items'].value)
        .subscribe(_ => {
        });
    }
  }

  updateState(id: number, newState: OptionalMandatoryState, itemsMixtureTypeEnum: ItemsMixtureTypeEnum) {
    let item = this.items.find(item => item.id == id && item.type == itemsMixtureTypeEnum);
    item.documentState = newState == OptionalMandatoryState.Mandatory ? OptionalMandatoryState.Mandatory : OptionalMandatoryState.Optional;
    this.setValuesAndEmit();
  }
  updateAnnouncementState(id: number, newState: SiteAnnouncementForceViewMode, itemsMixtureTypeEnum: ItemsMixtureTypeEnum) {
    let item = this.items.find(item => item.id == id && item.type == itemsMixtureTypeEnum);
    item.announcementState = newState;
    this.setValuesAndEmit();
  }


  showObcSortable: boolean = true;

  reDrawSortable() {
    this.showObcSortable = false;
    setTimeout(() => {
      this.showObcSortable = true;
    }
      , 50);

    this.scrollToByElementId();
  }

  onRemoveSelected() {
    this.modalService.confirm("Are you sure you want to delete selected items?").subscribe(del => {
      if (del === true) {
        this.selectedDocumentIds.forEach((item) => {
          this.removeSelectedDocument(item);
        });

        this.selectedAnnouncementIds.forEach((item) => {
          this.removeSelectedAnnouncement(item);
        });

        this.selectedQuestionIds.forEach((item) => {
          this.removeSelectedQuestion(item);
        });
      }
    });
  }


}
