import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { DateComparisonMode, DateQuestionLimit, DateQuestionLimitValueMode, DateQuestionParameter } from 'src/app/models/questionParameters/date-question-parameter';
import { SiteQuestionItemFormControl, SiteQuestionRendererViewModel } from 'src/app/models/site-question-viewmodel';
import { QuestionRendererComponent } from '../question-renderer/question-renderer.component';
import { CacheService } from "../../../../services/cache.service";

@Component({
  selector: 'obc-date-question-renderer',
  templateUrl: './date-question-renderer.component.html',
  styleUrls: ['./date-question-renderer.component.scss']
})
export class DateQuestionRendererComponent extends QuestionRendererComponent implements OnInit {
  DatetimePickerMode = DatetimePickerMode;
  formControl: SiteQuestionItemFormControl;

  @Input() set question(model: SiteQuestionRendererViewModel) {
    let answer = model.initialAnswers && model.initialAnswers.length > 0 ? model.initialAnswers[0]?.answer : null;
    this.model = model;
    let dateValue = answer != null ? new Date(answer) : null;
    this.formControl = new SiteQuestionItemFormControl(dateValue, null);
    this.config = JSON.parse(this.model.question.questionParameters) as DateQuestionParameter;
    this.minDate = this.getLimitDate(this.config?.limitForMinimumValue);
    this.maxDate = this.getLimitDate(this.config?.limitForMaximumValue);

    this.formGroup = this.fb.group({
      value: this.formControl
    });
  };

  config: DateQuestionParameter;
  minDate: Date;
  maxDate: Date;

  get mode(): DatetimePickerMode {
    return this.config.includeTime ? DatetimePickerMode.DateTimePicker : DatetimePickerMode.Calender;
  }

  constructor(private fb: FormBuilder, public cacheService: CacheService) {
    super(cacheService);
  }


  getLimitDate(limit: DateQuestionLimit): Date {

    if (limit?.valueMode == null)
      return null;
    if (limit.valueMode == DateQuestionLimitValueMode.Absolute)
      return new Date(Date.parse(limit.compareValue));
    var date = new Date();
    var numberOfDaysToAdd = (limit.dayCount ?? 0) * (limit.dateComparisonMode == DateComparisonMode.Before ? -1 : 1);
    date.setDate(date.getDate() + numberOfDaysToAdd);
    return date;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
