<div class="card">
  <div class="card-header">
    <h5>Add/Delete Industry Types</h5>
  </div>
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="row">
      <div class="col-12">
        <form class="form-horizontal form-bordered" [formGroup]="newIndustryFormGroup" (ngFormSubmit)="addIndustryType()">
          <section class="card">
            <div class="card-body">
              <div class="form-group row">
                <label class="col-sm-4 control-label text-sm-right pt-2">Industry name :</label>
                <div class="col-sm-8">
                  <input type="text" name="name" class="form-control" formControlName="type">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-4 control-label text-sm-right pt-2">Is active? </label>
                <div class="col-sm-8">
                  <obc-switch [value]="newIndustryFormGroup.controls.isActive.value"
                              (valueChanged)="newIndustryFormGroup.controls.isActive.setValue($event)">
                  </obc-switch>
                </div>
              </div>

            </div>
            <footer class="card-footer text-right">
              <button class="btn btn-primary" [disabled]="!newIndustryFormGroup.valid" (click)="addIndustryType()">
                Add Industry Type
              </button>
              <button type="reset" class="btn btn-default">Cancel</button>
            </footer>
          </section>
        </form>
        <div class="alert alert-warning mt-1" *ngIf="errorMessage">
          <strong>{{errorMessage}}</strong>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="errorMessage = null">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <hr class="w-100">
      <div class="col-12 mb-2">
        <p style="text-align: center;" *ngIf="industryTypes == null">
          Loading ...
        </p>
        <p style="text-align: center;" *ngIf="industryTypes != null && industryTypes.length == 0">
          Nothing Found
        </p>
        <table *ngIf="industryTypes" class="table table-bordered table-striped mb-0">
          <thead>
            <tr>
              <th>#</th>
              <th>Type</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let industryType of industryTypes">
              <td><span>{{industryType.id}}</span></td>
              <td><span>{{industryType.type}}</span></td>
              <td>
                <span *ngIf="industryType.isActive">Enable</span>
                <span *ngIf="!industryType.isActive">Disable</span>
              </td>

              <td>
                <p class="button-group">
                  <button class="btn btn-sm btn-primary" (click)="onShowEditModal(editIndustryType, industryType)">
                    Edit
                  </button>
                </p>
                <div class="d-flex justify-content-end">

                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<ng-template #editIndustryType>
  <div class="card" *ngIf="editIndustryFormGroup">
    <obc-loader [show]="inAddEditProgress"></obc-loader>
    <div class="card-header">
      <span>Edit Industry Type</span>
      <button type="button" class="close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <form [formGroup]="editIndustryFormGroup">
        <input type="hidden" formControlName="id">
        <div class="form-group">
          <label>Type</label>
          <input type="text" class="form-control" formControlName="type">
        </div>
        <div class="form-group form-check">
          <input type="checkbox" class="form-check-input" formControlName="isActive">
          <label class="form-check-label">Is active?</label>
        </div>
        <p class="button-group">
          <button [disabled]="!editIndustryFormGroup.valid" href="#" class="card-link btn btn-primary"
                  (click)="onSubmitEditForm()">
            Save Changes
          </button>
        </p>
      </form>
      <div class="alert alert-warning mt-1" *ngIf="errorEditModalMessage">
        <strong>{{errorEditModalMessage}}</strong>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="errorEditModalMessage = null">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

