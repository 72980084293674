<obc-loader [show]="inProgress"></obc-loader>
<div class="table-responsive">
  <table class="table table-bordered obc-table" style="width: 100%;">
    <thead>
      <tr>
        <td>Action</td>
        <td>Condition</td>
        <td>Status</td>
        <td>
          <div class="text-right">
            <button class="btn btn-sm btn-primary" (click)="onAddToolDynamicFieldTrigger(editTriggerModal)">+Add</button>
          </div>
        </td>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let trigger of (triggers | async)">
        <td>
          {{getTriggerActionFriendlyName(trigger.action)}}
        </td>
        <td>
          {{getTriggerConditionFriendlyName(trigger)}}
        </td>
        <td>
          <obc-switch (valueChanged)="onToolDynamicFieldTriggerStateChange($event, trigger)" [value]="trigger.status"
            [trueLabel]="'Active'" falseLabel="Disabled"></obc-switch>
        </td>
        <td>
          <button class="btn btn-danger m-1" (click)="onRemoveToolDynamicFieldTrigger(trigger)">Remove</button>
          <button class="btn btn-info m-1" (click)="onEditToolDynamicFieldTrigger(trigger, editTriggerModal)">Edit</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #editTriggerModal>
  <div class="card">
    <div class="card-header">
      <ng-container *ngIf="currentTrigger?.id > 0">Edit Trigger</ng-container>
      <ng-container *ngIf="!currentTrigger?.id">Add Trigger</ng-container>
      <button type="button" class="close float-right" (click)="editModalHandle.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <obc-inventory-dynamic-field-trigger-editor [toolDynamicField]="_dynamicField" [trigger]="currentTrigger" (close)="onCloseInventoryDynamicFieldTriggerEditor()">
      </obc-inventory-dynamic-field-trigger-editor>
    </div>
  </div>
</ng-template>
