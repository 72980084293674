import { UserService } from './../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserEditMobileModel } from 'src/app/models/user-edit-mobile-model';
import { UserEditMobileViewModel } from 'src/app/models/user-edit-mobile-viewmodel';
import { Subject } from 'rxjs';

@Component({
  selector: 'obc-user-info-modal',
  templateUrl: './user-info-modal.component.html',
  styleUrls: ['./user-info-modal.component.scss']
})
export class UserInfoModalComponent implements OnInit {
  public onClose: Subject<boolean>;

  mobileModel: UserEditMobileModel;
  selectedUser: UserEditMobileViewModel;
  constructor(public bsModalRef: BsModalRef, public userService: UserService) { }

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  cancelModal() {
    this.bsModalRef.hide();
  }

  editMobileNumber() {
    this.userService.editUserMobile(this.mobileModel).subscribe(res => {
      if (res) {
        this.onClose.next(true);
        this.bsModalRef.hide();
      }
    }, err => {
      this.onClose.next(false);
      this.bsModalRef.hide();
    });
  }

}
