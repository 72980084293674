import { UserNotificationModel } from './../../models/notifications/models';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UserNotificationQueryModel, UserNotificationViewModel } from 'src/app/models/notifications/models';
import { BasePagingModel } from 'src/app/models/paging_model';
import { ModalService } from 'src/app/services/modal.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LayoutService } from 'src/app/services/layout.service';
import { NotificationServerResponseViewModel } from "../../models/notification-server-response-view-model";
import { EventType } from "../../enums/event-type";
import { SignalRService } from "../../services/signal-r.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: 'obc-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss']
})
export class NotificationCenterComponent implements OnInit {

  pageInfo: BasePagingModel = { pageSize: 50, pageNumber: 0 };
  inProgress: boolean;
  notifications: UserNotificationViewModel[];
  notificationsCount: number;
  unreadNotificationCount: number;
  formattedUnreadNotificationCount: string;
  hideElement: boolean[];
  ignoreSignalRLoadNotificationInvoke: boolean = false;

  @Input() hide: boolean = true;
  @Output() onUpdateNotificationCount: EventEmitter<string> = new EventEmitter<string>();

  constructor(private notificationService: NotificationService,
              private modalService: ModalService,
              private layoutService: LayoutService,
              private signalRService: SignalRService,
              private userService: UserService,
  ) {
  }

  ngOnInit(): void {
    this.userService.info.asObservable().subscribe((res) => {
      if(res) {
        this.loadNotifications();
      }
    })
    this.signalRService.serverMessage.asObservable().subscribe((res: NotificationServerResponseViewModel) => {
      if (res?.eventType == EventType.Notification) {
        if (this.ignoreSignalRLoadNotificationInvoke) {
          this.ignoreSignalRLoadNotificationInvoke = false;
          this.emitNotificationCount();
        } else {
          this.pageInfo.pageNumber = 0;
          this.loadNotifications();
        }
      }
    });
  }

  get isDark() {
    return this.layoutService.isInDarkMode;
  }

  hidePanel() {
    this.hide = true;
  }

  loadNotifications(add = false) {
    if (this.inProgress)
      return;
    this.inProgress = true;
    this.notificationService.getUserNotificationList({ pagingInfo: this.pageInfo } as UserNotificationQueryModel)
      .pipe((finalize(() => this.inProgress = false)))
      .subscribe(
        res => {
          this.inProgress = false;
          if (!add)
            this.notifications = res.data;
          else {
            res.data.forEach(item => {
              this.notifications.push(item);
            });
          }
          this.notificationsCount = res.totalCount;
          this.unreadNotificationCount = res.info.unreadCount;
          this.formattedUnreadNotificationCount = res.info.formattedUnreadCount;
          this.emitNotificationCount();
        }
      );
  }

  emitNotificationCount() {
    if (this.onUpdateNotificationCount)
      this.onUpdateNotificationCount.emit(this.formattedUnreadNotificationCount);
  }

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    if (this.inProgress)
      return;
    var scrollTop = event.srcElement.scrollTop;
    var scrollHeight = event.srcElement.scrollHeight;
    var elementHeight = event.srcElement.clientHeight;
    var atBottom = scrollHeight < (elementHeight + scrollTop + 50);
    if (atBottom) {
      if (this.notificationsCount >= (this.pageInfo.pageSize * (this.pageInfo.pageNumber + 1))) {
        this.pageInfo.pageNumber++;
        this.loadNotifications(true);
      }
    }
  }

  SwitchReadState(item: UserNotificationViewModel) {
    var notificationModel: UserNotificationModel = { notificationId: item.id };
    this.inProgress = true;
    if (item.isRead) {
      this.notificationService.unreadNotification(notificationModel).subscribe(res => {
        this.reloadNotificationsManually();
      });
    } else {
      this.notificationService.readNotification(notificationModel).subscribe(res => {
        this.reloadNotificationsManually();
      });
    }
  }

  RemoveNotification(item: UserNotificationViewModel) {
    this.modalService.confirm('Are you sure you want to remove this record?').subscribe(res => {
      var notificationModel: UserNotificationModel = { notificationId: item.id };
      if (res == true) {
        this.inProgress = true;
        this.notificationService.deleteNotification(notificationModel).pipe((finalize(() => this.inProgress = false)))
          .subscribe(res => {
            this.reloadNotificationsManually();
          });
      }
    });
  }

  MarkAllAsRead() {
    this.inProgress = true;
    this.notificationService.readAllNotification()
      .subscribe(res => {
        this.reloadNotificationsManually();
      });
  }

  reloadNotificationsManually() {
    this.inProgress = false;

    // ignore fetch data from server when signal r notification event receives from server
    this.ignoreSignalRLoadNotificationInvoke = true;

    this.loadNotifications();
  }
}

