<ng-container *ngIf="form?.permitFormInfo?.isEnablePermitSchedule">
  <div class="row" *ngIf="permitSchedules">
    <div class="col-12 mb-1" *ngFor="let permitSchedule of permitSchedules; let i = index;">
      <obc-permit-schedule-item [permitSchedule]="permitSchedule" (remove)="removeSchedule(permitSchedule)">
      </obc-permit-schedule-item>
    </div>
  </div>
</ng-container>
<div class="mb-3 mt-2 ">
  <button (click)="addSchedule(scheduleModal)" class="btn btn-info">
    <i class="fas fa-plus"></i>
    {{permitSchedules && permitSchedules.length ? 'Add' : 'Set'}} Schedule
  </button>
</div>

<ng-template #scheduleModal>
  <div class="card scrollable-list">
    <div class="card-header">
    </div>
    <div class="card-body">

      <div class="p-4" style="border: 1px solid #c5c5c5;; border-radius: 8px;">
        <div class="mt-4">
          <input checked type="radio" name="scheduleType" (change)="changeScheduleMode(PermitScheduleTypeEnum.WeekDay)">
          Day of Week
          <input class="ml-3" type="radio" name="scheduleType"
            (change)="changeScheduleMode(PermitScheduleTypeEnum.Date)"> Date
        </div>
        <div class="mt-2">
          <div *ngIf="permitScheduleType == PermitScheduleTypeEnum.Date">
            <label>Schedule Date</label>
            <obc-input-date-time-picker [formControl]="formGroup?.controls['dateTime']" [mode]="datetimePickerMode.Calender">
            </obc-input-date-time-picker>
            <!-- <input [owlDateTime]="schedulePicker" class="form-control" [formControl]="formGroup?.controls['dateTime']"
              [owlDateTimeTrigger]="schedulePicker">
            <owl-date-time [pickerType]="'calendar'" #schedulePicker></owl-date-time> -->
          </div>
          <div *ngIf="permitScheduleType == PermitScheduleTypeEnum.WeekDay">
            <label>Weekday</label>
            <select class="form-control" [formControl]="formGroup?.controls['weekDay']">
              <option *ngFor="let day of daysOfWeek | enumToArray; let i = index" [selected]="day == i"
                value="{{ daysOfWeek[day] }}">{{daysOfWeek[day] | daysOfWeek}}</option>
            </select>
          </div>
          <div class="row mt-3">
            <div class="col-12 col-sm-5 d-flex flex-column align-items-center justify-content-center">
              <label>From Hour:</label>
              <timepicker [contentEditable]="false" (change)="updateFromTime()"
                [formControl]="formGroup?.controls['fromHour']" [showMeridian]="false"></timepicker>
            </div>
            <div class="col-12 col-sm-5 d-flex flex-column align-items-center justify-content-center">
              <label>To Hour:</label>
              <timepicker [contentEditable]="false" (change)="updateToTime()"
                [formControl]="formGroup?.controls['toHour']" [showMeridian]="false"></timepicker>
            </div>
            <div class="col-12 col-sm-2 d-flex flex-column align-items-center justify-content-center">
              <label>&nbsp;</label>
              <button class="btn btn-danger" tooltip="Clear Times" (click)="clearTimes()">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="mt-3 d-flex justify-content-center">
          <button class="btn btn-success" (click)="saveSchedule()">
            <i class="fas fa-check"></i> Save
          </button>
        </div>
      </div>

    </div>
  </div>
</ng-template>