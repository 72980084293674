import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SiteAnnouncementForceViewMode } from 'src/app/enums/site-announcement-force-view-mode';
import { FileType } from "../../../enums/file-type.enum";

@Component({
  selector: 'obc-announcement-force-view-mode-selector',
  templateUrl: './announcement-force-view-mode-selector.component.html',
  styleUrls: ['./announcement-force-view-mode-selector.component.scss']
})
export class AnnouncementForceViewModeSelectorComponent implements OnInit {

  @Input() type: FileType = FileType.Pdf;
  @Input() forceViewMode: SiteAnnouncementForceViewMode;
  SiteAnnouncementForceViewMode = SiteAnnouncementForceViewMode;
  FileType = FileType;

  @Output() onSelect: EventEmitter<SiteAnnouncementForceViewMode> = new EventEmitter<SiteAnnouncementForceViewMode>();
  constructor() { }

  ngOnInit(): void {}

  emitOutput() {
    this.onSelect.emit(this.forceViewMode);
  }

}
