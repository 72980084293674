import { Component, OnInit, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';

@Component({
  selector: 'obc-array-form-control',
  templateUrl: './array-form-control.component.html',
  styleUrls: ['./array-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ArrayFormControlComponent,
      multi: true,
    }
  ]
})
export class ArrayFormControlComponent implements OnInit, ControlValueAccessor {

  onChange: Function;
  formControl = new FormControl('');
  @Input() splitter: string = ",";
  @Input() placeholder: string = "";
  constructor() { }
  writeValue(obj: any[]): void {
    if (obj != null && obj.length > 0) {
      var value = obj.join(`${this.splitter} `);
      this.formControl.setValue(value);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }

  items: any[] = [];
  ngOnInit() {
    this.formControl.valueChanges.subscribe((res: string) => {
      this.items = res.split(this.splitter).filter(i =>
        i != null && i.length > 0)
        .map(i => {
          return i.trim()
        });
      if (this.onChange)
        this.onChange(this.items);
    });
  }
}
