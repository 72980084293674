import { Pipe, PipeTransform } from '@angular/core';
import { AskMode } from '../enums/ask-mode';

@Pipe({
  name: 'tokenize'
})
export class TokenizePipe implements PipeTransform {

  transform(value: string, displayCharsCount: number = 4): string {
    if (value.length < 8)
      displayCharsCount = value.length / 2;
    return value.substring(0, displayCharsCount) +
      '*****************' +
      value.substring(value.length - displayCharsCount)
  }

}
