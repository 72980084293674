import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CountryViewModel } from '../models/country-view-model';
import { ServerViewModel } from '../models/server_view_model';
import { Timezone } from '../models/time-zone';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(
    private _http: HttpClient,
    private _errorService: ErrorService) { }

  getCoutries = (): Observable<CountryViewModel[]> =>
    this._http
      .get<CountryViewModel[]>('/api/country')
      .pipe(catchError(this._errorService.handleError));

  getTimeZones = (): Observable<Timezone[]> =>
    this._http.get<Timezone[]>('/api/country/time-zones')
      .pipe(catchError(this._errorService.handleError));

  getOtherServerList = (): Observable<ServerViewModel[]> =>
    this._http.get<ServerViewModel[]>('/api/country/other-server-list')
      .pipe(catchError(this._errorService.handleError));

}
