import { Pipe, PipeTransform } from '@angular/core';
import { SiteSupplierDocumentVersionStatus } from "../enums/site-supplier-document-status";

@Pipe({
  name: 'siteSupplierDocumentVersionStatus'
})
export class SiteSupplierDocumentVersionStatusPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): string {
    if (value == SiteSupplierDocumentVersionStatus.Pending || value == 1 || value == "Pending") return "Pending";
    if (value == SiteSupplierDocumentVersionStatus.Approved || value == 2 || value == "Approved") return "Approved";
    if (value == SiteSupplierDocumentVersionStatus.Declined || value == 3 || value == "Declined") return "Declined";
    if (value == SiteSupplierDocumentVersionStatus.Deleted || value == 4 || value == "Deleted") return "Deleted";
    return value?.toString() ?? "";
  }

}
