import { Pipe, PipeTransform } from '@angular/core';
import {ScheduleReportMode} from "../enums/schedule-report-mode";

@Pipe({
  name: 'scheduleReportMode'
})
export class ScheduleReportModePipe implements PipeTransform {

  transform(value: any): string {
    if (value == ScheduleReportMode.ScheduleReport)
      return "Scheduled Report";
    if (value == ScheduleReportMode.HashCode)
      return "Magic Link";
  }

}
