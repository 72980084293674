import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FullProductPlanViewmodel, RequestProductPlanModel } from 'src/app/models/product-plan-viewmodel';
import { ProductPlanService } from 'src/app/services/product-plan.service';
import { LayoutService } from 'src/app/services/layout.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProductPlanSubscriptionType } from '../../../enums/product-plan-subscription-type.enum';

@Component({
  selector: 'obc-manage-plans',
  templateUrl: './manage-plans.component.html',
  styleUrls: ['./manage-plans.component.css']
})
export class ManagePlansComponent implements OnInit {
  editFormGroup: FormGroup;
  inProgress: boolean = false;
  inEditProgress: boolean = false;
  bsModalRef: BsModalRef;
  plans: FullProductPlanViewmodel[] = null;
  SubscriptionPlans = ProductPlanSubscriptionType;


  constructor(private layoutService: LayoutService,
    private productPlanService: ProductPlanService,
    private fb: FormBuilder,
    private bsModalService: BsModalService) { }

  ngOnInit() {
    this.layoutService.header = "Manage Plans";
    this.inProgress = true;
    this.productPlanService.getAllPlans().subscribe(res => {
      this.plans = res;
      this.inProgress = false;
    });
  }

  onShowEditModal(template, model: RequestProductPlanModel) {
    this.editFormGroup = this.fb.group({
      id: new FormControl(model?.id ?? 0),
      title: new FormControl(model?.title ?? '', [Validators.required]),
      activeSiteCount: new FormControl(model?.activeSiteCount ?? null),
      averageCheckInCountPerDay: new FormControl(model?.averageCheckInCountPerDay ?? null),
      stripePlanId: new FormControl(model?.stripePlanId ?? '', [Validators.required]),
      pricePerMonth: new FormControl(model?.pricePerMonth ?? null),
      priceUnit: new FormControl(model?.priceUnit ?? 'AUD', [Validators.required]),
      billingPeriod: new FormControl(model?.billingPeriod ?? 0, [Validators.required]),
      subscriptionType: new FormControl(model?.subscriptionType ?? null),
      numberOfQuestions: new FormControl(model?.numberOfQuestions ?? null),
      numberOfUsers: new FormControl(model?.numberOfUsers ?? null),
      supportViaEmail: new FormControl(model?.supportViaEmail ?? false),
      support24x7: new FormControl(model?.support24x7 ?? false),
      personalisedTraining: new FormControl(model?.personalisedTraining ?? false),
      integrationApi: new FormControl(model?.integrationApi ?? false),
      isPublic: new FormControl(model?.isPublic ?? false),
      exportFeature: new FormControl(model?.enableExportFeature ?? false),
      requiredDocumentFieldFeature: new FormControl(model?.enableRequiredDocumentFieldFeature ?? false),
      schaduleReportFeature: new FormControl(model?.enableSchaduleReportFeature ?? false),
      announcementsFeature: new FormControl(model?.enableAnnouncementsFeature ?? false),
      scannablesFeature: new FormControl(model?.enableScannablesFeature ?? false),
      visitorTypesFeature: new FormControl(model?.enableVisitorTypesFeature ?? false),
      siteHostsFeature: new FormControl(model?.enableSiteHostsFeature ?? false),
      automationFeature: new FormControl(model?.enableAutomationFeature ?? false),
      kioskFeature: new FormControl(model?.enableKioskFeature ?? false),
      preCheckInFeature: new FormControl(model?.enablePreCheckInFeature ?? false),
      inventory: new FormControl(model?.enableInventoryFeature ?? false),
      form: new FormControl(model?.enableFormFeature ?? false),
      accessControl: new FormControl(model?.enableAccessControl ?? false),
      siteBriefing: new FormControl(model?.enableSiteBriefing ?? false),
      earlyAccess: new FormControl(model?.enableEarlyAccess ?? false),
      autoRenew:  new FormControl(model?.enableSiteBriefingAutoRenew ?? false),
    });

    var config = {
      class: "modal-lg"
    };

    this.bsModalRef = this.bsModalService.show(template, config);
  }

  onSave() {
    var model = {
      id: this.editFormGroup.get('id').value,
      title: this.editFormGroup.get('title').value,
      activeSiteCount: this.editFormGroup.get('activeSiteCount').value,
      averageCheckInCountPerDay: this.editFormGroup.get('averageCheckInCountPerDay').value,
      stripePlanId: this.editFormGroup.get('stripePlanId').value,
      pricePerMonth: this.editFormGroup.get('pricePerMonth').value,
      priceUnit: this.editFormGroup.get('priceUnit').value,
      billingPeriod: this.editFormGroup.get('billingPeriod').value,
      subscriptionType: this.editFormGroup.get('subscriptionType').value,
      numberOfQuestions: this.editFormGroup.get('numberOfQuestions').value,
      numberOfUsers: this.editFormGroup.get('numberOfUsers').value,
      supportViaEmail: this.editFormGroup.get('supportViaEmail').value,
      support24x7: this.editFormGroup.get('support24x7').value,
      personalisedTraining: this.editFormGroup.get('personalisedTraining').value,
      integrationApi: this.editFormGroup.get('integrationApi').value,
      isPublic: this.editFormGroup.get('isPublic').value,
      enableExportFeature: this.editFormGroup.get('exportFeature').value,
      enableRequiredDocumentFieldFeature: this.editFormGroup.get('requiredDocumentFieldFeature').value,
      enableSchaduleReportFeature: this.editFormGroup.get('schaduleReportFeature').value,
      enableAnnouncementsFeature: this.editFormGroup.get('announcementsFeature').value,
      enableScannablesFeature: this.editFormGroup.get('scannablesFeature').value,
      enableVisitorTypesFeature: this.editFormGroup.get('visitorTypesFeature').value,
      enableSiteHostsFeature: this.editFormGroup.get('siteHostsFeature').value,
      enableAutomationFeature: this.editFormGroup.get('automationFeature').value,
      enableKioskFeature: this.editFormGroup.get('kioskFeature').value,
      enablePreCheckInFeature: this.editFormGroup.get('preCheckInFeature').value,
      enableInventoryFeature: this.editFormGroup.get('inventory').value,
      enableFormFeature: this.editFormGroup.get('form').value,
      enableAccessControl: this.editFormGroup.get('accessControl').value,
      enableSiteBriefing: this.editFormGroup.get('siteBriefing').value,
      enableEarlyAccess: this.editFormGroup.get('earlyAccess').value,
      enableSiteBriefingAutoRenew: this.editFormGroup.get('autoRenew').value,
    };
    this.inEditProgress = true;
    this.productPlanService.addUpdatePlan(model).subscribe(res => {
      this.inProgress = true;
      this.productPlanService.getAllPlans().subscribe(res => {
        this.plans = res;
        this.inProgress = false;
      });
      this.inEditProgress = false;
      this.bsModalRef.hide();
    }, err => {
      this.inEditProgress = false;
    }, () => {

    })
  }
}
