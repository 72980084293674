<div class="row">
  <div class="col-md-12">
    <div>
      <div class="chips">
        <button *ngFor="let chip of chips" [disabled]="!chip.removable" class="chip btn btn-sm btn-primary">
          {{chip.label}}<i *ngIf="chip.removable" class="fas fa-times" (click)="onRemoveChip(chip)"></i>
        </button>
      </div>
      <div class="form-group mt-1 mb-1" style="position: relative;">
        <input [placeholder]="label" [typeahead]="chipLists" (typeaheadOnSelect)="onSelectChip($event)" class="input form-control" typeaheadOptionField="label" [(ngModel)]="newChip" (keypress)="onKeyPress($event)">
        <button type="button" class="btn btn-sm btn-primary" style="position: absolute; right: 5px; top: 5px;" (click)="pushNewChip(null)"><i class="fas fa-plus"></i></button>
      </div>
    </div>
  </div>
</div>