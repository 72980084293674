<div *ngIf="control" class="form-group">
  <label>{{item?.fieldName}} {{item?.isRequired ? "*":""}}</label>
  <input [attr.type]="fieldType" *ngIf="type === toolDynamicFieldType.Text || type === toolDynamicFieldType.Number"
    class="form-control" [formControl]="control">
    
  <obc-input-date-time-picker *ngIf="type === toolDynamicFieldType.Date" [formControl]="control"
    [mode]="DatetimePickerMode.Calender">
  </obc-input-date-time-picker>

  <!-- <input [owlDateTime]="dt1" class="form-control" [formControl]="control" [owlDateTimeTrigger]="dt1"
    *ngIf="type === toolDynamicFieldType.Date">
  <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time> -->
</div>