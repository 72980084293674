<div class="row">
  <div class="col-md-12">
    <form class="form-horizontal form-bordered" [formGroup]="formGroup" (ngFormSubmit)="addInduction()">
      <section class="card">
        <div class="card-header">
          {{ inductionToEdit ? 'Edit Induction' : 'Add Induction'}}
          <div class="card-actions">
            <a (click)="hide.emit()" class="card-action card-action-dismiss" data-card-dismiss=""></a>
          </div>
        </div>
        <div class="card-body">
          <obc-loader [show]="inProgress"></obc-loader>
          <div class="row">
            <div class="col-md-12 col-xl-6">
              <section class="card">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-md-6 col-sm-12">
                      <label class="control-label">Induction Title:</label>
                      <input type="text" class="form-control" formControlName="title">
                    </div>
                    <div class="col-md-3 col-sm-12">
                      <label class="control-label">Status:</label>
                      <div class="d-flex flex-column">
                        <obc-switch formControlName="isActive" [trueLabel]="' Active '"
                          [falseLabel]="' Inactive '"></obc-switch>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-12" *ngIf="formGroup.controls.isActive.value">
                      <label class="control-label">Approval Mode:</label>
                      <select
                        [class.ng-invalid]="!(formGroup.controls.isActive.value == true && formGroup.controls.mode.value>0)"
                        required class="form-control" formControlName="mode">
                        <option [ngValue]="InductionMode.Auto">
                          Automatic
                        </option>
                        <option [ngValue]="InductionMode.HostApprove">
                          Host Approval
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-3 col-sm-12">
                      <label class="control-label">Expiry:</label>
                      <select required class="form-control" formControlName="expiryMode" [formControl]="formGroup.get('expiryMode')">
                        <option *ngFor="let item of expiryModeEnum | enumToArray " [ngValue]="expiryModeEnum[item]">
                          {{expiryModeEnum[item] | expiryMode}}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-9 col-sm-12" *ngIf="formGroup.get('expiryMode').value === expiryModeEnum.Custom">
                      <label class="control-label">Enter custom expiry:</label>
                      <div class="form-group row">
                        <div class="col-md-4 col-sm-12">
                          <input type="number" class="form-control" formControlName="expiryCount" required="required">
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <select required class="form-control" formControlName="expiryUnit">
                            <option *ngFor="let item of expiryUnitEnum | enumToArray " [ngValue]="expiryUnitEnum[item]">
                              {{expiryUnitEnum[item] | expiryUnit}}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">Perform Induction:</label>
                      <select required class="form-control" formControlName="askMode">
                        <option *ngFor="let item of AskModeEnum | enumToArray " [ngValue]="AskModeEnum[item]">
                          {{AskModeEnum[item] | inductionAskMode}}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">Type:</label>
                      <div class="d-flex flex-column">
                        <label class="mb-0">
                          <input class="radio" [value]='InductionScopeEnum.Company' formControlName='scope'
                            type="radio">
                          Company wide
                        </label>
                        <label class="mb-0">
                          <input class="radio" [value]='InductionScopeEnum.Site' formControlName='scope' type="radio">
                          Site specific
                        </label>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">Is Mandatory?</label>
                      <div class="d-flex flex-column">
                        <label class="mb-0">
                          <input class="radio" [value]='true' formControlName='isMandatory' type="radio">
                          Mandatory
                        </label>
                        <label class="mb-0">
                          <input class="radio" [value]='false' formControlName='isMandatory' type="radio">
                          Optional
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-12">
                      <div class="d-flex align-items-center">
                        <label class="mt-1 mr-2 mb-0">Allow users to attach additional documents</label>
                        <obc-switch [trueLabel]="' Yes '" [falseLabel]="' No '"
                          [value]="formGroup.controls.allowUsersToAttachAdditionalDocuments.value"
                          (switchChanged)="toggleAttachAdditionalDocuments()">
                        </obc-switch>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <app-visitor-type-selector [selectedVisitorIds]="formGroup.controls.visitorTypeIds.value"
                        (selectedVisitorIdsChange)="onSelectedVisitorTypeChange($event)"></app-visitor-type-selector>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="col-md-12 col-xl-6">
              <section class="card">
                <div class="card-body">
                  <obc-items-mixture [orderableItems]="formGroup.controls['items'].value"
                    (items)="orderedItems($event)">
                  </obc-items-mixture>
                </div>
                <div class="card-footer">
                  <button class="btn btn-warning mr-1 pull-right" [disabled]="!isFormGroupValid"
                    (click)="onModalPreview(PreviewInduction)">
                    Show Preview
                  </button>
                </div>
              </section>
            </div>
          </div>
        </div>
        <footer class="card-footer text-right">

          <button class="btn btn-primary mr-1" [disabled]="!isFormGroupValid" (click)="addInduction()">
            {{ inductionToEdit ? 'Update' : 'Add'}}
          </button>
          <button type="reset" (click)="hide.emit()" class="btn btn-default">Cancel</button>
        </footer>
      </section>
    </form>
  </div>
</div>
<ng-template #PreviewInduction>
  <obc-preview-upsert [previewType]="'induction'" [inductionModel]="inductionModel" [modalHeader]="'Induction Preview'"
    (hide)="bsModalRef.hide()">

  </obc-preview-upsert>
</ng-template>