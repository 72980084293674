import { Component, Input, OnInit } from '@angular/core';
import {
  VariableDefinitionType,
  VariableValueDefinition
} from "../../../models/workflow/variable-difinition";
import { DatetimePickerMode } from "../../../enums/datetime-picker-mode.enum";
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'obc-workflow-variables-renderer',
  templateUrl: './workflow-variables-renderer.component.html',
  styleUrls: ['./workflow-variables-renderer.component.scss']
})
export class WorkflowVariablesRendererComponent implements OnInit {
  VariableDefinitionType = VariableDefinitionType;
  DateTimePickerMode = DatetimePickerMode;
  @Input() formGroup: FormGroup;
  @Input() variablesList: VariableValueDefinition[] = [];
  constructor() { }
  ngOnInit(): void { }
}
