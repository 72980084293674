import { Pipe, PipeTransform } from '@angular/core';
import { SecurityLogType } from '../enums/security-log-type.enum';

@Pipe({
  name: 'securityLogType'
})
export class SecurityLogTypePipe implements PipeTransform {

  transform(value: any): string {
    if (value == SecurityLogType.ResetSiteInductionStatus)
      return 'Reset Induction Status';
    if (value == SecurityLogType.CancelSupplierLinking)
      return 'Cancel Supplier Linking';
    if (value == SecurityLogType.ResetAttendanceStatus)
      return 'Reset Attendance Status';
    if (value == SecurityLogType.ResetSupplierFormStatus)
      return 'Reset Supplier Form Status';
    if (value == SecurityLogType.ResetPermitStatus)
      return 'Reset Permit Status';
    if (value == SecurityLogType.ResetSiteAssetStatus)
      return 'Reset Site Asset Status';
    if (value == SecurityLogType.ResetSiteAssetOperatorStatus)
      return 'Reset Site Asset Operator Status';
    if (value == SecurityLogType.DeleteSiteSupplierDocumentVersion)
      return 'Delete Supplier Document Version';
    if (value == SecurityLogType.SiteAlert)
      return 'Site Alert';
    if (value == SecurityLogType.ChangeMobileNumber)
      return 'Change Mobile Number';
    if (value == SecurityLogType.DeleteSiteSupplierDocumentType)
      return 'Delete Site Supplier Document';
    if (value == SecurityLogType.UpdateProcoreIntegartionsConfig)
      return 'Update Procore Integration Configs';
    if (value == SecurityLogType.SiteUpdates)
      return 'Update Site Settings';

    return value?.toString() ?? 'Unknown';
  }

}
