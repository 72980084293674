<div *ngIf="formGroup">
  <form [formGroup]="formGroup">
    <div class="d-flex flex-column" *ngIf="model">
      <div>
        <i *ngIf="valid" style="color: green;" class="fas fa-check-circle  mr-2"></i>
        <i *ngIf="!valid" style="color: red;" class="fas fa-times-circle  mr-2"></i>
        <obc-question-title-renderer [question]="model.question"></obc-question-title-renderer>
      </div>
      <div class="form-group d-flex justify-content-end">
        <div class="btn-group" btnRadioGroup formControlName="value">
          <label [btnRadio]="true" uncheckable class="btn btn-primary" tabindex="0" role="button">Yes</label>
          <label [btnRadio]="false" uncheckable class="btn btn-primary" tabindex="0" role="button">No</label>
        </div>
      </div>
    </div>
  </form>
</div>