import { Pipe, PipeTransform } from '@angular/core';
import { DocumentMultiImageMode } from '../enums/document-multi-image-mode-enum';
import { isNumber } from '../helpers/general-functions';

@Pipe({
  name: 'multiImageModePipe',
})
export class MultiImageModePipe implements PipeTransform {
  transform(value: unknown): string {
    if (isNumber(value)) {
      let valueAsNumber = parseInt(value.toString());
      switch (valueAsNumber) {
        case DocumentMultiImageMode.FrontBack:
          return 'Front / Back';
        case DocumentMultiImageMode.SingleImage:
          return 'Single Image';
        case DocumentMultiImageMode.MultiImage:
          return 'Multi Image';
      }
    }
    return value?.toString() ?? '?';
  }
}
