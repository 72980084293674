<div>
  <ng-select style="padding-bottom: 0 !important;" class="region-selector"
             bindValue="companyRoleId"
             bindLabel="name"
             [typeaheadOptionsLimit]="7"
             [typeaheadMinLength]="0" [items]="roles"
             [placeholder]="'Select Roles'"
             [multiple]="true"
             [typeahead]="term$ | async"
             [loading]="loading"
             [clearable]="true"
             [formControl]="formControl">
    <ng-template ng-option-tmp let-item="item">
      {{item.name}}
    </ng-template>
  </ng-select>
</div>
