import { Pipe, PipeTransform } from '@angular/core';
import { SiteAnnouncementDisplayTime } from '../enums/site-announcement-display-time.enum';

@Pipe({
  name: 'siteAnnouncementDisplayTime'
})
export class SiteAnnouncementDisplayTimePipe implements PipeTransform {

  transform(value: any): unknown {
    if (value == SiteAnnouncementDisplayTime.AfterCheckin)
      return "While Checked In";
  if (value == SiteAnnouncementDisplayTime.WhileCheckin)
    return "Check In Page";
  if (value == SiteAnnouncementDisplayTime.AnyTime)
    return "Both";
  }

}
