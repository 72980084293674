import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserBusinessViewModel } from '../models/user-business-viewmodel';

@Injectable({
  providedIn: 'root'
})
export class UserBusinessService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getAddressSuggestionList = (nameOrAbnOrAcn: string): Observable<UserBusinessViewModel[]> =>
    this._http.get<UserBusinessViewModel[]>(`api/UserBusiness/lookup/${nameOrAbnOrAcn}`)
      .pipe(catchError(this._errorService.handleError));

  getAddressSuggestionListBySessionKey = (sessionId: string, nameOrAbnOrAcn: string): Observable<UserBusinessViewModel[]> =>
    this._http.get<UserBusinessViewModel[]>(`api/UserBusiness/lookup-by-session-key/${sessionId}/${nameOrAbnOrAcn}`)
      .pipe(catchError(this._errorService.handleError));
}
