import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CaptchaMode } from '../enums/captcha-mode';

@Injectable({
  providedIn: 'root'
})
export class CaptchaService {
  private _captchaMode: CaptchaMode = CaptchaMode.Disabled;
  private urlsNeedToken: string[] = [
    "obcauthentication/web-sign-in",
    "obcauthentication/send-reset-pass-otp",
    "obcauthentication/send-2fa-otp",
  ];

  constructor(
    private _http: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service) { }

  isCaptchaActive = () => this._captchaMode != CaptchaMode.Disabled;

  set captchaMode(value: CaptchaMode) {
    this._captchaMode = value ?? CaptchaMode.Disabled;
  }

  UrlNeedToken = (url): boolean => {
    let result: boolean = false;

    this.urlsNeedToken
      .forEach((urlNeedToken) => {
        if (url.includes(urlNeedToken)) {
          result = true;
        }
      })

    return result;
  }

  getCurrentServerCaptchaConfig = () => this._http
    .get(`/api/captcha/get-current-server-captcha-config`)
    .pipe(
      catchError((error) => observableThrowError(error))
    );

  getToken = (): Observable<string> => {
    return new Observable<string>(observer =>
      this.recaptchaV3Service
        .execute('getToken')
        .subscribe((token: string) => {
          observer.next(token);
          observer.complete();
        }));
  }
}
