import { Pipe, PipeTransform } from '@angular/core';
import { CompanySiteScope } from '../enums/company-site-scope';

@Pipe({
  name: 'companySiteScope'
})
export class CompanySiteScopePipe implements PipeTransform {

  transform(value: unknown): string {
    if (value === CompanySiteScope.Company) return "Company";
    if (value === CompanySiteScope.Site) return "Site";
    return value?.toString();
  }

}
