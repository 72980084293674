import { FormGroup, FormControl, Validators } from '@angular/forms';

export function constructFormGroup<T>(model: T): FormGroup {
  const group: { [key: string]: FormControl } = {};

  Object.keys(model).forEach((key) => {
    const value = model[key as keyof T];

    // Example logic: Add custom validators or initial values based on key
    switch (key) {
      case 'id':
        group[key] = new FormControl(value ?? null, []);
        break;
      case 'name':
        group[key] = new FormControl(value ?? null, [Validators.required]);
        break;
      case 'email':
        group[key] = new FormControl(value ?? null, [Validators.email]);
        break;
      default:
        group[key] = new FormControl(value ?? null, []); // Default case for unspecified fields
        break;
    }
  });

  return new FormGroup(group);
}