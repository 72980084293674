import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DisplayStyle} from 'src/app/enums/display-style';
import {QuestionListItem, QuestionListItemOutputModel} from 'src/app/models/questionParameters/question-list-tem';
import {ModalService} from 'src/app/services/modal.service';
import {uuid4} from "../../../helpers/uuid";
import Sortable from 'sortablejs';
import {MultiSelectRenderMode} from "../../../models/questionParameters/multi-select-render-type";
import {SingleSelectRenderMode} from "../../../models/questionParameters/single-select-render-type";
import {LayoutService} from "../../../services/layout.service";

enum ItemRendererDisplayMode {
  Dropdown = 0,
  Button = 1,
  CheckBox = 2,
  RadioButton = 3
}

enum TooltipTypeEnum {
  Empty = 1,
  Duplicate = 2
}

@Component({
  selector: 'obc-question-items-renderer',
  templateUrl: './question-items-renderer.component.html',
  styleUrls: ['./question-items-renderer.component.scss']
})
export class QuestionItemsRendererComponent implements OnInit {
  showAdvancedMode: boolean = false;
  DisplayStyle = DisplayStyle;
  sortableUniqueId: string = uuid4();

  _items: QuestionListItem[] = [];
  @Input() set items(items: QuestionListItem[]) {
    items.forEach(item => {
      if (!item._clientRandomId)
        item._clientRandomId = uuid4();
    })
    this._items = items;
  };

  get items() {
    return this._items;
  }

  itemRenderMode: ItemRendererDisplayMode;
  ItemRendererDisplayMode = ItemRendererDisplayMode;

  @Input() set multipleItemRenderMode(mode: MultiSelectRenderMode) {
    switch (mode) {
      case MultiSelectRenderMode.Button:
        this.itemRenderMode = ItemRendererDisplayMode.Button;
        break;
      case MultiSelectRenderMode.CheckBoxList:
        this.itemRenderMode = ItemRendererDisplayMode.CheckBox;
        break;
    }
  }

  @Input() set singleItemRenderMode(mode: SingleSelectRenderMode) {
    switch (mode) {
      case SingleSelectRenderMode.Button:
        this.itemRenderMode = ItemRendererDisplayMode.Button;
        break;
      case SingleSelectRenderMode.DropDown:
        this.itemRenderMode = ItemRendererDisplayMode.Dropdown;
        break;
      case SingleSelectRenderMode.RadioButton:
        this.itemRenderMode = ItemRendererDisplayMode.RadioButton;
        break;
    }
  }

  @Output() onItemsUpdated = new EventEmitter<QuestionListItemOutputModel>();
  newItem: QuestionListItem;
  @ViewChild('input') input: ElementRef;

  constructor(private modalService: ModalService, private layoutService: LayoutService) {
  }

  ngOnInit(): void {
    this.resetNewItem();
    if (this._items?.length === 0) {
      this.addNewItemRow();
    }
  }

  ngAfterViewInit(): void {
    let el = document.getElementById(`sortable-list-${this.sortableUniqueId}`);
    Sortable.create(el, {
      ghostClass: 'ghost-class',
      fallbackClass: "ghost-class",
      handle: ".move-handler",
      dragoverBubble: true,
      forceFallback: true,
      onEnd: (event: any) => {
        let orderedItems = [];

        for (let child of event.target.children) {
          let matchedItem = this.items.find((item) => item._clientRandomId == child?.dataset.id);
          if (matchedItem) {
            orderedItems.push(matchedItem);
          }
        }

        this.items = [
          ...orderedItems
        ];
        this._updateOutputItems();
      }
    });
  }

  detectPressedKey(keyEvent) {
    if (keyEvent.which === 13 || keyEvent.which === 188) {
      return false;
    }
  }
  _updateOutputItems() {
    this.onItemsUpdated?.emit({
      items: this.items,
      isValidList: !this.disabledAddNewRow()
    } as QuestionListItemOutputModel);
  }

  buttonStyles(style: DisplayStyle): { background: string, border: string } {
    switch (style) {
      case DisplayStyle.Error:
        return {background: '#FF644E', border: '#e55844'};
      case DisplayStyle.Warning:
        return {background: '#FFA319', border: '#db8b14'};
      case DisplayStyle.Success:
        return {background: '#91D19F', border: '#66AE87'};
    }
  }

  chooseQuestionStyle(event, style: DisplayStyle, item: QuestionListItem) {
    event.stopPropagation();
    if (item.displayStyle === style) {
      item.displayStyle = null;
    } else {
      item.displayStyle = style;
    }
    this._updateOutputItems();
  }

  resetNewItem() {
    this.newItem = {isInvalid: false, displayStyle: DisplayStyle.Default, _clientRandomId: uuid4()};
  }

  valueChanged() {
    if (this.newItem.value.search(/,/g) != -1) {
      this.newItem.value = this.newItem.value.replace(/,/g, ' ').replace(/  +/g, ' ');
      this.modalService.warn("You can't use comma character in value!");
    }
  }

  addNewItemRow() {
    this.items.push({
      _clientRandomId: uuid4(),
      displayStyle: DisplayStyle.Default,
      isInvalid: false,
      title: "",
      value: "",
      hint: null,
      selectedDisplayStyle: DisplayStyle.Default,
    } as QuestionListItem)
    this._updateOutputItems();
  }

  removeItemRow(itemToRemove: QuestionListItem) {
    this.modalService.confirm(`Are you sure?`)
      .subscribe(confirmed => {
        if (confirmed) {
          this.items = [
            ...this.items.filter((listItem) => listItem._clientRandomId != itemToRemove._clientRandomId)
          ];
          this._updateOutputItems();
        }
      });
  }

  invalidRows: Record<number, any> = {}

  get duplicateOrEmptyTitle() {
    this.invalidRows = {};
    let isInvalidResult = false;
    if (this.items.find((item: QuestionListItem) => {
      let isInvalid = item.title.trim() === "";
      if (isInvalid) this.invalidRows[item._clientRandomId] = TooltipTypeEnum.Empty;
      return isInvalid
    })) {
      if(isInvalidResult === false)
        isInvalidResult = true;
    }

    let reservedItemList = [...this.items].reverse();
    for (const item of reservedItemList) {
      if (reservedItemList.find(innerItem => {
        let isInvalid = innerItem.title === item.title && item._clientRandomId !== innerItem._clientRandomId;
        if (isInvalid) this.invalidRows[item._clientRandomId] = TooltipTypeEnum.Duplicate;
        return isInvalid;
      })) {
        if(isInvalidResult === false)
          isInvalidResult = true;
      }
    }
    return isInvalidResult;
  }

  updateTitle(item: QuestionListItem, input) {
    item.title = input.value;
    item.value = this.purifyText(input.value)
    this.updateTextareaSize(input);
    this._updateOutputItems();
  }

  purifyText(text: string) {
    return text.replace(/,/g, ' ').replace(/\s+/g, ' ');
  }

  updateHint(item: QuestionListItem, input) {
    item.hint = input.value;
    this.updateTextareaSize(input);
    this._updateOutputItems();
  }

  updatePreventSubmission() {
    this._updateOutputItems();
  }

  disabledAddNewRow() {
    return this.duplicateOrEmptyTitle || this.items[this.items.length - 1]?.title?.length === 0;
  }

  getDefaultStyleControlColor() {
    if (this.layoutService.isInDarkMode)
      return '#777777';
    else
      return '#c5c5c5';
  }

  isInvalidRow(_clientRandomId: string): string | boolean {
    let invalidRow = this.invalidRows[_clientRandomId];
    if (invalidRow)
      return invalidRow;
    return false;
  }

  getTooltipText(_clientRandomId: string) {
    switch (this.invalidRows[_clientRandomId]) {
      case TooltipTypeEnum.Empty:
        return "The title can not be empty";
      case TooltipTypeEnum.Duplicate:
        return "Duplicated titles are not permitted";
    }
  }

  updateTextareaSize(input: any) {
    input.style.height = 0;
    input.style.height = ((input.scrollHeight) + 3) + "px"; // 3px offset
  }
}
