import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CacheCsvModel, ColumnSetting, TableSetting } from 'src/app/models/table-setting';
import { FormBuilder, FormControl } from '@angular/forms';
import { GridColumn } from "../../../models/grid/grid.config";
import { TableSettingService } from "../../../services/table-setting.service";

@Component({
  selector: 'obc-table-setting',
  templateUrl: './table-setting.component.html',
  styleUrls: ['./table-setting.component.scss']
})
export class TableSettingComponent<T> implements OnInit {

  @Input() modalRef;
  @Input() cacheKey: string;
  @Input() allColumns: GridColumn<T>[];
  @Input() visibleColumns: GridColumn<T>[];
  @Output() saveChanges = new EventEmitter();
  @Output() isReorderingMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() tableSetting: TableSetting;
  formControl = new FormControl()
  hideEmptyColumns: boolean = false;
  localScopeTableSettings: TableSetting;

  form = this.formBuilder.array([])

  constructor(private formBuilder: FormBuilder,
              private tableSettingService: TableSettingService,
  ) {
  }

  data: ColumnSetting[] = [];

  ngOnInit() {
    this.localScopeTableSettings = Object.assign({}, this.tableSetting);
    this.hideEmptyColumns = this.localScopeTableSettings?.hideEmptyColumns ?? false;

    this.allColumns?.filter((res: GridColumn<T>) => res.displayInCheckBoxSettings != false).map((col: GridColumn<T>) => {
      let matchedVisibleColumn = this.visibleColumns?.find((item: GridColumn<T>) => item.key == col.key);

      let csvFields = [];
      if (col.gridTemplate?.csvFields) {
        let matchedColumnFromCache = this.localScopeTableSettings.columnSettings?.find((item) => item.key == col.key);
        col.gridTemplate?.csvFields?.map((csvFieldString) => {
          let matchedCsvFieldFromCache = matchedColumnFromCache?.csvFields?.find((csvCacheField) => csvCacheField.key == csvFieldString)
          csvFields.push({
              value: matchedCsvFieldFromCache?.value ?? true,
              key: csvFieldString,
              title: this.toTitleCase(csvFieldString),
            } as CacheCsvModel
          )
        })
      }

      this.data.push({
          visible: matchedVisibleColumn?.visible ?? false,
          key: col.key,
          title: col.name,
          order: col.order,
          csvFields,
        } as ColumnSetting,
      )
    });
  }

  onSaveChanges() {
    this.localScopeTableSettings.columnSettings = this.data;
    this.localScopeTableSettings.hideEmptyColumns = this.hideEmptyColumns
    this.tableSettingService.setToLocalStorage(this.cacheKey, this.localScopeTableSettings);
    this.saveChanges.emit(this.allColumns);
  }
  onReorderClicked() {
    this.isReorderingMode.emit(true);
  }

  toTitleCase(str) {
    const result = str.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1)
  }
}
