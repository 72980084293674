import { ItemTitleValue } from '../models/helper-models';

export const regexLists : ItemTitleValue[] = [
    { id: 1, itemTitle: 'General', itemValue: '' },
    { id: 0, itemTitle: 'Custom(Regex)', itemValue: '(.*?)' },
    { id: 2, itemTitle: 'Number', itemValue: '^\\d+$' },
    { id: 3, itemTitle: 'Email', itemValue: '^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})*$' },
    { id: 4, itemTitle: 'Url', itemValue: '^https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,}\\b([-a-zA-Z0-9@:%_\\+.~#()?&//=]*)$' },
    { id: 5, itemTitle: 'Phone Number', itemValue: '^(?:(?:\\(?(?:00|\\+)([1-4]\\d\\d|[1-9]\\d?)\\)?)?[\\-\\.\\ \\\\\\/]?)?((?:\\(?\\d{1,}\\)?[\\-\\.\\ \\\\\\/]?){0,})(?:[\\-\\.\\ \\\\\\/]?(?:#|ext\\.?|extension|x)[\\-\\.\\ \\\\\\/]?(\\d+))?$' }
  ]
