<div class="w-100 d-flex flex-xl-row justify-content-start flex-column align-items-xl-center align-items-start">
  <div class="header-icon-title d-flex justify-content-start align-items-center">
    <i class="{{ icon }}" style="color: {{ iconColor }}"></i>
    <span class="mr-2">{{ mainTitle }}</span>
  </div>
  <div [hidden]="!isAccordionDirty" class="text-info position-relative ml-auto ml-xl-2 mt-2 mt-xl-0"
    style="top:2px;font-size: 12px">(Unsaved Changes)
  </div>
  <div class="invalid-sign ml-auto ml-xl-2 mt-2 mt-xl-0 {{ invalidControlsCount > 0 ? 'visible' : '' }}">
    <div class="d-flex justify-content-between align-items-center">
      <div class="text-danger" style="font-size: 9px;">
        {{ invalidControlsCount }} {{ invalidControlsCount > 1 ? 'Errors' : 'Error' }}
      </div>
      <i class="ml-1 error fa fa-exclamation-triangle"></i>
    </div>
  </div>

  <ng-container *ngIf="info && info.length > 0">
    <div class="ml-auto">
      <div class="d-flex flex-column flex-md-row align-items-end ">
      <span *ngFor="let data of info" class="d-flex align-items-center text-quaternary mt-2 mt-xl-0 -accordion-details"
            style="font-size: 13px;">
        <span *ngIf="data.title" class="ml-2 text-secondary">{{ data.title }}</span>
        <span class="ml-2 text-info font-weight-bold">{{ data.value }}</span>
        <span *ngIf="data.title || data.value" class="ml-2 d-none d-md-inline-block pipe"><i class="fas fa-pipe"></i></span>
      </span>
      </div>
    </div>
  </ng-container>

</div>
