import { Pipe, PipeTransform } from '@angular/core';
import { DatetimePipe } from './datetime.pipe';
import { LocaleService } from '../services/locale.service';
import { dateFormat } from '../helpers/date-time-format';

@Pipe({
  name: 'date',
})
export class DatePipe extends DatetimePipe {
  constructor(localeService: LocaleService) {
    super(localeService);
  }

  transform(value: any, format?: string): string {
    let finalFormat = format ?? dateFormat;
    try { return super.transform(value, finalFormat); }
    catch{ return value; }
  }
}
