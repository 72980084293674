<div class="row">
  <div class="col-md-12">
    <div class="card row">
      <div class="card-body">
        <obc-loader [show]="inProgress"></obc-loader>
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-bordered table-striped mt-3">
              <thead>
                <tr>
                  <td>Title</td>
                  <td>Delete After</td>
                  <td>Delete All Data</td>
                  <td>Delete User Data</td>
                  <td>Delete Documents</td>
                  <td>Delete Answers</td>
                  <td>Status</td>
                  <td><button class="btn btn-info" (click)="onAddNewPolicy(upsertPolicy)">New Policy</button>
                  </td>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let policy of (deletionPolicies | async)">
                  <tr>
                    <td colspan="8">
                      <div class="d-flex flex-column">
                        <div *ngIf="!policy.sites?.length"> All Sites</div>
                        <div *ngIf="policy.sites?.length"> Selected Site(s) </div>
                        <div class="d-flex flex-row">
                          <button class="btn btn-dark mr-1" disabled *ngFor="let site of policy.sites">
                            {{site.name}} [{{site.siteReference}}]
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>{{policy.title}}</td>
                    <td>{{policy.daysBefore}} Days</td>
                    <td>
                      <span *ngIf="policy.deleteAttendance == true" class="text-danger">Yes</span>
                      <span *ngIf="policy.deleteAttendance == false" class="text-success">No</span>
                    </td>
                    <td [ngClass]="DataDeletionPolicyToCssClass(policy.dataDeletionPolicy)">{{policy.deleteAttendance ? "---" :DataDeletionPolicyToLabel(policy.dataDeletionPolicy)}}</td>
                    <td [ngClass]="DocumentDeletionPolicyToCssClass(policy.dataDeletionPolicy)">{{policy.deleteAttendance ? "---"
                      :DocumentDeletionPolicyToLabel(policy.documentDeletionPolicy)}}</td>
                    <td [ngClass]="AnswerDeletionPolicyToCssClass(policy.answerDeletionPolicy)">{{policy.deleteAttendance ? "---" :AnswerDeletionPolicyToLabel(policy.answerDeletionPolicy)}}
                    </td>
                    <td>
                      <obc-switch [formControl]="getFakeSwitchFormControl(policy)"
                        (switchChanged)="onPolicyStatusChange($event, policy)" [trueLabel]="'Active'"
                        [falseLabel]="'Disabled'"></obc-switch>
                    </td>
                    <td>
                      <div class="d-flex flex-row flex-row-reverse">
                        <button class="btn-danger btn" (click)="onRemovePolicy(policy)">Remove</button>
                        <button class="btn-info btn mr-1" (click)="onUpdatePolicy(policy, upsertPolicy)">Edit</button>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #upsertPolicy>
  <div class="card">
    <div class="card-header">
      <span>
        Add / Update Retention Policy
      </span>
      <button type="button" class="close" (click)="modalHandle.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="row" [formGroup]="formGroup">
        <div class="col-md-6 col-sm-6">
          <div class="mb-3">
            <label class="form-label">Title </label>
            <input class="form-control" placeholder="Title" formControlName="title">
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
          <div class="mb-3">
            <label class="form-label">Delete data after </label>
            <div class="input-group">
              <input class="form-control" placeholder="Days" formControlName="daysBefore">
              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2">Days</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-sm-12">
          <div class="mb-3">
            <span class="d-inline-block mt-2 mr-1">Does this policy apply to all sites?</span>
            <obc-switch formControlName="isCompanyPolicy" [trueLabel]="'Yes'" [falseLabel]="'No'">
            </obc-switch>
          </div>
          <div class="row" style="overflow: auto; max-height: 350px;">
            <div class="col-md-12" *ngIf="formGroup.controls.isCompanyPolicy.value == false">
              <div class="mb-3">
                <label class="form-label">Select Sites</label>
                <app-site-selector [activeStatus]="SiteActiveStatus.All" (siteSelect)="onSiteSelect($event)">
                </app-site-selector>
              </div>
              <div class="table-responsive">
                <table class="table table-bordered table-striped mt-3">
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Count: {{sites.length}}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let site of sites">
                      <td>{{site.name}} [{{site.siteReference}}]</td>
                      <td>
                        <button class="btn btn-danger" (click)="onRemoveSite(site)">Remove</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-sm-12">
          <div class="mb-3">
            <span class="d-inline-block mt-2 mr-1">Do you want to delete all data?</span>
            <!-- <label class="form-label">At Level Company?</label> -->
            <obc-switch formControlName="deleteAttendance" [trueLabel]="'Yes'" [falseLabel]="'No'">
            </obc-switch>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 alert alert-danger" *ngIf="formGroup.controls.deleteAttendance.value == true">
          Warning: This policy will delete all attendance data.
        </div>
        <ng-container *ngIf="formGroup.controls.deleteAttendance.value == false">
          <div class="col-md-12 col-sm-12 alert alert-success"
            [class.alert-danger]="formGroup.controls.dataDeletionPolicy.value==DataDeletionPolicy.All">
            <div class="form-group">
              <label class="bold">De-identify data</label>
              <div class="form-check">
                <label class="form-check-label text-success">
                  <input type="radio" class="form-check-input" formControlName="dataDeletionPolicy"
                    [value]="DataDeletionPolicy.None"> Keep User Data
                </label>
              </div>

              <div class="form-check">
                <label class="form-check-label text-danger">
                  <input type="radio" class="form-check-input" formControlName="dataDeletionPolicy"
                    [value]="DataDeletionPolicy.All"> Delete User data (including name, mobile, email, ...)
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 alert alert-success"
            [class.alert-danger]="formGroup.controls.documentDeletionPolicy.value==DataDeletionPolicy.All">
            <div class="form-group">
              <label for="firstname" class="bold">Delete Documents</label>
              <div class="form-check">
                <label class="form-check-label text-success">
                  <input type="radio" class="form-check-input" formControlName="documentDeletionPolicy"
                    [value]="DocumentDeletionPolicy.None"> Keep Documents
                </label>
              </div>

              <div class="form-check">
                <label class="form-check-label text-danger">
                  <input type="radio" class="form-check-input" formControlName="documentDeletionPolicy"
                    [value]="DocumentDeletionPolicy.All"> Delete Collected Documents
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 alert alert-success"
          [class.alert-danger]="formGroup.controls.answerDeletionPolicy.value==AnswerDeletionPolicy.All"
          [class.alert-warning]="formGroup.controls.answerDeletionPolicy.value==AnswerDeletionPolicy.Sensitive">
            <div class="form-group">
              <label class="bold">Delete Question Answers</label>
              <div class="form-check">
                <label class="form-check-label text-success">
                  <input type="radio" class="form-check-input" formControlName="answerDeletionPolicy"
                    [value]="AnswerDeletionPolicy.None"> Keep Answers
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label text-danger">
                  <input type="radio" class="form-check-input" formControlName="answerDeletionPolicy"
                    [value]="AnswerDeletionPolicy.All"> Delete All Answers to All Questions
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label text-warning">
                  <input type="radio" class="form-check-input" formControlName="answerDeletionPolicy"
                    [value]="AnswerDeletionPolicy.Sensitive"> Delete Answers to Sensitive Questions Only
                </label>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="col-md-12 text-right mt-2">
          <button *ngIf="formGroup.controls.id.value != null" [disabled]="!formGroupIsValid()" (click)="updatePolicy()"
            class="btn btn-primary">Update Policy</button>
          <button *ngIf="formGroup.controls.id.value  == null" (click)="addPolicy()" [disabled]="!formGroupIsValid()"
            class="btn btn-primary">Add Policy</button>
        </div>
      </div>
    </div>
  </div>

</ng-template>