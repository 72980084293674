export enum SiteRequiredRenderType {
  Input = 0,
  DropDown = 1,
  Date = 2,
  Business = 3,
  Supplier = 4,
  EmergencyContact = 5,
}

export enum SiteRequiredFieldType {
  Email = 0,
  Mobile = 1,
  Text = 2,
  SingleSelect = 3,
  Date = 4,
  Business = 5,
  Supplier = 6,
  EmergencyContact = 7
}

export enum SiteRequiredFieldState {
  ignore = 0,
  optional = 1,
  mandatory = 2,
  validatedMandatory = 3,
}


export interface BaseSiteRequiredFieldViewModel {
  title: string;
  fieldKey: string;
  fieldType: SiteRequiredFieldType;
  items: any[];
  state: SiteRequiredFieldState;
}


export interface SiteRequiredFieldViewModel extends BaseSiteRequiredFieldViewModel {
  id: number;
}

export interface SiteRequiredFieldWithVisitorTypeViewModel extends SiteRequiredFieldViewModel {
  visitorTypeIds: number[];
}

export interface SiteRequiredFieldValue {
  fieldKey: string;
  value: any;
}

export interface SiteRequiredFieldStateViewModel {
  // fieldKey: string;
  state: number;
  title: string;
}

export interface ResponseSiteRequiredFieldViewModel {
  fields: SiteRequiredFieldWithVisitorTypeViewModel[];
  states: SiteRequiredFieldStateViewModel[];
}
