import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '../services/locale.service';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'weekday'
})
export class WeekdayPipe implements PipeTransform {

  private datePipe: DatePipe;
  constructor(private localeService : LocaleService) {
    this.datePipe = new DatePipe(this.localeService.defaultLocale);
  }

  transform(value: any): string{
    return this.datePipe.transform(value,'EEEE');
  }
}
