import { CompanyType } from '../enums/company-type.enum';
import { SiteViewModel } from './site-viewmodel';
import { Timezone } from './time-zone';

export class BaseCompanyViewModel {
  public id?: number;
  public title: string;
  public description: string;
  public logoUrl?: string;
  public isActive?: boolean;
  public maxSites?: number;
  public companySupervisorEmailAddress?: string;
}
export class CompanyViewModel extends BaseCompanyViewModel {
  public currentActiveSites: number;
  public sites: SiteViewModel[];
  public isTemplate: boolean;
  public activePlan: string;
  public activePlanId?: number;
  public timezoneId: number;
  public timezone: Timezone;
  public canAddSite: boolean;
  public tempdata: any[];
  public companyTypeId?: number;
  public companyType: CompanyType;
  public originalMaxSites?: number;
  public averageDailyCheckIns?: number;
  public maxAverageDailyCheckIns?: number;
  public endUserCanAddNewLocation : boolean;
  public smsSoftLimit?: number;
  public smsHardLimit?: number;
}
