<accordion class="site-management">
  <accordion-group *ngIf="userService.hasCompanyFeature(ProductPlanFeature.AccessControl)">
    <div accordion-heading>
      <obc-accordion-header [mainTitle]="'Access Control'" [icon]="'fa fa-sliders-h'" [iconColor]="'#2196F3'">
      </obc-accordion-header>
    </div>
    <obc-access-control-white-list-users></obc-access-control-white-list-users>
  </accordion-group>
  <accordion-group *ngIf="userService.hasCompanyFeature(ProductPlanFeature.Form)">
    <div accordion-heading>
      <obc-accordion-header [mainTitle]="'Permit'" [icon]="'fa fa-sliders-h'" [iconColor]="'#2196F3'">
      </obc-accordion-header>
    </div>
    <form [formGroup]="permitFormGroup" class="row">
      <ng-container>
        <div class="form-group col-md-6 mt-3 col-sm-12">
          <label>Permit Open Policy</label>
          <select formControlName="permitOpenPolicy" class="form-control">
            <option *ngFor="let permit of PermitOpenPolicy | enumToArray" [ngValue]="PermitOpenPolicy[permit]">
              {{PermitOpenPolicy[permit] | permitOpenPolicy}}</option>
          </select>
        </div>
        <div class="form-group col-md-6 mt-3 col-sm-12">
          <label>Permit Start Date Policy</label>
          <select formControlName="permitStartDateOffsetInDays" class="form-control">
            <option [ngValue]="null">
              {{null | permitStartDatePolicy}}</option>
            <option *ngFor="let permit of PermitStartDatePolicyEnum | enumToArray"
              [ngValue]="PermitStartDatePolicyEnum[permit]">
              {{PermitStartDatePolicyEnum[permit] | permitStartDatePolicy}}</option>
          </select>
        </div>
        <div class="col-md-12 mt-3">
          <p class="button-group">
            <button type="submit" class="btn btn-primary" (click)="onSaveCompanyPermitConfiguration()">
              Save Changes
            </button>
          </p>
        </div>
      </ng-container>
    </form>
  </accordion-group>
  <accordion-group>
    <div accordion-heading>
      <obc-accordion-header [mainTitle]="'Inductions and Check In'" [icon]="'fa fa-sliders-h'" [iconColor]="'#2196F3'">
      </obc-accordion-header>
    </div>
    <form [formGroup]="InductionsAndCheckInFormGroup" class="row">
      <div class="form-group col-md-6 mt-3 col-sm-12">
        <label>Min Required Expiry for Documents</label>
        <div class="d-flex align-items-center">
          <input type="number" style="width: 100px;" placeholder="E.g. 10" class="form-control" min="1"
            formControlName="minRequiredExpiryForDocuments" (focusout)="minRequiredExpiryForDocumentsFocusout($event)"
            value="1" />
          <span class="ml-2">day{{InductionsAndCheckInFormGroup.controls.minRequiredExpiryForDocuments.value ?
            InductionsAndCheckInFormGroup.controls.minRequiredExpiryForDocuments.value > 1 ? 's' : '' : ''
            }}</span>
        </div>
      </div>
      <div class="form-group col-md-6 mt-3 col-sm-12">
        <span class="form-check">
          <label class="form-check-label">
            <input class="form-check-input" formControlName="visitorCanDisputeCheckIns" type="checkbox">
            Allow visitors to dispute check ins?
          </label>
        </span>
      </div>
      <div class="col-12">
        <h4>Contact Information</h4>
        <div class="row">
          <div class="col-12 col-md-4">
            <label>Title</label>
            <input type="text" placeholder="CEO, CTO, Manager, ..." class="form-control"
              formControlName="contactTitle" />
          </div>
          <div class="col-12 col-md-4">
            <label>Full Name</label>
            <input type="text" placeholder="John Doe" class="form-control" formControlName="contactFullName" />
          </div>
          <div class="col-12 col-md-4">
            <label>Phone Number</label>
            <input type="text" placeholder="" class="form-control" formControlName="contactNumber" />
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-3">
        <p class="button-group">
          <button type="submit" class="btn btn-primary" (click)="onSaveInductionsAndCheckInConfiguration()">
            Save Changes
          </button>
        </p>
      </div>
    </form>
  </accordion-group>
  <accordion-group *ngIf="userService.hasCompanyFeature(ProductPlanFeature.ViewKioskMode)">
    <div accordion-heading>
      <obc-accordion-header [mainTitle]="'Kiosk Mode'" [icon]="'fa fa-sliders-h'" [iconColor]="'#2196F3'">
      </obc-accordion-header>
    </div>
    <form [formGroup]="KioskModeFormGroup" class="row">
      <div class="form-group col-md-6 mt-3 col-sm-12">
        <label class="d-inline-block mt-2 mr-1">Disable Location Requirement For Kiosk Mode</label>
        <obc-switch formControlName="disableLocationRequirementForKioskMode">
        </obc-switch>
      </div>
      <div class="col-md-12 mt-3">
        <p class="button-group">
          <button type="submit" class="btn btn-primary" (click)="onSaveKioskModeConfiguration()">
            Save Changes
          </button>
        </p>
      </div>
    </form>
  </accordion-group>
  <accordion-group>
    <div accordion-heading>
      <obc-accordion-header [mainTitle]="'Evacuation Settings'" [icon]="'fa fa-sliders-h'" [iconColor]="'#2196F3'">
      </obc-accordion-header>
    </div>
    <obc-evacuation-setting-editor></obc-evacuation-setting-editor>
  </accordion-group>
  <accordion-group>
    <div accordion-heading>
      <obc-accordion-header [mainTitle]="'Reports'" [icon]="'fa fa-sliders-h'" [iconColor]="'#2196F3'">
      </obc-accordion-header>
    </div>
    <form [formGroup]="ReportsFormGroup" class="row">
      <div class="form-group col-md-6 mt-3 col-sm-12">
        <span class="form-check">
          <label class="form-check-label">
            <input class="form-check-input" formControlName="enableReportsOnMobileApp" type="checkbox">
            Enable Reports on Mobile App (Experimental)
          </label>
        </span>
      </div>
      <div class="col-md-12 mt-3">
        <p class="button-group">
          <button type="submit" class="btn btn-primary" (click)="onSaveReportsConfiguration()">
            Save Changes
          </button>
        </p>
      </div>
    </form>
  </accordion-group>

</accordion>