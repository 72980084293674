<div class="card">
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div *ngIf="reseted" [formGroup]="_model.formGroup">
      <div class="form-group">
        <form>
          <fieldset>
            <label>Type</label>
            <select class="custom-select form-control" [formControl]="_model.formGroup.get('type')">
              <option *ngFor="let type of types" [ngValue]="type">{{typeLabels[type]}}</option>
            </select>
          </fieldset>
        </form>
      </div>
      <div class="form-group">
        <label>Title</label>
        <input class="form-control" [formControl]="_model.formGroup.get('title')">
      </div>
      <div class="form-group"
        *ngIf="isDataModel(_model.formGroup.get('type').value) || (isFileModel(_model.formGroup.get('type').value))">
        <label>Data / Link</label>
        <div *ngIf="isDataModel(_model.formGroup.get('type').value)">
          <input class="form-control" [formControl]="_model.formGroup.get('data')">
        </div>
        <div class="d-flex flex-column" *ngIf="isFileModel(_model.formGroup.get('type').value)">
          <div class="custom-file" style="text-align: start;">
            <input [accept]="getValidFileExtensions()" style="margin-top: 5px;" type="file"
              (change)="onFileChange($event, _model)">
          </div>
          <div *ngIf="_model.formGroup.get('file').value && !isSelectedFileCompatibleWithType(_model.formGroup)"
            class="alert alert-danger mb-0 mt-2" role="alert">
            <i class="fas fa-exclamation-triangle"></i>
            The selected file is not compatible with announcement type or is the unknown format, this format may do not
            work
            properly on the user device.
          </div>
        </div>
      </div>
      <div class="form-group ">
        <label>Display Mode</label>
        <select required class="form-control" [formControl]="_model.formGroup.get('askMode')">
          <option *ngFor="let item of askMode | enumToArray " [ngValue]="askMode[item]">
            {{askMode[item] | askMode}}
          </option>
        </select>
      </div>

      <ng-container *ngIf="_model.formGroup.get('type').value == 5">
        <div class="form-group ">
          <label>Display As</label>
          <div>
            <span class="radio-custom radio-primary">
              <input [value]="announcementDisplayMode.Button" [formControl]="_model.formGroup.get('displayMode')"
                name="displayMode" id="buttonDisplayMode" type="radio" />
              <label for="buttonDisplayMode">{{ announcementDisplayMode.Button | announcementDisplayMode }}</label>
            </span>
            <span class="radio-custom radio-primary ml-2">
              <input [value]="announcementDisplayMode.Preview" [formControl]="_model.formGroup.get('displayMode')"
                name="displayMode" id="previewDisplayMode" type="radio" />
              <label for="previewDisplayMode">{{ announcementDisplayMode.Preview | announcementDisplayMode }}</label>
            </span>
          </div>
        </div>
      </ng-container>

      <div class="form-group">
        <label>Announcement Group</label>
        <input name="groupName" placeholder="Search and add announcement group"
          [formControl]="_model.formGroup.get('groupName')" [typeahead]="announcementGroups$" [isAnimated]="true"
          [adaptivePosition]="true" [typeaheadAsync]="true" [typeaheadOptionsInScrollableView]="6"
          (typeaheadOnSelect)="onSelectAnnouncementGroup($event)"
          [typeaheadItemTemplate]="customAnnouncementGroupTemplate" [typeaheadMinLength]="2" class="form-control" />
        <div class="input-group-prepend" *ngIf="announcementGroupLoader">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      <ng-template #customAnnouncementGroupTemplate let-model="item">
        <div>
          <strong>{{ model }}</strong>
        </div>
      </ng-template>

      <div class="form-group" *ngIf="isActiveStatusChangable == true">
        <label>Is Active?</label>
        <div class="row">
          <div class="col-md-12" style="text-align: start;">
            <obc-switch [value]="_model.formGroup.get('isActive').value"
              (valueChanged)="_model.formGroup.get('isActive').setValue($event)"></obc-switch>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="isFileModel(_model.formGroup.get('type').value)">
        <div class="progress">
          <div class="progress-bar" [ngStyle]="{'width': _model.formGroup.get('progress').value}">
            {{_model.formGroup.get('progress').value}}
          </div>
        </div>
      </div>
      <div class="form-group" style="text-align: end;">
        <button type="button" [disabled]="!_model.formGroup.valid" *ngIf="!_model.formGroup.get('announcementId').value" class="btn btn-primary"
          (click)="onAdd(_model)">
          Add New
          Announcement
        </button>
        <button type="button" [disabled]="!_model.formGroup.valid" *ngIf="_model.formGroup.get('announcementId').value" class="btn btn-info"
          (click)="onAdd(_model)">
          Update
        </button>
        <button type="button" [disabled]="!_model.formGroup.valid" *ngIf="_model.formGroup.get('announcementId').value"
          class="btn btn-danger ml-1" (click)="onDiscardChanges(_model)">
          Discard Changes
        </button>
      </div>
    </div>
  </div>
</div>
