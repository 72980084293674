<form class="{{ formClass }}">
  <div class="row">
    <div [class]="colClass" *ngFor="let c of formArray.controls">
      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" [formControl]="c" id="{{getCustomFormControl(c).item.label}}">
        <label class="form-check-label"
               for={{getCustomFormControl(c).item.label}}>{{getCustomFormControl(c).item.label}}</label>
      </div>
    </div>
  </div>
</form>

