import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AssignToolType } from 'src/app/enums/assign-tool-type.enum';
import { getFileExtenstion, toBase64 } from 'src/app/helpers/general-functions';
import { ToolCategoryViewModel } from 'src/app/models/inventory/tool-category-model';
import { ToolDynamicFieldValueModel } from 'src/app/models/inventory/tool-dynamic-field-model';
import { ToolFullInfoViewModel } from 'src/app/models/inventory/tool-full-info-view-model';
import { ToolStatusViewModel } from 'src/app/models/inventory/tool-status-model';
import { BaseToolModel, TemporarilyToolModel, ToolModel } from 'src/app/models/inventory/tool-view-model';
import { RegionViewModel } from 'src/app/models/region/region-model';
import { UploadFileInfo } from 'src/app/models/requiredDocumentAnswer';
import { BaseSiteViewModel } from 'src/app/models/site-viewmodel';
import { UserBasicInfoViewModel } from 'src/app/models/user-basic-info-viewmodel';
import { InventoryService } from 'src/app/services/inventory.service';
import { ModalService } from 'src/app/services/modal.service';
import { UserService } from 'src/app/services/user.service';
import { ToolFieldRendererComponent } from '../tool-field-renderers/tool-field-renderer/tool-field-renderer.component';
import { InventoryLocationSelectorModel } from "../../../models/inventory/inventory-location-selector-model";
import { SiteAssetService } from 'src/app/services/site-asset.service';
import {
  CompanyAssetConfiguration, CompanyAssetConfigurationQueryViewModel,
  SiteAssetConfigurationQueryViewModel
} from 'src/app/models/site-asset/site-asset-view-model';
import { CompanyViewModel } from 'src/app/models/company-viewmodel';
import { FileUploadAcceptType } from "../../../enums/file-upload-accept-type";

@Component({
  selector: 'app-upsert-inventory-asset',
  templateUrl: './upsert-inventory-asset.component.html',
  styleUrls: ['./upsert-inventory-asset.component.scss']
})
export class UpsertInventoryAssetComponent implements OnInit {
  _asset: ToolFullInfoViewModel = null;
  FileUploadAcceptType = FileUploadAcceptType;
  @Input() set isTemporarilyAsset(value: boolean) {
    this.isTempAsset = value;
    this.updateOnChangeTempStatus();
  }

  isTempAsset: boolean = false;
  @Input() parentSelectedSite: BaseSiteViewModel;
  @Input() externalSiteSupplierId: number;
  @Input() showSubmitButton: boolean = true;
  @ViewChild('assetName') assetName: ElementRef;

  @Input() set asset(value: ToolFullInfoViewModel) {
    this._asset = value;
    this.updateFormValues();
  }

  get editMode(): boolean {
    return this._asset != null;
  }

  @Output() closeMe = new EventEmitter();

  selectedSiteId: number;
  AssignToolType = AssignToolType;
  inProgress: boolean = false;
  categories = new BehaviorSubject<ToolCategoryViewModel[]>([]);
  statuses = new BehaviorSubject<ToolStatusViewModel[]>([]);
  assetConfiguration = new BehaviorSubject<CompanyAssetConfiguration>(null);
  //locations = new BehaviorSubject<InventorySiteLocationsViewModel[]>([]);
  showRegionSelectorComponent: boolean = false;
  newLocation: string;

  formGroup = new FormGroup({
    categoryId: new FormControl(null, [Validators.required]),
    regionId: new FormControl(null),
    name: new FormControl(null, [Validators.required]),
    statusId: new FormControl(null, [Validators.required]),
    image: new FormControl(null, []),
    toUserId: new FormControl(null, []),
    toLocationId: new FormControl(null, []),
    assignType: new FormControl(AssignToolType.Location)
  })

  constructor(private inventoryService: InventoryService,
    private modalService: ModalService,
    public userService: UserService, private siteAssetService: SiteAssetService) {
  }

  _currentCompany: CompanyViewModel = null;
  get currentCompany(): CompanyViewModel {
    return this._currentCompany;
  }

  get currentCompanyId(): number {
    if (this._currentCompany == null)
      return null;
    else
      return this._currentCompany.id;
  }

  ngOnInit() {
    this.userService.currentCompany$.subscribe(c => this._currentCompany = c);
    this.formGroup.controls.assignType.valueChanges.subscribe(_ => this.resetUserLocation());
    this.formGroup.controls.image.valueChanges.subscribe(async _ => await this.updateMainImageSource());
    this.selectedSiteId = this.parentSelectedSite?.id;

      this.inProgress = true;
      const q: SiteAssetConfigurationQueryViewModel ={siteId:this.selectedSiteId,siteSupplierId:this.externalSiteSupplierId};
      (this.selectedSiteId ?
        this.siteAssetService.getSiteAssetsConfiguration(q) :
        this.siteAssetService.getCompanyAssetsConfiguration(
          {companyId: this._currentCompany?.id} as CompanyAssetConfigurationQueryViewModel
        ))
        .pipe(finalize(() => {
          this.inProgress = false;
        }))
        .subscribe(res => {
          this.assetConfiguration.next(res);
          if ((this.isTempAsset && this.assetConfiguration.value.isRequiredShortTermAssetImage) || (!this.isTempAsset && this.assetConfiguration.value.isRequiredAssetImage))
          {
            this.formGroup.get('image').setValidators([Validators.required]);
            this.formGroup.controls['image'].updateValueAndValidity();
          }
          else{
            this.formGroup.get('image').setValidators([]);
            this.formGroup.controls['image'].updateValueAndValidity();
          }
        });

    if (!this.isTempAsset) {
      this.inProgress = true;
      forkJoin({
        categories: this.inventoryService.getToolCategories(),
        statuses: this.inventoryService.getToolStatuses(),
        //locations : this.inventoryService.getInventorySiteLocations()
      })
        .pipe(finalize(() => {
          this.inProgress = false;
        }))
        .subscribe(res => {
          this.categories.next(res.categories.data);
          this.statuses.next(res.statuses.data);

          if (!this.editMode) {
            this.formGroup.controls.categoryId.setValue(res.categories.data[0]?.id);
            this.formGroup.controls.statusId.setValue(res.statuses.data[0]?.id);
          }
        });
    }

  }

  updateOnChangeTempStatus() {
    if (this.isTempAsset) {
      this.formGroup.controls['categoryId'].clearValidators();
      this.formGroup.controls['statusId'].clearValidators();
    }
    else {
      this.formGroup.controls['categoryId'].setValidators([Validators.required]);
      this.formGroup.controls['statusId'].setValidators([Validators.required]);
    }
  }

  updateFormValues() {
    if (this._asset) {
      this.formGroup.controls.categoryId.setValue(this._asset.categoryId);
      this.formGroup.controls.name.setValue(this._asset.name);
      this.formGroup.controls.statusId.setValue(+this._asset.currentStatusId);
      this.formGroup.controls.regionId.setValue(this._asset.regionId);
      //this.formGroup.controls.image.setValue(this._asset.);
      //this.formGroup.controls.toUserId.setValue();
      //this.formGroup.controls.toLocationId.setValue();
      //this.formGroup.controls.assignType.setValue();
      this.updateMainImageSource();
    }
  }

  async onEditAsset() {
    if (!this.isEditFormValid()) {
      this.modalService.error("All Required Data Not Entered")
      return;
    }
    var mainImageFileInfo: UploadFileInfo = null;
    if (this.formGroup.controls.image.value) {
      var file = this.formGroup.controls.image.value;
      var body = (await toBase64(file)) as string;
      var fileAsBase64 = body.split(';base64,')[1];
      var fileExtension = getFileExtenstion(file.name);
      mainImageFileInfo = {
        fileAsBase64: fileAsBase64,
        fileExtension: fileExtension,
      } as UploadFileInfo;
    }
    var fieldValues = this.toolFieldRenderer.getFieldValues();

    var model = {
      categoryId: this.formGroup.controls.categoryId.value,
      name: this.formGroup.controls.name.value,
      mainImageFileInfo: mainImageFileInfo,
      toolDynamicFieldValues: fieldValues,
      regionId: this.formGroup.controls.regionId.value
    } as BaseToolModel;
    this.inProgress = true;
    this.inventoryService.updateWebTool(this._asset.id, model)
      .pipe(finalize(() => {
        this.inProgress = false
      }))
      .subscribe(res => {
        if (res.success == true)
          this.closeMe.emit();
        else
          this.modalService.error(res.message);
      })
  }

  public async createQueryModel() {
    var file = this.formGroup.controls.image.value;
    var body = file == null ? null : (await toBase64(file)) as string;
    var fileAsBase64 = body == null ? null : body.split(';base64,')[1];
    var fileExtension = file == null ? null : getFileExtenstion(file.name);


    if (this.isTempAsset) {
      if (file != null)
        return {
          name: this.formGroup.controls.name.value,
          mainImageFileInfo: {
            fileAsBase64: fileAsBase64,
            fileExtension: fileExtension,
          } as UploadFileInfo,
        } as TemporarilyToolModel;
      return {
        name: this.formGroup.controls.name.value,
        mainImageFileInfo: null as UploadFileInfo,
      } as TemporarilyToolModel;
    }

    var fieldValues = this.toolFieldRenderer?.getFieldValues()?.map((f) => {
      return {
        dynamicFieldId: f.dynamicFieldId,
        value: f.value,
        uniqueKey: f.uniqueKey,
      } as ToolDynamicFieldValueModel;
    })
    let toUserId = this.formGroup.controls.toLocationId.value == null && this.newLocation == null ? this.formGroup.controls.toUserId.value : null;
    let toLocationId = this.formGroup.controls.toUserId.value == null && this.newLocation == null ? this.formGroup.controls.toLocationId.value : null;
    let newLocationName = this.formGroup.controls.toLocationId.value == null && this.formGroup.controls.toUserId.value == null ? this.newLocation : null;
    return {
      categoryId: this.formGroup.controls.categoryId.value,
      regionId: this.formGroup.controls.regionId.value,
      name: this.formGroup.controls.name.value,
      toolStatusId: this.formGroup.controls.statusId.value,
      toLocationId: toLocationId,
      toUserId: toUserId,
      toSiteId: this.selectedSiteId,
      newLocationTitle: newLocationName,
      mainImageFileInfo: file ==null?null: {
        fileAsBase64: fileAsBase64,
        fileExtension: fileExtension,
      } as UploadFileInfo,
      toolDynamicFieldValues: fieldValues,
    } as ToolModel;
  }

  async onAddAsset() {
    let model = await this.createQueryModel();

    if (this.showSubmitButton == true) {
      this.inProgress = true;
      this.inventoryService.addWebTool(model)
        .pipe(finalize(() => {
          this.inProgress = false
        }))
        .subscribe(res => {
          if (res.success == true)
            this.closeMe.emit();
          else
            this.modalService.error(res.message);
        })
    } else return model;

  }

  getCurrentCategory() {
    return this.categories?.value.find(x => x.id == this.formGroup.controls.categoryId.value)
  }

  @ViewChild('toolFieldRenderer') toolFieldRenderer: ToolFieldRendererComponent;

  public isAddFormValid() {
    return this.formGroup?.valid &&
      this.toolFieldRenderer?.valid &&
      (this.formGroup?.controls?.toUserId?.value != null ||
        this.formGroup?.controls?.toLocationId?.value != null ||
        this.newLocation != null);
  }

  public isTempAddFormValid() {
    return this.formGroup.valid;
  }

  isEditFormValid() {
    return this.formGroup.controls.categoryId.valid &&
      this.formGroup.controls.name.valid &&
      this.toolFieldRenderer?.valid == true;
  }

  resetUserLocation() {
    this.formGroup.controls.toLocationId.setValue(null);
    this.formGroup.controls.toUserId.setValue(null);
    this.selectedSiteId = null;
    this.newLocation = null;
  }

  onUserChange($event: UserBasicInfoViewModel) {
    this.resetUserLocation();
    if ($event?.id)
      this.formGroup.controls.toUserId.setValue($event.id);
  }

  onLocationChange($event: InventoryLocationSelectorModel) {
    this.resetUserLocation();
    this.formGroup.controls.toLocationId.setValue($event?.selectedLocation?.id);
    this.selectedSiteId = $event?.selectedSite?.siteId;
  }

  onAddNewLocation(newLocation: InventoryLocationSelectorModel) {
    this.resetUserLocation();
    this.selectedSiteId = newLocation.selectedSite?.siteId;
    this.newLocation = newLocation.newLocationTitle;
  }

  imageSource = null;

  async updateMainImageSource() {

    if (this.formGroup.controls.image.value) {
      var file = this.formGroup.controls.image.value;
      this.imageSource = (await toBase64(file)) as string;
      return;
    }
    this.imageSource = null;
  }

  onRegionSelect(value) {
    this.formGroup.controls.regionId.setValue(value);
  }

  onRegionLoaded(regions: RegionViewModel[]) {
    this.showRegionSelectorComponent = regions && regions.length > 0;
  }
  get showAssetAdditionalInfo(): boolean {
    return this._asset?.additionalData?.length > 0;
  }
  onViewAssetAdditionalInfo(template: any) {
    this.modalService.show(template);
  }
}
