<ng-container *ngIf="noCard">
  <accordion-group>
    <div accordion-heading>
      <obc-accordion-header [isAccordionDirty]="dirtyComponents?.scannableGroups?.isDirty" [mainTitle]="'Scannables'"
        [icon]="'fa fa-print'" [iconColor]="'#746868'" [info]="[{'title': '', 'value': headerInfo}]">
      </obc-accordion-header>
    </div>
    <div>
      <div class="d-flex justify-content-end mb-3">
        <a *ngIf="unAddedSiteGroups.length" (click)="onShowSiteScannableGroupModal(addSiteScannableGroup)"
          class="btn btn-sm btn-info text-decoration-none text-white">
          <i class="fa fa-plus"></i>
          Add Scannable Group
        </a>
      </div>
      <obc-loader [show]="siteGroupProgress"></obc-loader>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-responsive-md table-striped mb-0">
            <thead>
              <tr>
                <th>Group</th>
                <th><span class="pull-right">Remove</span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td *ngIf="!siteGroups || siteGroups.length == 0" style="width: 100%; float: none;border-top: none;"
                  colspan="2">
                  <p class="text-center mb-0">
                    There is no scannable group, You can add by clicking on `Add Scannable Group` button
                  </p>
                </td>
              </tr>

              <tr *ngFor="let siteGroup of siteGroups">
                <td>
                  {{siteGroup.name}} <sub>{{siteGroup.label}}</sub>
                </td>
                <td>
                  <button (click)="removeSiteGroup(siteGroup)" class="pull-right btn btn-xs btn-warning"><i
                      class="fas fa-times"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </accordion-group>

</ng-container>
<ng-container *ngIf="!noCard">
  <div class="row">
    <obc-loader [show]="inProgress"></obc-loader>
  </div>
  <div class="row">
    <div class="col-md-12 mb-4">
      <section class="card">
        <obc-loader [show]="siteGroupProgress"></obc-loader>
        <header class="card-header">
          <h2 class="card-title">Site Scannable Groups </h2>
          <button *ngIf="unAddedSiteGroups.length" (click)="onShowSiteScannableGroupModal(addSiteScannableGroup)"
            class="btn btn-sm btn-info pull-right card-header-button">
            <i class="fa fa-plus"></i>
            Add Scannable Groups</button>
        </header>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <table class="table table-responsive-md table-striped mb-0">
                <thead>
                  <tr>
                    <th>Group</th>
                    <th><span class="pull-right">Remove</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td *ngIf="!siteGroups || siteGroups.length == 0" style="width: 100%; float: none;border-top: none;"
                      colspan="2">
                      <p class="text-center mb-0">
                        Please Select Scannable Groups
                      </p>
                    </td>
                  </tr>

                  <tr *ngFor="let siteGroup of siteGroups">
                    <td>
                      {{siteGroup.name}} <sub>{{siteGroup.label}}</sub>
                    </td>
                    <td>
                      <button (click)="removeSiteGroup(siteGroup)" class="pull-right btn btn-xs btn-warning"><i
                          class="fas fa-times"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</ng-container>

<ng-template #addSiteScannableGroup>
  <div class="card">
    <div class="card-header">
      <h2 class="card-title">Scannable Groups
      </h2>
      <button type="button" class="close pull-right card-header-button" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <obc-loader [show]="siteGroupProgress"></obc-loader>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-responsive-md table-striped mb-0 obc-table">
            <thead>
              <tr>
                <th>Groups</th>
                <th><span class="pull-right">Add</span></th>
              </tr>
            </thead>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 scrollable-list" style="max-height: 400px;">
          <table class="table table-responsive-md table-striped mb-0 obc-table">
            <tbody>
              <tr *ngIf="!unAddedSiteGroups || unAddedSiteGroups.length == 0">
                <td colspan="2" style="width: 100%; float: none;border-top: none;">
                  <p class="text-center mb-0">There is no scannable group</p>
                </td>
              </tr>
              <tr *ngFor="let siteGroup of unAddedSiteGroups">
                <td>
                  {{siteGroup.name}}

                  <sub>
                    {{siteGroup.label}}
                  </sub>
                </td>
                <td>
                  <button class="pull-right btn btn-xs btn-info" (click)="addSiteGroup(siteGroup.id)">
                    <i class="fas fa-plus"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>