import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SiteAttendanceApprovalStatus } from 'src/app/enums/site-attendance-approval-status.enum';
import { SiteAttendanceApprovalStatusViewModel } from 'src/app/models/site-attendance-approval-status';
import { ModalService } from 'src/app/services/modal.service';
import { SiteAttendanceService } from 'src/app/services/site-attendance.service';

@Component({
  selector: 'obc-site-attendance-approval-status',
  templateUrl: './site-attendance-approval-status.component.html',
  styleUrls: ['./site-attendance-approval-status.component.scss']
})
export class SiteAttendanceApprovalStatusComponent implements OnInit {
  @Input() status : SiteAttendanceApprovalStatusViewModel = null;
  @Output() statusChange = new EventEmitter();
  SiteAttendanceApprovalStatus = SiteAttendanceApprovalStatus;
  constructor(private siteAttendanceService : SiteAttendanceService, private modalService : ModalService) { }

  ngOnInit() {
  }

  onStatusChange(isApprove : boolean) {
    var subs = isApprove ? this.siteAttendanceService.approveSiteAttentendanceByHost(this.status.siteAttendanceId) : this.siteAttendanceService.rejectSiteAttentendanceByHost(this.status.siteAttendanceId);
    subs.subscribe(res=> {
      this.statusChange.emit();
    }, err => {
      this.modalService.error(err);
    });
  }

}
