import { Injectable } from '@angular/core';
import { VideoViewModel, VideoList } from '../models/video-viewmodel';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AcademyService {
  apiUrl: string = "/api/academy";
  constructor(private _http: HttpClient, private _errorService: ErrorService) { }
  getAll = (system: number): Observable<VideoViewModel[]> =>
    this._http.get<VideoViewModel[]>(`${this.apiUrl}/get-all/${system}`)
      .pipe(catchError(this._errorService.handleError));
  addVideo = (model: VideoViewModel): Observable<number> =>
    this._http.post<number>(`${this.apiUrl}/add-video`, model)
      .pipe(catchError(this._errorService.handleError));
  removeVideo = (id: number): Observable<number> =>
    this._http.get<number>(`${this.apiUrl}/remove-video/${id}`)
      .pipe(catchError(this._errorService.handleError));
  editVideo = (model: VideoViewModel): Observable<number> =>
    this._http.post<number>(`${this.apiUrl}/edit-video`, model)
      .pipe(catchError(this._errorService.handleError));
  pageRelatedVideo = (path: string, system: number): Observable<VideoViewModel> =>
    this._http.get<VideoViewModel>(`${this.apiUrl}/related/${system}?path=${path}`)
      .pipe(catchError(this._errorService.handleError));

  getCategorizedList = (system: number): Observable<VideoList[]> =>
    this._http.get<VideoViewModel[]>(`${this.apiUrl}/get-categorized-list/${system}`)
      .pipe(catchError(this._errorService.handleError));
}
