import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormType } from 'src/app/enums/form-type';
import { FormDataDetailViewModel } from 'src/app/models/form/form-data-detail-view-model';
import { FormService } from 'src/app/services/form.service';
import { ModalService } from 'src/app/services/modal.service';
import { SiteAssetService } from 'src/app/services/site-asset.service';
import { SupplierFormService } from "../../../services/supplier-form.service";

@Component({
  selector: 'obc-form-details',
  templateUrl: './form-details.component.html',
  styleUrls: ['./form-details.component.scss']
})
export class FormDetailsComponent implements OnInit {
  @Input() formDataId: number;
  @Input() entityId: number;
  @Input() hashLink: string;
  @Input() formType: FormType;
  @Input() set formData(data: FormDataDetailViewModel) {
    this.formDataDetails = data;
    this.formDataId = this.formDataDetails?.filledFormInfo?.formDataId;
    this.initializeFormData();
  }
  @Output() onRefesh = new EventEmitter();

  multipleTabsAvailable: boolean = false;
  inProgress = false;
  formDataDetails: FormDataDetailViewModel;
  siteAttendanceInfoFields: any[];
  supplierReviewInfoFields: any[];
  constructor(private supplierFormService: SupplierFormService, private formService: FormService, private modalService: ModalService, private siteAssetService: SiteAssetService) { }

  ngOnInit(): void {
    this.getFormDataDetails()
  }

  getFormDataDetails() {
    if (this.hashLink != null || (this.formDataId && this.formDataId > 0)) {
      this.inProgress = true;
      (this.hashLink != null ?
        ((this.formType == FormType.AssetRegistration || this.formType == FormType.AssetOperator) ?
          this.siteAssetService.getAnonymousFilledForm(this.formDataId, this.hashLink) :
          this.supplierFormService.getAnonymousFilledForm(this.formDataId, this.hashLink)) :
        this.formService.getFormDataDetails(this.formDataId)).subscribe(
          res => {
            if (res && res.success) {
              this.formDataDetails = res.data;
              this.initializeFormData();

            } else {
              this.modalService.error('No Details Available');
            }
          }, err => {
            this.modalService.error("Request Failed");
          }, () => {
            this.inProgress = false;
          }
        );
    }
  }

  private initializeFormData() {
    this.setAttendanceInformationFields();
    this.setsupplierReviewInfoFields();

    if (this.formDataDetails.formDataSiteAttendanceDetail ||
      this.formDataDetails.formDataSupplierReviewDetail)
      this.multipleTabsAvailable = true;
  }

  setAttendanceInformationFields() {
    if (this.formDataDetails.formDataSiteAttendanceDetail)
      this.siteAttendanceInfoFields = [
        { title: 'Site', key: 'siteName', icon: 'map-marker-alt' },
        { title: 'First Name', key: 'firstName', icon: 'user' },
        { title: 'Last Name', key: 'lastName', icon: 'user' },
        { title: 'Mobile', key: 'mobile', icon: 'phone', type: 'mobile' },
        { title: 'CheckIn', key: 'checkInDate', icon: 'calendar', type: 'datetime' },
        { title: 'CheckOut', key: 'checkOutDate', icon: 'calendar', type: 'datetime' },
      ].filter((f) => this.formDataDetails?.formDataSiteAttendanceDetail[f.key] != null);
  }
  setsupplierReviewInfoFields() {
    if (this.formDataDetails.formDataSupplierReviewDetail)
      this.supplierReviewInfoFields = [
        { title: 'Site', key: 'siteName', icon: 'map-marker-alt' },
        { title: 'First Name', key: 'firstName', icon: 'user' },
        { title: 'Last Name', key: 'lastName', icon: 'user' },
        { title: 'Mobile', key: 'mobile', icon: 'phone', type: 'mobile' },
        { title: 'Supplier', key: 'supplierName', icon: 'industry' },
        { title: 'Document Status', key: 'status', icon: 'book', type: 'siteSupplierDocumentStatus' },
      ].filter((f) => this.formDataDetails?.formDataSupplierReviewDetail[f.key] != null);
  }
}

