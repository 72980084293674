import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sortable-title-header',
  templateUrl: './sortable-title-header.component.html',
  styleUrls: ['./sortable-title-header.component.scss']
})
export class SortableTitleHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() isDesc: boolean;
  @Input() headerKey: string;
  @Input() sortedHeaderKey: string;
  @Output() sort = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  onSort() {
    this.sort.emit(this.headerKey);
  }

}
