<div class="row">
  <div class="col-md-12">
    <div class="card">
      <obc-loader [show]="inProgress"></obc-loader>
      <header class="card-header">
        <div class="d-flex flex-row justify-content-between">
          <div>Register Asset</div>
        </div>
        <div class="card-actions">
          <a class="card-action card-action-dismiss" (click)="closeModal()"></a>
        </div>
      </header>
      <div class="card-body">
        <div *ngIf="showRegisterPanel != true">
          <div *ngIf="showRegisterAssetAs">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12" *ngIf="activeRegisterAssetAs">
                    <label>
                      <input name="registerAssetAs" type="radio" [value]="RegisterAssetAsType.Company"
                        [ngModel]="registerAssetAs" (change)="registerAssetAsChanged(RegisterAssetAsType.Company)"
                        [disabled]="!activeRegisterAssetAs" />
                        Register on behalf of <span class="font-weight-bold">{{CompanyName}}</span>
                    </label>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group row">
                    <label>
                      <input name="registerAssetAs" type="radio" [value]="RegisterAssetAsType.CompanySupplier"
                        [ngModel]="registerAssetAs"
                        (change)="registerAssetAsChanged(RegisterAssetAsType.CompanySupplier)"
                        [disabled]="!activeRegisterAssetAs" />
                        Register on behalf of supplier:
                    </label>
                    <ng-container *ngIf="registerAssetAs == RegisterAssetAsType.CompanySupplier">
                      <label *ngIf="!activeRegisterAssetAs" class="ml-2 font-weight-bold">
                        {{ supplierName }}
                      </label>
                      <select [(ngModel)]="selectedSupplier" *ngIf="activeRegisterAssetAs"
                        [class]="'form-control '+ (activeRegisterAssetAs == true && registerAssetAs == RegisterAssetAsType.CompanySupplier && selectedSupplier == null ? 'ng-invalid' : 'ng-valid') ">
                        <option [ngValue]="null">Select Supplier</option>
                        <option *ngFor="let supplier of siteSuppliers" [ngValue]="supplier">
                          {{ supplier.supplierName }}
                        </option>
                      </select>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3" *ngIf="!_onlyTempAssets">
            <div class="col-md-12">
              <label>
                <input name="options" ng-control="options" type="radio" [value]="RegisterAssetMode.SelectAsset"
                  [(ngModel)]="registerAssetMode" />
                Select Existing Asset
              </label>
              <label>
                <input class="ml-3" name="options" ng-control="options" type="radio"
                  [value]="RegisterAssetMode.CreateAsset" [(ngModel)]="registerAssetMode" />
                Create New Asset
              </label>
              <label>
                <input class="ml-3" name="options" ng-control="options" type="radio"
                  [value]="RegisterAssetMode.CreateTemporarilyAsset" [(ngModel)]="registerAssetMode" />
                Register Short Term Asset
              </label>
            </div>
          </div>

          <div class="row" *ngIf="registerAssetMode == RegisterAssetMode.CreateAsset">
            <div class="col-md-12 mt-2">
              <app-upsert-inventory-asset #insertAssetRenderer [parentSelectedSite]="site"
                [externalSiteSupplierId]="externalSiteSupplierId" [showSubmitButton]="false">
              </app-upsert-inventory-asset>
            </div>
          </div>

          <div class="row" *ngIf="
              registerAssetMode == RegisterAssetMode.CreateTemporarilyAsset
              ">
            <div class="col-md-12 mt-2">
              <app-upsert-inventory-asset #insertTempAssetRenderer [parentSelectedSite]="site"
                [isTemporarilyAsset]="registerAssetMode == RegisterAssetMode.CreateTemporarilyAsset"
                [externalSiteSupplierId]="externalSiteSupplierId" [showSubmitButton]="false">
              </app-upsert-inventory-asset>
            </div>
          </div>

          <div class="row" *ngIf="registerAssetMode == RegisterAssetMode.SelectAsset">
            <div class="col-md-12 mt-2">
              <form action="" (ngSubmit)="onClickNextButton()">
                <obc-asset-selector [required]="true" [siteId]="siteId" (assetIdSelect)="onChangeAssetId($event)"
                  (onLoadAssets)="onLoadAssetsToRegister($event)">
                </obc-asset-selector>
              </form>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-12">
              <button type="button" class="btn btn-primary pull-right" [disabled]="!isValidAsset"
                (click)="onClickNextButton()">
                Next
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="showRegisterPanel">
          <div class="row mt-2">
            <div class="col-12">
              <strong><label>Select Asset Registration Form</label></strong>
              <select class="form-control" [(ngModel)]="selectedForm">
                <option *ngFor="let form of assetRegistrationForms" [ngValue]="form">
                  {{ form.name }}
                </option>
              </select>
            </div>
          </div>
          <div *ngIf="selectedForm">
            <div class="row mt-2">
              <div class="col-12">
                <obc-form-data-renderer #formDataRenderer [formType]="formType.AssetRegistration"
                  [supplierId]="supplierId" [formName]="selectedForm.name" [allParameters]="{
                    formId: selectedForm.id,
                    siteId: siteId,
                    hashLink: supplierHashLink,
                    toolId: selectedAssetId
                  }" [initialQuestionAnswers]="initialAnswers" (validate)="validateForm($event)">
                </obc-form-data-renderer>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <obc-asset-operator-mode-selector [manageSiteAssetMode]="manageSiteAssetMode"
                  [supplierHashLink]="supplierHashLink" [onlyTempAsset]="onlyTempAssets"
                  [siteCompanyTitle]="siteCompanyTitle"
                  (operatorMode)="setOperatorMode($event)"></obc-asset-operator-mode-selector>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12">
                <button type="button" class="btn btn-primary pull-right" [disabled]="!isFormValid"
                  (click)="registerSiteAsset()">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>