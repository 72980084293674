<div class="input-group">
  <input [disabled]="disabled" name="Address" [(ngModel)]="searchTerm" [typeahead]="suggestions$" [isAnimated]="true"
    [adaptivePosition]="true" [typeaheadAsync]="true" [typeaheadItemTemplate]="customItemTemplate"
    [typeaheadOptionsInScrollableView]="6" (typeaheadOnSelect)="onSelectUser($event)" [typeaheadMinLength]="2"
    (typeaheadNoResults)="typeaheadNoResults($event)" [container]="container" #userNgModel="ngModel"
    (keyup)="onChange($event)" [placeholder]="placeholder" [class.ng-invalid]="isInvalid" class="form-control"
    autocomplete="off">
  <div class="input-group-prepend" *ngIf="loader">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<small class="ml-1 text-danger" *ngIf="noResult && displayNoResult">{{notFoundMessage}}</small>
<ng-template #customItemTemplate let-model="item">
  <span>{{model.firstName}} - <strong>{{model.lastName}} </strong>- <small>{{model.mobile | mobile}}</small></span>
</ng-template>