import { Component, Input, OnInit } from '@angular/core';
import { ToolDynamicFieldType } from 'src/app/enums/tool-dynamic-field-type.enum';
import { CustomFormControl } from 'src/app/models/custom-formcontrol';
import { ToolDynamicFieldViewModel } from 'src/app/models/inventory/tool-dynamic-field-model';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';


@Component({
  selector: 'obc-text-tool-field-renderer',
  templateUrl: './text-tool-field-renderer.component.html',
  styleUrls: ['./text-tool-field-renderer.component.scss']
})
export class TextToolFieldRendererComponent implements OnInit {
  @Input() type: ToolDynamicFieldType;
  @Input() control: CustomFormControl;
  toolDynamicFieldType = ToolDynamicFieldType;
  get item(): ToolDynamicFieldViewModel {
    return this.control?.item;
  }
  get fieldType(): string {
    if (this.type == this.toolDynamicFieldType.Number)
      return "number";
    else if (this.type == this.toolDynamicFieldType.Text)
      return "text";
  }
  DatetimePickerMode = DatetimePickerMode;
  constructor() {
  }

  ngOnInit() {
  }
}
