import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'obc-hour-minute-selector',
  templateUrl: './hour-minute-selector.component.html',
  styleUrls: ['./hour-minute-selector.component.scss']
})
export class HourMinuteSelectorComponent implements OnInit {
  mode: 'Minute' | 'Hour' = 'Minute';
  valueAsMinute: number;
  valueAsHour: number;
  value: any;
  alreadyInitialized = false;

  @Input() cssClass: String;

  @Input() set minute(v: number) {
    this.valueAsMinute = v;
    this.valueAsHour = Math.round(this.valueAsMinute / 60);
    this.changeMode(
      this.alreadyInitialized == false ?
        v % 60 == 0 ? 'Hour' : 'Minute' : this.mode
    );
    this.alreadyInitialized = true;
  };

  @Input() set isMinuteActiveByDefault(_: boolean) {
    this.changeMode(this.mode);
  };

  get minute() {
    return this.valueAsMinute;
  };

  @Output() valueChanged: EventEmitter<number> = new EventEmitter<number>();

  set currentMode(mode) {
    this.mode = mode;
    this.changeMode(this.mode);
  }

  get currentMode() {
    return this.mode;
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  changeMode(mode: 'Hour' | 'Minute') {
    this.mode = mode;

    if (this.mode == 'Minute')
      this.value = this.valueAsMinute;
    else
      this.value = this.valueAsHour;
    this.emitValue();
  }

  setHourMinuteValues(value, element: HTMLInputElement) {
    if(!element.checkValidity()) {
      value = 0;
    }
    if (this.mode == 'Hour') {
      this.valueAsHour = +value;
      this.valueAsMinute = Math.round(value * 60);
    } else {
      this.valueAsMinute = value;
      this.valueAsHour = Math.round(value * (1 / 60));
    }
    element.value = value;
    this.emitValue();
  }

  selectValue(input: HTMLInputElement) {
    input.select();
  }

  emitValue() {
    this.valueChanged.emit(+this.valueAsMinute);
  }

}
