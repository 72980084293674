import { Pipe, PipeTransform } from '@angular/core';
import { FormDataStatus } from '../enums/form-data-status';

@Pipe({
  name: 'permitStatusTitle'
})
export class PermitStatusTitlePipe implements PipeTransform {

  transform(value: FormDataStatus): string {
    if (value == FormDataStatus.NotRequired) return "";
    if (value == FormDataStatus.PendingApproval) return "Pending Approval";
    if (value == FormDataStatus.Approved) return "Approved";
    if (value == FormDataStatus.Rejected) return "Rejected";
    return value;
  }

}
