import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseGridComponent } from 'src/app/custom-controls/grid/base-grid.component';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { Table } from 'src/app/enums/table.enum';
import { GridColumn, GridConfig, GridTemplate, InitialFilter } from 'src/app/models/grid/grid.config';
import { WorkerProfileViewModel } from 'src/app/models/userProfile/worker-profile-view-model';
import { MobilePipe } from 'src/app/pipes/mobile.pipe';
import { LayoutService } from 'src/app/services/layout.service';
import { ModalService } from "../../../services/modal.service";
import { Observable, of, Subscription } from 'rxjs';
import { UserPermission } from 'src/app/enums/user-permission.enum';

@Component({
  selector: 'obc-user-directory',
  templateUrl: './user-directory.component.html',
  styleUrls: ['./user-directory.component.scss']
})
export class UserDirectoryComponent implements OnInit {


  @ViewChild('grid') grid: BaseGridComponent<WorkerProfileViewModel>;
  gridColumns: GridColumn<WorkerProfileViewModel>[] = [];
  gridConfig: GridConfig = {} as GridConfig;
  showProfilePanel: boolean = false;
  selectedUserProfile: WorkerProfileViewModel;
  initialFilters: InitialFilter[] = [];
  selectedSiteId: number;
  UserPermission = UserPermission;
  siteRequiredPermissions = UserPermission.ViewReports | UserPermission.EditUserDirectory;

  constructor(private layoutService: LayoutService,
    private mobilePipe: MobilePipe,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.prepareGridConfig();
    this.layoutService.header = "User Directory";
  }

  prepareGridConfig() {
    this.gridConfig = new GridConfig({
      apiUrl: '/api/workerprofile/get-user-directory',
      tableType: Table.UserDirectory,
      forceHideAllControlButtons: false,
      displayQuestionsFilter: false,
      displayGeneralSearch: true,
      generalSearchPlaceholder: 'Search by name, mobile, supplier or visitor type',
      generalSearchApiKeyName: 'searchTerm',
      generalSearchMinimumCharsToSearch: 2,
      initialFilters: this.initialFilters,
    });
    this.gridColumns = [
      {
        name: "Profile Image",
        key: "profileImageUrl",
        type: FilterType.Image,
        enableFilter: false,
        enableSort: false,
      },
      {
        name: "User",
        key: "user",
        propertyNameInQuery: 'userIdList',
        type: FilterType.User,
        enableFilter: true,
        enableSort: true,
        displayFunction: (profile) => {
          return `${profile.firstName} ${profile.lastName} (${this.mobilePipe.transform(profile.mobile)})`;
        },
        hideEmptyColumnsEmptinessConditionCallback: (_) => {
          return false;
        },
      },
      {
        name: "Supplier",
        key: "companySupplierTitle",
        propertyNameInQuery: 'supplierIdList',
        type: FilterType.Supplier,
        enableFilter: true,
        enableSort: true,
        isArray: true,
      },
      {
        name: "Visitor Type",
        key: "visitorTypeTitle",
        propertyNameInQuery: 'visitorTypeIdList',
        type: FilterType.VisitorType,
        enableFilter: true,
        enableSort: true,
        isArray: true,
      },
      {
        name: "Details",
        key: 'View Profile',
        type: FilterType.Template,
        enableSort: false,
        enableFilter: false,
        gridTemplate: new GridTemplate().ComponentTemplateName('viewProfile'),
        visible: true,
      }
    ]
  };

  onSelectSite(site) {
    this.selectedSiteId = site?.id;

    this.initialFilters = this.initialFilters.filter(f => f.key != 'siteIds');

    if (this.selectedSiteId)
      this.initialFilters.push({ key: 'siteIds', value: [this.selectedSiteId] });

    this.grid.gridConfig.initialFilters = this.initialFilters;
    this.grid?.loadData(0);
    if (this.showProfilePanel) {

    }
  }

  showProfile(profile: WorkerProfileViewModel) {
    this.selectedUserProfile = profile;
    this.showProfilePanel = true;
  }

  confirmIgnoreUnsavedChanges() {
    if (this.isEditFormDirtyFlag) {
      return this.modalService.confirm('There are unsaved changes. Are you sure you want to leave?', 'Unsaved Changes');
    } else {
      return of(true);
    }
  }

  onBackButton() {
    this.confirmIgnoreUnsavedChanges()
      .subscribe(res => {
        if (res)
          this.navigateBack()
      });
  }

  canDeactivate(): Observable<Subscription> | Observable<boolean> | Promise<boolean> | boolean {
    return this.confirmIgnoreUnsavedChanges();
  }

  navigateBack() {
    this.selectedUserProfile = null;
    this.showProfilePanel = false;
  }

  onProfileUpdated() {
    this.grid?.loadData();
  }

  isEditFormDirtyFlag: boolean = false;
  isEditFormDirty(value: boolean) {
    this.isEditFormDirtyFlag = value;
  }


}
