import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { QuestionType } from 'src/app/enums/question-type';
import { AsyncResult } from 'src/app/models/helper-models';
import { AddQuestionConditionModel } from 'src/app/models/question-condition-models';
import { QuestionViewModel } from 'src/app/models/question-viewmodel';
import { ModalService } from 'src/app/services/modal.service';
import { QuestionService } from 'src/app/services/question.service';

@Component({
  selector: 'app-conditional-question',
  templateUrl: './conditional-question.component.html',
  styleUrls: ['./conditional-question.component.scss']
})
export class ConditionalQuestionComponent implements OnInit {
  @Output() public result = new EventEmitter<AsyncResult>();
  @Input() question: QuestionViewModel;
  allQuestions: QuestionViewModel[] = [];
  get masterQuestion(): QuestionViewModel {
    return this.formGroup.controls.masterQuestion.value;
  };
  inprocess = false;
  get questionId(): Number {
    return this.question.id;
  };
  //question: QuestionViewModel;
  questions: QuestionViewModel[];
  formGroup = new FormGroup({
    masterQuestion: new FormControl(null, [Validators.required]),
    answers: new FormControl(null, [Validators.required])
  })
  constructor(private questionService: QuestionService, private modalService: ModalService) { }

  ngOnInit() {
    this.formGroup.controls.masterQuestion.valueChanges.subscribe(res => {
      this.formGroup.controls.answers.reset();
    });
    this.loadAllQuestions();
  }

  loadAllQuestions() {
    this.questionService.getQuestions()
      .subscribe(res => {
        this.allQuestions = res.map(x => x = new QuestionViewModel(x))
        this.setDropDownQuestion();
      })
  }

  private setDropDownQuestion() {
    if (this.question.conditions && this.question.conditions.length > 0) {
      var masterQuestionId = this.question.conditions[0].masterQuestionId;
      this.questions = this.allQuestions.filter(x => x.id == masterQuestionId);
    } else {
      this.questions = this.allQuestions.filter(x => x.id != this.questionId &&
        (x.questionType == QuestionType.Boolean ||
        x.questionType == QuestionType.SingleSelect ||
        x.questionType == QuestionType.MultiSelect)
      );
    }
    if (this.questions.length == 1)
      this.formGroup.controls.masterQuestion.setValue(this.questions[0]);
  }

  getQuestionTitle(id: number) {
    if (this.questions) {
      return this.questions.find(x => x.id == id)?.questionText ?? "Not Found Or Inactive";
    }
    return "Not Found Or Inactive";
  }

  onAddCondition() {
    var model = {
      questionId: this.questionId,
      masterQuestionId: this.formGroup.controls.masterQuestion.value.id,
      answers: this.formGroup.controls.answers.value
    } as AddQuestionConditionModel;
    this.inprocess = true;
    this.questionService.addQuestionCondition(model)
      .pipe(finalize(() => { this.inprocess = false }))
      .subscribe(res => {
        if (res.isValid) {
          this.question.conditions = res.conditions;
          this.formGroup.controls.answers.reset();
          this.setDropDownQuestion();
        }
        else
          this.modalService.error(res.message);
      }, err => {
        console.log(err);
        this.modalService.error("internal server error");
      })
  }

  onRemoveCondition(id: number) {
    var condition = this.question.conditions.find(x => x.id == id);
    if (!condition) {
      this.modalService.error("item not found");
      return;
    }
    this.inprocess = true;
    this.questionService.removeQuestionCondition(id)
      .pipe(finalize(() => { this.inprocess = false }))
      .subscribe(res => {
        if (res.isValid) {
          this.question.conditions = res.conditions;
          this.formGroup.controls.answers.reset();
          this.setDropDownQuestion();
        }
        else
          this.modalService.error(res.message);
      }, err => {
        console.log(err);
        this.modalService.error("internal server error");
      })
  }

  cancel() {
    this.result.emit({ isFinished: true, isSucceeded: true });
  }
}
