import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { convertObjectToUrlParameters } from '../helpers/extract-params-from-model';
import { GlobalViewModel } from '../models/global-viewmodel';
import { GlobalToolDynamicFieldViewModel } from '../models/inventory/global-tool-dynamic-field-view-model';
import { InventorySiteLocationsViewModel } from '../models/inventory/inventory-site-locations-view-model';
import { SearchGlobalToolDynamicFieldsQueryModel, SearchToolsQueryModel } from '../models/inventory/search-tools-query-model';
import { ToolAssignHistoryRequestModel } from '../models/inventory/tool-assign-history-request-model';
import { ToolAssignHistoryViewModel } from '../models/inventory/tool-assign-history-view-model';
import { ToolCategoryViewModel } from '../models/inventory/tool-category-model';
import { ToolFullInfoViewModel } from '../models/inventory/tool-full-info-view-model';
import { ToolReportRequestModel } from '../models/inventory/tool-report-request-model';
import { ToolStatusViewModel } from '../models/inventory/tool-status-model';
import { ToolViewModel, BaseToolModel, TemporarilyToolModel } from '../models/inventory/tool-view-model';
import { ServerResult, ServerResultData } from '../models/server-result-data';
import { PagingViewModel } from '../models/site-list-paging-view-model';
import { ErrorService } from './error.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  baseApiUrlWithoutCompanyId = `/api/inventory`;
  baseApiUrl = `/api/inventory/${this.userService.currentCompanyId}`;

  constructor(private http: HttpClient, private _errorService: ErrorService, private userService: UserService) { }

  //TODO: complete to use cache
  private _inventoryCategories = new BehaviorSubject<ToolCategoryViewModel[]>(null);
  get inventoryCategories(): BehaviorSubject<ToolCategoryViewModel[]> {
    if (this._inventoryCategories.value == null) {
      this.getToolCategories().subscribe(res => {
        this._inventoryCategories.next(res.data);
      })
    }
    return this._inventoryCategories;
  }

  private _inventoryToolStatuses = new BehaviorSubject<ToolStatusViewModel[]>(null);
  get inventoryToolStatuses(): BehaviorSubject<ToolStatusViewModel[]> {
    if (this._inventoryToolStatuses.value == null) {
      this.getToolStatuses().subscribe(res => {
        this._inventoryToolStatuses.next(res.data);
      })
    }
    return this._inventoryToolStatuses;
  }

  private _inventorySiteLocations = new BehaviorSubject<InventorySiteLocationsViewModel[]>(null);
  get inventorySiteLocations(): BehaviorSubject<InventorySiteLocationsViewModel[]> {
    if (this._inventorySiteLocations.value == null) {
      this.getInventorySiteLocations().subscribe(res => {
        if (res.success)
          this._inventorySiteLocations.next(res.data);
      })
    }
    return this._inventorySiteLocations;
  }

  getToolReport(model: ToolReportRequestModel): Observable<ServerResultData<PagingViewModel<ToolFullInfoViewModel>>> {
    return this.http.post<ServerResultData<PagingViewModel<ToolFullInfoViewModel>>>(`${this.baseApiUrl}/tool-report`, model)
      .pipe(catchError(this._errorService.handleError));
  }
  getToolCategories(): Observable<ServerResultData<ToolCategoryViewModel[]>> {
    return this.http.get<any>(`${this.baseApiUrl}/get-tool-categories`)
      .pipe(catchError(this._errorService.handleError));
  }
  getToolsForCategory(categoryId: number): Observable<GlobalViewModel[]> {
    return this.http.post<GlobalViewModel[]>(`${this.baseApiUrl}/get-tools-for-category/${categoryId}`, null)
      .pipe(catchError(this._errorService.handleError));
  }
  getToolStatuses(): Observable<ServerResultData<ToolStatusViewModel[]>> {
    return this.http.get<any>(`${this.baseApiUrl}/get-tool-statuses`)
      .pipe(catchError(this._errorService.handleError));
  }
  exportToolReportAsCsv(model: ToolReportRequestModel) {
    const params = convertObjectToUrlParameters(model);
    return window.open(this.baseApiUrl + '/export-tool-report-csv/?' + params);
  }
  getInventorySiteLocations(): Observable<ServerResultData<InventorySiteLocationsViewModel[]>> {
    return this.http.get<any>(`${this.baseApiUrl}/get-inventory-locations`)
      .pipe(catchError(this._errorService.handleError));
  }
  getInventoryLocationsBySiteId(siteId: number): Observable<ServerResultData<InventorySiteLocationsViewModel>> {
    return this.http.get<any>(`${this.baseApiUrl}/get-site-locations/${siteId}`)
      .pipe(catchError(this._errorService.handleError));
  }
  getInventoryLocationsBySiteSupplierId(externalSiteSupplierId: number): Observable<ServerResultData<InventorySiteLocationsViewModel>> {
    return this.http.get<any>(`${this.baseApiUrl}/get-site-supplier-locations/${externalSiteSupplierId}`)
      .pipe(catchError(this._errorService.handleError));
  }
  getToolAssignHistory(model: ToolAssignHistoryRequestModel): Observable<ServerResultData<PagingViewModel<ToolAssignHistoryViewModel>>> {
    return this.http.post<ServerResultData<PagingViewModel<ToolAssignHistoryViewModel>>>(`${this.baseApiUrl}/tool-assign-history`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  updateWebTool(toolId: number, model: BaseToolModel): Observable<ServerResultData<ToolViewModel>> {
    return this.http.post<ServerResultData<ToolViewModel>>(`${this.baseApiUrl}/update-tool/${toolId}`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  addWebTool(model: TemporarilyToolModel): Observable<ServerResultData<ToolViewModel>> {
    return this.http.post<ServerResultData<ToolViewModel>>(`${this.baseApiUrl}/web/add-tool`, model)
      .pipe(catchError(this._errorService.handleError));
  }
  removeAsset(id: number): Observable<ServerResult> {
    return this.http.post<ServerResult>(`${this.baseApiUrl}/remove-asset/${id}`, {})
      .pipe(catchError(this._errorService.handleError));
  }

  fetchAssets(model: SearchToolsQueryModel): Observable<GlobalViewModel[]> {
    return this.http.post<ServerResult>(`${this.baseApiUrl}/search-assets`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  getGlobalToolDynamicFields(request: SearchGlobalToolDynamicFieldsQueryModel): Observable<GlobalToolDynamicFieldViewModel[]> {
    return this.http.post<ServerResult>(`${this.baseApiUrlWithoutCompanyId}/search-global-asset-fields`, request)
      .pipe(catchError(this._errorService.handleError));
  }

}
