import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WorkflowTemplateResult } from '../models/workflow/company-workflow-template-view-model';

@Injectable({
  providedIn: 'root'
})
export class WorkflowTemplateService {
  apiUrl: string = "/api/workflowTemplate";

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getAllWorkflowTemplate = (): Observable<WorkflowTemplateResult> =>
    this._http.post<WorkflowTemplateResult>(`${this.apiUrl}/get-all-template/`, null)
      .pipe(catchError(this._errorService.handleError));
}
