<div #selfRef class="d-flex flex-column">
  <bs-datepicker-inline [minDate]="minDate" [maxDate]="maxDate" [bsConfig]="{showWeekNumbers :false }"
    [isDisabled]="isDatePickerDisabled" (bsValueChange)="onDateChange($event)" [bsValue]="date"></bs-datepicker-inline>
  <div class="d-flex flex-row justify-content-around">
    <timepicker *ngIf="mode == DatetimePickerMode.DateTimePicker" [formControl]="timePickerFormControl"
      [showMeridian]="false"></timepicker>
    <div class="d-flex flex-column justify-content-center">
      <button class="btn btn-primary" (click)="select.emit(date)">Set Date/Time</button>
    </div>
  </div>
</div>