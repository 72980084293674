<div>
    <div class="row">
        <div class="col-md-12">
            <canvas id="signaturePad"  style="width: 100%;" height="500px"></canvas>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button type="button" class="btn btn-default pull-right" (click)="clearPad()">Clear</button>
            <button type="button" class="btn btn-primary pull-right" (click)="savePad()">Save</button>
        </div>
    </div>
</div>