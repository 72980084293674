import { Pipe, PipeTransform } from '@angular/core';
import { SupplierDocumentReviewMode } from '../enums/supplier-document-review-mode.enum';

@Pipe({
  name: 'supplierDocumentReviewModePipe'
})
export class SupplierDocumentReviewModePipe implements PipeTransform {

  transform(value: any): string {
    if (value == SupplierDocumentReviewMode.Mandatory) return 'Mandatory';
    if (value == SupplierDocumentReviewMode.Optional) return 'Optional';
    if (value == SupplierDocumentReviewMode.Disabled) return 'Disabled';
    return value?.toString();
  }

}
