<div class="card">
    <div class="card-header">
        <h5>{{pageTitle}}</h5>
    </div>
    <obc-descriptor [text]="pageInfo">
    </obc-descriptor>
    <div class="card-body">
        <obc-loader [show]="inProgress"></obc-loader>
        <div class="row">
            <div class="col-md-12 mb-2">
                <div class="form-group row">
                    <div class="col-md-12 col-sm-12">
                        <label class="control-label text-lg-right pt-2 mr-1">
                            Frequent Users Of
                        </label>
                        <obc-switch [trueLabel]="' Company '" [falseLabel]="' Site '" [value]="false"
                            (valueChanged)="onActiveChanged($event)">
                        </obc-switch>
                    </div>
                    <div class="col-md-12 col-sm-12">
                        <div class="row" *ngIf="!isCompanyMode">
                            <div class="col-md-12 mb-2 mt-2">
                                <app-site-selector [required]="true" #sl (siteSelect)="onSiteChange($event); ">
                                </app-site-selector>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-sm-12 text-right">
                        <button class="form-control btn btn-info" style="width: auto;" (click)="toggle()">
                            Add New User
                            <i [ngClass]="{'fa-chevron-up': toggled, 'fa-chevron-down': !toggled }" class="fa pl-2"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 mb-2" *ngIf="toggled">
                <tabset>
                    <tab heading="Add Single Person">
                        <div class="col-12 pt-2">
                            <form [formGroup]="newAttendeeFormGroup"
                                (ngFormSubmit)="addSinglePerson()">
                              <div class="row">
                                <div class="col-12 col-md-4">
                                  <input type="text" formControlName="mobile"
                                         class="form-control mb-2" placeholder="xxxxxxxxxx">
                                </div>
                                <div class="col-12 col-md-4">
                                  <input type="text" formControlName="firstname"
                                         class="form-control mb-2" placeholder="Name">
                                </div>
                                <div class="col-12 col-md-4">
                                  <input type="text" formControlName="lastname"
                                         class="form-control mb-2" placeholder="Family">
                                </div>
                              </div>
                              <div class="row" *ngIf="isTemporayInvite">
                                <div class="col-12 col-md-4">
                                  <obc-input-date-time-picker required
                                                              class="mb-2"
                                                              formControlName="allowCheckInFrom" [placeholder]="'Select The Start Date'"
                                                              [mode]="DatetimePickerMode.Calender">
                                  </obc-input-date-time-picker>
                                </div>
                                <div class="col-12 col-md-4">
                                  <obc-input-date-time-picker required
                                                              class="mb-2"
                                                              formControlName="allowCheckInTo" [placeholder]="'Select The End Date'"
                                                              [minDate]="newAttendeeFormGroup.controls.allowCheckInFrom.value">
                                  </obc-input-date-time-picker>
                                </div>
                                <div class="col-12 col-md-4"></div>
                              </div>
                              <div class="row mt-3">
                                <div class="col-12">
                                  <div class="d-flex justify-content-end">
                                    <button [disabled]="!newAttendeeFormGroup.valid" type="button"
                                            class="btn btn-primary mb-2"
                                            (click)="addSinglePerson()">Add</button>
                                  </div>
                                </div>
                              </div>
                            </form>
                        </div>
                    </tab>
                    <tab heading="Add Multiple People" *ngIf="!isTemporayInvite">
                        <div class="row">
                            <div class="col-md-12 mb-2">
                                <textarea class="form-control" rows="3" [(ngModel)]="commaSeperatedData"
                                    placeholder="xxxxxxxxxx, xxxxxxxxxx, xxxxxxxxxx, ..."></textarea>
                            </div>
                            <div class="col-md-12" *ngIf="commaSeperatedDataError">
                                <p class="text-danger">
                                    {{commaSeperatedDataError}}
                                </p>
                            </div>
                            <div class="col-md-12">
                                <p class="mb-0 button-group">
                                    <button [disabled]="!commaSeperatedData" (click)="addMultiplePeople()"
                                        class="btn btn-primary">Add</button>
                                </p>
                            </div>
                        </div>
                    </tab>
                </tabset>
            </div>
            <div class="col-md-12 mb-2">
                <div [formGroup]="attendeeTable" class="table-responsive">

                    <table class="table table-bordered table-striped mb-0">
                        <thead>
                            <tr>
                                <th>Mobile</th>
                                <th>Name</th>
                                <th>Family</th>
                                <ng-container *ngIf="isTemporayInvite">
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                </ng-container>
                                <th *ngIf="!isCompanyMode">Site</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container formArrayName="tableRows"
                                *ngFor="let group of getFormControls.controls ; let i=index">
                                <tr [class.danger]="!group.valid" *ngIf="group.get('isEditable').value"
                                    [formGroupName]="i">
                                    <td>
                                        {{group.get('mobile').value | mobile}}
                                    </td>
                                    <td>
                                        <input formControlName="firstname" class="form-control" placeholder="Name"
                                            type="text">
                                    </td>
                                    <td>
                                        <input formControlName="lastname" class="form-control" placeholder="Family"
                                            type="text">
                                    </td>
                                    <ng-container *ngIf="isTemporayInvite">
                                        <td>
                                            <obc-input-date-time-picker required formControlName="allowCheckInFrom"
                                                [placeholder]="'Date Time'">
                                            </obc-input-date-time-picker>
                                        </td>
                                        <td>
                                            <obc-input-date-time-picker required formControlName="allowCheckInTo"
                                                [placeholder]="'Date Time'"
                                                [minDate]="getAllowCheckInFrom(group).value">
                                            </obc-input-date-time-picker>
                                        </td>
                                    </ng-container>
                                    <td *ngIf="!isCompanyMode">
                                        <span>
                                            {{getSiteName(group.get("siteId").value)}}
                                        </span>
                                    </td>
                                    <td>
                                        <p class="button-group">
                                            <button [disabled]="!group.valid" class="btn btn-sm btn-info"
                                                (click)="doneRow(group)">
                                                Update
                                            </button>
                                        </p>
                                    </td>
                                </tr>

                                <tr [class.danger]="!group.valid" *ngIf="!group.get('isEditable').value"
                                    [formGroupName]="i" (click)="editRow(group)">
                                    <td>
                                        {{group.get('mobile').value | mobile}}
                                    </td>
                                    <td>
                                        {{group.get('firstname').value}}
                                    </td>
                                    <td>
                                        {{group.get('lastname').value}}
                                    </td>
                                    <ng-container *ngIf="isTemporayInvite">
                                        <td>
                                            <span>
                                                {{group.get("allowCheckInFrom").value | datetime}}
                                            </span>
                                        </td>
                                        <td>
                                            <span>
                                                {{group.get("allowCheckInTo").value | datetime}}
                                            </span>
                                        </td>
                                    </ng-container>
                                    <td *ngIf="!isCompanyMode">
                                        <span>
                                            {{selectedSite.name}}
                                        </span>
                                    </td>
                                    <td>
                                        <p class="button-group">
                                            <button *ngIf="!group.get('sealed').value"
                                                class="btn btn-sm btn-primary no-min-width" (click)="editRow(group)">
                                                <i class="fas fa-user-edit"></i>
                                            </button>
                                            <button class="btn btn-sm btn-danger no-min-width"
                                                (click)="deleteRow($event, i)">
                                                <i class="fas fa-user-times"></i>
                                            </button>
                                        </p>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="nothingFound">
                                <td colspan="7" style="text-align: center;">
                                    <span>
                                        Nothing Found
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
