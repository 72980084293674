<ng-container *ngIf="showByModal">
  <button *ngIf="!previewImage || (previewImage && previewImage == '')" type="button"
    (click)="onOpenFileViewer(fileViewer, prefix)" [style]="btnStyle + 'position: relative; z-index: 1'"
    class="{{ btnClass }} d-inline-flex align-items-center {{this.btnClass.indexOf('dropdown-item')>-1 ? '': 'justify-content-center'}} ">
    <i *ngIf="btnIcon" class="{{ btnIcon }} {{ btnTitle ? 'mr-1' : '' }}"></i>
    <span>{{ btnTitle }}</span>
  </button>

  <img width="{{ previewImageWidth }}" height="{{ previewImageHeight }}" style="cursor: pointer" class="{{ imgClass }}"
    (click)="onOpenFileViewer(fileViewer, prefix)" *ngIf="previewImage && previewImage != ''" src="{{ previewImage }}"
    alt="Preview" />
</ng-container>

<ng-container *ngIf="!showByModal">
  <ng-container *ngTemplateOutlet="fileListTemplate">

  </ng-container>
</ng-container>

<ng-template #fileViewer>
  <div class="card">
    <div class="card-header">
      <h4>View Files</h4>
      <div class="card-actions">
        <a class="card-action card-action-dismiss" (click)="closeModal()"></a>
      </div>
    </div>
    <div class="card-body">
      <ng-container *ngTemplateOutlet="fileListTemplate">
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #fileListTemplate>

  <accordion>
    <ng-container *ngFor="let file of files; let index = index">
      <div class="{{ file.fileType == FileType.Pdf ? 'pdf-accordion' : '' }}">
        <accordion-group #accordion [isDisabled]="!fileHasContent(file)" [isOpen]="index === 0 && fileHasContent(file)">
          <button (click)="onFileOpened(file.fileUrl)" *ngIf="fileHasContent(file)"
            class="text-decoration-none btn btn-link btn-block clearfix d-flex align-items-center justify-content-start"
            accordion-heading type="button" style="padding: 0;">
            <i *ngIf="file.fileType == FileType.Image" class="fa fa-image text-info mr-2" style="font-size: 22px"></i>
            <i *ngIf="file.fileType == FileType.Pdf" class="fa fa-file-pdf text-info mr-2" style="font-size: 22px"></i>
            <i *ngIf="file.fileType == FileType.ExternalLink" class="fa fa-file text-info mr-2"
              style="font-size: 22px"></i>
            {{ getFilename(index) }}
          </button>
          <a (click)="onFileOpened(file.fileUrl)" *ngIf="!fileHasContent(file)" target="_blank"
            href="{{ file.fileUrl }}"
            class="text-decoration-none btn btn-link btn-block clearfix d-flex align-items-center justify-content-center"
            accordion-heading type="button">
            <i *ngIf="file.fileType == FileType.Youtube" class="fab fa-youtube text-danger mr-2"
              style="font-size: 22px"></i>
            <i *ngIf="file.fileType == FileType.Audio" class="fa fa-headphones text-danger mr-2"
              style="font-size: 22px"></i>
            <i *ngIf="file.fileType == FileType.Video" class="fa fa-film text-danger mr-2" style="font-size: 22px"></i>
            <i *ngIf="file.fileType == FileType.ExternalLink" class="fa fa-link text-danger mr-2"
              style="font-size: 22px"></i>
            {{ getFilename(index) }}
          </a>
          <img class="fw-image {{ imgClass }}" [src]="file.fileUrl | safe: 'resourceUrl'"
            *ngIf="file.fileType == FileType.Image && accordion.isOpen" alt="image" />
          <div class="pdf-viewer" style="position: relative;" *ngIf="file.fileType == FileType.Pdf && accordion.isOpen">
            <obc-pdf-viewer [src]="file.fileUrl"></obc-pdf-viewer>
          </div>
          <div style="position: relative"
            *ngIf="(file.fileType == FileType.Binary || file.fileType == FileType.ExternalLink) && accordion.isOpen">
            <a target="_blank" href="{{ file.fileUrl }}" style="position: absolute; top: 0; left: 0; z-index: 1"
              class="btn btn-primary"><i class="fas fa-download"></i></a>
            <iframe [src]="googleDriveUrl(file.fileUrl) | safe: 'resourceUrl'" style="width:100%;height:50vh;"
              frameborder="0"></iframe>
          </div>
        </accordion-group>
      </div>
    </ng-container>
  </accordion>
</ng-template>