<div class="alert alert-info align-items-center">
  <div class="row">
    <div class="col-4 d-flex align-items-center">
      <div *ngIf="permitSchedule?.dateTime">
        {{ permitSchedule?.dateTime | date }}
      </div>
      <div *ngIf="permitSchedule?.weekDay">
        {{ permitSchedule?.weekDay | numberToWeekday }}
      </div>
    </div>
    <div class="col-4 d-flex align-items-center">
      <div *ngIf="permitSchedule?.fromHour && permitSchedule?.toHour">
        <i class="fas fa-history"></i>
        From: {{ permitSchedule?.fromHour }} - To: {{ permitSchedule?.toHour }}
      </div>
    </div>
    <div class="col-4 d-flex align-items-center justify-content-end">
      <button class="btn btn-danger" tooltip="Remove" (click)="onRemove()">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
</div>
