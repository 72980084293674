import { Pipe, PipeTransform } from '@angular/core';
import { PermitClosurePolicy } from '../enums/permit-closure-policy.enum';

@Pipe({
  name: 'permitClosurePolicy'
})

export class PermitClosurePolicyPipe implements PipeTransform {
  transform(value: unknown): any {
    if (value === PermitClosurePolicy.OnlyIfOnHold) { return "Must be On-Hold to Close"; }
    if (value === PermitClosurePolicy.OnHoldOrOpen) { return "Can Close while Open or On-Hold"; }
    return value;
  }
}
