import { Component, OnInit } from '@angular/core';
import { SiteAttendanceBlockedUserService } from 'src/app/services/site-attendance-blocked-user.service';
import { finalize } from 'rxjs/operators';
import { SiteAttendanceBlockedUserViewModel } from 'src/app/models/site-attendance-blocked-user-view-model';
import { SiteBasicInfo } from 'src/app/models/site-basicinfo';
import { UserAutoCompleteFilterByFields } from 'src/app/enums/user-auto-complete-target-fields';
import { SiteService } from 'src/app/services/site.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-site-attendance-blocked-user',
  templateUrl: './site-attendance-blocked-user.component.html',
  styleUrls: ['./site-attendance-blocked-user.component.scss']
})
export class SiteAttendanceBlockedUserComponent implements OnInit {

  inProgress: boolean = false;
  addNewMode: boolean = false;
  selectedSite: SiteBasicInfo;
  filterUserBy = UserAutoCompleteFilterByFields;
  blockedUsers: SiteAttendanceBlockedUserViewModel[] = [];
  blockedUser: SiteAttendanceBlockedUserViewModel = new SiteAttendanceBlockedUserViewModel();
  inEdit = {};
  filterListTerm: string;
  constructor(private siteAttendanceBlockedUserService: SiteAttendanceBlockedUserService,
    private siteService: SiteService,
    private modalService: ModalService) { }

  ngOnInit(): void {
    this.getBlockeUserList();
  }

  getBlockeUserList() {
    this.inProgress = true;
    this.siteAttendanceBlockedUserService.getBlockeUsers()
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(result => {
        this.blockedUsers = result;
        this.cancel();
      }, error => { console.log(error); });
  }

  initNew() {
    this.cancel();
    this.addNewMode = true;
    this.blockedUser = new SiteAttendanceBlockedUserViewModel();
  }

  initEdit(user) {
    this.blockedUsers.map(c => c.inEdit = false);
    user.inEdit = true;
    this.blockedUser = user;

  }

  addUpdateBlockedUser() {
    if (!this.isValidate()) return;
    this.inProgress = true;
    if (this.addNewMode) {
      this.siteAttendanceBlockedUserService.add(this.blockedUser)
        .pipe(finalize(() => this.inProgress = false))
        .subscribe(result => this.getBlockeUserList(),
          error => {
            this.modalService.error(error)
          });
    }
    // else if (this.blockedUser.id) {
    //   this.siteAttendanceBlockedUserService.update(this.blockedUser)
    //     .pipe(finalize(() => this.inProgress = false))
    //     .subscribe(result => this.getBlockeUserList(),
    //       error => { console.log(error) });

    // }
  }

  delete(user) {
    this.modalService.confirm("Are you sure? ", "Delete ").subscribe(res => {
      if (res) {
        this.inProgress = true;
        this.siteAttendanceBlockedUserService.delete(user.id)
          .pipe(finalize(() => this.inProgress = false))
          .subscribe(result => this.getBlockeUserList(),
            error => { console.log(error) });
      }
    })
  }

  isValidate() {
    if (!this.blockedUser.siteId)
      return false;

    if (!this.blockedUser.userId)
      return false;

    return true;
  }

  cancel() {
    this.addNewMode = false;
    this.blockedUsers.map(c => c.inEdit = false);
  }
}
