import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UserDocumentType } from "../../models/user-document-type";
import { UserDocumentTypeService } from "../../services/user-document-type.service";
import { finalize } from "rxjs/operators";
import { OrderedEntity } from "../../models/ordered-entity";

@Component({
  selector: 'obc-document-type-viewer',
  templateUrl: './document-type-viewer.component.html',
  styleUrls: ['./document-type-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocumentTypeViewerComponent implements OnInit {

  @Input() documents: UserDocumentType[] = [];
  inProgress: boolean = false;
  @Input() canDelete: boolean = true;
  @Input() canAdd: boolean = true;
  @Input() canUpdate: boolean = true;
  @Input() draggable: boolean = false;
  @Input() cacheUniqueId: string = '';
  @Output() orderedList: EventEmitter<OrderedEntity[]> = new EventEmitter<OrderedEntity[]>();

  constructor(private userDocumentTypeService: UserDocumentTypeService) { }
  ngOnInit(): void {
    if(this.documents?.length == 0) {
      this.inProgress = true;
      this.userDocumentTypeService.GetAllCompaniesUserDocumentTypes()
        .pipe(finalize(()=> this.inProgress = false))
        .subscribe((res) => {
          this.documents = res;
        });
    }
  }

  get rootDocuments() {
    return this.documents?.filter((item) => !item.parentId);
  }

  onOrderedList(orderedList: OrderedEntity[]) {
    this.orderedList.emit(orderedList);
  }
}
