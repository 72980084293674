<div *ngIf="formGroup" [formGroup]="formGroup" class="row">
  <div class="form-group col-md-12">
    <label>Email Subject</label>
    <input class="form-control" formControlName="subject" placeholder="Email subject">
  </div>
  <div class="col-md-12">
    <label><input type="checkbox" class="" formControlName="sendEmailToCompanySupervisor">
      Send Email To Company Supervisor</label>
  </div>
  <div class="col-md-12" *ngIf="showSendEmailToSiteSupervisor">
    <label><input type="checkbox" class="" formControlName="sendEmailToSiteSupervisor">
      Send Email To Site Supervisor</label>
  </div>
  <div class="form-group col-md-12">
    <label>Recipients</label>
    <obc-array-form-control splitter="," placeholder="user1@domain.com, user2@domain.com, ..."
      formControlName="recipients">
    </obc-array-form-control>

  </div>
  <div
    *ngIf="!formGroup.controls.recipients.valid ||(formGroup.errors?.emailIsRequired && (formGroup.touched || formGroup.dirty))"
    class="col-md-12">
    <div class="cross-validation-error-message alert alert-danger">
      Select one of supervisor email addresses or enter recipients email address.
    </div>
  </div>
</div>