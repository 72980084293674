import { Pipe, PipeTransform } from '@angular/core';
import { QuestionProfileSubTypeEnum } from '../enums/question-profile-sub-type-enum';

@Pipe({
  name: 'questionProfileSubTypePipe'
})
export class QuestionProfileSubTypePipe implements PipeTransform {

  transform(value: any): string {
    if (value == QuestionProfileSubTypeEnum.DOB || value == 0 || value == "DOB") return "Date of Birth";
    if (value == QuestionProfileSubTypeEnum.ProfilePicture || value == 1 || value == "ProfilePicture") return "Profile Picture";
    if (value == QuestionProfileSubTypeEnum.Email || value == 2 || value == "Email") return "Email";
    if (value == QuestionProfileSubTypeEnum.Gender || value == 3 || value == "Gender") return "Gender";
    if (value == QuestionProfileSubTypeEnum.EmergencyContact || value == 4 || value == "EmergencyContact") return "Emergency Contact";
    return value?.toString() ?? "";
  }

}
