import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { ToolStatusModel, ToolStatusViewModel } from 'src/app/models/inventory/tool-status-model';
import { ManageInventoryService } from 'src/app/services/manage-inventory.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-manage-tool-statuses',
  templateUrl: './manage-tool-statuses.component.html',
  styleUrls: ['./manage-tool-statuses.component.scss']
})
export class ManageToolStatusesComponent implements OnInit {
  formGroup = new FormGroup({
    title: new FormControl(null, [Validators.required])
  })
  toolStatuses: ToolStatusViewModel[] = [];
  constructor(private manageInventoryService: ManageInventoryService,
     private modalService: ModalService,
     private bsModalService : BsModalService) { }
  inProgress = false;
  ngOnInit() {
    this.refresh();
  }

  onAdd() {
    this.inProgress = true;
    var model = {
      title: this.formGroup.controls.title.value,
    } as ToolStatusModel;
    this.manageInventoryService.addToolStatus(model)
      .pipe(finalize(() => { this.inProgress = false }))
      .subscribe(res => {
        this.toolStatuses.push(res);
        this.formGroup.reset();
      }, err => {
        this.modalService.error(err)
      })
  }

  onRemove(id: number) {
    this.modalService.confirm("Are you sure you want to remove this tool status?", "Remove Tool Status").subscribe(res => {
      if (res) {
        this.inProgress = true;
        this.manageInventoryService.removeToolStatus(id)
          .pipe(finalize(() => { this.inProgress = false }))
          .subscribe(res => {
            var loc = this.toolStatuses.find(x=> x.id == id);
            var index = this.toolStatuses.indexOf(loc) ;
            this.toolStatuses.splice(index, 1);
          }, err => {
            this.modalService.error(err)
          })
      }
    })

  }

  currentStatus : ToolStatusViewModel = null;
  statusTriggersModalRef : any = null;
  onShowTriggers(status : ToolStatusViewModel , template : any) {
    this.currentStatus = status;
    this.statusTriggersModalRef = this.bsModalService.show(template, {
      class:"modal-lg",
      ignoreBackdropClick : true,
    })
  }

  onCloseStatusTriggers() {
    this.statusTriggersModalRef.hide();
    this.refresh();
  }

  refresh() {
    this.inProgress = true;
    this.manageInventoryService.getToolStatuses()
      .pipe(finalize(() => { this.inProgress = false }))
      .subscribe(res => {
        this.toolStatuses = res;
      }, err => {
        this.modalService.error(err.error)
      })
  }
}
