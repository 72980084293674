<form name="siteformLocation"
      (ngSubmit)="siteForm.form.valid && updateLocation()"
      #siteForm="ngForm"
      class="form-horizontal form-bordered"
      autocomplete="off">
  <section class="card">
    <header class="card-header">
      <div class="card-actions">
        <a (click)="cancel()"
           class="card-action card-action-dismiss"
           data-card-dismiss=""></a>
      </div>
      <h2 class="card-title">Site location verification</h2>
    </header>
    <div class="card-body">
      <obc-loader [show]="inProgress"></obc-loader>
      <div class="form-group row pb-2 mb-1">
        <label class="col-sm-4 control-label text-left pt-2">Address:</label>
        <div class="col-sm-8">
          <div class="input-group mb-3">
            <input required
                   name="Address"
                   [(ngModel)]="site.address"
                   [typeahead]="suggestions$"
                   [isAnimated]="true"
                   [adaptivePosition]="true"
                   [typeaheadAsync]="true"
                   [typeaheadItemTemplate]="customItemTemplate"
                   [typeaheadOptionsInScrollableView]="6"
                   [typeaheadOrderBy]="sortConfig"
                   (typeaheadOnSelect)="onSelectAddress($event)"
                   [typeaheadMinLength]="2"
                   class="form-control" />
            <div class="input-group-prepend" *ngIf="loader">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <ng-template #customItemTemplate let-model="item">
          <span>
            <strong>{{ model.primaryTitle }}</strong> -
            {{ model.secondaryTitle }} -
            <small>{{ model.description }}</small>
          </span>
        </ng-template>
      </div>
      <div class="form-group row pb-0 mb-0">
        <div class="p-2 w-100">
          <div class="col-6 pull-left">
            <div class="input-group mb-3">
              <span class="input-group-text">Latitude</span>
              <input type="number"
                     class="form-control"
                     name="latitude"
                     [(ngModel)]="latitude"
                     placeholder="Latitude"
                     required />
            </div>
          </div>
          <div class="col-6 pull-left">
            <div class="input-group mb-3">
              <span class="input-group-text">Longitude</span>
              <input type="number"
                     class="form-control"
                     name="longitude"
                     [(ngModel)]="longitude"
                     placeholder="Longitude"
                     required />
            </div>
          </div>

          <obc-map [markerTitle]="site.name"
                   [siteRadius]="site.siteRadius"
                   [location]="{ lat: latitude, lng: longitude }"
                   (locationChange)="latitude = $event.lat; longitude = $event.lng">
          </obc-map>
        </div>
      </div>
    </div>
    <footer class="card-footer row">
      <div class="col-auto text-left">
        <span class="text-danger" *ngIf="errorMessage && errorMessage != ''">
          {{
          errorMessage
          }}
        </span>
      </div>
      <div class="col text-right">
        <button class="btn btn-success mr-2" [disabled]="!siteForm.form.valid" type="submit">Verify Site Location</button>
        <button type="reset" class="btn btn-primary" (click)="cancel()">
          Cancel
        </button>
      </div>
    </footer>
  </section>
</form>
