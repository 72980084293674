import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'questionDefaultMode'
})
export class QuestionDefaultModePipe implements PipeTransform {

  transform(value: unknown): any {
    if (value === 0) { return "Disabled"; }
    if (value === 1) { return "Check In"; }
    if (value === 2) { return "Check Out"; }
    if (value === 3) { return "Check In / Check Out"; }
    return "N/A";
  }

}
