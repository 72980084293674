import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropzoneSourceType } from 'src/app/enums/dropzone-source-type';
import { FileType } from 'src/app/enums/file-type.enum';
import {
  detectFileType,
  fileSize,
  getFileInputTypeByMimeType,
  getFileNameFromUrl,
  getFileTypeFromExtension
} from "../../../../helpers/general-functions";
import { UploadFileInfo } from "../../../../models/requiredDocumentAnswer";
import { SafePipe } from "../../../../pipes/safe-pipe";

@Component({
  selector: 'obc-dropzone-file',
  templateUrl: './dropzone-file.component.html',
  styleUrls: ['./dropzone-file.component.scss']
})
export class DropzoneFileComponent implements OnInit {


  DropzoneSourceType = DropzoneSourceType;
  @Input() source: any;
  @Input() sourceType: DropzoneSourceType;
  @Output() removeItem: EventEmitter<any> = new EventEmitter<any>();

  constructor(private safePipe: SafePipe,) { }

  ngOnInit(): void {
  }

  displayFile(source, type: DropzoneSourceType) {
    if(type == this.DropzoneSourceType.Base64) {
      return 'data:image/' + source.fileExtension.toLowerCase() + ';base64,'+source.fileAsBase64;
    } else if(type == this.DropzoneSourceType.File) {
      return this.safePipe.transform(URL.createObjectURL(source), 'url');
    } else {
      return source;
    }
  }

  FileType = FileType;

  getFileTypeAny(source: UploadFileInfo|string|any, type: DropzoneSourceType) {
    if(type == this.DropzoneSourceType.Base64) { // UploadFileInfo
      return getFileTypeFromExtension(source.fileExtension);
    } else if(type == this.DropzoneSourceType.File) { // file
      return getFileInputTypeByMimeType(source.type);
    } else { // string
      return detectFileType(source);
    }
  }

  getNameFromUrl(url) {
    return getFileNameFromUrl(url);
  }
  getReadableSize(size) {
    return fileSize(size);
  }
}
