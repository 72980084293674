<div class="row">
  <div *ngIf="siteSupplierId" class="col-12 col-lg-8 mb-1">
    <button class="btn btn-default" [routerLink]="['/'+referer]">
      <i class="fas fa-arrow-left"></i>
      Return to List
    </button>
  </div>
  <div class="col-md-12 mb-3">
    <section class="card">
      <div class="card-header">
        <h5 style="line-height: 32px;">
          View/Update Document Version, Permits and Assets
        </h5>
      </div>
      <div class="card-body">
        <ng-container *ngIf="validateResult?.siteSupplierId">
          <div class="row">
            <div class="col-md-6 com-sm-12">
              <h4 class="text-center">Site: {{validateResult?.siteName}}</h4>
            </div>
            <div class="col-md-6 com-sm-12">
              <h4 class="text-center">Supplier: {{validateResult?.supplierName}}</h4>
            </div>
          </div>
        </ng-container>
        <tabset *ngIf="validateResult">
          <tab heading="Supplier Documents" [active]="selectedTab.value == 'documents'"
            (selectTab)="this.selectedTab.next('documents')">            
            <app-view-site-supplier-document-version *ngIf="validateResult?.siteSupplierId || hashlink"
              [selectedSuplierId]="supplierId" [siteSupplierHashlink]="hashlink"
              [selectedSiteSupplierId]="siteSupplierId" [selectedSiteId]="siteId" [isArchiveTab]="false"
              [accessMode]="validateResult?.accessMode" [mainCompanyId]="validateResult?.mainCompanyId">
            </app-view-site-supplier-document-version>
          </tab>
          <tab heading="Permits" [active]="selectedTab.value == 'permits'" (selectTab)="selectedTab.next('permits')">
            <obc-anonymous-permit [siteId]="validateResult?.siteId" [siteSupplierHashLink]="hashlink"
              [siteSupplierId]="validateResult?.siteSupplierId">
            </obc-anonymous-permit>
          </tab>
          <tab heading="Site Assets/Plant" [active]="selectedTab.value == 'assets'"
            (selectTab)="selectedTab.next('assets')">
            <obc-manage-site-asset [siteSupplierId]="validateResult?.siteSupplierId" [supplierId]="supplierId"
              [siteId]="siteId" [siteName]="validateResult?.siteName" [mainCompanyId]="validateResult?.mainCompanyId"></obc-manage-site-asset>
          </tab>
          <tab inde *ngIf="showDocumentsArchiveTab" heading="Supplier Document Archive"
            [active]="selectedTab.value == 'documentsArchive'" (selectTab)="this.selectedTab.next('documentsArchive')">
            <app-view-site-supplier-document-version *ngIf="validateResult?.siteSupplierId || hashlink"
              [selectedSuplierId]="supplierId" [siteSupplierHashlink]="hashlink"
              [selectedSiteSupplierId]="siteSupplierId" [selectedSiteId]="siteId" [isArchiveTab]="true"
              [accessMode]="validateResult?.accessMode" [mainCompanyId]="validateResult?.mainCompanyId">
            </app-view-site-supplier-document-version>
          </tab>
        </tabset>
      </div>
    </section>
  </div>
</div>
