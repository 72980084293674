<div *ngFor="let item of items; let index = index">
  <div class="mt-2" *ngIf="item.question && item.question?.document == null">
    <obc-single-question-renderer [isEditMode]="isEditMode" [question]="item" [tagged]="index"
      (onValueChanges)="onValueChanges()">
    </obc-single-question-renderer>
  </div>

  <div class="mt-2" *ngIf="item.isAnnouncementGroup">
    <div class="card">
      <div class="card-header">
        <span>{{item.groupName == null ? "Announcement" : item.groupName}}</span>
      </div>
      <div class="card-body" *ngIf="announcements && announcements.length > 0">
        <ng-container *ngFor="let ann of item.announcements">
          <div class="mt-2">
            <obc-announcement-item-renderer [validateComponent]="true" [largeButton]="largeButton" [ann]="ann"
              [notifyVisits]="notifyVisits" [anonymousAttendanceKey]="anonymousAttendanceKey"
              (onAnnouncementVisit)="callOnAnnouncementVisitEvent($event)">
            </obc-announcement-item-renderer>
          </div>
        </ng-container>
      </div>
      <div class="card-body" *ngIf="checkinAnnouncements && checkinAnnouncements.length > 0">
        <ng-container *ngFor="let ann of item.announcements">
          <div class="mt-2">
            <obc-checkin-announcement-renderer [validateComponent]="true" [largeButton]="largeButton" [ann]="ann"
              [notifyVisits]="false" [anonymousAttendanceKey]="anonymousAttendanceKey" [validateComponent]="true"
              (onAnnouncementVisit)="callOnAnnouncementVisitEvent($event)"
              (onCheckedMandatorySignOff)="callOnAnnouncementCheckedMandatorySignOffEvent($event)">
            </obc-checkin-announcement-renderer>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="item.item || (item.question?.documentFormControl != null && item.showFlagLastState)">
    <div class="my-2">
      <obc-required-document-renderer (removeDocumentType)="onRemoveAdditionalDocumentType($event)"
        [control]="item.item ? item : item.question?.documentFormControl"></obc-required-document-renderer>
    </div>
  </div>

  <div *ngIf="item?.allowUsersToAttachAdditionalDocuments" class="col-12">
    <div *ngIf="availableDocumentTypes.length > 0" class="mt-2 mb-4">
      <button class="btn btn-info" (click)="onClickAdditionalDocumentButton(selectUserDocumentType)">
        <i class="fa fa-upload mr-1"></i>
        Add Additional Document
      </button>
    </div>
  </div>

</div>



<ng-template #selectUserDocumentType>
  <div class="card">
    <div class="card-header">
      Additional Document
    </div>
    <div class="card-body">
      <div *ngIf="availableDocumentTypes.length > 0" class="d-flex flex-column">
        <label>Select a document to add</label>
        <select class="form-control" [(ngModel)]="selectedDocumentTypeId" [disabled]="allUserDocumentTypes.length == 0">
          <option *ngFor="let documentType of availableDocumentTypes" value="{{ documentType.id }}">{{
            documentType.title}}</option>
        </select>
        <button type="button" class="mt-2 btn btn-success ml-auto" [disabled]="!selectedDocumentTypeId"
          (click)="onAddAdditionalDocumentToDocuments()">
          <i class="fa fa-plus"></i>
          Add
        </button>
      </div>
      <div *ngIf="availableDocumentTypes?.length === 0" class="alert alert-info">
        There is no document type available to add
      </div>
    </div>
  </div>
</ng-template>