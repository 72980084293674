<div>
  <ng-select style="padding-bottom: 0 !important;" class="region-selector"
             [typeaheadOptionsLimit]="7"
             [typeaheadMinLength]="0"
             [placeholder]="'Select Document Type'"
             [multiple]="isMultiple"
             [typeahead]="term$ | async"
             [loading]="loading"
             (change)="onAdd($event)"
             (add)="onAdd($event)"
             (remove)="onRemove($event)"
             [clearable]="false"
             [formControl]="completelyProcess ? formControl : null">
      <ng-option *ngFor="let item of allRequiredUserDocuments" [value]="item?.id">{{ item?.title ?? nullValueCaption }}</ng-option>
      <ng-option *ngFor="let item of globalScopeSelectedItems" [value]="item?.id">{{ item?.title }}</ng-option>
  </ng-select>
</div>
