<div class="card-body">
    <form name="messageForm" (ngSubmit)="messageForm.form.valid && sendMessage()" #messageForm="ngForm">
        <div class="form-row">
            <div class="form-group col-12">
                <div class="row" *ngIf="isCompanyAdminOrGod">
                    <div class="col-md-12 mb-2">
                        <obc-switch [trueLabel]="' All Sites '" [falseLabel]="' Select Site '" [value]="isAllSiteMode"
                            (valueChanged)="onAllSiteModeSelected($event)">
                        </obc-switch>
                    </div>
                </div>
                <div *ngIf="!isAllSiteMode">
                    <app-site-selector [permission]="UserPermission.SendEmergencyAlarm"
                        (siteSelect)="changeSite($event)" [notSelectedSiteLabel]="'Select Site'">
                    </app-site-selector>
                </div>
            </div>
        </div>
        <div class="alert alert-success" *ngIf="totalCount || totalCount == 0 && selectedSite != null">
            <strong class="text-primary">{{totalCount}}</strong> people are checked in {{selectedSite?.name}} now.
        </div>
        <div class="form-group pt-2" *ngIf="totalCount || selectedSite == null">
            <label for="inputAddress">Message</label>
            <input type="text" class="form-control" id="message" name="message" [(ngModel)]="message"
                placeholder="Enter your message to broadcast" required>
            <span class="text-primary small">{{resultMessage}}</span>
        </div>
    </form>
</div>
<footer class="card-footer1">
    <div class="row">
        <div class="col-md-12 text-right">
            <button class="btn btn-primary modal-confirm" (click)="sendMessage()"
                *ngIf="(totalCount || isAllSiteMode) && message">Send Message</button>
            <button class="btn btn-default modal-dismiss" (click)="closeModal()">Cancel</button>
        </div>
    </div>
</footer>
<!-- </section> -->