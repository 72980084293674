<div class="datetime-popover" #self style="position: relative; width: 100%">
  <input class="form-control"
         #pop="bs-popover"
         [outsideClick]="false"
         [adaptivePosition]="true"
         [placement]="'auto'"
         [popover]="template"
         [formControl]="inputFormControl"
         [placeholder]="placeholder"
         popoverTitle="Date/Time Picker"
         container="body" containerClass="hide-title">
  <div #popDivTemplate style="position: absolute; right:10px; top:12px">
    <div class="d-flex flex-row align-items-center justify-content-between">
      <i class="pointer fa fa-times mr-2"
         *ngIf="this.inputFormControl?.value"
         (click)="this.inputFormControl.setValue(null)"></i>
      <i class="pointer fas fa-calendar-alt" (click)="pop.show()">
        <ng-template #template>
          <obc-date-time-picker
            [minDate]="minDate"
            [maxDate]="maxDate"
            (select)="onSelectDateTime($event)" #datepickerRef (self)="datepickerComponent = $event" [mode]="mode"
            (valueChange)="datetimePickerValueChange($event)"
            [formControl]="datetimePickerformControl"></obc-date-time-picker>
        </ng-template>
      </i>
    </div>
  </div>
</div>
