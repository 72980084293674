import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { FormType } from 'src/app/enums/form-type';
import { PopoverFilterSimpleComponent } from '../popover-filter-simple.component';
import { FilterSettings } from 'src/app/models/grid/grid.config';

@Component({
  selector: 'obc-filter-header',
  templateUrl: './filter-header.component.html',
  styleUrls: ['./filter-header.component.scss']
})
export class FilterHeaderComponent implements OnInit {
  @Input() outsideClick: Boolean = false;
  @Input() placement: string = "auto";
  @Input() items: any[];
  @Input() filterType: FilterType;
  @Input() popoverTitle: string;
  @Input() addButtonLabel: string = 'Add To Filter(s)';
  @Input() loadForExternalCompany: boolean = false;
  @Input() includeExternalCompanySites: boolean = false;
  @Input() inventoryLocationSelectorCanAddNewLocation: boolean;
  @Input() fromDate: any | undefined = null;
  @Input() toDate: Date | undefined = null;
  @Input() formType: FormType;
  @Input() filterSettings?: FilterSettings;

  @Output() update = new EventEmitter();
  @ViewChild('popDivTemplate') popDivTemplate: any;
  @ViewChild('pop') pop;
  popoverFilter: PopoverFilterSimpleComponent;

  constructor() {
  }

  ngOnInit() {
    document.addEventListener('mousedown', this.closePopoverOnClickOutside.bind(this));
  }

  onUpdate($event) {
    this.update.emit($event);
  }

  findAncestor(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
  }

  closePopoverOnClickOutside(event: any) {
    let bool = this.popDivTemplate.nativeElement!.contains(event.target);
    if (this.popoverFilter) {
      if (this.popoverFilter?.refUserAutocomplete?.nativeElement)
        bool = bool || this.popoverFilter.refUserAutocomplete.nativeElement!.contains(event.target);
      let datePickerElements = document.querySelector("obc-date-time-picker");
      bool = bool || datePickerElements;
    }
    if (!bool) {
      this.pop.hide();
    }
  }
}
