<div class="row">
  <div class="col-12">
    <div class="d-flex flex-row flex-wrap">
      <ng-container *ngFor="let filter of filters">
        <div *ngIf="filter?.data?.length" class="filter-card mb-1">
          <div><b class="mr-1 mb-3">{{filter.name}}:</b></div>
          <div class="d-flex flex-row">
            <div *ngFor="let item of getFilterData(filter)" class="mr-2 ml-2">
              <span [innerHTML]="getFilterText(item,filter.type, filter) | async"></span>
              <span class="closable-button fa fa-times font-danger pointer"
                (click)="onRemoveFromFilter(item, getFilterData(filter))"></span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>    
  </div>
</div>