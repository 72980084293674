import { InventoryService } from './../../services/inventory.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FormControl, Validators } from '@angular/forms';
import { ToolCategoryViewModel } from 'src/app/models/inventory/tool-category-model';

@Component({
  selector: 'obc-filter-asset-category',
  templateUrl: './filter-asset-category.component.html',
  styleUrls: ['./filter-asset-category.component.scss']
})
export class FilterAssetCategoryComponent implements OnInit {

  @ViewChild('selector') selector: NgSelectComponent;

  @Input() excludeList: number[] = [];

  _required = false;
  @Input() siteId;
  @Input() set control(value: FormControl) {
    this.formControl = value;
    this.formControl.valueChanges.subscribe(res => {
      this.selectAssetCategory.emit(this.assetCategories?.find(x => x.id == res));
    })
  };
  formControl: FormControl;
  loading: boolean = false;
  $assetCategories: Observable<ToolCategoryViewModel[]>;
  assetCategories: ToolCategoryViewModel[];
  term$ = new BehaviorSubject<string>(null);
  @Input() sessionId = null;
  @Output() selectAssetCategory = new EventEmitter();
  @Input() set required(value: boolean) {
    if (value) {
      this._required = true;
      this.formControl?.setValidators(Validators.required);
    }
  };

  clearSelector() {
    this.selector.handleClearClick();
  }

  constructor(private inventoryService: InventoryService) { }

  ngOnInit() {
    var method = this.inventoryService.getToolCategories();
    // var method = this.sessionId ?
    //   this.companySupplierService.getSuppliersBySession(this.sessionId, { term: null } as CompanySupplierQueryTerm, null)
    //   : this.companySupplierService.getCachedSuppliers({ siteId: this.siteId, term: null } as CompanySupplierQuery, null);

    method.subscribe(res => {
      let that = this;
      this.assetCategories = res.data.filter((el) => {
        return that.excludeList == null ||
        that.excludeList.length == 0 ||
        !that.excludeList.some((selected_el) => {
          return selected_el == el.id;
        });
      });
      //this.$suppliers = of(this.suppliers);
      this.term$.subscribe(term => {
        if (term) {
          var filteredSites = this.assetCategories.filter((x) => {
            return x.title?.toLocaleLowerCase()?.indexOf(term) > -1;
          });
          this.$assetCategories = of(filteredSites);
        } else {
          this.$assetCategories = of(this.assetCategories);
        }
      });
    })
  }

}
