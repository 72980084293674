<div class="input-group">
  <input autocomplete="off" name="userBusiness" [required]="required" [(ngModel)]="searchTerm" [typeahead]="suggestions$"
    [isAnimated]="true" [adaptivePosition]="true" [typeaheadAsync]="true" [typeaheadItemTemplate]="customItemTemplate"
    [typeaheadOptionsInScrollableView]="6" (typeaheadOnSelect)="onSelectBusiness($event)" [typeaheadMinLength]="2"
    (typeaheadNoResults)="typeaheadNoResults($event)" container="body" (ngModelChange)="onChange($event)"
    [class.ng-invalid]="!isControlValid"
    [class.ng-valid]="isControlValid"
    [placeholder]="placeholder" class="form-control" autocomplete="off">
  <div class="input-group-prepend" *ngIf="loader">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<small class="ml-1 text-danger" *ngIf="noResult && displayNoResult">{{notFoundMessage}}</small>
<ng-template #customItemTemplate let-model="item">
  <div>
    <strong>{{ model.name }}</strong>
  </div>
  <div>
    <small *ngIf="model.abn">abn: {{model.abn}}</small>
    <small *ngIf="model.acn"> _ acn: {{model.acn}}</small>
  </div>
</ng-template>
