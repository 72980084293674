import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { add } from 'ngx-bootstrap/chronos';
import { TypeaheadOrder } from 'ngx-bootstrap/typeahead';
import { BehaviorSubject } from 'rxjs';
import { tap, finalize, debounceTime, filter, startWith } from 'rxjs/operators';
import { uuid4 } from 'src/app/helpers/uuid';
import { AddressLookupGetSuggestionModel } from 'src/app/models/gmap/address-lookup-get-suggestion-model';
import { AddressLookupSuggestion } from 'src/app/models/gmap/address-lookup-suggestion';
import { IAddressModel } from 'src/app/models/quick-setup-models/minimal-site-model';
import { AddressLookupService } from 'src/app/services/address-lookup.service';

@Component({
  selector: 'obc-address-form-control',
  templateUrl: './address-form-control.component.html',
  styleUrls: ['./address-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AddressFormControlComponent,
      multi: true,
    }
  ]
})
export class AddressFormControlComponent implements ControlValueAccessor {
  _isGeolocationRequierd : boolean = false;
  @Input() set isGeolocationRequierd (value: boolean) {
    this._isGeolocationRequierd = value;
    this.initFormControl();
  };
  get isGeolocationRequierd() {
    return this._isGeolocationRequierd;
  }
  onChange: Function;
  formGroup: FormGroup;
  _address: string;
  set address(value: string) {
    this.addressControl.setValue(value);
    this._address = value;
    this.formGroup.controls.address.setValue(value);
  };
  get address(): string {
    return this._address;
  }
  addressControl: FormControl = new FormControl();
  uuid: string;
  suggestions = new BehaviorSubject<AddressLookupSuggestion[]>([]);
  addressLoader: boolean = false;
  sortConfig: TypeaheadOrder = {
    direction: 'asc',
    field: 'primaryTitle',
  };
  constructor(private addressLookupService: AddressLookupService) { }

  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }

  ngOnInit() {
    this.uuid = uuid4();
    this.addressControl.valueChanges
      .pipe(
        startWith(''),
        // map(value => value as string),
        // tap(() => {
        //   if (!this.suggestions)
        //     this.suggestions = new BehaviorSubject<AddressLookupSuggestion[]>([]);
        // }),
        filter(value => value && value.length > 0),
        debounceTime(500),
        tap(() => {
          this.addressLoader = true;
          let model: AddressLookupGetSuggestionModel = {
            address: this.addressControl.value,
            sessionToken: this.uuid,
          };
          this.addressLookupService.getAddressSuggestionList(model)
            .pipe(finalize(() => this.addressLoader = false))
            .subscribe(res => {
              this.suggestions.next(res);
            }, err => {
              console.log(err);
            })
        })
      ).subscribe(res => { });


    this.initFormControl();
  }

  private initFormControl() {
    var geolocValidator = this.isGeolocationRequierd ? [Validators.required] : [];
    this.formGroup = new FormGroup({
      address: new FormControl(null, [Validators.required]),
      latitude: new FormControl(null, geolocValidator),
      longitude: new FormControl(null, geolocValidator),
    });

    this.formGroup.valueChanges.subscribe(res => {
      if (this.onChange)
        if (!this.formGroup.valid)
          this.onChange({
            address: this._address
          });

        else
          this.onChange(this.formGroup.value);
    });
  }

  writeValue(address: IAddressModel): void {
    if (address == null)
      address = {} as IAddressModel;
    this._address = address.address;
    this.formGroup.controls.address.setValue(address.address);
    this.formGroup.controls.latitude.setValue(address.latitude);
    this.formGroup.controls.longitude.setValue(address.longitude);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onSelectAddress($event) {
    this._address = $event.item.primaryTitle + " - " + $event.item.secondaryTitle;
    this.addressLookupService.getDetailsOfSelectedPlace($event.item.id, this.uuid)
      .subscribe(res => {
        this.writeValue({
          address: this._address,
          latitude: +res.latitude,
          longitude: +res.longitude,
        })
      });
  }

}
