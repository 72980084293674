<div *ngIf="formGroup">
  <form [formGroup]="formGroup">
    <div class="d-flex flex-column" *ngIf="model">
      <div>
        <i *ngIf="valid" style="color: green;" class="fas fa-check-circle  mr-2"></i>
        <i *ngIf="!valid" style="color: red;" class="fas fa-times-circle  mr-2"></i>
        <obc-question-title-renderer [question]="model.question"
          aria-describedby="emailHelp"></obc-question-title-renderer>
        <div *ngIf="selectedItemHint && selectedItemHint?.trim() != ''" id="emailHelp" class="small" style="color: gray;">
          <i class="fas fa-info-circle"></i> {{selectedItemHint}}
        </div>
      </div>
      <div class="form-group d-flex justify-content-end">
        <div class="form-group" *ngIf="model.question.renderMode == 1 || model.question.renderMode == 2">
          <div class="btn-group" btnRadioGroup formControlName="value">
            <label *ngFor="let item of items" [btnRadio]="item.value" uncheckable
              [class]="item.selectedDisplayStyle|displayStyleCssClass:model.question.questionType:model.question.renderMode"
              (click)="onCheckChange(item)" tabindex="0" role="button">{{item.title}}</label>
          </div>
        </div>
        <div class="form-group" *ngIf="model.question.renderMode == 0">
          <select class="form-control" formControlName="value" (change)="onItemChange($event)">
            <option *ngFor="let item of items" [ngValue]="item.value"
              [class]="item.selectedDisplayStyle|displayStyleCssClass:model.question.questionType:model.question.renderMode">
              {{item.title}}</option>
          </select>
        </div>
      </div>
    </div>
  </form>
</div>