import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { LocaleService } from '../services/locale.service';
import { dateTimeFormat } from '../helpers/date-time-format';
import * as moment from 'moment';
@Pipe({
  name: 'datetime'
})
export class DatetimePipe implements PipeTransform {

  private datePipe: DatePipe;

  constructor(private localeService : LocaleService) {
    this.datePipe = new DatePipe(this.localeService.defaultLocale);
  }

  transform(value: any, format?: string): string {
    if(!value)
      return null;
    var ret = moment(value).format(format ?? dateTimeFormat);;
    return ret;

    //return this one if timezone is needed
    return new Intl.DateTimeFormat(this.localeService.defaultLocale, {
      year: "2-digit",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }).format(value);
  }
}
