<obc-loader [show]="inProgress"></obc-loader>
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <div class="d-flex flex-row justify-content-between">
          <div>Register Asset Operator</div>
        </div>
        <div class="card-actions">
          <a class="card-action card-action-dismiss" (click)="closeModal()"></a>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <obc-user-autocomplete #mobile [filterBy]="filterUserBy.Mobile" [required]="true"
              (selectedUser)="fillCompanyUser($event)" [currentUser]="selectedUser" [isValid]="isValidMobile"
              (inputChange)="updateMobile($event.value)" display="mobile" name="mobile" placeholder="Mobile"
              [notFoundMessage]="isValidMobile ? 'add as a new user' : ''">
            </obc-user-autocomplete>
            <small class="text-warning" *ngIf="!isValidMobile">Please enter a valid mobile number.</small>
          </div>
          <div class="col-md-4">
            <obc-user-autocomplete [filterBy]="filterUserBy.FirstName" [displayNoResult]="false" [required]="false"
              (selectedUser)="fillCompanyUser($event)" [currentUser]="selectedUser"
              (inputChange)="updateFirstName($event.value)" display="firstName" placeholder="First Name">
            </obc-user-autocomplete>
          </div>
          <div class="col-md-4">
            <obc-user-autocomplete [filterBy]="filterUserBy.LastName" [required]="false" [displayNoResult]="false"
              (selectedUser)="fillCompanyUser($event)" [currentUser]="selectedUser"
              (inputChange)="updateLastName($event.value)" display="lastName" placeholder="Last Name">
            </obc-user-autocomplete>
          </div>
        </div>
        <div class="row mt-2" *ngIf="selectedSiteAsset.assetOperatorFormId">
          <div class="col-12">
            <obc-form-data-renderer #formDataRenderer [siteId]="this.siteId" [supplierId]="supplierId"
              [formType]="formType.AssetOperator" [formId]="selectedSiteAsset.assetOperatorFormId"
              [allParameters]="{toolId:selectedSiteAsset.assetId}" (validate)="validateForm($event)"
              [hashLink]="hashLink">
            </obc-form-data-renderer>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button type="button" class="btn btn-primary mr-1" [disabled]="!isFormValid" (click)="registerOperator()">
            Register Operator
          </button>
        </div>
      </div>
    </div>
  </div>
</div>