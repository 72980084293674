import { MaxFileSize } from './../../../enums/constants';
import { EventEmitter } from '@angular/core';
import { Component, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { dateFormat } from 'src/app/helpers/date-time-format';
import { getFileExtenstion, getFilename, toBase64 } from 'src/app/helpers/general-functions';
import { uuid4 } from 'src/app/helpers/uuid';
import { SiteSupplierDocumentTypeFileModel, SiteSupplierDocumentTypeVersionModel, SiteSupplierDocumentTypeWithCurrentVersionViewModel } from 'src/app/models/supplier-document/site-supplier-document-type-models';
import { VariableDefinitionMode, VariableDefinitionType, VariableValueDefinition } from 'src/app/models/workflow/variable-difinition';
import { WorkflowVariableValue } from 'src/app/models/workflow/workflow-variable-value';
import { DatetimePipe } from 'src/app/pipes/datetime.pipe';
import { ModalService } from 'src/app/services/modal.service';
import { SiteSupplierService } from 'src/app/services/site-supplier.service';


@Component({
  selector: 'app-site-supplier-document-uploader',
  templateUrl: './site-supplier-document-uploader.component.html',
  styleUrls: ['./site-supplier-document-uploader.component.scss'],
  providers: [DatetimePipe]
})
export class SiteSupplierDocumentUploaderComponent implements OnInit {
  VariableDefinitionType = VariableDefinitionType;
  DatetimePickerMode = DatetimePickerMode;

  currentDate: Date = new Date();
  fiveYearsLaterDate: Date = (new Date()).addDays(365 * 5);

  inProgress = false;
  _siteSupplierDocumentType: SiteSupplierDocumentTypeWithCurrentVersionViewModel = null;
  selectedFiles = [];
  @Output() hide = new EventEmitter()
  formGroup = new FormGroup({
    versionDate: new FormControl(null),
    expirationDate: new FormControl(null),
    siteSupplierDocumentTypeTitle: new FormControl(null, [Validators.required]),
    siteSupplierDocumentTypeDescription: new FormControl(null)
  });

  variableFormGroups = new FormGroup({});
  @HostListener('change', ['$event.target.files']) async emitFiles($event: FileList) {
    if ($event?.length)
      for (let index = 0; index < $event.length; index++) {
        const file = $event[index];
        this.setSiteSupplierDocumentTypeTitle(file);
        if (file.size > MaxFileSize * 1024000) {
          this.modalService.error(`maximum file size is ${MaxFileSize}mb`);
          continue;
        }
        var fileModel = {
          base64: (await toBase64(file)).toString(),
          extension: (getFileExtenstion(file.name)),
          filename: file.name,
          length: file.size,
          mimeType: file.type,
          guid: uuid4(),
        };
        this.selectedFiles.push(fileModel);
      }
    this.resetFileInput();
  }

  @Output() updateFileDone = new EventEmitter();
  @Input() documentKindId: number;
  @Input() documentKindTitle: string;
  @Input() siteSupplierId: number = null;
  @Input() set siteSupplierDocumentType(value: SiteSupplierDocumentTypeWithCurrentVersionViewModel) {

    if (value != this._siteSupplierDocumentType && value != null) {
      //console.log("siteSupplierDocumentType change", value)
      // reset form
      this._siteSupplierDocumentType = value;
      this.formGroup.controls.siteSupplierDocumentTypeTitle.setValue(this._siteSupplierDocumentType.supplierDocumentTypeName);
      this.formGroup.controls.siteSupplierDocumentTypeDescription.setValue('');
      this.formGroup.controls.siteSupplierDocumentTypeTitle.disable();

      for (let v of this.variableList) {
        this.variableFormGroups.addControl(v.key, new FormControl(v.value, v.mode == VariableDefinitionMode.Mandatory ? [Validators.required] : []));
      }
    }
    else {
      if (value == null) {
        this.formGroup.controls.siteSupplierDocumentTypeTitle.setValue(null);
        this.formGroup.controls.siteSupplierDocumentTypeDescription.setValue(null);
        this.formGroup.controls.siteSupplierDocumentTypeTitle.enable();

        if (this._siteSupplierDocumentType != null) {
          for (let v of this.variableList) {
            this.variableFormGroups.addControl(v.key, new FormControl(v.value, v.mode == VariableDefinitionMode.Mandatory ? [Validators.required] : []));
          }
        }
      }
    }
  }

  @Input() siteSupplierHashlink: string = null;
  @Input() variableList: VariableValueDefinition[];

  constructor(
    private siteSupplierService: SiteSupplierService,
    private datetimePipe: DatetimePipe,
    private modalService: ModalService) { }

  ngOnInit() {
  }

  onRemoveFile(sortedfile: any) {
    var file = this.selectedFiles.find(x => {
      return sortedfile.guid == x.guid;
    });
    if (file == null) return;
    var index = this.selectedFiles.indexOf(file);
    if (index >= 0)
      this.selectedFiles.splice(index, 1);
    this.resetFileInput();
  }

  lovalVariables?: WorkflowVariableValue[];
  onAddSDTVersion() {
    let workflowVariableValues = [];
    this.variableList.forEach(variable => {
      workflowVariableValues.push(<WorkflowVariableValue>{
        key: variable.key,
        value: this.variableFormGroups.value[variable.key]
      });
    });

    var model = {
      documentKindId: this.documentKindId,
      versionDate: this.formGroup.controls.versionDate.value ? this.datetimePipe.transform(this.formGroup.controls.versionDate.value, dateFormat) : null,
      //variables: this.formGroup.controls.expirationDate.value ? {"ExpireDate": this.formGroup.controls.expirationDate.value} : null,
      title: this.formGroup.controls.siteSupplierDocumentTypeTitle.value,
      description: this.formGroup.controls.siteSupplierDocumentTypeDescription.value,
      variables: workflowVariableValues,
      files: this.selectedFiles.map(x => {
        return {
          base64: x.base64.split(';base64,')[1],
          filename: x.filename,
          mimeType: x.mimeType,
          extension: x.extension,
        } as SiteSupplierDocumentTypeFileModel
      }),
    } as SiteSupplierDocumentTypeVersionModel;

    this.inProgress = true;
    this.siteSupplierService.addSiteSupplierDocumentTypeVersion(model, this.siteSupplierHashlink, this.siteSupplierId, this._siteSupplierDocumentType?.siteSupplierDocumentTypeId)
      .pipe(finalize(() => { this.inProgress = false }))
      .subscribe(res => {
        this.reset();
        this.updateFileDone.emit();
      }, err => {
        this.modalService.error(err);
      })
  }
  showFileInput = true;
  resetFileInput() {
    this.showFileInput = false;
    setTimeout(() => {
      this.showFileInput = true;
    }, 100)
  }

  reset() {
    this.formGroup.reset();
    this.selectedFiles = [];
    this.resetFileInput();
  }

  formIsValid() {
    return this.selectedFiles?.length > 0 &&
      this.formGroup.controls.versionDate.valid &&
      this.formGroup.controls.siteSupplierDocumentTypeDescription.valid &&
      Object.values(this.variableFormGroups.controls)
        .filter(c => c.invalid).length === 0;
  }

  onChangeFilesOrder($event) {
    // nothing needed model is updated
    // this.selectedFiles = $event;
    // this.resetFileInput();

  }

  setSiteSupplierDocumentTypeTitle(file: File) {
    if (this.siteSupplierDocumentType == null && !this.formGroup.controls.siteSupplierDocumentTypeTitle.value) {
      var onlyfilename = getFilename(file.name);
      this.formGroup.controls.siteSupplierDocumentTypeTitle.setValue(onlyfilename);
    }
  }
}
