import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { GlobalItemType } from 'src/app/enums/global-item-type.enum';
import { ToolDynamicFieldType } from 'src/app/enums/tool-dynamic-field-type.enum';
import { ToolDynamicFieldUsage } from 'src/app/enums/tool-dynamic-field-usage.enum';
import { BaseToolDynamicFieldViewModel, ToolDynamicFieldModel, ToolDynamicFieldViewModel } from 'src/app/models/inventory/tool-dynamic-field-model';
import { QuestionAnswerFileFormat } from 'src/app/models/questionParameters/question-answer-file-format';
import { ManageInventoryService } from 'src/app/services/manage-inventory.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-manage-global-dynamic-fields',
  templateUrl: './manage-global-dynamic-fields.component.html',
  styleUrls: ['./manage-global-dynamic-fields.component.scss']
})
export class ManageGlobalDynamicFieldsComponent implements OnInit {

  GlobalItemType = GlobalItemType;
  ToolDynamicFieldType = ToolDynamicFieldType;
  ToolDynamicFieldUsage = ToolDynamicFieldUsage;
  QuestionAnswerFileFormat = QuestionAnswerFileFormat;

  toolDynamicFieldformGroup = new FormGroup({
    fieldName: new FormControl(null, [Validators.required]),
    usage: new FormControl(null, [Validators.required]),
    type: new FormControl(0, [Validators.required]),
    fileType: new FormControl(0, [Validators.required]),
    isBarcode: new FormControl(false, []),
    isSearchable: new FormControl(false, []),
    isLongText: new FormControl(false, []),
    isGlobalField: new FormControl(false, [Validators.required]),
  });
  inProgress: boolean;
  handlerToolDynamicField: any;

  allDynamicFields: ToolDynamicFieldViewModel[];
  get toolDynamicFields(): ToolDynamicFieldViewModel[] {
    return this.allDynamicFields?.filter(x => x.usage == ToolDynamicFieldUsage.Tool);
  }
  get toolAssignDynamicFields(): ToolDynamicFieldViewModel[] {
    return this.allDynamicFields?.filter(x => x.usage == ToolDynamicFieldUsage.ToolAssign);
  }

  constructor(private manageInventoryService: ManageInventoryService,
    private bsModalService: BsModalService,
    private modalService: ModalService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.inProgress = true;
    this.manageInventoryService.getGlobalToolDynamicFields()
      .pipe(finalize(() => {
        this.inProgress = false;
      }))
      .subscribe(res => {
        this.allDynamicFields = res;
      }, err => {
        this.modalService.error(err)
      });
  }

  onOpenToolDynamicField(template: any, usage: ToolDynamicFieldUsage) {
    this.toolDynamicFieldformGroup.controls.usage.setValue(usage)
    this.handlerToolDynamicField = this.bsModalService.show(template);
  }

  onToolDynamicFieldTypeChange() {
    this.toolDynamicFieldformGroup.controls.isBarcode.setValue(false);
    this.toolDynamicFieldformGroup.controls.isSearchable.setValue(false);
    this.toolDynamicFieldformGroup.controls.isLongText.setValue(false);
  }
  get isAddToolDynamicFieldButtonDisabled() {
    return !this.toolDynamicFieldformGroup.valid;
  }

  onAddToolDynamicField() {
    if (this.validateInfos() == false)
      return;

    this.inProgress = true;

    var model = {
      fieldName: this.toolDynamicFieldformGroup.controls.fieldName.value,
      usage: this.toolDynamicFieldformGroup.controls.usage.value,
      type: this.toolDynamicFieldformGroup.controls.type.value,
      fileType: this.toolDynamicFieldformGroup.controls.fileType.value,
      isBarcode: this.toolDynamicFieldformGroup.controls.isBarcode.value,
      isSearchable: this.toolDynamicFieldformGroup.controls.isSearchable.value,
      isLongText: this.toolDynamicFieldformGroup.controls.isLongText.value,
    } as BaseToolDynamicFieldViewModel;

    this.manageInventoryService.addGlobalToolDynamicField(model)
      .pipe(finalize(() => {
        this.inProgress = false;
        this.resetForm();
        this.loadData();
        this.handlerToolDynamicField.hide();
      }))
      .subscribe(res => {
      }, err => {
        this.modalService.error(err)
      });
  }
  validateInfos() {
    var usage = this.toolDynamicFieldformGroup.controls.usage.value;
    var type = this.toolDynamicFieldformGroup.controls.type.value;
    var isBarcode = this.toolDynamicFieldformGroup.controls.isBarcode.value;
    var isSearchable = this.toolDynamicFieldformGroup.controls.isSearchable.value;
    var isLongText = this.toolDynamicFieldformGroup.controls.isLongText.value;

    if (isBarcode == true &&
      (type == ToolDynamicFieldType.File ||
        type == ToolDynamicFieldType.Date)) {
      this.modalService.error("This field cannot be a barcode");
      return false;
    }
    if (isSearchable == true &&
      (usage == ToolDynamicFieldUsage.ToolAssign ||
        type == ToolDynamicFieldType.File ||
        type == ToolDynamicFieldType.Date)) {
      this.modalService.error("This field cannot be searchable");
      return false;
    }
    if (isLongText == true &&
      type != ToolDynamicFieldType.Text) {
      this.modalService.error("This field cannot be a long text");
      return false;
    }
    return true;
  }

  resetForm() {
    this.toolDynamicFieldformGroup.reset();
    this.toolDynamicFieldformGroup.controls.type.setValue(ToolDynamicFieldType.Text);
    this.toolDynamicFieldformGroup.controls.fileType.setValue(QuestionAnswerFileFormat.AnyFile);
    this.toolDynamicFieldformGroup.controls.isBarcode.setValue(false);
    this.toolDynamicFieldformGroup.controls.isSearchable.setValue(false);
    this.toolDynamicFieldformGroup.controls.isLongText.setValue(false);
    this.toolDynamicFieldformGroup.controls.isGlobalField.setValue(false);
  }

  onRemoveToolDynamicField(itemId: number, usage: ToolDynamicFieldUsage) {
    this.modalService.confirm('Are you sure you want to remove this global field?')
      .subscribe(confirm => {
        if (confirm) {
          this.inProgress = true;
          var me = this;
          this.manageInventoryService.removeGlobalToolDynamicField(itemId)
            .pipe(finalize(() => { me.inProgress = false }))
            .subscribe(res => {
              me.loadData();
            }, err => {
              me.modalService.error(err)
            });
        }
      });
  }


}
