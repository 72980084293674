import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { SupplierDocumentReviewFormSelectionMode } from 'src/app/enums/supplier-documnet-review-form-selection-mode.enum';
import { FilledFormInfoViewModel } from 'src/app/models/filled-form-info-view-model';
import { ServerResultData } from 'src/app/models/server-result-data';
import { ISiteSupplierDocumentTypeVersion } from 'src/app/models/supplier-document/site-supplier-document-type';
import { FormService } from 'src/app/services/form.service';
import { ModalService } from 'src/app/services/modal.service';
import { SiteSupplierService } from 'src/app/services/site-supplier.service';
import { IWorkflowStep } from 'src/app/workflow/IWorkflowStep';
import { WorkflowInfoViewModel } from 'src/app/models/workflow/workflow-info-viewmodel';
import { CommandView } from 'src/app/models/workflow/command-view';
import { SaveStepViewModel } from 'src/app/models/workflow/continue-workflow-query-viewModel';
import { FormDataDetailsComponent } from 'src/app/components/form-manage/form-data-details/form-data-details.component';
import { SupplierDocumentService } from 'src/app/services/supplier-document.service';

@Component({
  selector: 'obc-supplier-document-detail-workflow',
  templateUrl: './supplier-document-detail-workflow.component.html',
  styleUrls: ['./supplier-document-detail-workflow.component.scss']
})
export class SupplierDocumentDetailWorkflowComponent implements OnInit, IWorkflowStep {

  @ViewChild('supplierDocumentDetail') supplierDocumentDetail: FormDataDetailsComponent;

  selectedFormDataId?: number;
  isInitilize: boolean = false;
  inProgress: boolean;

  workflow: WorkflowInfoViewModel;

  showPicPanel: boolean;
  formDetail: FilledFormInfoViewModel;
  selectedSiteSupplierDocument: ISiteSupplierDocumentTypeVersion;
  formSelectionMode: SupplierDocumentReviewFormSelectionMode = SupplierDocumentReviewFormSelectionMode.withoutForm;
  SupplierDocumentReviewFromSelectionMode = SupplierDocumentReviewFormSelectionMode;

  constructor(
    private formService: FormService,
    private siteSupplierService: SupplierDocumentService,
    private modalService: ModalService,
    private toastrService: ToastrService,
    private router: Router) { }

  ngOnInit(): void {
    this.onResize();
  }

  showTabPage: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    setTimeout(() => {
      this.showTabPage = false;
      if (window.innerWidth < 1200)
        this.showTabPage = true;

      this.showPicPanel = true;
      if (this.selectedSiteSupplierDocument == null || this.selectedSiteSupplierDocument.files == null || this.selectedSiteSupplierDocument.files.length == 0) {
        this.showPicPanel = false;
      }
    }, 500);
  }

  initWorkflow(workflow: WorkflowInfoViewModel): Promise<boolean> | boolean {
    this.workflow = workflow;
    return new Promise<boolean>((resolve, _) => {
      this.inProgress = true;

      //Get data of workflow
      this.siteSupplierService.getSiteSupplierDocumentVersionByWorkflowInstanceId(workflow.id)
        .pipe(finalize(() => this.inProgress = false))
        .subscribe(res => {
          if (res && res.siteId) {
            this.selectedFormDataId = res.formDataId;

            this.selectedSiteSupplierDocument = {
              files: res.documentTypes[0].siteSupplierDocumentFiles,
              displayVersion: res.documentTypes[0].displayVersion,
              lastSupplierVersionId: res.documentTypes[0].siteSupplierDocumentVersionId,
              supplierDocumentTypeName: res.documentTypes[0].supplierDocumentTypeName
            } as ISiteSupplierDocumentTypeVersion;

            if (this.selectedFormDataId) {
              this.formSelectionMode = SupplierDocumentReviewFormSelectionMode.withForm;

              this.formService
                .getFilledForm(this.selectedFormDataId)
                .subscribe(
                  res => {
                    if (res) {
                      this.formDetail = res;
                      this.isInitilize = true;
                      this.onResize();

                      resolve(true);
                    } else {
                      this.modalService.error('No Details Available');
                      resolve(false);
                    }
                  }, err => {
                    this.modalService.error("Request Failed");
                    resolve(false);
                  }
                )
            }
            else {
              this.formSelectionMode = SupplierDocumentReviewFormSelectionMode.withoutForm;
              this.onResize();
              this.isInitilize = true;
              resolve(true);
            }
          }
          else {
            this.toastrService.warning("There is not workflow");
            resolve(false);
          }
        }, err => {
          this.modalService.error(err);
          resolve(false);
        });
    });
  }

  validateStep<T>(command: CommandView, note: string): boolean | Promise<boolean> {
    return true;
  }

  saveStep(command: CommandView, note: string): SaveStepViewModel | Promise<SaveStepViewModel> {
    return <SaveStepViewModel>{
      continue: true,
    }
  }

}