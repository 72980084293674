import { Pipe, PipeTransform } from '@angular/core';
import { regexLists } from '../helpers/general-lists';

@Pipe({
  name: 'regex'
})
export class RegexPipe implements PipeTransform {

  transform(value: string): unknown {
    if (!value || value === undefined) return '';
    var founded = regexLists.find(c => c.itemValue.trim() === value.trim());
    if (founded) return founded.itemTitle;
    return '';
  }

}
