export enum ProcoreExportCheckInAsTimeCardMode {
    Disabled = 0,
    EmployeesOnly = 1,
}

export enum ProcoreExportCheckInAsVisitorLogMode {
    Disabled = 0,
    Everyone = 1,
    EveryoneExceptEmployees = 2,
    EveryoneExceptSubcontractorsAndEmployees = 3,
}

export enum ProcoreExportCheckInAsManpowerMode {
    Disabled = 0,
    Everyone = 1,
    EveryoneExceptEmployees = 2,
    SubcontractorsOnly = 3,
    AggregatedSubcontractors = 4,
    AggregatedSubcontractorsAndEmployees = 5,
}