import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ManageSiteQuestionVisitorTypeMode } from 'src/app/enums/manage-site-question-visitor-type-mode.enum';
import { SiteQuestionService } from 'src/app/services/site-question.service';
import { ManageSiteViewModel } from "../../models/manage-site-view-model";
import { CheckedVisitorType, VisitorType } from "../../models/visitor-type";
import { SiteVisitorTypeViewModel } from "../../models/site-visitortype-accompanier-viewmodel";

enum SiteQuestionMethod {
  Add = 1,
  Remove = 2,
  Clear = 3,
  GetAll = 4,
}

@Component({
  selector: 'obc-manage-site-question-visitor-type',
  templateUrl: './manage-site-question-visitor-type.component.html',
  styleUrls: ['./manage-site-question-visitor-type.component.scss']
})
export class ManageSiteQuestionVisitorTypeComponent implements OnInit {

  @Input() questionVisitorTypeIds: number[] = [];

  manageSiteModel: ManageSiteViewModel;
  @Input() set manageSiteViewModel(manageSiteModel: ManageSiteViewModel) {
    this.manageSiteModel = manageSiteModel;
    this.init();
  }

  ManageSiteQuestionVisitorTypeMode = ManageSiteQuestionVisitorTypeMode;
  @Input() mode: ManageSiteQuestionVisitorTypeMode = ManageSiteQuestionVisitorTypeMode.Question;
  get modeLabel(): string {
    switch (this.mode) {
      case ManageSiteQuestionVisitorTypeMode.Document:
        return "document";
      case ManageSiteQuestionVisitorTypeMode.Question:
        return "question";
      case ManageSiteQuestionVisitorTypeMode.RequiredField:
        return "filed";
    }
  }
  inprogress = false;
  types: VisitorType[] = [];
  get selecteds(): number[] {
    if (!this.checkedTypes) return [];
    return this.checkedTypes.filter(i => i.checked).map(i => i.id);
  };
  checkedTypes: CheckedVisitorType[];
  _siteEntityId: number;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() set siteEntityId(id: number) {
    this._siteEntityId = id;
  }
  constructor(private siteQuestionService: SiteQuestionService) { }

  ngOnInit() {
    this.init();
  }

  init() {

    if (this.manageSiteModel?.siteVisitorTypes) {
      this.checkedTypes = this.manageSiteModel?.siteVisitorTypes?.map((siteVisitorType) => {
        return {
          ...this.manageSiteModel?.visitorTypes?.find(vType => vType.id == siteVisitorType.visitorTypeId) ?? {},
          checked: this.questionVisitorTypeIds?.findIndex(questionVisitorTypeId => questionVisitorTypeId == siteVisitorType.visitorTypeId) > -1
        } as CheckedVisitorType
      });
      this.limited = this.questionVisitorTypeIds?.length > 0;

    }
  }

  onItemClick(item: any) {
    this.onChange.emit(item);
  }

  getCheckedType(siteVisitorType: SiteVisitorTypeViewModel) {
    return {
      ...this.manageSiteModel?.visitorTypes?.find(vType => vType.id == siteVisitorType.visitorTypeId) ?? {},
      checked: this.questionVisitorTypeIds?.findIndex(questionVisitorTypeId => questionVisitorTypeId == siteVisitorType.visitorTypeId) > -1
    } as CheckedVisitorType
  }

  anySelected(): boolean {
    if (this.checkedTypes == null) return false;
    return this.checkedTypes.find(i => i.checked) != null;
  }

  onClearAll() {
    this.types = [];
    this.checkedTypes = [];
  }

  _limitedPrivate: boolean;
  _limited: boolean;
  set limited(value: boolean) {
    if (this._limited != value) {
      this._limited = value;
      this._limitedPrivate = value;
      if (this._limited == false && this.selecteds.length > 0)
        this.onClearAll();
    }
  };

  get limited(): boolean {
    return this._limited;
  }

  onVisitorTypeStatusChanged(enabled: boolean) {
    if (enabled == false) {
      this.manageSiteModel?.siteVisitorTypes?.forEach(visitorType => {
        var val = this.getCheckedType(visitorType);
        if (val.checked)
          this.onItemClick(val);
      });
    }
    this._limitedPrivate = enabled;
  }

}
