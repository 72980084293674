<button class="btn btn-default btn-sm mb-2 position-relative" (click)="onBackButton()" *ngIf="showProfilePanel">
  <i class="fas fa-arrow-left"></i> Return to list
</button>

<div class="card ">
  <header class="card-header">User Directory</header>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <app-site-selector [permission]="siteRequiredPermissions"
          (siteSelect)="onSelectSite($event)"></app-site-selector>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-12">
        <div class="card" [hidden]="showProfilePanel">
          <!-- <header class="card-header">User Directory</header> -->
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>
                  <ng-template obcGridTemplateName="viewProfile" let-viewDetails let-data="data">
                    <button class="btn btn-info" (click)="showProfile(data)">
                      View Details
                    </button>
                  </ng-template>
                </app-base-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="card" [hidden]="!showProfilePanel">
      <header class="card-header">User Details</header>
      <div class="card-body">
        <div>
          <obc-worker-profile (isEditFormDirty)="isEditFormDirty($event)" [profile]="selectedUserProfile"
            (onProfileUpdated)="onProfileUpdated()" [siteId]="selectedSiteId"></obc-worker-profile>
        </div>
      </div>
    </div>
  </div>
</div>