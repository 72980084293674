import { Pipe, PipeTransform } from '@angular/core';
import {AssetOperatorMode} from "../enums/asset-operator-mode.enum";
import {AssetOperatorModePipe} from "./asset-operator-mode.pipe";
import {DatePipe} from "@angular/common";

@Pipe({
  name: 'listOfOperatorRegistrationMode'
})
export class ListOfOperatorRegistrationModePipe implements PipeTransform {

  private assetOperatorModePipe: AssetOperatorModePipe = new AssetOperatorModePipe();

  transform(value: any): string {
    let output = [];
    for (let item in AssetOperatorMode) {
      if((Number(item) & value) > 0) {
        output.push(this.assetOperatorModePipe.transform(item));
      }
    }
    return output?.join(', ');
  }
}
