import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CompanyViewModel } from 'src/app/models/company-viewmodel';
import { CompaniesQueryModel } from 'src/app/models/get-companies-query-model';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'obc-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss']
})
export class CompanySelectorComponent implements OnInit {
  placeholder = 'Select Company';

  constructor(private companyService: CompanyService) { }

  _companyId: number = null;
  _required = false;

  @Input() selectFirstItemOfOneItemArray: boolean = false;
  @Input() set companyId(value: number,) {
    if (value === undefined || value === null)
      return;
    this._companyId = value;
  };

  @Input() set required(value: boolean) {
    if (value) {
      this._required = true;
      this.formControl?.setValidators(Validators.required);
    }
  };

  @ViewChild('selector') selector: NgSelectComponent;

  companies$: Observable<CompanyViewModel[]>;
  term$ = new BehaviorSubject<string>(null)
  formControl = new FormControl(null);
  @Output() selectedCompanyId = new EventEmitter<number>();
  @Output() selectedCompany = new EventEmitter<CompanyViewModel>();
  companies: CompanyViewModel[] = [];
  inProgress = false;

  ngOnInit() {
    this.getCompanies();
    this.formControl.valueChanges.subscribe(res => {
      if (this.companies) {
        if (res == null || res == 0) {
          this.selectedCompanyId.emit(null);
          this.selectedCompany.emit(null);
        } else {
          let company = this.companies.find(x => x.id == res);
          if (company == null) {
          } else {
            this.selectedCompany.emit(company);
            this.selectedCompanyId.emit(company.id);
          }
        }
      }
    });
  }

  private getCompanies() {
    this.inProgress = true;
    this.companyService.getCompanies({ searchTerm: this.term$.value } as CompaniesQueryModel)
      .subscribe(
        {
          next:
            (res) => {
              this.companies = res.data;
              this.companies$ = of(this.companies);
              if (this._companyId !== undefined && this._companyId !== null)
                this.formControl.setValue(this._companyId);
              else if (this.selectFirstItemOfOneItemArray == true && this.companies?.length == 1) {
                this.formControl.setValue(this.companies[0].id);
              }
            },
          complete: () => {
            this.inProgress = false;
          }
        }
      );
  }

  reachToEnd = false;
  reachToEndTerm = "";

  onScrollToEnd() {
    this.reachToEnd = true;
    this.reachToEndTerm = this.term$.value;
    this.term$.next(this.term$.value)
  }

  public clearInput() {
    this.selector.handleClearClick();
  }

  filterForms(res) {
    return res.data?.filter((form) => {
      //if (!this.excludeFormIds?.length || this.excludeFormIds.indexOf(form.id) == -1) {
      return form;
      //}
    }).sort((a, b) => a.name.localeCompare(b.title));
  }
}