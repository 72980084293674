<span *ngIf="siteInduction" class="clearfix">
    <span>
        <i class="fas fa-id-badge mr-2"></i>
        <strong>{{siteInduction.title}}</strong>
    </span>
    <span *ngIf="siteInduction.inductionKey" style="font-size: small;"> ({{siteInduction.inductionKey}})</span>
    <span class="pull-right">
        <span [ngSwitch]="siteInduction.inductionStatus">
            <span *ngSwitchCase="siteInductionStatusEnum.ApprovedByHost">
                <span class="text-success">
                    <i class="fas fa-check-circle"></i>
                    Approved
                </span>
            </span>
            <span *ngSwitchCase="siteInductionStatusEnum.PendingToApprove">
                <span class="text-warning">
                    <i class="fas fa-history"></i> Pending Approval
                </span>
                <button *ngIf="showApproveRejectButtons()" class="btn btn-info ml-2"
                    (click)="onOpenApproveRejectTemplate(approveRejectTemplate)">Review
                </button>
            </span>
            <span *ngSwitchCase="siteInductionStatusEnum.RejectByHost">
                <span class="text-danger">
                    <i class="fas fa-times-circle"></i> Rejected </span>
            </span>
            <span *ngSwitchCase="siteInductionStatusEnum.AutoApproved">
                <span class="text-success">
                    <i class="fas fa-check-circle"></i>
                    Approved
                </span>
            </span>
        </span>

        <span *ngIf="siteInduction.processDateTime">
            (<span *ngIf="siteInduction.inductionStatus==siteInductionStatusEnum.AutoApproved"><i>Auto</i> - </span>
            <span
                *ngIf="siteInduction.inductionStatus!=siteInductionStatusEnum.AutoApproved && siteInduction.inductionApproverFullName"><strong>{{siteInduction.inductionApproverFullName}}</strong>
                - </span>
            {{siteInduction.processDateTime | datetime}})
        </span>
        <span *ngIf="siteInduction.expiredDateTime">
            (Expiry: {{siteInduction.expiredDateTime | date}})
        </span>
    </span>
</span>

<ng-template #approveRejectTemplate>
    <obc-approve-reject [entityId]="siteInduction.siteInductionId" [entityType]="approveEntityType"
      (onRefresh)="closeModal(); fetchinduction();"></obc-approve-reject>
  </ng-template>