import { Pipe, PipeTransform } from '@angular/core';
import {ScheduleReportType} from "../enums/schedule-report-type";

@Pipe({
  name: 'scheduleReportType'
})
export class ScheduleReportTypePipe implements PipeTransform {

  transform(value: any): string {
    if (value == ScheduleReportType.InventoryReport)
      return "Inventory Report";
    if (value == ScheduleReportType.ActivityReport)
      return "Activity Report";
  }
}
