import { Pipe, PipeTransform } from '@angular/core';
import { SiteAssetRequestApproveStatus } from '../enums/site-asset-request-approve-status.enum';

@Pipe({
  name: 'siteAssetRequestApproveStatus'
})
export class SiteAssetRequestApproveStatusPipe implements PipeTransform {

  transform(value: unknown): string {
    if (value === SiteAssetRequestApproveStatus.Pending) return 'Pending';
    if (value === SiteAssetRequestApproveStatus.Approved) return 'Approved';
    if (value === SiteAssetRequestApproveStatus.Rejected) return 'Rejected';
    if (value === SiteAssetRequestApproveStatus.AutoApproved) return 'Auto Approved';
    return value?.toString();
  }

}
