import { Input, Output, EventEmitter, Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SiteQuestionRendererViewModel, SiteQuestionChangeEvent, SiteQuestionItemFormControl } from 'src/app/models/site-question-viewmodel';
import { CacheService } from "../../../../services/cache.service";

@Component({
  selector: 'obc-question-renderer',
  template: '',
  styles: []
})
export class QuestionRendererComponent {
  get formGroup(): FormGroup {
    return this.model?.formGroup;
  };

  set formGroup(fg) {
    this.model.formGroup = fg;
    this.model.formGroup.valueChanges.subscribe(res=>{
      this.valueChanges.emit({tagged : this.tagged, anwsers: this.model.answers});
    });
    setTimeout(()=>{
      this.valueChanges.emit({tagged : this.tagged, anwsers: this.model?.answers});
    }, 1000);
  }
  model : SiteQuestionRendererViewModel;
  @Input() tagged: any;
  @Output() valueChanges: EventEmitter<SiteQuestionChangeEvent> = new EventEmitter<SiteQuestionChangeEvent>();

  constructor(public cacheService: CacheService) { }

  ngOnInit() {
    this.formGroup.valueChanges.subscribe((_)=> {
      this.cacheService.formControlDirtySubject.next({questionId: this.model.question?.questionId})
    })
  }

  get controls(): SiteQuestionItemFormControl[] {
    return this.model?.controls;
  }

  get valid() : boolean {
    return this.model?.valid ?? false;
  }

}
