<div class="form-group row">
    <label class="col-sm-4 control-label text-left">Display Mode: </label>
    <div class="col-sm-8">
        <select required class="form-control" name="renderMode" #drp="ngModel"
            [(ngModel)]="_questionParam['renderMode']">
            <option *ngFor="let item of multiSelectRenderMode|enumToArray " [ngValue]="multiSelectRenderMode[item]">
                {{item}}
            </option>
        </select>
        <div *ngIf="drp.invalid && (drp.dirty || drp.touched)" class="help-block with-errors">
            Please select a Render type
        </div>
    </div>
</div>
<div class="form-group row">
    <label class="col-sm-4 control-label text-left ">Required items count to select </label>
    <div class="col-sm-8">
        <input type="number" name='minimum-answers' class="form-control" #minimumSelectCount="ngModel"
               #inputEl
               (keyup)="validateMinimumSelectCount()"
               (click)="selectValue(inputEl)"
            [(ngModel)]="_questionParam['minimumSelectCount']" min="0" [max]="_inputItems?.length" placeholder="Number">
    </div>
</div>
<obc-question-items-renderer [items]="_inputItems"
                             [multipleItemRenderMode]="_questionParam['renderMode']"
    (onItemsUpdated)="onItemsUpdated($event)"></obc-question-items-renderer>
