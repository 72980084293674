import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { BioStarLogViewModel } from '../models/biostar/biostar-log-viewmodel';
import { BioStarDevice } from '../models/biostar/view-models';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class BiostarService {

  apiUrl = '/api/BioStar';
  constructor(private http: HttpClient, private _errorService: ErrorService) { }

  getBioStarDevices(siteId?: number)
    : Observable<BioStarDevice[]> {
    return this.http.get(`${this.apiUrl}/biostar-device-list/${siteId ? siteId : ''}`)
      .pipe(catchError(this._errorService.handleError));
  }
  // isBiostarEnabled(): Observable<boolean> {
  //   return this.http.get(`${this.apiUrl}/is-enabled-biostar`)
  //     .pipe(catchError(this._errorService.handleError));
  // }
  getLogs(): Observable<BioStarLogViewModel[]> {
    return this.http.get(`${this.apiUrl}/logs`)
      .pipe(catchError(this._errorService.handleError));
  }
}
