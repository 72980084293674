import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PagingViewModel } from '../models/site-list-paging-view-model';
import { CompanySupplierModel, CompanySupplierQuery, CompanySupplierQueryTerm, CompanySupplierViewModel } from '../models/supplier-models';
import { CompanySupplierBusinessModel, CompanySupplierBusinessViewModel, CompanySupplierDocumentModel, CompanySupplierDocumentViewModel } from '../models/user-business-viewmodel';
import { ErrorService } from './error.service';
import {
  JoinSupplierInfo,
  JoinSupplierRequestModel,
  LinkToCompanyRequestModel,
  SiteSupplierAccessViewModel,
  UnlinkToCompanyRequestModel
} from "../models/link_to_company_request_model";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanySupplierService {

  constructor(private http: HttpClient, private errorService: ErrorService) { }

  getCachedSuppliers(query: CompanySupplierQuery, pagingInfo: any) {

    var model = {
      pagingInfo: pagingInfo,
      query: query,
    };
    return this.http.post<PagingViewModel<CompanySupplierViewModel>>("/api/CompanySupplier/suppliers/all", model);
  }


  getSuppliers(query: CompanySupplierQuery, pagingInfo: any) {
    var model = {
      pagingInfo: pagingInfo,
      query: query,
    };
    return this.http.post<PagingViewModel<CompanySupplierViewModel>>("/api/CompanySupplier/suppliers/all", model);
  }
  getSuppliersBySession(sessionId: string, query: CompanySupplierQueryTerm, pagingInfo: any) {
    var model = {
      pagingInfo: pagingInfo,
      query: query,
    };
    return this.http.post<PagingViewModel<CompanySupplierViewModel>>(`/api/CompanySupplier/suppliers/all/${sessionId}`, model);
  }
  addSupplier(model: CompanySupplierModel) {
    return this.http.put<CompanySupplierViewModel>("/api/CompanySupplier/suppliers", model);
  }
  updateSupplier(id: number, model: CompanySupplierModel) {
    return this.http.post<CompanySupplierViewModel>(`/api/CompanySupplier/suppliers/${id}`, model);
  }
  deleteSupplier(id: number) {
    return this.http.delete(`/api/CompanySupplier/suppliers/${id}`);
  }
  activateSupplier(id: number) {
    return this.http.post(`/api/CompanySupplier/suppliers/activate/${id}`, null);
  }
  getBusinesses(supplierId: number, query: any) {
    return this.http.post<PagingViewModel<CompanySupplierBusinessViewModel>>(`/api/CompanySupplier/supplier/${supplierId}/businesses/all`, query);
  }

  addBusiness(supplierId: number, model: CompanySupplierBusinessModel) {
    return this.http.put<CompanySupplierBusinessViewModel>(`/api/CompanySupplier/supplier/${supplierId}/business`, model);
  }

  removeBusiness(supplierId: number, bid: number) {
    return this.http.delete(`/api/CompanySupplier/supplier/${supplierId}/business/${bid}`);
  }

  getDocuments(supplierId: number) {
    return this.http.post<PagingViewModel<CompanySupplierDocumentViewModel>>(`/api/CompanySupplier/supplier/${supplierId}/documents/all`, null);
  }

  addDocument(supplierId: number, model: CompanySupplierDocumentModel) {
    return this.http.put<CompanySupplierDocumentViewModel>(`/api/CompanySupplier/supplier/${supplierId}/document`, model);
  }

  removeDocument(supplierId: number, did: number) {
    return this.http.delete(`/api/CompanySupplier/supplier/${supplierId}/document/${did}`);
  }


  linkSupplierToCompany(model: LinkToCompanyRequestModel) {
    return this.http.post<boolean>(`/api/CompanySupplier/link-supplier-to-company`, model);
  }

  unlinkSupplierFromCompany(model: UnlinkToCompanyRequestModel) {
    return this.http.post<boolean>(`/api/CompanySupplier/unlink-supplier`, model);
  }

  joinSupplier(model: JoinSupplierRequestModel): Observable<number> {
    return this.http.post<number>(`/api/CompanySupplier/supplier-join`, model);
  }

  getjoinInfo(token: string) {
    return this.http.post<JoinSupplierInfo>(`/api/CompanySupplier/supplier-join-info/${token}`, {});
  }

  validateJoinLink(token: string, smsToken: string) {
    return this.http.post<JoinSupplierInfo>(`/api/CompanySupplier/validate-supplier-join/${token}/${smsToken}`, {});
  }

  resendSupplierLinkingInvitation(id: number) {
    return this.http.post<JoinSupplierInfo>(`/api/CompanySupplier/resend-supplier-join-invitation/${id}`, {});
  }

  cancelSupplierLinking(id: number) {
    return this.http.post<JoinSupplierInfo>(`/api/CompanySupplier/cancel-supplier-join/${id}`, {});
  }

  validateSiteSupplier(siteSupplierId: number, supplierId: number, siteId: number): Observable<SiteSupplierAccessViewModel> {
    return this.http.get<SiteSupplierAccessViewModel>(`/api/CompanySupplier/validate-site-supplier/${siteSupplierId}/${siteId}/${supplierId}`);
  }

  sendSMSSupplierActivationToken(token: string) {
    return this.http.post<boolean>(`/api/CompanySupplier/send-sms-supplier-activation-token/${token}`, {});
  }

}
