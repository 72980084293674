import { Component, OnInit } from '@angular/core';
import { PermissionModeEnum } from "../../../enums/permission-mode.enum";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { CompanyRoleService } from "../../../services/company-role.service";
import { ModalService } from "../../../services/modal.service";
import { CompanyRoleViewModel } from "../../../models/company-role.view.model";
import { UserPermission } from "../../../enums/user-permission.enum";
import { ImportSource } from "../../../enums/import-source.enum";
import { CheckListItemViewModel } from "../../../custom-controls/check-list-form-control/check-list-item";
import { SiteUserService } from "../../../services/site-user.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { forkJoin } from "rxjs";
import { CompanyUserService } from "../../../services/company-user.service";
import { combinePermissions } from "../../../helpers/permission-helper";
import { UserService } from "../../../services/user.service";
import { LayoutService } from "../../../services/layout.service";

@Component({
  selector: 'obc-permissions-settings',
  templateUrl: './permissions-settings.component.html',
  styleUrls: ['./permissions-settings.component.scss']
})
export class PermissionsSettingsComponent implements OnInit {

  inProgress: boolean = false;
  PermissionModeEnum = PermissionModeEnum;
  UserPermissions = UserPermission;
  companyPermissionMode: PermissionModeEnum;
  companyRoles: CompanyRoleViewModel[] = [];
  selectedRoleToUpdateName: CompanyRoleViewModel;
  selectedRoleToUpdatePermissions: CompanyRoleViewModel;
  selectedCompanyRoleName: string;
  bsModalRef: any;
  siteUserPermissions: CheckListItemViewModel[] = [];
  companyUserPermissions: CheckListItemViewModel[] = [];
  isTemplateCompany: boolean = false;

  form = this.formBuilder.group({
    permissionMode: new FormControl(PermissionModeEnum.Mixed, [Validators.required]),
  })

  roleDefinitionForm = this.formBuilder.group({
    roleName: new FormControl(null, [Validators.required]),
  })

  rolePermissionsForm = this.formBuilder.group({
    roleId: new FormControl([Validators.required]),
    fullAccess: new FormControl(false, [Validators.required]),
    sitePermissions: new FormControl([], [Validators.required]),
    companyPermissions: new FormControl([], [Validators.required]),
  })

  constructor(
    private formBuilder: FormBuilder,
    public companyRoleService: CompanyRoleService,
    public modalService: ModalService,
    public siteUserService: SiteUserService,
    private bsModalService: BsModalService,
    public companyUserService: CompanyUserService,
    public userService: UserService,
    private layoutService: LayoutService,
  ) {
  }

  ngOnInit(): void {

    this.isTemplateCompany = this.userService.currentCompany.isTemplate ?? false;
    if (this.isTemplateCompany)
      this.layoutService.header = "Role Definition";

    this.companyRoleService.getCompanyPermissionMode().subscribe(res => {
      this.form.controls.permissionMode.setValue(res);
    });

    const companyRoles = this.companyRoleService.getCompanyRoles();
    const companyPermissions = this.companyUserService.getPermissions();
    const sitePermissions = this.siteUserService.getAllPermissions();

    this.inProgress = true;
    forkJoin({
      roles: companyRoles,
      companyPermissions: companyPermissions,
      sitePermissions: sitePermissions
    })
      .subscribe({
        next: (ret) => {
          this.companyRoles = ret["roles"];
          this.prepareSitePermissions({ ...ret['sitePermissions'] });
          this.prepareCompanyPermissions(
            { ...ret['companyPermissions'] },
            ret['sitePermissions'],
          );
        },
        error: (_) => {
          this.inProgress = false;
        },
        complete: () => {
          this.inProgress = false;
        }
      });
  }

  prepareCompanyPermissions(companyPermissions, sitePermissions) {
    let companyKeys = Object.keys(companyPermissions);
    let siteKeys = Object.keys(sitePermissions);
    this.companyUserPermissions = companyKeys.filter((companyKey) => {
      return !siteKeys.find((siteKey) => siteKey == companyKey);
    }).map(up => {
      return {
        value: false,
        key: +up,
        label: companyPermissions[up],
        validators: [],
      } as CheckListItemViewModel;
    });
  }

  prepareSitePermissions(permissions) {
    let keys = Object.keys(permissions);
    this.siteUserPermissions = keys.map(up => {
      return {
        value: false,
        key: +up,
        label: permissions[up],
        validators: [],
      } as CheckListItemViewModel;
    });
  }

  reset() {
    this.selectedCompanyRoleName = undefined;
    this.selectedRoleToUpdateName = undefined;
    this.selectedRoleToUpdatePermissions = undefined;
    this.roleDefinitionForm.reset();
    this.rolePermissionsForm.controls.roleId.setValue(null);
    this.rolePermissionsForm.controls.fullAccess.setValue(false);
    this.resetCompanyPermissions();
    this.resetSitePermissions();
  }
  close() {
    this.bsModalService?.hide();
  }

  submitPermissionMode() {
    if (this.form.controls.permissionMode.value == PermissionModeEnum.Role && (this.companyRoles == null || this.companyRoles.length == 0))
      this.modalService.error("You need to create roles in the 'Role Definition' tab to use this setting");
    else {
      this.inProgress = true;
      this.companyRoleService.setCompanyRoles({
        permissionMode: this.form.controls.permissionMode.value,
      }).subscribe({
        next: res => {
          this.companyPermissionMode = res;
          this.modalService.success('Permission mode updated successfully');
        },
        error: (err) => {
          this.modalService.error(err);
          this.inProgress = false;
        },
        complete: () => {
          this.inProgress = false;
        }
      });
    }
  }

  upsertCompanyRole(isUpdate: boolean = false) {
    this.inProgress = true;
    (!isUpdate ?
      this.companyRoleService.addCompanyRole({
        roleName: this.roleDefinitionForm.controls.roleName.value,
      })
      :
      this.companyRoleService.updateCompanyRole({
        roleId: this.selectedRoleToUpdateName.companyRoleId,
        roleName: this.selectedCompanyRoleName,
      }))
      .subscribe({
        next: (_) => {
          if (this.selectedRoleToUpdateName)
            this.modalService.success('The role updated successfully');
          else
            this.modalService.success('The new role submitted');
          this.reset();
          this.getCompanyRoles();
        },
        error: (err) => {
          this.modalService.error(err);
          this.inProgress = false;
        },
        complete: () => {
          this.inProgress = false;
        }
      });
  }

  getCompanyRoles() {
    this.inProgress = true;
    this.companyRoleService.getCompanyRoles()
      .subscribe({
        next: (res) => {
          this.companyRoles = res;
          this.inProgress = false;
        },
        error: (err) => {
          this.modalService.error(err);
          this.inProgress = false;
        },
        complete: () => {
          this.inProgress = false;
        }
      });
  }


  displayUpdateRoleNameInput(role: CompanyRoleViewModel) {
    this.selectedRoleToUpdateName = role;
    this.selectedCompanyRoleName = role.name;
  }

  displayUpdateRolePermissionsModal(template, role: CompanyRoleViewModel) {
    this.selectedRoleToUpdatePermissions = role;
    this.rolePermissionsForm.controls.roleId.setValue(role.companyRoleId);
    this.rolePermissionsForm.controls.fullAccess.setValue(role.isFullAccess);

    this.companyUserPermissions = this.companyUserPermissions.map((item: CheckListItemViewModel) => {
      item.value = (role.permissions & item.key) > 0;
      return item;
    })
    this.siteUserPermissions = this.siteUserPermissions.map((item: CheckListItemViewModel) => {
      item.value = (role.permissions & item.key) > 0;
      return item;
    })
    this.bsModalRef = this.bsModalService.show(template, Object.assign({},
      { class: 'gray modal-lg' })).onHide.subscribe((_) => {
        this.reset();
      });
  }

  deleteRole(role: CompanyRoleViewModel) {
    this.modalService.confirm("Are you sure you want to remove this role?", "Remove Role").subscribe(res => {
      if (res) {
        this.inProgress = true;
        this.companyRoleService.deleteCompanyRole({
          roleId: role.companyRoleId,
        })
          .subscribe({
            next: (_) => {
              this.modalService.success('The role deleted successfully');
              this.getCompanyRoles();
            },
            error: (err) => {
              this.modalService.error(err);
              this.inProgress = false;
            },
            complete: () => {
              this.inProgress = false;
            }
          });
      }
    })
  }

  getPermissionCount(role: CompanyRoleViewModel) {
    let selectedPermissionsCount = this.siteUserPermissions
      .filter(p => (p.key & role.permissions) > 0).length + this.companyUserPermissions
        .filter(p => (p.key & role.permissions) > 0).length;

    if (role.isFullAccess) {
      return 'All Permissions';
    }
    return selectedPermissionsCount + ' Permission(s)';
  }

  getPermissionLabels(role: CompanyRoleViewModel) {
    let tips = [];
    this.siteUserPermissions
      .filter(p => (p.key & role.permissions) > 0)
      .forEach(p => tips.push(p.label));
    this.companyUserPermissions
      .filter(p => (p.key & role.permissions) > 0)
      .forEach(p => tips.push(p.label));
    return tips;
  }


  get hasAnySitePermissionSelected() {
    return !(this.rolePermissionsForm.controls.fullAccess?.value ?? false) && this.siteUserPermissions?.findIndex((item) => item.value == true) > -1;
  }

  get hasAnyCompanyPermissionSelected() {
    return !(this.rolePermissionsForm.controls.fullAccess?.value ?? false) && this.companyUserPermissions?.findIndex((item) => item.value == true) > -1;
  }

  resetCompanyPermissions() {
    this.companyUserPermissions = this.companyUserPermissions?.map((item) => {
      item.value = false;
      return item;
    });
  }

  resetSitePermissions() {
    this.siteUserPermissions = this.siteUserPermissions?.map((item) => {
      item.value = undefined;
      return item;
    });
  }

  updateRolePermissions() {
    if (!this.selectedRoleToUpdatePermissions)
      return;

    this.bsModalService.hide();

    let isFullAccess = this.rolePermissionsForm.controls.fullAccess.value ?? false;

    let totalPermissionsKeys = [
      ...this.siteUserPermissions?.filter((key) => isFullAccess ? true : key.value)?.map((key) => key.key),
      ...this.companyUserPermissions?.filter((key) => isFullAccess ? true : key.value)?.map((key) => key.key),
    ];

    this.inProgress = true;
    this.companyRoleService.updateRolePermissions({
      roleId: this.selectedRoleToUpdatePermissions?.companyRoleId,
      permissions: totalPermissionsKeys.length > 0 ? combinePermissions(totalPermissionsKeys) :
        UserPermission.None,
      isFullAccess: isFullAccess,
    })
      .subscribe({
        next: (_) => {
          this.modalService.success('The role permissions updated successfully');
          this.getCompanyRoles();
          this.reset();
        },
        error: (err) => {
          this.modalService.error(err);
          this.inProgress = false;
        },
        complete: () => {
          this.inProgress = false;
        }
      });
  }
}
