import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { OptionalMandatoryState } from 'src/app/enums/site-document-state.enum';

@Component({
  selector: 'obc-optional-mandatory-selector',
  templateUrl: './optional-mandatory-selector.component.html',
})
export class OptionalMandatorySelectorComponent implements OnInit {

  @ViewChild('selector') selector: NgSelectComponent;
  @Input() set state(value: OptionalMandatoryState) {
    if (value == undefined || value == null)
      this.selectedState = OptionalMandatoryState.Optional;
    else
      this.selectedState = value;
  }
  @Output() onChangeSelectedState: EventEmitter<OptionalMandatoryState> = new EventEmitter();
  optionalMandatoryState = OptionalMandatoryState;
  selectedState: OptionalMandatoryState = OptionalMandatoryState.Optional;

  constructor() { }

  ngOnInit(): void {
  }
  onChangeState() {
    this.onChangeSelectedState.emit(this.selectedState);
  }
}
