import { Component, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from "../../../services/layout.service";
import { ModalService } from "../../../services/modal.service";
import { GridColumn, GridConfig, GridTemplate, RequestPayloadModeEnum } from "../../../models/grid/grid.config";
import { AttendanceDocumentReportViewModel } from "../../../models/attendance-document-report-viewmodel";
import { Table } from "../../../enums/table.enum";
import { FilterType } from "../../../enums/filter-type.enum";
import { TableSetting } from "../../../models/table-setting";
import { BsModalService } from "ngx-bootstrap/modal";
import { SupplierDocumentService } from "../../../services/supplier-document.service";
import { SiteSupplierDocumentStatus } from 'src/app/enums/site-supplier-document-status';

@Component({
  selector: 'obc-supplier-document-report',
  templateUrl: './supplier-document-report.component.html',
  styleUrls: ['./supplier-document-report.component.scss']
})
export class SupplierDocumentReportComponent implements OnInit {
  SiteSupplierDocumentStatus = SiteSupplierDocumentStatus;
  constructor(
    private layoutService: LayoutService,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private supplierDocumentService: SupplierDocumentService) { }

  ngOnInit() {
    this.layoutService.header = `Supplier Document Report`;
    this.prepareGridConfig();
  }

  @ViewChild('grid') grid: any;
  gridColumns: GridColumn<AttendanceDocumentReportViewModel>[] = [];
  gridConfig: GridConfig = {} as GridConfig;

  prepareGridConfig() {
    this.gridConfig = new GridConfig({
      apiUrl: '/api/SupplierDocument/site-supplier-document-report',
      tableType: Table.SWMSReport,
      displayGeneralSearch: true,
      generalSearchPlaceholder: "Filter result by Site Name, Supplier Name, Version Date and Document Type",
      csvReportUrl: '/api/SupplierDocument/site-supplier-document-report-export-csv-as-job',
      requestPayloadMode: RequestPayloadModeEnum.SeparateQueryKey,
      csvExportSubject: "Supplier Document Report"
    });
    this.gridColumns = [
      {
        name: "Site",
        key: "siteName",
        sortBy: "site",
        type: FilterType.Site,
        propertyNameInQuery: 'siteIdList',
      },
      {
        name: "Supplier",
        key: "supplierName",
        sortBy: "supplier",
        type: FilterType.Supplier,
        propertyNameInQuery: 'supplierIdList',
      },
      {
        name: "Document Title",
        key: "supplierDocumentTypeName",
        sortBy: "title",
        type: FilterType.DocumentType,
        propertyNameInQuery: 'supplierIdList',
        enableFilter: false,
      },
      {
        name: "Document Type",
        key: "documentKindName",
        sortBy: "documentKindId",
        type: FilterType.SupplierDocumentKind,
        propertyNameInQuery: 'documentKindList',
      },
      {
        name: "Date Submitted",
        type: FilterType.DateTimeRange,
        key: "registerDateTime",
        propertyNameInQuery: 'submitDateRange',
        isArray: false,
      },
      {
        name: "Version",
        key: "displayVersion",
        sortBy: "version",
        propertyNameInQuery: 'statusList',
        enableFilter: false,
      },
      {
        name: "Review Status",
        type: FilterType.SiteSupplierDocumentVersionStatus,
        key: "status",
        sortBy: "status",
        propertyNameInQuery: 'statusList',
      },
      {
        name: "Document History",
        key: 'documentHistory',
        enableSort: false,
        enableFilter: false,
        visible: false,
        showAsColumn: false,
        displayInCheckBoxSettings: true,
      },
      {
        name: "See Deactivated Sites",
        key: 'includeDisableSites',
        enableSort: false,
        enableFilter: false,
        visible: false,
        showAsColumn: false,
        hasIncludeFilter: true,
        propertyNameInQuery: "includeDisableSites",
        displayInCheckBoxSettings: true,
      },
      {
        name: "Expiration Date",
        type: FilterType.DateTimeRange,
        key: "expirationDate",
        propertyNameInQuery: 'expirationDateRange',
        isArray: false,
      },
      {
        name: "Details",
        key: 'viewDetails',
        type: FilterType.Template,
        enableSort: false,
        enableFilter: false,
        gridTemplate: new GridTemplate().ComponentTemplateName('viewDetails'),
        visible: true,
      }
    ];
  }

  data;

  openModal(template, data) {
    this.data = data;
    this.modalService.show(template, "modal-lg");
  }

  closeModal() {
    this.grid.loadData(0);
  }

  exportPdf(siteSupplierDocumentVersionId: number) {
    this.supplierDocumentService.exportDataAsPdf(siteSupplierDocumentVersionId);
  }

  siteSupplierDocumentTypeId = null;

  onViewVersions(template: any, siteSupplierDocumentTypeId: number) {
    this.siteSupplierDocumentTypeId = siteSupplierDocumentTypeId;
    this.bsModalService.show(template, {
      class: "modal-lg",
    })
  }

  checkGridSettingsForHistoryButton(settings: TableSetting) {
    return !!settings.columnSettings?.find((item) => item.key == 'documentHistory' && item.visible == true);
  }
}

