import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'obc-user',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  constructor(private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.layoutService.header = "User Info";
  }

}
