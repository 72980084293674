export enum RegisterAssetMode {
    SelectAsset,
    CreateAsset,
    CreateTemporarilyAsset
}

export enum RegisterAssetAsType {
    Company,
    CompanySupplier
}
