<div *ngIf="formGroup">
  <form [formGroup]="formGroup">
    <div class="d-flex flex-column" *ngIf="model">
      <div>
        <i *ngIf="valid" style="color: green;" class="fas fa-check-circle  mr-2"></i>
        <i *ngIf="!valid" style="color: red;" class="fas fa-times-circle  mr-2"></i>
        <obc-question-title-renderer [question]="model.question"></obc-question-title-renderer>
        <ng-container *ngIf="selectedItemHint?.length">
          <ng-container *ngFor="let hint of selectedItemHint">
            <ng-container *ngIf="hint && hint?.trim() != ''">
              <div class="small" style="color: gray;">
                <i class="fas fa-info-circle"></i> {{hint}}
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="d-flex justify-content-end">
        <div class="btn-group" *ngIf="model.question.renderMode == 1">
          <label *ngFor="let ctrl of controls" (click)="onClick(ctrl.item)"
            [class]="ctrl.item.selectedDisplayStyle|displayStyleCssClass:model.question.questionType:model.question.renderMode"
            [formControlName]="ctrl.item.title" btnCheckbox tabindex="0" role="button">
            <!-- displayStyle={{ctrl.item.selectedDisplayStyle}}
            <br> -->
            {{ctrl.item.title}}
          </label>
        </div>

        <div class="d-flex flex-row flex-wrap" *ngIf="model.question.renderMode == 0">
          <span *ngFor="let ctrl of controls" role="menuitem">
            <div class="form-check" style="margin: 5px 10px;">
              <label
                [class]="'form-check-label' + (ctrl.item.selectedDisplayStyle|displayStyleCssClass:model.question.questionType:model.question.renderMode)"><!-- style="background-color: unset !important;" class="form-check-label" -->
                <input (click)="onCheckChange(ctrl.item)" [formControlName]="ctrl.item.title" type="checkbox"
                  class="form-check-input">
                  <!-- displayStyle={{ctrl.item.selectedDisplayStyle}}
            <br> -->
                {{ctrl.item.title}}
              </label>
            </div>
          </span>
        </div>

        <!-- <div class="btn-group" *ngIf="model.question.renderMode == 0" dropdown #dropdown="bs-dropdown"
          [autoClose]="false">
          <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
            Select Answers <span class="caret"></span>
          </button>
          <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu" role="menu"
            aria-labelledby="button-triggers-manual">
            <li *ngFor="let ctrl of controls" role="menuitem">
              <div class="form-check" style="margin: 5px 10px;">
                <input [formControlName]="ctrl.item.title" type="checkbox" class="form-check-input">
                <label style="background-color: unset !important;" class="form-check-label">{{ctrl.item.title}}</label>
              </div>
            </li>
          </ul>
        </div> -->
      </div>

    </div>
  </form>
</div>