import { Pipe, PipeTransform } from '@angular/core';
import { DisplayStyle } from '../enums/display-style';
import { QuestionType } from '../enums/question-type';
import { SingleSelectRenderMode } from '../models/questionParameters/single-select-render-type';
import { MultiSelectRenderMode } from '../models/questionParameters/multi-select-render-type';

@Pipe({
  name: 'displayStyleCssClass'
})
export class DisplayStyleCssClassPipe implements PipeTransform {

  transform(value: any, questionType?: QuestionType, renderMode?: MultiSelectRenderMode | SingleSelectRenderMode): string {

    if (!questionType && !renderMode) {
      var result = '';
      if (value === DisplayStyle.Success) result = 'text-success';
      if (value === DisplayStyle.Warning) result = 'text-warning';
      if (value === DisplayStyle.Error) result = 'text-danger';
      if (value === DisplayStyle.Default) result = '';
      return `${result}`;
    }

    let customCss = this.getCssClass(value, questionType, renderMode);
    return customCss?.trim()?.length ? ` ${customCss}` : '';
  }

  getCssClass(value: any, questionType: QuestionType, renderMode: MultiSelectRenderMode | SingleSelectRenderMode): string {
    if (questionType == QuestionType.SingleSelect) {
      switch (renderMode) {

        case SingleSelectRenderMode.Button:
        case SingleSelectRenderMode.RadioButton:
          if (value === DisplayStyle.Success) return 'custom-style btn btn-success';
          if (value === DisplayStyle.Warning) return 'custom-style btn btn-warning';
          if (value === DisplayStyle.Error) return 'custom-style btn btn-danger';
          if (value === DisplayStyle.Default) return 'custom-style btn btn-primary';
          return 'btn btn-primary';
        case SingleSelectRenderMode.DropDown:
          if (value === DisplayStyle.Success) return 'custom-style text-success';
          if (value === DisplayStyle.Warning) return 'custom-style text-warning';
          if (value === DisplayStyle.Error) return 'custom-style text-danger';
          if (value === DisplayStyle.Default) return 'custom-style text-primary';
          return '';

        default:
          break;
      }
    }
    else if (questionType == QuestionType.MultiSelect) {
      switch (renderMode) {
        case MultiSelectRenderMode.Button:
          if (value === DisplayStyle.Success) return 'custom-style btn btn-success';
          if (value === DisplayStyle.Warning) return 'custom-style btn btn-warning';
          if (value === DisplayStyle.Error) return 'custom-style btn btn-danger';
          if (value === DisplayStyle.Default) return 'custom-style btn btn-primary';
          return 'btn btn-primary';//value?.toString() ?? '?';
        case MultiSelectRenderMode.CheckBoxList:
          if (value === DisplayStyle.Success) return 'custom-style text-success';
          if (value === DisplayStyle.Warning) return 'custom-style text-warning';
          if (value === DisplayStyle.Error) return 'custom-style text-danger';
          if (value === DisplayStyle.Default) return 'custom-style text-primary';
          return '';//value?.toString() ?? '?';
        default:
          break;
      }
    }
    return '';
  }

}
