import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'obc-site-activity',
  templateUrl: './site-activity.component.html',

  styleUrls: ['./site-activity.component.css']
})
export class SiteActivityComponent implements OnInit {
  ngOnInit(): void { }
}
