export class SiteAttendanceAccompanierModel {
  firstName: string;
  lastName: string;
  mobile: string;

  clone(): SiteAttendanceAccompanierModel {
    var newPerson = new SiteAttendanceAccompanierModel();
    newPerson.firstName = this.firstName;
    newPerson.lastName = this.lastName;
    newPerson.mobile = this.mobile;
    return newPerson;
  }
}
