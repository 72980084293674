import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'obc-accordion-header',
  templateUrl: './accordion-header.component.html',
  styleUrls: ['./accordion-header.component.scss']
})
export class AccordionHeaderComponent implements OnInit {

  @Input() mainTitle: string = '';
  @Input() icon: string = '';
  @Input() iconColor: string = '#333';
  @Input() invalidControlsCount: number = 0;
  @Input() isAccordionDirty: boolean = false;
  @Input() info: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
