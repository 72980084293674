import { Pipe, PipeTransform } from '@angular/core';
import { ApproveEntityType } from "../enums/approve-entity-type";

@Pipe({
  name: 'approveEntityType'
})
export class ApproveEntityTypePipe implements PipeTransform {

  transform(value: any): string {
    if (value == ApproveEntityType.Induction || value == 1 || value=="Induction") return "Induction";
    if (value == ApproveEntityType.SiteSupplierDocument || value == 2 || value=="SiteSupplierDocument") return "Supplier Document";
    if (value == ApproveEntityType.Attendance || value == 4 || value=="Attendance") return "Attendance";
    if (value == ApproveEntityType.PermitForms || value == 5 || value=="PermitForms") return "Permit";
    if (value == ApproveEntityType.SiteAsset  || value == 6 || value=="SiteAsset") return "Site Asset";
    if (value == ApproveEntityType.SiteAssetOperator || value == 7 || value=="SiteAssetOperator") return "Asset Operator";
    if (value == ApproveEntityType.SupplierForms || value == 8 || value=="SupplierForms") return "Supplier Form"
    return value?.toString() ?? "";
  }

}
