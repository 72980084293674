import { Component, OnInit } from '@angular/core';
import { ModalService, State, Options, Icon } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  visible: boolean = false;
  mode: string = '';
  message: string = '';
  header: string = '';
  state: State;
  isConfirm: boolean = false;
  icon: string = '';
  trueLabel: string = '';
  falseLabel: string = '';
  trueButtonClass: string = '';
  falseButtonClass: string = '';
  isDangerState: boolean = false;
  constructor(private modalService: ModalService) { }

  ngOnInit() {
    this.mode = '';
    this.modalService.confirmMessage.subscribe((option: Options) => {
      if (!option) { this.mode = ''; this.visible = false; return; };
      this.isDangerState = option.isDangerState ?? false;
      this.mode = State[option.state];
      this.visible = true;
      this.message = option.message;
      this.header = option.header;
      this.icon = this.isDangerState ? Icon[State['modal-block-danger']] : Icon[option.state];
      this.isConfirm = option.state === State["modal-block-primary"];
      this.trueLabel = (!option.trueButtonLabel) ? this.isConfirm ? 'Yes' : 'Ok' : option.trueButtonLabel;
      this.falseLabel = (!option.trueButtonLabel) ? 'No' : option.falseButtonLabel;
      this.trueButtonClass = (!option.trueButtonClass) ? (this.isDangerState ? 'btn-danger' : 'btn-success') : option.trueButtonClass;
      this.falseButtonClass = (!option.falseButtonClass) ? (this.isDangerState ? 'btn-default' : 'btn-danger') : option.falseButtonClass;
    })
  }

  cleanup() {
    this.visible = false;
    this.message = '';

  }
  close() {
    this.do(null);
  }

  ok() {
    this.do(true);
  }

  cancel() {
    this.do(false);
  }

  do(result: boolean | null) {
    this.cleanup();
    this.modalService.closeConfirm(result);
  }
}
