import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/services/layout.service';
import { ProductPlanService } from 'src/app/services/product-plan.service';
import { FullProductPlanViewmodel } from 'src/app/models/product-plan-viewmodel';

@Component({
  selector: 'obc-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  constructor(public layoutService: LayoutService, private productPlanService: ProductPlanService) { }
  activeProductPlan: FullProductPlanViewmodel;
  ngOnInit() {
    this.layoutService.header = "Billing";
    this.productPlanService.getActivePlan().subscribe(res => {
      this.activeProductPlan = res;
    });
  }

  get activeSiteCountLabel(): string {
    return this.activeProductPlan.activeSiteCount == null ? "Unlimited" : `Up to ${this.activeProductPlan.activeSiteCount}`;
  }
  get averageCheckInCountPerDayLabel(): string {
    return this.activeProductPlan.averageCheckInCountPerDay == null ? "Unlimited" : `Up to ${this.activeProductPlan.averageCheckInCountPerDay}`;
  }
  get numberOfQuestionLabel(): string {
    return this.activeProductPlan.numberOfQuestions == null ? "Unlimited" : `Up to ${this.activeProductPlan.numberOfQuestions}`;
  }
  get numberOfUserLabel(): string {
    return this.activeProductPlan.numberOfUsers == null ? "Unlimited" : `Up to ${this.activeProductPlan.numberOfUsers}`;
  }
}
