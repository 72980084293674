import { Pipe, PipeTransform } from '@angular/core';
import { ReportPeriodType } from '../enums/report-type.enum';

@Pipe({
  name: 'reportPeriodType'
})
export class ReportPeriodTypePipe implements PipeTransform {

  transform(value: any): string {
    return value == ReportPeriodType.Daily ? "Daily" :
      value == ReportPeriodType.Weekly ? "Weekly" :
        value == ReportPeriodType.Monthly ? "Monthly" :
          (value?.toString() ?? "?");
  }

}
