import { Directive, ElementRef, OnInit, Output, EventEmitter, Input } from '@angular/core';
import interact from 'interactjs';
import { RectInfo } from '../models/print-layout/print-layout-model';

@Directive({
  selector: '[obcDraggable]'
})
export class DraggableDirective implements OnInit {
  @Output() rectInfoChanged: EventEmitter<RectInfo> = new EventEmitter<RectInfo>();
  _elementPosition: RectInfo;

  @Input() rectInfo: RectInfo = new RectInfo();

  constructor(private elem: ElementRef) {
    this.elem.nativeElement
  }

  ngOnInit() {
    var that = this;
    interact(this.elem.nativeElement)
      .draggable({
        // enable inertial throwing
        inertia: true,
        maxPerElement : 2,
        // keep the element within the area of it's parent
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true
          })
        ],
        // enable autoScroll
        autoScroll: true,

        listeners: {
          // call this function on every dragmove event
          move: (event: any) => this.dragMoveListener(event, that, false),

          // call this function on every dragend event
          end(event) {
            // var textEl = event.target.querySelector('p')

            // textEl && (textEl.textContent =
            //   'moved a distance of ' +
            //   (Math.sqrt(Math.pow(event.pageX - event.x0, 2) +
            //     Math.pow(event.pageY - event.y0, 2) | 0))
            //     .toFixed(2) + 'px')
          }
        }
      }).resizable({
        // resize from all edges and corners
        edges: { left: true, right: true, bottom: true, top: true },

        listeners: {
          move: (event: any) => this.dragMoveListener(event, that, true)
        },
        modifiers: [
          // keep the edges inside the parent
          interact.modifiers.restrictEdges({
            outer: 'parent'
          }),

          // minimum size
          interact.modifiers.restrictSize({
            min: { width: 100, height: 50 }
          })
        ],

        inertia: true
      });
  }

  dragMoveListener(event, that, isResize) {
    var target = event.target
    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute('data-x')) || 0);
    var y = (parseFloat(target.getAttribute('data-y')) || 0);

    // update the element's style
    target.style.width = event.rect.width + 'px'
    target.style.height = event.rect.height + 'px'

    that.rectInfo.width = Math.floor(event.rect.width * 10) / 10;
    that.rectInfo.height = Math.floor(event.rect.height * 10) / 10;

    if (isResize === true) {
      // translate when resizing from top or left edges
      x += event.deltaRect.left
      y += event.deltaRect.top
    }
    else {
      x += event.dx;
      y += event.dy;
    }
    // translate the element
    target.style.webkitTransform =
      target.style.transform =
      'translate(' + x + 'px, ' + y + 'px)'

    // update the posiion attributes
    // target.setAttribute('data-x', x)
    // target.setAttribute('data-y', y)

    that.rectInfo.top = Math.floor(y * 10) / 10;
    that.rectInfo.left = Math.floor(x * 10) / 10;
    // this.rectInfoChanged.emit({
    //   left: Math.floor(x * 10) / 10,
    //   top: Math.floor(y * 10) / 10,
    //   height: Math.floor(that._elementPosition.height * 10) / 10,
    //   width: Math.floor(that._elementPosition.width * 10) / 10
    // });
  }
}
