<div *ngIf="hasExportFeatureOnCompany()" class="btn-group" dropdown [dropup]="isDropUp" placement="bottom right">
    <button [disabled]="disabled"
            (click)="updateCsvReportTitle()"
            dropdownToggle type="button"
            class="btn btn-success dropdown-toggle {{ disabled ? 'disabled' : ''}}" aria-controls="dropdown-basic">
        Export as CSV <span class="caret"></span>
    </button>
    <div class="p-4 csv-popover dropdown-menu dropdown-menu-right" (click)="onCsvPopoverClick($event)"
         *dropdownMenu>
        <form [formGroup]="csvFormGroup" (submit)="exportCsv()">
            <h2 style="font-size: 18px;">Export as CSV configuration</h2>
            <div class="form-floating mb-3">
                <label class="mb-1 control-label">CSV title</label>
                <input type="email" [formControl]="csvFormGroup.controls.title" class="form-control" required
                       placeholder="name@example.com">
            </div>
            <div class="form-floating mb-3">
                <label class="mb-1 control-label">Email</label>
                <input type="email" [formControl]="csvFormGroup.controls.email" class="form-control"
                       placeholder="name@example.com">
            </div>
            <ng-container *ngIf="displayColumnFilterRadios">
              <div class="custom-control custom-radio mb-1">
                <input type="radio" id="exportCurrent-{{id}}" [formControl]="csvFormGroup.controls.csvExportOnlyVisible" [value]="true"
                       class="custom-control-input">
                <label class="custom-control-label" for="exportCurrent-{{id}}">Export Current View</label>
              </div>
              <div class="custom-control custom-radio mb-3">
                  <input type="radio" id="exportAll-{{id}}" [formControl]="csvFormGroup.controls.csvExportOnlyVisible" [value]="false"
                         class="custom-control-input">
                  <label class="custom-control-label" for="exportAll-{{id}}">Export All Columns</label>
              </div>
            </ng-container>
            <div class="d-flex flex-column">
                <button [disabled]="!csvFormGroup.valid" class="btn btn-primary mb-2" type="submit">Confirm</button>
                <button id="cancel" type="button" class="btn btn-default">Cancel</button>
            </div>
        </form>
    </div>
</div>
