import { Component, OnInit, Input } from '@angular/core';
import { QuestionRendererComponent } from '../question-renderer/question-renderer.component';
import { FormBuilder } from '@angular/forms';
import { SiteQuestionItemFormControl, SiteQuestionRendererViewModel } from 'src/app/models/site-question-viewmodel';
import { CacheService } from "../../../../services/cache.service";

@Component({
  selector: 'obc-single-select-question-renderer',
  templateUrl: './single-select-question-renderer.component.html',
  styleUrls: ['./single-select-question-renderer.component.scss']
})
export class SingleSelectQuestionRendererComponent extends QuestionRendererComponent implements OnInit {
  selectedItemHint: string;
  @Input() set question(model: SiteQuestionRendererViewModel) {
    this.model = model;
    if (this.model.question.renderMode == 0) {
      if (!this.model.question.items.some(x => x.value == null))
        this.model.question.items.splice(0, 0, { title: "Not Selected", value: null })
    }
    let answer: string = null;
    if(model.initialAnswers && model.initialAnswers.length) {
      answer = model.initialAnswers[0]?.answer;
      this.updateHintAndStyle(answer);
    }
    this.formGroup = this.fb.group({
      value: new SiteQuestionItemFormControl(answer, null)
    });

  };

  get items(): any[] {
    return this.model.question.items;
  }

  constructor(private fb: FormBuilder, public cacheService: CacheService) {
    super(cacheService)
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onItemChange($event?) {
    if ($event == null) return;

    let value = this.formGroup.controls["value"].value as string;
    this.updateHintAndStyle(value);
  }

  updateHintAndStyle(answer) {
    let questionItem = this.model.question.items.find(p => p.value == answer);
    if (questionItem?.hint)
      this.selectedItemHint = questionItem.hint;
    this.model.question.items.map(p => {
      (<any>p.selectedDisplayStyle) = "";
    })
    questionItem.selectedDisplayStyle = questionItem.displayStyle;
  }

  onCheckChange(item: any) {
    if (item == null) return;

    if (item?.hint)
      this.selectedItemHint = item.hint;

    this.model.question.items.map(p => {
      (<any>p.selectedDisplayStyle) = "";
    })
    item.selectedDisplayStyle = item.displayStyle;

  }
}
