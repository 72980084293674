import { QuestionParameter } from "./question-parameter";

export class DateQuestionParameter extends QuestionParameter {
    isRequired: boolean;
    includeTime: boolean;
    limitForMinimumValue: DateQuestionLimit;
    limitForMaximumValue: DateQuestionLimit;
}

export class DateQuestionLimit {
    valueMode: DateQuestionLimitValueMode;
    dateComparisonMode: DateComparisonMode;
    dayCount: number;
    compareValue?: string;
}

export enum DateQuestionLimitValueMode {
    Relative = 0,
    Absolute = 1,
}

export enum DateComparisonMode {
    Before = 0,
    After = 1,
}