<div class="row">
  <div class="col-md-4 col-sm-12">
    <obc-user-autocomplete #mobile [currentUser]="finalUser" placeholder="Mobile" [displayNoResult]="false" display="mobile"
                           (inputChange)="onInputChange($event)" [filterBy]="FilterUserBy.All"
                           (selectedUser)="onSelectUser($event)" [filterByCurrentUserRegion]="filterByCurrentUserRegion">
    </obc-user-autocomplete>
    <div *ngIf="this.finalUser?.mobile == null" class="invalid-feedback" style="display: block;">
      Please enter valid mobile number
    </div>
    <div *ngIf="this.finalUser?.mobile != null && isMobileValid === false" class="invalid-feedback"
         style="display: block;">
      Mobile number is not valid
    </div>
    <div *ngIf="this.finalUser?.mobile == null && successMessage && successMessage.length > 0"
         class="valid-feedback" style="display: block;">
      {{successMessage}}
    </div>
  </div>
  <div class="col-md-4 col-sm-12">
    <obc-user-autocomplete  #lastname [currentUser]="finalUser" placeholder="Family" [displayNoResult]="false" display="lastName"
                           (inputChange)="onInputChange($event)" [filterBy]="FilterUserBy.All"
                           (selectedUser)="onSelectUser($event)" [filterByCurrentUserRegion]="filterByCurrentUserRegion">
    </obc-user-autocomplete>
  </div>
  <div class="col-md-4 col-sm-12">
    <obc-user-autocomplete  #firstname [currentUser]="finalUser" placeholder="Name" [displayNoResult]="false" display="firstName"
                           (inputChange)="onInputChange($event)" [filterBy]="FilterUserBy.All"
                           (selectedUser)="onSelectUser($event)" [filterByCurrentUserRegion]="filterByCurrentUserRegion">
    </obc-user-autocomplete>
  </div>

  
</div>