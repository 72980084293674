import { Pipe, PipeTransform } from '@angular/core';
import { PermitOpenPolicy } from '../enums/permit-open-policy.enum';

@Pipe({
  name: 'permitOpenPolicy'
})
export class PermitOpenPolicyPipe implements PipeTransform {


  transform(value: unknown): any {
    if (value === PermitOpenPolicy.NoRestricted) { return "Not Restricted"; }
    if (value === PermitOpenPolicy.RestrictedToScheduled) { return "Restricted to schedule"; }
    if (value === PermitOpenPolicy.RestrictedToOnlyScheduledDay) { return "Restricted To Only Schedule Day"; }
    return value;
  }

}
