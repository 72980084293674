<obc-loader [show]="inProgress"></obc-loader>

<!-- <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>Select Site</label>
                <app-site-selector [required]="false" [activeStatus]="SiteActiveStatus.All"
                  (siteSelect)="setCurrentSite($event)">
                </app-site-selector>
              </div>
            </div>
          </div> -->

<div class="row" *ngIf="serverResult">
  <div class="col-md-12 mt-2">
    <h5 class="pull-left">
      <span>
        {{ serverResult?.totalCount }} Record(s)
      </span>
    </h5>
    <button class="btn btn-primary pull-right" style="min-width: 140px" (click)="fetchPendingList()">
      Refresh
    </button>
  </div>
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table table-bordered table-striped mt-3">
        <thead>
          <tr>
            <td>
              Site Name
            </td>
            <td>
              User Name
            </td>
            <td>
              Mobile
            </td>
            <td>
              Check In Date
            </td>
            <td></td>
          </tr>
        </thead>
        <tbody *ngIf="!serverResult || ( serverResult && serverResult?.totalCount == 0)">
          <tr>
            <td colspan="10">
              <p class="text-center mb-0">
                There is no pre check in to finalise
              </p>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="serverResult?.totalCount > 0">
          <ng-container *ngFor="let item of serverResult?.data">
            <tr>
              <td>
                {{ item.siteName }}
              </td>
              <td>
                {{ item.user?.firstName }} {{ item.user?.lastName }}
              </td>
              <td>
                {{ item.user?.mobile | mobile }}
              </td>
              <td>
                {{ item.checkInDate | datetime }}
              </td>
              <td>
                <div class="d-flex">
                  <div>
                    <button class="btn btn-success" (click)="finaliseOrCancelPreCheckin(item, true)">
                      Finalise Pre Check-in
                    </button>
                    <button class="btn btn-danger ml-2" (click)="finaliseOrCancelPreCheckin(item, false)">Cancel
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
        <tfoot *ngIf="serverResult && serverResult?.totalCount > 0">
          <tr>
            <td [attr.colspan]="5">
              <obc-pagination [totalCount]="serverResult?.totalCount" [pageSize]="pagingInfo.pageSize"
                [pageNumber]="pagingInfo.pageNumber" (pageChanged)="onPageChange($event)"></obc-pagination>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
  <div class="col-md-12 mt-2" style="text-align: end">
    <!--<button *ngIf="userService.hasCompanyFeature(ProductPlanFeature.Export)" type="button" [disabled]="!report"
                    class="btn btn-success" (click)="exportCsv()">
              <i class="fas fa-file-excel-o"></i>
              Export As CSV
            </button>-->
    <!--            <button type="button" class="mb-1 mt-1 ml-1 btn btn-info">-->
    <!--              <i class="fas fa-file-pdf-o"></i>-->
    <!--              Export As PDF-->
    <!--            </button>-->
  </div>
</div>