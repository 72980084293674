import { Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { QuestionViewModel } from '../models/question-viewmodel';
import { QuestionDefaultMode } from '../enums/question-default-mode';
import { QuestionListQueryModel } from '../models/question-list-query-model';
import { PagingViewModel } from '../models/site-list-paging-view-model';
import { AddQuestionConditionModel, CrudQuestionConditionModelResponse } from '../models/question-condition-models';
import { QuestionProfileSubTypeViewModel } from '../models/question-profile-sub-type-viewmodel';


@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getQuestions(): Observable<QuestionViewModel[]> {
    return this._http.get('/api/question/question-list').pipe(catchError(this._errorService.handleError));
  }

  getActiveQuestions(query?: QuestionListQueryModel): Observable<PagingViewModel<QuestionViewModel>> {
    return this._http.post('/api/question/question-list/active', query).pipe(catchError(this._errorService.handleError));
  }

  getInactiveQuestions(query: QuestionListQueryModel): Observable<PagingViewModel<QuestionViewModel>> {
    return this._http.post('/api/question/question-list/inactive', query).pipe(catchError(this._errorService.handleError));
  }

  addNewQuestion(model: QuestionViewModel) {
    return this._http.post('/api/question/add', model).pipe(catchError(this._errorService.handleError));
  }

  addWithResultNewQuestion(model: QuestionViewModel) {
    return this._http.post('/api/question/add-with-result', model).pipe(catchError(this._errorService.handleError));
  }

  updateQuestion(model: QuestionViewModel) {
    return this._http.post(`/api/question/${model.id}/update`, model).pipe(catchError(this._errorService.handleError));
  }

  deleteQuestion(questionId: number) {
    return this._http.delete(`/api/question/${questionId}`).pipe(catchError(this._errorService.handleError));
  }

  addRemoveQuestionToAllSites(questionId: Number, addQuestionToAllSites: boolean, checkInMode: QuestionDefaultMode): Observable<boolean> {
    return this._http.post(`/api/question/add-to-all-site`, { questionId: questionId, addQuestionToAllSites: addQuestionToAllSites, checkInMode: checkInMode })
      .pipe(catchError(this._errorService.handleError));
  }

  addQuestionCondition(model: AddQuestionConditionModel) : Observable<CrudQuestionConditionModelResponse> {
    return this._http.post<CrudQuestionConditionModelResponse>(`/api/question/add-question-condition`, model);
  }

  removeQuestionCondition(id: number) : Observable<CrudQuestionConditionModelResponse> {
    return this._http.get<CrudQuestionConditionModelResponse>(`/api/question/remove-question-condition/${id}`);
  }

  getQuestionProfileSubTypes(): Observable<QuestionProfileSubTypeViewModel[]> {
    return this._http.get('/api/question/question-profile-sub-types')
      .pipe(catchError(this._errorService.handleError));
  }
}
