<div>
  <ng-select style="padding-bottom: 0 !important;" class="site-selector" [typeaheadOptionsLimit]="7"
    [typeaheadMinLength]="0" [items]="assets$ | async"
    [placeholder]="formControl.value ? null : (_required ? 'Please Select Asset' : (notSelectedSiteLabel ? notSelectedSiteLabel : 'All Assets'))"
    bindLabel="title" (scrollToEnd)="onScrollToEnd()" bindValue="id" [typeahead]="term$" [loading]="loading"
    [clearable]="!_required" [formControl]="formControl">
    <ng-template ng-option-tmp let-item="item">
      {{item.title}}
    </ng-template>
  </ng-select>
</div>