<div class="row">
  <div class="col-12">
    <div class="card">
      <obc-loader [show]="inProgress"></obc-loader>
      <header class="card-header">Search Site Attendance</header>
      <obc-descriptor [closePanelName]="'siteAtttendance'"
        [text]="'This allows you to view who is on site at any particular time. You can view all users or filter by a particular user.'">
      </obc-descriptor>
      <div class="card-body">
        <form [formGroup]="searchQueryForm" class="row">
          <div class="col-12 col-md-6 col-xl-4">
            <div class="flex flex-column">
              <label>Date Time</label>
              <obc-input-date-time-picker class="input-group-append" formControlName="date">
              </obc-input-date-time-picker>
            </div>
          </div>
        </form>
        <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>
          <ng-template obcGridTemplateName="checkInDateTime" let-item="data">
            <span *ngIf="isShowTemplateColumns('checkInDate','checkInDate')">
              {{item.checkInDate | datetime}}
            </span>
            <small *ngIf="isShowTemplateColumns('checkInDate','checkInForm') && item.checkInForm">
              ({{item.checkInForm}})
            </small>
          </ng-template>

          <ng-template obcGridTemplateName="checkOutDateTime" let-item="data">
            <span *ngIf="isShowTemplateColumns('checkOutDate','checkOutDate')">
              {{item.checkOutDate | datetime}}
            </span>
            <small *ngIf="isShowTemplateColumns('checkOutDate','checkOutForm') && item.checkOutForm">
              ({{item.checkOutForm}})
            </small>
          </ng-template>

          <ng-template obcGridTemplateName="viewDetails" let-item="data">
            <button class="btn btn-info pull-right"
              *ngIf=" userService.hasUserPermissionForCompany(UserPermission.ViewAttendanceAnswers, item.siteId)"
              (click)="openModal(questionAnswer,item.siteAttendanceId,true, item.checkOutDate!=null)">
              Details
            </button>
          </ng-template>
        </app-base-grid>
      </div>
    </div>
  </div>
</div>

<ng-template #questionAnswer>
  <obc-question-answer [questionAnswerInput]="questionAnswerInputModel" (result)="closeModal($event)">
  </obc-question-answer>
</ng-template>
