import { UserEditPhoneComponent } from './components/user-edit-phone/user-edit-phone.component';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SecureComponent } from './layout/secure/secure.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CompanyComponent } from './components/company/company.component';
import { UserComponent } from './components/user/user.component';
import { PublicComponent } from './layout/public/public.component';
import { SiteAttendancePermissionComponent } from './components/site-permissions/site-attendance-permission/site-attendance-permission.component';
import { LoginComponent } from './auth/login/login.component';
import { QuestionManagerComponent } from './components/question/question-manager/question-manager.component';
import { SiteActivityComponent } from './components/site-activity/site-activity.component';
import { SiteAttendanceComponent } from './components/site-attendance/site-attendance.component';
import { UserDocumentTypesComponent } from './components/user-document-types/user-document-types.component';
import { GodComponent } from './components/god/god.component';
import { ManageCompaniesComponent } from './components/god/manage-companies/manage-companies.component';
import { SiteAttendanceBlockedUserComponent } from './components/site-permissions/site-attendance-blocked-user/site-attendance-blocked-user.component';
import { ManageVideosComponent } from './components/manage-videos/manage-videos.component';
import { VideoListComponent } from './components/video-list/video-list.component';
import { ManualCheckComponent } from './components/manual-check/manual-check.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { CompanyGuardService } from './services/company-guard.service';
import { CompanyUserComponent } from './components/site-permissions/company-user/company-user.component';
import { FlaggedUserComponent } from './components/site-permissions/flagged-user/flagged-user.component';
import { CompanyOwnerComponent } from './components/company-owner/company-owner.component';
import { BillingComponent } from './components/company-owner/billing/billing.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CompanySettingsComponent } from './components/company-settings/company-settings.component';
import { ManagePlansComponent } from './components/god/manage-plans/manage-plans.component';
import { SiteAutomationsComponent } from './components/company-owner/site-automations/site-automations.component';
import { CardDesignerComponent } from './components/designer/card-designer/card-designer.component';
import { AnonymousComponent } from './layout/anonymouse/anonymous.component';
import { AnonymousCheckComponent } from './components/anonymous-check/anonymous-check.component';
import { AnonymousCheckinComponent } from './components/anonymous-check/anonymous-checkin/anonymous-checkin.component';
import { SuccessfullAnonymousCheckinComponent } from './components/anonymous-check/anonymous-checkin/successfull-anonymous-checkin/successfull-anonymous-checkin.component';
import { ManageIndustryTypesComponent } from './components/god/manage-industry-types/manage-industry-types.component';
import { AnnouncementManagerComponent } from './components/announcement-manager/announcement-manager.component';
import { ScannableItemsComponent } from './components/scannables/scannable-items/scannable-items.component';
import { ScannableGroupsComponent } from './components/scannables/scannable-groups/scannable-groups.component';
import { ImportSitesComponent } from './components/site/import-sites/import-sites.component';
import { MyScheduledReportsComponent } from './components/site-activity/my-scheduled-reports/my-scheduled-reports.component';
import {
  CompanyAdminGuardService,
  GodUserGuardService,
  OverLappedPermissionGuardService,
  PermissionGuardService,
  SiteAdminGuardService,
  SitesPageGuardService,
} from './services/permission-guard.service';
import { SiteUserComponent } from './components/site-permissions/site-user/site-user.component';
import { CombinedUserPermissionList, UserPermission } from './enums/user-permission.enum';
import { AnonymousCheckoutComponent } from './components/anonymous-check/anonymous-checkout/anonymous-checkout.component';
import { FeatureGuardService } from './services/feature-guard.service';
import { ProductPlanFeature } from './enums/product-plan-feature.enum';
import { QuickSetupComponent } from './components/quick-setup/quick-setup.component';
import { HostPushNotificationComponent } from './components/host-push-notification/host-push-notification.component';
import { CompanyEmployeeComponent } from './components/site-permissions/company-employee/company-employee.component';
import { DocumentReportComponent } from './components/document-report/document-report.component';
import { CompanySupplierComponent } from './components/company-supplier/company-supplier.component';
import { EditCompanySupplierComponent } from './components/company-supplier/edit-company-supplier/edit-company-supplier.component';
import { InventoryToolReportComponent } from './components/inventory/reports/inventory-tool-report/inventory-tool-report.component';
import { FormManageComponent } from './components/form-manage/form-manage/form-manage.component';
import { InductionManageComponent } from './components/induction/induction-manage/induction-manage.component';
import { SupplierDocumentsComponent } from './components/supplier-documents/supplier-documents.component';
import { SiteSupplierDocumentUploaderContainerComponent } from './components/supplier-documents/site-supplier-document-uploader-container/site-supplier-document-uploader-container.component';
import { FormReportComponent } from './components/form-manage/form-report/form-report.component';
import { SiteManagementComponent } from "./components/site-management/site-management.component";
import { ProcoreConfigurationAccessCodeCallbackComponent } from './components/company-integrations/procore-configuration-access-code-callback/procore-configuration-access-code-callback.component';
import { CompanyIntegrationsComponent } from './components/company-integrations/company-integrations.component';
import { CanDeactivateGuard } from "./services/can-deactivate-guard.service";
import { PendingToApproveListComponent } from "./components/pending-approval/pending-to-approve-list/pending-to-approve-list.component";
import { MyobConfigurationAccessCodeCallbackComponent } from './components/company-integrations/myob-configuration-access-code-callback/myob-configuration-access-code-callback.component';
import { PermitReportComponent } from './components/form-manage/permit/permit-report/permit-report.component';
import { SupplierFormsComponent } from "./components/supplier-forms/supplier-forms.component";
import { SupplierAnonymousFormsComponent } from "./components/supplier-forms/supplier-anonymous-forms/supplier-anonymous-forms.component";
import { SupplierJoinToCompanyComponent } from "./components/supplier-join-to-company/supplier-join-to-company.component";
import { SecurityLogReportComponent } from './components/security-log-report/security-log-report.component';
import { SiteAccessControlUnitManualComponent } from './components/site-access-control-unit-manual/site-access-control-unit-manual.component';
import { ManageSiteAssetComponent } from './components/site-asset/manage-site-asset/manage-site-asset.component';
import { TemplateCompanyAssetManagerComponent } from './components/inventory/template-company-asset-manager/template-company-asset-manager.component';
import { ManageGlobalDynamicFieldsComponent } from './components/inventory/manage-global-dynamic-fields/manage-global-dynamic-fields.component';
import { NotificationSettingComponent } from './components/notification-setting/notification-setting.component';
import { PermissionContainerComponent } from './components/permission-container/permission-container.component';
import { EvacuationReportComponent } from './components/evacuation-report/evacuation-report.component';
import { GodUserPermission } from './enums/god-user-permission.enum';
import { SupplierDocumentReportComponent } from "./components/supplier-documents/supplier-document-report/supplier-document-report.component";
import { PermissionsSettingsComponent } from "./components/company-settings/permissions-settings/permissions-settings.component";
import { ProcoreSyncMonitoringComponent } from './components/procore-sync-monitoring/procore-sync-monitoring.component';
import { ManageGatesComponent } from './components/god/manage-gates/manage-gates.component';
import { WorkflowEngineComponent } from './workflow/workflow-engine/workflow-engine.component';
import { WorkflowCartableComponent } from './workflow/workflow-cartable/workflow-cartable.component';
import { OtherSiteSupplierDocumentReportComponent } from './components/other-site-supplier-document-report/other-site-supplier-document-report.component';
import { TemplateCompanyVisitorTypesComponent } from './components/template-company-visitor-types/template-company-visitor-types.component';
import { ProcoreForceToSyncComponent } from './components/god/procore-force-to-sync/procore-force-to-sync.component';
import { PdfViewerComponent } from "./components/pdf-viewer/pdf-viewer.component";
import { CompanyWorkflowTemplateComponent } from './components/god/company-workflow-template/company-workflow-template.component';
import { SiteBriefingReportComponent } from './components/form-manage/site-briefing-report/site-briefing-report.component';
import { InductionReportComponent } from './components/induction/induction-report/induction-report.component';
import { UserDirectoryComponent } from './components/worker-profile/user-directory/user-directory.component';


const routes: Routes = [
  { path: '', redirectTo: 'sites', pathMatch: 'full' },
  {
    path: '', component: PublicComponent, data: { title: 'Public Views' }, children: [
      { path: 'sign-in', component: LoginComponent },
      { path: 'supplier-join/:token', component: SupplierJoinToCompanyComponent },
      { path: 'myob-config', component: MyobConfigurationAccessCodeCallbackComponent },
      { path: 'procore-config', component: ProcoreConfigurationAccessCodeCallbackComponent },
      { path: 'load-pdf/:src', component: PdfViewerComponent },
    ]
  },
  {
    path: '', component: AnonymousComponent, children: [
      {
        path: '', component: AnonymousCheckComponent, children: [
          { path: 'anonymous-check-in/:session-id', component: AnonymousCheckinComponent },
          { path: 'anonymous', component: AnonymousCheckoutComponent },
          { path: 'successfull-anonymous-check-in', component: SuccessfullAnonymousCheckinComponent }
        ]
      },
      {
        path: "anonymous-manage-site-supplier/:hashlink", component: SiteSupplierDocumentUploaderContainerComponent, data: {},
      },
      {
        path: "anonymous-manage-supplier-forms/:hashlink", component: SupplierAnonymousFormsComponent, data: {},
      }
    ]
  },
  {
    path: '', component: SecureComponent, data: { title: 'Secure Views' }, children: [
      { path: '', redirectTo: 'sites', pathMatch: 'full' },
      { path: 'not-found', component: NotFoundPageComponent },
      { path: 'no-access', component: NoAccessComponent },

      { path: 'manual-check', component: ManualCheckComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.ManualCheckIn | UserPermission.ManualCheckOut } },
      { path: 'dashboard', component: DashboardComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: CombinedUserPermissionList } },
      {
        path: '', component: CompanyOwnerComponent, canActivate: [CompanyAdminGuardService, FeatureGuardService], data: { feature: ProductPlanFeature.Automation }, children: [
          { path: 'manage-automations/:id', component: SiteAutomationsComponent },
          { path: 'manage-automations', component: SiteAutomationsComponent },
        ]
      },
      { path: 'quick-setup', component: QuickSetupComponent, canActivate: [CompanyGuardService, CompanyAdminGuardService] },
      { path: 'sites', component: CompanyComponent, canActivate: [CompanyGuardService, SitesPageGuardService] },
      { path: 'sites/:id', redirectTo: 'manage-site/:id' },
      { path: 'sites/:id/questions/:ischeckin', redirectTo: 'manage-site/:id' },
      { path: 'sites/:id/announcements', redirectTo: 'manage-site/:id' },
      { path: 'question', component: QuestionManagerComponent, canActivate: [CompanyGuardService, CompanyAdminGuardService] },
      { path: 'inductions', component: InductionManageComponent, canActivate: [CompanyGuardService, CompanyAdminGuardService] },
      { path: 'forms', component: FormManageComponent, canActivate: [CompanyGuardService, CompanyAdminGuardService] },
      { path: 'supplier', component: CompanySupplierComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.SupplierManagement } },
      { path: 'supplier/:id/edit', component: EditCompanySupplierComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.SupplierManagement } },
      { path: 'academy', component: VideoListComponent },
      { path: 'user-permissions', component: SiteAttendancePermissionComponent, canActivate: [CompanyGuardService, CompanyAdminGuardService] },
      { path: 'user-permissions/:type', component: SiteAttendancePermissionComponent, canActivate: [CompanyGuardService, CompanyAdminGuardService] },
      { path: 'blocked-users', component: SiteAttendanceBlockedUserComponent, canActivate: [CompanyGuardService, CompanyAdminGuardService] },
      { path: 'flagged-users', component: FlaggedUserComponent, canActivate: [CompanyGuardService, PermissionGuardService, FeatureGuardService], data: { feature: ProductPlanFeature.Automation, permission: UserPermission.ManageViewTaggedUser } },
      { path: 'manage-hosts', component: CompanyUserComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.CompanyHost } },
      { path: 'reports/site-activity/:mode', component: SiteActivityComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.ViewReports } },
      { path: 'reports/site-attendance', component: SiteAttendanceComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.ViewReports } },
      { path: 'reports/user-activity/:mode', component: SiteActivityComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.ViewReports } },
      { path: 'reports/my-scheduled-reports', component: MyScheduledReportsComponent, canActivate: [CompanyGuardService, PermissionGuardService, FeatureGuardService], data: { feature: ProductPlanFeature.SchaduleReport, permission: UserPermission.ViewReports } },
      { path: 'reports/card', component: DocumentReportComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.ViewReports } },
      { path: 'manage-announcements', component: AnnouncementManagerComponent, canActivate: [CompanyGuardService, OverLappedPermissionGuardService, FeatureGuardService], data: { feature: ProductPlanFeature.Announcements, permissions: [UserPermission.ManageSiteAnnouncement] } },
      { path: 'document-types', component: UserDocumentTypesComponent, canActivate: [CompanyGuardService] }, /// TODO duplicate usage must remove
      { path: 'card-designer', component: CardDesignerComponent, canActivate: [CompanyGuardService, CompanyAdminGuardService] },
      { path: 'scannables', component: ScannableGroupsComponent, canActivate: [CompanyGuardService, CompanyAdminGuardService, FeatureGuardService], data: { feature: ProductPlanFeature.Scannables } },
      { path: 'scannables/:id/items', component: ScannableItemsComponent, canActivate: [CompanyGuardService, CompanyAdminGuardService, FeatureGuardService], data: { feature: ProductPlanFeature.Scannables } },
      { path: 'import-sites', component: ImportSitesComponent, canActivate: [CompanyGuardService, CompanyAdminGuardService] },
      { path: 'site-users', component: SiteUserComponent, canActivate: [CompanyGuardService, PermissionGuardService, FeatureGuardService], data: { feature: ProductPlanFeature.SiteHosts, permission: UserPermission.SiteHost } },
      { path: 'host-push-notification', component: HostPushNotificationComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.SendPushNotification } },
      { path: 'employees', component: CompanyEmployeeComponent, canActivate: [CompanyGuardService, CompanyAdminGuardService], data: {} },
      { path: 'reports/asset-report', component: InventoryToolReportComponent, canActivate: [CompanyGuardService, PermissionGuardService, FeatureGuardService], data: { feature: ProductPlanFeature.Inventory, permission: UserPermission.AssetTrackerAdmin } },
      { path: 'reports/induction-report', component: InductionReportComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.ViewReports } },
      { path: 'reports/form-report', component: FormReportComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.ViewReports } },
      { path: 'supplier-forms/:supplierId', component: SupplierFormsComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.SupplierManagement } },
      { path: 'supplier-documents/:supplierId', component: SupplierDocumentsComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.SupplierManagement } },
      { path: 'site-supplier-documents/:siteId', component: SupplierDocumentsComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.SupplierManagement } },
      { path: 'manage-site-supplier/:id/:supplierId/:siteId/:ref', component: SiteSupplierDocumentUploaderContainerComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.SupplierManagement } },
      //{ path: 'manage-site-supplier/:id/:supplierId', component: SiteSupplierDocumentUploaderContainerComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.SupplierManagement } },
      { path: 'manage-site/new', component: SiteManagementComponent, canActivate: [CompanyGuardService, CompanyAdminGuardService], canDeactivate: [CanDeactivateGuard], data: {} },
      { path: 'manage-site/new-template', component: SiteManagementComponent, canActivate: [CompanyGuardService, CompanyAdminGuardService], canDeactivate: [CanDeactivateGuard], data: {} },
      { path: 'manage-site/:id', component: SiteManagementComponent, canActivate: [CompanyGuardService, PermissionGuardService], canDeactivate: [CanDeactivateGuard], data: { permission: UserPermission.ModifySite } },
      { path: 'manage-site/supplier/:siteSupplierId', component: SiteManagementComponent, canActivate: [CompanyGuardService, PermissionGuardService], canDeactivate: [CanDeactivateGuard], data: { permission: UserPermission.ModifySite } },
      { path: 'reports/pending-approval', component: PendingToApproveListComponent, canActivate: [CompanyGuardService, PermissionGuardService], canDeactivate: [CanDeactivateGuard], data: { permission: UserPermission.ApproveRejectSiteInduction } },
      { path: 'reports/permit-report', component: PermitReportComponent, canActivate: [CompanyGuardService, PermissionGuardService], canDeactivate: [CanDeactivateGuard], data: { permission: UserPermission.ApproveRejectSiteInduction } },
      { path: 'reports/evacuations', component: EvacuationReportComponent, canActivate: [CompanyGuardService], canDeactivate: [CanDeactivateGuard], },
      { path: "manage-site-supplier-documents/:hashlink", component: SiteSupplierDocumentUploaderContainerComponent, data: {}, },
      { path: "manage-site-supplier-forms/:hashlink", component: SupplierAnonymousFormsComponent, data: {}, },
      { path: "manage-site-supplier-forms", component: SupplierAnonymousFormsComponent, data: {}, },
      { path: "audit-log-report", component: SecurityLogReportComponent, canActivate: [CompanyGuardService, CompanyAdminGuardService], },
      { path: 'site-assets/:siteId/:referer', component: ManageSiteAssetComponent, canActivate: [CompanyGuardService] },
      //{ path: 'site-assets/supplier/:siteSupplierId', component: ManageSiteAssetComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.AssetTrackerAdmin } },
      { path: "site-access-control-unit", component: SiteAccessControlUnitManualComponent, canActivate: [CompanyGuardService, SiteAdminGuardService], },
      { path: "asset-manager", component: TemplateCompanyAssetManagerComponent, canActivate: [CompanyGuardService], },
      { path: "visitor-type-manager", component: TemplateCompanyVisitorTypesComponent, canActivate: [CompanyGuardService], },
      { path: "company-roles", component: PermissionsSettingsComponent, canActivate: [CompanyGuardService], },
      { path: "permissions", component: PermissionContainerComponent, canActivate: [CompanyGuardService], },
      { path: 'reports/supplier-document', component: SupplierDocumentReportComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.ViewReports } },
      { path: 'workflow-cartable', component: WorkflowCartableComponent, canActivate: [CompanyGuardService] },
      { path: 'workflow-engine/:id', component: WorkflowEngineComponent, canActivate: [CompanyGuardService] },
      { path: 'sds-supplier-document/:attId', component: OtherSiteSupplierDocumentReportComponent, canActivate: [CompanyGuardService] },
      { path: 'user-directory', component: UserDirectoryComponent, canActivate: [CompanyGuardService, PermissionGuardService], canDeactivate: [CanDeactivateGuard], data: { permission: UserPermission.ViewReports | UserPermission.EditUserDirectory } },
      // { path: 'user/:id', component: UserComponent, canActivate: [CompanyGuardService]  },
      {
        path: 'user', canActivate: [CompanyGuardService], component: UserComponent, children: [
          { path: 'profile', component: UserProfileComponent },
          { path: 'change-password', component: ChangePasswordComponent },
          { path: 'notification-settings', component: NotificationSettingComponent },
        ]
      },
      {
        path: '', component: GodComponent, canActivate: [CompanyGuardService, GodUserGuardService], children: [
          { path: 'user-documents', component: UserDocumentTypesComponent, canActivate: [GodUserGuardService], data: { godPermission: GodUserPermission.ManageDocumentTypes } },
          { path: 'manage-companies', component: ManageCompaniesComponent, canActivate: [GodUserGuardService], data: { godPermission: GodUserPermission.ManageCompanies } },
          { path: 'manage-videos', component: ManageVideosComponent, canActivate: [GodUserGuardService], data: { godPermission: GodUserPermission.ManageVideos } },
          { path: 'manage-plans', component: ManagePlansComponent, canActivate: [GodUserGuardService], data: { godPermission: GodUserPermission.ManagePlans } },
          { path: 'manage-industry-types', component: ManageIndustryTypesComponent, canActivate: [GodUserGuardService], data: { godPermission: GodUserPermission.ManageIndustryTypes } },
          { path: 'asset-global-dynamic-fields', component: ManageGlobalDynamicFieldsComponent, canActivate: [GodUserGuardService], data: { godPermission: GodUserPermission.ManageGlobalAssetFields } },
          { path: 'user-edit-phone', component: UserEditPhoneComponent, canActivate: [GodUserGuardService], data: { godPermission: GodUserPermission.EditMobileNumber } },
          { path: "audit-log-report/:mode", component: SecurityLogReportComponent, canActivate: [GodUserGuardService], data: { godPermission: GodUserPermission.AuditLog } },
          { path: "procore-sync-monitoring", component: ProcoreSyncMonitoringComponent, canActivate: [GodUserGuardService], data: { godPermission: GodUserPermission.ProcoreSyncMonitoring } },
          { path: 'manage-gates', component: ManageGatesComponent, canActivate: [GodUserGuardService], data: { godPermission: GodUserPermission.ManageGates } },
          { path: 'force-procore-sync', component: ProcoreForceToSyncComponent, canActivate: [GodUserGuardService], data: { godPermission: GodUserPermission.ForceProcoreSync } },
          { path: 'company-workflow-template/:companyId/:companyTitle', component: CompanyWorkflowTemplateComponent, canActivate: [GodUserGuardService], data: { godPermission: GodUserPermission.WorkflowManagement } },
        ]
      },
      {
        path: '', component: CompanyOwnerComponent, canActivate: [CompanyAdminGuardService], children: [
          { path: 'billing', component: BillingComponent },
          { path: 'company-settings', component: CompanySettingsComponent },
          { path: 'company-integrations', component: CompanyIntegrationsComponent },
        ]
      },
      { path: 'reports/site-briefing-report', component: SiteBriefingReportComponent, canActivate: [CompanyGuardService, PermissionGuardService], data: { permission: UserPermission.ViewReports } },
    ]
  },
  { path: '**', redirectTo: 'sites' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
