<div class="card">
  <div class="card-header">
    {{title}}
    <div class="card-actions">
      <a (click)="hide.emit()" class="card-action card-action-dismiss" data-card-dismiss=""></a>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <table class="table table-responsive-md table-striped mb-0 obc-table">
          <thead>
            <tr>
              <th>
                Document
              </th>
              <th> <span class="pull-right">Add</span> </th>
            </tr>
            <tr>
              <td colspan="2">
                <input type="text" class="form-control" placeholder="Search in Documents" [(ngModel)]="filterTerm" />
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div class="row" style="padding-right: 15px;">
      <div class="col-md-12 scrollable-list" style="max-height: 400px;overflow-y: scroll;">
        <table class="table table-responsive-md table-striped mb-0 obc-table">
          <tbody>
            <tr *ngIf="!availableDocuments || availableDocuments.length == 0">
              <td colspan="3" style="width: 100%; float: none;border-top: none;">
                <p class="text-center mb-0">There is no document to add</p>
              </td>
            </tr>
            <tr *ngFor="let queze of (availableDocuments | filter:filterTerm:['title'])">
              <td colspan="3">
                <i class="fas fa-id-card"></i> {{queze?.title}}
              </td>
              <td>
                <obc-optional-mandatory-selector (onChangeSelectedState)="queze.state = $event">
                </obc-optional-mandatory-selector>
              </td>
              <td>
                <button class="pull-right btn btn-xs btn-info" (click)="onAddDocument(queze)">
                  <i class="fas fa-plus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>