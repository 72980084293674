import { Pipe, PipeTransform } from '@angular/core';
import { FormDataStatus } from '../enums/form-data-status';
import { SiteBriefingStatus } from '../enums/site-briefing-status';

@Pipe({
  name: 'SiteBriefingStatusPipe'
})
export class SiteBriefingStatusPipe implements PipeTransform {

  transform(value: any): string {
    if (value == SiteBriefingStatus.Draft || value == 0) return "Draft";
    if (value == SiteBriefingStatus.Active || value == 1) return "Active";
    if (value == SiteBriefingStatus.Inactive || value == 2) return "Inactive";
    return value;
  }
}
