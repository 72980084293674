import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServerResult, ServerResultData } from '../models/server-result-data';
import { ErrorService } from './error.service';
import { RegionModel, RegionViewModel } from "../models/region/region-model";
import { PagingViewModel } from "../models/site-list-paging-view-model";

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  constructor(private _http: HttpClient, private _errorService: ErrorService) { }


  getRegions = (): Observable<PagingViewModel<RegionViewModel>> =>
    this._http.get<PagingViewModel<RegionViewModel>>(`api/Region/all-regions`)
      .pipe(catchError(this._errorService.handleError));

  getAccessableRegions = (): Observable<ServerResultData<RegionViewModel[]>> =>
    this._http.get<ServerResultData<RegionViewModel[]>>(`api/Region/accessable-regions`)
      .pipe(catchError(this._errorService.handleError));

  addRegion = (model: RegionModel): Observable<ServerResult> =>
    this._http.post<ServerResult>(`api/Region/add-region`, model)
      .pipe(catchError(this._errorService.handleError));

  updateRegion = (id: number, model: RegionModel): Observable<ServerResult> =>
    this._http.post<ServerResult>(`api/Region/update-region/${id}`, model)
      .pipe(catchError(this._errorService.handleError));

  removeRegion = (id: number): Observable<ServerResult> =>
    this._http.delete(`api/Region/remove-region/${id}`)
      .pipe(catchError(this._errorService.handleError));

}
