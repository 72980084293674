<section class="card" #modal>
    <header class="card-header">
        <h5>User Details</h5>
        <div class="card-actions">
            <a (click)="this.modalService.hide()" class="card-action card-action-dismiss" data-card-dismiss=""></a>
        </div>
    </header>

    <div class="card-body">
        <obc-work-profile-tab [userId]="userId" [isModalTab]="true"></obc-work-profile-tab>
    </div>

</section>