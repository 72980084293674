import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { InventoryLocationModel, InventoryLocationViewModel } from 'src/app/models/inventory/inventory-location-model';
import { ManageInventoryService } from 'src/app/services/manage-inventory.service';
import { ModalService } from 'src/app/services/modal.service';
import { UpdateInventoryLocationModel } from 'src/app/models/inventory/update-inventory-location-model';

@Component({
  selector: 'obc-manage-inventory-locations',
  templateUrl: './manage-inventory-locations.component.html',
  styleUrls: ['./manage-inventory-locations.component.scss']
})
export class ManageInventoryLocationsComponent implements OnInit {
  formGroup = new FormGroup({
    siteId: new FormControl(null),
    name: new FormControl(null, [Validators.required])
  })
  invertoryLocations: InventoryLocationViewModel[] = [];
  constructor(private manageInventoryService: ManageInventoryService, private modalService: ModalService) { }
  inProgress = false;
  ngOnInit() {
    this.refresh();
  }

  onAdd() {
    this.inProgress = true;
    var model = {
      siteId: this.formGroup.controls.siteId.value,
      name: this.formGroup.controls.name.value,
    } as InventoryLocationModel;
    this.manageInventoryService.addInventoryLocation(model)
      .pipe(finalize(() => { this.inProgress = false }))
      .subscribe(res => {
        this.invertoryLocations.push(res);
        this.formGroup.controls['name'].setValue(null);
      }, err => {
        this.modalService.error(err)
      })
  }

  onRemove(id: number) {
    this.modalService.confirm("Are you sure you want to remove this location?", "Remove inventory location").subscribe(res => {
      if (res) {
        this.inProgress = true;
        this.manageInventoryService.removeInventoryLocation(id)
          .pipe(finalize(() => { this.inProgress = false }))
          .subscribe(res => {
            var loc = this.invertoryLocations.find(x=> x.id == id);
            var index = this.invertoryLocations.indexOf(loc) ;
            this.invertoryLocations.splice(index, 1);
          }, err => {
            this.modalService.error(err)
          })
      }
    })

  }

  refresh() {
    this.inProgress = true;
    this.manageInventoryService.getInventoryLocations()
      .pipe(finalize(() => { this.inProgress = false }))
      .subscribe(res => {
        this.invertoryLocations = res;
      }, err => {
        this.modalService.error(err.error)
      })
  }

  onSetAsDefaultLocation(id: number){
    this.modalService.confirm("Are you sure you want to set this location as default?").subscribe(res => {
      if (res) {
        this.inProgress = true;
        this.manageInventoryService.setAsDefaultInventoryLocation(id)
          .pipe(finalize(() => { this.inProgress = false }))
          .subscribe(res => {
            this.refresh();
          }, err => {
            this.modalService.error(err)
          })
      }
    })
  }

  toggleStatus(loc: InventoryLocationViewModel){
    this.modalService.confirm(`Are you sure you want to ${loc.isActive ? 'deactivate' : 'activate'} this location?`).subscribe(res => {
      if (res) {
        this.inProgress = true;
        this.manageInventoryService.toggleInventoryLocationStatus(loc.id, !loc.isActive)
          .pipe(finalize(() => { this.inProgress = false }))
          .subscribe(res => {
            this.refresh();
          }, err => {
            this.modalService.error(err)
          })
      }
    })
  }


  selectedInventoryLocation: InventoryLocationViewModel;
  selectedInventoryLocationNameFormControl = new FormControl('', [Validators.required])
  onClickUpdateItem(template: any, item: InventoryLocationViewModel) {
    this.selectedInventoryLocationNameFormControl.setValue(item.name);
    this.selectedInventoryLocation = item;
    this.modalService.show(template);
  }

  updateName(){
    this.inProgress = true;
    this.manageInventoryService.updateInventoryLocation(this.selectedInventoryLocation?.id, {name: this.selectedInventoryLocationNameFormControl?.value} as UpdateInventoryLocationModel)
      .pipe(finalize(() => { this.inProgress = false }))
      .subscribe(res => {
        this.refresh();
        this.modalService.hide();
        this.modalService.success('The name of the location changed successfully');
      }, err => {
        this.modalService.error(err)
      })
  }

}
