import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ProductPlanFeature } from 'src/app/enums/product-plan-feature.enum';
import { LayoutService } from 'src/app/services/layout.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'obc-company-integrations',
  templateUrl: './company-integrations.component.html',
  styleUrls: ['./company-integrations.component.scss']
})
export class CompanyIntegrationsComponent implements OnInit {
  inProgress: boolean = false;
  inProgress$: Subject<boolean> = new Subject();
  ProductPlanFeature = ProductPlanFeature;
  constructor(public userService: UserService, private layoutService: LayoutService) { }

  ngOnInit() {
    this.layoutService.header = "Company Integration Configuration"
    this.inProgress$.subscribe(res => {
      setTimeout(() => {
        this.inProgress = res
      }, 1)
    });
  }

  get enabledIntegration(): boolean {
    return this.userService.hasCompanyFeature(ProductPlanFeature.IntegrationApi);
  }

}
