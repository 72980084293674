<div id="id_{{id}}" style="min-width: 200px;">
  <ng-container>
    <ng-container *ngIf="type== FilterType.EventLogType">
      <div class="d-flex flex-column">
        <div *ngFor="let exp of obcEventLogTypeEnum | enumToArray">
          <button class="btn fw mb-1"
            [ngClass]="{'btn-warning':contain(obcEventLogTypeEnum[exp]), 'filter-btn':!contain(obcEventLogTypeEnum[exp])}"
            (click)="handleAddOrRemove(obcEventLogTypeEnum[exp], true)">
            {{obcEventLogTypeEnum[exp] | workerProfileLogType}}
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.DateTimeRange">
      <div class="row no-gutters">
        <ng-container *ngFor="let dr of defaultDateRanges; let index = index">
          <div class="col-12 col-md-6 mb-1">
            <div class="mr-1 ml-1">
              <button class="btn mt-0 fw mb-1" [ngClass]="{'btn-warning':contain(dr), 'filter-btn':!contain(dr)}"
                (click)="handleAddOrRemove(dr,true)">
                <!-- {{contain(dr) ? "remove" : "add"}} -->
                {{dr.label}}
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.DateTime">
      <div class="row pl-1 pr-1">
        <ng-container *ngFor="let dr of defaultDateRanges; let index = index">
          <div class="col-md-12 mb-1" [ngClass]="{'pr-1':index%2 == 0, 'pl-1':index%2 == 1}">
            <button class="btn fw mb-1 btn-warning" (click)="handleAddOrRemoveDateTime(dr,true)">
              <!-- {{contain(dr) ? "remove" : "add"}} -->
              {{dr.label}}
            </button>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.VisitorType">
      <div class="d-flex flex-column">
        <div *ngFor="let dr of visitorTypes">
          <button class="btn fw mb-1" [ngClass]="{'btn-warning':contain(dr), 'filter-btn':!contain(dr)}"
            (click)="handleAddOrRemove(dr)">
            {{dr.title}}
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.DocumentExpiryStatus">
      <div class="d-flex flex-column">
        <div *ngFor="let exp of documentExpiryStatusEnum | enumToArray">
          <button class="btn fw mb-1" [ngClass]="{'btn-warning':contain(exp), 'filter-btn':!contain(exp)}"
            (click)="handleAddOrRemove(exp, false, false)">
            {{documentExpiryStatusEnum[exp] | documentExpiryStatus}}
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.SiteBriefingStatus">
      <div class="d-flex flex-column">
        <div *ngFor="let exp of SiteBriefingStatus | enumToArray">
          <button class="btn fw mb-1" [ngClass]="{'btn-warning':contain(exp), 'filter-btn':!contain(exp)}"
            (click)="handleAddOrRemove(exp, false, false)">
            {{SiteBriefingStatus[exp] | SiteBriefingStatusPipe}}
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.ApprovalStatus">
      <div class="d-flex flex-column">
        <div *ngFor="let exp of approvalStatusEnum | enumToArray">
          <button class="btn fw mb-1"
            [ngClass]="{'btn-warning':contain(approvalStatusEnum[exp]), 'filter-btn':!contain(approvalStatusEnum[exp])}"
            (click)="handleAddOrRemove(approvalStatusEnum[exp], false, false)">
            {{approvalStatusEnum[exp] | approveStatus}}
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.SiteSupplierDocumentVersionStatus">
      <div class="d-flex flex-column">
        <div *ngFor="let exp of SiteSupplierDocumentVersionStatus | enumToArray">
          <button class="btn fw mb-1"
            [ngClass]="{'btn-warning':contain(SiteSupplierDocumentVersionStatus[exp]), 'filter-btn':!contain(SiteSupplierDocumentVersionStatus[exp])}"
            (click)="handleAddOrRemove(SiteSupplierDocumentVersionStatus[exp], false, false)">
            {{SiteSupplierDocumentVersionStatus[exp] | siteSupplierDocumentVersionStatus}}
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.ApproveEntityType">
      <div class="d-flex flex-column">
        <div *ngFor="let exp of approveEntityTypeEnum | enumToArray">
          <button class="btn fw mb-1" [ngClass]="{'btn-warning':contain(exp), 'filter-btn':!contain(exp)}"
            (click)="handleAddOrRemove(exp, false, false)">
            {{approveEntityTypeEnum[exp] | approveEntityType}}
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="type == FilterType.AssetCategoryType">
      <div class="d-flex flex-column">
        <div *ngFor="let exp of assetCategoryTypeEnum | enumToArray">
          <button class="btn fw mb-1" [ngClass]="{'btn-warning':contain(exp), 'filter-btn':!contain(exp)}"
            (click)="handleAddOrRemove(exp, false, false)">
            {{assetCategoryTypeEnum[exp] | assetCategoryType}}
          </button>
        </div>
      </div>
    </ng-container>


    <ng-container *ngIf="type == FilterType.AssetStatus">
      <div class="d-flex flex-column">
        <div *ngFor="let exp of siteAssetQueryModeEnum | enumToArray">
          <button class="btn fw mb-1" [ngClass]="{'btn-warning':contain(exp), 'filter-btn':!contain(exp)}"
            (click)="handleAddOrRemove(exp, false, false)">
            {{siteAssetQueryModeEnum[exp] | siteAssetQueryMode}}
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="type == FilterType.Induction">
      <div class="d-flex flex-column">
        <div *ngFor="let dr of defaultInductionStatuses">
          <button class="btn fw mb-1" [ngClass]="{'btn-warning':contain(dr), 'filter-btn':!contain(dr)}"
            (click)="handleAddOrRemove(dr,false,true)">
            {{dr.label}}</button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.IndustryType">
      <div class="d-flex flex-column">
        <div *ngFor="let dr of industryTypes">
          <button class="btn fw mb-1" [ngClass]="{'btn-warning':contain(dr), 'filter-btn':!contain(dr)}"
            (click)="handleAddOrRemove(dr)">
            {{dr.type}}</button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.Gender">
      <div class="d-flex flex-column">
        <div *ngFor="let dr of defaultGenders">
          <button class="btn fw mb-1" [ngClass]="{'btn-warning':contain(dr), 'filter-btn':!contain(dr)}"
            (click)="handleAddOrRemove(dr)">
            {{dr.label}}</button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.InventoryCategory">
      <div class="d-flex flex-column">
        <div *ngFor="let dr of inventoryCategories">
          <button class="btn fw mb-1" [ngClass]="{'btn-warning':contain(dr), 'filter-btn':!contain(dr)}"
            (click)="handleAddOrRemove(dr)">
            {{dr.title}}</button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.InventoryToolStatus">
      <div class="d-flex flex-column">
        <div *ngFor="let dr of inventoryToolStatuses">
          <button class="btn fw mb-1" [ngClass]="{'btn-warning':contain(dr), 'filter-btn':!contain(dr)}"
            (click)="handleAddOrRemove(dr)">
            {{dr.title}}</button>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <!-- selected -->
  <ng-container *ngIf="showRemovableItems(type)">
    <div class="mb-2">
      <ng-container *ngFor="let item of items">
        <div class="filter-bordered mb-1" *ngIf="isRenderableAsSelectedItem(item, type)">
          <span class="d-flex flex-row justify-content-between">
            <span> {{ getFilterText(item,type) }} </span>
            <i class="fa fa-times font-danger" style="position: relative; top:4px; right:0px"
              (click)="onRemove(item, type)"></i>
          </span>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container>
    <div class="mb-2" *ngIf="type == FilterType.DateTime">
      <ng-container *ngFor="let item of items">
        <div class="filter-bordered mb-1" *ngIf="showDateTime">
          <span class="d-flex flex-row justify-content-between">
            <span> {{ getFilterText(item,type) }} </span>
            <i class="fa fa-times font-danger" style="position: relative; top:4px; right:0px"
              (click)="onRemove(item)"></i>
          </span>
        </div>
      </ng-container>
    </div>

  </ng-container>

  <!-- inputs -->
  <ng-container>
    <ng-container *ngIf="type == FilterType.Text">
      <div class="input-group mb-3">
        <input (keyup.enter)="onAdd(formControl.value)" [formControl]="formControl" type="text" class="form-control">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="onAdd(formControl.value)" type="button"><i
              class="fas fa-plus"></i></button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.SingleText">
      <div class="input-group mb-3">
        <input (keyup.enter)="onAdd(formControl.value, true)" [formControl]="formControl" type="text"
          class="form-control">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="onAdd(formControl.value, true)" type="button"><i
              class="fas {{ items?.length > 0 ? 'fa-edit' : 'fa-plus' }}"></i></button>
          <button *ngIf="items?.length > 0" class="btn btn-danger"
            (click)="onRemove(items[0]); formControl.setValue(null)" type="button"><i class="fas fa-times"></i></button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.Site">
      <app-site-selector [siteId]="siteId" #sl [required]="false" [activeStatus]="SiteActiveStatus.Active"
        [permission]="SiteViewPermissions" [loadForExternalCompany]="loadForExternalCompany"
        [includeExternalCompanySites]="includeExternalCompanySites" (siteSelect)="onAdd($event)">
      </app-site-selector>
    </ng-container>

    <ng-container *ngIf="type == FilterType.Supplier">
      <obc-company-supplier-selector [control]="supplierFormControl" (selectSupplier)="onAdd($event)">
      </obc-company-supplier-selector>
    </ng-container>
    <ng-container *ngIf="type == FilterType.AssetCategory">
      <obc-filter-asset-category [control]="assetCategoryFormControl" (selectAssetCategory)="onAdd($event)">
      </obc-filter-asset-category>
    </ng-container>
    <ng-container *ngIf="type == FilterType.DocumentType">
      <div class="d-flex flex-column">
        <obc-document-type-selector [control]="documentTypeFormControl" (selectDocument)="onAdd($event)">
        </obc-document-type-selector>
      </div>
    </ng-container>
    <!-- <ng-container *ngIf="type == FilterType.SiteSupplierDocumentWorkflow && documentKindList">
      <div class="d-flex flex-column">
        <div *ngFor="let exp of documentKindList">
          <button class="btn fw mb-1" [ngClass]="{'btn-danger':contain(exp.id), 'btn-warning':!contain(exp.id)}"
            (click)="handleAddOrRemove(exp.id, false, false)">
            {{exp.name}}
          </button>
        </div>
      </div>
    </ng-container> -->
    <ng-container *ngIf="type == FilterType.SupplierDocumentKind && documentKindList">
      <div class="d-flex flex-column">
        <div *ngFor="let exp of documentKindList">
          <button class="btn fw mb-1" [ngClass]="{'btn-danger':contain(exp.id), 'btn-warning':!contain(exp.id)}"
            (click)="handleAddOrRemove(exp.id, false, false)">
            {{exp.name}}
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="type == FilterType.Form">
      <obc-form-selector [formType]="formType" (selectedForm)="onAdd($event)"
        [includeInactiveForms]="filterSettings?.includeInactiveItems" #formSelector></obc-form-selector>
    </ng-container>

    <ng-container *ngIf="type == FilterType.User">
      <div class="d-flex flex-row" #refUserAutocomplete>
        <obc-user-autocomplete #user class="fw" container="#id_{{id}}" [filterBy]="filterUserBy.All"
          (selectedUser)="formControl.setValue($event.id); onAdd($event)" [required]="!formControl.valid"
          [loadForExternalCompany]="loadForExternalCompany">
        </obc-user-autocomplete>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.DateTimeRange">
      <div class="d-flex flex-column" [formGroup]="dateFormGroup">
        <div>
          <label>From</label>
          <obc-input-date-time-picker formControlName="from">
          </obc-input-date-time-picker>
          <!-- <input [owlDateTime]="dt1" class="form-control" formControlName="from" [owlDateTimeTrigger]="dt1">
          <owl-date-time [pickerType]="'both'" #dt1></owl-date-time> -->
        </div>
        <div class="form-group">
          <label>To</label>
          <obc-input-date-time-picker formControlName="to">
          </obc-input-date-time-picker>
          <!-- <input [owlDateTime]="dt2" class="form-control" formControlName="to" [owlDateTimeTrigger]="dt2">
          <owl-date-time [pickerType]="'both'" #dt2></owl-date-time> -->
        </div>
        <span class="alert alert-danger mt-1"
          *ngIf="invalidDate(dateFormGroup.controls.from.value, dateFormGroup.controls.to.value, false)">
          `From` date should be less than `To` date
        </span>
        <button class="btn btn-primary mt-2"
          [disabled]="!(dateFormGroup.controls.from.value || dateFormGroup.controls.to.value) || invalidDate(dateFormGroup.controls.from.value, dateFormGroup.controls.to.value)"
          (click)="onAdd(getFilterDateRange(dateFormGroup.controls.from.value, dateFormGroup.controls.to.value), true)">{{addButtonLabel}}</button>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.DateTime">
      <div class="d-flex flex-column" [formGroup]="dateFormGroup">
        <div>
          <label>Date Time</label>
          <obc-input-date-time-picker formControlName="from">
          </obc-input-date-time-picker>
          <!-- <input [owlDateTime]="dt1" class="form-control" formControlName="from" [owlDateTimeTrigger]="dt1">
          <owl-date-time [pickerType]="'both'" #dt1></owl-date-time> -->
        </div>
        <button class="btn btn-primary mt-2" [disabled]="!(dateFormGroup.controls.from.value)"
          (click)="onAdd(getFilterDateRange(dateFormGroup.controls.from.value), true)">{{addButtonLabel}}</button>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.DateRange">
      <div class="d-flex flex-column" [formGroup]="dateFormGroup">
        <div class="form-group">
          <label>From</label>
          <obc-input-date-time-picker formControlName="from" [mode]="DatetimePickerMode.Calender">
          </obc-input-date-time-picker>
          <!-- <input [owlDateTime]="dt3" class="form-control" formControlName="from" [owlDateTimeTrigger]="dt3">
          <owl-date-time [pickerType]="'calendar'" #dt3></owl-date-time> -->
        </div>
        <div class="form-group">
          <label>To</label>
          <obc-input-date-time-picker formControlName="to" [mode]="DatetimePickerMode.Calender">
          </obc-input-date-time-picker>
          <!-- <input [owlDateTime]="dt4" class="form-control" formControlName="to" [owlDateTimeTrigger]="dt4">
          <owl-date-time [pickerType]="'calendar'" #dt4></owl-date-time> -->
        </div>
        <span class="alert alert-danger mt-1"
          *ngIf="invalidDate(dateFormGroup.controls.from.value, dateFormGroup.controls.to.value, false)">
          `From` date should be less than `To` date
        </span>
        <button class="btn btn-primary mt-2"
          [disabled]="!(dateFormGroup.controls.from.value || dateFormGroup.controls.to.value)"
          (click)="onAdd(getFilterDateRange(dateFormGroup.controls.from.value, dateFormGroup.controls.to.value), true)">{{addButtonLabel}}</button>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.Duration">
      <div class="d-flex flex-column" [formGroup]="dateFormGroup">
        <div class="form-group">
          <label>From</label>
          <app-duration-form-control formControlName="from"></app-duration-form-control>
        </div>
        <div class="form-group">
          <label>To</label>
          <app-duration-form-control formControlName="to"></app-duration-form-control>
        </div>
        <button class="btn btn-primary mt-2"
          [disabled]="!(dateFormGroup.controls.from.value || dateFormGroup.controls.to.value) || !dateFormGroup.controls.from.valid || !dateFormGroup.controls.to.valid"
          (click)="onAdd(getFilterDurationRange(dateFormGroup.controls.from.value, dateFormGroup.controls.to.value), true)">{{addButtonLabel}}</button>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.InventoryLocation">
      <div class="d-flex flex-column">
        <obc-inventory-location-selector [canAddNewLocation]="inventoryLocationSelectorCanAddNewLocation"
          (onSelectLocation)="saveSelectedLocation($event?.selectedLocation)">
        </obc-inventory-location-selector>
        <button class="btn btn-primary ml-auto mt-2" (click)="onAdd(selectedLocationToAdd)">
          Add
          <i class="fas fa-plus"></i>
        </button>
      </div>

    </ng-container>
    <ng-container *ngIf="type == FilterType.FormType">
      <div class="d-flex flex-column">
        <div *ngFor="let dr of defaultFormType">
          <button class="btn fw mb-1" [ngClass]="{'btn-warning':contain(dr), 'filter-btn':!contain(dr)}"
            (click)="handleAddOrRemove(dr, false, true)">
            {{dr.label}}</button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type == FilterType.PermitStatus">
      <div class="d-flex flex-column">
        <div *ngFor="let pm of permitStatusTypes">
          <button class="btn fw mb-1" [ngClass]="{'btn-warning':contain(pm), 'filter-btn':!contain(pm)}"
            (click)="handleAddOrRemove(pm)">
            {{pm.label}}</button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="type == FilterType.InductionId">
      <obc-company-induction-selector [selectedInductions]="items" [control]="inductionFormControl"
        (onSelectInduction)="onAdd($event)" [includeInactiveInductions]="filterSettings?.includeInactiveItems">
      </obc-company-induction-selector>
    </ng-container>

  </ng-container>

</div>