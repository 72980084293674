import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { TriggerType } from 'src/app/enums/trigger-type.enum';
import { CompanyTriggerActionViewmodel } from 'src/app/models/company-trigger-action-viewmodel';
import { CompanyTriggerActionService } from 'src/app/services/company-trigger-action.service';
import { TriggerAction } from 'src/app/enums/trigger-action.enum';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmailTemplateViewmodel } from 'src/app/models/email-template-viewmodel';
import { CompanyTagService } from 'src/app/services/company-tag.service';
import { CompanyTagViewModel } from 'src/app/models/company-tag';
import { SiteService } from 'src/app/services/site.service';
import { SiteViewModel } from 'src/app/models/site-viewmodel';
import { UserService } from 'src/app/services/user.service';
import { CompanyViewModel } from 'src/app/models/company-viewmodel';
import { CompanySiteSelectorResponseModel } from 'src/app/models/company-site-selector-response-model';
import { CompanySiteSelectedType } from 'src/app/enums/company-site-selected-type';
import { FormService } from 'src/app/services/form.service';
import { finalize } from 'rxjs/operators';
import { FullFormViewModel } from 'src/app/models/form/form-view-model';
import { SiteAttendanceApprovalStatus } from "../../../enums/site-attendance-approval-status.enum";
import { FormType } from 'src/app/enums/form-type';

@Component({
  selector: 'obc-company-trigger-action',
  templateUrl: './company-trigger-action.component.html',
  styleUrls: ['./company-trigger-action.component.css']
})
export class CompanyTriggerActionComponent implements OnInit {

  @Input() inProgress$: Subject<boolean>;
  @Input() siteId: number = -1;
  @Input() siteName: string;
  @Input() triggerDefaultValue: any = 'All';

  companyTriggerActions: CompanyTriggerActionViewmodel[] = null;
  companyTags: CompanyTagViewModel[];
  selectedCompanyTriggerAction: CompanyTriggerActionViewmodel;
  triggerType = TriggerType;
  triggerAction = TriggerAction;
  editFormGroup: FormGroup;
  bsModalRef: BsModalRef;
  inAddEditProgress: boolean = false;
  errorMessage: string = "";
  sites: SiteViewModel[];
  forms = new BehaviorSubject<FullFormViewModel[]>([]);
  currentCompany: CompanyViewModel;
  filterType: TriggerType;
  filteredItems: number;
  formTypes = FormType;
  siteAttendanceApprovalState = SiteAttendanceApprovalStatus;

  get isCompanyWide() {
    return !this.siteId || this.siteId <= 0;
  }

  constructor(private companyTriggerActionService: CompanyTriggerActionService,
    private companyTagService: CompanyTagService,
    private formService: FormService,
    private userService: UserService,
    private siteService: SiteService,
    private bsModalService: BsModalService) {
  }

  ngOnInit() {
    this.currentCompany = this.userService.currentCompany;
    this.userService.currentCompany$.subscribe(res => this.currentCompany = res);
    this.updateCompanyTags();

    this.inProgress$.next(true)
    forkJoin({
      forms: this.formService.getAllForms(),
      sites: this.siteService.getAllActiveSites(),
    })
      .pipe(finalize(() => {
        this.inProgress$.next(false);

      }))
      .subscribe(result => {
        this.forms.next(result.forms.data.filter(s => s.isEnable == true));
        this.sites = result.sites;
        setTimeout(() => {
          this.updateTriggerActionList();
        }, 100)
      })
  }


  private updateCompanyTags() {
    this.companyTagService.getCompanyTags().subscribe(res => this.companyTags = res);
  }

  private updateTriggerActionList() {
    if (this.inProgress$)
      this.inProgress$.next(true);
    var method = null;
    switch (this.siteId) {
      case -1:
        method = this.companyTriggerActionService.getForAllLocation();
        break;
      case 0:
        method = this.companyTriggerActionService.getForCompany();
        break;
      default:
        method = this.companyTriggerActionService.getForSite(this.siteId);
        break;
    }
    method.subscribe((res: CompanyTriggerActionViewmodel[]) => {
      this.companyTriggerActions = res;
      this.onSelectedTrigger(this.filterType);
      if (this.inProgress$)
        this.inProgress$.next(false);
    });
  }

  onRefresh() {
    this.updateTriggerActionList();
    this.updateCompanyTags();
  }

  onSiteIdChange($event: CompanySiteSelectorResponseModel) {
    if ($event != null) {
      if ($event.CompanySiteSelectedType == CompanySiteSelectedType.AllCompanyAndSites)
        this.siteId = CompanySiteSelectedType.AllCompanyAndSites.valueOf();
      else if ($event.CompanySiteSelectedType == CompanySiteSelectedType.Company)
        this.siteId = CompanySiteSelectedType.Company.valueOf();
      else if ($event.CompanySiteSelectedType == CompanySiteSelectedType.Site && $event.siteId) {
        this.siteId = $event.siteId.valueOf();
        this.siteName = $event.siteName;
      }
    }
    this.updateTriggerActionList();
  }

  onStatusChanged($event: { value: boolean, tagged: CompanyTriggerActionViewmodel }) {
    if ($event.value != $event.tagged.status) {
      if (this.inProgress$)
        this.inProgress$.next(true);
      this.companyTriggerActionService.toggleStatus($event.tagged.id).subscribe(res => {
        $event.tagged.status = $event.value;
        if (this.inProgress$)
          this.inProgress$.next(false);
      });
    }
  }

  onShowEditModal(template, companyTriggerAction: CompanyTriggerActionViewmodel) {
    this.updateCompanyTags();
    this.selectedCompanyTriggerAction = companyTriggerAction;
    this.createFormGroup();
    this.bsModalRef = this.bsModalService.show(template);
    this.onTriggerChange();
  }

  onSave() {
    var model = {
      siteId: this.editFormGroup.get("siteId").value,
      id: parseInt(this.editFormGroup.get("id").value),
      action: parseInt(this.editFormGroup.get("action").value),
      approvalStatusList: this.editFormGroup.get("approvalStatusList")?.value ?? [],
      type: parseInt(this.editFormGroup.get("type").value),
      companyTagId: (this.editFormGroup.get("type").value == this.triggerType.Reject || this.editFormGroup.get("type").value == this.triggerType.SubmitForm) ? null : (this.editFormGroup.get("tagId").value == null ? null : parseInt(this.editFormGroup.get("tagId").value)),
      formId: +this.editFormGroup.get("formId").value == 0 ? null : +this.editFormGroup.get("formId").value,
      formType: +this.editFormGroup.get("formId").value == 0 ? +this.editFormGroup.get("formType").value : null,
      parameters: JSON.stringify(this.editFormGroup.get("parameters").value),
    } as CompanyTriggerActionViewmodel;

    this.inAddEditProgress = true;
    if (model.id == 0) {
      model.status = true;
      this.companyTriggerActionService.add(model).subscribe(res => {
        model.id = res;
        if (model.siteId) {
          var siteName = this.sites.find(x => x.id == model.siteId).name;
          model.siteName = siteName;
        }
        this.companyTriggerActions.push(model);
        this.inAddEditProgress = false;
        this.bsModalRef.hide();
        this.onRefresh();
      }, err => {
        this.inAddEditProgress = false;
        this.errorMessage = err;
        setTimeout(() => {
          this.errorMessage = null;
        }, 10000);
      });
    } else {
      model.status = this.selectedCompanyTriggerAction.status;
      this.companyTriggerActionService.edit(model).subscribe(res => {
        this.selectedCompanyTriggerAction.action = model.action;
        this.selectedCompanyTriggerAction.type = model.type;
        this.selectedCompanyTriggerAction.parameters = model.parameters;
        this.selectedCompanyTriggerAction.siteId = model.siteId;
        this.selectedCompanyTriggerAction.companyTagId = model.companyTagId;
        this.inAddEditProgress = false;
        this.bsModalRef.hide();
        this.onRefresh();
      }, err => {
        this.inAddEditProgress = false;
        this.errorMessage = err;
        setTimeout(() => {
          this.errorMessage = null;
        }, 10000);
      });
    }
  }

  createFormGroup() {
    if (this.selectedCompanyTriggerAction != null) {
      var parameters = JSON.parse(this.selectedCompanyTriggerAction.parameters) as EmailTemplateViewmodel;
      let controls = {
        id: new FormControl(this.selectedCompanyTriggerAction.id),
        siteId: new FormControl(this.selectedCompanyTriggerAction.siteId),
        action: new FormControl(this.selectedCompanyTriggerAction.action, [Validators.required]),
        type: new FormControl(this.selectedCompanyTriggerAction.type, [Validators.required]),
        tagId: new FormControl(this.selectedCompanyTriggerAction.companyTagId),
        formId: new FormControl(this.selectedCompanyTriggerAction.formId),
        formType: new FormControl(this.selectedCompanyTriggerAction.formType ?? FormType.Generic),
        parameters: new FormControl(parameters, [Validators.required]),
        approvalStatusList: new FormControl(this.selectedCompanyTriggerAction.approvalStatusList, [Validators.required, Validators.minLength(1)])
      };
      this.editFormGroup = new FormGroup(controls);
    } else {
      this.editFormGroup = new FormGroup({
        id: new FormControl(0),
        siteId: new FormControl(this.siteId == 0 ? null : this.siteId),
        action: new FormControl(null, [Validators.required]),
        type: new FormControl(this.triggerDefaultValue != 'All' ? this.triggerDefaultValue : null, [Validators.required]),
        tagId: new FormControl(null),
        formId: new FormControl(null),
        formType: new FormControl(FormType.Generic),
        parameters: new FormControl({}, [Validators.required]),

      })
    }

  }

  getTriggerTypeFriendlyName(type: TriggerType): string {
    switch (type) {
      case TriggerType.Flag:
        return 'When a user is tagged';
      case TriggerType.Reject:
        return 'When a user is rejected';
      case TriggerType.SubmitForm:
        return 'When a user submits a form';
      case TriggerType.ApprovalState:
        return 'When check in approval changed';
      default:
        return TriggerType[type];
    }
  }

  getTriggerActionFriendlyName(action: TriggerAction): string {
    switch (action) {
      case TriggerAction.Email:
        return 'Send an Email';
      default:
        return TriggerAction[action];
    }
  }

  getCompanyTagLabel(model: CompanyTriggerActionViewmodel) {
    if (model.companyTagId && this.companyTags && this.companyTags.length) {
      return this.companyTags.filter((i) => i.id == model.companyTagId)[0].title;
    } else {
      return null;
    }
  }

  getTriggerActionSummery(model: CompanyTriggerActionViewmodel) {
    if (model.action == this.triggerAction.Email) {
      try {
        var paramObj = JSON.parse(model.parameters) as EmailTemplateViewmodel;
        return paramObj.recipients.join(", ");

      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  }

  getSelectedSiteName(id: number): string {
    if (this.sites)
      return this.sites.filter((e) => e.id == id)[0]?.name;
  }

  onTagChange($event: number) {
    if ($event == null) return;
    var parameters = this.editFormGroup.controls["parameters"].value as EmailTemplateViewmodel;
    //if (parameters.subject == null || parameters.subject.length == 0)
    parameters.subject = this.companyTags.find(i => i.id == $event).title;
    this.editFormGroup.controls["parameters"].setValue(parameters);
  }

  approvalStatusItemChanged(value) {

    let selectedItems = this.editFormGroup.get('approvalStatusList').value ?? [];

    let index = selectedItems.indexOf(value);
    if (index > -1)
      selectedItems.splice(index, 1);
    else
      selectedItems.push(value);

    this.editFormGroup.controls["approvalStatusList"].setValue(selectedItems);
  }

  onTriggerChange() {
    if (this.editFormGroup.get('type').value == TriggerType.ApprovalState) {
      let states: number[] = [];
      if (this.selectedCompanyTriggerAction != null) {
        states = this.selectedCompanyTriggerAction.approvalStatusList;
      }
      this.editFormGroup.addControl('approvalStatusList', new FormControl(states, [Validators.required, Validators.minLength(1)]),)
    } else {
      this.editFormGroup.removeControl('approvalStatusList');
    }
    this.editFormGroup.updateValueAndValidity();
  }

  onSelectedTrigger(value) {
    this.filterType = value;
    if (this.companyTriggerActions) {
      this.filteredItems = 0;
      for (let ta of this.companyTriggerActions) {
        if (this.filterType == null || ta.type == this.filterType) {
          this.filteredItems++;
        }
      }
    }
  }

}
