<div class="card" style="border:none">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="card-body" style="padding: 0;">
        <div>
            <tabset>
                <tab heading="Procore Integration" *ngIf="enabledIntegration">
                    <app-procore-integration></app-procore-integration>
                </tab>
                <tab heading="Myob Integration" *ngIf="enabledIntegration">
                    <obc-myob-integration></obc-myob-integration>
                </tab>
                <tab heading="HandsHQ Integration" *ngIf="enabledIntegration">
                    <obc-handshq-integration></obc-handshq-integration>
                </tab>
                <tab heading="BioStar2 Integration" *ngIf="enabledIntegration">
                    <obc-biostar-configuration></obc-biostar-configuration>
                </tab>
            </tabset>
        </div>
    </div>
</div>
