import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ProductPlanFeature } from 'src/app/enums/product-plan-feature.enum';

@Component({
  selector: 'obc-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.css']
})
export class CompanySettingsComponent implements OnInit {

  inProgress: boolean = false;
  showTab = [1];
  inProgress$: Subject<boolean> = new Subject();
  ProductPlanFeature = ProductPlanFeature;

  constructor(public userService: UserService, private layoutService: LayoutService) { }

  ngOnInit() {
    this.layoutService.header = "Company Configuration"

    this.inProgress$.subscribe(res => {
      setTimeout(() => {
        this.inProgress = res
      }, 1)
    });
  }

}
