<obc-loader [show]="inProgress"></obc-loader>
<div class="row mt-2">
    <div class="col-md-12">
        <div class="table-responsive">
            <table class="table table-bordered table-striped" *ngIf="messageList">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Sent Date</th>
                        <th>To</th>
                        <th>Subject</th>
                        <th>Content</th>
                        <th>Site</th>
                        <th>Supplier</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="messageList && messageList.length == 0">
                        <td colspan="20">
                            There are no messages.
                        </td>
                    </tr>
                    <tr *ngFor="let msg of messageList">
                        <td>
                            {{msg.registerDateTime | datetime}}
                        </td>
                        <td>{{msg.doneDateTime | datetime}}</td>
                        <td>
                            <span *ngIf="msg.messageType == MessageType.SMS">{{msg.to | mobile}}</span>
                            <span *ngIf="msg.messageType != MessageType.SMS">{{msg.to}}</span>
                        </td>
                        <td>{{msg.subject}}</td>
                        <td>
                            <button class="btn btn-sm btn-info" type="button"
                                (click)="showContent(msg,contentTemplate)">View</button>
                        </td>
                        <td>{{msg.siteName}}</td>
                        <td>{{msg.supplierName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #contentTemplate>
    <div class="card">
        <div class="card-header">
            Content
            <button type="button" class="btn-close btn btn-default pull-right" aria-label="Close"
                (click)="modalRef.hide()">&times;</button>
        </div>
        <div class="card-body">
            <div id="contentElement"></div>
        </div>

    </div>

</ng-template>