import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ExpiryMode } from 'src/app/enums/expiry-mode.enum';
import { SignOffMode } from 'src/app/enums/signoff-mode.enum';
import { SupplierDocumentAccessMode } from 'src/app/enums/supplier-document-access-mode.enum';
import { SupplierDocumentAmendmentMode } from 'src/app/enums/supplier-document-amendment-mode.enum';
import { SupplierDocumentCommentMode } from 'src/app/enums/supplier-document-comment-mode.enum';
import { SupplierDocumentExpireDateMode } from 'src/app/enums/supplier-document-expire-date-mode.enum';
import { WorkflowType } from 'src/app/enums/workflow/workflow-type';
import { CompanyViewModel } from 'src/app/models/company-viewmodel';
import { SupplierDocumentKindViewModel } from 'src/app/models/supplier-document/supplier-document-kind-viewmodel';
import { DocumentKindService } from 'src/app/services/document-kind.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'obc-document-kind-add-edit',
  templateUrl: './document-kind-add-edit.component.html',
  styleUrls: ['./document-kind-add-edit.component.scss']
})
export class DocumentKindAddEditComponent implements OnInit, AfterViewInit {
  @Input() documentKind: SupplierDocumentKindViewModel;
  @Output() onSaveDocumentKind = new EventEmitter<void>()

  SupplierDocumentAccessMode = SupplierDocumentAccessMode;
  SupplierDocumentExpireDateMode = SupplierDocumentExpireDateMode;
  SignOffMode = SignOffMode
  SupplierDocumentCommentMode = SupplierDocumentCommentMode;
  SupplierDocumentAmendmentMode = SupplierDocumentAmendmentMode;

  @Input() companyId: number;
  inProgress: boolean = false;
  inEditProgress: boolean = false;
  selectedCompany: CompanyViewModel;
  WorkflowType = WorkflowType;
  expiryMode: SupplierDocumentExpireDateMode;

  editFormGroup: FormGroup;
  bsModalRef: BsModalRef;
  isGodUser: boolean;

  get isLockedDocumentKind() {
    return this.documentKind.companyId == null;
  }
  _workflowRenderer: any;
  @ViewChild('workflowRenderer') set workflowRenderer(v: any) {
    if (v)
      this._workflowRenderer = v;
  }

  constructor(
    private documentKindService: DocumentKindService,
    private fb: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.isGodUser = this.userService.isGodUser();
    if (!this.documentKind)
      this.documentKind = { companyId: this.companyId } as SupplierDocumentKindViewModel;

    let kind = this.documentKind;
    this.editFormGroup = this.fb.group({
      id: new FormControl(kind?.id ?? 0),
      name: new FormControl(kind?.name ?? '', [Validators.required],),
      accessMode: new FormControl(kind?.accessMode ?? SupplierDocumentAccessMode.Supplier, [Validators.required]),
      companyId: new FormControl(kind.companyId),
      expireDateMode: new FormControl(kind?.expireDateMode ?? SupplierDocumentExpireDateMode.Optional, [Validators.required]),
      signOffMode: new FormControl(kind?.signOffMode ?? SignOffMode.Optional, [Validators.required]),
      commentMode: new FormControl(kind?.commentMode ?? SupplierDocumentCommentMode.Disable, [Validators.required]),
      amendmentMode: new FormControl(kind?.amendmentMode ?? SupplierDocumentAmendmentMode.Disable, [Validators.required]),
      isActive: new FormControl(kind?.isActive ?? true, [Validators.required]),
    });
    if (this.isLockedDocumentKind) {
      Object.keys(this.editFormGroup.controls).forEach(key => {
        let control = this.editFormGroup.get(key);
        if (control instanceof AbstractControl && key != 'isActive')
          control.disable();
      });
    }

    this.editFormGroup.controls['accessMode'].valueChanges.subscribe(_ => {
      if (this.editFormGroup.controls['accessMode'].value == SupplierDocumentAccessMode.HostOnly) {
        let signOffModeFormControl = this.editFormGroup.controls['signOffMode'];
        signOffModeFormControl.setValue(SignOffMode.Disable);
      }
    });
    this.editFormGroup.controls['expireDateMode'].valueChanges.subscribe(_ => {
      this.updateWorkflowExpiryMode();
    });
    this.editFormGroup.controls['name'].valueChanges.subscribe(_ => {
      this.updateWorkflowTitle();
    });
    this.editFormGroup.controls['isActive'].valueChanges.subscribe(isActive => {
      if (isActive)
        this.updateWorkflowTitle();
    });
  }

  ngAfterViewInit(): void {
    this.updateWorkflowTitle();
    this.updateWorkflowExpiryMode();
  }

  updateWorkflowTitle() {
    setTimeout(() => {
      this._workflowRenderer.customWorkflowTitle = this.editFormGroup.controls['name'].value;
    }, 50);
  }

  updateWorkflowExpiryMode() {
    this.expiryMode = this.editFormGroup.controls['expireDateMode'].value;
  }

  showSignOff() {
    return this.editFormGroup.controls['accessMode'].value != SupplierDocumentAccessMode.HostOnly;
  }

  get showWorkflowSection() {
    return this.editFormGroup.get('isActive').value;
  }
  get formIsValid() {
    return (this.isLockedDocumentKind || this.editFormGroup.valid) &&
      (!this.showWorkflowSection || !this._workflowRenderer || this._workflowRenderer.isValid(false));
  }

  get getDocumentKind() {
    var model = {
      id: this.editFormGroup.get('id').value,
      name: this.editFormGroup.get('name').value,
      accessMode: this.editFormGroup.get('accessMode').value,
      companyId: this.editFormGroup.get('companyId').value,
      expireDateMode: this.editFormGroup.get('expireDateMode').value,
      signOffMode: this.editFormGroup.get('signOffMode').value,
      commentMode: this.editFormGroup.get('commentMode').value,
      amendmentMode: this.editFormGroup.get('amendmentMode').value,
      isActive: this.editFormGroup.get('isActive').value,
    } as SupplierDocumentKindViewModel;
    return model;
  }
  onSave() {
    let model = this.getDocumentKind;
    let isActive = this.editFormGroup.get('isActive').value;
    let workflowModel = isActive ? this._workflowRenderer.getWorkflowTemplateModel() : null;
    model.workflowSpecification = workflowModel;

    this.inEditProgress = true;
    this.documentKindService.addUpdateDocumentKind(model).subscribe({
      next: (res) => {
        this.toastrService.success('Document kind saved successfully');
        this.onClosePopup();
      },
      error: (err) => {
        this.toastrService.error(err ?? 'Error while saving document kind!');
        this.inEditProgress = false;
      },
      complete: () => {
        this.inEditProgress = false;
      }
    });
  }

  onClosePopup() {
    this.onSaveDocumentKind?.emit();
  }
}
