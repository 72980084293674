import { FilterType } from "src/app/enums/filter-type.enum";

export interface WorkflowListViewModel {
    title: string;
    instanceId: string;
    definitionId: string;
    companyId: string;
    version: number;
    createdAt: Date;
    lastExecutedAt?: Date;
    activityId: string;
    variables: WorkflowVariable[];
}
export interface WorkflowVariable {
    title: string;
    value: string;

    columnType: FilterType;
    dataType: ObcEntityType;
    filterType: FilterType;
    order: number;
}

export enum ObcEntityType {
    None = 0,
    Supplier = 1,
    Site = 2,
}