import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'obc-announcement-manager',
  templateUrl: './announcement-manager.component.html',
  styleUrls: ['./announcement-manager.component.css']
})
export class AnnouncementManagerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
