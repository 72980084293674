<ng-container *ngFor="let filter of filterQuestions">
    <div class="alert alert-default nomargin filter-card">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"
            (click)="onRemoveQuestionFromFilterByUUID(filter.uuid);">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="mr-1">
            <span>
                <i class="fas fa-filter mr-2"></i>{{ filter.text
                }}<br /><small>{{getFilterAnswers(filter)}}</small>
            </span>
        </div>
    </div>
</ng-container>
