import { Pipe, PipeTransform } from '@angular/core';
import { PermissionModeEnum } from "../enums/permission-mode.enum";

@Pipe({
  name: 'permissionMode'
})
export class PermissionModePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === PermissionModeEnum.Permission) { return "Individual User Permissions Only"; }
    if (value === PermissionModeEnum.Role) { return "Roles Only"; }
    if (value === PermissionModeEnum.Mixed) { return "Mixed (Roles and Individual User Permissions)"; }
    return value;
  }

}
