import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CompanyWorkflowTemplateViewModel } from '../models/workflow/company-workflow-template-view-model';
import { ServerResult } from '../models/server-result-data';
import { WorkflowType } from '../enums/workflow/workflow-type';

@Injectable({
  providedIn: 'root'
})
export class CompanyWorkflowTemplateService {
  apiUrl: string = "/api/companyworkflowTemplate";

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getCompanyWorkflowTemplate = (companyId: number, workflowType: WorkflowType, workflowSubType: number): Observable<CompanyWorkflowTemplateViewModel> =>
    this._http.get<CompanyWorkflowTemplateViewModel>(`${this.apiUrl}/get-company-workflow-template/${companyId}/${workflowType}/${workflowSubType}`)
      .pipe(catchError(this._errorService.handleError));

  getAllCompanyWorkflowTemplate = (): Observable<CompanyWorkflowTemplateViewModel> =>
    this._http.post<CompanyWorkflowTemplateViewModel>(`${this.apiUrl}/get-company-workflow-templates/`, null)
      .pipe(catchError(this._errorService.handleError));

  addCompanyWorkflowTemplate = (model: CompanyWorkflowTemplateViewModel): Observable<ServerResult> =>
    this._http.post<ServerResult>(`${this.apiUrl}/add-company-workflow-template/`, model)
      .pipe(catchError(this._errorService.handleError));

}
