import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subscription } from 'rxjs';
import { forkJoin, Subject } from 'rxjs';
import { FormScope } from 'src/app/enums/form-scope';
import { SiteActiveStatus } from 'src/app/enums/site-active-status.enum';
import { AnnouncementViewModel } from 'src/app/models/announcement-viewmodel';
import { FullFormViewModel } from 'src/app/models/form/form-view-model';
import { QuestionViewModel } from 'src/app/models/question-viewmodel';
import { UserDocumentType } from 'src/app/models/user-document-type';
import { VisitorType } from 'src/app/models/visitor-type';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { FormService } from 'src/app/services/form.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ModalService } from 'src/app/services/modal.service';
import { QuestionService } from 'src/app/services/question.service';
import { UserDocumentTypeService } from 'src/app/services/user-document-type.service';
import { VisitorTypeService } from 'src/app/services/visitor-type.service';
import { FormType } from "../../../enums/form-type";
import { FilterType } from 'src/app/enums/filter-type.enum';
import { FilterList } from 'src/app/models/filter-list';
import { CloneFormQueryModel } from 'src/app/models/induction-viewmodel';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'obc-form-manage',
  templateUrl: './form-manage.component.html',
  styleUrls: ['./form-manage.component.scss']
})

export class FormManageComponent implements OnInit, OnDestroy {
  formScope = FormScope;
  formType = FormType;
  inProgress: boolean;
  upsertModalRef: BsModalRef;

  forms: FullFormViewModel[];
  formToEdit: FullFormViewModel;

  questions: QuestionViewModel[] = [];
  visitorTypes: VisitorType[] = [];
  announcements: AnnouncementViewModel[] = [];
  documents: UserDocumentType[] = [];

  inProgress$: Subject<boolean> = new Subject();
  onlyActiveForms: boolean = true;

  formsList = new BehaviorSubject<FullFormViewModel[]>([]);
  formsFilter: string = '';
  cloneName: string;
  cloneButtonTitle: string;
  formIdToClone: number;
  filterFormTypes = [];
  filters = [
    { data: this.filterFormTypes, name: 'Type', type: FilterType.FormType },
  ] as FilterList[];
  FilterType = FilterType;
  subscription: Subscription;

  constructor(private formService: FormService,
    private questionService: QuestionService,
    private documentTypeService: UserDocumentTypeService,
    private announcementService: AnnouncementService,
    private visitorTypeService: VisitorTypeService,
    private layoutService: LayoutService,
    private modalService: ModalService,
    private bsModalService: BsModalService,
  ) { }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.layoutService.header = "Forms";
    this.loadForms();
    this.InitPage();

    //To handle backdrop-click
    this.subscription = this.bsModalService.onHide.subscribe((_) => {
      this.loadForms();
    })
  }

  get filteredForms() {
    let formsFilter = (this.formsFilter ?? '').toLowerCase();
    let selectedTypes = this.filters.find(x => x.name == 'Type')?.data.map(({ key }) => key);
    return this.formsList.value?.filter(
      (s) =>
        ((formsFilter ?? '').length == 0 ||
          s.name?.toLowerCase().indexOf(formsFilter) >= 0 ||
          s.buttonTitle?.toLowerCase().indexOf(formsFilter) >= 0)
        &&
        (selectedTypes.length == 0 || (selectedTypes.length > 0 && selectedTypes.some(q => q == s.type)))
    );
  }

  onRemoveFilter(currentItems: any[], removeItem = null) {
    currentItems.splice(currentItems.findIndex(p => p.key !== removeItem.key) - 1, 1);
  }

  onUpdateFilter(currentItems: any[], newItems: any[]) {
    currentItems.splice(0, currentItems.length, ...newItems);
  }

  private InitPage() {
    forkJoin({
      questions: this.questionService.getQuestions(),
      documents: this.documentTypeService.getAll(),
      announcements: this.announcementService.getAll(SiteActiveStatus.All),
      visitorTypes: this.visitorTypeService.cacheGetAllVisitorTypes(),
    }).subscribe(res => {
      this.questions = res.questions.map(x => new QuestionViewModel(x));
      this.visitorTypes = res.visitorTypes;
      this.announcements = res.announcements;
      this.documents = res.documents;
    })
  }


  loadForms() {
    this.inProgress = true;

    this.formService.getAllForms().subscribe(
      res => {
        if (res.success) {
          this.forms =
            this.onlyActiveForms == true
              ? res.data.filter((f) => f.isEnable == true)
              : res.data.filter((f) => f.isEnable == false);
          this.formsList = new BehaviorSubject<FullFormViewModel[]>(this.forms);
        } else {
          this.modalService.error(res.message);
          this.inProgress = false;
        }
      }, err => {
        this.modalService.error(err.message);
        this.inProgress = false;
      }, () => {
        this.inProgress = false;
      }
    )
  }

  onModalAutomation(template: any) {
    this.upsertModalRef = this.bsModalService.show(template, { class: 'modal-lg' });
  }

  onModalUpsertForm(template: any, formToEdit?: FullFormViewModel) {
    this.formToEdit = formToEdit;
    this.upsertModalRef = this.bsModalService.show(template, { class: 'modal-xl modal-full-width', ignoreBackdropClick: true, });
  }

  closeQuestionModal() {
    this.upsertModalRef.hide();
  }

  onRemoveForm(formId: number) {
    this.modalService.confirm('Are you sure you want to remove selected form?', 'Remove').subscribe(del => {
      if (del === true) {
        this.inProgress = true;
        this.formService.removeForm(formId).subscribe(
          res => {
            if (res.success) {
              this.loadForms();
            } else {
              this.modalService.error(res.message);
              this.inProgress = false;
            }
          }, err => {
            this.modalService.error(err);
            this.inProgress = false;
          }, () => {
            this.inProgress = false;
          }
        )
      }
    });
  }

  getVisitorType(visitorTypeId: number) {
    return this.visitorTypes.find(x => x.id == visitorTypeId);
  }

  onActiveChanged(value, isActiveMode) {
    if (isActiveMode)
      this.onlyActiveForms = value;
    this.loadForms();
  }

  onCloneForm(template, formId: number) {
    this.formIdToClone = formId;
    this.modalService.show(template, 'modal-xl modal-full-width');
  }
  closeCloneModal() {
    this.modalService.hide();
    this.cloneName = '';
    this.cloneButtonTitle = '';
  }
  cloneForm() {
    if (!this.cloneName?.length) {
      this.modalService.error('Enter form name!');
      return;
    }

    this.inProgress = true;
    this.formService.cloneForm({
      formId: this.formIdToClone,
      name: this.cloneName,
      buttonTitle: this.cloneButtonTitle,
    } as CloneFormQueryModel)
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe(result => {
        this.inProgress = false;
        if (!result)
          this.modalService.error('Clone form failed!');
        else {
          this.loadForms();
          this.closeCloneModal();
        }
      });
  }
}
