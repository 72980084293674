<div *ngIf="formGroup">
  <form [formGroup]="formGroup">
    <div class="d-flex flex-column" *ngIf="model">
      <div>
        <i *ngIf="valid" style="color: green;" class="fas fa-check-circle  mr-2"></i>
        <i *ngIf="!valid" style="color: red;" class="fas fa-times-circle  mr-2"></i>
        <obc-question-title-renderer [question]="model.question"></obc-question-title-renderer>
      </div>
      <div *ngIf="!isRichTextQuestion" class="form-group d-flex justify-content-end">
        <input class="form-control" style="max-width: 350px;" formControlName="value">
        <div class="form-group">
        </div>
      </div>
      <obc-markdown-editor *ngIf="isRichTextQuestion" [value]="model.answers"
      (valueChanged)="formGroup.controls.value.setValue($event)" [id]="id">
      </obc-markdown-editor>
    </div>
  </form>
</div>
