import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { ErrorService } from "./error.service";
import { ApproveRejectQueryModel } from '../models/approve/approve-reject-query-model';
import { ResetEntityStatusQueryModel } from '../models/approve/reset-entity-status-query-model';
import { SaveScheduledReportViewmodel } from '../models/save-scheduled-report-model';
import { SiteCheckInOutData } from '../models/site-check-in-out-data';

@Injectable({
  providedIn: 'root'
})
export class ApproveService {

  constructor(private http: HttpClient, private _errorService: ErrorService) { }
  baseUrl = '/api/approve';
  approveRejectEntity(model: ApproveRejectQueryModel): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/approve-reject-entity`, model)
      .pipe(catchError(this._errorService.handleError));
  }
  resetEntityStatus(model: ResetEntityStatusQueryModel): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/reset-status`, model).pipe(catchError(this._errorService.handleError));
  }
  saveScheduledReport(model: SaveScheduledReportViewmodel): Observable<SiteCheckInOutData> {
    return this.http.post<SiteCheckInOutData>(`${this.baseUrl}/save-scheduled-report/`, model)
      .pipe(catchError(this._errorService.handleError));
  }
}
