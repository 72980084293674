
export interface VariableDefinition {
  key: string;
  title: string;
  type: VariableDefinitionType;
  mode: VariableDefinitionMode;
}
export interface VariableValueDefinition extends VariableDefinition {
  value: string;

}
export enum VariableDefinitionType {
  Unknown = 0,
  Date = 1,
}
export enum VariableDefinitionMode {
  Mandatory = 0,
  Optional = 1,
}
