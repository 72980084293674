import { Component, EventEmitter, Input, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { dateFormat, dateTimeFormat, parseDateTime, parseDateTime2 } from 'src/app/helpers/date-time-format';
import { datetimeValidator } from 'src/app/helpers/datetimeValidator';
import { DatetimePipe } from 'src/app/pipes/datetime.pipe';
import { DateTimePickerComponent } from '../date-time-picker/date-time-picker.component';

@Component({
  selector: 'obc-input-date-time-picker',
  templateUrl: './input-date-time-picker.component.html',
  styleUrls: ['./input-date-time-picker.component.scss'],
  providers: [DatetimePipe]
})
export class InputDateTimePickerComponent implements ControlValueAccessor, OnInit {
  dateFormat = dateTimeFormat;
  datepickerComponent: DateTimePickerComponent;
  _mode = DatetimePickerMode.DateTimePicker;

  @Input() set value(value: string) {
    this.addRequiredToFormControl();
  }

  @Input() placeholder: string = '';
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() set mode(value: DatetimePickerMode) {
    this._mode = value;
    if (this._mode == DatetimePickerMode.Calender)
      this.dateFormat = dateFormat;
    else
      this.dateFormat = dateTimeFormat;
    var val = this.datePipe.transform(this._date, this.dateFormat);
    this.inputFormControl.setValue(val);
  }
  get mode(): DatetimePickerMode {
    return this._mode;
  }
  _date: Date;
  @Input() set date(value: Date) {
    this._date = value;
    this.EmitChanges();
  };
  get date(): Date {
    return this._date;
  }
  onChange: Function;
  @Output() valueChange = new EventEmitter();
  datetimePickerformControl = new FormControl(new Date());
  inputFormControl = new FormControl(null, [datetimeValidator]);
  @ViewChild('popDivTemplate') popDivTemplate: any;
  @ViewChild('pop') pop;
  @ViewChild('datepickerRef') datepickerRef;
  @ViewChild('self') self;
  constructor(@Optional() @Self() private controlDirective: NgControl, private datePipe: DatetimePipe) {
    if (controlDirective)
      controlDirective.valueAccessor = this;
    this.inputFormControl.setValue(this.datePipe.transform(new Date(), this.dateFormat));
  }

  writeValue(date: Date): void {
    this._date = this.getDateTime(date);
    var inputValue = this.datePipe.transform(this._date, this.dateFormat);
    this.inputFormControl.setValue(inputValue?.toLowerCase() == "invalid date" || inputValue == null ? null : inputValue);
    this.datetimePickerformControl.setValue(this._date);
  }

  getDateTime(date: Date) {
    var date1 = parseDateTime(date, true);
    if (date1 == null || date1?.toDateString()?.toLowerCase() == "invalid date")
      date1 = parseDateTime2(date, this.dateFormat);
    return date1;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.inputFormControl.disable();
      this.datetimePickerformControl.disable();
    } else {
      this.inputFormControl.enable();
      this.datetimePickerformControl.enable();
    }
  }

  ngOnInit() {
    if (this.minDate != null && (this._date ?? new Date()) < this.minDate) {
      this._date = this.minDate;
      this.datetimePickerformControl.setValue(this._date);
    }
    this.addRequiredToFormControl();

    document.addEventListener('mousedown', this.closePopoverOnClickOutside.bind(this));

    this.inputFormControl.valueChanges.subscribe(res => {
      this.inputValueChange(res);
    });
  }

  addRequiredToFormControl() {
    if (!this.controlDirective?.control?.hasValidator(this.validate.bind(this))) {
      this.controlDirective?.control?.addValidators([this.validate.bind(this)]);
    }
    this.controlDirective?.control?.updateValueAndValidity();

    if (this.controlDirective?.control?.hasValidator(Validators.required)) {
      this.inputFormControl.addValidators([Validators.required]);
    } else {
      this.inputFormControl.removeValidators([Validators.required]);
    }
  }


  validate({ value }: FormControl) {
    if (!this.controlDirective?.control.valid || !this.inputFormControl.valid || !this.datetimePickerformControl.valid) // mod it later
      return { invalid: true }
  }

  inputValueChange(res: any) {
    try {
      if (!res)
        this.date = null;

      var parsed = parseDateTime(res);
      if (!parsed) {
        this.controlDirective?.control.updateValueAndValidity();
        return;
      }
      if (parsed && this.date != parsed) {
        this.date = parsed;
        this.datetimePickerformControl.setValue(parsed);
      }
    } catch {
    }
    this.controlDirective?.control.updateValueAndValidity();

  }

  datetimePickerValueChange(res: Date) {
    if (this.date != res) {
      this.date = res;
      this.inputFormControl.setValue(this.datePipe.transform(res, this.dateFormat));
    }

    this.controlDirective?.control.updateValueAndValidity();
  }

  closePopoverOnClickOutside(event: any) {
    var bool = this.popDivTemplate.nativeElement!.contains(event.target);
    if (this.datepickerComponent) {
      if (this.datepickerComponent?.selfRef?.nativeElement)
        bool = bool || this.datepickerComponent.selfRef.nativeElement!.contains(event.target);
    }
    if (!bool) {
      this.pop.hide();
    }
  }

  EmitChanges() {
    if (this.onChange)
      this.onChange(this.date);
    this.valueChange.emit(this.date);
    this.controlDirective?.control.updateValueAndValidity();
  }

  containsElement(target: any) {
    return this.self?.nativeElement?.contains(target) || this.datepickerRef?.selfRef?.nativeElement.contains(target);
  }

  onSelectDateTime($event) {
    if ($event && $event.toString()?.toLowerCase() != "invalid date") {
      this.writeValue($event);
      this.pop.hide();
    }
  }
}
