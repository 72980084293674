export enum VisitStatus {
  Full = 0,
  Start = 1,
}

export interface BaseAnnouncementVisit {
  announcementId: number;
  visitStatus: VisitStatus;
  isCheckedMandatorySignOff: boolean;
  isSupplierAnnouncement: boolean;
  inductionId?: number;
}

export interface AnonymousAnnouncementVisitModel extends BaseAnnouncementVisit {
  anonymousAttendanceKey: any;
}

export interface AnnouncementVisitModel extends BaseAnnouncementVisit {
  siteAttendanceId: number;
  formId: number;
  isPreCheckIn: boolean;
  isCheckedMandatorySignOff: boolean;
}
