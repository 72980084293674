<div class="card">
  <div class="card-header">
    <div class="d-flex flex-row justify-content-between">
      <div>Workflow Cartable</div>
    </div>
  </div>
  <div class="card-body mt-2">
    <div class="row">
      <div class="col-md-12">
        <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>
          <ng-template obcGridTemplateName="additional-info-template" let-data let-item="data">
            <ul>
              <li *ngFor="let d of item.variables">
                {{d.title}}: <strong>{{d.value}}</strong>
              </li>
            </ul>
          </ng-template>
          <ng-template obcGridTemplateName="viewDetails" let-item="data">
            <a class="btn btn-sm btn-info ml-2 m-1" (click)="openWorkflowEngine(item.instanceId, workflowEngine)">
              View Details
            </a>
            <button class="btn btn-sm btn-primary ml-2 m-1" (click)="onOpenHistory(historyView, item.instanceId)">
              History
            </button>
            <button class="btn btn-sm btn-danger ml-2 m-1" disabled *ngIf="showTerminateButton(item)">
              Terminate
            </button>
          </ng-template>
        </app-base-grid>
      </div>
    </div>
  </div>
</div>
<ng-template #historyView>
  <obc-workflow-instance-history [workflowInstanceId]="instanceId" (hide)="closeModal()">
  </obc-workflow-instance-history>
</ng-template>

<ng-template #workflowEngine>
  <obc-workflow-engine [workflowInstanceId]="selectedInstanceId" (workflowEningeModalHide)="closeModal()">
  </obc-workflow-engine>
</ng-template>