import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TriggerAction } from 'src/app/enums/trigger-action.enum';
import { ToolStatusViewModel } from 'src/app/models/inventory/tool-status-model';
import { ToolStatusTriggerModel, ToolStatusTriggerViewModel } from 'src/app/models/inventory/tool-status-trigger-model';
import { InventoryService } from 'src/app/services/inventory.service';
import { ManageInventoryService } from 'src/app/services/manage-inventory.service';

@Component({
  selector: 'obc-inventory-status-trigger-editor',
  templateUrl: './inventory-status-trigger-editor.component.html',
  styleUrls: ['./inventory-status-trigger-editor.component.scss']
})
export class InventoryStatusTriggerEditorComponent implements OnInit {
  @Input() toolStatus: ToolStatusViewModel = null;
  _trigger: ToolStatusTriggerViewModel;
  TriggerAction = TriggerAction;
  formGroup = new FormGroup({
    action: new FormControl(null, [Validators.required]),
    parameters: new FormControl(null, [Validators.required])
  });
  @Input() set trigger(value: ToolStatusTriggerViewModel) {
    if (this._trigger?.id != value?.id) {
      this.formGroup.controls.action.setValue(value?.action);
      this.formGroup.controls.parameters.setValue(JSON.parse(value?.parameters));
    }
    this._trigger = value;
  }

  @Output() close = new EventEmitter();
  constructor(private manageInventoryService: ManageInventoryService) { }

  ngOnInit() {
  }

  upsert() {
    var model = {
      action: this.formGroup.controls.action.value,
      parameters: JSON.stringify(this.formGroup.controls.parameters.value),
    } as ToolStatusTriggerModel;
    if (this._trigger?.id) {
      this.manageInventoryService.updateToolStatusTrigger(this.toolStatus.id, this._trigger.id, model).subscribe(res => {
        this.close.emit();
      })
    } else {
      this.manageInventoryService.addToolStatusTrigger(this.toolStatus.id, model).subscribe(res => {
        this.close.emit();
      })
    }
  }

  getTriggerActionFriendlyName(action: TriggerAction): string {
    switch (action) {
      case TriggerAction.Email: return 'Send an Email';
      default: return TriggerAction[action];
    }
  }

}
