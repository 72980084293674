import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
  transform(list: any[], field: string, desc: boolean): any[] {
    if (!Array.isArray(list)) {
      return;
    }
    if (!field)
      return list;
    var fac = desc ? 1 : -1;
    list.sort((a: any, b: any) => {
      var v1;
      var v2;
      var sections = field.split('.');
      sections.forEach(section => {
        if (v1 == null && v2 == null) {
          v1 = a[section];
          v2 = b[section];
        } else {
          v1 = v1[section];
          v2 = v2[section];
        }
      });
      if (v1 == null && v2 == null)
        return 0;
      if (v1 == null && v2 != null)
        return -1 * fac;
      if (v2 == null && v1 != null)
        return 1 * fac;

      v1 = v1.toString().toLowerCase();
      v2 = v2.toString().toLowerCase();

      if (v1 < v2) {
        return -1 * fac;
      } else if (v1 > v2) {
        return 1 * fac;
      } else {
        return 0;
      }
    });
    return list;
  }

}
