import { Pipe, PipeTransform } from '@angular/core';
import { SiteInductionStatus } from '../enums/site-induction-status';

@Pipe({
  name: 'siteInductionStatus'
})
export class SiteInductionStatusPipe implements PipeTransform {

  transform(value: any): string {
    if (value == SiteInductionStatus.ApprovedByHost) return "Approved";
    if (value == SiteInductionStatus.AutoApproved) return "Auto-Approved";
    if (value == SiteInductionStatus.PendingToApprove) return "Pending";
    if (value == SiteInductionStatus.RejectByHost) return "Rejected";
    return value?.toString();
  }

}
