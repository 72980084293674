import { DocumentMultiImageMode } from '../enums/document-multi-image-mode-enum';
import { OptionalMandatoryState } from '../enums/site-document-state.enum';
import { SiteQuestionViewModel } from "./site-question-viewmodel";
import { DocumentViewModel } from "./document-viewmodel";

export class BaseUserDocumentType {
  id: number;
  title: string;
}

export class UserDocumentType extends BaseUserDocumentType {
  enableTitle: boolean;
  enableExpireDate: boolean;
  state: OptionalMandatoryState;
  multiImageMode: DocumentMultiImageMode;
  order: number;
  initialAnswer?: DocumentViewModel;
  isSelectable: boolean;
  parentId: number;
  isAvailable: boolean;
}

export class RequiredDocumentViewModel {
  documentType: UserDocumentType;
  documentTypes: UserDocumentType[];
  displayOrder: number;
  additionalDocument: boolean
  questionId: number;
  inductionId: number;

  //Only To Show Document Mode Question Title,Rich Text and Others
  question: SiteQuestionViewModel;
  isDirty?: boolean;
}
