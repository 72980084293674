import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { Table } from 'src/app/enums/table.enum';
import { GridColumn, GridConfig } from 'src/app/models/grid/grid.config';
import { UserProfileCardViewModel } from 'src/app/models/userProfile/worker-profile-view-model';
import { ModalService } from "../../../../services/modal.service";

@Component({
  selector: 'obc-worker-profile-card-history',
  templateUrl: './worker-profile-card-history.component.html',
  styleUrls: ['./worker-profile-card-history.component.scss']
})
export class WorkerProfileCardHistoryComponent implements OnInit {
  @Input() companyUserCardId: number = null;
  @Input() modalReference: any;
  @ViewChild('grid') grid: any;
  gridColumns: GridColumn<UserProfileCardViewModel>[] = [];
  gridConfig: GridConfig = {} as GridConfig;

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
    this.prepareGridConfig();
  }

  prepareGridConfig() {

    let initialFilters = [];
    if (this.companyUserCardId) {
      initialFilters.push({ key: 'companyUserCardId', value: this.companyUserCardId });
    }

    this.gridConfig = new GridConfig({
      apiUrl: '/api/workerprofile/get-user-card-history',
      tableType: Table.WorkerProfileCards,
      forceHideAllControlButtons: true,
      displayQuestionsFilter: false,
      displayGeneralSearch: false,
      initialFilters: initialFilters,
    });
    this.gridColumns = [
      {
        name: "Type",
        key: "documentTypeTitle",
        type: FilterType.Text,
        enableFilter: false,
        enableSort: false,
      },
      {
        name: "Update Date",
        key: "dateTime",
        type: FilterType.DateTime,
        enableFilter: false,
        enableSort: false,
      },
      {
        name: "Title",
        key: "documentTitle",
        enableFilter: false,
        enableSort: false,
      },
      {
        name: "Expiry Date",
        key: "expireDate",
        type: FilterType.DateRange,
        enableFilter: false,
        enableSort: false,
      },
      {
        name: "Status",
        key: "documentExpiryStatus",
        type: FilterType.DocumentExpiryStatus,
        enableFilter: false,
        enableSort: false,
      },
      {
        name: "File",
        key: "fileUrls",
        type: FilterType.File,
        enableSort: false,
        enableFilter: false,
        displayFunction: (_) => { return 'View' },
      },
    ]
  };

  closePopup() {
    this.modalService.hide(this.modalReference);
  }
}
