import { Component, Input, OnInit } from '@angular/core';
import { BasePagingModel } from "../../models/paging_model";
import { getPageSizeByUrl } from "../../helpers/general-functions";
import { BaseSiteViewModel } from "../../models/site-viewmodel";
import { UserPermission } from "../../enums/user-permission.enum";
import { LayoutService } from "../../services/layout.service";
import { ActivatedRoute, Params } from "@angular/router";
import { ModalService } from "../../services/modal.service";
import { SiteAttendanceService } from "../../services/site-attendance.service";
import { finalize } from "rxjs/operators";

import { SiteActiveStatus } from "../../enums/site-active-status.enum";
import {
  ApprovePreCheckInModel,
  PreAttendanceReportViewModel,
  UserPreAttendanceCheckInsViewModel
} from "../../models/pre-check-in/user-pre-attendance-check-ins-view-model";
import { PagingRequest } from "../../models/paging-request-model";
import { PreCheckInListQueryModel } from "../../models/pre-check-in/pre-checkin-list-query-model";
import { PagingViewModel } from "../../models/site-list-paging-view-model";

@Component({
  selector: 'obc-pre-check-in-list',
  templateUrl: './pre-check-in-list.component.html',
  styleUrls: ['./pre-check-in-list.component.scss']
})
export class PreCheckInListComponent implements OnInit {

  pagingInfo: BasePagingModel = {
    pageNumber: 0,
    pageSize: getPageSizeByUrl() ?? 30,
  }

  @Input() siteId: number;

  //selectedSite: BaseSiteViewModel;
  inProgress = false;
  userPermissions = UserPermission;
  SiteActiveStatus = SiteActiveStatus;
  serverResult: PagingViewModel<PreAttendanceReportViewModel>;

  constructor(
    private route: ActivatedRoute,
    public siteAttendanceService: SiteAttendanceService,
    private modalService: ModalService,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe((_: Params) => {
      this.fetchPendingList();
    });
  }

  fetchPendingList() {
    this.inProgress = true;
    this.siteAttendanceService.getPreCheckInsList({
      query: { siteIdList: this.siteId ? [this.siteId] : [] },
      pagingInfo: this.pagingInfo
    } as PagingRequest<PreCheckInListQueryModel>)
      .pipe(finalize(() => {
        this.inProgress = false
      }))
      .subscribe(res => {
        this.serverResult = res;
      }, err => {
        this.modalService.error(err)
      })
  }

  finaliseOrCancelPreCheckin(item: UserPreAttendanceCheckInsViewModel, isConfirm: boolean) {

    let message = 'Are you sure you want to ' + (isConfirm ? 'confirm' : 'cancel') + ' pre check-in?';
    this.modalService.confirm(message, (isConfirm ? 'Confirm' : 'Cancel')).subscribe(confirmRes => {
      if (confirmRes === true) {
        this.inProgress = true;

        const model = {
          preSiteAttendanceId: item?.preSiteAttendanceId,
        } as ApprovePreCheckInModel;
        if (isConfirm) {
          this.siteAttendanceService.approvePreCheckInModel(model)
            .pipe(finalize(() => {
              this.inProgress = false
            }))
            .subscribe(res => {
              if (res && res.success) {
                this.modalService.success('Confirmed successfully.');
                this.fetchPendingList();
              } else
                this.modalService.error(res.message);
            }, err => {
              this.modalService.error(err)
            });
        }
        else {
          this.siteAttendanceService.cancelPreCheckInModel(model.preSiteAttendanceId)
            .pipe(finalize(() => {
              this.inProgress = false
            }))
            .subscribe(res => {
              if (res && res.success) {
                this.modalService.success('Canceled successfully.');
                this.fetchPendingList();
              } else
                this.modalService.error(res.message);
            }, err => {
              this.modalService.error(err)
            });
        }
      }
    });
  }

  onPageChange(paginationInfo) {
    this.pagingInfo.pageSize = paginationInfo.pageSize;
    this.pagingInfo.pageNumber = paginationInfo.pageNumber;
    this.fetchPendingList();
  }
}
