import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FileType } from 'src/app/enums/file-type.enum';
import { OptionalMandatoryState } from 'src/app/enums/site-document-state.enum';
import { InternalAnnouncementVisitViewModel } from 'src/app/models/announcement-viewmodel';
import { AnonymousAnnouncementVisitModel, VisitStatus } from 'src/app/models/announcement-visit';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { ModalService } from 'src/app/services/modal.service';
import { SiteAnnouncementForceViewMode } from "../../../enums/site-announcement-force-view-mode";

@Component({
  selector: 'obc-announcement-item-renderer',
  templateUrl: './announcement-item-renderer.component.html',
  styleUrls: ['./announcement-item-renderer.component.scss']
})
export class AnnouncementItemRendererComponent implements OnInit {
  @Input() ann: InternalAnnouncementVisitViewModel;
  @Input() notifyVisits = true;
  @Input() anonymousAttendanceKey: string = null;
  @Input() largeButton: boolean = true;
  @Input() validateComponent: boolean = false;
  @Input() isSupplierAnnouncement: boolean = false;
  @Output() onAnnouncementVisit = new EventEmitter<number>();
  AnnouncementType = FileType;
  optionalMandatoryState = OptionalMandatoryState;
  constructor(private announcementSertvice: AnnouncementService,
    private modalService: ModalService,
    private bsModalService: BsModalService
  ) { }
  ngOnInit() {
  }

  bsModalRef = null;
  @Output('onFileViewed') onFileViewed = new EventEmitter();
  FileType = FileType;

  fileHasContent(url, type) {
    let extension = url.split(/[#?]/)[0].split('.').pop().trim();
    var binaryFileTypesOpen = ['xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx'];
    return type == FileType.Pdf || type == FileType.Image ||
      (type == FileType.Binary && binaryFileTypesOpen.includes(extension));
  }

  onOpenFileViewer(template: any) {
    this.ann.viewed = true;
    this.onAnnouncementVisit.emit(this.ann.announcementId);
    this.bsModalRef = this.bsModalService.show(template, {
      class: "modal-xl modal-full-width",
      ignoreBackdropClick: false,
    })
  }

  onFileOpened(fileUrl: string) {
    this.onFileViewed.emit(fileUrl);
  }

  closeModal() {
    if (this.bsModalRef)
      this.bsModalRef.hide();
  }

  onClickAnnouncement() {
    this.ann.viewed = true;
    this.onAnnouncementVisit.emit(this.ann.announcementId);

    // TODO display text annoncement
    if (this.ann.type == FileType.Text)
      this.modalService.info(this.ann.data);

    // if (this.notifyVisits) {
    //   var model = {
    //     announcementId: this.ann.announcementId,
    //     anonymousAttendanceKey: this.anonymousAttendanceKey,
    //     visitStatus: VisitStatus.Full,
    //     isSupplierAnnouncement: this.isSupplierAnnouncement ?? false,
    //   } as AnonymousAnnouncementVisitModel;

    //   this.announcementSertvice.NotifyAnonymousAnnouncementVisit(model)
    //     .subscribe(res => {
    //       if (!res) // expired key
    //       {
    //         this.modalService.error("your session key is expired, reloading page ...");
    //         setTimeout(() => {
    //           window.location.reload();
    //         }, 5000);
    //       }
    //     });
    // }
  }

  isAnnouncementMandatory(announcement) {
    return (announcement.state == SiteAnnouncementForceViewMode.MandatoryView ||
      announcement.state == SiteAnnouncementForceViewMode.SetPlaythroughToMandatory ||
      announcement.state == SiteAnnouncementForceViewMode.MandatoryViewAndSignOff
    )
  }
}

