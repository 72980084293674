import { Pipe, PipeTransform } from '@angular/core';
import { AccompanierMode } from '../enums/accompanier-mode';

@Pipe({
  name: 'accompanierMode'
})
export class AccompanierModePipe implements PipeTransform {

  transform(value: unknown): any {
    if (value === AccompanierMode.Disable) { return "Do not collect"; }
    if (value === AccompanierMode.OptionalNameAndMobile) { return "Collect Number of People and Optional Names"; }
    if (value === AccompanierMode.NameAndOptionalMobile) { return "Collect All Names and Optional Mobile Numbers"; }
    if (value === AccompanierMode.NameAndMobile) { return "Collect All Names and Mobile Numbers"; }
    return value;
  }
}
