<section class="card">
  <header class="card-header">
    <h5>Review</h5>
    <div class="card-actions" *ngIf="showCloseButton">
      <a class="card-action card-action-dismiss" (click)="closeModal()"></a>
    </div>
  </header>
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="row p-2" *ngIf="showEndDate">
      <div class="col-md-12">
        <i class="fas fa-calendar pr-2"></i>End Date:
        <ng-container>
          <div class="form-group p-2">
            <obc-input-date-time-picker [(ngModel)]="endDate" [mode]="datetimePickerMode.Calender">
            </obc-input-date-time-picker>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="row p-2 mt-2">
      <div class="col-md-12">
        <i class="fas fa-pen pr-1"></i>
        <span class="pr-2">Review comments:</span>
        <textarea rows="5" class="form-control mt-1" placeholder="Enter review comments ..."
          (input)="onReviewCommentChange()" [(ngModel)]="description"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button *ngIf="!isRequestRevision" class="btn btn-danger ml-2 pull-right" (click)="approveReject(false)">
          Reject
        </button>
        <button *ngIf="isRequestRevision" class="btn btn-success ml-2 pull-right" (click)="approveReject(false)">
          Request Revision
        </button>

        <button *ngIf="!isRequestRevision" class="btn btn-success pull-right" (click)="approveReject(true)">
          Approve
        </button>
      </div>
    </div>
  </div>
</section>