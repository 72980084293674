<div class="row" *ngIf="isTemplateCompany">
  <div class="col-md-12 alert alert-danger">You cannot add site to template companies!</div>
</div>
<div class="row" *ngIf="!isTemplateCompany">
  <div class="col-12">
    <section class="card">
      <obc-loader [show]="inProgress"></obc-loader>
      <div class="card-header mb-0">
        <span class="mr-5" *ngIf="userService.isCompanyAdminUser()">
          <span class="d-inline-block mt-2 mr-1">Display Mode: </span>
          <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" [value]="onlyActiveSites"
            (valueChanged)="onActiveChanged($event,true)">
          </obc-switch>
        </span>

        <span>
          <obc-switch [trueLabel]="' Sites '" [falseLabel]="' Templates '" [value]="onlyRealSites"
            (valueChanged)="onActiveChanged($event,false)">
          </obc-switch>
        </span>

      </div>
      <header class="card-header d-flex  justify-content-between">
        <h5>Sites</h5>
        <div *ngIf="userService.isCompanyAdminUser()">

          <button *ngIf="userService.canAddSite" class="btn btn-sm btn-dark-scale-2 mr-1"
            (click)="onCloneSiteFromTemplate(cloneSite)">
            <i class="fas fa-plus mr-1"></i> Add New Site From Template
          </button>

          <button *ngIf="userService.canAddSite && onlyRealSites" class="btn btn-sm btn-success mr-1"
            [routerLink]="['/manage-site/new']">
            <i class="fas fa-plus mr-1"></i> Add New Site
          </button>
          <button *ngIf="userService.canAddSite && !onlyRealSites" class="btn btn-sm btn-warning mr-1"
            [routerLink]="['/manage-site/new-template']">
            <i class="fas fa-plus mr-1"></i> Add New Template
          </button>

          <button
            *ngIf="isCompanyOwner && onlyRealSites && currentCompany != null && (currentCompany.maxSites == null || currentCompany.maxSites > 0)"
            class="btn btn-sm btn-info mr-1" [routerLink]="['/import-sites']">
            <i class="fas fa-plus mr-1"></i>Import Sites
          </button>
          <a *ngIf="onlyRealSites && userService.hasCompanyFeature(ProductPlanFeature.Export)" target="_blank"
            class="btn btn-sm btn-danger" style="color: white !important;"
            href="/general/export-sites/{{onlyActiveSites}}">
            <i class="fas fa-copy mr-1"></i>Export Sites
          </a>
        </div>
      </header>
      <!--      <obc-descriptor [text]="'To add a new site, click on the add new site and follow the prompts'"></obc-descriptor>-->
      <div class="card-body">
        <div>
          <div>
            <div>
              <input [attr.disabled]="siteList == null ? true : null" type="text" class="form-control"
                [formControl]="filterFormcontrol" placeholder="Search by name, description and reference" />
            </div>
            <div *ngIf="siteList && siteList.length">
              <ul class="simple-post-list">
                <li class="d-flex flex-column justify-content-around" *ngFor="let site of siteList">
                  <div class="post-info flex-fill">
                    <a class="image-holder" [routerLink]="[getSiteManageRouterLink(site)]">
                      <img class="site-logo" *ngIf="site.logoUrl" [src]="site.logoUrl">
                    </a>
                    <div>
                      <a [routerLink]="[getSiteManageRouterLink(site)]">
                        <strong>{{site.name}}</strong> <small *ngIf="site.siteReference">
                          ({{site.siteReference}})</small>
                        <strong *ngIf="site?.isTemplate" class="ml-2 badge badge-danger">Template</strong>
                        <strong class="ml-2 badge badge-danger" *ngIf="site.isExternalSite">
                          Shared By {{site.companyName}}
                        </strong>
                      </a>
                      <span class="ml-1 verify-loc"
                        *ngIf="!site.isTemplate && !site.locationVerifiedUserId && userService.hasUserPermissionForCompany(userPermissions.AdjustSiteLocation, site.id)"
                        tooltip="Click here to verify site location"
                        (click)="openLocationVerifyModal(locationVerify,site)">
                        <i class="fas fa-exclamation-triangle text-danger"></i>
                      </span>
                      <div class="post-meta">
                        {{site.description}}
                      </div>

                    </div>
                  </div>
                  <div class="mt-1">
                    <div class="pull-right align-self-center mr-2">
                      <button
                        *ngIf="!site.isExternalSite && !site.isTemplate && site.isActive !== true && userService.isCompanyAdminUser()"
                        class="btn btn-sm " (click)="changeActiveState(site)"
                        [ngClass]="site.isActive ? 'btn-info' : 'btn-warning text-white'">
                        {{site.isActive ? 'Deactivate Site' : 'Activate Site'}}
                      </button>
                    </div>
                    <div
                      *ngIf="!site.isExternalSite && site.isActive === true && userService.isCompanyAdminUser() && userService.canAddSite"
                      class="pull-right align-self-center mr-2">
                      <button (click)="onCloneSite(site, cloneSite)" class="btn btn-sm btn-dark-scale-2">
                        Clone
                      </button>
                    </div>
                    <div
                      *ngIf="!site.isExternalSite && !site.isTemplate && site.secretCode && site.isActive === true && this.userService.hasUserPermissionForCompany(userPermissions.ModifySite, site.id)"
                      class="pull-right align-self-center mr-2">
                      <a target="_blank" [href]="getShowQrCodeWithInstruction(site)" class="btn btn-sm btn-success">
                        Print Instructions
                      </a>
                    </div>

                    <!--                    <div
                      *ngIf="userService.hasUserPermissionForCompany(userPermissions.ManageSiteAnnouncement, site.id) && userService.hasCompanyFeature(ProductPlanFeature.Announcements) && site.isActive === true"
                      class="pull-right align-self-center mr-2">
                      <button [routerLink]="['/sites/'+site.id+'/announcements']"
                        class="btn btn-sm btn-success">Announcements</button>
                    </div>-->

                    <div *ngIf="!site.isExternalSite && hasAccessToSiteAsset(site)"
                      class="pull-right align-self-center mr-2">
                      <button [routerLink]="['/site-assets/'+site.id + '/sites']"
                        [state]="{ data: {siteId:site.id,siteCompanyId:site.companyId,siteCompanyName:site.companyName,siteName:site.name}}"
                        class="btn btn-sm btn-secondary">
                        Site Assets
                      </button>
                    </div>
                    <div
                      *ngIf="!site.isExternalSite && !site.isTemplate && userService.isCompanyAdminUser() && site.isActive === true && userService.hasCompanyFeature(ProductPlanFeature.Automation)"
                      class="pull-right align-self-center mr-2">
                      <button [routerLink]="['/manage-automations/'+site.id]" class="btn btn-sm btn-info">
                        Automations
                      </button>
                    </div>

                    <div class="pull-right align-self-center mr-2"
                      *ngIf="!site.isExternalSite && site.isActive === true && !site.isTemplate && userService.hasUserPermissionForCompany(userPermissions.SupplierManagement, site.id)">
                      <button [routerLink]="['/site-supplier-documents/'+site.id]" class="btn btn-sm btn-danger">
                        Suppliers
                      </button>
                    </div>

                    <div *ngIf="site.isExternalSite && hasAccessToSiteAsset(site)"
                      class="pull-right align-self-center mr-2">
                      <button
                        [routerLink]="['/manage-site-supplier/'+site.externalSiteSupplierId + '/'+ site.externalSupplierId + '/' + site.id + '/sites']"
                        fragment="assets" class="btn btn-sm btn-info">
                        Site Assets/Plant
                      </button>
                    </div>
                    <div *ngIf="site.isExternalSite && site.isActive === true && !site.isTemplate"
                      class="pull-right align-self-center mr-2">
                      <button
                        [routerLink]="['/manage-site-supplier/'+site.externalSiteSupplierId + '/'+ site.externalSupplierId + '/' + site.id + '/sites']"
                        fragment="permits" class="btn btn-sm btn-info">
                        Permits
                      </button>
                    </div>
                    <div class="pull-right align-self-center mr-2"
                      *ngIf="site.isExternalSite && site.isActive === true && !site.isTemplate">
                      <button
                        [routerLink]="['/manage-site-supplier/'+site.externalSiteSupplierId + '/'+ site.externalSupplierId + '/' + site.id + '/sites' ]"
                        fragment="documents" class="btn btn-sm btn-info">
                        Site Documents
                      </button>
                    </div>
                    <div class="pull-right align-self-center mr-2" *ngIf="hasAccessToManageSite(site)">
                      <button [routerLink]="[getSiteManageRouterLink(site)]"
                        class="btn btn-sm btn-primary">{{site?.isTemplate
                        ==
                        true
                        ? 'Edit Template' : ('Manage '+ (site.isExternalSite?'Setting':'Site'))}}</button>
                    </div>
                  </div>
                </li>
              </ul>
              <div *ngIf="siteList && siteList.length" class="simple-post-list pt-3">
                <obc-pagination [totalCount]="pagingInfo.totalCount" [pageSize]="pagingInfo.pageSize"
                  [pageNumber]="pagingInfo.pageNumber" (pageChanged)="onPageChange($event)"></obc-pagination>
              </div>
            </div>
            <div *ngIf="siteList && !siteList.length" class="alert alert-warning">
              No sites found!
            </div>
          </div>

          <div class="simple-post-list" *ngIf="!inProgress && (!siteList || !siteList.length)">
            Looks like you have no sites set up yet.
            <a style="text-decoration:underline !important;" class="text-info" href="https://support.1breadcrumb.com"
              target="support">
              <!-- Watch our academy to get a full tour first. -->
              Contact administrator to get help.
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<ng-template #locationVerify>
  <obc-site-location-verify [locationVerifyInput]="locationVerifyModel" (result)="closeLocationVerifyModal($event)">
  </obc-site-location-verify>
</ng-template>
<ng-template #cloneSite>
  <div class="card" *ngIf="iscloneSiteFromTemplateList || cloneSiteFormGroup?.controls?.site?.value">
    <div class="card-header">
      <span *ngIf="cloneSiteFormGroup.controls.site?.value?.name">
        Clone Site {{cloneSiteFormGroup.controls.site.value.name}}
      </span>
      <button type="button" class="close" (click)="bsModalHandle.hide(); cloneSiteFormGroup.reset();">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <form [formGroup]="cloneSiteFormGroup">
        <obc-loader [show]="inCloningProgress"></obc-loader>
        <div class="row" *ngIf="!iscloneSiteFromTemplateList">
          <div class="col-md-3 col-sm-12">
            <label>Clone As: </label>
          </div>
          <div class="col-md-9 col-sm-12">
            <label>
              <input type="radio" [value]="true" formControlName="cloneAsSite">
              Site
            </label>
            <label class="ml-3">
              <input type="radio" [value]="false" formControlName="cloneAsSite">
              Template
            </label>
          </div>
        </div>

        <div class="row mb-2" *ngIf="iscloneSiteFromTemplateList">
          <div class="col-md-3 col-sm-12">
            <label>Select template: </label>
          </div>
          <div class="col-md-9 col-sm-12">
            <select class="form-control" name="site" required formControlName="site">
              <option [ngValue]="null" selected disabled>
                Select a template site
              </option>
              <option *ngFor="let template of templateSiteListForClone" [ngValue]="template">
                {{template.name}}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-4 col-sm-12">
            <label>{{cloneAsSite?'Site':'Template'}} Name: </label>
            <input type="text" class="form-control" formControlName="name">
          </div>
          <div class="form-group col-md-8 col-sm-12" *ngIf="cloneAsSite">
            <label>Address: </label>
            <obc-address-form-control formControlName="address"></obc-address-form-control>
          </div>
        </div>

        <div class="row" *ngIf="cloneAsSite">
          <div class="form-group col-sm-12 col-md-6">
            <label>Latitude: </label>
            <input class="form-control" placeholder="Latitude" formControlName="latitude" />
          </div>
          <div class="form-group col-sm-12 col-md-6">
            <label>Longitude: </label>
            <input class="form-control" placeholder="Longitude" formControlName="longitude" />
          </div>
        </div>

        <div class="form-group" *ngIf="cloneAsSite">
          <label>Site Name: </label>
          <obc-map style="width: 100%;" [markerTitle]="cloneSiteFormGroup.controls.name.value"
            [siteRadius]="cloneSiteFormGroup?.controls?.site?.value?.siteRadius ? cloneSiteFormGroup.controls.site.value.siteRadius : 500"
            [location]="{ lat: cloneSiteFormGroup.controls.latitude.value, lng: cloneSiteFormGroup.controls.longitude.value }"
            (locationChange)="cloneSiteFormGroup.controls.latitude.setValue($event.lat);
                       cloneSiteFormGroup.controls.longitude.setValue($event.lng)">
          </obc-map>
        </div>
      </form>
      <div class="pt-1 text-right">
        <hr />
        <button class="btn btn-primary" [disabled]="!cloneSiteFormGroup.valid" (click)="clone()">Clone</button>
      </div>
    </div>
  </div>
</ng-template>
