import { Pipe, PipeTransform } from '@angular/core';
import { ExpiryUnit } from '../enums/expiry-mode.enum';

@Pipe({
  name: 'expiryUnit'
})
export class ExpiryUnitPipe implements PipeTransform {

  transform(value: any): string {
    if (value == ExpiryUnit.Hour) return "Hour(s)";
    if (value == ExpiryUnit.Day) return "Day(s)";
    if (value == ExpiryUnit.Week) return "Week(s)";
    if (value == ExpiryUnit.Month) return "Month(s)";
    if (value == ExpiryUnit.Year) return "Year(s)";
    return value?.toString() ?? "?";
  }

}
