import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Observable } from 'rxjs';
import { SiteQuestionsViewModel } from '../models/sitequestion-viewmodel';
import { catchError } from 'rxjs/operators';
import { SiteQuestionSearchModel } from '../models/site-questions-search-model';
import { extractParamsFromModel } from '../helpers/extract-params-from-model';
import { WidgetSummaryViewmodel } from '../models/widget-summary-viewmodel';
import {ServerResultData} from "../models/server-result-data";
import {SelfCheckOutViewModel} from "../models/site-check-in-out-data";

@Injectable({
  providedIn: 'root'
})
export class SiteQuestionService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  searchSiteQuestion = (model: SiteQuestionSearchModel): Observable<SiteQuestionsViewModel[]> =>
    this._http.get(`/api/sitequestion/search`, { params: extractParamsFromModel(model) })
      .pipe(catchError(this._errorService.handleError));


  getSiteQuestionDashboardInfo = (model: SiteQuestionSearchModel): Observable<WidgetSummaryViewmodel[]> =>
    this._http.get(`/api/sitequestion/dashboard`, { params: extractParamsFromModel(model) })
      .pipe(catchError(this._errorService.handleError));


  addQuestionToSite = (model: SiteQuestionsViewModel) =>
    this._http.post('/api/sitequestion', model)
      .pipe(catchError(this._errorService.handleError));


  removeQuestionFromSite = (siteQuestioId: number) =>
    this._http.delete(`/api/sitequestion/${siteQuestioId}`)
      .pipe(catchError(this._errorService.handleError));


  changeSiteQuestionOrder = (siteQuestionIds: number[]) =>
    this._http.post<SiteQuestionsViewModel>(`/api/sitequestion/change-order/`, siteQuestionIds)
      .pipe(catchError(this._errorService.handleError));

  //GetSiteQuestions(siteId: number, isCheckIn: boolean): Observable<SiteQuestionsViewModel[]> {
  //  return this._http.get<SiteQuestionsViewModel[]>(`/api/sitequestion/site-questions/${siteId}/${isCheckIn}`)
  //    .pipe(catchError(this._errorService.handleError));
  //}

  // Deprecated
  GetHostCheckOutInformation(mobile: string, siteId: number, visitorTypeId?: number): Observable<ServerResultData<SelfCheckOutViewModel>> {
    if (visitorTypeId == null)
      return this._http.get<ServerResultData<SelfCheckOutViewModel>>(`/api/SiteAttendance/manual-check-out-form/${mobile}/${siteId}`)
        .pipe(catchError(this._errorService.handleError));
    else
      return this._http.get<ServerResultData<SelfCheckOutViewModel>>(`/api/SiteAttendance/manual-check-out-form/${mobile}/${siteId}/${visitorTypeId}`)
        .pipe(catchError(this._errorService.handleError));
  }

  GetQuestionsToCheckOutForAnonymousUser(key: any): Observable<SelfCheckOutViewModel> {
    return this._http.get<SelfCheckOutViewModel>(`/api/sitequestion/check-out-site-questions/${key}`)
      .pipe(catchError(this._errorService.handleError));
  }
}
