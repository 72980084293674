<div>
  <ng-select style="padding-bottom: 0 !important;" class="supplier-selector" [typeaheadOptionsLimit]="10"
             [typeaheadMinLength]="0" [items]="$suppliers | async"
             [placeholder]="'Please Select Supplier'" bindLabel="name"
             [ngClass]="{'ng-valid' : !formControl.valid, 'valid' : formControl.valid}"
             bindValue="id" [typeahead]="term$" [loading]="loading" [clearable]="!_required" [formControl]="formControl"
             #selector>
    <ng-template ng-option-tmp let-item="item">
      {{item.name}}
    </ng-template>
  </ng-select>
</div>
