import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'obc-supplier-document-kind-uploder',
  templateUrl: './supplier-document-kind-uploder.component.html',
  styleUrls: ['./supplier-document-kind-uploder.component.scss']
})

export class SupplierDocumentKindUploderComponent implements OnInit {

  @Input() documentKindTitle: string = null;
  @Input() showAddButton: boolean;
  @Input() showRequestButton: boolean;

  @Output() filesDragedOrSelected = new EventEmitter();
  @Output() addDocumentClicked = new EventEmitter();
  @Output() requestDocumentClicked = new EventEmitter();

  uploadFileModalRef = null;
  _siteSupplierHashlink = null;

  constructor() { }

  ngOnInit(): void { }

  dropped(files: NgxFileDropEntry[]) {
    this.filesDragedOrSelected.emit(files);
  }

  addClicked() {
    this.addDocumentClicked.emit();
  }

  requestClicked() {
    this.requestDocumentClicked.emit();
  }
}
