import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CompanySettingService } from 'src/app/services/company-setting.service';
import { ModalService } from 'src/app/services/modal.service';
import { finalize } from 'rxjs/operators';
import { CompanySupplierService } from 'src/app/services/company-supplier.service';
import { CompanySupplierViewModel } from 'src/app/models/supplier-models';
import { HandsHQConfiguration, HandsHQConfigurationModel } from 'src/app/models/handsHQ/handshq-configuration';
import { HandsHQSupplierDocumentApprovalMode } from 'src/app/enums/handshq-supplier-document-approval-mode';
import { OptionalMandatoryState } from 'src/app/enums/site-document-state.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'obc-handshq-integration',
  templateUrl: './handshq-integration.component.html',
  styleUrls: ['./handshq-integration.component.scss']
})
export class HandshqIntegrationComponent implements OnInit {
  inProgress: boolean;
  errorMessage: string;
  config: HandsHQConfigurationModel = { isHandsHQEnabled: false, allConfigurations: [] };
  companySuppliers: CompanySupplierViewModel[] = [];

  HandsHQSupplierDocumentApprovalMode = HandsHQSupplierDocumentApprovalMode;
  OptionalMandatoryState = OptionalMandatoryState;
  enableEdit: boolean = false;
  currentStatus: boolean;

  formGroup = new FormGroup({
    handsHQApiToken: new FormControl(null, [Validators.required]),
    handsHQSupplierId: new FormControl(null, [Validators.required]),
    handsHQSupplierName: new FormControl(null, null),
    handsHQDocumentSignOffMode: new FormControl(null, [Validators.required]),
    handsHQSupplierDocumentApprovalMode: new FormControl(null, [Validators.required]),
  });

  bsModalHandle: any;

  constructor(private companySettingService: CompanySettingService,
    private modalService: ModalService,
    private ref: ChangeDetectorRef,
    private companySupplierService: CompanySupplierService,
    private bsModalService: BsModalService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getConfig();
  }

  onCompanySupplierChange($event) {
    let text = $event.target.options[$event.target.options.selectedIndex].text;
    this.formGroup.controls.handsHQSupplierName.setValue(text);
  }

  openCompanyConfigurationModal(template) {
    this.bsModalHandle = this.bsModalService.show(template);
  }

  onAddCompanyApiToken(template: any) {
    this.formGroup.reset();
    this.openCompanyConfigurationModal(template);
  }

  // onEditCompanyApiToken(template: any, configuration: HandsHQConfiguration) {
  //   this.formGroup.controls.handsHQApiToken.setValue(configuration.handsHQApiToken);
  //   this.formGroup.controls.handsHQSupplierId.setValue(configuration.handsHQSupplierId);
  //   this.formGroup.controls.handsHQDocumentSignOffMode.setValue(configuration.handsHQDocumentSignOffMode);
  //   this.formGroup.controls.handsHQSupplierDocumentApprovalMode.setValue(configuration.handsHQSupplierDocumentApprovalMode);
  //   this.inEdit=true;
  //   this.openCompanyConfigurationModal(template);
  // }

  onRemoveCompanyApiToken(index: number) {
    this.modalService.confirm("Do You Want to Delete Api Token?", "Delete").subscribe(confirm => {
      if (confirm) {
        this.inProgress = true;
        this.config.allConfigurations.splice(index, 1);
        this.save(this.config);
      }
    })
  }

  getConfig() {
    this.inProgress = true;
    this.companySettingService.getHandsHQConfiguration()
      .pipe(finalize(() => this.inProgress = false))
      .subscribe((res) => {
        if (res.isValid === false) {
          this.errorMessage = "Token is invalid!";
        }
        this.config = res.configuration;
        this.currentStatus = this.config.isHandsHQEnabled;
        this.getCompanySuppliers();

      });
  }

  getCompanySuppliers() {
    this.inProgress = true;
    this.companySupplierService.getSuppliers(null, null)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe((res) => {
        this.companySuppliers = res.data;

        this.ref.detectChanges();
      }, (error) => {
        this.errorMessage = error;
      });
  }

  private getConfigurationModel(): HandsHQConfiguration {
    return {
      handsHQApiToken: this.formGroup.controls.handsHQApiToken.value,
      handsHQSupplierId: this.formGroup.controls.handsHQSupplierId.value,
      supplierName: this.formGroup.controls.handsHQSupplierName.value,
      handsHQDocumentSignOffMode: this.formGroup.controls.handsHQDocumentSignOffMode.value,
      handsHQSupplierDocumentApprovalMode: this.formGroup.controls.handsHQSupplierDocumentApprovalMode.value,
    } as HandsHQConfiguration;
  }

  validateAndSaveHandsHQConfig() {
    let config = JSON.parse(JSON.stringify(this.config));
    if (!this.config.allConfigurations)
      this.config.allConfigurations = [];
    let data = this.getConfigurationModel();

    if (config.allConfigurations.find(p => p.handsHQApiToken == data.handsHQApiToken)) {
      this.modalService.error(`HandsHQ Api token is already inserted`);
      return;
    }

    config.allConfigurations.push(data);
    this.save(config);

  }

  private save(config) {
    this.inProgress = true;
    this.companySettingService
      .validateAndSaveHandsHQConfiguration(config)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe((res) => {
        if (res != null) {
          this.errorMessage = "";

          this.config = res;
          this.toastr.success('HandsHQ configuration saved!');
          this.formGroup.reset();
          this.bsModalHandle.hide();
        }
      }, (error) => {
        this.errorMessage = error;
        this.modalService.error(`Token is invalid`);
      });
  }

  inactiveHandsHQ() {
    setTimeout(() => {
      if (this.config && this.config.isHandsHQEnabled != this.currentStatus) {
        if (this.currentStatus == true) {
          this.companySettingService
            .inactiveHandsHQIntegration()
            .pipe(finalize(() => this.inProgress = false))
            .subscribe((res) => {
              this.currentStatus = false;
              // if (res)
              //   this.modalService.success('HandsHQ The integration was deactivated!');
            }, (error) => {
              this.errorMessage = error;
            });
        }
        else {
          this.config.isHandsHQEnabled = true;
          this.currentStatus = true;
          if (this.config.allConfigurations?.length)
            this.save(this.config);
        }
      }
    }, 500);
  }

}
