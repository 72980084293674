import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { CompanySiteContactInformationModel } from 'src/app/models/company-site-contact-information.view.model';
import { e168ValidatorNullableCompanyPhoneNumber } from "../../../helpers/mobile-format-validator";
import { pairwise, startWith } from "rxjs/operators";

@Component({
  selector: 'obc-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss']
})
export class ContactInformationComponent implements OnInit {

  fg: FormGroup = new FormGroup({
    contactTitle: new FormControl(null),
    contactFullName: new FormControl(null),
    contactNumber: new FormControl(null, [e168ValidatorNullableCompanyPhoneNumber]),
  })

  @Output() result: EventEmitter<boolean> = new EventEmitter<boolean>()
  _contactInformationAsJson: FormControl;
  @Input() set contactInformationAsJson(contactInformationAsJson: FormControl) {
    this._contactInformationAsJson = contactInformationAsJson;
  }

  constructor() {
  }

  ngOnInit(): void {

    this._contactInformationAsJson.valueChanges?.pipe(startWith(undefined), pairwise()
    )?.subscribe(([oldValue, newValue]) => {
      if(oldValue != newValue) {
        try {
          let parsedContactInformation = JSON.parse(newValue) as CompanySiteContactInformationModel;
          if(parsedContactInformation) {
            this.fg.controls['contactTitle'].setValue(parsedContactInformation.contactTitle);
            this.fg.controls['contactFullName'].setValue(parsedContactInformation.contactFullName);
            this.fg.controls['contactNumber'].setValue(parsedContactInformation.contactNumber);
          }
        } catch(_) {}
      }
    });

    this.fg.valueChanges?.subscribe((r) => {
      this._contactInformationAsJson.setValue(
        JSON.stringify(
          {
            contactNumber: this.fg.controls['contactNumber'].value,
            contactFullName: this.fg.controls['contactFullName'].value,
            contactTitle: this.fg.controls['contactTitle'].value,
          } as CompanySiteContactInformationModel)
      );
      if(!this.fg.controls['contactNumber']?.valid) {
        this.result.emit(true);
      } else {
        this.result.emit(false);
      }
    })

  }

}
