import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByCondition'
})
export class FilterByConditionPipe implements PipeTransform {

  transform(items: any[], condition?: any, parameter1?: any, parameter2?: any, parameter3?: any): any[] {
    return items?.filter(v => condition(v, parameter1, parameter2, parameter3) == true);
  }

}
