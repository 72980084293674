import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { catchError } from 'rxjs/operators';
import { UserDocumentType } from '../models/user-document-type';
import { OrderedEntity } from '../models/ordered-entity';
import { DocumentTypeChildrenQueryModel } from "../models/document-type-children.query.model";

@Injectable({
  providedIn: 'root'
})
export class UserDocumentTypeService {
  apiUrl: string = "/api/UserDocumentType";
  constructor(private http: HttpClient, private _errorService: ErrorService) { }
  getAll(sessionId = ''): Observable<UserDocumentType[]> {
    let key = (sessionId == null || sessionId == 'null') ? '' : sessionId;
    return this.http.get<any>(`${this.apiUrl}/get-all/${key}`)
      .pipe(catchError(this._errorService.handleError));
  }
  companyGetAll(companyId: number): Observable<UserDocumentType[]> {
    return this.http.get<any>(`${this.apiUrl}/company-get-all/${companyId}`)
        .pipe(catchError(this._errorService.handleError));
  }
  getSelectable(sessionId = ''): Observable<UserDocumentType[]> {
    let key = (sessionId == null || sessionId == 'null') ? '' : sessionId;
    return this.http.get<any>(`${this.apiUrl}/get-selectable/${key}`)
      .pipe(catchError(this._errorService.handleError));
  }
  GetAllCompaniesUserDocumentTypes(): Observable<UserDocumentType[]> {
    return this.http.get<UserDocumentType[]>(`${this.apiUrl}/get-all-companies`)
      .pipe(catchError(this._errorService.handleError));
  }
  getUserDocumentTypeChildren(model: DocumentTypeChildrenQueryModel): Observable<UserDocumentType[]> {
    return this.http.post<any>(`${this.apiUrl}/get-document-type-children`, model)
      .pipe(catchError(this._errorService.handleError));
  }
  edit(model: UserDocumentType): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/edit`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  add(model: UserDocumentType): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/add`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  delete(documentTypeId: number): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/${documentTypeId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  reorder(orderedList: OrderedEntity[]): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/reorder`, orderedList)
      .pipe(catchError(this._errorService.handleError));
  }

  getDocumentTypeChildren(documentType: UserDocumentType, allUserDocuments: UserDocumentType[]) {
    if(!documentType) return [];
    let children: UserDocumentType[] = [];
    allUserDocuments?.filter((item) => item.parentId == documentType.id)?.map((item) => {
      children.push(item);
      children = [
        ...children,
        ...this.getDocumentTypeChildren(item, allUserDocuments),
      ];
    })
    return children;
  }


  public addDocumentTypeReplay: ReplaySubject<UserDocumentType|null> = new ReplaySubject<UserDocumentType|null>();
  public updateDocumentTypeReplay: ReplaySubject<UserDocumentType|null> = new ReplaySubject<UserDocumentType|null>();
  public deleteDocumentTypeReplay: ReplaySubject<UserDocumentType|null> = new ReplaySubject<UserDocumentType|null>();
}
