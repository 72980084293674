import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ScannableGroupModel } from '../models/scannable-group-model';
import { Observable } from 'rxjs';
import { ErrorService } from './error.service';


@Injectable({
  providedIn: 'root'
})
export class ScannableService {

  constructor(private http: HttpClient, private _errorService: ErrorService) { }

  public addGroup(model: ScannableGroupModel) {
    return this.http.post<any>(`/api/scannable/add-group`, model)
      .pipe(catchError((error) => throwError(error)))
  }

  public removeGroup(id: number) {
    return this.http.get<any>(`/api/scannable/remove-group/${id}`)
      .pipe(catchError((error) => throwError(error)))
  }

  public getGroups(): Observable<ScannableGroupModel[]> {
    return this.http.get<ScannableGroupModel[]>(`/api/scannable/get-groups`)
      .pipe(catchError(this._errorService.handleError));
  }

  public getItems(id: number) {
    return this.http.get<any>(`/api/scannable/get-items/${id}`)
      .pipe(catchError((error) => throwError(error)))
  }

  public addItem(model: any) {
    return this.http.post<any>(`/api/scannable/add-item`, model)
      .pipe(catchError((error) => throwError(error)))
  }

  public updateItem(id: number, model: any) {
    return this.http.post<any>(`/api/scannable/update-item/${id}`, model)
      .pipe(catchError((error) => throwError(error)))
  }

  public removeItem(id: number) {
    return this.http.get<any>(`/api/scannable/remove-item/${id}`)
      .pipe(catchError((error) => throwError(error)))
  }

  public updateGroup(id: number, model: any) {
    return this.http.post<any>(`/api/scannable/update-group/${id}`, model)
      .pipe(catchError((error) => throwError(error)))
  }
}
