<div class="row">
    <div class="col-md-12">
        <div class="card">
            <header class="card-header">Supplier Document SDS</header>
            <div class="card-body">
                <app-base-grid *ngIf="gridConfig?.apiUrl" [columns]="gridColumns" [configs]="gridConfig" #grid>
                    <ng-template obcGridTemplateName="viewDetails" let-settings="settings" let-item="data">                        
                        <obc-site-supplier-document-version-comments
                            *ngIf="item?.hasPermissionToAddSupplierDocumentComment || item?.supplierDocumentVersionComments?.length > 0"
                            [siteSupplierDocumentVersionId]="item?.siteSupplierDocumentVersionId"
                            [supplierDocCommentAcknowledgementText]="item?.supplierDocCommentAcknowledgementText"
                            [comments]="item?.supplierDocumentVersionComments" [classes]="'ml-2 btn-sm pull-right '"
                            [noIcon]="true">
                        </obc-site-supplier-document-version-comments>
                        <obc-file-viewer *ngIf="item?.supplierDocumentFiles?.length"
                            [prefix]="item?.title+(item?.description ? '-'+item?.description : '')"
                            [files]="item?.supplierDocumentFiles || []" [btnTitle]="'View Files'"
                            [btnClass]="'btn-sm btn-primary ml-2 pull-right'"></obc-file-viewer>
                        <!-- <button *ngIf="checkGridSettingsForHistoryButton(settings)" type="button"
                            class="btn-sm btn-success ml-2 pull-right"
                            (click)="onViewVersions(viewVersionsTemplate, data?.supplierDocumentTypeId)">
                            <i class="fas fa-history"></i>
                            Document History
                        </button> -->
                    </ng-template>

                    <ng-template #viewVersionsTemplate>
                        <obc-view-site-supplier-document-version-history
                            [siteSupplierDocumentTypeId]="siteSupplierDocumentTypeId">
                        </obc-view-site-supplier-document-version-history>
                    </ng-template>

                </app-base-grid>
            </div>
        </div>
    </div>
</div>