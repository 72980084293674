import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enabledDisabledSwitch'
})
export class EnabledDisabledSwitchPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value) return 'Enabled';
    if (!value) return 'Disabled';
    return value?.toString() ?? '?';
  }

}
