import { Pipe, PipeTransform } from '@angular/core';
import { PendingToApproveStatus } from "../enums/approve-status";

@Pipe({
  name: 'approveStatus'
})
export class ApproveStatusPipe implements PipeTransform {

  transform(value: any): string {
    if (value == PendingToApproveStatus.Pending || value == 0 || value == "Pending") return "Pending";
    if (value == PendingToApproveStatus.Approved || value == 1 || value == "Approved") return "Approved";
    if (value == PendingToApproveStatus.Rejected || value == 1 || value == "Rejected") return "Rejected";
    return value?.toString() ?? "";
  }

}
