import { FilterType } from "src/app/enums/filter-type.enum";
import { Table } from "src/app/enums/table.enum";
import { ScheduleReportMode } from "../../enums/schedule-report-mode";
import { ScheduleReportType } from "../../enums/schedule-report-type";

export class GridConfig {

  // the api url of the grid system
  apiUrl: string;

  // Report Type, this property is used as the key name to save grid-settings inside local storage.
  tableType: Table;

  // To display general search input
  displayGeneralSearch?: boolean;

  // The text of the placeholder of the general search input
  generalSearchPlaceholder?: string;

  // The key-name of the general search in `Payload` of the api request
  generalSearchApiKeyName?: string;

  // The minimum character count which the user should type to send request to server
  generalSearchMinimumCharsToSearch?: number;

  // display control buttons of the grid
  displayQuestionsFilter?: boolean;
  displayAnnouncementsFilter?: boolean;
  displayDynamicFieldsFilter?: boolean;
  displayDynamicAssignFieldsFilter?: boolean;
  displayEnableMagicLink?: boolean;
  displaySettingsButton?: boolean;
  displayExportsButton?: boolean;
  forceHideAllControlButtons?: boolean;

  scheduleReport?: ScheduleReport;

  // The url of the CSV Report request. if this property is set to empty,
  // then the csvReport button will not be displayed
  csvReportUrl?: string;

  // the title of CSV Report. if this property is set to null,
  // then the type of the Table.toString() will be used
  csvExportSubject?: string;

  // To append extra filters to the final `Payload` to send via api request to server
  initialFilters?: InitialFilter[];

  loadDataOnInitialFilterChange?: boolean;

  // To control the `refresh` button to be disabled or enabled from outside.
  refreshButtonValidity?: boolean;

  displayRefreshButton?: boolean;

  // By default, the grid system reads data from `data` property of the response
  // this callback method is used when the response data is inside another property-name or nested property
  // example:
  //   apiResultCallback: (res) => {
  //     return {
  //       data: res?.serverResponse?.data,
  //       totalCount: res?.serverResponse?.totalCount,
  //     }
  //   }
  apiResultCallback?: <T>(data) => { totalCount: number, data: T[] };

  // append headers to the grid api request
  headers?: { [key: string]: any };

  // request object mode
  requestPayloadMode?: RequestPayloadModeEnum;
  includeExternalCompanySites: boolean = false;

  cacheKeyPrefix?: string;
  hideEmptyColumns?: boolean;

  constructor(config: GridConfigModel) {
    this.apiUrl = config.apiUrl;
    this.tableType = config.tableType;
    this.displayGeneralSearch = config.displayGeneralSearch;
    this.generalSearchPlaceholder = config.generalSearchPlaceholder;
    this.generalSearchApiKeyName = config.generalSearchApiKeyName;
    this.generalSearchMinimumCharsToSearch = config.generalSearchMinimumCharsToSearch;
    this.displayQuestionsFilter = config.displayQuestionsFilter;
    this.displayAnnouncementsFilter = config.displayAnnouncementsFilter;
    this.displayDynamicFieldsFilter = config.displayDynamicFieldsFilter;
    this.displayDynamicAssignFieldsFilter = config.displayDynamicAssignFieldsFilter;
    this.displaySettingsButton = config.displaySettingsButton;
    this.displayExportsButton = config.displayExportsButton;
    this.forceHideAllControlButtons = config.forceHideAllControlButtons;
    this.displayEnableMagicLink = config.displayEnableMagicLink;
    this.scheduleReport = config.scheduleReport;
    this.csvReportUrl = config.csvReportUrl;
    this.initialFilters = config.initialFilters;
    this.loadDataOnInitialFilterChange = config.loadDataOnInitialFilterChange;
    this.refreshButtonValidity = config.refreshButtonValidity;
    this.displayRefreshButton = config.displayRefreshButton;
    this.apiResultCallback = config.apiResultCallback;
    this.headers = config.headers;
    this.requestPayloadMode = config.requestPayloadMode;
    this.csvExportSubject = config.csvExportSubject;
    this.includeExternalCompanySites = config.includeExternalCompanySites;
    this.cacheKeyPrefix = config.cacheKeyPrefix;
    this.hideEmptyColumns = config.hideEmptyColumns;
  }

  get exportAsJob() {
    switch (this.tableType) {
      case Table.ActivityReport:
      case Table.ContactTracingReport:
      case Table.Approval:
      case Table.AssetReport:
      case Table.CardReport:
      case Table.FormReport:
      case Table.InductionReport:
      case Table.InventoryReport:
      case Table.PermitReport:
      case Table.SiteAsset:
      case Table.SWMSReport:
      case Table.LiveAttendance:
      case Table.SupplierAsset:
      case Table.SiteBriefingReport:
        return true;
    }
    return false;
  }
}

export class GridConfigModel {
  apiUrl: string;
  tableType: Table;
  displayGeneralSearch?: boolean;
  generalSearchPlaceholder?: string;
  generalSearchApiKeyName?: string;
  generalSearchMinimumCharsToSearch?: number;
  displayQuestionsFilter?: boolean;
  displayAnnouncementsFilter?: boolean;
  displayDynamicFieldsFilter?: boolean;
  displayDynamicAssignFieldsFilter?: boolean;
  displayEnableMagicLink?: boolean;
  displaySettingsButton?: boolean;
  displayExportsButton?: boolean;
  displayRefreshButton?: boolean;
  forceHideAllControlButtons?: boolean;
  scheduleReport?: ScheduleReport;
  csvReportUrl?: string;
  initialFilters?: InitialFilter[];
  loadDataOnInitialFilterChange?: boolean;
  refreshButtonValidity?: boolean;
  apiResultCallback?: <T>(data) => { totalCount: number; data: T[] };
  headers?: { [key: string]: any };
  requestPayloadMode?: RequestPayloadModeEnum;
  csvExportSubject?: string;
  companyId?: number;
  includeExternalCompanySites?: boolean = false;
  cacheKeyPrefix?: string = '';
  hideEmptyColumns?: boolean = true;
}


export class GridColumn<T> {
  // Filter Type
  type?: FilterType;
  order?: number;
  name: string;

  // The data key-name of the column in server response. Also a unique identifier to make things work!
  key: string;

  // The key-name of sort by in the api request of the grid
  sortBy?: string;

  // Whether this column is able to sort the data or not
  enableSort?: boolean;

  // Whether this column is able to get filtered or not
  enableFilter?: boolean;

  // The key-name of this column in the `Payload` of the request
  propertyNameInQuery?: string;

  // To display customized data inside the cell of the column
  displayFunction?: (el: T) => any;

  // do some data modification
  populateData?: (el: T) => any;

  // if this property is set, then a flag with the key-name of the column `key` property, will append
  // to the `Payload` and will be sent to server.
  // this type of properties has extra data from server if they set to true
  hasIncludeFilter?: boolean;

  // if set to true, the column will be displayed as a column, otherwise no column will be displayed
  showAsColumn?: boolean;

  // Display or hide the column of this item in the grid table
  visible?: boolean;

  // The filter request data.
  // if the data of the filters related to the column is a `list` then this property should be true
  // example:
  //
  //   siteIds filter data to send to server
  //   siteIds: [ 1, 2, 3 ] => isArray true
  //   siteIds: 1 => isArray false
  isArray?: boolean;

  // to display the checkbox related to this column inside settings of the grid
  displayInCheckBoxSettings?: boolean;

  // if it is needed to send a specific part of the value of this column to the server
  payloadCustomKeyValue?: (el) => any;

  // The result of this callback is used to check if the data of the column is empty or not
  // This is mostly used for template columns which involves more than 1 field to display the data
  hideEmptyColumnsEmptinessConditionCallback?: (res) => boolean;

  // To display customized data as an ng-template inside the cell of the column or a built-in template in the grid component
  gridTemplate?: GridTemplate;
  filterSettings?: FilterSettings;
}

export class ScheduleReport {
  // api: string;
  enabled: boolean;
  mode: ScheduleReportMode;
  type: ScheduleReportType;
  reportColumns?: (string | ScheduleReportColumn)[]
}

export class ScheduleReportColumn {
  labelCaption: string;
  key: string;
}

export class InitialFilter {
  key: string;
  value: any;

  // displays the initial filter beside other generic filters
  displayInFilterRenderer?: boolean;
  usage?: InitialFilterUsage = InitialFilterUsage.Always;
}

export enum InitialFilterUsage {
  Always,
  Report,
  CSV,
}

export enum RequestPayloadModeEnum {
  Normal,
  SeparateQueryKey,
}

/*Grid Template Data Injector*/
export class GridTemplate {
  private _isComponentTemplateName: boolean;
  get isComponentTemplateName() {
    return this._isComponentTemplateName;
  }

  private _templateName: string;
  get templateName() {
    return this._templateName;
  }

  private _data: {};
  get data() {
    return this._data;
  }

  private _csvFields: string[];
  get csvFields() {
    return this._csvFields;
  }


  ComponentTemplateName(name: string) {
    this._templateName = name;
    this._isComponentTemplateName = true;
    return this;
  }

  FullNameMobile(keys: {
    mobile?: string,
    fullName?: string,
    firstName?: string,
    lastName?: string,
    userId?: string
  }) {
    this._initializeGridScopeTemplate('FullNameMobile', keys)
    return this;
  }

  SiteNameReference(keys: {
    siteName: string,
    siteReference?: string,
  }) {
    this._initializeGridScopeTemplate('SiteNameReference', keys)
    return this
  }

  FullNameAccompanierCount(keys: {
    accompaniersText: string,
    firstName: string,
    lastName: string,
    accompanierCount: string,
    userId?: string
  }) {
    this._initializeGridScopeTemplate('FullNameAccompanierCount', keys)
    return this;
  }

  Inductions(keys: {
    inductions: string,
  }) {
    this._initializeGridScopeTemplate('Inductions', keys)
    return this;
  }

  SupplierDocuments(keys: {
    supplierDocuments: string,
  }) {
    this._initializeGridScopeTemplate('SupplierDocuments', keys)
    return this;
  }


  CsvFields(keys?: string[]) {
    this._csvFields = keys ?? [];
    return this;
  }

  private _initializeGridScopeTemplate(templateName: string, keys: { [key: string]: any }) {
    this._templateName = templateName?.toString();
    this._data = keys;
    this._isComponentTemplateName = false;
  }
}

export class FilterSettings {
  includeInactiveItems: boolean
}

/*
export enum GridTemplateNames {
  FullNameMobile = 'FullNameMobile',
  SiteNameReference = 'SiteNameReference',
  FullNameAccompanierCount = 'FullNameAccompanierCount',
  SiteInductions = 'SiteInductions',
}
*/
