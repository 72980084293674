<section class="card" *ngIf="multipleTabsAvailable">
    <header class="card-header">
        <h5>{{ formDataDetails?.filledFormInfo?.formName }}</h5>
        <div class="card-actions">
            <!-- <a (click)="result.emit({isFinished: true,isSucceeded: false,needParentRefresh: needParentRefresh})"
                class="card-action card-action-dismiss" data-card-dismiss=""></a> -->
        </div>
    </header>
    <div class="card-body">
        <obc-loader [show]="inProgress"></obc-loader>
        <tabset *ngIf="formDataDetails">
            <tab heading="Form">
                <ng-container *ngTemplateOutlet="multipleTabsTemplate">Form</ng-container>
            </tab>
            <tab heading="Check In" *ngIf="this.formDataDetails.formDataSiteAttendanceDetail">
                <ng-template [ngTemplateOutlet]="attendanceTemplate"></ng-template>
            </tab>
            <tab heading="Supplier Document Review" *ngIf="this.formDataDetails.formDataSupplierReviewDetail">
                <ng-template [ngTemplateOutlet]="supplierReviewTemplate"></ng-template>
            </tab>
        </tabset>
    </div>
</section>
<ng-container *ngIf="!multipleTabsAvailable && formDataDetails">
    <ng-container *ngTemplateOutlet="formTemplate">Form</ng-container>
</ng-container>

<ng-template #multipleTabsTemplate>
    <div>
        <ul class="simple-post-list">
            <obc-form-data-details [noCard]="true" [entityId]="entityId" [formDetail]="formDataDetails.filledFormInfo"
                (onRefresh)="onRefesh.emit()"></obc-form-data-details>
        </ul>
    </div>
</ng-template>
<ng-template #formTemplate>
    <obc-form-data-details class="card" [entityId]="entityId"
                           [siteSupplierHashlink]="hashLink"
                           [formDetail]="formDataDetails.filledFormInfo"
        (onRefresh)="onRefesh.emit()"></obc-form-data-details>
</ng-template>


<ng-template #attendanceTemplate>
    <div>
        <ul class="simple-post-list">
            <li *ngFor="let fld of siteAttendanceInfoFields" class="d-flex flex-row justify-content-around">
                <div class="flex-fill">
                    <i *ngIf="fld.icon" class="fas fa-{{ fld.icon }}"></i> {{ fld.title }}:
                    <span class="text-primary" [ngSwitch]="fld.type">
                        <span *ngSwitchCase="'datetime'">
                            {{formDataDetails.formDataSiteAttendanceDetail[fld.key] | datetime }}
                        </span>
                        <span *ngSwitchDefault>{{formDataDetails.formDataSiteAttendanceDetail[fld.key] }}</span>
                        <span *ngSwitchCase="'mobile'">{{ formDataDetails.formDataSiteAttendanceDetail[fld.key] | mobile
                            }}</span>
                    </span>
                </div>
            </li>
        </ul>
    </div>
</ng-template>

<ng-template #supplierReviewTemplate>
    <div>
        <div style="position: relative">
            <obc-file-viewer style="position: absolute; right: 9px; top: 9px"
                *ngIf="formDataDetails.formDataSupplierReviewDetail.files?.length"
                [prefix]="formDataDetails.formDataSupplierReviewDetail.supplierDocumentTypeName+'-'+formDataDetails.formDataSupplierReviewDetail.displayVersion"
                [files]="formDataDetails.formDataSupplierReviewDetail.files" [btnTitle]="'View Files'"
                [btnClass]="'btn btn-primary float-right'">
            </obc-file-viewer>
            <ul class="simple-post-list">
                <li *ngFor="let fld of supplierReviewInfoFields" class="d-flex flex-row justify-content-around">
                    <div class="flex-fill">

                        <i *ngIf="fld.icon && fld.type != 'siteSupplierDocumentStatus'"
                            class="fas fa-{{ fld.icon }}"></i>

                        {{ fld.title }}:

                        <span class="text-primary" [ngSwitch]="fld.type">
                            <span *ngSwitchCase="'datetime'">
                                {{formDataDetails.formDataSupplierReviewDetail[fld.key] | datetime }}
                            </span>
                            <span *ngSwitchDefault>{{formDataDetails.formDataSupplierReviewDetail[fld.key] }}</span>
                            <span *ngSwitchCase="'mobile'">{{ formDataDetails.formDataSupplierReviewDetail[fld.key] |
                                mobile}}
                            </span>
                            <span *ngSwitchCase="'siteSupplierDocumentStatus'">
                                <i *ngIf="fld.type == 'siteSupplierDocumentStatus'"
                                    class="{{formDataDetails.formDataSupplierReviewDetail[fld.key] | supplierDocumentTypeStatusIcon}}"></i>
                                {{formDataDetails.formDataSupplierReviewDetail[fld.key] |
                                supplierDocumentTypeStatusTooltip }}
                            </span>
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</ng-template>
