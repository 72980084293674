import { Pipe, PipeTransform } from '@angular/core';
import { TwoFactorVerificationMode } from '../enums/two-factor-verification-mode';

@Pipe({
  name: 'twoFactorVerificationModePipe'
})
export class TwoFactorVerificationModePipe implements PipeTransform {

  transform(value: any): unknown {
    if (value == TwoFactorVerificationMode.SMS || value == "SMS") return "SMS";
    if (value == TwoFactorVerificationMode.Email || value == "Email") return "Email";
    if (value == TwoFactorVerificationMode.Totp || value == "Totp") return "Time-based One-time Password";
  }
}
