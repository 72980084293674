import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { AcademyService } from 'src/app/services/academy.service';
import { VideoViewModel } from 'src/app/models/video-viewmodel';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UserSystem } from 'src/app/enums/user-system.enum';
import { EnumEntry, EnumToArray } from 'src/app/helpers/enum-to-array';
import { LayoutService } from 'src/app/services/layout.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { urlValidator } from 'src/app/helpers/url-validator';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-manage-videos',
  templateUrl: './manage-videos.component.html',
  styleUrls: ['./manage-videos.component.css']
})
export class ManageVideosComponent implements OnInit {

  _videos: VideoViewModel[];
  get videos(): VideoViewModel[] {
    if (this._videos == null) return null;
    var ret = this._videos;
    if (this.query != null && this.query.length > 0) {
      var q = this.query.toLowerCase();
      ret = ret.filter((v) => {
        var f1 = v.title == null ? true : v.title.toLowerCase().indexOf(q) >= 0;
        var f2 = v.category == null ? true : v.category.toLowerCase().indexOf(q) >= 0;
        var f3 = v.pageURL == null ? true : v.pageURL.toLowerCase().indexOf(q) >= 0;
        return f1 || f2 || f3;
      })
    }
    return ret.sort((a, b) => {
      if (a[this.orderByField] < b[this.orderByField]) {
        return -1 * this.orderDesc;
      }
      if (a[this.orderByField] > b[this.orderByField]) {
        return 1 * this.orderDesc;
      }
      return 0 * this.orderDesc;
    })
  }
  selectedVideo: VideoViewModel;
  formGroup: FormGroup;
  inAddEditProgress: boolean = false;
  inProgress: boolean = false;
  bsModalRef: BsModalRef;

  searchFormGroup: FormGroup;
  orderByField = "order";
  orderDesc = 1;
  query = null;

  constructor(private academyService: AcademyService,
    private layoutService: LayoutService,
    private bsModalService: BsModalService,
    private modalService : ModalService,
    public fb: FormBuilder
  ) { }

  ngOnInit() {
    this.layoutService.header = "Manage Videos";
    this.inProgress = true;
    this.searchFormGroup = this.fb.group({
      orderByField: new FormControl("order"),
      orderDesc: new FormControl(1),
      query: new FormControl(null),
    });
    this.searchFormGroup.statusChanges
      .subscribe(res => {
        this.orderByField = this.searchFormGroup.get('orderByField').value;
        this.orderDesc = this.searchFormGroup.get('orderDesc').value;
        this.query = this.searchFormGroup.get('query').value;
      })
    this.academyService.getAll(null).subscribe(res => {
      this._videos = res;
      this.inProgress = false;
    });
  }

  onEdit(template, model: VideoViewModel) {
    this.formGroup = new FormGroup({
      id: new FormControl(model.id),
      title: new FormControl(model.title, [Validators.required]),
      category: new FormControl(model.category),
      description: new FormControl(model.description),
      videoURL: new FormControl(model.videoURL, [Validators.required, urlValidator]),
      imageURL: new FormControl(model.imageURL, [Validators.required, urlValidator]),
      order: new FormControl(model.order),
      pageURL: new FormControl(model.pageURL, [Validators.required]),
      system: new FormControl(model.system, [Validators.required])
    });
    this.selectedVideo = model;
    this.bsModalRef = this.bsModalService.show(template, Object.assign({}, { class: 'gray modal-lg' }));
  }

  onAdd(template) {
    this.formGroup = new FormGroup({
      id: new FormControl(0),
      title: new FormControl('', [Validators.required]),
      category: new FormControl(''),
      description: new FormControl(''),
      videoURL: new FormControl('', [Validators.required, urlValidator]),
      imageURL: new FormControl('', [Validators.required, urlValidator]),
      order: new FormControl(0),
      pageURL: new FormControl('', [Validators.required, Validators.maxLength(500)]),
      system: new FormControl(null, [Validators.required])
    });
    this.bsModalRef = this.bsModalService.show(template, Object.assign({}, { class: 'gray modal-lg' }));
  }

  onRemove(model: VideoViewModel) {
    this.academyService.removeVideo(model.id).subscribe(res => {
      var index = this._videos.indexOf(model);
      this._videos.splice(index, 1);
    });
  }

  onSubmit() {
    if (!this.formGroup.valid) {
      // close modal
      this.bsModalRef.hide();
      return;
    }
    this.inAddEditProgress = true;
    var model = {
      id: this.formGroup.get("id").value,
      title: this.formGroup.get("title").value,
      category: this.formGroup.get("category").value,
      description: this.formGroup.get("description").value,
      videoURL: this.formGroup.get("videoURL").value,
      imageURL: this.formGroup.get("imageURL").value,
      order: this.formGroup.get("order").value as number,
      pageURL: this.formGroup.get("pageURL").value,
      system: parseInt(this.formGroup.get("system").value),
    } as VideoViewModel;
    if (this.formGroup.get("id").value == 0) { // add
      this.academyService.addVideo(model).subscribe(res => {
        model.id = res;
        this._videos.push(model);
        // close modal
        this.bsModalRef.hide();
        this.inAddEditProgress = false;
      })
    } else { // edit
      this.academyService.editVideo(model).subscribe(res => {
        if (res) {
          var index = this._videos.indexOf(this.selectedVideo);
          this._videos.splice(index, 1, model);
          // close modal
          this.bsModalRef.hide();
        }
        else {
          this.modalService.error("unable to edit this video", "manage video").subscribe(res=> {
            //alert("unable to edit this video");
          });
        }
        this.inAddEditProgress = false;
      })
    }
  }

  getSystemName(id: number) {
    return this.userSystems.filter(i => i.id == id)[0].name;
  }

  get userSystems(): EnumEntry[] {
    return EnumToArray(UserSystem);
  }
}
