import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber, PhoneNumber } from 'awesome-phonenumber';
import { UserService } from 'src/app/services/user.service';

@Pipe({
  name: 'mobile'
})
export class MobilePipe implements PipeTransform {
  currentRegion: string;

  constructor(userService: UserService) {
    this.currentRegion = 'AU';
    userService.info.subscribe(info => {
      try {
        const prefix = info.mobile !== null && info.mobile.startsWith('+') ? '' : '+';
        const number = parsePhoneNumber(prefix + info.mobile);
        this.currentRegion = number.isValid() ? number.getRegionCode() : 'AU';
      } catch (e) {
        this.currentRegion = 'AU';
      }
    });
  }

  transform(value: string): string {
    try {
      const prefix = value !== null && value.startsWith('+') ? '' : '+';
      const number = parsePhoneNumber(prefix + value, this.currentRegion);
      return number.isValid()
        ? number.getRegionCode() == this.currentRegion ? number.getNumber('national') : number.getNumber('international')
        : value;
    }
    catch (e) {
      return value;
    }
  }
}
