import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { ScannableService } from 'src/app/services/scannable.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { FileCategory } from 'src/app/enums/file-category.enum';
import { FileStorageService } from 'src/app/services/file-storage.service';
import { HttpEventType } from '@angular/common/http';
import { urlValidator } from 'src/app/helpers/url-validator';
import { LayoutService } from 'src/app/services/layout.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FileUploadAcceptType } from 'src/app/enums/file-upload-accept-type';

@Component({
  selector: 'obc-scannable-items',
  templateUrl: './scannable-items.component.html',
  styleUrls: ['./scannable-items.component.css']
})
export class ScannableItemsComponent implements OnInit {
  FileUploadAcceptType = FileUploadAcceptType;
  inProgress = false;
  inEditProgress = false;
  formGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    description: new FormControl(null, []),
    url: new FormControl(null, [urlValidator]),
    file: new FormControl(null, []),
    scannableGroupId: new FormControl(null, [Validators.required]),
  });
  items = [];
  groups = [];
  filterListTerm: string = null;

  resetFormGroup() {
    var id = this.formGroup.controls['scannableGroupId'].value;
    this.formGroup.reset();
    this.formGroup.controls['scannableGroupId'].setValue(id);
  }

  constructor(private scannableService: ScannableService,
    private bsModalService: BsModalService,
    private fileStorageService: FileStorageService,
    private layoutService: LayoutService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService) { }

  ngOnInit() {
    this.getGroups();
    this.layoutService.header = "Manage Scannable Items";
    this.route.params
      .pipe(finalize(() => { this.inProgress = false; }))
      .subscribe((param: Params) => {
        var groupId = +param['id'];
        this.formGroup.controls['scannableGroupId'].setValue(groupId);
        this.getItems(groupId);
      });
  }

  getGroups() {
    this.inProgress = true;
    this.scannableService.getGroups()
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        this.groups = res;
        if (this.formGroup.controls['scannableGroupId'].value == null && this.groups && this.groups.length)
          this.formGroup.controls['scannableGroupId'].setValue(this.groups[0].id);
        this.getItems();
      })
  }

  changeGroup(groupId?: number) {
    var id = groupId ?? this.formGroup.controls['scannableGroupId'].value;
    if (!id)
      return;
    this.router.navigate([`scannables/${id}/items`], { skipLocationChange: false });
  }

  getItems(groupId?: number) {
    var id = groupId ?? this.formGroup.controls['scannableGroupId'].value;
    if (!id)
      return;

    this.inProgress = true;
    this.scannableService.getItems(id)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        this.items = res;
      })
  }

  uploadEventHandler = (event) => {
    if (true) { // this.isFileModel(model.type)
      if (event.type === HttpEventType.UploadProgress) {
        //editableModel.formGroup.get('progress').setValue(`${Math.round(100 * event.loaded / event.total)}%`);
      }
      else if (event.type === HttpEventType.Response) {
        //editableModel.formGroup.get('progress').setValue(`100%`);
      }
    } else {
    }
  };

  onAddItem() {
    var signedUploadInfoModel = this.formGroup.controls['file'].value != null ? {
      fileCategory: FileCategory.ScannableItemFile,
      filename: this.formGroup.controls['file'].value.name,
    } : null;

    var model = {
      id: null,
      name: this.formGroup.controls['name'].value,
      description: this.formGroup.controls['description'].value,
      url: this.formGroup.controls['url'].value,
      storageKey: null,
      scannableGroupId: this.formGroup.controls['scannableGroupId'].value,
    };

    if (signedUploadInfoModel != null) {
      var file = this.formGroup.controls['file'].value;
      var uploadSuccess = true;
      this.inProgress = true;
      this.fileStorageService.getSignedUploadInfo(signedUploadInfoModel)
        .pipe(finalize(() => this.inProgress = false))
        .subscribe(uploadInfo => {
          var form = new FormData();
          form.append("file", file);
          this.inProgress = true;
          this.fileStorageService.uploadFile(form, uploadInfo)
            .pipe(finalize(() => this.inProgress = false))
            .subscribe(
              event => { this.uploadEventHandler(event); },
              error => {
                this.modalService.error(error, "unable to add item");
                this.inProgress = false;
              }, () => {
                if (uploadSuccess) {
                  model.storageKey = uploadInfo.storageKey;
                  this.addItem(model);
                } else {
                  this.inProgress = false;
                }
              }
            )
        })
    } else {
      this.addItem(model);
    }
  }

  addItem(model: any) {
    this.inProgress = true;
    this.scannableService.addItem(model)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        this.items.push(res);
        this.resetFormGroup();
      });
  }

  getGroupName(item) {
    return this.groups.find(i => i.id == item.scannableGroupId).name;
  }

  getGroupLabel(item) {
    return this.groups.find(i => i.id == item.scannableGroupId).label;
  }

  onRemoveItem(item: any) {
    this.modalService.confirm(`Are you sure you want to delete '${item.name}'`, "Delete Item", "Yes, I'm Sure", "Cancel").subscribe(res => {
      if (res) {
        this.inProgress = true;
        this.scannableService.removeItem(item.id)
          .pipe(finalize(() => this.inProgress = false))
          .subscribe(res => {
            this.items.splice(this.items.indexOf(item), 1);
          }, err => {
            if (err.status == 547) {
              this.modalService.error("This scannable item is used in user attendance or ...", "You can't remove this scannable item").subscribe(res => {
                this.getItems();
              });
            }
          }, () => {
          });
      }
    })
  }

  editFormGroup: FormGroup;
  bsModalRef: any;
  onShowUpdateItem(template, item) {
    this.editFormGroup = new FormGroup({
      id: new FormControl(item.id, [Validators.required]),
      name: new FormControl(item.name, [Validators.required]),
      description: new FormControl(item.description, []),
      url: new FormControl(item.url, [urlValidator]),
      file: new FormControl(null, []),
      removeFile: new FormControl(false, []),
      scannableGroupId: new FormControl(item.scannableGroupId, [Validators.required]),
    });
    this.bsModalRef = this.bsModalService.show(template);
  }

  onUpdateItem() {
    var removeFile = this.editFormGroup.controls['removeFile'].value;
    var signedUploadInfoModel = this.editFormGroup.controls['file'].value != null && !removeFile ? {
      fileCategory: FileCategory.ScannableItemFile,
      filename: this.editFormGroup.controls['file'].value.name,
    } : null;

    var model = {
      id: this.editFormGroup.controls['id'].value,
      name: this.editFormGroup.controls['name'].value,
      description: this.editFormGroup.controls['description'].value,
      url: this.editFormGroup.controls['url'].value,
      storageKey: null,
      scannableGroupId: this.editFormGroup.controls['scannableGroupId'].value,
      removeFile: removeFile,
    };

    if (signedUploadInfoModel != null) {
      var file = this.editFormGroup.controls['file'].value;
      var uploadSuccess = true;
      this.inEditProgress = true;
      this.fileStorageService.getSignedUploadInfo(signedUploadInfoModel)
        .pipe(finalize(() => this.inEditProgress = false))
        .subscribe(uploadInfo => {
          var form = new FormData();
          form.append("file", file);
          this.inEditProgress = true;
          this.fileStorageService.uploadFile(form, uploadInfo)
            .pipe(finalize(() => this.inEditProgress = false))
            .subscribe(
              event => { this.uploadEventHandler(event); },
              error => {
                this.modalService.error(error, "unable to edit item");
                this.inProgress = false;
              }, () => {
                if (uploadSuccess) {
                  model.storageKey = uploadInfo.storageKey;
                  this.updateItem(model);
                } else {
                  this.inProgress = false;
                }
              }
            )
        })
    } else {
      this.updateItem(model);
    }
  }

  updateItem(model) {
    this.inEditProgress = true;
    this.scannableService.updateItem(model.id, model)
      .pipe(finalize(() => this.inEditProgress = false))
      .subscribe(res => {
        var item = this.items.find(i => i.id == model.id);
        var index = this.items.indexOf(item);
        this.items.splice(index, 1, res);
        this.bsModalRef.hide();
      });
  }


}
