<div class="form-group row">
  <label class="col-sm-4 control-label text-left pt-2">Is Required ?</label>
  <div class="col-sm-8 pull-left">
    <obc-descriptor [text]="'Do you require an answer to this question for an individual to check in successfully?'">
    </obc-descriptor>
    <obc-switch [value]="questionParam['isRequired']" (valueChanged)="questionParam['isRequired'] = $event">
    </obc-switch>
  </div>
</div>
<div class="form-group row">
  <label class="col-sm-4 control-label text-left pt-2">Text Mode:</label>
  <div class="col-sm-8">
    <!--<obc-descriptor [text]="'WHAT DOES THIS MEAN?!?!'">
    </obc-descriptor>-->
    <select #regexlist (change)="onTextModeChanged(regexlist.value)" class="form-control mb-3">
      <option *ngFor="let reg of regexItems" [value]="reg.id" [selected]="reg.id == questionParam['regexType']">
        {{reg.itemTitle}}</option>
    </select>
  </div>
</div>
<div class="form-group row" *ngIf="questionParam['regexType'] == 1">
  <label class="col-sm-4 control-label text-left pt-2">Text Render Mode:</label>
  <div class="col-sm-8">
    <select (change)="onTextRenderModeChanged($event)" class="form-control mb-3">
      <option *ngFor="let item of TextRenderType | enumToArray"
              [selected]="TextRenderType[item] == questionParam['textRenderMode']"
              [value]="TextRenderType[item]">
        {{TextRenderType[item] | textRenderType}}</option>
    </select>
  </div>
</div>
<div class="row" *ngIf="showRegexInput">
  <label class="col-sm-4 control-label text-left pt-2">Regex:</label>
  <div class="col-sm-8">
    <input required class="form-control" name="Regex" #regCustom="ngModel" [(ngModel)]="questionParam['regex']">
    <div *ngIf="regCustom.invalid && (regCustom.dirty || regCustom.touched)" class="help-block with-errors">
      Please Enter Reqular Expression
    </div>
  </div>
</div>
