import { Pipe, PipeTransform } from '@angular/core';
import { FormApplyFor } from '../enums/form-apply-for';

@Pipe({
  name: 'formApplyFor'
})
export class FormApplyForPipe implements PipeTransform {

  transform(value: unknown): string {
    if (value === FormApplyFor.AnyPerson) return "Anyone";
    if (value === FormApplyFor.Individual) return "Individual";
    if (value === FormApplyFor.Supplier) return "Supplier";
    return value?.toString();
  }

}
