import { Component, Input, OnInit } from '@angular/core';
import { SiteAttendanceDisputeType } from 'src/app/enums/site-attendance-dispute-type.enum';
import { DisputeHistoryRequest, SiteAttendanceDisputeViewModel } from 'src/app/models/site-attendance-dispute-model';
import { DisputeSiteAttendanceService } from 'src/app/services/dispute-site-attendance.service';

@Component({
  selector: 'obc-dispute-history',
  templateUrl: './dispute-history.component.html',
  styleUrls: ['./dispute-history.component.scss']
})
export class DisputeHistoryComponent implements OnInit {

  constructor(private disputeSiteAttendanceService: DisputeSiteAttendanceService) { }
  @Input() siteAttendanceId: number;
  @Input() type: SiteAttendanceDisputeType;

  history: SiteAttendanceDisputeViewModel[];

  ngOnInit(): void {
    this.disputeSiteAttendanceService.getDisputeHistory({ siteAttendanceId: this.siteAttendanceId, type: this.type } as DisputeHistoryRequest)
      .subscribe(res => this.history = res);
  }

}
