import { Component, OnInit, Input } from '@angular/core';
import { ToolTipPlace, ToolTipTriggers, ToolTipMode } from 'src/app/enums/tooltip-enums';

@Component({
  selector: 'obc-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

  @Input() placement = ToolTipPlace.auto;
  @Input() message: string = '';
  @Input() trigger = ToolTipTriggers.hover;
  @Input() mode = ToolTipMode.info;

  constructor() { }
  ngOnInit(): void {
  }

}

