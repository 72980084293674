<ul class="simple-post-list">
  <ng-container *ngFor="let item of answers">
    <li class="d-flex flex-row justify-content-around"
      *ngIf="item.questionType != QuestionType.EmergencyContact || emergencyContactAnswerIsNotEmpty(item.answer)">
      <div class="flex-fill">
        <i class="fas fa-question-circle mr-1" aria-hidden="true"></i>
        <obc-question-title-renderer [question]="item"></obc-question-title-renderer>
        <span *ngIf="item.answerIsCopy === true" class="text-primary">&nbsp;({{ item.answerDate | datetime }})</span>
        <span>&nbsp;<span *ngIf="item.siteInductionId"><i class="fas fa-id-badge"></i></span></span>
        <div class="ml-4 text-primary" [ngSwitch]="item.questionType">
          <span class="text-info" *ngSwitchCase="QuestionType.File">
            <obc-file-viewer *ngIf="!item.userDocumentId" [urls]="item.fileUrls" [btnTitle]="'View Files'"
              [btnClass]="'btn btn-info'">
            </obc-file-viewer>
            <obc-document-answer-renderer *ngIf="item.userDocumentId" [questionAnswer]="item">
            </obc-document-answer-renderer>
          </span>
          <span class="text-info" *ngSwitchCase="QuestionType.SignaturePad">
            <obc-file-viewer [imgClass]="'signature-bg'" [previewImage]="item.fileUrls[0]" [urls]="item.fileUrls"
              [btnTitle]="'View Signature'" [btnClass]="'btn btn-info'">
            </obc-file-viewer>
          </span>
          <span class="text-info" *ngSwitchCase="QuestionType.Date">
            {{isQuestionTimeIncluded(item.questionParameters) == true
            ? (item.answer | datetime)
            : (item.answer | date)}}
          </span>
          <span class="text-info" *ngSwitchCase="QuestionType.EmergencyContact">
            <obc-emergency-contact-viewer
              [data]="item.answer"
            ></obc-emergency-contact-viewer>
          </span>
          <span class="text-info" *ngSwitchDefault>
            <div *ngIf="!isRichTextQuestion(item)">{{ item.answer }}</div>
            <obc-rich-text-renderer *ngIf="isRichTextQuestion(item)" [answer]="item"></obc-rich-text-renderer>
          </span>
        </div>
      </div>
    </li>
  </ng-container>
</ul>
