import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PermitStatus } from 'src/app/enums/permit-status';
import { FormViewModel } from 'src/app/models/form/form-view-model';
import { PermitUpdateStatusRequestModel } from 'src/app/models/permit/permit-update-status-request-model';
import { ModalService } from 'src/app/services/modal.service';
import { PermitService } from 'src/app/services/permit.service';
import { FormDataRendererComponent } from '../../form-data-renderer/form-data-renderer.component';

@Component({
  selector: 'obc-update-permit-status',
  templateUrl: './update-permit-status.component.html',
  styleUrls: ['./update-permit-status.component.scss']
})
export class UpdatePermitStatusComponent implements OnInit {
  @Input() permitStatusForm: FormViewModel;
  @Input() permitId: number;
  @Input() nextStatus: PermitStatus;
  @Input() siteId: number;
  @Output() public onHide = new EventEmitter();
  @Output() public onSubmitted = new EventEmitter();

  isPermitStatusFormValid: boolean;
  inProgress: boolean = false;
  @ViewChild(FormDataRendererComponent) public formDataRenderer: FormDataRendererComponent;

  constructor(private permitService: PermitService, private modalService: ModalService) { }
  ngOnInit(): void {
  }

  validatePermitStatusForm(formIsValid: boolean) {
    this.isPermitStatusFormValid = formIsValid;
  }

  onUpdatePermitStatus() {
    this.inProgress = true;
    var formAnswers = this.formDataRenderer.getFormResponse();
    let model = {
      formData: formAnswers,
      permitId: this.permitId,
      status: this.nextStatus,
    } as PermitUpdateStatusRequestModel;

    this.permitService.updatePermitStatus(model).subscribe({
      next: res => {
        if (res.success) {
          this.onSubmitted.emit();
        } else
          this.modalService.error(res.message);
      },
      error: err => {
        this.modalService.error(err, "Failed");
      }
      , complete: () => {
        this.inProgress = false;
      }
    })
  }
}
