import { Pipe, PipeTransform } from '@angular/core';
import { QuestionAnswerFileFormat } from '../models/questionParameters/question-answer-file-format';

@Pipe({
  name: 'questionAnswerFileFormat'
})
export class QuestionAnswerFileFormatPipe implements PipeTransform {

  transform(value: unknown): any {
    if (value === 0 || value == QuestionAnswerFileFormat.AnyFile) { return "Any File"; }
    if (value === 1 || value == QuestionAnswerFileFormat.Image) { return "Image"; }
    if (value === 2 || value == QuestionAnswerFileFormat.PDF) { return "PDF"; }
    return "Any File";
  }
}
