<tabset>
  <tab heading="Supplier Document Configuration">
    <obc-supplier-document-reminder></obc-supplier-document-reminder>
  </tab>
  <!-- <tab heading="Supplier Document Types">
    <app-manage-supplier-document-type></app-manage-supplier-document-type>
  </tab> -->
  <tab heading="Document Kinds" *ngIf="showDocumentKindTab()">
    <obc-document-kind-manager [isCurrentCompanyManagement]="true"></obc-document-kind-manager>
  </tab>
</tabset>