import { Pipe, PipeTransform } from '@angular/core';
import { DelayedAnswerMode } from '../enums/delayed-answer-mode';

@Pipe({
  name: 'delayAnswerMode'
})
export class DelayAnswerModePipe implements PipeTransform {

  transform(value: unknown): string {
    if (value === DelayedAnswerMode.Disable || value === 0) return "Disable";
    if (value === DelayedAnswerMode.EnableForAllQuestions || value === 1) return "Enable For All Questions";
    if (value === DelayedAnswerMode.EnableForMandatoryQuestions || value === 2) return "Enable For Mandatory Questions";
    return value?.toString() ?? '?';
  }

}
