<div *ngIf="ann" [ngSwitch]="ann.type"
  [ngClass]="{'mandatory':validateComponent && isAnnouncementMandatory(ann),'visited':ann.viewed}">
  <ng-container *ngSwitchCase="AnnouncementType.Text">
    <button (click)="onClickAnnouncement()" [ngClass]="{'custom-large-button btn-lg':largeButton}"
      class="mb-2 btn-info  btn btn-block announcement-btn text-color-light d-flex justify-content-start align-content-center">
      <i class="fas fa-envelope-open-text align-self-center"></i>
      <span class="align-self-center text-center ml-2">{{ann.title}}<small class="text-warning"> {{ ann.isActive ==
          true
          || ann.isActive == null ?
          '' :
          ' - disabled'}}</small>
      </span>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="AnnouncementType.Youtube">
    <a [href]="ann.data" target="_blank" (click)="onClickAnnouncement()"
      [ngClass]="{'custom-large-button btn-lg':largeButton}"
      class="mb-2 btn-info  btn btn-block announcement-btn text-color-light d-flex justify-content-start align-content-center">
      <i class="fab fa-youtube align-self-center"></i>
      <span class="align-self-center text-center ml-2">{{ann.title}}<small class="text-warning"> {{ ann.isActive ==
          true
          || ann.isActive == null ? '' :
          ' - disabled'}}</small>
      </span>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="AnnouncementType.Video">
    <a [href]="ann.m3u8VideoUrl" target="_blank" (click)="onClickAnnouncement()"
      [ngClass]="{'custom-large-button btn-lg':largeButton}"
      class="mb-2 btn-info  btn btn-block announcement-btn text-color-light d-flex justify-content-start align-content-center">
      <i class="fas fa-video align-self-center"></i>
      <span class="align-self-center text-center ml-2">{{ann.title}}<small class="text-warning"> {{ ann.isActive ==
          true
          || ann.isActive == null ? '' :
          ' - disabled'}}</small>
      </span>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="AnnouncementType.ExternalLink">
    <a [href]="ann.url? ann.url: ann.data" target="_blank" (click)="onClickAnnouncement()"
      [ngClass]="{'custom-large-button btn-lg':largeButton}"
      class="b-2 btn-info  btn btn-block announcement-btn text-color-light d-flex justify-content-start align-content-center">
      <i class="fas fa-link align-self-center"></i>
      <span class="align-self-center text-center ml-2">{{ann.title}}<small class="text-warning"> {{ ann.isActive ==
          true
          || ann.isActive == null ? '' :
          ' - disabled'}}</small>
      </span>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="AnnouncementType.Audio">
    <a [href]="ann.url" target="_blank" (click)="onClickAnnouncement()"
      [ngClass]="{'custom-large-button btn-lg':largeButton}"
      class="mb-2 btn-info  btn btn-block announcement-btn text-color-light d-flex justify-content-start align-content-center">
      <i class="far fa-file-audio align-self-center"></i>
      <span class="align-self-center text-center ml-2">{{ann.title}}<small class="text-warning"> {{ ann.isActive ==
          true
          || ann.isActive == null ? '' :
          ' - disabled'}}</small>
      </span>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="AnnouncementType.Pdf">
    <button type="button" (click)="onOpenFileViewer(fileViewer)" [ngClass]="{'custom-large-button btn-lg':largeButton}"
      class="mb-2 btn-info  btn btn-block announcement-btn text-color-light d-flex justify-content-start align-content-center">
      <i class="far fa-file-pdf align-self-center"></i>
      <span class="align-self-center text-center ml-2">{{ann.title}}<small class="text-warning"> {{ ann.isActive ==
          true
          || ann.isActive == null ? '' :
          ' - disabled'}}</small>
      </span>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="AnnouncementType.Image">
    <button type="button" (click)="onOpenFileViewer(fileViewer)" [ngClass]="{'custom-large-button btn-lg':largeButton}"
      class="mb-2 btn-info  btn btn-block announcement-btn text-color-light d-flex justify-content-start align-content-center">
      <i class="far fa-image align-self-center"></i>
      <span class="align-self-center text-center ml-2">{{ann.title}}<small class="text-warning"> {{ ann.isActive ==
          true
          || ann.isActive == null ? '' :
          ' - disabled'}}</small>
      </span>
    </button>
  </ng-container>
</div>

<ng-template #fileViewer>
  <div class="card">
    <div class="card-header">
      <h4>View Files</h4>
      <div class="card-actions">
        <a class="card-action card-action-dismiss" (click)="closeModal()"></a>
      </div>
    </div>
    <div class="card-body">
      <ng-container *ngTemplateOutlet="fileListTemplate">
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #fileListTemplate>
  <accordion>
    <ng-container>
      <accordion-group #accordion [isDisabled]="!fileHasContent(ann.url,ann.type)"
        [isOpen]="fileHasContent(ann.url,ann.type)">
        <button (click)="onFileOpened(ann.url)" *ngIf="fileHasContent(ann.url,ann.type)"
          class="text-decoration-none btn btn-link btn-block clearfix d-flex align-items-center justify-content-center"
          accordion-heading type="button">
          <i *ngIf="ann.type == FileType.Image" class="fa fa-image text-info mr-2" style="font-size: 22px"></i>
          <i *ngIf="ann.type == FileType.Pdf" class="fa fa-file-pdf text-info mr-2" style="font-size: 22px"></i>
          {{ ann.title }}
        </button>

        <img class="fw-image" [src]="ann.url | safe: 'resourceUrl'" style="display: block;width: 100%;height: 100%;"
          *ngIf="ann.type == FileType.Image && accordion.isOpen" alt="image" />
        <!-- <iframe class="fw" [src]="file.fileUrl | safe: 'resourceUrl'" *ngIf="file.fileType == FileType.Pdf && accordion.isOpen"></iframe> -->
        <div class="pdf-viewer" style="position: relative" *ngIf="ann.type == FileType.Pdf && accordion.isOpen">
          <a target="_blank" href="{{ ann.url }}" style="position: absolute; top: 0; left: 0; z-index: 1"
            class="btn btn-primary"><i class="fas fa-download"></i></a>
          <obc-pdf-viewer [src]="ann.url"></obc-pdf-viewer>
        </div>
      </accordion-group>
    </ng-container>
  </accordion>
</ng-template>
