import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AsyncResult } from 'src/app/models/helper-models';
import { QuestionViewModel } from 'src/app/models/question-viewmodel';

@Component({
  selector: 'app-question-selector',
  templateUrl: './question-selector.component.html',
  styleUrls: ['./question-selector.component.scss']
})
export class QuestionSelectorComponent implements OnInit {
  title = "Select Question(s)";
  filterQuestionTerm = null;
  @Input() showAll = false;
  @Input() companyQuestions: QuestionViewModel[] = [];
  @Input() addedQuestions: number[] = [];
  @Input() showAddButton: boolean = true;
  @Input() isMultiSelect: boolean = true;
  @Output() addQuestion = new EventEmitter();
  @Output() public hide = new EventEmitter<void>();
  questions: QuestionViewModel[] = []

  get companyAvailableQuestions(): QuestionViewModel[] {
    return this.companyQuestions.filter(function (x) { return this.indexOf(x.id) < 0 && (this.showAll || x.isActive) }, this.addedQuestions);
  };
  bsModalRef: BsModalRef
  constructor(private bsModalService: BsModalService) { }

  ngOnInit() {
  }

  onAddQuestion(id: number) {
    var question = this.companyAvailableQuestions.find(x => x.id == id);
    if (question && this.addQuestion) {
      this.addQuestion.emit(question);

      if(!this.isMultiSelect) {
        this.hide.emit();
      }
    }    
  }
  onAddNewQuestionClick(template: TemplateRef<any>) {
    this.bsModalRef = this.bsModalService.show(template, { class: 'modal-lg' });
  }
  onResultQuestionAdd(result: AsyncResult) {
    this.bsModalRef.hide();
    if (!result.isSucceeded) return;
    let newQuestion = new QuestionViewModel(result.result);
    this.companyQuestions.push(newQuestion)
    this.onAddQuestion(newQuestion.id);
    this.hide.next();
  }
}
