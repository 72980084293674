<div>
    <div style="position: relative">
        <button *ngIf="isDisputable" style="position: absolute; right: 9px; top: 9px"
            class="btn btn-primary float-right" (click)="onShowDisputeModal()">
            Dispute Check In/Out Date
        </button>
        <ul class="simple-post-list">
            <li *ngFor="let fld of userInformationFields" class="d-flex flex-row justify-content-around">
                <div class="flex-fill">
                    <i *ngIf="fld.icon" class="fas fa-{{ fld.icon }}"></i>
                    {{ fld.title }}:
                    <span class="text-primary" [ngSwitch]="fld.type">
                        <span *ngSwitchCase="'date'">
                            {{ userInformation[fld.key] | date }}
                        </span>
                        <span *ngSwitchCase="'datetime'">
                            {{ userInformation[fld.key] | datetime }}
                            <span *ngIf="fld.key == 'checkInDate' && userInformation.checkInDistanceTitle"
                                class="ml-2">({{userInformation.checkInDistanceTitle}})</span>
                            <span *ngIf="fld.key == 'checkOutDate' && userInformation.checkOutDistanceTitle"
                                class="ml-2">({{userInformation.checkOutDistanceTitle}})</span>

                            <i class="
                            fas
                            fa-exclamation-triangle
                            text-danger
                            cursor-pointer
                          " (click)="showDisputHistory(true)" *ngIf="
                            fld.key == 'checkInDate' &&
                            userInformation.isDisputedCheckInDate
                          " tooltip="Click To View History"></i>

                            <i class="
                            fas
                            fa-exclamation-triangle
                            text-danger
                            cursor-pointer
                          " (click)="showDisputHistory(false)" tooltip="Click To View History" *ngIf="
                            fld.key == 'checkOutDate' &&
                            userInformation.isDisputedCheckOutDate
                          "></i>
                        </span>
                        <span *ngSwitchCase="'emergencyContact'">
                            <div class="ml-3">
                                <obc-emergency-contact-viewer
                                    [data]="userInformation[fld.key]"></obc-emergency-contact-viewer>
                            </div>
                        </span>
                        <span *ngSwitchCase="'gender'">
                            {{ userInformation[fld.key] | gender }}
                        </span>
                        <span *ngSwitchCase="'industryTypeText'">
                            {{ userInformation[fld.key] }}
                        </span>
                        <span *ngSwitchCase="'businessName'">
                            {{ userInformation[fld.key] }}
                        </span>
                        <span *ngSwitchCase="'mobile'">
                            {{ userInformation[fld.key] | mobile }}
                        </span>
                        <span *ngSwitchCase="'supplier'">
                            {{ userInformation[fld.key] }}
                        </span>
                        <span *ngSwitchCase="'check'">
                            {{
                            userInformation[fld.key] == null
                            ? "Not collected"
                            : userInformation[fld.key] == true
                            ? "Yes"
                            : "No"
                            }}
                        </span>
                        <span *ngSwitchCase="'email'">
                            {{ userInformation[fld.key] }}
                            <i title="Verified Email" *ngIf="userInformation.isEmailVerified"
                                class="fas fa-check text-success"></i>
                            <i title="Not Verified Email" *ngIf="!userInformation.isEmailVerified"
                                class="fas fa-times text-danger"></i>
                        </span>
                        <!-- <span *ngSwitchCase="'induction'">
                        <obc-induction-status [siteInductionStatus]="siteInductionStatus"
                          [siteAttendanceId]="this.questionAnswer.siteAttendanceId" [siteId]="userInformation.siteId"
                          [readOnly]="false" (onApproveOrReject)="onApproveInduction()"></obc-induction-status>
                      </span> -->
                        <span *ngSwitchDefault>{{ userInformation[fld.key] }}</span>
                    </span>
                </div>
            </li>
        </ul>
    </div>
</div>