import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { timeFormat } from '../helpers/date-time-format';
import { LocaleService } from '../services/locale.service';
import { DatetimePipe } from './datetime.pipe';
import { dateTimeFormat } from '../helpers/date-time-format';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {
  transform(value: string, format?: string): string {
    if (!value)
      return '';
    var ret = moment(value, timeFormat).format('LT');
    return ret.toString();
  }

}
