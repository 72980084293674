<section class="card">
    <header class="card-header">
      <h2 class="card-title">Blocked Site Users</h2>
    </header>

    <obc-descriptor [closePanelName]="'siteAttendanceBlockedUser'"
        [text]="'Adding a blocked user will deny a user the ability to check in to a particular site. Press the recycle bin on the right hand side to unblock a user'">
    </obc-descriptor>
    <div class="card-body">
        <obc-loader [show]="inProgress"></obc-loader>
        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div class="row">
                <div class="col-12">
                    <button id="addToTable" class="btn btn-info my-2" (click)="initNew()">Add New<i
                            class="fas fa-plus pl-2"></i></button>

                </div>
            </div>
            <div class="table-responsive overflow-visible">
                <table class="table table-bordered table-striped mb-0 dataTable no-footer">
                    <thead>
                        <tr role="row">
                            <th>Mobile</th>
                            <th>Name</th>
                            <th>Family</th>
                            <th>Site</th>
                            <th>Actions</th>
                        </tr>
                        <tr>
                            <td colspan="5">
                                <input type="text" class="form-control"
                                    placeholder="Filter Result by Mobile, First Name, Last Name, Site"
                                    [(ngModel)]="filterListTerm" />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="addNewMode">
                            <ng-container *ngTemplateOutlet="addupdate"></ng-container>
                        </ng-container>
                        <ng-container
                            *ngFor="let user of (blockedUsers | filter:filterListTerm:['mobile','firstName','lastName','siteName'])">
                            <tr *ngIf="!user.inEdit">
                                <td>{{user.mobile | mobile}}</td>
                                <td>{{user.firstName}}</td>
                                <td>{{user.lastName}}</td>
                                <td>{{user.siteName}}</td>
                                <td class="actions">
                                    <!-- <a class="on-default edit-row pointer" (click)="initEdit(user)"><i
                                            class="fas fa-pencil-alt"></i></a> -->
                                    <a class="btn p-1 remove-row" (click)="delete(user)"><i
                                            class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                            <ng-container *ngIf="user.inEdit">
                                <ng-container *ngTemplateOutlet="addupdate"></ng-container>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>
<ng-template #addupdate>
    <tr role="row" class="adding odd">
        <td colspan="3">
            <obc-user-autocomplete [filterBy]="filterUserBy.All" [required]="true"
                (selectedUser)="blockedUser.userId = $event.id">
            </obc-user-autocomplete>
        </td>
        <td>
            <div class="form-group">
                <app-site-selector [required]="true" #sl (siteIdSelect)="blockedUser.siteId = $event">
                </app-site-selector>
            </div>
            <!-- <select class="form-control" [(ngModel)]="blockedUser.siteId" required>
                <option *ngFor="let site of sites" [ngValue]="site.id">{{site.name}}</option>
            </select> -->
        </td>
        <td>
            <a (click)="addUpdateBlockedUser()" class="btn p-1 on-editing save-row"><i
                    class="fas fa-save text-success"></i></a>
            <a (click)="cancel()" class="btn px-3 py-1"><i class="fas fa-times text-warning"></i></a>
            <a class="btn p-1 on-default edit-row hidden"><i class="fas fa-pencil-alt"></i></a>
            <a class="btn p-1 on-default remove-row hidden"><i class="far fa-trash-alt"></i></a>
        </td>
    </tr>
</ng-template>
