import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Observable } from 'rxjs';
import { AddressLookupDetails } from '../models/gmap/address-lookup-details';
import { catchError } from 'rxjs/operators';
import { AddressLookupGetSuggestionModel } from '../models/gmap/address-lookup-get-suggestion-model';
import { AddressLookupSuggestion } from '../models/gmap/address-lookup-suggestion';

@Injectable({
  providedIn: 'root'
})
export class AddressLookupService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getAddressSuggestionList = (model: AddressLookupGetSuggestionModel): Observable<AddressLookupSuggestion[]> =>
    this._http.post<AddressLookupSuggestion[]>('/api/addresslookup/', model)
      .pipe(catchError(this._errorService.handleError));

  getDetailsOfSelectedPlace = (suggestionId: string, sessionToken: string): Observable<AddressLookupDetails> =>
    this._http.get<AddressLookupDetails>(`/api/addresslookup/map/details/${suggestionId}/${sessionToken}`)
      .pipe(catchError(this._errorService.handleError));

  getLocation = (address: string): Observable<any> =>
    this._http.get<AddressLookupDetails>(`/api/addresslookup/get-location/${address}`)
      .pipe(catchError(this._errorService.handleError));
}
