import { Pipe, PipeTransform } from '@angular/core';
import { ProcoreFolderStructureMode } from '../enums/procore-folder-structure-mode.enum';

@Pipe({
  name: 'procoreFolderStructureMode'
})
export class ProcoreFolderStructureModePipe implements PipeTransform {

  transform(value: any): unknown {
    if (value == ProcoreFolderStructureMode.Legacy) return "Legacy";
    else if (value == ProcoreFolderStructureMode.FeatureBased) return "Grouped by Feature";
    else if (value == ProcoreFolderStructureMode.SupplierBased) return "Grouped by Supplier";
    return value?.toString() ?? "?";
  }

}
