import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServerResult, ServerResultData } from '../models/server-result-data';
import { AnnonymousAcceptSupplierDocument } from '../models/supplier-document/accept-supplier-document';
import {
  ApproveDeclineSiteSupplierDocumentVersion,
  ArchiveSiteSupplierDocumentTypeModel,
  DeleteSiteSupplierDocumentVersion
} from '../models/supplier-document/site-supplier-document-type-models';
import {
  SupplierDocumentTypeModel,
  SupplierDocumentTypeViewModel
} from '../models/supplier-document/supplier-document-models';
import { ErrorService } from './error.service';
import {
  SiteSupplierDocumentTypeModel,
  SupplierDocumentModel
} from "../models/supplier-document/site-supplier-document-type-view-model.model";
import { SiteSupplierHashLinkModel } from "../models/supplier-document/site-supplier-hash-link-model.model";
import { BaseSiteSupplierDocTypeFormsViewModel, SiteSupplierRequestModel } from '../models/supplier-document/site-supplier-doc-type-forms-view-model';
import { SiteSupplierVersionFormReviewModel } from '../models/supplier-document/site-supplier-version-form-review-model';
import { ExternalSitePermission } from '../enums/external-site-permission';
import {
  SiteSupplierDocumentVersionCommentModel,
  SiteSupplierDocumentVersionCommentViewModel
} from "../models/supplier-document/site-supplier-document-version-comment-model";
import { SiteSupplierViewModel } from '../models/supplier-document/site-supplier-view-model.model';
import { InfoPagingViewModel } from '../models/site-list-paging-view-model';
import { PagingRequest } from '../models/paging-request-model';
import { SiteSupplierWithDocumentTypesViewModel } from '../models/supplier-document/site-supplier-models';
import { SiteSupplierInfoFull } from '../models/supplier-document/site-supplier-info';
import {ChangeSiteSupplierDocumentKindQueryModel} from "../models/change-site-supplier-document-kind-query-model";
import { SupplierDocumentSignOff, SupplierDocumentSignOffRequest } from '../models/supplier-document/supplier-document-signoff';

@Injectable({
  providedIn: 'root'
})
export class SupplierDocumentService {

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getSupplierDocumentTypes = (): Observable<ServerResultData<SupplierDocumentTypeViewModel[]>> =>
    this._http.get<ServerResultData<SupplierDocumentTypeViewModel[]>>(`api/SupplierDocument/get-supplier-document-types`)
      .pipe(catchError(this._errorService.handleError));

  addSupplierDocumentTypes = (model: SupplierDocumentTypeModel): Observable<SupplierDocumentTypeViewModel> =>
    this._http.post<SupplierDocumentTypeViewModel>(`api/SupplierDocument/add-supplier-document-type`, model)
      .pipe(catchError(this._errorService.handleError));

  updateSupplierDocumentTypes = (id: number, model: SupplierDocumentTypeModel): Observable<SupplierDocumentTypeViewModel> =>
    this._http.post<SupplierDocumentTypeViewModel>(`api/SupplierDocument/update-supplier-document-type/${id}`, model)
      .pipe(catchError(this._errorService.handleError));

  removeSupplierDocumentTypes = (id: number) =>
    this._http.get(`api/SupplierDocument/remove-supplier-document-type/${id}`)
      .pipe(catchError(this._errorService.handleError));

  acceptAnonymousSupplierDocument = (model: AnnonymousAcceptSupplierDocument): Observable<boolean> =>
    this._http.post<boolean>(`api/SiteSupplierDocument/anonymous-accept-supplier-document`, model)
      .pipe(catchError(this._errorService.handleError));


  addSiteSupplierDocumentTypes(model: SiteSupplierDocumentTypeModel): Observable<any> {
    return this._http.post<any>(`api/SupplierDocument/add-site-supplier-document-types`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  editSiteSupplierDocumentTypes(siteSupplierId: number, model: SiteSupplierDocumentTypeModel): Observable<any> {
    return this._http.post<any>(`api/SupplierDocument/edit-site-supplier-document-types/${siteSupplierId}`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  removeSiteSupplierDocumentTypes(siteSupplierId: number): Observable<ServerResultData<ServerResult>> {
    return this._http.get<ServerResultData<ServerResult>>(`api/SupplierDocument/remove-site-supplier-document-types/${siteSupplierId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  getSiteSupplierDocumentTypes(request: PagingRequest<SiteSupplierRequestModel>): Observable<InfoPagingViewModel<SiteSupplierViewModel, BaseSiteSupplierDocTypeFormsViewModel>> {
    return this._http.post<InfoPagingViewModel<SiteSupplierViewModel, BaseSiteSupplierDocTypeFormsViewModel>>(`api/SupplierDocument/site-supplier-document-types/`, request)
      .pipe(catchError(this._errorService.handleError));
  }

  // getSiteSupplierDocumentTypesBySite(siteId?: number): Observable<SiteSupplierDocTypeFormsViewModel> {
  //   return this._http.get<SiteSupplierDocTypeFormsViewModel>(`api/SupplierDocument/get-site-supplier-document-types-by-site/${siteId ?? ''}`)
  //     .pipe(catchError(this._errorService.handleError));
  // }

  getSiteSupplierDocumentTypesBySiteWithStatistics(request: PagingRequest<SiteSupplierRequestModel>): Observable<InfoPagingViewModel<SiteSupplierViewModel, BaseSiteSupplierDocTypeFormsViewModel>> {
    return this._http.post<InfoPagingViewModel<SiteSupplierViewModel, BaseSiteSupplierDocTypeFormsViewModel>>(`api/SupplierDocument/site-supplier-document-types-by-site-with-statistics/`, request)
      .pipe(catchError(this._errorService.handleError));
  }


  editSiteSupplierDocumentTypeEnableStatus(siteSupplierId: number, value: boolean): Observable<ServerResultData<ServerResult>> {
    return this._http.get<ServerResultData<ServerResult>>(`api/SupplierDocument/edit-site-supplier-document-type-enable-status/${siteSupplierId}/${value}`)
      .pipe(catchError(this._errorService.handleError));
  }

  sendEmail(model: SiteSupplierHashLinkModel): Observable<any> {
    return this._http.post<any>(`api/SiteSupplierDocument/send-site-supplier-hash-link`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  approveDeclineSiteSupplierVersion(model: ApproveDeclineSiteSupplierDocumentVersion): Observable<ServerResult> {
    return this._http.post<ServerResult>(`api/SupplierDocument/approve-decline-site-supplier-version`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  renameSiteSupplierDocumentType(model: any) {
    return this._http.post(`api/SiteSupplierDocument/rename-site-supplier-document-type`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  siteSupplierVersionFormReview(model: SiteSupplierVersionFormReviewModel): Observable<ServerResult> {
    return this._http.post<ServerResult>(`api/SupplierDocument/site-supplier-version-form-review`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  updateExternalSitePermissions(siteSupplierId: number, externalSitePermissions: ExternalSitePermission): Observable<any> {
    return this._http.post<any>(
      `api/SiteSupplierDocument/update-external-site-permissions/${siteSupplierId}/${externalSitePermissions}`, {})
      .pipe(catchError(this._errorService.handleError));
  }

  requestNewSiteSupplierDocumentType(siteSupplierId: number, model: SupplierDocumentModel) {
    return this._http.post(`api/SiteSupplierDocument/request-new-site-supplier-document-type/${siteSupplierId}`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  deleteSiteSupplierDocumentVersion(request: DeleteSiteSupplierDocumentVersion) {
    return this._http.post(`api/SiteSupplierDocument/delete-supplier-document-version`, request)
      .pipe(catchError(this._errorService.handleError));
  }

  archiveSiteSupplierDocumentTypes(model: ArchiveSiteSupplierDocumentTypeModel) {
    return this._http.post(`api/SiteSupplierDocument/archive-site-supplier-document-type-list`, model)
      .pipe(catchError(this._errorService.handleError));
  }
  unarchiveSiteSupplierDocumentTypes(model: ArchiveSiteSupplierDocumentTypeModel) {
    return this._http.post(`api/SiteSupplierDocument/unarchive-site-supplier-document-type-list`, model)
      .pipe(catchError(this._errorService.handleError));
  }

  addSiteSupplierDocumentVersionComment(model: SiteSupplierDocumentVersionCommentModel, siteSupplierHashlink?: string): Observable<ServerResultData<SiteSupplierDocumentVersionCommentViewModel[]>> {
    let headers = new HttpHeaders({ "hash-link": siteSupplierHashlink ?? '' });
    return this._http.post<ServerResultData<SiteSupplierDocumentVersionCommentViewModel[]>>(
      `api/SiteSupplierDocument/add-supplier-document-version-comment`, model, { headers: headers })
      .pipe(catchError(this._errorService.handleError));
  }

  deleteAllSupplierDocuments(supplierDocumentTypeId: number) {
    return this._http.post(`api/SiteSupplierDocument/delete-all-supplier-documents`, supplierDocumentTypeId)
      .pipe(catchError(this._errorService.handleError));
  }

  exportDataAsPdf(siteSupplierDocumentVersionId: number) {
    return window.open(`api/SupplierDocument/site-supplier-document-pdf/${siteSupplierDocumentVersionId}`);
  }

  getSiteSupplierDocumentVersionByWorkflowInstanceId(workflowInstanceId: string): Observable<SiteSupplierWithDocumentTypesViewModel> {
    return this._http.get<SiteSupplierWithDocumentTypesViewModel>(`api/SiteSupplierDocument/site-supplier-document-version/${workflowInstanceId}`);
  }

  getSiteSuppliersBySiteId(siteId: number): Observable<ServerResultData<SiteSupplierInfoFull[]>> {
    return this._http.get<ServerResultData<SiteSupplierInfoFull[]>>(`api/SupplierDocument/get-site-suppliers-by-siteId/${siteId}`)
      .pipe(catchError(this._errorService.handleError));
  }

}
