import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { ModalService } from "../../../services/modal.service";
import { finalize } from "rxjs/operators";
import { RegionModel, RegionViewModel } from "../../../models/region/region-model";
import { RegionService } from "../../../services/region.service";

@Component({
  selector: 'obc-manage-region',
  templateUrl: './manage-region.component.html',
  styleUrls: ['./manage-region.component.scss']
})
export class ManageRegionComponent implements OnInit {

  formGroup = new FormGroup({
    id: new FormControl(null, []),
    name: new FormControl(null, [Validators.required])
  })
  inProgress = false;
  regions = new BehaviorSubject<RegionViewModel[]>([]);
  currentRegion: RegionViewModel = null;
  constructor(private regionService: RegionService, private modalService: ModalService) { }

  ngOnInit() {
    this.inProgress = true;
    this._getRegions();
  }

  onEdit(model: RegionViewModel) {
    this.formGroup.controls.id.setValue(model.regionId);
    this.formGroup.controls.name.setValue(model.regionName);
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  _getRegions() {
    this.resetFormGroups();
    this.regionService.getRegions()
      .pipe(finalize(() => { this.inProgress = false }))
      .subscribe(res => {
        this.regions.next(res.data);
      }, err => {
        this.modalService.error(err)
      })
  }

  onInsert() {
    var model = {
      regionName: this.formGroup.controls.name.value,
    } as RegionModel
    var subscription = this.regionService.addRegion(model);
    this.inProgress = true;
    subscription
      .pipe(finalize(() => {
        this.resetFormGroups();
        this.inProgress = false
      }))
      .subscribe(res => {
        if (res.success) {
          this.formGroup.controls.name.setValue(null);
          this._getRegions();
        } else
          this.modalService.error(res.message);
      }, err => {
        this.modalService.error(err)
      })
  }

  onRemove(regionId: number) {
    this.modalService.confirm("Are you sure you want to delete this region?", "Remove Region").subscribe(res => {
      if (res) {
        this.inProgress = true;
        this.regionService.removeRegion(regionId)
          .pipe(finalize(() => {
            this.resetFormGroups();
            this.inProgress = false
          }))
          .subscribe(res => {
            if (res.success) {
              this.modalService.info(res.message);
              this._getRegions();
            } else
              this.modalService.error(res.message);
          }, err => {
            this.modalService.error(err);
          })
      }
    })
  }

  onUpdate() {
    var model = {
      regionName: this.formGroup.controls.name.value,
    } as RegionModel
    this.inProgress = true;
    var subscription = this.regionService.updateRegion(this.formGroup.controls.id.value, model);
    subscription
      .pipe(finalize(() => {
        this.resetFormGroups();
        this.inProgress = false;
      }))
      .subscribe(res => {
        if (res.success) {
          this._getRegions();
        }
        else
          this.modalService.error(res.message);
      }, err => {
        this.modalService.error(err)
      })
  }
  resetFormGroups() {
    this.formGroup.controls.name.setValue(null);
    this.formGroup.controls.id.setValue(null);
  }


  isValid() {
    return this.formGroup.controls.name.value?.trim()?.length > 0;
  }
}
