import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserCredentialsViewModel } from '../models/user-credentials-viewModel';
import { SignalRService } from "./signal-r.service";
@Injectable({
  providedIn: 'root'
})
export class SignInService {

  constructor(private http: HttpClient, private router: Router, private signalRService: SignalRService) { }

  public getInitXsrfToken() {
    return this.http.post<any>(`/api/obcauthentication/get-init-xsrf-token`, {})
      .pipe(catchError((error) => observableThrowError(error)))
  }

  public webPreLogin(mobile: string, countryCode: string) {
    return this.http
      .post<any>(`/api/obcauthentication/web-pre-login?countryCode=${countryCode}`, JSON.stringify(mobile))
      .pipe(catchError((error) => observableThrowError(error)))
  }

  public requestSendToken(mobileNumber: string, countryCode: string) {
    return this.http
      .post<any>(`/api/obcauthentication/send-reset-pass-otp`, JSON.stringify({ mobileNumber, countryCode }))
      .pipe(catchError((error) => observableThrowError(error)))
  }

  public signIn(returnPath, body: UserCredentialsViewModel) {
    const headers = new HttpHeaders({ 'ReturnPath': returnPath === undefined ? '' : returnPath });

    return this.http
      .post('/api/obcauthentication/web-sign-in', body, { headers: headers, observe: 'response' })
      .pipe(catchError((error) => observableThrowError(error)))
  }

  signOut() {
    this.http.post('/api/obcauthentication/web-sign-out', {}, { observe: 'response' }).subscribe((resp: HttpResponse<Object>) => {
      //this.userStore.resetPermissions(); TODO must impelment
      this.handleRedirection(resp, true);
      this.signalRService.closeConnection();
    });
  }

  private handleRedirection(response: any, reload: boolean = false) {
    const ajaxRedirectStatusCode = 200;
    if (response.status === ajaxRedirectStatusCode) {
      if (reload)
        location.href = response.headers.get('Location');
      else
        this.router.navigate([response.headers.get('Location')]);
    }
  }
}
