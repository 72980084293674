import { Pipe, PipeTransform } from '@angular/core';
import { AccessControlInductionMode, AccessControlSupplierDocumentMode } from '../enums/access-control-automatic-attendance-mode';

@Pipe({
  name: 'accessControlSupplierDocumentMode'
})
export class AccessControlSupplierDocumentModePipe implements PipeTransform {

  transform(value: any): string {
    if (value === AccessControlSupplierDocumentMode.NoRestriction) { return "No Restrictions"; }
    if (value === AccessControlSupplierDocumentMode.RequiredSignOffForAllActiveSupplierDocuments) { return "Require Sign-off on All Mandatory Supplier Documents"; }
    return value?.toString() ?? '?';
  }

}
