import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SiteAttendanceBlockedUserViewModel } from '../models/site-attendance-blocked-user-view-model';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class SiteAttendanceBlockedUserService {

  baseUrl: string = '/api/SiteAttendanceBlockedUser';

  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getBlockeUsers = (): Observable<SiteAttendanceBlockedUserViewModel[]> =>
    this._http.get<SiteAttendanceBlockedUserViewModel[]>(`${this.baseUrl}`)
      .pipe(catchError(this._errorService.handleError));

  add = (model: SiteAttendanceBlockedUserViewModel) =>
    this._http.post(`${this.baseUrl}`, model)
      .pipe(catchError(this._errorService.handleError));

  update = (model: SiteAttendanceBlockedUserViewModel) =>
    this._http.put(`${this.baseUrl}`, model)
      .pipe(catchError(this._errorService.handleError));

  delete = (siteBlockedUserId: number) =>
    this._http.delete(`${this.baseUrl}/${siteBlockedUserId}`)
      .pipe(catchError(this._errorService.handleError));
}
