import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SiteAttendanceQuestionAnswer } from 'src/app/models/attendance-request/site-attendance-question-answer';
import { CustomFormControl } from 'src/app/models/custom-formcontrol';
import { SiteAttendanceInductionViewModel } from 'src/app/models/site-attendance-induction-view-model';
import { RequiredDocumentViewModel, UserDocumentType } from "../../../models/user-document-type";
import { ModalService } from "../../../services/modal.service";
import {
  convertDocumentToFormControl, isEqual,
  setDocumentFormControlForQuestions
} from "../../../helpers/general-functions";
import { SiteAnnouncementForceViewMode } from "../../../enums/site-announcement-force-view-mode";

@Component({
  selector: 'obc-induction-renderer',
  templateUrl: './induction-renderer.component.html',
  styleUrls: ['./induction-renderer.component.scss']
})
export class InductionRendererComponent implements OnInit {
  _induction: SiteAttendanceInductionViewModel = null;
  questionContainerValidationSubscription: Subscription = null;
  questionContainerValid: boolean = true;
  questionAnswers: any = null;
  questionContainerValidation$: BehaviorSubject<{ valid: boolean, answers: SiteAttendanceQuestionAnswer[], isUpdatingByUser?: boolean }>
    = new BehaviorSubject<{ valid: boolean, answers: SiteAttendanceQuestionAnswer[], isUpdatingByUser?: boolean }>({ valid: false, answers: [] });
  requiredDocumentFormControls: CustomFormControl[];
  requiredDocumentFormArray: FormArray;

  @Input() previewRenderer: boolean = false;

  @Input() set induction(value: SiteAttendanceInductionViewModel) {
    if (this._induction?.induction?.id != value?.induction?.id) {
      this._induction = value;
      this._induction.questions = setDocumentFormControlForQuestions(this._induction.questions,
        this._induction?.induction?.id);

      this.resetInduction();
      this.questionAnswers = value.answers?.answers?.questions;
      this.update();
      this.manageDocuments();
    }
  };

  _allUserDocumentTypes: UserDocumentType[];
  @Input() set allUserDocumentTypes(data: UserDocumentType[]){
    this._allUserDocumentTypes = data;
    this.update();
    this.manageDocuments();
  }
  get allUserDocumentTypes() {
    return this._allUserDocumentTypes;
  }
  availableDocumentTypes: UserDocumentType[];
  selectedDocumentsIds: number[] = [];

  documents: RequiredDocumentViewModel[] = [];

  @Input() notifyVisits = false;
  @Input() anonymousAttendanceKey = null;
  @Output() removeDocumentFromRequiredDocumentsOutput = new EventEmitter();
  @Output() addDocumentToDocumentTypesOutput = new EventEmitter<UserDocumentType>();
  @Output() onAnnouncementVisit = new EventEmitter<{ announcementId: number, inductionId: number }>();
  @Output() onCheckedMandatorySignOff = new EventEmitter<{ announcementId: number, inductionId: number }>();

  resetInduction() {
    this.requiredDocumentFormControls = this._induction?.answers?.answers?.documents ?? [];
    let notEmptyFormControls = [ ...this.requiredDocumentFormControls?.filter((item) => item.value != null) ];
    let res = convertDocumentToFormControl(this._induction.documents, (this._induction.questions?.filter(question => question.documentFormControl != null)?.map(question => question.documentFormControl) ?? []));
    this.requiredDocumentFormArray = res?.formArray;
    this.requiredDocumentFormControls = res?.controls;
    this.requiredDocumentFormControls.forEach((control) => {
      let relatedFormControl = notEmptyFormControls.find((notEmptyFormControl) => isEqual(notEmptyFormControl.item, control.item));
      if(relatedFormControl) {
        control.setValue(relatedFormControl.value);
      }
    })
    this.requiredDocumentFormArray.controls.forEach(c => c.valueChanges.subscribe(_ => {
      this.update();
    }));
  }

  constructor() {
  }

  ngOnInit() {
    this.questionContainerValidationSubscription = this.questionContainerValidation$.subscribe((res) => {
      if (!this?._induction?.questions?.length) return;
      this.questionContainerValid = res.valid;
      if(res.isUpdatingByUser) {
        this.questionAnswers = res.answers;
      }
      this.update();
    });
  }

  manageDocuments() {
    this.selectedDocumentsIds = this._induction?.documents?.map(item => item.documentType?.id);
    this.availableDocumentTypes = this.allUserDocumentTypes?.filter(doc => this.selectedDocumentsIds?.indexOf(doc.id) === -1) || [];
  }

  private isValid() {
    //let isValidDocuments = !this.requiredDocumentFormArray.controls.map(x => x.valid).some(x => x == false);
    let isValidDocuments = this.documents == null ||
      this.requiredDocumentFormControls?.every(c => {
        let control = (c as CustomFormControl);
        let doc = control.item as RequiredDocumentViewModel;
        return doc.questionId == null ? c.valid : true;
      });

    let isValidQuestions = this.questionContainerValid;
    let isValidAnnouncements = !this._induction.announcements.some(s => (s.forceViewMode == SiteAnnouncementForceViewMode.MandatoryView ||
      s.forceViewMode == SiteAnnouncementForceViewMode.SetPlaythroughToMandatory ||
      s.forceViewMode == SiteAnnouncementForceViewMode.MandatoryViewAndSignOff
    ) && s.viewed != true);
    return isValidDocuments && isValidQuestions && isValidAnnouncements;
  }

  private getAnswers() {
    return {
      questions: this.questionAnswers,
      documents: this.requiredDocumentFormArray?.controls?.map(
        (formControl) => {
          return formControl as CustomFormControl;
        }
      ).filter(doc => doc != null)
    };
  }

  update() {
    if(this._induction) {
      this._induction.isValid = this._induction.skipped ? true : this.isValid();
      this._induction.answers = this._induction.skipped ? {} : {
        id: this._induction.induction.id,
        answers: this.getAnswers(),
      }
    }
  }


  onSkipInductionStatusChanged(_induction: SiteAttendanceInductionViewModel, skip) {
    _induction.skipped = !skip;
    this.resetInduction();
    this.update();
  }

  onUpdateVisitedAnnouncements(announcementId: number, inductionId: number) {
    this.update();
    this.onAnnouncementVisit.emit({ announcementId: announcementId, inductionId: inductionId });
  }

  onAddDocumentToDocuments(documentType) {
    if (documentType) {
      this.addDocumentToDocumentTypesOutput.emit(documentType);
    }
    // this.modalService.hide();
  }

  removeDocumentFromRequiredDocuments(reqDocument) {
    if (reqDocument) {
      this.removeDocumentFromRequiredDocumentsOutput.emit(reqDocument);
    }
  }

  onAnnouncementCheckedMandatorySignOff(announcementId: number, inductionId: number) {
    this.onCheckedMandatorySignOff.emit({ announcementId: announcementId, inductionId: inductionId });
  }
}
