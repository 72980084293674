import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { Table } from 'src/app/enums/table.enum';
import { GridColumn, GridConfig, GridTemplate } from 'src/app/models/grid/grid.config';
import { SiteBriefingSignOff } from 'src/app/models/site-briefings/site-briefing-signoff';
import { MobilePipe } from 'src/app/pipes/mobile.pipe';
import { Constants } from "../../../../enums/constants";
import { SiteBriefingStatus } from 'src/app/enums/site-briefing-status';
import { ModalService } from 'src/app/services/modal.service';
import { isArray, property } from 'lodash';

@Component({
  selector: 'obc-site-briefing-signoff',
  templateUrl: './site-briefing-signoff.component.html',
  styleUrls: ['./site-briefing-signoff.component.scss']
})
export class SiteBriefingSignoffComponent implements OnInit {
  SiteBriefingStatus = SiteBriefingStatus;


  @Input() selectedUserId: number = null;
  @Input() siteIdList: number[] = [];
  @Input() isReportMode: boolean = false;

  @ViewChild('grid') grid: any;
  gridColumns: GridColumn<SiteBriefingSignOff>[] = [];
  gridConfig: GridConfig = {} as GridConfig;
  selectedFormDataId: number;

  constructor(private mobilePipe: MobilePipe, private modalService: ModalService) { }

  ngOnInit(): void {
    this.prepareGridConfig();
  }

  prepareGridConfig() {

    let initialFilters = [];
    if (this.selectedUserId) {
      initialFilters.push({ key: 'userId', value: this.selectedUserId });
    }
    if (this.siteIdList?.length ?? 0) {
      initialFilters.push({ key: 'siteIdList', value: this.siteIdList });
    }
    this.gridConfig = new GridConfig({
      apiUrl: '/api/sitebriefing/get-site-briefing-signoff',
      tableType: Table.SiteBriefingSignOffReport,
      forceHideAllControlButtons: this.isReportMode,
      displayQuestionsFilter: false,
      displayGeneralSearch: false,
      initialFilters: initialFilters,
      cacheKeyPrefix: this.isReportMode ? Constants.WORKER_PROFILE_REPORT_PREFIX : '',
    });
    this.gridColumns = [
      {
        name: "Site",
        key: "siteName",
        type: FilterType.Site,
        enableFilter: true,
        enableSort: true,
        propertyNameInQuery: 'siteIdList',
      },
      {
        name: "Name",
        key: "formName",
        type: FilterType.SiteBriefingForm,
        propertyNameInQuery: 'formIdList',
        enableFilter: true,
        enableSort: true,
      },
      {
        name: "Submitted On",
        key: "siteBriefingSubmitDate",
        propertyNameInQuery: 'siteBriefingSubmitDate',
        type: FilterType.DateTimeRange,
        isArray: false,
        enableFilter: true,
        enableSort: true,
      },
      this.isReportMode ? {
        name: "Submitted By",
        key: "submittedByUser",
        enableFilter: false,
        enableSort: false,
        displayFunction: (row) => {
          return row.user?.firstName + ' ' + row.user?.lastName + ' (' + this.mobilePipe.transform(row.user?.mobile) + ')';
        }
      } : null,
      {
        name: "SignOff Date",
        key: "signOffDate",
        type: FilterType.DateTimeRange,
        enableFilter: false,
        enableSort: false,
      },
      !this.isReportMode ? {
        name: "User",
        key: "user",
        enableFilter: false,
        enableSort: false,
        displayFunction: (row) => {
          return row.user?.firstName + ' ' + row.user?.lastName + ' (' + this.mobilePipe.transform(row.user?.mobile) + ')';
        }
      } : null,
      {
        name: "Signed By",
        key: "signedByUser",
        enableFilter: false,
        enableSort: false,
        displayFunction: (row) => {
          return row.signedByUser
            ? `${row.signedByUser.firstName} ${row.signedByUser.lastName} (${this.mobilePipe.transform(row.signedByUser.mobile)})`
            : '';
        }
      },
      {
        name: "Actions",
        key: "",
        gridTemplate: new GridTemplate().ComponentTemplateName('actions'),
        enableFilter: false,
        enableSort: false,
      },
    ].filter((q) => !!q);
  };

  openModal(template: any, selectedFormDataId: number) {
    this.selectedFormDataId = selectedFormDataId;
    this.modalService.show(template, 'modal-lg');
  }
}
