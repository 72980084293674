import { Pipe, PipeTransform } from '@angular/core';
import { FormAskMode } from '../enums/form-ask-mode.enum';

@Pipe({
  name: 'formAskMode'
})
export class FormAskModePipe implements PipeTransform {

  transform(value: unknown): string {
    if (value === FormAskMode.AnyTime) return "Any time";
    if (value === FormAskMode.WhileCheckedIn) return "While checked-in";
    if (value === FormAskMode.AfterCheckOut) return "After check-out";
    return value?.toString();
  }

}
