<div class="alert alert-default nomargin">
  <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="onRemove()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="announcement-box d-flex justify-content-between">
    <div class="announcement-title">
      <span>
        <i class="fas fa-bell  mr-2"></i>
        {{_announcement.title}}
      </span>
    </div>
    <div class="announcement-state">
      <obc-switch falseLabel="Not Viewed" trueLabel="Viewed" class="mr-2" [value]="_announcement.viewed" (valueChanged)="_announcement.viewed = $event;onChangeState($event)">
      </obc-switch>
    </div>
  </div>
</div>
