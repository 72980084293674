export enum GodUserPermission {
  None = 0,
  ManageCompanies = 1 << 0, //1
  ManagePlans = 1 << 1,  //2
  EditMobileNumber = 1 << 2, //4
  DeleteSupplierDocuments = 1 << 3, //8    
  ManageDocumentTypes = 1 << 4, //16    
  ManageVideos = 1 << 5, //32    
  ManageIndustryTypes = 1 << 6, //64    
  ManageGlobalAssetFields = 1 << 7, //128    
  AuditLog = 1 << 8, //256
  ProcoreSyncMonitoring = 1 << 9, //512
  ManageGates = 1 << 10, //1024
  ForceProcoreSync = 1 << 11,//2048
  WorkflowManagement = 1 << 12,//4096
  WorklfowAdvancedManagement = 1 << 13,
}

export const CombinedGodUserPermissionList: GodUserPermission =
  GodUserPermission.None |
  GodUserPermission.ManageCompanies |
  GodUserPermission.ManagePlans |
  GodUserPermission.EditMobileNumber |
  GodUserPermission.DeleteSupplierDocuments |
  GodUserPermission.ManageDocumentTypes |
  GodUserPermission.ManageVideos |
  GodUserPermission.ManageIndustryTypes |
  GodUserPermission.ManageGlobalAssetFields |
  GodUserPermission.AuditLog |
  GodUserPermission.ProcoreSyncMonitoring |
  GodUserPermission.ManageGates |
  GodUserPermission.ForceProcoreSync |
  GodUserPermission.WorkflowManagement |
  GodUserPermission.WorklfowAdvancedManagement;

export const GodUserPermissionList = [
  GodUserPermission.ManageCompanies, //1
  GodUserPermission.ManagePlans, //2
  GodUserPermission.EditMobileNumber, //4
  GodUserPermission.DeleteSupplierDocuments, //8
  GodUserPermission.ManageDocumentTypes, //16
  GodUserPermission.ManageVideos, //32
  GodUserPermission.ManageIndustryTypes, //64
  GodUserPermission.ManageGlobalAssetFields, //128
  GodUserPermission.AuditLog, //256
  GodUserPermission.ProcoreSyncMonitoring, //512
  GodUserPermission.ManageGates, //1024
  GodUserPermission.ForceProcoreSync,//2048
  GodUserPermission.WorkflowManagement,//4,096
  GodUserPermission.WorklfowAdvancedManagement,
];
