import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'obc-question-boolean',
  templateUrl: './question-boolean.component.html',
  styleUrls: ['./question-boolean.component.scss']
})
export class QuestionBooleanComponent implements OnInit {

  questionParam: any;
  @Input() set questionParamInput(_questionParamInput: any) {
    if (!_questionParamInput) { return; }
    else {
      this.questionParam = _questionParamInput;
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
