export enum FilterType {
  Text = 0,
  User = 1,
  DateTimeRange = 2,
  DateRange = 3,
  Site = 4,
  VisitorType = 5,
  IndustryType = 6,
  Gender = 7,
  Duration = 8,
  Induction = 9,
  Supplier = 10,
  InventoryCategory = 11,
  InventoryToolStatus = 12,
  InventoryLocation = 13,
  SingleText = 14,
  Form = 15,
  FormType = 16,
  InductionId = 17,
  PermitStatus = 18,
  DateTime = 19,
  DocumentType = 20,
  DocumentExpiryStatus = 21,
  Mobile = 22,
  SupplierDocuments = 23,
  Template = 24,
  QuestionAndAnswer = 25,
  PermitForm = 26,
  File = 27,
  AssetCategory = 28,
  AssetStatus = 29,
  AssetCategoryType = 30,
  ApprovalStatus = 31,
  ApproveEntityType = 32,
  Image = 33,
  SiteSupplierDocumentVersionStatus = 34,
  RoleArray = 37,
  MessageType = 38,
  Switch = 39,
  ActionList = 45,
  SiteBriefingForm = 46,
  SiteBriefingStatus = 47,
  SupplierDocumentKind = 48,
  EventLogType = 49,
}
