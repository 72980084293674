<div class="row">
  <div class="col-md-12">
    <div class="card">
      <header class="card-header">Card Report</header>
      <obc-descriptor [closePanelName]="'cardReport'"
        [text]="'This allows you to view user uploaded documents for each attendance. You can view all attendances or filter by a particular field.'">
      </obc-descriptor>
      <div class="card-header mb-2">
        <span class="mr-5">
          <span class="d-inline-block mt-2 mr-1">Display Mode: </span>
          <obc-switch [trueLabel]="' All Cards '" [falseLabel]="' Only Latest '" value="showAllCards"
            (valueChanged)="onActiveChanged($event)">
          </obc-switch>
        </span>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <app-base-grid
              [columns]="gridColumns"
              [configs]="gridConfig" #grid>
            </app-base-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
