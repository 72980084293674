import { Pipe, PipeTransform } from '@angular/core';
import { DocumentKindService } from '../services/document-kind.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'documentKindNameAsyncFilter'
})
export class DocumentKindNamePipe implements PipeTransform {
  constructor(private documentKindService: DocumentKindService) { }

  transform(value: any): Observable<string> {
    return new Observable(observer => {
      this.documentKindService.get(+value).subscribe(kind => { observer.next(kind.name); observer.complete(); });
    });
  }
}
