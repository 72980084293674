<div class="row">
    <div class="col-md-12">
        <div class="card">
            <obc-loader [show]="inProgress"></obc-loader>
            <header class="card-header">Audit Logs</header>
            <obc-descriptor [text]="'You can view all security logs here.'" [closePanelName]="'securityLogs'">
            </obc-descriptor>
            <div class="card-body">
                <div class="mb-5">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-xl-4 mb-2">
                            <label>Select Log Type</label>
                            <select class="custom-select" required="" name="log_type" [(ngModel)]="selectedEntityType">
                                <option value="{{ null }}" [ngValue]="null" selected>All</option>
                                <option *ngFor="let type of SecurityLogType  | enumToArray"
                                    [ngValue]="SecurityLogType [type]">
                                    {{ SecurityLogType[type] | securityLogType}}
                                </option>
                            </select>
                        </div>
                        <div class="col-12 col-sm-6 col-xl-4 mb-2" *ngIf="mode === 'company'">
                            <label>Select Site</label>
                            <app-site-selector [required]="false" [notSelectedSiteLabel]="'All Sites'"
                                (siteIdSelect)="onSiteIdSelect($event)"></app-site-selector>
                        </div>
                        <div class="col-12 col-sm-6 col-xl-4 mb-2">
                            <div class="form-group">
                                <label>Start Date</label>
                                <obc-input-date-time-picker [(ngModel)]="startDate"
                                    [mode]="DatetimePickerMode.Calender">
                                </obc-input-date-time-picker>
                                <!-- <input [owlDateTime]="dt1" class="form-control" [(ngModel)]="startDate"
                                    [owlDateTimeTrigger]="dt1">
                                <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time> -->
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-xl-4 mb-2">
                            <div class="form-group">
                                <label>End Date</label>
                                <obc-input-date-time-picker [(ngModel)]="endDate"
                                    [mode]="DatetimePickerMode.Calender">
                                </obc-input-date-time-picker>
                                <!-- <input [owlDateTime]="dt2" class="form-control" [(ngModel)]="endDate"
                                    [owlDateTimeTrigger]="dt2">
                                <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time> -->
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12 d-flex flex-row-reverse">
                            <button class="btn btn-primary" style="min-width: 140px" (click)="loadReport()">
                                Refresh
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="list">
                    <div class="col-md-12 mt-2">
                        <h5 class="pull-left">
                            <span>
                                {{ totalRecords }} Record(s)
                            </span>
                        </h5>
                    </div>
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped mt-3">
                                <thead>
                                    <tr>
                                        <td>Date/Time</td>
                                        <td>
                                            Type
                                        </td>
                                        <td>
                                            User
                                        </td>
                                        <td *ngIf="mode === 'company'">
                                            Site Name
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody *ngIf="list?.length > 0">
                                    <ng-container *ngFor="let item of list">
                                        <tr>
                                            <td>
                                                {{item.dateTime | datetime}}
                                            </td>
                                            <td>
                                                {{ item.type | securityLogType}}
                                            </td>
                                            <td>
                                                {{ item.userFullName }}
                                                <span class="ml-1">({{item.userMobile | mobile}})</span>
                                            </td>
                                            <td *ngIf="mode === 'company'">
                                                {{item.siteName}}
                                            </td>
                                            <td>
                                                <button type="button" class="btn btn-primary"
                                                    (click)="showDetails(item, logDetails)">Details</button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>

                            </table>
                            <div *ngIf="list && list.length" class="simple-post-list pt-3">
                                <obc-pagination [totalCount]="totalRecords" [pageSize]="30" [pageNumber]="pageNumber"
                                    (pageChanged)="onPageChange($event)"></obc-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #logDetails>
    <div class="card">
        <div class="card-header">
            Audit Log Details ({{ details.type | securityLogType}})
        </div>
        <div class="card-body">
            <div *ngIf="details">
                <div class="row">
                    <div class="col-md-6">
                        DateTime: <strong>{{details.dateTime | datetime}}</strong>
                    </div>
                    <div class="col-md-6">
                        User: <strong>{{ details.userFullName }}
                            <span class="ml-1">({{details.userMobile | mobile}})</span></strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        IP: <strong>{{details.ip}}</strong>
                    </div>
                    <div class="col-md-6">
                        Browser: <strong>
                            <span *ngIf="details.userAgent.length > 30"
                                title="{{details.userAgent}}">{{details.userAgent | slice:0:30}}...</span>
                            <span *ngIf="details.userAgent.length <= 30"
                                title="{{details.userAgent}}">{{details.userAgent}}</span>
                        </strong>
                    </div>
                </div>
                <div class="row" *ngIf="details.siteName">
                    <div class="col-md-6">
                        Site Name: <strong>{{details.siteName}}</strong>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-12">
                        <ul>
                            <li *ngFor="let item of details.details">
                                {{item.title}}:
                                <span class="text-danger">
                                    <strong>
                                        <span *ngIf="item.dateTime">{{ item.dateTime | datetime }}</span>
                                        <span *ngIf="!item.dateTime">{{ item.value ? item.value : '-' }}</span>
                                    </strong>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
