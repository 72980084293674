<div class="">
  <div class="form-group">
    <label class="bold">Reason of Dispute:</label>
    <div class="form-check">
      <label class="form-check-label">
        <input type="radio" class="form-check-input" [(ngModel)]="selectedType"
          [value]="SiteAttendanceDisputeType.CheckInDate" (change)="onChangeMode(true)"> Edit Check-In
      </label>
      <div class="fw">
        <div class="d-flex flex-row justify-content-between">
          <label class="bold">Current Check-In Date</label>
          <span>{{userInformation.checkInDate | datetime}} </span>
        </div>
      </div>
      <div *ngIf="returnModel.newCheckInDateValue && selectedType != SiteAttendanceDisputeType.CheckInDate" class="fw">
        <div class="d-flex flex-row justify-content-between text-danger">
          <label class="bold">Set Check-In Date To</label>
          <span>{{returnModel.newCheckInDateValue | datetime}} <i tooltip="Discard Changes"
              (click)="returnModel.newCheckInDateValue = null" class="fas fa-times"></i></span>
        </div>
      </div>
      <ng-container *ngIf="selectedType == SiteAttendanceDisputeType.CheckInDate">
        <div class="form-group">
          <label class="bold text-danger">Select New Check-In Date</label>
          <obc-input-date-time-picker [(ngModel)]="returnModel.newCheckInDateValue"
            (valueChange)="datePickerValueChage($event,true)">
          </obc-input-date-time-picker>
          <!-- <input [owlDateTime]="dt1" class="form-control" [owlDateTimeTrigger]="dt1"
            [(ngModel)]="returnModel.newCheckInDateValue" (dateTimeChange)="datePickerValueChage($event.value,true)">
          <owl-date-time [pickerType]="'both'" #dt1></owl-date-time> -->
        </div>
      </ng-container>
    </div>
    <div class="form-check mt-4" *ngIf="hasCheckOutDate">
      <label class="form-check-label">
        <input type="radio" class="form-check-input" [(ngModel)]="selectedType"
          [value]="SiteAttendanceDisputeType.CheckOutDate" (change)="onChangeMode(false)"> Edit Check-Out Date
      </label>
      <div class="fw">
        <div class="d-flex justify-content-between flex-row">
          <label class="bold">Current Check-Out Date</label>
          <span>{{userInformation.checkOutDate | datetime}} </span>
        </div>
      </div>
      <div *ngIf="returnModel.newCheckOutDateValue && selectedType != SiteAttendanceDisputeType.CheckOutDate"
        class="fw">
        <div class="d-flex flex-row justify-content-between text-danger">
          <label class="bold">Set Check-Out Date To</label>
          <span>{{returnModel.newCheckOutDateValue | datetime}} <i tooltip="Discard Changes"
              (click)="returnModel.newCheckOutDateValue = null" class="fas fa-times"></i></span>
        </div>
      </div>
      <ng-container *ngIf="selectedType == SiteAttendanceDisputeType.CheckOutDate">
        <div class="form-group">
          <label class="bold text-danger">Select New Check-Out Date</label>
          <obc-input-date-time-picker [(ngModel)]="returnModel.newCheckOutDateValue"
            (valueChange)="datePickerValueChage($event,false)">
          </obc-input-date-time-picker>
          <!-- <input [owlDateTime]="dt2" class="form-control" [owlDateTimeTrigger]="dt2"
            [(ngModel)]="returnModel.newCheckOutDateValue" (dateTimeChange)="datePickerValueChage($event.value,false)">
          <owl-date-time [pickerType]="'both'" #dt2></owl-date-time> -->
        </div>
      </ng-container>
    </div>
    <div class="btn-group fw text-right">
      <button [disabled]="!isFormValid()" (click)="onDispute()" class="btn btn-primary">Dispute</button>
    </div>
  </div>

</div>