import { Component, OnInit, OnDestroy } from '@angular/core';
import { CardLayout, PageSize, PageLayout, CardLayoutElement, StyleSetting, getPageSizeWithLayout } from 'src/app/models/print-layout/print-layout-model';
import { ModalService } from 'src/app/services/modal.service';
import { StorageService } from 'src/app/services/storage.service';
import { finalize } from 'rxjs/operators';
import { isEquall } from 'src/app/helpers/general-functions';
import { LocalStorageKey } from '../../../enums/local-storage-key.enum';
import { CardLayoutService } from 'src/app/services/card-layout.service';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'obc-card-designer',
  templateUrl: './card-designer.component.html',
  styleUrls: ['./card-designer.component.scss']
})

export class CardDesignerComponent implements OnInit, OnDestroy {
  inProgress = false;
  layoutList: CardLayout[] = [];
  currentLayout: CardLayout = null;//new CardLayout();
  layoutForConfig: CardLayout = new CardLayout();
  designerSelected: boolean = true;
  _elemntRepository: CardLayoutElement[] = [];

  get elementRepository(): CardLayoutElement[] {
    //return this._elemntRepository.filter(function (x) { return this?.indexOf(x.id) < 0 }, this.selectedLayout.elements?.map(c => c.id));
    if (!this._elemntRepository) return [];
    if (!this.currentLayout || !this.currentLayout.elements) return this._elemntRepository;
    return this._elemntRepository.filter((l) => this.currentLayout.elements.filter(i => l.id == i.id).length == 0);
  };

  constructor(private modalService: ModalService, private layoutService: LayoutService, private cardLayoutService: CardLayoutService) {
    this.loadlayouts();
  }

  selectedLayoutId: number;
  modelchange(id) {
    this.selectedLayout = this.layoutList.find(x => x.id == id)
  }


  set selectedLayout(newModel: CardLayout) {
    console.log(newModel)
    if (newModel == null) {
      this.currentLayout = null;
      return;
    }

    if (this.layoutChanged === true) {
      var currentLayoutId = this.currentLayout.id;

      this.modalService.confirm('Did you want save layout before leave it ?', 'Save Layout', 'Yes, Save Layout', 'Disacard all changes')
        .subscribe(result => {
          if (result === true) {
            this.inProgress = true;
            this.cardLayoutService.updateCardLayout(this.currentLayout)
              .pipe(finalize(() => this.inProgress = false))
              .subscribe(res => {
                var index = this.layoutList.indexOf(this.layoutList.find(i => i.id == currentLayoutId));
                this.layoutList.splice(index, 1, this.currentLayout);
                this.currentLayout = JSON.parse(JSON.stringify(newModel));
              });
          } else if (result === false) {
            this.currentLayout = JSON.parse(JSON.stringify(newModel));
          }
        })
      return;
    } else {
      this.currentLayout = JSON.parse(JSON.stringify(newModel));
    }
  }

  get selectedLayout(): CardLayout {
    if (this.currentLayout != null)
      return this.layoutList.filter(i => i.id == this.currentLayout.id)[0];
  }

  loadlayouts() {
    this.inProgress = true;
    this.cardLayoutService.getCardLayouts()
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        this.layoutList = res.cardLayouts;
        this._elemntRepository = res.cardElements.map(element => {
          element.position = { height: 90, left: 0, top: 0, width: 180 };
          return element;
        });
        if (this.layoutList.length > 0) {
          this.selectedLayout = this.layoutList[0];
          this.selectedLayoutId = this.layoutList[0].id;
        }
      });
  }

  ngOnInit(): void {
    this.layoutService.header = "Card Designer"
  }

  addElementToLayout(element: CardLayoutElement) {
    if (!this.selectedLayout.elements)
      this.currentLayout.elements = [];
    var clone = JSON.parse(JSON.stringify(element));
    this.currentLayout.elements.push(clone);
  }

  confirmRemoveLayout() {
    this.modalService.confirm('Do you want remove selected layout?', 'Remove Layout', 'Yes', 'Cancel')
      .subscribe(result => {
        if (result === true) {
          this.inProgress = true;
          this.cardLayoutService.removeCardLayout(this.selectedLayout.id)
            .pipe(finalize(() => this.inProgress = false))
            .subscribe(res => {
              var index = this.layoutList.indexOf(this.selectedLayout);
              this.layoutList.splice(index, 1);
              this.currentLayout = null;
              if (this.layoutList.length > 0) {
                this.selectedLayout = this.layoutList[0];
              }
              else
                this.selectedLayout = null;
            });
        }
      })
  }

  get layoutChanged(): boolean {
    if (this.currentLayout == null)
      return false;
    var savedLayout = this.layoutList?.find(c => c.id === this.currentLayout.id);
    var result = isEquall(this.currentLayout, savedLayout);
    return result === false;
  }

  saveLayout() {
    this.inProgress = true;
    this.cardLayoutService.updateCardLayout(this.currentLayout)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(res => {
        var index = this.layoutList.indexOf(this.selectedLayout);
        this.layoutList[index] = JSON.parse(JSON.stringify(this.currentLayout));
      });
  }

  openConfigLayout(layoutpage, layout) {
    if (layout == null) {
      layout = { // new layout
        id: 0,
        name: `New Layout ${this.layoutList.length + 1}`,
        size: PageSize.A5,
        width: getPageSizeWithLayout(PageSize.A5, PageLayout.Portrait).width.toString(),
        height: getPageSizeWithLayout(PageSize.A5, PageLayout.Portrait).height.toString(),
        layout: PageLayout.Portrait, elements: []
      }
    }
    this.layoutForConfig = layout;
    this.modalService.show(layoutpage)
      .subscribe(result => {
        console.log('result : ', result);
        if (result === undefined) return;
        if (result.saved === true) {
          if (result.layout.id == 0) // add
          {
            this.inProgress = true;
            this.cardLayoutService.addCardLayout(result.layout)
              .pipe(finalize(() => this.inProgress = false))
              .subscribe(res => {
                result.layout.id = res;
                this.layoutList.push(result.layout);
                this.selectedLayoutId = result.layout.id;
                this.modelchange(result.layout.id);
              })
          }
          else if (result.layout.id != 0) { // update
            this.resizeElements(this.layoutForConfig, result.layout);
            this.inProgress = true;
            this.cardLayoutService.updateCardLayout(result.layout)
              .pipe(finalize(() => this.inProgress = false))
              .subscribe(res => {
                var index = this.layoutList.map(c => c.id).indexOf(this.layoutForConfig.id);
                this.layoutList[index] = JSON.parse(JSON.stringify(result.layout));
                this.currentLayout = JSON.parse(JSON.stringify(result.layout));
                this.selectedLayoutId = result.layout.id;
                this.modelchange(result.layout.id);
              });
          }
        }
      });
  }

  resizeElements(origin: CardLayout, changed: CardLayout) {
    if (origin.width != changed.width) {
      var chgangedPercent = (((+changed.width * 100) / +origin.width) / 100);
      changed.elements.forEach(c => c.position.width = c.position.width * chgangedPercent);
    }

    if (origin.height != changed.height) {
      var chgangedPercent = (((+changed.height * 100) / +origin.height) / 100);
      changed.elements.forEach(c => c.position.height = c.position.height * chgangedPercent);
    }
  }

  ngOnDestroy() {
  }
}
