<section class="card">
    <div class="card-header">
        <span>
            {{modalHeader}}
        </span>
        <button type="button" class="close" (click)="hide.emit()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</section>
<div class="card-body">
    <div class="row">
        <div class="col-md-12 ">
            <obc-loader [show]="inProgress"></obc-loader>
            <ng-container *ngIf="previewType=='induction'">
                <obc-induction-renderer [induction]="inductionViewModel" [previewRenderer]="true">
                </obc-induction-renderer>
            </ng-container>

            <ng-container *ngIf="previewType=='form'">
                <obc-form-data-renderer  [previewFormData]="formViewModel" [formType]="formViewModel?.type">
                </obc-form-data-renderer>
            </ng-container>
        </div>
    </div>
</div>