import { Pipe, PipeTransform } from '@angular/core';
import { ReportDeliveryType } from '../enums/report-type.enum';

@Pipe({
  name: 'reportDeliveryType'
})
export class ReportDeliveryTypePipe implements PipeTransform {

  transform(value: any): string {
    return value == ReportDeliveryType.Email ? "Email" : (value?.toString() ?? "?");
  }

}
