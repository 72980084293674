import { SiteUserUpdateRequest, SiteUserViewModel } from './../models/company-user-view-model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class SiteUserService {
  apiUrl = '/api/SiteUser';
  constructor(private _http: HttpClient, private _errorService: ErrorService) { }

  getSiteUsers(siteId: number): Observable<SiteUserViewModel[]> {
    return this._http.get<SiteUserViewModel[]>(`${this.apiUrl}/get-site-users?siteId=${siteId}`)
      .pipe(catchError(this._errorService.handleError));
  }

  getAllPermissions = (): Observable<any> =>
    this._http.get<any>(`${this.apiUrl}/get-all-permissions`)
      .pipe(catchError(this._errorService.handleError));

  addSiteUser(siteId: number, model: SiteUserViewModel): Observable<any> {
    return this._http.post<SiteUserViewModel>(`${this.apiUrl}/add-site-user?siteId=${siteId}`, model)
  }

  removeSiteUser(siteUserId: number, siteId: number): Observable<boolean> {
    return this._http.delete<boolean>(`${this.apiUrl}/${siteUserId}`);
  }

  updateSiteUser(siteUserId: number, model: SiteUserUpdateRequest): Observable<any> {
    return this._http.post(`${this.apiUrl}/update-site-user?siteUserId=${siteUserId}`, model);
  }

  ImportUsers(siteId: number, formData: any): Observable<any> {
    return this._http.post(`/api/SiteUser/import-users/${siteId}`, formData,
      { reportProgress: false, observe: 'events', headers: { "do-not-modify-content-type": "" } })
      .pipe(catchError(this._errorService.handleError));
  }
}
