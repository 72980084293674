import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { DropzoneSourceType } from 'src/app/enums/dropzone-source-type';
import { getFileExtenstion, toBase64 } from 'src/app/helpers/general-functions';
import { CustomFormControl } from 'src/app/models/custom-formcontrol';
import { InventoryDynamicFieldFile } from 'src/app/models/inventory/inventory-dynamic-field-values';
import { ToolDynamicFieldViewModel } from 'src/app/models/inventory/tool-dynamic-field-model';
import { QuestionAnswerFileFormat } from 'src/app/models/questionParameters/question-answer-file-format';
import { FileUploadAcceptType } from "../../../../enums/file-upload-accept-type";
import { SafePipe } from "../../../../pipes/safe-pipe";
import { ModalService } from "../../../../services/modal.service";

@Component({
  selector: 'obc-image-tool-field-renderer',
  templateUrl: './image-tool-field-renderer.component.html',
  styleUrls: ['./image-tool-field-renderer.component.scss'],
  providers: [
    SafePipe
  ]
})
export class ImageToolFieldRendererComponent implements OnInit {
  DropzoneSourceType = DropzoneSourceType;
  formArray = new FormArray([]);
  _control: CustomFormControl;
  images : InventoryDynamicFieldFile[] = [];
  fileAcceptType: FileUploadAcceptType;

  @Input() set control(c: CustomFormControl) {
    this._control = c;
    switch(c.item.fileType){
      case QuestionAnswerFileFormat.AnyFile:
        this.fileAcceptType = FileUploadAcceptType.Any;
        break;
      case QuestionAnswerFileFormat.Image:
        this.fileAcceptType = FileUploadAcceptType.Image;
        break;
      case QuestionAnswerFileFormat.PDF:
        this.fileAcceptType = FileUploadAcceptType.PDF;
        break;
      default:
        this.fileAcceptType = FileUploadAcceptType.Any;
        break;
    }
    this.images = this.item.tempData ? JSON.parse(JSON.stringify(this.item.tempData)) as InventoryDynamicFieldFile[] : []; // clone images
    this.formArray.clear();
    this.addImageInput();
    this.updateValues();
  };
  get item(): ToolDynamicFieldViewModel {
    return this._control?.item;
  }
  constructor(private modalService: ModalService,) { }

  ngOnInit() {
  }

  removeImageInput(control: FormControl) {
    var index = this.formArray.controls.indexOf(control);
    if (index >= 0) {
      this.formArray.controls.splice(index, 1);
      this.updateValues();
    }
  }

  addImageInput() {
    var nc = new FormControl(null);
    nc.valueChanges.subscribe(res => {
      this.updateValues();
    })
    this.formArray.controls.push(nc);
  }

  async updateValues() {
    this._control.setValue(null);
    var list = [];
    for (let index = 0; index < this.formArray.controls.length; index++) {
      const c = this.formArray.controls[index];
      // update here
      if (c.value) {
        var file = c.value;
        var body = (await toBase64(file)) as string;
        var fileAsBase64 = body.split(';base64,')[1];
        var fileExtension = getFileExtenstion(file.name);
        list.push({
          fileAsBase64: fileAsBase64,
          fileExtension: fileExtension,
          fileSize: file.size,
          fileName: file.name,
        })
      }
    }

    var value = {
      files : list,
      images : this.images,
    }

    this._control.setValue(value);
  }

  removeImage(image) {
    this.modalService.confirm("Are you sure you want to delete?").subscribe(del => {
      if (del === true) {
        let index = this.images.indexOf(image);
        this.images.splice(index, 1);
      }
    });
  }
}
