<div class="row">
  <div class="col-md-12">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="row">
      <div class="col-12">
        <label>Document Type</label>
        <!--<span *ngIf="documentTypes.length == 0" class="text-success ml-2" style="font-size: 12px;">(There are no more
          document types to add)</span>-->
      </div>
      <div class="col-md-6 col-12">
        <obc-document-type-nested-selector
          [getAllCompaniesDocumentTypes]="true"
          [onlySelectables]="false"
          [isMultiple]="false"
          [ignoreUserDocumentIds]="ignoreDocumentTypeIds"
          [firstLoadedSelectedDocumentTypeIds]="selectedDocumentType"
          (result)="onSelectedDocumentType($event)"
        ></obc-document-type-nested-selector>
      </div>
      <div class="col-md-6 col-12">
        <button type="button" class="btn btn-primary" [disabled]="selectedDocumentType == null"
                (click)="addCompanyDocumentType()">
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive">

          <ng-container *ngIf="companyDocumentTypes?.length > 0">
            <div class="mt-5"></div>
            <obc-document-type-viewer
              [canAdd]="false"
              [canDelete]="true"
              [canUpdate]="false"
              [draggable]="false"
              [documents]="companyDocumentTypes"
              [cacheUniqueId]="'company-document-types-tree'"
              >
            </obc-document-type-viewer>
          </ng-container>


          <div class="mt-4" *ngIf="companyDocumentTypes?.length == 0">
            <div class="text-center" role="alert">
              There are currently not documents specified for {{userService.currentCompany?.title}}.
              <br>
              Select a document type from the drop down and click the + to add it to the options for site
              visitors.
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>
