import { Pipe, PipeTransform } from '@angular/core';
import { KeyValue } from '../models/helper-models';

@Pipe({
  name: 'objectToListKeyValue'
})
export class ObjectToListKeyValuePipe implements PipeTransform {

  transform(obj: any): KeyValue<string, string>[] {
    let list: KeyValue<string, string>[] = [];
    for (let key in obj) {
      list.push({ key: key, value: obj[key] });
    }
    return list;
  }

}
