import { MaxFileSize } from '../../../../enums/constants';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuestionRendererComponent } from '../question-renderer/question-renderer.component';
import { FormBuilder } from '@angular/forms';
import { SiteQuestionItemFormControl, SiteQuestionRendererViewModel } from 'src/app/models/site-question-viewmodel';
import {
  fileSize,
  getFileExtenstion,
  isValidImageFile,
  isValidPDFFile,
  toBase64
} from 'src/app/helpers/general-functions';
import { ModalService } from 'src/app/services/modal.service';
import { FileType } from "../../../../enums/file-type.enum";
import { UploadFileInfo } from "../../../../models/requiredDocumentAnswer";
import { QuestionAnswerFileFormat } from 'src/app/models/questionParameters/question-answer-file-format';
import { SafePipe } from 'src/app/pipes/safe-pipe';
import { DropzoneSourceType } from 'src/app/enums/dropzone-source-type';
import { CacheService } from "../../../../services/cache.service";

@Component({
  selector: 'obc-file-question-renderer',
  templateUrl: './file-question-renderer.component.html',
  styleUrls: [ './file-question-renderer.component.scss' ],
  providers: [
    SafePipe
  ]
})
export class FileQuestionRendererComponent extends QuestionRendererComponent implements OnInit {

  @Input() isEditMode: boolean = false;
  files: File[] = [];
  // displayInitialValues = false;
  @Output('removeItem') removeItemEmitter = new EventEmitter<SiteQuestionRendererViewModel>();

  DropzoneSourceType = DropzoneSourceType;
  displayMimeTypeError: boolean = false;
  parsedInitialBase64AnswersJson: UploadFileInfo[];

  validImageExtentions = [
    '.png', '.jpg', '.jpeg'
  ];
  pdfMimeTypes = [
    'application/pdf'
  ]

  @Input() set question(model: SiteQuestionRendererViewModel) {
    this.model = model;

    let initialAnswer = model.initialAnswers ? model.initialAnswers[0] : null;
    this.parsedInitialBase64AnswersJson = model.lastStateInitialFileAnswers ?? [];

    let initialAnswerValue: string;
    let initialAnswerFileType: string;
    let isNotModified: boolean;
    if(initialAnswer?.isBase64Answer) {
      let parsedAnswer = [];
      try {
        parsedAnswer = JSON.parse(initialAnswer.answer)
      } catch(e){}
      this.parsedInitialBase64AnswersJson = [
        ...parsedAnswer ?? [],
        ...this.parsedInitialBase64AnswersJson,
      ];
    }

    if(this.parsedInitialBase64AnswersJson?.length) {
      let initialAnswerValues: string[] = [];
      let initialAnswerFileTypes: string[] = [];
      this.parsedInitialBase64AnswersJson?.map((item) => {
        initialAnswerValues.push(item.fileAsBase64);
        initialAnswerFileTypes.push(item.fileExtension);
      });
      initialAnswerValue = initialAnswerValues.join('|');
      initialAnswerFileType = initialAnswerFileTypes.join('|');
      isNotModified = false;
    } else {
      initialAnswerValue = initialAnswer?.answer;
      initialAnswerFileType = null;
      isNotModified = this.isEditMode == true;

      if(this.model?.initialAnswers?.length > 0 && (this.model?.initialAnswers[0]?.fileStorageKeyList == null || this.model?.initialAnswers[0]?.fileStorageKeyList == undefined ||
        this.model?.initialAnswers[0]?.fileStorageKeyList?.length == 0)) {
        this.model.initialAnswers[0].fileStorageKeyList = initialAnswer?.answer?.split('|');
      }
    }
    this.formGroup = this.fb.group({
      value: new SiteQuestionItemFormControl(initialAnswerValue, null),
      fileType: new SiteQuestionItemFormControl(initialAnswerFileType, null),
      isNotModified: new SiteQuestionItemFormControl(isNotModified, null),
    });
    this.update().then();
  };

  constructor(private fb: FormBuilder, private modalService: ModalService, public cacheService: CacheService) {
    super(cacheService);
  }

  ngOnInit() {
  }

  removeItem(key: string) {
    this.modalService.confirm('Are you sure you want to delete?').subscribe((res) => {
      if(res == true) {

        // this is only for storage keys in answer
        if(this.model?.initialAnswers?.length > 0) {
          let storageKeyIndex = this.model?.initialAnswers[0]?.fileStorageKeyList?.findIndex((item) => item == key);
          if(storageKeyIndex > -1) {
            this.model?.initialAnswers[0]?.fileUrls?.splice(storageKeyIndex, 1);
            this.model?.initialAnswers[0]?.fileStorageKeyList?.splice(storageKeyIndex, 1);

            let newAnswers = [];
            this.formGroup.controls['value'].value?.split('|').map((item) => {
              let matchedAnswer = item.split(':').find((iS) => iS == key);
              if(!matchedAnswer) {
                newAnswers.push(item);
              }
            });
            this.formGroup.controls['value'].setValue(newAnswers?.length > 0 ? newAnswers?.join("|") : null);
          }

          try {
            window.atob(key);
            this.parsedInitialBase64AnswersJson = this.parsedInitialBase64AnswersJson.filter((bFile) => bFile.fileAsBase64 != key);
            this.formGroup.controls['value'].setValue(this.parsedInitialBase64AnswersJson?.length > 0 ? this.parsedInitialBase64AnswersJson.map((item) => item.fileAsBase64).join("|") : null);
          } catch(e){}
        }

        this.parsedInitialBase64AnswersJson =
          this.parsedInitialBase64AnswersJson?.filter((item) => item.fileAsBase64 != key);

          if(!this.isEditMode)  {
            let filteredOldValue = this.formGroup.controls['value'].value
              ?.split('|')?.filter((item) => item != key)?.join('|');
            this.formGroup.controls['value'].setValue(
              filteredOldValue != '' ? filteredOldValue : null,
            )
          }

        this.formGroup.controls['isNotModified'].setValue(false);

        this.cacheService.formControlDirtySubject.next({questionId: this.model?.question?.questionId});
      }
    });
  }

  onSelectFile(input, $event) {
    this.displayMimeTypeError = false;
    let sizeLimitInMB = this.model?.question.questionParametersAsString?.sizeLimitInMB;
    for (let index = 0; index < $event.target.files.length; index++) {
      const file = $event.target.files[index];

      switch(this.model?.question?.questionParametersAsString?.fileType) {
        case QuestionAnswerFileFormat.Image:
          if(!isValidImageFile(file.type)) {
            this.displayMimeTypeError = true;
            continue;
          }
          break;
        case QuestionAnswerFileFormat.PDF:
          if(!isValidPDFFile(file.type)) {
            this.displayMimeTypeError = true;
            continue;
          }
          break;
      }

      if (file.size > (sizeLimitInMB ?? MaxFileSize) * 1024000) {
        this.modalService.error(`Maximum File Size is ${sizeLimitInMB ?? MaxFileSize}mb`, "").subscribe(_ => {
        });
        $event.target.value = null;
        continue;
      }
      this.files.push(file);
    }

    this.update().then(_ => {});
    setTimeout(() => {
      this.clearForm(input);
    }, 100)
    this.cacheService.formControlDirtySubject.next({questionId: this.model?.question?.questionId});
  }

  remove(index) {
    this.files.splice(index, 1);
    this.update().then(_ => {});
    this.cacheService.formControlDirtySubject.next({questionId: this.model?.question?.questionId});
  }

  async update() {
    let fileBase64s = [];
    let extensions = [];
    for (let index = 0; index < this.files.length; index++) {
      const file = this.files[index];
      if (file == null) continue;
      let base64 = (await toBase64(file)) as string;
      base64 = base64.split(';base64,')[1];
      fileBase64s.push(base64);
      let ext = getFileExtenstion(file.name);
      extensions.push(ext);
    }
    if(this.model?.initialAnswers?.length > 0) {
      if(this.model?.initialAnswers[0]?.fileStorageKeyList?.length > 0)
        fileBase64s = [
          ...fileBase64s,
          ...this.model?.initialAnswers[0].answer.split("|"), //?.fileStorageKeyList,
        ];
    }
    let uniqueBase64s = this.parsedInitialBase64AnswersJson.filter((item) => {
      for (const base64String of fileBase64s) {
        if(base64String == item.fileAsBase64) {
          return false;
        }
      }
      return true;
    });
    if(uniqueBase64s.length > 0) {
      fileBase64s = [
        ...fileBase64s,
        ...uniqueBase64s.map((item) => item.fileAsBase64)
      ]
      extensions = [
        ...extensions,
        ...uniqueBase64s.map((item) => item.fileExtension)
      ]
    }

    this.formGroup?.get('value').setValue(fileBase64s?.length > 0 ? fileBase64s.join("|") : null);
    this.formGroup?.get('fileType').setValue(extensions.join("|"));
    this.formGroup?.get('isNotModified').setValue(false);
  }

  clearForm(element) {
    element.value = "";
  }

  getAccept() {
    switch(this.model?.question?.questionParametersAsString?.fileType) {
      case QuestionAnswerFileFormat.Image:
        return this.validImageExtentions.join(", ");
      case QuestionAnswerFileFormat.AnyFile:
        return '*/*';
      case QuestionAnswerFileFormat.PDF:
        return this.pdfMimeTypes.join(", ");
    }
    return '*/*';
  }

  FileType = FileType;
  totalSize = 0;
  getTotalSize(files) {
    this.totalSize = 0;
    files.map((item) => this.totalSize += item.size);
    return fileSize(this.totalSize);
  }

  base64TotalSize = 0;
  getTotalBase64FileSize(files: UploadFileInfo[]) {
    this.base64TotalSize = 0;
    files?.map((item) => this.base64TotalSize += item.fileSize);
    return fileSize(this.base64TotalSize);
  }
}
