import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CompanyHostModel } from 'src/app/models/quick-setup-models/company-host';
import { MinimalSiteModel } from 'src/app/models/quick-setup-models/minimal-site-model';
import { QuickSetupModel } from 'src/app/models/quick-setup-models/quick-setup-model';
import { LayoutService } from 'src/app/services/layout.service';
import { ModalService } from 'src/app/services/modal.service';
import { SiteService } from 'src/app/services/site.service';

@Component({
  selector: 'obc-quick-setup',
  templateUrl: './quick-setup.component.html',
  styleUrls: ['./quick-setup.component.scss']
})
export class QuickSetupComponent implements OnInit {
  inprogress: boolean = false;
  constructor(private layoutSerivce: LayoutService,
    private modalService : ModalService,
    private router : Router,
    private siteService: SiteService) { }

  ngOnInit() {
    this.layoutSerivce.header = "Quick Setup"
  }

  onQuickSetup(hosts: CompanyHostModel[], psites:Promise<MinimalSiteModel[]>) {
    psites.then(sites=> {
      this.inprogress = true;
      this.siteService.quickSetup({
        hosts: hosts,
        sites: sites,
      } as QuickSetupModel).pipe(finalize(() => this.inprogress = false))
        .subscribe(res => {
          this.router.navigateByUrl('/sites');
        }, err => {
          this.modalService.error(err ?? "Unbale to quick Setup");
          console.log(err);
        })
    })

  }
}
