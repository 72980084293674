import { CheckInApprovalMode } from "../enums/check-in-approval-mode.enum";
import { CompareOperator } from "../enums/compare-operator.enum";
import { LocationOverrideMode } from "../enums/location-override-mode-enum";

export function CompareOperatorFriendlyLabel(operator: CompareOperator) : string {
  switch(operator) {
    case CompareOperator.GreaterThan: return "Greater Than";
    case CompareOperator.GreaterEqualThan: return "Greater Equal Than";
    case CompareOperator.LowerThan: return "Lower Than";
    case CompareOperator.LowerEqualThan: return "Lower Equal Than";
    case CompareOperator.Equal: return "Equal";
    case CompareOperator.Contains: return "Contains";
    default : return CompareOperator[operator];
  }
}

export function getCheckinApprovalModeFriendlyMode(mode?: CheckInApprovalMode) : string {
  switch(mode) {
    case CheckInApprovalMode.Disable: return "Disabled";
    case CheckInApprovalMode.EveryCheckIn: return "Every Check-In";
    case CheckInApprovalMode.NewSupplierDocument: return "New Supplier Document";
    default : return CheckInApprovalMode[mode];
  }
}

export function getcheckInLocationOverrideInKioskModeFriendlyMode(mode?: LocationOverrideMode) : string {
  switch(mode) {
    case LocationOverrideMode.Disabled: return "No";
    case LocationOverrideMode.OverrideNonDisableModesToOptional: return "Yes";
    default : return LocationOverrideMode[mode];
  }
}
