<div class="card">
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="mb-2" [formGroup]="formGroup">
      <div class="card">
        <div class="card-header">
          Add New Category
        </div>
        <div class="card-body">
          <div class="form-group mb-3">
            <label>Title</label>
            <div class="input-group">
              <input class="form-control" formControlName="title" (keydown.enter)="formGroup.valid ? onAdd() : null" />
            </div>
          </div>
          <div class="text-right">
            <button [disabled]="!formGroup.valid" class="btn btn-primary" (click)="onAdd()">Add</button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered fw">
        <thead>
          <tr>
            <th colspan="2">Category</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of toolCategories">
            <tr>
              <td colspan="2"><b>{{item.title}}</b>
                <button class="btn btn-danger pull-right" (click)="onRemove(item.id)">Remove</button>
              </td>
            </tr>
            <tr>
              <td>
                <accordion>
                  <accordion-group [isOpen]="isAccordionOpen(item.id, false)">
                    <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                      <div class="d-flex flex-row justify-content-between">
                        <div class="pull-left float-left">Asset Field(s) </div>
                        <div>{{item.toolDynamicFields?.length}} items</div>
                      </div>
                    </button>
                    <div>
                      <div class="row field-list-header">
                        <div class="col-md-3">
                          Name
                        </div>
                        <div class="col-md-2">
                          Is Required?
                        </div>
                        <div class="col-md-2">
                          Type
                        </div>
                        <div class="col-md-2">
                          Is Barcode?
                        </div>
                        <div class="col-md-2">
                          Use In Search?
                        </div>
                        <div class="col-md-1">
                          <button class="btn btn-info pull-right"
                            (click)="onOpenToolDynamicField(toolDynamicField, item, ToolDynamicFieldUsage.Tool)">+Add</button>
                        </div>
                      </div>
                      <ng-container *ngIf="item.toolDynamicFields?.length">
                        <obc-sortable [(ngModel)]="item.toolDynamicFields" [template]="dynamicFields"
                          (onChangeOrder)="onChangeDynamicFieldOrder(item, $event, false)"></obc-sortable>
                      </ng-container>
                    </div>
                  </accordion-group>
                </accordion>
              </td>
            </tr>
            <tr>
              <td>
                <accordion>
                  <accordion-group [isOpen]="isAccordionOpen(item.id, true)">
                    <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                      <div class="d-flex flex-row justify-content-between">
                        <div class="pull-left float-left">Assignment Field(s) </div>
                        <div>{{item.toolAssignDynamicFields?.length}} items</div>
                      </div>
                    </button>
                    <div>
                      <div class="row field-list-header">
                        <div class="col-md-4">
                          Name
                        </div>
                        <div class="col-md-2">
                          Is Required?
                        </div>
                        <div class="col-md-2">
                          Type
                        </div>
                        <div class="col-md-2">
                          Is Barcode?
                        </div>
                        <div class="col-md-2">
                          <button class="btn btn-info pull-right"
                            (click)="onOpenToolDynamicField(toolDynamicField, item, ToolDynamicFieldUsage.ToolAssign)">+
                            Add</button>
                        </div>
                      </div>
                      <ng-container *ngIf="item.toolAssignDynamicFields?.length">
                        <obc-sortable [(ngModel)]="item.toolAssignDynamicFields" [template]="assignDynamicFields"
                          (onChangeOrder)="onChangeDynamicFieldOrder(item, $event, true)"></obc-sortable>
                      </ng-container>
                    </div>
                  </accordion-group>
                </accordion>
              </td>
            </tr>
            <tr *ngIf="!hideFormOperationPanel">
              <td>
                <accordion>
                  <accordion-group [isOpen]="isAccordionOpen(item.id, true)">
                    <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                      <div class="d-flex flex-row justify-content-between">
                        <div class="pull-left float-left">Operation Form(s) </div>
                        <div>{{item.operationForms?.length}} items</div>
                      </div>
                    </button>
                    <div>
                      <div class="row field-list-header">
                        <div class="col-md-10">
                          Title
                        </div>
                        <div class="col-md-2">
                          <button class="btn btn-info pull-right" (click)="onOpenNewOperationFormModal(item)">+
                            Add</button>
                        </div>
                      </div>
                      <ng-container *ngIf="item.operationForms?.length">
                        <div class="mt-2"></div>
                        <div class="p-1" *ngFor="let oform of item.operationForms">
                          <div class="row">
                            <div class="col-md-10">
                              {{oform.name}}
                            </div>
                            <div class="col-md-2">
                              <button class="btn btn-danger pull-right" (click)="removeForm(item, oform)">Remove</button>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </accordion-group>
                </accordion>
              </td>
            </tr>
            <tr>
              <td class="p-0" colspan="2">
                <hr style="height: 2px; background-color: brown;" class="m-0">
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #toolDynamicField>
  <div class="card">
    <div class="card-header">
      {{toolDynamicFieldformGroup.controls.usage.value == ToolDynamicFieldUsage.ToolAssign ? "Add Tool Assign Field":
      "Add Tool Field"}}
    </div>
    <div class="card-body">
      <div [formGroup]="toolDynamicFieldformGroup">
        <div class="form-group mb-3">
          <div class="input-group">
            <label>
              <input type="radio" [value]="false" class="radio" formControlName="isGlobalField" />
              Define New Field
            </label>
            <label class="ml-3">
              <input type="radio" [value]="true" class="radio" formControlName="isGlobalField" />
              Use Global Field
            </label>
          </div>
        </div>
        <ng-container *ngIf="isGlobalField">
          <div class="form-group mb-3">
            <label>Global Field</label>
            <div>
              <obc-item-selector [itemId]="selectedGlobalToolDynamicField?.id"
                [itemType]="GlobalItemType.GlobalAssetField" [required]="true" [options]="{usage:getUsage()}"
                (onItemSelect)="onGlobalToolDynamicFieldSelect($event)">
              </obc-item-selector>
            </div>
          </div>
        </ng-container>
        <div class="form-group mb-3">
          <label>Field Name</label>
          <div class="input-group">
            <input class="form-control" formControlName="fieldName" />
          </div>
        </div>
        <div class="form-group form-check">
          <input type="checkbox" class="form-check-input" formControlName="isRequired">
          <label class="form-check-label">Is Required</label>
        </div>
        <ng-container *ngIf="!isGlobalField || selectedGlobalToolDynamicField">
          <div class="form-group">
            <label>Type</label>
            <select class="custom-select form-control" formControlName="type" (change)="onToolDynamicFieldTypeChange()"
              [disabled]="isGlobalField">
              <option *ngFor="let index of ToolDynamicFieldType | enumToArray" [ngValue]="ToolDynamicFieldType[index]">
                {{ ToolDynamicFieldType[index] | toolDynamicFieldType }}
              </option>
            </select>
          </div>
          <div *ngIf="toolDynamicFieldformGroup.controls.type.value == ToolDynamicFieldType.Text ||
            toolDynamicFieldformGroup.controls.type.value == ToolDynamicFieldType.Number"
            class="form-group form-check">
            <input type="checkbox" class="form-check-input" formControlName="isBarcode" [disabled]="isGlobalField">
            <label class="form-check-label">Show barcode scanner to read information?</label>
          </div>
          <div *ngIf="toolDynamicFieldformGroup.controls.usage.value == ToolDynamicFieldUsage.Tool &&
                (toolDynamicFieldformGroup.controls.type.value == ToolDynamicFieldType.Text ||
                  toolDynamicFieldformGroup.controls.type.value == ToolDynamicFieldType.Number)"
            class="form-group form-check">
            <input type="checkbox" class="form-check-input" formControlName="isSearchable" [disabled]="isGlobalField">
            <label class="form-check-label">Use in search?</label>
          </div>
          <div *ngIf="toolDynamicFieldformGroup.controls.type.value == ToolDynamicFieldType.Text"
            class="form-group form-check">
            <input type="checkbox" class="form-check-input" formControlName="isLongText" [disabled]="isGlobalField">
            <label class="form-check-label">Is long text?</label>
          </div>

          <div *ngIf="toolDynamicFieldformGroup.controls.type.value == ToolDynamicFieldType.File" class="form-group">
            <label>File Type</label>
            <select class="custom-select form-control" formControlName="fileType" [disabled]="isGlobalField">
              <option [ngValue]="QuestionAnswerFileFormat[type]"
                *ngFor="let type of QuestionAnswerFileFormat | enumToArray">
                {{ QuestionAnswerFileFormat[type] | questionAnswerFileFormat }}
              </option>
            </select>
          </div>
        </ng-container>
        <div class="text-right">
          <button class="btn btn-primary" [disabled]="isAddToolDynamicFieldButtonDisabled"
            (click)="onAddToolDynamicField()">Add</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #assignDynamicFields let-item="item" let-index="index">
  <div class="row">
    <div class="col-md-4">
      {{item.value.fieldName}}{{item.value.isLongText ? ' (Long Text)' : ''}}
    </div>
    <div class="col-md-2">
      {{item.value.isRequired ? "Yes":"No"}}
    </div>
    <div class="col-md-2">
      {{item.value.type | toolDynamicFieldType}}{{item.value.type == ToolDynamicFieldType.File? "("+(item.value.fileType| questionAnswerFileFormat)+")":""}}
    </div>
    <div class="col-md-2">
      {{item.value.isBarcode ? "Yes":"No"}}
    </div>
    <div class="col-md-2">
      <button
        *ngIf="item.initData.type == ToolDynamicFieldType.Number || item.initData.type == ToolDynamicFieldType.Text || item.initData.type == ToolDynamicFieldType.Date"
        (click)="onShowTriggers( item.initData, triggersModal )" class="btn btn-sm btn-info m-1">
        Triggers <span *ngIf="item.initData.triggersCount">[{{item.initData.triggersCount}}]</span>
      </button>
      <button (click)="onRemoveToolDynamicField( item.value.id, ToolDynamicFieldUsage.ToolAssign)"
        class="btn btn-sm btn-danger m-1">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #dynamicFields let-item="item" let-index="index">
  <div class="row">
    <div class="col-md-3">
      {{item.value.fieldName}}{{item.value.isLongText ? ' (Long Text)' : ''}}
    </div>
    <div class="col-md-2">
      {{item.value.isRequired ? "Yes":"No"}}
    </div>
    <div class="col-md-2">
      {{item.value.type | toolDynamicFieldType}}{{item.value.type == ToolDynamicFieldType.File? "("+(item.value.fileType| questionAnswerFileFormat)+")":""}}
    </div>
    <div class="col-md-2">
      {{item.value.isBarcode ? "Yes":"No"}}
    </div>
    <div class="col-md-2">
      {{item.value.isSearchable ? "Yes":"No"}}
    </div>
    <div class="col-md-1">
      <button class="pl-1 pr-1" (click)="onRemoveToolDynamicField(item.value.id, ToolDynamicFieldUsage.Tool)"
        class="btn btn-sm btn-danger">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #triggersModal>
  <div class="card">
    <div class="card-header">
      Add / Update Assignment Field Triggers
      <button type="button" class="close float-right" (click)="onCloseStatusTriggers()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <obc-inventory-dynamic-field-triggers [toolDynamicField]="currentDynamicField">
      </obc-inventory-dynamic-field-triggers>
      <div>
        <button class="btn btn-primary m-1" (click)="onCloseStatusTriggers()">Close</button>
      </div>
    </div>
  </div>
</ng-template>
