import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByProperty'
})
export class FilterByPropertyPipe implements PipeTransform {
  transform(list: any[],value : any, property: string): any {
    if(list==null || list.length==0)
      return list;
    return list.filter(item => {
      return item[property] === value;
    });
  }
}
