<div class="row">
  <div class="col-md-12">
    <div class="card">
      <header class="card-header">
        <h5>Contact Tracing</h5>
        <div class="card-actions">
          <a (click)="result.emit({ isFinished: true, isSucceeded: false })" class="card-action card-action-dismiss"
            data-card-dismiss=""></a>
        </div>
      </header>
      <obc-loader [show]="inProgress"></obc-loader>
      <!-- <obc-descriptor [text]="pageInfo">
      </obc-descriptor>-->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 mt-3">
            <p class="mb-1"><strong>Target User</strong></p>
            <table class="table table-bordered table-striped obc-table">
              <thead>
                <tr>
                  <td>Full Name</td>
                  <td>Mobile</td>
                  <td>Site</td>
                  <td>Check In Date</td>
                  <td>Check Out Date</td>
                  <td></td>
                </tr>
              </thead>
              <tbody *ngIf="selectedModel">
                <tr *ngFor="let item of [selectedModel]">
                  <td>{{item.firstName}} {{item.lastName}}</td>
                  <td>{{item.mobile | mobile}}</td>
                  <td>{{item.site}}
                    <small class="text-danger" *ngIf="item.siteReference"> ({{item.siteReference }}) </small>
                  </td>
                  <td>
                    <div>{{item.checkInDate | datetime}}</div>
                    <div><small>( {{item.checkInForm}} )</small></div>
                  </td>
                  <td>
                    <div>{{item.checkOutDate | datetime}}</div>
                    <div><small *ngIf="item.checkOutForm">( {{item.checkOutForm}} )</small></div>
                  </td>
                  <td>
                    <button class="btn btn-info pull-right"
                      (click)="openModal(questionAnswer,item.siteAttendanceId,true, item.checkOutDate != null )">
                      View Details
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12  mt-3">
            <p class="mb-1"><strong>All users who had contact with the target user on {{selectedModel.site}}</strong>
            </p>
            <table class="table table-bordered table-striped obc-table">
              <thead>
                <tr>
                  <td>Full Name</td>
                  <td>Mobile</td>
                  <td>Check In Date</td>
                  <td>Check Out Date</td>
                  <td></td>
                </tr>
                <tr>
                  <td colspan="6">
                    <input type="text" class="form-control"
                      placeholder="Filter Result by Mobile, First Name, Last Name, Site" [(ngModel)]="filterListTerm" />
                  </td>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="report && report.length > 0">
                  <tr *ngFor="let item of (report | filter:filterListTerm:['mobile','firstName','lastName','site'])">
                    <td>{{item.firstName}} {{item.lastName}}</td>
                    <td>{{item.mobile | mobile}}</td>
                    <td>
                      <div>{{item.checkInDate | datetime}}</div>
                      <div><small>( {{item.checkInForm}} )</small></div>
                    </td>
                    <td>
                      <div>{{item.checkOutDate | datetime}}</div>
                      <div><small *ngIf="item.checkOutForm">( {{item.checkOutForm}} )</small></div>
                    </td>
                    <td>
                      <button class="btn btn-info pull-right"
                        (click)="openModal(questionAnswer,item.siteAttendanceId,true, item.checkOutDate != null )">
                        View Details
                      </button>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="report == null">
                  <tr>
                    <td colspan="6">
                      <p style="text-align: center;">Searching</p>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="report != null && report.length == 0">
                  <tr>
                    <td colspan="6">
                      <p style="text-align: center;">No Contact Found!</p>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="col-md-12" style="text-align: end;">
            <button type="button" class="btn btn-success" (click)="exportAsCSV()"><i class="fas fa-file-excel-o"></i>
              Export As CSV</button>
            <button *ngIf="false" type="button" class="mb-1 mt-1 mr-1 btn btn-info"><i class="fas fa-file-pdf-o"></i>
              Export As PDF</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #questionAnswer>
    <obc-question-answer [questionAnswerInput]="questionAnswerInputModel" (result)="closeModal($event)">
    </obc-question-answer>
  </ng-template>
