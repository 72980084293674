<div>
  <obc-loader [show]="isLoading"></obc-loader>
  <div *ngIf="!isLoading" class="card">
    <div class="card-header">Evacuation Setting</div>
    <div class="card-body">
      <form [formGroup]="EvacuationSettingFormControl">
        <div class="form-group">
          <label>Default Message Mode:</label>
          <label class="radio-inline ml-3">
            <input type="radio" [value]="MessageType.SMS"
              [formControl]="EvacuationSettingFormControl.controls.evacuationMessageType" name="selector">
            <b class="ml-1">
              SMS
            </b>
          </label>
          <label class="radio-inline ml-3">
            <input type="radio" [value]="MessageType.PushNotification"
              [formControl]="EvacuationSettingFormControl.controls.evacuationMessageType" name="selector">
            <b class="ml-1">
              Push Notification
            </b>
          </label>
        </div>
        <div class="form-group">
          <label class="d-inline-block mt-2 mr-1">
            By Default
            <strong>Send Site Alert </strong>
            Is
          </label>
          <obc-switch [value]="evacuationSetting?.evacuationIsByDefaultOn"
            (valueChanged)="EvacuationSettingFormControl.controls.evacuationIsByDefaultOn.setValue($event)"
            [trueLabel]="' On '" [falseLabel]="' Off '">
          </obc-switch>
        </div>
        <div class="form-group">
          <label class="d-inline-block mt-2 mr-1">Default SMS Content</label>
          <textarea class="form-control" formControlName="evacuationDefaultSMSMessage" placeholder="Default SMS Content"
            rows="3"></textarea>
        </div>
        <div class="form-group">
          <label class="d-inline-block mt-2 mr-1">Default Push Notification Content</label>
          <textarea type="multiline" class="form-control" formControlName="evacuationDefaultNotificationMessage"
            placeholder="Default Push Notification Content" rows="3">
          </textarea>
        </div>
        <button type="button" class="btn btn-primary mt-3 pull-right" (click)="updateEvacuation()">
          Save Changes
        </button>
      </form>
    </div>
  </div>
</div>