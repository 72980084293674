import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SiteAttendanceQuestionAnswer } from 'src/app/models/attendance-request/site-attendance-question-answer';
import { CustomFormControl } from 'src/app/models/custom-formcontrol';
import { FormDataViewModel } from 'src/app/models/form/form-data-view-model';
import { FormDataModel } from 'src/app/models/form/form-data-model';
import { RequiredDocumentAnswer } from 'src/app/models/requiredDocumentAnswer';
import { FormService } from 'src/app/services/form.service';
import { ModalService } from 'src/app/services/modal.service';
import { FormType } from "../../../enums/form-type";
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { SupplierPermitsService } from "../../../services/supplier-permits.service";
import { RequiredDocumentViewModel, UserDocumentType } from "../../../models/user-document-type";
import {
  convertDocumentToFormControl, detectExtension,
  isDateValid,
  isQuestionIsVisibleByCheckingConditions,
  setDocumentFormControlForQuestions
} from 'src/app/helpers/general-functions';
import { PermitFormInfoModel, PermitScheduleModel } from "../../../models/form/permits-info-model";
import { SupplierFormService } from "../../../services/supplier-form.service";
import { InternalAnnouncementVisitViewModel } from 'src/app/models/announcement-viewmodel';
import { finalize, pairwise } from "rxjs/operators";
import { UserDocumentTypeService } from "../../../services/user-document-type.service";
import { SiteAssetService } from 'src/app/services/site-asset.service';
import { UniqueGeneralAnswerModel } from 'src/app/models/form/general-answer-model';
import { SiteQuestionAnswerViewModel } from 'src/app/models/site_question_answer_view_model';
import { FilledFormRequestModel } from "../../../models/form/filled-form-request-model";
import { FormApplyFor } from "../../../enums/form-apply-for";
import { FormRegisterFor } from "../../../enums/form-register-for";
import { FillFormMode } from "../../../enums/fill-form-mode";
import { AssetOperatorMode } from "../../../enums/asset-operator-mode.enum";
import { EditFormInformationModel } from "../../../models/form/edit-form-information-model";
import { AnnouncementService } from "../../../services/announcement.service";
import { AnnouncementVisitModel, VisitStatus } from "../../../models/announcement-visit";
import { DocumentViewModel } from "../../../models/document-viewmodel";
import { ObcTime } from "../../../models/obc-time";
import { parseUTCDateTimeToLocalDateTime } from 'src/app/helpers/date-time-format';
import { SiteAnnouncementForceViewMode } from "../../../enums/site-announcement-force-view-mode";
import { VariableDefinitionType, VariableValueDefinition } from 'src/app/models/workflow/variable-difinition';
import { WorkflowInfoViewModel } from 'src/app/models/workflow/workflow-info-viewmodel';

@Component({
  selector: 'obc-form-data-renderer',
  templateUrl: './form-data-renderer.component.html',
  styleUrls: ['./form-data-renderer.component.scss']
})
export class FormDataRendererComponent implements OnInit {
  @Input() workflowModel: WorkflowInfoViewModel;

  @Input() innerSubmitButton: boolean = false;
  @Input() innerSubmitButtonText: string = 'Submit';
  @Output() innerSubmitButtonCallback = new EventEmitter<FormDataModel>();
  @Output() editFormInformationModel = new EventEmitter<EditFormInformationModel>();

  VariableDefinitionType = VariableDefinitionType;

  endTime: ObcTime;
  startTime: ObcTime;

  innerSubmitButtonMethod() {
    this.validateAnswers();
    if (this.isFormValid) {
      this.innerSubmitButtonCallback.emit(this.getFormResponse());
    }
  }

  @Output() public validate = new EventEmitter<boolean>();
  getFormsTimeoutHandler: any;
  toolId: number;


  _formId: number;
  @Input() set formId(value: number) {
    if (this._formId != value) {
      this._formId = value;
      if (this.getFormsTimeoutHandler)
        clearTimeout(this.getFormsTimeoutHandler);

      //Timeout to ensure site id has been set
      this.getFormsTimeoutHandler = setTimeout(() => {
        this.getForm();
      }, 500);
    }
  };

  get formId() {
    return this._formId;
  }

  _siteId: number;
  @Input() set siteId(value: number) {
    this._siteId = value;
    if (this._formId) {
      if (this.getFormsTimeoutHandler)
        clearTimeout(this.getFormsTimeoutHandler);
      this.getForm();
    }
  }

  get siteId() {
    return this._siteId;
  }

  _filledFormDataId: number;
  @Input() set filledFormDataId(value: number) {
    this._filledFormDataId = value;
    if (this._filledFormDataId) {
      if (this.getFormsTimeoutHandler)
        clearTimeout(this.getFormsTimeoutHandler);
      this.getForm();
    }
  }

  get filledFormDataId() {
    return this._filledFormDataId;
  }

  get isEditMode() {
    return this.filledFormDataId;
  }

  get validFormRegisterForValues() {
    let validFormRegisterForValues = [];
    if ((this.form?.applyFor == FormApplyFor.AnyPerson ||
      this.form?.applyFor == FormApplyFor.Supplier) &&
      this.form?.draftFilledForm?.supplierId != null
    )
      validFormRegisterForValues.push(FormRegisterFor.Supplier);

    if ((this.form?.applyFor == FormApplyFor.AnyPerson ||
      this.form?.applyFor == FormApplyFor.Individual) &&
      this.form?.draftFilledForm?.siteAttendanceId &&
      this.form?.draftFilledForm?.userId
    )
      validFormRegisterForValues.push(FormRegisterFor.MySelf);

    return validFormRegisterForValues;
  }

  setFormRegisterFor() {
    this.registerFor?.setValue(this.form?.draftFilledForm?.registerFor != null &&
      this.validFormRegisterForValues?.includes(this.form?.draftFilledForm?.registerFor)
      ? this.form?.draftFilledForm?.registerFor
      : this.validFormRegisterForValues[0] ?? null);
  }


  @Input() set allParameters(value: { formId: number, siteId: number, hashLink?: string, toolId?: number }) {
    this._formId = value.formId ?? this._formId;
    this._siteId = value.siteId ?? this.siteId;
    this.hashLink = value.hashLink ?? this.hashLink;
    this.toolId = value.toolId ?? this.toolId;
    if (this.getFormsTimeoutHandler)
      clearTimeout(this.getFormsTimeoutHandler);
    this.getForm();
  }

  _supplierId: number;
  @Input() set supplierId(value: number) {
    this._supplierId = value;
  }

  get supplierId() {
    return this._supplierId;
  }

  _siteSupplierId: number;
  @Input() set siteSupplierId(value: number) {
    this._siteSupplierId = value;
  }

  get siteSupplierId() {
    return this._siteSupplierId;
  }

  _formName: string;
  @Input() set formName(value: string) {
    this._formName = value ?? '';
  }

  get formName() {
    return this._formName;
  }

  _formType: FormType;
  @Input() set formType(value: FormType) {
    this._formType = value;
  }

  get formType() {
    return this._formType;
  }

  @Input() hashLink: string;

  @Input() displayCloseBtn: boolean = false;

  onCloseButton() {
    this.onCloseButtonClicked.emit(true);
  }

  @Output() onCloseButtonClicked = new EventEmitter<boolean>();


  _notifyVisits: boolean;
  @Input() set notifyVisits(value: boolean) {
    this._notifyVisits = value ?? false;
  }

  get notifyVisits() {
    return this._notifyVisits;
  }

  _anonymousAttendanceKey: string;
  @Input() set anonymousAttendanceKey(value: string) {
    this._anonymousAttendanceKey = value;
  }

  get anonymousAttendanceKey() {
    return this._anonymousAttendanceKey;
  }

  @Input() initialQuestionAnswers: UniqueGeneralAnswerModel[];

  previewRenderer: boolean = false;

  _previewFormData: FormDataViewModel;
  get previewFormData(): FormDataViewModel {
    return this._previewFormData;
  }

  @Input() set previewFormData(value: FormDataViewModel) {
    if (value?.type != null && value?.type != undefined) {
      this._previewFormData = value;
      this.previewRenderer = true;
      this.getForm();
    } else {
      this.previewRenderer = false;
      this._previewFormData = undefined;
    }
  }

  today = new Date();

  DatetimePickerMode = DatetimePickerMode;
  FormTypeEnum = FormType;
  minStartDate: Date;
  startDate: FormControl = new FormControl(null);
  endDate: FormControl = new FormControl(null);
  versionDate: FormControl = new FormControl(null);
  registerFor: FormControl = new FormControl(FormRegisterFor.Supplier);

  endMinAvailableDate: Date = new Date();
  startMaxAvailableDate: Date = new Date();

  inProgress: boolean = false;
  form: FormDataViewModel;
  formResponse: FormDataModel;

  questionContainerValidationSubscription: Subscription = null;
  questionContainerValid: boolean = true;
  questionContainerValidation$: BehaviorSubject<{ valid: boolean, answers: SiteAttendanceQuestionAnswer[] }>
    = new BehaviorSubject<{ valid: boolean, answers: SiteAttendanceQuestionAnswer[] }>({ valid: false, answers: [] });
  requiredDocumentFormControls: CustomFormControl[];
  requiredDocumentFormArray: FormArray;


  permitSchedulesValidationResult: boolean = true;
  permitSchedules: PermitScheduleModel[] = [];

  zoneControl: FormControl = new FormControl(null);

  allUserDocumentTypes: UserDocumentType[];
  availableDocumentTypes: UserDocumentType[];

  formQueryToFillFormMode() {
    let fillFormMap = {};
    fillFormMap[FormType.Generic] = FillFormMode.General;
    fillFormMap[FormType.Permit] = FillFormMode.General;
    fillFormMap[FormType.AssetRegistration] = FillFormMode.AssetRegistration;
    fillFormMap[FormType.AssetOperator] = FillFormMode.AssetOperator;
    fillFormMap[FormType.AssetOperation] = FillFormMode.AssetOperation;
    fillFormMap[FormType.SupplierDocumentReview] = FillFormMode.SupplierDocument;

    return fillFormMap[this.formType] ?? fillFormMap[FormType.Generic];
  }

  constructor(private formService: FormService,
    private supplierFormService: SupplierFormService,
    private supplierPermitService: SupplierPermitsService,
    private modalService: ModalService,
    private userDocumentTypeService: UserDocumentTypeService,
    private siteAssetService: SiteAssetService,
    private announcementService: AnnouncementService,
  ) {
  }


  ngOnInit(): void {
    this.resetDates();
    this.inProgress = true;
    this.resetFormResponse();
    //questions
    this.questionContainerValidationSubscription = this.questionContainerValidation$.subscribe((res) => {
      if (!this?.form?.questions?.length) {
        this.questionContainerValid = true;
        this.validateAnswers();
        return;
      }
      this.questionContainerValid = res.valid;
      this.formResponse.questionAnswers = res.answers;

      // update draftFilledForm with all new non-file answers.
      // we're doing this to prevent resetting non-file answers when ResetAll() calls.
      // let oldFileQuestionAnswers = this.form?.draftFilledForm?.questionAnswers?.filter(q => q.questionType == QuestionType.File || q.questionType == QuestionType.SignaturePad);
      // let nonFileQuestionIds = this.form?.questions?.filter((q: SiteQuestionViewModel) => q.questionType != QuestionType.File && q.questionType != QuestionType.SignaturePad)
      //   ?.map(q => q.questionId);
      // let newNonFileQuestionAnswers =
      //   res.answers?.filter(qa => nonFileQuestionIds.includes(qa.questionId));
      // this.form.draftFilledForm.questionAnswers = [ ...oldFileQuestionAnswers, ...newNonFileQuestionAnswers ];
      this.validateAnswers();
    });

    this.inProgress = false;
  }

  resetDates() {
    this.today.setHours(0, 0, 0, 0);
    let minStartDate = this.form?.permitFormInfo?.minStartDate;
    let startDate = minStartDate && new Date(minStartDate) > this.today ? new Date(minStartDate) : new Date(this.today);

    if (this.formType == FormType.Permit && !this.isEditMode) {
      this.startDate.setValue(startDate);

      this.startDate?.setValidators(Validators.required);
    }
    if (!this.isEditMode && (this.formType == FormType.Permit || (this.formType == FormType.SupplierForm && this.form?.isEnableSupplierFormExpiryDate))) {
      let endDate = new Date(startDate);
      endDate.setHours(23, 59, 59);
      this.endDate.setValue(endDate);
      this.endDate?.setValidators(Validators.required);
    }

    this.setStartAndEndTimes();

    if (this.formType == FormType.SupplierForm) {
      this.versionDate.setValue(new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()));
      this.versionDate?.setValidators(Validators.required);
    }

    this.setMinAndMaxAvailableDates();
  }

  resetDatesInResetAll: boolean = true;
  resetFormResponse() {
    this.requiredDocumentFormControls = [];
    this.formResponse = {
      documentAnswers: [],
      questionAnswers: [],
      isValid: false,
      formId: this._formId,
      startDate: null,
      endDate: null,
      versionDate: null,
      supplierId: this.supplierId,
      siteId: this.siteId,
    } as FormDataModel;
    if (this.resetDatesInResetAll)
      this.resetDates();
  }

  getForm() {
    if (this.getFormsTimeoutHandler)
      clearTimeout(this.getFormsTimeoutHandler);

    this.userDocumentTypeService.getAll()
      .pipe(finalize(() => this.inProgress = false))
      .subscribe((res) => {
        this.allUserDocumentTypes = res;
        this.refreshDocuments();

        if (this.previewRenderer === true) {
          this.form = this.previewFormData;

          this.initializeFormSpecificControls();
          this.formName = this.form.formName;

          this.form.additionalDocumentConfig = this.previewFormData?.additionalDocumentConfig ?? {
            allowUsersToAttachAdditionalDocuments: true,
            order: 999999
          };
          this.documents = this.form.documents;

          if (this.form?.questions?.length > 0) {
            this.form.questions = setDocumentFormControlForQuestions(this.form.questions);
          }

          this.refreshDocuments();

        } else {
          this.inProgress = true;
          let service = new Observable<any>();
          
          if (!this.filledFormDataId) {
            if (this.formType == FormType.Permit)
              service = this.supplierPermitService.getFormInformation(this._formId, this.siteId, this.hashLink, this.siteSupplierId, this.toolId);
            else if (this.formType == FormType.SupplierForm && this.hashLink?.length)
              service = this.supplierFormService.getFormsDetailsToFill(this._formId, this.hashLink, this.toolId);
            else if (this.formType == FormType.AssetRegistration && this.hashLink?.length)
              service = this.siteAssetService.getFormViewmodel(this._formId, this.hashLink, this.toolId);
            else if (this.formType == FormType.AssetOperator && this.hashLink?.length)
              service = this.siteAssetService.getFormViewmodel(this._formId, this.hashLink, this.toolId, this.siteId);
            else
              service = this.formService.getFillFormViewModel(this._formId, this.siteId, this.toolId);
          }
          else
            service = this.formService.getFilledFormViewModel({ filledFormDataId: this.filledFormDataId } as FilledFormRequestModel);

            service.subscribe(
            res => {
              if (res.success) {

                this.form = res.data;

                this.minStartDate = new Date(this.form.permitFormInfo.minStartDate);
                if (this.form?.draftFilledForm != null) {
                  // remove answers that their question is removed from form
                  this.form.draftFilledForm.questionAnswers = this.form?.draftFilledForm?.questionAnswers
                    ?.filter((qA) => this.form?.questions?.find((formQ) =>
                      formQ.questionId == qA.questionId));

                  // add those documents which there is not a document in document list for them, as
                  // additional document to documents list.
                  let removedDocumentAnswers: DocumentViewModel[] = this.form?.draftFilledForm?.documents
                    ?.filter((dA) => !this.form?.documents?.find((formD) =>
                      formD.documentType?.id == dA.documentTypeId)) ?? [];
                  if (removedDocumentAnswers.length > 0) {
                    removedDocumentAnswers.map((item) => {
                      let matchedDocumentType: UserDocumentType = this.allUserDocumentTypes?.find((userDocumentType) => userDocumentType.id == item.documentTypeId);
                      if (matchedDocumentType) {
                        this.form?.documents?.push({
                          documentType: matchedDocumentType,
                          documentTypes: [matchedDocumentType],
                          additionalDocument: true,
                        } as RequiredDocumentViewModel);
                      }
                    })
                  }
                }

                if (this.isEditMode) {
                  this.initializeFormSpecificControls();
                }

                this.form.additionalDocumentConfig = res.data?.additionalDocumentConfig ?? {
                  allowUsersToAttachAdditionalDocuments: true,
                  order: 999999
                };
                this.documents = this.form.documents;

                if (this.form?.questions?.length > 0) {
                  this.form.questions = setDocumentFormControlForQuestions(this.form.questions);
                }

                this.refreshDocuments();

                if (!this.isEditMode && this.form?.questions?.length && (this.initialQuestionAnswers?.length || this.form?.defaultQuestionAnswers?.length)) {
                  if (!this.form?.draftFilledForm)
                    this.form!.draftFilledForm = {
                      questionAnswers: []
                    };

                  if (this.initialQuestionAnswers) {
                    this.form.draftFilledForm.questionAnswers = this.form?.questions
                      .filter((q) =>
                        // q.questionType != QuestionType.File &&
                        q.uniqueKey?.length &&
                        this.initialQuestionAnswers?.some((element) => element.uniqueKey == q.uniqueKey))
                      .map((e) => {

                        let answer = this.initialQuestionAnswers
                          ?.filter((element) => element.uniqueKey == e.uniqueKey)[0];
                        if (answer) {
                          // I hate anybody that use 'any' variable type; and can avoid it!
                          return {
                            questionId: e.questionId,
                            answer: answer?.answer?.answer,
                            isBase64Answer: answer?.answer?.isBase64Answer,
                            //files: answer?.value?.files,
                          } as SiteQuestionAnswerViewModel;
                        }
                        return null;
                      })
                      ?.filter(e => e != null) ?? [];
                  }

                  if (this.form.defaultQuestionAnswers) {
                    this.form.draftFilledForm.questionAnswers = [
                      ...this.form.draftFilledForm.questionAnswers,
                      ...this.form?.questions
                        .filter((q) =>
                          this.form.defaultQuestionAnswers?.some((element) => element.questionId == q.questionId))
                        .map((e) => {
                          let answer = this.form.defaultQuestionAnswers
                            ?.find((element) => element.questionId == e.questionId);
                          if (answer) {
                            return {
                              questionId: e.questionId,
                              answer: answer?.answer,
                              fileUrls: answer?.fileUrls,
                              fileUrl: answer?.fileUrls?.length > 0 ? answer?.fileUrls[0] : null,
                              fileStorageKeyList: answer?.fileStorageKeyList,
                              questionType: answer?.questionType,
                              isBase64Answer: false,
                            } as SiteQuestionAnswerViewModel;
                          }
                          return null;
                        })
                        .filter(e => e != null) ?? [],
                    ];
                  }
                }

              } else {
                this.modalService.error(res.message);
              }
            }, _ => {
              this.modalService.error("Request Failed");
            }, () => {
              this.ResetAll();
              this.inProgress = false;
            }
          );
        }
      });
  }

  initializeFormSpecificControls() {
    this.formType = this.form?.type;
    this.formName = this.form?.draftFilledForm?.formName;
    //this.supplierId = this.form?.draftFilledForm?.supplierId;
    this.notifyVisits = false;
    this._formId = this.form?.draftFilledForm?.formId;
    this._siteId = this.form?.draftFilledForm?.siteId;

    if (this.form?.permitFormInfo?.minStartDate != null) {
      this.minStartDate = parseUTCDateTimeToLocalDateTime(this.form?.permitFormInfo?.minStartDate);
    }

    if (this.form?.draftFilledForm?.startDate != null) {
      this.startDate.setValue(parseUTCDateTimeToLocalDateTime(this.form?.draftFilledForm?.startDate));
    }

    if (this.form?.draftFilledForm?.endDate != null) {
      this.endDate.setValue(parseUTCDateTimeToLocalDateTime(this.form?.draftFilledForm?.endDate));
    }

    this.endTime = FormDataRendererComponent.getTimeOutOfDate(this.endDate?.value);
    this.startTime = FormDataRendererComponent.getTimeOutOfDate(this.startDate?.value);


    this.setDocumentAnswers();

    // this.versionDate.setValue(this.form?.draftFilledForm?.endDate);
    this.permitSchedules = this.form?.draftFilledForm?.schedules?.map(function (item) {
      return {
        weekDay: item.weekDay,
        dateTime: item.dateTime,
        fromHour: item.fromHour?.toString(),
        toHour: item.toHour?.toString(),
      } as PermitScheduleModel;
    }
    );
    this.zoneControl.setValue(this.form?.draftFilledForm?.zoneId);
    this.setFormRegisterFor();
  }

  ResetAll() {
    this.resetFormResponse();
    let res = convertDocumentToFormControl(this.documents, (this.form?.questions?.filter(question => question.documentFormControl != null)?.map(question => question.documentFormControl) ?? []));
    this.requiredDocumentFormArray = res?.formArray;
    this.requiredDocumentFormControls = res?.controls;
    this.requiredDocumentFormArray?.controls.forEach(c => c.valueChanges.pipe(pairwise()).subscribe(([prev, next]: [RequiredDocumentAnswer, any]) => {
      this.validateAnswers();
      if (prev != null) {
        // if user clicks on × button and removed the filled input type,
        // then prev != null and prev.fileAsBase64 == null
        if (prev.filaAsBase64 == null) {
          this.removeFromDocumentAnswers(prev?.documentTypeId, prev?.questionId);
        }
      }
    }));
    this.resetDatesInResetAll = true;
  }


  setMinAndMaxAvailableDates() {
    if (isDateValid(this.startDate)) {
      this.endMinAvailableDate = new Date(this.startDate.value.getTime());
      this.endMinAvailableDate.setHours(0, 0, 0, 0);
    }
    if (isDateValid(this.endDate)) {
      this.startMaxAvailableDate = new Date(this.endDate.value.getTime());
      this.startMaxAvailableDate.setHours(0, 0, 0, 0);
    }
  }

  isUnvisitedMandatoryAnnouncements() {
    return this.form?.announcements?.some(s => (s.forceViewMode == SiteAnnouncementForceViewMode.MandatoryView ||
      s.forceViewMode == SiteAnnouncementForceViewMode.SetPlaythroughToMandatory ||
      s.forceViewMode == SiteAnnouncementForceViewMode.MandatoryViewAndSignOff
    ) && !(s as InternalAnnouncementVisitViewModel)?.viewed) ?? false;
  }

  isFormValid: boolean = false;

  validateAnswers(isRecall = 0) {

    this.formResponse.isValid = this.isValid();
    if (this.formType == FormType.Permit) {
      this.isFormValid = this.formResponse.isValid &&
        isDateValid(this.startDate) &&
        isDateValid(this.endDate) &&
        this.permitSchedulesValidationResult &&
        this.startDate.value <= this.endDate.value &&
        !this.isUnvisitedMandatoryAnnouncements();
      this.validate.emit(this.isFormValid);
    } else if (this.formType == FormType.SupplierForm) {
      this.isFormValid = this.formResponse.isValid &&
        isDateValid(this.versionDate) &&
        (!this.form?.isEnableSupplierFormExpiryDate ||
          (this.form?.isEnableSupplierFormExpiryDate && isDateValid(this.endDate))) &&
        !this.isUnvisitedMandatoryAnnouncements();
      this.validate.emit(this.isFormValid);
    } else {
      this.isFormValid = this.formResponse.isValid && !this.isUnvisitedMandatoryAnnouncements();
      this.validate.emit(this.isFormValid);
    }

    if (!isRecall) {
      setTimeout(() => {
        this.validateAnswers(1);
      }, 500);
    }
  }

  isDocumentVisible(docFormControl: CustomFormControl): boolean {
    let doc = docFormControl.item as RequiredDocumentViewModel;
    if (doc.questionId == null)
      return true;

    // #####  During refactore code, I have to read old version (q.questionId)! PLEASE AVOID (any) VARIABLE TYPE!!!!!      ####

    let question = this.form.questions.find(q => q.questionId == doc.questionId);
    return isQuestionIsVisibleByCheckingConditions(question,
      this.form.questions,
      this.formResponse.questionAnswers);
  }

  private isValid() {
    let isValidDocuments = this.requiredDocumentFormArray == null ||
      this.requiredDocumentFormArray.controls.every(c => {
        let control = (c as CustomFormControl);
        let doc = control.item as RequiredDocumentViewModel;

        let isValid: boolean;
        let isDocInInitialAnswers = this.initialDocumentAnswers?.find((item) =>
          item.questionId == null &&
          item.documentTypeId == doc.documentType?.id);

        if (isDocInInitialAnswers && doc.isDirty != true)
          isValid = true;
        else {
          isValid = doc.questionId == null ? c.valid : true;
        }
        return isValid;
      });

    //let isValidDocuments = !this.requiredDocumentFormArray?.controls?.map(x =>x. x.valid)?.some(x => x == false);
    let isValidQuestions = this.questionContainerValid;
    return isValidDocuments && isValidQuestions;
  }


  initialDocumentAnswers: RequiredDocumentAnswer[] = [];

  setDocumentAnswers() {
    this.initialDocumentAnswers = this.form?.draftFilledForm?.documents?.map((item) => {
      return {
        title: item?.documentTitle,
        documentTypeId: item?.documentTypeId,
        additionalFileUrls: item?.additionalFileUrls ?? [],
        expireDate: item?.expireDate,
        userDocumentId: item?.userDocumentId,
      } as RequiredDocumentAnswer
    });

    this.form?.draftFilledForm?.questionAnswers?.filter((item: SiteQuestionAnswerViewModel) => item.userDocumentId != null)?.map((innerItem: SiteQuestionAnswerViewModel) => {
      let question = this.form?.questions?.find(q => q.questionId == innerItem.questionId);
      let matchedDocumentType = question?.document?.documentTypes?.find((docType) => docType.id == innerItem.documentTypeId);
      if (matchedDocumentType) {
        question.documentTypeId = matchedDocumentType.id;
        if (question.document)
          question.document.documentType = matchedDocumentType;
        let foundItem = this.initialDocumentAnswers.find(ida => ida.documentTypeId == innerItem?.documentTypeId && ida.questionId == innerItem?.questionId);
        if (!foundItem) {
          this.initialDocumentAnswers.push({
            title: innerItem?.documentTitle,
            documentTypeId: innerItem?.documentTypeId,
            additionalFileUrls: innerItem?.fileUrls ?? [],
            expireDate: innerItem?.documentExpireDate,
            userDocumentId: innerItem?.userDocumentId,
            questionId: innerItem?.questionId,
            fileType: detectExtension(innerItem?.fileUrl),
            inductionId: innerItem?.inductionId,
          } as RequiredDocumentAnswer);
        }
      }
    });
  }

  private getDocumentAnswers() {

    // user input answers
    let userInputList = this.requiredDocumentFormArray?.controls?.map(
      (formControl) => {
        let form = formControl as CustomFormControl;
        return form.value as RequiredDocumentAnswer;
      }
    )?.filter(doc => doc != null);

    // merge old answers and user input answers together.
    // if there is a new answer, ignore the old answer, otherwise put old answer inside answers list
    let finalDocumentAnswerList = [
      ...userInputList,
    ];
    this.initialDocumentAnswers?.map((item) => {
      let relatedUserInputAnswer = userInputList.find((userInputAnswer) => userInputAnswer.documentTypeId == item.documentTypeId &&
        userInputAnswer.questionId == item.questionId
      );
      let relatedRemovedDocument: boolean = false;
      if (relatedUserInputAnswer == null) {
        finalDocumentAnswerList.push(item);
      }
    });

    return finalDocumentAnswerList;
  }

  public getFormResponse() {
    this.formResponse.formId = this.formId;
    this.formResponse.filledFormDataId = this.filledFormDataId;
    this.formResponse.documentAnswers = this.getDocumentAnswers();
    this.formResponse.registerFor = this.registerFor.value;
    this.formResponse.siteAttendanceId = this.form?.draftFilledForm?.siteAttendanceId;
    this.formResponse.versionDate = this.versionDate.value != null ?
      (this.versionDate.value.getFullYear() + '/' + (this.versionDate.value.getMonth() + 1) + '/' + this.versionDate.value.getDate()) : null;

    this.setStartAndEndTimes();
    this.formResponse.startDate = this.startDate?.value ?? null;
    this.formResponse.endDate = this.endDate?.value ?? null;
    this.formResponse.permitFormInfo = {
      permitSchedules: this.permitSchedules,
      permitZoneId: this.zoneControl?.value
    } as PermitFormInfoModel;

    // in editing permit forms
    if (this.form?.type == FormType.Permit) {
      this.formResponse.supplierId = this.registerFor.value == FormRegisterFor.Supplier
        ? this.form?.draftFilledForm?.supplierId : null;
    } else {
      if (this.form?.draftFilledForm?.supplierId && this.isEditMode)
        this.formResponse.supplierId = this.form?.draftFilledForm?.supplierId;
    }

    this.formResponse.siteId = this.siteId;
    this.editFormInformationModel.emit({
      fillFormMode: this.formQueryToFillFormMode(),
      operatorMode: this.operatorMode,
      draft: this.form?.draftFilledForm,
    } as EditFormInformationModel);
    return this.formResponse;
  }


  updatePermitSchedulesValidationResult(value) {
    this.permitSchedulesValidationResult = value;
    this.validateAnswers();
  }

  updatePermitSchedules(value) {
    this.permitSchedules = value;
  }


  onAnnouncementVisit(announcementId) {

    if (this.hashLink == null || this.hashLink == '') {
      this.inProgress = true;
      this.announcementService.NotifyAnnouncementVisit({
        formId: this.form?.draftFilledForm?.formId,
        announcementId: announcementId,
        isSupplierAnnouncement: this.form?.draftFilledForm?.isSupplierForm,
        visitStatus: VisitStatus.Full,
        siteAttendanceId: this.form?.draftFilledForm?.siteAttendanceId,
        isPreCheckIn: false,
      } as AnnouncementVisitModel)
        .pipe(finalize(() => {
          this.inProgress = false;
        }))
        .subscribe(res => {
          if (!res) {
            this.modalService.error("Announcement Visit Failed");
          }
        })
    }
    this.validateAnswers();
  }


  documents: RequiredDocumentViewModel[] = [];

  addSelectedDocumentToRequiredDocuments(documentType) {
    let baseOrder = (this.form?.additionalDocumentConfig?.order ?? 1000) + (this.documents?.filter(d => d.additionalDocument).length ?? 0);
    baseOrder = baseOrder - 100;//To Add All Additional Docs before [ADD] button

    if (documentType) {
      this.documents = [...this.documents, {
        documentType: documentType,
        documentTypes: [documentType],
        displayOrder: baseOrder,
        additionalDocument: true
      } as RequiredDocumentViewModel];
      this.resetDatesInResetAll = false;
      this.refreshDocuments();
    }
  }


  removeDocumentFromRequiredDocuments(documentType) {
    if (documentType) {

      this.documents = [...this.documents?.filter(item => item.documentType.id != documentType.id)];
      this.removeFromDocumentAnswers(documentType.id);
      this.resetDatesInResetAll = false;
      this.refreshDocuments();
    }
  }

  selectedDocumentsIds: number[] = [];

  refreshDocuments() {
    this.selectedDocumentsIds = this.documents?.map(item => item.documentType?.id);
    this.availableDocumentTypes = this.allUserDocumentTypes?.filter(doc => this.selectedDocumentsIds?.indexOf(doc.id) === -1) || [];
    this.ResetAll();
  }

  get isVisibleExpiryDate() {
    return this.form.isEnableSupplierFormExpiryDate && (
      this.formType == this.FormTypeEnum.SupplierForm ||
      this.formType == this.FormTypeEnum.AssetRegistration ||
      this.formType == this.FormTypeEnum.AssetOperator ||
      this.formType == this.FormTypeEnum.AssetOperation);
  }

  get isVisibleVersion() {
    return this.formType == this.FormTypeEnum.SupplierForm;
  }

  operatorMode: AssetOperatorMode;

  setOperatorMode(value) {
    this.operatorMode = value;
  }


  get operatorFullName() {
    return this.form?.draftFilledForm?.operatorFirstName + ' ' + this.form?.draftFilledForm?.operatorLastName;
  }

  // any means:
  // RequiredDocumentAnswer
  // UserDocumentType
  private removeFromDocumentAnswers(documentTypeId: number, questionId?: number) {
    let index;
    if (documentTypeId && !questionId)
      index = this.initialDocumentAnswers?.findIndex(d => d.documentTypeId == documentTypeId);
    if (questionId)
      index = this.initialDocumentAnswers?.findIndex(d => d.questionId == questionId);

    if (index > -1) {
      this.initialDocumentAnswers.splice(index, 1);
    }
  }

  private static getTimeOutOfDate(date: Date): ObcTime {
    if (!date)
      return null;

    return {
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds(),
    }
  }

  setStartAndEndTimes() {
    if (this.startTime) {
      this.startDate?.value?.setHours(this.startTime?.hour, this.startTime?.minute, this.startTime?.second, 0);
    } else {
      this.startDate?.value?.setHours(0, 0, 0, 0);
    }
    if (this.endTime) {
      this.endDate?.value?.setHours(this.endTime?.hour, this.endTime?.minute, this.endTime?.second, 0);
    } else {
      this.endDate?.value?.setHours(23, 59, 59, 0);
    }
  }
}
