<section class="card">
  <header class="card-header">
    <h5>
      Request Revision - {{requestorUserFullname}}
      <span *ngIf="currentSiteInductionHistoryLog">
        ({{currentSiteInductionHistoryLog?.title}})
        <span class="badge obc-badge badge-{{getStyleCssClass()}}">{{currentSiteInductionHistoryLog?.statusText}}</span>
      </span>
    </h5>
    <div class="card-actions">
      <a class="card-action card-action-dismiss" (click)="closeModal()"></a>
    </div>
  </header>
  <div class="card-body overflow-hidden">

    <div class="m-0">
      <div class="row">
        <div class="col-12 col-lg-3" *ngIf="showEntireReviewPanel()">
          <obc-approve-reject *ngIf="showReviewPanel()" [entityId]="currentSiteInductionId"
            [entityType]="ApproveEntityType.Induction" [isRequestRevision]="isRequestRevision()"
            [showCloseButton]="false" (onRefresh)="onRefreshFunc();"></obc-approve-reject>
          <div *ngIf="!showReviewPanel()" class="card">
            <div class="card-header">
              <h5>Comments</h5>
            </div>
            <div class="card-body">
              {{getCommentPanelContent()}}
            </div>
          </div>
        </div>
        <div class="{{showEntireReviewPanel()?'col-12 col-lg-6':'col-12 col-lg-9'}}">
          <obc-site-induction-detail [onlyDisplayFilledInfo]="true" [showCloseButton]="false"
            [siteInductionId]="currentSiteInductionId" *ngIf="currentSiteInductionHistoryLog">
          </obc-site-induction-detail>
        </div>
        <div class="col-12 col-lg-3">
          <div class="card">
            <div class="card-header">
              <h5>History</h5>
            </div>
            <div class="card-body">
              <accordion>
                <ng-container
                  *ngFor="let historyData of historyLogData; last as isLast; first as isFirst; let index = index">
                  <accordion-group #accordion [isOpen]="isHistoryPanelOpen(historyData)"
                    class="{{historyData.siteInductionId == currentSiteInductionId ? 'card-info':''}}">
                    <div accordion-heading>
                      <ng-container *ngIf="isFirst">
                        <span class="badge obc-badge badge-success">Latest Version</span>
                        <br>
                      </ng-container>
                      <!-- <i class="fa {{getSiteInductionIcon(historyData)}} mr-2"></i> -->
                      <strong>{{ historyData.registerDate | datetime }}</strong>
                      ({{historyData.title}})
                      <button
                        class="btn btn-sm btn-{{historyData.status == SiteInductionStatus.PendingToApprove?'info':'default'}} pull-right shadow-lg {{historyData.status == SiteInductionStatus.PendingToApprove?'':'text-info border'}}"
                        *ngIf="historyData.siteInductionId != currentSiteInductionId"
                        (click)="showSiteInduction(historyData)">
                        {{getHistoryItemButtonText(historyData)}}
                      </button>
                    </div>
                    <div class="d-flex flex-column pr-2">

                      <ng-container *ngIf="historyData.status == SiteInductionStatus.PendingToApprove">
                        <ng-container
                          *ngTemplateOutlet="historyItemTemplate; context: {$implicit:{date: null, title: getStatusText(SiteInductionStatus.PendingToApprove),userName:'',description:''}}"></ng-container>
                      </ng-container>

                      <ng-container *ngIf="historyData.approveDate">
                        <ng-container
                          *ngTemplateOutlet="historyItemTemplate; context: {$implicit:{date: historyData.approveDate, title: getStatusText(historyData.approverUserName ? SiteInductionStatus.ApprovedByHost : SiteInductionStatus.AutoApproved),userName:historyData.approverUserName,description:historyData.approveDescription }}"></ng-container>
                      </ng-container>

                      <ng-container *ngIf="historyData.rejectDate">
                        <ng-container
                          *ngTemplateOutlet="historyItemTemplate; context: {$implicit:{date: historyData.rejectDate, title:getStatusText(SiteInductionStatus.RejectByHost),userName:historyData.rejecterUserName,description:historyData.rejectDescription }}"></ng-container>
                      </ng-container>

                      <ng-container *ngIf="historyData.requestRevisionDate">
                        <ng-container
                          *ngTemplateOutlet="historyItemTemplate; context: {$implicit:{date: historyData.requestRevisionDate, title:'Request Revision',userName:historyData.requestRevisionUserName,description:historyData.requestRevisionDescription }}"></ng-container>
                      </ng-container>

                      <ng-container *ngIf="historyData.expiryDate">
                        <ng-container
                          *ngTemplateOutlet="historyItemTemplate; context: {$implicit:{date: historyData.expiryDate, title:'Expiry',userName:'',description:''}}"></ng-container>
                      </ng-container>

                    </div>
                  </accordion-group>
                </ng-container>
              </accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #historyItemTemplate let-historyData>
  <div>
    <div>
      <span><strong>{{historyData.title}}</strong></span>
      <span *ngIf="historyData.date" class="pull-right">{{ historyData.date | datetime }}</span>
    </div>
    <div *ngIf="historyData.userName" class="ml-3">
      {{ historyData.userName }}
    </div>
    <div *ngIf="historyData.description" class="ml-3">
      <small>{{historyData.description}}</small>
    </div>
  </div>
</ng-template>
