import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MaxFileSize } from '../../../../enums/constants';
import { FormControl, Validators } from '@angular/forms';
import { CustomFormControl } from 'src/app/models/custom-formcontrol';
import { getFileExtenstion, isValidImageFile, toBase64 } from 'src/app/helpers/general-functions';
import { ModalService } from 'src/app/services/modal.service';
import { UserDocumentType } from 'src/app/models/user-document-type';
import { DocumentMultiImageMode } from 'src/app/enums/document-multi-image-mode-enum';
import { RequiredDocumentAnswer, UploadFileInfo } from 'src/app/models/requiredDocumentAnswer';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { CacheService } from "../../../../services/cache.service";
import { FormService } from 'src/app/services/form.service';
import { ItemRendererDirtyControlsModel } from "../../../../models/item-renderer-dirty-controls-model";

@Component({
  selector: 'obc-required-document-renderer',
  templateUrl: './required-document-renderer.component.html',
  styleUrls: ['./required-document-renderer.component.css'],
})
export class RequiredDocumentRendererComponent implements OnInit {
  DatetimePickerMode = DatetimePickerMode;

  rdc: CustomFormControl;
  answer: RequiredDocumentAnswer;
  validImageExtentions = [
    '.png', '.jpg', '.jpeg'
  ];
  displayMimeTypeError: boolean = false;

  @Output() removeDocumentType = new EventEmitter();

  isRequiredDocument() {
    return this.rdc?.hasValidator(Validators.required)
  }

  @Input() set control(model: CustomFormControl) {
    this.rdc = model;
    if (this.rdc?.item?.documentType)
      this.rdc.item.documentType.minExpiryDate = new Date(this.rdc?.item?.documentType?.minExpiryDate);
    this.answer = {
      documentTypeId: model.item?.documentType?.id,
      filaAsBase64: model.item?.initialAnswer?.filaAsBase64,
      fileType: model.item?.initialAnswer?.fileType,
      additionalFiles: model.item?.initialAnswer?.additionalFiles ?? [],
      additionalFileUrls: model.item?.initialAnswer?.additionalFileUrls ?? [],
      expireDate: model.item?.initialAnswer?.expireDate,
      title: model.item?.initialAnswer?.title,
      inductionId: model.item?.inductionId,
      questionId: model.item?.questionId,
      minExpiryDate: model.item?.documentType?.minExpiryDate,
    }
    // if (this.type.multiImageMode == DocumentMultiImageMode.FrontBack) {
    //   this.addAditionalFile();
    // }
  }

  documentMultiImageModes = DocumentMultiImageMode;

  get type(): UserDocumentType {
    return this.rdc?.item?.documentType as UserDocumentType;
  };

  constructor(private modalService: ModalService, private formService: FormService, private cacheService: CacheService) {
  }

  documentTypeSelectorOldId: number;
  ngOnInit() {
    if (this.rdc?.item?.question?.documentTypeId) {
      this.documentTypesSelector.setValue(this.answer.documentTypeId);
      this.documentTypeSelectorOldId = this.answer.documentTypeId;
    }
  }

  async onSelectRequiredDocumentFile($event, formControl: FormControl, isAdditionalFile: boolean = false) {
    this.confirmChanges(async () => {
      this.displayMimeTypeError = false;

      let file = $event.target.files[0];
      if (!isValidImageFile(file.type)) {
        this.displayMimeTypeError = true;
        this.answer.filaAsBase64 = null;
        this.answer.fileType = null;
        this.rdc.setValue(this.validateAnswer() ? this.answer : null);
        return;
      }

      let fileInfo = await this.getFileInfo(file);
      if (fileInfo == null) {
        $event.target.value = null;
        return;
      } else {
        if (!isAdditionalFile) {
          this.answer.filaAsBase64 = fileInfo.base64;
          this.answer.fileType = fileInfo.fileType;
        } else {
          let additionalFile = {
            fileAsBase64: fileInfo.base64,
            fileExtension: fileInfo.fileType,
            fileSize: file.size,
          } as UploadFileInfo;

          this.answer.additionalFiles.push(additionalFile);
          $event.target.value = "";
        }
        // validate files
        if (await this.validateAnswer()) {
          this.rdc.setValue(this.answer);
          this.formService.isFormDirty.next(true);
          this.rdc.item.isDirty = true;
        }
        else
          this.rdc.setValue(null);
      }
      this.triggerDirtyListener();
    })
  }

  updateAnswerTitle(event: any) {
    this.confirmChanges(() => {
      this.answer.title = event.target.value;
      this.rdc.setValue(this.answer);
      this.triggerDirtyListener();
    })
  }

  updateAnswerExpireDate(event: any) {
    this.confirmChanges(() => {
      this.answer.expireDate = event;
      this.rdc.setValue(this.answer);
      this.triggerDirtyListener();
    })
  }

  get isDocumentComponentValid(): boolean {
    if (this.rdc.item?.initialAnswer != null && this.rdc.item?.isDirty != true) return true;
    let valid = this.rdc.valid && this.isAdditionalFilesAreValid;
    if (valid === false) return false;

    if (this.answer?.additionalFiles?.find(i => i.fileAsBase64 != null))
      return this.rdc.value != null;
    return true;
  }

  get isAdditionalFilesAreValid() {
    if (this.answer?.additionalFiles == null || this.answer?.additionalFiles?.length == 0) return true;
    return this.answer?.additionalFiles?.find(i => i.fileAsBase64 == null) == null;
  }

  private validateAnswer() {
    if (!this.answer?.filaAsBase64)
      return false;

    if (!this.answer?.documentTypeId)
      return false;

    if (this.answer?.additionalFiles?.length)
      for (let index = 0; index < this.answer?.additionalFiles?.length; index++) {
        const element = this.answer?.additionalFiles[index];
        if (!element.fileAsBase64)
          return false;
      }
    return true;
  }

  private async getFileInfo(file) {
    if (file.size > MaxFileSize * 1024000) {
      this.modalService.error(`Maximum File Size is ${MaxFileSize}mb`, "").subscribe(_ => {
        //alert('Maximum File Size is 5mb');
      });

      return null;
    }
    let base64 = (await toBase64(file)) as string;
    base64 = base64.split(';base64,')[1];

    let fileType = getFileExtenstion(file.name);
    return { base64: base64, fileType: fileType };
  }

  clearWithConfirm(additionalFile?: UploadFileInfo) {
    this.modalService.confirm("Are you sure you want to delete the file?").subscribe((res) => {
      if (res) {
        this.clearForm(additionalFile);
      }
    });
  }

  clearForm(additionalFile?: UploadFileInfo) {
    if (additionalFile == null) {
      this.answer.filaAsBase64 = null;
      this.answer.fileType = null;
    } else {
      this.removeAdditionalFile(additionalFile);
    }

    this.rdc.setValue(this.validateAnswer() ? this.answer : null);
    this.triggerDirtyListener();
  }

  removeAdditionalFile(additionalFile: UploadFileInfo) {
    this.answer.additionalFiles.splice(this.answer.additionalFiles.indexOf(additionalFile), 1);
    this.triggerDirtyListener();
  }

  onRemoveDocumentType(documentType: UserDocumentType) {
    this.modalService.confirm("Are You Sure?", `Delete ${documentType.title}`).subscribe(result => {
      if (result) {
        this.removeDocumentType.emit(documentType);
        this.triggerDirtyListener();
      }
    })
  }

  confirmChanges(fn, cancelFn?) {
    if (this.rdc?.item?.initialAnswer) {
      if (this.rdc.item.isDirty != true) {
        this.modalService.confirm("Are you sure you want to upload a new document?").subscribe((res) => {
          if (res === true) {
            this.rdc.item.isDirty = true;
            this.rdc.item.initialAnswer = null;
            this.answer = this.emptyAnswer();
            fn();
          } else if (res === false) {
            if (cancelFn)
              cancelFn();
          }
        });
      } else {
        fn();
      }
    } else {
      fn();
    }
  }

  documentTypesSelector: FormControl = new FormControl();
  onSelectedDocumentType(event) {
    this.confirmChanges(() => {
      this.answer = this.emptyAnswer();
      this.answer.documentTypeId = parseInt(event.target.value);
      this.rdc.item.documentType = this.rdc.item?.documentTypes.find(x => x.id == this.answer.documentTypeId);
      this.rdc.item.documentType.minExpiryDate = this.rdc?.item?.documentType?.minExpiryDate ? new Date(this.rdc?.item?.documentType?.minExpiryDate) : null;
      this.rdc.setValue(this.answer);
      this.rdc.item.initialAnswer = null;
      this.clearForm(null)
    }, () => {
      this.documentTypesSelector.setValue(this.documentTypeSelectorOldId, { emitEvent: false });
    })
  }

  displayInitialAnswerSrc() {
    if (this.answer?.filaAsBase64) {
      return 'data:image/' + this.answer?.fileType + ';base64,' + this.answer?.filaAsBase64;
    } else if (this.rdc?.item?.initialAnswer?.fileUrl) {
      return this.rdc?.item?.initialAnswer?.fileUrl;
    }
    return null;
  }
  displayAdditionalFile(file: UploadFileInfo) {
    if (file.fileAsBase64) {
      return 'data:image/' + file.fileExtension + ';base64,' + file.fileAsBase64;
    }
  }

  emptyAnswer() {
    return {
      documentTypeId: this.answer.documentTypeId,
      filaAsBase64: null,
      fileType: null,
      additionalFiles: [],
      additionalFileUrls: [],
      minExpiryDate: null,
      userDocumentId: null,
      inductionId: this.answer.inductionId,
      questionId: this.answer.questionId,
    } as RequiredDocumentAnswer;
  }

  triggerDirtyListener() {
    this.cacheService.formControlDirtySubject.next({
      questionId: this.answer.questionId,
      documentTypeId: this.answer.documentTypeId
    } as ItemRendererDirtyControlsModel);
  }
}
