import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { VideoViewModel } from 'src/app/models/video-viewmodel';


@Component({
  selector: 'obc-light-box',
  templateUrl: './light-box.component.html',
  styleUrls: ['./light-box.component.css']
})
export class LightBoxComponent implements OnInit, OnDestroy {
  aspectRatio: number = 1.8;

  videoPlayerHeight: string = '100%';
  subscription: Subscription;
  @ViewChild('modal') modal: ElementRef;
  @Output() hide: EventEmitter<any> = new EventEmitter<any>();
  @Input() video : VideoViewModel;
  @Input() set resizeSubject(subject: Subject<any>) {
    this.subscription = subject.subscribe(res => {
      this.videoPlayerHeight = `${this.modal.nativeElement.offsetWidth / this.aspectRatio}px`;
    })
  };

  constructor() { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
  }

  onclose() {
    this.hide.emit("");
  }

  get videoUrl():string {
    return this.video.videoURL;
  }

}
