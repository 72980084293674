<form [formGroup]="fg">
  <div class="form-group row pb-2 mb-1">
    <label class="col-sm-4 control-label text-left pt-2">Title</label>
    <div class="col-sm-8">
      <div class="input-group mb-3">
        <input type="text" formControlName="contactTitle" class="form-control"/>
      </div>
    </div>
  </div>
  <div class="form-group row pb-2 mb-1">
    <label class="col-sm-4 control-label text-left pt-2">Full Name</label>
    <div class="col-sm-8">
      <div class="input-group mb-3">
        <input type="text" formControlName="contactFullName" class="form-control"/>
      </div>
    </div>
  </div>
  <div class="form-group row pb-2 mb-1">
    <label class="col-sm-4 control-label text-left pt-2">Phone Number</label>
    <div class="col-sm-8">
      <div class="input-group mb-3">
        <input type="text" formControlName="contactNumber" class="form-control"/>
      </div>
    </div>
  </div>
</form>
