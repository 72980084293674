import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(array: any[], field: string, asc: boolean = true, nullIsFirst: boolean = false): any[] {
    if (!Array.isArray(array)) {
      return;
    }

    array.sort((a, b) => {
      var v1 = a[field];
      var v2 = b[field];
      // equal items sort equally
      if (v1 === v2) {
        return 0;
      }
      // nulls sort after anything else according to nullIsFirst
      else if (v1 === null) {
        return nullIsFirst ? -1 : 1;
      }
      else if (v2 === null) {
        return nullIsFirst ? 1 : -1;
      }

      if (typeof v1 == 'string')
        v1 = v1.toLowerCase();

      if (typeof v2 == 'string')
        v2 = v2.toLowerCase();

      if (asc)
        return v1 > v2 ? 1 : v1 < v2 ? -1 : 0;
      return v1 > v2 ? -1 : v1 < v2 ? 1 : 0;

    });

    return array;
  }

}

@Pipe({
  name: 'orderByFuction'
})
export class OrderByFuctionPipe implements PipeTransform {

  transform(array: any[], field: Function, asc: boolean = true, nullIsFirst: boolean = false): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a, b) => {
      var v1 = field(a);
      var v2 = field(b);
      // equal items sort equally
      if (v1 === v2) {
        return 0;
      }
      // nulls sort after anything else according to nullIsFirst
      else if (v1 === null) {
        return nullIsFirst ? -1 : 1;
      }
      else if (v2 === null) {
        return nullIsFirst ? 1 : -1;
      }

      if (typeof v1 == 'string')
        v1 = v1.toLowerCase();

      if (typeof v2 == 'string')
        v2 = v2.toLowerCase();

      if (asc)
        return v1 > v2 ? 1 : v1 < v2 ? -1 : 0;
      return v1 > v2 ? -1 : v1 < v2 ? 1 : 0;

    });

    return array;
  }

}
