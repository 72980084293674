<div class="card">
  <div class="card-header d-flex justify-content-between">
    <h5 class="m-0">Column Visibility</h5>
    <div class="card-actions d-flex align-items-center" style="top: 6px">
      <obc-switch class="mr-3 position-relative" style="top: -3px;" [trueLabel]="' Advanced '" [falseLabel]="' Basic '"
                  [(ngModel)]="this.localScopeTableSettings.isDetailsMode">
      </obc-switch>
      <a (click)="this.modalRef.hide()" class="card-action card-action-dismiss"></a>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-12">
            <div class="clearfix px-2">
              <div class="row ml-1 ns">
                <ng-container *ngFor="let item of data">
                  <div class="p-0 col-12 {{ !this.localScopeTableSettings?.isDetailsMode ? 'col-md-6' : '' }} checkbox-container">
                    <div class="">
                      <div class="d-flex align-items-center">
                        <input type="checkbox"
                               [(ngModel)]="item.visible"
                               class="form-check-input mr-2"
                               id="{{item.key}}">
                        <label class="form-check-label"
                               for="{{item.key}}">{{item.title}}</label>
                      </div>
                      <div
                        class="report-fields-title"
                        *ngIf="this.localScopeTableSettings?.isDetailsMode && item.csvFields?.length > 0"
                        >Report Fields: </div>
                      <div class="pl-3" *ngIf="this.localScopeTableSettings?.isDetailsMode">
                        <ng-container *ngFor="let innerItem of item.csvFields">
                          <div class="d-inline-block mr-5" >
                            <div class="d-flex align-items-center ws-nowrap">
                              <input type="checkbox"
                                     [disabled]="!item.visible"
                                     [(ngModel)]="innerItem.value"
                                     class="form-check-input mr-2"
                                     id="{{item.key}}{{innerItem.key}}">
                              <label class="form-check-label"
                                     for="{{item.key}}{{innerItem.key}}">{{innerItem.title}}</label>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <hr class="my-3">
            <div class="form-group pl-0 form-check d-flex align-items-center">
              <input type="checkbox" style="margin-top: 3px" [(ngModel)]="hideEmptyColumns"
                     id="hideEmptyColumns">
              <label class="form-check-label ml-2"
                     for="hideEmptyColumns">Hide Empty Columns</label>
            </div>
          </div>
          <div class="col-12 mt-3 text-right">
            <div class="d-flex justify-content-between">
              <button class="reorder-button btn btn-default" (click)="onReorderClicked()">
                <i class="fa fa-exchange-alt mr-2" style="opacity: 0.7"></i>
                Reorder Columns</button>
              <button class="btn btn-primary" (click)="onSaveChanges()">Save Changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



