<div class="row">
  <div class="col-md-12">
    <div class="card">
      <obc-loader [show]="inProgress"></obc-loader>
      <header class="card-header">Site Briefing Report</header>
      <div class="card-body">
        <span class="mr-5">
          <span class="d-inline-block mt-2 mr-1">Include Inactive Site Briefing Forms: </span>
          <obc-switch [trueLabel]="'Yes'" [falseLabel]="'No'" [value]="includeInactiveSiteBriefingForms"
            (valueChanged)="onIncludeInactiveSiteBriefingFormsChanged($event)">
          </obc-switch>
        </span>
        <app-base-grid [columns]="gridColumns" [configs]="gridConfig" #grid>

          <ng-template obcGridTemplateName="status" let-item="data">
            {{item.status | SiteBriefingStatusPipe}}
          </ng-template>
          <ng-template obcGridTemplateName="submittedBy" let-item="data">
            {{item.submittedByFullName }}
            <span *ngIf="item.submissionMethod === GenericFormSubmissionMethod.AutoRenew"
              class="p-1 badge obc-badge badge-info">Auto-Renew</span>
          </ng-template>

          <ng-template obcGridTemplateName="actions" let-viewDetails let-item="data">
            <div *ngIf="item.status != SiteBriefingStatus.Draft">
              <button class="btn btn-success" (click)="openModal(formDetails,item.id)">
                <i class="fa fa-eye"></i>
                View Details
              </button>

              <div class="btn-group m-1" dropdown *ngIf="item.status != SiteBriefingStatus.Draft">
                <button id="button-basic" dropdownToggle type="button" class="btn btn-info dropdown-toggle"
                  aria-controls="dropdown-basic">
                  More Actions
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                  aria-labelledby="button-basic">
                  <li role="menuitem">
                    <button id="button-basic" type="button"
                      class="dropdown-item cursor-pointer d-flex align-items-center" (click)="exportPdf(item)">
                      <i class="fa fa-file-pdf mr-2"></i> Download PDF
                    </button>
                  </li>
                  <li role="menuitem" *ngIf="item.status == SiteBriefingStatus.Active">
                    <button class="dropdown-item cursor-pointer d-flex align-items-center"
                      (click)="deactiveClicked(item.id)">
                      <i class="fa fa-ban mr-2"></i> Deactivated Document
                    </button>
                  </li>
                </ul>
              </div>

            </div>
          </ng-template>

          <ng-template #formDetails>
            <obc-site-briefing-form-viewer [selectedFormDataId]="selectedFormDataId"></obc-site-briefing-form-viewer>
          </ng-template>

        </app-base-grid>
      </div>
    </div>
  </div>
</div>