import { Component, Input, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TriggerAction } from 'src/app/enums/trigger-action.enum';
import { ToolStatusModel, ToolStatusViewModel } from 'src/app/models/inventory/tool-status-model';
import { ToolStatusTriggerViewModel } from 'src/app/models/inventory/tool-status-trigger-model';
import { InventoryService } from 'src/app/services/inventory.service';
import { ManageInventoryService } from 'src/app/services/manage-inventory.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'obc-inventory-status-triggers',
  templateUrl: './inventory-status-triggers.component.html',
  styleUrls: ['./inventory-status-triggers.component.scss']
})
export class InventoryStatusTriggersComponent implements OnInit {
  _toolStatus: ToolStatusViewModel;
  inProgress: boolean = false;
  triggers = new BehaviorSubject<ToolStatusTriggerViewModel[]>([])
  @Input() set toolStatus(value: ToolStatusViewModel) {
    if (this._toolStatus?.id != value?.id) {
      this._toolStatus = value;
      this.refreshTriggers();
    }
  }
  constructor(private manageInventoryService: ManageInventoryService,
    private modalService: ModalService,
    private bsModalService: BsModalService) { }

  ngOnInit() {
  }

  refreshTriggers() {
    this.inProgress = true;
    this.manageInventoryService.getToolStatusTriggers(this._toolStatus.id)
      .pipe(finalize(() => { this.inProgress = false }))
      .subscribe(res => {
        this.triggers.next(res.data);
      });
  }

  getTriggerActionFriendlyName(action: TriggerAction): string {
    switch (action) {
      case TriggerAction.Email: return 'Send an Email';
      default: return TriggerAction[action];
    }
  }

  onToolStatusTriggerStateChange($event: boolean, trigger: ToolStatusTriggerViewModel) {
    if ($event != trigger.status) {
      this.inProgress = true;
      this.manageInventoryService.changeStatusToolStatusTrigger(this._toolStatus.id, trigger.id)
        .pipe(finalize(() => { this.inProgress = false }))
        .subscribe(res => {
          this.refreshTriggers();
        });
    }
  }

  onAddToolStatusTrigger(modalTemplate: any) {
    this.currentTrigger = null;
    this.editModalHandle = this.bsModalService.show(modalTemplate, {
      ignoreBackdropClick: true,
    })
  }

  currentTrigger: ToolStatusTriggerViewModel = null;
  editModalHandle: any;
  onEditToolStatusTrigger(trigger: ToolStatusTriggerViewModel, modalTemplate: any) {
    this.currentTrigger = trigger;
    this.editModalHandle = this.bsModalService.show(modalTemplate, {
      ignoreBackdropClick: true,
    })
  }

  onRemoveToolStatusTrigger(trigger: ToolStatusTriggerViewModel) {
    this.modalService.confirm("Are You Sure You Want Remove This Trigger?", "Remove Trigger").subscribe(res => {
      if (res) {
        this.inProgress = true;
        this.manageInventoryService.removeToolStatusTrigger(this._toolStatus.id, trigger.id)
          .pipe(finalize(() => { this.inProgress = false }))
          .subscribe(res => {
            this.refreshTriggers();
          });
      }
    })
  }

  onCloseInventoryStatusTriggerEditor() {
    this.editModalHandle.hide();
    this.refreshTriggers();
  }

}
